import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../common/TabStyles.css';
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import StockDetailsPopup from "../Popups/StockDetailsPopup";
export default function PickSlipGenerationDialog({
  open,
  handleClose,
  data,
  handleResponse,
  onchangeFormData,
  handleFormSubmit,
  addRow,
  deleteRow,
  setFormData,
  PickSlipTableData,
  ware_HOUSE,
  setware_HOUSE,
  selectedRows,
  setSelectedRows,
  sd_IN_BR,
  setsd_IN_BR,
  sd_LO_CN,
  setsd_LO_CN,
  selectedCustomer,
  setselectedCustomer,
  selectedShipto,
  setselectedShipto,
  sdOrTy,
  sd_ST_CD,
  onchangeDropDownFormData1,
}) {
  const { 
    sd_UK_NO,
    sdOrNo,
    sd_OR_CO,
    sd_LN_NO,
    sd_IT_NM,sd_CO_CT,
    sd_OR_QT,
    sd_TR_UM,
    sd_SH_QT,
    sd_PK_NO,
    sh_ST_CD,sd_CU_NO,
    sd_SH_TO,
    
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,
    tablebutton,tablestyle,multitablestyle,multitableheader,Dialogstyle } = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const onchangedata = (e) => {
    const { name, value } = e.target;
      onchangeFormData(name, value);
  };
   // dropdowns
   const [sd_LO_CNOptions, setsd_LO_CNOptions] = useState([]);
   const urlloc = `${domain}/api/LocationMaster/DropDown`;
   const finalUrl1 = `${urlloc}`;
 
   const FetchLocation = async () => {
     try {
       const response = await axios.get(finalUrl1, {
         headers: {
           "Content-Type": "application/json",
           Authorization: `Bearer ${token}`,
           "Access-Control-Allow-Origin": "http://localhost:3000",
           "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
         },
       });
       const options = response.data.map((item) => ({
         label: item.lm_LO_CN,
         value: item.lm_LO_CN,
       }));
       setsd_LO_CNOptions(options);
     } catch (error) {
       console.error("Error fetching dropdown options:", error);
     }
   };
   useEffect(() => {
    FetchLocation();
  }, []);


  // location advance search
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedCostCentre, setSelectedCostCentre] = useState('');
  const [UK_NO, setUK_NO] = useState('');
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [StockDetailsPopupVisible, setStockDetailsPopupVisible] = useState(false);

  const OpenStockDetailsPopup = (index) => {
    // alert("index" + index);
    const item = PickSlipTableData[index].sd_IT_NM;
    const costCentre = PickSlipTableData[index].sd_CO_CT;
    const uKNO = PickSlipTableData[index].sd_UK_NO;
    // alert("uk_no" + uKNO);
    setSelectedItem(item);
    setSelectedCostCentre(costCentre);
    setUK_NO(uKNO);
    setStockDetailsPopupVisible(true);
  };
  const [stockDetailsData, setStockDetailsData] = useState([]);
  const handleStockDetailsConfirm = (dataStock) => {
    // console.log(dataStock);
    const existingList = stockDetailsData;
    if (dataStock && dataStock.length > 0) {
      const size = dataStock.length;
      for (let i = 0; i < size; i++){
      const Request =
        {
          "is_LO_CN" : dataStock[i].is_LO_CN,
          "is_LT_NO" : dataStock[i].is_LT_NO,
          "is_AV_QT" : dataStock[i].allocatedQty,
          "isUkNo" : dataStock[i].isUkNo
        }
        existingList.push(Request);
      }
    }

    setStockDetailsData(existingList); 
    // console.log("Data received from StockDetailsPopup:", existingList);
    setStockDetailsPopupVisible(false); 
  };
  // advance search
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
  const [addressTypeCust, setaddressTypeCust] = useState("C");
  const [addressTypeShip, setaddressTypeShip] = useState("C");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const openShiptoPopup = () => {
    setShipToPopupVisible(true);
  };
  const handleSelectCustomer =async (dataAb) => {
    setselectedCustomer(dataAb.ab_AD_NM);
    document.getElementById ("sd_CU_NO").value =  dataAb.abAdNo;
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectShipTo =async (data) => {
    setselectedShipto(data.ab_AD_NM);
    document.getElementById ("sd_CU_NO").value =  data.abAdNo;
    setShipToPopupVisible(false);
    setShowSearchIcon(false);
  };
  // generation
  const PickGenerate =  async () => {
    const  UrlGEN = `${domain}/api/PickSlip/GeneratePickNumber`;
    const table = document.getElementById("PickSlipTable");
    const totalRowCount = table.tBodies[0].rows.length;
    const salesDetail = [];
    for (let i = 0; i < totalRowCount; i++) {  
      const Details = {
        sd_SH_QT: document.getElementById("sd_SH_QT_" + i).value,
        sd_UK_NO: document.getElementById("sd_UK_NO_" + i).value,
        sd_LO_CN: table.tBodies[0].rows[i].cells[13].children[0].children[0].children[2].value,
      }
      salesDetail.push(Details);
    }
    const requestINP = {
      // header
        "detail":{
            "sd_CU_NO":document.getElementById("sd_CU_NO").value,
            "ware_HOUSE":ware_HOUSE, // version _id -N
            "sd_ST_CD":sd_ST_CD,
            "sd_SH_TO":document.getElementById("sd_CU_NO").value,
            "sd_IN_BR":sd_IN_BR // flag--version_id -Y
            },
      // multirow
        "salesDetail": salesDetail,
        "stockAvailabilityList" : stockDetailsData
    }
    console.log(requestINP);
    try {
      const response = await axios.post(UrlGEN, requestINP, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const data = response.data;
      if(data){
        handleResponse(response.data);
      }
      handleClose();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // confirmation
  const PickConfirm =  async () => {
    const  Urlconfirm = `${domain}/api/PickSlip/GeneratePickSlipDetails`
    const table = document.getElementById("PickSlipTable");
    const totalRowCount = table.tBodies[0].rows.length;
    const salesDetail = [];
    for (let i = 0; i < totalRowCount; i++) {  
      console.log(table.tBodies[0].rows[i].cells[13].children[0].children[0].children[2].value);
      const Details = {
        sd_SH_QT: document.getElementById("sd_SH_QT_" + i).value,
        sd_UK_NO: document.getElementById("sd_UK_NO_" + i).value,
        sd_LO_CN: table.tBodies[0].rows[i].cells[13].children[0].children[0].children[2].value,
      }
      salesDetail.push(Details);
    }
    const requestconfirm = {
        "detail":{
            "sd_CU_NO" : document.getElementById("sd_CU_NO").value,
            "ware_HOUSE": ware_HOUSE, // version _id
            "sd_ST_CD" : sd_ST_CD,
            "sd_SH_TO" : document.getElementById("sd_CU_NO").value,
            "sd_IN_BR" : sd_IN_BR // flag--version_id
            },
        "salesDetail": salesDetail,
    }
    console.log(requestconfirm);
    try {
      const response = await axios.post(Urlconfirm, requestconfirm, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const data = response.data;
      if(data){
        handleResponse(response.data);
      }
      handleClose();
      console.error("Confirmed Successfully:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // canecl
  const PickCancel =  async () => {
    const  UrlCancel = `${domain}/api/PickSlip/CancelPickSlip`
    const requestcan = {
      "salesDetail":[{
        "sd_UK_NO":`${selectedRows[0]}`
        }]
      }
    try {
      const response = await axios.post(UrlCancel, requestcan, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const data = response.data;
      console.error("Canceled Successfully:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{sd_UK_NO ? "Revise Pick Slip" : "Add Pick Slip"}</h5>
      <div style={{width:'60rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={{ width: "5%", marginRight: "0.5rem",border:"none",backgroundColor:"transparent",color:"blue", }}
          icon="pi pi-check"
          onClick={PickGenerate}
          tooltip="Generate Pick slip"
        />
        <Button
          style={{ width: "5%", marginRight: "0.5rem",border:"none",backgroundColor:"transparent",color:"blue", }}
          icon="pi pi-check-circle" 
          onClick={PickConfirm}
          tooltip="Confirm Pick slip"
        />
        <Button
          style={{ width: "5%",border:"none",backgroundColor:"transparent",color:"blue", }}
          icon="pi pi-times"
          onClick={PickCancel}
          tooltip="Cancel Pick slip"
        />
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle}>
        <div style={{display:"none"}}>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sd_UK_NO" className="font-bold">
              uk_no
            </label>
            <InputText
              id="sd_UK_NO"
              name="sd_UK_NO"
              value={sd_UK_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ware_HOUSE" className="font-bold">
              ware_HOUSE
            </label>
            <InputText disabled
              id="ware_HOUSE"
              name="ware_HOUSE"
              value={ware_HOUSE}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sd_IN_BR" className="font-bold">
            sd_IN_BR
            </label>
            <InputText disabled
              id="sd_IN_BR"
              name="sd_IN_BR"
              value={sd_IN_BR}
              onChange={(e) => onchangedata(e)}
            />
          </div>
        </div>
        <div className="formgrid grid">
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sd_CU_NO" className="font-bold">
                Customer <span style={{color:"red"}}>*</span>
            </label>
            <div style={{display:"flex"}}>
            <InputText 
              id="SelectedCustomer"
              name="SelectedCustomer"
              onFocus={() => setShowSearchIcon(true)}                      
              onChange={(e) => onchangedata(e)}
              value={selectedCustomer}
            />
              {showSearchIcon && (
              <i 
              className="pi pi-search"
              style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "7%",
                cursor: "pointer",                        
              }}
              onClick={(e) => openCustomerPopup(e)}
            ></i>
              )}
              <InputText disabled style={{display:"none"}}
              id="sd_CU_NO"
              name="sd_CU_NO"
              value={sd_CU_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>            
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="sd_SH_TO" className="font-bold">
              Ship To <span style={{color:"red"}}>*</span>
          </label>
          <div style={{display:"flex"}}>
          <InputText 
            id="selectedShipto"
            name="selectedShipto"
            onFocus={() => setShowSearchIcon(true)}                      
            onChange={(e) => onchangedata(e)}
            value={selectedShipto}
          />
            {showSearchIcon && (
            <i 
            className="pi pi-search"
            style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "7%",
              cursor: "pointer",
              
            }}
            onClick={(e) => openShiptoPopup(e)}
          ></i>
            )}
            <InputText disabled style={{display:"none"}}
            id="sd_SH_TO"
            name="sd_SH_TO"
            value={sd_SH_TO}
            onChange={(e) => onchangedata(e)}
          />
            </div>            
        </div>
        </div>
        </div>
        <div style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
        <div  style={multitablestyle}>
          <table className="custom-table" id="PickSlipTable" style={{overflow:"auto"}}>
            <thead>
              <tr>
                <th style={{ display: "none" }} >UK_NO</th>
                <th>Order No</th>
                <th>Type</th>
                <th>Company</th>
                <th>Line No</th>
                <th>Item No</th>
                <th>Description</th>
                <th>Cost Centre</th>
                <th>Order Quantity</th>
                <th>Ship Quantity</th>
                <th>UM</th> 
                <th>Sec. Quantity</th>
                <th>Sec. UM</th>
                <th>Location</th>
                <th>Stock</th>
                <th>Pick No</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
            {PickSlipTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }} >
                  <InputText
                    id={`sd_UK_NO_${index}`}
                    name={`sd_UK_NO_${index}`}
                    value={row.sd_UK_NO}
                  />
                </td>
                  <td>
                  <InputText style={{width:"90px"}} disabled
                  id={`sdOrNo_${index}`}
                  name={`sdOrNo_${index}`}
                  value={row.sdOrNo}
                  onChange={(Event) => onchangedata(Event)}
                />
                </td>
                <td>
                <InputText style={{width:"90px"}} disabled
                  id={`sdOrTy_${index}`}
                  name={`sdOrTy_${index}`}
                  value={sdOrTy}
                  onChange={(Event) => onchangedata(Event)}
                />
                </td>
                <td>
                <InputText style={{width:"90px"}} disabled
                  id={`sd_OR_CO_${index}`}
                  name={`sd_OR_CO_${index}`}
                  value={row.sd_OR_CO}
                  onChange={(Event) => onchangedata(Event)}
                />
                </td>
                <td>
                <InputText style={{width:"90px"}} disabled
                  id={`sdLnNo_${index}`}
                  name={`sdLnNo_${index}`}
                  value={row.sdLnNo}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText style={{width:"90px"}} disabled
                  id={`sd_IT_NM_${index}`}
                  name={`sd_IT_NM_${index}`}
                  defaultValue={row.sd_IT_NM}
                  onChange={(e) => onchangedata(e)}
                />  
                
                </td>
                <td>
                <InputText style={{width:"90px"}} disabled
                  id={`item_DESC_${index}`}
                  name={`item_DESC_${index}`}
                  defaultValue={row.item_DESC}
                  onChange={(e) => onchangedata(e)}
                />  
                </td>
                <td>
                <InputText style={{width:"90px"}} disabled
                  id={`sd_CO_CT_${index}`}
                  name={`sd_CO_CT_${index}`}
                  value={row.sd_CO_CT}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td> 
                <InputText style={{width:"90px"}} disabled
                  id={`sd_OR_QT_${index}`}
                  name={`sd_OR_QT_${index}`}
                  value={row.sd_OR_QT}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText style={{width:"90px"}}
                  id={`sd_SH_QT_${index}`}
                  name={`sd_SH_QT_${index}`}
                  defaultValue={row.sd_SH_QT}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText style={{width:"90px"}} disabled
                  id={`sd_TR_UM_${index}`}
                  name={`sd_TR_UM_${index}`}
                  value={row.sd_TR_UM}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                {/* SEC QTY */}
                <td>
                <InputText style={{width:"90px"}}
                  id={`sd_SC_QT_${index}`}
                  name={`sd_SC_QT_${index}`}
                  value={row.sd_SC_QT}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText style={{width:"90px"}}
                  id={`sd_SC_UM_${index}`}
                  name={`sd_SC_UM_${index}`}
                  value={row.sd_SC_UM}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <div style={{display:"flex"}}>
                <Dropdown
                  id={`sd_LO_CN_${index}`}
                  name={`sd_LO_CN_${index}`}
                  value={ data[`sd_LO_CN_${index}`] ? data[`sd_LO_CN_${index}`]: sd_LO_CN}
                  options={sd_LO_CNOptions}
                  onChange={(e) =>onchangeDropDownFormData1(`sd_LO_CN_${index}`, e.value)}
                  placeholder="Select Location"
                />
                  <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "7%",
                    cursor: "pointer",
                  }}
                  onClick={(e)=>OpenStockDetailsPopup(index)}
                ></i>
                </div>
                </td>
                <td>
                <InputText style={{width:"90px"}} disabled
                  id={`sd_TR_ST_${index}`}
                  name={`sd_TR_ST_${index}`}
                  value={row.sd_TR_ST}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText style={{width:"90px"}} disabled
                  id={`sd_PK_NO_${index}`}
                  name={`sd_PK_NO_${index}`}
                  value={row.sd_PK_NO}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText style={{width:"90px"}} disabled
                  id={`sd_ST_CD_{index}`}
                  name={`sd_ST_CD_${index}`}
                  value={sd_ST_CD}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                </tr>
                ))}
            </tbody>
          </table>   
        </div>
        </div>
      </div> 
        {/*Stock Avail popup  */}
      <StockDetailsPopup
        visible={StockDetailsPopupVisible}
        onClose={() => setStockDetailsPopupVisible(false)}
        onConfirm={handleStockDetailsConfirm}
        formData={data}
        sd_LO_CN={sd_LO_CN}
        sd_IT_NM={selectedItem}
        sd_CO_CT={selectedCostCentre} 
        sd_UK_NO={UK_NO}
      />
        {/* customer */}
     <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleSelectCustomer}
        formData={data}
        addressType={addressTypeCust}
        setFormData={setFormData}
      />
      {/* Shipto */}
      <AddressBookPopup
        visible={ShipToPopupVisible}
        onClose={() => setShipToPopupVisible(false)}
        onDataSelect={handleSelectShipTo}
        formData={data}
        addressType={addressTypeShip}
        setFormData={setFormData}
      />
    </Dialog>
  );
}
