import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
export default function ReleaseQCHoldDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  // onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  addRow,
  deleteRow,
  ReleaseQCHoldtable,
}) {
  const { 
  hoUkNo,
  gl_BA_TY,
  gl_BA_NO,
  ho_OR_NO,
  ho_OR_TY,
  ho_OR_CO,
  ho_CO_CT,
  ho_HL_CD,
  ho_AP_VR, 
  ho_ST_CD,
  ho_JB_NO,
  ho_JB_TY,
  ho_JB_CO,
  ho_IT_NM,
  item_DESC,
  ho_LO_CN,
  ho_LT_NO,
  ho_CO_QT,
  ho_RL_QT,
  ho_BL_QT,
  } = data;

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      const formattedDate1 = moment(selectedDate).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      const formattedDate1 = moment(value).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "rc_ST_DT"  || name === "rc_EN_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);
  
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };
  

  useEffect(() => {
   
  }, []);



const isUpdateMode = Boolean(hoUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={hoUkNo ? "Revise Error Batch" : "Add Error Batch"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div className="formgrid grid">
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="gl_BA_NO" className="font-bold">
                   Batch No
                  </label>
                  <InputText disabled
                    id="gl_BA_NO"
                    name="gl_BA_NO"
                    value={gl_BA_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="gl_BA_TY" className="font-bold">
                   Batch Type
                  </label>
                  <InputText disabled
                    id="gl_BA_TY"
                    name="gl_BA_TY"
                    value={gl_BA_TY}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
        </div>
      </div>
      <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            marginTop: "2%",
          }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
          }}
        >
          {!isUpdateMode && (
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          )}
           {!isUpdateMode && (
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
          )}
        </div>
        <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
            position: "relative",
          }}
        >
        <table className="custom-table" id="ReleaseQCHoldtable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Document No</th>
          <th>Document Type</th>
          <th>Company</th>
          <th>GL Date</th>
          <th>Account No</th>
          <th>Description</th>
          <th>Debit Amount</th>
          <th>Credit Amount</th>
          <th>Ledger type</th>
          <th>Post Code</th>
        </tr>
      </thead>
      <tbody>
      {ReleaseQCHoldtable.map((row, index) => (
              <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
              id={`hoUkNo_${index}`}
              name={`hoUkNo_${index}`}
              defaultValue={row.hoUkNo}
            />
          </td>
            
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`gl_DO_NO_${index}`}
            name={`gl_DO_NO_${index}`}
            defaultValue={row.gl_DO_NO}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`gl_DO_TY_${index}`}
            name={`gl_DO_TY_${index}`}
            defaultValue={row.gl_DO_TY}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`gl_DO_CO_${index}`}
            name={`gl_DO_CO_${index}`}
            defaultValue={row.gl_DO_CO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <Calendar disabled style={{width:"150px"}}
            id={`gl_GL_DT_${index}`}
            name={`gl_GL_DT_${index}`}
            value={ row.gl_GL_DT ? moment(row.gl_GL_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "gl_GL_DT", value: moment(e.value).format("YYYYMMDD") } })}
            showIcon
            dateFormat="dd/mm/yy"
          />
          </td>
          
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`gl_AC_NO_${index}`}
            name={`gl_AC_NO_${index}`}
            defaultValue={row.gl_AC_NO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`acc_DESC_${index}`}
            name={`acc_DESC_${index}`}
            defaultValue={row.acc_DESC}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`debit_AMOUNT_${index}`}
            name={`debit_AMOUNT_${index}`}
            defaultValue={row.debit_AMOUNT}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`credit_AMOUNT_${index}`}
            name={`credit_AMOUNT_${index}`}
            defaultValue={row.credit_AMOUNT}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`gl_LE_TY_${index}`}
            name={`gl_LE_TY_${index}`}
            defaultValue={row.gl_LE_TY}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`gl_GL_PO_${index}`}
            name={`gl_GL_PO_${index}`}
            defaultValue={row.gl_GL_PO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          </tr>
           ))}
      </tbody>
    </table>   
    
    </div>
  </div>   
  
    </Dialog>
  );
}
