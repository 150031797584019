import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import Costcenter from "views/common/CostCentreDrpDwn";
export default function OverTimeDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangeFormData,
  Salarydetailtable,
  addRow,
  deleteRow,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  SelectedemEMNO,
  setSelectedemEMNO,
  es_EM_NO,
  es_CO_CT,
}) {
  const { 
    es_YE_AR,
    esUkNo,
    es_MN_TH,
    es_PY_FR,
  } = data;
  const [es_CO_CTOptions, setes_CO_CTOptions] = useState([]);
  const { fetchCostCenter} = Costcenter();
  useEffect(() => {
    fetchDropdownCost();
  }, []);
  const fetchDropdownCost = async () => {
    const Options = await fetchCostCenter();
    setes_CO_CTOptions(Options);
  };


  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);
  const [addressType, setaddressType] = useState("E");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const openEmployeePopup = (e) => {
    setEmployeePopupVisible(true);
    setSelectedRowIndex(e);
  };
// dropdowns
const [es_YE_AROptions, setes_YE_AROptions] = useState([]);
const [es_MN_THOptions, setes_MN_THOptions] = useState([]);
const [es_PY_CLOptions, setes_PY_CLOptions] = useState([]);
const [es_PY_FROptions, setes_PY_FROptions] = useState([]);
const token = localStorage.getItem("token");
const company = localStorage.getItem("company");
const domain = localStorage.getItem("domain");

const onchangedata = (e) => {
  const { name, value } = e.target;
    onchangeFormData(name, value);
};
const url = `${domain}/api/ClassCode/All`;
// YEAR
const parameter1= "2000/2025";
const finalUrl1 = `${url}/${parameter1}`;
// MONTH
const parameter2 = "FO/MO";
const finalUrl2 = `${url}/${parameter2}`;
// pay class
const parameter3 = "FO/MO";
const finalUrl3 = `${url}/${parameter3}`;
// pay grade
const parameter4 = "FO/MO";
const finalUrl4 = `${url}/${parameter4}`;

useEffect(() => {
  fetchDropdownOptions1();
  fetchDropdownOptions2();
  fetchDropdownOptions3();
  fetchDropdownOptions4();
}, []);

  // 1st fetchdropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setes_YE_AROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  //2nd fetchdropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setes_MN_THOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  //3rd fetchdropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setes_PY_CLOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 4th fetchdropdown
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setes_PY_FROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };

  //  startsearchpopup
  const handleDataSelect = (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    document.getElementById (`es_EM_NO_${SelectedRowIndex}`).value = data.abAdNo;
    onchangeFormData("name", data.ab_AD_NM,SelectedRowIndex);
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  // endsearchpopup
  const isUpdateMode = Boolean(esUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={esUkNo ? "Revise Salary Details" : "Add Salary Details"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="es_CO_CT" className="font-bold">
                Cost Centre
              </label>
              <Dropdown
                id="es_CO_CT"
                name="es_CO_CT"
                value={es_CO_CT}
                options={es_CO_CTOptions}
                onChange={(e) =>onchangeDropDownFormData3("es_CO_CT", e.value)}
                placeholder="Select Cost Centre"
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="es_YE_AR" className="font-bold">
              Year
              </label>
              <InputText
                id="es_YE_AR"
                name="es_YE_AR"
                value={es_YE_AR}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="es_MN_TH" className="font-bold">
              Month
              </label>
              <InputText
                id="es_MN_TH"
                name="es_MN_TH"
                value={es_MN_TH}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="es_PY_FR" className="font-bold">
              Pay Frequency
              </label>
              {/* <Dropdown
                name="es_PY_FR"
                value={es_PY_FR}
                options={es_PY_FROptions}
                onChange={(e) => onchangeDropDownFormData4("es_PY_FR", e.value)}
                placeholder="Select a Pay Frequency"
              /> */}
               <InputText
                  id="es_PY_FR"
                  name="es_PY_FR"
                  value={es_PY_FR}
                  onChange={(Event) => onchangedata(Event)}
                />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="es_CO_CT" className="font-bold">
                Cost Centre
              </label>
              <Dropdown
                id="es_CO_CT"
                name="es_CO_CT"
                value={es_CO_CT}
                options={es_CO_CTOptions}
                onChange={(e) =>onchangeDropDownFormData3("es_CO_CT", e.value)}
                placeholder="Select Cost Centre"
              />
            </div>
          </div>
      </div>
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
      >
         <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
          }}
        >
          { (
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          )}
           {(
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
          )}
        </div>
        <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
            position: "relative",
          }}
        >
      <table className="custom-table" id="Salarydetailtable">
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Employee ID</th>
          <th>Name</th>
          <th>Basic</th>
          <th>HRA</th>
          <th>Travel Allowance</th>
          <th>Food Allowance</th>
          <th>Phone Allowance</th>
          <th>Other Allowance</th>
          <th>Total Salary</th>
          <th>WPS Basic</th>
          <th>WPS HRA</th>
          <th>WPS Allowance</th>
          <th>WPS Total</th>
        </tr>
      </thead>
      <tbody>
      {Salarydetailtable.map((row, index) => (
              <tr key={index}>
              <td style={{ display: "none" }}>
              <InputText
                id={`esUkNo_${index}`}
                name={`esUkNo_${index}`}
                defaultValue={row.esUkNo}
              />
            </td>
            <td style={{ display: "block" }}>
            <InputText
              style={{ width: "100px",}}
              id={`es_EM_NO_${index}`}
              name={`es_EM_NO_${index}`}
              onChange={(e) => onchangedata(e)}
              defaultValue={row.es_EM_NO ? row.es_EM_NO : row[`${'es_EM_NO'}_${index}`]}
            />
                </td>
                <td>
                  <div style={{width:"150px",display:"flex"}}>
                    <InputText
                      id={`name_${index}`}
                      name={`name_${index}`}
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedemEMNO}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "7%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openEmployeePopup(index)}
                      ></i>
                    )}
                  </div>
                </td>
          <td>
          <InputText style={{width:"100px"}}
            id={`es_BA_SC_${index}`}
            name={`es_BA_SC_${index}`}
            defaultValue={row.es_BA_SC}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"100px"}}
            id={`es_HO_RA_${index}`}
            name={`es_HO_RA_${index}`}
            defaultValue={row.es_HO_RA}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"100px"}}
            id={`travel_${index}`}
            name={`travel_${index}`}
            defaultValue={row.travel}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"100px"}}
            id={`food_${index}`}
            name={`food_${index}`}
            defaultValue={row.food}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"100px"}}
            id={`es_PH_AL_${index}`}
            name={`es_PH_AL_${index}`}
            defaultValue={row.es_PH_AL}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"100px"}}
            id={`other_${index}`}
            name={`other_${index}`}
            defaultValue={row.other}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"100px"}}
            id={`es_TO_SL_${index}`}
            name={`es_TO_SL_${index}`}
            defaultValue={row.es_TO_SL}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"100px"}}
            id={`es_WP_BS_${index}`}
            name={`es_WP_BS_${index}`}
            defaultValue={row.es_WP_BS}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"100px"}}
            id={`es_WP_HR_${index}`}
            name={`es_WP_HR_${index}`}
            defaultValue={row.es_WP_HR}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"100px"}}
            id={`es_WP_AL_${index}`}
            name={`es_WP_AL_${index}`}
            defaultValue={row.es_WP_AL}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"100px"}}
            id={`total_${index}`}
            name={`total_${index}`}
            defaultValue={row.total}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          </tr>
           ))}
      </tbody>
    </table>
    </div>       
  </div> 
<AddressBookPopup
  visible={EmployeePopupVisible}
  onClose={() => setEmployeePopupVisible(false)}
  onDataSelect={handleDataSelect}
  formData={data}
  addressType={addressType}
/>
</Dialog>
);
}
