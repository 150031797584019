import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import SalesTargetDialog from "./SalesTargetDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import '../../../components/ListStyle/ListStyle.css';
import { Dropdown } from "primereact/dropdown";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  sk_AD_NO: "",
  sk_MN_TH: "",
  sk_YE_AR: "",
  sk_SA_TR: "",
  sk_EN_GN: "",
  sk_CO_NO: "",
};
const SalesTargetMain = () => {
  const initialFilters = {
    sk_AD_NO: { value: "", operation: "" },
    sk_MN_TH: { value: "", operation: "" },
    sk_YE_AR: { value: "", operation: "" },
    sk_SA_TR: { value: "", operation: "" },
    sk_EN_GN: { value: "", operation: "" },
    sk_CO_NO: { value: "", operation: "" },
    ab_AD_NM: { value: "", operation: "" },
  };
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10); 
  const toast = useRef(null);

  const [sk_YE_AR, setsk_YE_AR] = useState("");
  const [sk_MN_TH, setsk_MN_TH] = useState("");

  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [sk_YE_AROptions, setsk_YE_AROptions] = useState([]);
  const [sk_MN_THOptions, setsk_MN_THOptions] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [SalesTargetData, setSalesTargetData] = useState([{}]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [Month, setMonth] = useState("");
  const [Year, setYear] = useState("");
  
  const url11 = `${domain}/api/ClassCode/All`;
  const parameter1 = "FO/MO";
  const finalUrl1 = `${url11}/${parameter1}`;

  const parameter = "YE/AR";
  const finalUrl2 = `${url11}/${parameter}`;

  const updateFilters2 = (sts) => {
    let updatedFilters = { ...filters };
  if (sts !== null) {
    updatedFilters = {
      ...updatedFilters,
      sk_MN_TH: {
        value: sts,
        operation: "LIKE",
      },
    };
  }
  setFilters(updatedFilters);
  };

  const updateFilters3 = (sts) => {
    let updatedFilters = { ...filters };
  if (sts !== null) {
    updatedFilters = {
      ...updatedFilters,
      sk_YE_AR: {
        value: sts,
        operation: "LIKE",
      },
    };
  }
  setFilters(updatedFilters);
  };

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;
  
        if (column === 'ccCnNo') {
          constructedColumn = 'ccCnNo';
        } else {
          constructedColumn = column.toUpperCase();
        }
  
        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;
    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }
  
    const updatedFilters = { ...filters };
    
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsk_MN_THOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsk_YE_AROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAddButtonClick = () => {
    setSalesTargetData([{}]);
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setsk_YE_AR(null);
    setsk_MN_TH(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setsk_YE_AR(null);
    setsk_MN_TH(null);
    setOpen(false);
  };

  const addRow = () => {
    setSalesTargetData([...SalesTargetData, {}]);
  };

  const deleteRow = () => {
    if (SalesTargetData.length > 0) {
      const updatedData = [...SalesTargetData];
      updatedData.pop();
      setSalesTargetData(updatedData);
    }
  };

  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/SalesTarget/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const SalesTarget = response.data;
          const updatedSalesTargets = SalesTarget.salesTargets.map(target => {
            const updatedTarget = { ...target };
            delete updatedTarget.skUkNo;
            return updatedTarget;
          });
          setSalesTargetData(updatedSalesTargets);
          setFormData(updatedSalesTargets[0]); 
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleSearch();
    fetchDropdownOptions1();
    fetchDropdownOptions2();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/SalesTarget/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (e) => {
    const { value, name } = e.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value,index=null) => {
    setFormData({ ...FormData, [name]: value });
    const fieldName=(FormData && FormData.skUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
    SalesTargetData[index][fieldName]=value;
  };

  // const onchangeFormData = (name, value) => {
  //   setFormData({ ...FormData, [name]: value });
  // };

  const onchangeDropDown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setMonth(value);
    updateFilters2 (value);
  };
  
  const onchangeDropDown1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setYear(value);
    updateFilters3 (value);
  };
  const onchangeDropDownFormData = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/SalesTarget/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const SalesTarget = response.data;
        setSalesTargetData(SalesTarget.salesTargets);
        setFormData(SalesTarget.salesTargets[0]);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFormSubmit = () => {
    if (FormData && FormData.skUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/SalesTarget/Update`;
  
      if (confirm) {
        const table = document.getElementById('SalesTarget');
        const totalRowCount = table.tBodies[0].rows.length;
  
        const modifiedTargets = [];
        for (let i = 0; i < totalRowCount; i++) {
          const UK_NO = document.getElementById('skUkNo_' + i);
          const AD_NO = document.getElementById('sk_AD_NO_' + i);
          const YE_AR = FormData["sk_YE_AR_" + i];
          // document.getElementById('sk_YE_AR_' + i);
          const MN_TH = FormData["sk_MN_TH_" + i];
          // document.getElementById('sk_MN_TH_' + i);
          const SA_TR = document.getElementById('sk_SA_TR_' + i);
          const EN_GN = document.getElementById('sk_EN_GN_' + i);
  
          const Target = {
            skUkNo: UK_NO.value,
            sk_AD_NO: AD_NO.value,
            sk_YE_AR: YE_AR,
            sk_MN_TH: MN_TH,
            sk_SA_TR: SA_TR.value,
            sk_EN_GN: EN_GN.value,
            sk_CO_NO: company,
          };
            modifiedTargets.push(Target);
        } 
        const requestBody = {
          salesTargets: modifiedTargets
        };
  
        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
        })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {

      const table = document.getElementById('SalesTarget');
      const totalRowCount = table.tBodies[0].rows.length;
      const salesTargets = [];
      for (let i = 0; i < totalRowCount; i++) {
        const AD_NO = document.getElementById('sk_AD_NO_' + i);
        const YE_AR = FormData["sk_YE_AR_" + i];
        // document.getElementById('sk_YE_AR_' + i);
        const MN_TH = FormData["sk_MN_TH_" + i];
        // document.getElementById('sk_MN_TH_' + i);
        const SA_TR = document.getElementById('sk_SA_TR_' + i);
        const EN_GN = document.getElementById('sk_EN_GN_' + i);
    
        const Target = {
          sk_AD_NO: AD_NO.value,
          sk_YE_AR: YE_AR,
          sk_MN_TH: MN_TH,
          sk_SA_TR: SA_TR.value,
          sk_EN_GN: EN_GN.value,
          sk_CO_NO: company,
        };
        salesTargets.push(Target);
      }
      const requestBody = {
        salesTargets,
      };
      axios
        .post(`${domain}/api/SalesTarget/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
         handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
    }
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleSearch();
    }
  };
  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "red !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      {/* <Tooltip target=".search-button" position="bottom" mouseTrack>
        <div
          style={{
            backgroundColor: "transparent !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Search
        </div>
      </Tooltip>
      <Button
        style={searchButtonStyles}
        icon="pi pi-search"
        outlined
        className="mr-2 search-button"
      ></Button> */}
      <Tooltip target=".add-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "white !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "white !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const tableHeaderStyleYM = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#e1e1e1",
  };
  const tableHeaderStyleCheck = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width:"0%"
  };
  const tableCellStyleMY = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
  };
  const tableCellStyleST = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "right",
  };
const exportExcel = () => {
import("xlsx").then((xlsx) => {
  const worksheet = xlsx.utils.json_to_sheet(tableData);
  const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  const excelBuffer = xlsx.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  const data = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });
  saveAs(data, "products_export.xlsx");
});
};
const rightToolbarTemplate = (
<React.Fragment>
  <Button style={{height: "35px",backgroundColor:"#1ed01e",border:"none" }}
    icon="pi pi-upload"
    className="p-button-help"
    rounded
    onClick={exportExcel}
    data-pr-tooltip="XLS"
  />
  
</React.Fragment>
);
const AdvanceSearch = (
      <div className="formgrid grid">
      <div className="field col" style={{display:"grid"}}>
      <label htmlFor="Status" className="font-bold">
        Month
      </label>
      <Dropdown
      style={{width:"140px"}}
        id="Month"
        name="Month"
        value={Month}
        options={sk_MN_THOptions}
        onChange={(e) => onchangeDropDown("Month", e.value)}
        placeholder="Select Month"
      />
    </div>
    <div className="field col" style={{display:"grid"}}>
      <label htmlFor="Status" className="font-bold">
        Year
      </label>
      <Dropdown
      style={{width:"140px"}}
        id="Year"
        name="Year"
        value={Year}
        options={sk_YE_AROptions}
        onChange={(e) => onchangeDropDown1("Year", e.value)}
        placeholder="Select Year"
      />
    </div>
  </div>
);

const handleClearButton=()=>{
  setMonth(null);
  setYear(null);
  setFilters(initialFilters);
}

const rightClear = (
  <React.Fragment>
    <Button
      style={{ height: "35px" }}
      icon="pi pi-times"
      className="p-button-help"
      link
      onClick={handleClearButton}
    />
  </React.Fragment>
);
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar className="p-mb-4" left={leftToolbarTemplate}  right={rightToolbarTemplate}/>
        <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
        </div>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sk_AD_NO"] ? filters["sk_AD_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sk_AD_NO",
                        e.target.value,
                        filters["sk_AD_NO"]
                          ? filters["sk_AD_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ab_AD_NM"] ? filters["ab_AD_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ab_AD_NM",
                        e.target.value,
                        filters["ab_AD_NM"]
                          ? filters["ab_AD_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sk_YE_AR"] ? filters["sk_YE_AR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sk_YE_AR",
                        e.target.value,
                        filters["sk_YE_AR"]
                          ? filters["sk_YE_AR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                <input
                    width="100%"
                    type="text"
                    style={inputStyle}
                    
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sk_SA_TR"] ? filters["sk_SA_TR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sk_SA_TR",
                        e.target.value,
                        filters["sk_SA_TR"]
                          ? filters["sk_SA_TR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sk_EN_GN"] ? filters["sk_EN_GN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sk_EN_GN",
                        e.target.value,
                        filters["sk_EN_GN"]
                          ? filters["sk_EN_GN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyleCheck}></th>
                <th style={tableHeaderStyle}>Sales Person</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyleYM}>Year</th>
                <th style={tableHeaderStyleYM}>Month</th>
                <th style={tableHeaderStyle}>Sales Target</th>
                <th style={tableHeaderStyle}>Leads Target</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.skUkNo}
                    style={{
                      backgroundColor: index % 2 === 0 ? 'aliceblue' : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        type="checkbox"
                        value={rowData.skUkNo}
                        checked={selectedRows.includes(rowData.skUkNo)}
                        onChange={() => handleRowSelect(rowData.skUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.sk_AD_NO}</td>
                    <td style={tableCellStyle}>{rowData.ab_AD_NM}</td>
                    <td style={tableCellStyleMY}>{rowData.sk_YE_AR}</td>
                    <td style={tableCellStyleMY}>{rowData.sk_MN_TH}</td>
                    <td style={tableCellStyleST}>{rowData.sk_SA_TR}</td>
                    <td style={tableCellStyleST}>{rowData.sk_EN_GN}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
       {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <SalesTargetDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData={onchangeDropDownFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        sk_YE_AR={sk_YE_AR}
        sk_MN_TH={sk_MN_TH}
        setSelectedAbAdNM={setSelectedAbAdNM}
        selectedAbAdNM={selectedAbAdNM}
        SalesTargetData={SalesTargetData}
        addRow={addRow}
        deleteRow={deleteRow}
      />
    </div>
  );
};

export default SalesTargetMain;
