import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
import UnitPopup from "../Popups/UnitPopup";
import ClassCode from "views/common/ClassCode";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function MarketRentDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  // onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  addRow,
  deleteRow,
  MarketRenttable,
  mr_CO_NO,
  mr_FL_NO,
  mr_BL_NO,
  mr_PR_NO,
  mr_UN_NO,
  mr_UN_TY,
  mr_UN_US,
  mr_AR_TY,
}) {
  const { 
    mrUkNo,
    mr_MR_RN,
    mr_RE_RN,
    mr_PO_RN,
    mr_UN_DS,
    mr_ST_DT,
    mr_EN_DT,
    mr_SQ_FT,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,savestyle,cancelstyle,Dialogstyle } = ButtonStyles();

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "rc_ST_DT"  || name === "rc_EN_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);
  
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter,fetchCostCenterP } = Costcenter();
  const [mr_UN_TYOptions, setmr_UN_TYOptions] = useState([]);
  const [mr_UN_USOptions, setmr_UN_USOptions] = useState([]);
  const [mr_RN_TYOptions, setmr_RN_TYOptions] = useState([]);
  const [mr_AR_TYOptions, setmr_AR_TYOptions] = useState([]);
  useEffect(() => {
    fetchareatype();
    fetchunitType();
    fetchUnitUsage();
    fetchRentType();
  }, []);
  const fetchareatype = async () => {
    const Options = await fetchClassCodeDS("BL/AR");
    setmr_AR_TYOptions(Options);
  };
  const fetchunitType = async () => {
    const Options = await fetchClassCodeDS("UN/TY");
    setmr_UN_TYOptions(Options);
  };
  const fetchUnitUsage = async () => {
    const Options = await fetchClassCodeDS("UN/US");
    setmr_UN_USOptions(Options);
  };
  const fetchRentType = async () => {
    const Options = await fetchClassCodeDS("RN/TY");
    setmr_RN_TYOptions(Options);
  };
  const [UnitPopupVisible, setUnitPopupVisible] = useState(false);
  const openUnitPopup = (e) => {
    setUnitPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const handleDataSelect = async(content) => {
    document.getElementById (`mr_UN_NO_${SelectedRowIndex}`).value = content.ui_UN_NO;
    document.getElementById (`mr_FL_NO_${SelectedRowIndex}`).value =  content.ui_FL_NO;
    document.getElementById (`mr_BL_NO_${SelectedRowIndex}`).value =  content.ui_BL_NO;
    document.getElementById (`bu_DESC_${SelectedRowIndex}`).value =  content.bu_DESC;
    document.getElementById (`mr_PR_NO_${SelectedRowIndex}`).value =  content.ui_PR_NO;
    document.getElementById (`pr_DESC_${SelectedRowIndex}`).value =  content.pr_DESC;
    document.getElementById (`mr_SQ_FT_${SelectedRowIndex}`).value =  content.ui_TR_QT;
    MarketRenttable.forEach(s=>{
      s['mr_UN_TY']=content.ui_UN_TY;
      s['mr_UN_US']=content.ui_UN_US;
      s['mr_AR_TY']=content.ui_AR_TY;
    });
    setUnitPopupVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(mrUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{ mrUkNo ? "Revise Market Rent" : "Add Market Rent" }</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle}>
          <div  style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
              {(
              <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
              )}
              {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
              )}
            </div>
            <div  style={multitablestyle}>
            <table className="custom-table" id="MarketRenttable" style={{overflow:"auto"}}>
          <thead>
            <tr>
              <th style={{ display: "none" }} >UK_NO</th>
              <th>Unit <span style={{color:"red"}}>*</span></th>
              <th>Floor</th>
              <th style={{ display: "none" }} >Buliding</th>
              <th>Buliding</th>
              <th style={{ display: "none" }} >Property</th>
              <th>Property</th>
              <th>Description</th>
              <th>Area Type</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Market Rent</th>
              <th>Renewal Rent</th>
              <th>Potential Rent</th>
              <th>Square Feet</th>
              <th>Unit Type</th>
              <th>Unit Usage</th>
              <th>Rent Type</th>
              <th style={{ display: "block" }}>Company</th>
            </tr>
          </thead>
          <tbody>
          {MarketRenttable.map((row, index) => (
            <tr key={index}>
                <td style={{ display: "none" }} >
                <InputText
                  id={`mrUkNo_${index}`}
                  name={`mrUkNo_${index}`}
                  defaultValue={row.mrUkNo}
                />
              </td>
              <td>
              <div style={{ display: "flex" }}>
              <InputText
                  style={{ width: "100px",}}
                  id={`mr_UN_NO_${index}`}
                  name={`mr_UN_NO_${index}`}
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  defaultValue={row.mr_UN_NO ? row.mr_UN_NO : row[`${'mr_UN_NO'}_${index}`]}
                />
                {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={(e) => openUnitPopup(index)}
                  ></i>
                )}
                </div>
              </td>
              <td>
                <InputText style={{width:"90px"}} disabled
                id={`mr_FL_NO_${index}`}
                name={`mr_FL_NO_${index}`}
                defaultValue={row.mr_FL_NO}
                readOnly
              />
              </td>
              
              <td  style={{ display: "none" }} >
                <InputText style={{width:"120px"}} disabled
                id={`mr_BL_NO_${index}`}
                name={`mr_BL_NO_${index}`}
                value={row.mr_BL_NO}
                readOnly
              />
              </td>
              
              
              <td>
                <InputText disabled style={{width:"120px"}} 
                id={`bu_DESC_${index}`}
                name={`bu_DESC_${index}`}
                defaultValue={row.bu_DESC}
                readOnly
              />
              </td>
              <td  style={{ display: "none" }} >
                <InputText style={{width:"120px"}} 
                id={`mr_PR_NO_${index}`}
                name={`mr_PR_NO_${index}`}
                value={row.mr_PR_NO}
                readOnly
              />
              </td>
                <td>
                <InputText style={{width:"120px"}} disabled
                id={`pr_DESC_${index}`}
                name={`pr_DESC_${index}`}
                defaultValue={row.pr_DESC}
                readOnly
              />
              </td>
              
              <td>
                <InputText style={{width:"90px"}}
                id={`mr_UN_DS_${index}`}
                name={`mr_UN_DS_${index}`}
                defaultValue={row.mr_UN_DS}
                onChange={(Event) => onchangedata(Event)}
              />
              </td>
              <td>
            <Dropdown disabled
                id={`mr_AR_TY_${index}`}
                name={`mr_AR_TY_${index}`}
                value={data[`mr_AR_TY_${index}`] ? data[`mr_AR_TY_${index}`]:row.mr_AR_TY }
                options={mr_AR_TYOptions}
                onChange={(e) => onchangeDropDownFormData4(`mr_AR_TY_${index}`, e.value)}
                placeholder="Select Area Type"
              />
              </td>
              <td>
              <Calendar style={{width:"150px"}}
                id={`mr_ST_DT_${index}`}
                name={`mr_ST_DT_${index}`}
                value={ row.mr_ST_DT ? moment(row.mr_ST_DT, "YYYYMMDD").toDate() : null }
                onChange={(e) => onDateChange({ target: { name: "mr_ST_DT", value: moment(e.value).format("YYYYMMDD") } })}
                dateFormat="dd/mm/yy"
              />
              </td>
              <td>
              <Calendar style={{width:"150px"}}
                  id={`mr_EN_DT_${index}`}
                  name={`mr_EN_DT_${index}`}
                  value={ row.mr_EN_DT ? moment(row.mr_EN_DT, "YYYYMMDD").toDate() : null }
                  onChange={(e) => onDateChange({ target: { name: "mr_EN_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  dateFormat="dd/mm/yy"
                />
              </td>
              <td>
              <InputText style={{width:"90px"}}
                id={`mr_MR_RN_${index}`}
                name={`mr_MR_RN_${index}`}
                defaultValue={row.mr_MR_RN}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText style={{width:"90px"}}
                id={`mr_RE_RN_${index}`}
                name={`mr_RE_RN_${index}`}
                defaultValue={row.mr_RE_RN}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText style={{width:"90px"}}
                id={`mr_PO_RN_${index}`}
                name={`mr_PO_RN_${index}`}
                defaultValue={row.mr_PO_RN}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText style={{width:"90px"}} disabled
                id={`mr_SQ_FT_${index}`}
                name={`mr_SQ_FT_${index}`}
                defaultValue={row.mr_SQ_FT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <Dropdown disabled
                id={`mr_UN_TY_${index}`}
                name={`mr_UN_TY_${index}`}
                value={data[`mr_UN_TY_${index}`] ? data[`mr_UN_TY_${index}`]:row.mr_UN_TY }
                options={mr_UN_TYOptions}
                onChange={(e) => onchangeDropDownFormData3(`mr_UN_TY_${index}`, e.value)}
                placeholder="Select Unit type"
              />
              </td>
              <td>
              <Dropdown disabled
                id={`mr_UN_US_${index}`}
                name={`mr_UN_US_${index}`}
                value={data[`mr_UN_US_${index}`] ? data[`mr_UN_US_${index}`]:row.mr_UN_US }
                options={mr_UN_USOptions}
                onChange={(e) => onchangeDropDownFormData4(`mr_UN_US_${index}`, e.value)}
                placeholder="Select Usage Type"
              />
              </td>
              <td>
              <Dropdown
                id={`mr_RN_TY_${index}`}
                name={`mr_RN_TY_${index}`}
                value={data[`mr_RN_TY_${index}`] ? data[`mr_RN_TY_${index}`]:row.mr_RN_TY}
                options={mr_RN_TYOptions}
                onChange={(e) => onchangeDropDownFormData5(`mr_RN_TY_${index}`, e.value)}
                placeholder="Select Rent Type"
              />
              </td>
              <td>
                <InputText style={{width:"90px"}} disabled
                id={`mr_CO_NO_${index}`}
                name={`mr_CO_NO_${index}`}
                defaultValue={mr_CO_NO}
              />
              </td>
              </tr>
              ))}
          </tbody>
        </table>   
        </div>
          </div>  
        </div>
      </div>
    <UnitPopup
      visible={UnitPopupVisible}
      onClose={() => setUnitPopupVisible(false)}
      onDataSelect={handleDataSelect}
      formData={data}
    /> 
  </Dialog>
  );
}
