import { Box, Flex, Stack } from "@chakra-ui/react";
import Links from "components/sidebar/components/Links";
import React from "react";
import "./Content.css";

function SidebarContent(props) {
  const { routes, onItemClick } = props;

  return (
    <div className="Sidewidth" style={{marginLeft:"-15%",marginTop:"-8%"}}>
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px'>
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>








          <Links routes={routes} onItemClick={onItemClick} />
        </Box>
      </Stack>
      <Box
        mt='60px'
        mb='40px'
        borderRadius='30px'>
      </Box>
    </Flex>
    </div>
  );
}

export default SidebarContent;
