import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
export default function ItemMasterDialog({
  open,
  handleClose,
  data,
  setFormData,
  handleFormSubmit,
  // onchangedata,
  onchangeFormData,
  onchangeDropDownFormData4,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  SelectedemEMNO,
  setSelectedemEMNO,
  addRow,
  deleteRow,
  im_LN_TY,
  im_IN_CL,
  im_CO_CT,
  ic_CO_TY,
  ic_CO_CT,
  bp_CR_CD,
  im_PR_CT,
  im_CO_TY,
  im_CR_CD,
  im_TO_DT,
  ItemMastertable,
  setim_TX_PE
}) {
  const { 
    imItNo,
 
  } = data;
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const [im_PR_UMOptions, setim_PR_UMOptions] = useState([]);
  const [im_ST_TYOptions, setim_ST_TYOptions] = useState([]);
  const [im_PD_GPOptions, setim_PD_GPOptions] = useState([]);
  const [im_TX_APOptions, setim_TX_APOptions] = useState([]);
  const [im_TX_TYOptions, setim_TX_TYOptions] = useState([]);
  const [im_TX_PEOptions, setim_TX_PEOptions] = useState([]);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [ItemPopupVisible, setItemVisible] = useState(false);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData2(name, value, index);
  };
  const handleFormDataChange2 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData3(name, value, index);
  };
  const handleFormDataChange3 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData4(name, value, index);
  };
  const handleFormDataChange4 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData5(name, value, index);
  };
  const handleFormDataChange5 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData6(name, value, index);
  };

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      const formattedDate1 = moment(selectedDate).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      const formattedDate1 = moment(value).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "rc_ST_DT"  || name === "rc_EN_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);
  
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };
  const TaxDropdown = (name, value) => {
    setFormData((prevData) => ({...prevData,[name]: value, }));
    setim_TX_PE(value);
  };

const url = `${domain}/api/ClassCode/All`;
 
const parameter1 = "IN/UM";
const finalUrl1 = `${url}/${parameter1}`;

const parameter2 = "IN/TY";
const finalUrl2 = `${url}/${parameter2}`;

const parameter3 = "IN/PD";
const finalUrl3 = `${url}/${parameter3}`;

const parameter4 = "ME/PL";
const finalUrl4 = `${url}/${parameter4}`;

const parameter5 = "TX/TY";
const finalUrl5 = `${url}/${parameter5}`;

useEffect(() => {
  fetchDropdownOptions1();
  fetchDropdownOptions2();
  fetchDropdownOptions3();
  fetchDropdownOptions4();
  fetchDropdownOptions5();
  Tax();
    
}, []);

const Tax = async () => {
  const Options = await fetchClassCodeCD("TX/PE");
  setim_TX_PEOptions(Options);
};

  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setim_PR_UMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setim_ST_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl3, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setim_PD_GPOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 4th dropdown
const fetchDropdownOptions4= async () => {
  try {
    const response = await axios.get(finalUrl4, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setim_TX_APOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 5th dropdown
const fetchDropdownOptions5= async () => {
  try {
    const response = await axios.get(finalUrl5, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setim_TX_TYOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};


const isUpdateMode = Boolean(imItNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={imItNo ? "Revise Item Master" : "Add Item Master"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
     
      <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            marginTop: "2%",
          }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
            position: "relative",
          }}
        >
        <table className="custom-table" id="ItemMastertable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Item Code </th>
          <th>Description</th>
          <th>UM</th>
          <th>Stocking Type</th>
          <th>Product Group</th>
          <th>Unit Cost</th>
          <th>Unit Price</th>
          <th>Tax Flag</th>
          <th>Tax Type</th>
          <th>Tax %</th>
          <th>Shelf Life</th>
          <th>Lead Time</th>
          <th style={{ display: "none" }}>Line Type</th>
          <th style={{ display: "none" }}>Inventory Level</th>
          <th style={{ display: "none" }}>Cost Center</th>
          <th style={{ display: "none" }}>Cost Type</th>
          <th style={{ display: "none" }}>Inventory Cost Centre</th>
          <th style={{ display: "none" }}>Base Price Currency</th>
          <th style={{ display: "none" }}>BP Cost Center</th>
          <th style={{ display: "none" }}>Cost Type</th>
          <th style={{ display: "none" }}>Currency</th>
          <th style={{ display: "none" }}>To Date</th>
        </tr>
      </thead>
      <tbody>
      {ItemMastertable.map((row, index) => (
            <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
            id={`imItNo_${index}`}
            name={`imItNo_${index}`}
            defaultValue={row.imItNo}
            />
          </td>
          <td>
          <InputText style={{width:"120px"}}
            id={`im_IT_NM_${index}`}
            name={`im_IT_NM_${index}`}
            defaultValue={row.im_IT_NM}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText style={{width:"120px"}}
            id={`im_IT_DS_${index}`}
            name={`im_IT_DS_${index}`}
            defaultValue={row.im_IT_DS}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <Dropdown
          id={`im_PR_UM_${index}`}
          name={`im_PR_UM_${index}`}
          value={data[`im_PR_UM_${index}`] ? data[`im_PR_UM_${index}`]:row.im_PR_UM }
          options={im_PR_UMOptions}
          onChange={(e) => handleFormDataChange1(`im_PR_UM_${index}`, e.value)}
          placeholder="Select UM"
          />
          </td>
          <td>
          <Dropdown
          id={`im_ST_TY_${index}`}
          name={`im_ST_TY_${index}`}
          value={data[`im_ST_TY_${index}`] ? data[`im_ST_TY_${index}`]:row.im_ST_TY }
          options={im_ST_TYOptions}
          onChange={(e) => handleFormDataChange2(`im_ST_TY_${index}`, e.value)}
          placeholder="Select Stocking Type"
          />
          </td>
          <td>
          <Dropdown
          id={`im_PD_GP_${index}`}
          name={`im_PD_GP_${index}`}
          value={data[`im_PD_GP_${index}`] ? data[`im_PD_GP_${index}`]:row.im_PD_GP }
          options={im_PD_GPOptions}
          onChange={(e) => handleFormDataChange3(`im_PD_GP_${index}`, e.value)}
          placeholder="Select Product Group"
          />
          </td>
          <td>
          <InputText style={{width:"90px"}}
            id={`im_UN_CT_${index}`}
            name={`im_UN_CT_${index}`}
            defaultValue={row.im_UN_CT}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText style={{width:"90px"}}
            id={`im_UN_PR_${index}`}
            name={`im_UN_PR_${index}`}
            defaultValue={row.im_UN_PR}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <Dropdown
          id={`im_TX_AP_${index}`}
          name={`im_TX_AP_${index}`} 
          value={data[`im_TX_AP_${index}`] ? data[`im_TX_AP_${index}`] : row.im_TX_AP} 
          options={[
          { label: "Yes", value: "y" },
          { label: "No", value: "n" },
          ]}
          onChange={(e) => handleFormDataChange4(`im_TX_AP_${index}`, e.value)} 
          placeholder="Select Tax Applicable "
          />
          </td>
          <td>
          <Dropdown
          id={`im_TX_TY_${index}`}
          name={`im_TX_TY_${index}`}
          value={data[`im_TX_TY_${index}`] ? data[`im_TX_TY_${index}`]:row.im_TX_TY }
          options={im_TX_TYOptions}
          onChange={(e) => handleFormDataChange5(`im_TX_TY_${index}`, e.value)}
          placeholder="Select Tax Type"
          />
          </td>
          <td>
          <Dropdown
          id={`im_TX_PE_${index}`}
          name={`im_TX_PE_${index}`}
          value={data[`im_TX_PE_${index}`] ? data[`im_TX_PE_${index}`]:row.im_TX_PE }
          options={im_TX_PEOptions}
          onChange={(e) => TaxDropdown(`im_TX_PE_${index}`, e.value)}
          placeholder="Select Tax %"
          />
          </td>
          <td>
          <InputText style={{width:"90px"}}
            id={`im_SH_LF_${index}`}
            name={`im_SH_LF_${index}`}
            defaultValue={row.im_SH_LF}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText style={{width:"90px"}}
            id={`im_LD_TM_${index}`}
            name={`iim_LD_TM_${index}`}
            defaultValue={row.im_LD_TM}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td style={{width:"100px" ,display: "none"}}>
          <InputText disabled
          style={{ width: "50px" }}
         id={`im_LN_TY_${index}`}
          name={`im_LN_TY_${index}`}
          value={im_LN_TY}
          readOnly
           />
           </td>
           <td style={{width:"100px" ,display: "none"}}>
          <InputText disabled
            style={{ width: "50px" }}
            id={`im_IN_CL_${index}`}
            name={`im_IN_CL_${index}`}
            value={im_IN_CL}
           />
           </td>
           <td style={{width:"100px" ,display: "none"}}>
          <InputText disabled
            style={{ width: "50px" }}
            id={`im_CO_CT_${index}`}
            name={`im_CO_CT_${index}`}
            value={im_CO_CT}
           />
           </td>
           <td style={{width:"100px",display: "none"}}>
          <InputText disabled
          style={{ width: "50px" }}
         id={`ic_CO_TY_${index}`}
          name={`ic_CO_TY_${index}`}
          value={ic_CO_TY}
          readOnly
           />
           </td>
           <td style={{width:"100px",display: "none"}}>
          <InputText disabled
          style={{ width: "50px" }}
         id={`ic_CO_CT_${index}`}
          name={`ic_CO_CT_${index}`}
          value={ic_CO_CT}
          readOnly
           />
           </td>
           <td style={{width:"100px" ,display: "none"}}>
          <InputText disabled
          style={{ width: "50px" }}
         id={`bp_CR_CD_${index}`}
          name={`bp_CR_CD_${index}`}
          value={bp_CR_CD}
          readOnly
           />
           </td>
           <td style={{width:"100px" , display: "none"}}>
          <InputText disabled
          style={{ width: "50px" }}
         id={`im_PR_CT_${index}`}
          name={`im_PR_CT_${index}`}
          value={im_PR_CT}
          readOnly
           />
           </td>
           <td style={{width:"100px" , display: "none"}}>
          <InputText disabled
          style={{ width: "50px" }}
         id={`im_CO_TY_${index}`}
          name={`im_CO_TY_${index}`}
          value={im_CO_TY}
          readOnly
           />
           </td>
           <td style={{width:"100px",display: "none"}}>
          <InputText disabled
          style={{ width: "50px" }}
         id={`im_CR_CD_${index}`}
          name={`im_CR_CD_${index}`}
          value={im_CR_CD}
          readOnly
           />
           </td>
           <td style={{width:"150px" , display: "none"}}>
          <Calendar disabled 
           id={`im_TO_DT_${index}`}
           name={`im_TO_DT_${index}`}
           value={im_TO_DT? moment(im_TO_DT, "YYYYMMDD").toDate(): null}
           onChange={(e) => onDateChange({ target: { name: "im_TO_DT", value: moment(e.value).format("YYYYMMDD") } })}
           dateFormat="dd/mm/yy"
          />
          </td>
          </tr>
           ))}
      </tbody>
    </table> 
    </div>
  </div>   
  </Dialog>
  );
}
