import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
export default function EmployeeRateMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit, 
  setFormData,
  er_EM_UM,
  seter_EM_UM,
  SelectedemEMNO,
  setSelectedemEMNO,

}) {
  const {
    erUkNo,
    er_EM_RT,
    er_AD_NO,

  } = data;
  const { borderbox, inboxstyle } = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [er_EM_UMOptions, seter_EM_UMOptions] = useState([]);
  // BIDDING type
  const onchangeDropDownFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seter_EM_UM(value);
  };
  useEffect(() => {
    fetchDropdownRGST();

  }, []);

  const fetchDropdownRGST = async () => {
    const Options = await fetchClassCodeDS("IN/UM");
    seter_EM_UMOptions(Options);
  };
  // advance searchs
  const [showSearchIcon, setShowSearchIcon] = useState(false);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");


  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    document.getElementById("er_AD_NO").value = data.abAdNo;
    const  Url = `${domain}/api/EmployeeMaster/GetByEmployee`
    const requestData = {
      "emEmNo": data.abAdNo
    };
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data.employeeDetails;
      if(data){
      }
      else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);  
  const [addressType, setaddressType] = useState("E");
  const openEmployeePopup = () => {
    setEmployeePopupVisible(true);
  };

  const isUpdateMode = Boolean(erUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
        tooltip={isUpdateMode ? "Update" : "Submit"}>
      </Button>
    </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={erUkNo ? "Revise Product Registration" : "Add Product Registration"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
        <div
          style={{
            marginTop: "2%",
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
          }}
        >
          <div className="formgrid grid">
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="er_AD_NO" className="font-bold">
                Employee <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedemEMNO}
                />
                {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openEmployeePopup}
                  ></i>
                )}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="er_AD_NO"
                  name="er_AD_NO"
                  defaultValue={er_AD_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="er_EM_RT" className="font-bold">
              Rate
              </label>
              <InputText
                id="er_EM_RT"
                name="er_EM_RT"
                defaultValue={er_EM_RT}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="er_EM_UM" className="font-bold">
                UM
              </label>
              <Dropdown
                id="er_EM_UM"
                name="er_EM_UM"
                value={er_EM_UM}
                options={er_EM_UMOptions}
                onChange={(e) => onchangeDropDownFormData("er_EM_UM", e.value)}
                placeholder="Select Type"
              />
            </div>
          
       
          </div>
        </div>
      </div>
      {/* Employee */}
      <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect}
        addressType={addressType}
        formData={data}
      />
    </Dialog>
  );
}
