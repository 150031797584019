import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import WorkDayCalendarDialog from "./WorkDayCalendarDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  wcUkNo:"",
  wc_YE_AR:"",
  wc_MN_TH:"",
  ep_EX_CT:"",   
  wc_CO_CT:"",
  wc_WK_CT:"",
  wc_DA_01:"", 
  wc_DA_02:"", 
  wc_DA_03:"", 
  wc_DA_04:"", 
  wc_DA_05:"", 
  wc_DA_06:"", 
  wc_DA_07:"", 
  wc_DA_08:"", 
  wc_DA_09:"", 
  wc_DA_10:"", 
  wc_DA_11:"", 
  wc_DA_12:"", 
  wc_DA_13:"", 
  wc_DA_14:"", 
  wc_DA_15:"", 
  wc_DA_16:"", 
  wc_DA_17:"", 
  wc_DA_18:"", 
  wc_DA_19:"", 
  wc_DA_20:"", 
  wc_DA_21:"", 
  wc_DA_22:"", 
  wc_DA_23:"", 
  wc_DA_24:"", 
  wc_DA_25:"", 
  wc_DA_26:"", 
  wc_DA_27:"", 
  wc_DA_28:"", 
  wc_DA_29:"", 
  wc_DA_30:"", 
  wc_DA_31:"",
  wc_CO_NO:"",
};

const initialFilters = {
  wc_YE_AR: { value: "", operation: "" },
  wc_MN_TH: { value: "", operation: "" },
  ep_EX_CT: { value: "", operation: "" },   
  wc_CO_CT: { value: "", operation: "" },
  wc_WK_CT: { value: "", operation: "" },
  wc_DA_01: { value: "", operation: "" }, 
  wc_DA_02: { value: "", operation: "" }, 
  wc_DA_03: { value: "", operation: "" }, 
  wc_DA_04: { value: "", operation: "" }, 
  wc_DA_05: { value: "", operation: "" }, 
  wc_DA_06: { value: "", operation: "" }, 
  wc_DA_07: { value: "", operation: "" }, 
  wc_DA_08: { value: "", operation: "" }, 
  wc_DA_09: { value: "", operation: "" }, 
  wc_DA_10: { value: "", operation: "" }, 
  wc_DA_11: { value: "", operation: "" }, 
  wc_DA_12: { value: "", operation: "" }, 
  wc_DA_13: { value: "", operation: "" }, 
  wc_DA_14: { value: "", operation: "" }, 
  wc_DA_15: { value: "", operation: "" }, 
  wc_DA_16: { value: "", operation: "" }, 
  wc_DA_17: { value: "", operation: "" }, 
  wc_DA_18: { value: "", operation: "" }, 
  wc_DA_19: { value: "", operation: "" }, 
  wc_DA_20: { value: "", operation: "" }, 
  wc_DA_21: { value: "", operation: "" }, 
  wc_DA_22: { value: "", operation: "" }, 
  wc_DA_23: { value: "", operation: "" }, 
  wc_DA_24: { value: "", operation: "" }, 
  wc_DA_25: { value: "", operation: "" }, 
  wc_DA_26: { value: "", operation: "" }, 
  wc_DA_27: { value: "", operation: "" }, 
  wc_DA_28: { value: "", operation: "" }, 
  wc_DA_29: { value: "", operation: "" }, 
  wc_DA_30: { value: "", operation: "" }, 
  wc_DA_31: { value: "", operation: "" },   
};

const WorkCentreMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,rightalined
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  const [wc_YE_AR, setwc_YE_AR ] = useState("");
  const [wc_MN_TH, setwc_MN_TH ] = useState("");
  const [wc_CO_CT, setwc_CO_CT ] = useState("");
  const [wc_WK_CT, setwc_WK_CT ] = useState("");
  const [wc_DA_01, setwc_DA_01 ] = useState("");
  const [wc_DA_02, setwc_DA_02 ] = useState("");
  const [wc_DA_03, setwc_DA_03 ] = useState("");
  const [wc_DA_04, setwc_DA_04 ] = useState("");
  const [wc_DA_05, setwc_DA_05 ] = useState("");
  const [wc_DA_06, setwc_DA_06 ] = useState("");
  const [wc_DA_07, setwc_DA_07 ] = useState("");
  const [wc_DA_08, setwc_DA_08 ] = useState("");
  const [wc_DA_09, setwc_DA_09 ] = useState("");
  const [wc_DA_10, setwc_DA_10 ] = useState("");
  const [wc_DA_11, setwc_DA_11 ] = useState("");
  const [wc_DA_12, setwc_DA_12 ] = useState("");
  const [wc_DA_13, setwc_DA_13 ] = useState("");
  const [wc_DA_14, setwc_DA_14 ] = useState("");
  const [wc_DA_15, setwc_DA_15 ] = useState("");
  const [wc_DA_16, setwc_DA_16 ] = useState("");
  const [wc_DA_17, setwc_DA_17 ] = useState("");
  const [wc_DA_18, setwc_DA_18 ] = useState("");
  const [wc_DA_19, setwc_DA_19 ] = useState("");
  const [wc_DA_20, setwc_DA_20 ] = useState("");
  const [wc_DA_21, setwc_DA_21 ] = useState("");
  const [wc_DA_22, setwc_DA_22 ] = useState("");
  const [wc_DA_23, setwc_DA_23 ] = useState("");
  const [wc_DA_24, setwc_DA_24 ] = useState("");
  const [wc_DA_25, setwc_DA_25 ] = useState("");
  const [wc_DA_26, setwc_DA_26 ] = useState("");
  const [wc_DA_27, setwc_DA_27 ] = useState("");
  const [wc_DA_28, setwc_DA_28 ] = useState("");
  const [wc_DA_29, setwc_DA_29 ] = useState("");
  const [wc_DA_30, setwc_DA_30 ] = useState("");
  const [wc_DA_31, setwc_DA_31 ] = useState("");
  
  

// AdvanceSearch
  const [selectedSupplier, setselectedSupplier ] = useState("");
  const [ep_PR_SU, setep_PR_SU ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [WorkDayCalendarTableData, setWorkDayCalendarTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setWorkDayCalendarTableData([{}]);
     setwc_YE_AR(null);
     setwc_MN_TH(null);
     setwc_CO_CT(null);
     setwc_WK_CT(null);
     setwc_DA_01(null);
     setwc_DA_02(null);
     setwc_DA_03(null);
     setwc_DA_04(null);
     setwc_DA_04(null);
     setwc_DA_05(null);
     setwc_DA_06(null);
     setwc_DA_07(null);
     setwc_DA_08(null);
     setwc_DA_09(null);
     setwc_DA_10(null);
     setwc_DA_11(null);
     setwc_DA_12(null);
     setwc_DA_13(null);
     setwc_DA_14(null);
     setwc_DA_15(null);
     setwc_DA_16(null);
     setwc_DA_17(null);
     setwc_DA_18(null);
     setwc_DA_19(null);
     setwc_DA_20(null);
     setwc_DA_21(null);
     setwc_DA_22(null);
     setwc_DA_23(null);
     setwc_DA_24(null);
     setwc_DA_25(null);
     setwc_DA_26(null);
     setwc_DA_27(null);
     setwc_DA_28(null);
     setwc_DA_29(null);
     setwc_DA_30(null);
     setwc_DA_31(null);

    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/WorkDayCalender/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const CashFlowData = response.data;
          const updatedCashFlowData = CashFlowData.workDayCalenderList.map((target) => {
            const UpdatedworkData = { ...target };
            delete UpdatedworkData.wcUkNo;
            return UpdatedworkData;
          });
          setWorkDayCalendarTableData(updatedCashFlowData);
          setFormData(updatedCashFlowData[0]);
          setwc_YE_AR(CashFlowData.wc_YE_AR);
          setwc_MN_TH(CashFlowData.wc_MN_TH);
          setwc_CO_CT(CashFlowData.wc_CO_CT);
          setwc_WK_CT(CashFlowData.wc_WK_CT);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [wc_CO_NO , setwc_CO_NO] = useState("");

  const handleDefault = async () => {
    setwc_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/WorkDayCalender/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/WorkDayCalender/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const CashFlowData = response.data;
        setWorkDayCalendarTableData(CashFlowData.workDayCalenderList);
        setFormData(CashFlowData.workDayCalenderList[0]);
         setwc_YE_AR(CashFlowData.workDayCalenderList[0].wc_YE_AR);
         setwc_MN_TH(CashFlowData.wc_MN_TH);
         setwc_CO_CT(CashFlowData.wc_CO_CT);
         setwc_WK_CT(CashFlowData.wc_WK_CT);
   
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("WorkDayCalendartable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const workDayCalenderList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const details = {
        wcUkNo : document.getElementById("wcUkNo_" + i).value,
        wc_DA_01 : table.tBodies[0].rows[i].cells[1].children[0].children[1].children[0].value,
        wc_DA_02 : table.tBodies[0].rows[i].cells[2].children[0].children[1].children[0].value,
        wc_DA_03 : table.tBodies[0].rows[i].cells[3].children[0].children[1].children[0].value,
        wc_DA_04 : table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
        wc_DA_05 : table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
        wc_DA_06 : table.tBodies[0].rows[i].cells[6].children[0].children[1].children[0].value,
        wc_DA_07 : table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
        wc_DA_08 : table.tBodies[0].rows[i].cells[8].children[0].children[1].children[0].value,
        wc_DA_09 : table.tBodies[0].rows[i].cells[9].children[0].children[1].children[0].value,
        wc_DA_10 : table.tBodies[0].rows[i].cells[10].children[0].children[1].children[0].value,
        wc_DA_11 : table.tBodies[0].rows[i].cells[11].children[0].children[1].children[0].value,
        wc_DA_12 : table.tBodies[0].rows[i].cells[12].children[0].children[1].children[0].value,
        wc_DA_13 : table.tBodies[0].rows[i].cells[13].children[0].children[1].children[0].value,
        wc_DA_14 : table.tBodies[0].rows[i].cells[14].children[0].children[1].children[0].value,
        wc_DA_15 : table.tBodies[0].rows[i].cells[15].children[0].children[1].children[0].value,
        wc_DA_16 : table.tBodies[0].rows[i].cells[16].children[0].children[1].children[0].value,
        wc_DA_17 : table.tBodies[0].rows[i].cells[17].children[0].children[1].children[0].value,
        wc_DA_18 : table.tBodies[0].rows[i].cells[18].children[0].children[1].children[0].value,
        wc_DA_19 : table.tBodies[0].rows[i].cells[19].children[0].children[1].children[0].value,
        wc_DA_20 : table.tBodies[0].rows[i].cells[20].children[0].children[1].children[0].value,
        wc_DA_21 : table.tBodies[0].rows[i].cells[21].children[0].children[1].children[0].value,
        wc_DA_22 : table.tBodies[0].rows[i].cells[22].children[0].children[1].children[0].value,
        wc_DA_23 : table.tBodies[0].rows[i].cells[23].children[0].children[1].children[0].value,
        wc_DA_24 : table.tBodies[0].rows[i].cells[24].children[0].children[1].children[0].value,
        wc_DA_25 : table.tBodies[0].rows[i].cells[25].children[0].children[1].children[0].value,
        wc_DA_26 : table.tBodies[0].rows[i].cells[26].children[0].children[1].children[0].value,
        wc_DA_27 : table.tBodies[0].rows[i].cells[27].children[0].children[1].children[0].value,
        wc_DA_28 : table.tBodies[0].rows[i].cells[28].children[0].children[1].children[0].value,
        wc_DA_29 : table.tBodies[0].rows[i].cells[29].children[0].children[1].children[0].value,
        wc_DA_30 : table.tBodies[0].rows[i].cells[30].children[0].children[1].children[0].value,
        wc_DA_31 : table.tBodies[0].rows[i].cells[31].children[0].children[1].children[0].value,
       // wc_CO_NO : document.getElementById("wc_CO_NO_" + i).value,
    };
    workDayCalenderList.push(details);
  }
 requestBody = {
  workDayCalenderList,
    wcUkNo:FormData.wcUkNo,
    wc_YE_AR:FormData.wc_YE_AR,
    wc_MN_TH:FormData.wc_MN_TH,
    wc_CO_CT:FormData.wc_CO_CT,
    wc_WK_CT:FormData.wc_WK_CT,
    wc_CO_NO:FormData.wc_CO_NO,
  };
}
  const handleFormSubmit = () => {
    values ();
    if (FormData.wcUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/WorkDayCalender/Update`;
      if (confirm) {
        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.wc_CO_NO = company;
      axios
        .post(`${domain}/api/WorkDayCalender/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_YE_AR"] ? filters["wc_YE_AR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_YE_AR",
                        e.target.value,
                        filters["wc_YE_AR"]
                          ? filters["wc_YE_AR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_MN_TH"] ? filters["wc_MN_TH"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_MN_TH",
                        e.target.value,
                        filters["wc_MN_TH"] ? filters["wc_MN_TH"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_CO_CT"] ? filters["wc_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_CO_CT",
                        e.target.value,
                        filters["wc_CO_CT"]
                          ? filters["wc_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["wc_WK_CT"] ? filters["wc_WK_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_WK_CT",
                        e.target.value,
                        filters["wc_WK_CT"]
                          ? filters["wc_WK_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_01"] ? filters["wc_DA_01"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_01",
                        e.target.value,
                        filters["wc_DA_01"]
                          ? filters["wc_DA_01"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_02"] ? filters["wc_DA_02"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_02",
                        e.target.value,
                        filters["wc_DA_02"]
                          ? filters["wc_DA_02"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_03"] ? filters["wc_DA_03"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_03",
                        e.target.value,
                        filters["wc_DA_03"]
                          ? filters["wc_DA_03"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_04"] ? filters["wc_DA_04"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_04",
                        e.target.value,
                        filters["wc_DA_04"]
                          ? filters["wc_DA_04"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_05"] ? filters["wc_DA_05"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_05",
                        e.target.value,
                        filters["wc_DA_05"]
                          ? filters["wc_DA_05"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_06"] ? filters["wc_DA_06"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_06",
                        e.target.value,
                        filters["wc_DA_06"]
                          ? filters["wc_DA_06"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_07"] ? filters["wc_DA_07"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_07",
                        e.target.value,
                        filters["wc_DA_07"]
                          ? filters["wc_DA_07"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_08"] ? filters["wc_DA_08"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_08",
                        e.target.value,
                        filters["wc_DA_08"]
                          ? filters["wc_DA_08"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_09"] ? filters["wc_DA_09"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_09",
                        e.target.value,
                        filters["wc_DA_09"]
                          ? filters["wc_DA_09"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_10"] ? filters["wc_DA_10"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_10",
                        e.target.value,
                        filters["wc_DA_10"]
                          ? filters["wc_DA_10"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_11"] ? filters["wc_DA_11"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_11",
                        e.target.value,
                        filters["wc_DA_11"]
                          ? filters["wc_DA_11"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_12"] ? filters["wc_DA_12"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_12",
                        e.target.value,
                        filters["wc_DA_12"]
                          ? filters["wc_DA_12"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_13"] ? filters["wc_DA_13"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_13",
                        e.target.value,
                        filters["wc_DA_13"]
                          ? filters["wc_DA_13"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_14"] ? filters["wc_DA_14"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_14",
                        e.target.value,
                        filters["wc_DA_14"]
                          ? filters["wc_DA_14"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_15"] ? filters["wc_DA_15"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_15",
                        e.target.value,
                        filters["wc_DA_15"]
                          ? filters["wc_DA_15"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_16"] ? filters["wc_DA_16"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_16",
                        e.target.value,
                        filters["wc_DA_16"]
                          ? filters["wc_DA_16"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_17"] ? filters["wc_DA_17"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_17",
                        e.target.value,
                        filters["wc_DA_17"]
                          ? filters["wc_DA_17"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_18"] ? filters["wc_DA_18"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_18",
                        e.target.value,
                        filters["wc_DA_18"]
                          ? filters["wc_DA_18"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_19"] ? filters["wc_DA_19"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_19",
                        e.target.value,
                        filters["wc_DA_19"]
                          ? filters["wc_DA_19"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_20"] ? filters["wc_DA_20"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_20",
                        e.target.value,
                        filters["wc_DA_20"]
                          ? filters["wc_DA_20"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_21"] ? filters["wc_DA_21"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_21",
                        e.target.value,
                        filters["wc_DA_21"]
                          ? filters["wc_DA_21"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_22"] ? filters["wc_DA_22"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_22",
                        e.target.value,
                        filters["wc_DA_22"]
                          ? filters["wc_DA_22"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_23"] ? filters["wc_DA_23"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_23",
                        e.target.value,
                        filters["wc_DA_23"]
                          ? filters["wc_DA_23"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_24"] ? filters["wc_DA_24"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_24",
                        e.target.value,
                        filters["wc_DA_24"]
                          ? filters["wc_DA_24"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_25"] ? filters["wc_DA_25"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_25",
                        e.target.value,
                        filters["wc_DA_25"]
                          ? filters["wc_DA_25"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_26"] ? filters["wc_DA_26"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_26",
                        e.target.value,
                        filters["wc_DA_26"]
                          ? filters["wc_DA_26"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_27"] ? filters["wc_DA_27"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_27",
                        e.target.value,
                        filters["wc_DA_27"]
                          ? filters["wc_DA_27"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_28"] ? filters["wc_DA_28"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_28",
                        e.target.value,
                        filters["wc_DA_28"]
                          ? filters["wc_DA_28"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_29"] ? filters["wc_DA_29"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_29",
                        e.target.value,
                        filters["wc_DA_29"]
                          ? filters["wc_DA_29"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_30"] ? filters["wc_DA_30"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_30",
                        e.target.value,
                        filters["wc_DA_30"]
                          ? filters["wc_DA_30"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wc_DA_31"] ? filters["wc_DA_31"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DA_31",
                        e.target.value,
                        filters["wc_DA_31"]
                          ? filters["wc_DA_31"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
              
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Year </th>
                <th style={tableHeaderStyle}>Month</th>
                <th style={tableHeaderStyle}>Cost Centre</th>  
                <th style={tableHeaderStyle}>Work Centre</th>   
                <th style={tableHeaderStyle}>Day 01</th>  
                <th style={tableHeaderStyle}>Day 02</th>  
                <th style={tableHeaderStyle}>Day 03</th>  
                <th style={tableHeaderStyle}>Day 04</th>  
                <th style={tableHeaderStyle}>Day 05</th>  
                <th style={tableHeaderStyle}>Day 06</th>  
                <th style={tableHeaderStyle}>Day 07</th>  
                <th style={tableHeaderStyle}>Day 08</th>  
                <th style={tableHeaderStyle}>Day 09</th>  
                <th style={tableHeaderStyle}>Day 10</th>  
                <th style={tableHeaderStyle}>Day 11</th>  
                <th style={tableHeaderStyle}>Day 12</th>  
                <th style={tableHeaderStyle}>Day 13</th>  
                <th style={tableHeaderStyle}>Day 14</th>  
                <th style={tableHeaderStyle}>Day 15</th>  
                <th style={tableHeaderStyle}>Day 16</th>  
                <th style={tableHeaderStyle}>Day 17</th>  
                <th style={tableHeaderStyle}>Day 18</th>  
                <th style={tableHeaderStyle}>Day 19</th>  
                <th style={tableHeaderStyle}>Day 20</th>  
                <th style={tableHeaderStyle}>Day 21</th>  
                <th style={tableHeaderStyle}>Day 22</th>  
                <th style={tableHeaderStyle}>Day 23</th>  
                <th style={tableHeaderStyle}>Day 24</th>  
                <th style={tableHeaderStyle}>Day 25</th>  
                <th style={tableHeaderStyle}>Day 26</th>  
                <th style={tableHeaderStyle}>Day 27</th>  
                <th style={tableHeaderStyle}>Day 28</th>  
                <th style={tableHeaderStyle}>Day 29</th>  
                <th style={tableHeaderStyle}>Day 30</th>  
                <th style={tableHeaderStyle}>Day 31</th>  
             
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.wcUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.wcUkNo}
                        checked={selectedRows.includes(rowData.wcUkNo)}
                        onChange={() => handleRowSelect(rowData.wcUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.wc_YE_AR}</td>
                    <td style={tableCellStyle}>{rowData.wc_MN_TH}</td>
                    <td style={tableCellStyle}>{rowData.wc_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.wc_WK_CT}</td>
                    <td style={tableCellStyle}>{rowData.wc_DA_01}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_02}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_03}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_04}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_05}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_06}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_07}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_08}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_09}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_10}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_11}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_12}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_13}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_14}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_15}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_16}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_17}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_18}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_19}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_20}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_21}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_22}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_23}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_24}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_25}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_26}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_27}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_28}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_29}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_30}</td> 
                    <td style={tableCellStyle}>{rowData.wc_DA_31}</td>   
         
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <WorkDayCalendarDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        WorkDayCalendarTableData={WorkDayCalendarTableData}
        setWorkDayCalendarTableData={setWorkDayCalendarTableData}
    
        selectedSupplier={selectedSupplier}
        setselectedSupplier={setselectedSupplier}
        wc_YE_AR={wc_YE_AR}
        setwc_YE_AR={setwc_YE_AR}
        wc_MN_TH={wc_MN_TH}
        setwc_MN_TH={setwc_MN_TH}
        wc_CO_CT={wc_CO_CT}
        setwc_CO_CT={setwc_CO_CT}
        wc_WK_CT={wc_WK_CT}
        setwc_WK_CT={setwc_WK_CT}
        wc_DA_01={wc_DA_01}
        setwc_DA_01={setwc_DA_01}
        wc_DA_02={wc_DA_02}
        setwc_DA_02={setwc_DA_02}
        wc_DA_03={wc_DA_03}
        setwc_DA_03={setwc_DA_03}
        wc_DA_04={wc_DA_04}
        setwc_DA_04={setwc_DA_04}
        wc_DA_05={wc_DA_05}
        setwc_DA_05={setwc_DA_05}
        wc_DA_06={wc_DA_06}
        setwc_DA_06={setwc_DA_06}
        wc_DA_07={wc_DA_07}
        setwc_DA_07={setwc_DA_07}
        wc_DA_08={wc_DA_08}
        setwc_DA_08={setwc_DA_08}
        wc_DA_09={wc_DA_09}
        setwc_DA_09={setwc_DA_09}
        wc_DA_10={wc_DA_10}
        setwc_DA_10={setwc_DA_10}
        wc_DA_11={wc_DA_11}
        setwc_DA_11={setwc_DA_11}
        wc_DA_12={wc_DA_12}
        setwc_DA_12={setwc_DA_12}
        wc_DA_13={wc_DA_13}
        setwc_DA_13={setwc_DA_13}
        wc_DA_14={wc_DA_14}
        setwc_DA_14={setwc_DA_14}
        wc_DA_15={wc_DA_15}
        setwc_DA_15={setwc_DA_15}
        wc_DA_16={wc_DA_16}
        setwc_DA_16={setwc_DA_16}
        wc_DA_17={wc_DA_17}
        setwc_DA_17={setwc_DA_17}
        wc_DA_18={wc_DA_18}
        setwc_DA_18={setwc_DA_18}
        wc_DA_19={wc_DA_19}
        setwc_DA_19={setwc_DA_19}
        wc_DA_20={wc_DA_20}
        setwc_DA_20={setwc_DA_20}
        wc_DA_21={wc_DA_21}
        setwc_DA_21={setwc_DA_21}
        wc_DA_22={wc_DA_22}
        setwc_DA_22={setwc_DA_22}
        wc_DA_23={wc_DA_23}
        setwc_DA_23={setwc_DA_23}
        wc_DA_24={wc_DA_24}
        setwc_DA_24={setwc_DA_24}
        wc_DA_25={wc_DA_25}
        setwc_DA_25={setwc_DA_25}
        wc_DA_26={wc_DA_26}
        setwc_DA_26={setwc_DA_26}
        wc_DA_27={wc_DA_27}
        setwc_DA_27={setwc_DA_27}
        wc_DA_28={wc_DA_28}
        setwc_DA_28={setwc_DA_28}
        wc_DA_29={wc_DA_29}
        setwc_DA_29={setwc_DA_29}
        wc_DA_30={wc_DA_30}
        setwc_DA_30={setwc_DA_30}
        wc_DA_31={wc_DA_31}
        setwc_DA_31={setwc_DA_31}
        wc_CO_NO={wc_CO_NO}
        setwc_CO_NO={setwc_CO_NO}
      />
    </div>
  );
};

export default WorkCentreMain;
