import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function StandardUMConversionDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  StandardUMConversiontabledata,
  setStandardUMConversiontabledata,
  seter_EX_TY,
  seter_AD_CR,
  cs_CO_NO,
}) {
  const {
    csUkNo,
    os_WK_CT,
    os_SQ_DS,
  } = data;

   // Dropdown
   const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
   const [er_EX_TYOptions, seter_EX_TYOptions] = useState([]);
   const [er_AD_CROptions, seter_AD_CROptions] = useState([]);
   const [er_ST_ATOptions, seter_ST_ATOptions] = useState([]);
   const [er_EX_CTOptions, seter_EX_CTOptions] = useState([]);

     //From UM
  const FromUMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seter_EX_TY(value);
};

  // To UM
  const ToUMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seter_AD_CR(value);
  };

  useEffect(() => {
    FromUM();
    ToUM();
  }, []);

  const FromUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    seter_ST_ATOptions(Options);
  };
  const ToUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    seter_EX_CTOptions(Options);
  };
  const addRow = () => {
    setStandardUMConversiontabledata([...StandardUMConversiontabledata, {}]);
  };
  
  const deleteRow = () => {
    if (StandardUMConversiontabledata.length > 0) {
      const updatedData = [...StandardUMConversiontabledata];
      updatedData.pop();
      setStandardUMConversiontabledata(updatedData);
    }
  };
const isUpdateMode = Boolean(csUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );


  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={csUkNo ? "Revise Standard UM Conversion" : "Add Standard UM Conversion"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
        }}
      >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="StandardUMConversiontable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>1</th>
              <th>From UM</th>
              <th>=</th>
              <th>Conversion Factor</th>
              <th>To UM</th>
              <th>Inverse Conversion</th>
            </tr>
          </thead>
          <tbody>
            {StandardUMConversiontabledata.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`csUkNo_${index}`}
                    name={`csUkNo_${index}`}
                    value={row.csUkNo}
                  />
                </td>
                <td >
                  <InputText 
                    id={`ep_DA_AL_${index}`}
                    name={`ep_DA_AL_${index}`}
                    defaultValue={row.ep_DA_AL}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Dropdown style={{width:"120px"}}
                    id={`er_CO_CT_${index}`}
                    name={`er_CO_CT_${index}`}
                    value={ data[`er_CO_CT_${index}`] ? data[`er_CO_CT_${index}`]: row.er_CO_CT}
                    options={er_EX_TYOptions}
                    onChange={(e) =>FromUMDropdown(`er_CO_CT_${index}`, e.value)}
                    placeholder="Select Cost Centre"
                  />
                  </td>
                <td >
                  <InputText 
                    id={`ep_DA_AL_${index}`}
                    name={`ep_DA_AL_${index}`}
                    defaultValue={row.ep_DA_AL}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText 
                    id={`ep_DA_AL_${index}`}
                    name={`ep_DA_AL_${index}`}
                    defaultValue={row.ep_DA_AL}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Dropdown style={{width:"120px"}}
                    id={`er_CO_CT_${index}`}
                    name={`er_CO_CT_${index}`}
                    value={ data[`er_CO_CT_${index}`] ? data[`er_CO_CT_${index}`]: row.er_CO_CT}
                    options={er_EX_TYOptions}
                    onChange={(e) =>ToUMDropdown(`er_CO_CT_${index}`, e.value)}
                    placeholder="Select Cost Centre"
                  />
                  </td>
                <td >
                  <InputText 
                    id={`ep_DA_AL_${index}`}
                    name={`ep_DA_AL_${index}`}
                    defaultValue={row.ep_DA_AL}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      </div> 
      
    </Dialog>
  );
}
