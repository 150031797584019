import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { Pie } from 'react-chartjs-2';
import { Dropdown } from "primereact/dropdown";
import { Select } from "@chakra-ui/react";
import { Progress } from '@chakra-ui/react';
import './styledash.css';
import axios from "axios";
import { Line } from "react-chartjs-2";

const AutomotiveOverview = () => {

// front
const [dashboardData, setDashboardData] = useState({
  SERVICEMth:"",
  SERVICEYtd:"",
  CURRENT_SalesMth:"",  
  CURRENT_SalesYtd:"",
  CURRENT_EXPENSESMth:"",
  CURRENT_EXPENSESYtd:"",
  SERVICE_BOOK:"",
  DELIVERED:"",
  IN_PROGRESS:"",
  EfficiencyPercentage:"",
  CURRENT_Sales_jan:"",
  CURRENT_Sales_feb:"",
  CURRENT_Sales_mar:"",
  CURRENT_Sales_apr:"",
  CURRENT_Sales_may:"",
  CURRENT_Sales_jun:"",
  CURRENT_Sales_jul:"",
  CURRENT_Sales_aug:"",
  CURRENT_Sales_sep:"",
  CURRENT_Sales_oct:"",
  CURRENT_Sales_nov:"",
  CURRENT_Sales_dec:"",
  CURRENT_EXPENSES_jan:"",
  CURRENT_EXPENSES_feb:"",
  CURRENT_EXPENSES_mar:"",
  CURRENT_EXPENSES_apr:"",
  CURRENT_EXPENSES_may:"",
  CURRENT_EXPENSES_jun:"",
  CURRENT_EXPENSES_jul:"",
  CURRENT_EXPENSES_aug:"",
  CURRENT_EXPENSES_sep:"",
  CURRENT_EXPENSES_oct:"",
  CURRENT_EXPENSES_nov:"",
  CURRENT_EXPENSES_dec:"",
  PREVIOUS_Sales_jan:"",
  PREVIOUS_Sales_feb:"",
  PREVIOUS_Sales_mar:"",
  PREVIOUS_Sales_apr:"",
  PREVIOUS_Sales_may:"",
  PREVIOUS_Sales_jun:"",
  PREVIOUS_Sales_jul:"",
  PREVIOUS_Sales_aug:"",
  PREVIOUS_Sales_sep:"",
  PREVIOUS_Sales_oct:"",
  PREVIOUS_Sales_nov:"",
  PREVIOUS_Sales_dec:"",
  PREVIOUS_EXPENSES_jan:"",
  PREVIOUS_EXPENSES_feb:"",
  PREVIOUS_EXPENSES_mar:"",
  PREVIOUS_EXPENSES_apr:"",
  PREVIOUS_EXPENSES_may:"",
  PREVIOUS_EXPENSES_jun:"",
  PREVIOUS_EXPENSES_jul:"",
  PREVIOUS_EXPENSES_aug:"",
  PREVIOUS_EXPENSES_sep:"",
  PREVIOUS_EXPENSES_oct:"",
  PREVIOUS_EXPENSES_nov:"",
  PREVIOUS_EXPENSES_dec:"",

  
});
// stylings
const headerbox_odd = {
  backgroundColor: "#c5f2ffcc",
  color: "#000000", 
  borderRadius: "17px", 
  width: "11%", 
  height: "80px",
  padding: "15px 20px", 
  display: "flex",
  justifyContent: "center", 
};
const headerbox_even = {
  backgroundColor: "#d5fff2",
  color: "#000000", 
  borderRadius: "17px", 
  width: "11%", 
  height: "80px",
  padding: "15px 20px", 
  display: "flex",
  justifyContent: "center", 
}
const boxtextstyle ={
  fontFamily: "DM Sans",
  color: "black",
  fontSize:"small",
  fontWeight:"bold"
};
const boxtextstyle1 ={
  fontFamily: "DM Sans",
  color: "black",
  fontSize:"smaller",
  fontWeight:"bold"
};
const token = localStorage.getItem("token");
const company = localStorage.getItem("company");
const domain = localStorage.getItem("domain");
const [selectedMonth, setSelectedMonth] = useState("");
const [selectedYear, setSelectedYear] = useState("");
const [selecteddays, setSelecteddays] = useState("");

const dashboard = `${domain}/api/Dashboard/AutoMotiveDashboard`;
const Autodashboard = async() =>{
  const requestinput ={
          "year":selectedYear,
          "month":selectedMonth,
        }
  try {
    const response = await axios.post(dashboard, requestinput, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const data = response.data;
    setDashboardData(data);
    } catch (error) {
    console.error('Error fetching data:', error);
  }
};

useEffect(() => {
  let newDate = new Date()
  if (selectedMonth === null || selectedMonth === "" ) {
  let month = newDate.getMonth() + 1;
  setSelectedMonth(month);
  }
  if (selectedYear === null || selectedYear === "") {
    let year = newDate.getFullYear();
    setSelectedYear(year);
  }  
  if (selectedMonth !== null && selectedYear !== null) {
    Autodashboard();
  }

}, [selectedMonth,selectedYear]);

const barChartRef1 = useRef(null);
useEffect(() => {
  const UpdateBarchart1Data=()=>{
    if(!barChartRef1.current) return;
    const ctx = barChartRef1.current.getContext("2d");
      const chart = Chart.getChart(ctx);
    if(chart){
        chart.data.datasets=[{
          label: "Expenses",
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          data: [
            dashboardData.CURRENT_Sales_jan,
            dashboardData.CURRENT_Sales_feb,
            dashboardData.CURRENT_Sales_mar,
            dashboardData.CURRENT_Sales_apr,
            dashboardData.CURRENT_Sales_may,
            dashboardData.CURRENT_Sales_jun,
            dashboardData.CURRENT_Sales_jul,
            dashboardData.CURRENT_Sales_aug,
            dashboardData.CURRENT_Sales_sep,
            dashboardData.CURRENT_Sales_oct,
            dashboardData.CURRENT_Sales_nov,
            dashboardData.CURRENT_Sales_dec
           ],
          stack: true,
      },
      {
          label: "Sales",
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1,
          data: [
            dashboardData.CURRENT_EXPENSES_jan,
            dashboardData.CURRENT_EXPENSES_feb,
            dashboardData.CURRENT_EXPENSES_mar,
            dashboardData.CURRENT_EXPENSES_apr,
            dashboardData.CURRENT_EXPENSES_may,
            dashboardData.CURRENT_EXPENSES_jun,
            dashboardData.CURRENT_EXPENSES_jul,
            dashboardData.CURRENT_EXPENSES_aug,
            dashboardData.CURRENT_EXPENSES_sep,
            dashboardData.CURRENT_EXPENSES_oct,
            dashboardData.CURRENT_EXPENSES_nov,
            dashboardData.CURRENT_EXPENSES_dec
           ],
          stack: true,
      },
      ];
    chart.update();
    }
  }
    if(dashboardData)
      UpdateBarchart1Data();
  },[dashboardData]);
// barchart
const barchart1 = () => {
  if (!barChartRef1.current) return;
  const ctx = barChartRef1.current.getContext("2d");
  new Chart(ctx, {
    type: 'bar',
    data: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: { drawOnChartArea: false, display: false, drawBorder: false },
          ticks: { color: 'black', font: { size: 8 } },
        },
        y: {
          grid: { drawBorder: true, drawOnChartArea: false, display: false },
          beginAtZero: true,
          min: 0,
          ticks: { stepSize: 10000, font: { size: 8 }, color: 'black' },
        },
      },
      plugins: {
        legend: {
          display: false,
          labels: { font: { size: 8 } },
        },
        interaction: {
          intersect: false,
        },
      },
      elements: {
        bar: {
          borderRadius: 15,
        },
      },
      barThickness: 15,
    },})
};
useEffect(() => { 
  barchart1();
}, []);

// piechart 1
const data = {
  labels: ['Open Deals', 'Won Deals', 'Lost Deals'],
  datasets: [
    {
      label: 'Total Deals',
      data: [dashboardData.openDeals,dashboardData.wonDeals,dashboardData.lostDeals], 
      backgroundColor: ['#99FFFF', '#d5fff2', '#66CCFF '],
    },
  ],
};
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'right', 
    },
  },
};
// pie chart2
const data1 = {
  labels: ['Rented', 'Available'],
  datasets: [
    {
      label: 'Rented vs Available',
      data: ["24","68"], 
      backgroundColor: ['#33FFFF', '#00CCFF'], 
    },
  ],
};
const options1 = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'right', 
    },
  },
};
// linechart- income trends
const lineChartData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug","Sep","Oct","Nov","Dec"],
  datasets: [
    {
      fill: true, 
      lineTension: 0.4, 
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)",
      borderWidth: 2,
      pointRadius: 1,
      data: [ 
        dashboardData.CURRENT_Sales_jan,
        dashboardData.CURRENT_Sales_feb,
        dashboardData.CURRENT_Sales_mar,
        dashboardData.CURRENT_Sales_apr,
        dashboardData.CURRENT_Sales_may,
        dashboardData.CURRENT_Sales_jun,
        dashboardData.CURRENT_Sales_jul,
        dashboardData.CURRENT_Sales_aug,
        dashboardData.CURRENT_Sales_sep,
        dashboardData.CURRENT_Sales_oct,
        dashboardData.CURRENT_Sales_nov,
        dashboardData.CURRENT_Sales_dec
      ]
    },
    {
      fill: true, 
      lineTension: 0.4,
      backgroundColor: "rgba(255,192,203,0.2)",
      borderColor: "rgba(255,192,203,1)",
      borderWidth: 2, 
      pointRadius: 1,
      data: [
        dashboardData.PREVIOUS_Sales_jan,
        dashboardData.PREVIOUS_Sales_feb,
        dashboardData.PREVIOUS_Sales_mar,
        dashboardData.PREVIOUS_Sales_apr,
        dashboardData.PREVIOUS_Sales_may,
        dashboardData.PREVIOUS_Sales_jun,
        dashboardData.PREVIOUS_Sales_jul,
        dashboardData.PREVIOUS_Sales_aug,
        dashboardData.PREVIOUS_Sales_sep,
        dashboardData.PREVIOUS_Sales_oct,
        dashboardData.PREVIOUS_Sales_nov,
        dashboardData.PREVIOUS_Sales_dec
       ]
    }
  ]
};

const lineChartOptions = {
  scales: {
    x: {
      grid: { display: false },
      ticks: { maxRotation: 45, minRotation: 45 },
    },
    y: {
      grid: { display: false },
      // stepSize: 1000000,
    },
  },
  plugins: {
    legend: {
      display: false // Hide the legend
    }
  }
};
// linechart- expense trends
const lineChartData1 = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug","Sep","Oct","Nov","Dec"],
  datasets: [
    {
      fill: true, 
      lineTension: 0.4, 
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)",
      borderWidth: 2,
      pointRadius: 1,
      data: [ 
        dashboardData.CURRENT_EXPENSES_jan,
        dashboardData.CURRENT_EXPENSES_feb,
        dashboardData.CURRENT_EXPENSES_mar,
        dashboardData.CURRENT_EXPENSES_apr,
        dashboardData.CURRENT_EXPENSES_may,
        dashboardData.CURRENT_EXPENSES_jun,
        dashboardData.CURRENT_EXPENSES_jul,
        dashboardData.CURRENT_EXPENSES_aug,
        dashboardData.CURRENT_EXPENSES_sep,
        dashboardData.CURRENT_EXPENSES_oct,
        dashboardData.CURRENT_EXPENSES_nov,
        dashboardData.CURRENT_EXPENSES_dec
      ]
    },
    {
      fill: true, 
      lineTension: 0.4,
      backgroundColor: "rgba(255,192,203,0.2)",
      borderColor: "rgba(255,192,203,1)",
      borderWidth: 2, 
      pointRadius: 1,
      data: [
        dashboardData.PREVIOUS_EXPENSES_jan,
        dashboardData.PREVIOUS_EXPENSES_feb,
        dashboardData.PREVIOUS_EXPENSES_mar,
        dashboardData.PREVIOUS_EXPENSES_apr,
        dashboardData.PREVIOUS_EXPENSES_may,
        dashboardData.PREVIOUS_EXPENSES_jun,
        dashboardData.PREVIOUS_EXPENSES_jul,
        dashboardData.PREVIOUS_EXPENSES_aug,
        dashboardData.PREVIOUS_EXPENSES_sep,
        dashboardData.PREVIOUS_EXPENSES_oct,
        dashboardData.PREVIOUS_EXPENSES_nov,
        dashboardData.PREVIOUS_EXPENSES_dec
       ]
    }
  ]
};

const lineChartOptions1 = {
  scales: {
    x: {
      grid: { display: false },
      ticks: { maxRotation: 45, minRotation: 45 },
    },
    y: {
      grid: { display: false },
      // stepSize: 1000000,
    },
  },
  plugins: {
    legend: {
      display: false // Hide the legend
    }
  }
};

// dropdwons
const handleMonthChange = (e) => {
  setSelectedMonth(e.value);
};
const handleYearChange = (e) => {
  setSelectedYear(e.value);
};
const months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 }
];
const years = [
  { label: 2022, value: 2022 },
  { label: 2023, value: 2023 },
  { label: 2024, value: 2024 }
];
return (
   
  <div style={{ marginTop: "5%",marginLeft:"1%" }}>
  {/* 1st Box List */}
  <div className="month-year d-flex justify-content-between text-white text-center">
  <div className="box" style={headerbox_odd} >
  <div>
  <div style={boxtextstyle}>Services</div>
  <div style={boxtextstyle}>{dashboardData.SERVICEMth}</div>
  </div>
  </div>
  <div className="box" style={headerbox_even}>
  <div>
  <div style={boxtextstyle}>Sales</div>
  <div style={boxtextstyle}>{dashboardData.CURRENT_SalesMth}</div>
  </div>
  </div>
  <div className="box" style={headerbox_odd}>
  <div>
  <div style={boxtextstyle}>Expenses</div>
  <div style={boxtextstyle}>{dashboardData.CURRENT_EXPENSESMth}</div>
  </div>
  </div>
  <div className="box" style={headerbox_even}>
  <div>
      <div style={{ fontSize: "18px", fontFamily: "DM Sans" , color: "#000000" }}>
      <label htmlFor="months"></label>
      <Dropdown
        style={{ height:"30%" , width:"100%",alignContent:"center",backgroundColor: "#d5fff2",border:"none",fontWeight:"bold",fontSize:"1%"}}
        id="months"
        value={selectedMonth}
        options={months}
        onChange={handleMonthChange}
        placeholder="Select Month"
      />
      {selectedMonth && <p>{selectedMonth.label}</p>}
      </div>
  </div>
  </div>
  <div className="box" style={headerbox_odd}>
  <div>
      <div style={boxtextstyle}>Services</div>
      <div style={boxtextstyle}>{dashboardData.SERVICEYtd}</div>
  </div>
  </div>
  <div className="box" style={headerbox_even}>
  <div>
      <div style={boxtextstyle}>Sales</div>
      <div style={boxtextstyle}>{dashboardData.CURRENT_SalesYtd}</div>
  </div>
  </div>
  <div className="box" style={headerbox_odd}>
  <div>
      <div style={boxtextstyle}>Expenses</div>
      <div style={boxtextstyle}>{dashboardData.CURRENT_EXPENSESYtd}</div>
  </div>
  </div>
  <div className="box" style={headerbox_even}>
  <div>
      <label htmlFor="Years"></label>
      <Dropdown
        style={{border:"none" , height:"100%" , width:"100%",backgroundColor: "#d5fff2",border:"none",fontWeight:"bold",fontSize:"1%"}}
        id="years"
        value={selectedYear}
        options={years}
        onChange={handleYearChange}
        placeholder="Select Year"
      />
      {selectedYear && <p>{selectedYear.label}</p>}
      </div>
  </div>
  </div> 

  {/* 1stbox Chart*/}
  <div style={{ display: "flex", marginTop: "2%",height:"30vh" }}>
    <div style={{ flex: 1, marginRight: "10px" ,marginBottom:"10px",borderRadius:"10px",backgroundColor:"white"}}>
    <div style={{ height: "13%", textAlign: "center", fontSize: "150%", fontWeight: "bold", marginTop: "3%" }}>Income & Expense Analytics</div>
    <div style={{height:"75%",marginTop:"1%"}}>
      <canvas ref={barChartRef1} id="barChart" style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF"}} />
      </div>
    </div>


    <div style={{ flex: 1, marginLeft:"1%",marginBottom:"10px",borderRadius:"10px",backgroundColor:"white"}}>
    <div style={{ height: "13%", textAlign: "center", fontSize: "150%", fontWeight: "bold", marginTop: "3%" }}>Lead Analysis</div>
      <div style={{height:"75%",marginTop:"1%"}}>
      <Pie data={data} options={options} style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF", backgroundColor: "white", height: "50%" }} />
      </div>
    </div>
  </div>

  {/* 2nd Box List */}
  <div className="month-year d-flex justify-content-between text-white text-center" style={{marginTop:"1%"}} >
  <div className="box" style={headerbox_odd}>
  <div>
    <div style={boxtextstyle}>Service Booked</div>
    <div style={boxtextstyle}>{dashboardData.SERVICE_BOOK}</div>
  </div>
  </div>
  <div className="box" style={headerbox_even}>
    <div>
    <div style={boxtextstyle}>Delivered</div>
      <div style={boxtextstyle}>{dashboardData.DELIVERED} </div>  
  </div>
  </div>
  <div className="box" style={headerbox_odd}>
    <div>
    <div style={boxtextstyle}>In - Progress</div>
      <div style={boxtextstyle}>{dashboardData.IN_PROGRESS}</div>
  </div>
  </div>
  <div className="box" style={headerbox_even}>
    <div>
      <div style={boxtextstyle}>Efficiency %</div>
      <div style={boxtextstyle}>{dashboardData.EfficiencyPercentage} %</div>
  </div>
  </div>
  <div className="box" style={headerbox_odd}>
    <div>
    <div style={boxtextstyle}>Total Deals</div>
      <div style={boxtextstyle}>{dashboardData.totalDeals}</div>   
  </div>
  </div>
  <div className="box" style={headerbox_even}>
    <div>
    <div style={boxtextstyle}>Won Deals</div>
      <div style={boxtextstyle}>{dashboardData.wonDeals}</div>
  </div>
  </div>
  <div className="box" style={headerbox_odd}>
    <div>
    <div style={boxtextstyle1}>Open Deals</div>
      <div style={boxtextstyle}>{dashboardData.openDeals}</div>
  </div>
  </div>
  <div className="box" style={headerbox_even}>
    <div>
    <div style={boxtextstyle}>Lost Deals</div>
      <div style={boxtextstyle}>{dashboardData.lostDeals}</div>   
  </div>
  </div>
 
  </div>

  {/* line graph1 */}
<div style={{ display: "flex", marginTop: "20px",height:"30vh" }} className="divprgs">
    <div style={{ flex: 1, marginRight: "10px", backgroundColor: "white", borderRadius: "10px" }}>
    <div style={{ height: "10%", textAlign: "center", fontSize: "110%", fontWeight: "bold", marginTop: "1%" }}> Income Trends </div>
        <Line data={lineChartData} options={lineChartOptions} />
    </div>
  {/* linegraph2 */}
    <div style={{ flex: 1, marginRight: "10px", backgroundColor: "white", borderRadius: "10px" }}>
    <div style={{ height: "10%", textAlign: "center", fontSize: "110%", fontWeight: "bold", marginTop: "1%" }}> Expense Trends </div>
        <Line data={lineChartData1} options={lineChartOptions1} />
    </div>

  {/* 2nd pie chart */}
  <div style={{ flex: 1, marginLeft:"1%", marginBottom: "10px", backgroundColor: "white", borderRadius: "10px", position: 'relative' }}>
  <div style={{ height: "13%", textAlign: "center", fontSize: "110%", fontWeight: "bold", marginTop: "1%" }} className="ocpncy">Utilization Analysis</div>
  <div style={{ backgroundColor: "white", height: "85%", marginTop: "3%" }}>
      <Pie data={data1} options={options1} style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF", backgroundColor: "white", height: "50%" }} />
  </div>
  </div>

</div>

  </div>
  );
};
export default AutomotiveOverview;
