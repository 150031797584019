import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import LeadIDPopup from "./LeadIDPopup";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import ButtonStyles from "views/common/ButtonStyles";
export default function ActivityPlanDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  onchangeDropDownFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  la_AC_TY,
  la_AC_VT,
  la_ST_CD,
  la_AC_RE,
  selectedAbAdNM,
  selectedAbAdNN,
  selectedSLLDID,
  setSelectedAbAdNM,
  setSelectedAbAdNN,
  setSelectedSLLDID,
  la_DO_CO,
  la_DO_TY,
  la_DO_NO,
  la_AC_OW,
  la_AC_CU,
  setla_DO_NO,
  setla_AC_OW,
  setla_AC_CU,
  laUkNo,
}) {
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [LeadOwnerPopupVisible, setLeadOwnerPopupVisible] = useState(false);
  const [LeadIDPopupVisible, setLeadIDPopupVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [addressTypeCust, setaddressTypecust] = useState("C");
  const [addressTypeLDOW, setaddressTypeLDOW] = useState("E");
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const openLeadOwnerPopup = () => {
    setLeadOwnerPopupVisible(true);
  };
  const openLeadIDPopup = () => {
    setLeadIDPopupVisible(true);
  };

  const [la_AC_TYOptions, setla_AC_TYOptions] = useState([]);
  const [la_AC_VTOptions, setla_AC_VTOptions] = useState([]);
  const [la_ST_CDOptions, setla_ST_CDOptions] = useState([]);
  const [la_AC_REOptions, setla_AC_REOptions] = useState([]);
  const { 
     la_AC_SD, 
     la_AC_ED, 
     la_AC_CD, 
    } = data;

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const url = `${domain}/api/ClassCode/All`;
  const parameter = "SA/AV";
  const finalUrl = `${url}/${parameter}`;

  const parameter1 = "AC/PL";
  const finalUrl1 = `${url}/${parameter1}`;

  const parameter2 = "AV/ST";
  const finalUrl2 = `${url}/${parameter2}`;

  const parameter3 = "AV/RE";
  const finalUrl3 = `${url}/${parameter3}`;

  useEffect(() => {
    fetchDropdownOptions();
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
  }, []);

  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setla_AC_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setla_AC_VTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setla_ST_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setla_AC_REOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const handleDataSelect = (data) => {
    setSelectedAbAdNN(data.ab_AD_NM);
    document.getElementById("la_AC_CU").value = data.abAdNo;
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect1 = (data) => {
    setSelectedAbAdNM(data.ab_AD_NM);
    // onchangeFormData("la_AC_OW", data.abAdNo);
    document.getElementById("la_AC_OW").value = data.abAdNo;
    setLeadOwnerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelectID =async (data) => {
    const Url = `${domain}/api/SalesLead/onChangeOfLeadId`;
    const requestData={
      "slLdId":data.slLdId,
      "sl_LD_TY":data.sl_LD_TY,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const saLdData = response.data.Result;
      if (saLdData) {
        setSelectedAbAdNN(saLdData.ab_AD_NM); //customer
        setSelectedAbAdNM(saLdData.lead_NAME); //lead owner
        setla_DO_NO(saLdData.slLdId);
        setla_AC_OW(saLdData.sl_LD_OW);
        setla_AC_CU(saLdData.sl_CO_NM);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLeadIDPopupVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(laUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{laUkNo ? "Revise Activity Plan" : "Add Activity Plan"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="la_DO_NO" className="font-bold">
                Lead ID <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
              <InputText
                id="la_DO_NO"
                name="la_DO_NO"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={la_DO_NO}
              />
              {showSearchIcon && (
              <i className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openLeadIDPopup}
                ></i>
              )}
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="la_DO_TY" className="font-bold">
                Type
              </label>
              <InputText disabled
                id="la_DO_TY"
                name="la_DO_TY"
                value={la_DO_TY}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="la_DO_CO" className="font-bold">
              Company
              </label>
              <InputText disabled
                id="la_DO_CO"
                name="la_DO_CO"
                value={la_DO_CO}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display : "none"}}>
              <label htmlFor="laUkNo" className="font-bold">
              laUkNo
              </label>
              <InputText disabled
                id="laUkNo"
                name="laUkNo"
                value={laUkNo}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="la_AC_OW" className="font-bold">
                Lead Owner <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={selectedAbAdNM}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openLeadOwnerPopup}
                ></i>
                )}
                <div style={{display : "none"}}>
                  <InputText 
                    id="la_AC_OW"
                    name="la_AC_OW"
                    defaultValue={la_AC_OW}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="abAdNo" className="font-bold">
                Customer <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  value={selectedAbAdNN}
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openCustomerPopup}
                ></i>
                )}
              <div  style={{display : "none"}}>
                <InputText 
                  id="la_AC_CU"
                  name="la_AC_CU"
                  defaultValue={la_AC_CU}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="la_ST_CD" className="font-bold">
                Status
              </label>
              <Dropdown
                id="la_ST_CD"
                name="la_ST_CD"
                value={la_ST_CD}
                options={la_ST_CDOptions}
                onChange={(e) => onchangeDropDownFormData2("la_ST_CD", e.value)}
                placeholder="Status"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="la_AC_SD" className="font-bold">
                Form Date
              </label>
              <Calendar
                id="la_AC_SD"
                name="la_AC_SD"
                value={moment(data.la_AC_SD, "YYYYMMDD").isValid() ? moment(data.la_AC_SD, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "la_AC_SD", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="la_AC_ED" className="font-bold">
                To Date
              </label>
              <Calendar
                id="la_AC_ED"
                name="la_AC_ED"
                value={moment(data.la_AC_ED, "YYYYMMDD").isValid() ? moment(data.la_AC_ED, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "la_AC_ED", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="la_AC_CD" className="font-bold">
                Completion Date
              </label>
              <Calendar
                id="la_AC_CD"
                name="la_AC_CD"
                value={moment(data.la_AC_CD, "YYYYMMDD").isValid() ? moment(data.la_AC_CD, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "la_AC_CD", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="la_AC_TY" className="font-bold">
                Activity Type
              </label>
              <Dropdown
                id="la_AC_TY"
                name="la_AC_TY"
                value={la_AC_TY}
                options={la_AC_TYOptions}
                onChange={(e) => onchangeDropDownFormData("la_AC_TY", e.value)}
                placeholder="Activity Type"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="la_AC_VT" className="font-bold">
                Activity Plan
              </label>
              <Dropdown
                id="la_AC_VT"
                name="la_AC_VT"
                value={la_AC_VT}
                options={la_AC_VTOptions}
                onChange={(e) => onchangeDropDownFormData1("la_AC_VT", e.value)}
                placeholder="Activity Plan"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="la_AC_RE" className="font-bold">
                Activity Result
              </label>
              <Dropdown
                id="la_AC_RE"
                name="la_AC_RE"
                value={la_AC_RE}
                options={la_AC_REOptions}
                onChange={(e) => onchangeDropDownFormData3("la_AC_RE", e.value)}
                placeholder="Activity Result"
              />
            </div>
          </div>
        </div>
      </div>
       {/* Customer */}
       <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypeCust}
        setFormData={setFormData}
      />
      {/* Lead Owner */}
      <AddressBookPopup
        visible={LeadOwnerPopupVisible}
        onClose={() => setLeadOwnerPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={addressTypeLDOW}
        setFormData={setFormData}
      />
      <LeadIDPopup
        visible={LeadIDPopupVisible}
        onClose={() => setLeadIDPopupVisible(false)}
        onDataSelect={handleDataSelectID}
        formData={data}
      />
    </Dialog>
  );
}
