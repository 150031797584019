import React, { useState, useEffect,useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toast } from "primereact/toast";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import moment from "moment";
import axios from "axios";
export default function GLDistribution({
  visible, onClose,
  data,
  gl_DO_NO,
  gl_DO_TY,
  gl_DO_CO,
  handleCloseMain
}) {
  const [isGLSaved, setIsGLSaved] = useState(true);
  const initialValue = {
    gl_BA_DY:"",
    gl_BA_TY:"",
    gl_GL_DT:"",
    gl_CR_CD:"",
    gl_CR_RT:"",
    ex_CR_CR:"",
    gl_EX_PL:"",
    gl_JE_LE:"",
    gl_AC_NO:"",
    acc_DESC:"",
    glUkNo:"",
    gl_AC_AM:"",
    gl_TX_AP:"",
    gl_TX_CD:"",
    gl_TA_AM: "",
    gl_SB_TY:"",
    gl_SB_LE:"",
    name: "",
    gl_RM_MK:"",
    gl_GL_PO:"",
    gl_BA_NO:"",
    gl_AC_ID:"",
    gl_CO_NM:"",
    gl_OB_AC:"",
    gl_OB_SB:"",
    gl_PE_NO:"",
    gl_CT_RY:"",
    gl_FY_YR:"",
    
  
  };
  const toast = useRef(null);
  const [FormData, setFormData] = useState(initialValue);
  const [SelectedDesc, setSelectedDesc ] = useState("");
  const [SelectedAcc, setSelectedAcc ] = useState("");
  const addRow = () => {
    setJournalEntrytable([...JournalEntrytable, {}]);
  };

  const deleteRow = () => {
    if (JournalEntrytable.length > 0) {
      const updatedData = [...JournalEntrytable];
      updatedData.pop();
      setJournalEntrytable(updatedData);
    }
  };
 
  const [gl_DO_AM, setgl_DO_AM] = useState([]);
  const [gl_FR_AM, setgl_FR_AM] = useState([]);
  const [gl_SB_TY, setgl_SB_TY] = useState([]);

  const [JournalEntrytable, setJournalEntrytable] = useState([{}]);

  const [CustomerPopupVisibleGL, setCustomerPopupVisibleGL] = useState(false);
  const openCustomerPopupGL = (e) => {
    const table1 = document.getElementById("JournalEntrytable");
    setgl_SB_TY(table1.tBodies[0].rows[e].cells[6].children[0].children[1].children[0].value);

    setCustomerPopupVisibleGL(true);
    setSelectedRowIndex(e);
  };

  const  getRemaingingAmount= async(SelectedRowIndex) => {
      const table = document.getElementById("JournalEntrytable");
      const totalRowCount = table.tBodies[0].rows.length;
      const journalEntryList = [];
      for (let i = 0; i < totalRowCount; i++) {  
        const Details = {
          gl_AC_AM: document.getElementById("gl_AC_AM_" + i).value
        };
        journalEntryList.push(Details);
      }
      const requestBodyAmt = {
        "slDoNo" : gl_DO_NO,
        "sl_DO_TY" : gl_DO_TY,
        journalEntryList,
      };
      axios.post(`${domain}/api/SupplierLedger/GetRemainingAmount`, requestBodyAmt, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((respNet) => {
        const reAmt = respNet.data.output.gl_AC_AM;
        if(document.getElementById("gl_DO_AM").value){
          document.getElementById("gl_DO_AM").value = reAmt;
        } else {
          document.getElementById("gl_FR_AM").value = reAmt;
        }
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  }

  const onChangeFormAmount = async(e,index) => {
    onchangedata(e);
    getRemaingingAmount(index);
  };

  const handleDataSelect2 = async (data) => {
    const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
    const requestData={
      "abAdNo":data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const result = response.data;
      if (result && result.data ) {
        document.getElementById (`gl_SB_LE_${SelectedRowIndex}`).value =  result.data.abAdNo;
        document.getElementById (`gl_SB_NM_${SelectedRowIndex}`).value =  result.data.ab_AD_NM;
      } else {
        console.error("Invalid API response:", result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setCustomerPopupVisibleGL(false);
  };

  const onchangeDropDownFormData61 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setgl_SB_TY(value);
  };

  const onchangeDropDownFormData6 = (name, value, index=null) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setgl_SB_TY(value);
    const fieldName= name; 
    //JournalEntrytable[index][fieldName]=value;
    
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleCloseMain();
    }
  };

  const getGaGlToDislay = () => {
    const gaGlDisplay = {
      "gl_DO_TY": gl_DO_TY,
      "gl_DO_NO": gl_DO_NO
    };
    axios.post(`${domain}/api/JournalEntry/GetGAGLToDisplay`, gaGlDisplay, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            const len = resp.data.header.length;
            if(len > 0){
              setIsGLSaved(false);
              setJournalEntrytable(resp.data.header);
            } else {
              const culdInput = {
                "slDoNo": gl_DO_NO,
                "sl_DO_TY": gl_DO_TY
            }
              axios.post(`${domain}/api/SupplierLedger/GetAmountSummary`, culdInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp1) => {
                const culdSum = resp1.data.output;
                 setgl_DO_AM(culdSum.sl_GR_AM);
                 setgl_FR_AM(culdSum.sl_CR_AM);
              })
              .catch((error) => {
                console.error("Error adding data:", error);
              });
            }
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });

  };
    useEffect(() => {
      getGaGlToDislay();
  });

  const handleGLDistribution = () => {   
        const table1 = document.getElementById("JournalEntrytable");
        const totalRowCount = table1.tBodies[0].rows.length;

        const journalEntryList = [];
        for (let i = 0; i < totalRowCount; i++) {
          const JE_LE = document.getElementById("gl_JE_LE_" + i);
          const AC_NO = document.getElementById("gl_AC_NO_" + i);
          const AC_AM = document.getElementById("gl_AC_AM_" + i);
          const Details = {
            gl_JE_LE:JE_LE.value,
            gl_AC_NO: AC_NO.value,
            gl_AC_AM: AC_AM.value,
            gl_SB_TY: table1.tBodies[0].rows[i].cells[6].children[0].children[1].children[0].value,
            gl_SB_LE : document.getElementById("gl_SB_LE_" + i).value,
            gl_UN_QT : document.getElementById("gl_UN_QT_" + i).value,
            gl_EX_PL : document.getElementById("gl_EX_PL_" + i).value
          };
          journalEntryList.push(Details);
        }
        const requestBody = {
          journalEntryList,
          "slDoNo" : gl_DO_NO,
          "sl_DO_TY" : gl_DO_TY,
          "sl_DO_CO" : document.getElementById("gl_DO_CO").value
        };
            axios.post(`${domain}/api/SupplierLedger/SaveVoucherLedger`, requestBody, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                const ukNo = resp.data.result.sl_UK_NO;
                const requestBodyGL = {
                  sl_UK_NO: ukNo
                };
                axios.post(`${domain}/api/SupplierLedger/PostReceiptsToGAGL`, requestBodyGL, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "http://localhost:3000",
                      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                    },
                  })
                  .then((resp1) => {
                    axios.post(`${domain}/api/SupplierLedger/PostReceiptsToGABL`, requestBodyGL, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "http://localhost:3000",
                      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                    },
                  })
                  .then((resp2) => {
                    handleResponse(resp2.data);
                  })
                  .catch((error) => {
                    console.error("Error saving data:", error);
                  });
                  })
                  .catch((error) => {
                    console.error("Error saving data:", error);
                  });
              })
              .catch((error) => {
                console.error("Error adding data:", error);
              });
          
    
        
        }; 
        
  const {
  gl_BA_NO,
  gl_GL_DT,
  gl_CR_RT,
  gl_EX_PL,
  ex_CR_CR,
  glUkNo,
  gl_JE_LE,
  gl_AC_NO,
  acc_DESC,
  gl_AC_AM,
  gl_TA_AM,
  gl_TX_AM,
  gl_SB_LE,
  name,
  gl_RM_MK,
    
  } = data;
  const [open, setOpen] = useState(false);
  const [gl_DO_TYOptions, setgl_DO_TYOptions] = useState([]);
  const [gl_BA_TYOptions, setgl_BA_TYOptions] = useState([]);
  const [gl_CR_CDOptions, setgl_CR_CDOptions] = useState([]);

  const [gl_TX_APOptions, setgl_TX_APOptions] = useState([]);
  const [gl_TX_CDOptions, setgl_TX_CDOptions] = useState([]);
  const [gl_SB_TYOptions, setgl_SB_TYOptions] = useState([]);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
 
  const handleClose = () => {
    onClose();
    setOpen(false);
  };
  useEffect(() => {
    setOpen(visible);
   
  },);

  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const openAccountPopup = (e) => {
    setAccountPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = (data) => {
    setSelectedDesc(data.am_AC_DS);
    setSelectedAcc(data.account);
    document.getElementById("gl_AC_NO_" + SelectedRowIndex).value = data.account;
    document.getElementById("acc_DESC_" + SelectedRowIndex).value = data.am_AC_DS;
    setAccountPopupVisible(false);
  };
  const handleFormDataChange6 = (name, value) => {
    const index = name.split("_")[1];
    onchangeDropDownFormData6(name, value, index);
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      const formattedDate1 = moment(selectedDate).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      const formattedDate1 = moment(value).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "gl_GL_DT" ) {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format(
        "YYYYMMDD"
      );
      console.log(`Formatted date for ${name}:`, formattedDate);

      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };
  const url = `${domain}/api/ClassCode/All`;

  const parameter4 = "IN/TX";
  const finalUrl4 = `${url}/${parameter4}`;

  const parameter5 = "IN/UM";
  const finalUrl5 = `${url}/${parameter5}`;

  const parameter6 = "FO/AT";
  const finalUrl6 = `${url}/${parameter6}`;

  useEffect(() => {
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
  }, []);


  // 4nd dropdown
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setgl_TX_APOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  // 5nd dropdown
  const fetchDropdownOptions5 = async () => {
    try {
      const response = await axios.get(finalUrl5, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setgl_TX_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 6nd dropdown
  const fetchDropdownOptions6 = async () => {
    try {
      const response = await axios.get(finalUrl6, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setgl_SB_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const isUpdateMode = Boolean(glUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      ></Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleGLDistribution}
        tooltip={isUpdateMode ? "Update" : "Submit"}
        disabled={!isGLSaved}
      ></Button>
    </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={"Add GLDistribution"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
        <Toast ref={toast} />
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div className="formgrid grid">
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="gl_DO_NO" className="font-bold">
              Document No
            </label>
            <InputText disabled
              id="gl_DO_NO"
              name="gl_DO_NO"
              value={gl_DO_NO}
              readOnly={true}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="gl_DO_TY" className="font-bold">
              Doc Type
            </label>
            <InputText disabled
              id="gl_DO_TY"
              name="gl_DO_TY"
              value={gl_DO_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="gl_DO_CO" className="font-bold">
              Doc Company
            </label>
            <InputText disabled
              id="gl_DO_CO"
              name="gl_DO_CO"
              value={gl_DO_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="gl_DO_CO" className="font-bold">
            Auto Post
            </label>
            <InputText disabled
                    id="auto"
                    name="auto"
                    value={"Yes"}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="gl_DO_CO" className="font-bold">
            Amount to Distribute
            </label>
            <InputText disabled
              id="gl_DO_AM"
              name="gl_DO_AM"
              value={gl_DO_AM}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="gl_DO_CO" className="font-bold">
              Fore Amount  to Distribute
            </label>
            <InputText disabled
              id="gl_FR_AM"
              name="gl_FR_AM"
              value={gl_FR_AM}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
          }}
        >
          {!isUpdateMode && (
            <Button
              icon="pi pi-plus"
              onClick={addRow}
              style={{
                marginRight: "8px",
                backgroundColor: "transparent",
                color: "black",
                border: "none",
              }}
              tooltip="Add"
            />
          )}
          {!isUpdateMode && (
            <Button
              icon="pi pi-minus"
              onClick={deleteRow}
              style={{
                backgroundColor: "transparent",
                color: "black",
                border: "none",
              }}
              tooltip="Delete"
            />
          )}
        </div>
        <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
            position: "relative",
          }}
        >
          <table
            className="custom-table"
            id="JournalEntrytable"
            style={{ overflow: "auto" }}
          >
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK_NO</th>
                <th>Account</th>
                <th>Amount</th>
                <th>Explanation</th>
                <th>Line No</th>
                <th>Unit </th>
                <th>Sub Ledger Type</th>
                <th>Sub Ledger </th>
                
              </tr>
            </thead>
            <tbody>
              {JournalEntrytable.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`glUkNo_${index}`}
                      name={`glUkNo_${index}`}
                      defaultValue={row.glUkNo}
                    />
                  </td>
                    <td>
                      <div style={{ display: "flex" }}>
                      <InputText readOnly
                          style={{ width: "100px" }}
                          id={`acc_DESC_${index}`}
                          name={`acc_DESC_${index}`}
                          onChange={(e) => onchangedata(e, index)}
                          defaultValue={row.acc_DESC ? row.acc_DESC : row.acc_DESC ? row.acc_DESC : row[`${'acc_DESC'}_${index}`]}
                        />
                        <InputText style={{display:"none"}}
                          id={`gl_AC_NO_${index}`}
                          name={`gl_AC_NO_${index}`}
                          onChange={(e) => onchangedata(e, index)}
                          value={row.gl_AC_NO ? row.gl_AC_NO : row[`${'gl_AC_NO'}_${index}`]}
                        />
                        {(
                        <i
                          className="pi pi-search"
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "119%",
                            marginTop: "15%",
                            cursor: "pointer",
                          }}
                          onClick={(e) => openAccountPopup(index)}
                        ></i>
                        )}
                      </div>
                    </td>
                  <td>
                    <InputText
                      style={{ width: "90px" }}
                      id={`gl_AC_AM_${index}`}
                      name={`gl_AC_AM_${index}`}
                      defaultValue={row.gl_AC_AM}
                      onChange={(e) => onChangeFormAmount(e,index)}
                    />
                  </td>
                  <td>
                    <InputText
                      style={{ width: "90px" }}
                      id={`gl_EX_PL_${index}`}
                      name={`gl_EX_PL_${index}`}
                      defaultValue={row.gl_EX_PL}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </td>
                  <td>
                  <InputText disabled
                      style={{ width: "90px" }}
                      id={`gl_JE_LE_${index}`}
                      name={`gl_JE_LE_${index}`}
                      value={row.gl_JE_LE ? row.gl_JE_LE: index + 1}
                    />
                  </td>
                  <td>
                  <InputText
                      style={{ width: "90px" }}
                      id={`gl_UN_QT_${index}`}
                      name={`gl_UN_QT_${index}`}
                      defaultValue={row.gl_UN_QT}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`gl_SB_TY_${index}`}
                      name={`gl_SB_TY_${index}`}
                      value={FormData[`gl_SB_TY_${index}`] ? FormData[`gl_SB_TY_${index}`]:row.gl_SB_TY }
                      options={gl_SB_TYOptions}
                      onChange={(e) => handleFormDataChange6(`gl_SB_TY_${index}`, e.value)}
                      placeholder="Select Sub Ledger Type"
                    />
                  </td>
                    <td>
                      <div style={{ display: "flex" }}>
                      <InputText
                          style={{ width: "100px" }}
                          id={`gl_SB_NM_${index}`}
                          name={`gl_SB_NM_${index}`}
                          onChange={(e) => onchangedata(e, index)}
                          defaultValue={row.name ? row.name : row.gl_SB_NM ? row.gl_SB_NM : row[`${'gl_SB_NM'}_${index}`]}
                        />
                        <InputText style={{display:"none"}}
                          id={`gl_SB_LE_${index}`}
                          name={`gl_SB_LE_${index}`}
                          onChange={(e) => onchangedata(e, index)}
                          value={row.gl_SB_LE ? row.gl_SB_LE : row[`${'gl_SB_LE'}_${index}`]}
                        />
                        {(
                        <i
                          className="pi pi-search"
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "119%",
                            marginTop: "15%",
                            cursor: "pointer",
                          }}
                          onClick={(e) => openCustomerPopupGL(index)}
                        ></i>
                        )}
                      </div>
                    </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
      <AddressBookPopup
        visible={CustomerPopupVisibleGL}
        onClose={() => setCustomerPopupVisibleGL(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
        addressType={gl_SB_TY}
      />
     
    </Dialog>
  );
}
