import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import SupplierPopup from "../Popups/SupplierPopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
import ItemPopup from "../Popups/ItemPopup";
export default function PurchaseOrderInterfaceDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  PurchaseOrderInterfaceTableData,
  setPurchaseOrderInterfaceTableData,
  pi_TR_CO,
  setep_EX_CT,
  pi_TR_TY,
  pi_TR_NO,
  pi_OR_TY,
  pi_OR_CO,
  setselectedSupplier,
  setselectedshipto,
}) {
  const {
    piUkNo,
    pi_OR_NO,
    pi_CO_CT,
    pi_TR_CR,
    pi_SU_NO,
    cus_NAME,
    pi_SH_TO,
    shipto_NAME,
    pi_PR_DT,
    pi_OR_DT,
    pi_RQ_DT,
    pi_PY_TR,
    pi_PO_DT,
    pi_IT_NM,
    item_DES,
    pi_OR_QT,
    pi_TR_UM,
    pi_UT_PR,
    pi_DS_PR,
    pi_FR_PR,
    pi_TX_PE,
    pi_RC_DT,
    pi_IN_NO,
    pi_IN_DT,
    pi_LO_CN,
    pi_LT_NO,
    pi_EX_DT,
    pi_IV_CT,
    pi_TR_DT,

  } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, multitableheader } = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "pi_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
   // onDateChange({ target: { name: "pi_RQ_DT", value: moment(e.value).format("YYYYMMDD") } });
  //  onDateChange({ target: { name: "pi_PO_DT", value: moment(e.value).format("YYYYMMDD") } });
  };

  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();

  const [ep_EX_CTOptions, setep_EX_CTOptions] = useState([]);





  // Expense Category
  const ExpenseCategoryDropdown = (name, value) => {
    const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_EX_CT(value);
  };



  useEffect(() => {


    ExpenseCategory();



  }, []);


  const ExpenseCategory = async () => {
    const Options = await fetchClassCodeDS("EX/CT");
    setep_EX_CTOptions(Options);
  };


  const addRow = () => {
    setPurchaseOrderInterfaceTableData([...PurchaseOrderInterfaceTableData, {}]);
  };

  const deleteRow = () => {
    if (PurchaseOrderInterfaceTableData.length > 0) {
      const updatedData = [...PurchaseOrderInterfaceTableData];
      updatedData.pop();
      setPurchaseOrderInterfaceTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(piUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
        tooltip={isUpdateMode ? "Update" : "Submit"}>
      </Button>
      {isUpdateMode && (
        <Button
          style={{ width: "5%" }}
          icon="pi pi-circle"
          onClick={(e) => handleFormSubmit("process")}
          tooltip="Process"
        />
      )}
    </React.Fragment>
  );

  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
  const [shiptoPopupVisible, setshiptoPopupVisible] = useState(false);
  const [ItemVisible, setItemVisible] = useState(false);


  const [addressType, setaddressType] = useState("E");


  const openSupplierPopup = (e) => {
    setSupplierPopupVisible(true);
    setSelectedRowIndex(e);
    setShowSearchIcon(false);
  };
  const openshiptoPopup = (e) => {
    setshiptoPopupVisible(true);
    setSelectedRowIndex(e);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const handleSelectSupplier = async (data) => {
    setselectedSupplier(data.ab_AD_NM);
    document.getElementById(`cus_NAME_${SelectedRowIndex}`).value = data.ab_AD_NM;
    document.getElementById(`pi_SU_NO_${SelectedRowIndex}`).value = data.abAdNo;
    const Url = `${domain}/api/SupplierMaster/GetById`;
    const requestData={
      "su_SU_NO": data.abAdNo
    }
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataSuMt = response.data.Result;
      if (dataSuMt) {
        document.getElementById(`pi_TR_CR_${SelectedRowIndex}`).value = dataSuMt.su_CR_CD;
        document.getElementById(`pi_SU_NO_${SelectedRowIndex}`).value = dataSuMt.su_SU_NO;

        // const exData={
        //   "cr_TO_CR": document.getElementById("sh_BS_CR").value, //base curr
        //   "cr_FR_CR": dataCuMt.cu_CR_CD, //currency
        //   "cr_EF_DT": data.sh_OR_DT
        // }
        //exchangeRate (exData);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setSupplierPopupVisible(false);
    setShowSearchIcon(false);
  };

  const handleSelectshipto = (data) => {
    setselectedshipto(data.ab_AD_NM);
    document.getElementById(`shipto_NAME_${SelectedRowIndex}`).value = data.ab_AD_NM;
    document.getElementById(`pi_SH_TO_${SelectedRowIndex}`).value = data.abAdNo;
    setshiptoPopupVisible(false);
    setShowSearchIcon(false);
  };

  const handleItem = async (data) => {
    document.getElementById(`pi_IT_NM_${SelectedRowIndex}`).value = data.im_IT_NM;
    document.getElementById(`item_DES_${SelectedRowIndex}`).value = data.im_IT_DS;

    setItemVisible(false);
    setShowSearchIcon(false);
  };


  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "85rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={piUkNo ? "Revise Purchase Order Interface" : "Add Purchase Order Interface"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={inboxstyle}>
        <div className="formgrid grid">

          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ri_SR_NO" className="font-bold">
              Transaction No
            </label>
            <InputText disabled
              id="pi_TR_NO"
              name="pi_TR_NO"
              value={pi_TR_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="pi_TR_TY" className="font-bold">
              Type
            </label>
            <InputText disabled
              id="pi_TR_TY"
              name="pi_TR_TY"
              value={pi_TR_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="pi_TR_CO" className="font-bold">
              Company
            </label>
            <InputText disabled
              id="pi_TR_CO"
              name="pi_TR_CO"
              value={pi_TR_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>

          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="pi_TR_DT" className="font-bold">
              Transaction Date
            </label>
            <Calendar
              id="pi_TR_DT"
              name="pi_TR_DT"
              value={moment(pi_TR_DT, "YYYYMMDD").toDate()}
              onChange={handleDateChange}
              dateFormat="dd/mm/yy"
            />
          </div>

        </div>
      </div>
      <div style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
            <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
          )}
          {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div style={multitablestyle}>
          <table className="custom-table" id="PurchaseOrderInterfaceTableData">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Line Id</th>
                <th>Order No</th>
                <th>Type</th>
                <th>Company</th>
                <th>Cost Centre</th>
                <th>Currency</th>
                <th>Supplier No<span style={{ color: 'red' }}>*</span></th>
                <th>Ship To<span style={{ color: 'red' }}>*</span></th>
                <th>Order Date</th>
                <th>Requested Date</th>
                <th>PO Date</th>
                <th>Payment Term</th>
                <th>Promised Date</th>
                <th>Item Name<span style={{ color: 'red' }}>*</span></th>
                <th>Description</th>
                <th>Quantity</th>
                <th>UM</th>
                <th>Unit Price</th>
                <th>Discount %</th>
                <th>Fore.Unit Price</th>
                <th>Tax %</th>
                <th>Receipt Date</th>
                <th>Delivery No	</th>
                <th>Delivery Date</th>
                <th>Location</th>
                <th>Lot No</th>
                <th>Expiry Date</th>
                <th>Take Receipt</th>
                <th>Inv.Cost Centre</th>
                <th>Processed </th>

              </tr>
            </thead>
            <tbody>
              {PurchaseOrderInterfaceTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`piUkNo_${index}`}
                      name={`piUkNo_${index}`}
                      value={row.piUkNo}
                    />
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "90px" }}
                      id={`pi_LN_NO_${index}`}
                      name={`pi_LN_NO_${index}`}
                      value={index + 1}
                      readOnly
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_OR_NO_${index}`}
                      name={`pi_OR_NO_${index}`}
                      defaultValue={row.pi_OR_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_OR_TY_${index}`}
                      name={`pi_OR_TY_${index}`}
                      defaultValue={pi_OR_TY}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_OR_CO_${index}`}
                      name={`pi_OR_CO_${index}`}
                      defaultValue={pi_OR_CO}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_CO_CT_${index}`}
                      name={`pi_CO_CT_${index}`}
                      defaultValue={row.pi_CO_CT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_TR_CR_${index}`}
                      name={`pi_TR_CR_${index}`}
                      defaultValue={row.pi_TR_CR}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td>
                    <div style={{ display: "flex" }}>
                      <InputText
                        style={{ width: "200px" }}
                        id={`cus_NAME_${index}`}
                        name={`cus_NAME_${index}`}
                        onFocus={() => setShowSearchIcon(true)}
                        //  onChange={(Event) => onchangedata(Event)}
                        onChange={(e) => onchangedata('cus_NAME', e.value, index)}
                        defaultValue={row.cus_NAME ? row.cus_NAME : row[`${'cus_NAME'}_${index}`]}
                      />
                      {showSearchIcon && (
                        <i
                          className="pi pi-search"
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "119%",
                            marginTop: "7%",
                            cursor: "pointer",
                          }}
                          onClick={(e) => openSupplierPopup(index)}
                        ></i>
                      )}
                    </div>
                    <InputText
                      style={{ display: "none" }}
                      id={`pi_SU_NO_${index}`}
                      name={`pi_SU_NO_${index}`}
                      onChange={(Event) => onchangedata(Event)}
                      defaultValue={row.pi_SU_NO ? row.pi_SU_NO : row[`${'pi_SU_NO'}_${index}`]}
                    />

                  </td>

                  {/* <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_SH_TO_${index}`}
                      name={`pi_SH_TO_${index}`}
                      defaultValue={row.pi_SH_TO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td> */}
                  <td>
                    <div style={{ display: "flex" }}>
                      <InputText
                        style={{ width: "200px" }}
                        id={`shipto_NAME_${index}`}
                        name={`shipto_NAME_${index}`}
                        onFocus={() => setShowSearchIcon(true)}
                        //  onChange={(Event) => onchangedata(Event)}
                        onChange={(e) => onchangedata('shipto_NAME', e.value, index)}
                        defaultValue={row.shipto_NAME ? row.shipto_NAME : row[`${'shipto_NAME'}_${index}`]}
                      />
                      {showSearchIcon && (
                        <i
                          className="pi pi-search"
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "119%",
                            marginTop: "7%",
                            cursor: "pointer",
                          }}
                          onClick={(e) => openshiptoPopup(index)}
                        ></i>
                      )}
                    </div>
                    <InputText
                      style={{ display: "none" }}
                      id={`pi_SH_TO_${index}`}
                      name={`pi_SH_TO_${index}`}
                      onChange={(Event) => onchangedata(Event)}
                      defaultValue={row.pi_SH_TO ? row.pi_SH_TO : row[`${'pi_SH_TO'}_${index}`]}
                    />

                  </td>
                  <td>
                    <Calendar style={{ width: "150px" }}
                      id={`pi_OR_DT_${index}`}
                      name={`pi_OR_DT_${index}`}
                      value={moment(row.pi_OR_DT, "YYYYMMDD").isValid() ? moment(row.pi_OR_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onDateChange({ target: { name: "pi_OR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                      dateFormat="dd/mm/yy"
                    />
                  </td>
                  <td>
                    <Calendar style={{ width: "150px" }}
                      id={`pi_RQ_DT_${index}`}
                      name={`pi_RQ_DT_${index}`}
                      value={moment(row.pi_RQ_DT, "YYYYMMDD").isValid() ? moment(row.pi_RQ_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onDateChange({ target: { name: "pi_RQ_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                      dateFormat="dd/mm/yy"
                    />
                  </td>
                  <td>
                    <Calendar style={{ width: "150px" }}
                      id={`pi_PO_DT_${index}`}
                      name={`pi_PO_DT_${index}`}
                      value={moment(row.pi_PO_DT, "YYYYMMDD").isValid() ? moment(row.pi_PO_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onDateChange({ target: { name: "pi_PO_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                      dateFormat="dd/mm/yy"
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_PY_TR_${index}`}
                      name={`pi_PY_TR_${index}`}
                      defaultValue={row.pi_PY_TR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <Calendar style={{ width: "150px" }}
                      id={`pi_PR_DT_${index}`}
                      name={`pi_PR_DT_${index}`}
                      value={moment(row.pi_PR_DT, "YYYYMMDD").isValid() ? moment(row.pi_PR_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onDateChange({ target: { name: "pi_PR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                      dateFormat="dd/mm/yy"
                    />
                  </td>
                  <td
                    style={{ display: "flex" }}>
                    <InputText
                      style={{ width: "110px" }}
                      id={`pi_IT_NM_${index}`}
                      name={`pi_IT_NM_${index}`}
                      onChange={(e) => onchangeFormData(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.pi_IT_NM ? row.pi_IT_NM : row[`${"pi_IT_NM"}_${index}`]}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "15%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openItemPopup(index)}
                      ></i>
                    )}
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "200px" }}
                      id={`item_DES_${index}`}
                      name={`item_DES_${index}`}
                      value={row.item_DES ? row.item_DES : row[`${"item_DES"}_${index}`]}
                      readOnly
                    />
                  </td>
                  {/* 
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_IT_NM_${index}`}
                      name={`pi_IT_NM_${index}`}
                      defaultValue={row.pi_IT_NM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`item_DES_${index}`}
                      name={`item_DES_${index}`}
                      defaultValue={row.item_DES}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td> */}
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_OR_QT_${index}`}
                      name={`pi_OR_QT_${index}`}
                      defaultValue={row.pi_OR_QT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_TR_UM_${index}`}
                      name={`pi_TR_UM_${index}`}
                      defaultValue={row.pi_TR_UM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_UT_PR_${index}`}
                      name={`pi_UT_PR_${index}`}
                      defaultValue={row.pi_UT_PR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_DS_PR_${index}`}
                      name={`pi_DS_PR_${index}`}
                      defaultValue={row.pi_DS_PR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_FR_PR_${index}`}
                      name={`pi_FR_PR_${index}`}
                      defaultValue={row.pi_FR_PR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_TX_PE_${index}`}
                      name={`pi_TX_PE_${index}`}
                      defaultValue={row.pi_TX_PE}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <Calendar style={{ width: "150px" }}
                      id={`pi_RC_DT_${index}`}
                      name={`pi_RC_DT_${index}`}
                      value={moment(row.pi_RC_DT, "YYYYMMDD").isValid() ? moment(row.pi_RC_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onDateChange({ target: { name: "pi_RC_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                      dateFormat="dd/mm/yy"
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_IN_NO_${index}`}
                      name={`pi_IN_NO_${index}`}
                      defaultValue={row.pi_IN_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <Calendar style={{ width: "150px" }}
                      id={`pi_IN_DT_${index}`}
                      name={`pi_IN_DT_${index}`}
                      value={moment(row.pi_IN_DT, "YYYYMMDD").isValid() ? moment(row.pi_IN_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onDateChange({ target: { name: "pi_IN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                      dateFormat="dd/mm/yy"
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_LO_CN_${index}`}
                      name={`pi_LO_CN_${index}`}
                      defaultValue={row.pi_LO_CN}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_LT_NO_${index}`}
                      name={`pi_LT_NO_${index}`}
                      defaultValue={row.pi_LT_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <Calendar style={{ width: "150px" }}
                      id={`pi_EX_DT_${index}`}
                      name={`pi_EX_DT_${index}`}
                      value={moment(row.pi_EX_DT, "YYYYMMDD").isValid() ? moment(row.pi_EX_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onDateChange({ target: { name: "pi_EX_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                      dateFormat="dd/mm/yy"
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_TK_RC_${index}`}
                      name={`pi_TK_RC_${index}`}
                      defaultValue={row.pi_TK_RC}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_IV_CT_${index}`}
                      name={`pi_IV_CT_${index}`}
                      defaultValue={row.pi_IV_CT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  {/* <td >
                    <InputText style={{ width: "100px" }}
                      id={`pi_PR_FL_${index}`}
                      name={`pi_PR_FL_${index}`}
                      defaultValue={row.pi_PR_FL}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td> */}
                  <td>
                    <InputText
                      style={{ width: "100px" }}
                      id={`pi_PR_FL_${index}`}
                      name={`pi_PR_FL_${index}`}
                      defaultValue={row.pi_PR_FL}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Supplier No */}
      <AddressBookPopup
        visible={SupplierPopupVisible}
        onClose={() => setSupplierPopupVisible(false)}
        onDataSelect={handleSelectSupplier}
        formData={data}
        addressType={addressType}
      />
      {/* Ship To */}
      <AddressBookPopup
        visible={shiptoPopupVisible}
        onClose={() => setshiptoPopupVisible(false)}
        onDataSelect={handleSelectshipto}
        formData={data}
        addressType={addressType}
      />
      {/* item */}
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
