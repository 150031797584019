import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import SpecificationDialog from "./SpecificationDialog";
import { InputText } from "primereact/inputtext";
import SecondPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  tsUkNo:"",
  ts_SP_ID:"",
  ts_SP_DS:"",
  ts_TS_ID:"",   
  ts_TS_DS:"",
  ts_AL_MI:"",
  ts_PR_MI:"",   
  ts_TR_VL:"",
  ts_PR_MX:"",
  ts_AL_MX:"",    
  ts_TS_UM:"",    
  ts_FR_DT:"",    
  ts_TO_DT:"",    
  ts_CO_CT:"", 
  sh_SP_ID:"",
  sh_SP_DS:"",
  sh_RV_NO:"",
  sh_UK_NO:"",
};

const initialFilters = {
  ts_SP_ID: { value: "", operation: "" },
  ts_SP_DS: { value: "", operation: "" },
  ts_TS_ID: { value: "", operation: "" },   
  ts_TS_DS: { value: "", operation: "" },
  ts_AL_MI: { value: "", operation: "" },
  ts_PR_MI: { value: "", operation: "" },   
  ts_TR_VL: { value: "", operation: "" },
  ts_PR_MX: { value: "", operation: "" },
  ts_AL_MX: { value: "", operation: "" },    
  ts_TS_UM: { value: "", operation: "" },    
  ts_FR_DT: { value: "", operation: "" },    
  ts_TO_DT: { value: "", operation: "" },    
  ts_CO_CT: { value: "", operation: "" }, 
};
const WorkCentreMain = () => {
  // buttonstyles
  const { buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle } = ButtonStyles();

    const token = localStorage.getItem("token");
    const company = localStorage.getItem("company");
    const domain = localStorage.getItem("domain");
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns

  const [sh_CO_CT, setsh_CO_CT ] = useState("");
  const [sh_TS_ST, setsh_TS_ST ] = useState("");
  const [ts_TS_UM, setts_TS_UM ] = useState("");

  const [sh_UK_NO, setsh_UK_NO ] = useState("");
  const [tsUkNo, settsUkNo ] = useState("");

  const [cc_LD_STOptions, setcc_LD_STOptions] = useState([]);
  const [ts_CO_CTOptions, setts_CO_CTOptions] = useState([]);
  const [customer, setCustomer] = useState("");
  const [Owner, setOwner] = useState("");
  const [Status, setStatus] = useState("");
  const [secondPopupVisible, setSecondPopupVisible] = useState(false);
  const [addressTypeC, setaddressTypeC] = useState("C");
  const [selectedcustmer, setSelectedcustmer] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SpecificationTableData, setSpecificationTableData] = useState([{}]);
  const url11 = `${domain}/api/ClassCode/All`;
const parameter2 = "EM/ST";
const finalUrl2 = `${url11}/${parameter2}`;
const fetchDropdownOptions2 = async () => {
  try {
    const response = await axios.get(finalUrl2, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcc_LD_STOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const url = `${domain}/api/CostCenter/DropDown`;
const finalUrl = `${url}`;

const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cc_CC_NM,
      value: item.cc_CC_NM,
    }));
    setts_CO_CTOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

  const openSecondPopup = () => {
    setSecondPopupVisible(true);
  };


  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      cc_AD_NO: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }

  setFilters(updatedFilters);
  };

  const updateFilters2 = (sts) => {
    let updatedFilters = { ...filters };
  if (sts !== null) {
    updatedFilters = {
      ...updatedFilters,
      cc_LD_ST: {
        value: sts,
        operation: "LIKE",
      },
    };
  }

  setFilters(updatedFilters);
  };

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleDataSelect = (data) => {
    setSelectedcustmer(data.ab_AD_NM);
    setCustomer(data.abAdNo);
    setSecondPopupVisible(false);
    setShowSearchIcon(false);
    updateFilters (data.abAdNo);
  };

  const onchangeDropDown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setStatus(value);
    updateFilters2 (value);
    console.log(value);
  };


  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSpecificationTableData([{}]);
    setsh_TS_ST(null);
    setsh_CO_CT(null);
    setts_TS_UM(null);
 
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleClearButton=()=>{
    setCustomer("");
    setOwner("");
    setSelectedcustmer("");
    setStatus(null);
    setFilters("");
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/Specification/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const specifications = response.data;
          const updatedspecifications = specifications.specificationsList.map((target) => { 

            const UpdatedworkData = { ...target };
            delete UpdatedworkData.tsUkNo;
            return UpdatedworkData;
          });
          setSpecificationTableData(updatedspecifications);
          setFormData(specifications.header);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault(); 
    fetchDropdownOptions2();
    fetchDropdownOptions3();

  }, [currentPage, pageSize, filters]);
  const [ts_CO_NO , setts_CO_NO] = useState("");

  const [sh_CO_NO , setsh_CO_NO] = useState("");

  const handleDefault = async () => {
    setts_CO_NO(`${company}`)
    setsh_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/Specification/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/Specification/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const specifications = response.data;
        setSpecificationTableData(specifications.specificationsList);
        setFormData(specifications.header);
        setsh_UK_NO(specifications.header.sh_UK_NO);
        setsh_TS_ST(specifications.header.sh_TS_ST);
        setsh_CO_CT(specifications.header.sh_CO_CT);
        setts_TS_UM(specifications.specificationsList.ts_TS_UM);
        settsUkNo(specifications.specificationsList.tsUkNo)
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("Specificationtable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const specificationsList = [];
  
  const header = {
    sh_UK_NO:FormData.sh_UK_NO,
    sh_SP_ID:FormData.sh_SP_ID,
    sh_SP_DS:FormData.sh_SP_DS,
    sh_TS_ST:sh_TS_ST,
    sh_CO_CT:sh_CO_CT,
    sh_RV_NO:FormData.sh_RV_NO,
    sh_CO_NO :sh_CO_NO,
  }
  for (let i = 0; i < totalRowCount1; i++) {
    const RQ_DT = document.getElementsByName("ep_EF_DT_" + i)[0];
    const details = {
        tsUkNo : document.getElementById("tsUkNo_" + i).value,
        ts_TS_ID : document.getElementById("ts_TS_ID_" + i).value,
        ts_TS_DS : document.getElementById("ts_TS_DS_" + i).value,
        ts_AL_MI : document.getElementById("ts_AL_MI_" + i).value,
        ts_PR_MI : document.getElementById("ts_TR_VL_" + i).value,
        ts_TR_VL : document.getElementById("ts_TS_ID_" + i).value,
        ts_AL_MX : document.getElementById("ts_AL_MX_" + i).value,
        ts_PR_MX : document.getElementById("ts_PR_MX_" + i).value,
        ts_TS_UM : table.tBodies[0].rows[i].cells[8].children[0].children[1].children[0].value,
        ts_FR_DT : document.getElementById("ts_FR_DT_" + i).value,
        ts_TO_DT : document.getElementById("ts_TO_DT_" + i).value,
        ts_CO_NO : document.getElementById("ts_CO_NO_" + i).value,

    };
    specificationsList.push(details);
  }

  
 requestBody = {
  specificationsList,
   header,
  };
}
  const handleFormSubmit = () => {
    values ();
    if (FormData.tsUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/Specification/Update`;
      if (confirm) {
        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.ts_CO_NO = company;
      axios
        .post(`${domain}/api/Specification/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
 

  const AdvanceSearch = (
    <div className="formgrid grid" id="headerfilters">
  <div className="field col" >
    <label htmlFor="customer" className="font-bold">
    Specification ID<span style={{ color: 'red' }}>*</span>
    </label>
    <div>
    </div>
    <div style={{ display: "flex", width:"50%" }}>
      <InputText 
      style={{display:"none"}}
        id="customer"
        name="customer"
        value={customer}
      />
      <InputText 
        id="ab_AD_NM"
        name="ab_AD_NM"
        readOnly
        onChange={(event) => onchangedata(event)}
        onFocus={() => setShowSearchIcon(true)}
        value={selectedcustmer}
        style={{backgroundColor:"#ffffff"}}
       
      />
        {showSearchIcon && (
      <i
        className="pi pi-search"
        style={{
          color: "var(--primary-color)",
          fontSize: "119%",
          marginTop: "4%",
          cursor: "pointer",
        }}
        onClick={openSecondPopup}
      ></i>
        )}
    </div>
  </div>
  
  <div className="field col" style={{marginLeft:"3%"}}>
    <label htmlFor="status" className="font-bold">
      Status
    </label>
    <Dropdown
    style={{width:"110%"}}
      id="Status"
      name="Status"
      value={Status}
      options={cc_LD_STOptions}
      onChange={(e) => onchangeDropDown("Status", e.value)}
      placeholder="Select Status"
    />
  </div>

  <div className="field col" style={{marginLeft:"3%"}}>
    <label htmlFor="status" className="font-bold">
    Cost Centre
    </label>
    <Dropdown
    style={{width:"110%"}}
      id="Status"
      name="Status"
      value={Status}
      options={ts_CO_CTOptions}
      onChange={(e) => onchangeDropDown("Status", e.value)}
      placeholder="Select Status"
    />
  </div>
</div>
);
const rightClear = (
<React.Fragment>
<Tooltip  target=".clear-button" position="bottom" mouseTrack>  
<div>Clear Filter</div>
</Tooltip>

<Button
style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
icon="pi pi-times"
className="mr-2 clear-button"
link
onClick={handleClearButton}
/>
</React.Fragment>
);
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
              <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4" 
          left={AdvanceSearch}
          right={rightClear}
        />
        </div>
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ts_SP_ID"] ? filters["ts_SP_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ts_SP_ID",
                        e.target.value,
                        filters["ts_SP_ID"]
                          ? filters["ts_SP_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ts_SP_DS"] ? filters["ts_SP_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ts_SP_DS",
                        e.target.value,
                        filters["ts_SP_DS"] ? filters["ts_SP_DS"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ts_TS_ID"] ? filters["ts_TS_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ts_TS_ID",
                        e.target.value,
                        filters["ts_TS_ID"]
                          ? filters["ts_TS_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>      
                
                <td>
                  <input
                    type="text"
                    value={filters["ts_TS_DS"] ? filters["ts_TS_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ts_TS_DS",
                        e.target.value,
                        filters["ts_TS_DS"]
                          ? filters["ts_TS_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ts_AL_MI"] ? filters["ts_AL_MI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ts_AL_MI",
                        e.target.value,
                        filters["ts_AL_MI"] ? filters["ts_AL_MI"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ts_PR_MI"] ? filters["ts_PR_MI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ts_PR_MI",
                        e.target.value,
                        filters["ts_PR_MI"]
                          ? filters["ts_PR_MI"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["ts_TR_VL"] ? filters["ts_TR_VL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ts_TR_VL",
                        e.target.value,
                        filters["ts_TR_VL"]
                          ? filters["ts_TR_VL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ts_PR_MX"] ? filters["ts_PR_MX"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ts_PR_MX",
                        e.target.value,
                        filters["ts_PR_MX"]
                          ? filters["ts_PR_MX"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ts_AL_MX"] ? filters["ts_AL_MX"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ts_AL_MX",
                        e.target.value,
                        filters["ts_AL_MX"]
                          ? filters["ts_AL_MX"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ts_TS_UM"] ? filters["ts_TS_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ts_TS_UM",
                        e.target.value,
                        filters["ts_TS_UM"]
                          ? filters["ts_TS_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ts_FR_DT"] ? filters["ts_FR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ts_FR_DT",
                        e.target.value,
                        filters["ts_FR_DT"]
                          ? filters["ts_FR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ts_TO_DT"] ? filters["ts_TO_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ts_TO_DT",
                        e.target.value,
                        filters["ts_TO_DT"]
                          ? filters["ts_TO_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["ts_CO_CT"] ? filters["ts_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ts_CO_CT",
                        e.target.value,
                        filters["ts_CO_CT"]
                          ? filters["ts_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Specification ID</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Test ID</th>  
                <th style={tableHeaderStyle}>Description</th>   
                <th style={tableHeaderStyle}>Allowed Minimum</th>  
                <th style={tableHeaderStyle}>Preferred Minimum</th> 
                <th style={tableHeaderStyle}>Target</th>  
                <th style={tableHeaderStyle}>Preferred Maximum</th>  
                <th style={tableHeaderStyle}>Allowed Maximum</th> 
                <th style={tableHeaderStyle}>UM</th>  
                <th style={tableHeaderStyle}>From Date</th>    
                <th style={tableHeaderStyle}>To Date</th>    
                <th style={tableHeaderStyle}>Cost Centre</th>               
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.tsUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.tsUkNo}
                        checked={selectedRows.includes(rowData.tsUkNo)}
                        onChange={() => handleRowSelect(rowData.tsUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ts_SP_ID}</td>
                    <td style={tableCellStyle}>{rowData.ts_SP_DS}</td>
                    <td style={tableCellStyle}>{rowData.ts_TS_ID}</td>   
                    <td style={tableCellStyle}>{rowData.ts_TS_DS}</td>
                    <td style={tableCellStyle}>{rowData.ts_AL_MI}</td>
                    <td style={tableCellStyle}>{rowData.ts_PR_MI}</td>   
                    <td style={tableCellStyle}>{rowData.ts_TR_VL}</td>
                    <td style={tableCellStyle}>{rowData.ts_PR_MX}</td>
                    <td style={tableCellStyle}>{rowData.ts_AL_MX}</td>    
                    <td style={tableCellStyle}>{rowData.ts_TS_UM}</td>    
                    <td style={tableCellStyle}>{rowData.ts_FR_DT}</td>    
                    <td style={tableCellStyle}>{rowData.ts_TO_DT}</td>    
                    <td style={tableCellStyle}>{rowData.ts_CO_CT}</td>             
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <SecondPopup
        visible={secondPopupVisible}
        onClose={() => setSecondPopupVisible(false)}
        onDataSelect={handleDataSelect}
        addressType={addressTypeC}
      />
      <SpecificationDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        SpecificationTableData={SpecificationTableData}
        setSpecificationTableData={setSpecificationTableData}
        sh_UK_NO={sh_UK_NO}
        setsh_UK_NO={setsh_UK_NO}
        sh_CO_CT={sh_CO_CT}
        setsh_CO_CT={setsh_CO_CT}
        sh_TS_ST={sh_TS_ST}
        setsh_TS_ST={setsh_TS_ST}
        ts_TS_UM={ts_TS_UM}
        setts_TS_UM={setts_TS_UM}
        tsUkNo={tsUkNo}
        settsUkNo={settsUkNo}
      />
    </div>
  );
};

export default WorkCentreMain;
