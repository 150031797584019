import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ShipmentReturnDialog from "./ShipmentReturnDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  sdOrNo:"",
  sdOrTy:"",
  sd_OR_CO:"",
  sdLnNo:"",
  sd_CU_NO:"",
  cus_NAME:"",
  sd_SH_TO:"",
  shipto_NAME :"",
  sd_IT_NM:"",
  item_DESC:"",
  sd_SH_NO:"",
  sd_LO_CN:"",
  sd_ST_CD:"",sd_UK_NO:"",sh_UK_NO:""
};
const initialFilters = {
  sdOrNo: { value: "", operation: "" },
  sdOrTy: { value: "", operation: "" },
  sd_OR_CO: { value: "", operation: "" },
  sdLnNo: { value: "", operation: "" },
  sd_CU_NO: { value: "", operation: "" },
  cus_NAME: { value: "", operation: "" },
  sd_SH_TO: { value: "", operation: "" },
  shipto_NAME : { value: "", operation: "" },
  sd_IT_NM: { value: "", operation: "" },
  item_DESC: { value: "", operation: "" },
  sd_SH_NO: { value: "", operation: "" },
  sd_LO_CN: { value: "", operation: "" },
  sd_ST_CD: { value: "", operation: "" },
};
const ShipmentReturnMain = () => {
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [selectedCustomer, setselectedCustomer ] = useState("");
  const [selectedShipto, setselectedShipto ] = useState("");
  const [ShipmentTableData, setShipmentTableData] = useState([{}]);
  const [sh_UK_NO, setsh_UK_NO] = useState("");
  const [sd_PK_NO, setsd_PK_NO] = useState("");
  const [sd_UK_NO, setsd_UK_NO] = useState("");
  const [sd_SH_NO, setsd_SH_NO] = useState("");
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,inputStyle1,
  } = ButtonStyles();
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;  
        if (column === 'sdOrNo') {
          constructedColumn = 'sdOrNo';
        } 
        else if (column === 'sdOrTy') {
          constructedColumn = 'sdOrTy';
        }
        else if (column === 'sdLnNo') {
          constructedColumn = 'sdLnNo';
        }
        else {
          constructedColumn = column.toUpperCase();
        }
        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;
    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const addRow = () => {
    setShipmentTableData([...ShipmentTableData, {}]);
  };
  const deleteRow = () => {
    if (ShipmentTableData.length > 0) {
      const updatedData = [...ShipmentTableData];
      updatedData.pop();
      setShipmentTableData(updatedData);
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]); 
  const handleDefault = async () => {

    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ShipmentReturn/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value,index=null) => {
    setFormData({ ...FormData, [name]: value });
    if(index!=null)
      ShipmentTableData[index][name]=value;
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }

    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ShipmentReturn/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ShipmentData = response.data;
        setShipmentTableData(ShipmentData.salesDetail);
        setFormData(ShipmentData.salesHeader);
        setsh_UK_NO(ShipmentData.salesHeader.shUkNo);
        setsd_PK_NO(ShipmentData.salesDetail[0].sd_PK_NO);
        setsd_SH_NO(ShipmentData.salesDetail[0].sd_SH_NO);
        setselectedCustomer(ShipmentData.salesHeader.cus_NAME);
        setselectedShipto(ShipmentData.salesHeader.shipto_NAME);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // confirmation
  const handleConfirm = () => {
    const table = document.getElementById("ShipmentTable");
    const totalRowCount = table.tBodies[0].rows.length;
    const salesDetail = [];
    for (let i = 0; i < totalRowCount; i++) {  
      const Details = {
        sd_UK_NO: document.getElementById("sd_UK_NO_" + i).value,
        sd_RT_QT: document.getElementById("sd_RT_QT_" + i).value,
      }
      salesDetail.push(Details);
    };
    const RequestRet = {
      "salesDetail":salesDetail
    }
      if (Request) {
        const confirm = window.confirm("Do you want to Confirm ?");
        const ConfirmUrl = `${domain}/api/ShipmentReturn/Add`;
        if (confirm) {
          axios.post(ConfirmUrl, RequestRet, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } 
  };
  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "red !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sdOrNo"] ? filters["sdOrNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sdOrNo",
                        e.target.value,
                        filters["sdOrNo"]
                          ? filters["sdOrNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sdOrTy"] ? filters["sdOrTy"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sdOrTy",
                        e.target.value,
                        filters["sdOrTy"] ? filters["sdOrTy"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_OR_CO"] ? filters["sd_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_OR_CO",
                        e.target.value,
                        filters["sd_OR_CO"]
                          ? filters["sd_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sdLnNo"] ? filters["sdLnNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sdLnNo",
                        e.target.value,
                        filters["sdLnNo"]
                          ? filters["sdLnNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_CU_NO"] ? filters["sd_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_CU_NO",
                        e.target.value,
                        filters["sd_CU_NO"]
                          ? filters["sd_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cus_NAME"] ? filters["cus_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cus_NAME",
                        e.target.value,
                        filters["cus_NAME"]
                          ? filters["cus_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_SH_TO"] ? filters["sd_SH_TO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_SH_TO",
                        e.target.value,
                        filters["sd_SH_TO"]
                          ? filters["sd_SH_TO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_IT_NM"] ? filters["sd_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_IT_NM",
                        e.target.value,
                        filters["sd_IT_NM"]
                          ? filters["sd_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["item_DESC"] ? filters["item_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "item_DESC",
                        e.target.value,
                        filters["item_DESC"]
                          ? filters["item_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_SH_QT"] ? filters["sd_SH_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_SH_QT",
                        e.target.value,
                        filters["sd_SH_QT"]
                          ? filters["sd_SH_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_TR_UM"] ? filters["sd_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_TR_UM",
                        e.target.value,
                        filters["sd_TR_UM"]
                          ? filters["sd_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_SH_NO"] ? filters["sd_SH_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_SH_NO",
                        e.target.value,
                        filters["sd_SH_NO"]
                          ? filters["sd_SH_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_PK_NO"] ? filters["sd_PK_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_PK_NO",
                        e.target.value,
                        filters["sd_PK_NO"]
                          ? filters["sd_PK_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
               
                <td>
                  <input
                    type="text"
                    value={filters["sd_DL_NO"] ? filters["sd_DL_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_DL_NO",
                        e.target.value,
                        filters["sd_DL_NO"]
                          ? filters["sd_DL_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
                <td>
                  <input
                    type="text"
                    value={filters["sd_LO_CN"] ? filters["sd_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_LO_CN",
                        e.target.value,
                        filters["sd_LO_CN"]
                          ? filters["sd_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_ST_CD"] ? filters["sd_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_ST_CD",
                        e.target.value,
                        filters["sd_ST_CD"]
                          ? filters["sd_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Line No</th>
                <th style={tableHeaderStyle}>Customer No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Ship To </th>
                <th style={tableHeaderStyle}>Item No</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Shipped Quantity</th>
                <th style={tableHeaderStyle}>UM </th>
                <th style={tableHeaderStyle}>Ship No</th>
                <th style={tableHeaderStyle}>Pick No </th>
                <th style={tableHeaderStyle}>Deliver No</th>
                <th style={tableHeaderStyle}>Location</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.sd_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.sd_UK_NO}
                        checked={selectedRows.includes(rowData.sd_UK_NO)}
                        onChange={() => handleRowSelect(rowData.sd_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.sdOrNo}</td>
                    <td style={tableCellStyle}>{rowData.sdOrTy}</td>
                    <td style={tableCellStyle}>{rowData.sd_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.sdLnNo}</td>
                    <td style={tableCellStyle}>{rowData.sd_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.cus_NAME}</td>
                    <td style={tableCellStyle}>{rowData.sd_SH_TO}</td>
                    <td style={tableCellStyle}>{rowData.sd_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.item_DESC}</td>
                    <td style={tableCellStyle}>{rowData.sd_SH_QT}</td>
                    <td style={tableCellStyle}>{rowData.sd_TR_UM}</td>
                    <td style={tableCellStyle}>{rowData.sd_SH_NO}</td>
                    <td style={tableCellStyle}>{rowData.sd_PK_NO}</td>
                    <td style={tableCellStyle}>{rowData.sd_DL_NO}</td>
                    <td style={tableCellStyle}>{rowData.sd_LO_CN}</td>
                    <td style={tableCellStyle}>{rowData.sd_ST_CD}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

      </div>

      <ShipmentReturnDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        selectedCustomer={selectedCustomer}
        setselectedCustomer={setselectedCustomer}
        selectedShipto={selectedShipto}
        setselectedShipto={setselectedShipto}
        addRow={addRow}
        deleteRow={deleteRow}
        ShipmentTableData={ShipmentTableData}
        setShipmentTableData={setShipmentTableData}
        handleConfirm={handleConfirm}
        sh_UK_NO={sh_UK_NO}
        setsh_UK_NO={setsh_UK_NO}
        sd_UK_NO={sd_UK_NO}
        setsd_UK_NO={setsd_UK_NO}
        sd_PK_NO={sd_PK_NO}
        sd_SH_NO={sd_SH_NO}
      />
    </div>
  );
};
export default ShipmentReturnMain;
