import React, { useState, useEffect ,useRef} from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import moment from "moment";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import ButtonStyles from "views/common/ButtonStyles";

export default function PreApproval({  isOpen, handleClose }) {
  const {borderbox,inboxstyle} = ButtonStyles();
  const intialvalues = {
    pa_DP_DT : moment(moment().toDate()).format("YYYYMMDD"),
    pa_DP_NM : "",
    pa_DP_FN : "",
    pa_CO_NO : "",
  };
  const [FormData, setFormData] = useState(intialvalues);
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  useEffect(() => {
    if (isOpen) {
      setFormData(intialvalues);
    }
  }, [isOpen]);
  
  const onchangedata = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
    }
  };
  const handleFormSubmit = () => {
    const request = {
      "preApproval":{
        "pa_DP_DT" : FormData.pa_DP_DT,
        "pa_DP_NM" : document.getElementById("pa_DP_NM").value,
        "pa_DP_FN" : document.getElementById("pa_DP_FN").value,
        "pa_CO_NO" : company
      }
    }
    axios.post(`${domain}/api/PreApproval/Add`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        handleResponse(resp.data);
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  };
  const productDialogFooter = (
    <React.Fragment>
    <Button 
      style={{width:"8%"}}
      icon="pi pi-check" 
      onClick={handleFormSubmit} 
      tooltip={'Save'}>
    </Button>
  </React.Fragment>
  );
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "pa_DP_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
return (
    <Dialog id="dialog-style"
      visible={isOpen}
      onHide={handleClose}
      style={{ width: "40rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={"Pre Approval"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
          <div style={inboxstyle} >
          <div className="formgrid grid">  
                <div className="field col">
                  <label htmlFor="pa_DP_NM" className="font-bold" style={{fontSize:'70%'}}>
                    Name 
                  </label>
                  <InputText 
                    id="pa_DP_NM"
                    name="pa_DP_NM"
                    value={FormData.pa_DP_NM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                </div>
                <div className="formgrid grid">  
                  <div className="field col">
                    <label htmlFor="pa_DP_FN" className="font-bold" style={{fontSize:'70%'}}>
                      Phone Number 
                    </label>
                    <InputText 
                      id="pa_DP_FN"
                      name="pa_DP_FN"
                      value={FormData.pa_DP_FN}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="pa_DP_DT" className="font-bold" style={{fontSize:'70%'}}>
                    Date 
                  </label>
                  <Calendar 
                    id="pa_DP_DT"
                    name="pa_DP_DT"
                    value={moment(FormData.pa_DP_DT, "YYYYMMDD").toDate()}
                    onChange={handleDateChange}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                </div>
                
            </div>
      </div>

    </Dialog>
 
 );
  }