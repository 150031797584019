import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import EquipmentPopup from "../Popups/EquipmentPopup";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
export default function ServiceBookingDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  sb_TK_NO,
  sb_TK_TY,
  sb_DO_CO,
  sb_CO_CT,
  sb_SR_TY,
  sb_ST_CD,
  setsb_CO_CT,
  setsb_SR_TY,
  setsb_ST_CD,
  SelectedCustomer,
  setSelectedCustomer,
  SelectedVehicle,
  setSelectedVehicle,
  onchangeDropDownFormData3,
  onchangeDropDownFormData1
}) {
  const {
    sbUkNo,
    sb_CU_NO,
    sb_VH_NO,
    sb_CO_NO,
    sb_EM_ID,
    sb_AP_DT,
    sb_RM_RK,

  } = data;
  const {borderbox,inboxstyle} = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter,fetchCostCenterP } = Costcenter();
  const [sb_CO_CTOptions, setsb_CO_CTOptions] = useState([]);
  const [sb_SR_TYOptions, setsb_SR_TYOptions] = useState([]);
  const [sb_ST_CDOptions, setsb_ST_CDOptions] = useState([]);

// Service Type
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsb_SR_TY(value);
  };

  useEffect(() => {
    fetchDropdownBR();
    fetchDropdownSR();
    fetchDropdownST();
  }, []);

  const fetchDropdownBR = async () => {
    const Options = await fetchCostCenter();
    setsb_CO_CTOptions(Options);
  };

  const fetchDropdownSR = async () => {
    const Options = await fetchClassCodeDS("SE/TY");
    setsb_SR_TYOptions(Options);
  };

  const fetchDropdownST = async () => {
    const Options = await fetchClassCodeDS("PR/ST");
    setsb_ST_CDOptions(Options);
  };
// advance search
const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
const [VehiclePopupVisible, setVehiclePopupVisible] = useState(false);

const [showSearchIcon, setShowSearchIcon] = useState("");
const [addressCustomer, setaddressCustomer] = useState("C");

const openCustomerPopup = () => {
  setCustomerPopupVisible(true);
};
const openVehiclePopup = () => {
  setVehiclePopupVisible(true);
};
const handleSelectCustomer = (data) => {
  setSelectedCustomer(data.ab_AD_NM);
  onchangeFormData("sb_CU_NO", data.abAdNo);
  setCustomerPopupVisible(false);
  setShowSearchIcon(false);
};
const handleSelectVehicle = (data) => {
  setSelectedVehicle(data.em_EQ_DS);
  onchangeFormData("sb_VH_NO", data.em_EQ_NM);
  setVehiclePopupVisible(false);
  setShowSearchIcon(false);
};

const isUpdateMode = Boolean(sbUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  // date default
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "sb_AP_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={sbUkNo ? "Revise Service Booking" : "Add Service Booking"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
        <div
            style={{
              marginTop: "2%",
              border: "1px solid #e8e8e8",
              padding: "16px",
              borderRadius: "15px",
              backgroundColor: "aliceblue",
              overflow: "auto",
            }}
          >
            <div className="formgrid grid">
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="sb_TK_NO" className="font-bold">
                  Token No
                </label>
                <InputText disabled
                  id="sb_TK_NO"
                  name="sb_TK_NO"
                  value={sb_TK_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="sb_TK_TY" className="font-bold">
                  Type
                </label>
                <InputText disabled
                  id="sb_TK_TY"
                  name="sb_TK_TY"
                  value={sb_TK_TY}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="sb_DO_CO" className="font-bold">
                  Company
                </label>
                <InputText disabled
                  id="sb_DO_CO"
                  name="sb_DO_CO"
                  value={sb_DO_CO}
                />
              </div>

              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="sb_CU_NO" className="font-bold">
                  Customer <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{display:"flex"}}>
                <InputText 
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedCustomer}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openCustomerPopup}
                ></i>
                  )}
                </div>
                <InputText style={{display:"none"}}
                    id="sb_CU_NO"
                    name="sb_CU_NO"
                    value={sb_CU_NO}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
              
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="sb_VH_NO" className="font-bold">
                Vehicle <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{display:"flex"}}>
                <InputText 
                  id="im_TT_DS"
                  name="im_TT_DS"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedVehicle}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openVehiclePopup}
                ></i>
                  )}
                  </div>
                  <InputText  style={{display:"none"}}
                    id="sb_VH_NO"
                    name="sb_VH_NO"
                    value={sb_VH_NO}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>

              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="sb_CO_NO" className="font-bold">
                  Contact No
                </label>
                <InputText 
                  id="sb_CO_NO"
                  name="sb_CO_NO"
                  value={sb_CO_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="sb_EM_ID" className="font-bold">
                  Email ID
                </label>
                <InputText 
                  id="sb_EM_ID"
                  name="sb_EM_ID"
                  value={sb_EM_ID}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="sb_CO_CT" className="font-bold">
                  Branch
                </label>
                <Dropdown
                id="sb_CO_CT"
                name="sb_CO_CT"
                value={sb_CO_CT}
                options={sb_CO_CTOptions}
                onChange={(e) => onchangeDropDownFormData1("sb_CO_CT", e.value)}
                placeholder="Select Branch"
              />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="sb_AP_DT" className="font-bold">
                  Appointment Date
                </label>
                <Calendar
                  id="sb_AP_DT"
                  name="sb_AP_DT"
                  value={moment(sb_AP_DT, "YYYYMMDD").toDate()}
                  onChange={handleDateChange}
                  dateFormat="dd/mm/yy"
                />
              </div>

              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="sb_SR_TY" className="font-bold">
                  Service Type
                </label>
                <Dropdown
                    id="sb_SR_TY"
                    name="sb_SR_TY"
                    value={sb_SR_TY}
                    options={sb_SR_TYOptions}
                    onChange={(e) => onchangeDropDownFormData2("sb_SR_TY", e.value)}
                    placeholder="Select Service Type"
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="sb_ST_CD" className="font-bold">
                  Status
                </label>
                <Dropdown
                    id="sb_ST_CD"
                    name="sb_ST_CD"
                    value={sb_ST_CD}
                    options={sb_ST_CDOptions}
                    onChange={(e) => onchangeDropDownFormData3("sb_ST_CD", e.value)}
                    placeholder="Select Status"
                />
              </div>
            </div>        
        </div>

        <div style={inboxstyle} >
            <div className="formgrid grid">
              <label htmlFor="sb_RM_RK" className="font-bold">
                Remarks
              </label>
              <textarea
                id="sb_RM_RK"
                name="sb_RM_RK"
                defaultValue={sb_RM_RK}
                onChange={(e) => onchangedata(e)}
                style={{ width: "100%" }}
              />
            </div>
          </div>
      </div>

       {/* customer */}
       <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleSelectCustomer}
        formData={data}
        addressType={addressCustomer}
      />

        {/* vEHICLE */}
        <EquipmentPopup
          visible={VehiclePopupVisible}
          onClose={() => setVehiclePopupVisible(false)}
          onDataSelect={handleSelectVehicle}
          formData={data}
        />
    </Dialog>
  );
}
