import React, { useState, useEffect, useRef} from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { faLink, faFileImage, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { } from '@fortawesome/free-solid-svg-icons';

const CallPopup = ({ visible, onClose, av_CC_NO,av_TO_CL ,av_FR_CL}) => {

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [ShowCallPopup, setShowCallPopup] = useState(false);
  const [CallData, setCallData] = useState([]);  
  // const [CallData, setCallData] = useState({
  //   av_CC_NO: "",
  //   av_TO_CL: av_TO_CL || "",
  //   av_FR_CL: av_FR_CL || "",
  //   av_TE_XT: "",
  //   avUkNo: ""
  // });
  const toast = useRef(null);
  const [avUkNo, setavUkNo ] = useState([]);
  var flag = 0;
  const {
    ccCnNo,
    cc_UK_NO,
    av_TE_XT,
  } = CallData;

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      if(flag === 1){
        handleClose();
      }
      
    }
  };

  const handleClose = () => {
    onClose();
    setShowCallPopup(false);
  };
  useEffect(() => {
    setCallData(prevNoteData => ({ ...prevNoteData,  av_TE_XT: ''   }));
    const modal = document.getElementById("callModal");
    if (visible) {
      modal.classList.add("show");
      modal.style.display = "block";
      setCallData(prevState => ({
        ...prevState,
        av_TE_XT: null
      }));
    } else {
      modal.classList.remove("show");
      modal.style.display = "none";
    }
  }, [visible]);

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setCallData({ ...CallData, [name]: value });
  };

  const StartCall = async () => {
      const addInput = {
        "av_CC_NO" : av_CC_NO,
        "av_FR_CL" : av_FR_CL,
        "av_TO_CL" : av_TO_CL,
        "av_TE_XT" : document.getElementById("av_TE_XT_CALL").value
      }
      try {
        const response = await axios.post(
          `${domain}/api/Calling/MakeCall`,addInput,
          {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });

      const data = response.data;
      if(data){
        setCallData(data);
        setavUkNo(response.data.result.avUkNo);
        flag = 0;
        handleResponse(data); 
      }
    }
    catch (error) {
        console.error("Error fetching data:", error);
      }
  };

  const EndCall = async () => {
    const endCallInput = {
      "avUkNo" : avUkNo
    }
    try {
      const response = await axios.post(
        `${domain}/api/Calling/DownloadRecording`,endCallInput,
        {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

    const data = response.data;
    if(data){
      setCallData(data);
      flag = 1;
      handleResponse(data); 
    }
  }
  catch (error) {
      console.error("Error fetching data:", error);
    }
  };

const isUpdateMode = Boolean(cc_UK_NO);
const productDialogFooter = (
  <React.Fragment>
  <Button 
    style={{width:"20%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={EndCall}
  >
  </Button>
  <Button   
    style={{width:"20%"}}
    icon="pi pi-check" 
    onClick={StartCall} 
    tooltip={isUpdateMode ? "EndCall" : "StartCall"}>
  </Button>
  </React.Fragment>
  );
    
  return (
    <div
      className="modal fade"
      id="callModal"
      tabIndex="-1"
      aria-labelledby="noteModalLabel"
      aria-hidden="true"
      header={cc_UK_NO ? "Note" : "Note"}
      footer={productDialogFooter}
    >
   <div>
      <Toast ref={toast} />
        <div className="modal-dialog">
          <div className="modal-content" style={{backgroundColor:"#f9fbff"}}>
            <div className="modal-header" style={{backgroundColor:"aliceblue"}}>
              <h5 className="modal-title" id="noteModalLabel">
               Call
              </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
      </div>
      <div className="modal-body" style={{padding:"25px"}}>
      <div className="field row" style={{display:"none"}} >
      <label htmlFor="av_CC_NO" className="font-bold">
      Contact No
      </label>
        <InputText
          id="av_CC_NO"
          name="av_CC_NO"
          value={av_CC_NO}
          onChange={(Event) => onchangedata(Event)}
        />
        </div>
        <div className="field row" style={{display:"none"}}>
          <label htmlFor="av_FR_CL" className="font-bold">
          From
          </label>
          <InputText
              id="av_FR_CL"
              name="av_FR_CL"
              value={av_FR_CL}
              readOnly
            />
        </div>
        <div className="field row" >
          <label htmlFor="av_TO_CL" className="font-bold">
         To
          </label>
          <InputText disabled
              id="av_TO_CL"
              name="av_TO_CL"
              value={av_TO_CL}
              onChange={(Event) => onchangedata(Event)}
            />
        </div>
        <div className="field row" >
          <label htmlFor="av_TE_XT" className="font-bold">
         Text
          </label>
          <textarea 
            style={{ marginBottom: '10px',width:"100%",height:"150px" }}
              id="av_TE_XT_CALL"
              name="av_TE_XT"
              value={av_TE_XT}
              onChange={(Event) => onchangedata(Event)}
            />
        </div>
        <div className="field row"
        style={{display:"none"}}
        >
          <label htmlFor="avUkNo" className="font-bold">
         UKno
          </label>
          <InputText
              id="avUkNo"
              name="avUkNo"
              value={avUkNo}
              onChange={(Event) => onchangedata(Event)}
            />
        </div>
        </div>
        <div className="modal-footer">
            <Button
              icon="pi pi-times"
              variant="outlined"
              onClick={EndCall}
              tooltip="End"
            />
            <Button
              icon="pi pi-check"
              onClick={StartCall}
              tooltip="Start"
            />
          </div>
      </div>
    </div>
  </div>
</div>
  );
};

export default CallPopup;
