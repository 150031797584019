import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import Pagination from "views/common/Pagination";
import ButtonStyles from "views/common/ButtonStyles";

const initialFilters = {
    am_CO_NO: { value: "", operation: "" },
    account: { value: "", operation: "" },
    am_AC_ID: { value: "", operation: "" },
    am_AC_DS: { value: "", operation: "" },
    am_OB_AC: { value: "", operation: "" },
    am_OB_SB: { value: "", operation: "" },
    am_CO_NM: { value: "", operation: "" },
    am_AC_LD: { value: "", operation: "" },
    am_PE_CD: { value: "", operation: "" },
    am_CR_CD: { value: "", operation: "" },
};

const AssetPopup = ({ visible, onClose, onDataSelect,AelmId }) => {
// buttonstyles
const {formatDate,  checkboxStyle,tableHeaderStyle, inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [open, setOpen] = useState(false);
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(visible);
    handleSearch();
    setCurrentPage(0);
  }, [currentPage, pageSize, filters, visible]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const Url = `${domain}/api/FixedAssetMaster/List/${currentPage}/${pageSize}`;
    const parameter = "BA";
    const paginationUrl = `${Url}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{width:"65rem"}}
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
            <tr>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_NO"] ? filters["fm_AT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_NO",
                        e.target.value,
                        filters["fm_AT_NO"]
                          ? filters["fm_AT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_D1"] ? filters["fm_AT_D1"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_D1",
                        e.target.value,
                        filters["fm_AT_D1"] ? filters["fm_AT_D1"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["asset_COST"] ? filters["asset_COST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "asset_COST",
                        e.target.value,
                        filters["asset_COST"] ? filters["asset_COST"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["acc_DEPR"] ? filters["acc_DEPR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "acc_DEPR",
                        e.target.value,
                        filters["acc_DEPR"]
                          ? filters["acc_DEPR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["net_VAL"] ? filters["net_VAL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "net_VAL",
                        e.target.value,
                        filters["net_VAL"]
                          ? filters["net_VAL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ytd_DEPRECIATION"] ? filters["ytd_DEPRECIATION"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ytd_DEPRECIATION",
                        e.target.value,
                        filters["ytd_DEPRECIATION"]
                          ? filters["ytd_DEPRECIATION"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cur_PRD"] ? filters["cur_PRD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cur_PRD",
                        e.target.value,
                        filters["cur_PRD"]
                          ? filters["cur_PRD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_DP_MT"] ? filters["fm_DP_MT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_DP_MT",
                        e.target.value,
                        filters["fm_DP_MT"]
                          ? filters["fm_DP_MT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_LF"] ? filters["fm_AT_LF"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_LF",
                        e.target.value,
                        filters["fm_AT_LF"]
                          ? filters["fm_AT_LF"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />   
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["fm_DP_SD"] ? filters["fm_DP_SD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_DP_SD",
                        e.target.value,
                        filters["fm_DP_SD"]
                          ? filters["fm_DP_SD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                {/* <td>
                  <input
                    type="text"
                    value={filters["fm_RS_VL"] ? filters["fm_RS_VL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_RS_VL",
                        e.target.value,
                        filters["fm_RS_VL"]
                          ? filters["fm_RS_VL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> */}
              </tr>
              <tr>
              <th style={tableHeaderStyle}>Asset No </th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Asset Cost</th>
                <th style={tableHeaderStyle}>Acc. Depreciation</th>
                <th style={tableHeaderStyle}>Net Book Value</th>
                <th style={tableHeaderStyle}>YTD Depreciation</th>
                <th style={tableHeaderStyle}>Current Period</th>
                <th style={tableHeaderStyle}>Depreciation Method</th>
                <th style={tableHeaderStyle}>Life in Months</th>
                <th style={tableHeaderStyle}>Depreciation Start Date</th>
                {/* <th style={tableHeaderStyle}>Residual Value</th> */}
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                key={rowData.fmUkNo}
                onClick={() => onDataSelect(rowData)}
                style={{
                  backgroundColor:
                    index % 2 === 0 ? "aliceblue" : "#ffffff",
                }}
                className="table-row"
              >
                    <td style={tableCellStyle}>{rowData.fm_AT_NO}</td>
                    <td style={tableCellStyle}>{rowData.fm_AT_D1}</td>
                    <td style={tableCellStyle}>{rowData.asset_COST}</td>
                    <td style={tableCellStyle}>{rowData.acc_DEPR}</td>
                    <td style={tableCellStyle}>{rowData.net_VAL}</td>
                    <td style={tableCellStyle}>{rowData.ytd_DEPRECIATION}</td>
                    <td style={tableCellStyle}>{rowData.cur_PRD}</td>
                    <td style={tableCellStyle}>{rowData.fm_DP_MT}</td>
                    <td style={tableCellStyle}>{rowData.fm_AT_LF}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.fm_DP_SD)}</td>
                    {/* <td style={tableCellStyle}>{rowData.fm_RS_VL}</td>  */}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
        />      
      </div>
    </Dialog>
  );
};

AssetPopup.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDataSelect: PropTypes.func,
};

export default AssetPopup;
