import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
export default function ImportLeadDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangeFormData, 
  addRow,
  deleteRow,
  ImportLeadtable,
  li_DO_TY,
  li_DO_CO,
  li_DO_NO,

}) {
  const { 
    li_TR_DT,
    liUkNo,  
  } = data;
 

const onchangedata = (e) => {
  const { name, value } = e.target;
    onchangeFormData(name, value);
};

const [selectedDate, setSelectedDate] = useState(moment().toDate());
const handleDateChange = (e) => {
  setSelectedDate(e.value);
  onchangedata({ target: { name: "li_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
};

const isUpdateMode = Boolean(liUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={(e)=>handleFormSubmit("")} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
 

    {isUpdateMode && ( 
      <Button 
        style={{width:"5%"}}
        icon="pi pi-circle" 
        onClick={(e)=>handleFormSubmit("process")} 
        tooltip="Process"
      />
    )}
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={{ width: "75rem" }}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={liUkNo ? "Revise Import Lead" : "Add Import Lead"}
    modal
    className="p-fluid"
    footer={productDialogFooter}
  >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
         <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="li_DO_NO" className="font-bold">
             Doc No
              </label>
              <div style={{display:"flex"}}>
              <InputText
                id="li_DO_NO"
                name="li_DO_NO"
                value={li_DO_NO}
                disabled
              />   
            </div>
            </div>
            <div className="field col">
              <label htmlFor="li_DO_TY" className="font-bold">
             Type
              </label>
              <InputText
                id="li_DO_TY"
                name="li_DO_TY"
                value={li_DO_TY}
               disabled
              />
            </div>
            <div className="field col">
              <label htmlFor="li_DO_CO" className="font-bold">
             Company
              </label>
              <InputText
                id="li_DO_CO"
                name="li_DO_CO"
                value={li_DO_CO}
                disabled
              />
            </div>
            <div className="field col">
              <label htmlFor="li_TR_DT" className="font-bold">
             Date
              </label>
              {/* <Calendar
                id="li_TR_DT"
                name="li_TR_DT"
                value={moment(li_TR_DT, "YYYYMMDD").toDate()}
                onChange={(e) => onchangedata({ target: { name: "li_TR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                showIcon
                dateFormat="dd/mm/yy"
              /> */}
              <Calendar
                id="li_TR_DT"
                name="li_TR_DT"
                value={moment(li_TR_DT, "YYYYMMDD").toDate()}
                onChange={handleDateChange}
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            </div>
      </div>
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
          }}
        >
          {!isUpdateMode && (
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          )}
           {!isUpdateMode && (
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
          )}
        </div>
        <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
            position: "relative",
          }}
        >
        <table className="custom-table" id="ImportLeadtable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Line No</th>
          <th>Source</th>
          <th>Customer</th>
          <th>Address</th>
          <th>Phone</th>
          <th>E-Mail</th>          
          <th>WebSite</th>
          <th>Industry</th>
          <th>Verticals</th>
          <th>City</th>
          <th>Country</th>          
          <th>Contact Person</th>
         
          <th>Title</th> 
          <th>Email</th> 
          <th>Phone</th> 
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
      {ImportLeadtable.map((row, index) => (
              <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText disabled
              id={`liUkNo_${index}`}
              name={`liUkNo_${index}`}
              // defaultValue={row.liUkNo}
              value={index + 1}
            />
          </td>
          <td>
          <InputText style={{width:"90px"}}
            id={`li_LN_NO_${index}`}
            name={`li_LN_NO_${index}`}
            defaultValue={row.li_LN_NO}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          
          <td>
          <InputText style={{width:"90px"}}
            id={`li_LD_SC_${index}`}
            name={`li_LD_SC_${index}`}
            defaultValue={row.li_LD_SC}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          <td>
          <InputText style={{width:"150px"}}
            id={`li_CU_NM_${index}`}
            name={`li_CU_NM_${index}`}
            defaultValue={row.li_CU_NM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"110px"}}
            id={`li_AD_L1_${index}`}
            name={`li_AD_L1_${index}`}
            defaultValue={row.li_AD_L1}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          
          <td>
          <InputText style={{width:"90px"}}
            id={`li_PH_NO_${index}`}
            name={`li_PH_NO_${index}`}
            defaultValue={row.li_PH_NO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"160px"}}
            id={`li_EM_ID_${index}`}
            name={`li_EM_ID_${index}`}
            defaultValue={row.li_EM_ID}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"120px"}}
            id={`li_WB_SI_${index}`}
            name={`li_WB_SI_${index}`}
            defaultValue={row.li_WB_SI}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"90px"}}
            id={`li_IN_RY_${index}`}
            name={`li_IN_RY_${index}`}
            defaultValue={row.li_IN_RY}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"90px"}}
            id={`in_VE_CL_${index}`}
            name={`in_VE_CL_${index}`}
            defaultValue={row.in_VE_CL}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"90px"}}
            id={`in_CI_TY_${index}`}
            name={`in_CI_TY_${index}`}
            defaultValue={row.in_CI_TY}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"90px"}}
            id={`in_CN_RY_${index}`}
            name={`in_CN_RY_${index}`}
            defaultValue={row.in_CN_RY}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"120px"}}
            id={`in_CO_PE_${index}`}
            name={`in_CO_PE_${index}`}
            defaultValue={row.in_CO_PE}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"110px"}}
            id={`li_TI_TL_${index}`}
            name={`li_TI_TL_${index}`}
            defaultValue={row.li_TI_TL}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"90px"}}
            id={`li_CO_EM_${index}`}
            name={`li_CO_EM_${index}`}
            defaultValue={row.li_CO_EM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"90px"}}
            id={`li_CO_PH_${index}`}
            name={`li_CO_PH_${index}`}
            defaultValue={row.li_CO_PH}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"90px"}}
                id={`in_ST_AT_${index}`}
                name={`in_ST_AT_${index}`}
                defaultValue={row.in_ST_AT}
                onChange={(e) => onchangedata(e)}
                disabled
              />
          </td>
         
          </tr>
           ))}
      </tbody>
    </table>   
    
    </div>
  </div>   
  
  
    </Dialog>
  );

      }



