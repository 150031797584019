import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import ButtonStyles from "views/common/ButtonStyles";
const initialFilters = {
    is_IT_NM    :   { value: "", operation: "" },
    itemDES     :   { value: "", operation: "" },
    is_CO_CT    :   { value: "", operation: "" },
    is_LO_CN    :   { value: "", operation: "" },
    is_LT_NO    :   { value: "", operation: "" },
    is_PR_UM    :   { value: "", operation: "" },
    is_SC_QT    :   { value: "", operation: "" },
    is_SC_CM    :   { value: "", operation: "" },
    is_AV_QT    :   { value: "", operation: "" },
};

const StockDetailsPopup = ({ visible, onClose, onDataSelect, ItemlmId,
  sd_LO_CN,sd_IT_NM,sd_CO_CT,onConfirm,sd_UK_NO,
}) => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,PopupDailogStyle,
  } = ButtonStyles();

  const [tableData, setTableData] = useState(null);
  const [filters, setFilters] = useState(initialFilters);
  const [open, setOpen] = useState(false);
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleResponse = (data) => {
    // Assuming `errors` is the correct field for error messages
    const { warnings = [], errors = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errors.length > 0) {
      const errorMessages = errors.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errors.length === 0) {
      //FetchStockDetails(sd_LO_CN, sd_IT_NM, sd_CO_CT );
    }
  };
  
  const handleClose = () => {
    onClose();
    setOpen(false);
  };
  useEffect(() => {
    if (visible) {
      setOpen(true);
      FetchStockDetails(sd_LO_CN, sd_IT_NM, sd_CO_CT );
    } else {
      setOpen(false);
    }
  }, [visible, sd_LO_CN, sd_IT_NM, sd_CO_CT ]);

  // useEffect(() => {
  //   FetchStockDetails(sd_LO_CN, sd_IT_NM, sd_CO_CT );
  // }, [sd_LO_CN, sd_IT_NM, sd_CO_CT]);

  const FetchStockDetails = async (sd_LO_CN, sd_IT_NM, sd_CO_CT) => {
    setTableData(null);
    const StockRequest = {
      "is_LO_CN" : sd_LO_CN,
      "is_IT_NM" : sd_IT_NM,
      "is_CO_CT" : sd_CO_CT,
      "isUkNo" : sd_UK_NO
  }
    const paginationUrl = `${domain}/api/StockAvailability/GetStockAvailable`;
    try {
      const response = await axios.post(paginationUrl, StockRequest, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data;
    
      if (data && data.Result && Array.isArray(data.Result)) {
        setTableData(data.Result);
        console.log("stockapi" + data.Result);
        handleResponse(response.data);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [userInput, setUserInput] = useState("");

  const handleInputChange = (index, value) => {
    setUserInput((prev) => ({ ...prev, [index]: value }));
  };

  const handleConfirm = () => {
    const updatedData = tableData.map((rowData, index) => ({
      ...rowData,
      allocatedQty: userInput[index] || "",
    }));
    
    onConfirm(updatedData); // Call the callback with the updated data
    handleClose();
  };
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{"Stock Details"}</h5>
      <div style={{width:'60rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={{ width: "5%", marginRight: "0.5rem",border:"none",backgroundColor:"transparent",color:"blue", }}
        icon="pi pi-check-circle" 
        onClick={handleConfirm}
        tooltip="Confirm Pick slip"
      />
      </div>
    </div>
  );
  
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={PopupDailogStyle}
      header={dialogHeader}      
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse", width: "100%",  marginTop: "20px",  }}  >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    value={filters["is_IT_NM"] ? filters["is_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_IT_NM",
                        e.target.value,
                        filters["is_IT_NM"]
                          ? filters["is_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["itemDES"] ? filters["itemDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "itemDES",
                        e.target.value,
                        filters["itemDES"] ? filters["itemDES"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["is_CO_CT"] ? filters["is_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_CO_CT",
                        e.target.value,
                        filters["is_CO_CT"]
                          ? filters["is_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["is_LO_CN"] ? filters["is_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_LO_CN",
                        e.target.value,
                        filters["is_LO_CN"]
                          ? filters["is_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["is_LT_NO"] ? filters["is_LT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_LT_NO",
                        e.target.value,
                        filters["is_LT_NO"]
                          ? filters["is_LT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["is_PR_UM"] ? filters["is_PR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_PR_UM",
                        e.target.value,
                        filters["is_PR_UM"]
                          ? filters["is_PR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["is_SC_QT"] ? filters["is_SC_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_SC_QT",
                        e.target.value,
                        filters["is_SC_QT"] ? filters["is_SC_QT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["is_SC_CM"] ? filters["is_SC_CM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_SC_CM",
                        e.target.value,
                        filters["is_SC_CM"] ? filters["is_SC_CM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["is_AV_QT"] ? filters["is_AV_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_AV_QT",
                        e.target.value,
                        filters["is_AV_QT"] ? filters["is_AV_QT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["Al_Qty"] ? filters["Al_Qty"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "Al_Qty",
                        e.target.value,
                        filters["Al_Qty"] ? filters["Al_Qty"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["isUkNo"] ? filters["isUkNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "isUkNo",
                        e.target.value,
                        filters["isUkNo"] ? filters["isUkNo"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
               <th style={tableHeaderStyle}>Item No</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Location</th>
                <th style={tableHeaderStyle}>Lot No</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Sec Qty</th>
                <th style={tableHeaderStyle}>Sec UM</th>
                <th style={tableHeaderStyle}>Available Qty</th>
                <th style={tableHeaderStyle}>Allocated Qty</th>
                <th style={{display:"none"}}>uk_no</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                      key={rowData.isUkNo}
                      // onClick={() => onDataSelect(rowData,ItemlmId)}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "aliceblue" : "#ffffff",
                      }}
                      className="table-row"
                    >
                    <td style={tableCellStyle}>{rowData.is_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.itemDES}</td>
                    <td style={tableCellStyle}>{rowData.is_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.is_LO_CN}</td>
                    <td style={tableCellStyle}>{rowData.is_LT_NO}</td> 
                    <td style={tableCellStyle}>{rowData.is_PR_UM}</td>
                    <td style={tableCellStyle}>{rowData.is_SC_QT}</td>
                    <td style={tableCellStyle}>{rowData.is_SC_CM}</td> 
                    <td style={tableCellStyle}>{rowData.is_AV_QT}</td> 
                    <td style={tableCellStyle}> 
                     {/* <input
                        type="text"
                        value={userInput[index] || ""}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                      /> */}
                       <InputText
                        id="Alloc_Qty"
                        name="Alloc_Qty"
                        value={userInput[index] || ""}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                      />
                    </td> 
                    <td style={{display:"none"}}>{rowData.isUkNo}</td> 
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>     
      </div>
    </Dialog>
  );
};

StockDetailsPopup.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  sd_LO_CN: PropTypes.string.isRequired,
  sd_IT_NM: PropTypes.string.isRequired,
  sd_CO_CT: PropTypes.string.isRequired,
};

export default StockDetailsPopup;
