import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import SalesReturnDialog from "./SalesReturnDialog";
import PropTypes from 'prop-types';
const SalesReturnEnquiry = ({SalesEnquiryVisible,CloseEnquiry}) => {

const initialValue = {
	  sd_CU_NO:"",
    cus_NAME:"",
    sd_OR_NO:"",
    sd_OR_TY:"",
    sd_OR_CO:"",
    sdLnNo:"",
    sd_IT_NM:"",
    item_DESC:"",
    sd_OR_QT:"",
    sd_SH_QT:"",
    sd_TR_UM:"",
    sd_UT_PR:"",
    sd_EX_PR:"",
    sd_DS_AT:"",
    sd_TX_PE:"",
    sd_TX_AM:"",
    sd_EX_CT:"",
    sd_ST_CD:"",
    sd_DO_NO:"",
    sd_DO_TY:"",
    sd_IN_DT:"",
    sd_SH_TO:"",
};
const initialFilters = {
    sd_CU_NO: { value: "", operation: "" },
    cus_NAME: { value: "", operation: "" },
    sd_OR_NO: { value: "", operation: "" },
    sd_OR_TY: { value: "", operation: "" },
    sd_OR_CO: { value: "", operation: "" },
    sdLnNo: { value: "", operation: "" },
    sd_IT_NM: { value: "", operation: "" },
    item_DESC: { value: "", operation: "" },
    sd_OR_QT: { value: "", operation: "" },
    sd_SH_QT: { value: "", operation: "" },
    sd_TR_UM: { value: "", operation: "" },
    sd_UT_PR: { value: "", operation: "" },
    sd_EX_PR: { value: "", operation: "" },
    sd_DS_AT: { value: "", operation: "" },
    sd_TX_PE: { value: "", operation: "" },
    sd_TX_AM: { value: "", operation: "" },
    sd_EX_CT: { value: "", operation: "" },
    sd_ST_CD: { value: "", operation: "" },
    sd_DO_NO: { value: "", operation: "" },
    sd_DO_TY: { value: "", operation: "" },
    sd_IN_DT: { value: "", operation: "" },
    sd_SH_TO: { value: "", operation: "" },
};
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [selectedCustomer, setselectedCustomer ] = useState("");
  const [selectedShipto, setselectedShipto ] = useState("");
  const [sh_OR_TY, setsh_OR_TY] = useState("");
  const [sh_OR_CO, setsh_OR_CO] = useState("");
  const [sh_UK_NO, setsh_UK_NO]= useState("");
  const [sh_OR_NO, setsh_OR_NO] = useState("");
  const [SalesTableData, setSalesTableData] = useState([{}]);
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;  
        if (column === 'sdOrNo') {
          constructedColumn = 'sdOrNo';
        } 
        else if (column === 'sdOrTy') {
          constructedColumn = 'sdOrTy';
        }
        else if (column === 'sdLnNo') {
          constructedColumn = 'sdLnNo';
        }
        else {
          constructedColumn = column.toUpperCase();
        }
        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;
    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const addRow = () => {
    setSalesTableData([...SalesTableData, {}]);
  };
  const deleteRow = () => {
    if (SalesTableData.length > 0) {
      const updatedData = [...SalesTableData];
      updatedData.pop();
      setSalesTableData(updatedData);
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]); 
  const handleDefault = async () => {
    setsh_OR_CO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "SH_OR_TY"){
              setsh_OR_TY(dataDefault.versionIds[i].default_VALUE);
            }

          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/SalesReturn/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value,index=null) => {
    setFormData({ ...FormData, [name]: value });
    if(index!=null)
      SalesTableData[index][name]=value;
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }

    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  var requestBodyInput = {};
  function values () {
   const table = document.getElementById("SalesReturnTable");
    const totalRowCount = table.tBodies[0].rows.length;
    const salesDetail = [];
    for (let i = 0; i < totalRowCount; i++) { 
      const Details = { 
      sd_UK_NO: document.getElementById("sd_UK_NO_" + i).value,
      sd_OR_QT: document.getElementById("sd_OR_QT_" + i).value,
    }
    salesDetail.push(Details);
  }
  
  requestBodyInput = {
      "salesHeader":{
        sh_OR_DT: document.getElementById("sh_OR_DT").value,
        sh_OR_NO: document.getElementById("sh_OR_NO").value,
        sh_OR_TY: document.getElementById("sh_OR_TY").value,
        sh_OR_CO: document.getElementById("sh_OR_CO").value,
        sh_IN_NO: document.getElementById("sh_IN_NO").value,
        sh_IN_TY: document.getElementById("sh_IN_TY").value,
        sh_IN_CO: document.getElementById("sh_IN_CO").value,
      },
      "salesDetail":salesDetail
    }

  };
  const handleFormSubmit = () => {
    values();
    if (document.getElementById("sh_OR_NO").value) {
      } else {
        const OR_DT = FormData.sh_OR_DT;
        if(OR_DT){
          const currentYear = OR_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${sh_OR_TY}/${secondHalf}/${company}/${firstHalf}`;  
          const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            setsh_OR_NO(resp.dn_DO_NO);
              document.getElementById("sh_OR_NO").value = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/SalesReturn/Add`, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
      }
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const table = document.getElementById("SalesReturnListtable");
    const totalRowCount = table.tBodies[0].rows.length;
    var  selected = [];
    for (let i = 0; i < totalRowCount; i++) {
      if(table.tBodies[0].rows[i].cells[0].children[0].checked){
        var v = Number (table.tBodies[0].rows[i].cells[0].children[0].value);
        selected.push(v);
      }
    }
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setSelectedRows(selected);
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    setFormData(initialValue);
    if(selectedRows){
      const salesDetail= [];
      const ukNos = selectedRows.toString().split(",");
      const size = ukNos.length;
   
      for (let i = 0; i < size; i++){
        const Details = {
          sd_UK_NO: ukNos[i],
        }
        salesDetail.push(Details);
      }
      const requestBodyInput = {
          "salesDetail" : salesDetail,
        };
    try {
      const response = await axios.post(
        `${domain}/api/SalesReturn/Select`,requestBodyInput,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const SalesData = response.data;
          delete SalesData.salesHeader.sh_OR_NO
        setFormData(SalesData.salesHeader);
        setselectedCustomer(SalesData.salesHeader.cus_NAME);
        setselectedShipto(SalesData.salesHeader.shipto_NAME);
        setSalesTableData(SalesData.salesDetail);
        setsh_UK_NO(SalesData.salesHeader.shUkNo);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  };
  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack>
        <div style={{    background: "red !important",    color: "white",   fontSize: "10px",  }} >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
       <Tooltip target=".add-button" position="bottom" mouseTrack>
        <div style={{  background: "white !important", color: "white",  fontSize: "10px",   }} >
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleClickOpen}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
return(
  <Dialog id="dialog-style"
    visible={SalesEnquiryVisible}
    onHide={CloseEnquiry}
  >
    <div className="tableData" style={{ marginTop: "5%" }}>
        <Toast ref={toast} />
        <div className="card">
          <Toolbar
            className="p-mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />
          <div style={{ overflowX: "auto", maxWidth: "100%" }}>
            <table id="SalesReturnListtable"
              style={{
                borderCollapse: "collapse",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <thead>
                <tr>
                  <td style={{width:"40px"}}>
                    <input
                      style={checkboxStyle}
                      readOnly
                      onMouseDown={(e) => e.preventDefault()}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_TR_UM"] ? filters["sd_TR_UM"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_TR_UM",
                          e.target.value,
                          filters["sd_TR_UM"]
                            ? filters["sd_TR_UM"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_DL_NO"] ? filters["sd_DL_NO"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_DL_NO",
                          e.target.value,
                          filters["sd_DL_NO"]
                            ? filters["sd_DL_NO"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_IT_NM"] ? filters["sd_IT_NM"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_IT_NM",
                          e.target.value,
                          filters["sd_IT_NM"]
                            ? filters["sd_IT_NM"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["item_DESC"] ? filters["item_DESC"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "item_DESC",
                          e.target.value,
                          filters["item_DESC"]
                            ? filters["item_DESC"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_SH_NO"] ? filters["sd_SH_NO"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_SH_NO",
                          e.target.value,
                          filters["sd_SH_NO"]
                            ? filters["sd_SH_NO"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_LO_CN"] ? filters["sd_LO_CN"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_LO_CN",
                          e.target.value,
                          filters["sd_LO_CN"]
                            ? filters["sd_LO_CN"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_LO_NO"] ? filters["sd_LO_NO"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_LO_NO",
                          e.target.value,
                          filters["sd_LO_NO"]
                            ? filters["sd_LO_NO"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_ST_CD"] ? filters["sd_ST_CD"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_ST_CD",
                          e.target.value,
                          filters["sd_ST_CD"]
                            ? filters["sd_ST_CD"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_PK_NO"] ? filters["sd_PK_NO"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_PK_NO",
                          e.target.value,
                          filters["sd_PK_NO"]
                            ? filters["sd_PK_NO"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_SH_QT"] ? filters["sd_SH_QT"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_SH_QT",
                          e.target.value,
                          filters["sd_SH_QT"]
                            ? filters["sd_SH_QT"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_TR_UM"] ? filters["sd_TR_UM"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_TR_UM",
                          e.target.value,
                          filters["sd_TR_UM"]
                            ? filters["sd_TR_UM"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_DL_NO"] ? filters["sd_DL_NO"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_DL_NO",
                          e.target.value,
                          filters["sd_DL_NO"]
                            ? filters["sd_DL_NO"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  
                  <td>
                    <input
                      type="text"
                      value={filters["sd_IT_NM"] ? filters["sd_IT_NM"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_IT_NM",
                          e.target.value,
                          filters["sd_IT_NM"]
                            ? filters["sd_IT_NM"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["item_DESC"] ? filters["item_DESC"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "item_DESC",
                          e.target.value,
                          filters["item_DESC"]
                            ? filters["item_DESC"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_SH_NO"] ? filters["sd_SH_NO"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_SH_NO",
                          e.target.value,
                          filters["sd_SH_NO"]
                            ? filters["sd_SH_NO"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_LO_CN"] ? filters["sd_LO_CN"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_LO_CN",
                          e.target.value,
                          filters["sd_LO_CN"]
                            ? filters["sd_LO_CN"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_LO_NO"] ? filters["sd_LO_NO"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_LO_NO",
                          e.target.value,
                          filters["sd_LO_NO"]
                            ? filters["sd_LO_NO"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_ST_CD"] ? filters["sd_ST_CD"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_ST_CD",
                          e.target.value,
                          filters["sd_ST_CD"]
                            ? filters["sd_ST_CD"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_PK_NO"] ? filters["sd_PK_NO"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_PK_NO",
                          e.target.value,
                          filters["sd_PK_NO"]
                            ? filters["sd_PK_NO"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_SH_QT"] ? filters["sd_SH_QT"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_SH_QT",
                          e.target.value,
                          filters["sd_SH_QT"]
                            ? filters["sd_SH_QT"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_TR_UM"] ? filters["sd_TR_UM"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_TR_UM",
                          e.target.value,
                          filters["sd_TR_UM"]
                            ? filters["sd_TR_UM"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["sd_DL_NO"] ? filters["sd_DL_NO"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "sd_DL_NO",
                          e.target.value,
                          filters["sd_DL_NO"]
                            ? filters["sd_DL_NO"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                </tr>
                <tr>
                  <th style={tableHeaderStyle}></th>
                  <th style={tableHeaderStyle}>Customer No</th>
                  <th style={tableHeaderStyle}>Name</th>
                  <th style={tableHeaderStyle}>Order No</th>
                  <th style={tableHeaderStyle}>Type</th>
                  <th style={tableHeaderStyle}>Company</th>
                  <th style={tableHeaderStyle}>Line No</th>
                  <th style={tableHeaderStyle}>Item Number </th>
                  <th style={tableHeaderStyle}>Description</th>
                  <th style={tableHeaderStyle}>Order Quantity</th>
                  <th style={tableHeaderStyle}>Shipped Quantity</th>
                  <th style={tableHeaderStyle}>UM</th>
                  <th style={tableHeaderStyle}>Unit Price</th>
                  <th style={tableHeaderStyle}>Amount</th>
                  <th style={tableHeaderStyle}>Discount Amount</th>
                  <th style={tableHeaderStyle}>Tax %</th>
                  <th style={tableHeaderStyle}>Tax</th>
                  <th style={tableHeaderStyle}>Extended Cost </th>
                  <th style={tableHeaderStyle}>Status</th>
                  <th style={tableHeaderStyle}>Invoice No</th>
                  <th style={tableHeaderStyle}>Invoice Type</th>
                  <th style={tableHeaderStyle}>Invoice Date</th>
                  <th style={tableHeaderStyle}>Ship To</th>


                </tr>
              </thead>
              <tbody>
                {tableData &&
                  tableData.map((rowData, index) => (
                    <tr
                      key={rowData.sd_UK_NO}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "aliceblue" : "#ffffff",
                      }}
                      className="table-row"
                    >
                      <td style={tableCellStyle}>
                        <input
                          style={{ width: "10% !important" }}
                          type="checkbox"
                          value={rowData.sd_UK_NO}
                          checked={selectedRows.includes(rowData.sd_UK_NO)}
                          onChange={() => handleRowSelect(rowData.sd_UK_NO)}
                        />
                      </td>
                      <td style={tableCellStyle}>{rowData.sd_CU_NO}</td>
                      <td style={tableCellStyle}>{rowData.cus_NAME}</td>
                      <td style={tableCellStyle}>{rowData.sd_OR_NO}</td>
                      <td style={tableCellStyle}>{rowData.sd_OR_TY}</td>
                      <td style={tableCellStyle}>{rowData.sd_OR_CO}</td>
                      <td style={tableCellStyle}>{rowData.sdLnNo}</td>
                      <td style={tableCellStyle}>{rowData.sd_IT_NM}</td>
                      <td style={tableCellStyle}>{rowData.item_DESC}</td>
                      <td style={tableCellStyle}>{rowData.sd_OR_QT}</td>
                      <td style={tableCellStyle}>{rowData.sd_SH_QT}</td>
                      <td style={tableCellStyle}>{rowData.sd_TR_UM}</td>
                      <td style={tableCellStyle}>{rowData.sd_UT_PR}</td>
                      <td style={tableCellStyle}>{rowData.sd_EX_PR}</td>
                      <td style={tableCellStyle}>{rowData.sd_DS_AT}</td>
                      <td style={tableCellStyle}>{rowData.sd_TX_PE}</td>
                      <td style={tableCellStyle}>{rowData.sd_TX_AM}</td>
                      <td style={tableCellStyle}>{rowData.sd_EX_CT}</td>
                      <td style={tableCellStyle}>{rowData.sd_ST_CD}</td>
                      <td style={tableCellStyle}>{rowData.sd_DO_NO}</td>
                      <td style={tableCellStyle}>{rowData.sd_DO_TY}</td>
                      <td style={tableCellStyle}>{rowData.sd_IN_DT}</td>
                      <td style={tableCellStyle}>{rowData.sd_SH_TO}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        {/* pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />

        </div>
        <SalesReturnDialog
          open={open}
          handleClose={handleClose}
          data={FormData}
          onchangedata={onchangedata}
          onchangeFormData={onchangeFormData}
          selectedCustomer={selectedCustomer}
          setselectedCustomer={setselectedCustomer}
          selectedShipto={selectedShipto}
          setselectedShipto={setselectedShipto}
          addRow={addRow}
          deleteRow={deleteRow}
          SalesTableData={SalesTableData}
          setSalesTableData={setSalesTableData}
          sh_OR_TY={sh_OR_TY}
          sh_OR_CO={sh_OR_CO}
          sh_UK_NO={sh_UK_NO}
          sh_OR_NO={sh_OR_NO}
          handleFormSubmit={handleFormSubmit}
        />
    </div>
  </Dialog>
);
}
SalesReturnEnquiry.propTypes = {
  SalesEnquiryVisible: PropTypes.bool.isRequired,
  CloseEnquiry: PropTypes.func.isRequired,
};
export default SalesReturnEnquiry;
