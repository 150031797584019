import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import AddressPopup from "./AddressPopup";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import moment from "moment";
import axios from "axios";
import { Calendar } from "primereact/calendar";
export default function CompanyMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  onchangeDropDownFormData9,
  onchangeDropDownFormData10,
  onchangeDropDownFormData11,
  onchangeDropDownFormData12,
  onchangeDropDownFormData13,
  em_DE_PT,
  em_GR_DE,
  setSelectedemEMNO,
  SelectedemEMNO,
  setSelectedAbAdNM,
  selectedAbAdNM,
}) {
  const {
    coCoNo,
    co_RT_AC,
    co_MC_TR,
    co_MC_MD,
    co_FY_CH,
    co_DT_TY,
    co_CO_NM,
    ec_CO_NO,
    co_AD_NO,
    co_CR_CD,
    co_GL_FY,
    co_GL_PN,
    co_AP_FY,
    co_AP_PN,
    co_AR_FY,
    co_AR_PN,
    co_FR_FY,
    co_FR_PN,
    
  } = data;
  const [co_CR_CDOptions, setco_CR_CDOptions] = useState([]);
  const [co_MC_TROptions, setco_MC_TROptions] = useState([]);
  const [co_MC_MDOptions, setco_MC_MDOptions] = useState([]);
  const [co_FY_CHOptions, setco_FY_CHOptions] = useState([]);
  const [co_DT_TYOptions, setco_DT_TYOptions] = useState([]);
  const [co_GL_FYOptions, setco_GL_FYOptions] = useState([]);

  const [co_GL_PNOptions, setco_GL_PNOptions] = useState([]);
  const [co_AP_FYOptions, setco_AP_FYOptions] = useState([]);
  const [co_AP_PNOptions, setco_AP_PNOptions] = useState([]);
  const [co_AR_FYOptions, setco_AR_FYOptions] = useState([]);
  const [co_AR_PNOptions, setco_AR_PNOptions] = useState([]);
  const [co_FR_FYOptions, setco_FR_FYOptions] = useState([]);
  const [co_FR_PNOptions, setco_FR_PNOptions] = useState([]);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const urlcost = `${domain}/api/CostCenter/DropDown`;

  const url = `${domain}/api/ClassCode/All`;
  // designation
  const parameter2 = "FO/CR";
  const finalUrl2 = `${url}/${parameter2}`;
  // departmemt
  const parameter3 = "ME/PL";
  const finalUrl3 = `${url}/${parameter3}`;
  // pay class
  const parameter4 = "ME/PL";
  const finalUrl4 = `${url}/${parameter4}`;
  // grade
  const parameter5 = "FO/YT";
  const finalUrl5 = `${url}/${parameter5}`;
  // Pay Frequency
  const parameter6 = "ME/PL";
  const finalUrl6 = `${url}/${parameter6}`;

    const parameter7 = "YE/AR";
    const finalUrl7= `${url}/${parameter7}`;
    // period
    const parameter8 = "FO/PE";
    const finalUrl8 = `${url}/${parameter8}`;

    const parameter9= "YE/AR";
    const finalUrl9 = `${url}/${parameter9}`;
    // period
    const parameter10 = "FO/PE";
    const finalUrl10 = `${url}/${parameter10}`;

    const parameter11 = "YE/AR";
    const finalUrl11 = `${url}/${parameter11}`;
    // period
    const parameter12 = "FO/PE";
    const finalUrl12= `${url}/${parameter12}`;

    const parameter13 = "YE/AR";
    const finalUrl13 = `${url}/${parameter13}`;
    // PERIOD4
    const parameter14= "FO/PE";
    const finalUrl14 = `${url}/${parameter14}`;
   

  useEffect(() => {
   
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
    fetchDropdownOptions8();
    fetchDropdownOptions9();
    fetchDropdownOptions10();
    fetchDropdownOptions11();
    fetchDropdownOptions12();
    fetchDropdownOptions13();
    fetchDropdownOptions14();
  }, []);


// 2nd dropdowns
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setco_CR_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl3, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setco_MC_TROptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 4th dropdown
const fetchDropdownOptions4 = async () => {
  try {
    const response = await axios.get(finalUrl4, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setco_MC_MDOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 5th dropdown
const fetchDropdownOptions5 = async () => {
  try {
    const response = await axios.get(finalUrl5, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setco_DT_TYOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 6th dropdown
const fetchDropdownOptions6 = async () => {
  try {
    const response = await axios.get(finalUrl6, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setco_FY_CHOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions7 = async () => {
  try {
    const response = await axios.get(finalUrl7, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setco_GL_FYOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions8= async () => {
  try {
    const response = await axios.get(finalUrl8, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setco_GL_PNOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

const fetchDropdownOptions9= async () => {
  try {
    const response = await axios.get(finalUrl9, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setco_AP_FYOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions10= async () => {
  try {
    const response = await axios.get(finalUrl10, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setco_AP_PNOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions11= async () => {
  try {
    const response = await axios.get(finalUrl11, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setco_AR_FYOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions12= async () => {
  try {
    const response = await axios.get(finalUrl12, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setco_AR_PNOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions13= async () => {
  try {
    const response = await axios.get(finalUrl13, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setco_FR_FYOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions14= async () => {
  try {
    const response = await axios.get(finalUrl14, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setco_FR_PNOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

const [AddressPopupVisible, setAddressPopupVisible] = useState(false);
const openAddressPopup = (e) => {
  setAddressPopupVisible(true);
  setSelectedRowIndex(e);
};
//  startsearchpopup
const handleDataSelect = async (data) => {
  setSelectedemEMNO(data.ab_AD_NM);
  onchangeFormData("co_AD_NO", data.abAdNo);
  setAddressPopupVisible(false);
  setShowSearchIcon(false);
};

const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const openAccountPopup = (e) => {
    setAccountPopupVisible(true);
    setSelectedRowIndex(e);
  };

  const handleDataSelect1 = async (data) => {
    setSelectedAbAdNM(data.am_AC_DS);
    onchangeFormData("co_RT_AC", data.am_AC_ID);
    setAccountPopupVisible(false);
    setShowSearchIcon(false);
  };


const isUpdateMode = Boolean(coCoNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={coCoNo ? "Revise Company master" : "Add Company Master"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
        }}
      >
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="coCoNo" className="font-bold">
                    Company

                  </label>
                  <InputText
                    id="coCoNo"
                    name="coCoNo"
                    value={coCoNo}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_CO_NM" className="font-bold">
                    Name
                  </label>
                  <InputText
                    id="co_CO_NM"
                    name="co_CO_NM"
                    value={co_CO_NM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_AD_NO" className="font-bold">
                    Address   <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
              <InputText
                id="ab_AD_NM"
                name="ab_AD_NM"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNO}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openAddressPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="co_AD_NO"
                name="co_AD_NO"
                value={co_AD_NO}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openAddressPopup}
              ></i>
            </div>
                </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_CR_CD" className="font-bold">
                    Currency
                  </label>
                  <Dropdown
                    name="co_CR_CD"
                    value={co_CR_CD}
                    options={co_CR_CDOptions}
                    onChange={(e) => onchangeDropDownFormData1("co_CR_CD", e.value)}
                    placeholder="Select Currency"
                  />
                </div>
            
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_MC_TR" className="font-bold">
                  Multi Currency
                  </label>
                  <Dropdown
                  id="co_MC_TR"
                  name="co_MC_TR"
                  value={co_MC_TR}
                  options={[
                    { label: "Yes", value: "y" },
                    { label: "No", value: "n" },
                  ]}
                  onChange={(e) => onchangeDropDownFormData2("co_MC_TR", e.value)}
                  placeholder="Select MultiCurrency"
                />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_MC_TR" className="font-bold">
                   Multi Currency Method
                  </label>
                  <Dropdown
                  id="co_MC_MD"
                  name="co_MC_MD"
                  value={co_MC_MD}
                  options={[
                    { label: "D", value: "D" },
                    { label: "M", value: "M" },
                  ]}
                  onChange={(e) => onchangeDropDownFormData3("co_MC_MD", e.value)}
                  placeholder="Select MultiCurrency Method"
                />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_RT_AC" className="font-bold">
                  Retained Earning A/C  <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="am_AC_DS"
                      name="am_AC_DS"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={selectedAbAdNM}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openAccountPopup}
                    ></i>
                    )}
               </div>
                </div>
                <div style={{ display: "none" }}>
              <InputText
                id="co_RT_AC"
                name="co_RT_AC"
                value={co_RT_AC}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openAccountPopup}
              ></i>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_DT_TY" className="font-bold">
                  Year Type
                  </label>
                  <Dropdown
                    name="co_DT_TY"
                    value={co_DT_TY}
                    options={ co_DT_TYOptions}
                    onChange={(e) => onchangeDropDownFormData4("co_DT_TY", e.value)}
                    placeholder="Select Year Type"

                  />
                </div>
         
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_FY_CH" className="font-bold">
                  FY Check
                  </label>
                  <Dropdown
                  id="co_FY_CH"
                  name="co_FY_CH"
                  value={co_FY_CH}
                  options={[
                    { label: "Yes", value: "y" },
                    { label: "No", value: "n" },
                  ]}
                  onChange={(e) => onchangeDropDownFormData5("co_FY_CH", e.value)}
                  placeholder="Select FY Check"
                />
                </div>
                </div>
   
            </div>
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
            
              <div className="formgrid grid">
              <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_GL_FY" className="font-bold">
                   Fiscal Year - General Accounting
                  </label>
                  <Dropdown
                    name="co_GL_FY"
                    value={co_GL_FY}
                    options={ co_GL_FYOptions}
                    onChange={(e) => onchangeDropDownFormData6("co_GL_FY", e.value)}
                    placeholder="Select Fiscal Year - GL"

                  />
                </div>

                <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_GL_PN" className="font-bold">
                    Period
                  </label>
                  <Dropdown
                    name="co_GL_PN"
                    value={co_GL_PN}
                    options={ co_GL_PNOptions}
                    onChange={(e) => onchangeDropDownFormData7("co_GL_PN", e.value)}
                    placeholder="Select Period"

                  />
                </div>

                <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_AP_FY" className="font-bold">
                  Fiscal Year - Accounts Payable
                  </label>
                  <Dropdown
                    name="co_AP_FY"
                    value={co_AP_FY}
                    options={ co_AP_FYOptions}
                    onChange={(e) => onchangeDropDownFormData8("co_AP_FY", e.value)}
                    placeholder="Select Fiscal Year - AP"

                  />
                </div>
                
                <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_AP_PN" className="font-bold">
                  Period
                  </label>
                  <Dropdown
                    name="co_AP_PN"
                    value={co_AP_PN}
                    options={ co_AP_PNOptions}
                    onChange={(e) => onchangeDropDownFormData9("co_AP_PN", e.value)}
                    placeholder="Select Period"

                  />
                  
                </div>
                <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_AR_FY" className="font-bold">
                  Fiscal Year - Accounts Receivable
                  </label>
                  <Dropdown
                    name="co_AR_FY"
                    value={co_AR_FY}
                    options={ co_AR_FYOptions}
                    onChange={(e) => onchangeDropDownFormData10("co_AR_FY", e.value)}
                    placeholder="Select Fiscal year - AR"

                  />
                </div>
                <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_AR_PN" className="font-bold">
                  Period
                  </label>
                  <Dropdown
                    name="co_AR_PN"
                    value={co_AR_PN}
                    options={ co_AR_PNOptions}
                    onChange={(e) => onchangeDropDownFormData11("co_AR_PN", e.value)}
                    placeholder="Select Period"

                  />
                </div>  
                <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_FR_FY" className="font-bold">
                  Financial Reporting
                  </label>
                  <Dropdown
                    name="co_FR_FY"
                    value={co_FR_FY}
                    options={ co_FR_FYOptions}
                    onChange={(e) => onchangeDropDownFormData12("co_FR_FY", e.value)}
                    placeholder="Select Financial Reporting"

                  />
                </div>
                <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="co_FR_PN" className="font-bold">
                  Period
                  </label>
                  <Dropdown
                    name="co_FR_PN"
                    value={co_FR_PN}
                    options={ co_FR_PNOptions}
                    onChange={(e) => onchangeDropDownFormData13("co_FR_PN", e.value)}
                    placeholder="Select Period"

                  />
                 
                </div>
                
              </div>
            </div>

      </div>    
      <AddressPopup
        visible={AddressPopupVisible}
        onClose={() => setAddressPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
      <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
    </Dialog>
  );
}
