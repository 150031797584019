import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import CompanyMasterDialog from "./CompanyMasterDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import { Tooltip } from "primereact/tooltip";
const initialValue = {
  coCoNo: "",
  co_CO_NM: "",
  co_CR_CD: "",
  co_GL_FY: "",
  co_GL_PN: "",
  co_AP_FY: "",
  co_AP_PN: "",
  co_AR_FY: "",
  co_AR_PN: "",
};

const initialFilters = {
  coCoNo: { value: "", operation: "" },
  co_CO_NM: { value: "", operation: "" },
  co_CR_CD: { value: "", operation: "" },
  co_GL_FY: { value: "", operation: "" },
  co_GL_PN: { value: "", operation: "" },
  co_AP_FY: { value: "", operation: "" },
  co_AP_PN: { value: "", operation: "" },
  co_AR_FY: { value: "", operation: "" },
  co_AR_PN: { value: "", operation: "" },
};

const CompanyMasterMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,
   } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");

  const [co_CR_CD, setco_CR_CD] = useState([]);
  const [co_MC_TR, setco_MC_TR] = useState([]);
  const [co_MC_MD, setco_MC_MD] = useState([]);
  const [co_FY_CH, setco_FY_CH] = useState([]);
  const [co_DT_TY, setco_DT_TY] = useState([]);
  const [co_GL_FY, setco_GL_FY] = useState([]);
  const [co_GL_PN, setco_GL_PN] = useState([]);
  const [co_AP_FY, setco_AP_FY] = useState([]);
  const [co_AP_PN, setco_AP_PN] = useState([]);
  const [co_AR_FY, setco_AR_FY] = useState([]);
  const [co_AR_PN, setco_AR_PN] = useState([]);
  const [co_FR_FY, setco_FR_FY] = useState([]);
  const [co_FR_PN, setco_FR_PN] = useState([]);



  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setco_CR_CD(null);
    setco_MC_TR(null);
    setco_MC_MD(null);
    setco_FY_CH(null);
    setco_DT_TY(null);
    setco_GL_FY(null);
    setco_GL_PN(null);
    setco_AP_FY(null);
    setco_AP_PN(null);
    setco_AR_FY(null);
    setco_AR_PN(null);
    setco_FR_FY(null);
    setco_FR_PN(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setco_CR_CD(null);
    setco_MC_TR(null);
    setco_MC_MD(null);
    setco_FY_CH(null);
    setco_DT_TY(null);
    setco_GL_FY(null);
    setco_GL_PN(null);
    setco_AP_FY(null);
    setco_AP_PN(null);
    setco_AR_FY(null);
    setco_AR_PN(null);
    setco_FR_FY(null);
    setco_FR_PN(null);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleSearch();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/CompanyMaster/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const CompanyMasterData = response.data;
          delete CompanyMasterData.coCoNo;
          setco_CR_CD(CompanyMasterData.co_CR_CD);
          setco_MC_TR(CompanyMasterData.co_MC_TR);
          setco_MC_MD(CompanyMasterData.co_MC_MD);
          setco_FY_CH(CompanyMasterData.co_FY_CH);
          setco_DT_TY(CompanyMasterData.co_DT_TY);
          setco_GL_FY(CompanyMasterData.co_GL_FY);
          setco_GL_PN(CompanyMasterData.co_GL_PN);
          setco_AP_FY(CompanyMasterData.co_AP_FY);
          setco_AP_PN(CompanyMasterData.co_AP_PN);
          setco_AR_FY(CompanyMasterData.co_AR_FY);
          setco_AR_PN(CompanyMasterData.co_AR_PN);
          setco_FR_FY(CompanyMasterData.co_FR_FY);
          setco_FR_PN(CompanyMasterData.co_FR_PN);
          setFormData(CompanyMasterData);
          setSelectedemEMNO(CompanyMasterData.name);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const url = "${domain}/api/Contact/AllFields";

  useEffect(() => {
    handleSearch();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/CompanyMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
 
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setco_CR_CD(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setco_MC_TR(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setco_MC_MD(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setco_DT_TY(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setco_FY_CH(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setco_GL_FY(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setco_GL_PN(value);
  };
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setco_AP_FY(value);
  };
  const onchangeDropDownFormData9 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setco_AP_PN(value);
  };
  const onchangeDropDownFormData10 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setco_AR_FY(value);
  };
  const onchangeDropDownFormData11 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setco_AR_PN(value);
  };
  const onchangeDropDownFormData12 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setco_FR_FY(value);
  };
  const onchangeDropDownFormData13 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setco_FR_PN(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/CompanyMaster/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const CompanyMasterData = response.data;
        setFormData(CompanyMasterData);
        setSelectedAbAdNM(response.data.ab_AD_NM);
        setSelectedemEMNO(response.data.name);
        setco_CR_CD(response.data.co_CR_CD);
        setco_MC_TR(response.data.co_MC_TR);
        setco_MC_MD(response.data.co_MC_MD);
        setco_FY_CH(response.data.co_FY_CH);
        setco_DT_TY(response.data.co_DT_TY);
        setco_GL_FY(response.data.co_GL_FY);
        setco_GL_PN(response.data.co_GL_PN);
        setco_AP_FY(response.data.co_AP_FY);
        setco_AP_PN(response.data.co_AP_PN);
        setco_AR_FY(response.data.co_AR_FY);
        setco_AR_PN(response.data.co_AR_PN);
        setco_FR_FY(response.data.co_FR_FY);
        setco_FR_PN(response.data.co_FR_PN);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {
    if (FormData.coCoNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/CompanyMaster/Update`;
      if (confirm) {
        axios
          .put(update, FormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.coCoNo = company;
      axios
        .post(`${domain}/api/CompanyMaster/Add`, FormData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };


  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(0);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };



  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

 
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
     
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["coCoNo"] ? filters["coCoNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "coCoNo",
                        e.target.value,
                        filters["coCoNo"]
                          ? filters["coCoNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["co_CO_NM"] ? filters["co_CO_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "co_CO_NM",
                        e.target.value,
                        filters["co_CO_NM"] ? filters["co_CO_NM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["co_CR_CD"] ? filters["co_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "co_CR_CD",
                        e.target.value,
                        filters["co_CR_CD"]
                          ? filters["co_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["co_GL_FY"] ? filters["co_GL_FY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "co_GL_FY",
                        e.target.value,
                        filters["co_GL_FY"]
                          ? filters["co_GL_FY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["co_GL_PN"] ? filters["co_GL_PN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "co_GL_PN",
                        e.target.value,
                        filters["co_GL_PN"]
                          ? filters["co_GL_PN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["co_AP_FY"] ? filters["co_AP_FY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "co_AP_FY",
                        e.target.value,
                        filters["co_AP_FY"]
                          ? filters["co_AP_FY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["co_AP_PN"] ? filters["co_AP_PN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "co_AP_PN",
                        e.target.value,
                        filters["co_AP_PN"]
                          ? filters["co_AP_PN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["co_AR_FY"] ? filters["co_AR_FY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "co_AR_FY",
                        e.target.value,
                        filters["co_AR_FY"]
                          ? filters["co_AR_FY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["co_AR_PN"] ? filters["co_AR_PN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "co_AR_PN",
                        e.target.value,
                        filters["co_AR_PN"]
                          ? filters["co_AR_PN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>GL Year</th>
                <th style={tableHeaderStyle}>GL Period</th>
                <th style={tableHeaderStyle}>AP Year</th>
                <th style={tableHeaderStyle}>AP Period</th>
                <th style={tableHeaderStyle}>AR Year</th>
                <th style={tableHeaderStyle}>AR Period</th>
            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.coCoNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.coCoNo}
                        checked={selectedRows.includes(rowData.coCoNo)}
                        onChange={() => handleRowSelect(rowData.coCoNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.coCoNo}</td>
                    <td style={tableCellStyle}>{rowData.co_CO_NM}</td>
                    <td style={tableCellStyle}>{rowData.co_CR_CD}</td>
                    <td style={tableCellStyle}>{rowData.co_GL_FY}</td>
                    <td style={tableCellStyle}>{rowData.co_GL_PN}</td>
                    <td style={tableCellStyle}>{rowData.co_AP_FY}</td>
                    <td style={tableCellStyle}>{rowData.co_AP_PN}</td>
                    <td style={tableCellStyle}>{rowData.co_AR_FY}</td>
                    <td style={tableCellStyle}>{rowData.co_AR_PN}</td>                 
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
       
      </div>

      <CompanyMasterDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        onchangeDropDownFormData8={onchangeDropDownFormData8}
        onchangeDropDownFormData9={onchangeDropDownFormData9}
        onchangeDropDownFormData10={onchangeDropDownFormData10}
        onchangeDropDownFormData11={onchangeDropDownFormData11}
        onchangeDropDownFormData12={onchangeDropDownFormData12}
        onchangeDropDownFormData13={onchangeDropDownFormData13}
        co_CR_CD={co_CR_CD}
        co_MC_TR={co_MC_TR}
        co_MC_MD={co_MC_MD}
        co_FY_CH={co_FY_CH}
        co_DT_TY={co_DT_TY}
        co_GL_FY={co_GL_FY}
        co_GL_PN={co_GL_PN}
        co_AP_FY={co_AP_FY}
        co_AP_PN={co_AP_PN}
        co_AR_FY={co_AR_FY}
        co_AR_PN={co_AR_PN}
        co_FR_FY={co_FR_FY}
        co_FR_PN={co_FR_PN}
        selectedAbAdNM={selectedAbAdNM}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedemEMNO={setSelectedemEMNO}
     
      />
    </div>
  );
};

export default CompanyMasterMain;
