import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import CostCentreDialog from "./CostCentreDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  ccCcNo:"",
  cc_CC_NM:"",
  cc_CC_DS:"",
  cc_CC_TY:"",
  cc_CO_NO:"",
  cc_AC_IA:"",
};

const initialFilters = {
  cc_CC_NM: { value: "", operation: "" },
  cc_CC_DS: { value: "", operation: "" },
  cc_CC_TY: { value: "", operation: "" },
  cc_CO_NO: { value: "", operation: "" },
  cc_AC_IA: { value: "", operation: "" },
};

const CostCentreMain = () => {
   // buttonstyles
   const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,
   } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [cc_CC_TY, setcc_CC_TY] = useState([]);
  const [cc_CL_01, setcc_CL_01] = useState([]);
  const [cc_CL_02, setcc_CL_02] = useState([]);
  const [cc_CL_03, setcc_CL_03] = useState([]);
  const [cc_CL_04, setcc_CL_04] = useState([]);
  const [cc_CL_05, setcc_CL_05] = useState([]);
  const [cc_CL_06, setcc_CL_06] = useState([]);
  const [cc_CL_07, setcc_CL_07] = useState([]);
  const [cc_CL_08, setcc_CL_08] = useState([]);
  const [cc_CL_09, setcc_CL_09] = useState([]);
  const [cc_CL_10, setcc_CL_10] = useState([]);
  const [cc_AC_IA, setcc_AC_IA] = useState([]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setcc_CC_TY(null);
    setcc_CL_01(null);
    setcc_CL_02(null);
    setcc_CL_03(null);
    setcc_CL_04(null);
    setcc_CL_05(null);
    setcc_CL_06(null);
    setcc_CL_07(null);
    setcc_CL_08(null);
    setcc_CL_09(null);
    setcc_CL_10(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setcc_CC_TY(null);
    setcc_CL_01(null);
    setcc_CL_02(null);
    setcc_CL_03(null);
    setcc_CL_04(null);
    setcc_CL_05(null);
    setcc_CL_06(null);
    setcc_CL_07(null);
    setcc_CL_08(null);
    setcc_CL_09(null);
    setcc_CL_10(null);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/CostCenter/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const CostCentreData = response.data;
          delete CostCentreData.ccCcNo;
          setcc_CC_TY(CostCentreData.cc_CC_TY);
          setcc_CL_01(CostCentreData.cc_CL_01);
          setcc_CL_02(CostCentreData.cc_CL_02);
          setcc_CL_03(CostCentreData.cc_CL_03);
          setcc_CL_04(CostCentreData.cc_CL_04);
          setcc_CL_05(CostCentreData.cc_CL_05);
          setcc_CL_06(CostCentreData.cc_CL_06);
          setcc_CL_07(CostCentreData.cc_CL_07);
          setcc_CL_08(CostCentreData.cc_CL_08);
          setcc_CL_09(CostCentreData.cc_CL_09);
          setcc_CL_10(CostCentreData.cc_CL_10);
          setcc_AC_IA(CostCentreData.cc_AC_IA);
          setFormData(CostCentreData);
          setSelectedemEMNO(CostCentreData.ab_AD_NM);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
    // handleSearch();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/CostCenter/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [cc_CO_NO , setcc_CO_NO ] = useState("");
    const handleDefault = async () => {
      setcc_CO_NO(`${company}`);
      const linkPath = String(window.location);
      const l_array = linkPath.split("=");
      const LinkId = l_array[1];
      
      const requestBody = {
        "version_ID" : LinkId
      };
      const paginationUrl = `${domain}/api/LinkId/Select`;
      try {
        const response = await axios.post(paginationUrl, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataDefault = response.data;
        const listDefault = [];
        var count = 0;
        if (dataDefault.versionIds != null) {
          for (let i = 0; i < dataDefault.versionIds.length; i++) {
            if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
              const fName = dataDefault.versionIds[i].field_ID;
              
              if(fName === "CC_AC_IA"){
              onchangeDropDownFormData12("cc_AC_IA",dataDefault.versionIds[i].default_VALUE);
              setcc_AC_IA(response.data.versionIds[i].default_VALUE);
              }
             }
            if(dataDefault.versionIds[i].field_TYPE === "LIST"){
              count++;
              const def = {
                "column" : dataDefault.versionIds[i].field_ID ,
                "value" : dataDefault.versionIds[i].default_VALUE,
                "operation" : "EQUAL"
              }
              listDefault.push(def);
            }
          }
          handleSearch(listDefault,count);
        } else {
          handleSearch(listDefault,count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
 
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_CC_TY(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_CL_01(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_CL_02(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_CL_03(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_CL_04(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_CL_05(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_CL_06(value);
  };
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_CL_07(value);
  };
  const onchangeDropDownFormData9= (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_CL_08(value);
  };
  const onchangeDropDownFormData10 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_CL_09(value);
  };
  const onchangeDropDownFormData11 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_CL_10(value);
  };
  const onchangeDropDownFormData12 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_AC_IA(value);
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/CostCenter/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const CostCentreData = response.data;
        setFormData(CostCentreData);
        // setSelectedAbAdNM(response.data.ab_AD_NM);
        setSelectedemEMNO(response.data.ab_AD_NM);
        setcc_CC_TY(response.data.cc_CC_TY);
        setcc_CL_01(response.data.cc_CL_01);
        setcc_CL_02(response.data.cc_CL_02);
        setcc_CL_03(response.data.cc_CL_03);
        setcc_CL_04(response.data.cc_CL_04);
        setcc_CL_05(response.data.cc_CL_05);
        setcc_CL_06(response.data.cc_CL_06);
        setcc_CL_07(response.data.cc_CL_07);
        setcc_CL_08(response.data.cc_CL_08);
        setcc_CL_09(response.data.cc_CL_09);
        setcc_CL_10(response.data.cc_CL_10);
        setcc_AC_IA(response.data.cc_AC_IA);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {
    if (FormData.ccCcNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/CostCenter/Update`;
      if (confirm) {
        axios
          .put(update, FormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.cc_CO_NO = company;
      axios
        .post(`${domain}/api/CostCenter/Add`, FormData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };


  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(0);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };



  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" ,backgroundColor:"#1ed01e",border:"none"}}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_CC_NM"] ? filters["cc_CC_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_CC_NM",
                        e.target.value,
                        filters["cc_CC_NM"]
                          ? filters["cc_CC_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_CC_DS"] ? filters["cc_CC_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_CC_DS",
                        e.target.value,
                        filters["cc_CC_DS"] ? filters["cc_CC_DS"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_CC_TY"] ? filters["cc_CC_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_CC_TY",
                        e.target.value,
                        filters["cc_CC_TY"]
                          ? filters["cc_CC_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_CO_NO"] ? filters["cc_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_CO_NO",
                        e.target.value,
                        filters["cc_CO_NO"]
                          ? filters["cc_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_AC_IA"] ? filters["cc_AC_IA"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_AC_IA",
                        e.target.value,
                        filters["cc_AC_IA"]
                          ? filters["cc_AC_IA"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>CostCentre</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.ccCcNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.ccCcNo}
                        checked={selectedRows.includes(rowData.ccCcNo)}
                        onChange={() => handleRowSelect(rowData.ccCcNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.cc_CC_NM}</td>
                    <td style={tableCellStyle}>{rowData.cc_CC_DS}</td>
                    <td style={tableCellStyle}>{rowData.cc_CC_TY}</td>
                    <td style={tableCellStyle}>{rowData.cc_CO_NO}</td>
                    <td style={tableCellStyle}>{rowData.cc_AC_IA}</td>         
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
       
      </div>

      <CostCentreDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        onchangeDropDownFormData8={onchangeDropDownFormData8}
        onchangeDropDownFormData9={onchangeDropDownFormData9}
        onchangeDropDownFormData10={onchangeDropDownFormData10}
        onchangeDropDownFormData11={onchangeDropDownFormData11}
        onchangeDropDownFormData12={onchangeDropDownFormData12}
        cc_CC_TY={cc_CC_TY}
        cc_CL_01={cc_CL_01}
        cc_CL_02={cc_CL_02}
        cc_CL_03={cc_CL_03}
        cc_CL_04={cc_CL_04}
        cc_CL_05={cc_CL_05}
        cc_CL_06={cc_CL_06}
        cc_CL_07={cc_CL_07}
        cc_CL_08={cc_CL_08}
        cc_CL_09={cc_CL_09}
        cc_CL_10={cc_CL_10}
        cc_CO_NO={cc_CO_NO}
        cc_AC_IA={cc_AC_IA}
        selectedAbAdNM={selectedAbAdNM}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedemEMNO={setSelectedemEMNO}
     
      />
    </div>
  );
};

export default CostCentreMain;
