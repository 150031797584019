import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import AssetPopup from "views/admin/Popups/AssetPopup";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import axios from "axios";
import { Calendar } from "primereact/calendar";
export default function AssetCashDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  gl_DO_CO,
  onchangeDropDownFormData1,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNO1,
  setSelectedemEMNO1,
  SelectedemEMNOE,
  setSelectedemEMNOE,
  SelectedemEMNOF,
  setSelectedemEMNOF,
}) {
  const {
    fmUkNo,
    em_GR_EF,
    fm_AT_NO,
    gl_DO_NO,
    gl_DO_TY,
    gl_BA_NO,
    gl_GL_DT,
    gl_CR_CD,
    gl_CR_RT,
    gl_AC_AM,
    gl_AC_NO,
  } = data;
  const [gl_CR_CDOptions, setgl_CR_CDOptions] = useState([]);

  const [activeTab, setActiveTab] = useState(0);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");



  const url = `${domain}/api/ClassCode/All`;
  // designation
  const parameter2 = "FO/CR";
  const finalUrl1 = `${url}/${parameter2}`;

  useEffect(() => {
    fetchDropdownOptions1();
  }, []);

// 1st dropdowns
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setgl_CR_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

const isUpdateMode = Boolean(fmUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AssetPopupVisible, setAssetPopupVisible] = useState(false);
  const openAssetPopup = (e) => {
    setAssetPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect1 =async (data) => {
    setSelectedemEMNO1(data.fm_AT_NO);
    setSelectedemEMNOF(data.fm_AT_D1);
    onchangeFormData("fm_AT_NO", data.fm_AT_NO);
    onchangeFormData("fm_AT_D1", data.fm_AT_D1);
    const Url = `${domain}/api/AssetCash/GetCashProceedings`;
    const requestData={
      "journalEntry":{
        "gl_AT_NO":data.fm_AT_NO,
      }
      
    }
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
    
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
    
      const datatax = response.data.Result;
    
      if (datatax) {
        // document.getElementById("fmUkNo").value =  datatax.gl_DO_NO;
        document.getElementById("gl_DO_NO").value =  datatax.gl_DO_NO;
        document.getElementById("gl_DO_TY").value =  datatax.gl_DO_TY;
        document.getElementById("gl_DO_CO").value =  datatax.gl_DO_CO;
        document.getElementById("gl_CR_RT").value =  datatax.gl_CR_RT;
        document.getElementById("gl_BA_NO").value =  datatax.gl_BA_NO;
        document.getElementById("gl_AC_AM").value =  datatax.gl_AC_AM;
        document.getElementById("gl_AC_NO").value =  datatax.gl_AC_NO;
        onchangeDropDownFormData1("gl_CR_CD", datatax.gl_CR_CD);
        onchangeFormData("gl_GL_DT", datatax.gl_GL_DT);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    
    setAssetPopupVisible(false);
    setShowSearchIcon(false);
  };

  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const openAccountPopup = (e) => {
    setAccountPopupVisible(true);
    setSelectedRowIndex(e);
  };
  
  const handleDataSelect = (data) => {
    setSelectedemEMNO(data.account);
    setSelectedemEMNOE(data.am_AC_DS);
    onchangeFormData("gl_AC_NO", data.account);
    onchangeFormData("fb_AC_DS", data.am_AC_DS);
    setAccountPopupVisible(false);
    setShowSearchIcon(false);
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={fmUkNo ? "Revise Asset Cash" : "Add Asset Cash"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="General">
            {/* tab1 general */}

            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
              {/* <div className="field col-lg-4 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                  <label htmlFor="fmUkNo" className="font-bold">
                  UK No
                  </label>
                  <InputText disabled
                    id="gl_BA_NO"
                    name="gl_BA_NO"
                    value={gl_BA_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div> */}
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_AT_NO" className="font-bold">
                 Asset No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="fm_AT_NO"
                      name="fm_AT_NO"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedemEMNO1}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openAssetPopup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_AT_D1" className="font-bold">
                  Description
                  </label>
                  <InputText
                    id="fm_AT_D1"
                    name="fm_AT_D1"
                    value={SelectedemEMNOF}
                   readOnly
                  />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="gl_BA_NO" className="font-bold">
                   Batch No
                  </label>
                  <InputText disabled
                    id="gl_BA_NO"
                    name="gl_BA_NO"
                    value={gl_BA_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="gl_DO_NO" className="font-bold">
                    Document No
                  </label>
                  <InputText disabled
                    id="gl_DO_NO"
                    name="gl_DO_NO"
                    value={gl_DO_NO}
                    onChange={(Event) => onchangedata(Event)}
                    
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="gl_DO_TY" className="font-bold">
                    Type
                  </label>
                  <InputText disabled
                    id="gl_DO_TY"
                    name="gl_DO_TY"
                    value={gl_DO_TY}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="gl_DO_CO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="gl_DO_CO"
                    name="gl_DO_CO"
                    value={gl_DO_CO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="gl_GL_DT" className="font-bold">
                    GL Date
                  </label>
                  <Calendar
                    id="gl_GL_DT"
                    name="gl_GL_DT"
                    value={moment(gl_GL_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "gl_GL_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    showIcon
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="gl_CR_CD" className="font-bold">
                   Currency
                  </label>
                  <Dropdown
                    id="gl_CR_CD"
                    name="gl_CR_CD"
                    value={gl_CR_CD}
                    options={gl_CR_CDOptions}
                    onChange={(e) => onchangeDropDownFormData1("gl_CR_CD", e.value)}
                    placeholder="Select Currency"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="gl_CR_RT" className="font-bold">
                    Exchange Rate
                  </label>
                  <InputText disabled
                    id="gl_CR_RT"
                    name="gl_CR_RT"
                    value={gl_CR_RT}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="gl_AC_NO" className="font-bold">
                 Account <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="gl_AC_NO"
                      name="gl_AC_NO"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedemEMNO}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openAccountPopup}
                    ></i>
                    )}
                  </div>
                 
                </div>
                {/* <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fb_AC_DS" className="font-bold">
                  Description
                  </label>
                  <InputText
                    id="fb_AC_DS"
                    name="fb_AC_DS"
                    value={SelectedemEMNOE}
                   readOnly
                  />
            </div> */}
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="gl_AC_AM" className="font-bold">
                   Amount
                  </label>
                  <InputText
                    id="gl_AC_AM"
                    name="gl_AC_AM"
                    defaultValue={gl_AC_AM}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                </div>
              </div>
          </TabPanel>
      </TabView>
      </div>    
      <AssetPopup
        visible={AssetPopupVisible}
        onClose={() => setAssetPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
        <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
    </Dialog>
  );
}
