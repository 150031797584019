import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import SymptomPopup from "views/admin/Popups/SymptomPopup";
import ItemPopup from "../Popups/ItemPopup";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function DiagnosisMasterDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  DiagnosisMasterTableData,
  setDiagnosisMasterTableData,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  selectedSymptom,
  setselectedSymptom,
  selectedName,
  setselectedName,
  dm_CH_NC,
  setdm_CH_NC,
  dm_CO_NO,
}) {
  const {
    dmUkNo,
    ds_UK_NO,
    dm_DA_CD,
    dm_DA_NM,
    dm_DA_DS,
    os_SQ_DS,
  } = data;
  const [AddresstypeC, setAddresstype] = useState("C");
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const [dm_CH_NCOptions, setdm_CH_NCOptions] = useState([]);

// Cross Type
const ChronicDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setdm_CH_NC(value);
  };

  useEffect(() => {
    Chronic();
  }, []);

  const Chronic = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setdm_CH_NCOptions(Options);
  }


  const addRow = () => {
    setDiagnosisMasterTableData([...DiagnosisMasterTableData, {}]);
  };
  
  const deleteRow = () => {
    if (DiagnosisMasterTableData.length > 0) {
      const updatedData = [...DiagnosisMasterTableData];
      updatedData.pop();
      setDiagnosisMasterTableData(updatedData);
    }
  };
const isUpdateMode = Boolean(dmUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    onchangeFormData("dm_DA_CD", data.im_IT_NM);
    onchangeFormData("dm_DA_NM", data.im_IT_DS);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

  // Address
  const [SymptomVisible, setSymptomVisible] = useState(false);
  const handleSymptom =async (data) => {
    setselectedSymptom(data.sm_SY_CD)
    setselectedName(data.sm_SY_DS)
    onchangeFormData("ds_SY_CD", data.sm_SY_CD, SelectedRowIndex);
    onchangeFormData("symptom", data.sm_SY_DS, SelectedRowIndex);
    setSymptomVisible(false);
    setShowSearchIcon(false);
  };
  const openAddressPopup = (e) => {
    setSymptomVisible(true);
    setSelectedRowIndex(e);
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "70rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dmUkNo ? "Revise Diagnosis Master" : "Add Diagnosis Master"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                    <label htmlFor="dmUkNo" className="font-bold" >
                     UK No
                    </label>
                    <InputText  disabled
                      id="dmUkNo"
                      name="dmUkNo"
                      value={dmUkNo}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                    <label htmlFor="dm_CO_NO" className="font-bold" >
                     Company
                    </label>
                    <InputText  disabled
                      id="dm_CO_NO"
                      name="dm_CO_NO"
                      value={dm_CO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="dm_DA_CD" className="font-bold">
                     Diagnosis Code
                    </label>
                    <InputText 
                      id="dm_DA_CD"
                      name="dm_DA_CD"
                      value={dm_DA_CD}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="dm_DA_NM" className="font-bold">
                    Diagnosis Name
                    </label>
                    <InputText 
                      id="dm_DA_NM"
                      name="dm_DA_NM"
                      value={dm_DA_NM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="dm_DA_DS" className="font-bold">
                     Description
                    </label>
                    <InputText 
                      id="dm_DA_DS"
                      name="dm_DA_DS"
                      value={dm_DA_DS}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="dm_CH_NC" className="font-bold">
                    Chronic
                    </label>
                    <Dropdown
                    name="dm_CH_NC"
                    value={dm_CH_NC}
                    options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                      ]}
                    onChange={(e) => ChronicDropdown("dm_CH_NC", e.value)}
                    placeholder="Select Chronic"
                  />
                  </div>
          </div>
          </div>  
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
        >
      
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="DiagnosisMastertable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Seq No</th>
              <th>Symptom <span style={{ color: 'red' }}>*</span> </th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {DiagnosisMasterTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`ds_UK_NO_${index}`}
                    name={`ds_UK_NO_${index}`}
                    value={row.ds_UK_NO}
                  />
                </td>
                  <td >
                  <InputText  disabled
                    id={`ds_SQ_NO_${index}`}
                    name={`ds_SQ_NO_${index}`}
                    defaultValue={index+1}
                  />
                </td>
                
                
                    <td style={{ display: "flex" }}>
                    <InputText
                    id={`ds_SY_CD_${index}`}
                    name={`ds_SY_CD_${index}`}
                    onChange={(e) => onchangeFormData(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={row.ds_SY_CD ? row.ds_SY_CD: row[`${"ds_SY_CD"}_${index}`]}
                    />
                    {showSearchIcon && (
                  <i
                  className="pi pi-search"
                  style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "15%",
                  cursor: "pointer",
                  }}
                  onClick={(e) => openAddressPopup(index)}
                  ></i>
                  )}
                </td>
                  <td>
                  <InputText disabled
                  id={`symptom_${index}`}
                  name={`symptom_${index}`}
                  defaultValue={row.symptom ? row.symptom : row[`${"symptom"}_${index}`]}
                  readOnly
                  />
                  </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>

        <SymptomPopup
        visible={SymptomVisible}
        onClose={() => setSymptomVisible(false)}
        onDataSelect={handleSymptom}
        formData={data}
      />
    </Dialog>
  );
}
