import React from "react";
import PropTypes from "prop-types";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";

const CarrierPopupData = ({
  tableData,
  filters,
  handleFirstPage,
  handlePreviousPage,
  handleNextPage,
  handleLastPage,
  handleFilterChange,
  toast,
  open,
  handleClose,
  onDataSelect,
}) => {
  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
  };

  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Parent Details"
      modal
      className="p-fluid"
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    value={filters["abAdNo"] ? filters["abAdNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "abAdNo",
                        e.target.value,
                        filters["abAdNo"]
                          ? filters["abAdNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ab_AD_NM"] ? filters["ab_AD_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ab_AD_NM",
                        e.target.value,
                        filters["ab_AD_NM"]
                          ? filters["ab_AD_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}>Carrier</th>
                <th style={tableHeaderStyle}>Name</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                key={rowData.em_UK_NO}
                onClick={() => onDataSelect(rowData)}
                style={{
                  backgroundColor:
                    index % 2 === 0 ? "aliceblue" : "#ffffff",
                }}
                className="table-row"
              >
                      <td style={tableCellStyle}>{rowData.abAdNo}</td>
                      <td style={tableCellStyle}>{rowData.ab_AD_NM}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="Pagination" style={{ marginLeft: "85%" }}>
          <span
            onClick={handleFirstPage}
            style={{
              fontSize: "200%",
              cursor: "pointer",
              whiteSpace: "pre-wrap",
            }}
          >
            &laquo;{" "}
          </span>{" "}
          <span
            onClick={handlePreviousPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Prev{" "}
          </span>{" "}
          <span style={{ whiteSpace: "pre-wrap", fontSize: "111%" }}> </span>{" "}
          <span
            onClick={handleNextPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Next{" "}
          </span>{" "}
          <span
            onClick={handleLastPage}
            style={{ fontSize: "200%", cursor: "pointer" }}
          >
            &raquo;
          </span>
        </div>
      </div>
    </Dialog>
  );
};

CarrierPopupData.propTypes = {
  tableData: PropTypes.array,
  filters: PropTypes.object,
  handleFirstPage: PropTypes.func,
  handlePreviousPage: PropTypes.func,
  handleNextPage: PropTypes.func,
  handleLastPage: PropTypes.func,
  handleFilterChange: PropTypes.func,
  toast: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onDataSelect: PropTypes.func,
};

export default CarrierPopupData;
