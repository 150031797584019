import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import PatientDetailsPopup from "views/admin/Popups/PatientDetailsPopup";
import ClassCode from "views/common/ClassCode";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import Costcenter  from "views/common/CostCentreDrpDwn";
export default function AppointmentBookingDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  AppointmentBookingTableData,
  setAppointmentBookingTableData,
  SelectedItem,
  setselectedItem,
  qualification,
  setqualification,
  specialization,
  setspecialization,
  FormData,
  ad_DE_PT,
  setad_DE_PT,
  ad_AP_TM,
  setad_AP_TM,
  selectedSymptom,
  setselectedSymptom,
  ad_TY_PE,
  ad_CO_NO,
  day,
  setday,
}) {
  const {
    adUkNo,
    ad_TK_NO,
    ad_TR_DT,
    ad_PA_ID,
    ad_DR_NO,
    ad_AP_DT,
    consultation_FEE,
  } = data;

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [ad_DE_PTOptions, setad_DE_PTOptions] = useState([]);
  const [qualificationOptions, setqualificationOptions] = useState([]);
  const [specializationOptions, setspecializationOptions] = useState([]);
  const [ad_AP_TMOptions, setad_AP_TMOptions] = useState([]);
  const [dayOptions, setdayOptions] = useState([]);

  // Expense Type
  const DepartmentDropdown = (name, value,index=null) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_DE_PT(value);
    const fieldName=(FormData && FormData.adUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      AppointmentBookingTableData[index][fieldName]=value;
    else{
      for(var i=0;i<AppointmentBookingTableData.length;i++){
        AppointmentBookingTableData[i][name]=value;
        setFormData((prevData) => ({
          ...prevData,
          [`${name}_${i}`]: value,
        }));
      }
    }
};

  // Advance Curr
  const QualificationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setqualification(value);
  };

 // Reim Curr
  const SpecializationDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setspecialization(value);
  };

   // Status
   const AppointmentDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_AP_TM(value);
    };

  //Type
  const TypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setday(value);
};



  useEffect(() => {
    Department();
    Specialization();
    Qualification();
    Appointment();
    Type();
    

  }, []);

  const Type = async () => {
    const Options = await fetchClassCodeCD("DO/AV");
    setdayOptions(Options);
  };

  const Department = async () => {
    const Options = await fetchClassCodeDS("BD/DP");
    setad_DE_PTOptions(Options);
  };

  const Specialization = async () => {
    const Options = await fetchClassCodeDS("DO/SP");
    setspecializationOptions(Options);
  };

  const Qualification = async () => {
    const Options = await fetchClassCodeDS("DO/QA");
    setqualificationOptions(Options);
  };
  const Appointment = async () => {
    const Options = await fetchClassCodeDS("AP/TM");
    setad_AP_TMOptions(Options);
  };


// Address
const [SymptomVisible, setSymptomVisible] = useState(false);
const handleSymptom =async (data) => {
  setselectedSymptom(data.pd_PA_NM)
  onchangeFormData("ad_PA_ID", data.pd_PA_ID, SelectedRowIndex);
  setSymptomVisible(false);
  setShowSearchIcon(false);
};
const openAddressPopup = (e) => {
  setSymptomVisible(true);
  setSelectedRowIndex(e);
};


// Doctor

const openItemPopup = (e) => {
  setAddressVisible(true);
  setSelectedRowIndex(e);
};
const [AddressVisible, setAddressVisible] = useState(false);
const handleAddress =async (data) => {
  setselectedItem(data.ab_AD_NM)
  onchangeFormData("ad_DR_NO", data.abAdNo);
  const Url = `${domain}/api/DoctorMaster/GetDocDetails`;
    const requestData={
    "dm_AD_NO":  data.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCuMt = response.data.Result;
    if (dataCuMt) {
      QualificationDropdown(`qualification`, dataCuMt.dm_QA_FN);
      SpecializationDropdown(`specialization`, dataCuMt.dm_SP_ON);
      document.getElementById ("consultation_FEE").value =  dataCuMt.dm_CN_FE;
      const exData={
        "ad_DR_NO": data.abAdNo,
      }
      Details (exData);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setAddressVisible(false);
  setShowSearchIcon(false);
};


const Details =async (exData) => {
  const Url = `${domain}/api/AppointmentBooking/GetAPDTDocAvailability`;
try {
  const response =await axios.post(Url, exData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  });

  if (response.status !== 200) {
    throw new Error(`API request failed with status: ${response.status}`);
  }
  const dataItMt = response.data.result;
  if (dataItMt ) {
    TypeDropdown("day", dataItMt.da_AV_DA, SelectedRowIndex);
    // TypeDropdown("day", dataItMt.da_AV_DA);
  } else {
    console.error("Invalid API response:", data);
  }
} catch (error) {
  console.error("Error fetching data:", error);
}
 
};

  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AddresstypeC, setAddresstype] = useState("E");


  const addRow = () => {
    setAppointmentBookingTableData([...AppointmentBookingTableData, {}]);
  };
  
  const deleteRow = () => {
    if (AppointmentBookingTableData.length > 0) {
      const updatedData = [...AppointmentBookingTableData];
      updatedData.pop();
      setAppointmentBookingTableData(updatedData);
    }
  };  
const isUpdateMode = Boolean(adUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

 
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={adUkNo ? "Revise Appointment Booking" : "Add Appointment Booking"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ad_TK_NO" className="font-bold">
           Token No
          </label>
          <InputText disabled
            id="ad_TK_NO"
            name="ad_TK_NO"
            defaultValue={ad_TK_NO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ad_TY_PE" className="font-bold">
           Type 
          </label>
          <InputText disabled
            id="ad_TY_PE"
            name="ad_TY_PE"
            value={ad_TY_PE}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ad_CO_NO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="ad_CO_NO"
            name="ad_CO_NO"
            value={ad_CO_NO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ad_TR_DT" className="font-bold">
          Trans Date
            </label>
            <Calendar
              id="ad_TR_DT"
              name="ad_TR_DT"
              value={moment(ad_TR_DT, "YYYYMMDD").toDate()}
              onChange={(e) => onchangedata({ target: { name: "ad_TR_DT", value: moment(e.value).format("YYYYMMDD") } })}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ad_PA_ID" className="font-bold">
              Patient ID <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="patient_NAME"
                      name="patient_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={selectedSymptom}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openAddressPopup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="ad_PA_ID"
                      name="ad_PA_ID"
                      value={ad_PA_ID}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ad_DR_NO" className="font-bold">
              Doctor No <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="dname"
                      name="dname"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedItem}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItemPopup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="ad_DR_NO"
                      name="ad_DR_NO"
                      value={ad_DR_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
                 
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="qualification" className="font-bold">
                    Qualification
                    </label>
                    <Dropdown disabled
                    name="qualification"
                    value={qualification}
                    options={qualificationOptions}
                    onChange={(e) => QualificationDropdown("qualification", e.value)}
                    placeholder="Select Qualification"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="specialization" className="font-bold">
                    Specialization
                    </label>
                    <Dropdown disabled
                    name="specialization"
                    value={specialization}
                    options={specializationOptions}
                    onChange={(e) => SpecializationDropdown("specialization", e.value)}
                    placeholder="Select Specialization"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="consultation_FEE" className="font-bold">
                  Consultation Fee
                  </label>
                  <InputText disabled
                    id="consultation_FEE"
                    name="consultation_FEE"
                    value={consultation_FEE}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>    
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ad_DE_PT" className="font-bold">
                     Department
                    </label>
                    <Dropdown
                    name="ad_DE_PT"
                    value={ad_DE_PT}
                    options={ad_DE_PTOptions}
                    onChange={(e) => DepartmentDropdown("ad_DE_PT", e.value)}
                    placeholder="Select Department"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ad_AP_DT" className="font-bold">
                Appointment Date
                  </label>
                  <Calendar
                    id="ad_AP_DT"
                    name="ad_AP_DT"
                    value={moment(ad_AP_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "ad_AP_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ad_AP_TM" className="font-bold">
                    Appointment Time
                    </label>
                    <Dropdown
                    name="ad_AP_TM"
                    value={ad_AP_TM}
                    options={ad_AP_TMOptions}
                    onChange={(e) => AppointmentDropdown("ad_AP_TM", e.value)}
                    placeholder="Select Appointment Time"
                  />
                  </div>
                  
                  
          </div>
          </div>  
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
        >
      
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="operationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Available Day</th>
              <th>In Time</th>
              <th>Out Time</th>
              
            </tr>
          </thead>
          <tbody>
            {AppointmentBookingTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`adUkNo_${index}`}
                    name={`adUkNo_${index}`}
                    value={row.adUkNo}
                  />
                </td>
                <td>
                  <Dropdown
                    id={`day_${index}`}
                    name={`day_${index}`}
                    value={ data[`day_${index}`] ? data[`day_${index}`]: row.day}
                    options={dayOptions}
                    onChange={(e) =>TypeDropdown(`day_${index}`, e.value)}
                    placeholder="Select Available Days"
                  />
                  </td>
                <td >
                  <InputText disabled 
                    id={`in_TIME_${index}`}
                    name={`in_TIME_${index}`}
                    defaultValue={row.in_TIME}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled 
                    id={`out_TIME_${index}`}
                    name={`out_TIME_${index}`}
                    defaultValue={row.out_TIME}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
       <PatientDetailsPopup
        visible={SymptomVisible}
        onClose={() => setSymptomVisible(false)}
        onDataSelect={handleSymptom}
        formData={data}
      />
            <AddressBookPopup
        visible={AddressVisible}
        onClose={() => setAddressVisible(false)}
        onDataSelect={handleAddress}
        formData={data}
        addressType={AddresstypeC}
        setFormData= {setFormData}
      />
    </Dialog>
  );
}
