import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";

const CreditPopup = ({ visible,onClose,grossAmount,paidTotal,sh_BS_CR,sh_EX_RT,sh_OR_DT,handlebalCredit,Creditsp_TR_CR,setCreditsp_TR_CR}) => {
  const [formData, setFormData] = useState('');
  const toast = useRef(null);
  const { borderbox, inboxstyle } = ButtonStyles();

  const [CreditOpenAmount, setCreditOpenAmount] = useState("");
  const [CreditPaid_amt, setCreditPaid_amt] = useState("");
  const [Difference, setDifference] = useState("");
  const [CreditExchangeRate, setCreditExchangeRate] = useState("");
  const [Remarks, setRemarks] = useState("");

 // Define the CalculateOpamt function
 const calculateOpenAmount = async (gAmount,pTotal,flag,exRate) => {
 if(!pTotal){
  pTotal = 0;
 }
  const inputData ={
    "detail":{
      "exchangeRate": exRate, 
      "amount": pTotal, 
      "grossAmount": gAmount
    } 
  }
  try {
    const response = await axios.post(`${domain}/api/KioskSales/GetCalculateOpenAmount`, inputData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`,
        "x-flag": localStorage.getItem("userType"),
      },
    });
    const dataOPamt = response.data.Result;
    if (dataOPamt) {
      if(flag === 0){
        setCreditOpenAmount(dataOPamt.OpenAmount);
      }
      setDifference(dataOPamt.OpenAmount);
      setCreditPaid_amt(0.00);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Effect to handle CalculateOpamt on popup open
useEffect(() => {
  if (visible) {
    setCreditExchangeRate(sh_EX_RT);
    const flag = 0;
    calculateOpenAmount(grossAmount,document.getElementById("PAID_TOTAL").value,flag,sh_EX_RT);
  }
}, [visible,grossAmount,paidTotal,sh_EX_RT]);
 
const token = localStorage.getItem("token");
const company = localStorage.getItem("company");
const domain = localStorage.getItem("domain");

const onchangepaidamt = () => {
  const pAmt = document.getElementById("CreditPaid_amt").value;
  const eRate = document.getElementById("CreditExchangeRate").value;
  const flag = 1;
  var pTOTAL = document.getElementById("PAID_TOTAL").value;
  if(pTOTAL === undefined || !pTOTAL){
    pTOTAL = 0;
  }
  const gAmt = grossAmount - pTOTAL;
  calculateOpenAmount(gAmt,pAmt,flag,eRate);    
};
  // dropdowns
  const [CreditBA_NK, setCreditBA_NK ] = useState(sh_BS_CR);
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [sp_TR_CROptions, setsp_TR_CROptions] = useState([]);
  const [Ba_NkOptions, setBa_NkOptions] = useState([]);

  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setCreditsp_TR_CR(value);
    const exData={
      "cr_TO_CR": sh_BS_CR, //base curr
      "cr_FR_CR": value, //currency
      "cr_EF_DT": sh_OR_DT
    }
    exchangeRate(exData);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setCreditBA_NK(value);
  };
  useEffect(() => {
    fetchDropdownCurr();
    fetchDropdownBank();
  }, []);   
  
  const fetchDropdownCurr = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setsp_TR_CROptions(Options);
  };

  const fetchDropdownBank = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setBa_NkOptions(Options);
  };
  const onchangedata = (event) => {
    const { value, name } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleClose = () => {
    onClose();
  };
  useEffect(() => {
    setFormData();
  }, []);
// exchnage rate
function exchangeRate (exData){
  axios.post(`${domain}/api/ExChangeRate/Rate`, exData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    const dataExRt = resp.data.Result;
    if (dataExRt) {
      document.getElementById("CreditExchangeRate").value = dataExRt.cr_CO_MR;
      setCreditExchangeRate(dataExRt.cr_CO_MR);
      const PAmt = document.getElementById("CreditPaid_amt").value;
      const flag = 0;
      const gAmt = grossAmount - document.getElementById("PAID_TOTAL").value;
      calculateOpenAmount(gAmt,PAmt,flag,dataExRt.cr_CO_MR);

    }
  })
  .catch((error) => {
    console.error("Error saving data:", error);
  });
};
const handleconfirm = () =>{
  handlebalCredit();
  handleClose();
}
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "10%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      />
       <Button
        style={{ width: "10%" }}
        icon="pi pi-check"
        variant="outlined"
        onClick={handleconfirm}
        tooltip="Confirm"
      />
    </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={visible}
      onHide={handleClose}
      style={{ width: "40rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Pay By Credit"
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >      
    <Toast ref={toast} />
        <div style={borderbox}>
            <div style={inboxstyle}>
              <div style={{display:"none"}}>
              <InputText style={{height:"3vh"}} disabled
                  id="CreditExchangeRate"
                  name="CreditExchangeRate"
                  value={CreditExchangeRate}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
                <div className="formgrid grid">
                    <div className="field col-lg-12 col-md-6 col-12 pe-md-2 d-flex justify-content-between align-items-center " >
                        <label htmlFor="CreditBA_NK" className="font-bold w-50" style={{ marginRight: '10px' }}>
                          Pay Term
                        </label>
                        <Dropdown
                          id="CreditBA_NK"
                          name="CreditBA_NK"
                          value={CreditBA_NK}
                          options={Ba_NkOptions}
                          onChange={(e) => onchangeDropDownFormData2("Creditsp_TR_CR", e.value)}
                          placeholder="Select Bank"
                        />
                      </div>
                      <div className="field col-lg-12 col-md-6 col-12 pe-md-2 d-flex justify-content-between align-items-center ">
                          <label htmlFor="Remarks" className="font-bold w-50" style={{ marginRight: '10px' }}>
                            Remarks
                          </label>
                          <InputText style={{height:"3vh"}}
                            id="Remarks"
                            name="Remarks"
                            value={Remarks}
                            onChange={(e) => onchangedata(e)}
                          />
                      </div>
                    <div className="field col-lg-12 col-md-6 col-12 pe-md-2 d-flex justify-content-between align-items-center " >
                        <label htmlFor="Creditsp_TR_CR" className="font-bold w-50" style={{ marginRight: '10px' }}>
                        Currency
                        </label>
                        <Dropdown
                          id="Creditsp_TR_CR"
                          name="Creditsp_TR_CR"
                          value={Creditsp_TR_CR}
                          options={sp_TR_CROptions}
                          onChange={(e) => onchangeDropDownFormData1("Creditsp_TR_CR", e.value)}
                          placeholder="Select Currency"
                        />
                    </div>
                    </div>
                    <div className="formgrid grid">
                      <div className="field col-lg-12 col-md-6 col-12 pe-md-2 d-flex justify-content-between align-items-center ">
                          <label htmlFor="CreditOpenAmount" className="font-bold w-50" style={{ marginRight: '10px' }}>
                            Open Amount
                          </label>
                          <InputText style={{height:"3vh"}} disabled
                            id="CreditOpenAmount"
                            name="CreditOpenAmount"
                            value={CreditOpenAmount}
                            onChange={(e) => onchangedata(e)}
                          />
                      </div>
                    </div>
                    <div className="formgrid grid">
                      <div className="field col-lg-12 col-md-6 col-12 pe-md-2 d-flex justify-content-between align-items-center ">
                          <label htmlFor="CreditPaid_amt" className="font-bold w-50" style={{ marginRight: '10px' }}>
                          Paid
                          </label>
                          <InputText style={{height:"3vh"}}
                            id="CreditPaid_amt"
                            name="CreditPaid_amt"
                            defaultValue={CreditPaid_amt}
                            onChange={(e) => onchangepaidamt(e)}
                          />
                      </div>
                    </div>
                  <div className="formgrid grid">
                    <div className="field col-lg-12 col-md-6 col-12 pe-md-2 d-flex justify-content-between align-items-center ">
                        <label htmlFor="Difference" className="font-bold w-50" style={{ marginRight: '10px' }}>
                        Difference
                        </label>
                        <InputText style={{height:"3vh"}} disabled
                          id="Difference"
                          name="Difference"
                          value={Difference}
                          onChange={(e) => onchangedata(e)}
                        />
                    </div>
                </div>                
            </div>
        </div>
    </Dialog>
  );
};

CreditPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  OPData: PropTypes.object,
};

export default CreditPopup;
