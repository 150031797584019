import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import SupplierhPopup from "./SupplierhPopup";
import ShipToPopup from "./ShipToPopup";
import ItemPopup from "./ItemPopup";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import { TabView, TabPanel } from "primereact/tabview";
import ButtonStyles from "views/common/ButtonStyles";
export default function PurchaseReturnDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  // onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeOrderdate,
  setFormData,
  SelectedemEMNO,
  setSelectedemEMNO,
  setph_SH_NO,
  selectedAbAdNM,
  setSelectedAbAdNN,
  setSelectedAbAdNM,
  ph_OR_TY,
  ph_OR_CO,
  ph_BS_CR,
  ph_SU_NO,
  ph_SH_NO,
  ph_CO_CT,
  ph_EX_RT,
  ph_PY_TR,
  pd_ST_CD,
  setpd_IT_NM,
  setph_PY_TR,
  onchangedata,
  setph_SU_NO,
  setph_EX_RT,
  addRow,
  deleteRow,
  PurchaseReturntable,
  ph_HL_CD,
  setph_HL_CD,
  setpd_LO_CN,
  setpd_LT_NO,
}) {
  const { 
    pdUkNo,
    phUkNO,
    ph_OR_NO,
    ph_OR_DT,
    ph_RQ_DT,
    net_AMOUNT,
    price_AMOUNT,
    tax_AMOUNT,
    fr_PRICE_AMOUNT,
    fr_NET_AMOUNT,
    fr_TAX_AMOUNT,
  } = data;

  const {footergridstyle,footerfieldstyle,plusstyle,
    minusstyle,tablebutton,tablestyle,multitablestyle} = ButtonStyles();

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      const formattedDate1 = moment(selectedDate).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      const formattedDate1 = moment(value).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate, index);
    }
  };

  
  const [activeTab, setActiveTab] = useState(0); 
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [currentDate, setCurrentDate] = useState(moment().toDate());

  const [ph_CO_CTOptions, setph_CO_CTOptions] = useState([]);
  const [ph_TR_CROptions, setph_TR_CROptions] = useState([]);
  const [pd_TR_UMOptions, setpd_TR_UMOptions] = useState([]);
  const [ph_PY_TROptions, setph_PY_TROptions] = useState([]);
  const [ph_HL_CDOptions, setph_HL_CDOptions] = useState([]);
  const [pd_LT_NOOptions, setpd_LT_NOOptions] = useState([]);
  const [pd_LO_CNOptions, setpd_LO_CNOptions] = useState([]);

  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();


  const LOTNoDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_LT_NO(value);
  };

  const LocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_LO_CN(value);
  };

  const HoldCodeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setph_HL_CD(value);
  };


 
  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData4(name, value, index);
  };


  const url = `${domain}/api/ClassCode/All`;
  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl1 = `${urlcost}`;

  const parameter2 = "FO/CR";
  const finalUrl2 = `${url}/${parameter2}`;
   
  const parameter4 = "IN/UM";
  const finalUrl4 = `${url}/${parameter4}`;
  
  const urlpayterm = `${domain}/api/PaymentTerm/GetAll`;
  const finalUrl6 = `${urlpayterm}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions4();
    fetchDropdownOptions6();
    HoldCode();
    LOTNo();
    LocatioN();
  }, []);


  const LOTNo = async () => {
    const Options = await fetchClassCodeDS("AP/LC");
    setpd_LT_NOOptions(Options);
  };

  const LocatioN = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setpd_LO_CNOptions(Options);
  };

  const HoldCode = async () => {
    const Options = await fetchClassCodeDS("FO/HD");
    setph_HL_CDOptions(Options);
  };


// 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setph_CO_CTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setph_TR_CROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

// 3rd dropdown
const fetchDropdownOptions4 = async () => {
  try {
    const response = await axios.get(finalUrl4, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setpd_TR_UMOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

// 4th dropdown
const fetchDropdownOptions6 = async () => {
  try {
    const response = await axios.get(finalUrl6, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    const options = response.data.map((item) => ({
      label: item.pt_PY_DS,
      value: item.pt_PY_CO,
    }));
    setph_PY_TROptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

// SUPPLIER ADVANCE SEARCH
const [SupplierhPopupVisible, setSupplierhPopupVisible] = useState(false);
const openSupplierhPopup = (e) => {
  setSupplierhPopupVisible(true);
  setSelectedRowIndex(e);
};
// Supplier
const handleDataSelect = async (data) => {
  setSelectedemEMNO(data.ab_AD_NM);
  setph_SU_NO(data.abAdNo);

  setSelectedAbAdNM(data.ab_AD_NM);
  setph_SH_NO( data.abAdNo);


  const Url = `${domain}/api/SupplierMaster/GetById`;
  const requestData={
      "su_SU_NO": data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        onchangeDropDownFormData6("ph_PY_TR", dataCuMt.su_PT_TM);
         setph_PY_TR(dataCuMt.su_PT_TM);
        onchangeDropDownFormData2("ph_TR_CR", dataCuMt.su_CR_CD);
        onchangeDropDownFormData3("ph_TX_AP", dataCuMt.su_TX_AP);
        HoldCodeDropdown("ph_HL_CD", dataCuMt.su_HL_CD);
        onchangeFormData(`ph_TR_CR`, dataCuMt.su_CR_CD);
        const exData={
          "cr_TO_CR": document.getElementById("ph_BS_CR").value, //base curr
          "cr_FR_CR": dataCuMt.ph_TR_CR, //currency
          "cr_EF_DT": data.ph_OR_DT
        }
        exchangeRate (exData);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  setSupplierhPopupVisible(false);
};

// SHIPTO ADVANCE SEARCH
const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
const openShipToPopup = (e) => {
  setShipToPopupVisible(true);
  setSelectedRowIndex(e);
};
const handleDataSelect1 = async (data) => {
  setSelectedAbAdNM(data.ab_AD_NM);
  setph_SH_NO( data.abAdNo);
  const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
  const requestData={
    "abAdNo":data.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });  
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const data = response.data;
    if (data && data.data) {
      setSelectedAbAdNM(data.data.ab_AD_NM);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setShipToPopupVisible(false);
};

// ITEM ADVANCE SEARCH
const [ItemPopupVisible, setItemPopupVisible] = useState(false);
const openItemPopup = (e) => {
  setItemPopupVisible(true);
  setSelectedRowIndex(e);
};

const handleDataSelect2 = async(Result) => {
  setSelectedAbAdNN(Result.im_IT_DS);
  setpd_IT_NM(Result.im_IT_NM);
  document.getElementById (`pd_IT_NM_${SelectedRowIndex}`).value =  Result.im_IT_NM;
  document.getElementById (`im_IT_DS_${SelectedRowIndex}`).value =  Result.im_IT_DS;
  const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
  const requestData={
      "im_IT_NM":Result.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      onchangeDropDownFormData7(`pd_TX_AP_${SelectedRowIndex}`, dataItMt.im_TX_AP);
      document.getElementById (`pd_TX_PE_${SelectedRowIndex}`).value =  dataItMt.im_VA_TX;
      onchangeDropDownFormData4(`pd_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
      onchangebspr(SelectedRowIndex);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setItemPopupVisible(false);
  setShowSearchIcon(false);
};


// EXCHANGE RATE
exchangeRate(null);
function exchangeRate (exData){
  if(exData==null && ph_BS_CR!="" && data.ph_TR_CR!="" && data.ph_OR_DT!=""){
    exData={
      "cr_TO_CR": ph_BS_CR,
      "cr_FR_CR": data.ph_TR_CR,
      "cr_EF_DT": data.ph_OR_DT
    }
  }
  if(exData!=null){

    axios
    .post(`${domain}/api/ExChangeRate/Rate`, exData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      const dataExRt = resp.data.Result;
      if (dataExRt) {
        document.getElementById("ph_EX_RT").value = dataExRt.cr_CO_MR;
        setph_EX_RT(dataExRt.cr_CO_MR);
      }

    })
    .catch((error) => {
      console.error("Error saving data:", error);
    });
  }
}

// ON CHANGE OF UNIT PRICE
const onchangebspr = async(SelectedRowIndex) => {
	const table = document.getElementById("PurchaseReturntable");
    const UrlPrice = `${domain}/api/BasePrice/GetUnitPriceForSales`;
      const priceInput = {
        "bp_CO_CT" : ph_CO_CT,
        "bp_IT_NM" : document.getElementById (`pd_IT_NM_${SelectedRowIndex}`).value,
        "bp_FR_DT" : ph_OR_DT,
        "bp_TO_DT" : ph_OR_DT,
        "bp_CU_NO" : document.getElementById("ph_SU_NO").value,
        "bp_PR_UM" : table.tBodies[0].rows[SelectedRowIndex].cells[5].children[0].children[1].children[0].value,        
        "bp_CR_CD" : data.ph_TR_CR,
      }
        try {
          const response =await axios.post(UrlPrice, priceInput, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          });
      
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
          const dataItMt = response.data.price;
          if (dataItMt ) {
            document.getElementById (`pd_BS_UP_${SelectedRowIndex}`).value =  dataItMt.bp_UN_PR;
            // onChangeOfPrice(SelectedRowIndex);
          } else {
            console.error("Invalid API response:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
  }

const isUpdateMode = Boolean(phUkNO);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "85rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={phUkNO ? "Revise Purchase Return" : "Add Purchase Return"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Quote Details">

            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
            <div className="formgrid grid">
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
            <label htmlFor="phUkNO" className="font-bold">
            UKNO
            </label>
            <InputText
            id="phUkNO"
            name="phUkNO"
            value={phUkNO}
            onChange={(e) => onchangedata(e)}
            />
            </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ph_OR_NO" className="font-bold">
          Order No
          </label>
          <InputText disabled
          id="ph_OR_NO"
          name="ph_OR_NO"
          value={ph_OR_NO}
           onChange={(e) => onchangedata(e)}
          />
          </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ph_OR_TY" className="font-bold">
          Type
          </label>
          <InputText disabled
          id="ph_OR_TY"
          name="ph_OR_TY"
          value={ph_OR_TY}
          onChange={(e) => onchangedata(e)}
          />
          </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ph_OR_CO" className="font-bold">
           Company
          </label>
          <InputText disabled
          id="ph_OR_CO"
          name="ph_OR_CO"
          value={ph_OR_CO}
          onChange={(e) => onchangedata(e)}
          />
          </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ph_OR_DT" className="font-bold">
          Order Date
          </label>
          <Calendar 
          id="ph_OR_DT"
          name="ph_OR_DT"
          value={moment(ph_OR_DT, "YYYYMMDD").toDate()}
          onChange={(e) =>
          onchangeOrderdate({
          target: {
          name: "ph_OR_DT",
          value: moment(e.value).format("YYYYMMDD"),
          },
          })
          }
          dateFormat="dd/mm/yy"
          />
          </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ph_RQ_DT" className="font-bold">
          Requested Date
          </label>
          <Calendar 
          id="ph_RQ_DT"
          name="ph_RQ_DT"
          value={moment(ph_RQ_DT, "YYYYMMDD").toDate()}
          onChange={(e) => onchangedata({ target: { name: "ph_RQ_DT", value: moment(e.value).format("YYYYMMDD") } })}
          dateFormat="dd/mm/yy"
          
          />
          </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ph_CO_CT" className="font-bold">
          Cost Centre
          </label>
          <Dropdown disabled
          id="ph_CO_CT"
          name="ph_CO_CT"
          value={ph_CO_CT}
          options={ph_CO_CTOptions}
          onChange={(e) => onchangeDropDownFormData1("ph_CO_CT", e.value)}
          placeholder="Select Cost Centre"
          />
          </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ph_SU_NO" className="font-bold">
           Supplier  <span style={{ color: 'red' }}>*</span>
          </label>
          <div style={{ display: "flex" }}>
            <InputText disabled
            id="sup_NAME"
            name="sup_NAME"
            onChange={(Event) => onchangedata(Event)}
            onFocus={() => setShowSearchIcon(true)}
            value={SelectedemEMNO}
            />
            {showSearchIcon && (
            <i
            className="pi pi-search"
            style={{
            color: "var(--primary-color)",
            fontSize: "119%",
            marginTop: "4%",
            cursor: "pointer",
            }}
            onClick={openSupplierhPopup}
            ></i>
            )}
            </div>
            <div style={{ display: "none" }}>
            <InputText
            id="ph_SU_NO"
            name="ph_SU_NO"
            value={ph_SU_NO}
            onChange={(Event) => onchangedata(Event)}
            />
            <i
            className="pi pi-search"
            style={{
            color: "var(--primary-color)",
            fontSize: "119%",
            marginTop: "4%",
            cursor: "pointer",
            }}
            onClick={openSupplierhPopup}
            ></i>
            </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_PY_TR" className="font-bold">
              Pay Term
            </label>
            <Dropdown disabled
              id="ph_PY_TR"
              name="ph_PY_TR"
              value={ph_PY_TR}
              options={ph_PY_TROptions}
              onChange={(e) => onchangeDropDownFormData6("ph_PY_TR", e.value)}
              placeholder="Select Pay Term"
            />
          </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_SH_NO" className="font-bold">
            Deliver To <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
            <InputText disabled
            id="shipto_NAME"
            name="shipto_NAME"
            onChange={(Event) => onchangedata(Event)}
            onFocus={() => setShowSearchIcon(true)}
            value={selectedAbAdNM}
            />
            {showSearchIcon && (
            <i
            className="pi pi-search"
            style={{
            color: "var(--primary-color)",
            fontSize: "119%",
            marginTop: "4%",
            cursor: "pointer",
            }}
            onClick={openShipToPopup}
            ></i>
            )}
            </div>
            <div style={{display:"none"}}>
            <InputText
            id="ph_SH_NO"
            name="ph_SH_NO"
            value={ph_SH_NO}
            onChange={(Event) => onchangedata(Event)}
            />
            </div>
            </div>
          
           
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_TR_CR" className="font-bold">
             Currency
            </label>
            <Dropdown disabled
            id="ph_TR_CR"
            name="ph_TR_CR"
            value={data.ph_TR_CR}
            options={ph_TR_CROptions}
            onChange={(e) => onchangeDropDownFormData2("ph_TR_CR", e.value)}
            placeholder="Select Currency"
            />
            </div>

           <div className="field col-lg-4 col-md-6 col-12 pe-md-2" >
           <label htmlFor="ph_EX_RT" className="font-bold">
             Exchange Rate
            </label>
            <InputText disabled
            id="ph_EX_RT"
            name="ph_EX_RT"
            value={ph_EX_RT}
            onChange={(e) => onchangedata(e)}
            />
          </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2" >
          <label htmlFor="ph_BS_CR" className="font-bold">
           Base Currency
          </label>
          <InputText disabled
          id="ph_BS_CR"
          name="ph_BS_CR"
          value={ph_BS_CR}
          onChange={(e) => onchangedata(e)}
          />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_HL_CD" className="font-bold">
            Hold Code
            </label>
            <Dropdown disabled
            id="ph_HL_CD"
            name="ph_HL_CD"
            value={ph_HL_CD}
            options={ph_HL_CDOptions}
            onChange={(e) => HoldCodeDropdown("ph_HL_CD", e.value)}
            placeholder="Select Hold Code"
            />
            </div>
           
        </div>
      </div>

      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="PurchaseReturntable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Line No</th>
          <th>Item Number <span style={{ color: 'red' }}>*</span> </th>
          <th>Description</th>
          <th> Order   Quantity</th>
          <th>UM</th>
          <th>Unit Price</th>
          <th>Amount</th>
          <th>Tax %</th>
          <th>Tax Amount</th>
          <th>Fore.Price</th>
          <th>Fore.Amount</th>
          <th>LOT No</th>
          <th>Location</th>
          <th>Req.Date</th>
          <th>Promised Date</th>
          <th>Can. Quantity</th>
          <th>Can. Date</th>
          <th>Remarks</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
      {PurchaseReturntable.map((row, index) => (
              <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
              id={`pdUkNo_${index}`}
              name={`pdUkNo_${index}`}
              defaultValue={row.pdUkNo}
            />
          </td>
          <td>
          <InputText disabled
          style={{ width: "90px" }}
          id={`pd_LN_NO_${index}`}
          name={`pd_LN_NO_${index}`}
          value={index + 1}
          readOnly
        />
          </td>
          <td style={{ display: "flex" }}>
            <InputText disabled
              style={{ width: "130px" }}
              id={`pd_IT_NM_${index}`}
              name={`pd_IT_NM_${index}`}
              // defaultValue={pd_IT_NM || row.pd_IT_NM}
              onChange={(e) => onchangeFormData(e)}
              onFocus={() => setShowSearchIcon(true)}
              defaultValue={row.pd_IT_NM ? row.pd_IT_NM: row[`${"pd_IT_NM"}_${index}`]}
            />
            {showSearchIcon && (
            <i
              className="pi pi-search"
              style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "15%",
                cursor: "pointer",
              }}
              onClick={(e) => openItemPopup(index)}
            ></i>
            )}
          </td>
          <td>
          <InputText disabled
              style={{ width: "200px" }}
              id={`im_IT_DS_${index}`}
              name={`im_IT_DS_${index}`}
              // defaultValue={im_IT_DS  || row.im_IT_DS}
              defaultValue={row.im_IT_DS ? row.im_IT_DS : row[`${"im_IT_DS"}_${index}`]}
              readOnly
            />
          </td>
          <td>
          <InputText style={{width:"120px"}}
            id={`pd_OR_QT_${index}`}
            name={`pd_OR_QT_${index}`}
            defaultValue={row.pd_OR_QT}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
           <Dropdown disabled
            id={`pd_TR_UM_${index}`}
            name={`pd_TR_UM_${index}`}
            value={ data[`pd_TR_UM_${index}`] ? data[`pd_TR_UM_${index}`]: row.pd_TR_UM}
            options={pd_TR_UMOptions}
            onChange={(e) =>handleFormDataChange(`pd_TR_UM_${index}`, e.value)}
            placeholder="Select UM"
           />
          </td>
          <td>
          <InputText disabled style={{width:"100px"}}
          //  disabled={data.ph_TR_CR==ph_BS_CR?false:true}
            id={`pd_BS_UP_${index}`}
            name={`pd_BS_UP_${index}`}
            defaultValue={row.pd_BS_UP}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"100px"}}
            id={`pd_OR_AT_${index}`}
            name={`pd_OR_AT_${index}`}
            defaultValue={row.pd_OR_AT}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"100px"}}
            id={`pd_TX_PE_${index}`}
            name={`pd_TX_PE_${index}`}
            defaultValue={row.pd_TX_PE}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"100px"}}
            id={`pd_TX_AM_${index}`}
            name={`pd_TX_AM_${index}`}
            defaultValue={row.pd_TX_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>   
          <td>
          <InputText disabled style={{width:"100px"}}
          //  disabled={data.ph_TR_CR!=ph_BS_CR?false:true}
            id={`pd_FR_UP_${index}`}
            name={`pd_FR_UP_${index}`}
            defaultValue={row.pd_FR_UP}
            onChange={(e) => onchangedata(e)}
          />
          </td>

          <td>
          <InputText   disabled style={{width:"90px"}}
            id={`pd_FR_AT_${index}`}
            name={`pd_FR_AT_${index}`}
            defaultValue={row.pd_FR_AT}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
           <Dropdown disabled
            id={`pd_LT_NO_${index}`}
            name={`pd_LT_NO_${index}`}
            value={ data[`pd_LT_NO_${index}`] ? data[`pd_LT_NO_${index}`]: row.pd_LT_NO}
            options={pd_LT_NOOptions}
            onChange={(e) =>LOTNoDropdown(`pd_LT_NO_${index}`, e.value)}
            placeholder="Select LOT No"
           />
          </td>
          <td>
           <Dropdown disabled
            id={`pd_LO_CN_${index}`}
            name={`pd_LO_CN_${index}`}
            value={ data[`pd_LO_CN_${index}`] ? data[`pd_LO_CN_${index}`]: row.pd_LO_CN}
            options={pd_LO_CNOptions}
            onChange={(e) =>LocationDropdown(`pd_LO_CN_${index}`, e.value)}
            placeholder="Select Location"
           />
          </td>
          <td>
          <Calendar disabled style={{width:"150px"}}
           id={`pd_RQ_DT_${index}`}
           name={`pd_RQ_DT_${index}`}
           value={row.pd_RQ_DT? moment(row.pd_RQ_DT, "YYYYMMDD").toDate(): null}
           onChange={(e) => onDateChange({ target: { name: "pd_RQ_DT", value: moment(e.value).format("YYYYMMDD") } })}
           dateFormat="dd/mm/yy"
          />
          </td>
          <td>
          <Calendar disabled style={{width:"150px"}}
           id={`pd_PR_DT_${index}`}
           name={`pd_PR_DT_${index}`}
           value={row.pd_PR_DT? moment(row.pd_PR_DT, "YYYYMMDD").toDate(): null}
           onChange={(e) => onDateChange({ target: { name: "pd_PR_DT", value: moment(e.value).format("YYYYMMDD") } })}
           dateFormat="dd/mm/yy"
          />
          </td>
          <td>
          <InputText disabled style={{width:"120px"}}
            id={`pd_CN_QT_${index}`}
            name={`pd_CN_QT_${index}`}
            defaultValue={row.pd_CN_QT}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <Calendar disabled
          style={{width:"150px"}}
          id={`pd_CN_DT_${index}`}
          name={`pd_CN_DT_${index}`}
          value={currentDate}
          onChange={(e) => {setCurrentDate(e.value);
          onDateChange("pd_CN_DT", index, e.value);
          }}
          dateFormat="dd/mm/yy"
          />
          </td>
          <td>
          <InputText  style={{width:"300px"}}
            id={`pd_RE_MK_${index}`}
            name={`pd_RE_MK_${index}`}
            defaultValue={row.pd_RE_MK}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText disabled
          style={{ width: "100px" }}
          id={`pd_ST_CD_${index}`}
          name={`pd_ST_CD_${index}`}
          value={ row.pd_ST_CD ? row.pd_ST_CD : pd_ST_CD}
          readOnly
          />
         </td>
          </tr>
           ))}
      </tbody>
    </table>   
    
    </div>
      </div>  

       <div
        style={{
          marginTop: "2%",
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div className="formgrid grid" style={footergridstyle}>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="price_AMOUNT" className="font-bold">
              Amount
            </label>
            <InputText disabled
              id="price_AMOUNT"
              name="price_AMOUNT"
              value={price_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="net_AMOUNT" className="font-bold">
              Taxable Amount
            </label>
            <InputText disabled
              id="net_AMOUNT"
              name="net_AMOUNT"
              value={net_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="tax_AMOUNT" className="font-bold">
              Tax
            </label>
            <InputText disabled
              id="tax_AMOUNT"
              name="tax_AMOUNT"
              value={tax_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          
          </div>
          <div className="formgrid grid" style={footergridstyle}>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="fr_PRICE_AMOUNT" className="font-bold">
              Fore.Amount
            </label>
            <InputText disabled
              id="fr_PRICE_AMOUNT"
              name="fr_PRICE_AMOUNT"
              value={fr_PRICE_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="FR_NET_AMOUNT" className="font-bold">
              Fore. Taxable Amount
            </label>
            <InputText disabled
              id="fr_NET_AMOUNT"
              name="fr_NET_AMOUNT"
              value={fr_NET_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="FR_TAX_AMOUNT" className="font-bold">
              Fore.Tax
            </label>
            <InputText disabled
              id="fr_TAX_AMOUNT"
              name="fr_TAX_AMOUNT"
              value={fr_TAX_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          
        </div>
      </div>
      </TabPanel>
      </TabView>
      </div> 
      
        <SupplierhPopup
        visible={SupplierhPopupVisible}
        onClose={() => setSupplierhPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
       <ShipToPopup
        visible={ShipToPopupVisible}
        onClose={() => setShipToPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
       <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
      />

      
    </Dialog>
  );
}
