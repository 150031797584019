import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
const initialValue = {
  es_YE_AR:"",
  es_PE_NO:"",
};
const SalaryProcessMain = () => {
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const toast = useRef(null);
  const [years, setyears] = useState(null);
  const [months, setMonths] = useState(null);
  const [em_YE_AROptions, setem_YE_AROptions] = useState("");
  const [em_PE_NOOptions, setem_PE_NOOptions] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
// Dropdown
const url = `${domain}/api/ClassCode/All`;
const parameter1 = "YE/AR";
const finalUrl = `${url}/${parameter1}`;

const parameter2 = "MN/TH";
const finalUrl2 = `${url}/${parameter2}`;

useEffect(() => {
  fetchDropdownOptions1();
  fetchDropdownOptions2();
}, []);

  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`,
          "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_DS,
      }));
      setem_YE_AROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_PE_NOOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const handleClearButton=()=>{
    setyears("");
    setMonths("");
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
    }
  };
  const onchangeDropDown = (e) => {
    setyears(e.value); 
    setFormData({ ...FormData, em_FY_YR: e.value }); 
    setIsDropdownSelected(!!e.value);
  };
  const onchangeDropDownM = (e) => {
    setMonths(e.value); 
    setFormData({ ...FormData, em_PE_NO: e.value }); 
    setIsDropdownSelected(!!e.value);
  };

  const handleSalaryProcess = async () => {
    if (!isDropdownSelected) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Select a record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    const Url = `${domain}/api/SalaryDetails/ProcessSalary`;
    const request = {
      "es_YE_AR": years, 
      "es_PE_NO": months,
    };
    try {
      const resp = await axios.post(Url, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": company,
          "x-flag":  localStorage.getItem("userType"),
        },
      });
      handleResponse(resp.data);
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };
  const [isDropdownSelected, setIsDropdownSelected] = useState(false);

  const AdvanceSearch = (
    <div className="formgrid grid">
      <div className="field col" >
        <label htmlFor="years" className="font-bold">
       Financial Year
        </label>
        <Dropdown
            style={{ width: "90%" }}
            id="years"
            name="years"
            value={years} 
            options={em_YE_AROptions}
            onChange={onchangeDropDown} 
            placeholder="Select Financial Year"
          />
        </div>
        <div className="field col" >
        <label htmlFor="months" className="font-bold">
       Month
        </label>
        <Dropdown
            style={{ width: "90%" }}
            id="months"
            name="months"
            value={months} 
            options={em_PE_NOOptions}
            onChange={onchangeDropDownM} 
            placeholder="Select Month"
          />
        </div>
        <div className="field col">
        <Button
          style={{
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "#03a9f485",
            border: "none", 
            marginTop:"13%"
          }}
            type="button"
            label="Process Salary"
            icon="pi pi-check"
            onClick={handleSalaryProcess}
        >
        </Button> 
      </div>  
    </div>
);
const rightClear = (
  <React.Fragment>  
  <Button
      style={{ color: "blue",
       transition: "color 0.3s", 
       backgroundColor: "transparent" }}
      icon="pi pi-times"
      className="mr-2 clear-button"
      link
      onClick={handleClearButton}
  />
  </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        {/* <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        /> */}
        <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
        
      </div>
      </div>
      
    </div>
  );
};

export default SalaryProcessMain;
