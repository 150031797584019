import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import CustomerPopup from "./CustomerPopup";
import moment from "moment";
import axios from "axios";
export default function PostReceiptsDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  // onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  addRow,
  deleteRow,
  PostReceiptstable,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNOA,
  setSelectedemEMNOA,
  SelectedemEMNOE,
  setSelectedemEMNOE,
}) {
  const { 
  rh_RC_NO,
  rh_UK_NO,
  rh_RC_AM,
  rh_DO_CO,
  rh_GL_DT,
  rh_CR_CO,
  rh_RC_DT,
  rh_CR_RT,
  rh_GL_BA,
  rh_RM_EX,
  rc_CE_CD,
  rc_CO_CD,
  } = data;
  const [rh_CR_COOptions, setrh_CR_COOptions] = useState([]);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [bankFilter, setbankFilter] = useState("BA");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      const formattedDate1 = moment(selectedDate).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      const formattedDate1 = moment(value).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "rc_ST_DT"  || name === "rc_EN_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);
  
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };
  const url = `${domain}/api/ClassCode/All`;
  // comp type
  const parameter1 =  "FO/CR";
  const finalUrl1 = `${url}/${parameter1}`;

  useEffect(() => {
    fetchDropdownOptions1();
  }, []);

  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setrh_CR_COOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
const openCustomerPopup = (e) => {
  setCustomerPopupVisible(true);
  setSelectedRowIndex(e);
};

const handleDataSelect = async (data) => {
  setSelectedemEMNO(data.abAdNo);
  setSelectedemEMNOE(data.ab_AD_NM);
  onchangeFormData("rh_CU_NO", data.abAdNo);
  onchangeFormData("name", data.ab_AD_NM);
  setCustomerPopupVisible(false);
};

const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
const openAccountPopup = (e) => {
  setAccountPopupVisible(true);
  setSelectedRowIndex(e);
};
const handleDataSelect1 = async (data) => {
  setSelectedemEMNOA(data.am_AC_DS);
  onchangeFormData("rh_GL_BA", data.account);
  setAccountPopupVisible(false);
};
const isUpdateMode = Boolean(rh_UK_NO);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={rh_UK_NO ? "Revise Post Receipts" : "Add Post Receipts"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div className="formgrid grid">
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="rh_RC_NO" className="font-bold">
              Receipt Number
              </label>
              <InputText disabled
                id="rh_RC_NO"
                name="rh_RC_NO"
                value={rh_RC_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rh_RC_AM" className="font-bold">
                  Amount
                  </label>
                  <InputText disabled
                    id="rh_RC_AM"
                    name="rh_RC_AM"
                    defaultValue={rh_RC_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rh_DO_CO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="rh_DO_CO"
                    name="rh_DO_CO"
                    value={rh_DO_CO}
                    onChange={(e) => onchangedata(e)}
                  />
          </div>    
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rh_CU_NO" className="font-bold">
                  Customer<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText disabled
                    id="rh_CU_NO"
                    name="rh_CU_NO"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={SelectedemEMNO}
                  />
                   {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openCustomerPopup}
                    ></i>
                   )}
              </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="name" className="font-bold">
                  Name
                  </label>
                  <InputText disabled
                    id="name"
                    name="name"
                    value={SelectedemEMNOE}
                   readOnly
                  />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rh_GL_DT" className="font-bold">
                  GL Date
                  </label>
                  <Calendar disabled
                      id="rh_GL_DT"
                      name="rh_GL_DT"
                      value={moment(rh_GL_DT, "YYYYMMDD").toDate()}
                      onChange={(e) => onchangedata({ target: { name: "rh_GL_DT", value: moment(e.value).format("YYYYMMDD") } })}
                      showIcon
                      dateFormat="dd/mm/yy"
                    />    
                     
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rh_CR_CO" className="font-bold">
                  Currency
                  </label>
                  <Dropdown disabled
                    id="rh_CR_CO"
                    name="rh_CR_CO"
                    value={rh_CR_CO}
                    options={rh_CR_COOptions}
                    onChange={(e) => onchangeDropDownFormData1("rh_CR_CO", e.value)}
                    placeholder="Select Currency"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rh_CR_RT" className="font-bold">
                  Exchange Rate
                  </label>
                  <InputText disabled
                    id="rh_CR_RT"
                    name="rh_CR_RT"
                    value={rh_CR_RT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rh_RC_DT" className="font-bold">
                  Receipt Date
                  </label>
                  <Calendar disabled
                      id="rh_RC_DT"
                      name="rh_RC_DT"
                      value={moment(rh_RC_DT, "YYYYMMDD").toDate()}
                      onChange={(e) => onchangedata({ target: { name: "rh_RC_DT", value: moment(e.value).format("YYYYMMDD") } })}
                      showIcon
                      dateFormat="dd/mm/yy"
                    />    
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rh_GL_BA" className="font-bold">
                  Bank Account <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
              <InputText disabled
                id="accountDes"
                name="accountDes"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNOA}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openAccountPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="rh_GL_BA"
                name="rh_GL_BA"
                value={rh_GL_BA}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openAccountPopup}
              ></i>
            </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rh_RM_EX" className="font-bold">
                 Remarks
                  </label>
                  <InputText disabled
                    id="rh_RM_EX"
                    name="rh_RM_EX"
                    value={rh_RM_EX}
                    onChange={(e) => onchangedata(e)}
                  />
                 
                </div>
        </div>
      </div>
      <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            marginTop: "2%",
          }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
          }}
        >
          {!isUpdateMode && (
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          )}
           {!isUpdateMode && (
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
          )}
        </div>
        <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
            position: "relative",
          }}
        >
        <table className="custom-table" id="PostReceiptstable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Payment Id</th>
          <th>Payment Amount</th>
          <th>Discount Amount</th>
          <th>Write Off Amount</th>
          <th>Write Off Code</th>
          <th>Gross Amount</th>
          <th>Open Amount</th>
          <th>Document Number</th>
          <th>Document Type</th>
          <th>Company</th>
          <th>Pay Item</th>
          <th>Batch No</th>
          <th>Batch Type</th>
          <th>Batch Date</th>
          <th>Foreign Payment Amount</th>
          <th>Foreign Write Off Amount</th>
          <th>Foreign Disccount Amount</th>
          <th>G/L Post Code</th>
          <th>Void Flag</th>
          <th>Void Date</th>
          <th>Void Reason</th>
          <th>Line No</th>
        </tr>
      </thead>
      <tbody>
      {PostReceiptstable.map((row, index) => (
              <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
              id={`rh_UK_NO_${index}`}
              name={`rh_UK_NO_${index}`}
              defaultValue={row.rh_UK_NO}
            />
          </td>
          <td>
          <InputText  disabled style={{width:"120px"}}
            id={`rd_PY_ID_${index}`}
            name={`rd_PY_ID_${index}`}
            defaultValue={row.rd_PY_ID}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rd_PA_AM_${index}`}
            name={`rd_PA_AM_${index}`}
            defaultValue={row.rd_PA_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rd_DI_AM_${index}`}
            name={`rd_DI_AM_${index}`}
            defaultValue={row.rd_DI_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rd_WO_AM_${index}`}
            name={`rd_WO_AM_${index}`}
            defaultValue={row.rd_WO_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rd_WO_CO_${index}`}
            name={`rd_WO_CO_${index}`}
            defaultValue={row.rd_WO_CO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rd_GR_AM_${index}`}
            name={`rd_GR_AM_${index}`}
            defaultValue={row.rd_GR_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rd_OP_AM_${index}`}
            name={`rd_OP_AM_${index}`}
            defaultValue={row.rd_OP_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rd_DO_NO_${index}`}
            name={`rd_DO_NO_${index}`}
            defaultValue={row.rd_DO_NO}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`rd_DO_TY_${index}`}
            name={`rd_DO_TY_${index}`}
            defaultValue={row.rd_DO_TY}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`rd_DO_CO_${index}`}
            name={`rd_DO_CO_${index}`}
            defaultValue={row.rd_DO_CO}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`rd_DO_PI_${index}`}
            name={`rd_DO_PI_${index}`}
            defaultValue={row.rd_DO_PI}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rh_BA_NO_${index}`}
            name={`rh_BA_NO_${index}`}
            defaultValue={row.rh_BA_NO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rh_BA_TY_${index}`}
            name={`rh_BA_TY_${index}`}
            defaultValue={row.rh_BA_TY}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <Calendar  disabled style={{width:"150px"}}
            id={`rh_BA_DT_${index}`}
            name={`rh_BA_DT_${index}`}
            value={ row.rh_BA_DT ? moment(row.rh_BA_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "rh_BA_DT", value: moment(e.value).format("YYYYMMDD") } })}
            showIcon
            dateFormat="dd/mm/yy"
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rd_PA_FR_${index}`}
            name={`rd_PA_FR_${index}`}
            defaultValue={row.rd_PA_FR}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rh_OP_FR_${index}`}
            name={`rh_OP_FR_${index}`}
            defaultValue={row.rh_OP_FR}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rd_Di_FR_${index}`}
            name={`rd_Di_FR_${index}`}
            defaultValue={row.rd_Di_FR}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rd_GL_PO_${index}`}
            name={`rd_GL_PO_${index}`}
            defaultValue={row.rd_GL_PO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rd_VO_FL_${index}`}
            name={`rd_VO_FL_${index}`}
            defaultValue={row.rd_VO_FL}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          <td>
          <Calendar  disabled style={{width:"150px"}}
            id={`rd_VO_DT_${index}`}
            name={`rd_VO_DT_${index}`}
            value={ row.rd_VO_DT ? moment(row.rd_VO_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "rd_VO_DT", value: moment(e.value).format("YYYYMMDD") } })}
            showIcon
            dateFormat="dd/mm/yy"
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rd_VO_RE_${index}`}
            name={`rd_VO_RE_${index}`}
            defaultValue={row.rd_VO_RE}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rd_LN_NO_${index}`}
            name={`rd_LN_NO_${index}`}
            defaultValue={row.rd_LN_NO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          </tr>
           ))}
      </tbody>
    </table>   
    
    </div>
  </div>   
  <CustomerPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        formData={data}
        onDataSelect={handleDataSelect}
      />
      <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        bankFilter={bankFilter}
      />
    </Dialog>
  );
}
