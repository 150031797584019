import React, { useState, useEffect,useRef} from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import LeadOwnerPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import { faLink, faFileImage, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { } from '@fortawesome/free-solid-svg-icons';

const TaskPopup = ({visible,onClose,av_CC_NO, }) => {
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [ShowTaskPopup, setShowTaskPopup] = useState(false);
  const [TaskData, setTaskData] = useState([]); 
  const toast = useRef(null);
  const {
    av_TR_DT,
    // av_CC_NO,
    av_TE_XT,
    // av_PR_TY,
    av_AS_TO,
    cc_UK_NO,
    ab_AD_NM,
    abAdNo,
  } = TaskData;
  const [SelectedAbAdNo, setSelectedAbAdNo] = useState("");
  const [LeadOwnerPopupVisible, setLeadOwnerVisible] = useState(false);

  const openLeadOwnerPopup = () => {
    setSelectedAbAdNo(null);
    setLeadOwnerVisible(true);
  };
  const handleDataSelect = async (content) => {
    setSelectedAbAdNo(content.ab_AD_NM);
    setTaskData({
      ...TaskData,
      av_AS_TO: content.abAdNo,
      ab_AD_NM: content.ab_AD_NM
    });
    setLeadOwnerVisible(false);
  };
  const handleClose = () => {
    onClose();
    setShowTaskPopup(false);
  };
  useEffect(() => {
    setTaskData(prevNoteData => ({ ...prevNoteData,  av_TE_XT: '' ,av_TR_DT:'',av_AS_TO:'',av_PR_TY:'',  }));
    setSelectedAbAdNo("");
    const modal = document.getElementById("TaskModal");
    if (visible) {
      modal.classList.add("show");
      modal.style.display = "block";
    } else {
      modal.classList.remove("show");
      modal.style.display = "none";
    }
  }, [visible]);

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setTaskData({ ...TaskData, [name]: value });
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
    }
  };
  const StartTask = async () => {
    const addInput = {
      "av_CC_NO" : av_CC_NO,
      "av_TE_XT" : TaskData.av_TE_XT,
      "av_PR_TY" : TaskData.av_PR_TY,
      "av_AS_TO" : TaskData.av_AS_TO,
      "av_TR_DT" : TaskData.av_TR_DT,
    }
    try {
      const response = await axios.post(
        `${domain}/api/Activity/Task/Add`,addInput,
        {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

    const data = response.data;
    if(data){
      setTaskData(data);
      handleResponse(data);
    }
  }
  catch (error) {
      console.error("Error fetching data:", error);
    }
};
// const handleAddButtonClick = () => {
//   setSelectedAbAdNo(null);
// };
const isUpdateMode = Boolean(cc_UK_NO);
const productDialogFooter = (
  <React.Fragment>
  <Button 
    style={{width:"20%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={
      handleClose
        }
    tooltip="Cancel"
  >
  </Button>
  <Button   
    style={{width:"20%"}}
    icon="pi pi-check" 
    onClick={StartTask} 
    tooltip={isUpdateMode ? "Update" : "Save"}>
  </Button>
</React.Fragment>
);
const [addressTypeE, setaddressTypeE] = useState("E");
const [FormData, setFormData] = useState(TaskData);

const [av_PR_TY, setav_PR_TY] =useState("");
const [av_PR_TYOptions, setav_PR_TYOptions] = useState([]);

const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();

const onchangeDropDownFormData1 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setav_PR_TY(value);
};

useEffect(() => {
  fetchDropdownPriority();
}, []);

const fetchDropdownPriority = async () => {
  const Options = await fetchClassCodeDS("WO/PR");
  setav_PR_TYOptions(Options);
};

  return (
    <div
    className="modal fade"
    id="TaskModal"
    tabIndex="-1"
    aria-labelledby="noteModalLabel"
    aria-hidden="true"
    header={cc_UK_NO ? "Task" : "Task"}
    footer={productDialogFooter}
  >
   <div>
   <Toast ref={toast} />
      <div className="modal-dialog">
        <div className="modal-content" style={{backgroundColor:"#f9fbff"}}>
          <div className="modal-header" style={{backgroundColor:"aliceblue"}}>
            <h5 className="modal-title" id="noteModalLabel">
              Task
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">
              <div className="field row" style={{display:"none"}}>
                <label htmlFor="av_CC_NO" className="font-bold">
                Contact No
                </label>
                  <InputText
                    id="av_CC_NO"
                    name="av_CC_NO"
                    value={av_CC_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
              </div>
              <div className="field row" >
                <label htmlFor="av_TE_XT" className="font-bold">
              Text
                </label>
                <InputText
                    id="av_TE_XT"
                    name="av_TE_XT"
                    value={av_TE_XT}
                    onChange={(Event) => onchangedata(Event)}
                  />
              </div>
              <div className="field row" >
                <label htmlFor="av_AS_TO" className="font-bold">
                  Assigned To
                </label>
                <div style={{display:"flex"}}>
                <InputText
                    id="av_AS_TO"
                    name="av_AS_TO"
                    value={av_AS_TO}
                    onChange={(Event) => onchangedata(Event)}
                    // style={{ display:"none"}}
                  />
                <InputText style={{width:"100%"}}
                    id="av_AS_TO"
                    name="av_AS_TO"
                    value={SelectedAbAdNo}
                    onChange={(Event) => onchangedata(Event)}
                  />
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openLeadOwnerPopup}
                ></i>
                </div>
              </div>
              <div className="field row" >
                <label htmlFor="av_PR_TY" className="font-bold">
                    Priority
                </label>
                {/* <InputText
                    id="av_PR_TY"
                    name="av_PR_TY"
                    value={av_PR_TY}
                    onChange={(Event) => onchangedata(Event)}
                  /> */}
                <Dropdown
                    id="av_PR_TY"
                    name="av_PR_TY"
                    value={av_PR_TY}
                    options={av_PR_TYOptions}
                    onChange={(e) => onchangeDropDownFormData1("av_PR_TY", e.value)}
                    placeholder="Select Priority"
                  />
              </div>
              <div className="field row" >
                <label htmlFor="av_TR_DT" className="font-bold">
              Date
                </label>
              <Calendar
                id="av_TR_DT"
                name="av_TR_DT"
                value={moment(av_TR_DT, "YYYYMMDD").toDate()}
                onChange={(e) => onchangedata({ target: { name: "av_TR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                showIcon
                dateFormat="dd/mm/yy"
              />
              </div>
          </div>
          <div className="modal-footer">
            <Button
              icon="pi pi-times"
              variant="outlined"
              onClick={handleClose}
              tooltip="Cancel"
            />
            <Button
              icon="pi pi-check"
              onClick={StartTask}
              tooltip="Save"
            />
          </div>
          </div>
        </div>
      </div>
      <LeadOwnerPopup
        visible={LeadOwnerPopupVisible}
        onClose={() => setLeadOwnerVisible(false)}
        onDataSelect={handleDataSelect}
        formData={TaskData}
        addressType={addressTypeE}
      />
      </div>

  );
};

export default TaskPopup;
