import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  pdUkNo:"",
  pd_PY_ID:"",
  pd_PY_GP:"",
  pd_BA_AC:"",
  pd_TR_DT:"",
  pd_DO_NO:"",
  pd_DO_TY:"",
  pd_DO_CO:"",
  pd_DO_PI:"",
  pd_DD_DT:"",
  pd_GL_AM:"",
  pd_PA_AM:"",
  pd_DI_AM:"",
  pd_CR_CD:"",
  pd_SU_NO:"",
  pd_PA_FR:"",
  pd_DI_FR:"",
};

const initialFilters = {
  pd_PY_ID: { value: "", operation: "" },
  pd_PY_GP: { value: "", operation: "" },
  pd_BA_AC: { value: "", operation: "" },
  pd_TR_DT: { value: "", operation: "" },
  pd_DO_NO: { value: "", operation: "" },
  pd_DO_TY: { value: "", operation: "" },
  pd_DO_CO: { value: "", operation: "" },
  pd_DO_PI: { value: "", operation: "" },
  pd_DD_DT: { value: "", operation: "" },
  pd_GL_AM: { value: "", operation: "" },
  pd_PA_AM: { value: "", operation: "" },
  pd_DI_AM: { value: "", operation: "" },
  pd_CR_CD: { value: "", operation: "" },
  pd_SU_NO: { value: "", operation: "" },
  pd_PA_FR: { value: "", operation: "" },
  pd_DI_FR: { value: "", operation: "" },
};

const PaymentProcessingMain = () => {
    // buttonstyles
    const {formatDate, buttonStyles, addButtonStyles,
      copyButtonStyles,
      checkboxStyle,tableHeaderStyle,
      inputStyle,tableCellStyle,
     } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
// drodpdowns
  const [cp_BI_NG, setcp_BI_NG ] = useState("");
  const [cp_AC_ON, setcp_AC_ON ] = useState("");
  const [cp_LO_CN, setcp_LO_CN ] = useState("");
  const [cp_LN_GE, setcp_LN_GE ] = useState("");
  const [cp_DM_PH, setcp_DM_PH ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setcp_BI_NG(null);
    setcp_AC_ON(null);
    setcp_LO_CN(null);
    setcp_LN_GE(null);
    setcp_DM_PH(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/PaymentProcessing/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const PaymentProcessingData = response.data;
          delete PaymentProcessingData.pdUkNo;
          setFormData(PaymentProcessingData);
          setcp_BI_NG(PaymentProcessingData.cp_BI_NG);
          setcp_AC_ON(PaymentProcessingData.cp_AC_ON);
          setcp_LO_CN(PaymentProcessingData.cp_LO_CN);
          setcp_LN_GE(PaymentProcessingData.cp_LN_GE);
          setcp_DM_PH(PaymentProcessingData.cp_DM_PH);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [cp_CO_NO , setcp_CO_NO] = useState("");
 
    const handleDefault = async () => {
      setcp_CO_NO(`${company}`)
      const linkPath = String(window.location);
      const l_array = linkPath.split("=");
      const LinkId = l_array[1];
      
      const requestBody = {
        "version_ID" : LinkId
      };
      const paginationUrl = `${domain}/api/LinkId/Select`;
      try {
        const response = await axios.post(paginationUrl, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataDefault = response.data;
        const listDefault = [];
        var count = 0;
        if (dataDefault.versionIds != null) {
          for (let i = 0; i < dataDefault.versionIds.length; i++) {
            if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
              const fName = dataDefault.versionIds[i].field_ID;
           
            }
            if(dataDefault.versionIds[i].field_TYPE === "LIST"){
              count++;
              const def = {
                "column" : dataDefault.versionIds[i].field_ID ,
                "value" : dataDefault.versionIds[i].default_VALUE,
                "operation" : "EQUAL"
              }
              listDefault.push(def);
            }
          }
          handleSearch(listDefault,count);
        } else {
          handleSearch(listDefault,count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const handleProcess =  async () => {
      const  Url = `${domain}/api/PaymentProcess/APPHProcessDetails`
      const request = {
          "ph_PY_GP": FormData.pd_PY_GP,
      }
      axios.post(Url, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        handleResponse(resp.data);
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
    };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PaymentProcess/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
 
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/PaymentProcessing/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const PaymentProcessingData = response.data;
        setFormData(PaymentProcessingData);
        setcp_BI_NG(PaymentProcessingData.cp_BI_NG);
        setcp_AC_ON(PaymentProcessingData.cp_AC_ON);
        setcp_LO_CN(PaymentProcessingData.cp_LO_CN);
        setcp_LN_GE(PaymentProcessingData.cp_LN_GE);
        setcp_DM_PH(PaymentProcessingData.cp_DM_PH);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleProcess}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_PY_ID"] ? filters["pd_PY_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_PY_ID",
                        e.target.value,
                        filters["pd_PY_ID"]
                          ? filters["pd_PY_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_PY_GP"] ? filters["pd_PY_GP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_PY_GP",
                        e.target.value,
                        filters["pd_PY_GP"] ? filters["pd_PY_GP"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_BA_AC"] ? filters["pd_BA_AC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_BA_AC",
                        e.target.value,
                        filters["pd_BA_AC"]
                          ? filters["pd_BA_AC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_TR_DT"] ? filters["pd_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_TR_DT",
                        e.target.value,
                        filters["pd_TR_DT"]
                          ? filters["pd_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_DO_NO"] ? filters["pd_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_DO_NO",
                        e.target.value,
                        filters["pd_DO_NO"]
                          ? filters["pd_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_DO_TY"] ? filters["pd_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_DO_TY",
                        e.target.value,
                        filters["pd_DO_TY"]
                          ? filters["pd_DO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_DO_CO"] ? filters["pd_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_DO_CO",
                        e.target.value,
                        filters["pd_DO_CO"] ? filters["pd_DO_CO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_DO_PI"] ? filters["pd_DO_PI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_DO_PI",
                        e.target.value,
                        filters["pd_DO_PI"]
                          ? filters["pd_DO_PI"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_DD_DT"] ? filters["pd_DD_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_DD_DT",
                        e.target.value,
                        filters["pd_DD_DT"]
                          ? filters["pd_DD_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_GL_AM"] ? filters["pd_GL_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_GL_AM",
                        e.target.value,
                        filters["pd_GL_AM"]
                          ? filters["pd_GL_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_PA_AM"] ? filters["pd_PA_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_PA_AM",
                        e.target.value,
                        filters["pd_PA_AM"]
                          ? filters["pd_PA_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_DI_AM"] ? filters["pd_DI_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_DI_AM",
                        e.target.value,
                        filters["pd_DI_AM"]
                          ? filters["pd_DI_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_CR_CD"] ? filters["pd_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_CR_CD",
                        e.target.value,
                        filters["pd_CR_CD"]
                          ? filters["pd_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_SU_NO"] ? filters["pd_SU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_SU_NO",
                        e.target.value,
                        filters["pd_SU_NO"]
                          ? filters["pd_SU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_PA_FR"] ? filters["pd_PA_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_PA_FR",
                        e.target.value,
                        filters["pd_PA_FR"]
                          ? filters["pd_PA_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_DI_FR"] ? filters["pd_DI_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_DI_FR",
                        e.target.value,
                        filters["pd_DI_FR"]
                          ? filters["pd_DI_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Payment Id</th>
                <th style={tableHeaderStyle}>Payment Group</th>
                <th style={tableHeaderStyle}>Bank Account</th>
                <th style={tableHeaderStyle}>Transaction Date</th>
                <th style={tableHeaderStyle}>Document No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Document Pay Item</th>
                <th style={tableHeaderStyle}>Due Date</th>
                <th style={tableHeaderStyle}>Gross Amount</th>
                <th style={tableHeaderStyle}>Payment Amount</th>
                <th style={tableHeaderStyle}>Discount Amount</th>
                <th style={tableHeaderStyle}>Currency Code</th>
                <th style={tableHeaderStyle}>Supplier No</th>
                <th style={tableHeaderStyle}>Foreign Payment Amount</th>
                <th style={tableHeaderStyle}>Foreign Discount Amount</th>
            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.pdUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.pdUkNo}
                        checked={selectedRows.includes(rowData.pdUkNo)}
                        onChange={() => handleRowSelect(rowData.pdUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.pd_PY_ID}</td>
                    <td style={tableCellStyle}>{rowData.pd_PY_GP}</td>
                    <td style={tableCellStyle}>{rowData.pd_BA_AC}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.pd_TR_DT)}</td>
                    <td style={tableCellStyle}>{rowData.pd_DO_NO}</td>
                    <td style={tableCellStyle}>{rowData.pd_DO_TY}</td> 
                    <td style={tableCellStyle}>{rowData.pd_DO_CO}</td>
                    <td style={tableCellStyle}>{rowData.pd_DO_PI}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.pd_DD_DT)}</td>
                    <td style={tableCellStyle}>{rowData.pd_GL_AM}</td>
                    <td style={tableCellStyle}>{rowData.pd_PA_AM}</td> 
                    <td style={tableCellStyle}>{rowData.pd_DI_AM}</td>
                    <td style={tableCellStyle}>{rowData.pd_CR_CD}</td> 
                    <td style={tableCellStyle}>{rowData.pd_SU_NO}</td> 
                    <td style={tableCellStyle}>{rowData.pd_PA_FR}</td>
                    <td style={tableCellStyle}>{rowData.pd_DI_FR}</td>                
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
    </div>
  );
};

export default PaymentProcessingMain;
