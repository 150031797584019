import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ButtonStyles from "views/common/ButtonStyles";
import moment from "moment";
import axios from "axios";
import { Calendar } from "primereact/calendar";
export default function CostCentreDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  onchangeDropDownFormData9,
  onchangeDropDownFormData10,
  onchangeDropDownFormData11,
  onchangeDropDownFormData12,
  cc_CO_NO,
  SelectedemEMNO,
  setSelectedemEMNO,
  cc_AC_IA,
  setFormData,
}) {
  const {
    ccCcNo,
    cc_CC_NM,
    cc_CC_DS,
    cc_CC_TY,
    cc_AD_NO,
    cc_CL_01,
    cc_CL_02,
    cc_CL_03,
    cc_CL_04,
    cc_CL_05,
    cc_CL_06,
    cc_CL_07,
    cc_CL_08,
    cc_CL_09,
    cc_CL_10, 
  } = data;
  const {borderbox,inboxstyle} = ButtonStyles();
  const [cc_CC_TYOptions, setcc_CC_TYOptions] = useState([]);
  const [cc_CL_01Options, setcc_CL_01Options] = useState([]);
  const [cc_CL_02Options, setcc_CL_02Options] = useState([]);
  const [cc_CL_03Options, setcc_CL_03Options] = useState([]);
  const [cc_CL_04Options, setcc_CL_04Options] = useState([]);
  const [cc_CL_05Options, setcc_CL_05Options] = useState([]);
  const [cc_CL_06Options, setcc_CL_06Options] = useState([]);
  const [cc_CL_07Options, setcc_CL_07Options] = useState([]);
  const [cc_CL_08Options, setcc_CL_08Options] = useState([]);
  const [cc_CL_09Options, setcc_CL_09Options] = useState([]);
  const [cc_CL_10Options, setcc_CL_10Options] = useState([]);
  const [cc_AC_IAOptions, setcc_AC_IAOptions] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const url = `${domain}/api/ClassCode/All`;
  const parameter2 = "FO/CT";
  const finalUrl2 = `${url}/${parameter2}`;
  const parameter3 = "CC/01";
  const finalUrl3 = `${url}/${parameter3}`;
  const parameter4 = "CC/02";
  const finalUrl4 = `${url}/${parameter4}`;
  const parameter5 = "CC/03";
  const finalUrl5 = `${url}/${parameter5}`;
  const parameter6 = "CC/04";
  const finalUrl6 = `${url}/${parameter6}`;
  const parameter7 = "CC/05";
  const finalUrl7 = `${url}/${parameter7}`;
  const parameter8 = "CC/06";
  const finalUrl8 = `${url}/${parameter8}`;
  const parameter9 = "CC/07";
  const finalUrl9 = `${url}/${parameter9}`;
  const parameter10 = "CC/08";
  const finalUrl10 = `${url}/${parameter10}`;
  const parameter11 = "CC/09";
  const finalUrl11 = `${url}/${parameter11}`;
  const parameter12 = "CC/10";
  const finalUrl12 = `${url}/${parameter12}`;
  const parameter13 = "AB/ST";
  const finalUrl13 = `${url}/${parameter13}`;
  useEffect(() => {
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
    fetchDropdownOptions8();
    fetchDropdownOptions9();
    fetchDropdownOptions10();
    fetchDropdownOptions11();
    fetchDropdownOptions12();
    fetchDropdownOptions13();
  }, []);
// 2nd dropdowns
const fetchDropdownOptions2 = async () => {
  try {
    const response = await axios.get(finalUrl2, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcc_CC_TYOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 3rd dropdown
const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl3, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcc_CL_01Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 4th dropdown
const fetchDropdownOptions4 = async () => {
  try {
    const response = await axios.get(finalUrl4, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcc_CL_02Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 5th dropdown
const fetchDropdownOptions5 = async () => {
  try {
    const response = await axios.get(finalUrl5, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcc_CL_03Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 6th dropdown
const fetchDropdownOptions6 = async () => {
  try {
    const response = await axios.get(finalUrl6, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcc_CL_04Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions7 = async () => {
  try {
    const response = await axios.get(finalUrl7, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcc_CL_05Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions8 = async () => {
  try {
    const response = await axios.get(finalUrl8, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcc_CL_06Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions9 = async () => {
  try {
    const response = await axios.get(finalUrl9, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcc_CL_07Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions10 = async () => {
  try {
    const response = await axios.get(finalUrl10, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcc_CL_08Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions11 = async () => {
  try {
    const response = await axios.get(finalUrl11, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcc_CL_09Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions12 = async () => {
  try {
    const response = await axios.get(finalUrl12, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcc_CL_10Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions13 = async () => {
  try {
    const response = await axios.get(finalUrl13, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcc_AC_IAOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const isUpdateMode = Boolean(ccCcNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  const [AddressPopupVisible, setAddressPopupVisible] = useState(false);
  const [addressTypecust, setaddressTypecust] = useState("F");
  const openCustomerPopup = () => {
    setAddressPopupVisible(true);
  };
  //  startsearchpopup
  const handleDataSelect = (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("cc_AD_NO", data.abAdNo);
    setAddressPopupVisible(false);
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={ccCcNo ? "Revise Cost Centre" : "Add Cost Centre"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
       <div style={borderbox} >
        <div style={inboxstyle}>
            <div className="formgrid grid">
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cc_CC_NM" className="font-bold">
                      Cost Centre
                    </label>
                    <InputText
                      id="cc_CC_NM"
                      name="cc_CC_NM"
                      value={cc_CC_NM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cc_CC_DS" className="font-bold">
                      Description
                    </label>
                    <InputText
                      id="cc_CC_DS"
                      name="cc_CC_DS"
                      value={cc_CC_DS}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cc_CC_TY" className="font-bold">
                      Type
                    </label>
                    <Dropdown
                      name="cc_CC_TY"
                      value={cc_CC_TY}
                      options={cc_CC_TYOptions}
                      onChange={(e) => onchangeDropDownFormData1("cc_CC_TY", e.value)}
                      placeholder="Select Type"
                    />
                  </div>
              
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cc_CO_NO" className="font-bold">
                      Company
                    </label>
                    <InputText disabled
                      id="cc_CO_NO"
                      name="cc_CO_NO"
                      value={cc_CO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cc_AD_NO" className="font-bold">
                    Address <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedemEMNO}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openCustomerPopup}
                ></i>
                )}
                    </div>
                    <div style={{ display: "none" }}>
                <InputText
                  id="cc_AD_NO"
                  name="cc_AD_NO"
                  value={cc_AD_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openCustomerPopup}
                ></i>
              </div>
              </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cc_AC_IA" className="font-bold">
                      Status
                    </label>
                    <Dropdown
                      name="cc_AC_IA"
                      value={cc_AC_IA}
                      options={cc_AC_IAOptions}
                      onChange={(e) => onchangeDropDownFormData12("cc_AC_IA", e.value)}
                      placeholder="Select Status"
                    />
                  </div>
            </div>
          </div>
            <div style={inboxstyle} >
            <div className="formgrid grid">
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cc_CL_01" className="font-bold">
                   Class Code 1
                  </label>
                  <Dropdown
                    name="cc_CC_TY"
                    value={cc_CL_01}
                    options={cc_CL_01Options}
                    onChange={(e) => onchangeDropDownFormData2("cc_CL_01", e.value)}
                    placeholder="Select ClassCode 1"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cc_CL_02" className="font-bold">
                  Class Code 2
                  </label>
                  <Dropdown
                    name="cc_CL_02"
                    value={cc_CL_02}
                    options={cc_CL_02Options}
                    onChange={(e) => onchangeDropDownFormData3("cc_CL_02", e.value)}
                    placeholder="Select ClassCode 1"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cc_CL_03" className="font-bold">
                  Class Code 3
                  </label>
                  <Dropdown
                    name="cc_CL_03"
                    value={cc_CL_03}
                    options={cc_CL_03Options}
                    onChange={(e) => onchangeDropDownFormData4("cc_CL_03", e.value)}
                    placeholder="Select ClassCode 3"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cc_CL_04" className="font-bold">
                  Class Code 4
                  </label>
                  <Dropdown
                    name="cc_CL_04"
                    value={cc_CL_04}
                    options={cc_CL_04Options}
                    onChange={(e) => onchangeDropDownFormData5("cc_CL_04", e.value)}
                    placeholder="Select ClassCode 4"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cc_CL_05" className="font-bold">
                  Class Code 5
                  </label>
                  <Dropdown
                    name="cc_CL_05"
                    value={cc_CL_05}
                    options={cc_CL_05Options}
                    onChange={(e) => onchangeDropDownFormData6("cc_CL_05", e.value)}
                    placeholder="Select ClassCode 5"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_GR_EF" className="font-bold">
                  Class Code 6
                  </label>
                  <Dropdown
                    name="cc_CL_06"
                    value={cc_CL_06}
                    options={cc_CL_06Options}
                    onChange={(e) => onchangeDropDownFormData7("cc_CL_06", e.value)}
                    placeholder="Select ClassCode 6"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cc_CL_07" className="font-bold">
                  Class Code 7
                  </label>
                  <Dropdown
                    name="cc_CL_07"
                    value={cc_CL_07}
                    options={cc_CL_07Options}
                    onChange={(e) => onchangeDropDownFormData8("cc_CL_07", e.value)}
                    placeholder="Select ClassCode 7"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cc_CL_08" className="font-bold">
                  Class Code 8
                  </label>
                  <Dropdown
                    name="cc_CL_08"
                    value={cc_CL_08}
                    options={cc_CL_08Options}
                    onChange={(e) => onchangeDropDownFormData9("cc_CL_08", e.value)}
                    placeholder="Select ClassCode 8"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cc_CL_09" className="font-bold">
                  Class Code 9
                  </label>
                  <Dropdown
                    name="cc_CL_09"
                    value={cc_CL_09}
                    options={cc_CL_09Options}
                    onChange={(e) => onchangeDropDownFormData10("cc_CL_09", e.value)}
                    placeholder="Select ClassCode 9"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cc_CL_10" className="font-bold">
                  Class Code 10
                  </label>
                  <Dropdown
                    name="cc_CL_10"
                    value={cc_CL_10}
                    options={cc_CL_10Options}
                    onChange={(e) => onchangeDropDownFormData11("cc_CL_10", e.value)}
                    placeholder="Select ClassCode 10"
                  />
                </div>
              </div>
          </div>  
      </div>    
      <AddressBookPopup
        visible={AddressPopupVisible}
        onClose={() => setAddressPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
    </Dialog>
  );
}
