import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import WorkOrderTimeEntryDialog from "./WorkOrderTimeEntryDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  woUkNo:"",
  ep_PO_NM:"",
  po_NAME:"",
  ep_EX_CT:"",
  expanseCategoryDES:"",
  ep_EX_TY:"",
  expanseTypeDES:"",
  ep_EF_DT:"",
  ep_LO_CN:"",
  ep_DA_AL:"",
  ep_TL_PE:"",
  ep_AU_AM:"",
};

const initialFilters = {
  ep_PO_NM: { value: "", operation: "" },
  po_NAME: { value: "", operation: "" },
  ep_EX_CT: { value: "", operation: "" },
  expanseCategoryDES: { value: "", operation: "" },
  ep_EX_TY: { value: "", operation: "" },
  expanseTypeDES: { value: "", operation: "" },
  ep_EF_DT: { value: "", operation: "" },
  ep_LO_CN: { value: "", operation: "" },
  ep_DA_AL: { value: "", operation: "" },
  ep_TL_PE: { value: "", operation: "" },
  ep_AU_AM: { value: "", operation: "" },
};

const WorkOrderTimeEntryMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,rightalined
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

// AdvanceSearch
  const [EmployeeName, setEmployeeName ] = useState("");
  const [wo_CO_CT, setwo_CO_CT ] = useState("");
  const [wo_TY_HR, setwo_TY_HR ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [WorkOrderTimeEntryTableData, setWorkOrderTimeEntryTableData] = useState([{}]);
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setWorkOrderTimeEntryTableData([{}]);
    setEmployeeName(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/WorkOrderTimeEntry/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const CashFlowData = response.data;
          const updatedCashFlowData = CashFlowData.WorkOrderTimeEntryList.map((target) => {
            const UpdatedworkData = { ...target };
            delete UpdatedworkData.woUkNo;
            return UpdatedworkData;
          });
          setWorkOrderTimeEntryTableData(updatedCashFlowData);
          setFormData(updatedCashFlowData[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [wo_WO_CO , setwo_WO_CO] = useState("");

  const handleDefault = async () => {
    setwo_WO_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "WO_CO_CT"){
              onchangeDropDownFormData1("wo_CO_CT",dataDefault.versionIds[i].default_VALUE);
              setwo_CO_CT(dataDefault.versionIds[i].default_VALUE);
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/WorkOrderTimeEntry/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({...prevData,   [name]: value, }));
  };
  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
// Cost Centre
const onchangeDropDownFormData1 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setwo_CO_CT(value);
};
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/WorkOrderTimeEntry/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const CashFlowData = response.data;
        setWorkOrderTimeEntryTableData(CashFlowData.WorkOrderTimeEntryList);
        setFormData(CashFlowData.WorkOrderTimeEntryList[0]);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("WorkOrderTimeEntrytable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const workOrderTimeList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const TR_DT = document.getElementsByName("wo_TR_DT_" + i)[0];
    const EN_DT = document.getElementsByName("wo_EN_DT_" + i)[0];
    const details = {
        woUkNo : document.getElementById("woUkNo_" + i).value,
        wo_WO_NO : document.getElementById("wo_WO_NO_" + i).value,
        wo_WO_TY : document.getElementById("wo_WO_TY_" + i).value,
        wo_WO_CO : document.getElementById("wo_WO_CO_" + i).value,
        wo_SF_CD : document.getElementById("wo_SF_CD_" + i).value,
        wo_EM_NO : document.getElementById("wo_EM_NO_" + i).value,
        wo_WC_CT : document.getElementById("wo_WC_CT_" + i).value,
        wo_TY_HR : table.tBodies[0].rows[i].cells[9].children[0].children[1].children[0].value,
        wo_TR_DT : TR_DT.value?moment(TR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        wo_EN_DT : EN_DT.value?moment(EN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        wo_ST_TM : document.getElementById("wo_ST_TM_" + i).value.replaceAll(":", ""),
        wo_EN_TM : document.getElementById("wo_EN_TM_" + i).value.replaceAll(":", ""),
    };
    workOrderTimeList.push(details);
  }
  requestBody = {
    workOrderTimeList,
    woUkNo:FormData.woUkNo,
    wo_CO_CT:FormData.wo_CO_CT,
  };
};
const handleFormSubmit = () => {
  values ();
  if (FormData.woUkNo) {
    const confirm = window.confirm("Do you want to Update ?");
    const update = `${domain}/api/WorkOrderTimeEntry/Update`;
    if (confirm) {
      axios.put(update, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });
    }
  } else {
    axios.post(`${domain}/api/WorkOrderTimeEntry/Add`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        handleResponse(resp.data);
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  }
};
const leftToolbarTemplate = ( 
  <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack >
      <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
      Update
    </div>  
    </Tooltip>
      <Button   
      style={buttonStyles}
      icon="pi pi-check"
      outlined
      className="mr-2 update-button"
      onClick={handleUpdate}
      disabled={!isRecordSelected}
    ></Button>
    <Tooltip target=".add-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Add
      </div>
  </Tooltip>
    <Button 
      style={addButtonStyles}
      icon="pi pi-plus"     
      outlined
      className="mr-2 add-button"
      onClick={handleAddButtonClick}
    ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Copy
      </div>
  </Tooltip>
    <Button 
      style={copyButtonStyles}
      icon="pi pi-copy"
      outlined
      className="mr-2 copy-button"
      onClick={handleCopyData}
    ></Button>
  </React.Fragment>
);
const exportExcel = () => {
  import("xlsx").then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(tableData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "products_export.xlsx");
  });
};
const rightToolbarTemplate = (
  <React.Fragment>
    <Button
      style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
      icon="pi pi-upload"
      className="p-button-help"
      rounded
      onClick={exportExcel}
      data-pr-tooltip="XLS"
    />
  </React.Fragment>
);
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wo_CO_CT"] ? filters["wo_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wo_CO_CT",
                        e.target.value,
                        filters["wo_CO_CT"] ? filters["wo_CO_CT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wo_WC_CT"] ? filters["wo_WC_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wo_WC_CT",
                        e.target.value,
                        filters["wo_WC_CT"]
                          ? filters["wo_WC_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>      
                
                <td>
                  <input
                    type="text"
                    value={filters["wo_WO_NO"] ? filters["wo_WO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wo_WO_NO",
                        e.target.value,
                        filters["wo_WO_NO"]
                          ? filters["wo_WO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wo_WO_TY"] ? filters["wo_WO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wo_WO_TY",
                        e.target.value,
                        filters["wo_WO_TY"] ? filters["wo_WO_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wo_EM_NO"] ? filters["wo_EM_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wo_EM_NO",
                        e.target.value,
                        filters["wo_EM_NO"]
                          ? filters["wo_EM_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["wo_OP_SQ"] ? filters["wo_OP_SQ"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wo_OP_SQ",
                        e.target.value,
                        filters["wo_OP_SQ"]
                          ? filters["wo_OP_SQ"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["wo_TY_HR"] ? filters["wo_TY_HR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wo_TY_HR",
                        e.target.value,
                        filters["wo_TY_HR"]
                          ? filters["wo_TY_HR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["wo_EN_DT"] ? filters["wo_EN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wo_EN_DT",
                        e.target.value,
                        filters["wo_EN_DT"]
                          ? filters["wo_EN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["wo_SF_CD"] ? filters["wo_SF_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wo_SF_CD",
                        e.target.value,
                        filters["wo_SF_CD"]
                          ? filters["wo_SF_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["wo_ST_TM"] ? filters["wo_ST_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wo_ST_TM",
                        e.target.value,
                        filters["wo_ST_TM"]
                          ? filters["wo_ST_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wo_EN_TM"] ? filters["wo_EN_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wo_EN_TM",
                        e.target.value,
                        filters["wo_EN_TM"]
                          ? filters["wo_EN_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>    
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Cost Centre </th>
                <th style={tableHeaderStyle}>Work Centre</th>
                <th style={tableHeaderStyle}>Order No</th>  
                <th style={tableHeaderStyle}>Type</th>   
                <th style={tableHeaderStyle}>Employee No</th> 
                <th style={tableHeaderStyle}>Oper. Seq.</th>  
                <th style={tableHeaderStyle}>Type of Hrs</th>  
                <th style={tableHeaderStyle}>Date</th> 
                <th style={tableHeaderStyle}>Shift</th>  
                <th style={tableHeaderStyle}>Start Time</th>      
                <th style={tableHeaderStyle}>End Time</th>            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.woUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.woUkNo}
                        checked={selectedRows.includes(rowData.woUkNo)}
                        onChange={() => handleRowSelect(rowData.woUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.wo_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.wo_WC_CT}</td>
                    <td style={tableCellStyle}>{rowData.wo_WO_NO}</td>   
                    <td style={tableCellStyle}>{rowData.wo_WO_TY}</td>
                    <td style={tableCellStyle}>{rowData.wo_EM_NO}</td>
                    <td style={tableCellStyle}>{rowData.wo_OP_SQ}</td>   
                    <td style={tableCellStyle}>{rowData.wo_TY_HR}</td>   
                    <td style={tableCellStyle}>{formatDate(rowData.wo_EN_DT)}</td>
                    <td style={tableCellStyle}>{rowData.wo_SF_CD}</td>
                    <td style={tableCellStyle}>{rowData.wo_ST_TM}</td>    
                    <td style={tableCellStyle}>{rowData.wo_EN_TM}</td>           
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <WorkOrderTimeEntryDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        WorkOrderTimeEntryTableData={WorkOrderTimeEntryTableData}
        setWorkOrderTimeEntryTableData={setWorkOrderTimeEntryTableData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        wo_CO_CT={wo_CO_CT}
        setwo_CO_CT={setwo_CO_CT}
        wo_WO_CO={wo_WO_CO}
        wo_TY_HR={wo_TY_HR}
        setwo_TY_HR={setwo_TY_HR}
        EmployeeName={EmployeeName}
        setEmployeeName={setEmployeeName}
      />
    </div>
  );
};

export default WorkOrderTimeEntryMain;
