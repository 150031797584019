import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
export default function ItemMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  FormData,
  setFormData,
  im_SG_IT1,
  setim_SG_IT1,
  im_SG_IT2,
  setim_SG_IT2,
  im_SG_IT3,
  setim_SG_IT3,
  im_SG_IT4,
  setim_SG_IT4,
  im_SG_IT5,
  setim_SG_IT5,
  im_SG_IT,
  setim_SG_IT,
  im_PR_UM,
  setim_PR_UM,
  im_DU_UM,
  setim_DU_UM,
  im_ST_TY,
  setim_ST_TY,
  im_GL_CD,
  setim_GL_CD,
  im_LN_TY,
  setim_LN_TY,
  im_PD_GP,
  setim_PD_GP,
  im_PR_GP,
  setim_PR_GP,
  im_PR_MD,
  setim_PR_MD,
  im_PR_FM,
  setim_PR_FM,
  im_IN_CL,
  setim_IN_CL,
  im_PO_CN,
  setim_PO_CN,
  im_ST_PO,
  setim_ST_PO,
  im_FR_PO,
  setim_FR_PO,
  im_TO_PO,
  setim_TO_PO,
  im_GR_CN,
  setim_GR_CN,
  im_ST_GR,
  setim_ST_GR,
  im_FR_GR,
  setim_FR_GR,
  im_TO_GR,
  setim_TO_GR,
  im_IT_CD1,
  setim_IT_CD1,
  im_IT_CD2,
  setim_IT_CD2,
  im_IT_CD3,
  setim_IT_CD3,
  im_IT_CD4,
  setim_IT_CD4,
  im_IT_CD5,
  setim_IT_CD5,
  im_SA_CD1,
  setim_SA_CD1,
  im_SA_CD2,
  setim_SA_CD2,
  im_SA_CD3,
  setim_SA_CD3,
  im_SA_CD4,
  setim_SA_CD4,
  im_SA_CD5,
  setim_SA_CD5,
  im_PR_CD1,
  setim_PR_CD1,
  im_PR_CD2,
  setim_PR_CD2,
  im_PR_CD3,
  setim_PR_CD3,
  im_PR_CD4,
  setim_PR_CD4,
  im_PR_CD5,
  setim_PR_CD5,
  im_MF_CD1,
  setim_MF_CD1,
  im_MF_CD2,
  setim_MF_CD2,
  im_MF_CD3,
  setim_MF_CD3,
  im_MF_CD4,
  setim_MF_CD4,
  im_MF_CD5,
  setim_MF_CD5,
  im_WH_CD1,
  setim_WH_CD1,
  im_WH_CD2,
  setim_WH_CD2,
  im_WH_CD3,
  setim_WH_CD3,
  im_WH_CD4,
  setim_WH_CD4,
  im_WH_CD5,
  setim_WH_CD5,
  im_TR_CD1,
  setim_TR_CD1,
  im_TR_CD2,
  setim_TR_CD2,
  im_TR_CD3,
  setim_TR_CD3,
  im_TR_CD4,
  setim_TR_CD4,
  im_TR_CD5,
  setim_TR_CD5,
  im_SC_UM,
  setim_SC_UM,
  im_PO_UM,
  setim_PO_UM,
  im_SO_UM,
  setim_SO_UM,
  im_SH_UM,
  setim_SH_UM,
  im_PD_UM,
  setim_PD_UM,
  im_CO_UM,
  setim_CO_UM,
  im_WT_UM,
  setim_WT_UM,
  im_VL_UM,
  setim_VL_UM,
  im_PL_CD,
  setim_PL_CD,
  im_PL_RL,
  setim_PL_RL,
  im_CS_WT,
  setim_CS_WT,
  im_JW_WT,
  setim_JW_WT,
  im_WX_WT,
  setim_WX_WT,
  im_KT_WT,
  setim_KT_WT,
  im_JW_TY,
  setim_JW_TY,
  im_TX_AP,
  setim_TX_AP,
  im_VA_TX,
  setim_VA_TX,
  im_GS_RT,
  setim_GS_RT,
  im_CH_NO,
  setim_CH_NO,
  im_FD_TX,
  setim_FD_TX,
  im_ST_TX,
  setim_ST_TX,
  im_CO_NO,
}) {
  const {
    imItNo,
    im_IT_NM,
    im_IT_DS,
    im_TO_PE,
    im_SH_LF,
    im_BB_DA,
    im_PL_FE,
    im_LD_TM,
    im_RO_QT,
    im_RO_LV,
    im_SF_ST,
    im_MI_QT,
    im_MX_QT,
    im_AD_IN,
    im_PL_NO,
    im_SU_NO,

  } = data;
  const {borderbox,inboxstyle} = ButtonStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [AddresstypeE, setAddresstypeE] = useState("E");
  const [AddresstypeS, setAddresstypeS] = useState("S");
  // dropdowns

  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [im_SG_IT1Options, setim_SG_IT1Options] = useState([]);
  const [im_SG_IT2Options, setim_SG_IT2Options] = useState([]);
  const [im_SG_IT3Options, setim_SG_IT3Options] = useState([]);
  const [im_SG_IT4Options, setim_SG_IT4Options] = useState([]);
  const [im_SG_IT5Options, setim_SG_IT5Options] = useState([]);
  const [im_SG_ITOptions, setim_SG_ITOptions] = useState([]);
  const [im_DU_UMOptions, setim_DU_UMOptions] = useState([]);
  const [im_ST_TYOptions, setim_ST_TYOptions] = useState([]);
  const [im_GL_CDOptions, setim_GL_CDOptions] = useState([]);
  const [im_LN_TYOptions, setim_LN_TYOptions] = useState([]);
  const [im_PD_GPOptions, setim_PD_GPOptions] = useState([]);
  const [im_PR_GPOptions, setim_PR_GPOptions] = useState([]);
  const [im_PR_MDOptions, setim_PR_MDOptions] = useState([]);
  const [im_PR_FMOptions, setim_PR_FMOptions] = useState([]);
  const [im_IN_CLOptions, setim_IN_CLOptions] = useState([]);
  const [im_PO_CNOptions, setim_PO_CNOptions] = useState([]);
  const [im_ST_POOptions, setim_ST_POOptions] = useState([]);
  const [im_FR_POOptions, setim_FR_POOptions] = useState([]);
  const [im_TO_POOptions, setim_TO_POOptions] = useState([]);
  const [im_GR_CNOptions, setim_GR_CNOptions] = useState([]);
  const [im_ST_GROptions, setim_ST_GROptions] = useState([]);
  const [im_FR_GROptions, setim_FR_GROptions] = useState([]);
  const [im_TO_GROptions, setim_TO_GROptions] = useState([]);
  const [im_IT_CD1Options, setim_IT_CD1Options] = useState([]);
  const [im_IT_CD2Options, setim_IT_CD2Options] = useState([]);
  const [im_IT_CD3Options, setim_IT_CD3Options] = useState([]);
  const [im_IT_CD4Options, setim_IT_CD4Options] = useState([]);
  const [im_IT_CD5Options, setim_IT_CD5Options] = useState([]);
  const [im_SA_CD1Options, setim_SA_CD1Options] = useState([]);
  const [im_SA_CD2Options, setim_SA_CD2Options] = useState([]);
  const [im_SA_CD3Options, setim_SA_CD3Options] = useState([]);
  const [im_SA_CD4Options, setim_SA_CD4Options] = useState([]);
  const [im_SA_CD5Options, setim_SA_CD5Options] = useState([]);
  const [im_PR_CD1Options, setim_PR_CD1Options] = useState([]);
  const [im_PR_CD2Options, setim_PR_CD2Options] = useState([]);
  const [im_PR_CD3Options, setim_PR_CD3Options] = useState([]);
  const [im_PR_CD4Options, setim_PR_CD4Options] = useState([]);
  const [im_PR_CD5Options, setim_PR_CD5Options] = useState([]);
  const [im_MF_CD1Options, setim_MF_CD1Options] = useState([]);
  const [im_MF_CD2Options, setim_MF_CD2Options] = useState([]);
  const [im_MF_CD3Options, setim_MF_CD3Options] = useState([]);
  const [im_MF_CD4Options, setim_MF_CD4Options] = useState([]);
  const [im_MF_CD5Options, setim_MF_CD5Options] = useState([]);
  const [im_WH_CD1Options, setim_WH_CD1Options] = useState([]);
  const [im_WH_CD2Options, setim_WH_CD2Options] = useState([]);
  const [im_WH_CD3Options, setim_WH_CD3Options] = useState([]);
  const [im_WH_CD4Options, setim_WH_CD4Options] = useState([]);
  const [im_WH_CD5Options, setim_WH_CD5Options] = useState([]);
  const [im_TR_CD1Options, setim_TR_CD1Options] = useState([]);
  const [im_TR_CD2Options, setim_TR_CD2Options] = useState([]);
  const [im_TR_CD3Options, setim_TR_CD3Options] = useState([]);
  const [im_TR_CD4Options, setim_TR_CD4Options] = useState([]);
  const [im_TR_CD5Options, setim_TR_CD5Options] = useState([]);
  const [im_PR_UMOptions, setim_PR_UMOptions] = useState([]);
  const [im_SC_UMOptions, setim_SC_UMOptions] = useState([]);
  const [im_PO_UMOptions, setim_PO_UMOptions] = useState([]);
  const [im_SO_UMOptions, setim_SO_UMOptions] = useState([]);
  const [im_SH_UMOptions, setim_SH_UMOptions] = useState([]);
  const [im_PD_UMOptions, setim_PD_UMOptions] = useState([]);
  const [im_CO_UMOptions, setim_CO_UMOptions] = useState([]);
  const [im_WT_UMOptions, setim_WT_UMOptions] = useState([]);
  const [im_VL_UMOptions, setim_VL_UMOptions] = useState([]);
  const [im_PL_CDOptions, setim_PL_CDOptions] = useState([]);
  const [im_PL_RLOptions, setim_PL_RLOptions] = useState([]);
  const [im_CS_WTOptions, setim_CS_WTOptions] = useState([]);
  const [im_JW_WTOptions, setim_JW_WTOptions] = useState([]);
  const [im_WX_WTOptions, setim_WX_WTOptions] = useState([]);
  const [im_KT_WTOptions, setim_KT_WTOptions] = useState([]);
  const [im_JW_TYOptions, setim_JW_TYOptions] = useState([]);
  const [im_TX_APOptions, setim_TX_APOptions] = useState([]);
  const [im_VA_TXOptions, setim_VA_TXOptions] = useState([]);
  const [im_GS_RTOptions, setim_GS_RTOptions] = useState([]);
  const [im_CH_NOOptions, setim_CH_NOOptions] = useState([]);
  const [im_FD_TXOptions, setim_FD_TXOptions] = useState([]);
  const [im_ST_TXOptions, setim_ST_TXOptions] = useState([]);
  
 

// SegmentDropdown
  const SegmentDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_SG_IT1(value);
  };

  // SegmentDropdown
  const Segment1Dropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_SG_IT2(value);
  };

  // SegmentDropdown
  const Segment2Dropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_SG_IT3(value);
  };

  // SegmentDropdown
  const Segment3Dropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_SG_IT4(value);
  };

  // SegmentDropdown
  const Segment4Dropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_SG_IT5(value);
  };

  // SegmentDropdown
  const SegmentedDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_SG_IT(value);
  };
// UnitOfMeasureDropdown
  const UnitOfMeasureDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_PR_UM(value);
  };
// DualUnitDropdown
  const DualUnitDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_DU_UM(value);
  };
// StockingTypeDropdown
  const StockingTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_ST_TY(value);
  };

  // StockingTypeDropdown
  const GLDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_GL_CD(value);
  };

  // ClassCodeDropDown
  const LineTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_LN_TY(value);
  };

  // ClassCodeDropDown
  const ProductGroupDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_PD_GP(value);
  }
   // ClassCodeDropDown
  const PriceGroupDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_PR_GP(value);
  }
  // ClassCodeDropDown
  const ProductModelDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_PR_MD(value);
  }
  // ClassCodeDropDown
  const ProductFamilyDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_PR_FM(value);
  }
  // ClassCodeDropDown
  const InventoryLevelDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_IN_CL(value);
  }
  // ClassCodeDropDown
  const PotencyControlDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_PO_CN(value);
  }
  // ClassCodeDropDown
  const StandardPotencyDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_ST_PO(value);
  }
  // ClassCodeDropDown
  const FromPotencyDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_FR_PO(value);
  }
  // ClassCodeDropDown
  const ToPotencyDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_TO_PO(value);
  }
    // ClassCodeDropDown
    const GradeControlDropdown = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setim_GR_CN(value);
    }
    // ClassCodeDropDown
    const StandardGradeDropdown = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setim_ST_GR(value);
    }
    // ClassCodeDropDown
    const FromGradeDropdown = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setim_FR_GR(value);
    }
    // ClassCodeDropDown
    const ToGradeDropdown = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setim_TO_GR(value);
    }
 // ClassCodeDropDown
 const ClassCodeDropDown1 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_IT_CD1(value);
};

// ClassCodeDropDown
const ClassCodeDropDown2 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_IT_CD2(value);
}
 // ClassCodeDropDown
const ClassCodeDropDown3 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_IT_CD3(value);
}
// ClassCodeDropDown
const ClassCodeDropDown4 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_IT_CD4(value);
}
// ClassCodeDropDown
const ClassCodeDropDown5 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_IT_CD5(value);
}

// ClassCodeDropDown
const ClassCodeDropDown6 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_SA_CD1(value);
}
// ClassCodeDropDown
const ClassCodeDropDown7 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_SA_CD2(value);
}
// ClassCodeDropDown
const ClassCodeDropDown8 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_SA_CD3(value);
}
// ClassCodeDropDown
const ClassCodeDropDown9 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_SA_CD4(value);
}
// ClassCodeDropDown
const ClassCodeDropDown10 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_SA_CD5(value);
}

 // ClassCodeDropDown
 const ClassCodeDropDown11 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_PR_CD1(value);
};

// ClassCodeDropDown
const ClassCodeDropDown12 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_PR_CD2(value);
}
 // ClassCodeDropDown
const ClassCodeDropDown13 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_PR_CD3(value);
}
// ClassCodeDropDown
const ClassCodeDropDown14 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_PR_CD4(value);
}
// ClassCodeDropDown
const ClassCodeDropDown15 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_PR_CD5(value);
}
// ClassCodeDropDown
const ClassCodeDropDown16 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_MF_CD1(value);
}
// ClassCodeDropDown
const ClassCodeDropDown17 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_MF_CD2(value);
}
// ClassCodeDropDown
const ClassCodeDropDown18 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_MF_CD3(value);
}
// ClassCodeDropDown
const ClassCodeDropDown19 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_MF_CD4(value);
}
// ClassCodeDropDown
const ClassCodeDropDown20 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setim_MF_CD5(value);
}
  // ClassCodeDropDown
  const ClassCodeDropDown21 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_WH_CD1(value);
  };

  // ClassCodeDropDown
  const ClassCodeDropDown22 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_WH_CD2(value);
  }
   // ClassCodeDropDown
  const ClassCodeDropDown23 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_WH_CD3(value);
  }
  // ClassCodeDropDown
  const ClassCodeDropDown24 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_WH_CD4(value);
  }
  // ClassCodeDropDown
  const ClassCodeDropDown25 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_WH_CD5(value);
  }
  // ClassCodeDropDown
  const ClassCodeDropDown26 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_TR_CD1(value);
  }
  // ClassCodeDropDown
  const ClassCodeDropDown27 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_TR_CD2(value);
  }
  // ClassCodeDropDown
  const ClassCodeDropDown28 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_TR_CD3(value);
  }
  // ClassCodeDropDown
  const ClassCodeDropDown29 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_TR_CD4(value);
  }
  // ClassCodeDropDown
  const ClassCodeDropDown30 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_TR_CD5(value);
  }  
  // BIDDING type
  const primaryDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_PR_UM(value);
  };
// Acq
  const SecondaryDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_SC_UM(value);
  };
// loc
  const PurchasingDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_PO_UM(value);
  };
// lang
  const PrisingDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_SO_UM(value);
  };
// demogr
  const ShppingDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_SH_UM(value);
  };

  const ProductionDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_PD_UM(value);
  };
// Acq
  const ComponentDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_CO_UM(value);
  };
// loc
  const WeightDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_WT_UM(value);
  };
// lang
  const VolumeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_VL_UM(value);
  };

  // BIDDING type
  const PlanningCodeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_PL_CD(value);
  };
// Acq
  const PlanningRuleDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_PL_RL(value);
  };
// loc
  const CastingWeightDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_CS_WT(value);
  };
// lang
  const jewelleryWeightDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_JW_WT(value);
  };
  // loc
  const WaxWeightDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_WX_WT(value);
  };
// lang
  const KaratDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_KT_WT(value);
  };
  // loc
  const JewelleryTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_JW_TY(value);
  };

  // Acq
  const ApplicableDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_TX_AP(value);
  };
// loc
  const AddedTaxDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_VA_TX(value);
  };
// lang
  const GSTDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_GS_RT(value);
  };
  // loc
  const ChapterDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_CH_NO(value);
  };
// lang
  const FederralTaxDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_FD_TX(value);
  };
  // loc
  const StateTaxDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_ST_TX(value);
  };
  useEffect(() => {
    SegmentedItem();
    SegmentedItem1();
    SegmentedItem2();
    SegmentedItem3();
    SegmentedItem4();
    Segmented();
    UnitMeasure();
    PickingZone();
    StockingType();
    GLCode();
    LineType();
    ProductGroup();
    PriceGroup();
    ProductModel();
    ProductFamily();
    Inventorylevel();
    Potencycontrol();
    StandardPotency();
    FromPotency();
    ToPotency();
    Gradecontrol();
    StandardGrade();
    FromGrade();
    ToGrade();
    ClassCode1();
    ClassCode2();
    ClassCode3();
    ClassCode4();
    ClassCode5();
    ClassCode6();
    ClassCode7();
    ClassCode8();
    ClassCode9();
    ClassCode10();
    ClassCode11();
    ClassCode12();
    ClassCode13();
    ClassCode14();
    ClassCode15();
    ClassCode16();
    ClassCode17();
    ClassCode18();
    ClassCode19();
    ClassCode20();
    ClassCode21();
    ClassCode22();
    ClassCode23();
    ClassCode24();
    ClassCode25();
    ClassCode26();
    ClassCode27();
    ClassCode28();
    ClassCode29();
    ClassCode30();
    Primary();
    Secondary();
    Purchasing();
    Pricing();
    Shipping();
    Production();
    Component();
    Weight();
    Volume();
    PlanningCode();
    PlanningRule();
    CastWeight();
    JewelleryWeight();
    WaxWeight();
    Karat();
    JewelleryType();
    Applicable();
    AddedTax();
    GST();
    Chapter();
    FederralTax();
    StateTax();

  }, []);

  const SegmentedItem = async () => {
    const Options = await fetchClassCodeCD("IN/01");
    setim_SG_IT1Options(Options);
  };

  const SegmentedItem1 = async () => {
    const Options = await fetchClassCodeCD("IN/02");
    setim_SG_IT2Options(Options);
  };

  const SegmentedItem2 = async () => {
    const Options = await fetchClassCodeCD("IN/03");
    setim_SG_IT3Options(Options);
  };

  const SegmentedItem3 = async () => {
    const Options = await fetchClassCodeCD("IN/04");
    setim_SG_IT4Options(Options);
  };

  const SegmentedItem4 = async () => {
    const Options = await fetchClassCodeCD("IN/05");
    setim_SG_IT5Options(Options);
  };

  const Segmented = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setim_SG_ITOptions(Options);
  };

  const UnitMeasure = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setim_PR_UMOptions(Options);
  };

  const PickingZone = async () => {
    const Options = await fetchClassCodeDS("LC/ZN");
    setim_DU_UMOptions(Options);
  };

  const StockingType = async () => {
    const Options = await fetchClassCodeDS("IN/TY");
    setim_ST_TYOptions(Options);
  };

  const GLCode = async () => {
    const Options = await fetchClassCodeCD("IN/GC");
    setim_GL_CDOptions(Options);
  };

  const LineType = async () => {
    const Options = await fetchClassCodeDS("LN/TY");
    setim_LN_TYOptions(Options);
  };

  const ProductGroup = async () => {
    const Options = await fetchClassCodeDS("IN/PD");
    setim_PD_GPOptions(Options);
  };

  const PriceGroup = async () => {
    const Options = await fetchClassCodeDS("IN/PR");
    setim_PR_GPOptions(Options);
  };

  const ProductModel = async () => {
    const Options = await fetchClassCodeDS("IN/PM");
    setim_PR_MDOptions(Options);
  };

  const ProductFamily = async () => {
    const Options = await fetchClassCodeDS("IN/PF");
    setim_PR_FMOptions(Options);
  };

  const Inventorylevel = async () => {
    const Options = await fetchClassCodeDS("IN/CL");
    setim_IN_CLOptions(Options);
  };

  const Potencycontrol = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setim_PO_CNOptions(Options);
  };

  const StandardPotency = async () => {
    const Options = await fetchClassCodeDS("IN/PO");
    setim_ST_POOptions(Options);
  };

  const FromPotency = async () => {
    const Options = await fetchClassCodeDS("IN/PO");
    setim_FR_POOptions(Options);
  };

  const ToPotency = async () => {
    const Options = await fetchClassCodeDS("IN/PO");
    setim_TO_POOptions(Options);
  };

  const Gradecontrol = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setim_GR_CNOptions(Options);
  };

  const StandardGrade = async () => {
    const Options = await fetchClassCodeDS("IN/GR");
    setim_ST_GROptions(Options);
  };

  const FromGrade = async () => {
    const Options = await fetchClassCodeDS("IN/GR");
    setim_FR_GROptions(Options);
  };

  const ToGrade = async () => {
    const Options = await fetchClassCodeDS("IN/GR");
    setim_TO_GROptions(Options);
  };

  const ClassCode1 = async () => {
    const Options = await fetchClassCodeCD("IN/01");
    setim_IT_CD1Options(Options);
  };

  const ClassCode2 = async () => {
    const Options = await fetchClassCodeCD("IN/02");
    setim_IT_CD2Options(Options);
  };

  const ClassCode3 = async () => {
    const Options = await fetchClassCodeCD("IN/03");
    setim_IT_CD3Options(Options);
  };

  const ClassCode4 = async () => {
    const Options = await fetchClassCodeCD("IN/04");
    setim_IT_CD4Options(Options);
  };

  const ClassCode5 = async () => {
    const Options = await fetchClassCodeCD("IN/05");
    setim_IT_CD5Options(Options);
  };

  
  const ClassCode6 = async () => {
    const Options = await fetchClassCodeCD("IN/S1");
    setim_SA_CD1Options(Options);
  };

  const ClassCode7 = async () => {
    const Options = await fetchClassCodeCD("IN/S2");
    setim_SA_CD2Options(Options);
  };

  const ClassCode8 = async () => {
    const Options = await fetchClassCodeCD("IN/S3");
    setim_SA_CD3Options(Options);
  };

  const ClassCode9 = async () => {
    const Options = await fetchClassCodeCD("IN/S4");
    setim_SA_CD4Options(Options);
  };

  const ClassCode10 = async () => {
    const Options = await fetchClassCodeCD("IN/S5");
    setim_SA_CD5Options(Options);
  };

  const ClassCode11 = async () => {
    const Options = await fetchClassCodeCD("IN/P1");
    setim_PR_CD1Options(Options);
  };

  const ClassCode12 = async () => {
    const Options = await fetchClassCodeCD("IN/P2");
    setim_PR_CD2Options(Options);
  };

  const ClassCode13 = async () => {
    const Options = await fetchClassCodeCD("IN/P3");
    setim_PR_CD3Options(Options);
  };

  const ClassCode14 = async () => {
    const Options = await fetchClassCodeCD("IN/P4");
    setim_PR_CD4Options(Options);
  };

  const ClassCode15 = async () => {
    const Options = await fetchClassCodeCD("IN/P5");
    setim_PR_CD5Options(Options);
  };

  
  const ClassCode16 = async () => {
    const Options = await fetchClassCodeCD("IN/M1");
    setim_MF_CD1Options(Options);
  };

  const ClassCode17 = async () => {
    const Options = await fetchClassCodeCD("IN/M2");
    setim_MF_CD2Options(Options);
  };

  const ClassCode18 = async () => {
    const Options = await fetchClassCodeCD("IN/M3");
    setim_MF_CD3Options(Options);
  };

  const ClassCode19 = async () => {
    const Options = await fetchClassCodeCD("IN/M4");
    setim_MF_CD4Options(Options);
  };

  const ClassCode20 = async () => {
    const Options = await fetchClassCodeCD("IN/M5");
    setim_MF_CD5Options(Options);
  };

  const ClassCode21 = async () => {
    const Options = await fetchClassCodeCD("IN/W1");
    setim_WH_CD1Options(Options);
  };

  const ClassCode22 = async () => {
    const Options = await fetchClassCodeCD("IN/W2");
    setim_WH_CD2Options(Options);
  };

  const ClassCode23 = async () => {
    const Options = await fetchClassCodeCD("IN/W3");
    setim_WH_CD3Options(Options);
  };

  const ClassCode24 = async () => {
    const Options = await fetchClassCodeCD("IN/W4");
    setim_WH_CD4Options(Options);
  };

  const ClassCode25 = async () => {
    const Options = await fetchClassCodeCD("IN/W5");
    setim_WH_CD5Options(Options);
  };

  const ClassCode26 = async () => {
    const Options = await fetchClassCodeCD("IN/T1");
    setim_TR_CD1Options(Options);
  };

  const ClassCode27 = async () => {
    const Options = await fetchClassCodeCD("IN/T2");
    setim_TR_CD2Options(Options);
  };

  const ClassCode28 = async () => {
    const Options = await fetchClassCodeCD("IN/T3");
    setim_TR_CD3Options(Options);
  };

  const ClassCode29 = async () => {
    const Options = await fetchClassCodeCD("IN/T4");
    setim_TR_CD4Options(Options);
  };

  const ClassCode30 = async () => {
    const Options = await fetchClassCodeCD("IN/T5");
    setim_TR_CD5Options(Options);
  };

  const Primary = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setim_PR_UMOptions(Options);
  };

  const Secondary = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setim_SC_UMOptions(Options);
  };

  const Purchasing = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setim_PO_UMOptions(Options);
  };

  const Pricing = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setim_SO_UMOptions(Options);
  };

  const Shipping = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setim_SH_UMOptions(Options);
  };

  const Production = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setim_PD_UMOptions(Options);
  };

  const Component = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setim_CO_UMOptions(Options);
  };

  const Weight = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setim_WT_UMOptions(Options);
  };

  const Volume = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setim_VL_UMOptions(Options);
  };
  const PlanningCode = async () => {
    const Options = await fetchClassCodeDS("IN/PC");
    setim_PL_CDOptions(Options);
  };

  const PlanningRule = async () => {
    const Options = await fetchClassCodeDS("IN/PR");
    setim_PL_RLOptions(Options);
  };

  const CastWeight = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setim_CS_WTOptions(Options);
  };

  const JewelleryWeight = async () => {
    const Options = await fetchClassCodeCD("TX/RT");
    setim_JW_WTOptions(Options);
  };

  const WaxWeight = async () => {
    const Options = await fetchClassCodeDS("TX/RT");
    setim_WX_WTOptions(Options);
  };

  const Karat = async () => {
    const Options = await fetchClassCodeDS("TX/RT");
    setim_KT_WTOptions(Options);
  };

  const JewelleryType = async () => {
    const Options = await fetchClassCodeDS("JW/TY");
    setim_JW_TYOptions(Options);
  };
  const Applicable = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setim_TX_APOptions(Options);
  };

  const AddedTax = async () => {
    const Options = await fetchClassCodeDS("IN/VT");
    setim_VA_TXOptions(Options);
  };

  const GST = async () => {
    const Options = await fetchClassCodeDS("GS/RT");
    setim_GS_RTOptions(Options);
  };

  const Chapter = async () => {
    const Options = await fetchClassCodeDS("CH/NO");
    setim_CH_NOOptions(Options);
  };

  const FederralTax = async () => {
    const Options = await fetchClassCodeDS("IN/FT");
    setim_FD_TXOptions(Options);
  };

  const StateTax = async () => {
    const Options = await fetchClassCodeDS("IN/ST");
    setim_ST_TXOptions(Options);
  };


  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedPlanner, setSelectedPlanner] = useState("");
  const [SelectedSupplier, setSelectedSupplier] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [PlannerPopupVisible, setPlannerPopupVisible] = useState(false);
  const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
  const openPlannerPopup = () => {
    setPlannerPopupVisible(true);
  }
  const openSupplierPopup = () => {
    setSupplierPopupVisible(true);
  }
  const handlePlanner = async (data) => {
    setSelectedPlanner(data.ab_AD_NM);
    onchangeFormData("im_PL_NO", data.abAdNo);
    setPlannerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSupplier = async (data) => {
    setSelectedSupplier(data.ab_AD_NM);
    onchangeFormData("im_SU_NO", data.abAdNo);
    setSupplierPopupVisible(false);
    setShowSearchIcon(false);
  };

const isUpdateMode = Boolean(imItNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={imItNo ? "Revise ItemMaster" : "Add ItemMaster"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Item Details">
            {/* tab1 general */}

            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="im_IT_NM" className="font-bold">
              Item No
              </label>
              <InputText 
                id="im_IT_NM"
                name="im_IT_NM"
                value={im_IT_NM}
                onChange={(e) => onchangedata(e)}
              />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="im_IT_DS" className="font-bold">
            Description
              </label>
              <InputText 
                id="im_IT_DS"
                name="im_IT_DS"
                value={im_IT_DS}
                onChange={(e) => onchangedata(e)}
              />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="im_CO_NO" className="font-bold">
            Company
              </label>
              <InputText disabled
                id="im_CO_NO"
                name="im_CO_NO"
                value={im_CO_NO}
                onChange={(e) => onchangedata(e)}
              />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_SG_IT" className="font-bold">
                    Segmented
                  </label>
                  <Dropdown
                    name="im_SG_IT"
                    value={im_SG_IT}
                    options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                      ]}
                    onChange={(e) => SegmentedDropdown("im_SG_IT", e.value)}
                    placeholder="Select Segmented"
                  />
                    </div>    
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_SG_IT1" className="font-bold">
                     Segment
                    </label>
                    <Dropdown
                    name="im_SG_IT1"
                    value={im_SG_IT1}
                    options={im_SG_IT1Options}
                    onChange={(e) => SegmentDropdown("im_SG_IT1", e.value)}
                    placeholder="Select Segment"
                    disabled={im_SG_IT !== "y"}
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_SG_IT2" className="font-bold">
                     Segment
                    </label>
                    <Dropdown
                    name="im_SG_IT2"
                    value={im_SG_IT2}
                    options={im_SG_IT2Options}
                    onChange={(e) => Segment1Dropdown("im_SG_IT2", e.value)}
                    placeholder="Select Segment"
                    disabled={im_SG_IT !== "y"}
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_SG_IT3" className="font-bold">
                     Segment
                    </label>
                    <Dropdown
                    name="im_SG_IT3"
                    value={im_SG_IT3}
                    options={im_SG_IT3Options}
                    onChange={(e) => Segment2Dropdown("im_SG_IT3", e.value)}
                    placeholder="Select Segment"
                    disabled={im_SG_IT !== "y"}
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_SG_IT4" className="font-bold">
                     Segment
                    </label>
                    <Dropdown
                    name="im_SG_IT4"
                    value={im_SG_IT4}
                    options={im_SG_IT4Options}
                    onChange={(e) => Segment3Dropdown("im_SG_IT4", e.value)}
                    placeholder="Select Segment"
                    disabled={im_SG_IT !== "y"}
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_SG_IT5" className="font-bold">
                     Segment
                    </label>
                    <Dropdown
                    name="im_SG_IT5"
                    value={im_SG_IT5}
                    options={im_SG_IT5Options}
                    onChange={(e) => Segment4Dropdown("im_SG_IT5", e.value)}
                    placeholder="Select Segment"
                    disabled={im_SG_IT !== "y"}
                  />
                  </div>
                 
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_PR_UM" className="font-bold">
                   Unit of Measure
                    </label>
                    <Dropdown
                    name="im_PR_UM"
                    value={im_PR_UM}
                    options={im_PR_UMOptions}
                    onChange={(e) => UnitOfMeasureDropdown("im_PR_UM", e.value)}
                    placeholder="Select Unit Of Measure"
                  />
                  </div>
                  
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_DU_UM" className="font-bold">
                   Dual Unit Of Measure
                    </label>
                    <Dropdown
                    name="im_DU_UM"
                    value={im_DU_UM}
                    options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                      ]}
                    onChange={(e) => DualUnitDropdown("im_DU_UM", e.value)}
                    placeholder="Select Dual Unit of Measure"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="im_TO_PE" className="font-bold">
              Dual Um Tolerance %
              </label>
              <InputText 
                id="im_TO_PE"
                name="im_TO_PE"
                value={im_TO_PE}
                onChange={(e) => onchangedata(e)}
                disabled={im_DU_UM !== "y"}
              />
          </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_ST_TY" className="font-bold">
                   Stock Type
                    </label>
                    <Dropdown
                    name="im_ST_TY"
                    value={im_ST_TY}
                    options={im_ST_TYOptions}
                    onChange={(e) => StockingTypeDropdown("im_ST_TY", e.value)}
                    placeholder="Select   Stock Type"
                  />
                  </div>
                  
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_GL_CD" className="font-bold">
               GL Code
                    </label>
                    <Dropdown
                    name="im_GL_CD"
                    value={im_GL_CD}
                    options={im_GL_CDOptions}
                    onChange={(e) => GLDropdown("im_GL_CD", e.value)}
                    placeholder="Select   GL Code"
                  />
                  </div>

                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_LN_TY" className="font-bold">
                  Line Type
                  </label>
                  <Dropdown
                    name="im_LN_TY"
                    value={im_LN_TY}
                    options={im_LN_TYOptions}
                    onChange={(e) =>LineTypeDropdown("im_LN_TY", e.value)}
                    placeholder="Select Line Type"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_PD_GP" className="font-bold">
                  Product Group 
                  </label>
                  <Dropdown
                    name="im_PD_GP"
                    value={im_PD_GP}
                    options={im_PD_GPOptions}
                    onChange={(e) =>ProductGroupDropdown("im_PD_GP", e.value)}
                    placeholder="Select Product Group "
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_PR_GP" className="font-bold">
                  Price Group 
                  </label>
                  <Dropdown
                    name="im_PR_GP"
                    value={im_PR_GP}
                    options={im_PR_GPOptions}
                    onChange={(e) => PriceGroupDropdown("im_PR_GP", e.value)}
                    placeholder="Select Price Group "
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_PR_MD" className="font-bold">
                  Product Model 
                  </label>
                  <Dropdown
                    name="im_PR_MD"
                    value={im_PR_MD} 
                    options={im_PR_MDOptions}
                    onChange={(e) => ProductModelDropdown("im_PR_MD", e.value)}
                    placeholder="Select  Product Model "
                  />
                  
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_PR_FM" className="font-bold">
                  Product Family 
                  </label>
                  <Dropdown
                    name="im_PR_FM"
                    value={im_PR_FM}
                    options={im_PR_FMOptions}
                    onChange={(e) => ProductFamilyDropdown("im_PR_FM", e.value)}
                    placeholder="Select  Product Family "
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_IN_CL" className="font-bold">
                  Inventory Level
                  </label>
                  <Dropdown
                    name="im_IN_CL"
                    value={im_IN_CL}
                    options={im_IN_CLOptions}
                    onChange={(e) => InventoryLevelDropdown("im_IN_CL", e.value)}
                    placeholder="Select  Inventory Level"
                  />
                </div>
                  
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_PO_CN" className="font-bold">
                  Potency Control
                  </label>
                  <Dropdown
                    name="im_PO_CN"
                    value={im_PO_CN}
                    options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                      ]}
                    onChange={(e) => PotencyControlDropdown("im_PO_CN", e.value)}
                    placeholder="Select  Potency Control"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_ST_PO" className="font-bold">
                 Standard Potency
                  </label>
                  <Dropdown
                    name="im_ST_PO"
                    value={im_ST_PO}
                    options={im_ST_POOptions}
                    onChange={(e) => StandardPotencyDropdown("im_ST_PO", e.value)}
                    placeholder="Select  Standard Potency"
                    disabled={im_PO_CN !== "y"}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_FR_PO" className="font-bold">
                  From Potency
                  </label>
                  <Dropdown
                    name="im_FR_PO"
                    value={im_FR_PO}
                    options={im_FR_POOptions}
                    onChange={(e) => FromPotencyDropdown("im_FR_PO", e.value)}
                    placeholder="Select  From Potency"
                    disabled={im_PO_CN !== "y"}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_TO_PO" className="font-bold">
                 To Potency
                  </label>
                  <Dropdown
                    name="im_TO_PO"
                    value={im_TO_PO}
                    options={im_TO_POOptions}
                    onChange={(e) => ToPotencyDropdown("im_TO_PO", e.value)}
                    placeholder="Select To Potency"
                    disabled={im_PO_CN !== "y"}
                  />
                </div>

                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_GR_CN" className="font-bold">
                  Grade Control
                  </label>
                  <Dropdown
                    name="im_GR_CN"
                    value={im_GR_CN}
                    options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                      ]}
                    onChange={(e) => GradeControlDropdown("im_GR_CN", e.value)}
                    placeholder="Select  Grade Control"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_ST_GR" className="font-bold">
                 Standard Grade
                  </label>
                  <Dropdown
                    name="im_ST_GR"
                    value={im_ST_GR}
                    options={im_ST_GROptions}
                    onChange={(e) => StandardGradeDropdown("im_ST_GR", e.value)}
                    placeholder="Select  Standard Grade"
                    disabled={im_GR_CN !== "y"}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_FR_GR" className="font-bold">
                  From Grade
                  </label>
                  <Dropdown
                    name="im_FR_GR"
                    value={im_FR_GR}
                    options={im_FR_GROptions}
                    onChange={(e) => FromGradeDropdown("im_FR_GR", e.value)}
                    placeholder="Select  From Grade"
                    disabled={im_GR_CN !== "y"}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_TO_GR" className="font-bold">
                 To Grade
                  </label>
                  <Dropdown
                    name="im_TO_GR"
                    value={im_TO_GR}
                    options={im_TO_GROptions}
                    onChange={(e) => ToGradeDropdown("im_TO_GR", e.value)}
                    placeholder="Select To Grade"
                    disabled={im_GR_CN !== "y"}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_SH_LF" className="font-bold">
                  Shelf Life Days
                  </label>
                  <InputText 
                  id="im_SH_LF"
                  name="im_SH_LF"
                  value={im_SH_LF}
                  onChange={(e) => onchangedata(e)}
                  />
                </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="im_BB_DA" className="font-bold">
              Best Before days
              </label>
              <InputText 
                id="im_BB_DA"
                name="im_BB_DA"
                value={im_BB_DA}
                onChange={(e) => onchangedata(e)}
              />
              </div>
             </div>
              </div>
              
          </TabPanel>
          {/* tab2 */}
          <TabPanel header="Classification Codes">
             {/* 1st box */}
      <div class="row">
        <div class="col">
          <div
            style={{
              border: "1px solid #e8e8e8",
              padding: "16px",
              borderRadius: "15px",
              backgroundColor: "aliceblue",
              overflow: "auto",
            }}
          >
             <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}
             >Inventory</h1>
            <div className="formgrid grid">
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_IT_CD1" className="font-bold">
                  Class Code 1
                  </label>
                  <Dropdown
                    name="im_IT_CD1"
                    value={im_IT_CD1}
                    options={im_IT_CD1Options}
                    onChange={(e) =>ClassCodeDropDown1("im_IT_CD1", e.value)}
                    placeholder="Select Class Code 01"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_IT_CD2" className="font-bold">
                  Class Code 2
                  </label>
                  <Dropdown
                    name="im_IT_CD2"
                    value={im_IT_CD2}
                    options={im_IT_CD2Options}
                    onChange={(e) =>ClassCodeDropDown2("im_IT_CD2", e.value)}
                    placeholder="Select Class Code 02"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_IT_CD3" className="font-bold">
                  Class Code 3
                  </label>
                  <Dropdown
                    name="im_IT_CD3"
                    value={im_IT_CD3}
                    options={im_IT_CD3Options}
                    onChange={(e) => ClassCodeDropDown3("im_IT_CD3", e.value)}
                    placeholder="Select Class Code 03"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_IT_CD4" className="font-bold">
                  Class Code 4
                  </label>
                  <Dropdown
                    name="im_IT_CD4"
                    value={im_IT_CD4}
                    options={im_IT_CD4Options}
                    onChange={(e) => ClassCodeDropDown4("im_IT_CD4", e.value)}
                    placeholder="Select Class Code 04"
                  />
                  
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_IT_CD5" className="font-bold">
                  Class Code 5
                  </label>
                  <Dropdown
                    name="im_IT_CD5"
                    value={im_IT_CD5}
                    options={im_IT_CD5Options}
                    onChange={(e) => ClassCodeDropDown5("im_IT_CD5", e.value)}
                    placeholder="Select Class Code 05"
                  />
                </div>
            </div>
           
          </div>
        </div>
        <div class="col">
          <div
            style={{
              border: "1px solid #e8e8e8",
              padding: "16px",
              borderRadius: "15px",
              backgroundColor: "aliceblue",
              overflow: "auto",
            }}
          >
            <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}
             >Sales</h1>
             <div className="formgrid grid">
             <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_SA_CD1" className="font-bold">
                  Class Code 1
                  </label>
                  <Dropdown
                    name="im_SA_CD1"
                    value={im_SA_CD1}
                    options={im_SA_CD1Options}
                    onChange={(e) => ClassCodeDropDown6("im_SA_CD1", e.value)}
                    placeholder="Select Class Code 1"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_SA_CD2" className="font-bold">
                  Class Code 2
                  </label>
                  <Dropdown
                    name="im_SA_CD2"
                    value={im_SA_CD2}
                    options={im_SA_CD2Options}
                    onChange={(e) => ClassCodeDropDown7("im_SA_CD2", e.value)}
                    placeholder="Select Class Code 2"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_SA_CD3" className="font-bold">
                  Class Code 3
                  </label>
                  <Dropdown
                    name="im_SA_CD3"
                    value={im_SA_CD3}
                    options={im_SA_CD3Options}
                    onChange={(e) => ClassCodeDropDown8("im_SA_CD3", e.value)}
                    placeholder="Select Class Code 3"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_SA_CD4" className="font-bold">
                  Class Code 4
                  </label>
                  <Dropdown
                    name="im_SA_CD4"
                    value={im_SA_CD4}
                    options={im_SA_CD4Options}
                    onChange={(e) => ClassCodeDropDown9("im_SA_CD4", e.value)}
                    placeholder="Select Class Code 4"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_SA_CD5" className="font-bold">
                  Class Code 5
                  </label>
                  <Dropdown
                    name="im_SA_CD5"
                    value={im_SA_CD5}
                    options={im_SA_CD5Options}
                    onChange={(e) => ClassCodeDropDown10("im_SA_CD5", e.value)}
                    placeholder="Select Class Code 5"
                  />
                </div>
            </div>
          </div>
        </div>
      </div>

    {/* 2nd box */}
      <div class="row">
        <div class="col">
          <div
            style={{
              border: "1px solid #e8e8e8",
              padding: "16px",
              borderRadius: "15px",
              backgroundColor: "aliceblue",
              overflow: "auto",
            }}
          >
            <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}
             >Purchase</h1>
            <div className="formgrid grid">
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_PR_CD1" className="font-bold">
                  Class Code 1
                  </label>
                  <Dropdown
                    name="im_PR_CD1"
                    value={im_PR_CD1}
                    options={im_PR_CD1Options}
                    onChange={(e) =>ClassCodeDropDown11("im_PR_CD1", e.value)}
                    placeholder="Select Class Code 01"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_PR_CD2" className="font-bold">
                  Class Code 2
                  </label>
                  <Dropdown
                    name="im_PR_CD2"
                    value={im_PR_CD2}
                    options={im_PR_CD2Options}
                    onChange={(e) =>ClassCodeDropDown12("im_PR_CD2", e.value)}
                    placeholder="Select Class Code 02"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_PR_CD3" className="font-bold">
                  Class Code 3
                  </label>
                  <Dropdown
                    name="im_PR_CD3"
                    value={im_PR_CD3}
                    options={im_PR_CD3Options}
                    onChange={(e) => ClassCodeDropDown13("im_PR_CD3", e.value)}
                    placeholder="Select Class Code 03"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_PR_CD4" className="font-bold">
                  Class Code 4
                  </label>
                  <Dropdown
                    name="im_PR_CD4"
                    value={im_PR_CD4}
                    options={im_PR_CD4Options}
                    onChange={(e) => ClassCodeDropDown14("im_PR_CD4", e.value)}
                    placeholder="Select Class Code 04"
                  />
                  
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_PR_CD5" className="font-bold">
                  Class Code 5
                  </label>
                  <Dropdown
                    name="im_PR_CD5"
                    value={im_PR_CD5}
                    options={im_PR_CD5Options}
                    onChange={(e) => ClassCodeDropDown15("im_PR_CD5", e.value)}
                    placeholder="Select Class Code 05"
                  />
                </div>
                  
            </div>
          </div>
        </div>
        <div class="col">
          <div
            style={{
              border: "1px solid #e8e8e8",
              padding: "16px",
              borderRadius: "15px",
              backgroundColor: "aliceblue",
              overflow: "auto",
            }}
          >
            <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}
             >Manufacturing</h1>
            <div className="formgrid grid">
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_MF_CD1" className="font-bold">
                  Class Code 1
                  </label>
                  <Dropdown
                    name="im_MF_CD1"
                    value={im_MF_CD1}
                    options={im_MF_CD1Options}
                    onChange={(e) => ClassCodeDropDown16("im_MF_CD1", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_MF_CD2" className="font-bold">
                  Class Code 2
                  </label>
                  <Dropdown
                    name="im_MF_CD2"
                    value={im_MF_CD2}
                    options={im_MF_CD2Options}
                    onChange={(e) => ClassCodeDropDown17("im_MF_CD2", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_MF_CD3" className="font-bold">
                  Class Code 3
                  </label>
                  <Dropdown
                    name="im_MF_CD3"
                    value={im_MF_CD3}
                    options={im_MF_CD3Options}
                    onChange={(e) => ClassCodeDropDown18("im_MF_CD3", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_MF_CD4" className="font-bold">
                  Class Code 4
                  </label>
                  <Dropdown
                    name="im_MF_CD4"
                    value={im_MF_CD4}
                    options={im_MF_CD4Options}
                    onChange={(e) => ClassCodeDropDown19("im_MF_CD4", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_MF_CD5" className="font-bold">
                  Class Code 5
                  </label>
                  <Dropdown
                    name="im_MF_CD5"
                    value={im_MF_CD5}
                    options={im_MF_CD5Options}
                    onChange={(e) => ClassCodeDropDown20("im_MF_CD5", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                  
            </div>
          </div>
        </div>
        
      </div>
      {/* 3rd box */}
      <div class="row">
        <div class="col">
          <div
            style={{
              border: "1px solid #e8e8e8",
              padding: "16px",
              borderRadius: "15px",
              backgroundColor: "aliceblue",
              overflow: "auto",
            }}
          >
            <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}
             >WareHouse</h1>
            <div className="formgrid grid">
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}} >
                  <label htmlFor="im_WH_CD1" className="font-bold">
                 Process Group 1
                  </label>
                  <Dropdown
                    name="im_WH_CD1"
                    value={im_WH_CD1}
                    options={im_WH_CD1Options}
                    onChange={(e) =>ClassCodeDropDown21("im_WH_CD1", e.value)}
                    placeholder="Select  Process Group 1"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_WH_CD2" className="font-bold">
                  Process Group 2
                  </label>
                  <Dropdown
                    name="im_WH_CD2"
                    value={im_WH_CD2}
                    options={im_WH_CD2Options}
                    onChange={(e) =>ClassCodeDropDown22("im_WH_CD2", e.value)}
                    placeholder="Select  Process Group 02"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_WH_CD3" className="font-bold">
                  Process Group 3
                  </label>
                  <Dropdown
                    name="im_WH_CD3"
                    value={im_WH_CD3}
                    options={im_WH_CD3Options}
                    onChange={(e) => ClassCodeDropDown23("im_WH_CD3", e.value)}
                    placeholder="Select Process Group 03"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_WH_CD4" className="font-bold">
                  Process Group 4
                  </label>
                  <Dropdown
                    name="im_WH_CD4"
                    value={im_WH_CD4}
                    options={im_WH_CD4Options}
                    onChange={(e) => ClassCodeDropDown24("im_WH_CD4", e.value)}
                    placeholder="Select Process Group 04"
                  />
                  
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_WH_CD5" className="font-bold">
                  Process Group 5
                  </label>
                  <Dropdown
                    name="im_WH_CD5"
                    value={im_WH_CD5}
                    options={im_WH_CD5Options}
                    onChange={(e) => ClassCodeDropDown25("im_WH_CD5", e.value)}
                    placeholder="Select Process Group 05"
                  />
                </div>
                  
            </div>
          </div>
        </div>
        <div class="col">
          <div
            style={{
              border: "1px solid #e8e8e8",
              padding: "16px",
              borderRadius: "15px",
              backgroundColor: "aliceblue",
              overflow: "auto",
            }}
          >
            <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}
             >Transportation</h1>
            <div className="formgrid grid">
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_TR_CD1" className="font-bold">
                  Class Code 1
                  </label>
                  <Dropdown
                    name="im_TR_CD1"
                    value={im_TR_CD1}
                    options={im_TR_CD1Options}
                    onChange={(e) => ClassCodeDropDown26("im_TR_CD1", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_TR_CD2" className="font-bold">
                  Class Code 2
                  </label>
                  <Dropdown
                    name="im_TR_CD2"
                    value={im_TR_CD2}
                    options={im_TR_CD2Options}
                    onChange={(e) => ClassCodeDropDown27("im_TR_CD2", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_TR_CD3" className="font-bold">
                  Class Code 3
                  </label>
                  <Dropdown
                    name="im_TR_CD3"
                    value={im_TR_CD3}
                    options={im_TR_CD3Options}
                    onChange={(e) => ClassCodeDropDown28("im_TR_CD3", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_TR_CD4" className="font-bold">
                  Class Code 4
                  </label>
                  <Dropdown
                    name="im_TR_CD4"
                    value={im_TR_CD4}
                    options={im_TR_CD4Options}
                    onChange={(e) => ClassCodeDropDown29("im_TR_CD4", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2"style={{width:"45%"}}>
                  <label htmlFor="im_TR_CD5" className="font-bold">
                  Class Code 5
                  </label>
                  <Dropdown
                    name="im_TR_CD5"
                    value={im_TR_CD5}
                    options={im_TR_CD5Options}
                    onChange={(e) => ClassCodeDropDown30("im_TR_CD5", e.value)}
                    placeholder="Select Class Code 5"
                  />
                </div>
            </div>
          </div>
        </div>
        
      </div>

          </TabPanel>
          <TabPanel header="Unit of Measure">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
             <div className="formgrid grid">
             <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_PR_UM" className="font-bold">
                     Primary
                    </label>
                    <Dropdown
                    name="im_PR_UM"
                    value={im_PR_UM}
                    options={im_PR_UMOptions}
                    onChange={(e) => primaryDropdown("im_PR_UM", e.value)}
                    placeholder="Select Primary"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_SC_UM" className="font-bold">
                     Secondary
                    </label>
                    <Dropdown
                    name="im_SC_UM"
                    value={im_SC_UM}
                    options={im_SC_UMOptions}
                    onChange={(e) => SecondaryDropdown("im_SC_UM", e.value)}
                    placeholder="Select Secondary"
                  />
                  </div>

                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_PO_UM" className="font-bold">
                    Purchasing
                    </label>
                    <Dropdown
                    name="im_PO_UM"
                    value={im_PO_UM}
                    options={im_PO_UMOptions}
                    onChange={(e) => PurchasingDropdown("im_PO_UM", e.value)}
                    placeholder="Select Purchasing"
                  />
                  </div>
                  
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_SO_UM" className="font-bold">
                   Pricing
                    </label>
                    <Dropdown
                    name="im_SO_UM"
                    value={im_SO_UM}
                    options={im_SO_UMOptions}
                    onChange={(e) => PrisingDropdown("im_SO_UM", e.value)}
                    placeholder="Select Pricing"
                  />
                  </div>

                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_SH_UM" className="font-bold">
                    Shipping
                    </label>
                    <Dropdown
                    name="im_SH_UM"
                    value={im_SH_UM}
                    options={im_SH_UMOptions}
                    onChange={(e) => ShppingDropdown("im_SH_UM", e.value)}
                    placeholder="Select Shipping"
                  />
                  </div> 
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_PD_UM" className="font-bold">
                    Production
                    </label>
                    <Dropdown
                    name="im_PD_UM"
                    value={im_PD_UM}
                    options={im_PD_UMOptions}
                    onChange={(e) => ProductionDropdown("im_PD_UM", e.value)}
                    placeholder="Select Production"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_CO_UM" className="font-bold">
                   Component
                    </label>
                    <Dropdown
                    name="im_CO_UM"
                    value={im_CO_UM}
                    options={im_CO_UMOptions}
                    onChange={(e) => ComponentDropdown("im_CO_UM", e.value)}
                    placeholder="Select Component"
                  />
                  </div>

                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_WT_UM" className="font-bold">
                     Weight
                    </label>
                    <Dropdown
                    name="im_WT_UM"
                    value={im_WT_UM}
                    options={im_WT_UMOptions}
                    onChange={(e) => WeightDropdown("im_WT_UM", e.value)}
                    placeholder="Select Weight"
                  />
                  </div>
                  
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_VL_UM" className="font-bold">
                    Volume
                    </label>
                    <Dropdown
                    name="im_VL_UM"
                    value={im_VL_UM}
                    options={im_VL_UMOptions}
                    onChange={(e) => VolumeDropdown("im_VL_UM", e.value)}
                    placeholder="Select Volume"
                  />
                  </div>
                
              </div>
              </div>
          </TabPanel>
          <TabPanel header="Planning">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
             <div className="formgrid grid">
             <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_PL_CD" className="font-bold">
                     Planning Code
                    </label>
                    <Dropdown
                    name="im_PL_CD"
                    value={im_PL_CD}
                    options={im_PL_CDOptions}
                    onChange={(e) => PlanningCodeDropdown("im_PL_CD", e.value)}
                    placeholder="Select  Planning Code"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_PL_RL" className="font-bold">
                    Planning Rule
                    </label>
                    <Dropdown
                    name="im_PL_RL"
                    value={im_PL_RL}
                    options={im_PL_RLOptions}
                    onChange={(e) => PlanningRuleDropdown("im_PL_RL", e.value)}
                    placeholder="Select Planning Rule"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_PL_FE" className="font-bold">
                  Planning Fence
                  </label>
                  <InputText 
                  id="im_PL_FE"
                  name="im_PL_FE"
                  value={im_PL_FE}
                  onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_LD_TM" className="font-bold">
                 Lead Time
                  </label>
                  <InputText 
                  id="im_LD_TM"
                  name="im_LD_TM"
                  value={im_LD_TM}
                  onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_RO_QT" className="font-bold">
                 Reorder Quantity
                  </label>
                  <InputText 
                  id="im_RO_QT"
                  name="im_RO_QT"
                  value={im_RO_QT}
                  onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_RO_LV" className="font-bold">
                  Reorder Level
                  </label>
                  <InputText 
                  id="im_RO_LV"
                  name="im_RO_LV"
                  value={im_RO_LV}
                  onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_SF_ST" className="font-bold">
                 Safety Stock
                  </label>
                  <InputText 
                  id="im_SF_ST"
                  name="im_SF_ST"
                  value={im_SF_ST}
                  onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_MI_QT" className="font-bold">
                 Min Reorder Quantity
                  </label>
                  <InputText 
                  id="im_MI_QT"
                  name="im_MI_QT"
                  value={im_MI_QT}
                  onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_MX_QT" className="font-bold">
                 Max Reorder Quantity
                  </label>
                  <InputText 
                  id="im_MX_QT"
                  name="im_MX_QT"
                  value={im_MX_QT}
                  onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="im_PL_NO" className="font-bold">
              Planner <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="planner_NAME"
                      name="planner_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedPlanner}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openPlannerPopup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="im_PL_NO"
                      name="im_PL_NO"
                      value={im_PL_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="im_SU_NO" className="font-bold">
              Supplier <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="supplier_NAME"
                      name="supplier_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedSupplier}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openSupplierPopup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="im_SU_NO"
                      name="im_SU_NO"
                      value={im_SU_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
              </div>
              </div>
          </TabPanel>
          <TabPanel header="Tax">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
             <div className="formgrid grid">
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_TX_AP" className="font-bold">
                    Applicable
                    </label>
                    <Dropdown
                    name="im_TX_AP"
                    value={im_TX_AP}
                    options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                      ]}
                    onChange={(e) => ApplicableDropdown("im_TX_AP", e.value)}
                    placeholder="Select Applicable"
                  />
                  </div>
                  
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_VA_TX" className="font-bold">
                   Value Added Tax
                    </label>
                    <Dropdown
                    name="im_VA_TX"
                    value={im_VA_TX}
                    options={im_VA_TXOptions}
                    onChange={(e) => AddedTaxDropdown("im_VA_TX", e.value)}
                    placeholder="Select Value Added Tax"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_GS_RT" className="font-bold">
                    GST
                    </label>
                    <Dropdown
                    name="im_GS_RT"
                    value={im_GS_RT}
                    options={im_GS_RTOptions}
                    onChange={(e) => GSTDropdown("im_GS_RT", e.value)}
                    placeholder="Select GST"
                  />
                  </div>
                  
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_CH_NO" className="font-bold">
                   Chapter No
                    </label>
                    <Dropdown
                    name="im_CH_NO"
                    value={im_CH_NO}
                    options={im_CH_NOOptions}
                    onChange={(e) => ChapterDropdown("im_CH_NO", e.value)}
                    placeholder="Select  Chapter No"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_FD_TX" className="font-bold">
                    Federal Tax
                    </label>
                    <Dropdown
                    name="im_FD_TX"
                    value={im_FD_TX}
                    options={im_FD_TXOptions}
                    onChange={(e) => FederralTaxDropdown("im_FD_TX", e.value)}
                    placeholder="Select Federal Tax"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_ST_TX" className="font-bold">
                    State Tax
                    </label>
                    <Dropdown
                    name="im_ST_TX"
                    value={im_ST_TX}
                    options={im_ST_TXOptions}
                    onChange={(e) => StateTaxDropdown("im_ST_TX", e.value)}
                    placeholder="Select State Tax"
                  />
                  </div>
                  
              </div>
              </div>
          </TabPanel>
          <TabPanel header="Additional Information">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
             <div className="formgrid grid">
             
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_CS_WT" className="font-bold">
                    Casting Weight
                    </label>
                    <Dropdown
                    name="im_CS_WT"
                    value={im_CS_WT}
                    options={im_CS_WTOptions}
                    onChange={(e) => CastingWeightDropdown("im_CS_UM", e.value)}
                    placeholder="Select Casting Weight"
                  />
                  </div>
                  
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_JW_WT" className="font-bold">
                    Jewellery Weight
                    </label>
                    <Dropdown
                    name="im_JW_WT"
                    value={im_JW_WT}
                    options={im_JW_WTOptions}
                    onChange={(e) => jewelleryWeightDropdown("im_JW_WT", e.value)}
                    placeholder="Select jewellery Weight"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_WX_WT" className="font-bold">
                    Wax Weight
                    </label>
                    <Dropdown
                    name="im_WX_WT"
                    value={im_WX_WT}
                    options={im_WX_WTOptions}
                    onChange={(e) => WaxWeightDropdown("im_WX_WT", e.value)}
                    placeholder="Select Wax Weight"
                  />
                  </div>
                  
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_KT_WT" className="font-bold">
                    Karat
                    </label>
                    <Dropdown
                    name="im_KT_WT"
                    value={im_KT_WT}
                    options={im_KT_WTOptions}
                    onChange={(e) => KaratDropdown("im_KT_WT", e.value)}
                    placeholder="Select Karat"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="im_JW_TY" className="font-bold">
                    Jewellery Type
                    </label>
                    <Dropdown
                    name="im_JW_TY"
                    value={im_JW_TY}
                    options={im_JW_TYOptions}
                    onChange={(e) => JewelleryTypeDropdown("im_JW_TY", e.value)}
                    placeholder="Select Jewellery Type"
                  />
                  </div>
                  <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="im_AD_IN" className="font-bold">
                 Instructions
                  </label>
                  <textarea 
                  id="im_AD_IN"
                  name="im_AD_IN"
                  value={im_AD_IN}
                  onChange={(e) => onchangedata(e)}
                  rows={5} 
                  style={{ width: '150%' }} 
                  />
                </div>
              </div>
              </div>
          </TabPanel>
          
      </TabView>
      </div> 
      <AddressBookPopup
       visible={PlannerPopupVisible}
       onClose={() => setPlannerPopupVisible(false)}
       onDataSelect={handlePlanner}
       formData={data}
       addressType={AddresstypeE}
       setFormData= {setFormData}
      />
      <AddressBookPopup
       visible={SupplierPopupVisible}
       onClose={() => setSupplierPopupVisible(false)}
       onDataSelect={handleSupplier}
       formData={data}
       addressType={AddresstypeS}
       setFormData= {setFormData}
      />
    </Dialog>
  );
}
