import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import WorkCentreCapacityDialog from "./WorkCentreCapacityDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
const initialValue = {
  wcUkNo:"",
  wc_CO_CT:"",
  wc_WK_CT:"",
  wc_YE_AR:"",
  wc_MN_TH:"",
  wc_AV_01:"",
  wc_BK_01:"",
  wc_AV_02:"",
  wc_BK_02:"",
  wc_AV_03:"",
  wc_BK_03:"",
  wc_AV_04:"",
  wc_BK_04:"",
  wc_AV_05:"",
  wc_BK_05:"",
  wc_AV_06:"",
  wc_BK_06:"",
  wc_AV_07:"",
  wc_BK_07:"",
  wc_AV_08:"",
  wc_BK_08:"",
  wc_AV_09:"",
  wc_BK_09:"",
  wc_AV_10:"",
  wc_BK_10:"",
  wc_AV_11:"",
  wc_BK_11:"",
  wc_AV_12:"",
  wc_BK_12:"",
  wc_AV_13:"",
  wc_BK_13:"",
  wc_AV_14:"",
  wc_BK_14:"",
  wc_AV_15:"",
  wc_BK_15:"",
  wc_AV_16:"",
  wc_BK_16:"",
  wc_AV_17:"",
  wc_BK_17:"",
  wc_AV_18:"",
  wc_BK_18:"",
  wc_AV_19:"",
  wc_BK_19:"",
  wc_AV_20:"",
  wc_BK_20:"",
  wc_AV_21:"",
  wc_BK_21:"",
  wc_AV_22:"",
  wc_BK_22:"",
  wc_AV_23:"",
  wc_BK_23:"",
  wc_AV_24:"",
  wc_BK_24:"",
  wc_AV_25:"",
  wc_BK_25:"",
  wc_AV_26:"",
  wc_BK_26:"",
  wc_AV_27:"",
  wc_BK_27:"",
  wc_AV_28:"",
  wc_BK_28:"",
  wc_AV_29:"",
  wc_BK_29:"",
  wc_AV_30:"",
  wc_BK_30:"",
  wc_AV_31:"",
  wc_BK_31:"",
};

const initialFilters = {
  wc_CO_CT: { value: "", operation: "" },
  wc_WK_CT: { value: "", operation: "" },
  wc_YE_AR: { value: "", operation: "" },
  wc_MN_TH: { value: "", operation: "" },
  wc_AV_01: { value: "", operation: "" },
  wc_BK_01: { value: "", operation: "" },
  wc_AV_02: { value: "", operation: "" },
  wc_BK_02: { value: "", operation: "" },
  wc_AV_03: { value: "", operation: "" },
  wc_BK_03: { value: "", operation: "" },
  wc_AV_04: { value: "", operation: "" },
  wc_BK_04: { value: "", operation: "" },
  wc_AV_05: { value: "", operation: "" },
  wc_BK_05: { value: "", operation: "" },
  wc_AV_06: { value: "", operation: "" },
  wc_BK_06: { value: "", operation: "" },
  wc_AV_07: { value: "", operation: "" },
  wc_BK_07: { value: "", operation: "" },
  wc_AV_08: { value: "", operation: "" },
  wc_BK_08: { value: "", operation: "" },
  wc_AV_09: { value: "", operation: "" },
  wc_BK_09: { value: "", operation: "" },
  wc_AV_10: { value: "", operation: "" },
  wc_BK_10: { value: "", operation: "" },
  wc_AV_11: { value: "", operation: "" },
  wc_BK_11: { value: "", operation: "" },
  wc_AV_12: { value: "", operation: "" },
  wc_BK_12: { value: "", operation: "" },
  wc_AV_13: { value: "", operation: "" },
  wc_BK_13: { value: "", operation: "" },
  wc_AV_14: { value: "", operation: "" },
  wc_BK_14: { value: "", operation: "" },
  wc_AV_15: { value: "", operation: "" },
  wc_BK_15: { value: "", operation: "" },
  wc_AV_16: { value: "", operation: "" },
  wc_BK_16: { value: "", operation: "" },
  wc_AV_17: { value: "", operation: "" },
  wc_BK_17: { value: "", operation: "" },
  wc_AV_18: { value: "", operation: "" },
  wc_BK_18: { value: "", operation: "" },
  wc_AV_19: { value: "", operation: "" },
  wc_BK_19: { value: "", operation: "" },
  wc_AV_20: { value: "", operation: "" },
  wc_BK_20: { value: "", operation: "" },
  wc_AV_21: { value: "", operation: "" },
  wc_BK_21: { value: "", operation: "" },
  wc_AV_22: { value: "", operation: "" },
  wc_BK_22: { value: "", operation: "" },
  wc_AV_23: { value: "", operation: "" },
  wc_BK_23: { value: "", operation: "" },
  wc_AV_24: { value: "", operation: "" },
  wc_BK_24: { value: "", operation: "" },
  wc_AV_25: { value: "", operation: "" },
  wc_BK_25: { value: "", operation: "" },
  wc_AV_26: { value: "", operation: "" },
  wc_BK_26: { value: "", operation: "" },
  wc_AV_27: { value: "", operation: "" },
  wc_BK_27: { value: "", operation: "" },
  wc_AV_28: { value: "", operation: "" },
  wc_BK_28: { value: "", operation: "" },
  wc_AV_29: { value: "", operation: "" },
  wc_BK_29: { value: "", operation: "" },
  wc_AV_30: { value: "", operation: "" },
  wc_BK_30: { value: "", operation: "" },
  wc_AV_31: { value: "", operation: "" },
  wc_BK_31: { value: "", operation: "" },
};

const WorkCentreCapacityMain = () => {
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

// dateformat
const formatDate = (inputDate) => {
  if (!inputDate) {
    return inputDate;
  }
  const dateString = inputDate.toString();
  if (dateString.length !== 8) {
    return dateString;
  }
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);
  return `${day}/${month}/${year}`;
};

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [WorkCentreCapacitytable, setWorkCentreCapacitytable] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };



  const handleClickOpen = () => {
    setOpen(true);
  };
  

  const handleClose = () => {
    setWorkCentreCapacitytable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleSearch();
    }
  };
  const addRow = () => {
    setWorkCentreCapacitytable([...WorkCentreCapacitytable, {}]);
  };

  const deleteRow = () => {
    if (WorkCentreCapacitytable.length > 0) {
      const updatedData = [...WorkCentreCapacitytable];
      updatedData.pop();
      setWorkCentreCapacitytable(updatedData);
    }
  };
  

  useEffect(() => {
    handleSearch();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/WorkCentreCapacity/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/WorkCentreCapacity/Selects/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const WorkCentreCapacityData = response.data;
        setWorkCentreCapacitytable(WorkCentreCapacityData.journalEntryList);
        setFormData(WorkCentreCapacityData);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  var requestBody = {};
  function values(){
    const table = document.getElementById("WorkCentreCapacitytable");
    const totalRowCount = table.tBodies[0].rows.length;
    const journalEntryList = [];
    for (let i = 0; i < totalRowCount; i++) {
      const RQ_DT = document.getElementsByName("gl_GL_DT_" + i)[0];
      const Details = {
      wcUkNo : document.getElementById("wcUkNo_" + i).value,
      gl_DO_NO : document.getElementById("gl_DO_NO_" + i).value,
      gl_DO_TY : document.getElementById("gl_DO_TY_" + i).value,
      gl_DO_CO : document.getElementById("gl_DO_CO_" + i).value,
      gl_GL_DT: RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
      gl_AC_NO : document.getElementById("gl_AC_NO_" + i).value,
      gl_LE_TY : document.getElementById("gl_LE_TY_" + i).value,
      gl_GL_PO : document.getElementById("gl_GL_PO_" + i).value, 
    };
        journalEntryList.push(Details);
      }
      requestBody ={
        journalEntryList,
        wcUkNo : FormData.wcUkNo,
        gl_DO_NO : document.getElementById("gl_DO_NO").value,
        gl_BA_NO : document.getElementById("gl_BA_NO").value,
        gl_BA_TY : document.getElementById("gl_BA_TY").value,
      }
  }
  const handleFormSubmit =  async () => {
    const  Url = `${domain}/api/WorkCentreCapacity/PostErrorBatch`
    const request = {
        "wcUkNo": FormData.wcUkNo,
    }
    axios.post(Url, request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      handleResponse(resp.data);
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };

  const buttonStyles = {
    backgroundColor: "#ff0010a3",
    color: "white",
    lineHeight: "10%",
  };


  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      
    </React.Fragment>
  );

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(0);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width:"0%",

  };
  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    width:"0%",
  };

  const inputStyle = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
  };
  const checkboxStyle = {
    border: "6px solid #a7d6ff8c",
    borderRadius: "4px",
    width: "100%",
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };



  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_CO_CT"] ? filters["wc_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_CO_CT",
                        e.target.value,
                        filters["wc_CO_CT"]
                          ? filters["wc_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_WK_CT"] ? filters["wc_WK_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_WK_CT",
                        e.target.value,
                        filters["wc_WK_CT"]
                          ? filters["wc_WK_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_YE_AR"] ? filters["wc_YE_AR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_YE_AR",
                        e.target.value,
                        filters["wc_YE_AR"]
                          ? filters["wc_YE_AR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_MN_TH"] ? filters["wc_MN_TH"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_MN_TH",
                        e.target.value,
                        filters["wc_MN_TH"]
                          ? filters["wc_MN_TH"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_01"] ? filters["wc_AV_01"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_01",
                        e.target.value,
                        filters["wc_AV_01"]
                          ? filters["wc_AV_01"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_01"] ? filters["wc_BK_01"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_01",
                        e.target.value,
                        filters["wc_BK_01"]
                          ? filters["wc_BK_01"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_02"] ? filters["wc_AV_02"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_02",
                        e.target.value,
                        filters["wc_AV_02"]
                          ? filters["wc_AV_02"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_02"] ? filters["wc_BK_02"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_02",
                        e.target.value,
                        filters["wc_BK_02"]
                          ? filters["wc_BK_02"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_03"] ? filters["wc_AV_03"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_03",
                        e.target.value,
                        filters["wc_AV_03"]
                          ? filters["wc_AV_03"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_03"] ? filters["wc_BK_03"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_03",
                        e.target.value,
                        filters["wc_BK_03"]
                          ? filters["wc_BK_03"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_04"] ? filters["wc_AV_04"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_04",
                        e.target.value,
                        filters["wc_AV_04"]
                          ? filters["wc_AV_04"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_04"] ? filters["wc_BK_04"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_04",
                        e.target.value,
                        filters["wc_BK_04"]
                          ? filters["wc_BK_04"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_05"] ? filters["wc_AV_05"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_05",
                        e.target.value,
                        filters["wc_AV_05"]
                          ? filters["wc_AV_05"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_05"] ? filters["wc_BK_05"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_05",
                        e.target.value,
                        filters["wc_BK_05"]
                          ? filters["wc_BK_05"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_06"] ? filters["wc_AV_06"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_06",
                        e.target.value,
                        filters["wc_AV_06"]
                          ? filters["wc_AV_06"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_06"] ? filters["wc_BK_06"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_06",
                        e.target.value,
                        filters["wc_BK_06"]
                          ? filters["wc_BK_06"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_07"] ? filters["wc_AV_07"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_07",
                        e.target.value,
                        filters["wc_AV_07"]
                          ? filters["wc_AV_07"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_07"] ? filters["wc_BK_07"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_07",
                        e.target.value,
                        filters["wc_BK_07"]
                          ? filters["wc_BK_07"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_08"] ? filters["wc_AV_08"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_08",
                        e.target.value,
                        filters["wc_AV_08"]
                          ? filters["wc_AV_08"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_08"] ? filters["wc_BK_08"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_08",
                        e.target.value,
                        filters["wc_BK_08"]
                          ? filters["wc_BK_08"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_09"] ? filters["wc_AV_09"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_09",
                        e.target.value,
                        filters["wc_AV_09"]
                          ? filters["wc_AV_09"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_09"] ? filters["wc_BK_09"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_09",
                        e.target.value,
                        filters["wc_BK_09"]
                          ? filters["wc_BK_09"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_10"] ? filters["wc_AV_10"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_10",
                        e.target.value,
                        filters["wc_AV_10"]
                          ? filters["wc_AV_10"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_11"] ? filters["wc_BK_11"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_11",
                        e.target.value,
                        filters["wc_BK_11"]
                          ? filters["wc_BK_11"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_12"] ? filters["wc_AV_12"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_12",
                        e.target.value,
                        filters["wc_AV_12"]
                          ? filters["wc_AV_12"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_12"] ? filters["wc_BK_12"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_12",
                        e.target.value,
                        filters["wc_BK_12"]
                          ? filters["wc_BK_12"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_13"] ? filters["wc_AV_13"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_13",
                        e.target.value,
                        filters["wc_AV_13"]
                          ? filters["wc_AV_13"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_13"] ? filters["wc_BK_13"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_13",
                        e.target.value,
                        filters["wc_BK_13"]
                          ? filters["wc_BK_13"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_14"] ? filters["wc_AV_14"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_14",
                        e.target.value,
                        filters["wc_AV_14"]
                          ? filters["wc_AV_14"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_14"] ? filters["wc_BK_14"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_14",
                        e.target.value,
                        filters["wc_BK_14"]
                          ? filters["wc_BK_14"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_15"] ? filters["wc_AV_15"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_15",
                        e.target.value,
                        filters["wc_AV_15"]
                          ? filters["wc_AV_15"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_15"] ? filters["wc_BK_15"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_15",
                        e.target.value,
                        filters["wc_BK_15"]
                          ? filters["wc_BK_15"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_16"] ? filters["wc_AV_16"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_16",
                        e.target.value,
                        filters["wc_AV_16"]
                          ? filters["wc_AV_16"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_16"] ? filters["wc_BK_16"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_16",
                        e.target.value,
                        filters["wc_BK_16"]
                          ? filters["wc_BK_16"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_17"] ? filters["wc_AV_17"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_17",
                        e.target.value,
                        filters["wc_AV_17"]
                          ? filters["wc_AV_17"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_17"] ? filters["wc_BK_17"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_17",
                        e.target.value,
                        filters["wc_BK_17"]
                          ? filters["wc_BK_17"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_18"] ? filters["wc_AV_18"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_18",
                        e.target.value,
                        filters["wc_AV_18"]
                          ? filters["wc_AV_18"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_18"] ? filters["wc_BK_18"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_18",
                        e.target.value,
                        filters["wc_BK_18"]
                          ? filters["wc_BK_18"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_19"] ? filters["wc_AV_19"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_19",
                        e.target.value,
                        filters["wc_AV_19"]
                          ? filters["wc_AV_19"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_19"] ? filters["wc_BK_19"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_19",
                        e.target.value,
                        filters["wc_BK_19"]
                          ? filters["wc_BK_19"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_20"] ? filters["wc_AV_20"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_20",
                        e.target.value,
                        filters["wc_AV_20"]
                          ? filters["wc_AV_20"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_20"] ? filters["wc_BK_20"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_20",
                        e.target.value,
                        filters["wc_BK_20"]
                          ? filters["wc_BK_20"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_21"] ? filters["wc_AV_21"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_21",
                        e.target.value,
                        filters["wc_AV_21"]
                          ? filters["wc_AV_21"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_21"] ? filters["wc_BK_21"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_21",
                        e.target.value,
                        filters["wc_BK_21"]
                          ? filters["wc_BK_21"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_22"] ? filters["wc_AV_22"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_22",
                        e.target.value,
                        filters["wc_AV_22"]
                          ? filters["wc_AV_22"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_22"] ? filters["wc_BK_22"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_22",
                        e.target.value,
                        filters["wc_BK_22"]
                          ? filters["wc_BK_22"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_23"] ? filters["wc_AV_23"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_23",
                        e.target.value,
                        filters["wc_AV_23"]
                          ? filters["wc_AV_23"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_23"] ? filters["wc_BK_23"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_23",
                        e.target.value,
                        filters["wc_BK_23"]
                          ? filters["wc_BK_23"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_24"] ? filters["wc_AV_24"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_24",
                        e.target.value,
                        filters["wc_AV_24"]
                          ? filters["wc_AV_24"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_24"] ? filters["wc_BK_24"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_24",
                        e.target.value,
                        filters["wc_BK_24"]
                          ? filters["wc_BK_24"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_25"] ? filters["wc_AV_25"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_25",
                        e.target.value,
                        filters["wc_AV_25"]
                          ? filters["wc_AV_25"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_25"] ? filters["wc_BK_25"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_25",
                        e.target.value,
                        filters["wcwc_BK_25_BK_"]
                          ? filters["wc_BK_25"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_26"] ? filters["wc_AV_26"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_26",
                        e.target.value,
                        filters["wc_AV_26"]
                          ? filters["wc_AV_26"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_26"] ? filters["wc_BK_26"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_26",
                        e.target.value,
                        filters["wc_BK_26"]
                          ? filters["wc_BK_26"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_27"] ? filters["wc_AV_27"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_27",
                        e.target.value,
                        filters["wc_AV_27"]
                          ? filters["wc_AV_27"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_27"] ? filters["wc_BK_27"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_27",
                        e.target.value,
                        filters["wc_BK_27"]
                          ? filters["wc_BK_27"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_28"] ? filters["wc_AV_28"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_28",
                        e.target.value,
                        filters["wc_AV_28"]
                          ? filters["wc_AV_28"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_28"] ? filters["wc_BK_28"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_28",
                        e.target.value,
                        filters["wc_BK_28"]
                          ? filters["wc_BK_28"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_29"] ? filters["wc_AV_29"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_29",
                        e.target.value,
                        filters["wc_AV_29"]
                          ? filters["wc_AV_29"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_29"] ? filters["wc_BK_29"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_29",
                        e.target.value,
                        filters["wc_BK_29"]
                          ? filters["wc_BK_29"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_30"] ? filters["wc_AV_30"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_30",
                        e.target.value,
                        filters["wc_AV_30"]
                          ? filters["wc_AV_30"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_30"] ? filters["wc_BK_30"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_30",
                        e.target.value,
                        filters["wc_BK_30"]
                          ? filters["wc_BK_30"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AV_31"] ? filters["wc_AV_31"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AV_31",
                        e.target.value,
                        filters["wc_AV_31"]
                          ? filters["wc_AV_31"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BK_31"] ? filters["wc_BK_31"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BK_31",
                        e.target.value,
                        filters["wc_BK_31"]
                          ? filters["wc_BK_31"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Work Centre</th>
                <th style={tableHeaderStyle}>Year</th>
                <th style={tableHeaderStyle}>Month</th>
                <th style={tableHeaderStyle}>Day 01 Available</th>
                <th style={tableHeaderStyle}>Day 01 Booked</th>
                <th style={tableHeaderStyle}>Day 02 Available</th>
                <th style={tableHeaderStyle}>Day 02 Booked</th>
                <th style={tableHeaderStyle}>Day 03 Available</th>
                <th style={tableHeaderStyle}>Day 03 Booked</th>
                <th style={tableHeaderStyle}>Day 04 Available</th>
                <th style={tableHeaderStyle}>Day 04 Booked</th>
                <th style={tableHeaderStyle}>Day 05 Available</th>
                <th style={tableHeaderStyle}>Day 05 Booked</th>
                <th style={tableHeaderStyle}>Day 06 Available</th>
                <th style={tableHeaderStyle}>Day 06 Booked</th>
                <th style={tableHeaderStyle}>Day 07 Available</th>
                <th style={tableHeaderStyle}>Day 07 Booked</th>
                <th style={tableHeaderStyle}>Day 08 Available</th>
                <th style={tableHeaderStyle}>Day 08 Booked</th>
                <th style={tableHeaderStyle}>Day 09 Available</th>
                <th style={tableHeaderStyle}>Day 09 Booked</th>
                <th style={tableHeaderStyle}>Day 10 Available</th>
                <th style={tableHeaderStyle}>Day 10 Booked</th>
                <th style={tableHeaderStyle}>Day 11 Available</th>
                <th style={tableHeaderStyle}>Day 11 Booked</th>
                <th style={tableHeaderStyle}>Day 12 Available</th>
                <th style={tableHeaderStyle}>Day 12 Booked</th>
                <th style={tableHeaderStyle}>Day 13 Available</th>
                <th style={tableHeaderStyle}>Day 13 Booked</th>
                <th style={tableHeaderStyle}>Day 14 Available</th>
                <th style={tableHeaderStyle}>Day 14 Booked</th>
                <th style={tableHeaderStyle}>Day 15 Available</th>
                <th style={tableHeaderStyle}>Day 15 Booked</th>
                <th style={tableHeaderStyle}>Day 16 Available</th>
                <th style={tableHeaderStyle}>Day 16 Booked</th>
                <th style={tableHeaderStyle}>Day 17 Available</th>
                <th style={tableHeaderStyle}>Day 17 Booked</th>
                <th style={tableHeaderStyle}>Day 18 Available</th>
                <th style={tableHeaderStyle}>Day 18 Booked</th>
                <th style={tableHeaderStyle}>Day 19 Available</th>
                <th style={tableHeaderStyle}>Day 19 Booked</th>
                <th style={tableHeaderStyle}>Day 20 Available</th>
                <th style={tableHeaderStyle}>Day 20 Booked</th>
                <th style={tableHeaderStyle}>Day 21 Available</th>
                <th style={tableHeaderStyle}>Day 21 Booked</th>
                <th style={tableHeaderStyle}>Day 22 Available</th>
                <th style={tableHeaderStyle}>Day 22 Booked</th>
                <th style={tableHeaderStyle}>Day 23 Available</th>
                <th style={tableHeaderStyle}>Day 23 Booked</th>
                <th style={tableHeaderStyle}>Day 24 Available</th>
                <th style={tableHeaderStyle}>Day 24 Booked</th>
                <th style={tableHeaderStyle}>Day 25 Available</th>
                <th style={tableHeaderStyle}>Day 25 Booked</th>
                <th style={tableHeaderStyle}>Day 26 Available</th>
                <th style={tableHeaderStyle}>Day 26 Booked</th>
                <th style={tableHeaderStyle}>Day 27 Available</th>
                <th style={tableHeaderStyle}>Day 27 Booked</th>
                <th style={tableHeaderStyle}>Day 28 Available</th>
                <th style={tableHeaderStyle}>Day 28 Booked</th>
                <th style={tableHeaderStyle}>Day 29 Available</th>
                <th style={tableHeaderStyle}>Day 39 Booked</th>
                <th style={tableHeaderStyle}>Day 30 Available</th>
                <th style={tableHeaderStyle}>Day 30 Booked</th>
                <th style={tableHeaderStyle}>Day 31 Available</th>
                <th style={tableHeaderStyle}>Day 31 Booked</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.wcUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.wcUkNo}
                        checked={selectedRows.includes(rowData.wcUkNo)}
                        onChange={() => handleRowSelect(rowData.wcUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.wc_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.wc_WK_CT}</td>
                    <td style={tableCellStyle}>{rowData.wc_YE_AR}</td>
                    <td style={tableCellStyle}>{rowData.wc_MN_TH}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_01}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_01}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_02}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_02}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_03}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_03}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_04}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_04}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_05}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_05}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_06}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_06}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_07}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_07}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_08}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_08}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_09}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_09}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_10}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_10}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_11}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_11}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_12}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_12}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_13}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_13}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_14}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_14}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_15}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_15}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_16}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_16}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_17}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_17}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_18}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_18}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_19}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_19}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_20}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_20}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_21}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_21}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_22}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_22}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_23}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_23}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_24}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_24}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_25}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_25}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_26}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_26}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_27}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_27}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_28}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_28}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_29}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_29}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_30}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_30}</td>
                    <td style={tableCellStyle}>{rowData.wc_AV_31}</td>
                    <td style={tableCellStyle}>{rowData.wc_BK_31}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="Pagination" style={{ marginLeft: "85%" }}>
          <span
            onClick={handleFirstPage}
            style={{
              fontSize: "200%",
              cursor: "pointer",
              whiteSpace: "pre-wrap",
              whiteSpace: "pre-wrap",
            }}
          >
            &laquo;{" "}
          </span>{" "}
          <span
            onClick={handlePreviousPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Prev{" "}
          </span>{" "}
          <span style={{ whiteSpace: "pre-wrap", fontSize: "111%" }}> </span>{" "}
          <span
            onClick={handleNextPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Next{" "}
          </span>{" "}
          <span
            onClick={handleLastPage}
            style={{ fontSize: "200%", cursor: "pointer" }}
          >
            &raquo;
          </span>
        </div>
      </div>

      <WorkCentreCapacityDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}  
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        WorkCentreCapacitytable={WorkCentreCapacitytable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
  
      />
    </div>
  );
};

export default WorkCentreCapacityMain;
