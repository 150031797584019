import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import JobMasterDialog from "./JobMasterDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  jmUkNo:"",
  jm_PR_NO:"",
  jm_PR_DS:"",
  jm_PR_TY:"",
  proj_TYPE:"",
  jm_PR_ST:"",
  proj_STATUS:"",
  jm_PA_PR:"",
  proj_NAME:"",
  jm_PR_CO:"",
  jm_SI_AD:"",
  sitedDES:"",
  jm_OW_AD:"",
  ownerDES:"",
  // jm_ST_AT:"",
  jm_CO_RY:"",
  country:"",
  jm_PS_DT:"",
  jm_PC_DT:"",
  jm_AS_DT:"",
  jm_AC_DT:"",
  jm_AC_CD:"",
};

const initialFilters = {
  jm_PR_NO:{ value: "", operation: "" },
  jm_PR_DS:{ value: "", operation: "" },
  jm_PR_TY:{ value: "", operation: "" },
  proj_TYPE:{ value: "", operation: "" },
  jm_PR_ST:{ value: "", operation: "" },
  proj_STATUS:{ value: "", operation: "" },
  jm_PA_PR:{ value: "", operation: "" },
  proj_NAME:{ value: "", operation: "" },
  jm_PR_CO:{ value: "", operation: "" },
  jm_SI_AD:{ value: "", operation: "" },
  sitedDES:{ value: "", operation: "" },
  jm_OW_AD:{ value: "", operation: "" },
  ownerDES:{ value: "", operation: "" },
  // jm_ST_AT:{ value: "", operation: "" },
  jm_CO_RY:{ value: "", operation: "" },
  country:{ value: "", operation: "" },
  jm_PS_DT:{ value: "", operation: "" },
  jm_PC_DT:{ value: "", operation: "" },
  jm_AS_DT:{ value: "", operation: "" },
  jm_AC_DT:{ value: "", operation: "" },
};

const JobMasterMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,inputStyleH,tableCellStyleH,tableHeaderStyleH
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [jm_PR_TY, setjm_PR_TY] = useState([]);
  const [jm_PR_ST, setjm_PR_ST] = useState([]);
  const [jm_CO_RY, setjm_CO_RY] = useState([]);
  const [jm_CC_01, setjm_CC_01 ] = useState("");
  const [jm_CC_02, setjm_CC_02 ] = useState("");
  const [jm_CC_03, setjm_CC_03 ] = useState("");
  const [jm_CC_04, setjm_CC_04 ] = useState("");
  const [jm_CC_05, setjm_CC_05 ] = useState("");
  const [jm_CC_06, setjm_CC_06 ] = useState("");
  const [jm_CC_07, setjm_CC_07 ] = useState("");
  const [jm_CC_08, setjm_CC_08 ] = useState("");
  const [jm_CC_09, setjm_CC_09 ] = useState("");
  const [jm_CC_10, setjm_CC_10 ] = useState("");
  const [jm_PR_NO, setjm_PR_NO ] = useState("");
  const [jm_JB_TY, setjm_JB_TY ] = useState("");
  // AdvanceSearch
  const [SelectedCostCentre, setselectedCostCentre ] = useState("");
  const [SelectedDesc, setselectedDesc ] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO ] = useState("");
  const [SelectedemEMNO1, setSelectedemEMNO1 ] = useState("");
  const [SelectedAccount, setSelectedAccount ] = useState("");
  const [SelectedAccountNo, setSelectedAccountNo ] = useState("");
  const [SelectedProjectMaster, setSelectedProjectMaster ] = useState("");

  const ProjectStatusDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjm_PR_ST(value);
  };


  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setjm_PR_TY(null);
    setjm_CO_RY(null);
    setjm_CC_01(null);
    setjm_CC_02(null);
    setjm_CC_03(null);
    setjm_CC_04(null);
    setjm_CC_05(null);
    setjm_CC_06(null);
    setjm_CC_07(null);
    setjm_CC_08(null);
    setjm_CC_09(null);
    setjm_CC_10(null);
    setSelectedemEMNO(null);
    setSelectedemEMNO1(null);
    setSelectedAccount(null);
    setSelectedAccountNo(null);
    setSelectedProjectMaster(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setjm_PR_TY(null);
    setjm_CO_RY(null);
    setjm_CC_01(null);
    setjm_CC_02(null);
    setjm_CC_03(null);
    setjm_CC_04(null);
    setjm_CC_05(null);
    setjm_CC_06(null);
    setjm_CC_07(null);
    setjm_CC_08(null);
    setjm_CC_09(null);
    setjm_CC_10(null);
    setSelectedemEMNO(null);
    setSelectedemEMNO1(null);
    setSelectedAccount(null);
    setSelectedAccountNo(null);
    setSelectedProjectMaster(null);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/JobMaster/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const JobMasterData = response.data;
          delete JobMasterData.jmUkNo;
          delete JobMasterData.jm_PR_NO;
          setFormData(JobMasterData);
            setjm_PR_TY(response.data.jm_PR_TY);
            setjm_PR_ST(response.data.jm_PR_ST);
            setjm_CO_RY(response.data.jm_CO_RY);
            setjm_CC_01(response.data.jm_CC_01);
            setjm_CC_02(response.data.jm_CC_02);
            setjm_CC_03(response.data.jm_CC_03);
            setjm_CC_04(response.data.jm_CC_04);
            setjm_CC_05(response.data.jm_CC_05);
            setjm_CC_06(response.data.jm_CC_06);
            setjm_CC_07(response.data.jm_CC_07);
            setjm_CC_08(response.data.jm_CC_08);
            setjm_CC_09(response.data.jm_CC_09);
            setjm_CC_10(response.data.jm_CC_10);
            setSelectedemEMNO(response.data.ownerDES);
            setSelectedemEMNO1(response.data.sitedDES);
            setSelectedAccount(response.data.jm_AC_CD);
            setSelectedAccountNo(response.data.accntDES);
            setSelectedProjectMaster(response.data.proj_NAME);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

const [jm_PR_CO, setjm_PR_CO] = useState("");
const [pm_TR_TY, setpm_TR_TY] = useState("");
const [pm_TR_NO, setpm_TR_NO] = useState("");

  const handleDefault = async () => {
    setjm_PR_CO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "JM_JB_TY"){
              setjm_JB_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "JM_PR_ST"){
              ProjectStatusDropDown("jm_PR_ST",dataDefault.versionIds[i].default_VALUE);
              setjm_PR_ST(dataDefault.versionIds[i].default_VALUE);
            } 
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/JobMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/JobMaster/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const JobMasterData = response.data;
        setFormData(JobMasterData);
        setjm_PR_TY(response.data.jm_PR_TY);
        setjm_PR_ST(response.data.jm_PR_ST);
        setjm_CO_RY(response.data.jm_CO_RY);
        setjm_CC_01(response.data.jm_CC_01);
        setjm_CC_02(response.data.jm_CC_02);
        setjm_CC_03(response.data.jm_CC_03);
        setjm_CC_04(response.data.jm_CC_04);
        setjm_CC_05(response.data.jm_CC_05);
        setjm_CC_06(response.data.jm_CC_06);
        setjm_CC_07(response.data.jm_CC_07);
        setjm_CC_08(response.data.jm_CC_08);
        setjm_CC_09(response.data.jm_CC_09);
        setjm_CC_10(response.data.jm_CC_10);
        setSelectedemEMNO(response.data.ownerDES);
        setSelectedemEMNO1(response.data.sitedDES);
        setSelectedAccount(response.data.accntDES);
        setSelectedAccountNo(response.data.jm_AC_CD);
        setSelectedProjectMaster(response.data.proj_NAME);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const urlNextNumber = `${domain}/api/BaseNumber/All`;
  const parameters = `${jm_JB_TY}/${company}`;
  const finallink = `${urlNextNumber}/${parameters}`;

  var requestBodyInput = {};
  function values() {
    const PR_DS = FormData.jm_PR_DS;
    const PR_CO = FormData.jm_PR_CO;
    const PR_TY = FormData.jm_PR_TY;
    const PA_PR = FormData.jm_PA_PR;
    const CO_RY = FormData.jm_CO_RY;
    const AC_CD = FormData.jm_AC_CD;
    const SI_AD = FormData.jm_SI_AD;
    const OW_AD = FormData.jm_OW_AD;
    const PS_DT = FormData.jm_PS_DT;
    const PC_DT = FormData.jm_PC_DT;
    const AS_DT = FormData.jm_AS_DT;
    const AC_DT = FormData.jm_AC_DT;
    requestBodyInput = {
      jmUkNo: FormData.jmUkNo,
      jm_PR_NO: FormData.jm_PR_NO,
      jm_PR_DS: PR_DS,
      jm_PR_CO: PR_CO,
      jm_PR_TY: PR_TY,
      jm_PR_ST: jm_PR_ST,
      jm_PA_PR: PA_PR,
      jm_CO_RY: CO_RY,
      jm_AC_CD: AC_CD,
      jm_SI_AD: SI_AD,
      jm_OW_AD: OW_AD,
      jm_PS_DT: PS_DT,
      jm_PC_DT: PC_DT,
      jm_AS_DT: AS_DT,
      jm_AC_DT: AC_DT,
      jm_CC_01: FormData.jm_CC_01,
      jm_CC_02: FormData.jm_CC_02,
      jm_CC_03: FormData.jm_CC_03,
      jm_CC_04: FormData.jm_CC_04,
      jm_CC_05: FormData.jm_CC_05,
      jm_CC_06: FormData.jm_CC_06,
      jm_CC_07: FormData.jm_CC_07,
      jm_CC_08: FormData.jm_CC_08,
      jm_CC_09: FormData.jm_CC_09,
      jm_CC_10: FormData.jm_CC_10,

    };
  }
  const handleFormSubmit = () => {
    values();
    const cnNO = FormData.jm_PR_NO;
    if (cnNO) {
      const ukNO = FormData.jmUkNo;
      if (ukNO) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/JobMaster/Update`;
        if (confirm) {
          axios
            .put(update, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        axios.post(`${domain}/api/JobMaster/Add`, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    } else {
      axios.get(finallink, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          setjm_PR_NO(resp.bn_BA_NO);
          FormData.jm_PR_NO = resp.data.bn_BA_NO;
          if(resp.data.bn_BA_NO ===undefined)
          {
            handleResponse(resp.data);
          }else{
            values();
            axios.post(`${domain}/api/JobMaster/Add`, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error adding data:", error);
            });
          }
         
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jm_PR_NO"] ? filters["jm_PR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jm_PR_NO",
                        e.target.value,
                        filters["jm_PR_NO"]
                          ? filters["jm_PR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jm_PR_DS"] ? filters["jm_PR_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jm_PR_DS",
                        e.target.value,
                        filters["jm_PR_DS"] ? filters["jm_PR_DS"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["jm_PR_TY"] ? filters["jm_PR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jm_PR_TY",
                        e.target.value,
                        filters["jm_PR_TY"]
                          ? filters["jm_PR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["proj_TYPE"] ? filters["proj_TYPE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "proj_TYPE",
                        e.target.value,
                        filters["proj_TYPE"]
                          ? filters["proj_TYPE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["jm_PR_ST"] ? filters["jm_PR_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jm_PR_ST",
                        e.target.value,
                        filters["jm_PR_ST"]
                          ? filters["jm_PR_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["proj_STATUS"] ? filters["proj_STATUS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "proj_STATUS",
                        e.target.value,
                        filters["proj_STATUS"]
                          ? filters["proj_STATUS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jm_PA_PR"] ? filters["jm_PA_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jm_PA_PR",
                        e.target.value,
                        filters["jm_PA_PR"]
                          ? filters["jm_PA_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["proj_NAME"] ? filters["proj_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "proj_NAME",
                        e.target.value,
                        filters["proj_NAME"]
                          ? filters["proj_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jm_PR_CO"] ? filters["jm_PR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jm_PR_CO",
                        e.target.value,
                        filters["jm_PR_CO"]
                          ? filters["jm_PR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jm_SI_AD"] ? filters["jm_SI_AD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jm_SI_AD",
                        e.target.value,
                        filters["jm_SI_AD"]
                          ? filters["jm_SI_AD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sitedDES"] ? filters["sitedDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sitedDES",
                        e.target.value,
                        filters["sitedDES"]
                          ? filters["sitedDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jm_OW_AD"] ? filters["jm_OW_AD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jm_OW_AD",
                        e.target.value,
                        filters["jm_OW_AD"]
                          ? filters["jm_OW_AD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ownerDES"] ? filters["ownerDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ownerDES",
                        e.target.value,
                        filters["ownerDES"]
                          ? filters["ownerDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                {/* <td>
                  <input
                    type="text"
                    value={filters["jm_ST_AT"] ? filters["jm_ST_AT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jm_ST_AT",
                        e.target.value,
                        filters["jm_ST_AT"]
                          ? filters["jm_ST_AT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  */}
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["jm_CO_RY"] ? filters["jm_CO_RY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jm_CO_RY",
                        e.target.value,
                        filters["jm_CO_RY"]
                          ? filters["jm_CO_RY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["country"] ? filters["country"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "country",
                        e.target.value,
                        filters["country"]
                          ? filters["country"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jm_PS_DT"] ? filters["jm_PS_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jm_PS_DT",
                        e.target.value,
                        filters["jm_PS_DT"]
                          ? filters["jm_PS_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["jm_PC_DT"] ? filters["jm_PC_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jm_PC_DT",
                        e.target.value,
                        filters["jm_PC_DT"]
                          ? filters["jm_PC_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["jm_AS_DT"] ? filters["jm_AS_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jm_AS_DT",
                        e.target.value,
                        filters["jm_AS_DT"]
                          ? filters["jm_AS_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>          
                <td>
                  <input
                    type="text"
                    value={filters["jm_AC_DT"] ? filters["jm_AC_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jm_AC_DT",
                        e.target.value,
                        filters["jm_AC_DT"]
                          ? filters["jm_AC_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Job No </th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyleH}>Project Type</th>
                <th style={tableHeaderStyle}>Project Type</th>
                <th style={tableHeaderStyleH}>Project Status</th>
                <th style={tableHeaderStyle}>Project Status</th>
                <th style={tableHeaderStyle}>Parent Project</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Site Address</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Owner Address</th>
                <th style={tableHeaderStyle}>Description</th>
                {/* <th style={tableHeaderStyle}>State</th> */}
                <th style={tableHeaderStyleH}>Country</th>
                <th style={tableHeaderStyle}>Country</th>
                <th style={tableHeaderStyle}>Planned Start Date</th>
                <th style={tableHeaderStyle}>Planned Compl. Date</th>
                <th style={tableHeaderStyle}>Actual Start Date</th>
                <th style={tableHeaderStyle}>Actual Compl. Date</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.jmUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.jmUkNo}
                        checked={selectedRows.includes(rowData.jmUkNo)}
                        onChange={() => handleRowSelect(rowData.jmUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.jm_PR_NO}</td>
                    <td style={tableCellStyle}>{rowData.jm_PR_DS}</td>
                    <td style={tableCellStyleH}>{rowData.jm_PR_TY}</td>
                    <td style={tableCellStyle}>{rowData.proj_TYPE}</td>
                    <td style={tableCellStyleH}>{rowData.jm_PR_ST}</td>
                    <td style={tableCellStyle}>{rowData.proj_STATUS}</td>
                    <td style={tableCellStyle}>{rowData.jm_PA_PR}</td>
                    <td style={tableCellStyle}>{rowData.proj_NAME}</td>
                    <td style={tableCellStyle}>{rowData.jm_PR_CO}</td>
                    <td style={tableCellStyle}>{rowData.jm_SI_AD}</td>
                    <td style={tableCellStyle}>{rowData.sitedDES}</td>
                    <td style={tableCellStyle}>{rowData.jm_OW_AD}</td>
                    <td style={tableCellStyle}>{rowData.ownerDES}</td>
                    {/* <td style={tableCellStyle}>{rowData.jm_ST_AT}</td> */}
                    <td style={tableCellStyleH}>{rowData.jm_CO_RY}</td> 
                    <td style={tableCellStyle}>{rowData.country}</td>  
                    <td style={tableCellStyle}>{formatDate(rowData.jm_PS_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.jm_PC_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.jm_AS_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.jm_AC_DT)}</td>     
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
 {/* pagination */}
        <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <JobMasterDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        pm_TR_TY={pm_TR_TY}
        jm_PR_CO={jm_PR_CO}
        jm_PR_TY={jm_PR_TY}
        pm_TR_NO={pm_TR_NO}
        setjm_PR_TY={setjm_PR_TY}
        jm_PR_ST={jm_PR_ST}
        setjm_PR_ST={setjm_PR_ST}
        jm_CO_RY={jm_CO_RY}
        setjm_CO_RY={setjm_CO_RY}
        jm_CC_01={jm_CC_01}
        setjm_CC_01={setjm_CC_01}
        jm_CC_02={jm_CC_02}
        setjm_CC_02={setjm_CC_02}
        jm_CC_03={jm_CC_03}
        setjm_CC_03={setjm_CC_03}
        jm_CC_04={jm_CC_04}
        setjm_CC_04={setjm_CC_04}
        jm_CC_05={jm_CC_05}
        setjm_CC_05={setjm_CC_05}
        jm_CC_06={jm_CC_06}
        setjm_CC_06={setjm_CC_06}
        jm_CC_07={jm_CC_07}
        setjm_CC_07={setjm_CC_07}
        jm_CC_08={jm_CC_08}
        setjm_CC_08={setjm_CC_08}
        jm_CC_09={jm_CC_09}
        setjm_CC_09={setjm_CC_09}
        jm_CC_10={jm_CC_10}
        setjm_CC_10={setjm_CC_10}
        jm_JB_TY={jm_JB_TY}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedemEMNO1={SelectedemEMNO1}
        setSelectedemEMNO1={setSelectedemEMNO1}
        SelectedAccount={SelectedAccount}
        setSelectedAccount={setSelectedAccount}
        SelectedCostCentre={SelectedCostCentre}
        setselectedCostCentre={setselectedCostCentre}
        SelectedDesc={SelectedDesc}
        setselectedDesc={setselectedDesc}
        SelectedAccountNo={SelectedAccountNo}
        setSelectedAccountNo={setSelectedAccountNo}
        SelectedProjectMaster={SelectedProjectMaster}
        setSelectedProjectMaster={setSelectedProjectMaster}
        ProjectStatusDropDown={ProjectStatusDropDown}
      />
    </div>
  );
};

export default JobMasterMain;
