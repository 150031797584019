import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ItemPopup from "../Popups/ItemPopup";
import EquipmentPopup from "../Popups/EquipmentPopup";
import ParentItemPopup from "../Popups/ParentItemPopup";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ProjectMasterPopup from "../Popups/ProjectMasterPopup";
import ClassCode from "views/common/ClassCode";
import PayTerm from "views/common/PayTermDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function ServiceOrderDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  ServiceOrderTable,
  addRow,
  deleteRow,
  CurrencyDropDown,
  onchangegldate,
  sr_OR_TY,
  sr_OR_CO,
  sr_BS_CR,
  sr_EX_RT,
  setsr_EX_RT,
  sr_CO_CT,
  setsr_CO_CT,
  sr_PY_TM,
  setsr_PY_TM,
  setsr_BL_TY,
  sr_SH_TO,
  setsr_SH_TO,
  sr_PR_TY,
  setsr_PR_TY,
  sr_HL_CD,
  setsr_HL_CD,
  ic_CO_CT,
  ic_CO_TY,
  sr_ST_CD,
  price_AMOUNT,
  discount_AMOUNT,
  tax_AMOUNT,
  fr_PRICE_AMOUNT,
  fr_DISCOUNT_AMOUNT,
  fr_TAX_AMOUNT,
  setselectedEQ,
  setselectedNM,
  setselectedItem,
  setselectedDesc,
  setselectedItem1,
  setselectedDesc1,
  SelectedemEMNO,
  setSelectedemEMNO,
  setSelectedemEMNO1,
  selectedAbAdNM,
  setSelectedAbAdNM,
  SelectedProjectMaster,
  setSelectedProjectMaster,
  CostCentreDropDown

}) {
  const {
    srUkNo,
    sr_CU_NO,
    sr_OR_NO,
    sr_OR_DT,
    sr_RQ_DT,
    sr_CU_RF,
    sr_CR_CD,
    sr_PR_NO,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const {footergridstyle,footerfieldstyle } = ButtonStyles();
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AddresstypeC, setAddresstypeC] = useState("C");
  const [AddresstypeE, setAddresstypeE] = useState("E");
  const [currentDate, setCurrentDate] = useState(moment().toDate());
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "sr_OR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const [selectedTRDate, setSelectedTRDate] = useState(moment().toDate());
  const handleDateTRChange = (e) => {
    setSelectedTRDate(e.value);
    onchangedata({ target: { name: "sr_RQ_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  // DROPDOWN
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const { fetchPayTerm } = PayTerm();
  const [sr_PY_TMOptions, setsr_PY_TMOptions] = useState([]);
  const [sr_CO_CTOptions, setsr_CO_CTOptions] = useState([]);
  const [sr_CR_CDOptions, setsr_CR_CDOptions] = useState([]);
  const [sr_BL_TYOptions, setsr_BL_TYOptions] = useState([]);
  const [sr_PR_TYOptions, setsr_PR_TYOptions] = useState([]);
  const [sr_HL_CDOptions, setsr_HL_CDOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const PaymentTermDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsr_PY_TM(value);
  };
  const LocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsr_BL_TY(value);
  };
  const ProjectTypeDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsr_PR_TY(value);
  };
  const HoldCodeDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsr_HL_CD(value);
  };
  useEffect(() => {
    CostCentre();
    Currency();
    PaymentTerm();
    Type();
    ProjectType();
    HoldCode();
  }, []);
  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    setsr_CO_CTOptions(Options);
  };
  const PaymentTerm = async () => {
    const Options = await fetchPayTerm();
    setsr_PY_TMOptions(Options);
  };
  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setsr_CR_CDOptions(Options);
  };
  const Type = async () => {
    const Options = await fetchClassCodeDS("FO/DT");
    setsr_BL_TYOptions(Options);
  };
  const ProjectType = async () => {
    const Options = await fetchClassCodeDS("TR/MO");
    setsr_PR_TYOptions(Options);
  };
  const HoldCode = async () => {
    const Options = await fetchClassCodeDS("TR/MO");
    setsr_HL_CDOptions(Options);
  };
 // --------------------------------------------------------------------------------------
  
// Project Master
  const [ProjectMasterPopupVisible, setProjectMasterPopupVisible] = useState(false);
  const openProjectMasterPopup = (e) => {
    setProjectMasterPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleProjectMaster = async (data) => {
    setSelectedProjectMaster(data.pm_PR_DS);
    onchangeFormData("sr_PR_NO", data.pm_PR_NO);
    setProjectMasterPopupVisible(false);
  };

  // -------------------------------------------------------------------------------------------

// EMPLOYEE
  const [AddressPopupVisible, setAddressPopupVisible] = useState(false);
  const openAddressPopup = (e) => {
    setAddressPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelectE = (data) => {
    setSelectedemEMNO1(data.ab_AD_NM);
    onchangeFormData(`sr_EM_NO_${SelectedRowIndex}`, data.abAdNo);
    onchangeFormData(`name_${SelectedRowIndex}`, data.ab_AD_NM);
    setAddressPopupVisible(false);
    setShowSearchIcon(false);
  };

// ------------------------------------------------------------------------------------------------

// Equipment
const [EquipmentPopupVisible, setEquipmentVisible] = useState(false);
const openEquipmentPopup = () => {
  setEquipmentVisible(true);
};
const handleDataSelect2 = (data) => {
  setselectedEQ(data.em_EQ_NM)
  setselectedNM(data.em_EQ_DS)
  onchangeFormData("sr_EQ_NM", data.em_EQ_NM, SelectedRowIndex);
  onchangeFormData("equiDES", data.em_EQ_DS, SelectedRowIndex);
  setEquipmentVisible(false);
  setShowSearchIcon(false);
};

// -----------------------------------------------------------------------------------------------

  // Customer
 
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("sr_CU_NO", data.abAdNo);

    setSelectedAbAdNM(data.ab_AD_NM);
    setsr_SH_TO( data.abAdNo);
    const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
    const requestData={
    "cu_CU_NO":  data.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCuMt = response.data.Result;
    if (dataCuMt) {
      CurrencyDropDown(`sr_CR_CD`, dataCuMt.cu_CR_CD);
      PaymentTermDropDown(`sr_PY_TM`, dataCuMt.cu_PT_TM);
      const exData={
        "cr_TO_CR": document.getElementById("sr_BS_CR").value, //base curr
        "cr_FR_CR": dataCuMt.cu_CR_CD, //currency
        "cr_EF_DT": data.sr_OR_DT
      }
      exchangeRate (exData);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setCustomerPopupVisible(false);
  };


  // --------------------------------------------------------------------------------
  // Ship To
  const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
  const openShipToPopup = (e) => {
    setShipToPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect1 = async (data) => {
    setSelectedAbAdNM(data.ab_AD_NM);
    setsr_SH_TO( data.abAdNo);
    const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
    const requestData={
      "abAdNo":data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data;
      if (data && data.data) {
        setSelectedAbAdNM(data.data.ab_AD_NM);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setShipToPopupVisible(false);
  };


// ----------------------------------------------------------------------------------------
  // Item Name
  const [ItemVisible, setItemVisible] = useState(false);
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_NM);
    setselectedDesc(data.im_IT_DS);
    onchangeFormData("sr_IT_NM", data.im_IT_NM, SelectedRowIndex);
    document.getElementById(`itemDES_${SelectedRowIndex}`).value = data.im_IT_DS;
    // onchangeFormData("itemDES", data.im_IT_DS, SelectedRowIndex);
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
  const requestData={
      "im_IT_NM":data.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      document.getElementById (`sr_TX_PE_${SelectedRowIndex}`).value =  dataItMt.im_VA_TX;
    } else {
      console.error("Invalid API response:", data);
    }
    await UnitCost(data.im_IT_NM);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItemVisible(false);
    setShowSearchIcon(false);
  };

  // --------------------------------------------------------------------------------------------

  const UnitCost =async (im_IT_NM) => {
    const Url = `${domain}/api/ItemCost/GetUnitCost`;
    const requestData={
      "ic_IT_NM": im_IT_NM,
      "ic_CO_CT": document.getElementById (`ic_CO_CT_${SelectedRowIndex}`).value,
      "ic_CO_TY": document.getElementById (`ic_CO_TY_${SelectedRowIndex}`).value,
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
  
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data;
    if (dataItMt ) {
      document.getElementById (`sr_CO_ST_${SelectedRowIndex}`).value =  dataItMt.ic_CO_ST;
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
   
  };
  // ----------------------------------------------------------------------------------------
  // Billing Item
  const [Item1Visible, setItem1Visible] = useState(false);

  const openItem1Popup = (e) => {
    setItem1Visible(true);
    setSelectedRowIndex(e);
  };
  const handleItem1 =async (data) => {
    setselectedItem1(data.im_IT_NM)
    setselectedDesc1(data.im_IT_DS)
    onchangeFormData("sr_BL_NM", data.im_IT_NM, SelectedRowIndex);
    // onchangeFormData("itemDES1", data.im_IT_DS, SelectedRowIndex);
    document.getElementById(`itemDES1_${SelectedRowIndex}`).value = data.im_IT_DS;
    setItem1Visible(false);
    setShowSearchIcon(false);
  };

// ------------------------------------------------------------------------------------------------
  const onchangeFormUnitPrice = async(e,index) => {
    onChangeOfPrice(index);
  };
  const  onChangeOfPrice= async(SelectedRowIndex) => {
    const UrlCalulate = `${domain}/api/ServiceOrder/GetcalculateAndUpdateTaxDetails`;
    const priceInput = {
              "sr_MK_PE":document.getElementById (`sr_MK_PE_${SelectedRowIndex}`).value,
              "sr_CO_ST":document.getElementById (`sr_CO_ST_${SelectedRowIndex}`).value,
              "sr_AM_DM":document.getElementById (`sr_AM_DM_${SelectedRowIndex}`).value,
              "sr_TX_PE":document.getElementById (`sr_TX_PE_${SelectedRowIndex}`).value,
              "sr_MK_AM":document.getElementById (`sr_MK_AM_${SelectedRowIndex}`).value,
              "sr_DS_PE":document.getElementById (`sr_DS_PE_${SelectedRowIndex}`).value,
              "sr_AM_FR":document.getElementById (`sr_AM_FR_${SelectedRowIndex}`).value,
   }
    try {
      const response =await axios.post(UrlCalulate, priceInput, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCalulate = response.data.Result;
      if (dataCalulate ) {
        document.getElementById (`sr_TX_AM_${SelectedRowIndex}`).value =  dataCalulate.sr_TX_AM;
        document.getElementById (`sr_DS_AM_${SelectedRowIndex}`).value =  dataCalulate.sr_DS_AM;
        document.getElementById (`sr_TX_FR_${SelectedRowIndex}`).value =  dataCalulate.sr_TX_FR;
        document.getElementById (`sr_DS_FR_${SelectedRowIndex}`).value =  dataCalulate.sr_DS_FR;
      } else {
        console.error("Invalid API response:", dataCalulate);
      }
      await GetFooter();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
// ----------------------------------------------------------------------------------------------
  const GetFooter = async (index) => {
    const UrlCalulate = `${domain}/api/ServiceOrder/GetPopulateAmount`;
    const serviceOrders = [];
  
    for (let i = 0; i < ServiceOrderTable.length; i++) {
      serviceOrders.push({
        "sr_AM_DM": document.getElementById(`sr_AM_DM_${i}`).value,
        "sr_DS_AM": document.getElementById(`sr_DS_AM_${i}`).value,
        "sr_TX_AM": document.getElementById(`sr_TX_AM_${i}`).value,
        "sr_AM_FR": document.getElementById(`sr_AM_FR_${i}`).value,
        "sr_DS_FR": document.getElementById(`sr_DS_FR_${i}`).value,
        "sr_TX_FR": document.getElementById(`sr_TX_FR_${i}`).value,
      });
    }
    const priceInput = {
      "serviceOrders": serviceOrders
    };
    try {
      const response = await axios.post(UrlCalulate, priceInput, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      
      const datatax = response.data.Result;
      if (datatax) {
            document.getElementById("price_AMOUNT").value = datatax.total_AMOUNT;
            document.getElementById("discount_AMOUNT").value = datatax.total_DISCOUNT;
            document.getElementById("tax_AMOUNT").value = datatax.total_TAX;
            document.getElementById("fr_PRICE_AMOUNT").value = datatax.total_FOREIGN_AMOUNT;
            document.getElementById("fr_DISCOUNT_AMOUNT").value = datatax.total_FOREIGN_DISCOUNT;
            document.getElementById("fr_TAX_AMOUNT").value = datatax.total_FOREIGN_TAX;
      } else {
        console.error("Invalid API response:", datatax);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
// ----------------------------------------------------------------------------------------------------
  // Exchange Rate
  function exchangeRate(exData) {
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("sr_EX_RT").value = dataExRt.cr_CO_MR;
          setsr_EX_RT(dataExRt.cr_CO_MR);
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

const isUpdateMode = Boolean(srUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{srUkNo ? "Revise Service Order" : "Add Service Order"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      
      <div style={borderbox} >
          <div style={inboxstyle}>
            <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
            <label htmlFor="srUkNo" className="font-bold">
            UK NO
            </label>
            <InputText disabled
              id="srUkNo"
              name="srUkNo"
              defaultValue={srUkNo}
              onChange={(e) => onchangedata(e)}
            />
          </div>

          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sr_OR_NO" className="font-bold">
            Service No
            </label>
            <InputText disabled
              id="sr_OR_NO"
              name="sr_OR_NO"
              value={sr_OR_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>

          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sr_OR_TY" className="font-bold">
            Type 
            </label>
            <InputText disabled
              id="sr_OR_TY"
              name="sr_OR_TY"
              value={sr_OR_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>

          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sr_OR_CO" className="font-bold">
            Company
            </label>
            <InputText disabled
              id="sr_OR_CO"
              name="sr_OR_CO"
              value={sr_OR_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>

          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="sr_OR_DT" className="font-bold">
                Order Date
              </label>
              <Calendar
                id="sr_OR_DT"
                name="sr_OR_DT"
                value={moment(sr_OR_DT, "YYYYMMDD").toDate()}
                onChange={(e) => {
                  handleDateChange(e);
                  onchangegldate({ target: { name: "sr_OR_DT", value: moment(e.value).format("YYYYMMDD") } });
                }}
                
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sr_RQ_DT" className="font-bold">
            Req. Date
                </label>
                <Calendar
                id="sr_RQ_DT"
                name="sr_RQ_DT"
                value={moment(sr_RQ_DT, "YYYYMMDD").toDate()}
                onChange={handleDateTRChange}
                dateFormat="dd/mm/yy"
              />
              </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sr_CO_CT" className="font-bold">
              Cost Centre
            </label>
            <Dropdown disabled
              name="sr_CO_CT"
              value={sr_CO_CT}
              options={sr_CO_CTOptions}
              onChange={(e) => CostCentreDropDown("sr_CO_CT", e.value)}
              placeholder="Select Cost Centre"
            />
          </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="sr_CU_NO" className="font-bold">
                Customer  <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText 
                  id="cus_NAME"
                  name="cus_NAME"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedemEMNO}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openCustomerPopup}
                ></i>
                )}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="sr_CU_NO"
                  name="sr_CU_NO"
                  defaultValue={sr_CU_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openCustomerPopup}
                ></i>
              </div>
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sr_PY_TM" className="font-bold">
              Payment Term
            </label>
            <Dropdown
              name="sr_PY_TM"
              value={sr_PY_TM}
              options={sr_PY_TMOptions}
              onChange={(e) => PaymentTermDropDown("sr_PY_TM", e.value)}
              placeholder="Select Payment Term"
            />
          </div>

                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="sr_SH_TO" className="font-bold">
                Ship To <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="shipto_NAME"
                  name="shipto_NAME"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={selectedAbAdNM}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openShipToPopup}
                ></i>
                )}
              </div>
              <div style={{display:"none"}}>
                <InputText
                  id="sr_SH_TO"
                  name="sr_SH_TO"
                  value={sr_SH_TO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="sr_CR_CD" className="font-bold">
              Currency
              </label>
              <Dropdown 
                name="sr_CR_CD"
                value={sr_CR_CD}
                options={sr_CR_CDOptions}
                onChange={(e) =>CurrencyDropDown("sr_CR_CD", e.value)}
                placeholder="Select Currency"
              />
            </div>

                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sr_EX_RT" className="font-bold">
                    Exchange Rate
                    </label>
                    <InputText disabled
                      id="sr_EX_RT"
                      name="sr_EX_RT"
                      value={sr_EX_RT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>

                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sr_BS_CR" className="font-bold">
                      Base Currency
                    </label>
                    <InputText disabled
                      id="sr_BS_CR"
                      name="sr_BS_CR"
                      value={sr_BS_CR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
              

                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sr_PR_TY" className="font-bold">
                    Project Type
                    </label>
                    <Dropdown 
                      name="sr_PR_TY"
                      value={sr_PR_TY}
                      options={sr_PR_TYOptions}
                      onChange={(e) =>ProjectTypeDropDown("sr_PR_TY", e.value)}
                      placeholder="Select Project Type"
                    />
                  </div>

                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sr_HL_CD" className="font-bold">
                  Hold Code 
                    </label>
                    <Dropdown 
                      name="sr_HL_CD"
                      value={sr_HL_CD}
                      options={sr_HL_CDOptions}
                      onChange={(e) =>HoldCodeDropDown("sr_HL_CD", e.value)}
                      placeholder="Select HoldCode "
                    />
                    </div>

                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                      <label htmlFor="sr_PR_NO" className="font-bold">
                        Project No  <span style={{ color: 'red' }}>*</span>
                      </label>
                      <div style={{ display: "flex" }}>
                        <InputText
                          id="proj_NAME"
                          name="proj_NAME"
                          onChange={(Event) => onchangedata(Event)}
                          onFocus={() => setShowSearchIcon(true)}
                          value={SelectedProjectMaster}
                        />
                        {showSearchIcon && (
                        <i
                          className="pi pi-search"
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "119%",
                            marginTop: "4%",
                            cursor: "pointer",
                          }}
                          onClick={openProjectMasterPopup}
                        ></i>
                        )}
                      </div>
                      <div style={{ display: "none" }}>
                        <InputText
                          id="sr_PR_NO"
                          name="sr_PR_NO"
                          value={sr_PR_NO}
                          onChange={(Event) => onchangedata(Event)}
                        />
                        <i
                          className="pi pi-search"
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "119%",
                            marginTop: "4%",
                            cursor: "pointer",
                          }}
                          onClick={openProjectMasterPopup}
                        ></i>
                      </div>
                    </div>

                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sr_CU_RF" className="font-bold">
                    Customer Ref
                    </label>
                    <InputText 
                      id="sr_CU_RF"
                      name="sr_CU_RF"
                      value={sr_CU_RF}
                      onChange={(e) => onchangedata(e)}
                    />
                    </div>
            </div>
          </div>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
            {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
          </div>
          <div  style={multitablestyle}>
            <table className="custom-table" id="ServiceOrderTable">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th>Bill Item</th>
                  <th>Description</th>
                  <th>Bill Type</th>
                  <th>Item Name</th>
                  <th>Description</th>
                  <th>Cost</th>
                  <th>MarkUp %</th>
                  <th>MarkUp Amount</th>
                  <th>Amount</th>
                  <th>Dicount %</th>
                  <th>Discount Amount</th>
                  <th>Fore.Amount</th>
                  <th>Fore. Discount</th>
                  <th>Fore.Tax</th>
                  <th>Tax %</th>
                  <th>Tax Amount</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Invoice Date</th>
                  <th>Equipment Name</th>
                  <th>Description</th>
                  <th style={{display:"none"}}>Employee No</th>
                  <th>Employee No<span style={{ color: 'red', }}>*</span></th>
                  <th>Status</th>
                  <th style={{ display: "none" }}>Cost Centre</th>
                  <th style={{ display: "none" }}>Type</th>
                </tr>
              </thead>
              <tbody>
                {ServiceOrderTable.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`srUkNo_${index}`}
                        name={`srUkNo_${index}`}
                        value={row.srUkNo}
                      />
                    </td>

                    <td style={{ display: "flex" }}>
                    <InputText 
                    style={{ width: "110px" }}
                    id={`sr_BL_NM_${index}`}
                    name={`sr_BL_NM_${index}`}
                    onChange={(e) => onchangeFormData(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={row.sr_BL_NM ? row.sr_BL_NM: row[`${"sr_BL_NM"}_${index}`]}
                    />
                    {showSearchIcon && (
                  <i
                  className="pi pi-search"
                  style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "15%",
                  cursor: "pointer",
                  }}
                  onClick={(e) => openItem1Popup(index)}
                  ></i>
                  )}
                </td>
                  <td>
                  <InputText disabled
                  style={{ width: "200px" }}
                  id={`itemDES1_${index}`}
                  name={`itemDES1_${index}`}
                  defaultValue={row.itemDES1 ? row.itemDES1 : row[`${"itemDES1"}_${index}`]}
                  readOnly
                  />
                </td>
                <td>
                <Dropdown 
                  id={`sr_BL_TY_${index}`}
                  name={`sr_BL_TY_${index}`}
                  value={ data[`sr_BL_TY_${index}`] ? data[`sr_BL_TY_${index}`]: row.sr_BL_TY}
                  options={sr_BL_TYOptions}
                  onChange={(e) =>LocationDropdown(`sr_BL_TY_${index}`, e.value)}
                  placeholder="Select Bill Type"
                />
                </td>
                <td >
                <div style={{ display: "flex" }}>
                  <InputText 
                    style={{ width: "110px" }}
                    id={`sr_IT_NM_${index}`}
                    name={`sr_IT_NM_${index}`}
                    onChange={(e) => onchangeFormData(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={row.sr_IT_NM ? row.sr_IT_NM: row[`${"sr_IT_NM"}_${index}`]}
                  />
                  {showSearchIcon && (
                    <i
                    className="pi pi-search"
                    style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "15%",
                    cursor: "pointer",
                    }}
                    onClick={(e) => openItemPopup(index)}
                    ></i>
                    )}
                </div>
                  </td>
                    <td>
                    <InputText disabled
                    style={{ width: "200px" }}
                    id={`itemDES_${index}`}
                    name={`itemDES_${index}`}
                    defaultValue={row.itemDES ? row.itemDES : row[`${"itemDES"}_${index}`]}
                    />
                    </td>
                    <td >
                    <InputText   disabled style={{width:"90px"}}
                      id={`sr_CO_ST_${index}`}
                      name={`sr_CO_ST_${index}`}
                      defaultValue={row.sr_CO_ST}
                      onChange={(e) => {
                        onchangeFormUnitPrice(e, index);
                      }}
                    />
                    </td>
                    <td >
                      <InputText  style={{width:"90px"}}
                        id={`sr_MK_PE_${index}`}
                        name={`sr_MK_PE_${index}`}
                        defaultValue={row.sr_MK_PE}
                        onChange={(e) => {
                          onchangeFormUnitPrice(e, index);
                        }}
                      />
                    </td>
                    <td >
                      <InputText   style={{width:"90px"}}
                        id={`sr_MK_AM_${index}`}
                        name={`sr_MK_AM_${index}`}
                        defaultValue={row.sr_MK_AM}
                        onChange={(e) => {
                          onchangeFormUnitPrice(e, index);
                        }}
                      />
                    </td>
                    <td >
                      <InputText  style={{width:"90px"}}
                        disabled={data.sr_CR_CD==sr_BS_CR?false:true}
                        id={`sr_AM_DM_${index}`}
                        name={`sr_AM_DM_${index}`}
                        // defaultValue={data.sr_CR_CD==sr_BS_CR?row.sr_AM_DM:0}
                        defaultValue={row.sr_AM_DM}
                        onChange={(e) => {
                          onchangeFormUnitPrice(e, index);
                        }}
                      />
                    </td>
                    <td >
                      <InputText  style={{width:"90px"}}
                        id={`sr_DS_PE_${index}`}
                        name={`sr_DS_PE_${index}`}
                        defaultValue={row.sr_DS_PE}
                        onChange={(e) => {
                          onchangeFormUnitPrice(e, index);
                        }}
                      />
                    </td>
                    <td >
                      <InputText  disabled style={{width:"90px"}}
                        id={`sr_DS_AM_${index}`}
                        name={`sr_DS_AM_${index}`}
                        defaultValue={row.sr_DS_AM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText   style={{width:"90px"}}
                        disabled={data.sr_CR_CD!=sr_BS_CR?false:true}
                        id={`sr_AM_FR_${index}`}
                        name={`sr_AM_FR_${index}`}
                        // defaultValue={data.sr_CR_CD==sr_BS_CR?row.sr_AM_FR:0}
                        defaultValue={row.sr_AM_FR}
                        onChange={(e) => {
                          onchangeFormUnitPrice(e, index);
                        }}
                      />
                    </td>
                    <td >
                      <InputText  disabled style={{width:"90px"}}
                        id={`sr_DS_FR_${index}`}
                        name={`sr_DS_FR_${index}`}
                        defaultValue={row.sr_DS_FR}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{width:"90px"}}
                        id={`sr_TX_FR_${index}`}
                        name={`sr_TX_FR_${index}`}
                        defaultValue={row.sr_TX_FR}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    
                    <td >
                      <InputText  disabled style={{width:"90px"}}
                        id={`sr_TX_PE_${index}`}
                        name={`sr_TX_PE_${index}`}
                        defaultValue={row.sr_TX_PE}
                        onChange={(e) => {
                          onchangeFormUnitPrice(e, index);
                        }}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{width:"90px"}}
                        id={`sr_TX_AM_${index}`}
                        name={`sr_TX_AM_${index}`}
                        defaultValue={row.sr_TX_AM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                    <Calendar style={{width:"150px"}}
                    id={`sr_FR_DT_${index}`}
                    name={`sr_FR_DT_${index}`}
                    value={row.sr_FR_DT? moment(row.sr_FR_DT, "YYYYMMDD").toDate(): null}
                    onChange={(e) => onDateChange({ target: { name: "sr_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                    />
                    </td>
                    <td>
                    <Calendar style={{width:"150px"}}
                    id={`sr_TO_DT_${index}`}
                    name={`sr_TO_DT_${index}`}
                    value={row.sr_TO_DT? moment(row.sr_TO_DT, "YYYYMMDD").toDate(): null}
                    onChange={(e) => onDateChange({ target: { name: "sr_TO_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                    />
                    </td> 
                    <td>
                    <Calendar disabled
                      style={{width:"150px"}}
                      id={`sr_IN_DT_${index}`}
                      name={`sr_IN_DT_${index}`}
                      value={currentDate}
                      onChange={(e) => {setCurrentDate(e.value);
                      onDateChange("sr_IN_DT", index, e.value);
                      }}
                      dateFormat="dd/mm/yy"
                    />
                  </td>
                  <td >
                  <div style={{ display: "flex" }}>
                    <InputText 
                      style={{ width: "110px" }}
                      id={`sr_EQ_NM_${index}`}
                      name={`sr_EQ_NM_${index}`}
                      onChange={(e) => onchangeFormData(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.sr_EQ_NM ? row.sr_EQ_NM: row[`${"sr_EQ_NM"}_${index}`]}
                    />
                  {showSearchIcon && (
                    <i
                    className="pi pi-search"
                    style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "15%",
                    cursor: "pointer",
                    }}
                    onClick={(e) => openEquipmentPopup(index)}
                    ></i>
                    )}
                  </div>
                  </td>
                    <td>
                    <InputText disabled
                    style={{ width: "200px" }}
                    id={`equiDES_${index}`}
                    name={`equiDES_${index}`}
                    defaultValue={row.equiDES ? row.equiDES : row[`${"equiDES"}_${index}`]}
                    />
                    </td>                      
                    <td style={{display:"none"}}> 
                    <InputText
                    id={`sr_EM_NO_${index}`}
                    name={`sr_EM_NO_${index}`}
                    onFocus={() => setShowSearchIcon(true)}
                    value={row.sr_EM_NO ? row.sr_EM_NO: data[`${"sr_EM_NO"}_${index}`]}
                    onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <div style={{ display: "flex", width:"150px"}}>
                    <InputText
                    id={`name_${index}`}
                    name={`name_${index}`}
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={row.name ? row.name : data[`${"name"}_${index}`]}
                    />
                    {showSearchIcon && (
                    <i
                    className="pi pi-search"
                    style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "7%",
                    cursor: "pointer",
                    }}
                    onClick={(e) => openAddressPopup(index)}
                    ></i>
                    )}
                    </div>
                  </td>
                    <td >
                      <InputText disabled style={{width:"90px"}}
                        id={`sr_ST_CD_${index}`}
                        name={`sr_ST_CD_${index}`}
                        defaultValue={sr_ST_CD}
                      />
                    </td>
                    <td style={{display:"none"}} >
                      <InputText  disabled style={{width:"90px" }}
                        id={`ic_CO_TY_${index}`}
                        name={`ic_CO_TY_${index}`}
                        defaultValue={ic_CO_TY}
                      />
                    </td>
                    <td style={{display:"none"}} >
                      <InputText disabled style={{width:"90px" }}
                        id={`ic_CO_CT_${index}`}
                        name={`ic_CO_CT_${index}`}
                        defaultValue={ic_CO_CT}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
        <div style={inboxstyle} >
        <div className="formgrid grid" style={footergridstyle}>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={footerfieldstyle} >
            <label htmlFor="price_AMOUNT" className="font-bold">
              Amount
            </label>
            <InputText disabled
              id="price_AMOUNT"
              name="price_AMOUNT"
              defaultValue={price_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={footerfieldstyle} >
            <label htmlFor="discount_AMOUNT" className="font-bold">
              Discount
            </label>
            <InputText disabled
              id="discount_AMOUNT"
              name="discount_AMOUNT"
              defaultValue={discount_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={footerfieldstyle} >
            <label htmlFor="tax_AMOUNT" className="font-bold">
              Tax
            </label>
            <InputText disabled
              id="tax_AMOUNT"
              name="tax_AMOUNT"
              defaultValue={tax_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={footerfieldstyle} >
            <label htmlFor="fr_PRICE_AMOUNT" className="font-bold">
              Fore.Amount
            </label>
            <InputText disabled
              id="fr_PRICE_AMOUNT"
              name="fr_PRICE_AMOUNT"
              defaultValue={fr_PRICE_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={footerfieldstyle} >
            <label htmlFor="fr_DISCOUNT_AMOUNT" className="font-bold">
              Fore.Discount
            </label>
            <InputText disabled
              id="fr_DISCOUNT_AMOUNT"
              name="fr_DISCOUNT_AMOUNT"
              defaultValue={fr_DISCOUNT_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={footerfieldstyle} >
            <label htmlFor="fr_TAX_AMOUNT" className="font-bold">
              Fore.Tax
            </label>
            <InputText disabled
              id="fr_TAX_AMOUNT"
              name="fr_TAX_AMOUNT"
              defaultValue={fr_TAX_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
        </div>
        </div>
    </div> 
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={AddresstypeC}
      />
      
      <AddressBookPopup
         visible={ShipToPopupVisible}
         onClose={() => setShipToPopupVisible(false)}
         onDataSelect={handleDataSelect1}
         formData={data}
         addressType={AddresstypeE}
       />

        <AddressBookPopup
        visible={AddressPopupVisible}
        onClose={() => setAddressPopupVisible(false)}
        onDataSelect={handleDataSelectE}
        formData={data}
        addressType={AddresstypeE}
      />

      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />

      <ParentItemPopup
      visible={Item1Visible}
      onClose={() => setItem1Visible(false)}
      onDataSelect={handleItem1}
      formData={data}
    />

        <EquipmentPopup
        visible={EquipmentPopupVisible}
        onClose={() => setEquipmentVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
      />

      <ProjectMasterPopup
        visible={ProjectMasterPopupVisible}
        onClose={() => setProjectMasterPopupVisible(false)}
        onDataSelect={handleProjectMaster}
        formData={data}
      />

    </Dialog>
  );
}
