import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import ClassCode from "views/common/ClassCode";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
import { TabView, TabPanel} from "primereact/tabview";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import EquipmentPopup from "../Popups/EquipmentPopup";
import ServicePackagePopup from "../Popups/ServicePackagePopup";
import ServiceContractPopup from "../Popups/ServiceContractPopup";
// import { Tabs } from "react-bootstrap";
// import { Tab, Tabs, TabList} from 'react-tabs';
export default function ServiceContractDialog({
  open,
  handleClose,
  data,
  onchangedata,
  setFormData,
  onchangeFormData,
  onChangeServiceContractData,
  handleFormSubmit,
  ch_CO_NO,
  ch_SC_TY,
  ch_CO_CO,
  ch_BS_CR,
  ch_CO_CT,
  setch_CO_CT,
  ch_TR_CR,
  setch_TR_CR,
  ch_TX_AP,
  setch_TX_AP,
  cd_BL_UM,
  setcd_BL_UM,
  cd_FR_CY,
  setcd_FR_CY,
  ch_TX_AR,
  setch_TX_AR,
  ch_CO_TY,
  setch_CO_TY,
  cd_TX_RT,
  setcd_TX_RT,
  ch_ST_AT,
  setch_ST_AT,
  ServiceContractData,
  setServiceContractData,
  SelectedCustmr,
  setSelectedCustmr,
  SelectedSite,
  setSelectedSite,
  SelectedEQNO,
  setSelectedEQNO,
  SelectedItem,
  setSelectedItem,
  SelectedBill,
  setSelectedBill,
  SelectedSento,
  setSelectedSento,
  ch_EX_RT,
  setch_EX_RT,  
  onchangestartdate,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData8,
}) {
  const {
    cdUkNo,
    ch_ST_DT,
    ch_EN_DT,
    ch_CO_DS,
    ch_CU_NO,
    ch_DS_PE,
    ch_DS_AM,
    ch_SI_NO,
    ch_PC_NO,
    ch_NO_PE,
    ch_CN_FE,
    ch_BL_TO,
    ch_SN_TO,ch_UK_NO,
  } = data;
  const addRow = () => {
    setServiceContractData([...ServiceContractData, {}]);
  };
  const deleteRow = () => {
    if (ServiceContractData.length > 0) {
      const updatedData = [...ServiceContractData];
      updatedData.pop();
      setServiceContractData(updatedData);
    }
  };
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,multitableheader} = ButtonStyles();
  const [activeTab, setActiveTab] = useState(0);
  // DEFAULT DATE
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "ch_ST_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [ch_CO_CTOptions, setch_CO_CTOptions] = useState([]);
  const [ch_TR_CROptions, setch_TR_CROptions] = useState([]);
  const [ch_CO_TYOptions, setch_CO_TYOptions] = useState([]);
  const [ch_TX_APOptions, setch_TX_APOptions] = useState([]);
  const [cd_BL_UMOptions, setcd_BL_UMOptions] = useState([]);
  const [cd_FR_CYOptions, setcd_FR_CYOptions] = useState([]);
  const [ch_TX_AROptions, setch_TX_AROptions] = useState([]);
  const [ch_ST_ATOptions, setch_ST_ATOptions] = useState([]);
  const [cd_TX_RTOptions, setcd_TX_RTOptions] = useState([]);

// Tax AP
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setch_TX_AP(value);
  };
// UM
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcd_BL_UM(value);
  };
// Frequency
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcd_FR_CY(value);
  };
// Tax area
const onchangeDropDownFormData6 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setch_TX_AR(value);
};
// contract type
const onchangeDropDownFormData7 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setch_CO_TY(value);
};
// contract type
const onchangeDropDownFormData9 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setcd_TX_RT(value);
};

  useEffect(() => {
    CostCenter();
    fetchDropdownCurr();
    fetchDropdownCntype();
    fetchDropdownTxAP();
    fetchDropdownLG();
    fetchDropdownDM();
    fetchDropdownTxAR();
    fetchDropdownstats();
    fetchDropdowntxrate();
  }, []);

  const CostCenter = async () => {
    const Options = await fetchCostCenter();
    setch_CO_CTOptions(Options);
  };
  const fetchDropdownCurr = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setch_TR_CROptions(Options);
  };

  const fetchDropdownCntype = async () => {
    const Options = await fetchClassCodeDS("CO/TY");
    setch_CO_TYOptions(Options);
  };

  const fetchDropdownTxAP = async () => {
    const Options = await fetchClassCodeDS("CP/LN");
    setch_TX_APOptions(Options);
  };

  const fetchDropdownLG = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setcd_BL_UMOptions(Options);
  };

  const fetchDropdownDM = async () => {
    const Options = await fetchClassCodeDS("BL/FR");
    setcd_FR_CYOptions(Options);
  };

  const fetchDropdownTxAR = async () => {
    const Options = await fetchClassCodeDS("FO/TA");
    setch_TX_AROptions(Options);
  };

  const fetchDropdownstats = async () => {
    const Options = await fetchClassCodeDS("SH/ST");
    setch_ST_ATOptions(Options);
  };
  const fetchDropdowntxrate = async () => {
    const Options = await fetchClassCodeCD("TX/RT");
    setcd_TX_RTOptions(Options);
  };

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // advance search  
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [SitePopupVisible, setSitePopupVisible] = useState(false);
  const [EquipmentPopupVisible, setEquipmentPopupVisible] = useState(false);
  const [ItemPopupVisible, setItemPopupVisible] = useState(false);
  const [ServicePackagePopupVisible, setServicePackagePopupVisible] = useState(false);
  // 2nd tab
  const [ServiceContractPopupVisible, setServiceContractPopupVisible] = useState(false);
  const [BillPopupVisible, setBillPopupVisible] = useState(false);
  const [SendToPopupVisible, setSendToPopupVisible] = useState(false);

  const openParentCoPopup = () => {
    setServiceContractPopupVisible(true);
  };
  const handleDataSelectContract = (data) => {
    document.getElementById("ch_PC_NO").value = data.cd_CO_NO;
    setServiceContractPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openBillPopup = () => {
    setBillPopupVisible(true);
  };
  const handleDataSelectBill = (data) => {
    setSelectedBill(data.ab_AD_NM);
    onchangeFormData("ch_BL_TO", data.abAdNo);
    setBillPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openSendtoPopup = () => {
    setSendToPopupVisible(true);
  };
  const handleDataSelectSendTo = (data) => {
    setSelectedSento(data.ab_AD_NM);
    onchangeFormData("ch_SN_TO", data.abAdNo);
    setSendToPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [addressTypecust, setaddressTypecust] = useState("C");
  const [addressTypesite, setaddressTypesite] = useState("F");
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const handleDataSelectCust = async (data) => {
    setSelectedCustmr(data.ab_AD_NM);
    onchangeFormData("ch_CU_NO", data.abAdNo);
    // document.getElementById("ch_CU_NO").value = data.abAdNo;
    const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
    const requestData={
      "cu_CU_NO":data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.Result;
      if (dataLead) {
        onchangeDropDownFormData2("ch_TR_CR", dataLead.cu_CR_CD);
        onchangeDropDownFormData3("ch_TX_AP", dataLead.cu_TX_AP);
        const exData={
          "cr_TO_CR": document.getElementById("ch_BS_CR").value, //base curr
          "cr_FR_CR": dataLead.cu_CR_CD, //currency
          "cr_EF_DT": ch_ST_DT
        }
        exchangeRate (exData);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  function exchangeRate(exData) {
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("ch_EX_RT").value = dataExRt.cr_CO_MR;
          setch_EX_RT(dataExRt.cr_CO_MR);
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  }
  const openSitePopup = () => {
    setSitePopupVisible(true);
  };

  const handleDataSelectSite = (data) => {
    setSelectedSite(data.ab_AD_NM);
    onchangeFormData("ch_SI_NO", data.abAdNo);
    setSitePopupVisible(false);
    setShowSearchIcon(false);
  };

  const openEquipmentPopup = (e) => {
    setEquipmentPopupVisible(true);
    setSelectedRowIndex(e);
  };

  const handleDataSelectEquipment = (data) => {
    onChangeServiceContractData('em_EQ_DS',data.em_EQ_DS,SelectedRowIndex);
    onChangeServiceContractData('cd_EQ_NO',data.em_EQ_NM,SelectedRowIndex);
    onChangeServiceContractData('cd_PR_MD',data.em_PD_MD,SelectedRowIndex);
    setEquipmentPopupVisible(false);
    setShowSearchIcon(false);
  };

  const openItemPopup = (e) => {
    setItemPopupVisible(true);
    setSelectedRowIndex(e);
  };
  
  const handleDataSelectItem = (data) => {
    onChangeServiceContractData('cd_IT_NO',data.im_IT_NM,SelectedRowIndex);
    onChangeServiceContractData('im_IT_DS',data.im_IT_DS,SelectedRowIndex);
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };

  const openServicerPopup = (e) => {
    setServicePackagePopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelectSRPK = (content) => {
    onChangeServiceContractData('cd_SR_PK', content.sp_SE_PK, SelectedRowIndex);
    onChangeServiceContractData('sp_PK_DS', content.sp_PK_DS, SelectedRowIndex);
    onChangeServiceContractData('sp_SE_TY', content.sp_SE_TY, SelectedRowIndex);
    onChangeServiceContractData('service_DESC', content.service_DESC, SelectedRowIndex);
    onChangeServiceContractData('sp_CV_TY', content.sp_CV_TY, SelectedRowIndex);
    onChangeServiceContractData('coverage_DESC', content.coverage_DESC, SelectedRowIndex);
    onChangeServiceContractData('sp_CV_GP', content.sp_CV_GP, SelectedRowIndex);
    onChangeServiceContractData('coverage_GRP', content.coverage_GRP, SelectedRowIndex);
    setServicePackagePopupVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(cdUkNo);
  const productDialogFooter = (
    <React.Fragment>
       {activeTab === 0 && (
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      ></Button>
       )}
       {activeTab === 0 && (
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
        tooltip={isUpdateMode ? "Update" : "Submit"}
      ></Button>
       )}
    </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={cdUkNo ? "Revise Service Contract" : "Add Service Contract"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Service Contract"  id="tab1">
          <div
            style={{
              border: "1px solid #e8e8e8",
              padding: "16px",
              borderRadius: "15px",
              backgroundColor: "aliceblue",
            }}
          >
            <div className="formgrid grid">
            <div style={{display:"none"}}>
                <label htmlFor="ch_UK_NO" className="font-bold">
                  UK No
                </label>
                <InputText disabled
                  id="ch_UK_NO"
                  name="ch_UK_NO"
                  value={ch_UK_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_CO_NO" className="font-bold">
                  Contract No
                </label>
                <InputText disabled
                  id="ch_CO_NO"
                  name="ch_CO_NO"
                  value={ch_CO_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_SC_TY" className="font-bold">
                  Type
                </label>
                <InputText disabled
                  id="ch_SC_TY"
                  name="ch_SC_TY"
                  value={ch_SC_TY}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_CO_CO" className="font-bold">
                  Company
                </label>
                <InputText disabled
                  id="ch_CO_CO"
                  name="ch_CO_CO"
                  value={ch_CO_CO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_CO_TY" className="font-bold">
                Contract Type
                </label>
                <Dropdown
                  id="ch_CO_TY"
                  name="ch_CO_TY"
                  value={ch_CO_TY}
                  options={ch_CO_TYOptions}
                  onChange={(e) => onchangeDropDownFormData7("ch_CO_TY", e.value)}
                  placeholder="Select Contract Type"
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_CO_CT" className="font-bold">
                  Cost Centre
                </label>
                <Dropdown
                  id="ch_CO_CT"
                  name="ch_CO_CT"
                  value={ch_CO_CT}
                  options={ch_CO_CTOptions}
                  onChange={(e) => onchangeDropDownFormData1("ch_CO_CT", e.value)}
                  placeholder="Select Cost Centre"
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_ST_DT" className="font-bold">
                  Start Date
                </label>
                <Calendar
                    id="ch_ST_DT"
                    name="ch_ST_DT"
                    value={moment(ch_ST_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => {
                      handleDateChange(e);
                      // onchangestartdate({ target: { name: "rc_TR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : ""}  });
                     }}
                    dateFormat="dd/mm/yy"
                  />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_CU_NO" className="font-bold">
                  Customer <span style={{color:"red"}}> *</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText
                    id="ab_AD_NM"
                    name="ab_AD_NM"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedCustmr}
                  />
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openCustomerPopup}
                  ></i>
                </div>
                <div style={{ display: "block" }}>
                  <InputText
                    id="ch_CU_NO"
                    name="ch_CU_NO"
                    value={ch_CU_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_CO_DS" className="font-bold">
                  Description
                </label>
                <InputText
                  id="ch_CO_DS"
                  name="ch_CO_DS"
                  value={ch_CO_DS}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_EN_DT" className="font-bold">
                  End Date
                </label>
                <Calendar
                  id="ch_EN_DT"
                  name="ch_EN_DT"
                  value={moment(data.ch_EN_DT, "YYYYMMDD").isValid() ? moment(data.ch_EN_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "ch_EN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_TR_CR" className="font-bold">
                Currency
                </label>
                <Dropdown
                  id="ch_TR_CR"
                  name="ch_TR_CR"
                  value={ch_TR_CR}
                  options={ch_TR_CROptions}
                  onChange={(e) => onchangeDropDownFormData2("ch_TR_CR", e.value)}
                  placeholder="Select Currency"
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_EX_RT" className="font-bold">
                  Exchange Rate
                </label>
                <InputText disabled
                  id="ch_EX_RT"
                  name="ch_EX_RT"
                  value={ch_EX_RT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_BS_CR" className="font-bold">
                Base Currency
                </label>
                <InputText disabled
                  id="ch_BS_CR"
                  name="ch_BS_CR"
                  value={ch_BS_CR}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
             
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_SI_NO" className="font-bold">
                  Site <span style={{color:"red"}}> *</span>
                </label>
                <div style={{ display: 'flex' }}>
                  <InputText
                    id="ab_AD_NM"
                    name="ab_AD_NM"
                    onChange={(event) => onchangedata(event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedSite}
                  />
                  {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: 'var(--primary-color)',
                        fontSize: '119%',
                        marginTop: '4%',
                        cursor: 'pointer',
                      }}
                      onClick={openSitePopup}
                    ></i>
                  )}
                </div>
                <div style={{ display: 'none' }}>
                  <InputText
                    id="ch_SI_NO"
                    name="ch_SI_NO"
                    value={ch_SI_NO}
                    onChange={(event) => onchangedata(event)}
                  />
                </div>
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_ST_AT" className="font-bold">
                  Status
                </label>
                <Dropdown
                  id="ch_ST_AT"
                  name="ch_ST_AT"
                  value={ch_ST_AT}
                  options={ch_ST_ATOptions}
                  onChange={(e) => onchangeDropDownFormData8("ch_ST_AT", e.value)}
                  placeholder="Select Status"
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_TX_AP" className="font-bold">
                  Tax Applicable
                </label>
                <Dropdown
                  id="ch_TX_AP"
                  name="ch_TX_AP"
                  value={ch_TX_AP}
                  // options={ch_TX_APOptions}
                  options={[
                    { label: "Yes", value: "y" },
                    { label: "No", value: "n" },
                    ]}
                  onChange={(e) => onchangeDropDownFormData3("ch_TX_AP", e.value)}
                  placeholder="Select Tax Ap."
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_DS_PE" className="font-bold">
                  Discount %
                </label>
                <InputText
                  id="ch_DS_PE"
                  name="ch_DS_PE"
                  value={ch_DS_PE}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_DS_AM" className="font-bold">
                  Discount Amount
                </label>
                <InputText
                  id="ch_DS_AM"
                  name="ch_DS_AM"
                  value={ch_DS_AM}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
            </div>
          </div>
        {/* 2nd tab fields */}
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
            <div  style={multitablestyle}>
            <table className="custom-table" id="ServiceContractTable">
              <thead>
                <tr>
                  <th style={{display:"none"}}>UK_no</th>
                  <th>Line No</th>
                  {/* <th>Contract Type</th>
                  <th>Description</th> */}
                  <th>Equipment No <span style={{color:"red"}}> *</span> </th>
                  <th>Product Model</th>
                  <th>Item Number <span style={{color:"red"}}> *</span> </th>
                  <th>Install Date </th>
                  <th>Service Package  <span style={{color:"red"}}> *</span> </th>
                  <th>Service Type</th>
                  <th>Coverage Type</th>
                  <th>Coverage Group</th>
                  <th>Quantity</th>
                  <th>UM</th>
                  <th>Amount</th>
                  <th>Recurring Bill Amount</th>
                  <th>Tax Rate</th>
                  <th>Tax Amount</th>
                  <th>Customer PO</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Bill From </th>
                  <th>Bill To</th>
                  <th>Frequency</th>
                </tr>
              </thead>
              <tbody>
              {ServiceContractData.map((row, index) =>  (
              <tr key={index}>
              <td style={{display:"none"}}>
                <InputText disabled
                  style={{ width: "90px" }}
                  id={`cdUkNo_${index}`}
                  name={`cdUkNo_${index}`}
                  value={row.cdUkNo}
                />
              </td>
              <td>
              <InputText disabled
                style={{ width: "90px" }}
                id={`cd_LN_NO_${index}`}
                name={`cd_LN_NO_${index}`}
                value={index + 1}
                readOnly
              />
              </td>
              {/* <td>
              <div style={{display:"flex"}}>
              <InputText 
                id={`cd_CO_TY_${index}`}
                name={`cd_CO_TY_${index}`}
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={row.cd_CO_TY ? row.cd_CO_TY : row[`${'cd_CO_TY'}_${index}`]}
              />
              {showSearchIcon && (
              <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "7%",
                    cursor: "pointer",
                  }}
                  // onClick={(e)=>openContractTypePopup(index)}
                ></i>
              )}
              </div>
              </td>
              <td>
              <InputText 
                id={`ContType_${index}`}
                name={`ContType_${index}`}
                defaultValue={row.ContType}
                onChange={(Event) => onchangedata(Event)}
              />
              </td> */}
              <td>
              <div style={{display:"flex",width:"200px"}}>
              <InputText 
                id={`em_EQ_DS_${index}`}
                name={`em_EQ_DS_${index}`}
                onChange={(e) => onChangeServiceContractData('em_EQ_DS',e.value,index)}
                onFocus={() => setShowSearchIcon(true)}
                value={row.em_EQ_DS ? row.em_EQ_DS : row[`${'em_EQ_DS'}_${index}`]}
              />
              {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "7%",
                  cursor: "pointer",
                }}
                onClick={(e)=>openEquipmentPopup(index)}
              ></i>
              )}
                </div>
                <InputText 
                  style={{display:"block"}}
                  id={`cd_EQ_NO_${index}`}
                  name={`cd_EQ_NO_${index}`}
                  onChange={(e) => onChangeServiceContractData('cd_EQ_NO',e.value,index)}
                  value={row.cd_EQ_NO ? row.cd_EQ_NO : row[`${'cd_EQ_NO'}_${index}`]}
                />
              </td>
              <td>
              <InputText style={{width:"150px"}} disabled
                id={`cd_PR_MD_${index}`}
                name={`cd_PR_MD_${index}`}
                value={row.cd_PR_MD ? row.cd_PR_MD : row[`${'cd_PR_MD'}_${index}`]}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <div style={{display:"flex"}}>
              <InputText 
                style={{width:"200px"}}
                id={`im_IT_DS_${index}`}
                name={`im_IT_DS_${index}`}
                onChange={(e) => onChangeServiceContractData('im_IT_DS',e.value,index)}
                onFocus={() => setShowSearchIcon(true)}
                defaultValue={row.im_IT_DS ? row.im_IT_DS : row[`${"im_IT_DS"}_${index}`]}
              />
              {showSearchIcon && (
              <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "7%",
                    cursor: "pointer",
                  }}
                  onClick={(e)=>openItemPopup(index)}
                ></i>
              )}
                </div>
                <InputText style={{display:"block"}}
                  id={`cd_IT_NO_${index}`}
                  name={`cd_IT_NO_${index}`}
                  defaultValue={row.cd_IT_NO ? row.cd_IT_NO: row[`${"cd_IT_NO"}_${index}`]}
                  onChange={(e) => onChangeServiceContractData('cd_IT_NO',e.value,index)}
                />
              </td>
              <td>
              <Calendar style={{width:"150px"}}
                id={`cd_IN_DT_${index}`}
                name={`cd_IN_DT_${index}`}
                value={row.cd_IN_DT ? moment(row.cd_IN_DT, "YYYYMMDD").toDate() : null }
                onChange={(e) => onChangeServiceContractData('cd_IN_DT',moment(e.value).format("YYYYMMDD"),index)}
                dateFormat="dd/mm/yy"
              />
              </td>
              
              <td>
                <div style={{ display: "flex" }}>
                  <InputText style={{width:"150px"}}
                    id={`sp_PK_DS_${index}`}
                    name={`sp_PK_DS_${index}`}
                    onChange={(e) => onChangeServiceContractData('sp_PK_DS',e.value,index)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={row.sp_PK_DS ? row.sp_PK_DS : row[`${'sp_PK_DS'}_${index}`]}
                  />
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={(e)=>openServicerPopup(index)}
                  ></i>
                </div>
                {/* <InputText style={{width:"150px",display:"block"}}
                    id={`cd_SR_PK_${index}`}
                    name={`cd_SR_PK_${index}`}
                    defaultValue={data[`cd_SR_PK_${index}`] ? data[`cd_SR_PK_${index}`] : row.cd_SR_PK}
                    onChange={(e) => onChangeServiceContractData('cd_SR_PK',e.value,index)}
                  /> */}
                <InputText style={{display:"block"}}
                  id={`cd_SR_PK_${index}`}
                  name={`cd_SR_PK_${index}`}
                  value={row.cd_SR_PK ? row.cd_SR_PK: row[`${"cd_SR_PK"}_${index}`]}
                  onChange={(e) => onChangeServiceContractData('cd_IT_NO',e.value,index)}
                />
              </td>
              <td>
                <InputText style={{width:"200px",display:"block"}} //service type
                  id={`sp_SE_TY_${index}`}
                  name={`sp_SE_TY_${index}`}
                  defaultValue={row.sp_SE_TY ? row.sp_SE_TY : row[`${'sp_SE_TY'}_${index}`]}
                  onChange={(e) => onchangedata(e)}
                />
                <InputText style={{width:"200px",}} //service typedesc
                  id={`service_DESC_${index}`}
                  name={`service_DESC_${index}`}
                  defaultValue={row.service_DESC ? row.service_DESC : row[`${'service_DESC'}_${index}`]}
                  onChange={(e) => onChangeServiceContractData('service_DESC',e.value,index)}
                />
              </td>
              <td>
              <InputText  style={{width:"100px",display:"block"}}  // coverage type
                id={`sp_CV_TY_${index}`}
                name={`sp_CV_TY_${index}`}
                value={data[`sp_CV_TY_${index}`] ? data[`sp_CV_TY_${index}`] : row.sp_CV_TY}
                onChange={(e) => onChangeServiceContractData('sp_CV_TY',e.value,index)}
              />
               <InputText  style={{width:"100px"}}  // coverage type dwesc
                id={`coverage_DESC_${index}`}
                name={`coverage_DESC_${index}`}
                value={data[`coverage_DESC_${index}`] ? data[`coverage_DESC_${index}`] : row.coverage_DESC}
                onChange={(e) => onChangeServiceContractData('coverage_DESC',e.value,index)}
              />
              </td>
              <td>
                <InputText style={{width:"100px",display:"block"}} // coverage grp
                  id={`sp_CV_GP_${index}`}
                  name={`sp_CV_GP_${index}`}
                  defaultValue={row.sp_CV_GP ? row.sp_CV_GP : row[`${'sp_CV_GP'}_${index}`]}
                  onChange={(e) => onChangeServiceContractData('sp_CV_GP',e.value,index)}
                />
                 <InputText style={{width:"100px"}} // coverage grp desc
                  id={`coverage_GRP_${index}`}
                  name={`coverage_GRP_${index}`}
                  defaultValue={row.coverage_GRP ? row.coverage_GRP : row[`${'coverage_GRP'}_${index}`]}
                  onChange={(e) => onChangeServiceContractData('coverage_GRP',e.value,index)}
                />
              </td>
              <td>
                <InputText style={{width:"100px"}} 
                  id={`cd_BL_QT_${index}`}
                  name={`cd_BL_QT_${index}`}
                  value={data[`cd_BL_QT_${index}`] ? data[`cd_BL_QT_${index}`] : row.cd_BL_QT}
                  onChange={(e) => onchangedata(e)}
                />
              </td>
              <td>
              <Dropdown
                id={`cd_BL_UM_${index}`}
                name={`cd_BL_UM_${index}`}
                value={data[`cd_BL_UM_${index}`] ? data[`cd_BL_UM_${index}`]:row.cd_BL_UM }
                options={cd_BL_UMOptions}
                onChange={(e) => onchangeDropDownFormData4(`cd_BL_UM_${index}`, e.value)}
                placeholder="Select UM"
              />
              </td>
              <td>
                <InputText style={{width:"100px"}}
                  id={`cd_BL_AM_${index}`}
                  name={`cd_BL_AM_${index}`}
                  value={data[`cd_BL_AM_${index}`] ? data[`cd_BL_AM_${index}`] : row.cd_BL_AM}
                  onChange={(e) => onchangedata(e)}
                />
              </td>
              <td>
                <InputText style={{width:"100px"}}
                  id={`cd_RC_AM_${index}`}
                  name={`cd_RC_AM_${index}`}
                  value={data[`cd_RC_AM_${index}`] ? data[`cd_RC_AM_${index}`] : row.cd_RC_AM}
                  onChange={(e) => onchangedata(e)}
                />
              </td>
              <td>
                <Dropdown
                  id={`cd_TX_RT_${index}`}
                  name={`cd_TX_RT_${index}`}
                  value={data[`cd_TX_RT_${index}`] ? data[`cd_TX_RT_${index}`]:row.cd_TX_RT }
                  options={cd_TX_RTOptions}
                  onChange={(e) => onchangeDropDownFormData9(`cd_TX_RT_${index}`, e.value)}
                  placeholder="Select Tax Rate"
                />
              </td>
              <td>
                <InputText style={{width:"100px"}}
                  id={`cd_TX_AM_${index}`}
                  name={`cd_TX_AM_${index}`}
                  value={data[`cd_TX_AM_${index}`] ? data[`cd_TX_AM_${index}`] : row.cd_TX_AM}
                  onChange={(e) => onchangedata(e)}
                />
              </td>
              <td>
                <InputText style={{width:"100px"}}
                  id={`cd_CU_PO_${index}`}
                  name={`cd_CU_PO_${index}`}
                  value={data[`cd_CU_PO_${index}`] ? data[`cd_CU_PO_${index}`] : row.cd_CU_PO}
                  onChange={(e) => onchangedata(e)}
                />
              </td>
              <td>
               <Calendar style={{width:"150px"}}
                id={`cd_ST_DT_${index}`}
                name={`cd_ST_DT_${index}`}
                value={row.cd_ST_DT ? moment(row.cd_ST_DT, "YYYYMMDD").toDate() : null }
                onChange={(e) => onChangeServiceContractData('cd_ST_DT',moment(e.value).format("YYYYMMDD"),index)}
                dateFormat="dd/mm/yy"
              />
              </td>
              <td>
              <Calendar style={{width:"150px"}}
                id={`cd_EN_DT_${index}`}
                name={`cd_EN_DT_${index}`}
                value={row.cd_EN_DT ? moment(row.cd_EN_DT, "YYYYMMDD").toDate() : null }
                onChange={(e) => onChangeServiceContractData('cd_EN_DT',moment(e.value).format("YYYYMMDD"),index)}
                dateFormat="dd/mm/yy"
              />
              </td>
              <td>
              <Calendar style={{width:"150px"}}
                id={`cd_BL_FR_${index}`}
                name={`cd_BL_FR_${index}`}
                value={row.cd_BL_FR ? moment(row.cd_BL_FR, "YYYYMMDD").toDate() : null }
                onChange={(e) => onChangeServiceContractData('cd_BL_FR',moment(e.value).format("YYYYMMDD"),index)}
                dateFormat="dd/mm/yy"
              />
              </td>
              <td>
              <Calendar style={{width:"150px"}}
                id={`cd_BL_TO_${index}`}
                name={`cd_BL_TO_${index}`}
                value={row.cd_BL_TO ? moment(row.cd_BL_TO, "YYYYMMDD").toDate() : null }
                onChange={(e) => onChangeServiceContractData('cd_BL_TO',moment(e.value).format("YYYYMMDD"),index)}
                dateFormat="dd/mm/yy"
              />
              </td>
              <td>
              <Dropdown
                id={`cd_FR_CY_${index}`}
                name={`cd_FR_CY_${index}`}
                value={data[`cd_FR_CY_${index}`] ? data[`cd_FR_CY_${index}`]:row.cd_FR_CY }
                options={cd_FR_CYOptions}
                onChange={(e) => onchangeDropDownFormData5(`cd_FR_CY_${index}`, e.value)}
                placeholder="Select Frequency"
              />
              </td>
            </tr>
          ))}
        </tbody>
            </table>
          </div>
        </div>
        </TabPanel>
        <TabPanel header="Additional Information" id="tab2">
        <div style={inboxstyle} >
            <div className="formgrid grid">
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ch_PC_NO" className="font-bold">
                Parent Con. No. <span style={{color:"red"}}> *</span>
              </label>
              <div style={{ display: 'flex' }}>
              <InputText
                  id="ch_PC_NO"
                  name="ch_PC_NO"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  defaultValue={ch_PC_NO}
                />
               {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: 'var(--primary-color)',
                      fontSize: '119%',
                      marginTop: '4%',
                      cursor: 'pointer',
                    }}
                    onClick={openParentCoPopup}
                  ></i>
                )}
                </div>
              </div> 
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ch_CN_DT" className="font-bold">
              Cancel Date
              </label>
              <Calendar
                id="ch_CN_DT"
                name="ch_CN_DT"
                value={moment(data.ch_CN_DT, "YYYYMMDD").isValid() ? moment(data.ch_CN_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "ch_CN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
              </div> 
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ch_NO_PE" className="font-bold">
                Can. Not. Prd.
              </label>
              <InputText
                id="ch_NO_PE"
                name="ch_NO_PE"
                value={ch_NO_PE}
                onChange={(Event) => onchangedata(Event)}
              />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ch_CN_FE" className="font-bold">
            Cancel Fee
          </label>
          <InputText
            id="ch_CN_FE"
            name="ch_CN_FE"
            value={ch_CN_FE}
            onChange={(Event) => onchangedata(Event)}
          />
              </div>   
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_BL_TO" className="font-bold">
                  Bill To <span style={{color:"red"}}> *</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText
                    id="ab_AD_NM"
                    name="ab_AD_NM"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}                
                    value={SelectedBill}
                  />
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openBillPopup}
                  ></i>
                </div>
                <InputText
                  style={{ display: "none" }}
                  id="ch_BL_TO"
                  name="ch_BL_TO"
                  value={ch_BL_TO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_SN_TO" className="font-bold">
                Send To <span style={{color:"red"}}> *</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText
                    id="ab_AD_NM"
                    name="ab_AD_NM"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedSento}
                  />
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openSendtoPopup}
                  ></i>
                </div>
                <div style={{ display: "none" }}>
                  <InputText
                    id="ch_SN_TO"
                    name="ch_SN_TO"
                    value={ch_SN_TO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ch_TX_AR" className="font-bold">
                Tax Area
                </label>
                <Dropdown
                  id="ch_TX_AR"
                  name="ch_TX_AR"
                  value={ch_TX_AR}
                  options={ch_TX_AROptions}
                  onChange={(e) => onchangeDropDownFormData6("ch_TX_AR", e.value)}
                  placeholder="Select Type"
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>

       
         {/* custoemr */}
         <AddressBookPopup
            visible={CustomerPopupVisible}
            onClose={() => setCustomerPopupVisible(false)}
            onDataSelect={handleDataSelectCust}
            formData={data}
            addressType={addressTypecust}
            setFormData={setFormData}
          />
          {/* site */}
          <AddressBookPopup
            visible={SitePopupVisible}
            onClose={() => setSitePopupVisible(false)}
            onDataSelect={handleDataSelectSite}
            formData={data}
            addressType={addressTypesite}
            setFormData={setFormData}
          />
          {/* equipment */}
          <EquipmentPopup
            visible={EquipmentPopupVisible}
            onClose={() => setEquipmentPopupVisible(false)}
            onDataSelect={handleDataSelectEquipment}
            formData={data}
          />
           {/* item */}
          <ItemPopup
            visible={ItemPopupVisible}
            onClose={() => setItemPopupVisible(false)}
            onDataSelect={handleDataSelectItem}
            formData={data}
          />
           {/* Service package */}
           <ServicePackagePopup
            visible={ServicePackagePopupVisible}
            onClose={() => setServicePackagePopupVisible(false)}
            onDataSelect={handleDataSelectSRPK}
            formData={data}
          />
           {/* Service Contract */}
           <ServiceContractPopup
              visible={ServiceContractPopupVisible}
              onClose={() => setServiceContractPopupVisible(false)}
              onDataSelect={handleDataSelectContract}
              formData={data}
            />
          {/* Bill */}
          <AddressBookPopup
             visible={BillPopupVisible}
             onClose={() => setBillPopupVisible(false)}
             onDataSelect={handleDataSelectBill}
             formData={data}
             addressType={addressTypecust}
             setFormData={setFormData}
           />
           {/* Send To */}
           <AddressBookPopup
             visible={SendToPopupVisible}
             onClose={() => setSendToPopupVisible(false)}
             onDataSelect={handleDataSelectSendTo}
             formData={data}
             addressType={addressTypecust}
             setFormData={setFormData}
           />
</Dialog>
  );
}

