import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  ehUkNo:"",
  cp_CP_NM:"",
  cp_BI_NG:"",
  cp_AC_ON:"",
  cp_LO_CN:"",
  cp_LN_GE:"",
  cp_DM_PH:"",
  cp_ST_DT:"",
  cp_EN_DT:"",
  cp_BU_GT:"",
  cp_IM_ON:"",
  cp_CL_CK:"",
  cp_CN_CT:"",
  cp_RT_IV:"",
  cp_CV_RT:"",
  cp_CT_PL:"",
};

const initialFilters = {
  cp_CP_NM: { value: "", operation: "" },
  cp_BI_NG: { value: "", operation: "" },
  cp_AC_ON: { value: "", operation: "" },
  cp_LO_CN: { value: "", operation: "" },
  cp_LN_GE: { value: "", operation: "" },
  cp_DM_PH: { value: "", operation: "" },
  cp_ST_DT: { value: "", operation: "" },
  cp_EN_DT: { value: "", operation: "" },
  cp_BU_GT: { value: "", operation: "" },
  cp_IM_ON: { value: "", operation: "" },
  cp_CL_CK: { value: "", operation: "" },
  cp_CN_CT: { value: "", operation: "" },
};

const CompensationHistoryMain = () => {
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
// drodpdowns
  const [cp_BI_NG, setcp_BI_NG ] = useState("");
  const [cp_AC_ON, setcp_AC_ON ] = useState("");
  const [cp_LO_CN, setcp_LO_CN ] = useState("");
  const [cp_LN_GE, setcp_LN_GE ] = useState("");
  const [cp_DM_PH, setcp_DM_PH ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setcp_BI_NG(null);
    setcp_AC_ON(null);
    setcp_LO_CN(null);
    setcp_LN_GE(null);
    setcp_DM_PH(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [cp_CO_NO , setcp_CO_NO] = useState("");
 
  const handleDefault = async () => {
    setcp_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/CompensationHistory/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
 
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_CP_NM"] ? filters["cp_CP_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_CP_NM",
                        e.target.value,
                        filters["cp_CP_NM"]
                          ? filters["cp_CP_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_BI_NG"] ? filters["cp_BI_NG"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_BI_NG",
                        e.target.value,
                        filters["cp_BI_NG"] ? filters["cp_BI_NG"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_AC_ON"] ? filters["cp_AC_ON"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_AC_ON",
                        e.target.value,
                        filters["cp_AC_ON"]
                          ? filters["cp_AC_ON"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_LO_CN"] ? filters["cp_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_LO_CN",
                        e.target.value,
                        filters["cp_LO_CN"]
                          ? filters["cp_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_LN_GE"] ? filters["cp_LN_GE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_LN_GE",
                        e.target.value,
                        filters["cp_LN_GE"]
                          ? filters["cp_LN_GE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_DM_PH"] ? filters["cp_DM_PH"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_DM_PH",
                        e.target.value,
                        filters["cp_DM_PH"]
                          ? filters["cp_DM_PH"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_ST_DT"] ? filters["cp_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_ST_DT",
                        e.target.value,
                        filters["cp_ST_DT"] ? filters["cp_ST_DT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_EN_DT"] ? filters["cp_EN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_EN_DT",
                        e.target.value,
                        filters["cp_EN_DT"]
                          ? filters["cp_EN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_BU_GT"] ? filters["cp_BU_GT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_BU_GT",
                        e.target.value,
                        filters["cp_BU_GT"]
                          ? filters["cp_BU_GT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_IM_ON"] ? filters["cp_IM_ON"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_IM_ON",
                        e.target.value,
                        filters["cp_IM_ON"]
                          ? filters["cp_IM_ON"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_CL_CK"] ? filters["cp_CL_CK"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_CL_CK",
                        e.target.value,
                        filters["cp_CL_CK"]
                          ? filters["cp_CL_CK"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_CN_CT"] ? filters["cp_CN_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_CN_CT",
                        e.target.value,
                        filters["cp_CN_CT"]
                          ? filters["cp_CN_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Employee No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Designation</th>
                <th style={tableHeaderStyle}>Effective Date</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Pay Frequency</th>
                <th style={tableHeaderStyle}>Pay Class</th>
                <th style={tableHeaderStyle}>Total Salary</th>
                <th style={tableHeaderStyle}>Basic</th>
                <th style={tableHeaderStyle}>HRA</th>
                <th style={tableHeaderStyle}>LTA</th>
                <th style={tableHeaderStyle}>Phone</th>
                <th style={tableHeaderStyle}>Travel Allowance</th>
                <th style={tableHeaderStyle}>Fuel Allowance</th>
                <th style={tableHeaderStyle}>Other Allowance</th>
                <th style={tableHeaderStyle}>COLA</th>
                <th style={tableHeaderStyle}>Last Review</th>
                <th style={tableHeaderStyle}>Next Review</th>
                <th style={tableHeaderStyle}>WPS Basic</th>
                <th style={tableHeaderStyle}>WPS HRA</th>
                <th style={tableHeaderStyle}>WPS Travel Allowance</th>
                <th style={tableHeaderStyle}>WPS Other Allowance</th>
                <th style={tableHeaderStyle}>Transaction No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.ehUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.ehUkNo}
                        checked={selectedRows.includes(rowData.ehUkNo)}
                        onChange={() => handleRowSelect(rowData.ehUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.eh_EM_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{rowData.eh_EM_DS}</td>
                    <td style={tableCellStyle}>{rowData.ef_date}</td>
                    <td style={tableCellStyle}>{rowData.eh_CC_NM}</td> 
                    <td style={tableCellStyle}>{rowData.pay_fre}</td>
                    <td style={tableCellStyle}>{rowData.psy_cls}</td>
                    <td style={tableCellStyle}>{rowData.total_salart}</td>
                    <td style={tableCellStyle}>{rowData.basic}</td>
                    <td style={tableCellStyle}>{rowData.hra}</td> 
                    <td style={tableCellStyle}>{rowData.lta}</td>
                    <td style={tableCellStyle}>{rowData.phn}</td>    
                    <td style={tableCellStyle}>{rowData.tvl_alw}</td>
                    <td style={tableCellStyle}>{rowData.fl_alw}</td>
                    <td style={tableCellStyle}>{rowData.otj_alw}</td>
                    <td style={tableCellStyle}>{rowData.cola}</td>
                    <td style={tableCellStyle}>{rowData.eh_LT_RE}</td> 
                    <td style={tableCellStyle}>{rowData.eh_NX_RE}</td>
                    <td style={tableCellStyle}>{rowData.eh_BA_SC2}</td>
                    <td style={tableCellStyle}>{rowData.whra}</td>
                    <td style={tableCellStyle}>{rowData.wtalw}</td>
                    <td style={tableCellStyle}>{rowData.dew}</td> 
                    <td style={tableCellStyle}>{rowData.cp_DM_PH}</td>
                    <td style={tableCellStyle}>{rowData.cp_ST_DT}</td>
                    <td style={tableCellStyle}>{rowData.cp_EN_DT}</td>           
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
    </div>
  );
};

export default CompensationHistoryMain;
