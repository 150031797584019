import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import axios from "axios";
import { saveAs } from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import "../../../components/ListStyle/ListStyle.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { SetFilter } from "ag-grid-enterprise";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import CostCentrePopup from "../Popups/CostCentrePopup";
import ItemPopup from "../Popups/ItemPopup";
import PropertyInfoPopup from "../Popups/PropertyInformationPopup";

const ReportMain = () => {
  const toast = useRef(null);
  const [reportoptions, setreportoptions] = useState([]);
  const [typeoptions, settypeoptions] = useState([]);
  const [report, setreport] = useState("");
  const [type, settype] = useState("");
  const [ReportFields, setReportFields] = useState([]);
  const [selectedReportId, setSelectedReportId] = useState("");
  const [selectedType, setSelectedType] = useState(""); 
  const [filter, setfilter] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const Reportlist = `${domain}/api/ErpReports/GetAllReport`;
  const reportlistDropdownOptions = async () => {
    try {
      const response = await axios.get(Reportlist, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.reportName,
        value: item.reportId,
      }));
      setreportoptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const ReportType = `${domain}/api/ErpReports/GetAllReportTypes`;
  const reportTypeDropdownOptions = async () => {
    try {
      const response = await axios.get(ReportType, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((itemtype) => ({
        label: itemtype.description,
        value: itemtype.type,
      }));
      settypeoptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const Reportselect = `${domain}/api/ErpReports/GetByReport`;
  const fetchReportFields = async (item) => {
    const reportid = {
      "reportId": item,
    };
    try {
      const response = await axios.post(Reportselect, reportid, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const fields = response.data.map(field => {
        if (field.defaultValue === "${COMPANY}") {
          return {
            ...field,
            value: `${company}`,
          };
        } else {
          return field;
        }
      });
      setReportFields(fields);
      setfilter(response.filterValue)
      const linkPath = String(window.location);
      const l_array = linkPath.split("=");
      const LinkId = l_array[1];
      if (LinkId) {
        var ff = document.getElementById("report");
        ff.classList.add("p-disabled");
      }
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const Reportgeneration = `${domain}/api/ErpReports/Get`;
  const fetchReportgenerate = async () => {
const inpuList = [];
    const searchEles  = document.getElementById("reportInputField").getElementsByClassName("field col");
    for(var i = 0; i < searchEles.length; i++) {
      if(searchEles[i].childNodes[1].children.length === 0) {
        const label = searchEles[i].childNodes[0].htmlFor;
        console.log(label);
        const val = searchEles[i].childNodes[1].value;
        console.log(val);
        const input = {
          "attributeName": label,
          "value":val
        }
        inpuList.push(input);
      } else {
        const val = searchEles[i].childNodes[1].children[0].value;
        const label = searchEles[i].childNodes[0].htmlFor;
        const input = {
          "attributeName": label,
          "value":val
        }
        inpuList.push(input);
        console.log(label);
        console.log(val);
      }
      
    }

    const requestData = {
      reportId: selectedReportId,
      type: selectedType,
      erpreportAttributes: inpuList
      // ReportFields.map(field => ({
      //   attributeName: field.attributeName,
      //   value: field.value,
      //   defaultValue: field.defaultValue,
      // }))
    };
    try {
      const response = await axios.post(Reportgeneration , requestData ,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
        responseType: "arraybuffer",
      });
      const redata = response.data;
      console.log(redata);
      let fileExtension = "";
      switch (selectedType) {
        case "PDF":
          fileExtension = ".pdf";
          break;
        case "XLS":
          fileExtension = ".xlsx";
          break;
        case "TEXT":
          fileExtension = ".txt";
          break; 
        case "XLSX":
          fileExtension = ".xlsx";
          break;
        default:
          fileExtension = ".pdf"; 
      } 
      
      const fileName = `${selectedReportId}${fileExtension}`;
      saveAs(new Blob([response.data]), fileName);
      fetchReportFields(selectedReportId);


      // const data = new Blob([response.data], { type: 'application/pdf' });
      // displayPDF(data);
      // fetchReportFields(selectedReportId);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  // const displayPDF = (blob) => {
  //   const url = URL.createObjectURL(blob);
  
  //   // Create iframe
  //   const iframe = document.createElement('iframe');
  //   iframe.src = url;
  
  //   // Make sure the iframe is visible and correctly positioned
  //   // Append iframe to document body
  //   document.body.appendChild(iframe);
  // };
  
  

  
  useEffect(() => {
    reportlistDropdownOptions();
    reportTypeDropdownOptions();
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    if(LinkId){
      var ff = document.getElementById("report"); 
      ff.classList.add("p-disabled");
      setreport(LinkId);
      const e = {
        "value" : LinkId
      }
      handlereportChange(e);
    }
  }, []);

  const handlereportChange = (e) => {
    setreport(e.value);
    setSelectedReportId(e.value);
    fetchReportFields(e.value);
  };
  const handletypeChange = (e) => {
  settype(e.value);
  setSelectedType(e.value);
  };
const buttonStyles = {
  backgroundColor: "#ff0010a3",
  color: "white",
  lineHeight: "10%",
};
const [state, setState] = useState();
const onchangeDropDownFormData = (field, value) => {
  setState(value);
}


function ReportAttributeFileds () {
const searchEles  = document.getElementById("reportInputField").getElementsByClassName("field col");
for(var i = 0; i < searchEles.length; i++) {
  if(searchEles[i].childNodes[1].children.length === 0) {
    const label = searchEles[i].childNodes[0].htmlFor;
   //const label = searchEles[0].__reactProps$91jagirpvfb.children[0].props.children
    console.log(label);
    const vv = searchEles[i].childNodes[1].value;
    console.log(vv);
  } else {
    const v = searchEles[i].childNodes[1].children[0].value;
    const label = searchEles[i].childNodes[0].htmlFor;
    console.log(label);
    console.log(v);
  }
  
}
}

  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "red !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Select
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={fetchReportgenerate}
        //  onClick={ReportAttributeFileds}
      ></Button>
    </React.Fragment>
  );

  const ReportDropdown = (
    <div className="formgrid grid" style={{display:"flex"}}>
      <div className="field col" style={{ display: "flex" }}>
        <label htmlFor="Report" className="font-bold" style={{marginRight:"3%",marginTop:"2%"}}>
         Report
        </label>
        <Dropdown
          style={{width:"500px"}}
          id="report"
          name="report"
          value={report}
          options={reportoptions}
          onChange={handlereportChange}
          placeholder="Select Report"
        />
      </div>
      <div className="field col" style={{ display: "flex" }}>
        <label htmlFor="Type" className="font-bold" style={{marginRight:"5%",marginTop:"5%"}}>
        Type
        </label>
        <Dropdown
          id="type"
          name="type"
          value={type}
          options={typeoptions}
          onChange={handletypeChange}
          placeholder="Select Type"
        />
      </div>
      </div>
  );
// advance search
const searchstyle ={
  display:"flex",
  width:"55%"
}
// address book
const [addressType, setaddressType] = useState();
const [elmId, setelmId] = useState();
const [PopupVisible, setPopupVisible] =useState(false);
const openAddressPopup = (extraFilter,id) => {
  setaddressType(extraFilter);
  setelmId(id);
  setPopupVisible(true);
};

const handleDataSelect = (data,Id) => {
  document.getElementById(Id).value = data.abAdNo;
  setPopupVisible(false);
};
// AccountNoPopup
const [AelmId, setAelmId] = useState();
const [AccountPopupVisible, setAccountPopupVisible] =useState(false);
const handleDataSelect1 = (data,Id) => {
  document.getElementById(Id).value = data.account;
  setAccountPopupVisible(false);
};

const openAccountPopup = (id) => {
  setAelmId(id);
  setAccountPopupVisible(true);
};
// cost centre popup
const [CostCenterelmId, setCostCenterelmId] = useState();
const [CostCentrePopupVisible, setCostCentrePopupVisible] =useState(false);
const handleDataCostCentre = (content,Id) => {
  document.getElementById(Id).value = content.cc_CC_DS;
  setCostCentrePopupVisible(false);
};

const openCostCentrePopup = (id) => {
  setCostCenterelmId(id);
  setCostCentrePopupVisible(true);
};
// Item popup
const [ItemlmId, setItemlmId] = useState();
const [ItemPopupVisible, setItemPopupVisible] =useState(false);
const handleDataItem = (Result,Id) => {
  document.getElementById(Id).value = Result.im_IT_NM;
  setItemPopupVisible(false);
};

const openItemPopup = (id) => {
  setItemlmId(id);
  setItemPopupVisible(true);
};
// ProprtyPopup
const [PropertylmId, setPropertylmId] = useState();
const [ProprtyPopupVisible, setProprtyPopupVisible] =useState(false);
const handleDataProperty = (content,Id) => {
  document.getElementById(Id).value = content.pi_PR_NO;
  setProprtyPopupVisible(false);
};

const openPropertyPopup = (id) => {
  setPropertylmId(id);
  setProprtyPopupVisible(true);
};

  const renderField = (field) => {
    switch (field.attributeType) {
      case 1: // Text Input
        return (
          <InputText
            id={field.attributeName}
            name={field.attributeName}
            value={field.value}
            onChange={(e) => {
              const newValue = e.target.value;
              setReportFields(prevFields => 
                prevFields.map(prevField => 
                  prevField.attributeName === field.attributeName ? { ...prevField, value: newValue } : prevField
                ))
            }}
          />
        );
      case 7: // Dropdown
        var optionTemplate = [];
        if(field.attributeType === 7){
          optionTemplate = field.options.map((v) => (
            <option value={v.cl_CL_CD}>{v.cl_CL_DS}</option>
          ));
        }
        return (
      //   <Dropdown
      //     id={field.id}
      //     name={field.attributeName}
      //     value={state}
      //     options={optionTemplate}
      //     onChange={(e) => onchangeDropDownFormData(field, e.value.props.value)}
      //     placeholder="Select"
      // />
      <select id={field.attributeName}
      name={field.attributeName}
      value={state}
      onChange={(e) => onchangeDropDownFormData(field, e.value)}
      >
      {optionTemplate}
    </select>
        );
      case 3: // Calendar
        //   value={moment(field.value, "DD/MM/YYYY").toDate()}
        return (
          <Calendar
            id={field.attributeName}
            name={field.attributeName}
            value={field.value ? moment(field.value, "DD/MM/YYYY").toDate() : null}
            onChange={(e) => {
              const selectedDate = moment(e.target.value).format("DD/MM/YYYY"); // Format the selected date
              setReportFields(prevFields => 
                prevFields.map(prevField => 
                  prevField.attributeName === field.attributeName ? { ...prevField, value: selectedDate } : prevField
                )
              );
            }}
            showIcon
            dateFormat="dd/mm/yy"
          />
        );
      // case 11: // Cost Center dropdown
      //   return (
      //     <Dropdown
      //       id={field.attributeName}
      //       name={field.attributeName}
      //       value={field.value}
      //       options={field.options}
      //       onChange={(e) => {
      //         const newValue = e.target.value;
      //         setReportFields(prevFields => 
      //           prevFields.map(prevField => 
      //             prevField.attributeName === field.attributeName ? { ...prevField, value: newValue } : prevField
      //           )
      //         );
      //       }}
      //       placeholder="Select"
      //     />       
      //   );
      case 31: // Time
              return (
                <Calendar
                  id={field.attributeName}
                  name={field.attributeName}
                  value={moment(field.value, "HH:mm:ss").toDate()}
                  onChange={(e) => {
                    // Handle time change if needed
                  }}
                  showIcon
                  timeOnly
                  hourFormat="24"
                />
              );
      case 46: // Checkbox
        return (
          <Checkbox
            inputId={field.attributeName}
            name={field.attributeName}
            checked={field.checked}
            onChange={(e) => {
              // Handle checkbox change if needed
            }}
          />
        );
      case 40: // search
        switch (field.filterValue) {
          case "AddressBookPopup" :
            return (
              <div style={searchstyle}>
              <InputText
                id={field.id}
                name={field.attributeName}
                value={field.value}
              />
            <FontAwesomeIcon 
              style={{marginTop:"6%"}}
              icon={faSearch} 
              onClick={() => openAddressPopup(field.extraFilter,field.id)}
            />
              </div>
            );
          case "AccountNoPopup" :
            return (
              <div style={searchstyle}>
              <InputText
                id={field.id}
                name={field.attributeName}
                value={field.value}
              />
            <FontAwesomeIcon 
              style={{marginTop:"6%"}}
              icon={faSearch} 
              onClick={() => openAccountPopup(field.id)}
            />
              </div>
            );
          case "CostCentrePopup" :
              return (
              <div style={searchstyle}>
                <InputText
                  id={field.id}
                  name={field.attributeName}
                  value={field.value}
                />
                <FontAwesomeIcon 
                  style={{marginTop:"6%"}}
                  icon={faSearch} 
                  onClick={() => openCostCentrePopup(field.id)}
                />
              </div>
              );
          case "ItemPopup" :
                return (
                <div style={searchstyle}>
                  <InputText
                    id={field.id}
                    name={field.attributeName}
                    value={field.value}
                  />
                  <FontAwesomeIcon 
                    style={{marginTop:"6%"}}
                    icon={faSearch} 
                    onClick={() => openItemPopup(field.id)}
                  />
                </div>
                );
          case "PopertyInfoPopup" :
            return (
            <div style={searchstyle}>
              <InputText
                id={field.id}
                name={field.attributeName}
                value={field.value}
              />
              <FontAwesomeIcon 
                style={{marginTop:"6%"}}
                icon={faSearch} 
                onClick={() => openPropertyPopup(field.id)}
              />
            </div>
            );
        }
      default:
        return null;
    }
  };
  
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
        />
        <div style={{ marginTop: "2%" }}>
          <Toolbar className="p-mb-4" left={ReportDropdown}/>
        </div>
        <div style={{ maxWidth: "100%",marginTop: "2%" }}>
          <div className="formgrid grid" id ="reportInputField"  style={{ width:"80%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
            {ReportFields.map((field) => (
              <div className="field col"  key={field.id}>
                <label htmlFor={field.attributeName} className="font-bold" style={{marginRight:"2%"}}>
                  {field.displayName}
                </label>
                {renderField(field)}
              </div>
            ))}
          </div>
        </div>
      </div>
      <AddressBookPopup
        visible={PopupVisible}
        onClose={() => setPopupVisible(false)}
        onDataSelect={handleDataSelect}
        addressType={addressType}
        elmId={elmId}
      />
       <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        AelmId={AelmId}
      />
      <CostCentrePopup
        visible={CostCentrePopupVisible}
        onClose={() => setCostCentrePopupVisible(false)}
        onDataSelect={handleDataCostCentre}
        CostCenterelmId={CostCenterelmId}
      />
       <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataItem}
        ItemlmId={ItemlmId}
      />
      <PropertyInfoPopup
        visible={ProprtyPopupVisible}
        onClose={() => setProprtyPopupVisible(false)}
        onDataSelect={handleDataProperty}
        PropertylmId={PropertylmId}
      />
    </div>
  );
}

export default ReportMain;
