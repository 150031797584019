import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import MultiUnitInformationDialog from "./MultiUnitInformationDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Form } from "devextreme-react/tree-list";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  ui_BL_NO: "",
  bu_DESC: "",
  uiUkNo:"",
  ui_FL_NO:"",
  ui_UN_NO:"",
  ui_UN_DS:"",
  ui_UN_ST:"",
};

const initialFilters = {
  ui_BL_NO: { value: "", operation: "" },
  bu_DESC: { value: "", operation: "" },
  ui_FL_NO: { value: "", operation: "" },
  ui_UN_NO: { value: "", operation: "" },
  ui_UN_DS: { value: "", operation: "" },
  ui_UN_ST: { value: "", operation: "" },
};

const MultiUnitInformationMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [ui_FL_NO, setui_FL_NO] = useState("");
  const [ui_UN_NO, setui_UN_NO] = useState("");
  const [ui_UN_ST, setui_UN_ST] = useState("");
  const [ui_UN_TY, setui_UN_TY] = useState("");
  const [ui_UN_US, setui_UN_US] = useState("");
  const [ui_AR_TY, setui_AR_TY] = useState("");
  const [ui_PR_NO, setui_PR_NO] = useState("");
  const [ui_BL_NO, setui_BL_NO] = useState("");
  const [ui_TR_UM, setui_TR_UM] = useState("");
  const [MultiUnitInformationtable, setMultiUnitInformationtable] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setMultiUnitInformationtable([{}]);
    setFormData(initialValue);
    setui_FL_NO(null);
    setui_UN_NO(null);
    setui_UN_ST(null);
    setui_UN_TY(null);
    setui_UN_US(null);
    setui_AR_TY(null);
    setui_BL_NO(null);
    setui_PR_NO(null);
    setui_TR_UM(null);
    setOpen(true);
  };

  const handleClose = () => {
    setMultiUnitInformationtable([{}]);
    setFormData(initialValue);
    setui_FL_NO(null);
    setui_UN_NO(null);
    setui_UN_ST(null);
    setui_UN_TY(null);
    setui_UN_US(null);
    setui_AR_TY(null);
    setui_BL_NO(null);
    setui_PR_NO(null);
    setui_TR_UM(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const addRow = () => {
    setMultiUnitInformationtable([...MultiUnitInformationtable, {}]);
  };

  const deleteRow = () => {
    if (MultiUnitInformationtable.length > 0) {
      const updatedData = [...MultiUnitInformationtable];
      updatedData.pop();
      setMultiUnitInformationtable(updatedData);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/MultiUnitInformation/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const MultiUnitInformationData = response.data;
          delete MultiUnitInformationData.uiUkNo;
          for (let i = 0; i < MultiUnitInformationData.unitInformations.length; i++) {
            delete MultiUnitInformationData.unitInformations[i].uiUkNo;
          }
          setMultiUnitInformationtable(MultiUnitInformationData.unitInformations);
          setFormData(MultiUnitInformationData.unitInformations[0]);
          setui_PR_NO(MultiUnitInformationData.ui_PR_NO);
          setui_BL_NO(MultiUnitInformationData.ui_BL_NO);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const [ui_CO_NO, setui_CO_NO] = useState("");
  const handleDefault = async () => {
    setui_CO_NO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };





  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/MultiUnitInformation/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };

const onchangeDropDownFormData1 = (name, value) => {
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setui_UN_NO(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setui_UN_ST(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setui_UN_TY(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setui_UN_US(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setui_AR_TY(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setui_PR_NO(value);
  };
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setui_BL_NO(value);
  };
  const onchangeDropDownFormData9 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setui_BL_NO(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/MultiUnitInformation/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const MultiUnitInformationData = response.data;
        setMultiUnitInformationtable(MultiUnitInformationData.unitInformations);
        setFormData(MultiUnitInformationData.unitInformations[0]);
        setui_PR_NO(response.data.ui_PR_NO);
        setui_BL_NO(response.data.ui_BL_NO);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {  
    const table = document.getElementById("MultiUnitInformationtable");
    const totalRowCount = table.tBodies[0].rows.length;
    const UK_NO = FormData.uiUkNo;
    const BL_NO = FormData.ui_BL_NO;
    const PR_NO =FormData.ui_PR_NO;
    const CO_NO = document.getElementById("ui_CO_NO");
    const unitInformations = [];
    for (let i = 0; i < totalRowCount; i++) {
      const UK_NO = document.getElementById("uiUkNo_" + i);
      const FL_NO = table.tBodies[0].rows[i].cells[1].children[0].children[1].children[0].value;
      const UN_NO = document.getElementById("ui_UN_NO_"+i);
      const UN_TY = table.tBodies[0].rows[i].cells[3].children[0].children[1].children[0].value;
      const UN_US = table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value;
      const UN_ST = table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value;
      const AR_TY = table.tBodies[0].rows[i].cells[6].children[0].children[1].children[0].value;
      const AL_DS = document.getElementById("ui_AL_DS_"+i);
      const TR_QT = document.getElementById("ui_TR_QT_"+i);
      const TR_UM = table.tBodies[0].rows[i].cells[9].children[0].children[1].children[0].value;
      const ST_DT = document.getElementsByName("ui_ST_DT_" + i)[0];
      const EN_DT = document.getElementsByName("ui_EN_DT_" + i)[0];
          
      const Details = {
        uiUkNo: UK_NO.value,
        ui_FL_NO: FL_NO,
        ui_UN_NO: UN_NO.value,
        ui_UN_TY: UN_TY,
        ui_UN_US: UN_US,
        ui_UN_ST: UN_ST,
        ui_AR_TY: AR_TY,
        ui_AL_DS: AL_DS.value,
        ui_TR_QT: TR_QT.value,
        ui_TR_UM: TR_UM,
        ui_ST_DT: ST_DT.value?moment(ST_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        ui_EN_DT: EN_DT.value?moment(EN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,      
      };
      unitInformations.push(Details);
    }
    const requestBody = {
      unitInformations,
      uiUkNo: UK_NO,
      ui_PR_NO: PR_NO,
      ui_BL_NO: BL_NO,
      ui_CO_NO: CO_NO.value,
    };

      if (FormData.uiUkNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/MultiUnitInformation/Update`;
        if (confirm) {
          
          axios
            .put(update, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        axios
          .post(`${domain}/api/MultiUnitInformation/Add`, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    } ;

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" , backgroundColor: "#1ed01e", border: "none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ui_BL_NO"] ? filters["ui_BL_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ui_BL_NO",
                        e.target.value,
                        filters["ui_BL_NO"]
                          ? filters["ui_BL_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bu_DESC"] ? filters["bu_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bu_DESC",
                        e.target.value,
                        filters["bu_DESC"]
                          ? filters["bu_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ui_FL_NO"] ? filters["ui_FL_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ui_FL_NO",
                        e.target.value,
                        filters["ui_FL_NO"]
                          ? filters["ui_FL_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ui_UN_NO"] ? filters["ui_UN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ui_UN_NO",
                        e.target.value,
                        filters["ui_UN_NO"]
                          ? filters["ui_UN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["ui_UN_ST"] ? filters["ui_UN_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ui_UN_ST",
                        e.target.value,
                        filters["ui_UN_ST"]
                          ? filters["ui_UN_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Buildinig</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Floor</th>
                <th style={tableHeaderStyle}>Unit</th>
                <th style={tableHeaderStyle}>Unit Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.uiUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.uiUkNo}
                        checked={selectedRows.includes(rowData.uiUkNo)}
                        onChange={() => handleRowSelect(rowData.uiUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ui_BL_NO}</td>
                    <td style={tableCellStyle}>{rowData.bu_DESC}</td>
                    <td style={tableCellStyle}>{rowData.ui_FL_NO}</td>
                    <td style={tableCellStyle}>{rowData.ui_UN_NO}</td>
                    <td style={tableCellStyle}>{rowData.ui_UN_ST}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
 {/* pagination */}
 <Pagination
    currentPage={currentPage}
    totalPages={totalPages}
    setCurrentPage={setCurrentPage}
  />

      </div>

      <MultiUnitInformationDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        onchangeDropDownFormData8={onchangeDropDownFormData8}
        onchangeDropDownFormData9={onchangeDropDownFormData9}
        ui_FL_NO={ui_FL_NO}
        ui_UN_NO={ui_UN_NO}
        ui_UN_ST={ui_UN_ST}
        ui_UN_TY={ui_UN_TY}
        ui_UN_US={ui_UN_US}
        ui_AR_TY={ui_AR_TY}
        ui_PR_NO={ui_PR_NO}
        ui_BL_NO={ui_BL_NO}
        ui_TR_UM={ui_TR_UM}
        ui_CO_NO={ui_CO_NO}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        MultiUnitInformationtable={MultiUnitInformationtable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
  
      />
    </div>
  );
};

export default MultiUnitInformationMain;
