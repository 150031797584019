import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
const initialValue = {
  gbUkNo:"",
  gb_FY_YR:"",
};

const initialFilters = {
};

const AnnualClosureMain = () => {
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [filters, setFilters] = useState(initialFilters);

const [years, setyears] = useState(null);
const [gb_FY_YROptions, setgb_FY_YROptions] = useState("");
const token = localStorage.getItem("token");
const company = localStorage.getItem("company");
const domain = localStorage.getItem("domain");
// Dropdown
const url = `${domain}/api/ClassCode/All`;
const parameter1 = "YE/AR";
const finalUrl = `${url}/${parameter1}`;
useEffect(() => {
  fetchDropdownOptions();
  
}, [currentPage, pageSize, filters]);

const fetchDropdownOptions = async () => {
  try {
    const response = await axios.get(finalUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setgb_FY_YROptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
 
  const handleClearButton=()=>{
    setyears("");
    setFilters("");
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
    }
  };
  
  useEffect(() => {
  }, [currentPage, pageSize, filters]);


  const onchangeDropDown = (e) => {
    setyears(e.value); 
    setFormData({ ...FormData, gb_FY_YR: e.value }); 
    setIsDropdownSelected(!!e.value);
  };
  
  const handleAnnaulclosure = async () => {
    if (!isDropdownSelected) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Select a record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    const Url = `${domain}/api/AnnualClosure/AnnualClosure`;
    const request = {
      "gb_FY_YR": FormData.gb_FY_YR, 
    };
    try {
      const resp = await axios.post(Url, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": company,
        },
      });
      handleResponse(resp.data);
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };
  
  const [isDropdownSelected, setIsDropdownSelected] = useState(false);
  const AdvanceSearch = (
    <div className="formgrid grid">
          <div className="field col"   >
        <label htmlFor="years" className="font-bold">
       Financial Year
        </label>
        <Dropdown
          style={{ width: "90%" }}
          id="years"
          name="years"
          value={years} 
          options={gb_FY_YROptions}
          onChange={onchangeDropDown} 
          placeholder="Select Financial Year"
        />
      </div>
      <div className="field col">
        <Button
          style={{
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "#03a9f485",
            border: "none", 
            marginTop:"13%"
          }}
            type="button"
            label="Run Annual Closure"
            icon="pi pi-check"
            onClick={handleAnnaulclosure}
        >
        </Button> 
      </div>  
    </div>
          

  );
const rightClear = (
  <React.Fragment>
  <Tooltip  target=".clear-button" position="bottom" mouseTrack>
  <div>Clear Filter</div>
  </Tooltip>
  
  <Button
  style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
  icon="pi pi-times"
  className="mr-2 clear-button"
  link
  onClick={handleClearButton}
  />
  </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />        
      </div>
      </div>
      
    </div>
  );
};

export default AnnualClosureMain;
