import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ManagerPopup from "views/admin/Popups/ManagerPopup";
import EmployeePopup from "views/admin/Popups/EmployeePopup";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function EmployeeProfileDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  FormData,
  setFormData,
  ep_GP_PR,
  setep_GP_PR,
  ep_PO_NM,
  setep_PO_NM,
  ep_AP_RQ,
  setep_AP_RQ,
  ep_RM_ME,
  setep_RM_ME,
  ep_RM_CR,
  setep_RM_CR,
  ep_CO_NO,
}) {
  const {
    epUkNo,
    cp_CP_NM,
    cp_ST_DT,
    cp_EN_DT,
    cp_BU_GT,
    cp_IM_ON,
    cp_CL_CK,
    cp_CN_CT,
    ep_EM_NO,
    ep_MN_NO,
   

  } = data;
  const {borderbox,inboxstyle} = ButtonStyles();
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedemEMNO1, setSelectedemEMNO1] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);
  const [ManagerPopupVisible, setManagerPopupVisible] = useState(false);
  const openEmployeePopup = () => {
    setEmployeePopupVisible(true);
  }
  const openManagerPopup = () => {
    setManagerPopupVisible(true);
  }
  // dropdowns
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();

  const [ep_GP_PROptions, setep_GP_PROptions] = useState([]);
  const [ep_PO_NMOptions, setep_PO_NMOptions] = useState([]);
  const [ep_AP_RQOptions, setep_AP_RQOptions] = useState([]);
  const [ep_RM_MEOptions, setep_RM_MEOptions] = useState([]);
  const [ep_RM_CROptions, setep_RM_CROptions] = useState([]);
// BIDDING type
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_GP_PR(value);
  };
// Acq
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_PO_NM(value);
  };
// loc
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_AP_RQ(value);
  };
// lang
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_RM_ME(value);
  };
// demogr
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_RM_CR(value);
  };

  useEffect(() => {
    fetchDropdownBT();
    fetchDropdownAQ();
    fetchDropdownLN();
    fetchDropdownLG();
    fetchDropdownDM();

  }, []);

  const fetchDropdownBT = async () => {
    const Options = await fetchClassCodeDS("EX/GP");
    setep_GP_PROptions(Options);
  };

  const fetchDropdownAQ = async () => {
    const Options = await fetchClassCodeDS("EX/PO");
    setep_PO_NMOptions(Options);
  };

  const fetchDropdownLN = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setep_AP_RQOptions(Options);
  };

  const fetchDropdownLG = async () => {
    const Options = await fetchClassCodeDS("EX/RM");
    setep_RM_MEOptions(Options);
  };

  const fetchDropdownDM = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setep_RM_CROptions(Options);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("ep_EM_NO", data.abAdNo);
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect1 = async (data) => {
    setSelectedemEMNO1(data.ab_AD_NM);
    onchangeFormData("ep_MN_NO", data.abAdNo);
    setManagerPopupVisible(false);
    setShowSearchIcon(false);
  };

const isUpdateMode = Boolean(epUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={epUkNo ? "Revise EmployeeProfile" : "Add EmployeeProfile"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
            <div
                style={{
                  marginTop: "2%",
                  border: "1px solid #e8e8e8",
                  padding: "16px",
                  borderRadius: "15px",
                  backgroundColor: "aliceblue",
                  overflow: "auto",
                }}
              >
                <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ep_EM_NO" className="font-bold">
              Employee <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="employee_NAME"
                      name="employee_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedemEMNO}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openEmployeePopup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="ep_EM_NO"
                      name="ep_EM_NO"
                      value={ep_EM_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ep_MN_NO" className="font-bold">
              Manager <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="manager_NAME"
                      name="manager_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedemEMNO1}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openManagerPopup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="ep_MN_NO"
                      name="ep_MN_NO"
                      value={ep_MN_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ep_CO_NO" className="font-bold">
              Company
              </label>
              <InputText disabled
                id="ep_CO_NO"
                name="ep_CO_NO"
                value={ep_CO_NO}
                // onChange={(e) => onchangedata(e)}
                readOnly
              />
          </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ep_GP_PR" className="font-bold">
                     Group
                    </label>
                    <Dropdown
                    name="ep_GP_PR"
                    value={ep_GP_PR}
                    options={ep_GP_PROptions}
                    onChange={(e) => onchangeDropDownFormData1("ep_GP_PR", e.value)}
                    placeholder="Select Group"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ep_PO_NM" className="font-bold">
                     Policy Name
                    </label>
                    <Dropdown
                    name="ep_PO_NM"
                    value={ep_PO_NM}
                    options={ep_PO_NMOptions}
                    onChange={(e) => onchangeDropDownFormData2("ep_PO_NM", e.value)}
                    placeholder="Select Policy Name"
                  />
                  </div>

                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ep_AP_RQ" className="font-bold">
                     Approval Required
                    </label>
                    <Dropdown
                    name="ep_AP_RQ"
                    value={ep_AP_RQ}
                    options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                      ]}
                    onChange={(e) => onchangeDropDownFormData3("ep_AP_RQ", e.value)}
                    placeholder="Select Approval Required"
                  />
                  </div>
                  
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ep_RM_ME" className="font-bold">
                    Reim Method
                    </label>
                    <Dropdown
                    name="ep_RM_ME"
                    value={ep_RM_ME}
                    options={ep_RM_MEOptions}
                    onChange={(e) => onchangeDropDownFormData4("ep_RM_ME", e.value)}
                    placeholder="Select Reim Method"
                  />
                  </div>

                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ep_RM_CR" className="font-bold">
                     Reim Currency
                    </label>
                    <Dropdown
                    name="ep_RM_CR"
                    value={ep_RM_CR}
                    options={ep_RM_CROptions}
                    onChange={(e) => onchangeDropDownFormData5("ep_RM_CR", e.value)}
                    placeholder="Select Currency"
                  />
                  </div>
                </div>
            </div>
      </div>
      <EmployeePopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
        <ManagerPopup
        visible={ManagerPopupVisible}
        onClose={() => setManagerPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
    </Dialog>
  );
}
