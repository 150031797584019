import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import '../../../components/ListStyle/ListStyle.css';
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import { TabView, TabPanel } from "primereact/tabview";
import '../../common/TabStyles.css';
export default function CustomerEnquiryDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  shUkNo,
  sh_OR_NO,
  sh_OR_TY,
  sh_OR_CO,
  sh_CO_CT,
  setsh_CO_CT,
  sh_AS_TO,
  setsh_AS_TO,
  sh_TR_CR,
  setsh_TR_CR,
  sh_BS_CR,
  sd_TR_UM,
  setsd_TR_UM,
  sd_ST_TY,
  setsd_ST_TY,
  sh_EX_RT,
  setsh_EX_RT,
  selectedCustomer,
  setselectedCustomer,
  selectedShipto,
  setselectedShipto,
  selectedSales,
  setselectedSales,
  CustomerEnquiryTableData,
  setCustomerEnquiryTableData,
  selectedSupplier,
  setselectedSupplier,
  setsh_CU_NO,
  sh_CU_NO,
  sh_SH_TO,
  setsh_SH_TO,
  onchangeDropDownFormData1,
  is_LO_CN,
  sd_CO_CT,
  setsd_CO_CT,
  sh_SA_PE,
  setsh_SA_PE,
  sd_ST_CD,
  setsd_ST_CD,
  OnChangeCustomerTableData
}) {
  const {
    sd_UK_NO,
    sh_OR_DT,sh_RQ_DT,sh_RF_NO,sh_CO_PE,sh_PR_RF
  } = data;
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,
    multitableheader,multirowstyle,savestyle,cancelstyle} = ButtonStyles();
  const [activeTab, setActiveTab] = useState(0);
  // DATE DEFAULT
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [selectedRqDate, setSelectedRqDate] = useState(moment().toDate());

  const handleORDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "sh_OR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };

  const handleRQDateChange = (e) => {
    setSelectedRqDate(e.value);
    onchangedata({ target: { name: "sh_RQ_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter,fetchCostCenterP } = Costcenter();
  const [sh_CO_CTOptions, setsh_CO_CTOptions] = useState([]);
  const [sh_AS_TOOptions, setsh_AS_TOOptions] = useState([]);
  const [sh_TR_CROptions, setsh_TR_CROptions] = useState([]);
  const [sd_TR_UMOptions, setsd_TR_UMOptions] = useState([]);
  const [sd_ST_TYOptions, setsd_ST_TYOptions] = useState([]);

// Assigned To
const onchangeDropDownFormData2 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setsh_AS_TO(value);
};
const onchangeDropDownFormData3 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setsh_TR_CR(value);
};
// um
const onchangeDropDownFormData4 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setsd_TR_UM(value);
};
// type
const onchangeDropDownFormData5 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setsd_ST_TY(value);
};

  useEffect(() => {
    fetchCostCentre();
    fetchAssignedto();
    fetchCurrency();
    fetchDropdownUM();
    fetchDropdownType();
  }, []);

  const fetchCostCentre = async () => {
    const Options = await fetchCostCenter();
    setsh_CO_CTOptions(Options);
  };
  const fetchAssignedto = async () => {
    const Options = await fetchClassCodeDS("AB/6");
    setsh_AS_TOOptions(Options);
  };
  const fetchCurrency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setsh_TR_CROptions(Options);
  };
  const fetchDropdownUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setsd_TR_UMOptions(Options);
  };
  const fetchDropdownType = async () => {
    const Options = await fetchClassCodeCD("IN/TY");
    setsd_ST_TYOptions(Options);
  };
  const addRow = () => {
    setCustomerEnquiryTableData([...CustomerEnquiryTableData, {}]);
  };  
  const deleteRow = () => {
    if (CustomerEnquiryTableData.length > 0) {
      const updatedData = [...CustomerEnquiryTableData];
      updatedData.pop();
      setCustomerEnquiryTableData(updatedData);
    }
  };
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
  const [SalesPopupVisible, setSalesPopupVisible] = useState(false);
  const [ItemPopupVisible, setItemPopupVisible] = useState(false);
  const [addressTypeCust, setaddressTypeCust] = useState("C");
  const [addressTypeShip, setaddressTypeShip] = useState("C");
  const [addressTypeSales,setaaddressTypeSales] = useState("E");

  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const openShiptoPopup = () => {
    setShipToPopupVisible(true);
  };
  const openSalesPopup = () => {
    setSalesPopupVisible(true);
  };
  const openItemPopup = (e) => {
    setItemPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleSelectCustomer =async (dataAb) => {
  setselectedCustomer(dataAb.ab_AD_NM);
  setsh_CU_NO(dataAb.abAdNo);

  setselectedShipto(dataAb.ab_AD_NM);
  setsh_SH_TO(dataAb.abAdNo);
  const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
  const requestData={
    "cu_CU_NO": dataAb.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCuMt = response.data.Result;
    if (dataCuMt) {
      onchangeDropDownFormData3("sh_TR_CR", dataCuMt.cu_CR_CD);
      const exData={
        "cr_TO_CR": document.getElementById("sh_BS_CR").value, //base curr
        "cr_FR_CR": dataCuMt.cu_CR_CD, //currency
        "cr_EF_DT": data.sh_OR_DT
      }
      exchangeRate (exData);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setCustomerPopupVisible(false);
  setShowSearchIcon(false);
  };
  function exchangeRate (exData){
    axios.post(`${domain}/api/ExChangeRate/Rate`, exData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      const dataExRt = resp.data.Result;
      if (dataExRt) {
        document.getElementById("sh_EX_RT").value = dataExRt.cr_CO_MR;
        setsh_EX_RT(dataExRt.cr_CO_MR);
      }

    })
    .catch((error) => {
      console.error("Error saving data:", error);
    });
  };
  const handleSelectShipTo =async (data) => {
    setselectedShipto(data.ab_AD_NM);
    document.getElementById ("sh_CU_NO").value =  data.abAdNo;
    setShipToPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectSales =async (data) => {
    setselectedSales(data.ab_AD_NM);
    document.getElementById ("sh_SA_PE").value =  data.abAdNo;
    setSalesPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectItem =async (Result) => {
    document.getElementById(`sd_IT_NM_${SelectedRowIndex}`).value = Result.im_IT_NM;
    document.getElementById(`im_IT_DS_${SelectedRowIndex}`).value = Result.im_IT_DS;
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
        "im_IT_NM":Result.im_IT_NM
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataItMt = response.data.Result;
      if (dataItMt ) {
        onchangeDropDownFormData4(`sd_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
        onchangeDropDownFormData5(`sd_ST_TY_${SelectedRowIndex}`, dataItMt.im_ST_TY);
        const stockdata = {
          "is_IT_NM" : dataItMt.im_IT_NM,
          "is_LO_CN" : document.getElementById("is_LO_CN").value,
          "is_CO_CT" : document.getElementById(`sd_CO_CT_${SelectedRowIndex}`).value
        }
        StockAvai(stockdata);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };
  const StockAvai = async (stockdata)=>{
  const Url = `${domain}/api/StockAvailability/GetAvailableByName`
    try {
      const response =await axios.post(Url, stockdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataStCt = response.data.Result[0];
      if (dataStCt && dataStCt.is_AV_QT) {
        document.getElementById(`sd_TR_ST_${SelectedRowIndex}`).value = dataStCt.is_AV_QT;
        } else {
          console.log(dataStCt);
          console.error("Invalid API response:", dataStCt);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };
  const isUpdateMode = Boolean(sd_UK_NO);
  const isDisabled = sd_ST_CD !== "100";

  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{shUkNo ? "Revise Customer Enquiry" : "Add Customer Enquiry"}</h5>
      <div style={{width:'60rem',display:'flex', justifyContent:'flex-start'}}>
      {activeTab === 0 && (
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        )}
      {activeTab === 0 && (
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      )}
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "110rem",margin:"0px",position:"fixed",top:"5rem",left:"22rem", }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      modal
      className="p-fluid"
      header={dialogHeader}
    >
      <div style={borderbox}>
     <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
      <TabPanel header="General" className="tab-header">
        <div style={inboxstyle}>
        <div className="formgrid grid">
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
          <label htmlFor="shUkNo" className="font-bold">
            UKNO
          </label>
          <InputText disabled={isDisabled}
            id="shUkNo"
            name="shUkNo"
            value={shUkNo}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_OR_NO" className="font-bold">
            Order No
          </label>
          <InputText disabled
            id="sh_OR_NO"
            name="sh_OR_NO"
            value={sh_OR_NO}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_OR_TY" className="font-bold">
            Type
          </label>
          <InputText disabled
            id="sh_OR_TY"
            name="sh_OR_TY"
            value={sh_OR_TY}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_OR_CO" className="font-bold">
            Company
          </label>
          <InputText disabled
            id="sh_OR_CO"
            name="sh_OR_CO"
            value={sh_OR_CO}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_CO_CT" className="font-bold">
            Cost Centre
          </label>
          <Dropdown disabled={isDisabled}
            id="sh_CO_CT"
            name="sh_CO_CT"
            value={sh_CO_CT}
            options={sh_CO_CTOptions}
            onChange={(e) => onchangeDropDownFormData1("sh_CO_CT", e.value)}
            placeholder="Select Cost Centre"
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_OR_DT" className="font-bold">
            Order Date
          </label>    
          <Calendar disabled={isDisabled}
            id="sh_OR_DT"
            name="sh_OR_DT"
            value={moment(sh_OR_DT, "YYYYMMDD").toDate()}
            onChange={handleORDateChange}   
            dateFormat="dd/mm/yy"
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_RQ_DT" className="font-bold">
            Requested Date
          </label>
          <Calendar disabled={isDisabled}
            id="sh_RQ_DT"
            name="sh_RQ_DT"
            value={moment(sh_RQ_DT, "YYYYMMDD").toDate()}
            onChange={handleRQDateChange}
            dateFormat="dd/mm/yy"
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_CU_NO" className="font-bold">
            Customer <span style={{ color: 'red' }}>*</span>
          </label>
          <div style={{ display: "flex" }}>
            <InputText disabled={isDisabled}
              id="selectedCustomer"
              name="selectedCustomer"
                  onChange={(e) => onchangedata(e)}
              onFocus={() => setShowSearchIcon(true)}
              value={selectedCustomer}
            />
            {showSearchIcon && (
            <i
              className="pi pi-search"
              style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "4%",
                cursor: "pointer",
              }}
              onClick={openCustomerPopup}
            ></i>
            )}
          </div>
          <div style={{display:"none"}}>
            <InputText  disabled={isDisabled}
              id="sh_CU_NO"
              name="sh_CU_NO"
              value={sh_CU_NO}
                  onChange={(e) => onchangedata(e)}
            />
          </div>
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_SH_TO" className="font-bold">
            Ship To <span style={{ color: 'red' }}>*</span>
          </label>
          <div style={{ display: "flex" }}>
            <InputText disabled={isDisabled}
              id="selectedShipto"
              name="selectedShipto"
                  onChange={(e) => onchangedata(e)}
              onFocus={() => setShowSearchIcon(true)}
              value={selectedShipto}
            />
            {showSearchIcon && (
            <i
              className="pi pi-search"
              style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "4%",
                cursor: "pointer",
              }}
              onClick={openShiptoPopup}
            ></i>
            )}
          </div>
          <div style={{display:"none"}}>
            <InputText  disabled={isDisabled}
              id="sh_SH_TO"
              name="sh_SH_TO"
              value={sh_SH_TO}
                  onChange={(e) => onchangedata(e)}
            />
          </div>
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_SA_PE" className="font-bold">
            Sales Person <span style={{ color: 'red' }}>*</span>
          </label>
          <div style={{ display: "flex" }}>
            <InputText disabled={isDisabled}
              id="selectedSales"
              name="selectedSales"
                  onChange={(e) => onchangedata(e)}
              onFocus={() => setShowSearchIcon(true)}
              value={selectedSales}
            />
            {showSearchIcon && (
            <i
              className="pi pi-search"
              style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "4%",
                cursor: "pointer",
              }}
              onClick={openSalesPopup}
            ></i>
            )}
          </div>
          <div style={{display:"none"}}>
            <InputText  disabled={isDisabled}
              id="sh_SA_PE"
              name="sh_SA_PE"
              defaultValue={sh_SA_PE}
              onChange={(e) => onchangedata(e)}
            />
          </div>
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_TR_CR" className="font-bold">
            Currency
          </label>
          <Dropdown disabled={isDisabled}
            id="sh_TR_CR"
            name="sh_TR_CR"
            value={sh_TR_CR}
            options={sh_TR_CROptions}
            onChange={(e) => onchangeDropDownFormData3("sh_TR_CR", e.value)}
            placeholder="Select Currency"
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_EX_RT" className="font-bold">
            Exchange Rate
          </label>
          <InputText disabled
            id="sh_EX_RT"
            name="sh_EX_RT"
            value={sh_EX_RT}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_BS_CR" className="font-bold">
            Base Currency
          </label>
          <InputText disabled 
          id="sh_BS_CR"
          name="sh_BS_CR" 
          value={sh_BS_CR} 
          />
        </div>
        <div style={{display:"none"}}>
          <label htmlFor="is_LO_CN" className="font-bold">
            location
          </label>
          <InputText disabled 
          id="is_LO_CN"
          name="is_LO_CN" 
          value={is_LO_CN} 
          />
        </div>
        </div>
        </div>  
        <div  style={tablestyle} >
      <div className="table-buttons" style={tablebutton} >
        {(
        <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
        )}
          {(
        <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
        )}
      </div>
      <div  style={multitablestyle}>
      <table className="custom-table" id="CustomerEnquirytable" style={multirowstyle}>
        <thead>
          <tr>
            <th style={{ display: "none" }}>UK NO</th>
            <th>Line No</th>
            <th>Item Number</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>UM</th>
            <th>Stock</th>
            <th>Type</th>
            <th>Status</th>
            <th>Cancelled Qty</th>
            <th>Remarks</th>
            <th style={{display:"none"}}>Cost Center</th>
          </tr>
        </thead>
        <tbody>
          {CustomerEnquiryTableData.map((row, index) => (
            <tr key={index}>
              <td style={{ display: "none" }}>
                <InputText style={{width:"80px"}}
                  id={`sd_UK_NO_${index}`}
                  name={`sd_UK_NO_${index}`}
                  value={row.sd_UK_NO}
                />
              </td>
              <td>
                <InputText disabled
                  style={{ width: "90px" }}
                  id={`sdLnNo_${index}`}
                  name={`sdLnNo_${index}`}
                  value={index + 1}
                />
              </td>
              <td >
              <div style={{ display: "flex" }}>
              <InputText disabled={isDisabled}
                style={{ width: "110px" }}
                id={`sd_IT_NM_${index}`}
                name={`sd_IT_NM_${index}`}
                onChange={(e) => OnChangeCustomerTableData('sd_IT_NM',e.value,index)}
                onFocus={() => setShowSearchIcon(true)}
                defaultValue={row.sd_IT_NM ? row.sd_IT_NM: row[`${"sd_IT_NM"}_${index}`]}
              />
                {showSearchIcon && (
                  <i className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "15%",
                      cursor: "pointer",
                    }}
                    onClick={(e) => openItemPopup(index)}
                  ></i>
                )}
                </div>
              </td>
              <td>
              <InputText disabled
                  style={{ width: "200px" }}
                  id={`im_IT_DS_${index}`}
                  name={`im_IT_DS_${index}`}
                  defaultValue={row.im_IT_DS ? row.im_IT_DS : row[`${"im_IT_DS"}_${index}`]}
                  onChange={(e) => OnChangeCustomerTableData('im_IT_DS',e.value,index)}
                />
              </td>
                <td>
                  <InputText disabled={isDisabled}
                    style={{ width: '7rem' }}
                    id={`sd_OR_QT_${index}`}
                    name={`sd_OR_QT_${index}`}
                    // defaultValue={row.sd_OR_QT}
                    defaultValue={row.sd_OR_QT ? row.sd_OR_QT : row[`sd_OR_QT_${index}`]}
                    onChange={(e) => OnChangeCustomerTableData('sd_OR_QT',e.value,index)}
                  />
                </td>
                <td>
                  <Dropdown disabled={isDisabled}
                    id={`sd_TR_UM_${index}`}
                    name={`sd_TR_UM_${index}`}
                    value={ data[`sd_TR_UM_${index}`] ? data[`sd_TR_UM_${index}`]: row.sd_TR_UM}
                    options={sd_TR_UMOptions}
                    onChange={(e) =>onchangeDropDownFormData4(`sd_TR_UM_${index}`, e.value)}
                    placeholder="Select UM"
                  />
                </td>
              <td >
                <InputText style={{width:'8rem'}} disabled
                  id={`sd_TR_ST_${index}`}
                  name={`sd_TR_ST_${index}`}
                  value={row.sd_TR_ST ? row.sd_TR_ST : row[`sd_TR_ST_${index}`]}
                  onChange={(e) => onchangedata(e)}
                />
              </td>
              <td > 
                <Dropdown disabled={isDisabled}
                    id={`sd_ST_TY_${index}`}
                    name={`sd_ST_TY_${index}`}
                    value={ data[`sd_ST_TY_${index}`] ? data[`sd_ST_TY_${index}`]: row.sd_ST_TY}
                    options={sd_ST_TYOptions}
                    onChange={(e) =>onchangeDropDownFormData5(`sd_ST_TY_${index}`, e.value)}
                    placeholder="Select Type"
                  />
                </td>
                <td>
                    <InputText disabled
                      style={{width:'8rem'}}
                      id={`sd_ST_CD_${index}`}
                      name={`sd_ST_CD_${index}`}
                      value={ row.sd_ST_CD ? row.sd_ST_CD : sd_ST_CD}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <InputText disabled={!row.sd_UK_NO}
                      style={{width:"8rem"}}
                      id={`sd_CN_QT_${index}`}
                      name={`sd_CN_QT_${index}`}
                      defaultValue={row.sd_CN_QT ? row.sd_CN_QT : row[`sd_CN_QT_${index}`]}
                      onChange={(e) => OnChangeCustomerTableData('sd_CN_QT',e.value,index)}
                    />
                  </td>
                  <td>
                    <InputText disabled={isDisabled}
                      style={{width:"20rem"}}
                      id={`sd_RE_MK_${index}`}
                      name={`sd_RE_MK_${index}`}
                      defaultValue={row.sd_RE_MK ? row.sd_RE_MK : row[`sd_RE_MK_${index}`]}
                      onChange={(e) => OnChangeCustomerTableData('sd_RE_MK',e.value,index)}
                    />
                  </td>
                  <td style={{display:"none"}}>
                    <InputText disabled={isDisabled}
                      id={`sd_CO_CT_${index}`}
                      name={`sd_CO_CT_${index}`}
                      value={sd_CO_CT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
      </TabPanel>
      <TabPanel  header="Additional Details" className="tab-header">
      <div  style={inboxstyle}>
         <div className="formgrid grid">
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_RF_NO" className="font-bold">
              Enquiry Reference
          </label>
          <InputText disabled={isDisabled}
            id="sh_RF_NO"
            name="sh_RF_NO"
            value={sh_RF_NO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_PR_RF" className="font-bold">
              Project Reference
          </label>
          <InputText disabled={isDisabled}
            id="sh_PR_RF" 
            name="sh_PR_RF"
            value={sh_PR_RF}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_CO_PE" className="font-bold">
              Contact Person
          </label>
          <InputText disabled={isDisabled}
            id="sh_CO_PE"
            name="sh_CO_PE"
            value={sh_CO_PE}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_AS_TO" className="font-bold">
              Assigned To
            </label>
            <Dropdown disabled={isDisabled}
              id="sh_AS_TO"
              name="sh_AS_TO"
              value={sh_AS_TO}
              options={sh_AS_TOOptions}
              onChange={(e) => onchangeDropDownFormData2("sh_AS_TO", e.value)}
              placeholder="Select Assigned To"
            />
          </div>
      </div>
    </div>
      </TabPanel>
    </TabView>
        </div>
      {/* customer */}
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleSelectCustomer}
        formData={data}
        addressType={addressTypeCust}
        setFormData={setFormData}
      />
      {/* Shipto */}
      <AddressBookPopup
        visible={ShipToPopupVisible}
        onClose={() => setShipToPopupVisible(false)}
        onDataSelect={handleSelectShipTo}
        formData={data}
        addressType={addressTypeShip}
        setFormData={setFormData}
      />
      {/* Sales person */}
      <AddressBookPopup
        visible={SalesPopupVisible}
        onClose={() => setSalesPopupVisible(false)}
        onDataSelect={handleSelectSales}
        formData={data}
        addressType={addressTypeSales}
        setFormData={setFormData}
      />
      {/* item */}
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleSelectItem}
        formData={data}
      />
    </Dialog>
  );
}
