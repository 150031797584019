import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css'
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import EmployeePopup from "views/admin/Popups/AddressBookPopup";
import ReportingPopup from "views/admin/Popups/AddressBookPopup";
import axios from "axios";
import './table.css';
import ButtonStyles from "views/common/ButtonStyles";
export default function EmployeeMasterDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  onchangeDropDownFormData9,
  onchangeDropDownFormData10,
  onchangeDropDownFormData12,
  onchangeDropDownFormData13,
  onchangeDropDownFormData14,
  onchangeDropDownFormData15,
  onchangeDropDownFormData16,
  onchangeDropDownFormData17,
  onchangeDropDownFormData18,
  onchangeDropDownFormData19,
  onchangeDropDownFormData20,
  onchangeDropDownFormData21,
  onchangeDropDownFormData22,
  em_CC_NM,
  em_EM_DS,
  em_DE_PT,
  em_GR_DE,
  em_PY_FR,
  em_GN_DR,
  em_MR_ST,
  em_PB_PE,
  em_CT_BR,
  em_NA_TY,
  em_RG_TY,
  em_EM_ST,
  em_PA_CT,
  em_PA_ST,
  em_PA_CY,
  em_CA_CT,
  em_CA_ST,
  em_CA_CY,
  em_OT_EL,
  em_CO_NM,
  selectedAbAdNM,
  setSelectedAbAdNM,
  selectedAbAdNN,
  setSelectedAbAdNN,
}) {
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle,
    plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle} = ButtonStyles();

  // dropdowns
  const [em_CO_NMOptions, setem_CO_NMOptions] = useState([]);
  const [em_CC_NMOptions, setem_CC_NMOptions] = useState([]);
  const [em_EM_DSOptions, setem_EM_DSOptions] = useState([]);
  const [em_DE_PTOptions, setem_DE_PTOptions] = useState([]);
  const [em_GR_DEOptions, setem_GR_DEOptions] = useState([]);
  const [em_PY_FROptions, setem_PY_FROptions] = useState([]);
  const [em_GN_DROptions, setem_GN_DROptions] = useState([]);
  const [em_MR_STOptions, setem_MR_STOptions] = useState([]);
  const [em_PB_PEOptions, setem_PB_PEOptions] = useState([]);
  const [em_CT_BROptions, setem_CT_BROptions] = useState([]);
  const [em_NA_TYOptions, setem_NA_TYOptions] = useState([]);
  const [em_RG_TYOptions, setem_RG_TYOptions] = useState([]);
  const [em_EM_STOptions, setem_EM_STOptions] = useState([]);
  // TAB-2-DROPDOWNS
  const [em_PA_CTOptions, setem_PA_CTOptions] = useState([]);
  const [em_PA_STOptions, setem_PA_STOptions] = useState([]);
  const [em_PA_CYOptions, setem_PA_CYOptions] = useState([]);
  const [em_CA_CTOptions, setem_CA_CTOptions] = useState([]);
  const [em_CA_STOptions, setem_CA_STOptions] = useState([]);
  const [em_CA_CYOptions, setem_CA_CYOptions] = useState([]);
  // TAB-4-DROPDOWNS
  const [em_OT_ELOptions, setem_OT_ELOptions] = useState([]);
  const [em_CO_TYOptions, setem_CO_TYOptions] = useState([]);
  const { 
    em_QA_FN,    em_UK_NO,    em_RP_TO,    em_RE_LV,    
    em_NO_NE,    em_EM_AG,    em_PA_PC,    em_CA_PC,
    em_HO_EL,    em_HR_TY,    em_AL_NO,    em_PA_L3,
    em_PA_P2,    em_CA_L3,    em_CA_P1,    em_CA_L4,
    em_PA_L4,    em_CA_P2,    em_PA_L2,    em_CA_L1,
    em_CA_L2,    em_PA_P1,    em_PA_L1,    em_AG_NM,
    em_PA_NO,    em_LI_PR,    em_CO_TY,    em_DR_ID,    
    em_LB_ID,    em_PL_IS,    em_AC_NM,    em_MI_NM,    
    em_BF_AC,    em_TK_EN,    em_PS_NO,    em_UI_NO,
    em_MI_PR,    em_EM_ID,    em_BA_NM,    em_WM_PR,
    em_LV_EN,    em_WM_NM,    em_SW_CD,    em_BA_BR,
    em_BE_FI,    em_VI_NO,    em_AC_NO,    em_BF_NM,
    em_IB_NO,    em_LI_NM,    em_SN_SR,    emEmNo,
  } = data;
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);
  const [ReportingPopupVisible, setReportingPopupVisible] = useState(false);
  const openEmployeePopup = () => {
    setEmployeePopupVisible(true);
  };
  const openReportingPopup = () => {
    setReportingPopupVisible(true);
  };
//  startsearchpopup
const [addressType, setaddressType] = useState("E");
const [showSearchIcon, setShowSearchIcon] = useState("");
const handleDataSelect = (data) => {
  setSelectedAbAdNM(data.ab_AD_NM);
  onchangeFormData("emEmNo", data.abAdNo);
  setEmployeePopupVisible(false);
  setShowSearchIcon(false);
};
const handleDataSelect1 = (data) => {
  setSelectedAbAdNN(data.ab_AD_NM);
  onchangeFormData("em_RP_TO", data.abAdNo);
  setReportingPopupVisible(false);
  setShowSearchIcon(false);
};
// endsearchpopup
const urlcost = `${domain}/api/CostCenter/DropDown`;
// const parameter1 = "CO/CT";
const finalUrl1 = `${urlcost}`;
const url = `${domain}/api/ClassCode/All`;
// DESIGNATION
const parameter2 = "DE/SG";
const finalUrl2 = `${url}/${parameter2}`;
// DEPARTMENT
const parameter3 = "EM/DP";
const finalUrl3 = `${url}/${parameter3}`;
// grade
const parameter4 = "EM/GR";
const finalUrl4 = `${url}/${parameter4}`;
// PAY FREQUENCY
const parameter5 = "EM/PF";
const finalUrl5 = `${url}/${parameter5}`;
// GENDER
const parameter6 = "EM/GN";
const finalUrl6 = `${url}/${parameter6}`;
// MARITAL STATUS
const parameter7 = "EM/MS";
const finalUrl7 = `${url}/${parameter7}`;
// probition 
const parameter8 = "PB/PE";
const finalUrl8 = `${url}/${parameter8}`;
// COUNTRY OF BIRTH
const parameter9 = "FO/CY";
const finalUrl9 = `${url}/${parameter9}`;
// NATIONALITY
const parameter10 = "EM/NA";
const finalUrl10 = `${url}/${parameter10}`;
// LEAVING TYPE
const parameter12 = "EM/LT";
const finalUrl12 = `${url}/${parameter12}`;
// status
const parameter13 = "AB/ST";
const finalUrl13 = `${url}/${parameter13}`;
// CITY 
const parameter14 = "FO/CI";
const finalUrl14 = `${url}/${parameter14}`;
// STATE
const parameter15 = "AD/ST";
const finalUrl15 = `${url}/${parameter15}`;
// COUNTRY
const parameter16 = "FO/CY";
const finalUrl16 = `${url}/${parameter16}`;
// CITY
const parameter17 = "FO/CI";
const finalUrl17 = `${url}/${parameter17}`;
// STATE
const parameter18 = "AD/ST";
const finalUrl18 = `${url}/${parameter18}`;
// COUNTRY
const parameter19 = "FO/CY";
const finalUrl19 = `${url}/${parameter19}`;
// OT eligible
const parameter20 = "ME/PL";
const finalUrl20 = `${url}/${parameter20}`;

// Company
const parameter21 = "CO/MT";
const finalUrl21 = `${url}/${parameter21}`;

// Contract Type
const parameter22 = "EM/CT";
const finalUrl22 = `${url}/${parameter22}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
    fetchDropdownOptions8();
    fetchDropdownOptions9();
    fetchDropdownOptions10();
    fetchDropdownOptions12();
    fetchDropdownOptions13();
    fetchDropdownOptions14();
    fetchDropdownOptions15();
    fetchDropdownOptions16();
    fetchDropdownOptions17();
    fetchDropdownOptions18();
    fetchDropdownOptions19();
    fetchDropdownOptions20();
    fetchDropdownOptions21();
    fetchDropdownOptions22();
  }, []);

  // 1st fetchdropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setem_CC_NMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 2rd fetchdropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_EM_DSOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  //3rd fetchdropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_DE_PTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  //4th fetchdropdown
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_GR_DEOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 5th fetchdropdown
  const fetchDropdownOptions5 = async () => {
    try {
      const response = await axios.get(finalUrl5, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_PY_FROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 6th fetchdropdown
  const fetchDropdownOptions6 = async () => {
    try {
      const response = await axios.get(finalUrl6, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_GN_DROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 7th fetchdropdown
  const fetchDropdownOptions7 = async () => {
    try {
      const response = await axios.get(finalUrl7, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_MR_STOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 8th fetchdropdown
  const fetchDropdownOptions8 = async () => {
    try {
      const response = await axios.get(finalUrl8, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_PB_PEOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 9th fetchdropdown
  const fetchDropdownOptions9 = async () => {
    try {
      const response = await axios.get(finalUrl9, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_CT_BROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 10th fetchdropdown
  const fetchDropdownOptions10 = async () => {
    try {
      const response = await axios.get(finalUrl10, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_NA_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 12th fetchdropdown
  const fetchDropdownOptions12 = async () => {
    try {
      const response = await axios.get(finalUrl12, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_RG_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 13th fetchdropdown
  const fetchDropdownOptions13 = async () => {
    try {
      const response = await axios.get(finalUrl13, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_EM_STOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 14th fetchdropdown
  const fetchDropdownOptions14 = async () => {
    try {
      const response = await axios.get(finalUrl14, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_PA_CTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 15th fetchdropdown
  const fetchDropdownOptions15 = async () => {
    try {
      const response = await axios.get(finalUrl15, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_PA_STOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 16th fetchdropdown
  const fetchDropdownOptions16 = async () => {
    try {
      const response = await axios.get(finalUrl16, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_PA_CYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 17th fetchdropdown
  const fetchDropdownOptions17 = async () => {
    try {
      const response = await axios.get(finalUrl17, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_CA_CTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 18th fetchdropdown
  const fetchDropdownOptions18 = async () => {
    try {
      const response = await axios.get(finalUrl18, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_CA_STOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 19th fetchdropdown
  const fetchDropdownOptions19 = async () => {
    try {
      const response = await axios.get(finalUrl19, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_CA_CYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 20th fetchdropdown
  const fetchDropdownOptions20 = async () => {
    try {
      const response = await axios.get(finalUrl20, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_OT_ELOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 21th fetchdropdown
  const fetchDropdownOptions21 = async () => {
    try {
      const response = await axios.get(finalUrl21, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_CO_NMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 21th fetchdropdown
  const fetchDropdownOptions22 = async () => {
    try {
      const response = await axios.get(finalUrl22, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_CO_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// completedfetchdropdowns
// agefunction
const AGE = async (formattedDate) => {
  const Url = `${domain}/api/EmployeeMaster/GetAgeFromDOB`;
  const requestData = {
    "dob": formattedDate
  };

  try {
    const response = await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataAGE = response.data.Result;
    if (dataAGE) {
      onchangeFormData("em_EM_AG", dataAGE.year);
    } else {
      console.error("Invalid API response:", dataAGE);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const handleDateChange = (e) => {
  const formattedDate = moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "";
  onchangedata({ target: { name: "em_DT_BR", value: formattedDate } });
  // Call the AGE function after updating the date
  AGE(formattedDate);
};
  const [activeTab, setActiveTab] = useState(0);
  const isUpdateMode = Boolean(em_UK_NO);
    // DIALOG HEADER
    const dialogHeader = (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <h5>{ em_UK_NO ? "Edit Employee Master" : "Add Employee Master" }</h5>
        <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        {activeTab === 0 && (
          <Button
            style={savestyle}
            icon="pi pi-check"
            onClick={handleFormSubmit} 
            tooltip={isUpdateMode ? "Update" : "Submit"}
          />
        )}
         {activeTab === 0 && (
          <Button
            style={cancelstyle}
            icon="pi pi-times"
            onClick={handleClose}
            tooltip="Cancel"
          />
         )}
        </div>
      </div>
    );
  return (
        <Dialog id="dialog-style"
          visible={open}
          onHide={handleClose}
          style={Dialogstyle}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header={dialogHeader}
          modal
          className="p-fluid"
        >
      <div style={borderbox} >
        {/* tabs start */}
        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="General">
            {/* tab1 general */}
            <div style={inboxstyle}>
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="emEmNo" className="font-bold">
                    Employee<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={selectedAbAdNM}
                    />
                     {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openEmployeePopup}
                    ></i>
                     )}
                  </div>
                 
                  <div  style={{ display: "none" }}>
                    <InputText
                      id="emEmNo"
                      name="emEmNo"
                      value={emEmNo}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CO_NM" className="font-bold">
                    Company
                  </label>
                  <Dropdown
                    name="em_CO_NM"
                    value={em_CO_NM}
                    options={em_CO_NMOptions}
                    onChange={(e) => onchangeDropDownFormData21("em_CO_NM", e.value)}
                    placeholder="Select Company"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CC_NM" className="font-bold">
                    Cost Centre
                  </label>
                  <Dropdown
                    name="em_CC_NM"
                    value={em_CC_NM}
                    options={em_CC_NMOptions}
                    onChange={(e) => onchangeDropDownFormData1("em_CC_NM", e.value)}
                    placeholder="Select Cost Center"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_EM_DS" className="font-bold">
                    Designation
                  </label>
                  <Dropdown
                    name="em_EM_DS"
                    value={em_EM_DS}
                    options={em_EM_DSOptions}
                    onChange={(e) => onchangeDropDownFormData2("em_EM_DS", e.value)}
                    placeholder="Select a Designation"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_DE_PT" className="font-bold">
                    Department
                  </label>
                  <Dropdown
                    name="em_DE_PT"
                    value={em_DE_PT}
                    options={em_DE_PTOptions}
                    onChange={(e) => onchangeDropDownFormData3("em_DE_PT", e.value)}
                    placeholder="Select a Department"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_GR_DE" className="font-bold">
                    Grade
                  </label>
                  <Dropdown
                    name="em_GR_DE"
                    value={em_GR_DE}
                    options={em_GR_DEOptions}
                    onChange={(e) => onchangeDropDownFormData4("em_GR_DE", e.value)}
                    placeholder="Select a Grade"

                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="em_RP_TO" className="font-bold">
              Reporting To<span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={selectedAbAdNN}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openReportingPopup}
                ></i>
                )}
              </div>
              <div  style={{ display: "none" }} >
                <InputText
                  id="em_RP_TO"
                  name="em_RP_TO"
                  value={em_RP_TO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
                </div> 
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PY_FR" className="font-bold">
                    Pay Frequency
                  </label>
                  <Dropdown
                    name="em_PY_FR"
                    value={em_PY_FR}
                    options={em_PY_FROptions}
                    onChange={(e) => onchangeDropDownFormData5("em_PY_FR", e.value)}
                    placeholder="Select a Pay Frequency"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_GR_EF" className="font-bold">
                    Effective From
                  </label>
                   <Calendar
                      id="em_GR_EF"
                      name="em_GR_EF"
                      value={moment(data.em_GR_EF, "YYYYMMDD").isValid() ? moment(data.em_GR_EF, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "em_GR_EF", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_QA_FN" className="font-bold">
                    Qualification
                  </label>
                  <InputText
                    id="em_QA_FN"
                    name="em_QA_FN"
                    value={em_QA_FN}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_GN_DR" className="font-bold">
                    Gender
                  </label>
                  <Dropdown
                    name="em_GN_DR"
                    value={em_GN_DR}
                    options={em_GN_DROptions}
                    onChange={(e) => onchangeDropDownFormData6("em_GN_DR", e.value)}
                    placeholder="Gender"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_AL_NO" className="font-bold">
                    Alternative No
                  </label>
                  <InputText
                    id="em_AL_NO"
                    name="em_AL_NO"
                    value={em_AL_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_ST_DT" className="font-bold">
                    Start Date
                  </label>
                   <Calendar
                      id="em_ST_DT"
                      name="em_ST_DT"
                      value={moment(data.em_ST_DT, "YYYYMMDD").isValid() ? moment(data.em_ST_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "em_ST_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2"  >
                  <label htmlFor="em_HR_DT" className="font-bold">
                    Hire Date
                  </label>
                  <Calendar
                      id="em_HR_DT"
                      name="em_HR_DT"
                      value={moment(data.em_HR_DT, "YYYYMMDD").isValid() ? moment(data.em_HR_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "em_HR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />                
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_DT_BR" className="font-bold">
                    Date of Birth
                  </label>
                  <Calendar
                    id="em_DT_BR"
                    name="em_DT_BR"
                    value={moment(data.em_DT_BR, "YYYYMMDD").isValid() ? moment(data.em_DT_BR, "YYYYMMDD").toDate() : null}
                    onChange={handleDateChange}
                    dateFormat="dd/mm/yy"
                  />
                </div>            
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_MR_ST" className="font-bold">
                    Marital Status
                  </label>
                  <Dropdown
                    name="em_MR_ST"
                    value={em_MR_ST}
                    options={em_MR_STOptions}
                    onChange={(e) => onchangeDropDownFormData7("em_MR_ST", e.value)}
                    placeholder="Marital Status"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PB_PE" className="font-bold">
                    Probation Period
                  </label>
                   <Dropdown
                    name="em_PB_PE"
                    value={em_PB_PE}
                    options={em_PB_PEOptions}
                    onChange={(e) => onchangeDropDownFormData8("em_PB_PE", e.value)}
                    placeholder="Select Probition"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_EM_AG" className="font-bold">
                    Age
                  </label>
                  <InputText
                    id="em_EM_AG"
                    name="em_EM_AG"
                    value={em_EM_AG}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CT_BR" className="font-bold">
                    Country of Birth
                  </label>
                  <Dropdown
                    name="em_CT_BR"
                    value={em_CT_BR}
                    options={em_CT_BROptions}
                    onChange={(e) => onchangeDropDownFormData9("em_CT_BR", e.value)}
                    placeholder="Select a Country"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_NA_TY" className="font-bold">
                    Nationality
                  </label>
                  <Dropdown
                    name="em_NA_TY"
                    value={em_NA_TY}
                    options={em_NA_TYOptions}
                    onChange={(e) => onchangeDropDownFormData10("em_NA_TY", e.value)}
                    placeholder="Select Nationality"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_NO_NE" className="font-bold">
                    Nominee
                  </label>
                  <InputText
                    id="em_NO_NE"
                    name="em_NO_NE"
                    value={em_NO_NE}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_TR_DT" className="font-bold">
                    End Date
                  </label>
                  <Calendar
                      id="em_TR_DT"
                      name="em_TR_DT"
                      value={moment(data.em_TR_DT, "YYYYMMDD").isValid() ? moment(data.em_TR_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "em_TR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      
                      dateFormat="dd/mm/yy"
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_RG_TY" className="font-bold">
                    Leaving Type
                  </label>
                  <Dropdown
                    name="em_RG_TY"
                    value={em_RG_TY}
                    options={em_RG_TYOptions}
                    onChange={(e) => onchangeDropDownFormData12("em_RG_TY", e.value)}
                    placeholder="Leave Type"

                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_EM_ST" className="font-bold">
                    Status
                  </label>
                  <Dropdown
                    name="em_EM_ST"
                    value={em_EM_ST}
                    options={em_EM_STOptions}
                    onChange={(e) => onchangeDropDownFormData13("em_EM_ST", e.value)}
                    placeholder="Select a Status"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_RE_LV" className="font-bold">
                    Reason
                  </label>
                  <InputText
                    id="em_RE_LV"
                    name="em_RE_LV"
                    value={em_RE_LV}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                </div>
            </div>
          </TabPanel>
          {/* tab2 */}
          <TabPanel header="Address">
            <div style={{ width: "25%", height: "40px",  marginTop: "2%" }}>
              <h3 style={{
                color: '#0c60fff0',
                fontSize: "25px",
                marginLeft: "3%",
              }}>
                Permanent Address</h3>
            </div>
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PA_L1" className="font-bold">
                    Address Line 1
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_PA_L1"
                      name="em_PA_L1"
                      value={em_PA_L1}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PA_L2" className="font-bold">
                    Address Line 2
                  </label>
                  <InputText
                    id="em_PA_L2"
                    name="em_PA_L2"
                    value={em_PA_L2}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PA_L3" className="font-bold">
                    Address Line 3
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_PA_L3"
                      name="em_PA_L3"
                      value={em_PA_L3}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2"  style={{ display: "none" }}>
                  <label htmlFor="em_PA_L4" className="font-bold">
                    Address Line 4
                  </label>
                  <InputText
                    id="em_PA_L4"
                    name="em_PA_L4"
                    value={em_PA_L4}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PA_CT" className="font-bold">
                    City
                  </label>
                  <Dropdown
                    name="em_PA_CT"
                    value={em_PA_CT}
                    options={em_PA_CTOptions}
                    onChange={(e) => onchangeDropDownFormData14("em_PA_CT", e.value)}
                    placeholder="Select a City"

                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PA_ST" className="font-bold">
                    State
                  </label>
                  <Dropdown
                    name="em_PA_ST"
                    value={em_PA_ST}
                    options={em_PA_STOptions}
                    onChange={(e) => onchangeDropDownFormData15("em_PA_ST", e.value)}
                    placeholder="Select a State"

                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PA_CY" className="font-bold">
                    Country
                  </label>

                  <Dropdown
                    name="em_PA_CY"
                    value={em_PA_CY}
                    options={em_PA_CYOptions}
                    onChange={(e) => onchangeDropDownFormData16("em_PA_CY", e.value)}
                    placeholder="Select a Country"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PA_PC" className="font-bold">
                    Postal Code
                  </label>
                  <InputText
                    id="em_PA_PC"
                    name="em_PA_PC"
                    value={em_PA_PC}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PA_P1" className="font-bold">
                    Phone 1
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_PA_P1"
                      name="em_PA_P1"
                      value={em_PA_P1}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PA_P2" className="font-bold">
                    Phone 2
                  </label>
                  <InputText
                    id="em_PA_P2"
                    name="em_PA_P2"
                    value={em_PA_P2}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                </div>
            </div>

            <div style={{ width: "20%", height: "40px", marginTop: "2%" }}>
              <h3 style={{
                color: '#0c60fff0',
                fontSize: "25px",
                marginLeft: "3%",
              }}>
                Current Address</h3>
            </div>
            {/* permanent address */}
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CA_L1" className="font-bold">
                    Address Line 1
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_CA_L1"
                      name="em_CA_L1"
                      value={em_CA_L1}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CA_L2" className="font-bold">
                    Address Line 2
                  </label>
                  <InputText
                    id="em_CA_L2"
                    name="em_CA_L2"
                    value={em_CA_L2}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CA_L3" className="font-bold">
                    Address Line 3
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_CA_L3"
                      name="em_CA_L3"
                      value={em_CA_L3}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
                  <label htmlFor="em_CA_L4" className="font-bold">
                    Address Line 4
                  </label>
                  <InputText
                    id="em_CA_L4"
                    name="em_CA_L4"
                    value={em_CA_L4}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CA_CT" className="font-bold">
                    City
                  </label>
                  <Dropdown
                    name="em_CA_CT"
                    value={em_CA_CT}
                    options={em_CA_CTOptions}
                    onChange={(e) => onchangeDropDownFormData17("em_CA_CT", e.value)}
                    placeholder="Select a City"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CA_ST" className="font-bold">
                    State
                  </label>
                  <Dropdown
                    name="em_CA_ST"
                    value={em_CA_ST}
                    options={em_CA_STOptions}
                    onChange={(e) => onchangeDropDownFormData18("em_CA_ST", e.value)}
                    placeholder="Select a State"

                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CA_CY" className="font-bold">
                    Country
                  </label>
                     <Dropdown
                    name="em_CA_CY"
                    value={em_CA_CY}
                    options={em_CA_CYOptions}
                    onChange={(e) => onchangeDropDownFormData19("em_CA_CY", e.value)}
                    placeholder="Select a Country"
                  />
                  {/* </div> */}
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CA_PC" className="font-bold">
                    Postal Code
                  </label>
                  <InputText
                    id="em_CA_PC"
                    name="em_CA_PC"
                    value={em_CA_PC}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CA_P1" className="font-bold">
                    Phone 1
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_CA_P1"
                      name="em_CA_P1"
                      value={em_CA_P1}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CA_P2" className="font-bold">
                    Phone 2
                  </label>
                  <InputText
                    id="em_CA_P2"
                    name="em_CA_P2"
                    value={em_CA_P2}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          {/* tab3 */}
          <TabPanel header="Passport & Visa">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PS_NO" className="font-bold">
                    Passport No
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_PS_NO"
                      name="em_PS_NO"
                      value={em_PS_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PE_EX" className="font-bold">
                    Expiry Date
                  </label>
                  <Calendar
                    id="em_PE_EX"
                    name="em_PE_EX"
                    value={moment(data.em_PE_EX, "YYYYMMDD").isValid() ? moment(data.em_PE_EX, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_PE_EX", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    
                    dateFormat="dd/mm/yy"
                  />    
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_VI_NO" className="font-bold">
                    Visa No
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_VI_NO"
                      name="em_VI_NO"
                      value={em_VI_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_VI_EX" className="font-bold">
                    Expiry Date
                  </label>
                  <Calendar
                    id="em_VI_EX"
                    name="em_VI_EX"
                    value={moment(data.em_VI_EX, "YYYYMMDD").isValid() ? moment(data.em_VI_EX, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_VI_EX", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  /> 
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_SN_SR" className="font-bold">
                    Sponsor
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_SN_SR"
                      name="em_SN_SR"
                      value={em_SN_SR}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_UI_NO" className="font-bold">
                    U.I.D No
                  </label>
                  <InputText
                    id="em_UI_NO"
                    name="em_UI_NO"
                    value={em_UI_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PL_IS" className="font-bold">
                    Place of Issuance
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_PL_IS"
                      name="em_PL_IS"
                      value={em_PL_IS}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_ED_DT" className="font-bold">
                    EID Issue Date
                  </label>
                  <Calendar
                    id="em_ED_DT"
                    name="em_ED_DT"
                    value={moment(data.em_ED_DT, "YYYYMMDD").isValid() ? moment(data.em_ED_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_ED_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_EM_ID" className="font-bold">
                    Emirates ID
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_EM_ID"
                      name="em_EM_ID"
                      value={em_EM_ID}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_EM_EX" className="font-bold">
                     Expiry Date
                  </label>
                  <Calendar
                    id="em_EM_EX"
                    name="em_EM_EX"
                    value={moment(data.em_EM_EX, "YYYYMMDD").isValid() ? moment(data.em_EM_EX, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_EM_EX", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_LB_ID" className="font-bold">
                    Labour Card
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_LB_ID"
                      name="em_LB_ID"
                      value={em_LB_ID}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_LB_EX" className="font-bold">
                    Expiry Date
                  </label>
                  <Calendar
                    id="em_LB_EX"
                    name="em_LB_EX"
                    value={moment(data.em_LB_EX, "YYYYMMDD").isValid() ? moment(data.em_LB_EX, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_LB_EX", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_DR_ID" className="font-bold">
                    Driving Licence No
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_DR_ID"
                      name="em_DR_ID"
                      value={em_DR_ID}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PA_NO" className="font-bold">
                    Personal No
                  </label>
                  <InputText
                    id="em_PA_NO"
                    name="em_PA_NO"
                    value={em_PA_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          {/* tab4 */}
          <TabPanel header="Recruitment & Benefits">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_AG_NM" className="font-bold">
                    Agent Name
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_AG_NM"
                      name="em_AG_NM"
                      value={em_AG_NM}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
                  <label htmlFor="em_HR_TY" className="font-bold">
                    Hire Date
                  </label>
                   <InputText
                      id="em_HR_TY"
                      name="em_HR_TY"
                      value={em_HR_TY}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CO_TY" className="font-bold">
                    Contract Type
                  </label>
                  <Dropdown
                    name="em_CO_TY"
                    value={em_CO_TY}
                    options={em_CO_TYOptions}
                    onChange={(e) => onchangeDropDownFormData22("em_CO_TY", e.value)}
                    placeholder="Select Contract type"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_OT_EL" className="font-bold">
                    OT Eligible
                  </label>
                  <Dropdown
                    id="em_OT_EL"
                    name="em_OT_EL"
                    value={em_OT_EL}
                    options={[
                      { label: "Yes", value: "Y" },
                      { label: "No", value: "N" },
                    ]}
                    onChange={(e) => onchangeDropDownFormData20("em_OT_EL", e.value)}
                    placeholder="Select OT Eligible"
                  />                
                </div>
              <div style={{display:"none"}}>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_HO_EL" className="font-bold">
                    Holiday OT Only
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_HO_EL"
                      name="em_HO_EL"
                      value={em_HO_EL}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
            </div>
            </div>
          </div>
            {/* MEDICAL */}
              <div style={{ fontSize:"25px",marginTop:"2%", }}>
              <h3 style={{ color: '#0c60fff0', }}>Medical Insurance</h3>
              </div>
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_MI_NM" className="font-bold">
                    Provider Name
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_MI_NM"
                      name="em_MI_NM"
                      value={em_MI_NM}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_MI_PR" className="font-bold">
                    Premium Amount
                  </label>
                  <InputText
                    id="em_MI_PR"
                    name="em_MI_PR"
                    value={em_MI_PR}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_MI_EF" className="font-bold">
                    Effective Date
                  </label>
                  <div >
                  <Calendar
                    id="em_MI_EF"
                    name="em_MI_EF"
                    value={moment(data.em_MI_EF, "YYYYMMDD").isValid() ? moment(data.em_MI_EF, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_MI_EF", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_MI_EX" className="font-bold">
                    Expired Date
                  </label>
                  <Calendar
                    id="em_MI_EX"
                    name="em_MI_EX"
                    value={moment(data.em_MI_EX, "YYYYMMDD").isValid() ? moment(data.em_MI_EX, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_MI_EX", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                </div>
            </div>
            {/* COMPENSATION   */}
            <div style={{ fontSize:"25px", marginTop:"2%", }}>
            <h3 style={{ color: '#0c60fff0',}}>Compensation</h3>
            </div>
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_WM_NM" className="font-bold">
                    Provider Name
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_WM_NM"
                      name="em_WM_NM"
                      value={em_WM_NM}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_WM_PR" className="font-bold">
                  Premium Amount
                  </label>
                  <InputText
                    id="em_WM_PR"
                    name="em_WM_PR"
                    value={em_WM_PR}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_EF_DT" className="font-bold">
                    Effective Date
                  </label>
                  <div >
                  <Calendar
                    id="em_EF_DT"
                    name="em_EF_DT"
                    value={moment(data.em_EF_DT, "YYYYMMDD").isValid() ? moment(data.em_EF_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_EF_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_WM_EX" className="font-bold">
                    Expired Date
                  </label>
                  <Calendar
                    id="em_WM_EX"
                    name="em_WM_EX"
                    value={moment(data.em_WM_EX, "YYYYMMDD").isValid() ? moment(data.em_WM_EX, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_WM_EX", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                </div>
            </div>
            {/* lIFE INSURANCE */}
            <div style={{fontSize:"25px",marginTop:"2%",}}>
            <h3 style={{ color: '#0c60fff0', }}>Life Insurance</h3>
            </div>
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_LI_NM" className="font-bold">
                    Provider name
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_LI_NM"
                      name="em_LI_NM"
                      value={em_LI_NM}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_LI_PR" className="font-bold">
                  Premium Amount
                  </label>
                  <InputText
                    id="em_LI_PR"
                    name="em_LI_PR"
                    value={em_LI_PR}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_LI_EF" className="font-bold">
                    Effective date
                  </label>
                  <div >
                  <Calendar
                    id="em_LI_EF"
                    name="em_LI_EF"
                    value={moment(data.em_LI_EF, "YYYYMMDD").isValid() ? moment(data.em_LI_EF, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_LI_EF", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_LI_EX" className="font-bold">
                    Expired date
                  </label>
                  <Calendar
                    id="em_LI_EX"
                    name="em_LI_EX"
                    value={moment(data.em_LI_EX, "YYYYMMDD").isValid() ? moment(data.em_LI_EX, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_LI_EX", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />    
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_BE_FI" className="font-bold">
                    Beneficiary
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_BE_FI"
                      name="em_BE_FI"
                      value={em_BE_FI}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_BF_NM" className="font-bold">
                    Benificiary name
                  </label>
                  <InputText
                    id="em_BF_NM"
                    name="em_BF_NM"
                    value={em_BF_NM}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_BF_AC" className="font-bold">
                    Beneficiary Bank Account
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_BF_AC"
                      name="em_BF_AC"
                      value={em_BF_AC}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_TK_EN" className="font-bold">
                    Ticket Entertainment
                  </label>
                  <InputText
                    id="em_TK_EN"
                    name="em_TK_EN"
                    value={em_TK_EN}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_LV_EN" className="font-bold">
                    Leave Entitlement
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_LV_EN"
                      name="em_LV_EN"
                      value={em_LV_EN}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                </div>
            </div>
          </TabPanel>
          {/* tab5 */}
          <TabPanel header="Bank Details">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_BA_NM" className="font-bold">
                    Bank Name
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_BA_NM"
                      name="em_BA_NM"
                      value={em_BA_NM}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_BA_BR" className="font-bold">
                    Bank Branch
                  </label>
                  <InputText
                    id="em_BA_BR"
                    name="em_BA_BR"
                    value={em_BA_BR}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_AC_NM" className="font-bold">
                    Account Name
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_AC_NM"
                      name="em_AC_NM"
                      value={em_AC_NM}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_AC_NO" className="font-bold">
                    Account Number
                  </label>
                  <InputText
                    id="em_AC_NO"
                    name="em_AC_NO"
                    value={em_AC_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_IB_NO" className="font-bold">
                    IBAN No
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="em_IB_NO"
                      name="em_IB_NO"
                      value={em_IB_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_SW_CD" className="font-bold">
                    SWIFT Code
                  </label>
                  <InputText
                    id="em_SW_CD"
                    name="em_SW_CD"
                    value={em_SW_CD}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                </div>
            </div>
          </TabPanel>
        </TabView>
        {/* tabs end */}
      </div>
      <EmployeePopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressType}
      />
      <ReportingPopup
        visible={ReportingPopupVisible}
        onClose={() => setReportingPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={addressType}
      />
    </Dialog>
  );
}
