import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import CompanyPopup from "./CompanyPopup";
import axios from "axios";
import moment from "moment";
export default function SalesTargetDialog({
  open,
  handleClose,
  data,
  // onchangedata,
  handleFormSubmit,
  onchangeDropDownFormData,
  onchangeDropDownFormData1,
  sk_MN_TH,
  sk_YE_AR,
  sk_AD_NO,
  setSelectedAbAdNM,
  selectedAbAdNM,
  // onchangeFormData,
  SalesTargetData,
  deleteRow,
  addRow,
}) {
  const [sk_YE_AROptions, setsk_YE_AROptions] = useState([]);
  const [sk_MN_THOptions, setsk_MN_THOptions] = useState([]);

  const { skUkNo
  } = data;

  const onchangeFormData = (name, value, index) => {
    if (name === "sk_AD_NO" && Array.isArray(sk_AD_NO)) {
      // Handle the case where sk_AD_NO is an array
      const updatedArray = [...sk_AD_NO];
      updatedArray[index] = value;
      onchangeDropDownFormData(name, updatedArray);
    } else {
      // Handle other cases
      onchangeDropDownFormData(name, value);
    }
  };

  
const onchangedata = (e) => {
  const { name, value } = e.target;
  // if (name === "eo_OV_DT" ) {
  //   console.log(`Received date for ${name}:`, value);
  //   const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
  //   console.log(`Formatted date for ${name}:`, formattedDate);

  //   if (moment(formattedDate, "YYYYMMDD").isValid()) {
  //     onchangeFormData(name, formattedDate);
  //   } else {
  //     console.error("Invalid date format:", value);
  //   }
  // } else {
    onchangeFormData(name, value);
  // }
};
const handleFormDataChange = (name, value) => {
  const index = name.split('_')[1];
  onchangeDropDownFormData1(name, value, index);
};

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const url = `${domain}/api/ClassCode/All`;

  const parameter = "YE/AR";
  const finalUrl = `${url}/${parameter}`;

  const parameter1 = "FO/MO";
  const finalUrl1 = `${url}/${parameter1}`;

  useEffect(() => {
    fetchDropdownOptions();
    fetchDropdownOptions1();
  }, []);

  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsk_YE_AROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsk_MN_THOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const isUpdateMode = Boolean(skUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [CompanyPopupVisible, setCompanyPopupVisible] = useState(false);

  const openCompanyPopup = (e) => {
    setCompanyPopupVisible(true);
    setSelectedRowIndex(e);
  };

// const handleDataSelect = (data) => {
//   console.log('Selected Data:', data);
//   setSelectedAbAdNM(data.ab_AD_NM);
//   onchangeFormData("sk_AD_NO", data.abAdNo, SelectedRowIndex);
//   setCompanyPopupVisible(false);
// };
const handleDataSelect = (data) => {
  const updatedData = [...SalesTargetData];
  const updatedRow = updatedData[SelectedRowIndex];
  // updatedRow.sk_AD_NO = data.abAdNo;
  updatedRow.sk_AD_NO = data.abAdNo;
  // updatedRow.sk_AD_NO = data.ab_AD_NM;
  updatedData[SelectedRowIndex] = updatedRow;
  setCompanyPopupVisible(false);
};
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={skUkNo ? "Revise Sales Target" : "Add Sales Target"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
            {!isUpdateMode && (
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          )}
           {!isUpdateMode && (
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
          )}
        </div>
        <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
            position: "relative",
          }}
        >
        <div style={{ marginTop: "3%" }}>
        <table className="custom-table" id="SalesTarget">
          <thead>
            <tr>
              <th style={{display:"none"}}>UK NO</th>
              <th>Employee</th>
              {/* <th>Name</th> */}
              <th>Year</th>
              <th>Month</th>
              <th>Target - Sales</th>
              <th>Target - Lead</th>
            </tr>
          </thead>
          <tbody>
            {SalesTargetData.map((row, index) => (
              <tr key={index}>
                <td style={{display:"none"}}>
                  <InputText
                    id={`skUkNo_${index}`}
                    name={`skUkNo_${index}`}
                    defaultValue={row.skUkNo}
                  />
                </td>
                <td >
                <div style={{ display: "flex"}}>
                  <InputText
                    id={`sk_AD_NO_${index}`}
                    name={`sk_AD_NO_${index}`}
                    //defaultValue={sk_AD_NO || row.sk_AD_NO}
                    value={row.sk_AD_NO ? row.sk_AD_NO : row[`${'sk_AD_NO'}_${index}`]}
                    onChange={(e) => onchangedata(e)}
                  />
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "7%",
                      cursor: "pointer",
                    }}
                    onClick={() => openCompanyPopup(index)}
                    // onClick={openCompanyPopup}
                  ></i>
                  </div>
                </td>
                {/* <td>
                  <InputText
                    id={`ab_AD_NM_${index}`}
                    name={`ab_AD_NM_${index}`}
                    value={row.ab_AD_NM ? row.ab_AD_NM : row[`${'ab_AD_NM'}_${index}`]}
                    onChange={(e) => onchangedata(e)}
                  />
                </td> */}
                <td>
                <Dropdown
                  id={`sk_YE_AR_${index}`}
                  name={`sk_YE_AR_${index}`}
                  value={data[`sk_YE_AR_${index}`] ? data[`sk_YE_AR_${index}`]:row.sk_YE_AR }
                  // value={FormData.et_SH_FT}
                  options={sk_YE_AROptions}
                  onChange={(e) => handleFormDataChange(`sk_YE_AR_${index}`, e.value)}
                  placeholder="Select Year"
                />
                </td>
                <td>
                <Dropdown
                  id={`sk_MN_TH_${index}`}
                  name={`sk_MN_TH_${index}`}
                  value={data[`sk_MN_TH_${index}`] ? data[`sk_MN_TH_${index}`]:row.sk_MN_TH }
                  // value={FormData.et_SH_FT}
                  options={sk_MN_THOptions}
                  onChange={(e) => handleFormDataChange(`sk_MN_TH_${index}`, e.value)}
                  placeholder="Select Month"
                />
                  {/* <select
                    id={`sk_MN_TH_${index}`}
                    name={`sk_MN_TH_${index}`}
                    value={FormData[`sk_MN_TH_${index}`]}
                    onChange={(e) =>
                      onchangeDropDownFormData1(
                        `sk_MN_TH_${index}`,
                        e.target.value
                      )
                    }
                  >
                    <option value="">Select Month</option>
                    {sk_MN_THOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select> */}
                </td>
                <td>
                <InputText
                  id={`sk_SA_TR_${index}`}
                  name={`sk_SA_TR_${index}`}
                  defaultValue={row.sk_SA_TR}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText
                  id={`sk_EN_GN_${index}`}
                  name={`sk_EN_GN_${index}`}
                  defaultValue={row.sk_EN_GN}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        </div>
      </div>
      <CompanyPopup
        visible={CompanyPopupVisible}
        onClose={() => setCompanyPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
    </Dialog>
  );
}
