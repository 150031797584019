import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
import WorkCentrePopup from "../Popups/WorkCentrePopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import Costcenter  from "views/common/CostCentreDrpDwn";
import { InputMask } from "primereact/inputmask";
export default function WorkOrderTimeEntryDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  WorkOrderTimeEntryTableData,
  setWorkOrderTimeEntryTableData,
  onchangeDropDownFormData1,
  wo_CO_CT,
  wo_WO_CO,
  wo_TY_HR,
  setwo_TY_HR,
  setEmployeeName,

}) {
  const { woUkNo, } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const onChangeTime=(name,value,index)=>{
    value= moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value,index);
  };
  function updateTotalHours(index) {
    
    let et_IN_DT = WorkOrderTimeEntryTableData[index].et_IN_DT;
    let et_OU_DT = WorkOrderTimeEntryTableData[index].et_OU_DT;
    let et_OU_TM = WorkOrderTimeEntryTableData[index].et_OU_TM;
    let et_IN_TM = WorkOrderTimeEntryTableData[index].et_IN_TM;
      if(et_IN_DT!=undefined && et_OU_DT!=undefined && et_OU_DT!="" && et_IN_DT!="" ){
        let inTime=moment(et_IN_TM, "HHmmss").format("HH:mm:ss");
        let outTime=moment(et_OU_TM, "HHmmss").format("HH:mm:ss");
        let indate = moment(et_IN_DT, "YYYYMMDD").format("YYYY-MM-DD");
        let outDate = moment(et_OU_DT, "YYYYMMDD").format("YYYY-MM-DD");
        const checkInDateTime = moment(`${indate} ${inTime}`, "YYYY-MM-DD HH:mm:ss");
        const checkOutDate = moment(`${outDate} ${outTime}`, "YYYY-MM-DD HH:mm:ss");
        const duration = moment.duration(checkOutDate.diff(checkInDateTime));
        const totalHours = duration.asHours();
        var value = totalHours.toFixed(2);
        onchangeFormData("et_TO_HR",value,index);
      }else{
        onchangeFormData("et_TO_HR","",index);
      }
  };

  const [wo_TY_HROptions, setwo_TY_HROptions] = useState([]);
  const [wo_CO_CTOptions, setwo_CO_CTOptions] = useState([]);
  // Dropdown
  const { fetchClassCodeDS } = ClassCode();
  const { fetchCostCenter } = Costcenter();
 // Expense Category
const onchangeDropDownFormData2 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setwo_TY_HR(value);
};

  useEffect(() => {
    fetchDropdownCostCentre();
    fetchDropdownDataHrs();
  }, []);

  const fetchDropdownCostCentre = async () => {
    const Options = await fetchCostCenter();
    setwo_CO_CTOptions(Options);
  };
  const fetchDropdownDataHrs = async () => {
    const Options = await fetchClassCodeDS("HR/TY");
    setwo_TY_HROptions(Options);
  };
  const addRow = () => {
    setWorkOrderTimeEntryTableData([...WorkOrderTimeEntryTableData, {}]);
  };
  const deleteRow = () => {
    if (WorkOrderTimeEntryTableData.length > 0) {
      const updatedData = [...WorkOrderTimeEntryTableData];
      updatedData.pop();
      setWorkOrderTimeEntryTableData(updatedData);
    }
  };
const isUpdateMode = Boolean(woUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [addressType, setaddressType] = useState("E");
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);
  const [WorkCentrePopupVisible, setWorkCentrePopupVisible] = useState(false);

  const openEmployeePopup = (e) => {
    setEmployeePopupVisible(true);
    setSelectedRowIndex(e);
    setShowSearchIcon(false);
  };
  const handleSelectEmployee = (data) => {
    setEmployeeName(data.ab_AD_NM);
    document.getElementById(`EmployeeName_${SelectedRowIndex}`).value = data.ab_AD_NM;
    document.getElementById(`wo_EM_NO_${SelectedRowIndex}`).value = data.abAdNo;
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };

  const openWorkcentrePopup = (e) => {
    setWorkCentrePopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelectWKCT =async (content) => {
    document.getElementById(`workCentre_DESC_${SelectedRowIndex}`).value = content.wc_WC_DS;
    document.getElementById(`wo_WC_CT_${SelectedRowIndex}`).value = content.wc_WK_CT;
    setWorkCentrePopupVisible(false);
    setShowSearchIcon(false);
  };

  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{woUkNo ? "Revise WO Time Entry" : "Add WO Time Entry"}</h5>
      <div style={{width:'90rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div  style={borderbox}>
        <div  style={inboxstyle}>
         <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wo_CO_CT" className="font-bold">
              Cost Centre
            </label>
            <Dropdown
              id="wo_CO_CT"
              name="wo_CO_CT"
              value={wo_CO_CT}
              options={wo_CO_CTOptions}
              onChange={(e) => onchangeDropDownFormData1("wo_CO_CT", e.value)}
              placeholder="Select Cost Centre"
            />
            </div>                  
          </div>
        </div>  
        <div  style={tablestyle}>
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="WorkOrderTimeEntrytable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Order No</th>
                <th>Type</th>
                <th>Company</th>
                <th>Shift</th>
                <th>Employee No  <span style={{ color: 'red', }}>*</span> </th>
                <th>Name</th>
                <th>Work Centre  <span style={{ color: 'red', }}>*</span> </th>
                <th>Description</th>
                <th>Type of Hrs</th>
                <th>From Date</th>
                <th>To Date</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {WorkOrderTimeEntryTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`woUkNo_${index}`}
                      name={`woUkNo_${index}`}
                      value={row.woUkNo}
                    />
                  </td>
                  <td>
                    <InputText style={{width:"7rem"}}
                      id={`wo_WO_NO_${index}`}
                      name={`wo_WO_NO_${index}`}
                      value={row.wo_WO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText style={{width:"7rem"}}
                      id={`wo_WO_TY_${index}`}
                      name={`wo_WO_TY_${index}`}
                      value={row.wo_WO_TY}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText style={{width:"7rem"}} disabled  
                      id={`wo_WO_CO_${index}`}
                      name={`wo_WO_CO_${index}`}
                      value={wo_WO_CO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <InputText style={{width:"7rem"}}
                      id={`wo_SF_CD_${index}`}
                      name={`wo_SF_CD_${index}`}
                      value={row.wo_SF_CD}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td >
                      <div style={{display:'flex'}}>
                    <InputText style={{width:"10rem"}}
                      id={`wo_EM_NO_${index}`}
                      name={`wo_EM_NO_${index}`}
                      onFocus={() => setShowSearchIcon(true)}
                      onChange={(e) => onchangedata(e)}
                      value={row.wo_EM_NO}
                    />
                      {showSearchIcon && (
                      <i className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openEmployeePopup(index)}
                      ></i>
                      )}
                    </div>
                  </td>
                  <td >
                    <InputText style={{width:"15rem"}}
                      id={`EmployeeName_${index}`}
                      name={`EmployeeName_${index}`}
                      value={row.EmployeeName}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <div style={{display:'flex'}}>
                    <InputText style={{width:"8rem"}}
                      id={`wo_WC_CT_${index}`}
                      name={`wo_WC_CT_${index}`}
                      onFocus={() => setShowSearchIcon(true)}
                      onChange={(e) => onchangedata(e)}
                      value={row.wo_WC_CT}
                    />
                    {showSearchIcon && (
                      <i className="pi pi-search"
                        style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "7%",
                        cursor: "pointer",
                        }}
                        onClick={(e) => openWorkcentrePopup(index)}
                      ></i>
                      )}
                      </div>
                  </td>
                  <td > 
                    <InputText
                      style={{width:"15rem"}}
                      id={`workCentre_DESC_${index}`}
                      name={`workCentre_DESC_${index}`}
                      value={row.workCentre_DESC ? row.workCentre_DESC: data[`${"workCentre_DESC"}_${index}`]}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <Dropdown
                      id={`wo_TY_HR_${index}`}
                      name={`wo_TY_HR_${index}`}
                      value={data[`wo_TY_HR_${index}`] ? data[`wo_TY_HR_${index}`] : wo_TY_HR}
                      options={wo_TY_HROptions}
                      onChange={(e) => onchangeDropDownFormData2(`wo_TY_HR_${index}`, e.value)}
                      placeholder="Select Type of Hrs"
                    />
                  </td>
                  <td >
                  <Calendar style={{width:"7rem"}}
                    id={`wo_TR_DT_${index}`}
                    name={`wo_TR_DT_${index}`}
                    value={row.wo_TR_DT ? moment(row.wo_TR_DT , "YYYYMMDD").toDate(): null}
                    onChange={(e) => onDateChange({ target: { name: "wo_TR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td >
                  <Calendar style={{width:"7rem"}}
                    id={`wo_EN_DT_${index}`}
                    name={`wo_EN_DT_${index}`}
                    value={row.wo_EN_DT ? moment(row.wo_EN_DT , "YYYYMMDD").toDate(): null}
                    onChange={(e) => onDateChange({ target: { name: "wo_EN_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td >
                  <InputMask style={{width:'10rem'}}
                    id={`wo_ST_TM_${index}`}
                    name={`wo_ST_TM_${index}`}
                    mask="99:99:99"
                    onBlur={(e) => { onChangeTime("wo_ST_TM",e.target.value,index); updateTotalHours(index); }}
                    value={moment(row.wo_ST_TM, "HHmmss").format("HH:mm:ss")}
                  />
                  </td>
                  <td >
                  <InputMask style={{width:'10rem'}}
                    id={`wo_EN_TM_${index}`}
                    name={`wo_EN_TM_${index}`}
                    mask="99:99:99"
                    onBlur={(e) => { onChangeTime("wo_EN_TM",e.target.value,index);  updateTotalHours(index); }}
                    value={moment(row.wo_EN_TM, "HHmmss").format("HH:mm:ss")}
                  />
                  </td>
                  <td>
                    <InputText disabled style={{width:'7rem'}}
                      id={`Diff_${index}`}
                      name={`Diff_${index}`}
                      value={row.Diff}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
      <WorkCentrePopup
        visible={WorkCentrePopupVisible}
        onClose={() => setWorkCentrePopupVisible(false)}
        onDataSelect={handleDataSelectWKCT}
        formData={data}
      />
       <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleSelectEmployee}
        formData={data}
        addressType={addressType}
      />
    </Dialog>
  );
}
