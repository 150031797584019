import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import { InputMask } from "primereact/inputmask";
import ItemPopup from "../Popups/ItemPopup";
import ParentItemPopup from "../Popups/ParentItemPopup";
import EstimateEntryPopup from "../Popups/EstimateEntryPopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function EstimateMaterialDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  EstimateMaterialTableData,
  setEstimateMaterialTableData,
  em_DE_PT,
  setem_DE_PT,
  setbd_DO_TY,
  setem_PR_UM,
  SelectedemEMNO1,
  setSelectedemEMNO1,
  SelectedItem1,
  setselectedItem1,
  SelectedDesc1,
  setselectedDesc1,
  SelectedItem,
  setselectedItem,
  SelectedDesc,   
  setselectedDesc,
  ic_CO_CT,
  ic_CO_TY,
  em_EX_CT,
  ed_RQ_DT,
  ed_OR_DT,
  ed_EX_PR,
  seted_EX_PR,
  seted_RQ_DT,
  seted_OR_DT,
  setem_EX_CT,
  em_OR_NO,
  setem_OR_NO,
  em_OR_QT,
  setem_OR_QT,
  handlePO,
  ph_CO_CT,
  ph_OR_TY
}) {
  const {
    emUkNo,
    em_PE_RQ,
    em_OR_TY,
    em_OR_CO,
    em_LN_NO,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const [em_DE_PTOptions, setem_DE_PTOptions] = useState([]);
  const [bd_BL_STOptions, setbd_BL_STOptions] = useState([]);
  const [bd_DO_TYOptions, setbd_DO_TYOptions] = useState([]);
  const [em_PR_UMOptions, setem_PR_UMOptions] = useState([]);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const DepartmentDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setem_DE_PT(value);
  };
  const InvoiceTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_DO_TY(value);
  };
  const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setem_PR_UM(value);
  };
  useEffect(() => {
    Department();
    UM();
    Status();
    Type();
  }, []);
  const Department = async () => {
    const Options = await fetchClassCodeDS("PO/DP");
    setem_DE_PTOptions(Options);
  };
  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setem_PR_UMOptions(Options);
  };
  const Status = async () => {
    const Options = await fetchClassCodeDS("BL/ST");
    setbd_BL_STOptions(Options);
  };
  const Type = async () => {
    const Options = await fetchClassCodeDS("FO/DT");
    setbd_DO_TYOptions(Options);
  };
  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AddresstypeC, setAddresstypeC] = useState("C");
  const [AddresstypeE, setAddresstypeE] = useState("E");
  const [AddressPopupVisible, setAddressPopupVisible] = useState(false);
  const openAddressPopup = (e) => {
    setAddressPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect1 = (data) => {
    setSelectedemEMNO1(data.ab_AD_NM);
    onchangeFormData("em_PE_RQ", data.abAdNo);
    setAddressPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [EstimateEntryPopupVisible, setEstimateEntryPopupVisible] = useState(false);
  const openEstimateEntryPopup = () => {
    setEstimateEntryPopupVisible(true);
  };
  const handleEstimateEntry = (data) => {
    document.getElementById("em_OR_NO").value =  data.ed_OR_NO;
    onchangeFormData("em_OR_TY", data.ed_OR_TY);
    onchangeFormData("em_OR_CO", data.ed_OR_CO);
    onchangeFormData("em_LN_NO", data.ed_LN_NO);
    setem_EX_CT(data.ed_EX_CT);
    seted_EX_PR(data.ed_EX_PR);
    setem_OR_QT(data.ed_OR_QT);
    seted_OR_DT(data.ed_OR_DT);
    seted_RQ_DT(data.ed_RQ_DT);
    setselectedItem1(data.ed_IT_NO)
    setselectedDesc1(data.itemDES)
    setEstimateEntryPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [ItemVisible, setItemVisible] = useState(false);
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    onchangeFormData("em_IT_NM", data.im_IT_NM, SelectedRowIndex);
    onchangeFormData("itemDES2", data.im_IT_DS, SelectedRowIndex);
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
      "im_IT_NM":data.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      UMDropdown(`em_PR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
    } else {
      console.error("Invalid API response:", data);
    }
    await UnitCost(data.im_IT_NM);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const UnitCost =async (im_IT_NM) => {
    const Url = `${domain}/api/ItemCost/GetUnitCost`;
    const requestData={
      "ic_IT_NM": im_IT_NM,
      "ic_CO_CT": document.getElementById (`ic_CO_CT_${SelectedRowIndex}`).value,
      "ic_CO_TY": document.getElementById (`ic_CO_TY_${SelectedRowIndex}`).value,
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
  
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data;
    if (dataItMt ) {
      document.getElementById (`em_ST_CT_${SelectedRowIndex}`).value =  dataItMt.ic_CO_ST;
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
   
  };
  const onchangeFormUnitPrice = async(e,index) => {
    Quantity(index);
  };
  const Quantity =async (SelectedRowIndex) => {
    const Url = `${domain}/api/EstimateMaterial/GetOrderQuantityAndProcurredQuantity`;
    const requestData={
      "estimateMaterialList":[
        {
          "em_OR_QT": document.getElementById (`em_OR_QT_${SelectedRowIndex}`).value,
          "em_ST_CT": document.getElementById (`em_ST_CT_${SelectedRowIndex}`).value,
          "em_PR_QT": document.getElementById (`em_PR_QT_${SelectedRowIndex}`).value,
        }
      ]
  
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
  
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data;
    if (dataItMt ) {
      document.getElementById (`em_EX_CT_${SelectedRowIndex}`).value =  dataItMt.COSTAMOUNT;
      document.getElementById (`em_PR_AM_${SelectedRowIndex}`).value =  dataItMt.AMOUNT;
      document.getElementById (`balance_QTY_${SelectedRowIndex}`).value =  dataItMt.BALANCEQTY;
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
   
  };
  const addRow = () => {
    setEstimateMaterialTableData([...EstimateMaterialTableData, {}]);
  };
  const deleteRow = () => {
    if (EstimateMaterialTableData.length > 0) {
      const updatedData = [...EstimateMaterialTableData];
      updatedData.pop();
      setEstimateMaterialTableData(updatedData);
    }
  };  
  const [Item1Visible, setItem1Visible] = useState(false);
  const handleItem1 =async (data) => {
    setselectedItem1(data.im_IT_NM)
    setselectedDesc1(data.im_IT_DS)
    onchangeFormData("em_PA_IT", data.im_IT_NM);
    onchangeFormData("itemDES1", data.im_IT_DS);
    setItem1Visible(false);
    setShowSearchIcon(false);
  };
  const openItem1Popup = (e) => {
    setItem1Visible(true);
    setSelectedRowIndex(e);
  };

  const isUpdateMode = Boolean(emUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '70%' }}>
      <h5>{emUkNo ? "Revise Estimate Material" : "Add Estimate Material"}</h5>
      <div style={{ display:'flex', justifyContent: isUpdateMode ? 'flex-start' : 'flex-start', width: '70%'}}>
      {isUpdateMode && (
        <div style={{ display: 'flex',width:"10%" }}>
          <Button 
            style={{ width:"50%", marginRight: "8px",backgroundColor:"transparent",color:"blue",border:'none'}}
            icon="pi pi-check" 
            onClick={handlePO} 
            tooltip={"Generate Requisition"}>
          </Button>
        </div>
        )}
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
          <div style={inboxstyle}>
            <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="em_OR_NO" className="font-bold">
                  Estimate No <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{display:"flex"}}>
                  <InputText
                    id="em_OR_NO"
                    name="em_OR_NO"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={em_OR_NO}
                  />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openEstimateEntryPopup}
                    ></i>
                    )}
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="em_OR_TY" className="font-bold">
              Type 
              </label>
              <InputText disabled
                id="em_OR_TY"
                name="em_OR_TY"
                value={em_OR_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="em_OR_CO" className="font-bold">
              Company
              </label>
              <InputText disabled
                id="em_OR_CO"
                name="em_OR_CO"
                value={em_OR_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="em_LN_NO" className="font-bold">
                Line No
              </label>
              <InputText disabled
                id="em_LN_NO"
                name="em_LN_NO"
                value={em_LN_NO}
                onChange={(e) => onchangedata(e)}
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ed_OR_DT" className="font-bold">
                  Order Date
                </label>
                <Calendar disabled
                  id="ed_OR_DT"
                  name="ed_OR_DT"
                  value={moment(ed_OR_DT, "YYYYMMDD").toDate()}
                  onChange={(e) => onchangedata({ target: { name: "ed_OR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ed_RQ_DT" className="font-bold">
                  Req. Date
                </label>
                <Calendar disabled
                  id="ed_RQ_DT"
                  name="ed_RQ_DT"
                  value={moment(ed_RQ_DT, "YYYYMMDD").toDate()}
                  onChange={(e) => onchangedata({ target: { name: "ed_RQ_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ed_EX_PR" className="font-bold">
                  Amount
                </label>
                <InputText  disabled
                  id="ed_EX_PR"
                  name="ed_EX_PR"
                  value={ed_EX_PR}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="em_EX_CT" className="font-bold">
                  Cost
                </label>
                <InputText disabled
                  id="em_EX_CT"
                  name="em_EX_CT"
                  value={em_EX_CT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="em_OR_QT" className="font-bold">
                  Estimated Qty
                </label>
                <InputText  disabled
                  id="em_OR_QT"
                  name="em_OR_QT"
                  value={em_OR_QT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PA_IT" className="font-bold">
                    Parent Item <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText disabled
                      id="em_PA_IT"
                      name="em_PA_IT"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedItem1}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItem1Popup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="itemDES" className="font-bold">
                  Description
                  </label>
                  <InputText disabled
                    id="itemDES"
                    name="itemDES"
                    value={SelectedDesc1}
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PE_RQ" className="font-bold">
                  Requested By  <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="reqByDES"
                      name="reqByDES"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedemEMNO1}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openAddressPopup}
                    ></i>
                    )}
                  </div>
                  <div style={{ display: "none" }}>
                    <InputText
                      id="em_PE_RQ"
                      name="em_PE_RQ"
                      value={em_PE_RQ}
                      onChange={(Event) => onchangedata(Event)}
                    />
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openAddressPopup}
                    ></i>
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_DE_PT" className="font-bold">
                    Department
                  </label>
                  <Dropdown
                    name="em_DE_PT"  
                    value={em_DE_PT}
                    options={em_DE_PTOptions}
                    onChange={(e) => DepartmentDropDown("em_DE_PT", e.value)}
                    placeholder="Select Department"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                  <label htmlFor="ph_OR_TY" className="font-bold">
                  Type 
                  </label>
                  <InputText disabled
                    id="ph_OR_TY"
                    name="ph_OR_TY"
                    value={ph_OR_TY}
                    onChange={(e) => onchangedata(e)}
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                    <label htmlFor="ph_CO_CT" className="font-bold">
                    Cost Centre
                    </label>
                    <InputText disabled
                      id="ph_CO_CT"
                      name="ph_CO_CT"
                      value={ph_CO_CT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
            </div>
          </div>  
          <div  style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
              {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
            </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="operationtable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Item Name <span style={{color:"red"}}>*</span></th>
                <th>Description</th>
                <th>Order Qty</th>
                <th>UM</th>
                <th>Std. Cost</th>
                <th>Amount</th>
                <th>Procurred Qty</th>
                <th>Amount</th>
                <th>Balance Qty</th>
                <th style={{ display: "none" }}>Cost Centre</th>
                <th style={{ display: "none" }}>Type</th>
              </tr>
            </thead>
            <tbody>
              {EstimateMaterialTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`emUkNo_${index}`}
                      name={`emUkNo_${index}`}
                      value={row.emUkNo}
                    />
                  </td>
                  <td >
                    <div style={{ display: "flex" }}>
                  <InputText 
                    id={`em_IT_NM_${index}`}
                    name={`em_IT_NM_${index}`}
                    onChange={(e) => onchangeFormData(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={row.em_IT_NM ? row.em_IT_NM: row[`${"em_IT_NM"}_${index}`]}
                  />
                    {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "15%",
                      cursor: "pointer",
                    }}
                    onClick={(e) => openItemPopup(index)}
                  ></i>
                  )}
                  </div>
                </td>
                  <td>
                  <InputText disabled
                  style={{ width: "15rem" }}
                  id={`itemDES2_${index}`}
                  name={`itemDES2_${index}`}
                  defaultValue={row.itemDES2 ? row.itemDES2 : row[`${"itemDES2"}_${index}`]}
                  />
                  </td>
                  <td >
                    <InputText  
                      id={`em_OR_QT_${index}`}
                      name={`em_OR_QT_${index}`}
                      defaultValue={row.em_OR_QT}
                      onChange={(e) => onchangeFormUnitPrice(e,index)}
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`em_PR_UM_${index}`}
                      name={`em_PR_UM_${index}`}
                      value={ data[`em_PR_UM_${index}`] ? data[`em_PR_UM_${index}`]: row.em_PR_UM}
                      options={em_PR_UMOptions}
                      onChange={(e) =>UMDropdown(`em_PR_UM_${index}`, e.value)}
                      placeholder="Select UM"
                    />
                    </td>
                  <td >
                    <InputText disabled 
                      id={`em_ST_CT_${index}`}
                      name={`em_ST_CT_${index}`}
                      defaultValue={row.em_ST_CT}
                      onChange={(e) => onchangeFormUnitPrice(e,index)}
                    />
                  </td>
                  <td >
                    <InputText  disabled 
                      id={`em_EX_CT_${index}`}
                      name={`em_EX_CT_${index}`}
                      defaultValue={row.em_EX_CT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText  
                      id={`em_PR_QT_${index}`}
                      name={`em_PR_QT_${index}`}
                      defaultValue={row.em_PR_QT}
                      onChange={(e) => onchangeFormUnitPrice(e,index)}
                    />
                  </td>
                  <td >
                    <InputText  disabled 
                      id={`em_PR_AM_${index}`}
                      name={`em_PR_AM_${index}`}
                      defaultValue={row.em_PR_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText disabled 
                      id={`balance_QTY_${index}`}
                      name={`balance_QTY_${index}`}
                      defaultValue={row.balance_QTY}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td style={{display:"none"}} >
                    <InputText  disabled 
                      id={`ic_CO_TY_${index}`}
                      name={`ic_CO_TY_${index}`}
                      defaultValue={ic_CO_TY}
                    />
                  </td>
                  <td style={{display:"none"}} >
                    <InputText disabled 
                      id={`ic_CO_CT_${index}`}
                      name={`ic_CO_CT_${index}`}
                      defaultValue={ic_CO_CT}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
      <AddressBookPopup
        visible={AddressPopupVisible}
        onClose={() => setAddressPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={AddresstypeE}
      />
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
      <ParentItemPopup
        visible={Item1Visible}
        onClose={() => setItem1Visible(false)}
        onDataSelect={handleItem1}
        formData={data}
      />
      <EstimateEntryPopup
        visible={EstimateEntryPopupVisible}
        onClose={() => setEstimateEntryPopupVisible(false)}
        onDataSelect={handleEstimateEntry}
        formData={data}
      />
    </Dialog>
  );
}
