import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import EstimateRateDialog from "./EstimateRateDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  erUkNo:"",
  er_IT_NM:"",
  itemDES:"",
  er_CR_CD:"",
  er_UN_RT:"",
  er_PR_UM:"",
  er_UN_CT:"",
  er_MK_UP:"",
  er_MK_AM:"",
  er_EX_MR:"",
  er_AL_DS:"",
  er_FR_DT:"",
  er_TO_DT:"",
};

const initialFilters = {
  er_IT_NM:{ value: "", operation: "" },
  itemDES:{ value: "", operation: "" },
  er_CR_CD:{ value: "", operation: "" },
  er_UN_RT:{ value: "", operation: "" },
  er_PR_UM:{ value: "", operation: "" },
  er_UN_CT:{ value: "", operation: "" },
  er_MK_UP:{ value: "", operation: "" },
  er_MK_AM:{ value: "", operation: "" },
  er_EX_MR:{ value: "", operation: "" },
  er_AL_DS:{ value: "", operation: "" },
  er_FR_DT:{ value: "", operation: "" },
  er_TO_DT:{ value: "", operation: "" },
};

const EstimateRateMain = () => {
    // buttonstyles
    const {formatDate, buttonStyles, addButtonStyles,
      copyButtonStyles,
      checkboxStyle,tableHeaderStyle,
      inputStyle,tableCellStyle,
     } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
// drodpdowns
  const [eg_GP_NM, seteg_GP_NM ] = useState("");
  const [eg_PO_NM, seteg_PO_NM ] = useState("");
  const [eg_AP_RQ, seteg_AP_RQ ] = useState("");
  const [er_CR_CD, seter_CR_CD ] = useState("");
  const [er_PR_UM, seter_PR_UM ] = useState("");

  const [er_IT_NM, seter_IT_NM ] = useState("");

  // AdvanceSearch
  const [SelectedItem, setselectedItem ] = useState("");
  const [SelectedDesc, setselectedDesc ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setselectedItem(null);
    setselectedDesc(null);
    seter_CR_CD(null);
    seter_PR_UM(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setselectedItem(null);
    setselectedDesc(null);
    seter_CR_CD(null);
    seter_PR_UM(null);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/EstimateRate/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const EstimateRateData = response.data;
          delete EstimateRateData.erUkNo;
          setFormData(EstimateRateData);
          setselectedItem(EstimateRateData.er_IT_NM);
          setselectedDesc(EstimateRateData.itemDES);
          seter_CR_CD(EstimateRateData.er_CR_CD);
          seter_PR_UM(EstimateRateData.er_PR_UM);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [er_CO_NO , seter_CO_NO] = useState("");
 
    const handleDefault = async () => {
      seter_CO_NO(`${company}`)
      const linkPath = String(window.location);
      const l_array = linkPath.split("=");
      const LinkId = l_array[1];
      
      const requestBody = {
        "version_ID" : LinkId
      };
      const paginationUrl = `${domain}/api/LinkId/Select`;
      try {
        const response = await axios.post(paginationUrl, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataDefault = response.data;
        const listDefault = [];
        var count = 0;
        if (dataDefault.versionIds != null) {
          for (let i = 0; i < dataDefault.versionIds.length; i++) {
            if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
              const fName = dataDefault.versionIds[i].field_ID;
           
            }
            if(dataDefault.versionIds[i].field_TYPE === "LIST"){
              count++;
              const def = {
                "column" : dataDefault.versionIds[i].field_ID ,
                "value" : dataDefault.versionIds[i].default_VALUE,
                "operation" : "EQUAL"
              }
              listDefault.push(def);
            }
          }
          handleSearch(listDefault,count);
        } else {
          handleSearch(listDefault,count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };


  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/EstimateRate/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
 
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/EstimateRate/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const EstimateRateData = response.data;
        setFormData(EstimateRateData);
        setselectedItem(EstimateRateData.er_IT_NM);
        setselectedDesc(EstimateRateData.itemDES);
        seter_CR_CD(EstimateRateData.er_CR_CD);
        seter_PR_UM(EstimateRateData.er_PR_UM);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {
    if (FormData.erUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/EstimateRate/Update`;
      if (confirm) {
        axios
          .put(update, FormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.er_CO_NO = company;
      axios
        .post(`${domain}/api/EstimateRate/Add`, FormData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["er_IT_NM"] ? filters["er_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_IT_NM",
                        e.target.value,
                        filters["er_IT_NM"]
                          ? filters["er_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["itemDES"] ? filters["itemDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "itemDES",
                        e.target.value,
                        filters["itemDES"] ? filters["itemDES"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["er_CR_CD"] ? filters["er_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_CR_CD",
                        e.target.value,
                        filters["er_CR_CD"]
                          ? filters["er_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["er_UN_RT"] ? filters["er_UN_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_UN_RT",
                        e.target.value,
                        filters["er_UN_RT"]
                          ? filters["er_UN_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["er_PR_UM"] ? filters["er_PR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_PR_UM",
                        e.target.value,
                        filters["er_PR_UM"]
                          ? filters["er_PR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["er_UN_CT"] ? filters["er_UN_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_UN_CT",
                        e.target.value,
                        filters["er_UN_CT"]
                          ? filters["er_UN_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["er_MK_UP"] ? filters["er_MK_UP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_MK_UP",
                        e.target.value,
                        filters["er_MK_UP"] ? filters["er_MK_UP"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["er_MK_AM"] ? filters["er_MK_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_MK_AM",
                        e.target.value,
                        filters["er_MK_AM"] ? filters["er_MK_AM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["er_EX_MR"] ? filters["er_EX_MR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_EX_MR",
                        e.target.value,
                        filters["er_EX_MR"] ? filters["er_EX_MR"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["er_AL_DS"] ? filters["er_AL_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_AL_DS",
                        e.target.value,
                        filters["er_AL_DS"] ? filters["er_AL_DS"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["er_FR_DT"] ? filters["er_FR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_FR_DT",
                        e.target.value,
                        filters["er_FR_DT"] ? filters["er_FR_DT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["er_TO_DT"] ? filters["er_TO_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_TO_DT",
                        e.target.value,
                        filters["er_TO_DT"] ? filters["er_TO_DT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
               
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Item No</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Rate</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Unit Cost</th>
                <th style={tableHeaderStyle}>Mark Up %</th>
                <th style={tableHeaderStyle}>Mark Up Amount</th>
                <th style={tableHeaderStyle}>Expected Margin %</th>
                <th style={tableHeaderStyle}>Allowed Discount %</th>
                <th style={tableHeaderStyle}>From Date</th>
                <th style={tableHeaderStyle}>To Date</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.erUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.erUkNo}
                        checked={selectedRows.includes(rowData.erUkNo)}
                        onChange={() => handleRowSelect(rowData.erUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.er_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.itemDES}</td>
                    <td style={tableCellStyle}>{rowData.er_CR_CD}</td>
                    <td style={tableCellStyle}>{rowData.er_UN_RT}</td>
                    <td style={tableCellStyle}>{rowData.er_PR_UM}</td> 
                    <td style={tableCellStyle}>{rowData.er_UN_CT}</td>
                    <td style={tableCellStyle}>{rowData.er_MK_UP}</td>    
                    <td style={tableCellStyle}>{rowData.er_MK_AM}</td>   
                    <td style={tableCellStyle}>{rowData.er_EX_MR}</td>    
                    <td style={tableCellStyle}>{rowData.er_AL_DS}</td>    
                    <td style={tableCellStyle}>{formatDate(rowData.er_FR_DT)}</td>    
                    <td style={tableCellStyle}>{formatDate(rowData.er_TO_DT)}</td>                
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <EstimateRateDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        er_CO_NO={er_CO_NO}
        eg_GP_NM={eg_GP_NM}
        seteg_GP_NM={seteg_GP_NM}
        eg_PO_NM={eg_PO_NM}
        seteg_PO_NM={seteg_PO_NM}
        eg_AP_RQ={eg_AP_RQ}
        seteg_AP_RQ={seteg_AP_RQ}
        er_CR_CD={er_CR_CD}
        seter_CR_CD={seter_CR_CD}
        er_PR_UM={er_PR_UM}
        seter_PR_UM={seter_PR_UM}
        er_IT_NM={er_IT_NM}
        SelectedItem={SelectedItem}
        setselectedItem={setselectedItem}
        SelectedDesc={SelectedDesc}
        setselectedDesc={setselectedDesc}
      />
    </div>
  );
};

export default EstimateRateMain;
