import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function BankChargesDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  FormData,
  setFormData,
  bc_CO_NO,
  bc_BA_NK,
  setbc_BA_NK,
}) {
  const {
    bcUkNo,
    bc_EF_DT,
    bc_CH_RG,
  } = data;
  const {borderbox,inboxstyle} = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [bc_BA_NKOptions, setbc_BA_NKOptions] = useState([]);
// Bank
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbc_BA_NK(value);
  };

  useEffect(() => {
    fetchDropdownBnk();
  }, []);

  const fetchDropdownBnk = async () => {
    const Options = await fetchClassCodeDS("RT/BN");
    setbc_BA_NKOptions(Options);
  };

const isUpdateMode = Boolean(bcUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "55rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={bcUkNo ? "Revise Bank Charges" : "Add Bank Charges"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
            <div
                style={{
                  marginTop: "2%",
                  border: "1px solid #e8e8e8",
                  padding: "16px",
                  borderRadius: "15px",
                  backgroundColor: "aliceblue",
                  overflow: "auto",
                }}
              >
                <div className="formgrid grid">
                    <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                      <label htmlFor="bc_BA_NK" className="font-bold">
                      Bank Name
                      </label>
                      <Dropdown
                        name="bc_BA_NK"
                        value={bc_BA_NK}
                        options={bc_BA_NKOptions}
                        onChange={(e) => onchangeDropDownFormData1("bc_BA_NK", e.value)}
                        placeholder="Select Bank"
                      />
                    </div>
                    <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                      <label htmlFor="bc_CO_NO" className="font-bold">
                        Company
                      </label>
                      <InputText
                        id="bc_CO_NO"
                        name="bc_CO_NO"
                        value={bc_CO_NO}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                      <label htmlFor="bc_EF_DT" className="font-bold">
                        Effective From
                      </label>
                      <Calendar
                        id="bc_EF_DT"
                        name="bc_EF_DT"
                        value={moment(data.bc_EF_DT, "YYYYMMDD").isValid() ? moment(data.bc_EF_DT, "YYYYMMDD").toDate() : null}
                        onChange={(e) => onchangedata({ target: {name: "bc_EF_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                        dateFormat="dd/mm/yy"
                      />
                    </div>
                    <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                      <label htmlFor="bc_CH_RG" className="font-bold">
                        % of Charge
                      </label>
                      <InputText
                        id="bc_CH_RG"
                        name="bc_CH_RG"
                        value={bc_CH_RG}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                </div>
            </div>
      </div>
    </Dialog>
  );
}
