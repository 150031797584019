import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import PostReceiptsDialog from "./PostReceiptsDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
const initialValue = {
  rh_UK_NO: "",
  rh_DO_CO: "",
  rh_CU_NO: "",
  name:"",
  rh_PY_NO:"",
  rh_RC_DT:"",
  rh_GL_DT:"",
  rhPyId:"",
  rh_RC_NO:"",
  rh_GL_PO: "",
  rh_GL_CO: "",
  rh_BA_TY: "",
  rh_BA_NO:"",
  rh_BA_DT:"",
  rh_PA_NO:"",
  rh_RC_AM:"",
  rh_OP_AM:"",
  rh_CR_CO:"",
  rh_CR_MO:"",
  rh_CR_RT:"",
  rh_RC_FR:"",
  rh_OP_FR: "",
  rh_RM_EX: "",
  rh_VO_DT: "",
  rh_VO_FL:"",
};

const initialFilters = {
  rh_DO_CO: { value: "", operation: "" },
  rh_CU_NO: { value: "", operation: "" },
      name: { value: "", operation: "" },
  rh_PY_NO: { value: "", operation: "" },
  rh_RC_DT: { value: "", operation: "" },
  rh_GL_DT: { value: "", operation: "" },
    rhPyId: { value: "", operation: "" },
  rh_RC_NO: { value: "", operation: "" },
  rh_GL_PO: { value: "", operation: "" },
  rh_GL_CO: { value: "", operation: "" },
  rh_BA_TY: { value: "", operation: "" },
  rh_BA_NO: { value: "", operation: "" },
  rh_BA_DT: { value: "", operation: "" },
  rh_PA_NO: { value: "", operation: "" },
  rh_RC_AM: { value: "", operation: "" },
  rh_OP_AM: { value: "", operation: "" },
  rh_CR_CO: { value: "", operation: "" },
  rh_CR_MO: { value: "", operation: "" },
  rh_CR_RT: { value: "", operation: "" },
  rh_RC_FR: { value: "", operation: "" },
  rh_OP_FR: { value: "", operation: "" },
  rh_RM_EX: { value: "", operation: "" },
  rh_VO_DT: { value: "", operation: "" },
  rh_VO_FL: { value: "", operation: "" },
};

const PostReceiptsMain = () => {
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

// dateformat
const formatDate = (inputDate) => {
  if (!inputDate) {
    return inputDate;
  }
  const dateString = inputDate.toString();
  if (dateString.length !== 8) {
    return dateString;
  }
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);
  return `${day}/${month}/${year}`;
};

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedemEMNOE, setSelectedemEMNOE] = useState("");
  const [SelectedemEMNOA, setSelectedemEMNOA] = useState("");
  const [rc_CO_TY, setrc_CO_TY] = useState("");
  const [rc_CO_LV, setrc_CO_LV] = useState("");
  const [rc_CS_PR, setrc_CS_PR] = useState("");
  const [rc_GR_DE, setrc_GR_DE] = useState("");
  const [rh_CR_CO, setrh_CR_CO] = useState("");
  const [rc_DL_TY, setrc_DL_TY] = useState("");
  const [rc_TR_RE, setrc_TR_RE] = useState("");
  const [PostReceiptstable, setPostReceiptstable] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setPostReceiptstable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setrc_CO_TY(null);
    setrh_CR_CO(null);
    setrc_CO_LV(null);
    setrc_CS_PR(null);
    setrc_GR_DE(null);
    setrc_DL_TY(null);
    setrc_TR_RE(null);
    setOpen(true);
  };

  const handleClose = () => {
    setPostReceiptstable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setrc_CO_TY(null);
    setrh_CR_CO(null);
    setrc_CO_LV(null);
    setrc_CS_PR(null);
    setrc_GR_DE(null);
    setrc_DL_TY(null);
    setrc_TR_RE(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleSearch();
    }
  };
  const addRow = () => {
    setPostReceiptstable([...PostReceiptstable, {}]);
  };

  const deleteRow = () => {
    if (PostReceiptstable.length > 0) {
      const updatedData = [...PostReceiptstable];
      updatedData.pop();
      setPostReceiptstable(updatedData);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/PostReceipts/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const PostReceiptsData = response.data;
          delete PostReceiptsData.rh_UK_NO;
          setFormData(PostReceiptsData);
          setSelectedemEMNO(PostReceiptsData.ad_AD_NM); //ad_EM_NM
          setrc_CO_TY(PostReceiptsData.rc_CO_TY);
          setrc_CO_LV(PostReceiptsData.rc_CO_LV);
          setrc_CS_PR(PostReceiptsData.rc_CS_PR);
          setrc_GR_DE(PostReceiptsData.rc_GR_DE);
          setrc_DL_TY(PostReceiptsData.rc_DL_TY);
          setrc_TR_RE(PostReceiptsData.rc_TR_RE);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleSearch();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PostReceipts/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrc_CO_LV(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrc_CS_PR(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrc_GR_DE(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrc_DL_TY(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrc_TR_RE(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/PostReceipts/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const PostReceiptsData = response.data;
        setPostReceiptstable(PostReceiptsData.receiptDetailsList);
        setFormData(PostReceiptsData.receiptsEntry);
        setSelectedemEMNO(response.data.ad_AD_NM); 
        setSelectedemEMNO(PostReceiptsData.receiptsEntry.rh_CU_NO); 
        setSelectedemEMNOA(PostReceiptsData.receiptsEntry.accountDes); //ad_EM_NM
        setSelectedemEMNOE(PostReceiptsData.receiptsEntry.name);
        setrh_CR_CO(PostReceiptsData.receiptsEntry.rh_CR_CO);
        setrc_CO_TY(response.data.rc_CO_TY);
        setrc_CO_LV(response.data.rc_CO_LV);
        setrc_CS_PR(response.data.rc_CS_PR);
        setrc_GR_DE(response.data.rc_GR_DE);
        setrc_DL_TY(response.data.rc_DL_TY);
        setrc_TR_RE(response.data.rc_TR_RE);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleFormSubmit = async () => {
    const Url1 = `${domain}/api/PostReceipts/PostReceiptsToGAGL`;
    const Url2 = `${domain}/api/PostReceipts/PostReceiptsToGABL`;
    const request = {
      "receiptsEntry":{
        "rh_UK_NO": FormData.rh_UK_NO,
      }
    };
  
    try {
      const response1 = await axios.post(Url1, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      handleResponse(response1.data);

      const response2 = await axios.post(Url2, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      handleResponse(response2.data);
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };
  

  const buttonStyles = {
    backgroundColor: "#ff0010a3",
    color: "white",
    lineHeight: "10%",
  };
  const searchButtonStyles = {
    backgroundColor: "#3d33d9a6",
    color: "white",
    lineHeight: "10%",
  };
  
  const addButtonStyles = {
    backgroundColor: "#2e8b57ab",
    color: "white",
    lineHeight: "10%",
  };
  
  const copyButtonStyles = {
    backgroundColor: "#00c0ff8f",
    color: "white",
    lineHeight: "10%",
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
     
    </React.Fragment>
  );

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(0);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width:"0%",

  };
  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    width:"0%",
  };

  const inputStyle = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
  };
  const checkboxStyle = {
    border: "6px solid #a7d6ff8c",
    borderRadius: "4px",
    width: "100%",
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };



  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_DO_CO"] ? filters["rh_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_DO_CO",
                        e.target.value,
                        filters["rh_DO_CO"]
                          ? filters["rh_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_CU_NO"] ? filters["rh_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_CU_NO",
                        e.target.value,
                        filters["rh_CU_NO"]
                          ? filters["rh_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_PY_NO"] ? filters["rh_PY_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_PY_NO",
                        e.target.value,
                        filters["rh_PY_NO"]
                          ? filters["rh_PY_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["rh_RC_DT"] ? filters["rh_RC_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_RC_DT",
                        e.target.value,
                        filters["rh_RC_DT"]
                          ? filters["rh_RC_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_GL_DT"] ? filters["rh_GL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_GL_DT",
                        e.target.value,
                        filters["rh_GL_DT"]
                          ? filters["rh_GL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rhPyId"] ? filters["rhPyId"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rhPyId",
                        e.target.value,
                        filters["rhPyId"]
                          ? filters["rhPyId"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_RC_NO"] ? filters["rh_RC_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_RC_NO",
                        e.target.value,
                        filters["rh_RC_NO"]
                          ? filters["rh_RC_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_GL_PO"] ? filters["rh_GL_PO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_GL_PO",
                        e.target.value,
                        filters["rh_GL_PO"]
                          ? filters["rh_GL_PO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_GL_CO"] ? filters["rh_GL_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_GL_CO",
                        e.target.value,
                        filters["rh_GL_CO"]
                          ? filters["rh_GL_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_BA_TY"] ? filters["rh_BA_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_BA_TY",
                        e.target.value,
                        filters["rh_BA_TY"]
                          ? filters["rh_BA_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_BA_NO"] ? filters["rh_BA_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_BA_NO",
                        e.target.value,
                        filters["rh_BA_NO"]
                          ? filters["rh_BA_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_BA_DT"] ? filters["rh_BA_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_BA_DT",
                        e.target.value,
                        filters["rh_BA_DT"]
                          ? filters["rh_BA_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_PA_NO"] ? filters["rh_PA_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_PA_NO",
                        e.target.value,
                        filters["rh_PA_NO"]
                          ? filters["rh_PA_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_RC_AM"] ? filters["rh_RC_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_RC_AM",
                        e.target.value,
                        filters["rh_RC_AM"]
                          ? filters["rh_RC_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_OP_AM"] ? filters["rh_OP_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_OP_AM",
                        e.target.value,
                        filters["rh_OP_AM"]
                          ? filters["rh_OP_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_CR_CO"] ? filters["rh_CR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_CR_CO",
                        e.target.value,
                        filters["rh_CR_CO"]
                          ? filters["rh_CR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_CR_MO"] ? filters["rh_CR_MO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_CR_MO",
                        e.target.value,
                        filters["rh_CR_MO"]
                          ? filters["rh_CR_MO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_CR_RT"] ? filters["rh_CR_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_CR_RT",
                        e.target.value,
                        filters["rh_CR_RT"]
                          ? filters["rh_CR_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_RC_FR"] ? filters["rh_RC_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_RC_FR",
                        e.target.value,
                        filters["rh_RC_FR"]
                          ? filters["rh_RC_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_OP_FR"] ? filters["rh_OP_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_OP_FR",
                        e.target.value,
                        filters["rh_OP_FR"]
                          ? filters["rh_OP_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_GL_BA"] ? filters["rh_GL_BA"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_GL_BA",
                        e.target.value,
                        filters["rh_GL_BA"]
                          ? filters["rh_GL_BA"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_RM_EX"] ? filters["rh_RM_EX"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_RM_EX",
                        e.target.value,
                        filters["rh_RM_EX"]
                          ? filters["rh_RM_EX"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_VO_DT"] ? filters["rh_VO_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_VO_DT",
                        e.target.value,
                        filters["rh_VO_DT"]
                          ? filters["rh_VO_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_VO_FL"] ? filters["rh_VO_FL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_VO_FL",
                        e.target.value,
                        filters["rh_VO_FL"]
                          ? filters["rh_VO_FL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Customer No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Payor Number</th>
                <th style={tableHeaderStyle}>Receipt Date</th>
                <th style={tableHeaderStyle}>GL Date</th>
                <th style={tableHeaderStyle}>Payment Id</th>
                <th style={tableHeaderStyle}>Receipt No</th>
                <th style={tableHeaderStyle}>G/L Post Code</th>
                <th style={tableHeaderStyle}>Unapplied GL Code</th>
                <th style={tableHeaderStyle}>Batch Type</th>
                <th style={tableHeaderStyle}>Batch No</th>
                <th style={tableHeaderStyle}>Batch Date</th>
                <th style={tableHeaderStyle}>Parent No</th>
                <th style={tableHeaderStyle}>Receipt Amount</th>
                <th style={tableHeaderStyle}>Unapplied Amount</th>
                <th style={tableHeaderStyle}>Currency Mode</th>
                <th style={tableHeaderStyle}>Currency Code</th>
                <th style={tableHeaderStyle}>Exchange Rate</th>
                <th style={tableHeaderStyle}>Foreign Receipt Amount</th>
                <th style={tableHeaderStyle}>Foreign Unapplied Amount</th>
                <th style={tableHeaderStyle}>Bank Account</th>
                <th style={tableHeaderStyle}>Remarks</th>
                <th style={tableHeaderStyle}>Void Date</th>
                <th style={tableHeaderStyle}>Void Flag</th>
                
           
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.rh_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.rh_UK_NO}
                        checked={selectedRows.includes(rowData.rh_UK_NO)}
                        onChange={() => handleRowSelect(rowData.rh_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.rh_DO_CO}</td>
                    <td style={tableCellStyle}>{rowData.rh_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{rowData.rh_PY_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.rh_RC_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.rh_GL_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.rhPyId}</td>
                    <td style={tableCellStyle}>{rowData.rh_RC_NO}</td>
                    <td style={tableCellStyle}>{rowData.rh_GL_PO}</td>
                    <td style={tableCellStyle}>{rowData.rh_GL_CO}</td>
                    <td style={tableCellStyle}>{rowData.rh_BA_TY}</td>
                    <td style={tableCellStyle}>{rowData.rh_BA_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.rh_BA_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.rh_PA_NO}</td>
                    <td style={tableCellStyle}>{rowData.rh_RC_AM}</td>
                    <td style={tableCellStyle}>{rowData.rh_OP_AM}</td>
                    <td style={tableCellStyle}>{rowData.rh_CR_CO}</td>
                    <td style={tableCellStyle}>{rowData.rh_CR_MO}</td>
                    <td style={tableCellStyle}>{rowData.rh_CR_RT}</td>
                    <td style={tableCellStyle}>{rowData.rh_RC_FR}</td>
                    <td style={tableCellStyle}>{rowData.rh_OP_FR}</td>
                    <td style={tableCellStyle}>{rowData.rh_GL_BA}</td>
                    <td style={tableCellStyle}>{rowData.rh_RM_EX}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.rh_VO_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.rh_VO_FL}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="Pagination" style={{ marginLeft: "85%" }}>
          <span
            onClick={handleFirstPage}
            style={{
              fontSize: "200%",
              cursor: "pointer",
              whiteSpace: "pre-wrap",
              whiteSpace: "pre-wrap",
            }}
          >
            &laquo;{" "}
          </span>{" "}
          <span
            onClick={handlePreviousPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Prev{" "}
          </span>{" "}
          <span style={{ whiteSpace: "pre-wrap", fontSize: "111%" }}> </span>{" "}
          <span
            onClick={handleNextPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Next{" "}
          </span>{" "}
          <span
            onClick={handleLastPage}
            style={{ fontSize: "200%", cursor: "pointer" }}
          >
            &raquo;
          </span>
        </div>
      </div>

      <PostReceiptsDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        rc_CO_TY={rc_CO_TY}
        rh_CR_CO={rh_CR_CO}
        rc_CO_LV={rc_CO_LV}
        rc_CS_PR={rc_CS_PR}
        rc_GR_DE={rc_GR_DE}
        rc_DL_TY={rc_DL_TY}
        rc_TR_RE={rc_TR_RE}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedemEMNOA={SelectedemEMNOA}
        PostReceiptstable={PostReceiptstable} 
        SelectedemEMNOE={SelectedemEMNOE}
        setSelectedemEMNOE={setSelectedemEMNOE}
        setSelectedemEMNOA={setSelectedemEMNOA}
        addRow={addRow} 
        deleteRow={deleteRow} 
  
      />
    </div>
  );
};

export default PostReceiptsMain;
