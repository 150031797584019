import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ApplicationInformationDialog from "./ApplicationInformationDialog";
import axios from "axios";
import saveAs from "file-saver";
import moment from "moment";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  ai_RQ_TY: "",
  ai_AP_TY: "",
  ai_AP_NM : "",
  ai_AP_BY:"",
  ai_CO_CT:"",
  ai_AP_CO:"",
  ai_RQ_NO:"",
  ai_RQ_CO:"",
  ai_AP_NO:"",
  aiUkNo:"",
  ai_ST_CD:"",
  ai_AP_DT:"",
  ai_AV_DT:"",
  ai_JB_PO:"",
  ai_EX_SL:"",
  ai_TR_CR:"",
  ai_JB_TY:"",
  add_NAME:"",
  applicant_NAME:"",
  ai_NX_AC:"",
  ai_AI_LI:"",
  ai_AI_SO:"",
};

const initialFilters = {
  ai_AP_NO: { value: "", operation: "" },
  ai_CO_CT: { value: "", operation: "" },
  ai_AP_DT: { value: "", operation: "" },
  ai_TR_CR: { value: "", operation: "" },
  ai_ST_CD: { value: "", operation: "" },
  ai_JB_PO: { value: "", operation: "" },
  ai_JB_TY: { value: "", operation: "" },
  ai_EX_SL: { value: "", operation: "" },
  ai_AV_DT: { value: "", operation: "" },

};

const ApplicationInformationMain = () => {
      // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,
  checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,postStyles
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [interviewerDES, setinterviewerDES] = useState("");
  const [ai_CO_CT, setai_CO_CT] = useState("");
  const [ai_ST_CD, setai_ST_CD] = useState([]);
  const [ai_JB_PO, setai_JB_PO] = useState([]);
  const [ai_JB_TY, setai_JB_TY] = useState([]);
  const [ai_TR_CR, setai_TR_CR] = useState([]);
  const [ai_AP_NO, setai_AP_NO] = useState([]);
  const [ai_AP_BY, setai_AP_BY] = useState([]);
  const [ai_AP_CO, setai_AP_CO] = useState([]);
  const [ai_AI_SO, setai_AI_SO] = useState([]);
  const [ai_AI_LI, setai_AI_LI] = useState([]);
  const [ai_NX_AC, setai_NX_AC] = useState([]);
  const [ApplicationtableData, setApplicationtableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

  const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
  setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setai_CO_CT(null);
    setai_ST_CD(null);
    setai_JB_PO(null);
    setai_JB_TY(null);
    setai_TR_CR(null);   
    setai_AP_BY(null); 
    setai_AP_NO(null);
    setai_AI_SO(null);
    setai_NX_AC(null);
    setinterviewerDES(null);
    setApplicationtableData([{}]);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setai_CO_CT(null);
    setai_ST_CD(null);
    setai_JB_PO(null);
    setai_JB_TY(null); 
    setai_TR_CR(null);    
    setai_AP_BY(null);
    setai_AP_NO(null);
    setOpen(false);
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/applicationInformation/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const ApplicationInfoData = response.data;
          delete ApplicationInfoData.aiUkNo;
          delete ApplicationInfoData.ai_AP_NO;
          setFormData(ApplicationInfoData);
          setSelectedemEMNO(ApplicationInfoData.ad_EM_NM);
          setai_CO_CT(ApplicationInfoData.ai_CO_CT);
          setai_ST_CD(ApplicationInfoData.ai_ST_CD);
          setai_JB_PO(ApplicationInfoData.ai_JB_PO);
          setai_JB_TY(ApplicationInfoData.ai_JB_TY);
          setai_TR_CR(ApplicationInfoData.ai_TR_CR);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            setai_AP_CO(`${company}`);
            if(fName === "AI_AP_TY"){
              setai_AP_TY(dataDefault.versionIds[i].default_VALUE)
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/applicationInformation/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value) => {
    // setFormData({ ...FormData, [name]: value });
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setai_CO_CT(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setai_ST_CD(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setai_JB_PO(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setai_JB_TY(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setai_TR_CR(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setai_AI_SO(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setai_AI_LI(value);
  };
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setai_NX_AC(value);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/applicationInformation/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ApplicationInformationData = response.data;
        console.log('ApplicationInformationData:', ApplicationInformationData);
        const appInfo = ApplicationInformationData.applicationInformationList[0];     
        setApplicationtableData(ApplicationInformationData.applicationInformationList);        
        setFormData(appInfo);
        setSelectedemEMNO(ApplicationInformationData.ap_NAME);
        setinterviewerDES(appInfo.interviewerDES);
        setai_CO_CT(ApplicationInformationData.ai_CO_CT);
        setai_ST_CD(ApplicationInformationData.ai_ST_CD);
        setai_JB_PO(ApplicationInformationData.ai_JB_PO);
        setai_JB_TY(ApplicationInformationData.ai_JB_TY);
        setai_TR_CR(ApplicationInformationData.ai_TR_CR);
        setai_AP_NO(ApplicationInformationData.ai_AP_NO);
        setai_AP_BY(ApplicationInformationData.ai_AP_BY);
        setai_AI_SO(ApplicationInformationData.ai_AI_SO);
        setai_AI_LI(ApplicationInformationData.ai_AI_LI.toString());
        // onchangeFormData("ai_AI_LI",ApplicationInformationData.applicationInformationList[0].ai_AI_LI);
        setai_NX_AC(ApplicationInformationData.ai_NX_AC);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values() {
    const table = document.getElementById("Applicationtable");
    const totalRowCount = table.tBodies[0].rows.length;
    const applicationInformationList = [];
    for (let i = 0; i < totalRowCount; i++) {
      const IN_DT = document.getElementsByName("ai_IN_DT_" + i)[0]; 
      const Details = {
        aiUkNo: document.getElementById("aiUkNo_" + i).value,
        ai_IN_DT: IN_DT.value?moment(IN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        ai_AI_LI: table.tBodies[0].rows[i].cells[2].children[0].children[1].children[0].value,
        ai_IN_VW: document.getElementById("ai_IN_VW_" + i).value,
        ai_NX_AC: table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
        ai_RE_MK: document.getElementById("ai_RE_MK_" + i).value,
    };
    applicationInformationList.push(Details);
  }

    requestBodyInput = {
      applicationInformationList,
      aiUkNo: FormData.aiUkNo,
      ai_AP_NO: document.getElementById("ai_AP_NO").value,
      ai_AP_NM: FormData.ai_AP_NM,
      ai_AP_DT: FormData.ai_AP_DT,
      ai_AP_CO: company,
      ai_RQ_NO: document.getElementById("ai_RQ_NO").value,
      ai_RQ_TY: document.getElementById("ai_RQ_TY").value,
      ai_AP_TY: document.getElementById("ai_AP_TY").value,
      ai_CO_CT: FormData.ai_CO_CT,
      ai_JB_TY: FormData.ai_JB_TY,
      ai_JB_PO: FormData.ai_JB_PO,
      ai_AV_DT: FormData.ai_AV_DT,
      ai_EX_SL: FormData.ai_EX_SL,
      ai_TR_CR: FormData.ai_TR_CR,
      ai_AI_SO: FormData.ai_AI_SO,
      ai_AP_BY: document.getElementById("ai_AP_BY").value,
      ai_ST_CD: FormData.ai_ST_CD,
    };
  };
  const [ai_AP_TY, setai_AP_TY] =useState("");

  const handleFormSubmit = () => {
    values();
    if (FormData.ai_AP_NO) {
      if (FormData.aiUkNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/applicationInformation/Update`;
        if (confirm) {
          axios
            .put(update, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        axios.post(`${domain}/api/applicationInformation/Add`, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    } else {
      const urlNextNumber = `${domain}/api/BaseNumber/All`;
      const parameters = `${ai_AP_TY}/${company}`;
      const finallink = `${urlNextNumber}/${parameters}`;
      axios.get(finallink, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          setai_AP_NO(resp.data.bn_BA_NO);
          FormData.ai_AP_NO = resp.data.bn_BA_NO;
          if(resp.data.bn_BA_NO ===undefined)
          {
            handleResponse(resp.data);
          }else{
            values();
            axios.post(`${domain}/api/applicationInformation/Add`, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error adding data:", error);
            });
          }
         
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
   
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" , backgroundColor: "#1ed01e", border: "none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ai_AP_NO"] ? filters["ai_AP_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ai_AP_NO",
                        e.target.value,
                        filters["ai_AP_NO"]
                          ? filters["ai_AP_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ai_AP_NM"] ? filters["ai_AP_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ai_AP_NM",
                        e.target.value,
                        filters["ai_AP_NM"]
                          ? filters["ai_AP_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ai_AP_DT"] ? filters["ai_AP_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ai_AP_DT",
                        e.target.value,
                        filters["ai_AP_DT"] ? filters["ai_AP_DT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ai_ST_CD"] ? filters["ai_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ai_ST_CD",
                        e.target.value,
                        filters["ai_ST_CD"]
                          ? filters["ai_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["position"] ? filters["position"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "position",
                        e.target.value,
                        filters["position"]
                          ? filters["position"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["type"] ? filters["type"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "type",
                        e.target.value,
                        filters["type"]
                          ? filters["type"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ai_AV_DT"] ? filters["ai_AV_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ai_AV_DT",
                        e.target.value,
                        filters["ai_AV_DT"]
                          ? filters["ai_AV_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ai_EX_SL"] ? filters["ai_EX_SL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ai_EX_SL",
                        e.target.value,
                        filters["ai_EX_SL"]
                          ? filters["ai_EX_SL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ai_TR_CR"] ? filters["ai_TR_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ai_TR_CR",
                        e.target.value,
                        filters["ai_TR_CR"]
                          ? filters["ai_TR_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
               
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Applicant No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Date</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Position</th>
                <th style={tableHeaderStyle}>Job Type</th>
                <th style={tableHeaderStyle}>Date Available</th>
                <th style={tableHeaderStyle}>Expected Salary</th>
                <th style={tableHeaderStyle}>Currency</th>         
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.aiUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.aiUkNo}
                        checked={selectedRows.includes(rowData.aiUkNo)}
                        onChange={() => handleRowSelect(rowData.aiUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ai_AP_NO}</td>
                    <td style={tableCellStyle}>{rowData.ai_AP_NM}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.ai_AP_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.status}</td>
                    <td style={tableCellStyle}>{rowData.position}</td>
                    <td style={tableCellStyle}>{rowData.type}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.ai_AV_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.ai_EX_SL}</td>
                    <td style={tableCellStyle}>{rowData.ai_TR_CR}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
 {/* pagination */}
 <Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      setCurrentPage={setCurrentPage}
    />
      </div>

      <ApplicationInformationDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        onchangeDropDownFormData8={onchangeDropDownFormData8}
        ai_CO_CT={ai_CO_CT}
        ai_ST_CD={ai_ST_CD}
        ai_JB_PO={ai_JB_PO}
        ai_JB_TY={ai_JB_TY}
        ai_TR_CR={ai_TR_CR}
        ai_AP_NO={ai_AP_NO}
        ai_AP_BY={ai_AP_BY}
        ai_AP_CO={ai_AP_CO}
        ai_AI_SO={ai_AI_SO}
        ai_AP_TY={ai_AP_TY}
        ai_AI_LI={ai_AI_LI}
        ai_NX_AC={ai_NX_AC}
        interviewerDES={interviewerDES}
        setinterviewerDES={setinterviewerDES}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        ApplicationtableData={ApplicationtableData}
        setApplicationtableData={setApplicationtableData}
      />
    </div>
  );
};

export default ApplicationInformationMain;
