import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import ButtonStyles from "views/common/ButtonStyles";
export default function PropertyInformationDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  SelectedemEMNO,
  setSelectedemEMNO,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData3,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  pi_CO_NO,
  setFormData,
}) {
  const {
    piUkNo,
    pi_PR_NO,
    pi_FA_AD,
    pi_TX_RT,
    pi_TX_AR,
    pi_PR_AR,
    pi_AD_DS,
    pi_TR_QT,
    pi_ST_DT,
    pi_EN_DT,
    pi_TR_UM,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [Addresstype, setAddresstype] = useState("F");
  const [pi_PR_NOOptions, setpi_PR_NOOptions] = useState([]);
  const [pi_TX_AROptions, setpi_TX_AROptions] = useState([]);
  const [pi_TX_RTOptions, setpi_TX_RTOptions] = useState([]);
  const [pi_PR_AROptions, setpi_PR_AROptions] = useState([]);
  const [pi_TR_UMOptions, setpi_TR_UMOptions] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const { fetchCostCenter,fetchCostCenterP } = Costcenter();
  useEffect(() => {
    fetchDropdownCost();
    fetchDropdownAQ();
    fetchDropdownLN();
    fetchDropdownLG();
    fetchDropdownDM();
  }, []);
  const fetchDropdownCost = async () => {
    const Options = await fetchCostCenterP("PT");
    setpi_PR_NOOptions(Options);
  };
  // new dropdowns 
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const fetchDropdownAQ = async () => {
    const Options = await fetchClassCodeDS("FO/TA");
    setpi_TX_AROptions(Options);
  };
  const fetchDropdownLN = async () => {
    const Options = await fetchClassCodeDS("TX/RT");
    setpi_TX_RTOptions(Options);
  };
  const fetchDropdownLG = async () => {
    const Options = await fetchClassCodeDS("BL/AR");
    setpi_PR_AROptions(Options);
  };
  const fetchDropdownDM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setpi_TR_UMOptions(Options);
  };
  const [FacilityAddressPopupVisible, setFacilityAddressPopupVisible] = useState(false);
  const openCustomerPopup = () => {
    setFacilityAddressPopupVisible(true);
  };
  //  startsearchpopup
  const handleDataSelect = (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("pi_FA_AD", data.abAdNo);
    setFacilityAddressPopupVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(piUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{ piUkNo ? "Revise Property Information" : "Add  Property Information"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div  style={inboxstyle} >
          <div className="formgrid grid">
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pi_PR_NO" className="font-bold">
                Property
              </label>
              <Dropdown
                name="pi_PR_NO"
                value={pi_PR_NO}
                options={pi_PR_NOOptions}
                onChange={(e) => onchangeDropDownFormData1("pi_PR_NO", e.value) }
                placeholder="Select Property"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pi_CO_NO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="pi_CO_NO"
                name="pi_CO_NO"
                value={pi_CO_NO}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pi_FA_AD" className="font-bold">
                Facility Address <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedemEMNO}
                />
                  {showSearchIcon && (
                <i  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openCustomerPopup}
                ></i>
                  )}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="pi_FA_AD"
                  name="pi_FA_AD"
                  value={pi_FA_AD}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pi_TX_RT" className="font-bold">
                Tax Rate
              </label>
              <Dropdown
                id="pi_TX_RT"
                name="pi_TX_RT"
                value={pi_TX_RT}
                options={pi_TX_RTOptions}
                onChange={(e) => onchangeDropDownFormData3("pi_TX_RT", e.value) }
                placeholder="Select Tax Rate"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pi_TX_AR" className="font-bold">
                Tax Area
              </label>
              <Dropdown
                id="pi_TX_AR"
                name="pi_TX_AR"
                value={pi_TX_AR}
                options={pi_TX_AROptions}
                onChange={(e) => onchangeDropDownFormData3("pi_TX_AR", e.value) }
                placeholder="Select  Tax Area"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pi_PR_AR" className="font-bold">
                Area type
              </label>
              <Dropdown
                id="pi_PR_AR"
                name="pi_PR_AR"
                value={pi_PR_AR}
                options={pi_PR_AROptions}
                onChange={(e) => onchangeDropDownFormData4("pi_PR_AR", e.value) }
                placeholder="Select Area type"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pi_AD_DS" className="font-bold">
                Description
              </label>
              <InputText
                id="pi_AD_DS"
                name="pi_AD_DS"
                value={pi_AD_DS}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pi_TR_QT" className="font-bold">
                Area
              </label>
              <InputText
                id="pi_TR_QT"
                name="pi_TR_QT"
                value={pi_TR_QT}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pi_TR_UM" className="font-bold">
                UM
              </label>
              <Dropdown
                id="pi_TR_UM"
                name="pi_TR_UM"
                value={pi_TR_UM}
                options={pi_TR_UMOptions}
                onChange={(e) =>  onchangeDropDownFormData5("pi_TR_UM", e.value) }
                placeholder="Select UM"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pi_ST_DT" className="font-bold">
                Start Date
              </label>
              <Calendar
                  id="pi_ST_DT"
                  name="pi_ST_DT"
                  value={moment(data.pi_ST_DT, "YYYYMMDD").isValid() ? moment(data.pi_ST_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "pi_ST_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pi_EN_DT" className="font-bold">
                End Date
              </label>
              <Calendar
                  id="pi_EN_DT"
                  name="pi_EN_DT"
                  value={moment(data.pi_EN_DT, "YYYYMMDD").isValid() ? moment(data.pi_EN_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "pi_EN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
            </div>
          </div>
        </div>
      </div>
      <AddressBookPopup
        visible={FacilityAddressPopupVisible}
        onClose={() => setFacilityAddressPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={Addresstype}
        setFormData= {setFormData}
      />
    </Dialog>
  );
}
