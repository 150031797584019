import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function TenantWorkOrderCostDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  // onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  addRow,
  deleteRow,
  TenantWorkOrderCosttable,
}) {
  const { 
    wcUkNo,
  wc_LE_NO,
  wc_AC_HR,
  wc_OR_NO,
  wc_TE_NT,
  wc_BL_NO,
  wc_UN_TS,
  wc_CO_CT,
  wc_ES_HR,
  wc_ES_AM,
  wc_AC_AM,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,
    savestyle,cancelstyle,confirmstyle,Dialogstyle} = ButtonStyles();

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "rc_ST_DT"  || name === "rc_EN_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);
  
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };
  const { fetchClassCodeDS} = ClassCode();
  const [wc_DO_TYOptions, setwc_DO_TYOptions] = useState([]);
  useEffect(() => {
    fetchTYPE();
  }, []);
  const fetchTYPE = async () => {
    const Options = await fetchClassCodeDS("FO/DT");
    setwc_DO_TYOptions(Options);
  };
  const isUpdateMode = Boolean(wcUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{wcUkNo ? "Revise Tenant Work Order Cost" : "Add Tenant Work Order Cost"}</h5>
        <div style={{ display:'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end',}}>
          <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
            <Button
              style={savestyle}
              icon="pi pi-check"
              onClick={handleFormSubmit} 
              tooltip={isUpdateMode ? "Update" : "Submit"}
            />
            <Button
              style={cancelstyle}
              icon="pi pi-times"
              onClick={handleClose}
              tooltip="Cancel"
            />
        </div>
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_OR_NO" className="font-bold">
                Work Order
                </label>
                <InputText
                  id="wc_OR_NO"
                  name="wc_OR_NO"
                  value={wc_OR_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_CO_CT" className="font-bold">
                  Cost Centre
                </label>
                <InputText
                  id="wc_CO_CT"
                  name="wc_CO_CT"
                  value={wc_CO_CT}
                  onChange={(e) => onchangedata(e)}
                />
              </div> 
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_TE_NT" className="font-bold">
                    Tenant
                  </label>
                  <InputText
                    id="wc_TE_NT"
                    name="wc_TE_NT"
                    value={wc_TE_NT}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>    
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_TR_DT" className="font-bold">
                  Trans Date
                </label>
                <Calendar
                  id="wc_TR_DT"
                  name="wc_TR_DT"
                  value={moment(data.wc_TR_DT, "YYYYMMDD").isValid() ? moment(data.wc_TR_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "wc_TR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
                </div>    
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_LE_NO" className="font-bold">
                Lease Number
                </label>
                <InputText
                  id="wc_LE_NO"
                  name="wc_LE_NO"
                  value={wc_LE_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_GL_DT" className="font-bold">
                  GL Date
                </label>
                <Calendar
                  id="wc_GL_DT"
                  name="wc_GL_DT"
                  value={moment(data.wc_GL_DT, "YYYYMMDD").isValid() ? moment(data.wc_GL_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "wc_GL_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </div>    
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_BL_NO" className="font-bold">
                Building
                </label>
                <InputText
                  id="wc_BL_NO"
                  name="wc_BL_NO"
                  value={wc_BL_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_UN_TS" className="font-bold">
                    Unit
                  </label>
                  <InputText
                    id="wc_UN_TS"
                    name="wc_UN_TS"
                    value={wc_UN_TS}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>   
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_ES_HR" className="font-bold">
                  Estimated Hours
                  </label>
                  <InputText
                    id="wc_ES_HR"
                    name="wc_ES_HR"
                    value={wc_ES_HR}
                    onChange={(e) => onchangedata(e)}
                  />
                    </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wc_AC_HR" className="font-bold">
                      Actual Hours
                    </label>
                    <InputText
                      id="wc_AC_HR"
                      name="wc_AC_HR"
                      value={wc_AC_HR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>    
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wc_ES_AM" className="font-bold">
                    Estimated Amount
                    </label>
                    <InputText
                      id="wc_ES_AM"
                      name="wc_ES_AM"
                      value={wc_ES_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                    </div>
                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wc_AC_AM" className="font-bold">
                    Actual Amount
                    </label>
                    <InputText
                      id="wc_AC_AM"
                      name="wc_AC_AM"
                      value={wc_AC_AM}
                      onChange={(e) => onchangedata(e)}
                    />
            </div>    
          </div>
        </div>
        <div style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="TenantWorkOrderCosttable" style={{overflow:"auto"}}>
            <thead>
              <tr>
                <th style={{ display: "none" }} >UK_NO</th>
                <th>Description</th>
                <th>Doc No</th>
                <th>Type</th>
                <th>Company</th>
                <th>Part Number</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Um</th>
                <th>Rate</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
            {TenantWorkOrderCosttable.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }} >
                  <InputText
                    id={`wcUkNo_${index}`}
                    name={`wcUkNo_${index}`}
                    defaultValue={row.wcUkNo}
                  />
                </td>
                <td>
                <InputText 
                  id={`wc_WO_DS_${index}`}
                  name={`wc_WO_DS_${index}`}
                  defaultValue={row.wc_WO_DS}
                  onChange={(Event) => onchangedata(Event)}
                />
                </td>
                <td>
                <InputText 
                  id={`wc_DO_NO_${index}`}
                  name={`wc_DO_NO_${index}`}
                  defaultValue={row.wc_DO_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
                </td>
                <td>
                <Dropdown
                  id={`wc_DO_TY_${index}`}
                  name={`wc_DO_TY_${index}`}
                  value={data[`wc_DO_TY_${index}`] ? data[`wc_DO_TY_${index}`]:row.wc_DO_TY }
                  options={wc_DO_TYOptions}
                  onChange={(e) => handleFormDataChange(`wc_DO_TY_${index}`, e.value)}
                  placeholder="Select Grade"
                />
                </td>
                <td>
                <InputText 
                  id={`wc_OR_CO_${index}`}
                  name={`wc_OR_CO_${index}`}
                  defaultValue={row.wc_OR_CO}
                  onChange={(Event) => onchangedata(Event)}
                />
                </td>
                <td>
                <InputText 
                  id={`wc_PA_NO_${index}`}
                  name={`wc_PA_NO_${index}`}
                  defaultValue={row.wc_PA_NO}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText 
                  id={`wc_PA_DS_${index}`}
                  name={`wc_PA_DS_${index}`}
                  defaultValue={row.wc_PA_DS}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText 
                  id={`wc_TR_QT_${index}`}
                  name={`wc_TR_QT_${index}`}
                  defaultValue={row.wc_TR_QT}
                  onChange={(Event) => onchangedata(Event)}
                />
                </td>
                <td>
                <InputText 
                  id={`wc_TR_UM_${index}`}
                  name={`wc_TR_UM_${index}`}
                  defaultValue={row.wc_TR_UM}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText 
                  id={`wc_UN_RT_${index}`}
                  name={`wc_UN_RT_${index}`}
                  defaultValue={row.wc_UN_RT}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText 
                  id={`wc_EX_CT_${index}`}
                  name={`wc_EX_CT_${index}`}
                  defaultValue={row.wc_EX_CT}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                </tr>
                ))}
            </tbody>
        </table>
      </div>
        </div> 
      </div>
    </Dialog>
  );
}
