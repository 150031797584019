import axios from "axios";

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const PayTerm = () => {

  const fetchPayTerm = async () => {
    const url = `${domain}/api/PaymentTerm/GetAll`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      return response.data.map((item) => ({
        label: item.pt_PY_DS,
        value: item.pt_PY_CO,
      }));
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
      return [];
    }
  };

  return {fetchPayTerm};
  };

export default PayTerm;