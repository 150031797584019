import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import SupplierPopup from "./SupplierPopup";
import moment from "moment";
import axios from "axios";
export default function PostVouchersDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  // onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData4,
  addRow,
  deleteRow,
  PostVoucherstable,
  SelectedemEMNO,

  setsl_CO_NM,
  setSelectedemEMNO,
  SelectedemEMNOA,
  setSelectedemEMNOA,
  SelectedemEMNOE,
  setSelectedemEMNOE,
}) {
  const { 
  sl_UK_NO,
  slDoNo,
  sl_DO_TY,
  sl_DO_CO,
  sl_GL_DT,
  sl_CO_NM,
  sl_IN_DT,
  sl_SU_IN,
  sl_CR_CD,
  sl_CR_RT,
  rh_GL_BA,
  rh_RM_EX,
  rc_CE_CD,
  rc_CO_CD,
  } = data;
  const [sl_CO_NMOptions, setsl_CO_NMOptions] = useState([]);
  const [sl_CR_CDOptions, setsl_CR_CDOptions] = useState([]);
  const [sl_PY_COOptions, setsl_PY_COOptions] = useState([]);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      const formattedDate1 = moment(selectedDate).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      const formattedDate1 = moment(value).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "rc_ST_DT"  || name === "rc_EN_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);
  
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };
  const urlpayterm = `${domain}/api/PaymentTerm/GetAll`;
  const finalUrl4 = `${urlpayterm}`;
  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl1 = `${urlcost}`;


  const url = `${domain}/api/ClassCode/All`;

  const parameter3 = "FO/CR";
  const finalUrl3 = `${url}/${parameter3}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
  }, []);

  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setsl_CO_NMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
    // 3rdd dropdown
    const fetchDropdownOptions3 = async () => {
      try {
        const response = await axios.get(finalUrl3, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        const options = response.data.map((item) => ({
          label: item.cl_CL_CD,
          value: item.cl_CL_CD,
        }));
        setsl_CR_CDOptions(options);
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };
    const fetchDropdownOptions4 = async () => {
      try {
        const response = await axios.get(finalUrl4, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
  
        const options = response.data.map((item) => ({
          label: item.pt_PY_DS,
          value: item.pt_PY_CO,
        }));
        setsl_PY_COOptions(options);
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };
const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
const openSupplierPopup = (e) => {
  setSupplierPopupVisible(true);
  setSelectedRowIndex(e);
};

const handleDataSelect = async (data) => {
  setSelectedemEMNO(data.abAdNo);
  setSelectedemEMNOE(data.ab_AD_NM);
  onchangeFormData("sl_SU_NO", data.abAdNo);
  onchangeFormData("name", data.ab_AD_NM);
  setSupplierPopupVisible(false);
};


const isUpdateMode = Boolean(sl_UK_NO);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={sl_UK_NO ? "Revise Post Receipts" : "Add Post Receipts"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div className="formgrid grid">
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="slDoNo" className="font-bold">
              Document No
            </label>
            <InputText disabled
              id="slDoNo"
              name="slDoNo"
              value={slDoNo}
              onChange={(e) => onchangedata(e)}
          
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_DO_TY" className="font-bold">
              Document Type
            </label>
            <InputText disabled
              id="sl_DO_TY"
              name="sl_DO_TY"
              value={sl_DO_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_DO_CO" className="font-bold">
              Document Company
            </label>
            <InputText  disabled
              id="sl_DO_CO"
              name="sl_DO_CO"
              defaultValue={sl_DO_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
        <label htmlFor="sl_GL_DT" className="font-bold">
            GL Date
        </label>
        <Calendar disabled
            id="sl_GL_DT"
            name="sl_GL_DT"
            value={moment(sl_GL_DT, "YYYYMMDD").toDate()}
            onChange={(e) => onchangedata({ target: { name: "sl_GL_DT", value: moment(e.value).format("YYYYMMDD") } })}
            showIcon
            dateFormat="dd/mm/yy"
        />
    </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sl_SU_NO" className="font-bold">
                  Supplier<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText disabled
                    id="sl_SU_NO"
                    name="sl_SU_NO"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={SelectedemEMNO}
                  />
                   {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openSupplierPopup}
                    ></i>
                   )}
              </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="name" className="font-bold">
                  Name
                  </label>
                  <InputText disabled
                    id="name"
                    name="name"
                    value={SelectedemEMNOE}
                   readOnly
                  />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
        <label htmlFor="sl_IN_DT" className="font-bold">
            Voucher Date
        </label>
         <Calendar disabled
            id="sl_IN_DT"
            name="sl_IN_DT"
            value={moment(sl_IN_DT, "YYYYMMDD").toDate()}
            onChange={(e) => onchangedata({ target: { name: "sl_IN_DT", value: moment(e.value).format("YYYYMMDD") } })}
            showIcon
            dateFormat="dd/mm/yy"
          />
    </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_CO_NM" className="font-bold">
            Cost Centre
            </label>
            <Dropdown disabled
              id="sl_CO_NM"
              name="sl_CO_NM"
              value={sl_CO_NM}
              options={sl_CO_NMOptions}
              onChange={(e) => { setsl_CO_NM(e.value);
                onchangeDropDownFormData2("sl_CO_NM", e.value); 
              }}
              placeholder="Select Cost Centre"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_CR_CD" className="font-bold">
              Currency
            </label>
            <Dropdown disabled
              id="sl_CR_CD"
              name="sl_CR_CD"
              value={sl_CR_CD}
              options={sl_CR_CDOptions}
              onChange={(e) => onchangeDropDownFormData4("sl_CR_CD", e.value)}
              placeholder="Select Currency"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_CR_RT" className="font-bold">
              Exchange Rate
            </label>
            <InputText disabled
              id="sl_CR_RT"
              name="sl_CR_RT"
              defaultValue={sl_CR_RT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_SU_IN" className="font-bold">
              Supplier Invoice
            </label>
            <InputText disabled
              id="sl_SU_IN"
              name="sl_SU_IN"
              value={sl_SU_IN}
              onChange={(e) => onchangedata(e)}
            />
          </div>  
        </div>
      </div>
      <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            marginTop: "2%",
          }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
          }}
        >
          {!isUpdateMode && (
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          )}
           {!isUpdateMode && (
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
          )}
        </div>
        <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
            position: "relative",
          }}
        >
        <table className="custom-table" id="PostVoucherstable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Pay Item</th>
          <th>Gross Amount</th>
          <th>Discount Given</th>
          <th>Foreign Gross Amount</th>
          <th>Foreign Discount Given</th>
          <th>Payee No</th>
          <th>Payee Status</th>
          <th>G/L Post Code</th>
          <th>Batch No</th>
          <th>Batch Type</th>
          <th>PO No</th>
          <th>PO Co</th>
          <th>Rel. NO</th>
          <th>Rel.Type</th>
          <th>Rel.Company</th>
          <th>Units</th>
          <th>Reference</th>
          <th>GL Code</th>
          <th>Void Flag</th>
          <th>Void Date</th>
        </tr>
      </thead>
      <tbody>
      {PostVoucherstable.map((row, index) => (
              <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
              id={`sl_UK_NO_${index}`}
              name={`sl_UK_NO_${index}`}
              defaultValue={row.sl_UK_NO}
            />
          </td>
          <td>
          <InputText  disabled style={{width:"120px"}}
            id={`sl_DO_PI_${index}`}
            name={`sl_DO_PI_${index}`}
            defaultValue={row.sl_DO_PI}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`sl_GR_AM_${index}`}
            name={`sl_GR_AM_${index}`}
            defaultValue={row.sl_GR_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`sl_DI_AM_${index}`}
            name={`sl_DI_AM_${index}`}
            defaultValue={row.sl_DI_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`sl_CR_AM_${index}`}
            name={`sl_CR_AM_${index}`}
            defaultValue={row.sl_CR_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`sl_DI_FR_${index}`}
            name={`sl_DI_FR_${index}`}
            defaultValue={row.sl_DI_FR}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`sl_PY_NO_${index}`}
            name={`sl_PY_NO_${index}`}
            defaultValue={row.sl_PY_NO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`sl_PA_ST_${index}`}
            name={`sl_PA_ST_${index}`}
            defaultValue={row.sl_PA_ST}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`sl_GL_PO_${index}`}
            name={`sl_GL_PO_${index}`}
            defaultValue={row.sl_GL_PO}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`sl_BA_NO_${index}`}
            name={`sl_BA_NO_${index}`}
            defaultValue={row.sl_BA_NO}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`sl_BA_TY_${index}`}
            name={`sl_BA_TY_${index}`}
            defaultValue={row.sl_BA_TY}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`sl_SB_LE_${index}`}
            name={`sl_SB_LE_${index}`}
            defaultValue={row.sl_SB_LE}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`sl_SB_LT_${index}`}
            name={`sl_SB_LT_${index}`}
            defaultValue={row.sl_SB_LT}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
                    <InputText disabled  style={{ width: "90px" }}
                      id={`sl_OR_NO_${index}`}
                      name={`sl_OR_NO_${index}`}
                      defaultValue={row.sl_OR_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <InputText disabled style={{ width: "90px" }}
                      id={`sl_OR_CO_${index}`}
                      name={`sl_OR_CO_${index}`}
                      defaultValue={row.sl_OR_CO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
          {/* <td>
          <InputText  disabled style={{width:"90px"}}
            id={`sl_LN_NO_${index}`}
            name={`sl_LN_NO_${index}`}
            defaultValue={row.sl_LN_NO}
            onChange={(e) => onchangedata(e)}
          />
          </td> */}
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`sl_OR_TY_${index}`}
            name={`sl_OR_TY_${index}`}
            defaultValue={row.sl_OR_TY}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`sl_UN_QT_${index}`}
            name={`sl_UN_QT_${index}`}
            defaultValue={row.sl_UN_QT}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`sl_RE_FR_${index}`}
            name={`sl_RE_FR_${index}`}
            defaultValue={row.sl_RE_FR}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`sl_GL_CO_${index}`}
            name={`sl_GL_CO_${index}`}
            defaultValue={row.sl_GL_CO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`sl_VD_FL_${index}`}
            name={`sl_VD_FL_${index}`}
            defaultValue={row.sl_VD_FL}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <Calendar  disabled style={{width:"150px"}}
            id={`sl_VD_DT_${index}`}
            name={`sl_VD_DT_${index}`}
            value={ row.sl_VD_DT ? moment(row.sl_VD_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "sl_VD_DT", value: moment(e.value).format("YYYYMMDD") } })}
            showIcon
            dateFormat="dd/mm/yy"
          />
          </td>
          
          </tr>
           ))}
      </tbody>
    </table>   
    
    </div>
  </div>   
  <SupplierPopup
        visible={SupplierPopupVisible}
        onClose={() => setSupplierPopupVisible(false)}
        formData={data}
        onDataSelect={handleDataSelect}
      />

    </Dialog>
  );
}
