import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import EquipmentMasterDialog from "./EquipmentMasterDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  emUkNo: "",
  em_EQ_NM:"",
  em_EQ_DS:"",
  em_ST_NO:"",
  em_CU_NO:"",
  em_AQ_DT:"",
  em_IN_DT:"",
  em_DS_DT:"",
  em_SE_DT:"",
  em_CN_RY:"",
  em_UN_NO:"",
  em_SR_NO:"",
  em_PA_NM:"",
  em_IT_NM:"",
  em_PD_MD:"",
  em_PD_FM:"",
  em_PL_NO:"",
  em_AD_DS:"",
  em_SE_PR:"",
  em_TE_CN:"",
  em_SA_NO:"",
  em_SR_DT:"",
  em_WE_DT:"",
  em_DE_AL:"",
  em_AS_NO:"",
  em_DS_NO:"",
	em_CC_NM:"",
  em_RG_ST:"",
  em_EQ_ST:"",
  em_CL_01:"",
  em_CL_02:"",
  em_CL_03:"",
  em_CL_04:"",
  em_CL_05:"",
  em_CL_06:"",
  em_CL_07:"",
  em_CL_08:"",
  em_CL_09:"",
  em_CL_10:"",
};

const initialFilters = {
  em_EQ_NM: { value: "", operation: "" },
  em_EQ_DS: { value: "", operation: "" },
  em_SR_NO: { value: "", operation: "" },
  em_CC_NM: { value: "", operation: "" },
  em_PD_MD: { value: "", operation: "" },
  em_PD_FM: { value: "", operation: "" },
  em_PA_NM: { value: "", operation: "" },
  em_EQ_ST: { value: "", operation: "" }, 
  em_AQ_DT: { value: "", operation: "" },
  em_IN_DT: { value: "", operation: "" },
  em_DS_DT: { value: "", operation: "" },
};

const EquipmentMasterMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  // cost centre
const onchangeDropDownFormData2 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setem_CC_NM(value);
};

// Status
const onchangeDropDownFormData3 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setem_EQ_ST(value);
};

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const [em_CC_NM, setem_CC_NM ] = useState("");
  const [em_RG_ST, setem_RG_ST ] = useState("");
  const [em_EQ_ST, setem_EQ_ST ] = useState("");
  const [em_CL_01, setem_CL_01 ] = useState("");
  const [em_CL_02, setem_CL_02 ] = useState("");
  const [em_CL_03, setem_CL_03 ] = useState("");
  const [em_CL_04, setem_CL_04 ] = useState("");
  const [em_CL_05, setem_CL_05 ] = useState("");
  const [em_CL_06, setem_CL_06 ] = useState("");
  const [em_CL_07, setem_CL_07 ] = useState("");
  const [em_CL_08, setem_CL_08 ] = useState("");
  const [em_CL_09, setem_CL_09 ] = useState("");
  const [em_CL_10, setem_CL_10 ] = useState("");
  const [em_CN_RY, setem_CN_RY ] = useState("");

  const [SelectedSiteno, setSelectedSiteno] = useState("");
  const [SelectedCustomer, setSelectedCustomer] = useState("");
  const [SelectedParent, setSelectedParent] = useState("");
  const [SelectedItem, setSelectedItem] = useState("");
  const [SelectedServiceprd, setSelectedServiceprd] = useState("");
  const [SelectedTechnician, setSelectedTechnician] = useState("");
  const [SelectedSalesprsn, setSelectedSalesprsn] = useState("");
  const [SelectedDealer, setSelectedDealer] = useState("");
  const [SelectedAccessor, setSelectedAccessor] = useState("");
  const [SelectedDistributer, setSelectedDistributer] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    // setem_CC_NM(null);
    setem_RG_ST(null);
    // setem_EQ_ST(null);
    setem_CL_01(null);
    setem_CL_02(null);
    setem_CL_03(null);
    setem_CL_04(null);
    setem_CL_05(null);
    setem_CL_06(null);
    setem_CL_07(null);
    setem_CL_08(null);
    setem_CL_09(null);
    setem_CL_10(null);
    setem_CN_RY(null);
    setSelectedSiteno(null);
    setSelectedCustomer(null);
    setSelectedParent(null);
    setSelectedItem(null);
    setSelectedServiceprd(null);
    setSelectedTechnician(null);
    setSelectedSalesprsn(null);
    setSelectedDealer(null);
    setSelectedAccessor(null);
    setSelectedDistributer(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/EquipmentMaster/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const EquipmentMasterData = response.data;
          delete EquipmentMasterData.emUkNo;
          setFormData(EquipmentMasterData);
          setem_CC_NM(EquipmentMasterData.em_CC_NM);
          setem_RG_ST(EquipmentMasterData.em_RG_ST);
          setem_EQ_ST(EquipmentMasterData.em_EQ_ST);
          setem_CL_01(EquipmentMasterData.em_CL_01);
          setem_CL_02(EquipmentMasterData.em_CL_02);
          setem_CL_03(EquipmentMasterData.em_CL_03);
          setem_CL_04(EquipmentMasterData.em_CL_04);
          setem_CL_05(EquipmentMasterData.em_CL_05);
          setem_CL_06(EquipmentMasterData.em_CL_06);
          setem_CL_07(EquipmentMasterData.em_CL_07);
          setem_CL_08(EquipmentMasterData.em_CL_08);
          setem_CL_09(EquipmentMasterData.em_CL_09);
          setem_CL_10(EquipmentMasterData.em_CL_10);
          setem_CN_RY(EquipmentMasterData.em_CN_RY);
          setSelectedSiteno(EquipmentMasterData.site_NAME);
          setSelectedCustomer(EquipmentMasterData.customer_NAME);
          setSelectedParent(EquipmentMasterData.parent_NAME);
          setSelectedItem(EquipmentMasterData.item_NAME);
          setSelectedServiceprd(EquipmentMasterData.service_PROVIDER);
          setSelectedTechnician(EquipmentMasterData.technician_NAME);
          setSelectedSalesprsn(EquipmentMasterData.sales_PERSON);
          setSelectedDealer(EquipmentMasterData.delaer_NAME);
          setSelectedAccessor(EquipmentMasterData.accessor_NAME);
          setSelectedDistributer(EquipmentMasterData.distributer_NAME);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
// const [wc_WO_CO, setwc_WO_CO] = useState("");

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "EM_CC_NM"){
              onchangeDropDownFormData2("em_CC_NM",dataDefault.versionIds[i].default_VALUE);
              setem_CC_NM(dataDefault.versionIds[i].default_VALUE);
            } 
            if(fName === "EM_EQ_ST"){
              onchangeDropDownFormData3("em_EQ_ST",dataDefault.versionIds[i].default_VALUE);
              setem_EQ_ST(dataDefault.versionIds[i].default_VALUE);
            } 
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/EquipmentMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/EquipmentMaster/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const EquipmentMasterData = response.data;
        setFormData(EquipmentMasterData);
        setem_CC_NM(EquipmentMasterData.em_CC_NM);
        setem_RG_ST(EquipmentMasterData.em_RG_ST);
        setem_EQ_ST(EquipmentMasterData.em_EQ_ST);
        setem_CL_01(EquipmentMasterData.em_CL_01);
        setem_CL_02(EquipmentMasterData.em_CL_02);
        setem_CL_03(EquipmentMasterData.em_CL_03);
        setem_CL_04(EquipmentMasterData.em_CL_04);
        setem_CL_05(EquipmentMasterData.em_CL_05);
        setem_CL_06(EquipmentMasterData.em_CL_06);
        setem_CL_07(EquipmentMasterData.em_CL_07);
        setem_CL_08(EquipmentMasterData.em_CL_08);
        setem_CL_09(EquipmentMasterData.em_CL_09);
        setem_CL_10(EquipmentMasterData.em_CL_10);
        setem_CN_RY(EquipmentMasterData.em_CN_RY);
        setem_EQ_ST(EquipmentMasterData.em_EQ_ST);
        setSelectedSiteno(EquipmentMasterData.site_NAME);
        setSelectedCustomer(EquipmentMasterData.customer_NAME);
        setSelectedParent(EquipmentMasterData.parent_NAME);
        setSelectedItem(EquipmentMasterData.item_NAME);
        setSelectedServiceprd(EquipmentMasterData.service_PROVIDER);
        setSelectedTechnician(EquipmentMasterData.technician_NAME);
        setSelectedSalesprsn(EquipmentMasterData.sales_PERSON);
        setSelectedDealer(EquipmentMasterData.delaer_NAME);
        setSelectedAccessor(EquipmentMasterData.accessor_NAME);
        setSelectedDistributer(EquipmentMasterData.distributer_NAME);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFormSubmit = () => {
    if (FormData.emUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/EquipmentMaster/Update`;
      if (confirm) {
        axios
          .put(update, FormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.em_CO_NO = company;
      axios
        .post(`${domain}/api/EquipmentMaster/Add`, FormData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
   
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_EQ_NM"] ? filters["em_EQ_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_EQ_NM",
                        e.target.value,
                        filters["em_EQ_NM"]
                          ? filters["em_EQ_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_EQ_DS"] ? filters["em_EQ_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_EQ_DS",
                        e.target.value,
                        filters["em_EQ_DS"] ? filters["em_EQ_DS"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_SR_NO"] ? filters["em_SR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_SR_NO",
                        e.target.value,
                        filters["em_SR_NO"]
                          ? filters["em_SR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_CC_NM"] ? filters["em_CC_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_CC_NM",
                        e.target.value,
                        filters["em_CC_NM"]
                          ? filters["em_CC_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_PD_MD"] ? filters["em_PD_MD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_PD_MD",
                        e.target.value,
                        filters["em_PD_MD"]
                          ? filters["em_PD_MD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_PD_FM"] ? filters["em_PD_FM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_PD_FM",
                        e.target.value,
                        filters["em_PD_FM"]
                          ? filters["em_PD_FM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_PA_NM"] ? filters["em_PA_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_PA_NM",
                        e.target.value,
                        filters["em_PA_NM"]
                          ? filters["em_PA_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_EQ_ST"] ? filters["em_EQ_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_EQ_ST",
                        e.target.value,
                        filters["em_EQ_ST"]
                          ? filters["em_EQ_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_AQ_DT"] ? filters["em_AQ_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_AQ_DT",
                        e.target.value,
                        filters["em_AQ_DT"]
                          ? filters["em_AQ_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["em_IN_DT"] ? filters["em_IN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_IN_DT",
                        e.target.value,
                        filters["em_IN_DT"]
                          ? filters["em_IN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_DS_DT"] ? filters["em_DS_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_DS_DT",
                        e.target.value,
                        filters["em_DS_DT"]
                          ? filters["em_DS_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>        
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Equipment No </th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Serial No</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Product Model</th>
                <th style={tableHeaderStyle}>Product Family</th>
                <th style={tableHeaderStyle}>Parent Name</th>
                <th style={tableHeaderStyle}>Equipment Status</th>
                <th style={tableHeaderStyle}>Acquired Date</th> 
                <th style={tableHeaderStyle}>Installation Date</th> 
                <th style={tableHeaderStyle}>Disposed Date</th>               
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.emUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.emUkNo}
                        checked={selectedRows.includes(rowData.emUkNo)}
                        onChange={() => handleRowSelect(rowData.emUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.em_EQ_NM}</td>
                    <td style={tableCellStyle}>{rowData.em_EQ_DS}</td>
                    <td style={tableCellStyle}>{rowData.em_SR_NO}</td>
                    <td style={tableCellStyle}>{rowData.em_CC_NM}</td>
                    <td style={tableCellStyle}>{rowData.em_PD_MD}</td>
                    <td style={tableCellStyle}>{rowData.em_PD_FM}</td>
                    <td style={tableCellStyle}>{rowData.em_PA_NM}</td>
                    <td style={tableCellStyle}>{rowData.em_EQ_ST}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.em_AQ_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.em_IN_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.em_DS_DT)}</td>
                                      
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <EquipmentMasterDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        em_CC_NM={em_CC_NM}
        setem_CC_NM={setem_CC_NM}
        em_RG_ST={em_RG_ST}
        setem_RG_ST={setem_RG_ST}
        em_EQ_ST={em_EQ_ST}
        setem_EQ_ST={setem_EQ_ST}
        em_CL_01={em_CL_01}
        setem_CL_01={setem_CL_01}
        em_CL_02={em_CL_02}
        setem_CL_02={setem_CL_02}
        em_CL_03={em_CL_03}
        setem_CL_03={setem_CL_03}
        em_CL_04={em_CL_04}
        setem_CL_04={setem_CL_04}
        em_CL_05={em_CL_05}
        setem_CL_05={setem_CL_05}
        em_CL_06={em_CL_06}
        setem_CL_06={setem_CL_06}
        em_CL_07={em_CL_07}
        setem_CL_07={setem_CL_07}
        em_CL_08={em_CL_08}
        setem_CL_08={setem_CL_08}
        em_CL_09={em_CL_09}
        setem_CL_09={setem_CL_09}
        em_CL_10={em_CL_10}
        setem_CL_10={setem_CL_10}
        em_CN_RY={em_CN_RY}
        setem_CN_RY={setem_CN_RY}
        SelectedSiteno={SelectedSiteno}
        setSelectedSiteno={setSelectedSiteno}
        SelectedCustomer={SelectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        SelectedParent={SelectedParent}
        setSelectedParent={setSelectedParent}
        SelectedItem={SelectedItem}
        setSelectedItem={setSelectedItem}
        SelectedServiceprd={SelectedServiceprd}
        setSelectedServiceprd={setSelectedServiceprd}
        SelectedTechnician={SelectedTechnician}
        setSelectedTechnician={setSelectedTechnician}
        SelectedSalesprsn={SelectedSalesprsn}
        setSelectedSalesprsn={setSelectedSalesprsn}
        SelectedDealer={SelectedDealer}
        setSelectedDealer={setSelectedDealer}
        SelectedAccessor={SelectedAccessor}
        setSelectedAccessor={setSelectedAccessor}
        SelectedDistributer={SelectedDistributer}
        setSelectedDistributer={setSelectedDistributer}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
      />
    </div>
  );
};

export default EquipmentMasterMain;
