import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import ClassCode from "views/common/ClassCode";
import PayTerm from "views/common/PayTermDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function QuoteComparisionDialog({
  open,
  handleClose,
  data,
  FormData,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  QuoteComparisionTable,
  setQuoteComparisionTable,
  qt_OR_TY,
  qt_OR_CO,
  setqt_TR_UM,
  setselectedItem,
  setselectedDesc,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNOC,
  setSelectedemEMNOC,
  SelectedemEMNOD,
  setSelectedemEMNOD,
  SelectedemEMNOE,
  setSelectedemEMNOE,
  selectedAbAdNM,
  setSelectedAbAdNM,
  qt_FS_OP,
  setqt_FS_OP,
  setqt_PR_BY,
  qt_PR_BY,
  setSelectedsupplier,
  setSelectedName,
  setSelectedsupplier1,
  setSelectedName1,
  setSelectedsupplier2,
  setSelectedName2,
  setSelectedsupplier3,
  setSelectedName3,
  setSelectedsupplier4,
  setSelectedName4,
  qt_PT_01,
  setqt_PT_01,
  qt_PT_02,
  setqt_PT_02,
  qt_PT_03,
  setqt_PT_03,
  qt_PT_04,
  setqt_PT_04,
}) {
  const {
    qtUkNo,
    qt_RQ_BY,
    qt_OR_NO,
    qt_OR_DT,
    qt_RQ_DT,
    qt_SE_OP,
    qt_CH_BY,
    qt_IT_NM,
  } = data;

  const { footergridstyle,footerfieldstyle } = ButtonStyles();

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const [activeTab, setActiveTab] = useState(0);

  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const { fetchPayTerm } = PayTerm();
  const [qt_TR_UMOptions, setqt_TR_UMOptions] = useState([]);
  const [qt_PT_01Options, setqt_PT_01Options] = useState([]);
  const [qt_PT_02Options, setqt_PT_02Options] = useState([]);
  const [qt_PT_03Options, setqt_PT_03Options] = useState([]);
  const [qt_PT_04Options, setqt_PT_04Options] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setqt_TR_UM(value);
  };

  const Pay1Dropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setqt_PT_01(value);
  };
  
  
  const Pay2Dropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setqt_PT_02(value);
  };

  const Pay3Dropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setqt_PT_03(value);
  };

  const Pay4Dropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setqt_PT_04(value);
  };


  useEffect(() => {
    UM();
    Pay1();
    Pay2();
    Pay3();
    Pay4();

  }, []);


  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setqt_TR_UMOptions(Options);
  };

  const Pay1 = async () => {
    const Options = await fetchPayTerm();
    setqt_PT_01Options(Options);
  };

  const Pay2 = async () => {
    const Options = await fetchPayTerm();
    setqt_PT_02Options(Options);
  };

  const Pay3 = async () => {
    const Options = await fetchPayTerm();
    setqt_PT_03Options(Options);
  };

  const Pay4 = async () => {
    const Options = await fetchPayTerm();
    setqt_PT_04Options(Options);
  };


  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AddresstypeC, setAddresstypeC] = useState("C");
  const [AddresstypeE, setAddresstypeE] = useState("E");
  const [Addresstype, setAddresstype] = useState("S");
  const [RequestedbyPopupVisible, setRequestedbyPopupVisible] = useState(false);
  const openRequestedbyPopup = (e) => {
    setRequestedbyPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("qt_RQ_BY", data.abAdNo);
    setRequestedbyPopupVisible(false);
    setShowSearchIcon(false);
  };

  const [firstOptionPopupVisible, setfirstOptionPopupVisible] = useState(false);
  const openfirstOptionPopup = (e) => {
    setfirstOptionPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect1 = async (data) => {
    setSelectedAbAdNM(data.ab_AD_NM);
    setqt_FS_OP( data.abAdNo);
    setfirstOptionPopupVisible(false);
  };


  const [ItemVisible, setItemVisible] = useState(false);
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_DS);
    setselectedDesc(data.item_DES);
    document.getElementById(`itemDES_${SelectedRowIndex}`).value = data.im_IT_DS;
    document.getElementById(`qt_IT_NM_${SelectedRowIndex}`).value = data.im_IT_NM;
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

// SUPPLIER1
const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
const openSupplierPopup = (e) => {
  setSupplierPopupVisible(true);
  setSelectedRowIndex(e);
};

const handlesupplier =async (data) => {
  setSelectedName(data.ab_AD_NM);
  setSelectedsupplier( data.abAdNo);
  document.getElementById (`qt_SU_01_${SelectedRowIndex}`).value =  data.abAdNo;
  document.getElementById (`sup_NAME1_${SelectedRowIndex}`).value =  data.ab_AD_NM;
  setSupplierPopupVisible(false);
  setShowSearchIcon(false);
};

// SUPPLIER2
const [Supplier1PopupVisible, setSupplier1PopupVisible] = useState(false);
const openSupplier1Popup = (e) => {
  setSupplier1PopupVisible(true);
  setSelectedRowIndex(e);
};

const handlesupplier1 =async (data) => {
  setSelectedName1(data.ab_AD_NM);
  setSelectedsupplier1( data.abAdNo);
  document.getElementById (`qt_SU_02_${SelectedRowIndex}`).value =  data.abAdNo;
  document.getElementById (`sup_NAME2_${SelectedRowIndex}`).value =  data.ab_AD_NM;
  setSupplier1PopupVisible(false);
  setShowSearchIcon(false);
};

// SUPPLIER3
const [Supplier2PopupVisible, setSupplier2PopupVisible] = useState(false);
const openSupplier2Popup = (e) => {
  setSupplier2PopupVisible(true);
  setSelectedRowIndex(e);
};

const handlesupplier2 =async (data) => {
  setSelectedName2(data.ab_AD_NM);
  setSelectedsupplier2( data.abAdNo);
  document.getElementById (`qt_SU_03_${SelectedRowIndex}`).value =  data.abAdNo;
  document.getElementById (`sup_NAME3_${SelectedRowIndex}`).value =  data.ab_AD_NM;
  setSupplier2PopupVisible(false);
  setShowSearchIcon(false);
};

// SUPPLIER3
const [Supplier3PopupVisible, setSupplier3PopupVisible] = useState(false);
const openSupplier3Popup = (e) => {
  setSupplier3PopupVisible(true);
  setSelectedRowIndex(e);
};

const handlesupplier3 =async (data) => {
  setSelectedName3(data.ab_AD_NM);
  setSelectedsupplier3( data.abAdNo);
  document.getElementById (`qt_SU_04_${SelectedRowIndex}`).value =  data.abAdNo;
  document.getElementById (`sup_NAME4_${SelectedRowIndex}`).value =  data.ab_AD_NM;
  setSupplier3PopupVisible(false);
  setShowSearchIcon(false);
};


// SUPPLIER4
const [Supplier4PopupVisible, setSupplier4PopupVisible] = useState(false);
const openSupplier4Popup = (e) => {
  setSupplier4PopupVisible(true);
  setSelectedRowIndex(e);
};

const handlesupplier4 =async (data) => {
  setSelectedName4(data.ab_AD_NM);
  setSelectedsupplier4( data.abAdNo);
  document.getElementById (`qt_SU_NO_${SelectedRowIndex}`).value =  data.abAdNo;
  document.getElementById (`sup_NAME_${SelectedRowIndex}`).value =  data.ab_AD_NM;
  setSupplier4PopupVisible(false);
  setShowSearchIcon(false);
};


  const onchangeFormUnitPrice = async(e,index) => {
    onchangedata(e);
  };



  
  const [SecondOptionPopupVisible, setSecondOptionPopupVisible] = useState(false);
  const openSecondOptionPopup = () => {
    setSecondOptionPopupVisible(true);
  }
  const handleDataSelectE = async (data) => {
    setSelectedemEMNOE(data.ab_AD_NM);
    onchangeFormData("qt_SE_OP", data.abAdNo);
    setSecondOptionPopupVisible(false);
    setShowSearchIcon(false);
  };

  const [PreparedbyPopupVisible, setPreparedbyPopupVisible] = useState(false);
  const [CheckedbyPopupVisible, setCheckedbyPopupVisible] = useState(false);
  const openPreparedbyPopup = () => {
    setPreparedbyPopupVisible(true);
  };
  const openCheckedbyPopup = () => {
    setCheckedbyPopupVisible(true);
  };

  const handleDataSelectD = async (data) => {
    setSelectedemEMNOD(data.ab_AD_NM);
    setqt_PR_BY( data.abAdNo);
    setPreparedbyPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelectC = async (data) => {
    setSelectedemEMNOC(data.ab_AD_NM);
    onchangeFormData("qt_CH_BY", data.abAdNo);
    setCheckedbyPopupVisible(false);
    setShowSearchIcon(false);
  };


  const addRow = () => {
    setQuoteComparisionTable([...QuoteComparisionTable, {}]);

 
  };
  const deleteRow = () => {
    if (QuoteComparisionTable.length > 0) {
      const updatedData = [...QuoteComparisionTable];
      updatedData.pop();
      setQuoteComparisionTable(updatedData);
    }
  };



const isUpdateMode = Boolean(qtUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );


  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "85rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={qtUkNo ? "Revise Quote Comparision" : "Add Quote Comparision"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      
      <div
          style={{
            marginTop: "2%",
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
          }}
      >
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="qt_OR_NO" className="font-bold">
           Quote No
          </label>
          <InputText disabled
            id="qt_OR_NO"
            name="qt_OR_NO"
            value={qt_OR_NO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="qt_OR_TY" className="font-bold">
           Type 
          </label>
          <InputText disabled
            id="qt_OR_TY"
            name="qt_OR_TY"
            value={qt_OR_TY}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="qt_OR_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="qt_OR_CO"
            name="qt_OR_CO"
            value={qt_OR_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>

        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="qt_OR_DT" className="font-bold">
               Date
            </label>
            <Calendar
                id="qt_OR_DT"
                name="qt_OR_DT"
                value={moment(data.qt_OR_DT, "YYYYMMDD").isValid() ? moment(data.qt_OR_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "qt_OR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              /> 

          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="qt_RQ_DT" className="font-bold">
           Req. Date
              </label>
              <Calendar
                id="qt_RQ_DT"
                name="qt_RQ_DT"
                value={moment(data.qt_RQ_DT, "YYYYMMDD").isValid() ? moment(data.qt_RQ_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "qt_RQ_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              /> 
            </div>
           
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="qt_RQ_BY" className="font-bold">
              Requested By  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText 
                id="name1"
                name="name1"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNO}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openRequestedbyPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="qt_RQ_BY"
                name="qt_RQ_BY"
                defaultValue={qt_RQ_BY}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openRequestedbyPopup}
              ></i>
            </div>
          </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="qt_FS_OP" className="font-bold">
             First Option <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
                id="name2"
                name="name2"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={selectedAbAdNM}
              />
              {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openfirstOptionPopup}
              ></i>
              )}
            </div>
            <div style={{display:"none"}}>
              <InputText
                id="qt_FS_OP"
                name="qt_FS_OP"
                value={qt_FS_OP}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
          </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="qt_SE_OP" className="font-bold">
             Second Option<span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="name3"
                      name="name3"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedemEMNOE}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openSecondOptionPopup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="qt_SE_OP"
                      name="qt_SE_OP"
                      value={qt_SE_OP}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
                
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="qt_PR_BY" className="font-bold">
              Prepared By <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="name4"
                      name="name4"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      defaultValue={SelectedemEMNOD}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openPreparedbyPopup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="qt_PR_BY"
                      name="qt_PR_BY"
                      value={qt_PR_BY}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="qt_CH_BY" className="font-bold">
              Checked By<span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="name5"
                      name="name5"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedemEMNOC}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openCheckedbyPopup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="qt_CH_BY"
                      name="qt_CH_BY"
                      value={qt_CH_BY}
                      defaultValue={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
          </div>
          </div>  
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
        >
      
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="QuoteComparisionTable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Line No</th>
              <th>Item Name</th>
              <th>Description</th>
              <th>Order Quantity</th>
              <th>UM</th>
              <th style={{ display: "none" }}>Last Supplier</th>
              <th>Last Supplier<span style={{ color: 'red', }}>*</span></th>
              <th>Last price</th>
              <th>Std Cost</th>
              <th style={{ display: "none" }}>Supplier1</th>
              <th>Supplier1 <span style={{ color: 'red', }}>*</span></th>
              <th>Price </th>
              <th>Payment Term</th>
              <th>Make</th>
              <th>Delivery</th>
              <th>Place</th>
              <th style={{ display: "none" }}>Supplier2</th>
              <th>Supplier2 <span style={{ color: 'red', }}>*</span></th>
              <th>Price </th>
              <th>Payment Term</th>
              <th>Make</th>
              <th>Delivery</th>
              <th>Place</th>
              <th style={{ display: "none" }}>Supplier3</th>
              <th>Supplier3<span style={{ color: 'red', }}>*</span></th>
              <th>Price </th>
              <th>Payment Term</th>
              <th>Make</th>
              <th>Delivery</th>
              <th>Place</th>
              <th style={{ display: "none" }}>Supplier4</th>
              <th>Supplier4<span style={{ color: 'red', }}>*</span></th>
              <th>Price </th>
              <th>Payment Term</th>
              <th>Make</th>
              <th>Delivery</th>
              <th>Place</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {QuoteComparisionTable.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`qtUkNo_${index}`}
                    name={`qtUkNo_${index}`}
                    defaultValue={row.qtUkNo}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`qt_LN_NO_${index}`}
                    name={`qt_LN_NO_${index}`}
                    value={index+1}
                    readOnly
                  />
                </td>
               
                  <td style={{ display: "flex" }}>
                  <InputText 
                  style={{ width: "110px" }}
                  id={`qt_IT_NM_${index}`}
                  name={`qt_IT_NM_${index}`}
                  onChange={(e) => onchangeFormData(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={row.qt_IT_NM ? row.qt_IT_NM: row[`${"qt_IT_NM"}_${index}`]}
                  />
                  {showSearchIcon && (
                  <i
                  className="pi pi-search"
                  style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "15%",
                  cursor: "pointer",
                  }}
                  onClick={(e) => openItemPopup(index)}
                  ></i>
                  )}
                </td>

                  <td>
                  <InputText disabled
                  style={{ width: "200px" }}
                  id={`itemDES_${index}`}
                  name={`itemDES_${index}`}
                  defaultValue={row.itemDES ? row.itemDES : row[`${"itemDES"}_${index}`]}
                  readOnly
                  />
                  </td>
                    
                  <td >
                  <InputText  disabled  style={{width:"120px"}}
                    id={`qt_OR_QT_${index}`}
                    name={`qt_OR_QT_${index}`}
                    defaultValue={row.rc_OR_QT}
                    onChange={(e) => onchangedata(e)}
                    
                  />
                </td>
                <td>
                  <Dropdown 
                    id={`qt_TR_UM_${index}`}
                    name={`qt_TR_UM_${index}`}
                    value={ data[`qt_TR_UM_${index}`] ? data[`qt_TR_UM_${index}`]: row.qt_TR_UM}
                    options={qt_TR_UMOptions}
                    onChange={(e) =>UMDropdown(`qt_TR_UM_${index}`, e.value)}
                    placeholder="Select UM"
                  />
                  </td>

                  <td style={{ display: "none" }}> 
                    <InputText 
                    style={{width:"150px"}}
                    id={`qt_SU_NO_${index}`}
                    name={`qt_SU_NO_${index}`}
                    value={row.qt_SU_NO ? row.qt_SU_NO: data[`${"qt_SU_NO"}_${index}`]}
                    onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                    <InputText 
                    style={{width:"150px"}}
                    id={`sup_NAME_${index}`}
                    name={`sup_NAME_${index}`}
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={row.sup_NAME ? row.sup_NAME : data[`${"sup_NAME"}_${index}`]}
                    />
                    {showSearchIcon && (
                    <i
                    className="pi pi-search"
                    style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "7%",
                    cursor: "pointer",
                    }}
                    onClick={(e) => openSupplier4Popup(index)}
                    ></i>
                    )}
                    </div>
                    </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}          
                    id={`qt_LT_PR_${index}`}
                    name={`qt_LT_PR_${index}`}
                    defaultValue={row.qt_LT_PR}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  style={{width:"90px"}}
                    id={`qt_ST_CT_${index}`}
                    name={`qt_ST_CT_${index}`}
                    defaultValue={row.qt_ST_CT}
                    onChange={(e) => onchangedata(e)}
                    
                  />
                </td>
                    <td style={{ display: "none" }}> 
                    <InputText 
                    style={{width:"150px"}}
                    id={`qt_SU_01_${index}`}
                    name={`qt_SU_01_${index}`}
                    value={row.qt_SU_01 ? row.qt_SU_01: data[`${"qt_SU_01"}_${index}`]}
                    onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                    <InputText 
                    style={{width:"150px"}}
                    id={`sup_NAME1_${index}`}
                    name={`sup_NAME1_${index}`}
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={row.sup_NAME1 ? row.sup_NAME1 : data[`${"sup_NAME1"}_${index}`]}
                    />
                    {showSearchIcon && (
                    <i
                    className="pi pi-search"
                    style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "7%",
                    cursor: "pointer",
                    }}
                    onClick={(e) => openSupplierPopup(index)}
                    ></i>
                    )}
                    </div>
                    </td>

                    <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_PR_01_${index}`}
                      name={`qt_PR_01_${index}`}
                      defaultValue={row.qt_PR_01}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                  <Dropdown 
                    id={`qt_PT_01_${index}`}
                    name={`qt_PT_01_${index}`}
                    value={ data[`qt_PT_01_${index}`] ? data[`qt_PT_01_${index}`]: row.qt_PT_01}
                    options={qt_PT_01Options}
                    onChange={(e) =>Pay1Dropdown(`qt_PT_01_${index}`, e.value)}
                    placeholder="Select Payment Term"
                  />
                  </td>

                    <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_MK_01_${index}`}
                      name={`qt_MK_01_${index}`}
                      defaultValue={row.qt_MK_01}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_DL_01_${index}`}
                      name={`qt_DL_01_${index}`}
                      defaultValue={row.qt_DL_01}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_PL_01_${index}`}
                      name={`qt_PL_01_${index}`}
                      defaultValue={row.qt_PL_01}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>

                      <td style={{ display: "none" }}> 
                      <InputText 
                      style={{width:"150px"}}
                      id={`qt_SU_02_${index}`}
                      name={`qt_SU_02_${index}`}
                      value={row.qt_SU_02 ? row.qt_SU_02: data[`${"qt_SU_02"}_${index}`]}
                      onChange={(e) => onchangedata(e)}
                      />
                      </td>
                      <td>
                      <div style={{ display: "flex", marginTop: "10px" }}>
                      <InputText 
                      style={{width:"150px"}}
                      id={`sup_NAME2_${index}`}
                      name={`sup_NAME2_${index}`}
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.sup_NAME2 ? row.sup_NAME2 : data[`${"sup_NAME2"}_${index}`]}
                      />
                      {showSearchIcon && (
                      <i
                      className="pi pi-search"
                      style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "7%",
                      cursor: "pointer",
                      }}
                      onClick={(e) => openSupplier1Popup(index)}
                      ></i>
                      )}
                      </div>
                      </td>
                      <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_PR_02_${index}`}
                      name={`qt_PR_02_${index}`}
                      defaultValue={row.qt_PR_02}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                <td>
                  <Dropdown 
                    id={`qt_PT_02_${index}`}
                    name={`qt_PT_02_${index}`}
                    value={ data[`qt_PT_02_${index}`] ? data[`qt_PT_02_${index}`]: row.qt_PT_02}
                    options={qt_PT_02Options}
                    onChange={(e) =>Pay2Dropdown(`qt_PT_02_${index}`, e.value)}
                    placeholder="Select Payment Term"
                  />
                  </td>
                    <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_MK_02_${index}`}
                      name={`qt_MK_02_${index}`}
                      defaultValue={row.qt_MK_02}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_DL_02_${index}`}
                      name={`qt_DL_02_${index}`}
                      defaultValue={row.qt_DL_02}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_PL_02_${index}`}
                      name={`qt_PL_02_${index}`}
                      defaultValue={row.qt_PL_02}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                      <td style={{ display: "none" }}> 
                      <InputText 
                      style={{width:"150px"}}
                      id={`qt_SU_03_${index}`}
                      name={`qt_SU_03_${index}`}
                      value={row.qt_SU_03 ? row.qt_SU_03: data[`${"qt_SU_03"}_${index}`]}
                      onChange={(e) => onchangedata(e)}
                      />
                      </td>
                      <td>
                      <div style={{ display: "flex", marginTop: "10px" }}>
                      <InputText 
                      style={{width:"150px"}}
                      id={`sup_NAME3_${index}`}
                      name={`sup_NAME3_${index}`}
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.sup_NAME3 ? row.sup_NAME3 : data[`${"sup_NAME3"}_${index}`]}
                      />
                      {showSearchIcon && (
                      <i
                      className="pi pi-search"
                      style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "7%",
                      cursor: "pointer",
                      }}
                      onClick={(e) => openSupplier2Popup(index)}
                      ></i>
                      )}
                      </div>
                            </td>
                            <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_PR_03_${index}`}
                      name={`qt_PR_03_${index}`}
                      defaultValue={row.qt_PR_03}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                      <td>
                  <Dropdown 
                    id={`qt_PT_03_${index}`}
                    name={`qt_PT_03_${index}`}
                    value={ data[`qt_PT_03_${index}`] ? data[`qt_PT_03_${index}`]: row.qt_PT_03}
                    options={qt_PT_03Options}
                    onChange={(e) =>Pay3Dropdown(`qt_PT_03_${index}`, e.value)}
                    placeholder="Select Payment Term"
                  />
                  </td>
                    <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_MK_03_${index}`}
                      name={`qt_MK_03_${index}`}
                      defaultValue={row.qt_MK_03}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_DL_03_${index}`}
                      name={`qt_DL_03_${index}`}
                      defaultValue={row.qt_DL_03}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_PL_03_${index}`}
                      name={`qt_PL_03_${index}`}
                      defaultValue={row.qt_PL_03}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                      <td style={{ display: "none" }}> 
                      <InputText 
                      style={{width:"150px"}}
                      id={`qt_SU_04_${index}`}
                      name={`qt_SU_04_${index}`}
                      value={row.qt_SU_04 ? row.qt_SU_04: data[`${"qt_SU_04"}_${index}`]}
                      onChange={(e) => onchangedata(e)}
                      />
                      </td>
                      <td>
                      <div style={{ display: "flex", marginTop: "10px" }}>
                      <InputText 
                      style={{width:"150px"}}
                      id={`sup_NAME4_${index}`}
                      name={`sup_NAME4_${index}`}
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.sup_NAME4 ? row.sup_NAME4 : data[`${"sup_NAME4"}_${index}`]}
                      />
                      {showSearchIcon && (
                      <i
                      className="pi pi-search"
                      style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "7%",
                      cursor: "pointer",
                      }}
                      onClick={(e) => openSupplier3Popup(index)}
                      ></i>
                      )}
                      </div>
                      </td>
                      <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_PR_04_${index}`}
                      name={`qt_PR_04_${index}`}
                      defaultValue={row.qt_PR_04}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                <td>
                  <Dropdown 
                    id={`qt_PT_04_${index}`}
                    name={`qt_PT_04_${index}`}
                    value={ data[`qt_PT_04_${index}`] ? data[`qt_PT_04_${index}`]: row.qt_PT_04}
                    options={qt_PT_04Options}
                    onChange={(e) =>Pay4Dropdown(`qt_PT_04_${index}`, e.value)}
                    placeholder="Select Payment Term"
                  />
                  </td>
                    <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_MK_04_${index}`}
                      name={`qt_MK_04_${index}`}
                      defaultValue={row.qt_MK_04}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_DL_04_${index}`}
                      name={`qt_DL_04_${index}`}
                      defaultValue={row.qt_DL_04}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td >
                    <InputText  style={{width:"90px"}}
                      id={`qt_PL_04_${index}`}
                      name={`qt_PL_04_${index}`}
                      defaultValue={row.qt_PL_04}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>

                <td >
                  <InputText   style={{width:"300px"}}
                    id={`qt_RM_RK_${index}`}
                    name={`qt_RM_RK_${index}`}
                    defaultValue={row.qt_RM_RK}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
        <AddressBookPopup
        visible={RequestedbyPopupVisible}
        onClose={() => setRequestedbyPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={AddresstypeC}
      />
      
      <AddressBookPopup
         visible={firstOptionPopupVisible}
         onClose={() => setfirstOptionPopupVisible(false)}
         onDataSelect={handleDataSelect1}
         formData={data}
         addressType={AddresstypeE}
       />

        <AddressBookPopup
          visible={SecondOptionPopupVisible}
          onClose={() => setSecondOptionPopupVisible(false)}
          onDataSelect={handleDataSelectE}
          formData={data}
          addressType={AddresstypeE}
        />

        <AddressBookPopup
        visible={PreparedbyPopupVisible}
        onClose={() => setPreparedbyPopupVisible(false)}
        onDataSelect={handleDataSelectD}
        formData={data}
        addressType={AddresstypeC}
      />

        <AddressBookPopup
        visible={CheckedbyPopupVisible}
        onClose={() => setCheckedbyPopupVisible(false)}
        onDataSelect={handleDataSelectC}
        formData={data}
        addressType={AddresstypeC}
      />

      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
       <AddressBookPopup
        visible={SupplierPopupVisible}
        onClose={() => setSupplierPopupVisible(false)}
        onDataSelect={handlesupplier}
        formData={data}
        addressType={Addresstype}
      />
        <AddressBookPopup
        visible={Supplier1PopupVisible}
        onClose={() => setSupplier1PopupVisible(false)}
        onDataSelect={handlesupplier1}
        formData={data}
        addressType={Addresstype}
      />
        <AddressBookPopup
        visible={Supplier2PopupVisible}
        onClose={() => setSupplier2PopupVisible(false)}
        onDataSelect={handlesupplier2}
        formData={data}
        addressType={Addresstype}
      />
        <AddressBookPopup
        visible={Supplier3PopupVisible}
        onClose={() => setSupplier3PopupVisible(false)}
        onDataSelect={handlesupplier3}
        formData={data}
        addressType={Addresstype}
      />

        <AddressBookPopup
        visible={Supplier4PopupVisible}
        onClose={() => setSupplier4PopupVisible(false)}
        onDataSelect={handlesupplier4}
        formData={data}
        addressType={Addresstype}
      />




    </Dialog>
  );
}
