import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import EstimateHistoryDialog from "./EstimateHistoryDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  edUkNo:"",
  ehUkNo:"",
  ed_OR_NO:"",
  ed_OR_TY:"",
  ed_OR_CO:"",
  ed_CU_NO:"",
  name:"",
  ed_IT_NO:"",
  itemDES:"",
  ed_LN_TY:"",
  ed_OR_QT:"",
  ed_TR_UM:"",
  ed_UT_RT:"",
  ed_EX_PR:"",
  ed_DS_PR:"",
  ed_DS_AM:"",
  ed_TX_PR:"",
  ed_TX_AM:"",
  ed_UT_CT:"",
  ed_EX_CT:"",
  ed_CO_CT:"",
  ed_ST_CD:"",
  ed_MR_AM:"",
  ed_MR_PR:"",
  ed_LN_NO:"",
  eh_OR_DT:moment(moment().toDate()).format("YYYYMMDD"),
  eh_RQ_DT:moment(moment().toDate()).format("YYYYMMDD"),
 
};

const initialFilters = {
  ed_OR_NO:{ value: "", operation: "" },
  ed_OR_TY:{ value: "", operation: "" },
  ed_OR_CO:{ value: "", operation: "" },
  ed_CU_NO:{ value: "", operation: "" },
  name:{ value: "", operation: "" },
  ed_IT_NO:{ value: "", operation: "" },
  itemDES:{ value: "", operation: "" },
  ed_LN_TY:{ value: "", operation: "" },
  ed_OR_QT:{ value: "", operation: "" },
  ed_TR_UM:{ value: "", operation: "" },
  ed_UT_RT:{ value: "", operation: "" },
  ed_EX_PR:{ value: "", operation: "" },
  ed_DS_PR:{ value: "", operation: "" },
  ed_DS_AM:{ value: "", operation: "" },
  ed_TX_PR:{ value: "", operation: "" },
  ed_TX_AM:{ value: "", operation: "" },
  ed_UT_CT:{ value: "", operation: "" },
  ed_EX_CT:{ value: "", operation: "" },
  ed_CO_CT:{ value: "", operation: "" },
  ed_ST_CD:{ value: "", operation: "" },
  ed_MR_AM:{ value: "", operation: "" },
  ed_MR_PR:{ value: "", operation: "" },
  ed_LN_NO:{ value: "", operation: "" },
};

const WorkCentreMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,inputStyle1
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // drodpdowns
  const [eh_CO_CT, seteh_CO_CT] = useState("");
  const [eh_TR_CR, seteh_TR_CR ] = useState("");
  const [eh_PY_TR, seteh_PY_TR] = useState("");
  const [eh_HD_CD, seteh_HD_CD ] = useState("");
  const [eh_TX_AP, seteh_TX_AP ] = useState("");
  const [ed_ST_CD, seted_ST_CD] = useState("");
  const [ed_LN_TY, seted_LN_TY] = useState("");
  const [ed_TR_UM, seted_TR_UM] = useState("");
  const [eh_EX_TR, seteh_EX_TR] = useState("");
  const [bd_DO_TY, setbd_DO_TY] = useState("");
  const [eh_OR_NO, seteh_OR_NO] = useState("");
  const [price_AMOUNT, setprice_AMOUNT] = useState("");

  const onchangegldate = (Event) => {
    const { value, name } = Event.target;
     setFormData({ ...FormData, [name]: value });
     onChnageExchangeRate(eh_TR_CR,value);
  };

  const CurrencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seteh_TR_CR(value);
    onChnageExchangeRate(value, FormData.eh_OR_DT);
  };

  const CostCentreDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seteh_CO_CT(value);
  };


  function onChnageExchangeRate(frCr, ReceiveDate) {
    const exData = {
      "cr_TO_CR":  document.getElementById("eh_BS_CR").value, 
      "cr_FR_CR": frCr,
      "cr_EF_DT": ReceiveDate
    }
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("eh_EX_TR").value = dataExRt.cr_CO_MR;
          seteh_EX_TR(dataExRt.cr_CO_MR);
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });

  }
  
// Advancesearch
  const [SelectedemEMNO, setSelectedemEMNO ] = useState("");
  const [SelectedProjectMaster, setSelectedProjectMaster ] = useState("");
  const [SelectedemEMNO1, setSelectedemEMNO1 ] = useState("");
  const [SelectedItem, setselectedItem ] = useState("");
  const [SelectedDesc, setselectedDesc ] = useState("");

// Next No
  const [er_EX_NO, seter_EX_NO ] = useState("");



  const [EstimateHistoryTableData, setEstimateHistoryTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setEstimateHistoryTableData([{}]);
    setSelectedemEMNO(null);
    seteh_PY_TR(null);
    seteh_TX_AP(null);
    seteh_TR_CR(null);
    setSelectedProjectMaster(null);
    seteh_HD_CD(null);
    seted_LN_TY(null);
    seted_TR_UM(null);
    setselectedItem(null);
    setselectedDesc(null);
    setOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setFormData(initialValue);
    setEstimateHistoryTableData([{}]);
    setSelectedemEMNO(null);
    seteh_PY_TR(null);
    seteh_TX_AP(null);
    seteh_TR_CR(null);
    setSelectedProjectMaster(null);
    seteh_HD_CD(null);
    seted_LN_TY(null);
    seted_TR_UM(null);
    setselectedItem(null);
    setselectedDesc(null);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };


  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [eh_OR_CO , seteh_OR_CO] = useState("");
  const [eh_OR_TY , seteh_OR_TY] = useState("");
  const [eh_BS_CR , seteh_BS_CR] = useState("");
  const [ed_CO_CT , seted_CO_CT] = useState("");

  const handleDefault = async () => {
    seteh_OR_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/EstimateHistoryDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value, index) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
    const fieldName=(FormData && FormData.edUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      EstimateHistoryTableData[index][fieldName]=value;
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/EstimateHistoryDetails/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const WorkCentreData = response.data;
        setEstimateHistoryTableData(WorkCentreData.estimateHistoryDetailsList);
        setFormData(WorkCentreData.estimateHistory);
        setSelectedemEMNO(WorkCentreData.estimateHistory.name);
        seteh_CO_CT(WorkCentreData.estimateHistory.eh_CO_CT);
        seteh_PY_TR(WorkCentreData.estimateHistory.eh_PY_TR);
        seteh_TX_AP(WorkCentreData.estimateHistory.eh_TX_AP);
        seteh_TR_CR(WorkCentreData.estimateHistory.eh_TR_CR);
        seteh_EX_TR(WorkCentreData.estimateHistory.eh_EX_TR);
        seteh_OR_TY(WorkCentreData.estimateHistory.eh_OR_TY);
        seteh_BS_CR(WorkCentreData.estimateHistory.eh_BS_CR);
        setSelectedProjectMaster(WorkCentreData.estimateHistory.proj_NAME);
        seteh_HD_CD(WorkCentreData.estimateHistory.eh_HD_CD);
        seted_LN_TY(WorkCentreData.estimateHistoryDetailsList.ed_LN_TY);
        seted_TR_UM(WorkCentreData.estimateHistoryDetailsList.ed_TR_UM);
        seted_CO_CT(response.data.estimateHistoryDetailsList[0].ed_CO_CT);
        seted_ST_CD(response.data.estimateHistoryDetailsList[0].ed_ST_CD);
        setselectedItem(WorkCentreData.estimateHistoryDetailsList.ed_IT_NO);
        setselectedDesc(WorkCentreData.estimateHistoryDetailsList.itemDES);
        setprice_AMOUNT(response.data.estimateHistory.amount)
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


var requestBody = {};
function values () {
  const estimateHistory = {
    ehUkNo   : FormData.ehUkNo,
    eh_OR_NO : document.getElementById("eh_OR_NO").value,
    eh_OR_TY : document.getElementById("eh_OR_TY").value,
    eh_OR_CO : document.getElementById("eh_OR_CO").value,
    eh_OR_DT : FormData.eh_OR_DT,
    eh_RQ_DT : FormData.eh_RQ_DT,
    eh_CO_CT : eh_CO_CT,
    eh_CU_NO : document.getElementById("eh_CU_NO").value,
    eh_PY_TR : FormData.eh_PY_TR,
    eh_TX_AP : FormData.eh_TX_AP,
    eh_TR_CR : FormData.eh_TR_CR,
    eh_EX_TR : document.getElementById("eh_EX_TR").value,
    eh_BS_CR : document.getElementById("eh_BS_CR").value,
    eh_PR_NO : document.getElementById("eh_PR_NO").value,
    eh_PR_RF : document.getElementById("eh_PR_RF").value,
    eh_HD_CD : eh_HD_CD,
    eh_EN_RF : document.getElementById("eh_EN_RF").value,
    eh_RV_NO : document.getElementById("eh_RV_NO").value,
    eh_DS_PR : document.getElementById("eh_DS_PR").value,
    eh_DS_AT : document.getElementById("eh_DS_AT").value,
    eh_CO_PE : document.getElementById("eh_CO_PE").value,
    eh_CO_NO : document.getElementById("eh_CO_NO").value,
};
  const table = document.getElementById("operationtable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const estimateHistoryDetailsList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const details = {
        edUkNo : document.getElementById("edUkNo_" + i).value,
        ed_LN_NO : document.getElementById("ed_LN_NO_" + i).value,
        ed_IT_NO : document.getElementById("ed_IT_NO_" + i).value,
        itemDES : document.getElementById("itemDES_" + i).value,
        ed_LN_TY : table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
        ed_OR_QT : document.getElementById("ed_OR_QT_" + i).value,
        ed_TR_UM : table.tBodies[0].rows[i].cells[6].children[0].children[1].children[0].value,
        ed_UT_RT : document.getElementById("ed_UT_RT_" + i).value,
        ed_EX_PR : document.getElementById("ed_EX_PR_" + i).value,
        ed_DS_PR : document.getElementById("ed_DS_PR_" + i).value,
        ed_DS_AM : document.getElementById("ed_DS_AM_" + i).value,
        net_AMOUNT : document.getElementById("net_AMOUNT_" + i).value,
        ed_TX_PR : document.getElementById("ed_TX_PR_" + i).value,
        ed_TX_AM : document.getElementById("ed_TX_AM_" + i).value,
        ed_UT_CT : document.getElementById("ed_UT_CT_" + i).value,
        ed_EX_CT : document.getElementById("ed_EX_CT_" + i).value,
        ed_ST_CD : document.getElementById("ed_ST_CD_" + i).value,
        ed_MR_AM : document.getElementById("ed_MR_AM_" + i).value,
        ed_MR_PR : document.getElementById("ed_MR_PR_" + i).value,
        ed_UT_CT : document.getElementById("ed_UT_CT_" + i).value,
        ed_EX_CT : document.getElementById("ed_EX_CT_" + i).value,
        ed_CO_CT : document.getElementById("ed_CO_CT_" + i).value,
    };
    estimateHistoryDetailsList.push(details);
  }
  requestBody = {
    estimateHistory,
    estimateHistoryDetailsList,
   
  };
}
      const handleFormSubmit = () => {    
      values ();
        if (FormData.ehUkNo) {
          const confirm = window.confirm("Do you want to Update ?");
          const update = `${domain}/api/EstimateHistory/Add`;
          if (confirm) {
            axios
              .post(update, requestBody, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error updating data:", error);
              });
          }
        } 
      
  } ;
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ed_OR_NO"] ? filters["ed_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_OR_NO",
                        e.target.value,
                        filters["ed_OR_NO"]
                          ? filters["ed_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ed_OR_TY"] ? filters["ed_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_OR_TY",
                        e.target.value,
                        filters["ed_OR_TY"] ? filters["ed_OR_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ed_OR_CO"] ? filters["ed_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_OR_CO",
                        e.target.value,
                        filters["ed_OR_CO"]
                          ? filters["ed_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ed_CU_NO"] ? filters["ed_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_CU_NO",
                        e.target.value,
                        filters["ed_CU_NO"] ? filters["ed_CU_NO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["ed_IT_NO"] ? filters["ed_IT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_IT_NO",
                        e.target.value,
                        filters["ed_IT_NO"]
                          ? filters["ed_IT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["itemDES"] ? filters["itemDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "itemDES",
                        e.target.value,
                        filters["itemDES"]
                          ? filters["itemDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_LN_TY"] ? filters["ed_LN_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_LN_TY",
                        e.target.value,
                        filters["ed_LN_TY"]
                          ? filters["ed_LN_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_OR_QT"] ? filters["ed_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_OR_QT",
                        e.target.value,
                        filters["ed_OR_QT"]
                          ? filters["ed_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_TR_UM"] ? filters["ed_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_TR_UM",
                        e.target.value,
                        filters["ed_TR_UM"]
                          ? filters["ed_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_UT_RT"] ? filters["ed_UT_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_UT_RT",
                        e.target.value,
                        filters["ed_UT_RT"]
                          ? filters["ed_UT_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_EX_PR"] ? filters["ed_EX_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_EX_PR",
                        e.target.value,
                        filters["ed_EX_PR"]
                          ? filters["ed_EX_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_DS_PR"] ? filters["ed_DS_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_DS_PR",
                        e.target.value,
                        filters["ed_DS_PR"]
                          ? filters["ed_DS_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_DS_AM"] ? filters["ed_DS_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_DS_AM",
                        e.target.value,
                        filters["ed_DS_AM"]
                          ? filters["ed_DS_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_TX_PR"] ? filters["ed_TX_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_TX_PR",
                        e.target.value,
                        filters["ed_TX_PR"]
                          ? filters["ed_TX_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_TX_AM"] ? filters["ed_TX_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_TX_AM",
                        e.target.value,
                        filters["ed_TX_AM"]
                          ? filters["ed_TX_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_UT_CT"] ? filters["ed_UT_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_UT_CT",
                        e.target.value,
                        filters["ed_UT_CT"]
                          ? filters["ed_UT_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_EX_CT"] ? filters["ed_EX_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_EX_CT",
                        e.target.value,
                        filters["ed_EX_CT"]
                          ? filters["ed_EX_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_CO_CT"] ? filters["ed_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_CO_CT",
                        e.target.value,
                        filters["ed_CO_CT"]
                          ? filters["ed_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_ST_CD"] ? filters["ed_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_ST_CD",
                        e.target.value,
                        filters["ed_ST_CD"]
                          ? filters["ed_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_MR_AM"] ? filters["ed_MR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_MR_AM",
                        e.target.value,
                        filters["ed_MR_AM"]
                          ? filters["ed_MR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_MR_PR"] ? filters["ed_MR_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_MR_PR",
                        e.target.value,
                        filters["ed_MR_PR"]
                          ? filters["ed_MR_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ed_LN_NO"] ? filters["ed_LN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_LN_NO",
                        e.target.value,
                        filters["ed_LN_NO"]
                          ? filters["ed_LN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Estimate No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>   
                <th style={tableHeaderStyle}>Customer No</th> 
                <th style={tableHeaderStyle}>Description</th> 
                <th style={tableHeaderStyle}>Item No</th> 
                <th style={tableHeaderStyle}>Description</th>  
                <th style={tableHeaderStyle}>Type</th> 
                <th style={tableHeaderStyle}>Quantity</th>  
                <th style={tableHeaderStyle}>UM</th> 
                <th style={tableHeaderStyle}>Unit Rate</th> 
                <th style={tableHeaderStyle}>Amount</th> 
                <th style={tableHeaderStyle}>Discount %</th> 
                <th style={tableHeaderStyle}>Discount Amount</th>  
                <th style={tableHeaderStyle}>Tax %</th>
                <th style={tableHeaderStyle}>Tax Amount</th>  
                <th style={tableHeaderStyle}>Unit Cost</th>
                <th style={tableHeaderStyle}>Extended Cost</th> 
                <th style={tableHeaderStyle}>Cost Centre</th> 
                <th style={tableHeaderStyle}>Status</th> 
                <th style={tableHeaderStyle}>Margin Amount</th>   
                <th style={tableHeaderStyle}>Margin %</th> 
                <th style={tableHeaderStyle}>Line No</th>   
                          
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.edUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.edUkNo}
                        checked={selectedRows.includes(rowData.edUkNo)}
                        onChange={() => handleRowSelect(rowData.edUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ed_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.ed_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.ed_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.ed_CU_NO}</td> 
                    <td style={tableCellStyle}>{rowData.name}</td>  
                    <td style={tableCellStyle}>{rowData.ed_IT_NO}</td>
                    <td style={tableCellStyle}>{rowData.itemDES}</td>  
                    <td style={tableCellStyle}>{rowData.ed_LN_TY}</td>
                    <td style={tableCellStyle}>{rowData.ed_OR_QT}</td>   
                    <td style={tableCellStyle}>{rowData.ed_TR_UM}</td>   
                    <td style={tableCellStyle}>{rowData.ed_UT_RT}</td>   
                    <td style={tableCellStyle}>{rowData.ed_EX_PR}</td>   
                    <td style={tableCellStyle}>{rowData.ed_DS_PR}</td>
                    <td style={tableCellStyle}>{rowData.ed_DS_AM}</td>    
                    <td style={tableCellStyle}>{rowData.ed_TX_PR}</td> 
                    <td style={tableCellStyle}>{rowData.ed_TX_AM}</td>   
                    <td style={tableCellStyle}>{rowData.ed_UT_CT}</td> 
                    <td style={tableCellStyle}>{rowData.ed_EX_CT}</td>   
                    <td style={tableCellStyle}>{rowData.ed_CO_CT}</td>   
                    <td style={tableCellStyle}>{rowData.ed_ST_CD}</td>   
                    <td style={tableCellStyle}>{rowData.ed_MR_AM}</td>
                    <td style={tableCellStyle}>{rowData.ed_MR_PR}</td>    
                    <td style={tableCellStyle}>{rowData.ed_LN_NO}</td> 
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <EstimateHistoryDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        EstimateHistoryTableData={EstimateHistoryTableData}
        setEstimateHistoryTableData={setEstimateHistoryTableData}
        CurrencyDropDown={CurrencyDropDown}
        onchangegldate={onchangegldate}
        eh_OR_CO={eh_OR_CO}
        eh_OR_TY={eh_OR_TY}
        eh_BS_CR={eh_BS_CR}
        eh_CO_CT={eh_CO_CT}
        seteh_CO_CT={seteh_CO_CT}
        eh_TR_CR={eh_TR_CR}
        seteh_TR_CR={seteh_TR_CR}
        eh_PY_TR={eh_PY_TR}
        seteh_PY_TR={seteh_PY_TR}
        eh_HD_CD={eh_HD_CD}
        seteh_HD_CD={seteh_HD_CD}
        ed_ST_CD={ed_ST_CD}
        seted_ST_CD={seted_ST_CD}
        ed_LN_TY={ed_LN_TY}
        seted_LN_TY={seted_LN_TY}
        eh_EX_TR={eh_EX_TR}
        seteh_EX_TR={seteh_EX_TR}
        bd_DO_TY={bd_DO_TY}
        setbd_DO_TY={setbd_DO_TY}
        eh_TX_AP={eh_TX_AP}
        seteh_TX_AP={seteh_TX_AP}
        ed_TR_UM={ed_TR_UM}
        seted_TR_UM={seted_TR_UM}
        ed_CO_CT={ed_CO_CT}
        SelectedItem={SelectedItem}
        setselectedItem={setselectedItem}
        SelectedDesc={SelectedDesc}
        setselectedDesc={setselectedDesc}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedemEMNO1={SelectedemEMNO1}
        setSelectedemEMNO1={setSelectedemEMNO1}
        SelectedProjectMaster={SelectedProjectMaster}
        setSelectedProjectMaster={setSelectedProjectMaster}
        CostCentreDropDown={CostCentreDropDown}
        price_AMOUNT={price_AMOUNT}
      />
    </div>
  );
};

export default WorkCentreMain;
