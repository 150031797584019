import React, { useState, useEffect, useRef } from "react";
import  "components/ListStyle/ListStyle.css";
import Chart from "chart.js/auto";
import { Pie } from 'react-chartjs-2';
import { Dropdown } from "primereact/dropdown";
import { Select } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";

const AvailableDetails = ({
    visible,
    onClose,
    selectedYear,
    selectedMonth,
}) => {
    const {formatDate,tableHeaderStyle, tableCellStyle,} = ButtonStyles();
    const [open, setOpen] = useState(false);
    const [dashboardData, setDashboardData] = useState({
        TotalNOTAvailableVehicle: [],
    });
    const token = localStorage.getItem("token");
    const company = localStorage.getItem("company");
    const domain = localStorage.getItem("domain");

    const dashboard = `${domain}/api/Dashboard/RentalDashboard`;
    const REdashboard = async () => {
        const requestinput = {
            "year":selectedYear ,
            "month": selectedMonth,
        };
        try {
            const response = await axios.post(dashboard, requestinput, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
            });
            const data = response.data;
            setDashboardData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleClose = () => {
        onClose();
        setOpen(false);
    };

    useEffect(() => {
        REdashboard();
        setOpen(visible);
    }, [visible, selectedMonth, selectedYear]);

    return (
        <Dialog id="dialog-style"
            visible={open}
            onHide={handleClose}
            style={{ width: "75rem" }}
        >
            <div style={{ display: "flex", height: "30vh", }}>
                <div style={{ flex: "1 0 48%",  borderRadius: "10px", flexDirection: "column" }}>
                    <div style={{ height: "13%", textAlign: "center", fontSize: "150%", fontWeight: "bold", marginBottom: "1%" }}>UnAvailable Vehicles</div>
                    <div style={{ height: "80%", overflow: "auto" }}>
                        <table id="Table2" style={{ border: "1px solid", borderRadius: "10px", width: "100%", tableLayout: "fixed", height: "auto" }}>
                            <thead style={{position: "sticky", top: "0", zIndex: "1", backgroundColor: "#e1e1e1"}}>
                                <tr>
                                    <th style={tableHeaderStyle} >Vehicle No</th>
                                    <th style={tableHeaderStyle} >Vehicle</th>
                                </tr>
                            </thead>
                            <tbody style={{ backgroundColor: "white",overflowY: "auto", maxHeight: "calc(80vh - 80px)" }}>
                            {dashboardData.TotalNOTAvailableVehicle && dashboardData.TotalNOTAvailableVehicle.map((rowData, index) => (
                                    <tr key={index} className="table-row" 
                                    style={{ backgroundColor:index % 2 === 0 ? "aliceblue" : "#ffffff",}}>
                                        <td style={tableCellStyle} >{rowData.ri_IT_NO}</td>
                                        <td style={tableCellStyle} >{rowData.item_DES}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default AvailableDetails;

