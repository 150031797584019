import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ManualBillingDialog from "./ManualBillingDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  bu_DESC: "",
  tenant_NAME: "",
  payee_NAME: "",
  pr_DESC: "",
  be_DO_NO: "",
  be_DO_TY: "",
  be_CO_NO: "",
  be_TE_NT:"",
  name:"",
  be_IN_DT:moment(moment().toDate()).format("YYYYMMDD"),
  be_GL_DT:moment(moment().toDate()).format("YYYYMMDD"),
  beLeNo:"",
  be_UK_NO:"",
  be_BS_PR:"",
  be_EX_TR:"",
  be_PE_NO:"",
  be_RF_CE:"",
  be_TY_PR:"", 
  be_PA_NO:"",
  be_TO_DT:"",
  be_FR_DT:"",
  be_PY_TR:"",
  be_LE_CO:"",
  be_DO_CO:"",   
  be_UN_TS:"",
  be_FL_NO:""
};
const initialFilters = {
  be_DO_NO: { value: "", operation: "" },
  be_DO_TY: { value: "", operation: "" },
  be_CO_NO: { value: "", operation: "" },
  be_TE_NT: { value: "", operation: "" },
  name: { value: "", operation: "" },
  be_IN_DT: { value: "", operation: "" },
  be_GL_DT: { value: "", operation: "" },
  beLeNo: { value: "", operation: "" },
};
const ManualBillingMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,postStyles
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);


  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const[be_DO_TY,setbe_DO_TY]=useState("");
  const[be_LE_TY,setbe_LE_TY]=useState("");
  const[be_BL_CD,setbe_BL_CD]=useState("");
  //const[be_FL_NO,setbe_FL_NO]=useState("");
  const[be_TX_PE,setbe_TX_PE]=useState("");
  const[be_BL_NO,setbe_BL_NO]=useState("");
  const[be_PR_NO,setbe_PR_NO]=useState("");
  const[be_TR_CR,setbe_TR_CR]=useState("");
  const[be_PY_TR,setbe_PY_TR]=useState("");
  const[be_IN_DT,setbe_IN_DT]=useState("");
  const[be_UN_TS,setbe_UN_TS]=useState("");
  const[be_DO_NO,setbe_DO_NO]=useState("");
  
  const[ payee, setpayename] = useState("");

  const [ManualBillingtable, setManualBillingtable] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setManualBillingtable([{}])
    setFormData(initialValue);
    setbe_UN_TS(null);
    setbe_IN_DT(null);
    setbe_PY_TR(null);
    setbe_TR_CR(null);
    setpayename(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const addRow = () => {
    setManualBillingtable([...ManualBillingtable, {}]);
  };

  const deleteRow = () => {
    if (ManualBillingtable.length > 0) {
      const updatedData = [...ManualBillingtable];
      updatedData.pop();
      setManualBillingtable(updatedData);
    }
  };
  const handleCopyData = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ManualBilling/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        delete FormData.be_UK_NO;
        const ManualBillingData = response.data;
        delete ManualBillingData.be_UK_NO;
        delete ManualBillingData.manualBillings[0].be_DO_NO;
          for (let i = 0; i < ManualBillingData.manualBillings.length; i++) {
            delete ManualBillingData.manualBillings[i].be_UK_NO;
          }
        setManualBillingtable(ManualBillingData.manualBillings);
        setFormData(ManualBillingData.manualBillings[0]);
        setbe_UN_TS(ManualBillingData.manualBillings[0].be_UN_TS);
        setbe_IN_DT(ManualBillingData.manualBillings[0].be_IN_DT);
        setbe_PY_TR(ManualBillingData.manualBillings[0].be_PY_TR);
        setbe_TR_CR(ManualBillingData.manualBillings[0].be_TR_CR);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const url = `${domain}/api/Contact/AllFields`;

  // const [be_DO_TY, setbe_DO_TY] =useState("");
  const [be_DO_CO, setbe_DO_CO] = useState("");
  const [be_BS_CR, setbe_BS_CR] = useState("");
  const handleDefault = async () => {
    setbe_DO_CO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "BE_DO_TY"){
              setbe_DO_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "BE_BS_CR"){
              setbe_BS_CR(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "BE_LE_TY"){
              setbe_LE_TY(dataDefault.versionIds[i].default_VALUE)
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ManualBilling/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });

    if(name=="be_UN_TS") {
      setbe_UN_TS(value);
    }


    if(name === 'be_IN_DT'){
      setbe_IN_DT(value);
      const exData={
        "cr_TO_CR": document.getElementById("be_BS_CR").value, 
        "cr_FR_CR": be_TR_CR, 
        "cr_EF_DT": value
      }
  
      exchangeRate (exData);
    }

  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const onchangeDropDownFormData = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setbe_BL_CD(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setbe_DO_TY(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
   // setbe_FL_NO(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setbe_TX_PE(value);

    const i = name.split('_')[3];

    const exData={
      "be_TR_CR": be_TR_CR,
      "be_BS_CR": document.getElementById("be_BS_CR").value,
      "be_EX_RT": document.getElementById("be_EX_RT").value,
      "be_EX_PR": document.getElementById("be_EX_PR_"+i).value,
      "be_DS_PE": document.getElementById("be_DS_PE_"+i).value,
      "be_TX_PE": value,
      "row_NUM": i,
      "be_FR_AM": document.getElementById("be_FR_AM_"+i).value,
    }
    axios.post(`${domain}/api/ManualBilling/GetPriceDetailsForManualBilling`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const result = resp.data.result;
        if (result) {
          document.getElementById("be_EX_PR_"+i).value = result.be_EX_PR;
          document.getElementById("be_DS_AM_"+i).value = result.be_DS_AM;
          document.getElementById("be_TX_AM_"+i).value = result.be_TX_AM;
          document.getElementById("be_FR_DS_"+i).value = result.be_FR_DS;
          document.getElementById("be_FR_TX_"+i).value = result.be_FR_TX;
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    //setbe_FL_NO(value);
  };
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setbe_TX_PE(value);
  };
  const onchangeDropDownFormData9 = (name, value,i) => {
    setFormData({ ...FormData, [name]: value });
    const table = document.getElementById("ManualBillingtable");
    if(value.toUpperCase() == 'Y') {
      const exData={
        "be_TR_CR": be_TR_CR,
        "be_BS_CR": document.getElementById("be_BS_CR").value,
        "be_EX_RT": document.getElementById("be_EX_RT").value,
        "be_EX_PR": document.getElementById("be_EX_PR_"+i).value,
        "be_DS_PE": document.getElementById("be_DS_PE_"+i).value,
        "be_TX_PE": table.tBodies[0].rows[i].cells[6].children[0].children[1].children[0].value,
        "row_NUM": i,
        "be_FR_AM": document.getElementById("be_FR_AM_"+i).value,
      }
      axios.post(`${domain}/api/ManualBilling/GetPriceDetailsForManualBilling`, exData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          const result = resp.data.result;
          if (result) {
            document.getElementById("be_EX_PR_"+i).value = result.be_EX_PR;
            document.getElementById("be_DS_AM_"+i).value = result.be_DS_AM;
            document.getElementById("be_TX_AM_"+i).value = result.be_TX_AM;
            document.getElementById("be_FR_DS_"+i).value = result.be_FR_DS;
            document.getElementById("be_FR_TX_"+i).value = result.be_FR_TX;
          }
  
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
    } else if (value.toUpperCase() == 'N') {
      table.tBodies[0].rows[i].cells[7].children[0].value = '0';
      table.tBodies[0].rows[i].cells[10].children[0].value = '0';
    }
  };

  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setbe_PY_TR(value);

    const due={
      "pt_PY_CO": value,
      "inp_DATE": be_IN_DT
    }

    axios.post(`${domain}/api/PaymentTerm/onChangeOfPaymentTerm`, due, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          const dueDate = resp.data.inp1;
          if (dueDate) {
            onchangeFormData("be_DU_DT", dueDate.out_DATE);
          }
  
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
    
  };

  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setbe_TR_CR(value);

    const exData={
      "cr_TO_CR": document.getElementById("be_BS_CR").value,
      "cr_FR_CR": value, //currency
      "cr_EF_DT": FormData.be_IN_DT
    }

    exchangeRate (exData);
  };

  function exchangeRate (exData){
    axios
        .post(`${domain}/api/ExChangeRate/Rate`, exData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          const dataExRt = resp.data.Result;
          if (dataExRt) {
            document.getElementById("be_EX_RT").value = dataExRt.cr_CO_MR;
          }
  
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
  }
  
  const handlePost = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }

    const requestBodyAPIInput = {
      "be_UK_NO" : `${selectedRows[0]}`,
    }
    axios
    .post(`${domain}/api/ManualBilling/PostManualBill`, requestBodyAPIInput, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
    handleResponse(resp.data);
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };



  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ManualBilling/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ManualBillingData = response.data;
        setManualBillingtable(ManualBillingData.manualBillings);
        setFormData(ManualBillingData.manualBillings[0]);
        setbe_UN_TS(ManualBillingData.manualBillings[0].be_UN_TS);
        setbe_IN_DT(ManualBillingData.manualBillings[0].be_IN_DT);
        setbe_PY_TR(ManualBillingData.manualBillings[0].be_PY_TR);
        setbe_TR_CR(ManualBillingData.manualBillings[0].be_TR_CR);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyAPIInput = {};
  function values  (){
    const table = document.getElementById("ManualBillingtable");
    const totalRowCount = table.tBodies[0].rows.length;
          
          const manualBillings = [];
          for (let i = 0; i < totalRowCount; i++) {
            const tx_ap = table.tBodies[0].rows[i].cells[11].children[0].children[1].children[0].value;
            var TX_PE = table.tBodies[0].rows[i].cells[12].children[0].children[1].children[0].value;
            if(tx_ap.toUpperCase() === 'N'){
              TX_PE = 0;
            } 
            const Billings = {
              be_UK_NO: document.getElementById("be_UK_NO_" + i).value,
              be_UN_TS: document.getElementById("be_UN_TS_" + i).value,
              be_FL_NO: document.getElementById("be_FL_NO_" + i).value,
              be_BL_NO: document.getElementById("be_BL_NO_" + i).value,
              bu_DESC: document.getElementById("bu_DESC_" + i).value,
              be_PR_NO: document.getElementById("be_PR_NO_" + i).value,
              pr_DESC: document.getElementById("pr_DESC_" + i).value,
              be_BL_CD: table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
              be_EX_PR: document.getElementById("be_EX_PR_" + i).value,
              be_DS_PE: document.getElementById("be_DS_PE_" + i).value,
              be_DS_AM: document.getElementById("be_DS_AM_" + i).value,
              be_TX_AP: table.tBodies[0].rows[i].cells[11].children[0].children[1].children[0].value,
              be_TX_PE: TX_PE, 
              be_TX_AM: document.getElementById("be_TX_AM_" + i).value,
              be_FR_AM: document.getElementById("be_FR_AM_" + i).value,
              be_FR_DS: document.getElementById("be_FR_DS_" + i).value,
              be_FR_TX: document.getElementById("be_FR_TX_" + i).value,
            };
            manualBillings.push(Billings);
          }
          requestBodyAPIInput = {
            manualBillings,
            be_LE_CO: document.getElementById("be_LE_CO").value,
            be_DO_CO: document.getElementById("be_DO_CO").value,
            be_DO_NO: document.getElementById("be_DO_NO").value,
            be_DO_TY: document.getElementById("be_DO_TY").value,
            be_IN_DT: FormData.be_IN_DT,
            beLeNo: document.getElementById("beLeNo").value,
            be_LE_TY: document.getElementById("be_LE_TY").value,
            be_PY_TR: be_PY_TR,
            be_TE_NT: document.getElementById("be_TE_NT").value,
            be_PA_NO: document.getElementById("be_PA_NO").value,
            be_DU_DT: FormData.be_DU_DT,
            be_TR_CR: be_TR_CR,
            be_EX_RT: document.getElementById("be_EX_RT").value,
            be_BS_CR: document.getElementById("be_BS_CR").value,
            be_GL_DT: FormData.be_GL_DT,
            be_FR_DT: FormData.be_FR_DT, 
            be_TO_DT: FormData.be_TO_DT,
            be_RF_CE: document.getElementById("be_RF_CE").value    
          };
  };

  const handleFormSubmit = () => {
    values();
    if (FormData.be_DO_NO) {
    if (FormData.be_UK_NO) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/ManualBilling/Update`;
      if (confirm) {
        axios.put(update, requestBodyAPIInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      console.log(requestBodyAPIInput);
      axios.post(`${domain}/api/ManualBilling/Add`, requestBodyAPIInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
      } else {
        const IN_DT = be_IN_DT;
        if(IN_DT){
          const currentYear = IN_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${be_DO_TY}/${secondHalf}/${company}/${firstHalf}`;  
          const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            onchangeFormData("be_DO_NO", resp.dn_DO_NO);
              setFormData({ ...FormData, ["be_DO_NO"]: resp.dn_DO_NO });
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/ManualBilling/Add`, requestBodyAPIInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
      }
  };
 



  
  const handleConfirm = () => {
      values ();
      if (FormData.be_DO_NO) {
        if (FormData.be_UK_NO) {
          const confirm = window.confirm("Do you want to Confirm ?");
          const update = `${domain}/api/ManualBilling/ConfirmManualBill`;
          if (confirm) {
            axios
              .post(update, requestBodyAPIInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error updating data:", error);
              });
          }
        } 
      }
  };
  const leftToolbarTemplate = (
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
  <Tooltip target=".post-button" position="bottom" mouseTrack >
  <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
  Post
  </div>
    </Tooltip>
      <Button 
        style={postStyles}
        icon="pi pi-send"
        outlined
        className="mr-2 post-button"
        onClick={handlePost}
        disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["be_DO_NO"] ? filters["be_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "be_DO_NO",
                        e.target.value,
                        filters["be_DO_NO"]
                          ? filters["be_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["be_DO_TY"] ? filters["be_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "be_DO_TY",
                        e.target.value,
                        filters["be_DO_TY"]
                          ? filters["be_DO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["be_DO_CO"] ? filters["be_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "be_DO_CO",
                        e.target.value,
                        filters["be_DO_CO"]
                          ? filters["be_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["be_TE_NT"] ? filters["be_TE_NT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "be_TE_NT",
                        e.target.value,
                        filters["be_TE_NT"]
                          ? filters["be_TE_NT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["be_IN_DT"] ? filters["be_IN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "be_IN_DT",
                        e.target.value,
                        filters["be_IN_DT"]
                          ? filters["be_IN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["be_GL_DT"] ? filters["be_GL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "be_GL_DT",
                        e.target.value,
                        filters["be_GL_DT"]
                          ? filters["be_GL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["beLeNo"] ? filters["beLeNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "beLeNo",
                        e.target.value,
                        filters["beLeNo"]
                          ? filters["beLeNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["be_CN_FM"] ? filters["be_CN_FM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "be_CN_FM",
                        e.target.value,
                        filters["be_CN_FM"]
                          ? filters["be_CN_FM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["be_PO_ST"] ? filters["be_PO_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "be_PO_ST",
                        e.target.value,
                        filters["be_PO_ST"]
                          ? filters["be_PO_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Invoice No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Tenant</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Invoice Date</th>
                <th style={tableHeaderStyle}>GL Date</th>
                <th style={tableHeaderStyle}>Lease No</th>
                <th style={tableHeaderStyle}>Confirm</th>
                <th style={tableHeaderStyle}>Post</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.be_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.be_UK_NO}
                        checked={selectedRows.includes(rowData.be_UK_NO)}
                        onChange={() => handleRowSelect(rowData.be_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.be_DO_NO}</td>
                    <td style={tableCellStyle}>{rowData.be_DO_TY}</td>
                    <td style={tableCellStyle}>{rowData.be_DO_CO}</td>
                    <td style={tableCellStyle}>{rowData.be_TE_NT}</td>
                    <td style={tableCellStyle}>{rowData.tenant_NAME}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.be_IN_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.be_GL_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.beLeNo}</td>
                    <td style={tableCellStyle}>{rowData.be_CN_FM}</td>
                    <td style={tableCellStyle}>{rowData.be_PO_ST}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
 {/* pagination */}
 <Pagination
    currentPage={currentPage}
    totalPages={totalPages}
    setCurrentPage={setCurrentPage}
  />

      </div>
      <ManualBillingDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        handleConfirm={handleConfirm}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        ManualBillingtable={ManualBillingtable} 
        setManualBillingtable={setManualBillingtable}
        addRow={addRow} 
        deleteRow={deleteRow}
        onchangeDropDownFormData={onchangeDropDownFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        onchangeDropDownFormData8={onchangeDropDownFormData8}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData9={onchangeDropDownFormData9}
        be_BL_CD={be_BL_CD}
        //be_FL_NO={be_FL_NO}
        be_BS_CR={be_BS_CR}
        be_TX_PE={be_TX_PE}
        be_DO_TY={be_DO_TY}
        be_BL_NO={be_BL_NO}
        be_PR_NO={be_PR_NO}
        be_TR_CR={be_TR_CR}
        be_PY_TR={be_PY_TR}
        be_DO_CO={be_DO_CO}
        be_UN_TS={be_UN_TS}
        be_IN_DT={be_IN_DT}
        payee={payee}
        setpayename={setpayename}
        be_LE_TY={be_LE_TY}
        be_DO_NO={be_DO_NO}
      />
    </div>
  );
};

export default ManualBillingMain;
