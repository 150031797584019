import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import ServiceTypePopup from "../Popups/ServiceTypePopup";
import CoverageTypePopup from "../Popups/CoverageTypePopup";
export default function ServicePackageDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  ServicePackageTableData,
  setServicePackageTableData,
  sp_GL_CD,
  setsp_GL_CD,
  sp_CV_GP,
  setsp_CV_GP,
  SelectedServicetype,
  setSelectedServicetype,
  SelectedCoverageType,
  setSelectedCoverageType,
  SelectedServicedes,
  setSelectedServicedes,
  SelectedCoveragedesc,
  setSelectedCoveragedesc


}) {
  const {
    spUkNo,
    sp_SE_PK,
    sp_PK_DS,
  } = data;
  const addRow = () => {
    setServicePackageTableData([...ServicePackageTableData, {}]);
  };

  const deleteRow = () => {
    if (ServicePackageTableData.length > 0) {
      const updatedData = [...ServicePackageTableData];
      updatedData.pop();
      setServicePackageTableData(updatedData);
    }
  };
  const {borderbox,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle} = ButtonStyles();
  // dropdowns
  const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
  const [sp_GL_CDOptions, setsp_GL_CDOptions] = useState([]);
  const [sp_CV_GPOptions, setsp_CV_GPOptions] = useState([]);
// lease type
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsp_GL_CD(value);
  };
// status
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsp_CV_GP(value);
  };

  useEffect(() => {
    fetchDropdownTY();
    fetchDropdownST();
  }, []);

  const fetchDropdownTY = async () => {
    const Options = await fetchClassCodeCD("IN/GC");
    setsp_GL_CDOptions(Options);
  };

  const fetchDropdownST = async () => {
    const Options = await fetchClassCodeDS("CV/GP");
    setsp_CV_GPOptions(Options);
  };
//advance search
const [ServicePopupVisible, setServicePopupVisible] = useState(false);
const [CoveragePopupVisible, setCoveragePopupVisible] = useState(false);
const [showSearchIcon, setShowSearchIcon] = useState("");
const [SelectedRowIndex, setSelectedRowIndex] = useState("");
const openServicePopup = (e) => {
  setServicePopupVisible(true);
  setSelectedRowIndex(e);
  setShowSearchIcon(false);
};
const openCoveragePopup = (e) => {
  setCoveragePopupVisible(true);
  setSelectedRowIndex(e);
  setShowSearchIcon(false);
};

const handleSelectService = (data) => {
  document.getElementById(`service_DESC_${SelectedRowIndex}`).value = data.service_DESC;
  document.getElementById(`sp_SE_TY_${SelectedRowIndex}`).value = data.st_SE_TY;
  setServicePopupVisible(false);
  setShowSearchIcon(false);
};
const handleSelectCoverage = (data) => {
  document.getElementById(`coverage_DESC_${SelectedRowIndex}`).value = data.cc_CV_DS;
  document.getElementById(`sp_CV_TY_${SelectedRowIndex}`).value = data.cc_CV_TY;
  setCoveragePopupVisible(false);
  setShowSearchIcon(false);
};

const isUpdateMode = Boolean(spUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={spUkNo ? "Revise Service Package" : "Add Service Package"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
            <div
                style={{
                  marginTop: "2%",
                  border: "1px solid #e8e8e8",
                  padding: "16px",
                  borderRadius: "15px",
                  backgroundColor: "aliceblue",
                  overflow: "auto",
                }}
              >
                <div className="formgrid grid">
                <div style={{display:"none"}}>
                    <label htmlFor="spUkNo" className="font-bold">
                  uk_no
                    </label>
                    <InputText 
                      id="spUkNo"
                      name="spUkNo"
                      defaultValue={spUkNo}
                    />
                  </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sp_SE_PK" className="font-bold">
                    Serivce Package
                    </label>
                    <InputText 
                      id="sp_SE_PK"
                      name="sp_SE_PK"
                      value={sp_SE_PK}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sp_PK_DS" className="font-bold">
                     Description
                    </label>
                    <InputText 
                      id="sp_PK_DS"
                      name="sp_PK_DS"
                      value={sp_PK_DS}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sp_GL_CD" className="font-bold">
                      GL Code
                    </label>
                    <Dropdown
                    name="sp_GL_CD"
                    value={sp_GL_CD}
                    options={sp_GL_CDOptions}
                    onChange={(e) => onchangeDropDownFormData1("sp_GL_CD", e.value)}
                    placeholder="Select GL Code"
                  />
                  </div>             
                </div>
            </div>
      </div>
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="ServicePackageTable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Service Type <span style={{color:"red"}}>*</span> </th>    
          <th>Description</th>
          <th>Coverage Type <span style={{color:"red"}}>*</span> </th>
          <th>Description</th>    
          <th>Response Time</th>
          <th>Coverage Group</th>
          
        </tr>
      </thead>
      <tbody>
      {ServicePackageTableData.map((row, index) => (
              <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
              id={`spUkNo_${index}`}
              name={`spUkNo_${index}`}
              value={row.spUkNo}
            />

          </td>
          <td>
            <div style={{display:"flex"}}>
          <InputText 
            style={{width:"100px"}}
            id={`sp_SE_TY_${index}`}
            name={`sp_SE_TY_${index}`}
            onChange={(Event) => onchangedata(Event)}
            onFocus={() => setShowSearchIcon(true)}
            defaultValue={row.sp_SE_TY ? row.sp_SE_TY : row[`${'sp_SE_TY'}_${index}`]}
          />
           {showSearchIcon && (
           <i
              className="pi pi-search"
              style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "7%",
                cursor: "pointer",
              }}
              onClick={(e)=>openServicePopup(index)}
            ></i>
           )}
            </div>
          </td>
          <td>
          <InputText disabled
            style={{width:"150px"}}
            id={`service_DESC_${index}`}
            name={`service_DESC_${index}`}
            defaultValue={row.service_DESC}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <div style={{display:"flex"}}>
          <InputText 
          style={{width:"100px"}}
            id={`sp_CV_TY_${index}`}
            name={`sp_CV_TY_${index}`}
            onChange={(e) => onchangedata(e)}
            onFocus={() => setShowSearchIcon(true)}
            defaultValue={row.sp_CV_TY ? row.sp_CV_TY : row[`${'sp_CV_TY'}_${index}`]}
          />
           {showSearchIcon && (
            <i
              className="pi pi-search"
              style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "7%",
                cursor: "pointer",
              }}
              onClick={(e)=>openCoveragePopup(index)}
            ></i>
           )}
          </div>
          </td>
          <td>
          <InputText disabled
            style={{width:"150px"}} 
            id={`coverage_DESC_${index}`}
            name={`coverage_DESC_${index}`}
            defaultValue={row.coverage_DESC}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText 
          style={{width:"100px"}}
            id={`sp_RS_TM_${index}`}
            name={`sp_RS_TM_${index}`}
            defaultValue={row.sp_RS_TM}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
           <Dropdown
              style={{width:"150px"}}
              id={`sp_CV_GP_${index}`}
              name={`sp_CV_GP_${index}`}
              value={data[`sp_CV_GP_${index}`] ? data[`sp_CV_GP_${index}`] : row.sp_CV_GP}
              options={sp_CV_GPOptions}
              onChange={(e) => onchangeDropDownFormData2(`sp_CV_GP_${index}`, e.value)}
              placeholder="Select Coverage Group"
            />
          </td>
          </tr>
           ))}
      </tbody>
    </table> 
  </div>
</div>
      <CoverageTypePopup
        visible={CoveragePopupVisible}
        onClose={() => setCoveragePopupVisible(false)}
        onDataSelect={handleSelectCoverage}
        formData={data}
      /> 
      <ServiceTypePopup
        visible={ServicePopupVisible}
        onClose={() => setServicePopupVisible(false)}
        onDataSelect={handleSelectService}
        formData={data}
      /> 
    </Dialog>
  );
}
