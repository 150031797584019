import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import SupplierPopup from "../Popups/SupplierPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
import ItemPopup from "../Popups/ItemPopup";
export default function MaterialIssueDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  MaterialIssueTableData,
  setMaterialIssueTableData,
  ep_TR_CR,
  setep_TR_CR,
  ep_PO_NM,
  setep_PO_NM,
  ep_EX_CT,
  setep_EX_CT,
  ep_EX_TY,
  setep_EX_TY,
  ep_LO_CN,
  setep_LO_CN,
  selectedSupplier,
  setselectedSupplier,
  ep_CO_NO,
}) {
  const {
    jbUkNo,
    jb_JB_NO,
    jb_JB_TY,
    jb_JB_CO,
    jb_OR_NO,
    jb_OR_TY,
    jb_OR_CO,
  } = data;
  const {plusstyle, minusstyle, tablebutton,tablestyle,multitablestyle } = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();

  const [ep_PO_NMOptions, setep_PO_NMOptions] = useState([]);
  const [ep_TR_CROptions, setep_TR_CROptions] = useState([]);
  const [ep_EX_CTOptions, setep_EX_CTOptions] = useState([]);
  const [ep_EX_TYOptions, setep_EX_TYOptions] = useState([]);
  const [ep_LO_CNOptions, setep_LO_CNOptions] = useState([]);


  // Policy Name
  const GroupProfileDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_PO_NM(value);
  };

  // Curr
  const CurrencyDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_TR_CR(value);
  };

 // Expense Category
const ExpenseCategoryDropdown = (name, value) => {
  const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_EX_CT(value);
};
  // Expense Type
const ExpenseTypeDropdown = (name, value) => {
  const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_EX_TY(value);
};

  // Location
const LocationDropdown = (name, value) => {
  const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_LO_CN(value);
};

  useEffect(() => {
    GroupProfile();
    PolicyName();
    ExpenseCategory();
    ExpenseType();
    Location();

  }, []);

  const GroupProfile = async () => {
    const Options = await fetchClassCodeDS("EX/PO");
    setep_PO_NMOptions(Options);
  };

  const PolicyName = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setep_TR_CROptions(Options);
  };
  const ExpenseCategory = async () => {
    const Options = await fetchClassCodeDS("EX/CT");
    setep_EX_CTOptions(Options);
  };
  const ExpenseType = async () => {
    const Options = await fetchClassCodeDS("EX/TY");
    setep_EX_TYOptions(Options);
  };
  const Location = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setep_LO_CNOptions(Options);
  };


  const addRow = () => {
    setMaterialIssueTableData([...MaterialIssueTableData, {}]);
  };
  
  const deleteRow = () => {
    if (MaterialIssueTableData.length > 0) {
      const updatedData = [...MaterialIssueTableData];
      updatedData.pop();
      setMaterialIssueTableData(updatedData);
    }
  };

// advance search
const [ItemPopupVisible,setItemPopupVisible] =useState("");

const openItemPopup = () => {
  setItemPopupVisible(true);
};
const handleItemDataSelect =async (data) => {
  setItemPopupVisible(false);
};

  const isUpdateMode = Boolean(jbUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  const [SupplierVisible, setSupplierVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleDataSelect =async (data) => {
    setselectedSupplier(data.ab_AD_NM)
    onchangeFormData(`ep_PR_SU_${SelectedRowIndex}`, data.abAdNo);
    onchangeFormData(`sup_NAME_${SelectedRowIndex}`, data.ab_AD_NM);
    setSupplierVisible(false);
    setShowSearchIcon(false);
  };
  const openSupplierPopup = (e) => {
    setSupplierVisible(true);
    setSelectedRowIndex(e);
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={jbUkNo ? "Revise Material Issue" : "Add Material Issue"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="jb_JB_NO" className="font-bold">
              Related Order
            </label>
            <InputText disabled
              id="jb_JB_NO"
              name="jb_JB_NO"
              value={jb_JB_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="jb_JB_TY" className="font-bold">
              Related Type
            </label>
            <InputText disabled
              id="jb_JB_TY"
              name="jb_JB_TY"
              value={jb_JB_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="jb_JB_CO" className="font-bold">
              Company
            </label>
            <InputText disabled
              id="jb_JB_CO"
              name="jb_JB_CO"
              value={jb_JB_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="jb_OR_NO" className="font-bold">
             Order Number
            </label>
            <InputText disabled
              id="jb_OR_NO"
              name="jb_OR_NO"
              value={jb_OR_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="jb_OR_TY" className="font-bold">
              Order Type
            </label>
            <InputText disabled
              id="jb_OR_TY"
              name="jb_OR_TY"
              value={jb_OR_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="jb_OR_CO" className="font-bold">
              Order Company
            </label>
              <InputText disabled
                id="jb_OR_CO"
                name="jb_OR_CO"
                value={jb_OR_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ep_TR_CR" className="font-bold">
             Item Number 
            </label>
              <InputText disabled
                id="ep_PO_NM"
                name="ep_PO_NM"
                value={ep_PO_NM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ep_TR_CR" className="font-bold">
              Order Quantity
            </label>
              <InputText disabled
                id="ep_PO_NM"
                name="ep_PO_NM"
                defaultValue={ep_PO_NM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ep_TR_CR" className="font-bold">
              UM
            </label>
              <InputText disabled
                id="ep_PO_NM"
                name="ep_PO_NM"
                defaultValue={ep_PO_NM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ep_TR_CR" className="font-bold">
              Issued Quantity
            </label>
              <InputText disabled
                id="ep_PO_NM"
                name="ep_PO_NM"
                defaultValue={ep_PO_NM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ep_TR_CR" className="font-bold">
              Issue Quantity
            </label>
              <InputText 
                id="ep_PO_NM"
                name="ep_PO_NM"
                defaultValue={ep_PO_NM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
           
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ep_TR_CR" className="font-bold">
             Issued Date
            </label>
              <InputText 
                id="ep_PO_NM"
                name="ep_PO_NM"
                defaultValue={ep_PO_NM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
          </div>  
      <div style={tablestyle}  >
      <div  className="table-buttons"  style={tablebutton} >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={plusstyle}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={minusstyle}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="MaterialIssuetable">
          <thead>
            <tr>
              <th style={{ display: "block" }}>UK NO</th>
              <th> Line No </th>
              <th> Component Item </th>
              <th> Description </th>
              <th> Required Quantity </th>
              <th> UM </th>
              <th> Issued Quantity </th>
              <th> Cost Centre </th>
              <th> Location </th>
              <th> Stock </th>
              <th> Issue Type </th>
            </tr>
          </thead>
          <tbody>
            {MaterialIssueTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "block" }}>
                  <InputText
                    id={`jbUkNo_${index}`}
                    name={`jbUkNo_${index}`}
                    value={row.jbUkNo}
                  />
                </td>
                <td>
                  <Dropdown
                    id={`ep_EX_CT_${index}`}
                    name={`ep_EX_CT_${index}`}
                    value={ data[`ep_EX_CT_${index}`] ? data[`ep_EX_CT_${index}`]: row.ep_EX_CT}
                    options={ep_EX_CTOptions}
                    onChange={(e) =>ExpenseCategoryDropdown(`ep_EX_CT_${index}`, e.value)}
                    placeholder="Select Exp Category"
                  />
                  </td>
                  <td>
                  <Dropdown
                    id={`ep_EX_TY_${index}`}
                    name={`ep_EX_TY_${index}`}
                    value={ data[`ep_EX_TY_${index}`] ? data[`ep_EX_TY_${index}`]: row.ep_EX_TY}
                    options={ep_EX_TYOptions}
                    onChange={(e) =>ExpenseTypeDropdown(`ep_EX_TY_${index}`, e.value)}
                    placeholder="Select Exp Type"
                  />
                  </td>
                  <td>
                <Calendar style={{width:"150px"}}
                id={`ep_EF_DT_${index}`}
                name={`ep_EF_DT_${index}`}
                value={row.ep_EF_DT? moment(row.ep_EF_DT, "YYYYMMDD").toDate(): null}
                onChange={(e) => onDateChange({ target: { name: "ep_EF_DT", value: moment(e.value).format("YYYYMMDD") } })}
                showIcon
                dateFormat="dd/mm/yy"
                />
                </td>
                <td>
                  <Dropdown
                    id={`ep_LO_CN_${index}`}
                    name={`ep_LO_CN_${index}`}
                    value={ data[`ep_LO_CN_${index}`] ? data[`ep_LO_CN_${index}`]: row.ep_LO_CN}
                    options={ep_LO_CNOptions}
                    onChange={(e) =>LocationDropdown(`ep_LO_CN_${index}`, e.value)}
                    placeholder="Select Location"
                  />
                  </td>
                  <td >
                  <InputText style={{width:"90px"}}
                    id={`ep_DA_AL_${index}`}
                    name={`ep_DA_AL_${index}`}
                    defaultValue={row.ep_DA_AL}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`ep_TL_PE_${index}`}
                    name={`ep_TL_PE_${index}`}
                    defaultValue={row.ep_TL_PE}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`ep_AU_AM_${index}`}
                    name={`ep_AU_AM_${index}`}
                    defaultValue={row.ep_AU_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
          <td>
          <div style={{ display: "flex", marginTop: "10px" ,width:"150px"}}>
          <InputText
          id={`sup_NAME_${index}`}
          name={`sup_NAME_${index}`}
          onChange={(e) => onchangedata(e)}
          onFocus={() => setShowSearchIcon(true)}
          value={row.sup_NAME ? row.sup_NAME : data[`${"sup_NAME"}_${index}`]}
          />
          {showSearchIcon && (
           <i
          className="pi pi-search"
          style={{
          color: "var(--primary-color)",
          fontSize: "119%",
          marginTop: "7%",
          cursor: "pointer",
          }}
          onClick={(e) => openSupplierPopup(index)}
          ></i>
          )}
          </div>
          </td>
          <td >
          <InputText  disabled style={{width:"90px"}}
           id={`ep_CO_NO_${index}`}
           name={`ep_CO_NO_${index}`}
           defaultValue={ep_CO_NO}
           onChange={(e) => onchangedata(e)}
            />
            </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <SupplierPopup
        visible={SupplierVisible}
        onClose={() => setSupplierVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
    </Dialog>
  );
}
