import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import '../../common/TabStyles.css';
import { Calendar } from "primereact/calendar";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import moment from "moment";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function SalesReturnDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  handleSalesR,
  onchangeFormData,
  onchangeDropDownFormData1,
  SalesData,
  SalesTableData,
  setSalesTableData,
  deleteRow,
  addRow,
  selectedCustomer,
  setselectedCustomer,
  selectedShipto,
  setselectedShipto,
  sh_OR_TY,
  sh_OR_CO,
  sh_UK_NO,
  sh_OR_NO,
  handleFormSubmit,
}) {
  const {sh_PK_NO,sh_CU_PO,sh_CU_NO,sh_SH_TO,sd_VE_NO,sd_UK_NO,sh_TR_CR,sh_EX_RT,sh_BS_CR,sh_CO_CT,
    sh_OR_DT,
    sh_RQ_DT,
    sh_PY_TR,sh_PO_DT,sh_IN_NO,sh_IN_TY,sh_IN_CO,
    net_AMOUNT,
    price_AMOUNT,
    discount_AMOUNT,
    tax_AMOUNT,taxable_AMOUNT,
    sh_DS_PR,
    sh_DS_AT,
    sh_PR_DT,
    sh_RE_MK,sh_RF_NO,
    sh_BA_AC,sh_PR_RF,
    sh_CO_PE,sh_SA_PE,sh_DL_DT,sh_VL_TY,sh_HD_CD,sh_LD_TM} = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,savestyle,cancelstyle,Dialogstyle,footergridstyle,footerfieldstyle} = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // advance search
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
  const [addressTypeCust, setaddressTypeCust] = useState("C");
  const [addressTypeShip, setaddressTypeShip] = useState("C");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const openShipToPopup = () => {
    setShipToPopupVisible(true);
  };
  const handleSelectCustomer =async (dataAb) => {
    setselectedCustomer(dataAb.ab_AD_NM);
    document.getElementById ("sh_CU_NO").value =  dataAb.abAdNo;
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectShipTo =async (data) => {
    setselectedShipto(data.ab_AD_NM);
    document.getElementById ("sh_SH_TO").value =  data.abAdNo;
    setShipToPopupVisible(false);
    setShowSearchIcon(false);
  };
  // DATE DEFAULT
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleORDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "sh_OR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };

  const [selectedRQDate, setSelectedRQDate] = useState(moment().toDate());
  const handleRQDateChange = (e) => {
    setSelectedRQDate(e.value);
    onchangedata({ target: { name: "sh_RQ_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const isUpdateMode = Boolean(sd_UK_NO);
// DIALOG HEADER
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{sh_UK_NO ? "Revise Sales Return" : "Add Sales Return"}</h5>
    <div style={{width:'60rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
        tooltip={isUpdateMode ? "Update" : "Submit"}
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog  id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div style={{display:"none"}}>
              <label htmlFor="sh_UK_NO" className="font-bold">
                uk_no
              </label>
              <InputText 
                id="sh_UK_NO"
                name="sh_UK_NO"
                value={sh_UK_NO}
              />
            </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_OR_NO" className="font-bold">
                  Order No
                </label>
                <InputText disabled
                  id="sh_OR_NO"
                  name="sh_OR_NO"
                  value={sh_OR_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_OR_TY" className="font-bold">
                  Type
                </label>
                <InputText disabled
                  id="sh_OR_TY"
                  name="sh_OR_TY"
                  value={sh_OR_TY}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_OR_CO" className="font-bold">
                  Company
                </label>
                <InputText disabled
                  id="sh_OR_CO"
                  name="sh_OR_CO"
                  value={sh_OR_CO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_TR_CR" className="font-bold">
                  Currency
                </label>
                <InputText disabled
                  id="sh_TR_CR"
                  name="sh_TR_CR"
                  value={sh_TR_CR}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_EX_RT" className="font-bold">
                  Exchange Rate
                </label>
                <InputText disabled
                  id="sh_EX_RT"
                  name="sh_EX_RT"
                  value={sh_EX_RT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_BS_CR" className="font-bold">
                  Base Currency
                </label>
                <InputText disabled id="sh_BS_CR" name="sh_BS_CR" value={sh_BS_CR} />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_OR_DT" className="font-bold">
                  Order Date
                </label>
                <Calendar
                  id="sh_OR_DT"
                  name="sh_OR_DT"
                  value={moment(sh_OR_DT, "YYYYMMDD").toDate()}
                  onChange={(e) => { handleORDateChange(e); }}
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_RQ_DT" className="font-bold">
                  Requested Date
                </label>
                <Calendar
                  id="sh_RQ_DT"
                  name="sh_RQ_DT"
                  value={moment(sh_RQ_DT, "YYYYMMDD").toDate()}
                  onChange={handleRQDateChange}
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_CO_CT" className="font-bold">
                  Cost Center
                </label>
                  <InputText disabled
                    id="sh_CO_CT"
                    name="sh_CO_CT"
                    value={sh_CO_CT}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_CU_NO" className="font-bold">
                  Customer <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText disabled
                    id="selectedCustomer"
                    name="selectedCustomer"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={selectedCustomer}
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openCustomerPopup}
                  ></i>
                  )}
                </div>
                <div style={{display:"none"}}>
                  <InputText 
                    id="sh_CU_NO"
                    name="sh_CU_NO"
                    defaultValue={sh_CU_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_SH_TO" className="font-bold">
                  Ship To <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText disabled
                    id="selectedShipto"
                    name="selectedShipto"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={selectedShipto}
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openShipToPopup}
                  ></i>
                  )}
                </div>
                <div style={{display:"none"}}>
                  <InputText
                    id="sh_SH_TO"
                    name="sh_SH_TO"
                    value={sh_SH_TO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_PY_TR" className="font-bold">
                Pay Term
                </label>
                  <InputText disabled
                    id="sh_PY_TR"
                    name="sh_PY_TR"
                    value={sh_PY_TR}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_CU_PO" className="font-bold">
                    Customer PO
                </label>
                  <InputText disabled
                    id="sh_PY_TR"
                    name="sh_CU_PO"
                    value={sh_CU_PO}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_HD_CD" className="font-bold">
                    Hold Code
                </label>
                  <InputText disabled
                    id="sh_HD_CD"
                    name="sh_HD_CD"
                    value={sh_HD_CD}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_PO_DT" className="font-bold">
                  PO Date
                </label>
                <Calendar disabled
                  id="sh_PO_DT"
                  name="sh_PO_DT"
                  value={moment(data.sh_PO_DT, "YYYYMMDD").isValid() ? moment(data.sh_PO_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "sh_PO_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_IN_NO" className="font-bold">
                  Original Order No
                </label>
                <InputText disabled
                  id="sh_IN_NO"
                  name="sh_IN_NO"
                  value={sh_IN_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_IN_TY" className="font-bold">
                Original Type
                </label>
                <InputText disabled
                  id="sh_IN_TY"
                  name="sh_IN_TY"
                  value={sh_IN_TY}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_IN_CO" className="font-bold">
                Original Company
                </label>
                <InputText disabled
                  id="sh_IN_CO"
                  name="sh_IN_CO"
                  value={sh_IN_CO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
            </div>
          </div>
          <div style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
              {(
              <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
              )}
              {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
              )}
            </div>
            <div  style={multitablestyle}>
              <table className="custom-table" id="SalesReturnTable">
                <thead>
                  <tr>
                    <th style={{display:"none"}}>uK </th>
                    <th>Line No</th>
                    <th>Item Number</th>
                    <th>Description</th>
                    <th>Order Quantity</th>
                    <th>UM</th>
                    <th>Unit Price</th>
                    <th>Price Amount</th>
                    <th>Tax %</th>
                    <th>Tax</th>
                    <th>Discount Amount</th>
                    <th>Net Amount</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Shipped Quantity</th>
                    <th>Cancelled Quantity</th>
                    <th>Cost Center</th>
                    <th>Location</th>
                    <th>Lot Number</th>
                    <th>Unit Cost</th>
                    <th>Cost Amount</th>
                    <th>Margin % </th>
                    <th>Margin Amount </th>
                  </tr>
                </thead>
                <tbody>
                  {SalesTableData.map((row, index) => (
                    <tr key={index}>
                      <td style={{display:"none"}}>
                        <InputText disabled
                          id={`sd_UK_NO_${index}`}
                          name={`sd_UK_NO_${index}`}
                          value={row.sd_UK_NO}
                        />
                      </td>
                      <td>
                        <InputText disabled
                          style={{ width: "60px" }}
                          id={`sdLnNo_${index}`}
                          name={`sdLnNo_${index}`}
                          value={index + 1}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`sd_IT_NM_${index}`}
                          name={`sd_IT_NM_${index}`}
                          value={row.sd_IT_NM}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"15rem"}}
                          id={`item_DESC_${index}`}
                          name={`item_DESC_${index}`}
                          value={row.item_DESC}
                        />
                      </td>
                      <td>
                        <InputText
                          style={{ width: "10rem" }}
                          id={`sd_OR_QT_${index}`}
                          name={`sd_OR_QT_${index}`}
                          defaultValue={row.sd_OR_QT}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"5rem"}}
                          id={`sd_TR_UM_${index}`}
                          name={`sd_TR_UM_${index}`}
                          value={row.sd_TR_UM}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`sd_UT_PR_${index}`}
                          name={`sd_UT_PR_${index}`}
                          value={row.sd_UT_PR}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"7rem"}}
                          id={`sd_EX_PR_${index}`}
                          name={`sd_EX_PR_${index}`}
                          value={row.sd_EX_PR}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`sd_TX_PE_${index}`}
                          name={`sd_TX_PE_${index}`}
                          value={row.sd_TX_PE}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`sd_TX_AM_${index}`}
                          name={`sd_TX_AM_${index}`}
                          value={row.sd_TX_AM}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`sd_DS_AT_${index}`}
                          name={`sd_DS_AT_${index}`}
                          value={row.sd_DS_AT}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`NET_AMOUNT_${index}`}
                          name={`NET_AMOUNT_${index}`}
                          value={row.NET_AMOUNT}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"5rem"}}
                          id={`sd_ST_TY_${index}`}
                          name={`sd_ST_TY_${index}`}
                          value={row.sd_ST_TY}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"5rem"}}
                          id={`sd_ST_CD_${index}`}
                          name={`sd_ST_CD_${index}`}
                          value={row.sd_ST_CD}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`sd_SH_QT_${index}`}
                          name={`sd_SH_QT_${index}`}
                          value={row.sd_SH_QT}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`sd_CN_QT_${index}`}
                          name={`sd_CN_QT_${index}`}
                          value={row.sd_CN_QT}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"7rem"}}
                          id={`sd_CO_CT_${index}`}
                          name={`sd_CO_CT_${index}`}
                          value={row.sd_CO_CT}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`sd_LO_CN_${index}`}
                          name={`sd_LO_CN_${index}`}
                          value={row.sd_LO_CN}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`sd_LO_NO_${index}`}
                          name={`sd_LO_NO_${index}`}
                          value={row.sd_LO_NO}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`sd_UT_CT_${index}`}
                          name={`sd_UT_CT_${index}`}
                          value={row.sd_UT_CT}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`sd_EX_CT_${index}`}
                          name={`sd_EX_CT_${index}`}
                          value={row.sd_EX_CT}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`sd_MR_PR_${index}`}
                          name={`sd_MR_PR_${index}`}
                          value={row.sd_MR_PR}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`sd_MR_AT_${index}`}
                          name={`sd_MR_AT_${index}`}
                          value={row.sd_MR_AT}
                        />
                      </td>
                    
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div style={inboxstyle}>
          <div className="formgrid grid" style={footergridstyle} >
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2"  >
              <label htmlFor="price_AMOUNT" className="font-bold">
                Price Amount
              </label>
              <InputText disabled
                id="price_AMOUNT"
                name="price_AMOUNT"
                value={price_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2"  >
              <label htmlFor="taxable_AMOUNT" className="font-bold">
                Taxable Amount
              </label>
              <InputText disabled
                id="taxable_AMOUNT"
                name="taxable_AMOUNT"
                value={taxable_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2"  >
              <label htmlFor="tax_AMOUNT" className="font-bold">
                Tax
              </label>
              <InputText disabled
                id="tax_AMOUNT"
                name="tax_AMOUNT"
                value={tax_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
        </div>
    </div>
     {/* customer */}
     <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleSelectCustomer}
        formData={data}
        addressType={addressTypeCust}
        setFormData={setFormData}
      />
      {/* Shipto */}
      <AddressBookPopup
        visible={ShipToPopupVisible}
        onClose={() => setShipToPopupVisible(false)}
        onDataSelect={handleSelectShipTo}
        formData={data}
        addressType={addressTypeShip}
        setFormData={setFormData}
      />
    </Dialog>
  );
}
