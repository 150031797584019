import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import axios from "axios";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Calendar } from "primereact/calendar";
import ClassCode from "views/common/ClassCode";
import RentalConrtactPopup from "../Popups/RentalContractPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function RentalSecurityDepositDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  CurrencyDropDown,
  handleConfirm,
  onchangegldate,
  rs_ST_AT,
  setrs_ST_AT,
  rs_TR_CR,
  rs_DO_TY,
  rs_DO_CO,
  rs_BS_CR,
  rs_DP_TY,
  Amount,
  setrs_DP_TY,
  SelectedemEMNO,
  setSelectedemEMNO,
  rs_EX_RT,
  setrs_EX_RT,
  rs_RE_CD,
  setrs_RE_CD,
  refundFlag,
  ContractStatusDropDown,
  setrs_FD_AM,
  rs_FD_AM,
  RefAmount,
  rs_DP_AM,
}) {
  const {
    rsUkNo,
    rs_TR_DT,
    rs_GL_DT,
    rs_CU_NO,
    rs_CN_NO,
    rs_FR_DM,
    rs_FR_AM,
    rs_DO_NO,
    rs_CO_NO,
    rs_CN_TY,
    rs_DE_AM,
    // rs_DP_AM,
  rs_RF_AM,

  } = data;

  const {borderbox,inboxstyle} = ButtonStyles();
    
  const [activeTab, setActiveTab] = useState(0);
  const [AddresstypeS, setAddresstypeS] = useState("S");
  const [AddresstypeC, setAddresstypeC] = useState("C");
  // DROPDOWNS
  const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
  const [rs_ST_ATOptions, setrs_ST_ATOptions] = useState([]);
  const [rs_TR_CROptions, setrs_TR_CROptions] = useState([]);
  const [rs_DP_TYOptions, setrs_DP_TYOptions] = useState([]);
  const [rs_RE_CDOptions, setrs_RE_CDOptions] = useState([]);

const DisposableTypeDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrs_DP_TY(value);
};

const ReasonCodeDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrs_RE_CD(value);
};

  useEffect(() => {
    ContractStatus();
    DisposableType();
    ReasonCode();
    Currency();
  }, []);
  const ContractStatus = async () => {
    const Options = await fetchClassCodeDS("SH/ST");
    setrs_ST_ATOptions(Options);
  };
  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setrs_TR_CROptions(Options);
  };
  const DisposableType = async () => {
    const Options = await fetchClassCodeDS("DE/TY");
    setrs_DP_TYOptions(Options);
  };
  const ReasonCode = async () => {
    const Options = await fetchClassCodeDS("RN/RC");
    setrs_RE_CDOptions(Options);
  };
  // Advance Search
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("rs_CU_NO", data.abAdNo);
    const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
      const requestData={
      "cu_CU_NO":  data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        CurrencyDropDown(`rs_TR_CR`, dataCuMt.cu_CR_CD);
        const exData={
          "cr_TO_CR": document.getElementById("rs_BS_CR").value, //base curr
          "cr_FR_CR": dataCuMt.cu_CR_CD, //currency
          "cr_EF_DT": data.rs_GL_DT
        }
        exchangeRate (exData);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setCustomerPopupVisible(false);
  };
  function exchangeRate(exData) {
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("rs_EX_RT").value = dataExRt.cr_CO_MR;
          setrs_EX_RT(dataExRt.cr_CO_MR);
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };
  const [RentalContractPopupVisible, setRentalContractPopupVisible] = useState(false);
  const openRentalContractPopup = () => {
    setRentalContractPopupVisible(true);
  };
  const handleRentalContract = (data ) => {
    onchangeFormData("rs_CN_NO", data.rc_TR_NO);
    onchangeFormData("rs_CN_TY", data.rc_TR_TY);
    onchangeFormData("rs_CO_NO", data.rc_TR_CO);
    CurrencyDropDown("rs_TR_CR", data.rc_TR_CR);
    setSelectedemEMNO(data.cus_NAME);
    onchangeFormData("rs_CU_NO", data.rc_CU_NO);
    document.getElementById("rs_EX_RT").value = data.rc_EX_RT;
    document.getElementById("rs_BS_CR").value = data.rc_BS_CR;
    setRentalContractPopupVisible(false);
    setShowSearchIcon(false);
  };
const isUpdateMode = Boolean(rsUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button 
    style={{width:"5%",
    backgroundColor:"transparent",
    color:"blue",
    marginLeft:"82%",marginBottom:"-4%",
    display:isUpdateMode ? 'block' : 'none'}}
    icon="pi pi-check-circle" 
    onClick={handleConfirm} 
    tooltip={"Confirm"}>
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
      style={{width:"5%"}}
      icon="pi pi-check" 
      onClick={handleFormSubmit} 
      tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>

  </React.Fragment>
  );
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [showSearchIcon, setShowSearchIcon] = useState("");
  // deposit amount
  const [depositAmount, setDepositAmount] = useState(rs_DP_AM);
  useEffect(() => {
    setDepositAmount(rs_DP_AM);
  }, [rs_DP_AM]);
  const handleChange = (e) => {
    setDepositAmount(e.target.value);
    onchangedata(e);
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={rsUkNo ? "Revise Rental Security Deposit" : "Add Rental Security Deposit"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
        <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="General">
            {/* tab1 general */}

            <div
                style={{
                  marginTop: "2%",
                  border: "1px solid #e8e8e8",
                  padding: "16px",
                  borderRadius: "15px",
                  backgroundColor: "aliceblue",
                  overflow: "auto",
                }}
              >
                 <div className="formgrid grid">
                 <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_DO_NO" className="font-bold">
                  Document No
                  </label>
                  <InputText disabled
                    id="rs_DO_NO"
                    name="rs_DO_NO"
                    value={rs_DO_NO}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_DO_TY" className="font-bold">
                Type
                  </label>
                  <InputText disabled
                    id="rs_DO_TY"
                    name="rs_DO_TY"
                    value={rs_DO_TY}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_DO_CO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="rs_DO_CO"
                    name="rs_DO_CO"
                    value={rs_DO_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                 <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="rs_CN_NO" className="font-bold">
             Related Contract No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText
                    id="rs_CN_NO"
                    name="rs_CN_NO"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={rs_CN_NO}
                    disabled={refundFlag}
                  />
                   {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openRentalContractPopup}
                    ></i>
                   )}
              </div>
                </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_CN_TY" className="font-bold">
                Type
                  </label>
                  <InputText disabled
                    id="rs_CN_TY"
                    name="rs_CN_TY"
                    value={rs_CN_TY}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_CO_NO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="rs_CO_NO"
                    name="rs_CO_NO"
                    value={rs_CO_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="rs_CU_NO" className="font-bold">
              Customer  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
                id="cus_NAME"
                name="cus_NAME"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNO}
                disabled={refundFlag}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCustomerPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="rs_CU_NO"
                name="rs_CU_NO"
                defaultValue={rs_CU_NO}
                onChange={(Event) => onchangedata(Event)}
                disabled={refundFlag}
              />
            </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="rs_TR_DT" className="font-bold">
              Trans Date
              </label>
              <Calendar
                    id="rs_TR_DT"
                    name="rs_TR_DT"
                    value={moment(data.rs_TR_DT, "YYYYMMDD").isValid() ? moment(data.rs_TR_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "rs_TR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                    disabled={refundFlag}
                  />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="rs_GL_DT" className="font-bold">
           GL Date
              </label>
                <Calendar
                    id="rs_GL_DT"
                    name="rs_GL_DT"
                    value={moment(data.rs_GL_DT, "YYYYMMDD").isValid() ? moment(data.rs_GL_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "rs_GL_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                    disabled={refundFlag}
                  />
            </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_TR_CR" className="font-bold">
                 Currency
                  </label>
                  <Dropdown
                    name="rs_TR_CR"
                    value={rs_TR_CR}
                    options={rs_TR_CROptions}
                    onChange={(e) =>CurrencyDropDown("rs_TR_CR", e.value)}
                    placeholder="Select Currency"
					        disabled={refundFlag}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_EX_RT" className="font-bold">
                   Exchange Rate
                  </label>
                  <InputText disabled
                    id="rs_EX_RT"
                    name="rs_EX_RT"
                    value={rs_EX_RT}
                    // onChange={(e) => onchangedata(e)}
                    onChange={(e) => { 
                      // setrs_EX_RT(e.target.value); 
                      onchangedata(e)
                      RefAmount(); 
                    }}
                  />
            </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_BS_CR" className="font-bold">
                    Base Currency
                  </label>
                  <InputText disabled
                    id="rs_BS_CR"
                    name="rs_BS_CR"
                    value={rs_BS_CR}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_ST_AT" className="font-bold">
                   Status
                  </label>
                  <Dropdown
                    name="rs_ST_AT"
                    value={rs_ST_AT}
                    options={rs_ST_ATOptions}
                    onChange={(e) => ContractStatusDropDown("rs_ST_AT", e.value)}
                    placeholder="Select  Status"
                    disabled={refundFlag}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rs_DP_TY" className="font-bold">
                    Deposit Type
                    </label>
                    <Dropdown
                    name="rs_DP_TY"
                    value={rs_DP_TY}
                    options={rs_DP_TYOptions}
                    onChange={(e) => DisposableTypeDropdown("rs_DP_TY", e.value)}
                    placeholder="Select Deposit Type"
                    disabled={refundFlag}
                  />
                  </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_DP_AM" className="font-bold">
                   Deposit Amount
                  </label>
                  <InputText 
                    id="rs_DP_AM"
                    name="rs_DP_AM"
                    defaultValue={depositAmount}
                    disabled={data.rs_TR_CR !== rs_BS_CR || refundFlag}
                    onChange={handleChange}
                  />
                 </div>
                 <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_FD_AM" className="font-bold">
                  Fore Amount
                  </label>
                  <InputText 
                    id="rs_FD_AM"
                    name="rs_FD_AM"
                    value={data.rs_TR_CR === rs_BS_CR ? 0 : rs_FD_AM}
                    disabled={data.rs_TR_CR === rs_BS_CR || refundFlag}
                    onChange={(e) => { 
                      // setrs_FD_AM(e.target.value);
                      onchangedata(e);  Amount(); 
                    }}
                    // onChange={(e) => onchangedata(e)}
                    // onBlur={Amount} // Call Amount() onBlur or use useEffect for more complex updates
                  />
                 </div>
                </div>
            </div>
             
          </TabPanel>
          <TabPanel header="Refund">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
                <div className="formgrid grid">
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_RF_DT" className="font-bold">
                    Refund Date
                  </label>
                  <Calendar
                    id="rs_RF_DT"
                    name="rs_RF_DT"
                    value={moment(data.rs_RF_DT, "YYYYMMDD").isValid() ? moment(data.rs_RF_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "rs_RF_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_RF_AM" className="font-bold">
                   Refund Amount
                  </label>
                  <InputText
                    id="rs_RF_AM"
                    name="rs_RF_AM"
                    value={rs_RF_AM}
                    disabled={data.rs_TR_CR !== rs_BS_CR}
                    // onChange={(e) => { onchangedata(e);RefAmount();  }}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      onchangedata(e); // Assuming this function handles updating state
                      const requestData = {
                        rs_DP_AM: document.getElementById("rs_DP_AM").value,
                        rs_RF_AM: newValue,
                        rs_FD_AM: rs_FD_AM,
                        rs_FR_AM: document.getElementById("rs_FR_AM").value,
                        rs_EX_RT: rs_EX_RT,
                      };
                      RefAmount(requestData); // Call RefAmount with the updated requestData
                    }}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_DE_AM" className="font-bold">
                    Deducted Amount
                  </label>
                  <InputText disabled
                    id="rs_DE_AM"
                    name="rs_DE_AM"
                    defaultValue={rs_DE_AM}
                    readOnly
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rs_RE_CD" className="font-bold">
                   Reason Code
                    </label>
                    <Dropdown
                    name="rs_RE_CD"
                    value={rs_RE_CD}
                    options={rs_RE_CDOptions}
                    onChange={(e) => ReasonCodeDropdown("rs_RE_CD", e.value)}
                    placeholder="Select Reason Code"
                  />
                  </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_FR_AM" className="font-bold">
                   Fore Refund Amount
                  </label>
                  <InputText
                    id="rs_FR_AM"
                    name="rs_FR_AM"
                    value={data.rs_TR_CR=== rs_BS_CR ? 0 : rs_FR_AM}
                    disabled={data.rs_TR_CR === rs_BS_CR}
                    onChange={(e) => { onchangedata(e);RefAmount();}}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rs_FR_DM" className="font-bold">
                    Fore Deducted Amount
                  </label>
                  <InputText disabled
                    id="rs_FR_DM"
                    name="rs_FR_DM"
                    defaultValue={rs_FR_DM}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          </TabView>
      </div>

        <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={AddresstypeC}
      />
        <RentalConrtactPopup
        visible={RentalContractPopupVisible}
        onClose={() => setRentalContractPopupVisible(false)}
        onDataSelect={handleRentalContract}
        formData={data}
      />


    </Dialog>
  );
}
