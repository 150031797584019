import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import SparePartsIssueDialog from "./SparePartsIssueDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  spUkNo:"",
  sp_OR_NO:"",
  sp_OR_TY:"",
  sp_OR_CO:"",
  sp_EQ_NM:"",
  sp_CO_NM:"",
  sp_OR_QT:"",
  sp_IS_QT:"",
  sp_TR_UM:"",
  sp_LO_CN:"",
  sp_LT_NO:"",
  sp_RQ_DT:"",
  sp_IS_DT:"", 
};

const initialFilters = {
  sp_OR_NO: { value: "", operation: "" },
  sp_OR_TY: { value: "", operation: "" },
  sp_OR_CO: { value: "", operation: "" },
  sp_EQ_NM: { value: "", operation: "" },
  sp_CO_NM: { value: "", operation: "" },
  sp_OR_QT: { value: "", operation: "" },
  sp_IS_QT: { value: "", operation: "" },
  sp_TR_UM: { value: "", operation: "" },
  sp_LO_CN: { value: "", operation: "" },
  sp_LT_NO: { value: "", operation: "" },
  sp_RQ_DT: { value: "", operation: "" },
  sp_IS_DT: { value: "", operation: "" },
};

const SparePartsIssueMain = () => {
    // buttonstyles
    const {formatDate, buttonStyles, addButtonStyles,
      copyButtonStyles,
      checkboxStyle,tableHeaderStyle,
      inputStyle,tableCellStyle,
     } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSparePartsIssueTableData([{}]);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/SparePartsIssue/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const SparePartsIssueData = response.data;
          delete SparePartsIssueData.spUkNo;
          for (let i = 0; i < SparePartsIssueData.sparePartsIssueList.length; i++) {
            delete SparePartsIssueData.sparePartsIssueList[i].spUkNo;
          }
          setFormData(SparePartsIssueData);
          setSparePartsIssueTableData(SparePartsIssueData.sparePartsIssueList);
          setsp_TR_UM(SparePartsIssueData.sp_TR_UM);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    // setpi_TR_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/SparePartsIssue/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const [SparePartsIssueTableData, setSparePartsIssueTableData] = useState([{}]);
  const [sp_TR_UM , setsp_TR_UM] = useState("");
  const [Componentname, setComponentname] = useState("");

  const onchangeFormData = (name, value) => {
    // setFormData({ ...FormData, [name]: value });
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };
 
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/SparePartsIssue/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const SparePartsIssueData = response.data;
        setSparePartsIssueTableData(SparePartsIssueData.sparePartsIssueList);        
        setFormData(SparePartsIssueData.sparePartsIssueList[0]);
        setsp_TR_UM(SparePartsIssueData.sp_TR_UM);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  var requestBodyAPIInput = {};
  function values  (){
    const table = document.getElementById("SparePartsIssueTable");
    const totalRowCount = table.tBodies[0].rows.length;
      const sparePartsIssueList = [];
      for (let i = 0; i < totalRowCount; i++) {
        const RQ_DT =  document.getElementsByName("sp_RQ_DT_" + i)[0]; 
        const IS_DT =  document.getElementsByName("sp_IS_DT_" + i)[0];
        const Details = {
          spUkNo: document.getElementById("spUkNo_" + i).value,
          sp_CO_NM: document.getElementById("sp_CO_NM_" + i).value,
          sp_RQ_QT: document.getElementById("sp_RQ_QT_" + i).value,
          sp_TR_UM: table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
          sp_RQ_DT: RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          sp_IS_QT: document.getElementById("sp_IS_QT_" + i).value,
          sp_LO_CN: document.getElementById("sp_LO_CN_" + i).value,
          sp_LT_NO: document.getElementById("sp_LT_NO_" + i).value,
          sp_IS_DT: IS_DT.value?moment(IS_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          sp_ST_CD: document.getElementById("sp_ST_CD_" + i).value,
        };
        sparePartsIssueList.push(Details);
      }
      requestBodyAPIInput = {
        sparePartsIssueList,
        spUkNo  : document.getElementById("spUkNo").value,
        sp_OR_NO: document.getElementById("sp_OR_NO").value,
        sp_OR_TY: document.getElementById("sp_OR_TY").value,
        sp_OR_CO: document.getElementById("sp_OR_CO").value,
        sp_EQ_NM: document.getElementById("sp_EQ_NM").value,  
      };
  }
  const handleFormSubmit = () => {    
    values ();
      if (FormData.spUkNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/SparePartsIssue/Update`;
        if (confirm) {
          axios
            .put(update, requestBodyAPIInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        axios
          .post(`${domain}/api/SparePartsIssue/Add`, requestBodyAPIInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
          handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sp_OR_NO"] ? filters["sp_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sp_OR_NO",
                        e.target.value,
                        filters["sp_OR_NO"]
                          ? filters["sp_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sp_OR_TY"] ? filters["sp_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sp_OR_TY",
                        e.target.value,
                        filters["sp_OR_TY"]
                          ? filters["sp_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sp_OR_CO"] ? filters["sp_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sp_OR_CO",
                        e.target.value,
                        filters["sp_OR_CO"]
                          ? filters["sp_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sp_EQ_NM"] ? filters["sp_EQ_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sp_EQ_NM",
                        e.target.value,
                        filters["sp_EQ_NM"]
                          ? filters["sp_EQ_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sp_CO_NM"] ? filters["sp_CO_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sp_CO_NM",
                        e.target.value,
                        filters["sp_CO_NM"]
                          ? filters["sp_CO_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sp_OR_QT"] ? filters["sp_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sp_OR_QT",
                        e.target.value,
                        filters["sp_OR_QT"]
                          ? filters["sp_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sp_IS_QT"] ? filters["sp_IS_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sp_IS_QT",
                        e.target.value,
                        filters["sp_IS_QT"]
                          ? filters["sp_IS_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sp_TR_UM"] ? filters["sp_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sp_TR_UM",
                        e.target.value,
                        filters["sp_TR_UM"]
                          ? filters["sp_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sp_LO_CN"] ? filters["sp_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sp_LO_CN",
                        e.target.value,
                        filters["sp_LO_CN"]
                          ? filters["sp_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sp_LT_NO"] ? filters["sp_LT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sp_LT_NO",
                        e.target.value,
                        filters["sp_LT_NO"]
                          ? filters["sp_LT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sp_RQ_DT"] ? filters["sp_RQ_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sp_RQ_DT",
                        e.target.value,
                        filters["sp_RQ_DT"]
                          ? filters["sp_RQ_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sp_IS_DT"] ? filters["sp_IS_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sp_IS_DT",
                        e.target.value,
                        filters["sp_IS_DT"]
                          ? filters["sp_IS_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Equipment Name</th>
                <th style={tableHeaderStyle}>Component Name</th>
                <th style={tableHeaderStyle}>Required Quantity</th>
                <th style={tableHeaderStyle}>Issued Quantity</th> 
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Location</th>
                <th style={tableHeaderStyle}>Lot Number</th>
                <th style={tableHeaderStyle}>Requested Date</th>
                <th style={tableHeaderStyle}>Issued Date</th>            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.spUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.spUkNo}
                        checked={selectedRows.includes(rowData.spUkNo)}
                        onChange={() => handleRowSelect(rowData.spUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.sp_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.sp_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.sp_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.sp_EQ_NM}</td>
                    <td style={tableCellStyle}>{rowData.sp_CO_NM}</td> 
                    <td style={tableCellStyle}>{rowData.sp_RQ_QT}</td>
                    <td style={tableCellStyle}>{rowData.sp_IS_QT}</td>
                    <td style={tableCellStyle}>{rowData.sp_TR_UM}</td>
                    <td style={tableCellStyle}>{rowData.sp_LO_CN}</td>
                    <td style={tableCellStyle}>{rowData.sp_LT_NO}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.sp_RQ_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sp_IS_DT)}</td>             
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <SparePartsIssueDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        SparePartsIssueTableData={SparePartsIssueTableData}
        setSparePartsIssueTableData={setSparePartsIssueTableData}
        Componentname={Componentname}
        setComponentname={setComponentname}
        sp_TR_UM={sp_TR_UM}
        setsp_TR_UM={setsp_TR_UM}
      />
    </div>
  );
};

export default SparePartsIssueMain;
