import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import SalaryDetailsDialog from "./SalaryDetailsDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { color } from "@chakra-ui/system";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  em_DE_PT: "",
  es_TO_SL: "",
  es_BA_SC : "",
  es_HO_RA:"",
  es_EM_NO:"",
  es_YE_AR:"",
  es_CO_CT:"",
  es_CO_NO:"",
  esUkNo:"",
  es_MN_TH: "",
  es_SL_DD: "",
  es_CO_CA : "",
  es_WP_OT:"",
  es_SL_AD:"",
  es_WP_AL:"",
  es_HL_OT:"",
  es_NO_OT:"",
  es_OT_AL:"",
  es_WP_HR: "",
  es_SP_AL: "",
  es_WP_BS : "",
  es_PH_AL:"",
  es_LE_TA:"",
  name:"",
  ec_PY_CL:"",
  eo_OV_DT:"",
  es_PY_FR:"",

};

const initialFilters = {
  es_YE_AR: { value: "", operation: "" },
  es_MN_TH: { value: "", operation: "" },
  es_EM_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },
  es_CO_CT: { value: "", operation: "" },
  em_DE_PT: { value: "", operation: "" },
  es_PY_CL: { value: "", operation: "" },
  es_PY_FR: { value: "", operation: "" },
  es_TO_SL: { value: "", operation: "" },
  es_BA_SC: { value: "", operation: "" },
  es_HO_RA: { value: "", operation: "" },
};

const SalaryDetailsMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const [Salarydetailtable, setSalarydetailtable] = useState([{}]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [es_YE_AR, setes_YE_AR] = useState("");
  const [es_MN_TH, setes_MN_TH] = useState("");
  const [es_PY_CL, setes_PY_CL] = useState("");
  const [es_PY_FR, setes_PY_FR] = useState("");
  const [esUkNo, setesUkNo] = useState("");
  const [es_EM_NO, setes_EM_NO] = useState("");
  const [es_CO_CT, setes_CO_CT] = useState("");

const buildSearchRequestDto = () => {
  return Object.keys(filters)
    .filter((column) => filters[column].value)
    .map((column) => ({
      column: column.toUpperCase(),
      value: filters[column].value,
      operation: filters[column].operation,
    }));
};

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setSalarydetailtable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setesUkNo(null);
    setes_CO_CT(null);
    setes_YE_AR(null);
    setes_MN_TH(null);
    setes_PY_CL(null);
    setes_PY_FR(null);
    setOpen(true);
  };

  const handleClose = () => {
    setSalarydetailtable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setesUkNo(null);
    setes_CO_CT(null);
    setes_YE_AR(null);
    setes_MN_TH(null);
    setes_PY_CL(null);
    setes_PY_FR(null);
    setOpen(false);
  };

  const addRow = () => {
    setSalarydetailtable([...Salarydetailtable, {}]);
  };

  const deleteRow = () => {
    if (Salarydetailtable.length > 0) {
      const updatedData = [...Salarydetailtable];
      updatedData.pop();
      setSalarydetailtable(updatedData);
    }
  };

  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/SalaryDetails/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          delete FormData.esUkNo;
          const SalaryDetailsData = response.data;
          delete SalaryDetailsData.esUkNo;
          delete SalaryDetailsData.salaryDetails[0].liLeNo;
          for (let i = 0; i < SalaryDetailsData.salaryDetails.length; i++) {
            delete SalaryDetailsData.salaryDetails[i].esUkNo;
          }
          setSalarydetailtable(SalaryDetailsData.salaryDetails);
          setFormData(SalaryDetailsData.salaryDetails[0]);
          setSelectedemEMNO(SalaryDetailsData.name);
          setes_CO_CT(SalaryDetailsData.es_CO_CT);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/SalaryDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setes_YE_AR(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setes_MN_TH(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setes_CO_CT(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setes_PY_FR(value);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/SalaryDetails/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const SalaryDetailsData = response.data;
        setSalarydetailtable(SalaryDetailsData.salaryDetails);
        setFormData(SalaryDetailsData.salaryDetails[0]);
        setSelectedemEMNO(SalaryDetailsData.name);
        setes_CO_CT(SalaryDetailsData.es_CO_CT);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBody = {};
  function values () {
    const table = document.getElementById("Salarydetailtable");
    const totalRowCount1 = table.tBodies[0].rows.length;
    const salaryDetails = [];
    for (let i = 0; i < totalRowCount1; i++) {
      const details = {
        esUkNo : document.getElementById("esUkNo_" + i).value,
        es_EM_NO : document.getElementById("es_EM_NO_" + i).value,
        es_BA_SC : document.getElementById("es_BA_SC_" + i).value,
        es_HO_RA : document.getElementById("es_HO_RA_" + i).value,
        travel : document.getElementById("travel_" + i).value,
        food : document.getElementById("food_" + i).value,
        es_PH_AL : document.getElementById("es_PH_AL_" + i).value,
        other : document.getElementById("other_" + i).value,
        es_TO_SL : document.getElementById("es_TO_SL_" + i).value,
        es_WP_BS : document.getElementById("es_WP_BS_" + i).value,
        es_WP_HR : document.getElementById("es_WP_HR_" + i).value,
        es_WP_AL : document.getElementById("es_WP_AL_" + i).value,
      };
      salaryDetails.push(details);
    }
   requestBody = {
    salaryDetails,
    esUkNo: FormData.esUkNo,
    es_YE_AR: document.getElementById("es_YE_AR").value,
    es_MN_TH: document.getElementById("es_MN_TH").value,
    es_PY_FR: document.getElementById("es_PY_FR").value,
    es_CO_CT: FormData.es_CO_CT,
    };
  };
  const handleFormSubmit = () => {
    values ();
    if (FormData.esUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/SalaryDetails/Update`;
      if (confirm) {
        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/SalaryDetails/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      {/* <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
      </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button> */}
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
      </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div  className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["es_EM_NO"] ? filters["es_EM_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "es_EM_NO",
                        e.target.value,
                        filters["es_EM_NO"]
                          ? filters["es_EM_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["es_YE_AR"] ? filters["es_YE_AR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "es_YE_AR",
                        e.target.value,
                        filters["es_YE_AR"]
                          ? filters["es_YE_AR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["es_MN_TH"] ? filters["es_MN_TH"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "es_MN_TH",
                        e.target.value,
                        filters["es_MN_TH"]
                          ? filters["es_MN_TH"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["es_CO_CT"] ? filters["es_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "es_CO_CT",
                        e.target.value,
                        filters["es_CO_CT"]
                          ? filters["es_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_DE_PT"] ? filters["em_DE_PT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_DE_PT",
                        e.target.value,
                        filters["em_DE_PT"]
                          ? filters["em_DE_PT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["es_PY_FR"] ? filters["es_PY_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "es_PY_FR",
                        e.target.value,
                        filters["es_PY_FR"]
                          ? filters["es_PY_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["es_TO_SL"] ? filters["es_TO_SL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "es_TO_SL",
                        e.target.value,
                        filters["es_TO_SL"]
                          ? filters["es_TO_SL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["es_BA_SC"] ? filters["es_BA_SC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "es_BA_SC",
                        e.target.value,
                        filters["es_BA_SC"]
                          ? filters["es_BA_SC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["es_HO_RA"] ? filters["es_HO_RA"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "es_HO_RA",
                        e.target.value,
                        filters["es_HO_RA"]
                          ? filters["es_HO_RA"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Employee ID</th>
                <th style={tableHeaderStyle}>Year</th>
                <th style={tableHeaderStyle}>Month</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Department</th>
                <th style={tableHeaderStyle}>Pay Frequency</th>
                <th style={tableHeaderStyle}>Total Salary</th>
                <th style={tableHeaderStyle}>Basic</th>
                <th style={tableHeaderStyle}>HRA</th>
           
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.esUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.esUkNo}
                        checked={selectedRows.includes(rowData.esUkNo)}
                        onChange={() => handleRowSelect(rowData.esUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.es_EM_NO}</td>
                    <td style={tableCellStyle}>{rowData.es_YE_AR}</td>
                    <td style={tableCellStyle}>{rowData.es_MN_TH}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{rowData.es_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.em_DE_PT}</td>
                    <td style={tableCellStyle}>{rowData.es_PY_FR}</td>
                    <td style={tableCellStyle}>{rowData.es_TO_SL}</td>
                    <td style={tableCellStyle}>{rowData.es_BA_SC}</td>
                    <td style={tableCellStyle}>{rowData.es_HO_RA}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <SalaryDetailsDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        Salarydetailtable={Salarydetailtable} 
        addRow={addRow} 
        deleteRow={deleteRow}  
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        es_YE_AR={es_YE_AR}
        es_MN_TH={es_MN_TH}
        es_PY_CL={es_PY_CL}
        es_PY_FR={es_PY_FR}
        es_EM_NO={es_EM_NO}
        es_CO_CT={es_CO_CT}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}  
      />
    </div>
  );
};

export default SalaryDetailsMain;
