import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ServiceOrderDialog from "./ServiceOrderDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  srUkNo:"",
  sr_OR_NO:"",
  sr_OR_TY:"",
  sr_OR_CO:"",
  sr_CO_CT:"",
  sr_PR_NO:"",
  sr_PR_TY:"",
  sr_CU_NO:"",
  sr_CU_RF:"",
  sr_CR_CD:"",
  sr_EX_RT:"",
  sr_BS_CR:"",
  sr_HL_CD:"",
  sr_OR_DT:moment(moment().toDate()).format("YYYYMMDD"),
  sr_RQ_DT:moment(moment().toDate()).format("YYYYMMDD"),
  sr_PY_TM:"",
  sr_BL_NM:"",
  sr_BL_TY:"",
  sr_IT_NM:"",
  sr_CO_ST:"",
  sr_MK_PE:"",
  sr_MK_AM:"",
  sr_AM_DM:"",
  sr_ST_CD:"",
  sr_FR_DT:"",
  sr_TO_DT:"",
  sr_IN_DT:"",
  sr_DO_NO:"",
  sr_DO_TY:"",
  sr_EM_NO:"",
  sr_EQ_NM:"",
 
};

const initialFilters = {
  sr_OR_NO:{ value: "", operation: "" },
  sr_OR_TY:{ value: "", operation: "" },
  sr_OR_CO:{ value: "", operation: "" },
  sr_CO_CT:{ value: "", operation: "" },
  sr_PR_NO:{ value: "", operation: "" },
  sr_PR_TY:{ value: "", operation: "" },
  sr_CU_NO:{ value: "", operation: "" },
  sr_CU_RF:{ value: "", operation: "" },
  sr_CR_CD:{ value: "", operation: "" },
  sr_EX_RT:{ value: "", operation: "" },
  sr_BS_CR:{ value: "", operation: "" },
  sr_HL_CD:{ value: "", operation: "" },
  sr_OR_DT:{ value: "", operation: "" },
  sr_RQ_DT:{ value: "", operation: "" },
  sr_PY_TM:{ value: "", operation: "" },
  sr_BL_NM:{ value: "", operation: "" },
  sr_BL_TY:{ value: "", operation: "" },
  sr_IT_NM:{ value: "", operation: "" },
  sr_CO_ST:{ value: "", operation: "" },
  sr_MK_PE:{ value: "", operation: "" },
  sr_MK_AM:{ value: "", operation: "" },
  sr_AM_DM:{ value: "", operation: "" },
  sr_ST_CD:{ value: "", operation: "" },
  sr_FR_DT:{ value: "", operation: "" },
  sr_TO_DT:{ value: "", operation: "" },
  sr_IN_DT:{ value: "", operation: "" },
  sr_DO_NO:{ value: "", operation: "" },
  sr_DO_TY:{ value: "", operation: "" },
  sr_EM_NO:{ value: "", operation: "" },
  sr_EQ_NM:{ value: "", operation: "" },
};

const WorkCentreMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const CostCentreDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsr_CO_CT(value);
  };

  // drodpdowns
  const [sr_CO_CT, setsr_CO_CT] = useState("");
  const [sr_CR_CD, setsr_CR_CD ] = useState("");
  const [sr_PY_TM, setsr_PY_TM] = useState("");
  const [sr_EX_RT, setsr_EX_RT] = useState("");
  const [sr_OR_NO, setsr_OR_NO] = useState("");
  const [ic_CO_CT, setic_CO_CT ] = useState("");
  const [ic_CO_TY, setic_CO_TY ] = useState("");

  const onchangegldate = (Event) => {
    const { value, name } = Event.target;
     setFormData({ ...FormData, [name]: value });
     onChnageExchangeRate(sr_CR_CD,value);
  };

  const CurrencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsr_CR_CD(value);
    onChnageExchangeRate(value, FormData.sr_OR_DT);
  };


  function onChnageExchangeRate(frCr, ReceiveDate) {
    const exData = {
      "cr_TO_CR":  document.getElementById("sr_BS_CR").value, 
      "cr_FR_CR": frCr,
      "cr_EF_DT": ReceiveDate
    }
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("sr_EX_RT").value = dataExRt.cr_CO_MR;
          setsr_EX_RT(dataExRt.cr_CO_MR);
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });

  }
  
// Advancesearch
  const [SelectedemEMNO, setSelectedemEMNO ] = useState("");
  const [SelectedemEMNOC, setSelectedemEMNOC ] = useState("");
  const [SelectedemEMNOD, setSelectedemEMNOD ] = useState("");
  const [SelectedemEMNOE, setSelectedemEMNOE ] = useState("");
  const [SelectedemEMNO1, setSelectedemEMNO1 ] = useState("");
  const [selectedAbAdNM, setSelectedAbAdNM ] = useState("");
  const [SelectedItem, setselectedItem ] = useState("");
  const [SelectedDesc, setselectedDesc ] = useState("");
  const [SelectedItem1, setselectedItem1 ] = useState("");
  const [SelectedDesc1, setselectedDesc1 ] = useState("");

// Next No
  const [sr_SH_TO, setsr_SH_TO ] = useState("");




  const [ServiceOrderTable, setServiceOrderTable] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleAddButtonClick = () => {
    setServiceOrderTable([{}]);
    setFormData(initialValue);
    setsr_PY_TM(null);
    setsr_EX_RT(null);
    setsr_CR_CD(null);
    setsr_PR_TY(null);
    setsr_HL_CD(null);
    setsr_BL_TY(null);
    setSelectedemEMNO(null);
    setSelectedAbAdNM(null);
    setSelectedemEMNOE(null);
    setSelectedemEMNOD(null);
    setSelectedemEMNOC(null);
    setselectedItem(null);
    setselectedDesc(null);
    setprice_AMOUNT(null);
    setdiscount_AMOUNT(null);
    setnet_AMOUNT(null);
    settax_AMOUNT(null);
    settotal(null);
    setfr_PRICE_AMOUNT(null);
    setfr_DISCOUNT_AMOUNT(null);
    setfr_NET_AMOUNT(null);
    setfr_TAX_AMOUNT(null);
    setfr_TOTAL(null);
    setOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setServiceOrderTable([{}]);
    setFormData(initialValue);
    setsr_PY_TM(null);
    setsr_EX_RT(null);
    setsr_CR_CD(null);
    setsr_PR_TY(null);
    setsr_HL_CD(null);
    setsr_BL_TY(null);
    setSelectedemEMNO(null);
    setSelectedAbAdNM(null);
    setSelectedemEMNOE(null);
    setSelectedemEMNOD(null);
    setSelectedemEMNOC(null);
    setselectedItem(null);
    setselectedDesc(null);
    setprice_AMOUNT(null);
    setdiscount_AMOUNT(null);
    setnet_AMOUNT(null);
    settax_AMOUNT(null);
    settotal(null);
    setfr_PRICE_AMOUNT(null);
    setfr_DISCOUNT_AMOUNT(null);
    setfr_NET_AMOUNT(null);
    setfr_TAX_AMOUNT(null);
    setfr_TOTAL(null);
    setOpen(false);
  };

  const addRow = () => {
    setServiceOrderTable([...ServiceOrderTable, {}]);
  };
  const deleteRow = () => {
    if (ServiceOrderTable.length > 0) {
      const updatedData = [...ServiceOrderTable];
      updatedData.pop();
      setServiceOrderTable(updatedData);
    }
  };
  


  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };


  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [sr_OR_CO , setsr_OR_CO] = useState("");
  const [sr_OR_TY , setsr_OR_TY] = useState("");
  const [sr_BS_CR , setsr_BS_CR] = useState("");
  const [sr_BL_TY , setsr_BL_TY] = useState("");
  const [sr_PR_TY , setsr_PR_TY] = useState("");
  const [sr_HL_CD , setsr_HL_CD] = useState("");
  const [sr_ST_CD , setsr_ST_CD] = useState("");
  const [selectedEQ , setselectedEQ] = useState("");
  const [selectedNM , setselectedNM] = useState("");

  const [SelectedProjectMaster , setSelectedProjectMaster] = useState("");


  const [price_AMOUNT , setprice_AMOUNT] = useState("");
  const [discount_AMOUNT , setdiscount_AMOUNT] = useState("");
  const [net_AMOUNT , setnet_AMOUNT] = useState("");
  const [tax_AMOUNT , settax_AMOUNT] = useState("");
  const [total , settotal] = useState("");
  const [fr_PRICE_AMOUNT , setfr_PRICE_AMOUNT] = useState("");
  const [fr_DISCOUNT_AMOUNT , setfr_DISCOUNT_AMOUNT] = useState("");
  const [fr_NET_AMOUNT , setfr_NET_AMOUNT] = useState("");
  const [fr_TAX_AMOUNT , setfr_TAX_AMOUNT] = useState("");
  const [fr_TOTAL , setfr_TOTAL] = useState("");
 

  const handleDefault = async () => {
    setsr_OR_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "SR_OR_TY"){
              setsr_OR_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SR_BS_CR"){
              setsr_BS_CR(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "IC_CO_CT"){
              setic_CO_CT(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "IC_CO_TY"){
              setic_CO_TY(dataDefault.versionIds[i].default_VALUE)
            }
           
            if(fName === "SR_ST_CD"){
              setsr_ST_CD(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SR_CO_CT"){
              CostCentreDropDown("sr_CO_CT",dataDefault.versionIds[i].default_VALUE);
              setsr_CO_CT(dataDefault.versionIds[i].default_VALUE);
            } 
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ServiceOrder/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value, index) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
    const fieldName=(FormData && FormData.srUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      ServiceOrderTable[index][fieldName]=value;
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ServiceOrder/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const WorkCentreData = response.data;
        setServiceOrderTable(WorkCentreData.serviceOrders);
        setFormData(WorkCentreData.serviceOrders[0]);
        setsr_CO_CT(WorkCentreData.serviceOrders[0].sr_CO_CT);
        setsr_PY_TM(WorkCentreData.serviceOrders[0].sr_PY_TM);
        setsr_EX_RT(WorkCentreData.serviceOrders[0].sr_EX_RT);
        setsr_CR_CD(WorkCentreData.serviceOrders[0].sr_CR_CD);
        setsr_PR_TY(WorkCentreData.serviceOrders[0].sr_PR_TY);
        setsr_HL_CD(WorkCentreData.serviceOrders[0].sr_HL_CD);
        setsr_SH_TO(WorkCentreData.serviceOrders[0].sr_SH_TO);
        setsr_BL_TY(WorkCentreData.serviceOrders.sr_BL_TY);
        setSelectedProjectMaster(WorkCentreData.proj_NAME);
        setSelectedemEMNO(WorkCentreData.cus_NAME);
        setSelectedAbAdNM(WorkCentreData.shipto_NAME);
        setselectedItem(WorkCentreData.serviceOrders.sr_IT_NM);
        setselectedEQ(WorkCentreData.serviceOrders.sr_EQ_NM);
        setselectedNM(WorkCentreData.serviceOrders.equiDES);
        setselectedDesc(WorkCentreData.itemDES);
        setselectedItem1(WorkCentreData.serviceOrders.sr_BL_NM);
        setselectedDesc1(WorkCentreData.serviceOrders.itemDES1);
        setSelectedemEMNO1(WorkCentreData.serviceOrders.name);
        setprice_AMOUNT(WorkCentreData.total_AMOUNT);
        setdiscount_AMOUNT(WorkCentreData.total_DISCOUNT);
        settax_AMOUNT(WorkCentreData.total_TAX);
        setfr_PRICE_AMOUNT(WorkCentreData.total_FOREIGN_AMOUNT);
        setfr_DISCOUNT_AMOUNT(WorkCentreData.total_FOREIGN_DISCOUNT);
        setfr_TAX_AMOUNT(WorkCentreData.total_FOREIGN_TAX);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ServiceOrder/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const WorkCentreData = response.data;
          delete WorkCentreData.serviceOrders[0].sr_OR_NO;
          delete WorkCentreData.serviceOrders[0].srUkNo;
          for (let i = 0; i < WorkCentreData.serviceOrders.length; i++) {
            delete WorkCentreData.serviceOrders[i].srUkNo;
            WorkCentreData.serviceOrders[i].sr_ST_CD = sr_ST_CD;
          }
          setServiceOrderTable(WorkCentreData.serviceOrders);
          setFormData(WorkCentreData.serviceOrders[0]);
          setsr_CO_CT(WorkCentreData.serviceOrders[0].sr_CO_CT);
          setsr_PY_TM(WorkCentreData.serviceOrders[0].sr_PY_TM);
          setsr_EX_RT(WorkCentreData.serviceOrders[0].sr_EX_RT);
          setsr_CR_CD(WorkCentreData.serviceOrders[0].sr_CR_CD);
          setsr_PR_TY(WorkCentreData.serviceOrders[0].sr_PR_TY);
          setsr_HL_CD(WorkCentreData.serviceOrders[0].sr_HL_CD);
          setsr_SH_TO(WorkCentreData.serviceOrders[0].sr_SH_TO);
          setsr_BL_TY(WorkCentreData.serviceOrders.sr_BL_TY);
          setSelectedProjectMaster(WorkCentreData.proj_NAME);
          setSelectedemEMNO(WorkCentreData.cus_NAME);
          setSelectedAbAdNM(WorkCentreData.shipto_NAME);
          setselectedItem(WorkCentreData.serviceOrders.sr_IT_NM);
          setselectedEQ(WorkCentreData.serviceOrders.sr_EQ_NM);
          setselectedNM(WorkCentreData.serviceOrders.equiDES);
          setselectedDesc(WorkCentreData.itemDES);
          setselectedItem1(WorkCentreData.serviceOrders.sr_BL_NM);
          setselectedDesc1(WorkCentreData.serviceOrders.itemDES1);
          setSelectedemEMNO1(WorkCentreData.serviceOrders.name);
          setprice_AMOUNT(WorkCentreData.total_AMOUNT);
          setdiscount_AMOUNT(WorkCentreData.total_DISCOUNT);
          settax_AMOUNT(WorkCentreData.total_TAX);
          setfr_PRICE_AMOUNT(WorkCentreData.total_FOREIGN_AMOUNT);
          setfr_DISCOUNT_AMOUNT(WorkCentreData.total_FOREIGN_DISCOUNT);
          setfr_TAX_AMOUNT(WorkCentreData.total_FOREIGN_TAX);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("ServiceOrderTable");
  const totalRowCount = table.tBodies[0].rows.length;
  const serviceOrders = [];
  for (let i = 0; i < totalRowCount; i++) {
    const FR_DT = document.getElementsByName("sr_FR_DT_" + i)[0];
    const TO_DT = document.getElementsByName("sr_TO_DT_" + i)[0];
    const IN_DT = document.getElementsByName("sr_IN_DT_" + i)[0];
    const details = {
        srUkNo : document.getElementById("srUkNo_" + i).value,
        sr_BL_NM : document.getElementById("sr_BL_NM_" + i).value,
        itemDES1 : document.getElementById("itemDES1_" + i).value,
        sr_BL_TY : table.tBodies[0].rows[i].cells[3].children[0].children[1].children[0].value,
        sr_IT_NM : document.getElementById("sr_IT_NM_" + i).value,
        itemDES : document.getElementById("itemDES_" + i).value,
        sr_CO_ST : document.getElementById("sr_CO_ST_" + i).value,
        sr_MK_PE : document.getElementById("sr_MK_PE_" + i).value,
        sr_MK_AM : document.getElementById("sr_MK_AM_" + i).value,
        sr_AM_DM : document.getElementById("sr_AM_DM_" + i).value,
        sr_DS_PE : document.getElementById("sr_DS_PE_" + i).value,
        sr_DS_AM : document.getElementById("sr_DS_AM_" + i).value,
        sr_AM_FR : document.getElementById("sr_AM_FR_" + i).value,
        sr_DS_FR : document.getElementById("sr_DS_FR_" + i).value,
        sr_TX_FR : document.getElementById("sr_TX_FR_" + i).value,
        sr_TX_PE : document.getElementById("sr_TX_PE_" + i).value,
        sr_TX_AM : document.getElementById("sr_TX_AM_" + i).value,
        sr_FR_DT : FR_DT.value?moment(FR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        sr_TO_DT : TO_DT.value?moment(TO_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        sr_IN_DT : IN_DT.value?moment(IN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        sr_EQ_NM : document.getElementById("sr_EQ_NM_" + i).value,
        sr_EM_NO : document.getElementById("sr_EM_NO_" + i).value,
        sr_ST_CD : document.getElementById("sr_ST_CD_" + i).value,
    };
    serviceOrders.push(details);
  }
  requestBody = {
    serviceOrders,
    srUkNo   : FormData.srUkNo,
    sr_OR_NO : document.getElementById("sr_OR_NO").value,
    sr_OR_TY : document.getElementById("sr_OR_TY").value,
    sr_OR_CO : document.getElementById("sr_OR_CO").value,
    sr_OR_DT : FormData.sr_OR_DT,
    sr_RQ_DT : FormData.sr_RQ_DT,
    sr_CO_CT : sr_CO_CT,
    sr_CU_NO : document.getElementById("sr_CU_NO").value,
    sr_PY_TM : FormData.sr_PY_TM,
    sr_CR_CD : sr_CR_CD,
    sr_EX_RT : document.getElementById("sr_EX_RT").value,
    sr_BS_CR : document.getElementById("sr_BS_CR").value,
    sr_SH_TO : document.getElementById("sr_SH_TO").value,
    sr_CU_RF : document.getElementById("sr_CU_RF").value,
    sr_PR_NO : document.getElementById("sr_PR_NO").value,
    sr_PR_TY : FormData.sr_PR_TY,
    sr_HL_CD : FormData.sr_HL_CD,
  };
}
const handleFormSubmit = () => {
 values ()
  const orNo = FormData.sr_OR_NO;
  const ukNo = FormData.srUkNo;
  if (orNo) {
    if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/ServiceOrder/Update`;
  
      if (confirm) {
        axios.put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/ServiceOrder/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  } else {
      
      const OR_DT = FormData.sr_OR_DT;
      if (OR_DT) {
        const currentYear = OR_DT.toString();
        const firstHalf = currentYear.substring(0, 2);
        const secondHalf = currentYear.substring(2, 4);
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
        const parameters = `${sr_OR_TY}/${secondHalf}/1000/${firstHalf}`;
        const finalUrl1 = `${urlNextNumber}/${parameters}`;
        fetch(finalUrl1, {
          method: "GET",
          headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
        })
          .then((resp) => {
            if (!resp.ok) {
              throw new Error(`Request failed with status: ${resp.status}`);
            }
            return resp.json();
          })
          .then((resp) => {
            onchangeFormData("sr_OR_NO", resp.dn_DO_NO);
            setFormData({ ...FormData, ["sr_OR_NO"]: resp.dn_DO_NO });
            values();
            axios.post(`${domain}/api/ServiceOrder/Add`, requestBody, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error saving data:", error);
                });

          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sr_OR_NO"] ? filters["sr_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_OR_NO",
                        e.target.value,
                        filters["sr_OR_NO"]
                          ? filters["sr_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sr_OR_TY"] ? filters["sr_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_OR_TY",
                        e.target.value,
                        filters["sr_OR_TY"] ? filters["sr_OR_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sr_OR_CO"] ? filters["sr_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_OR_CO",
                        e.target.value,
                        filters["sr_OR_CO"]
                          ? filters["sr_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sr_CO_CT"] ? filters["sr_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_CO_CT",
                        e.target.value,
                        filters["sr_CO_CT"] ? filters["sr_CO_CT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sr_PR_NO"] ? filters["sr_PR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_PR_NO",
                        e.target.value,
                        filters["sr_PR_NO"]
                          ? filters["sr_PR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["sr_PR_TY"] ? filters["sr_PR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_PR_TY",
                        e.target.value,
                        filters["sr_PR_TY"]
                          ? filters["sr_PR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_CU_NO"] ? filters["sr_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_CU_NO",
                        e.target.value,
                        filters["sr_CU_NO"]
                          ? filters["sr_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_CU_RF"] ? filters["sr_CU_RF"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_CU_RF",
                        e.target.value,
                        filters["sr_CU_RF"]
                          ? filters["sr_CU_RF"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_CR_CD"] ? filters["sr_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_CR_CD",
                        e.target.value,
                        filters["sr_CR_CD"]
                          ? filters["sr_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_EX_RT"] ? filters["sr_EX_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_EX_RT",
                        e.target.value,
                        filters["sr_EX_RT"]
                          ? filters["sr_EX_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_BS_CR"] ? filters["sr_BS_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_BS_CR",
                        e.target.value,
                        filters["sr_BS_CR"]
                          ? filters["sr_BS_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_HL_CD"] ? filters["sr_HL_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_HL_CD",
                        e.target.value,
                        filters["sr_HL_CD"]
                          ? filters["sr_HL_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_OR_DT"] ? filters["sr_OR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_OR_DT",
                        e.target.value,
                        filters["sr_OR_DT"]
                          ? filters["sr_OR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_RQ_DT"] ? filters["sr_RQ_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_RQ_DT",
                        e.target.value,
                        filters["sr_RQ_DT"]
                          ? filters["sr_RQ_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_PY_TM"] ? filters["sr_PY_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_PY_TM",
                        e.target.value,
                        filters["sr_PY_TM"]
                          ? filters["sr_PY_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_BL_NM"] ? filters["sr_BL_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_BL_NM",
                        e.target.value,
                        filters["sr_BL_NM"]
                          ? filters["sr_BL_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_BL_TY"] ? filters["sr_BL_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_BL_TY",
                        e.target.value,
                        filters["sr_BL_TY"]
                          ? filters["sr_BL_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_IT_NM"] ? filters["sr_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_IT_NM",
                        e.target.value,
                        filters["sr_IT_NM"]
                          ? filters["sr_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_CO_ST"] ? filters["sr_CO_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_CO_ST",
                        e.target.value,
                        filters["sr_CO_ST"]
                          ? filters["sr_CO_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_MK_PE"] ? filters["sr_MK_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_MK_PE",
                        e.target.value,
                        filters["sr_MK_PE"]
                          ? filters["sr_MK_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_MK_AM"] ? filters["sr_MK_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_MK_AM",
                        e.target.value,
                        filters["sr_MK_AM"]
                          ? filters["sr_MK_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_AM_DM"] ? filters["sr_AM_DM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_AM_DM",
                        e.target.value,
                        filters["sr_AM_DM"]
                          ? filters["sr_AM_DM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_AM_FR"] ? filters["sr_AM_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_AM_FR",
                        e.target.value,
                        filters["sr_AM_FR"]
                          ? filters["sr_AM_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_ST_CD"] ? filters["sr_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_ST_CD",
                        e.target.value,
                        filters["sr_ST_CD"]
                          ? filters["sr_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_FR_DT"] ? filters["sr_FR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_FR_DT",
                        e.target.value,
                        filters["sr_FR_DT"]
                          ? filters["sr_FR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_TO_DT"] ? filters["sr_TO_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_TO_DT",
                        e.target.value,
                        filters["sr_TO_DT"]
                          ? filters["sr_TO_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_IN_DT"] ? filters["sr_IN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_IN_DT",
                        e.target.value,
                        filters["sr_IN_DT"]
                          ? filters["sr_IN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_DO_NO"] ? filters["sr_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_DO_NO",
                        e.target.value,
                        filters["sr_DO_NO"]
                          ? filters["sr_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_DO_TY"] ? filters["sr_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_DO_TY",
                        e.target.value,
                        filters["sr_DO_TY"]
                          ? filters["sr_DO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_EM_NO"] ? filters["sr_EM_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_EM_NO",
                        e.target.value,
                        filters["sr_EM_NO"]
                          ? filters["sr_EM_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sr_EQ_NM"] ? filters["sr_EQ_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sr_EQ_NM",
                        e.target.value,
                        filters["sr_EQ_NM"]
                          ? filters["sr_EQ_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Service No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>   
                <th style={tableHeaderStyle}>Cost Centre</th> 
                <th style={tableHeaderStyle}>Project No</th> 
                <th style={tableHeaderStyle}>Project Type</th> 
                <th style={tableHeaderStyle}>Customer</th> 
                <th style={tableHeaderStyle}>Customer Ref</th> 
                <th style={tableHeaderStyle}>Currency</th>  
                <th style={tableHeaderStyle}>Exchange Rate</th> 
                <th style={tableHeaderStyle}>Base Currency</th>  
                <th style={tableHeaderStyle}>Hold Code</th> 
                <th style={tableHeaderStyle}>Order Date</th> 
                <th style={tableHeaderStyle}>Requested Date</th> 
                <th style={tableHeaderStyle}>Payment Term</th> 
                <th style={tableHeaderStyle}>Bill Item</th> 
                <th style={tableHeaderStyle}>Bill Type</th> 
                <th style={tableHeaderStyle}>Item Name</th>  
                <th style={tableHeaderStyle}>Cost</th>
                <th style={tableHeaderStyle}>MarkUp %</th>
                <th style={tableHeaderStyle}>MarkUp Amount</th>
                <th style={tableHeaderStyle}>Amount</th> 
                <th style={tableHeaderStyle}>Amount-Foreign</th> 
                <th style={tableHeaderStyle}>Status</th> 
                <th style={tableHeaderStyle}>From Date</th> 
                <th style={tableHeaderStyle}>To Date</th> 
                <th style={tableHeaderStyle}>Invoice Date</th> 
                <th style={tableHeaderStyle}>Invoice No</th> 
                <th style={tableHeaderStyle}>Invoice Type</th> 
                <th style={tableHeaderStyle}>Employee No</th>  
                <th style={tableHeaderStyle}>Equipment Name</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.srUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.srUkNo}
                        checked={selectedRows.includes(rowData.srUkNo)}
                        onChange={() => handleRowSelect(rowData.srUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.sr_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.sr_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.sr_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.sr_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.sr_PR_NO}</td>   
                    <td style={tableCellStyle}>{rowData.sr_PR_TY}</td>   
                    <td style={tableCellStyle}>{rowData.sr_CU_NO}</td>   
                    <td style={tableCellStyle}>{rowData.sr_CU_RF}</td>   
                    <td style={tableCellStyle}>{rowData.sr_CR_CD}</td>
                    <td style={tableCellStyle}>{rowData.sr_EX_RT}</td>    
                    <td style={tableCellStyle}>{rowData.sr_BS_CR}</td> 
                    <td style={tableCellStyle}>{rowData.sr_HL_CD}</td>   
                    <td style={tableCellStyle}>{formatDate(rowData.sr_OR_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.sr_RQ_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.sr_PY_TM}</td> 
                    <td style={tableCellStyle}>{rowData.sr_BL_NM}</td>  
                    <td style={tableCellStyle}>{rowData.sr_BL_TY}</td>   
                    <td style={tableCellStyle}>{rowData.sr_IT_NM}</td> 
                    <td style={tableCellStyle}>{rowData.sr_CO_ST}</td>   
                    <td style={tableCellStyle}>{rowData.sr_MK_PE}</td>
                    <td style={tableCellStyle}>{rowData.sr_MK_AM}</td>
                    <td style={tableCellStyle}>{rowData.sr_AM_DM}</td>
                    <td style={tableCellStyle}>{rowData.sr_AM_FR}</td>
                    <td style={tableCellStyle}>{rowData.sr_ST_CD}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sr_FR_DT)}</td>   
                    <td style={tableCellStyle}>{formatDate(rowData.sr_TO_DT)}</td>  
                    <td style={tableCellStyle}>{formatDate(rowData.sr_IN_DT)}</td>
                    <td style={tableCellStyle}>{rowData.sr_DO_NO}</td>
                    <td style={tableCellStyle}>{rowData.sr_DO_TY}</td>   
                    <td style={tableCellStyle}>{rowData.sr_EM_NO}</td>
                    <td style={tableCellStyle}>{rowData.sr_EQ_NM}</td>   
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <ServiceOrderDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        ServiceOrderTable={ServiceOrderTable}
        setServiceOrderTable={setServiceOrderTable}
        CurrencyDropDown={CurrencyDropDown}
        onchangegldate={onchangegldate}
        sr_OR_CO={sr_OR_CO}
        sr_OR_TY={sr_OR_TY}
        sr_BS_CR={sr_BS_CR}
        sr_CO_CT={sr_CO_CT}
        setsr_CO_CT={setsr_CO_CT}
        sr_CR_CD={sr_CR_CD}
        setsr_CR_CD={setsr_CR_CD}
        sr_PY_TM={sr_PY_TM}
        setsr_PY_TM={setsr_PY_TM}
        sr_EX_RT={sr_EX_RT}
        setsr_EX_RT={setsr_EX_RT}
        addRow={addRow}
        deleteRow={deleteRow}
        SelectedItem={SelectedItem}
        setselectedItem={setselectedItem}
        SelectedDesc={SelectedDesc}
        setselectedDesc={setselectedDesc}
        SelectedItem1={SelectedItem1}
        setselectedItem1={setselectedItem1}
        SelectedDesc1={SelectedDesc1}
        setselectedDesc1={setselectedDesc1}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedemEMNOC={SelectedemEMNOC}
        setSelectedemEMNOC={setSelectedemEMNOC}
        SelectedemEMNOD={SelectedemEMNOD}
        setSelectedemEMNOD={setSelectedemEMNOD}
        SelectedemEMNOE={SelectedemEMNOE}
        setSelectedemEMNOE={setSelectedemEMNOE}
        SelectedemEMNO1={SelectedemEMNO1}
        setSelectedemEMNO1={setSelectedemEMNO1}
        selectedAbAdNM={selectedAbAdNM}
        setSelectedAbAdNM={setSelectedAbAdNM}
        ic_CO_TY={ic_CO_TY}
        ic_CO_CT={ic_CO_CT}
        sr_SH_TO={sr_SH_TO}
        setsr_SH_TO={setsr_SH_TO}
        sr_BL_TY={sr_BL_TY}
        setsr_BL_TY={setsr_BL_TY}
        sr_PR_TY={sr_PR_TY}
        setsr_PR_TY={setsr_PR_TY}
        sr_HL_CD={sr_HL_CD}
        setsr_HL_CD={setsr_HL_CD}
        selectedEQ={selectedEQ}
        setselectedEQ={setselectedEQ}
        selectedNM={selectedNM}
        setselectedNM={setselectedNM}
        sr_ST_CD={sr_ST_CD}
        SelectedProjectMaster={SelectedProjectMaster}
        setSelectedProjectMaster={setSelectedProjectMaster}
        price_AMOUNT={price_AMOUNT}
        setprice_AMOUNT={setprice_AMOUNT}
        discount_AMOUNT={discount_AMOUNT}
        setdiscount_AMOUNT={setdiscount_AMOUNT}
        setnet_AMOUNT={setnet_AMOUNT}
        net_AMOUNT={net_AMOUNT}
        settax_AMOUNT={settax_AMOUNT}
        tax_AMOUNT={tax_AMOUNT}
        settotal={settotal}
        total={total}
        fr_PRICE_AMOUNT={fr_PRICE_AMOUNT}
        setfr_PRICE_AMOUNT={setfr_PRICE_AMOUNT}
        fr_DISCOUNT_AMOUNT={fr_DISCOUNT_AMOUNT}
        setfr_DISCOUNT_AMOUNT={setfr_DISCOUNT_AMOUNT}
        fr_NET_AMOUNT={fr_NET_AMOUNT}
        setfr_NET_AMOUNT={setfr_NET_AMOUNT}
        fr_TAX_AMOUNT={fr_TAX_AMOUNT}
        setfr_TAX_AMOUNT={setfr_TAX_AMOUNT}
        fr_TOTAL={fr_TOTAL}
        setfr_TOTAL={setfr_TOTAL}
        CostCentreDropDown={CostCentreDropDown}
        
      />
    </div>
  );
};

export default WorkCentreMain;
