import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import CycleCountProcessDialog from "./CycleCountProcessDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  ccUkNo: "",
  cc_CN_NO:"",
  cc_CN_TY:"",
  cc_CN_CO:"",
  cc_CN_DT: moment(moment().toDate()).format("YYYYMMDD"),
  cc_IT_NM:"",
  item_DES:"",
  cc_CO_CT:"",
  cc_LO_CN:"",
  cc_LT_NO:"",
  cc_ON_QT:"",
  cc_PR_UM:"",
  cc_CN_QT:"",
  pr_QTY_VAR:"",
  pr_QTY_VAR_PER:"",
   cc_IT_CT:"",
   cc_ON_AM:"",
   cc_CN_AM:"",
   am_VAR:"",
   am_VAR_PER:"",
   cc_SC_QT:"",
   cc_CN_SC:"",
   sc_QTY_VAR:"",
   sc_QTY_VAR_PER:"",
   cc_LN_NO:"",	
};

const initialFilters = {
  cc_CN_NO: { value: "", operation: "" }, 
  cc_CN_TY: { value: "", operation: "" }, 
  cc_CN_CO: { value: "", operation: "" }, 
  cc_CN_DT: { value: "", operation: "" }, 
  cc_IT_NM: { value: "", operation: "" }, 
  item_DES: { value: "", operation: "" }, 
  cc_CO_CT: { value: "", operation: "" }, 
  cc_LO_CN: { value: "", operation: "" }, 
  cc_LT_NO: { value: "", operation: "" }, 
  cc_ON_QT: { value: "", operation: "" }, 
  cc_PR_UM: { value: "", operation: "" }, 
  cc_CN_QT: { value: "", operation: "" }, 
  pr_QTY_VAR: { value: "", operation: "" }, 
  pr_QTY_VAR_PER: { value: "", operation: "" }, 
   cc_IT_CT: { value: "", operation: "" }, 
   cc_ON_AM: { value: "", operation: "" }, 
   cc_CN_AM: { value: "", operation: "" }, 
   am_VAR: { value: "", operation: "" }, 
   am_VAR_PER: { value: "", operation: "" }, 
   cc_SC_QT: { value: "", operation: "" }, 
   cc_CN_SC: { value: "", operation: "" }, 
   sc_QTY_VAR: { value: "", operation: "" }, 
   sc_QTY_VAR_PER: { value: "", operation: "" }, 
   cc_LN_NO: { value: "", operation: "" }, 	
};

const WorkCentreMain = () => {
  // buttonstyles
  const { formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle, tableHeaderStyle,
    inputStyle, tableCellStyle, rightalined
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData1, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [cc_CN_TY, setcc_CN_TY] = useState("");
  const [pi_OR_TY, setpi_OR_TY] = useState("");
  const [cc_CN_NO, setcc_CN_NO] = useState("");
  
  // drodpdowns

  const [cc_CO_CT, setcc_CO_CT] = useState("");
  const [cc_PR_UM, setcc_PR_UM] = useState("");
  const [cc_LO_CN, setcc_LO_CN] = useState("");
  // AdvanceSearch
  const [item_DES, setitem_DES] = useState("");
 

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [CycleCountProcessTableData, setCycleCountProcessTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setCycleCountProcessTableData([{}]);
    setcc_CO_CT(null);
    setcc_PR_UM(null);
    setcc_LO_CN(null);
    setcc_CN_NO(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }

    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/CycleCountProcess/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const CashFlowData = response.data;
          const updatedCashFlowData = CashFlowData.cycleCountGenerationList.map((target) => {
            const UpdatedworkData = { ...target };
            delete UpdatedworkData.ccUkNo;
            return UpdatedworkData;
          });
          setCycleCountProcessTableData(updatedCashFlowData);
          setFormData(updatedCashFlowData[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [cc_CN_CO, setcc_CN_CO] = useState("");

  const [cc_CN_DT, setcc_CN_DT] = useState("");
  
  const handleDefault = async () => {
    setcc_CN_NO(`${company}`)
 
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];

    const requestBody = {
      "version_ID": LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if (dataDefault.versionIds[i].field_TYPE === "SCREEN") {
            const fName = dataDefault.versionIds[i].field_ID;
            if (fName === "CC_CN_TY") {
              setcc_CN_TY(dataDefault.versionIds[i].default_VALUE) 
            }
            
          }
          if (dataDefault.versionIds[i].field_TYPE === "LIST") {
            count++;
            const def = {
              "column": dataDefault.versionIds[i].field_ID,
              "value": dataDefault.versionIds[i].default_VALUE,
              "operation": "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault, count);
      } else {
        handleSearch(listDefault, count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists, nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for (let i = 0; i < nos; i++) {
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/CycleCountProcess/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/CycleCountProcess/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const PurchaseData = response.data;
        setCycleCountProcessTableData(PurchaseData.cycleCountGenerationList);
        setFormData(PurchaseData.cycleCountGenerationList[0]);
        setcc_CN_CO(PurchaseData.cc_CN_CO);
        setcc_CO_CT(PurchaseData.cc_CO_CT.toString());
        setcc_LO_CN(PurchaseData.cc_LO_CN.toString());
        setcc_PR_UM(PurchaseData.cycleCountGenerationList.cc_PR_UM);
        setitem_DES(PurchaseData.cycleCountGenerationList.item_DES); 
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBody = {};
  function values() {
    const table = document.getElementById("CycleCountProcessTableData");
    const totalRowCount1 = table.tBodies[0].rows.length;
    const cycleCountGenerationList = [];
    for (let i = 0; i < totalRowCount1; i++) {
      const details = { 
        ccUkNo: document.getElementById("ccUkNo_" + i).value,
        cc_LN_NO: document.getElementById("cc_LN_NO_" + i).value,
        cc_LT_NO: document.getElementById("cc_LT_NO_" + i).value,
        cc_ON_QT: document.getElementById("cc_ON_QT_" + i).value,
        cc_PR_UM : table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
        cc_CN_QT : document.getElementById("cc_CN_QT_" + i).value,
        cc_IT_CT: document.getElementById("cc_IT_CT_" + i).value,
        cc_ON_AM: document.getElementById("cc_ON_AM_" + i).value,
        cc_CN_AM: document.getElementById("cc_CN_AM_" + i).value,
        cc_SC_QT: document.getElementById("cc_SC_QT_" + i).value,
        cc_CN_SC: document.getElementById("cc_CN_SC_" + i).value,
      };
      cycleCountGenerationList.push(details);
    }
    requestBody = {

      cycleCountGenerationList,
      ccUkNo: FormData1.ccUkNo,
      cc_CN_NO: FormData1.cc_CN_NO,
      cc_CN_TY: FormData1.cc_CN_TY,
      cc_CN_CO: FormData1.cc_CN_CO,
      cc_CN_DT: FormData1.cc_CN_DT,
      cc_CO_CT: cc_CO_CT,
      cc_LO_CN: cc_LO_CN,
      cc_CN_DT: cc_CN_DT,

    };
  }
  const handleFormSubmit = (type) => {
    values();
    if (FormData1.cc_CN_NO) {
      if (FormData1.ccUkNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/CycleCountProcess/Update`;
        if (confirm) {
          axios
            .put(update, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      }
      else if (FormData1.ccUkNo && type == "process") {
        const confirm = window.confirm("Do you want to Process ?");
        const process = `${domain}/api/CycleCountProcess/UpdateCycleCountProcess`;
        if (confirm) {
          FormData1.pi_OR_TY = pi_OR_TY;

          axios
            .post(process, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      

      } else {
        FormData1.ep_CO_NO = company;
        axios
          .post(`${domain}/api/CycleCountProcess/Add`, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    } else {
      const TR_DT = FormData1.cc_CN_DT;
      if (TR_DT) {
        const currentYear = TR_DT.toString();
        const firstHalf = currentYear.substring(0, 2);
        const secondHalf = currentYear.substring(2, 4);
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
        const parameters = `${cc_CN_TY}/${secondHalf}/${company}/${firstHalf}`; 
        const finalUrl1 = `${urlNextNumber}/${parameters}`;
        fetch(finalUrl1, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        })
          .then((resp) => {
            if (!resp.ok) {
              throw new Error(`Request failed with status: ${resp.status}`);
            }
            return resp.json();
          })
          .then((resp) => {
            onchangeFormData("cc_CN_NO", resp.dn_DO_NO);
            setcc_CN_NO(resp.dn_DO_NO);
            setFormData({ ...FormData1, ["cc_CN_NO"]: resp.dn_DO_NO });
            axios
              .post(`${domain}/api/CycleCountProcess/Add`, requestBody, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error saving data:", error);
              });

          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }

  };


  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack >
        <div style={{ background: "red !important", color: "white", fontSize: "10px" }} >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

 
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_CN_NO"] ? filters["cc_CN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_CN_NO",
                        e.target.value,
                        filters["cc_CN_NO"]
                          ? filters["cc_CN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_CN_TY"] ? filters["cc_CN_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_CN_TY",
                        e.target.value,
                        filters["cc_CN_TY"] ? filters["cc_CN_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_CN_CO"] ? filters["cc_CN_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_CN_CO",
                        e.target.value,
                        filters["cc_CN_CO"]
                          ? filters["cc_CN_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["cc_CN_DT"] ? filters["cc_CN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_CN_DT",
                        e.target.value,
                        filters["cc_CN_DT"]
                          ? filters["cc_CN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_IT_NM"] ? filters["cc_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_IT_NM",
                        e.target.value,
                        filters["cc_IT_NM"] ? filters["cc_IT_NM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["item_DES"] ? filters["item_DES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "item_DES",
                        e.target.value,
                        filters["item_DES"]
                          ? filters["item_DES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_CO_CT"] ? filters["cc_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_CO_CT",
                        e.target.value,
                        filters["cc_CO_CT"]
                          ? filters["cc_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle} 
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_LO_CN"] ? filters["cc_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_LO_CN",
                        e.target.value,
                        filters["cc_LO_CN"]
                          ? filters["cc_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_LT_NO"] ? filters["cc_LT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_LT_NO",
                        e.target.value,
                        filters["cc_LT_NO"]
                          ? filters["cc_LT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_ON_QT"] ? filters["cc_ON_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_ON_QT",
                        e.target.value,
                        filters["cc_ON_QT"]
                          ? filters["cc_ON_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_PR_UM"] ? filters["cc_PR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_PR_UM",
                        e.target.value,
                        filters["cc_PR_UM"]
                          ? filters["cc_PR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_CN_QT"] ? filters["cc_CN_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_CN_QT",
                        e.target.value,
                        filters["cc_CN_QT"]
                          ? filters["cc_CN_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["pr_QTY_VAR"] ? filters["pr_QTY_VAR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_QTY_VAR",
                        e.target.value,
                        filters["pr_QTY_VAR"]
                          ? filters["pr_QTY_VAR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_QTY_VAR_PER"] ? filters["pr_QTY_VAR_PER"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_QTY_VAR_PER",
                        e.target.value,
                        filters["pr_QTY_VAR_PER"]
                          ? filters["pr_QTY_VAR_PER"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_IT_CT"] ? filters["cc_IT_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_IT_CT",
                        e.target.value,
                        filters["cc_IT_CT"]
                          ? filters["cc_IT_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_ON_AM"] ? filters["cc_ON_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_ON_AM",
                        e.target.value,
                        filters["cc_ON_AM"]
                          ? filters["cc_ON_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_CN_AM"] ? filters["cc_CN_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_CN_AM",
                        e.target.value,
                        filters["cc_CN_AM"]
                          ? filters["cc_CN_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["am_VAR"] ? filters["am_VAR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "am_VAR",
                        e.target.value,
                        filters["am_VAR"]
                          ? filters["am_VAR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["am_VAR_PER"] ? filters["am_VAR_PER"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "am_VAR_PER",
                        e.target.value,
                        filters["am_VAR_PER"]
                          ? filters["am_VAR_PER"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_SC_QT"] ? filters["cc_SC_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_SC_QT",
                        e.target.value,
                        filters["cc_SC_QT"]
                          ? filters["cc_SC_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_CN_SC"] ? filters["cc_CN_SC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_CN_SC",
                        e.target.value,
                        filters["cc_CN_SC"]
                          ? filters["cc_CN_SC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sc_QTY_VAR"] ? filters["sc_QTY_VAR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sc_QTY_VAR",
                        e.target.value,
                        filters["sc_QTY_VAR"]
                          ? filters["sc_QTY_VAR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sc_QTY_VAR_PER"] ? filters["sc_QTY_VAR_PER"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sc_QTY_VAR_PER",
                        e.target.value,
                        filters["sc_QTY_VAR_PER"]
                          ? filters["sc_QTY_VAR_PER"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_LN_NO"] ? filters["cc_LN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_LN_NO",
                        e.target.value,
                        filters["cc_LN_NO"]
                          ? filters["cc_LN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Count No	</th>
                <th style={tableHeaderStyle}>Typr</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Date</th>
                <th style={tableHeaderStyle}>Item Number</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Cost Center</th>
                <th style={tableHeaderStyle}>Location</th>
                <th style={tableHeaderStyle}>Lot Numbe</th>
                <th style={tableHeaderStyle}>On Hand Qty</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Counted Qty</th>
                <th style={tableHeaderStyle}>Qty Variance</th>
                <th style={tableHeaderStyle}>Qty Variance %</th>
                <th style={tableHeaderStyle}>Unit Cost</th>
                <th style={tableHeaderStyle}>On Hand Amount</th>
                <th style={tableHeaderStyle}>Counted Amount</th>
                <th style={tableHeaderStyle}>Amt Variance</th>
                <th style={tableHeaderStyle}>Amt Variance %</th>
                <th style={tableHeaderStyle}>Sec Qty</th>
                <th style={tableHeaderStyle}>Counted Sec Qty</th>
                <th style={tableHeaderStyle}>Sec Qty Variance</th>
                <th style={tableHeaderStyle}>Sec Qty Variance %	</th>
                <th style={tableHeaderStyle}>Line No</th> 
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.ccUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.ccUkNo}
                        checked={selectedRows.includes(rowData.ccUkNo)}
                        onChange={() => handleRowSelect(rowData.ccUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.cc_CN_NO}</td>
                    <td style={tableCellStyle}>{rowData.cc_CN_TY}</td>
                    <td style={tableCellStyle}>{rowData.cc_CN_CO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.cc_CN_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.cc_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.item_DES}</td>
                    <td style={tableCellStyle}>{rowData.cc_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.cc_LO_CN}</td>
                    <td style={tableCellStyle}>{rowData.cc_LT_NO}</td>
                    <td style={tableCellStyle}>{rowData.cc_ON_QT}</td>
                    <td style={tableCellStyle}>{rowData.cc_PR_UM}</td>
                    <td style={tableCellStyle}>{rowData.cc_CN_QT}</td>
                    <td style={tableCellStyle}>{rowData.pr_QTY_VAR}</td>
                    <td style={tableCellStyle}>{rowData.pr_QTY_VAR_PER}</td>
                    <td style={tableCellStyle}>{rowData.cc_IT_CT}</td>
                    <td style={tableCellStyle}>{rowData.cc_ON_AM}</td>
                    <td style={tableCellStyle}>{rowData.cc_CN_AM}</td>
                    <td style={tableCellStyle}>{rowData.am_VAR}</td>
                    <td style={tableCellStyle}>{rowData.am_VAR_PER}</td>
                    <td style={tableCellStyle}>{rowData.cc_SC_QT}</td>
                    <td style={tableCellStyle}>{rowData.cc_CN_SC}</td>
                    <td style={tableCellStyle}>{rowData.sc_QTY_VAR}</td>
                    <td style={tableCellStyle}>{rowData.sc_QTY_VAR_PER}</td>
                    <td style={tableCellStyle}>{rowData.cc_LN_NO}</td>

                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <CycleCountProcessDialog
        open={open}
        handleClose={handleClose}
        data={FormData1}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        CycleCountProcessTableData={CycleCountProcessTableData}
        setCycleCountProcessTableData={setCycleCountProcessTableData}
        item_DES={item_DES}
        setitem_DES={setitem_DES}
        cc_CN_NO={cc_CN_NO}
        setcc_CN_NO={setcc_CN_NO}
        cc_CN_DT={cc_CN_DT}
        setcc_CN_DT={setcc_CN_DT}
        cc_PR_UM={cc_PR_UM}
        setcc_PR_UM={setcc_PR_UM}
        cc_LO_CN={cc_LO_CN}
        setcc_LO_CN={setcc_LO_CN}
        cc_CO_CT={cc_CO_CT}
        setcc_CO_CT={setcc_CO_CT}
        cc_CN_TY={cc_CN_TY}
        setcc_CN_TY={setcc_CN_TY}
        cc_CN_CO={cc_CN_CO}
        setcc_CN_CO={setcc_CN_CO}
      />
    </div>
  );
};

export default WorkCentreMain;
