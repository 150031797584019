import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import AddressPopup from "./AddressPopup";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
export default function UserDetailsDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  // onchangedata,
  onchangeFormData,
  onchangeDropDownFormData4,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData5,
  SelectedemEMNO,
  setSelectedemEMNO,
  addRow,
  deleteRow,
  UserDetailstable,
  usrcompany,
  adname,
  setadname,

}) {
  const { 
    username,
    password,
    language,
    date_FORMAT,
    countrty,
    enabled,
    address_NO,
    name,
    id,
 
  } = data;
  const [languageOptions, setlanguageOptions] = useState([]);
  const [date_FORMATOptions, setdate_FORMATOptions] = useState([]);
  const [countrtyOptions, setcountrtyOptions] = useState([]);
  const [enabledOptions, setenabledOptions] = useState([]);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData2(name, value, index);
  };
  const handleFormDataChange2 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData3(name, value, index);
  };
  const handleFormDataChange3 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData4(name, value, index);
  };
  const handleFormDataChange4 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData5(name, value, index);
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "rc_ST_DT"  || name === "rc_EN_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);
  
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };

const url = `${domain}/api/ClassCode/All`;
 
const parameter1 = "FO/LN";
const finalUrl1 = `${url}/${parameter1}`;

const parameter2 = "FO/DF";
const finalUrl2 = `${url}/${parameter2}`;

const parameter3 = "FO/CY";
const finalUrl3 = `${url}/${parameter3}`;

const parameter4 = "FO/EN";
const finalUrl4 = `${url}/${parameter4}`;

useEffect(() => {
  fetchDropdownOptions1();
  fetchDropdownOptions2();
  fetchDropdownOptions3();
  fetchDropdownOptions4();
    
}, []);

  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setlanguageOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setdate_FORMATOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl3, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcountrtyOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 4th dropdown
const fetchDropdownOptions4= async () => {
  try {
    const response = await axios.get(finalUrl4, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setenabledOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

const [AddressPopupVisible, setAddressPopupVisible] = useState(false);
const openAddressPopup = (e) => {
  setAddressPopupVisible(true);
  setSelectedRowIndex(e);
};

const handleDataSelect = (data) => {
  setSelectedemEMNO(data.ab_AD_NM);
  onchangeFormData(`address_NO_${SelectedRowIndex}`, data.abAdNo);
  onchangeFormData(`name_${SelectedRowIndex}`, data.ab_AD_NM);
  setAddressPopupVisible(false);
  setShowSearchIcon(false);
};

const isUpdateMode = Boolean(id);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={id ? "Revise User Details" : "Add User Details"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          marginTop: "2%",
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          overflow: "auto",
        }}
      >
     <div className="formgrid grid">
     <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
        <label htmlFor="usrcompany" className="font-bold">
         Company
        </label>
            <InputText disabled
              id="usrcompany"
              name="usrcompany"
              value={usrcompany}
              onChange={(e) => onchangedata(e)}
            />
          </div>
     </div>
     </div>
{/* table */}
  <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            marginTop: "2%",
          }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
            position: "relative",
          }}
        >
        <table className="custom-table" id="UserDetailstable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>User Name </th>
          <th>Password</th>
          <th>Address No<span style={{ color: 'red'}}>*</span> </th>
          <th>Name</th>
          <th>Language</th>
          <th>Date Format</th>
          <th>Country</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
      {UserDetailstable.map((row, index) => (
          <tr key={index}>
          <td style={{ display: "none" }} >
            <InputText
            id={`id_${index}`}
            name={`id_${index}`}
            defaultValue={row.id}
            />
          </td>
          <td>
          <InputText style={{width:"150px"}}
            id={`username_${index}`}
            name={`username_${index}`}
            defaultValue={row.username}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"150px"}}
            id={`password_${index}`}
            name={`password_${index}`}
            defaultValue={row.password}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td > 
          <div style={{ display: "flex",width:"150px"}}>
          <InputText
          style={{width:"130px"}}
          id={`address_NO_${index}`}
          name={`address_NO_${index}`}
          onFocus={() => setShowSearchIcon(true)}
          value={row.address_NO ? row.address_NO: data[`${"address_NO"}_${index}`]}
          onChange={(e) => onchangedata(e)}
          />
          {showSearchIcon && (
           <i
          className="pi pi-search"
          style={{
          color: "var(--primary-color)",
          fontSize: "119%",
          marginTop: "7%",
          cursor: "pointer",
          }}
          onClick={(e) => openAddressPopup(index)}
          ></i>
          )}
          </div>
          </td>
          <td>
          <InputText
           style={{width:"170px"}}
          id={`name_${index}`}
          name={`name_${index}`}
          onChange={(e) => onchangedata(e)}
          onFocus={() => setShowSearchIcon(true)}
          value={row.name ? row.name : data[`${"name"}_${index}`]}
          />
          </td>
          <td>
          <Dropdown
          id={`language_${index}`}
          name={`language_${index}`}
          value={data[`language_${index}`] ? data[`language_${index}`]:row.language }
          options={languageOptions}
          onChange={(e) => handleFormDataChange1(`language_${index}`, e.value)}
          placeholder="Select Language"
          />
          </td>
          <td>
          <Dropdown
          id={`date_FORMAT_${index}`}
          name={`date_FORMAT_${index}`}
          value={data[`date_FORMAT_${index}`] ? data[`date_FORMAT_${index}`]:row.date_FORMAT }
          options={date_FORMATOptions}
          onChange={(e) => handleFormDataChange2(`date_FORMAT_${index}`, e.value)}
          placeholder="Select Date Format"
          />
          </td>
          <td>
          <Dropdown
          id={`countrty_${index}`}
          name={`countrty_${index}`}
          value={data[`countrty_${index}`] ? data[`countrty_${index}`]:row.countrty }
          options={countrtyOptions}
          onChange={(e) => handleFormDataChange3(`countrty_${index}`, e.value)}
          placeholder="Select Country"
          />
          </td>
          <td>
          <Dropdown
            id={`enabled_${index}`}
            name={`enabled_${index}`}
            value={data[`enabled_${index}`] ? data[`enabled_${index}`]:row.enabled }
            options={enabledOptions}
            onChange={(e) => handleFormDataChange4(`enabled_${index}`, e.value)}
            placeholder="Select Status"
          />
          </td>
          </tr>
           ))}
      </tbody>
    </table> 
    </div>
  </div>   
      <AddressPopup
      visible={AddressPopupVisible}
      onClose={() => setAddressPopupVisible(false)}
      onDataSelect={handleDataSelect}
      formData={data}
    />
  </Dialog>
  );
}
