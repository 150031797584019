import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import moment from "moment";
import axios from "axios";
import { Calendar } from "primereact/calendar";
export default function AssetMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  nextNumType,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  fm_CO_NO,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNOD,
  setSelectedemEMNOD,
  SelectedemEMNO1,
  setSelectedemEMNO1,
  SelectedemEMNOE,
  setSelectedemEMNOE,
  SelectedemEMNOF,
  setSelectedemEMNOF,
  SelectedemEMNOG,
  setSelectedemEMNOG,
  SelectedemEMNO2,
  setSelectedemEMNO2,
  SelectedemEMNO3,
  setSelectedemEMNO3,
  fm_AT_ST,
  setfm_AT_ST,
  fm_CO_NM,
  setfm_CO_NM,
}) {
  const {
    fmUkNo,
    fm_AT_NO,
    fm_AT_D1,
    fm_AT_D2,
    fm_DT_AC,
    fm_AT_SL,
    fm_AT_LO,
    fm_DP_MT,
    fm_DP_SD,
    fm_AT_LF,
    fm_CL_01,
    fm_CL_02,
    fm_CL_03,
    fm_CL_04,
    fm_CL_05,
    fm_DP_AC,
    fm_CO_AC,
    fm_AD_AC,
    fm_GL_AC,
    assetCostAccountDes,
  } = data;
  const [fm_AT_STOptions, setfm_AT_STOptions] = useState([]);
  const [fm_CO_NMOptions, setfm_CO_NMOptions] = useState([]);
  const [fm_DP_MTOptions, setfm_DP_MTOptions] = useState([]);
  const [fm_CL_01Options, setfm_CL_01Options] = useState([]);
  const [fm_CL_02Options, setfm_CL_02Options] = useState([]);
  const [fm_CL_03Options, setfm_CL_03Options] = useState([]);
  const [fm_CL_04Options, setfm_CL_04Options] = useState([]);
  const [fm_CL_05Options, setfm_CL_05Options] = useState([]);

  const [activeTab, setActiveTab] = useState(0);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl2 = `${urlcost}`;

  const url = `${domain}/api/ClassCode/All`;
  // designation
  const parameter2 = "FA/AS";
  const finalUrl1 = `${url}/${parameter2}`;
  // departmemt
  const parameter3 = "FA/DM";
  const finalUrl3 = `${url}/${parameter3}`;
  // pay class
  const parameter4 = "FA/1";
  const finalUrl4 = `${url}/${parameter4}`;
  // grade
  const parameter5 = "FA/2";
  const finalUrl5 = `${url}/${parameter5}`;
  // Pay Frequency
  const parameter6 = "FA/3";
  const finalUrl6 = `${url}/${parameter6}`;
    // grade
    const parameter7 = "FA/4";
    const finalUrl7= `${url}/${parameter7}`;
    // Pay Frequency
    const parameter8 = "FA/5";
    const finalUrl8 = `${url}/${parameter8}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
    fetchDropdownOptions8();
  }, []);

// 1st dropdowns
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setfm_AT_STOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 2nd dropdowns
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setfm_CO_NMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl3, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setfm_DP_MTOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 4th dropdown
const fetchDropdownOptions4 = async () => {
  try {
    const response = await axios.get(finalUrl4, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setfm_CL_01Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 5th dropdown
const fetchDropdownOptions5 = async () => {
  try {
    const response = await axios.get(finalUrl5, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setfm_CL_02Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 6th dropdown
const fetchDropdownOptions6 = async () => {
  try {
    const response = await axios.get(finalUrl6, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setfm_CL_03Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 5th dropdown
const fetchDropdownOptions7 = async () => {
  try {
    const response = await axios.get(finalUrl7, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setfm_CL_04Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 6th dropdown
const fetchDropdownOptions8 = async () => {
  try {
    const response = await axios.get(finalUrl8, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setfm_CL_05Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

const [SelectedRowIndex, setSelectedRowIndex] = useState("");
const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
const openAccountPopup = (e) => {
  setAccountPopupVisible(true);
  setSelectedRowIndex(e);
};
const [AccountclrPopupVisible, setAccountclrPopupVisible] = useState(false);
const openAccountclrPopup = (e) => {
  setAccountclrPopupVisible(true);
  setSelectedRowIndex(e);
};
const [AccountsalPopupVisible, setAccountsalPopupVisible] = useState(false);
const openAccountsalPopup = (e) => {
  setAccountsalPopupVisible(true);
  setSelectedRowIndex(e);
};
const [AccountdepPopupVisible, setAccountdepPopupVisible] = useState(false);
const openAccountdepPopup = (e) => {
  setAccountdepPopupVisible(true);
  setSelectedRowIndex(e);
};

const handleDataSelect1 = (data) => {
  setSelectedemEMNO(data.account);
  setSelectedemEMNOD(data.am_AC_DS);
  onchangeFormData("fm_CO_AC", data.account);
  onchangeFormData("assetCostAccountDes", data.am_AC_DS);
  setAccountclrPopupVisible(false);
  setShowSearchIcon(false);
};
const handleDataSelect = (data) => {
  setSelectedemEMNO1(data.account);
  setSelectedemEMNOE(data.am_AC_DS);
  onchangeFormData("fm_AD_AC", data.account);
  onchangeFormData("assetAccumulatedDes", data.am_AC_DS);
  setAccountPopupVisible(false);
  setShowSearchIcon(false);
};
const handleDataSelect2 = (data) => {
  setSelectedemEMNO2(data.account);
  setSelectedemEMNOF(data.am_AC_DS);
  onchangeFormData("fm_DP_AC", data.account);
  onchangeFormData("assetDepreciationDes", data.am_AC_DS);
  setAccountdepPopupVisible(false);
  setShowSearchIcon(false);
};

const handleDataSelect3 = (data) => {
  setSelectedemEMNO3(data.account);
  setSelectedemEMNOG(data.am_AC_DS);
  onchangeFormData("fm_GL_AC", data.account);
  onchangeFormData("glacdes", data.am_AC_DS);
  setAccountsalPopupVisible(false);
  setShowSearchIcon(false);
};
const isUpdateMode = Boolean(fmUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

 
  const [showSearchIcon, setShowSearchIcon] = useState("");
  // date default
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "fm_DT_AC", value: moment(e.value).format("YYYYMMDD") } });
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={fmUkNo ? "Revise Asset Master" : "Add  Asset Master"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Asset Details">
            {/* tab1 general */}

           
              <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
            
              <div className="formgrid grid">
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_AT_NO" className="font-bold">
                  Asset No
                  </label>
                  <InputText disabled
                    id="fm_AT_NO"
                    name="fm_AT_NO"
                    defaultValue={fm_AT_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_AT_D1" className="font-bold">
                  Asset Description
                  </label>
                  <InputText
                    id="fm_AT_D1"
                    name="fm_AT_D1"
                    value={fm_AT_D1}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_AT_D2" className="font-bold">
                  Asset Description 2
                  </label>
                  <InputText
                    id="fm_AT_D2"
                    name="fm_AT_D2"
                    value={fm_AT_D2}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_AT_ST" className="font-bold">
                   Asset Status
                  </label>
                 <Dropdown
                  id="fm_AT_ST"
                  name="fm_AT_ST"
                  value={fm_AT_ST}
                  options={fm_AT_STOptions}
                  onChange={(e) => onchangeDropDownFormData1("fm_AT_ST", e.value)}
                  placeholder="Select Status"
                />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_CO_NO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="fm_CO_NO"
                    name="fm_CO_NO"
                    value={fm_CO_NO}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_DT_AC" className="font-bold">
                  Date Acquired
                  </label>
                  <Calendar
                    id="fm_DT_AC"
                    name="fm_DT_AC"
                    value={moment(fm_DT_AC, "YYYYMMDD").toDate()}
                    onChange={handleDateChange}
                    dateFormat="dd/mm/yy"
                  />
                </div>                
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_AT_SL" className="font-bold">
                    Asset Serial No
                  </label>
                  <InputText
                    id="fm_AT_SL"
                    name="fm_AT_SL"
                    value={fm_AT_SL}
                    onChange={(e) => onchangedata(e)}
                  />
                 
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_AT_LO" className="font-bold">
                    Location
                  </label>
                  <InputText
                    id="fm_AT_LO"
                    name="fm_AT_LO"
                    value={fm_AT_LO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_CO_NM" className="font-bold">
                    Cost Centre
                  </label>
                  <Dropdown
                    id="fm_CO_NM"
                    name="fm_CO_NM"
                    value={fm_CO_NM}
                    options={fm_CO_NMOptions}
                    onChange={(e) => onchangeDropDownFormData2("fm_CO_NM", e.value)}
                    placeholder="Select Cost Center"
                  />
                  
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_DP_MT" className="font-bold">
                    Deprecation Method
                  </label>
                  <Dropdown
                    id="fm_DP_MT"
                    name="fm_DP_MT"
                    value={fm_DP_MT}
                    options={fm_DP_MTOptions}
                    onChange={(e) => onchangeDropDownFormData3("fm_DP_MT", e.value)}
                    placeholder="Select Dep Method"
                  />
                  
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_DP_SD" className="font-bold">
                  Depreciation Start Date
                  </label>
                  <Calendar
                    id="fm_DP_SD"
                    name="fm_DP_SD"
                    value={moment(data.fm_DP_SD, "YYYYMMDD").isValid() ? moment(data.fm_DP_SD, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "fm_DP_SD", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_AT_LF" className="font-bold">
                    Life in Months
                  </label>
                  <InputText
                    id="fm_AT_LF"
                    name="fm_AT_LF"
                    value={fm_AT_LF}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          {/* tab2 */}
          <TabPanel header="Asset Accounts">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_CO_AC" className="font-bold">
                 Asset Cost Account <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="fm_CO_AC"
                      name="fm_CO_AC"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedemEMNO}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openAccountclrPopup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="assetCostAccountDes" className="font-bold">
                  Description
                  </label>
                  <InputText
                    id="assetCostAccountDes"
                    name="assetCostAccountDes"
                    value={SelectedemEMNOD}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
                <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_AD_AC" className="font-bold">
                 Accumulated Deprecation Account <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="fm_AD_AC"
                      name="fm_AD_AC"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedemEMNO1}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openAccountPopup}
                    ></i>
                    )}
                  </div>
                 
                </div>
                <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="assetAccumulatedDes" className="font-bold">
                  Description
                  </label>
                  <InputText
                    id="assetAccumulatedDes"
                    name="assetAccumulatedDes"
                    value={SelectedemEMNOE}
                   readOnly
                  />
            </div>
                <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_DP_AC" className="font-bold">
                  Depreciation Account <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="fm_DP_AC"
                      name="fm_DP_AC"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedemEMNO2}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openAccountdepPopup}
                    ></i>
                    )}
                  </div>
                  
                </div>
                <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="assetDepreciationDes" className="font-bold">
                  Description
                  </label>
                  <InputText
                    id="assetDepreciationDes"
                    name="assetDepreciationDes"
                    value={SelectedemEMNOF}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
                <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_GL_AC" className="font-bold">
                  Gain/Loss on sales Account <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="fm_GL_AC"
                      name="fm_GL_AC"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedemEMNO3}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openAccountsalPopup}
                    ></i>
                    )}
                  </div>
                  
                </div>
                <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                  <label htmlFor="glacdes" className="font-bold">
                  Description
                  </label>
                  <InputText
                    id="glacdes"
                    name="glacdes"
                    value={SelectedemEMNOG}
                   readOnly
                  />
            </div>
                </div>
              </div>
          </TabPanel>
          <TabPanel header="Class Codes">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
             <div className="formgrid grid">
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_CL_01" className="font-bold">
                  Class Code 1
                  </label>
                  <Dropdown
                    id="fm_CL_01"
                    name="fm_CL_01"
                    value={fm_CL_01}
                    options={fm_CL_01Options}
                    onChange={(e) => onchangeDropDownFormData4("fm_CL_01", e.value)}
                    placeholder="Select Class Code 01"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_CL_02" className="font-bold">
                  Class Code 2
                  </label>
                  <Dropdown
                    id="fm_CL_02"
                    name="fm_CL_02"
                    value={fm_CL_02}
                    options={fm_CL_02Options}
                    onChange={(e) => onchangeDropDownFormData5("fm_CL_02", e.value)}
                    placeholder="Select Class Code 02"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_CL_03" className="font-bold">
                  Class Code 3
                  </label>
                  <Dropdown
                    id="fm_CL_03"
                    name="fm_CL_03"
                    value={fm_CL_03}
                    options={fm_CL_03Options}
                    onChange={(e) => onchangeDropDownFormData6("fm_CL_03", e.value)}
                    placeholder="Select Class Code 03"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_CL_04" className="font-bold">
                  Class Code 4
                  </label>
                  <Dropdown
                    id="fm_CL_04"
                    name="fm_CL_04"
                    value={fm_CL_04}
                    options={fm_CL_04Options}
                    onChange={(e) => onchangeDropDownFormData7("fm_CL_04", e.value)}
                    placeholder="Select Class Code 04"
                  />
                  
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_CL_05" className="font-bold">
                  Class Code 5
                  </label>
                  <Dropdown
                    id="fm_CL_05"
                    name="fm_CL_05"
                    value={fm_CL_05}
                    options={fm_CL_05Options}
                    onChange={(e) => onchangeDropDownFormData8("fm_CL_05", e.value)}
                    placeholder="Select Class Code 05"
                  />
                </div>
                
              </div>
              </div>
          </TabPanel>
      </TabView>
      </div>    
      <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      /> 
       <AccountNoPopup
        visible={AccountclrPopupVisible}
        onClose={() => setAccountclrPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
       <AccountNoPopup
        visible={AccountdepPopupVisible}
        onClose={() => setAccountdepPopupVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
      />
       <AccountNoPopup
        visible={AccountsalPopupVisible}
        onClose={() => setAccountsalPopupVisible(false)}
        onDataSelect={handleDataSelect3}
        formData={data}
      />
    </Dialog>
  );
}
