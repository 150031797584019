import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import WorkRequisitionDialog from "./WorkRequisitionDialog";
import axios from "axios";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
const initialValue = {
  wr_RQ_TY: "",
  wr_ST_CD: "", 
  wr_EX_SL: "", 
  wr_RQ_NO: "",
  wr_JB_PO: "", 
  wr_PY_GR: "", 
  wr_RQ_BY: "", 
  wr_DE_PT: "", 
  wr_TR_CR: "", 
  wr_RQ_DS: "", 
  wr_PY_CL: "", 
  wrUkNo: "", 
  wr_EF_DT: "",
  wr_RQ_DT: moment(moment().toDate()).format("YYYYMMDD"), 
  wr_AP_BY: "", 
  wr_RQ_CO: "", 
  wr_AP_DT: "", 
  wr_ET_DT: "",
  wr_JB_TY: "",
};

const initialFilters = {
  wr_RQ_NO: { value: "", operation: "" },
  wr_RQ_TY: { value: "", operation: "" },
  wr_RQ_CO: { value: "", operation: "" },
  wr_TR_CR: { value: "", operation: "" },
  wr_RQ_DT: { value: "", operation: "" },
  wr_RQ_DS: { value: "", operation: "" },
  wr_DE_PT: { value: "", operation: "" },
  wr_JB_PO: { value: "", operation: "" },
  wr_JB_TY: { value: "", operation: "" },
  wr_RQ_BY: { value: "", operation: "" },
  wr_AP_BY: { value: "", operation: "" },
  wr_ST_CD: { value: "", operation: "" },
 
};

const WorkRequisitionMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedAbAdNN, setSelectedAbAdNN] = useState("");
  const [wr_DE_PT, setwr_DE_PT] = useState("");
  const [wr_PY_CL, setwr_PY_CL] = useState([]);
  const [wr_JB_PO, setwr_JB_PO] = useState([]);
  const [wr_JB_TY, setwr_JB_TY] = useState([]);
  const [wr_RQ_NO, setwr_RQ_NO] = useState("");  
  const [wr_RQ_TY, setwr_RQ_TY] = useState("");
  const [wr_PY_GR, setwr_PY_GR] = useState("");
  const [wr_RQ_CO, setwr_RQ_CO] = useState("");
  const [wr_TR_CR, setwr_TR_CR] = useState("");
  const [wr_ST_CD, setwr_ST_CD] = useState("");
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedAbAdNN(null);
    setwr_RQ_NO(null);
    setwr_JB_TY(null);
    setwr_JB_PO(null);
    setwr_TR_CR(null);
    setwr_PY_GR(null);
    setwr_DE_PT(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedAbAdNN(null);
    setwr_JB_TY(null);
    setwr_JB_PO(null);    
    setwr_DE_PT(null);
    setOpen(false);
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/WorkForceRequisition/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const WorkForceRequisitionData = response.data;
          delete WorkForceRequisitionData.wrUkNo;
          delete WorkForceRequisitionData.wr_RQ_NO;
          setFormData(WorkForceRequisitionData);
          setSelectedemEMNO(response.data.approved_NAME);
          setSelectedAbAdNN(response.data.req_NAME);
          setwr_DE_PT(response.data.wr_DE_PT);
          setwr_JB_PO(response.data.wr_JB_PO);
          setwr_JB_TY(response.data.wr_JB_TY);
          setwr_PY_GR(response.data.wr_PY_GR);
          setwr_PY_CL(response.data.wr_PY_CL);
          setwr_RQ_NO(response.data.wr_RQ_NO);
          setwr_TR_CR(response.data.wr_TR_CR);
          setwr_ST_CD(response.data.wr_ST_CD);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);


  const handleDefault = async () => {
    setwr_RQ_CO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "WR_RQ_TY"){
              setwr_RQ_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "WR_ST_CD"){
              onchangeDropDownFormData4("wr_ST_CD",dataDefault.versionIds[i].default_VALUE);
              setwr_ST_CD(dataDefault.versionIds[i].default_VALUE);
            } 
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/WorkForceRequisition/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setwr_DE_PT(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setwr_PY_GR(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setwr_PY_CL(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setwr_ST_CD(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setwr_JB_PO(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setwr_JB_TY(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setwr_TR_CR(value);
  };


  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/WorkForceRequisition/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const WorkForceRequisitionData = response.data;
        setFormData(WorkForceRequisitionData);
        setSelectedemEMNO(response.data.approved_NAME);
        setSelectedAbAdNN(response.data.req_NAME);
        setwr_DE_PT(response.data.wr_DE_PT);
        setwr_JB_PO(response.data.wr_JB_PO);
        setwr_JB_TY(response.data.wr_JB_TY);
        setwr_PY_GR(response.data.wr_PY_GR);
        setwr_PY_CL(response.data.wr_PY_CL);
        setwr_RQ_NO(response.data.wr_RQ_NO);
        setwr_TR_CR(response.data.wr_TR_CR);
        setwr_ST_CD(response.data.wr_ST_CD);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  var requestBodyInput = {};
  function values() {
     requestBodyInput = {
      wrUkNo: FormData.wrUkNo,
      wr_RQ_NO: document.getElementById("wr_RQ_NO").value,
      wr_RQ_TY: document.getElementById("wr_RQ_TY").value, 
      wr_RQ_CO: company,
      wr_RQ_DS: document.getElementById("wr_RQ_DS").value,
      wr_DE_PT: FormData.wr_DE_PT,
      wr_RQ_DT: FormData.wr_RQ_DT,
      wr_EF_DT: FormData.wr_EF_DT,
      wr_AP_DT: FormData.wr_AP_DT,
      wr_JB_PO: FormData.wr_JB_PO,
      wr_ET_DT: FormData.wr_ET_DT,
      wr_JB_TY: FormData.wr_JB_TY,
      wr_PY_GR: FormData.wr_PY_GR,
      wr_RQ_BY: FormData.wr_RQ_BY,
      wr_PY_CL: FormData.wr_PY_CL, 
      wr_AP_BY: FormData.wr_AP_BY,
      wr_EX_SL: FormData.wr_EX_SL,
      wr_TR_CR: FormData.wr_TR_CR,
      wr_ST_CD: wr_ST_CD,
    }
  };
  const handleFormSubmit = () => {
    values();
    if (FormData.wr_RQ_NO) {
      if (FormData.wrUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/WorkForceRequisition/Update`;
      if (confirm) {
        axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/WorkForceRequisition/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
      } else {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const firstHalf = Math.floor(currentYear / 100);
        const secondHalf = String(currentYear % 100).padStart(2, "0");
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
        const parameters = `${wr_RQ_TY}/${secondHalf}/${company}/${firstHalf}`;
        const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            setwr_RQ_NO(resp.dn_DO_NO);
              document.getElementById("wr_RQ_NO").value = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/WorkForceRequisition/Add`, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
    
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" , backgroundColor: "#1ed01e", border: "none"}}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wr_RQ_NO"] ? filters["wr_RQ_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_RQ_NO",
                        e.target.value,
                        filters["wr_RQ_NO"]
                          ? filters["wr_RQ_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wr_RQ_TY"] ? filters["wr_RQ_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_RQ_TY",
                        e.target.value,
                        filters["wr_RQ_TY"]
                          ? filters["wr_RQ_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wr_RQ_CO"] ? filters["wr_RQ_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_RQ_CO",
                        e.target.value,
                        filters["wr_RQ_CO"]
                          ? filters["wr_RQ_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wr_TR_CR"] ? filters["wr_TR_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_TR_CR",
                        e.target.value,
                        filters["wr_TR_CR"]
                          ? filters["wr_TR_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["wr_RQ_DT"] ? filters["wr_RQ_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_RQ_DT",
                        e.target.value,
                        filters["wr_RQ_DT"]
                          ? filters["wr_RQ_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wr_RQ_DS"] ? filters["wr_RQ_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_RQ_DS",
                        e.target.value,
                        filters["wr_RQ_DS"]
                          ? filters["wr_RQ_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["wr_DE_PT"] ? filters["wr_DE_PT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_DE_PT",
                        e.target.value,
                        filters["wr_DE_PT"]
                          ? filters["wr_DE_PT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wr_JB_PO"] ? filters["wr_JB_PO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_JB_PO",
                        e.target.value,
                        filters["wr_JB_PO"]
                          ? filters["wr_JB_PO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wr_JB_TY"] ? filters["wr_JB_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_JB_TY",
                        e.target.value,
                        filters["wr_JB_TY"]
                          ? filters["wr_JB_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wr_RQ_BY"] ? filters["wr_RQ_BY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_RQ_BY",
                        e.target.value,
                        filters["wr_RQ_BY"]
                          ? filters["wr_RQ_BY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["wr_AP_BY"] ? filters["wr_AP_BY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_AP_BY",
                        e.target.value,
                        filters["wr_AP_BY"]
                          ? filters["wr_AP_BY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wr_ST_CD"] ? filters["wr_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_ST_CD",
                        e.target.value,
                        filters["wr_ST_CD"]
                          ? filters["wr_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
               
             
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Requisition No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Requisition Date</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Department</th>
                <th style={tableHeaderStyle}>Position</th>
                <th style={tableHeaderStyle}>Job Type</th>
                <th style={tableHeaderStyle}>Requested By</th>
                <th style={tableHeaderStyle}>Approved By</th>
                <th style={tableHeaderStyle}>Status</th>
                
           
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.wrUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.wrUkNo}
                        checked={selectedRows.includes(rowData.wrUkNo)}
                        onChange={() => handleRowSelect(rowData.wrUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.wr_RQ_NO}</td>
                    <td style={tableCellStyle}>{rowData.wr_RQ_TY}</td>
                    <td style={tableCellStyle}>{rowData.wr_RQ_CO}</td>
                    <td style={tableCellStyle}>{rowData.wr_TR_CR}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.wr_RQ_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.wr_RQ_DS}</td>
                    <td style={tableCellStyle}>{rowData.departmentDES}</td>
                    <td style={tableCellStyle}>{rowData.positionDES}</td>
                    <td style={tableCellStyle}>{rowData.jobTypeDES}</td>
                    <td style={tableCellStyle}>{rowData.req_NAME}</td>
                    <td style={tableCellStyle}>{rowData.approved_NAME}</td>
                    <td style={tableCellStyle}>{rowData.status}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
    </div>

      <WorkRequisitionDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        wr_DE_PT={wr_DE_PT}
        wr_JB_PO={wr_JB_PO}
        wr_JB_TY={wr_JB_TY}
        wr_RQ_TY={wr_RQ_TY}
        wr_PY_GR={wr_PY_GR}
        wr_RQ_CO={wr_RQ_CO}
        wr_PY_CL={wr_PY_CL}
        setwr_RQ_CO={setwr_RQ_CO}
        wr_TR_CR={wr_TR_CR}
        wr_ST_CD={wr_ST_CD}
        wr_RQ_NO={wr_RQ_NO}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedAbAdNN={SelectedAbAdNN}
        setSelectedAbAdNN={setSelectedAbAdNN}
        setFormData={setFormData}
      />
    </div>
  );
};

export default WorkRequisitionMain;
