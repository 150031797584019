import React, { useState, useEffect, useRef } from "react";
import "components/ListStyle/ListStyle.css";
import Chart from "chart.js/auto";
import { Pie } from 'react-chartjs-2';
import { Dropdown } from "primereact/dropdown";
import { Select } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import axios from "axios";

const UnoccupiedUnitDetails = ({
    visible,
    onClose,
    selectedYear,
    selectedMonth,
}) => {
const [hoveredCell, setHoveredCell] = useState(null);
const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    width:"0%",
    };
const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
};

    const [open, setOpen] = useState(false);
    const [dashboardData, setDashboardData] = useState({
        UNOCCUPIED_UNITS: [],
    });
    const token = localStorage.getItem("token");
    const company = localStorage.getItem("company");
    const domain = localStorage.getItem("domain");

    const dashboard = `${domain}/api/Dashboard/RealEstateDashboard`;
    const REdashboard = async () => {
        const requestinput = {
            "year":selectedYear ,
            "month": selectedMonth,
        };
        try {
            const response = await axios.post(dashboard, requestinput, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
            });
            const data = response.data;
            setDashboardData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleClose = () => {
        onClose();
        setOpen(false);
    };

    useEffect(() => {
        REdashboard();
        setOpen(visible);
    }, [visible, selectedMonth, selectedYear]);

    return (
        <Dialog id="dialog-style"
            visible={open}
            onHide={handleClose}
            style={{ width: "75rem", height: "53rem" }}
            header={"UnOccupied Units"}
            headerStyle={{textAlign:"center"}}
        >
            <div style={{ display: "flex",height:'100%',overflowY:'auto'  }}>
                <table id="Table2" style={{ border: "1px solid", borderRadius: "10px", width: "100%", tableLayout: "fixed", height: "auto" }}>
                    <thead style={{position: "sticky", top: "0", zIndex: "1", backgroundColor: "#e1e1e1"}}>
                        <tr>
                            <th style={tableHeaderStyle} >Unit</th>
                            <th style={tableHeaderStyle} >Floor</th>
                            <th style={tableHeaderStyle} >Building</th>
                            <th style={tableHeaderStyle} >Property</th>
                            <th style={tableHeaderStyle} >Area</th>
                            <th style={tableHeaderStyle} >UM</th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "white",overflowY: "auto", maxHeight: "calc(80vh - 80px)" }}>
                    {dashboardData.UNOCCUPIED_UNITS && dashboardData.UNOCCUPIED_UNITS.map((rowData, index) => (
                            <tr key={index} className="table-row" 
                            style={{ backgroundColor:index % 2 === 0 ? "aliceblue" : "#ffffff",}}>
                                <td style={tableCellStyle} >{rowData.ui_AL_DS}</td>
                                <td style={tableCellStyle} >{rowData.ui_FL_NO}</td>
                                <td style={tableCellStyle} >{rowData.ui_BL_NO}</td>
                                <td style={tableCellStyle} >{rowData.ui_PR_NO}</td>
                                <td style={tableCellStyle} >{rowData.ui_TR_QT}</td>
                                <td style={tableCellStyle} >{rowData.ui_TR_UM}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Dialog>
    );
};

export default UnoccupiedUnitDetails;

