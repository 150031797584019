import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import VoucherMatchDialog from "./VoucherMatchDialog";
import { Dropdown } from "primereact/dropdown";
import SupplierPopup from "./SupplierPopup";
import ItemPopup from "./ItemPopup";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
const initialValue = {
  prUkNo:"",
  pr_OR_NO:"",
  pr_OR_TY:"",
  pr_OR_CO:"",
  pr_DO_NO:"",
  pr_DO_TY:"",
  pr_SU_NO: "",
  name: "",
  pr_IN_NO: "",
  pr_IT_NM:"",
  itemDES:"",
  pr_RC_QT:"",
  pr_RC_AT:"",
  pr_FR_AT:"",
  pr_CO_CT:"",
  pr_ST_CD:"",
  pr_RC_DT:moment(moment().toDate()).format("YYYYMMDD"),
  pr_IN_DT:moment(moment().toDate()).format("YYYYMMDD"),
  pr_GL_DT:moment(moment().toDate()).format("YYYYMMDD"),
};

const initialFilters = {
  pr_OR_NO: { value: "", operation: "" },
  pr_OR_TY: { value: "", operation: "" },
  pr_OR_CO: { value: "", operation: "" },
  pr_DO_NO: { value: "", operation: "" },
  pr_DO_TY: { value: "", operation: "" },
  pr_SU_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },
  pr_IN_NO: { value: "", operation: "" },
  pr_IT_NM: { value: "", operation: "" },
  itemDES: { value: "", operation: "" },
  pr_TR_UM: { value: "", operation: "" },
  pr_RC_QT: { value: "", operation: "" },
  pr_RC_AT: { value: "", operation: "" },
  pr_FR_AT: { value: "", operation: "" },
  pr_CO_CT: { value: "", operation: "" },
  pr_ST_CD: { value: "", operation: "" },
};

const VoucherMatchMain = () => {

  const {formatDate, buttonStyles, addButtonStyles,rightalined,GenerateStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,inputStyle1
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [selecteditem, setSelecteditem] = useState("");
  const [item, setitem] = useState("");


  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
// Dropdown
const urlcost = `${domain}/api/CostCenter/DropDown`;
const finalUrl = `${urlcost}`;
useEffect(() => {
  fetchDropdownOptions();
  
}, [currentPage, pageSize, filters]);

const fetchDropdownOptions = async () => {
  try {
    const response = await axios.get(finalUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    const options = response.data.map((item) => ({
      label: item.cc_CC_NM,
      value: item.cc_CC_NM,
    }));
    setpr_CO_CTOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const [supplier, setSupplier] = useState("");
  const [pr_SU_NO, setpr_SU_NO] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [selectedAbAdNN, setSelectedAbAdNN] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [pr_OR_NO, setpr_OR_NO] = useState("");
  const [pr_TR_CR, setpr_TR_CR] = useState("");
  const [pr_TR_UM, setpr_TR_UM] = useState("");
  const [pr_CO_CT, setpr_CO_CT] = useState("");
  const [inv_AMOUNT, setinv_AMOUNT] = useState("");
  const [CostCenter, setCostCenter] = useState("");
  const [pr_CO_CTOptions, setpr_CO_CTOptions] = useState([]);
  const [VoucherMatchtable, setVoucherMatchtable] = useState([{}]);

  const [price_AMOUNT , setprice_AMOUNT] = useState("");
  const [net_AMOUNT , setnet_AMOUNT] = useState("");
  const [tax_AMOUNT , settax_AMOUNT] = useState("");
  const [fr_PRICE_AMOUNT , setfr_PRICE_AMOUNT] = useState("");
  const [fr_NET_AMOUNT , setfr_NET_AMOUNT] = useState("");
  const [fr_TAX_AMOUNT , setfr_TAX_AMOUNT] = useState("");


  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
  const openSupplierPopup = (e) => {
    setSupplierPopupVisible(true);
   
  };
  const handleDataSelect = (data) => {
    setSelectedSupplier(data.ab_AD_NM);
    setSupplier(data.abAdNo);
    setSupplierPopupVisible(false);
    updateFilters (data.abAdNo);
  };

  const [ItemPopupVisible, setItemPopupVisible] = useState(false);
  const openitemPopup = (e) => {
    setItemPopupVisible(true);
   
  };
  const handleDataSelect1 = (data) => {
    setSelecteditem(data.im_IT_DS);
    setitem(data.im_IT_NM);
    setItemPopupVisible(false);
    updateFilters1 (data.im_IT_NM);
  };
  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      pr_SU_NO: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const updateFilters1 = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      pr_IT_NM: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const updateFilters2 = (sts) => {
    let updatedFilters = { ...filters };
    if (sts !== null) {
      updatedFilters = {
        ...updatedFilters,
        pr_CO_CT: {
          value: sts,
          operation: "LIKE",
        },
      };
    }

    setFilters(updatedFilters);
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClearButton=()=>{
    setitem("");
    setSelecteditem("");
    setFilters("");
    setSupplier("");
    setSelectedSupplier("");
    setCostCenter("");
  }
  const handleClose = () => {
    setVoucherMatchtable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setpr_TR_CR(null);
    setpr_TR_UM(null);
    setpr_CO_CT(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleSearch();
    }
  };
  const addRow = () => {
    setVoucherMatchtable([...VoucherMatchtable, {}]);
  };

  const deleteRow = () => {
    if (VoucherMatchtable.length > 0) {
      const updatedData = [...VoucherMatchtable];
      updatedData.pop();
      setVoucherMatchtable(updatedData);
    }
  };
 

  useEffect(() => {
    handleSearch();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/MacthVoucher/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangeDropDown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setCostCenter(value);
    updateFilters2(value);
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setpr_TR_CR(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setpr_TR_UM(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setpr_CO_CT(value);
  };
 
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/MacthVoucher/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const VoucherMatchData = response.data;
        setVoucherMatchtable(VoucherMatchData.purchaseReceiptList);
        setFormData(VoucherMatchData.purchaseReceipt);
        setSelectedemEMNO(VoucherMatchData.purchaseReceipt.name);
        setpr_TR_CR(VoucherMatchData.purchaseReceipt.pr_TR_CR);
        setpr_TR_UM(VoucherMatchData.purchaseReceiptList.pr_TR_UM);
        setpr_CO_CT(VoucherMatchData.purchaseReceiptList.pr_CO_CT);
        setinv_AMOUNT(response.data.purchaseReceipt.inv_AMOUNT);
        setprice_AMOUNT(response.data.purchaseReceipt.gross_AMOUNT);
        setnet_AMOUNT(response.data.purchaseReceipt.taxable_AMOUNT);
        settax_AMOUNT(response.data.purchaseReceipt.tax_AMOUNT);
        setfr_PRICE_AMOUNT(response.data.purchaseReceipt.fr_GROSS_AMOUNT);
        setfr_NET_AMOUNT(response.data.purchaseReceipt.fr_TAXABLE_AMOUNT);
        setfr_TAX_AMOUNT(response.data.purchaseReceipt.fr_TAX_AMOUNT);
        handleClickOpen();
       
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values () {
      const purchaseReceipt = {
        prUkNo: FormData.prUkNo,
        pr_OR_NO: document.getElementById("pr_OR_NO").value,
        pr_OR_TY: document.getElementById("pr_OR_TY").value,
        pr_OR_CO: document.getElementById("pr_OR_CO").value,
        pr_GL_DT: FormData.pr_GL_DT,
        pr_IN_DT: FormData.pr_IN_DT,
        pr_RC_DT: FormData.pr_RC_DT,
        pr_SU_NO: document.getElementById("pr_SU_NO").value,
        pr_TR_CR: FormData.pr_TR_CR,
        pr_EX_RT: document.getElementById("pr_EX_RT").value,
        pr_BS_CR: document.getElementById("pr_BS_CR").value,
        pr_TX_AM: document.getElementById("pr_TX_AM").value,
        pr_IN_NO: document.getElementById("pr_IN_NO").value,
      };
     
      const table = document.getElementById("VoucherMatchtable");
      const totalRowCount = table.tBodies[0].rows.length;
      const purchaseReceiptList = [];
      for (let i = 0; i < totalRowCount; i++) {  
        const Details = {
        prUkNo:  document.getElementById("prUkNo_" + i).value,
        pr_LN_NO: document.getElementById("pr_LN_NO_" + i).value,
        pr_DO_NO: document.getElementById("pr_DO_NO_" + i).value,
        pr_DO_TY: document.getElementById("pr_DO_TY_" + i).value,
        pr_IT_NM: document.getElementById("pr_IT_NM_" + i).value,
        itemDES: document.getElementById("itemDES_" + i).value,
        pr_RC_QT: document.getElementById("pr_RC_QT_" + i).value,
        pr_TR_UM: table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
        pr_LO_CN: document.getElementById("pr_LO_CN_" + i).value,
        pr_LT_NO: document.getElementById("pr_LT_NO_" + i).value,
        pr_RC_AT: document.getElementById("pr_RC_AT_" + i).value,
        pr_OP_QT: document.getElementById("pr_OP_QT_" + i).value,
        pr_OP_AT: document.getElementById("pr_OP_AT_" + i).value,
        pr_TX_PE: document.getElementById("pr_TX_PE_" + i).value,
        pr_TX_AM: document.getElementById("pr_TX_AM_" + i).value,
        pr_FR_AT: document.getElementById("pr_FR_AT_" + i).value,
        pr_TR_UM: table.tBodies[0].rows[i].cells[16].children[0].children[1].children[0].value,
        pr_ST_CD: document.getElementById("pr_ST_CD_" + i).value,
        };
        purchaseReceiptList.push(Details);
      }
      requestBodyInput = {
        purchaseReceipt,
        purchaseReceiptList,
      };
    }


  const handleFormSubmit = () => {
    values();
    const orNo = document.getElementById("pr_OR_NO").value;
    const ukNo = FormData.prUkNo;
    if (orNo) {
      if (ukNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const add = `${domain}/api/MacthVoucher/Add`;
    
        if (confirm) {
          axios.post(add, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error adding data:", error);
            });
        }
      } 
    }
    };
  



  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(0);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };


  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };



  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  const AdvanceSearch = (
    <div className="formgrid grid">
      <div className="field col" >
    <label htmlFor="supplier" className="font-bold">
    Supplier <span style={{ color: 'red' }}>*</span>
    </label>
    <div style={{ display: "flex" }}>
      <InputText
      style={{display:"none"}}
        id="supplier"
        name="supplier"
        value={supplier}
      />
      <InputText
        id="sup_NAME"
        name="sup_NAME"
        readOnly
        style={{backgroundColor:"#ffffff"}}
        onChange={(Event) => onchangedata(Event)}
        onFocus={() => setShowSearchIcon(true)}
        value={selectedSupplier}
      />
       {showSearchIcon && (
      <i
        className="pi pi-search"
        style={{
          color: "var(--primary-color)",
          fontSize: "119%",
          marginTop: "4%",
          cursor: "pointer",
        }}
        onClick={openSupplierPopup}
      ></i>
       )}
    </div>
    </div>
      
              <div className="field col" >
              <label htmlFor="item" className="font-bold">
              Item <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                style={{display:"none"}}
                  id="item"
                  name="item"
                  value={item}
                />
                <InputText
                  id="im_IT_DS"
                  name="im_IT_DS"
                  readOnly
                  style={{backgroundColor:"#ffffff"}}
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={selecteditem}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openitemPopup}
                ></i>
                )}
              </div>
              </div>
          <div className="field col"   >
        <label htmlFor="CostCenter" className="font-bold">
        Cost Center
        </label>
        <Dropdown
          style={{ width: "90%" }}
          id="CostCenter"
          name="CostCenter"
          value={CostCenter}
          options={pr_CO_CTOptions}
          onChange={(e) => onchangeDropDown("CostCenter", e.value)}
          placeholder="Select Cost Centre"
        />
      </div>
          </div>
          

);
const rightClear = (
  <React.Fragment>
  <Tooltip  target=".clear-button" position="bottom" mouseTrack>
  <div>Clear Filter</div>
  </Tooltip>
  
  <Button
  style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
  icon="pi pi-times"
  className="mr-2 clear-button"
  link
  onClick={handleClearButton}
  />
  </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_OR_NO"] ? filters["pr_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_OR_NO",
                        e.target.value,
                        filters["pr_OR_NO"]
                          ? filters["pr_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_OR_TY"] ? filters["pr_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_OR_TY",
                        e.target.value,
                        filters["pr_OR_TY"]
                          ? filters["pr_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_OR_CO"] ? filters["pr_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_OR_CO",
                        e.target.value,
                        filters["pr_OR_CO"]
                          ? filters["pr_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_DO_NO"] ? filters["pr_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_DO_NO",
                        e.target.value,
                        filters["pr_DO_NO"]
                          ? filters["pr_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["pr_DO_TY"] ? filters["pr_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_DO_TY",
                        e.target.value,
                        filters["pr_DO_TY"]
                          ? filters["pr_DO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_SU_NO"] ? filters["pr_SU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_SU_NO",
                        e.target.value,
                        filters["pr_SU_NO"]
                          ? filters["pr_SU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_IN_NO"] ? filters["pr_IN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_IN_NO",
                        e.target.value,
                        filters["pr_IN_NO"]
                          ? filters["pr_IN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_IT_NM"] ? filters["pr_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_IT_NM",
                        e.target.value,
                        filters["pr_IT_NM"]
                          ? filters["pr_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["itemDES"] ? filters["itemDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "itemDES",
                        e.target.value,
                        filters["itemDES"]
                          ? filters["itemDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_TR_UM"] ? filters["pr_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_TR_UM",
                        e.target.value,
                        filters["pr_TR_UM"]
                          ? filters["pr_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_RC_QT"] ? filters["pr_RC_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_RC_QT",
                        e.target.value,
                        filters["pr_RC_QT"]
                          ? filters["pr_RC_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_RC_AT"] ? filters["pr_RC_AT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_RC_AT",
                        e.target.value,
                        filters["pr_RC_AT"]
                          ? filters["pr_RC_AT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_FR_AT"] ? filters["pr_FR_AT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_FR_AT",
                        e.target.value,
                        filters["pr_FR_AT"]
                          ? filters["pr_FR_AT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_CO_CT"] ? filters["pr_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_CO_CT",
                        e.target.value,
                        filters["pr_CO_CT"]
                          ? filters["pr_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_ST_CD"] ? filters["pr_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_ST_CD",
                        e.target.value,
                        filters["pr_ST_CD"]
                          ? filters["pr_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Doc No</th>
                <th style={tableHeaderStyle}>Doc Type</th>
                <th style={tableHeaderStyle}>Supplier No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Delivery No</th>
                <th style={tableHeaderStyle}>Item Number</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Receipt Quantity</th>
                <th style={tableHeaderStyle}>Amount</th>
                <th style={tableHeaderStyle}>Fore Amount</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Status</th>
                
           
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.prUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.prUkNo}
                        checked={selectedRows.includes(rowData.prUkNo)}
                        onChange={() => handleRowSelect(rowData.prUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.pr_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.pr_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.pr_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.pr_DO_NO}</td>
                    <td style={tableCellStyle}>{rowData.pr_DO_TY}</td>
                    <td style={tableCellStyle}>{rowData.pr_SU_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{rowData.pr_IN_NO}</td>
                    <td style={tableCellStyle}>{rowData.pr_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.itemDES}</td>
                    <td style={tableCellStyle}>{rowData.pr_TR_UM}</td>
                    <td style={tableCellStyle}>{rowData.pr_RC_QT}</td>
                    <td style={tableCellStyle}>{rowData.pr_RC_AT}</td>
                    <td style={tableCellStyle}>{rowData.pr_FR_AT}</td>
                    <td style={tableCellStyle}>{rowData.pr_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.pr_ST_CD}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="Pagination" style={{ marginLeft: "85%" }}>
          <span
            onClick={handleFirstPage}
            style={{
              fontSize: "200%",
              cursor: "pointer",
              whiteSpace: "pre-wrap",
              whiteSpace: "pre-wrap",
            }}
          >
            &laquo;{" "}
          </span>{" "}
          <span
            onClick={handlePreviousPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Prev{" "}
          </span>{" "}
          <span style={{ whiteSpace: "pre-wrap", fontSize: "111%" }}> </span>{" "}
          <span
            onClick={handleNextPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Next{" "}
          </span>{" "}
          <span
            onClick={handleLastPage}
            style={{ fontSize: "200%", cursor: "pointer" }}
          >
            &raquo;
          </span>
        </div>
      </div>
      <SupplierPopup
        visible={SupplierPopupVisible}
        onClose={() => setSupplierPopupVisible(false)}
        onDataSelect={handleDataSelect}
       
      />
       <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelect1}
       
      />
      <VoucherMatchDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        setpr_SU_NO={setpr_SU_NO}
        pr_SU_NO={pr_SU_NO}
        pr_TR_CR={pr_TR_CR}
        pr_TR_UM={pr_TR_UM}
        pr_CO_CT={pr_CO_CT}
        selectedAbAdNN={selectedAbAdNN}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedAbAdNN={setSelectedAbAdNN}
        setSelectedemEMNO={setSelectedemEMNO}
        VoucherMatchtable={VoucherMatchtable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
        price_AMOUNT={price_AMOUNT}
        setprice_AMOUNT={setprice_AMOUNT}
        setnet_AMOUNT={setnet_AMOUNT}
        net_AMOUNT={net_AMOUNT}
        settax_AMOUNT={settax_AMOUNT}
        tax_AMOUNT={tax_AMOUNT}
        fr_PRICE_AMOUNT={fr_PRICE_AMOUNT}
        setfr_PRICE_AMOUNT={setfr_PRICE_AMOUNT}
        fr_NET_AMOUNT={fr_NET_AMOUNT}
        setfr_NET_AMOUNT={setfr_NET_AMOUNT}
        fr_TAX_AMOUNT={fr_TAX_AMOUNT}
        inv_AMOUNT={inv_AMOUNT}
  
      />
    </div>
    </div>
  );
};

export default VoucherMatchMain;
