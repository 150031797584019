import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
export default function JobAccountStructureDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  handleFormSubmit,
  JobAccountStructureTableData,
  setJobAccountStructureTableData,
  setad_AD_TY,
  ja_CO_NO,
}) {
  const {
    ja_UK_NO,
    ja_CA_MD,
    ja_CD_DS,
    jaAcCd,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const addRow = () => {
    setJobAccountStructureTableData([...JobAccountStructureTableData, {}]);
  };
  const deleteRow = () => {
    if (JobAccountStructureTableData.length > 0) {
      const updatedData = [...JobAccountStructureTableData];
      updatedData.pop();
      setJobAccountStructureTableData(updatedData);
    }
  };
const isUpdateMode = Boolean(ja_UK_NO);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{ja_UK_NO ? "Revise Job Account Structure" : "Add Job Account Structure"}</h5>
    <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
        tooltip={isUpdateMode ? "Update" : "Submit"}
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jaAcCd" className="font-bold">
              Account Code Structure
              </label>
              <InputText 
                id="jaAcCd"
                name="jaAcCd"
                value={jaAcCd}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ja_CD_DS" className="font-bold">
                  Description
              </label>
              <InputText 
                id="ja_CD_DS"
                name="ja_CD_DS"
                value={ja_CD_DS}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ja_CA_MD" className="font-bold">
              Calc-Method
              </label>
              <InputText 
                id="ja_CA_MD"
                name="ja_CA_MD"
                value={ja_CA_MD}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
                  
          </div>
        </div>
          <div style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
              {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
            </div>
            <div  style={multitablestyle}>
              <table className="custom-table" id="JobAccountStructuretable">
                <thead>
                  <tr>
                    <th style={{ display: "none" }}>UK NO</th>
                    <th style={{ display: "none" }}>Company</th>
                    <th>Account Object</th>
                    <th>Subsidiary</th>
                    <th>Account Description</th>
                    <th>Level of Detail</th>
                    <th>Post Code</th>
                  </tr>
                </thead>
                <tbody>
                  {JobAccountStructureTableData.map((row, index) => (
                    <tr key={index}>
                      <td style={{ display: "none" }}>
                        <InputText
                          id={`ja_UK_NO_${index}`}
                          name={`ja_UK_NO_${index}`}
                          value={row.ja_UK_NO}
                        />
                      </td>
                      <td style={{ display: "none" }}>
                        <InputText disabled
                          id={`ja_CO_NO_${index}`}
                          name={`ja_CO_NO_${index}`}
                          value={ja_CO_NO}
                          readOnly
                        />
                      </td>
                      
                      <td >
                        <InputText 
                          id={`jaObAc_${index}`}
                          name={`jaObAc_${index}`}
                          defaultValue={row.jaObAc}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText 
                          id={`jaObSb_${index}`}
                          name={`jaObSb_${index}`}
                          defaultValue={row.jaObSb}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText 
                          id={`ja_AC_DS_${index}`}
                          name={`ja_AC_DS_${index}`}
                          defaultValue={row.ja_AC_DS}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText 
                          id={`jaLvDt_${index}`}
                          name={`jaLvDt_${index}`}
                          defaultValue={row.jaLvDt}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText 
                          id={`ja_PE_CD_${index}`}
                          name={`ja_PE_CD_${index}`}
                          defaultValue={row.ja_PE_CD}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
      </div> 
    </Dialog>
  );
}
