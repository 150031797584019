import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function PatientDetailsDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  FormData,
  setFormData,
  pd_GN_DR,
  setpd_GN_DR,
  pd_BL_GP,
  setpd_BL_GP,
  eg_AP_RQ,
  seteg_AP_RQ,
  eg_RM_ME,
  seteg_RM_ME,
  eg_RM_CR,
  seteg_RM_CR,
  eg_CO_NO,
}) {
  const {
    pdUkNo,
    pd_PA_ID,
    pd_RG_DT,
    pd_BR_DT,
    pd_PA_NM,
    pd_PA_AG,
    pd_AD_L1,
    pd_CI_TY,
    pd_EM_ID,
   

  } = data;
  const {borderbox,inboxstyle} = ButtonStyles();
  // dropdowns

  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  
  const [pd_GN_DROptions, setpd_GN_DROptions] = useState([]);
  const [pd_BL_GPOptions, setpd_BL_GPOptions] = useState([]);
  const [eg_AP_RQOptions, seteg_AP_RQOptions] = useState([]);
  const [eg_RM_MEOptions, seteg_RM_MEOptions] = useState([]);
  const [eg_RM_CROptions, seteg_RM_CROptions] = useState([]);
// BIDDING type
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_GN_DR(value);
  };
// Acq
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_BL_GP(value);
  };
// loc
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seteg_AP_RQ(value);
  };
// lang
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seteg_RM_ME(value);
  };
// demogr
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seteg_RM_CR(value);
  };

  useEffect(() => {
    fetchDropdownBT();
    fetchDropdownAQ();
    fetchDropdownLN();
    fetchDropdownLG();
    fetchDropdownDM();

  }, []);

  const fetchDropdownBT = async () => {
    const Options = await fetchClassCodeDS("EM/GN");
    setpd_GN_DROptions(Options);
  };

  const fetchDropdownAQ = async () => {
    const Options = await fetchClassCodeDS("BL/GP");
    setpd_BL_GPOptions(Options);
  };

  const fetchDropdownLN = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    seteg_AP_RQOptions(Options);
  };

  const fetchDropdownLG = async () => {
    const Options = await fetchClassCodeDS("EX/RM");
    seteg_RM_MEOptions(Options);
  };

  const fetchDropdownDM = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    seteg_RM_CROptions(Options);
  };

const isUpdateMode = Boolean(pdUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "70rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={pdUkNo ? "Revise Patient Details" : "Add Patient Details"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
            <div
                style={{
                  marginTop: "2%",
                  border: "1px solid #e8e8e8",
                  padding: "16px",
                  borderRadius: "15px",
                  backgroundColor: "aliceblue",
                  overflow: "auto",
                }}
              >
                <div className="formgrid grid">
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pd_PA_ID" className="font-bold">
                  Patient ID
                  </label>
                  <InputText disabled
                    id="pd_PA_ID"
                    name="pd_PA_ID"
                    value={pd_PA_ID}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pd_RG_DT" className="font-bold">
                  Date
                    </label>
                    <Calendar
                      id="pd_RG_DT"
                      name="pd_RG_DT"
                      value={moment(pd_RG_DT, "YYYYMMDD").toDate()}
                      onChange={(e) => onchangedata({ target: { name: "pd_RG_DT", value: moment(e.value).format("YYYYMMDD") } })}
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pd_BR_DT" className="font-bold">
                      Birth Date
                    </label>
                    <Calendar
                      id="pd_BR_DT"
                      name="pd_BR_DT"
                      value={moment(pd_BR_DT, "YYYYMMDD").toDate()}
                      onChange={(e) => onchangedata({ target: { name: "pd_BR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                      dateFormat="dd/mm/yy"
                    />
                  </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pd_PA_NM" className="font-bold">
                  Name
                  </label>
                  <InputText 
                    id="pd_PA_NM"
                    name="pd_PA_NM"
                    value={pd_PA_NM}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pd_PA_AG" className="font-bold">
                  Age
                  </label>
                  <InputText disabled
                    id="pd_PA_AG"
                    name="pd_PA_AG"
                    value={pd_PA_AG}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pd_AD_L1" className="font-bold">
                  Address
                  </label>
                  <InputText 
                    id="pd_AD_L1"
                    name="pd_AD_L1"
                    value={pd_AD_L1}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pd_CI_TY" className="font-bold">
                  City
                  </label>
                  <InputText 
                    id="pd_CI_TY"
                    name="pd_CI_TY"
                    value={pd_CI_TY}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pd_EM_ID" className="font-bold">
                  Email
                  </label>
                  <InputText 
                    id="pd_EM_ID"
                    name="pd_EM_ID"
                    value={pd_EM_ID}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pd_GN_DR" className="font-bold">
                     Gender
                    </label>
                    <Dropdown
                    name="pd_GN_DR"
                    value={pd_GN_DR}
                    options={pd_GN_DROptions}
                    onChange={(e) => onchangeDropDownFormData1("pd_GN_DR", e.value)}
                    placeholder="Select Gender"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pd_BL_GP" className="font-bold">
                    Blood Group
                    </label>
                    <Dropdown
                    name="pd_BL_GP"
                    value={pd_BL_GP}
                    options={pd_BL_GPOptions}
                    onChange={(e) => onchangeDropDownFormData2("pd_BL_GP", e.value)}
                    placeholder="Select Blood Group"
                  />
                  </div>
                </div>
            </div>
      </div>
    </Dialog>
  );
}
