import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import BudgetVsActualComparisionDialog from "./BudgetVsActualComparisionDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  fbUkNo: "",
  fb_DO_CO: "",
  fb_CT_RY: "",
  fb_FI_YR: "",
  fb_AC_ID: "",
  fb_OB_AC: "",
  fb_OB_SB: "",
  fb_LV_DT: "",
  fb_BU_AM: "",
  cu_BU_AM: "",
  cu_AC_AM: "",
  cu_VR_AM: "",
  cu_PR_AM: "",
  fb_PE_01: "",
  gb_NP_01: "",
  fb_PR_01: "",
  fb_VR_01: "",
  fb_PE_01: "",
  gb_NP_01: "",
  fb_PR_01: "",
  fb_VR_01: "",
  fb_PE_02: "",
  gb_NP_02: "",
  fb_VR_02: "",
  fb_PR_02: "",
  fb_PE_03: "",
  gb_NP_03: "",
  fb_PR_03: "",
  fb_VR_03: "",
  fb_PE_04: "",
  gb_NP_04: "",
  fb_PR_04: "",
  fb_VR_04: "",
  fb_PE_05: "",
  gb_NP_05: "",
  fb_PR_05: "",
  fb_VR_05: "",
  fb_PE_06: "",
  gb_NP_06: "",
  fb_PR_06: "",
  fb_VR_06: "",
  fb_PE_07: "",
  gb_NP_07: "",
  fb_PR_07: "",
  fb_VR_07: "",
  fb_PE_08: "",
  gb_NP_08: "",
  fb_PR_08: "",
  fb_VR_08: "",
  fb_PE_09: "",
  gb_NP_09: "",
  fb_PR_09: "",
  fb_VR_09: "",
  fb_PE_10: "",
  gb_NP_10: "",
  fb_PR_10: "",
  fb_VR_10: "",
  fb_PE_11: "",
  gb_NP_11: "",
  fb_PR_11: "",
  fb_VR_11: "",
  fb_PE_12: "",
  gb_NP_12: "",
  fb_PR_12: "",
  fb_VR_12: "",
 
};

const initialFilters = {
  fy_YE_AR: { value: "", operation: "" },
  fy_DT_TY: { value: "", operation: "" },
  fy_CO_NO: { value: "", operation: "" },
  fy_BD_DT: { value: "", operation: "" },
  
};

const BudgetVsActualComparisionMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,
       } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedemEMNOE, setSelectedemEMNOE] = useState("");

  const [fb_CT_RY, setfb_CT_RY] = useState([]);
  const [fb_FI_YR, setfb_FI_YR] = useState([]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setfb_CT_RY(null);
    setfb_FI_YR(null);
   
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setfb_CT_RY(null);
    setfb_FI_YR(null);
   
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
     // handleSearch();
     handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/FinancialBudget/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const BudgetVsActualComparisionData = response.data;
          delete BudgetVsActualComparisionData.fbUkNo;
          setfb_CT_RY(BudgetVsActualComparisionData.fb_CT_RY);
          setfb_FI_YR(BudgetVsActualComparisionData.fb_FI_YR);
          
          setFormData(BudgetVsActualComparisionData);
          setSelectedemEMNO(BudgetVsActualComparisionData.name);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const url = "${domain}/api/Contact/AllFields";

  useEffect(() => {
    // handleSearch();
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [fb_DO_CO , setfb_DO_CO] = useState("");
  const handleDefault = async () => {
    setfb_DO_CO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
           
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/FinancialBudget/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
 
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setfb_CT_RY(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setfb_FI_YR(value);
  };



  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/FinancialBudget/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const BudgetVsActualComparisionData = response.data;
        setFormData(BudgetVsActualComparisionData);
        setSelectedAbAdNM(response.data.ab_AD_NM);
        setSelectedemEMNO(response.data.name);
        setfb_CT_RY(response.data.fb_CT_RY);
        setfb_FI_YR(response.data.fb_FI_YR);
       
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {
    if (FormData.fbUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/FinancialBudget/Update`;
      if (confirm) {
        axios
          .put(update, FormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.fb_DO_CO = company;
      axios
        .post(`${domain}/api/FinancialBudget/Add`, FormData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      {/* <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button> */}
       {/* <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button> */}
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_DO_CO"] ? filters["fb_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_DO_CO",
                        e.target.value,
                        filters["fb_DO_CO"]
                          ? filters["fb_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_CT_RY"] ? filters["fb_CT_RY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_CT_RY",
                        e.target.value,
                        filters["fb_CT_RY"]
                          ? filters["fb_CT_RY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_FI_YR"] ? filters["fb_FI_YR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_FI_YR",
                        e.target.value,
                        filters["fb_FI_YR"]
                          ? filters["fb_FI_YR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_AC_ID"] ? filters["fb_AC_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_AC_ID",
                        e.target.value,
                        filters["fb_AC_ID"]
                          ? filters["fb_AC_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["fb_OB_AC"] ? filters["fb_OB_AC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_OB_AC",
                        e.target.value,
                        filters["fb_OB_AC"]
                          ? filters["fb_OB_AC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_OB_SB"] ? filters["fb_OB_SB"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_OB_SB",
                        e.target.value,
                        filters["fb_OB_SB"]
                          ? filters["fb_OB_SB"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_LV_DT"] ? filters["fb_LV_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_LV_DT",
                        e.target.value,
                        filters["fb_LV_DT"]
                          ? filters["fb_LV_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>      
                <td>
                  <input
                    type="text"
                    value={filters["fb_BU_AM"] ? filters["fb_BU_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_BU_AM",
                        e.target.value,
                        filters["fb_BU_AM"]
                          ? filters["fb_BU_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["cu_BU_AM"] ? filters["cu_BU_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cu_BU_AM",
                        e.target.value,
                        filters["cu_BU_AM"] ? filters["cu_BU_AM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cu_AC_AM"] ? filters["cu_AC_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cu_AC_AM",
                        e.target.value,
                        filters["cu_AC_AM"]
                          ? filters["cu_AC_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cu_VR_AM"] ? filters["cu_VR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cu_VR_AM",
                        e.target.value,
                        filters["cu_VR_AM"]
                          ? filters["cu_VR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cu_PR_AM"] ? filters["cu_PR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cu_PR_AM",
                        e.target.value,
                        filters["cu_PR_AM"]
                          ? filters["cu_PR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PE_01"] ? filters["fb_PE_01"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PE_01",
                        e.target.value,
                        filters["fb_PE_01"] ? filters["fb_PE_01"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gb_NP_01"] ? filters["gb_NP_01"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gb_NP_01",
                        e.target.value,
                        filters["gb_NP_01"]
                          ? filters["gb_NP_01"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_VR_01"] ? filters["fb_VR_01"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_VR_01",
                        e.target.value,
                        filters["fb_VR_01"]
                          ? filters["fb_VR_01"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PR_01"] ? filters["fb_PR_01"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PR_01",
                        e.target.value,
                        filters["fb_PR_01"]
                          ? filters["fb_PR_01"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PE_02"] ? filters["fb_PE_02"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PE_02",
                        e.target.value,
                        filters["fb_PE_02"] ? filters["fb_PE_02"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gb_NP_02"] ? filters["gb_NP_02"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gb_NP_02",
                        e.target.value,
                        filters["gb_NP_02"]
                          ? filters["gb_NP_02"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_VR_02"] ? filters["fb_VR_02"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_VR_02",
                        e.target.value,
                        filters["fb_VR_02"]
                          ? filters["fb_VR_02"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PR_02"] ? filters["fb_PR_02"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PR_02",
                        e.target.value,
                        filters["fb_PR_02"]
                          ? filters["fb_PR_02"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["fb_PE_03"] ? filters["fb_PE_03"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PE_03",
                        e.target.value,
                        filters["fb_PE_03"] ? filters["fb_PE_03"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gb_NP_03"] ? filters["gb_NP_03"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gb_NP_03",
                        e.target.value,
                        filters["gb_NP_03"]
                          ? filters["gb_NP_03"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_VR_03"] ? filters["fb_VR_03"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_VR_03",
                        e.target.value,
                        filters["fb_VR_03"]
                          ? filters["fb_VR_03"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PR_03"] ? filters["fb_PR_03"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PR_03",
                        e.target.value,
                        filters["fb_PR_03"]
                          ? filters["fb_PR_03"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["fb_PE_04"] ? filters["fb_PE_04"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PE_04",
                        e.target.value,
                        filters["fb_PE_04"] ? filters["fb_PE_04"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gb_NP_04"] ? filters["gb_NP_04"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gb_NP_04",
                        e.target.value,
                        filters["gb_NP_04"]
                          ? filters["gb_NP_04"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_VR_04"] ? filters["fb_VR_04"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_VR_04",
                        e.target.value,
                        filters["fb_VR_04"]
                          ? filters["fb_VR_04"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PR_04"] ? filters["fb_PR_04"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PR_04",
                        e.target.value,
                        filters["fb_PR_04"]
                          ? filters["fb_PR_04"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["fb_PE_05"] ? filters["fb_PE_05"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PE_05",
                        e.target.value,
                        filters["fb_PE_05"] ? filters["fb_PE_05"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gb_NP_05"] ? filters["gb_NP_05"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gb_NP_05",
                        e.target.value,
                        filters["gb_NP_05"]
                          ? filters["gb_NP_05"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_VR_05"] ? filters["fb_VR_05"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_VR_05",
                        e.target.value,
                        filters["fb_VR_05"]
                          ? filters["fb_VR_05"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PR_05"] ? filters["fb_PR_05"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PR_05",
                        e.target.value,
                        filters["fb_PR_05"]
                          ? filters["fb_PR_05"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["fb_PE_06"] ? filters["fb_PE_06"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PE_06",
                        e.target.value,
                        filters["fb_PE_06"] ? filters["fb_PE_06"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gb_NP_06"] ? filters["gb_NP_06"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gb_NP_06",
                        e.target.value,
                        filters["gb_NP_06"]
                          ? filters["gb_NP_06"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_VR_06"] ? filters["fb_VR_06"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_VR_06",
                        e.target.value,
                        filters["fb_VR_06"]
                          ? filters["fb_VR_06"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PR_06"] ? filters["fb_PR_06"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PR_06",
                        e.target.value,
                        filters["fb_PR_06"]
                          ? filters["fb_PR_06"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["fb_PE_07"] ? filters["fb_PE_07"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PE_07",
                        e.target.value,
                        filters["fb_PE_07"] ? filters["fb_PE_07"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gb_NP_07"] ? filters["gb_NP_07"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gb_NP_07",
                        e.target.value,
                        filters["gb_NP_07"]
                          ? filters["gb_NP_07"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_VR_07"] ? filters["fb_VR_07"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_VR_07",
                        e.target.value,
                        filters["fb_VR_07"]
                          ? filters["fb_VR_07"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PR_07"] ? filters["fb_PR_07"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PR_07",
                        e.target.value,
                        filters["fb_PR_07"]
                          ? filters["fb_PR_07"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["fb_PE_08"] ? filters["fb_PE_08"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PE_08",
                        e.target.value,
                        filters["fb_PE_08"] ? filters["fb_PE_08"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gb_NP_08"] ? filters["gb_NP_08"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gb_NP_08",
                        e.target.value,
                        filters["gb_NP_08"]
                          ? filters["gb_NP_08"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_VR_08"] ? filters["fb_VR_08"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_VR_08",
                        e.target.value,
                        filters["fb_VR_08"]
                          ? filters["fb_VR_08"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PR_08"] ? filters["fb_PR_08"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PR_08",
                        e.target.value,
                        filters["fb_PR_08"]
                          ? filters["fb_PR_08"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["fb_PE_09"] ? filters["fb_PE_09"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PE_09",
                        e.target.value,
                        filters["fb_PE_09"] ? filters["fb_PE_09"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gb_NP_09"] ? filters["gb_NP_09"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gb_NP_09",
                        e.target.value,
                        filters["gb_NP_09"]
                          ? filters["gb_NP_09"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_VR_09"] ? filters["fb_VR_09"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_VR_09",
                        e.target.value,
                        filters["fb_VR_09"]
                          ? filters["fb_VR_09"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PR_09"] ? filters["fb_PR_09"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PR_09",
                        e.target.value,
                        filters["fb_PR_09"]
                          ? filters["fb_PR_09"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PE_10"] ? filters["fb_PE_10"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PE_10",
                        e.target.value,
                        filters["fb_PE_10"] ? filters["fb_PE_10"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gb_NP_10"] ? filters["gb_NP_10"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gb_NP_10",
                        e.target.value,
                        filters["gb_NP_10"]
                          ? filters["gb_NP_10"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_VR_10"] ? filters["fb_VR_10"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_VR_10",
                        e.target.value,
                        filters["fb_VR_10"]
                          ? filters["fb_VR_10"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PR_10"] ? filters["fb_PR_10"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PR_10",
                        e.target.value,
                        filters["fb_PR_10"]
                          ? filters["fb_PR_10"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["fb_PE_11"] ? filters["fb_PE_11"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PE_11",
                        e.target.value,
                        filters["fb_PE_11"] ? filters["fb_PE_11"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gb_NP_11"] ? filters["gb_NP_11"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gb_NP_11",
                        e.target.value,
                        filters["gb_NP_11"]
                          ? filters["gb_NP_11"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_VR_11"] ? filters["fb_VR_11"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_VR_11",
                        e.target.value,
                        filters["fb_VR_11"]
                          ? filters["fb_VR_11"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PR_11"] ? filters["fb_PR_11"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PR_11",
                        e.target.value,
                        filters["fb_PR_11"]
                          ? filters["fb_PR_11"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["fb_PE_12"] ? filters["fb_PE_12"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PE_12",
                        e.target.value,
                        filters["fb_PE_12"] ? filters["fb_PE_12"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gb_NP_12"] ? filters["gb_NP_12"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gb_NP_12",
                        e.target.value,
                        filters["gb_NP_12"]
                          ? filters["gb_NP_12"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_VR_12"] ? filters["fb_VR_12"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_VR_12",
                        e.target.value,
                        filters["fb_VR_12"]
                          ? filters["fb_VR_12"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PR_12"] ? filters["fb_PR_12"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PR_12",
                        e.target.value,
                        filters["fb_PR_12"]
                          ? filters["fb_PR_12"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                 
              </tr>
              <tr>
              <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Century</th>
                <th style={tableHeaderStyle}>Financial Year</th>
                <th style={tableHeaderStyle}>Account ID</th>
                <th style={tableHeaderStyle}>Account </th>
                <th style={tableHeaderStyle}>Subsidary</th>
                <th style={tableHeaderStyle}>Level</th>
                <th style={tableHeaderStyle}>Budget Amount</th>
                <th style={tableHeaderStyle}>Cumulative Budget</th>
                <th style={tableHeaderStyle}>Cumulative Actual</th>
                <th style={tableHeaderStyle}>Cumulative Variance</th>
                <th style={tableHeaderStyle}>Cumulative % </th>
                <th style={tableHeaderStyle}>Period 1 Budget</th>
                <th style={tableHeaderStyle}>Period 1 Actual</th>
                <th style={tableHeaderStyle}>Period 1 Variance</th>
                <th style={tableHeaderStyle}>Period 1 % </th>
                <th style={tableHeaderStyle}>Period 2 Budget</th>
                <th style={tableHeaderStyle}>Period 2 Actual</th>
                <th style={tableHeaderStyle}>Period 2 Variance</th>
                <th style={tableHeaderStyle}>Period 2 % </th>
                <th style={tableHeaderStyle}>Period 3 Budget</th>
                <th style={tableHeaderStyle}>Period 3 Actual</th>
                <th style={tableHeaderStyle}>Period 3 Variance</th>
                <th style={tableHeaderStyle}>Period 3 % </th>
                <th style={tableHeaderStyle}>Period 4 Budget</th>
                <th style={tableHeaderStyle}>Period 4 Actual</th>
                <th style={tableHeaderStyle}>Period 4 Variance</th>
                <th style={tableHeaderStyle}>Period 4 % </th>
                <th style={tableHeaderStyle}>Period 5 Budget</th>
                <th style={tableHeaderStyle}>Period 5 Actual</th>
                <th style={tableHeaderStyle}>Period 5 Variance</th>
                <th style={tableHeaderStyle}>Period 5 % </th>
                <th style={tableHeaderStyle}>Period 6 Budget</th>
                <th style={tableHeaderStyle}>Period 6 Actual</th>
                <th style={tableHeaderStyle}>Period 6 Variance</th>
                <th style={tableHeaderStyle}>Period 6 % </th>
                <th style={tableHeaderStyle}>Period 7 Budget</th>
                <th style={tableHeaderStyle}>Period 7 Actual</th>
                <th style={tableHeaderStyle}>Period 7 Variance</th>
                <th style={tableHeaderStyle}>Period 7 % </th>
                <th style={tableHeaderStyle}>Period 8 Budget</th>
                <th style={tableHeaderStyle}>Period 8 Actual</th>
                <th style={tableHeaderStyle}>Period 8 Variance</th>
                <th style={tableHeaderStyle}>Period 8 % </th>
                <th style={tableHeaderStyle}>Period 9 Budget</th>
                <th style={tableHeaderStyle}>Period 9 Actual</th>
                <th style={tableHeaderStyle}>Period 9 Variance</th>
                <th style={tableHeaderStyle}>Period 9 % </th>
                <th style={tableHeaderStyle}>Period 10 Budget</th>
                <th style={tableHeaderStyle}>Period 10 Actual</th>
                <th style={tableHeaderStyle}>Period 10 Variance</th>
                <th style={tableHeaderStyle}>Period 10 % </th>
                <th style={tableHeaderStyle}>Period 11 Budget</th>
                <th style={tableHeaderStyle}>Period 11 Actual</th>
                <th style={tableHeaderStyle}>Period 11 Variance</th>
                <th style={tableHeaderStyle}>Period 11 % </th>
                <th style={tableHeaderStyle}>Period 12 Budget</th>
                <th style={tableHeaderStyle}>Period 12 Actual</th>
                <th style={tableHeaderStyle}>Period 12 Variance</th>
                <th style={tableHeaderStyle}>Period 12 % </th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.fbUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.fbUkNo}
                        checked={selectedRows.includes(rowData.fbUkNo)}
                        onChange={() => handleRowSelect(rowData.fbUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.fb_DO_CO}</td>
                    <td style={tableCellStyle}>{rowData.fb_CT_RY}</td>
                    <td style={tableCellStyle}>{rowData.fb_FI_YR}</td>
                    <td style={tableCellStyle}>{rowData.fb_AC_ID}</td>
                    <td style={tableCellStyle}>{rowData.fb_OB_AC}</td>
                    <td style={tableCellStyle}>{rowData.fb_OB_SB}</td>
                    <td style={tableCellStyle}>{rowData.fb_LV_DT}</td>
                    <td style={tableCellStyle}>{rowData.fb_BU_AM}</td>
                    <td style={tableCellStyle}>{rowData.cu_BU_AM}</td>
                    <td style={tableCellStyle}>{rowData.cu_AC_AM}</td>
                    <td style={tableCellStyle}>{rowData.cu_VR_AM}</td>
                    <td style={tableCellStyle}>{rowData.cu_PR_AM}</td>

                    <td style={tableCellStyle}>{rowData.fb_PE_01}</td>
                    <td style={tableCellStyle}>{rowData.gb_NP_01}</td>
                    <td style={tableCellStyle}>{rowData.fb_VR_01}</td>
                    <td style={tableCellStyle}>{rowData.fb_PR_01}</td>

                    <td style={tableCellStyle}>{rowData.fb_PE_02}</td>
                    <td style={tableCellStyle}>{rowData.gb_NP_02}</td>
                    <td style={tableCellStyle}>{rowData.fb_VR_02}</td>
                    <td style={tableCellStyle}>{rowData.fb_PR_02}</td>

                    <td style={tableCellStyle}>{rowData.fb_PE_03}</td>
                    <td style={tableCellStyle}>{rowData.gb_NP_03}</td>
                    <td style={tableCellStyle}>{rowData.fb_VR_03}</td>
                    <td style={tableCellStyle}>{rowData.fb_PR_03}</td>

                    <td style={tableCellStyle}>{rowData.fb_PE_04}</td>
                    <td style={tableCellStyle}>{rowData.gb_NP_04}</td>
                    <td style={tableCellStyle}>{rowData.fb_VR_04}</td>
                    <td style={tableCellStyle}>{rowData.fb_PR_04}</td>

                    <td style={tableCellStyle}>{rowData.fb_PE_05}</td>
                    <td style={tableCellStyle}>{rowData.gb_NP_05}</td>
                    <td style={tableCellStyle}>{rowData.fb_VR_05}</td>
                    <td style={tableCellStyle}>{rowData.fb_PR_05}</td>

                    <td style={tableCellStyle}>{rowData.fb_PE_06}</td>
                    <td style={tableCellStyle}>{rowData.gb_NP_06}</td>
                    <td style={tableCellStyle}>{rowData.fb_VR_06}</td>
                    <td style={tableCellStyle}>{rowData.fb_PR_06}</td>

                    <td style={tableCellStyle}>{rowData.fb_PE_07}</td>
                    <td style={tableCellStyle}>{rowData.gb_NP_07}</td>
                    <td style={tableCellStyle}>{rowData.fb_VR_07}</td>
                    <td style={tableCellStyle}>{rowData.fb_PR_07}</td>

                    <td style={tableCellStyle}>{rowData.fb_PE_08}</td>
                    <td style={tableCellStyle}>{rowData.gb_NP_08}</td>
                    <td style={tableCellStyle}>{rowData.fb_VR_08}</td>
                    <td style={tableCellStyle}>{rowData.fb_PR_08}</td>

                    <td style={tableCellStyle}>{rowData.fb_PE_09}</td>
                    <td style={tableCellStyle}>{rowData.gb_NP_09}</td>
                    <td style={tableCellStyle}>{rowData.fb_VR_09}</td>
                    <td style={tableCellStyle}>{rowData.fb_PR_09}</td>

                    <td style={tableCellStyle}>{rowData.fb_PE_10}</td>
                    <td style={tableCellStyle}>{rowData.gb_NP_10}</td>
                    <td style={tableCellStyle}>{rowData.fb_VR_10}</td>
                    <td style={tableCellStyle}>{rowData.fb_PR_10}</td>

                    <td style={tableCellStyle}>{rowData.fb_PE_11}</td>
                    <td style={tableCellStyle}>{rowData.gb_NP_11}</td>
                    <td style={tableCellStyle}>{rowData.fb_VR_11}</td>
                    <td style={tableCellStyle}>{rowData.fb_PR_11}</td>

                    <td style={tableCellStyle}>{rowData.fb_PE_12}</td>
                    <td style={tableCellStyle}>{rowData.gb_NP_12}</td>
                    <td style={tableCellStyle}>{rowData.fb_VR_12}</td>
                    <td style={tableCellStyle}>{rowData.fb_PR_12}</td>

                                   
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <BudgetVsActualComparisionDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        SelectedemEMNOE={SelectedemEMNOE}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNOE={setSelectedemEMNOE}
        setSelectedemEMNO={setSelectedemEMNO}
       
        fb_CT_RY={fb_CT_RY}
        fb_FI_YR={fb_FI_YR}
        fb_DO_CO={fb_DO_CO}
        selectedAbAdNM={selectedAbAdNM}
        setSelectedAbAdNM={setSelectedAbAdNM}
     
      />
    </div>
  );
};

export default BudgetVsActualComparisionMain;
