import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ItemPopup from "../Popups/ItemPopup";
import ButtonStyles from "views/common/ButtonStyles";
import ClassCode from "views/common/ClassCode";
import  Costcenter  from "views/common/CostCentreDrpDwn";
export default function PartListDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  PartListtable,
  setPartListtable,
  pl_CO_CT,
  setpl_CO_CT,
  pl_TR_UM,
  setpl_TR_UM,  
  pl_IS_TY,
  setpl_IS_TY,
  pl_SC_UM,
  setpl_SC_UM,
  pl_FX_VR,
  setpl_FX_VR,
  pl_OR_CO,
}) {
  const {
    pl_UK_NO,
    plWoNo,
    pl_WO_TY,
    pl_OR_QT,
  } = data;
// buttonstyles
const {plusstyle, minusstyle, tablebutton,tablestyle,multitablestyle } = ButtonStyles();
  const {borderbox} = ButtonStyles();
  const addRow = () => {
    setPartListtable([...PartListtable, {}]);
  };
  
  const deleteRow = () => {
    if (PartListtable.length > 0) {
      const updatedData = [...PartListtable];
      updatedData.pop();
      setPartListtable(updatedData);
    }
  };

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter, } = Costcenter();

  const [pl_CO_CTOptions, setpl_CO_CTOptions] = useState([]);
  const [pl_TR_UMOptions, setpl_TR_UMOptions] = useState([]);
  const [pl_IS_TYOptions, setpl_IS_TYOptions] = useState([]);
  const [pl_SC_UMOptions, setpl_SC_UMOptions] = useState([]);
  const [pl_FX_VROptions, setpl_FX_VROptions] = useState([]);


  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setpl_CO_CT(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setpl_TR_UM(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setpl_CO_CT(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setpl_TR_UM(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setpl_IS_TY(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setpl_SC_UM(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setpl_FX_VR(value);
  };

  useEffect(() => {
    fetchCost();
    fetchUM();
    fetchType();
    fetchCOby();
    fetchUMdtl();
  }, []);

  const fetchCost = async () => {
    const Options = await fetchCostCenter();
    setpl_CO_CTOptions(Options);
  };

  const fetchUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setpl_TR_UMOptions(Options);
  };

  const fetchType = async () => {
    const Options = await fetchClassCodeDS("MF/TY");
    setpl_IS_TYOptions(Options);
  };

  const fetchCOby = async () => {
    const Options = await fetchClassCodeDS("MF/CB");
    setpl_SC_UMOptions(Options);
  };

  const fetchUMdtl = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setpl_FX_VROptions(Options);
  };



const isUpdateMode = Boolean(pl_UK_NO);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  // advance search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [ParentItemVisible, setParentItemVisible] = useState(false);
  const handleDataSelect = (Result) => {
    // onchangeFormData("sl_AD_L1", Result.ab_AD_L1);
    setworkcentreVisible(false);
    setShowSearchIcon(false);
  };

  const [workcentreVisible, setworkcentreVisible] = useState(false);

  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleDataSelect1 = (content) => {
    document.getElementById (`os_WK_CT_${SelectedRowIndex}`).value =  content.wc_WK_CT;
    // onchangeFormData("os_WK_CT", content.wc_WK_CT,SelectedRowIndex);
    setworkcentreVisible(false);
    // setShowSearchIcon(false);
  };
  const openWorkcentrePopup = (e) => {
    setworkcentreVisible(true);
    setSelectedRowIndex(e);
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={pl_UK_NO ? "Revise Part List " : "Add Part List"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
        <div style={borderbox} >
            <div
                style={{
                  marginTop: "2%",
                  border: "1px solid #e8e8e8",
                  padding: "16px",
                  borderRadius: "15px",
                  backgroundColor: "aliceblue",
                  overflow: "auto",
                }}
              >
              <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_UK_NO" className="font-bold">
                   UK_NO
                  </label>
                  <InputText 
                    id="pl_UK_NO"
                    name="pl_UK_NO"
                    value={pl_UK_NO}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="plWoNo" className="font-bold">
                Order No
                  </label>
                  <InputText 
                    id="plWoNo"
                    name="plWoNo"
                    value={plWoNo}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_WO_TY" className="font-bold">
                  Order Type
                  </label>
                  <InputText  disabled
                    id="pl_WO_TY"
                    name="pl_WO_TY"
                    value={pl_WO_TY}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_OR_CO" className="font-bold">
                    Company
                  </label>
                  <InputText  disabled
                    id="pl_OR_CO"
                    name="pl_OR_CO"
                    value={pl_OR_CO}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_CO_CT" className="font-bold">
                  Cost Centre
                  </label>
                  <Dropdown
                    name="pl_CO_CT"
                    value={pl_CO_CT}
                    options={pl_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormData1("pl_CO_CT", e.value)}
                    placeholder="Select Cost Centre"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_OR_QT" className="font-bold">
                  Quantity
                  </label>
                  <InputText 
                    id="pl_OR_QT"
                    name="pl_OR_QT"
                    value={pl_OR_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_TR_UM" className="font-bold">
                  UM
                  </label>
                  <Dropdown
                    name="pl_TR_UM"
                    value={pl_TR_UM}
                    options={pl_TR_UMOptions}
                    onChange={(e) => onchangeDropDownFormData2("pl_TR_UM", e.value)}
                    placeholder="Select UM"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_TR_UM" className="font-bold">
                  Parent
                  </label>
                  <InputText 
                    id="pl_OR_QT"
                    name="pl_OR_QT"
                    value={pl_OR_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                    {/* <InputText 
                    id="pl_OR_QT"
                    name="pl_OR_QT"
                    value={pl_OR_QT}
                    onChange={(e) => onchangedata(e)}
                  /> */}
                </div>
              </div>
            </div>
        </div> 
        {/* table */}
      <div style={tablestyle}  >
        <div  className="table-buttons"  style={tablebutton} >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={plusstyle}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={minusstyle}
            tooltip="Delete"
          />
        </div>
        <div style={multitablestyle} >
        <table className="custom-table" id="PartListtable">
          <thead>
            <tr>
              <th style={{ display: "block" }}>UK NO</th>
              <th> Line No </th>
              <th> Item Number </th>
              <th> Item Description </th>
              <th> Order Quantity </th>
              <th> Issued Quantity </th>
              <th> UM </th>
              <th> Cost Centre </th>
              <th> Location </th>
              <th> Lot Number </th>
              <th> Requested Date </th>
              <th> Issued Date </th>
              <th> Issue Type </th>
              <th> Sec. Qty </th>
              <th> Sec. UM </th>
              <th> Oper. Seq. </th>
              <th> Fixed/Variable </th>
              <th> Stock </th>
            </tr>
          </thead>
          <tbody>
            {PartListtable.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "block" }}>
                  <InputText
                    id={`pl_UK_NO_${index}`}
                    name={`pl_UK_NO_${index}`}
                    value={row.pl_UK_NO}
                  />
                </td>
                <td>
                <InputText 
                style={{width:"120px"}}
                  id={`plLnNo_${index}`}
                  name={`plLnNo_${index}`}
                  value={row.plLnNo}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText 
                style={{width:"120px"}}
                  id={`cb_CO_NM_${index}`}
                  name={`cb_CO_NM_${index}`}
                  value={row.cb_CO_NM}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText 
                  style={{width:"150px"}}
                  id={`cb_CO_NM_${index}`}
                  name={`cb_CO_NM_${index}`}
                  value={row.cb_CO_NM}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                  <InputText 
                    style={{width:"90px"}}
                    id={`pl_OR_QT_${index}`}
                    name={`pl_OR_QT_${index}`}
                    value={row.pl_OR_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                <InputText 
                  style={{width:"90px"}}
                  id={`pl_IU_QT_${index}`}
                  name={`pl_IU_QT_${index}`}
                  value={row.pl_IU_QT}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <Dropdown
                  id={`pl_TR_UM_${index}`}
                  name={`pl_TR_UM_${index}`}
                  value={data[`pl_TR_UM_${index}`] ? data[`pl_TR_UM_${index}`]:row.pl_TR_UM }
                  options={pl_TR_UMOptions}
                  onChange={(e) => onchangeDropDownFormData3(`pl_TR_UM_${index}`, e.value)}
                  placeholder="Select UM"
                />
                </td>
                <td>
                <Dropdown
                  id={`pl_CO_CT_${index}`}
                  name={`pl_CO_CT_${index}`}
                  value={data[`pl_CO_CT_${index}`] ? data[`pl_CO_CT_${index}`]:row.pl_CO_CT }
                  options={pl_CO_CTOptions}
                  onChange={(e) => onchangeDropDownFormData4(`pl_CO_CT_${index}`, e.value)}
                  placeholder="Select Cost Centre"
                />
                </td>
                <td>
                  <InputText 
                    style={{width:"90px"}}
                    id={`pl_LO_CN_${index}`}
                    name={`pl_LO_CN_${index}`}
                    value={row.pl_LO_CN}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                <InputText 
                    style={{width:"90px"}}
                    id={`pl_LT_NO_${index}`}
                    name={`pl_LT_NO_${index}`}
                    value={row.pl_LT_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Calendar style={{width:"150px"}}
                    id={`pl_TR_DT_${index}`}
                    name={`pl_TR_DT_${index}`}
                    value={ row.pl_TR_DT ? moment(row.pl_TR_DT, "YYYYMMDD").toDate() : null }
                    onChange={(e) => onDateChange({ target: { name: "pl_TR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    showIcon
                    dateFormat="dd/mm/yy"
                  />
                </td>
                <td>
                <Calendar style={{width:"150px"}}
                  id={`pl_IS_DT_${index}`}
                  name={`pl_IS_DT_${index}`}
                  value={ row.pl_IS_DT ? moment(row.pl_IS_DT, "YYYYMMDD").toDate() : null }
                  onChange={(e) => onDateChange({ target: { name: "pl_IS_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  showIcon
                  dateFormat="dd/mm/yy"
                />
                </td>
                <td>
                <Dropdown
                  id={`pl_IS_TY_${index}`}
                  name={`pl_IS_TY_${index}`}
                  value={data[`pl_IS_TY_${index}`] ? data[`pl_IS_TY_${index}`]:row.pl_IS_TY }
                  options={pl_IS_TYOptions}
                  onChange={(e) => onchangeDropDownFormData5(`pl_IS_TY_${index}`, e.value)}
                  placeholder="Select Issue Type"
                />
                </td>
                <td>
                <InputText 
                    style={{width:"90px"}}
                    id={`pl_SC_QT_${index}`}
                    name={`pl_SC_QT_${index}`}
                    value={row.pl_SC_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                <Dropdown
                  id={`pl_SC_UM_${index}`}
                  name={`pl_SC_UM_${index}`}
                  value={data[`pl_SC_UM_${index}`] ? data[`pl_SC_UM_${index}`]:row.pl_SC_UM }
                  options={pl_SC_UMOptions}
                  onChange={(e) => onchangeDropDownFormData6(`pl_SC_UM_${index}`, e.value)}
                  placeholder="Select Sec. UM"
                />
                </td>
                <td>
                <InputText 
                    style={{width:"90px"}}
                    id={`pl_OP_SQ_${index}`}
                    name={`pl_OP_SQ_${index}`}
                    value={row.pl_OP_SQ}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                <Dropdown
                  id={`pl_FX_VR_${index}`}
                  name={`pl_FX_VR_${index}`}
                  value={data[`pl_FX_VR_${index}`] ? data[`pl_FX_VR_${index}`]:row.pl_FX_VR }
                  options={pl_FX_VROptions}
                  onChange={(e) => onchangeDropDownFormData7(`pl_FX_VR_${index}`, e.value)}
                  placeholder="Select Fixed/Variable"
                />
                </td>
                <td>
                <InputText 
                    style={{width:"90px"}}
                    id={`STOCK_${index}`}
                    name={`STOCK_${index}`}
                    value={row.STOCK}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>


              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <ItemPopup
        visible={ParentItemVisible}
        onClose={() => setParentItemVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
    </Dialog>
  );
}
