import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import InspectionDetailsDialog from "./InspectionDetailsDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  idUkNo:"",
  id_TR_NO:"",
  id_TR_TY:"",
  id_DO_CO:"",   
  id_EQ_NM:"",   
  equpment_NAME:"",
  id_TR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  id_CO_CT:"", 
  id_CU_NO:"",
  cus_NAME:"",      
  id_RG_NO:"",
  id_TX_AP:"",
  id_DS_PE:"",
  id_DS_AM:"",   
  id_IN_NM:"",   
  ins_name:"",
  id_ST_CD:"",
  id_PA_NO:"", 
  part_name:"",
  id_TR_QT:"",      
  id_TR_UM:"",  
  id_UN_PR:"",
  id_LB_AM:"",
  id_LB_HR:"",   
  id_UN_RT:"",   
  id_EX_PR:"",
  id_TX_PE:"",
  id_TX_AM:"", 
  id_RM_RK:"",    
};

const initialFilters = {
  id_TR_NO: { value: "", operation: "" },
  id_TR_TY: { value: "", operation: "" },
  id_DO_CO: { value: "", operation: "" },   
  id_EQ_NM: { value: "", operation: "" },   
  equpment_NAME: { value: "", operation: "" },
  id_TR_DT: { value: "", operation: "" },
  id_CO_CT: { value: "", operation: "" }, 
  id_CU_NO: { value: "", operation: "" },
  cus_NAME: { value: "", operation: "" },      
  id_RG_NO: { value: "", operation: "" },
  id_TX_AP: { value: "", operation: "" },
  id_DS_PE: { value: "", operation: "" },
  id_DS_AM: { value: "", operation: "" },   
  id_IN_NM: { value: "", operation: "" },   
  ins_name: { value: "", operation: "" },
  id_ST_CD: { value: "", operation: "" },
  id_PA_NO: { value: "", operation: "" }, 
  part_name: { value: "", operation: "" },
  id_TR_QT: { value: "", operation: "" },      
  id_TR_UM: { value: "", operation: "" },  
  id_UN_PR: { value: "", operation: "" },
  id_LB_AM: { value: "", operation: "" },
  id_LB_HR: { value: "", operation: "" },   
  id_UN_RT: { value: "", operation: "" },   
  id_EX_PR: { value: "", operation: "" },
  id_TX_PE: { value: "", operation: "" },
  id_TX_AM: { value: "", operation: "" }, 
  id_RM_RK: { value: "", operation: "" },     
};

const InspectionDetailsMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,inputStyle1,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  const [id_CO_CT, setid_CO_CT ] = useState("");
  const [id_TX_AP, setid_TX_AP ] = useState("");
  const [id_TR_UM, setid_TR_UM ] = useState("");
  const [id_TR_TY, setid_TR_TY ] = useState("");
  
// Advancesearch
const [SelectedEquipment, setSelectedEquipment] = useState("");
const [SelectedCustomer, setSelectedCustomer] = useState("");
const [SelectedInspection, setSelectedInspection] = useState("");
const [SelectedParts, setSelectedParts] = useState("");

// Next No
  const [id_TR_NO, setid_TR_NO] = useState("");
  const [id_DO_CO , setid_DO_CO] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [Inspectiondetailstable, setInspectiondetailstable] = useState([{}]);
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setInspectiondetailstable([{}]);
    setSelectedEquipment(null);
    setSelectedCustomer(null);
    setSelectedInspection(null);
    setSelectedParts(null);
    setid_TX_AP(null);
    setid_TR_UM(null);
    setid_TR_NO(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/InspectionDetails/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const InspectionDetailsData = response.data;
          delete InspectionDetailsData.idUkNo;
          delete InspectionDetailsData.id_TR_NO;
          for (let i = 0; i < InspectionDetailsData.inspectionDetails.length; i++) {
            delete InspectionDetailsData.inspectionDetails[i].idUkNo;
            delete InspectionDetailsData.inspectionDetails[i].id_TR_NO;
          }
          setInspectiondetailstable(InspectionDetailsData.inspectionDetails);
          setFormData(InspectionDetailsData.inspectionDetails[0]);
          setSelectedEquipment(InspectionDetailsData.inspectionDetails[0].equpment_NAME);  
          setSelectedCustomer(InspectionDetailsData.inspectionDetails[0].cus_NAME);      
          setid_TX_AP(InspectionDetailsData.inspectionDetails[0].id_TX_AP); 
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  // Cost CENTRE
  const onchangeDropdownData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setid_CO_CT(value);
};

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            setid_DO_CO(`${company}`);
            if(fName === "ID_TR_TY"){
              setid_TR_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "ID_CO_CT"){
              onchangeDropdownData1("id_CO_CT",dataDefault.versionIds[i].default_VALUE);
              setid_CO_CT(dataDefault.versionIds[i].default_VALUE);
            } 
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/InspectionDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({...prevData,  [name]: value, }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/InspectionDetails/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const InspectionDetailsData = response.data;
        setInspectiondetailstable(InspectionDetailsData.inspectionDetails);
        setFormData(InspectionDetailsData.inspectionDetails[0]);
        setSelectedEquipment(InspectionDetailsData.equpment_NAME);  
        setSelectedCustomer(InspectionDetailsData.cus_NAME);      
        setid_TX_AP(InspectionDetailsData.id_TX_AP);  
        setid_TR_NO(InspectionDetailsData.id_TR_NO); 
        setSelectedInspection(response.data.inspectionDetails[0].inspection_Name);  
        setSelectedParts(response.data.inspectionDetails[0].parts_Name); 
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBody = {};
  function values () {
    const table = document.getElementById("InspectionDetailsTable");
    const totalRowCount1 = table.tBodies[0].rows.length;
    const inspectionDetails = [];
    for (let i = 0; i < totalRowCount1; i++) {
      const details = {
          idUkNo : document.getElementById("idUkNo_" + i).value,
          id_IN_NM : document.getElementById("id_IN_NM_" + i).value,
          id_PA_NO : document.getElementById("id_PA_NO_" + i).value,
          id_TR_QT : document.getElementById("id_TR_QT_" + i).value,
          id_TR_UM : table.tBodies[0].rows[i].cells[6].children[0].children[1].children[0].value,
          id_UN_PR : document.getElementById("id_UN_PR_" + i).value,
          id_LD_AM : document.getElementById("id_LD_AM_" + i).value,
          id_LB_HR : document.getElementById("id_LB_HR_" + i).value,
          id_UN_RT : document.getElementById("id_UN_RT_" + i).value,
          id_LB_AM : document.getElementById("id_LB_AM_" + i).value,
          id_DS_PE : document.getElementById("id_DS_PE_" + i).value,
          id_DS_AM : document.getElementById("id_DS_AM_" + i).value,
          id_TX_PE : document.getElementById("id_TX_PE_" + i).value,
          id_TX_AM : document.getElementById("id_TX_AM_" + i).value,
          id_RM_RK : document.getElementById("id_RM_RK_" + i).value,
      };
      inspectionDetails.push(details);
    }
    requestBody = {
      inspectionDetails,
      idUkNo   : FormData.idUkNo,
      id_TR_NO : document.getElementById("id_TR_NO").value,
      id_TR_TY : document.getElementById("id_TR_TY").value,
      id_DO_CO : document.getElementById("id_DO_CO").value,
      id_EQ_NM : document.getElementById("id_EQ_NM").value,
      id_CU_NO : document.getElementById("id_CU_NO").value,
      id_TR_DT : FormData.id_TR_DT,
      id_CO_CT : FormData.id_CO_CT,
      id_TX_AP : FormData.id_TX_AP,
      id_RG_NO : document.getElementById("id_RG_NO").value,
      id_DS_PE : document.getElementById("id_DS_PE").value,
      id_DS_AM : document.getElementById("id_DS_AM").value,
    };
  };
  const handleFormSubmit = () => {
  values();
  const orNo = document.getElementById("id_TR_NO").value;
  const ukNo = FormData.idUkNo;
  if (orNo) {
    if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/InspectionDetails/Update`;
  
      if (confirm) {
        axios.put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/InspectionDetails/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  } else {
      
      const TR_DT = FormData.id_TR_DT;
      if (TR_DT) {
        const currentYear = TR_DT.toString();
        const firstHalf = currentYear.substring(0, 2);
        const secondHalf = currentYear.substring(2, 4);
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
        const parameters = `${id_TR_TY}/${secondHalf}/1000/${firstHalf}`;
        const finalUrl1 = `${urlNextNumber}/${parameters}`;
        fetch(finalUrl1, {
          method: "GET",
          headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
        })
          .then((resp) => {
            if (!resp.ok) {
              throw new Error(`Request failed with status: ${resp.status}`);
            }
            return resp.json();
          })
          .then((resp) => {
            setid_TR_NO(resp.dn_DO_NO);
            document.getElementById("id_TR_NO").value = resp.dn_DO_NO;

            values();
            axios.post(`${domain}/api/InspectionDetails/Add`, requestBody, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error saving data:", error);
                });

          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["id_TR_NO"] ? filters["id_TR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_TR_NO",
                        e.target.value,
                        filters["id_TR_NO"]
                          ? filters["id_TR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["id_TR_TY"] ? filters["id_TR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_TR_TY",
                        e.target.value,
                        filters["id_TR_TY"] ? filters["id_TR_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["id_DO_CO"] ? filters["id_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_DO_CO",
                        e.target.value,
                        filters["id_DO_CO"]
                          ? filters["id_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["id_EQ_NM"] ? filters["id_EQ_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_EQ_NM",
                        e.target.value,
                        filters["id_EQ_NM"]
                          ? filters["id_EQ_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["equpment_NAME"] ? filters["equpment_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "equpment_NAME",
                        e.target.value,
                        filters["equpment_NAME"] ? filters["equpment_NAME"].operation : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["id_TR_DT"] ? filters["id_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_TR_DT",
                        e.target.value,
                        filters["id_TR_DT"]
                          ? filters["id_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["id_CO_CT"] ? filters["id_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_CO_CT",
                        e.target.value,
                        filters["id_CO_CT"]
                          ? filters["id_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["id_CU_NO"] ? filters["id_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_CU_NO",
                        e.target.value,
                        filters["id_CU_NO"]
                          ? filters["id_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["cus_NAME"] ? filters["cus_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cus_NAME",
                        e.target.value,
                        filters["cus_NAME"]
                          ? filters["cus_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["id_RG_NO"] ? filters["id_RG_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_RG_NO",
                        e.target.value,
                        filters["id_RG_NO"]
                          ? filters["id_RG_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["id_TX_AP"] ? filters["id_TX_AP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_TX_AP",
                        e.target.value,
                        filters["id_TX_AP"]
                          ? filters["id_TX_AP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["id_DS_PE"] ? filters["id_DS_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_DS_PE",
                        e.target.value,
                        filters["id_DS_PE"]
                          ? filters["id_DS_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["id_DS_AM"] ? filters["id_DS_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_DS_AM",
                        e.target.value,
                        filters["id_DS_AM"]
                          ? filters["id_DS_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["id_IN_NM"] ? filters["id_IN_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_IN_NM",
                        e.target.value,
                        filters["id_IN_NM"]
                          ? filters["id_IN_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ins_name"] ? filters["ins_name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ins_name",
                        e.target.value,
                        filters["ins_name"] ? filters["ins_name"].operation : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                {/* <td>
                  <input
                    type="text"
                    value={filters["id_ST_CD"] ? filters["id_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_ST_CD",
                        e.target.value,
                        filters["id_ST_CD"]
                          ? filters["id_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  */}
                <td>
                  <input
                    type="text"
                    value={filters["id_PA_NO"] ? filters["id_PA_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_PA_NO",
                        e.target.value,
                        filters["id_PA_NO"]
                          ? filters["id_PA_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["part_name"] ? filters["part_name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "part_name",
                        e.target.value,
                        filters["part_name"] ? filters["part_name"].operation : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["id_TR_QT"] ? filters["id_TR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_TR_QT",
                        e.target.value,
                        filters["id_TR_QT"]
                          ? filters["id_TR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["id_TR_UM"] ? filters["id_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_TR_UM",
                        e.target.value,
                        filters["id_TR_UM"]
                          ? filters["id_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["id_UN_PR"] ? filters["id_UN_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_UN_PR",
                        e.target.value,
                        filters["id_UN_PR"]
                          ? filters["id_UN_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["id_LB_AM"] ? filters["id_LB_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_LB_AM",
                        e.target.value,
                        filters["id_LB_AM"]
                          ? filters["id_LB_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["id_LB_HR"] ? filters["id_LB_HR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_LB_HR",
                        e.target.value,
                        filters["id_LB_HR"]
                          ? filters["id_LB_HR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["id_UN_RT"] ? filters["id_UN_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_UN_RT",
                        e.target.value,
                        filters["id_UN_RT"]
                          ? filters["id_UN_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["id_EX_PR"] ? filters["id_EX_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_EX_PR",
                        e.target.value,
                        filters["id_EX_PR"]
                          ? filters["id_EX_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["id_TX_PE"] ? filters["id_TX_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_TX_PE",
                        e.target.value,
                        filters["id_TX_PE"]
                          ? filters["id_TX_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["id_TX_AM"] ? filters["id_TX_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_TX_AM",
                        e.target.value,
                        filters["id_TX_AM"]
                          ? filters["id_TX_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["id_RM_RK"] ? filters["id_RM_RK"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_RM_RK",
                        e.target.value,
                        filters["id_RM_RK"]
                          ? filters["id_RM_RK"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Trans. No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>  
                <th style={tableHeaderStyle}>Equipment Name</th>   
                <th style={tableHeaderStyle}>Description</th>  
                <th style={tableHeaderStyle}>Date</th> 
                <th style={tableHeaderStyle}>Cost Centre</th>  
                <th style={tableHeaderStyle}>Customer</th>  
                <th style={tableHeaderStyle}>Name</th> 
                <th style={tableHeaderStyle}>Registration No</th> 
                <th style={tableHeaderStyle}>Tax Applicable</th>
                <th style={tableHeaderStyle}>Discount %</th>
                <th style={tableHeaderStyle}>Discount Amount</th>  
                <th style={tableHeaderStyle}>Inspection Name</th>   
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Parts Number</th>  
                <th style={tableHeaderStyle}>Description</th>  
                <th style={tableHeaderStyle}>Quantity</th> 
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Unit Price</th>  
                <th style={tableHeaderStyle}>Amount</th> 
                <th style={tableHeaderStyle}>Labour Hrs</th>  
                <th style={tableHeaderStyle}>Unit Rate</th>  
                <th style={tableHeaderStyle}>Amount</th> 
                <th style={tableHeaderStyle}>Tax %</th>   
                <th style={tableHeaderStyle}>Tax Amount</th> 
                <th style={tableHeaderStyle}>Remarks</th>         
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.idUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.idUkNo}
                        checked={selectedRows.includes(rowData.idUkNo)}
                        onChange={() => handleRowSelect(rowData.idUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.id_TR_NO}</td>
                    <td style={tableCellStyle}>{rowData.id_TR_TY}</td>
                    <td style={tableCellStyle}>{rowData.id_DO_CO}</td>   
                    <td style={tableCellStyle}>{rowData.id_EQ_NM}</td>   
                    <td style={tableCellStyle}>{rowData.equpment_NAME}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.id_TR_DT)}</td>
                    <td style={tableCellStyle}>{rowData.id_CO_CT}</td> 
                    <td style={tableCellStyle}>{rowData.id_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.cus_NAME}</td>      
                    <td style={tableCellStyle}>{rowData.id_RG_NO}</td>
                    <td style={tableCellStyle}>{rowData.id_TX_AP}</td>
                    <td style={tableCellStyle}>{rowData.id_DS_PE}</td>
                    <td style={tableCellStyle}>{rowData.id_DS_AM}</td>   
                    <td style={tableCellStyle}>{rowData.id_IN_NM}</td>   
                    <td style={tableCellStyle}>{rowData.inspection_Name}</td>
                    {/* <td style={tableCellStyle}>{rowData.id_ST_CD}</td> */}
                    <td style={tableCellStyle}>{rowData.id_PA_NO}</td> 
                    <td style={tableCellStyle}>{rowData.parts_Name}</td>
                    <td style={tableCellStyle}>{rowData.id_TR_QT}</td>      
                    <td style={tableCellStyle}>{rowData.id_TR_UM}</td>  
                    <td style={tableCellStyle}>{rowData.id_UN_PR}</td>
                    <td style={tableCellStyle}>{rowData.id_LB_AM}</td>
                    <td style={tableCellStyle}>{rowData.id_LB_HR}</td>   
                    <td style={tableCellStyle}>{rowData.id_UN_RT}</td>   
                    <td style={tableCellStyle}>{rowData.id_EX_PR}</td>
                    <td style={tableCellStyle}>{rowData.id_TX_PE}</td>
                    <td style={tableCellStyle}>{rowData.id_TX_AM}</td> 
                    <td style={tableCellStyle}>{rowData.id_RM_RK}</td>            
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <InspectionDetailsDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        Inspectiondetailstable={Inspectiondetailstable}
        setInspectiondetailstable={setInspectiondetailstable}
        id_DO_CO={id_DO_CO}
        id_TR_TY={id_TR_TY}
        id_CO_CT={id_CO_CT}
        setid_CO_CT={setid_CO_CT}
        id_TX_AP={id_TX_AP}
        setid_TX_AP={setid_TX_AP}
        id_TR_UM={id_TR_UM}
        setid_TR_UM={setid_TR_UM}
        id_TR_NO={id_TR_NO}
        SelectedEquipment={SelectedEquipment}
        setSelectedEquipment={setSelectedEquipment}
        SelectedCustomer={SelectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        SelectedInspection={SelectedInspection}
        setSelectedInspection={setSelectedInspection}
        SelectedParts={SelectedParts}
        setSelectedParts={setSelectedParts}
        onchangeDropdownData1={onchangeDropdownData1}
      />
    </div>
  );
};

export default InspectionDetailsMain;
