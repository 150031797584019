import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import SalesOppurtunitiesDialog from "./SalesOppurtunitiesDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import '../../../components/ListStyle/ListStyle.css';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  so_TR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  so_OP_ID: "",
  so_LD_ID: "",
  so_CU_NO: "",
  so_CO_PE: "",
  so_TI_TL: "",
  so_PO_AM: "",
  so_BU_AM: "",
  so_OP_ST: "",
  so_CL_DT: "",
};
const SalesOppurtunitiesMain = () => {
  const initialFilters = {
    so_TR_DT: { value: "", operation: "" },
    so_OP_ID: { value: "", operation: "" },
    so_LD_ID: { value: "", operation: "" },
    so_CU_NO: { value: "", operation: "" },
    so_CO_PE: { value: "", operation: "" },
    so_TI_TL: { value: "", operation: "" },
    so_PO_AM: { value: "", operation: "" },
    so_BU_AM: { value: "", operation: "" },
    so_OP_ST: { value: "", operation: "" },
    so_CL_DT: { value: "", operation: "" },
  };
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();

  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [so_OP_ID, setso_OP_ID] = useState("");
  const [so_CU_NO, setso_CU_NO] = useState("");
  const [so_LD_OW, setso_LD_OW] = useState("");
  const [so_CO_PE, setso_CO_PE] = useState("");
  const [soUkNo, setsoUkNo] = useState("");
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters); 

  const [LeadOwnerPopupVisible, setLeadOwnerVisible] = useState(false);
  const [addressTypeLDOW, setaddressTypeLDOW] = useState("E");
  const [selectedowner, setSelectedowner] = useState("");
  const [selectedAbAdNN, setSelectedAbAdNN] = useState("");
  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [selectedAbAdNo, setSelectedAbAdNo] = useState("");
  const [selectedAbAdNp, setSelectedAbAdNp] = useState("");
  const [selectedAbAdNc, setSelectedAbAdNc] = useState("");
  const[ selectedSLLDID,setSelectedSLLDID] = useState("");

  const [so_OP_STOptions, setso_OP_STOptions] = useState([]);
  const [so_TR_CR, setso_TR_CR] = useState([]);
  const [so_LO_RE, setso_LO_RE] = useState([]);
  const [so_OP_ST, setso_OP_ST] = useState([]);
  const [Status, setStatus] = useState("");
  const [Owner, setOwner] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const openLeadOwnerPopup = () => {
    setLeadOwnerVisible(true);
  };

  const updateFilters = (cus) => {
  let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      so_LD_OW: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const updateFilters1 = (sts) => {
  let updatedFilters = { ...filters };
  if (sts !== null) {
    updatedFilters = {
      ...updatedFilters,
      so_OP_ST: {
        value: sts,
        operation: "LIKE",
      },
    };
  }
  setFilters(updatedFilters);
  };

  const url11 = `${domain}/api/ClassCode/All`;
  const parameter2 = "OP/ST";
  const finalUrl2 = `${url11}/${parameter2}`;
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setso_OP_STOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedAbAdNN(null);
    setSelectedAbAdNM(null);
    setSelectedAbAdNo(null);
    setSelectedAbAdNp(null);
    setSelectedAbAdNc(null);
    setso_TR_CR(null)
    setso_LO_RE(null)
    setso_OP_ST(null)
    setso_OP_ID(null);
    setso_CO_PE(null);
    setso_CU_NO(null);
    setsoUkNo(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setSelectedAbAdNN(null);
    setSelectedAbAdNM(null);
    setSelectedAbAdNo(null);
    setSelectedAbAdNp(null);
    setSelectedAbAdNc(null);
    setso_TR_CR(null)
    setso_LO_RE(null)
    setso_OP_ST(null)
    setOpen(false);
  };

  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/SalesOpportunity/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const SalesOppurtunitiesData = response.data;
          delete SalesOppurtunitiesData.soUkNo;
          delete SalesOppurtunitiesData.so_OP_ID;
          setFormData(SalesOppurtunitiesData);
          setso_CU_NO(SalesOppurtunitiesData.so_CU_NO);
          setSelectedAbAdNN(response.data.cus_NAME);
          setSelectedAbAdNM(response.data.influencer );
          setso_LD_OW(SalesOppurtunitiesData.so_LD_OW);
          setSelectedAbAdNo(SalesOppurtunitiesData.lead_NAME );
          setSelectedAbAdNp(SalesOppurtunitiesData.partner_NAME);
          setSelectedAbAdNc(SalesOppurtunitiesData.contact_NAME);
          setso_TR_CR(SalesOppurtunitiesData.so_TR_CR);
          setso_LO_RE(SalesOppurtunitiesData.so_LO_RE);
          setso_OP_ST(SalesOppurtunitiesData.so_OP_ST);
          setso_CO_PE(SalesOppurtunitiesData.so_CO_PE);
          setsoUkNo(null);
          setso_OP_ID(null);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleDataSelect = (data) => {
    setSelectedowner(data.ab_AD_NM);
    setOwner( data.abAdNo);
    setLeadOwnerVisible(false);
    setShowSearchIcon(false);
    updateFilters (data.abAdNo);
  };
  const onchangeDropDown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setStatus(value);
    updateFilters1 (value);
    console.log(value);
  };

  // DEFAULT TYPE AND COMPANY

  const [so_LD_TY, setso_LD_TY] =useState("");
  const [so_OP_CO, setso_OP_CO] = useState("");
  const [so_OP_TY, setso_OP_TY] = useState("");



  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
              setso_OP_CO(`${company}`)
            if(fName === "SO_LD_TY"){
              setso_LD_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SO_OP_TY"){
              setso_OP_TY(dataDefault.versionIds[i].default_VALUE)
            }        
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    handleDefault();
    fetchDropdownOptions2();
  }, [currentPage, pageSize, filters]);


  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/SalesOpportunity/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeDropDownFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setso_TR_CR(value);
   
  };

  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setso_LO_RE(value);
   
  };

  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setso_OP_ST(value);
   
  };
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/SalesOpportunity/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const SalesOpportunity = response.data;
        setFormData(SalesOpportunity);
        setso_CU_NO(SalesOpportunity.so_CU_NO);
        setSelectedAbAdNN(response.data.cus_NAME);
        setSelectedAbAdNM(response.data.influencer );
        setso_LD_OW(SalesOpportunity.so_LD_OW);
        setSelectedAbAdNo(response.data.lead_NAME);
        setSelectedAbAdNp(response.data.partner_NAME);
        setSelectedAbAdNc(response.data.contact_NAME);
        setso_TR_CR(response.data.so_TR_CR)
        setso_LO_RE(response.data.so_LO_RE)
        setso_OP_ST(response.data.so_OP_ST)
        setso_OP_ID(response.data.so_OP_ID)
        setso_CO_PE(SalesOpportunity.so_CO_PE);
        setsoUkNo(SalesOpportunity.soUkNo);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  var requestBodyInput = {};
  function values() {
    const OP_ID = document.getElementById("so_OP_ID");
    const TR_DT = FormData.so_TR_DT;
    const LD_ID = document.getElementById("so_LD_ID");
    const SC_OR = document.getElementById("so_SC_OR");
    const LD_OW = document.getElementById("so_LD_OW");
    const PR_TY = document.getElementById("so_PR_TY");
    const CO_PE = document.getElementById("so_CO_PE");
    const PO_AM = document.getElementById("so_PO_AM");
    const IN_FL = document.getElementById("so_IN_FL");
    const BU_AM = document.getElementById("so_BU_AM");
    const OP_PA = document.getElementById("so_OP_PA");
    const LD_TY = document.getElementById("so_LD_TY");

    requestBodyInput = {
      soUkNo : document.getElementById("soUkNo").value,
      so_OP_ID: OP_ID.value,
      so_TR_DT: TR_DT,
      so_LD_ID: LD_ID.value,
      so_CU_NO: document.getElementById("so_CU_NO").value,
      so_SC_OR: SC_OR.value,
      so_LD_OW: LD_OW.value,
      so_PR_TY: PR_TY.value,
      so_CO_PE: CO_PE.value,
      so_PO_AM: PO_AM.value,
      so_IN_FL: IN_FL.value,
      so_BU_AM: BU_AM.value,
      so_OP_PA: OP_PA.value,
      so_TR_CR: so_TR_CR,
      so_OP_TY: document.getElementById("so_OP_TY").value,
      so_LD_TY: document.getElementById("so_LD_TY").value,
      so_OP_CO :company,
      so_LD_CO :company,
      so_LO_RE: so_LO_RE,
      so_OP_ST: so_OP_ST
    };
  }


  const handleFormSubmit = () => {
    values();
    if(document.getElementById("so_OP_ID").value){
    if (document.getElementById("soUkNo").value) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/SalesOpportunity/Update`;
      if (confirm) {
        axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/SalesOpportunity/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
      }else {
        const OR_DT = FormData.so_TR_DT;
        if(OR_DT){
          const currentYear = OR_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${so_OP_TY}/${secondHalf}/1000/${firstHalf}`; 
          const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            setso_OP_ID(resp.dn_DO_NO);
            document.getElementById("so_OP_ID").value = resp.data.dn_DO_NO;
            if(resp.data.dn_DO_NO ===undefined)
            {
              handleResponse(resp.data);
            }else{
              values();
              axios.post(`${domain}/api/SalesOpportunity/Add`, requestBodyInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error saving data:", error);
              });
            }
           
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
      }
    };
  
    const handleResponse = (data) => {
      const { warnings = [], errorMessage = [], infos = [] } = data;
    
      if (warnings.length > 0) {
        const warningMessage = warnings.map((item) => `${item}\n`).join("");
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: warningMessage,
        });
      }
    
      if (infos.length > 0) {
        const infoMessage = infos.map((item) => `${item}\n`).join("");
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: infoMessage,
        });
      }
    
      if (errorMessage.length > 0) {
        const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMessages,
        });
      }
    
      if (errorMessage.length === 0) {
        handleClose();
        handleDefault();
      }
    };

  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "red !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
     
      <Tooltip target=".add-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "white !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "white !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const tableHeaderStyleCheck = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width:"0%"
  };

  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
    backgroundColor:"#f5f4f4"
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button style={{height: "35px",backgroundColor:"#1ed01e",border:"none"}}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  const AdvanceSearch = (
      <div className="formgrid grid">
      <div className="field col">
        <label htmlFor="Owner" className="font-bold">
          Owner <span style={{ color: 'red' }}>*</span>
        </label>
        <div style={{ display: "flex" }}>
          <InputText
          style={{display:"none"}}
            id="Owner"
            name="Owner"
            value={Owner}
            onChange={(Event) => onchangedata(Event)}
          />
           <InputText
            id="cc_EM_NM"
            name="cc_EM_NM"
            readOnly
            style={{backgroundColor:"#ffffff"}}
            onChange={(Event) => onchangedata(Event)}
            onFocus={() => setShowSearchIcon(true)}
            value={selectedowner}
          />
          {showSearchIcon && (
          <i
            className="pi pi-search"
            style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
            }}
            onClick={openLeadOwnerPopup}
          ></i>
          )}
        </div>
      </div>
        <div className="field col" style={{display:"grid"}}>
              <label htmlFor="Status" className="font-bold">
                Status
              </label>
              <Dropdown
              style={{width:"140px"}}
                id="Status"
                name="Status"
                value={Status}
                options={so_OP_STOptions}
                onChange={(e) => onchangeDropDown("Status", e.value)}
                placeholder="Select Status"
              />
        </div>
    </div>
  );
  const handleClearButton=()=>{
    setStatus(null);
    setSelectedowner("");
    setOwner("");
    setFilters("");
  };
  const rightClear = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-times"
        className="p-button-help"
        link
        onClick={handleClearButton}
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}/>
        <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
        </div>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["so_OP_ID"] ? filters["so_OP_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "so_OP_ID",
                        e.target.value,
                        filters["so_OP_ID"]
                          ? filters["so_OP_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["so_TR_DT"] ? filters["so_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "so_TR_DT",
                        e.target.value,
                        filters["so_TR_DT"]
                          ? filters["so_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["so_LD_OW"] ? filters["so_LD_OW"].value : ""}
					readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "so_LD_OW",
                        e.target.value,
                        filters["so_LD_OW"]
                          ? filters["so_LD_OW"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["so_CU_NO"] ? filters["so_CU_NO"].value : ""}
                    readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "so_CU_NO",
                        e.target.value,
                        filters["so_CU_NO"]
                          ? filters["so_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    readOnly
                    value={filters["so_CO_PE"] ? filters["so_CO_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "so_CO_PE",
                        e.target.value,
                        filters["so_CO_PE"]
                          ? filters["so_CO_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
               
                <td>
                  <input
                    type="text"
                    value={filters["so_PO_AM"] ? filters["so_PO_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "so_PO_AM",
                        e.target.value,
                        filters["so_PO_AM"]
                          ? filters["so_PO_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["so_BU_AM"] ? filters["so_BU_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "so_BU_AM",
                        e.target.value,
                        filters["so_BU_AM"]
                          ? filters["so_BU_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                  readOnly
                    width="100%"
                    type="text"
                    style={inputStyleH}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyleCheck}></th>
               
                <th style={tableHeaderStyle}>Opportunity ID</th>
                <th style={tableHeaderStyle}>Date</th>
                <th style={tableHeaderStyle}>Lead Owner</th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyle}>Contact</th>
                <th style={tableHeaderStyle}>Potential Amount</th>
                <th style={tableHeaderStyle}>Budget Amount</th>
                <th style={tableHeaderStyle}>Status</th>    

                
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.soUkNo}
                    style={{
                      backgroundColor: index % 2 === 0 ? 'aliceblue' : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        type="checkbox"
                        value={rowData.soUkNo}
                        checked={selectedRows.includes(rowData.soUkNo)}
                        onChange={() => handleRowSelect(rowData.soUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.so_OP_ID}</td>                    
                    <td style={tableCellStyle}>{formatDate(rowData.so_TR_DT)}</td>
                    <td style={tableCellStyle}>{rowData.lead_NAME}</td>
                    <td style={tableCellStyle}>{rowData.cus_NAME}</td>
                    <td style={tableCellStyle}>{rowData.contact_NAME}</td>
                    {/* <td style={tableCellStyle}>{rowData.so_TI_TL}</td> */}
                    <td style={tableCellStyle}>{rowData.so_PO_AM}</td>
                    <td style={tableCellStyle}>{rowData.so_BU_AM}</td>
                    <td style={tableCellStyle}>{rowData.status}</td>              
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
       {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
       {/* Lead Owner */}
       <AddressBookPopup
        visible={LeadOwnerPopupVisible}
        onClose={() => setLeadOwnerVisible(false)}
        onDataSelect={handleDataSelect}
        formData={FormData}
        addressType={addressTypeLDOW}
        setFormData={setFormData}
      />
      <SalesOppurtunitiesDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangeFormData={onchangeFormData}
        onchangedata={onchangedata}
        so_OP_ID={so_OP_ID}
        so_TR_CR={so_TR_CR}
        selectedAbAdNM={selectedAbAdNM}
        selectedAbAdNN={selectedAbAdNN}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedAbAdNN={setSelectedAbAdNN}
        selectedAbAdNo={selectedAbAdNo}
        selectedAbAdNp={selectedAbAdNp}
        setSelectedAbAdNo={setSelectedAbAdNo}
        setSelectedAbAdNp={setSelectedAbAdNp}
        setSelectedAbAdNc={setSelectedAbAdNc}
        selectedAbAdNc={selectedAbAdNc}
        so_LO_RE={so_LO_RE}
        so_OP_ST={so_OP_ST}
        so_OP_CO={so_OP_CO}
        so_OP_TY={so_OP_TY}
        so_LD_TY={so_LD_TY}
        // so_LD_ID={so_LD_ID}
        so_CU_NO={so_CU_NO}
        setso_CU_NO={setso_CU_NO}
        so_LD_OW={so_LD_OW}
        setso_LD_OW={setso_LD_OW}
        so_CO_PE={so_CO_PE}
        setso_CO_PE={setso_CO_PE}
        onchangeDropDownFormData={onchangeDropDownFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        selectedSLLDID={selectedSLLDID}
        setSelectedSLLDID={setSelectedSLLDID}
        soUkNo={soUkNo}
        setFormData={setFormData}
      />
    </div>
  );
};

export default SalesOppurtunitiesMain;
