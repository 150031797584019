import React from 'react';

const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(0);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };


  return (
      <div className="Pagination" style={{ marginLeft: "85%" }}>
          <span onClick={handleFirstPage}
            style={{ fontSize: "200%", cursor: "pointer", whiteSpace: "pre-wrap", }}  >
            &laquo;{" "}
          </span>{" "}
          <span
            onClick={handlePreviousPage}
            style={{ cursor: "pointer",fontSize: "111%", whiteSpace: "pre-wrap",}} >
            Prev{" "}
          </span>{" "}
          <span style={{ whiteSpace: "pre-wrap", fontSize: "111%" }}> </span>{" "}
          <span
            onClick={handleNextPage}
            style={{ cursor: "pointer", fontSize: "111%", whiteSpace: "pre-wrap", }} >
            Next{" "}
          </span>{" "}
          <span
            onClick={handleLastPage}
            style={{ fontSize: "200%", cursor: "pointer" }} >
            &raquo;
          </span>
      </div>
  );
};

export default Pagination;
