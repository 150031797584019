import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ItemPopup from "../Popups/ItemPopup";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ProjectMasterPopup from "views/admin/Popups/ProjectMasterPopup";
import ClassCode from "views/common/ClassCode";
import PayTerm from "views/common/PayTermDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function EstimateEntryDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  EstimateEntryTableData,
  setEstimateEntryTableData,
  eh_TR_CR,
  CurrencyDropDown,
  onchangegldate,
  eh_OR_TY,
  eh_OR_CO,
  eh_BS_CR,
  eh_EX_TR,
  seteh_EX_TR,
  eh_CO_CT,
  eh_PY_TR,
  seteh_PY_TR,
  eh_HD_CD,
  seteh_HD_CD,
  eh_TX_AP,
  seteh_TX_AP,
  seted_LN_TY,
  seted_TR_UM,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  SelectedProjectMaster,
  setSelectedProjectMaster,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNO1,
  setSelectedemEMNO1,
  CostCentreDropDown,
  ed_CO_CT,
  ed_ST_CD,
  HoldCodeDropDown
}) {
  const {
    edUkNo,
    ehUkNo,
    eh_CU_NO,
    eh_OR_NO,
    eh_OR_DT,
    eh_RQ_DT,
    eh_PR_NO,
    eh_EN_RF,
    eh_PR_RF,
    eh_RV_NO,
    eh_DS_PR,
    eh_DS_AT,
    eh_CO_NO,
    eh_CO_PE,
    price_AMOUNT,
    discount_AMOUNT,
    net_AMOUNT,
    tax_AMOUNT,
    ed_OR_QT,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const { fetchPayTerm } = PayTerm();
  const [eh_PY_TROptions, seteh_PY_TROptions] = useState([]);
  const [eh_HD_CDOptions, seteh_HD_CDOptions] = useState([]);
  const [eh_CO_CTOptions, seteh_CO_CTOptions] = useState([]);
  const [eh_TR_CROptions, seteh_TR_CROptions] = useState([]);
  const [eh_TX_APOptions, seteh_TX_APOptions] = useState([]);
  const [ed_LN_TYOptions, seted_LN_TYOptions] = useState([]);
  const [ed_TR_UMOptions, seted_TR_UMOptions] = useState([]);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const PaymentTermDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seteh_PY_TR(value);
  };
  const ApplicableDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seteh_TX_AP(value);
  };
  const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seted_TR_UM(value);
  };
  const TypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seted_LN_TY(value);
  };
  useEffect(() => {
    CostCentre();
    Currency();
    PaymentTerm();
    HoldCode();
    Applicable();
    UM();
    Type();
  }, []);
  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    seteh_CO_CTOptions(Options);
  };
  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    seteh_TR_CROptions(Options);
  };
  const PaymentTerm = async () => {
    const Options = await fetchPayTerm();
    seteh_PY_TROptions(Options);
  };
  const HoldCode = async () => {
    const Options = await fetchClassCodeDS("FO/HD");
    seteh_HD_CDOptions(Options);
  };
  const Applicable = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    seteh_TX_APOptions(Options);
  };
  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    seted_TR_UMOptions(Options);
  };
  const Type = async () => {
    const Options = await fetchClassCodeDS("IN/TY");
    seted_LN_TYOptions(Options);
  };
  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AddresstypeC, setAddresstypeC] = useState("C");
  const [AddresstypeE, setAddresstypeE] = useState("E");
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("eh_CU_NO", data.abAdNo);
    const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
    const requestData={
    "cu_CU_NO":  data.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCuMt = response.data.Result;
    if (dataCuMt) {
      CurrencyDropDown(`eh_TR_CR`, dataCuMt.cu_CR_CD);
      PaymentTermDropDown(`eh_PY_TR`, dataCuMt.cu_PT_TM);
      ApplicableDropdown(`eh_TX_AP`, dataCuMt.cu_TX_AP);
      const exData={
        "cr_TO_CR": document.getElementById("eh_BS_CR").value, //base curr
        "cr_FR_CR": dataCuMt.cu_CR_CD, //currency
        "cr_EF_DT": data.eh_OR_DT
      }
      exchangeRate (exData);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setCustomerPopupVisible(false);
  };
  function exchangeRate(exData) {
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("eh_EX_TR").value = dataExRt.cr_CO_MR;
          seteh_EX_TR(dataExRt.cr_CO_MR);
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };
  const [ProjectMasterPopupVisible, setProjectMasterPopupVisible] = useState(false);
  const openProjectMasterPopup = (e) => {
    setProjectMasterPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleProjectMaster = async (data) => {
    setSelectedProjectMaster(data.pm_PR_DS);
    onchangeFormData("eh_PR_NO", data.pm_PR_NO);
    setProjectMasterPopupVisible(false);
  };
  const [ItemVisible, setItemVisible] = useState(false);
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    // onchangeFormData("ed_IT_NO", data.im_IT_NM, SelectedRowIndex);
    // onchangeFormData("itemDES", data.im_IT_DS, SelectedRowIndex);
    document.getElementById (`ed_IT_NO_${SelectedRowIndex}`).value =  data.im_IT_NM;
  document.getElementById (`itemDES_${SelectedRowIndex}`).value =  data.im_IT_DS;
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
      "im_IT_NM":data.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      UMDropdown(`ed_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
      TypeDropdown(`ed_LN_TY_${SelectedRowIndex}`, dataItMt.im_ST_TY);
      document.getElementById (`ed_TX_PR_${SelectedRowIndex}`).value =  dataItMt.im_VA_TX;
    } else {
      console.error("Invalid API response:", data);
    }
    await UnitRate(data.im_IT_NM);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const UnitRate =async (im_IT_NM) => {
    const Url = `${domain}/api/EstimateRate/GetByItemName`;
    const requestData={
      "er_IT_NM": im_IT_NM,
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
  
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      document.getElementById (`ed_UT_RT_${SelectedRowIndex}`).value =  dataItMt.er_UN_RT;
      document.getElementById (`ed_UT_CT_${SelectedRowIndex}`).value =  dataItMt.er_UN_CT;
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
   
  };
  const GetCalculate = async (index) => {
    const UrlCalulate = `${domain}/api/EstimateEntry/calculate`;
    const mo_out_calc_priceList = [];
  
    for (let i = 0; i < EstimateEntryTableData.length; i++) {
      mo_out_calc_priceList.push({
        "order_QUANTITY": document.getElementById(`ed_OR_QT_${i}`).value,
        "discount_PERCENTAGE": document.getElementById(`ed_DS_PR_${i}`).value,
        "cost_CENTER": document.getElementById(`ed_CO_CT_${i}`).value,
        "item_NAME": document.getElementById(`ed_IT_NO_${i}`).value,
        "unit_PRICE": document.getElementById(`ed_UT_RT_${i}`).value,
        "tax_PERCENTAGE": document.getElementById(`ed_TX_PR_${i}`).value,
        "row_NUM": i,
        "unit_COST": document.getElementById(`ed_UT_CT_${i}`).value,
      });
    }
  
    const priceInput = {
      "mo_out_calc_priceList": mo_out_calc_priceList
    };
  
    try {
      const response = await axios.post(UrlCalulate, priceInput, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      
      const datatax = response.data.Result;
      
      if (datatax) {
        for (let i = 0; i < EstimateEntryTableData.length; i++) {
          if (index === i) {
            document.getElementById(`ed_DS_AM_${i}`).value = datatax[i].discount_AMOUNT;
            document.getElementById(`ed_EX_PR_${i}`).value = datatax[i].price_AMOUNT;
            document.getElementById(`ed_MR_AM_${i}`).value = datatax[i].margin_AMOUNT;
            document.getElementById(`ed_MR_PR_${i}`).value = datatax[i].margin_PERCENTAGE;
            document.getElementById(`ed_TX_AM_${i}`).value = datatax[i].tax_AMOUNT;
            document.getElementById(`ed_EX_CT_${i}`).value = datatax[i].extended_COST;
            document.getElementById(`net_AMOUNT_${i}`).value = datatax[i].net_AMOUNT;
          }
        }
      } else {
        console.error("Invalid API response:", datatax);
      }
      await GetFooter(index);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const GetFooter = async (index) => {
    const UrlCalulate = `${domain}/api/EstimateEntry/GetPopulateAmount`;
    const estimateEntryDetailsList = [];
  
    for (let i = 0; i < EstimateEntryTableData.length; i++) {
      estimateEntryDetailsList.push({
        "ed_EX_PR": document.getElementById(`ed_EX_PR_${i}`).value,
        "ed_DS_AM": document.getElementById(`ed_DS_AM_${i}`).value,
        "ed_TX_AM": document.getElementById(`ed_TX_AM_${i}`).value,
      });
    }
  
    const priceInput = {
      "estimateEntryDetailsList": estimateEntryDetailsList
    };
  
    try {
      const response = await axios.post(UrlCalulate, priceInput, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      
      const datatax = response.data.Result;
      if (datatax) {
            document.getElementById("price_AMOUNT").value = datatax.Price_AMOUNT;
            document.getElementById("discount_AMOUNT").value = datatax.discount_AMOUNT;
            document.getElementById("net_AMOUNT").value = datatax.net_AMOUNT;
            document.getElementById("tax_AMOUNT").value = datatax.tax_AMOUNT;
      } else {
        console.error("Invalid API response:", datatax);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const addRow = () => {
    setEstimateEntryTableData([...EstimateEntryTableData, {}]);
  };
  const deleteRow = () => {
    if (EstimateEntryTableData.length > 0) {
      const updatedData = [...EstimateEntryTableData];
      updatedData.pop();
      setEstimateEntryTableData(updatedData);
    }
  };  
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "eh_OR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const [selectedTRDate, setSelectedTRDate] = useState(moment().toDate());
  const handleDateTRChange = (e) => {
    setSelectedTRDate(e.value);
    onchangedata({ target: { name: "eh_RQ_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const isUpdateMode = Boolean(ehUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{ehUkNo ? "Revise Estimate Entry" : "Add Estimate Entry"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
          <div style={inboxstyle}>
            <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                <label htmlFor="ehUkNo" className="font-bold">
                UK NO
                </label>
                <InputText disabled
                  id="ehUkNo"
                  name="ehUkNo"
                  defaultValue={ehUkNo}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="eh_OR_NO" className="font-bold">
                Estimate No
                </label>
                <InputText disabled
                  id="eh_OR_NO"
                  name="eh_OR_NO"
                  defaultValue={eh_OR_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="eh_OR_TY" className="font-bold">
                Type 
                </label>
                <InputText disabled
                  id="eh_OR_TY"
                  name="eh_OR_TY"
                  value={eh_OR_TY}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="eh_OR_CO" className="font-bold">
                Company
                </label>
                <InputText disabled
                  id="eh_OR_CO"
                  name="eh_OR_CO"
                  value={eh_OR_CO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="eh_OR_DT" className="font-bold">
                Order Date
              </label>
              <Calendar
                id="eh_OR_DT"
                name="eh_OR_DT"
                value={moment(eh_OR_DT, "YYYYMMDD").toDate()}
                onChange={(e) => {
                  handleDateChange(e);
                  onchangegldate({ target: { name: "eh_OR_DT", value: moment(e.value).format("YYYYMMDD") } });
                }}
                dateFormat="dd/mm/yy"
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="eh_RQ_DT" className="font-bold">
            Req. Date
                </label>
                <Calendar
                id="eh_RQ_DT"
                name="eh_RQ_DT"
                value={moment(eh_RQ_DT, "YYYYMMDD").toDate()}
                onChange={handleDateTRChange}
                dateFormat="dd/mm/yy"
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="eh_CO_CT" className="font-bold">
                      Cost Centre
                    </label>
                    <Dropdown disabled
                      name="eh_CO_CT"
                      value={eh_CO_CT}
                      options={eh_CO_CTOptions}
                      onChange={(e) => CostCentreDropDown("eh_CO_CT", e.value)}
                      placeholder="Select Cost Centre"
                    />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="eh_CU_NO" className="font-bold">
                  Customer  <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText 
                    id="name"
                    name="name"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedemEMNO}
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openCustomerPopup}
                  ></i>
                  )}
                </div>
                <div style={{ display: "none" }}>
                  <InputText
                    id="eh_CU_NO"
                    name="eh_CU_NO"
                    defaultValue={eh_CU_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openCustomerPopup}
                  ></i>
                </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="eh_PY_TR" className="font-bold">
                    Payment Term
                    </label>
                    <Dropdown
                      name="eh_PY_TR"
                      value={eh_PY_TR}
                      options={eh_PY_TROptions}
                      onChange={(e) => PaymentTermDropDown("eh_PY_TR", e.value)}
                      placeholder="Select Payment Term"
                    />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="eh_TX_AP" className="font-bold">
                Tax Applicable
                </label>
                <Dropdown
                name="eh_TX_AP"
                value={eh_TX_AP}
                options={[
                  { label: "Yes", value: "y" },
                  { label: "No", value: "n" },
                  ]}
                onChange={(e) => ApplicableDropdown("eh_TX_AP", e.value)}
                placeholder="Select Applicable"
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="eh_TR_CR" className="font-bold">
                Currency
                </label>
                <Dropdown 
                  name="eh_TR_CR"
                  value={eh_TR_CR}
                  options={eh_TR_CROptions}
                  onChange={(e) =>CurrencyDropDown("eh_TR_CR", e.value)}
                  placeholder="Select Currency"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="eh_EX_TR" className="font-bold">
                    Exchange Rate
                    </label>
                    <InputText disabled
                      id="eh_EX_TR"
                      name="eh_EX_TR"
                      value={eh_EX_TR}
                      onChange={(e) => onchangedata(e)}
                    />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="eh_BS_CR" className="font-bold">
                      Base Currency
                    </label>
                    <InputText disabled
                      id="eh_BS_CR"
                      name="eh_BS_CR"
                      value={eh_BS_CR}
                      onChange={(e) => onchangedata(e)}
                    />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="eh_PR_NO" className="font-bold">
                Project No  <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="proj_NAME"
                  name="proj_NAME"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedProjectMaster}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openProjectMasterPopup}
                ></i>
                )}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="eh_PR_NO"
                  name="eh_PR_NO"
                  value={eh_PR_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="eh_PR_RF" className="font-bold">
                    Project Ref.
                    </label>
                    <InputText 
                      id="eh_PR_RF"
                      name="eh_PR_RF"
                      value={eh_PR_RF}
                      onChange={(e) => onchangedata(e)}
                    />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="eh_HD_CD" className="font-bold">
                    Hold Code
                    </label>
                    <Dropdown
                      name="eh_HD_CD"  
                      value={eh_HD_CD}
                      options={eh_HD_CDOptions}
                      onChange={(e) => HoldCodeDropDown("eh_HD_CD", e.value)}
                      placeholder="Select Hold Code"
                    />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="eh_EN_RF" className="font-bold">
                    Enquiry Ref.
                    </label>
                    <InputText 
                      id="eh_EN_RF"
                      name="eh_EN_RF"
                      value={eh_EN_RF}
                      onChange={(e) => onchangedata(e)}
                    />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
                    <label htmlFor="eh_RV_NO" className="font-bold">
                    Revision No
                    </label>
                    <InputText disabled
                      id="eh_RV_NO"
                      name="eh_RV_NO"
                      value={eh_RV_NO}
                      onChange={(e) => onchangedata(e)}
                    />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="eh_DS_PR" className="font-bold">
                  Discount %
                </label>
                <InputText 
                  id="eh_DS_PR"
                  name="eh_DS_PR"
                  value={eh_DS_PR}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="eh_DS_AT" className="font-bold">
                  Discount Amount
                </label>
                <InputText 
                  id="eh_DS_AT"
                  name="eh_DS_AT"
                  value={eh_DS_AT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="eh_CO_PE" className="font-bold">
                  Contact Person
                </label>
                <InputText 
                  id="eh_CO_PE"
                  name="eh_CO_PE"
                  value={eh_CO_PE}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="eh_CO_NO" className="font-bold">
                  Contact No
                </label>
                <InputText 
                  id="eh_CO_NO"
                  name="eh_CO_NO"
                  value={eh_CO_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
            </div>
          </div>
          <div style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
              {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
            </div>
            <div  style={multitablestyle}>
            <table className="custom-table" id="operationtable">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th>Line No</th>
                  <th>Item Name</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Quantity</th>
                  <th>UM</th>
                  <th>Unit Rate</th>
                  <th>Amount</th>
                  <th>Discount %</th>
                  <th>Discount Amount</th>
                  <th>Net Amount</th>
                  <th>Tax %</th>
                  <th>Tax Amount</th>
                  <th>Unit Cost</th>
                  <th>Extended Cost</th>
                  <th>Margin Amount</th>
                  <th>Margin %</th>
                  <th>Cost Centre</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {EstimateEntryTableData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`edUkNo_${index}`}
                        name={`edUkNo_${index}`}
                        value={row.edUkNo}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{width:"7rem"}}
                        id={`ed_LN_NO_${index}`}
                        name={`ed_LN_NO_${index}`}
                        value={index+1}
                        readOnly
                      />
                    </td>
                    <td style={{ display: "flex" }}>
                    <InputText 
                      style={{ width: "10rem" }}
                      id={`ed_IT_NO_${index}`}
                      name={`ed_IT_NO_${index}`}
                      onChange={(e) => onchangeFormData(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      defaultValue={row.ed_IT_NO ? row.ed_IT_NO: row[`${"ed_IT_NO"}_${index}`]}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "15%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => openItemPopup(index)}
                    ></i>
                    )}
                  </td>
                  <td>
                  <InputText disabled
                    style={{ width: "15rem" }}
                    id={`itemDES_${index}`}
                    name={`itemDES_${index}`}
                    defaultValue={row.itemDES ? row.itemDES : row[`${"itemDES"}_${index}`]}
                  />
                  </td>
                  <td>
                    <Dropdown disabled
                      id={`ed_LN_TY_${index}`}
                      name={`ed_LN_TY_${index}`}
                      value={ data[`ed_LN_TY_${index}`] ? data[`ed_LN_TY_${index}`]: row.ed_LN_TY}
                      options={ed_LN_TYOptions}
                      onChange={(e) =>TypeDropdown(`ed_LN_TY_${index}`, e.value)}
                      placeholder="Select Type"
                    />
                  </td>
                    <td>
                      <InputText  style={{ width: "10rem" }}
                        id={`ed_OR_QT_${index}`}
                        name={`ed_OR_QT_${index}`}
                        defaultValue={row.ed_OR_QT}
                        onChange={(e) => {
                          onchangedata(e);
                          GetCalculate(index);
                        }}
                      />
                    </td>
                    <td>
                      <Dropdown style={{ width: "10rem" }}
                        id={`ed_TR_UM_${index}`}
                        name={`ed_TR_UM_${index}`}
                        value={ data[`ed_TR_UM_${index}`] ? data[`ed_TR_UM_${index}`]: row.ed_TR_UM}
                        options={ed_TR_UMOptions}
                        onChange={(e) =>UMDropdown(`ed_TR_UM_${index}`, e.value)}
                        placeholder="Select UM"
                      />
                      </td>
                    <td >
                      <InputText  disabled  style={{ width: "10rem" }}
                        id={`ed_UT_RT_${index}`}
                        name={`ed_UT_RT_${index}`}
                        defaultValue={row.ed_UT_RT}
                        onChange={(e) => {
                          onchangedata(e);
                          GetCalculate(index);
                        }}
                      />
                    </td>
                    <td >
                      <InputText  disabled style={{ width: "10rem" }}
                        id={`ed_EX_PR_${index}`}
                        name={`ed_EX_PR_${index}`}
                        value={row.ed_EX_PR}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText  style={{ width: "10rem" }}
                        id={`ed_DS_PR_${index}`}
                        name={`ed_DS_PR_${index}`}
                        defaultValue={row.ed_DS_PR}
                        onChange={(e) => {
                          onchangedata(e);
                          GetCalculate(index);
                        }}
                      />
                    </td>
                    <td >
                      <InputText  disabled style={{ width: "10rem" }}
                        id={`ed_DS_AM_${index}`}
                        name={`ed_DS_AM_${index}`}
                        defaultValue={row.ed_DS_AM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    
                    <td >
                      <InputText disabled style={{ width: "10rem" }}
                        id={`net_AMOUNT_${index}`}
                        name={`net_AMOUNT_${index}`}
                        defaultValue={row.net_AMOUNT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    
                    <td >
                      <InputText disabled style={{ width: "10rem" }}
                        id={`ed_TX_PR_${index}`}
                        name={`ed_TX_PR_${index}`}
                        defaultValue={row.ed_TX_PR}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{ width: "10rem" }}
                        id={`ed_TX_AM_${index}`}
                        name={`ed_TX_AM_${index}`}
                        defaultValue={row.ed_TX_AM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{ width: "10rem" }}
                        id={`ed_UT_CT_${index}`}
                        name={`ed_UT_CT_${index}`}
                        defaultValue={row.ed_UT_CT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText  disabled style={{ width: "10rem" }}
                        id={`ed_EX_CT_${index}`}
                        name={`ed_EX_CT_${index}`}
                        defaultValue={row.ed_EX_CT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    {/* <td >
                      <InputText  style={{width:"90px"}}
                        id={`ed_ST_CD_${index}`}
                        name={`ed_ST_CD_${index}`}
                        defaultValue={ed_ST_CD}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td> */}                    
                    <td >
                      <InputText disabled style={{ width: "10rem" }}
                        id={`ed_MR_AM_${index}`}
                        name={`ed_MR_AM_${index}`}
                        defaultValue={row.ed_MR_AM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{ width: "10rem" }}
                        id={`ed_MR_PR_${index}`}
                        name={`ed_MR_PR_${index}`}
                        defaultValue={row.ed_MR_PR}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{ width: "10rem" }}
                        id={`ed_CO_CT_${index}`}
                        name={`ed_CO_CT_${index}`}
                        defaultValue={ed_CO_CT}
                      />
                    </td>
                    <td>
                    <InputText disabled style={{ width: "10rem" }}
                        id={`ed_ST_CD_${index}`}
                        name={`ed_ST_CD_${index}`}
                        defaultValue={ed_ST_CD}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
        <div style={inboxstyle} >
        <div className="formgrid grid">
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="price_AMOUNT" className="font-bold">
              Amount
            </label>
            <InputText disabled
              id="price_AMOUNT"
              name="price_AMOUNT"
              value={price_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="discount_AMOUNT" className="font-bold">
              Discount Amount
            </label>
            <InputText disabled
              id="discount_AMOUNT"
              name="discount_AMOUNT"
              value={discount_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="net_AMOUNT" className="font-bold">
              Net Amount
            </label>
            <InputText disabled
              id="net_AMOUNT"
              name="net_AMOUNT"
              value={net_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="tax_AMOUNT" className="font-bold">
              Tax Amount
            </label>
            <InputText disabled
              id="tax_AMOUNT"
              name="tax_AMOUNT"
              value={tax_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
        </div>
        </div>
    </div> 
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={AddresstypeC}
      />
      <ProjectMasterPopup
        visible={ProjectMasterPopupVisible}
        onClose={() => setProjectMasterPopupVisible(false)}
        onDataSelect={handleProjectMaster}
        formData={data}
      />
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
