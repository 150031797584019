import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
import Costcenter  from "views/common/CostCentreDrpDwn";
export default function MaterialTransferDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  // MaterialTransferTableData,
  // setMaterialTransferTableData,
  ic_CO_CT2,
  setic_CO_CT2,
  jb_LO_CN2,
  setjb_LO_CN2,
  jb_TR_UM,
  setjb_TR_UM,
  jb_CO_CT,
  setjb_CO_CT,
  jb_LO_CN,
  setjb_LO_CN,
  jb_SC_UM,
  setjb_SC_UM,
  jb_JB_TY,
  jb_JB_CO, 
  ic_LO_CN2,
}) {
  const {
    jbUkNo,
    jb_OR_NO,
    jb_TR_DT,
    jb_GL_DT,
    jb_JB_QT,
    jb_OR_CO,
    jb_OR_TY,
    wd_WO_NO,
    wd_WO_TY,
    wd_WO_CO,
  
  } = data;

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [jb_CO_CTOptions, setjb_CO_CTOptions] = useState([]);
  const [ic_CO_CT2Options, setic_CO_CT2Options] = useState([]);
  const [jb_TR_UMOptions, setjb_TR_UMOptions] = useState([]);
  const [jb_LO_CN2Options, setjb_LO_CN2Options] = useState([]);
  const [jb_LO_CNOptions, setjb_LO_CNOptions] = useState([]);
  const [ic_LT_NOOptions, setic_LT_NOOptions] = useState([]);
  const [jb_SC_UMOptions, setjb_SC_UMOptions] = useState([]);

  // Expense Type
  const FromCostCentreDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjb_CO_CT(value);
};

  // Advance Curr
  const ToCostCentreDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setic_CO_CT2(value);
  };

   // Expense Category
 const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjb_TR_UM(value);
};



  // Location
const LocationDropdown = (name, value) => {
  const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjb_LO_CN(value);
};



 // Reim Curr
 const SecUMDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setjb_SC_UM(value);
  };

  useEffect(() => {
    FromCostCentre();
    ToCostCentre();
    UM();
    Location();
    LotNo();
    Tolocation();
    SecUM();

  }, []);

  const FromCostCentre = async () => {
    const Options = await fetchCostCenter();
    setjb_CO_CTOptions(Options);
  };

  const ToCostCentre = async () => {
    const Options =  await fetchCostCenter();
    setic_CO_CT2Options(Options);
  };

  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setjb_TR_UMOptions(Options);
  };

  const Location = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setjb_LO_CNOptions(Options);
  };
  const LotNo = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setic_LT_NOOptions(Options);
  };

  const Tolocation = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setjb_LO_CN2Options(Options);
  };

  const SecUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setjb_SC_UMOptions(Options);
  };

  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ItemPopupVisible, setItemVisible] = useState(false);
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const [wd_IT_NM, setwd_IT_NM] = useState("");
  const [itemDES, setitemDES] = useState("");
  const handleItem = async (Result) => {
    setwd_IT_NM(Result.im_IT_NM);
    setitemDES(Result.im_IT_DS);
    onchangeFormData("wd_IT_NM", Result.im_IT_NM, SelectedRowIndex);
    onchangeFormData("itemDES", Result.im_IT_DS, SelectedRowIndex);
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
      "im_IT_NM":Result.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      UMDropdown(`jb_SC_UM_${SelectedRowIndex}`, dataItMt.im_SC_UM);
      SecUMDropdown(`jb_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
      // onchangebspr(SelectedRowIndex);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItemVisible(false);
    setShowSearchIcon(false);
  };



  const addRow = () => {
    setMaterialTransferTableData([...MaterialTransferTableData, {}]);
  };
  
  const deleteRow = () => {
    if (MaterialTransferTableData.length > 0) {
      const updatedData = [...MaterialTransferTableData];
      updatedData.pop();
      setMaterialTransferTableData(updatedData);
    }
  };  
const isUpdateMode = Boolean(jbUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  const [MaterialTransferTableData, setMaterialTransferTableData] = useState([]); 
  const [jb_JB_NO, setjb_JB_NO] = useState([]); 
  const handleOrderNO=async () => {
    const Url = `${domain}/api/MaterialTransfer/GetByJobOrder`;
    const requestData={
      "jb_JB_NO":document.getElementById("jb_JB_NO").value,
      "jb_JB_TY":document.getElementById("jb_JB_TY").value,
      "jb_JB_CO":document.getElementById("jb_JB_CO").value,
      "cost_CENTRE":jb_CO_CT,
      "location": ic_LO_CN2,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.Result;
  
      if (dataLead && dataLead.length > 0) {
        document.getElementById("wd_WO_NO").value = dataLead[0].jb_OR_NO;
        document.getElementById("wd_WO_TY").value = dataLead[0].jb_OR_TY;
        document.getElementById("wd_WO_CO").value = dataLead[0].jb_OR_CO;
        FromCostCentreDropdown("jb_CO_CT", dataLead[0].jb_CO_CT);
        ToCostCentreDropdown("ic_CO_CT2", dataLead[0].jb_CO_CT);
        document.getElementById("jb_JB_QT").value = dataLead[0].jb_JB_QT;

        setMaterialTransferTableData(dataLead.map(item => ({
          jbUkNo: item.jbUkNo,
          jb_TR_QT: item.jb_TR_QT,
          jb_SC_QT: item.jb_SC_QT,
          jb_LT_NO: item.jb_LT_NO,
          jb_LO_CN: item.jb_LO_CN,
          jb_SC_UM: item.jb_SC_UM,
          jb_TR_UM: item.jb_TR_UM,
          wd_IT_NM: item.jb_CO_NM,
          itemDES: item.it_DES,
          jb_OR_QT: item.jb_OR_QT,
        })));
  
      } else {
        console.error("Invalid API response:" );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const [jh_TR_QT, setjh_TR_QT] = useState([]); 
  const handleTransfer=async () => {
    const Url = `${domain}/api/MaterialTransfer/GetJobOrderAndWorkOrder`;
    const requestData={
      "jb_JB_NO":document.getElementById("jb_JB_NO").value,
      "jb_JB_TY":document.getElementById("jb_JB_TY").value,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.wd;
  
      if (dataLead && dataLead.length > 0) {
        setMaterialTransferTableData(dataLead.map(item => ({
          jbUkNo: item.wdUkNo,
          jb_TR_QT: item.wd_WO_QT,
          jb_SC_QT: item.wd_SC_QT,
          jb_LT_NO: item.wd_LT_NO,
          jb_LO_CN: item.wd_LO_CN,
          jb_TR_UM: item.wd_TR_UM,
          wd_IT_NM: item.wd_IT_NM,
          itemDES: item.wd_IT_DS,
          jb_OR_QT: item.wd_CO_QT,
        })));
  
      } else {
        console.error("Invalid API response:" );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
 
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={jbUkNo ? "Revise Material Transfer" : "Add Material Transfer"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
         <div className="formgrid grid">
         
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="jb_OR_NO" className="font-bold">
          Transaction No
          </label>
          <InputText disabled
            id="jb_OR_NO"
            name="jb_OR_NO"
            defaultValue={jb_OR_NO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="jb_OR_TY" className="font-bold">
           Type 
          </label>
          <InputText disabled
            id="jb_OR_TY"
            name="jb_OR_TY"
            value={jb_OR_TY}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="jb_OR_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="jb_OR_CO"
            name="jb_OR_CO"
            value={jb_OR_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="wd_WO_NO" className="font-bold">
         Related Order
          </label>
          <InputText disabled
            id="wd_WO_NO"
            name="wd_WO_NO"
            defaultValue={wd_WO_NO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="wd_WO_TY" className="font-bold">
           Type 
          </label>
          <InputText disabled
            id="wd_WO_TY"
            name="wd_WO_TY"
            value={wd_WO_TY}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="wd_WO_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="wd_WO_CO"
            name="wd_WO_CO"
            value={wd_WO_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="jb_JB_NO" className="font-bold">
          Order No
          </label>
          <InputText 
            id="jb_JB_NO"
            name="jb_JB_NO"
            value={jb_JB_NO}
            onChange={(e) => { 
              setjb_JB_NO(e.target.value); 
              handleOrderNO(); 
              // handlePeriod();
            }}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="jb_JB_TY" className="font-bold">
           Type 
          </label>
          <InputText disabled
            id="jb_JB_TY"
            name="jb_JB_TY"
            value={jb_JB_TY}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="jb_JB_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="jb_JB_CO"
            name="jb_JB_CO"
            value={jb_JB_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="jb_TR_DT" className="font-bold">
           Transfer Date
            </label>
            <Calendar
              id="jb_TR_DT"
              name="jb_TR_DT"
              value={moment(jb_TR_DT, "YYYYMMDD").toDate()}
              onChange={(e) => onchangedata({ target: { name: "jb_TR_DT", value: moment(e.value).format("YYYYMMDD") } })}
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="jb_GL_DT" className="font-bold">
           GL Date
            </label>
            <Calendar
              id="jb_GL_DT"
              name="jb_GL_DT"
              value={moment(jb_GL_DT, "YYYYMMDD").toDate()}
              onChange={(e) => onchangedata({ target: { name: "jb_GL_DT", value: moment(e.value).format("YYYYMMDD") } })}
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="jb_JB_QT" className="font-bold">
            Order Quantity
            </label>
            <InputText disabled
              id="jb_JB_QT"
              name="jb_JB_QT"
              value={jb_JB_QT}
              onChange={(e) => onchangedata(e)}
            />
          </div>   
             <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="jb_CO_CT" className="font-bold">
                    From Cost Centre
                    </label>
                    <Dropdown disabled
                    name="jb_CO_CT"
                    value={jb_CO_CT}
                    options={jb_CO_CTOptions}
                    onChange={(e) => FromCostCentreDropdown("jb_CO_CT", e.value)}
                    placeholder="Select Cost Centre"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ic_CO_CT2" className="font-bold">
                    To Cost Centre
                    </label>
                    <Dropdown disabled
                    name="ic_CO_CT2"
                    value={ic_CO_CT2}
                    options={ic_CO_CT2Options}
                    onChange={(e) => ToCostCentreDropdown("ic_CO_CT2", e.value)}
                    placeholder="Select Cost Centre"
                  />
                  </div>
                  
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="auto_POST" className="font-bold">
            Auto Post
            </label>
            <InputText disabled
                    id="auto"
                    name="auto"
                    value={"Yes"}
            />
          </div>       
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="jh_TR_QT" className="font-bold">
          Transferred Quantity
          </label>
          <InputText disabled
            id="jh_TR_QT"
            name="jh_TR_QT"
            value={jh_TR_QT}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="jh_TR_QT" className="font-bold">
          Transfer Quantity
          </label>
          <InputText 
            id="jh_TR_QT"
            name="jh_TR_QT"
            value={jh_TR_QT}
            onChange={(e) => { 
              setjh_TR_QT(e.target.value); 
              handleTransfer(); 
              // handlePeriod();
            }}
          />
        </div>
          </div>
          </div>  
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
        >
      
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="operationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Line No</th>
              <th>Item <span style={{ color: 'red' }}>*</span> </th>
              <th>Description</th>
              <th>Req Quantity</th>
              <th>UM</th>
              <th>Transfer Quantity</th>
              <th>Sec Quantity</th>
              <th>Sec UM</th>
              <th>From Location</th>
              <th>Stock</th>
              <th>To Location</th>
            </tr>
          </thead>
          <tbody>
            {MaterialTransferTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`jbUkNo_${index}`}
                    name={`jbUkNo_${index}`}
                    value={row.jbUkNo}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`jb_LN_NO_${index}`}
                    name={`jb_LN_NO_${index}`}
                    value={index+1}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td style={{ display: "flex" }}>
          <InputText
          style={{ width: "110px" }}
          id={`wd_IT_NM_${index}`}
          name={`wd_IT_NM_${index}`}
          onChange={(e) => onchangeFormData(e)}
          onFocus={() => setShowSearchIcon(true)}
          value={row.wd_IT_NM ? row.wd_IT_NM: row[`${"wd_IT_NM"}_${index}`]}
          />
          {showSearchIcon && (
         <i
         className="pi pi-search"
         style={{
         color: "var(--primary-color)",
         fontSize: "119%",
         marginTop: "15%",
         cursor: "pointer",
        }}
        onClick={(e) => openItemPopup(index)}
        ></i>
        )}
      </td>
        <td>
        <InputText disabled
        style={{ width: "200px" }}
        id={`itemDES_${index}`}
        name={`itemDES_${index}`}
        defaultValue={row.itemDES ? row.itemDES : row[`${"itemDES"}_${index}`]}
        readOnly
        />
        </td>
        <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`jb_OR_QT_${index}`}
                    name={`jb_OR_QT_${index}`}
                    defaultValue={row.jb_OR_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                
          <td>
          <Dropdown
          id={`jb_TR_UM_${index}`}
          name={`jb_TR_UM_${index}`}
          value={ data[`jb_TR_UM_${index}`] ? data[`jb_TR_UM_${index}`]: row.jb_TR_UM}
          options={jb_TR_UMOptions}
          onChange={(e) =>UMDropdown(`jb_TR_UM_${index}`, e.value)}
          placeholder="Select UM"
          />
          </td>
          <td >
                  <InputText  style={{width:"90px"}}
                    id={`jb_TR_QT_${index}`}
                    name={`jb_TR_QT_${index}`}
                    defaultValue={row.jb_TR_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  style={{width:"90px"}}
                    id={`jb_SC_QT_${index}`}
                    name={`jb_SC_QT_${index}`}
                    defaultValue={row.jb_SC_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Dropdown style={{width:"120px"}}
                    id={`jb_SC_UM_${index}`}
                    name={`jb_SC_UM_${index}`}
                    value={ data[`jb_SC_UM_${index}`] ? data[`jb_SC_UM_${index}`]: row.jb_SC_UM}
                    options={jb_SC_UMOptions}
                    onChange={(e) =>SecUMDropdown(`jb_SC_UM_${index}`, e.value)}
                    placeholder="Select SecUM"
                  />
                  </td>
                  <td>
                  <Dropdown
                    id={`jb_LO_CN_${index}`}
                    name={`jb_LO_CN_${index}`}
                    value={ data[`jb_LO_CN_${index}`] ? data[`jb_LO_CN_${index}`]: row.jb_LO_CN}
                    options={jb_LO_CNOptions}
                    onChange={(e) =>LocationDropdown(`jb_LO_CN_${index}`, e.value)}
                    placeholder="Select Location"
                  />
                  </td>
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`jb_LT_NO_${index}`}
                    name={`jb_LT_NO_${index}`}
                    defaultValue={row.jb_LT_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`ic_LO_CN2_${index}`}
                    name={`ic_LO_CN2_${index}`}
                    defaultValue={ic_LO_CN2}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                  
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
