import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
 
export default function CashFlowDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  CashFlowtabledata,
  setCashFlowtabledata,
  cs_CO_NO,
}) {
  const {
    csUkNo,
    os_WK_CT,
    os_SQ_DS,
  } = data;
  const addRow = () => {
    setCashFlowtabledata([...CashFlowtabledata, {}]);
  };
  
  const deleteRow = () => {
    if (CashFlowtabledata.length > 0) {
      const updatedData = [...CashFlowtabledata];
      updatedData.pop();
      setCashFlowtabledata(updatedData);
    }
  };
const isUpdateMode = Boolean(csUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );


  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={csUkNo ? "Revise Cash Flow" : "Add Cash Flow"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
        }}
      >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="CashFlowtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th >H1</th>
                <th >Beginning Object Range1</th>
                <th >Beginning Sub</th> 
                <th >Ending Object Range1</th>
                <th >Ending Sub</th>
                <th >Beginning Object Range2</th> 
                <th >Beginning Sub</th> 
                <th >Ending Object Range2</th>
                <th >Ending Sub</th>
                <th >Beginning Object Range3</th> 
                <th >Beginning Sub</th> 
                <th >Ending Object Range3</th>
                <th >Ending Sub</th>
                <th >Beginning Object Range4</th> 
                <th >Beginning Sub</th> 
                <th >Ending Object Range4</th>
                <th >Ending Sub</th>
                <th >Company</th>
            </tr>
          </thead>
          <tbody>
            {CashFlowtabledata.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`csUkNo_${index}`}
                    name={`csUkNo_${index}`}
                    value={row.csUkNo}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_HD_01_${index}`}
                    name={`cs_HD_01_${index}`}
                    defaultValue={row.cs_HD_01}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_OR_A1_${index}`}
                    name={`cs_OR_A1_${index}`}
                    defaultValue={row.cs_OR_A1}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_SB_A1_${index}`}
                    name={`cs_SB_A1_${index}`}
                    defaultValue={row.cs_SB_A1}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_OR_A2_${index}`}
                    name={`cs_OR_A2_${index}`}
                    defaultValue={row.cs_OR_A2}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_SB_A2_${index}`}
                    name={`cs_SB_A2_${index}`}
                    defaultValue={row.cs_SB_A2}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_OR_B1_${index}`}
                    name={`cs_OR_B1_${index}`}
                    defaultValue={row.cs_OR_B1}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_SB_B1_${index}`}
                    name={`cs_SB_B1_${index}`}
                    defaultValue={row.cs_SB_B1}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_OR_B2_${index}`}
                    name={`cs_OR_B2_${index}`}
                    defaultValue={row.cs_OR_B2}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_SB_B2_${index}`}
                    name={`cs_SB_B2_${index}`}
                    defaultValue={row.cs_SB_B2}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_OR_C1_${index}`}
                    name={`cs_OR_C1_${index}`}
                    defaultValue={row.cs_OR_C1}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_SB_C1_${index}`}
                    name={`cs_SB_C1_${index}`}
                    defaultValue={row.cs_SB_C1}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_OR_C2_${index}`}
                    name={`cs_OR_C2_${index}`}
                    defaultValue={row.cs_OR_C2}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_SB_C2_${index}`}
                    name={`cs_SB_C2_${index}`}
                    defaultValue={row.cs_SB_C2}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_OR_D1_${index}`}
                    name={`cs_OR_D1_${index}`}
                    defaultValue={row.cs_OR_D1}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_SB_D1_${index}`}
                    name={`cs_SB_D1_${index}`}
                    defaultValue={row.cs_SB_D1}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_OR_D2_${index}`}
                    name={`cs_OR_D2_${index}`}
                    defaultValue={row.cs_OR_D2}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cs_SB_D2_${index}`}
                    name={`cs_SB_D2_${index}`}
                    defaultValue={row.cs_SB_D2}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`cs_CO_NO_${index}`}
                    name={`cs_CO_NO_${index}`}
                    defaultValue={cs_CO_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      </div> 
      
    </Dialog>
  );
}
