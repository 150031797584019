import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import VATPayablesDialog from "./VATPayablesDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
const initialValue = {
  tdUkNo:"",
  tdUkNo: "",
  td_OR_NO: "",
  td_OR_TY: "",
  td_OR_CO: "",
  td_DO_NO: "",
  td_DO_TY: "",
  td_DO_CO: "",
  td_CR_CD: "",
  td_TX_AR: "",
  td_TX_TY: "",
  td_TR_DT: "",
  td_IN_DT: "",
  td_IN_AM: "",
  td_VA_TX: "",
};

const initialFilters = {
  td_OR_NO: { value: "", operation: "" }, 
  td_OR_TY: { value: "", operation: "" }, 
  td_OR_CO: { value: "", operation: "" }, 
  td_DO_NO: { value: "", operation: "" }, 
  td_DO_TY: { value: "", operation: "" }, 
  td_DO_CO: { value: "", operation: "" }, 
  td_CR_CD: { value: "", operation: "" }, 
  td_TX_AR: { value: "", operation: "" }, 
  td_TX_TY: { value: "", operation: "" }, 
  td_TR_DT: { value: "", operation: "" },  
  td_IN_DT: { value: "", operation: "" },  
  td_IN_AM: { value: "", operation: "" }, 
  td_VA_TX: { value: "", operation: "" }, 
};

const VATPayablesMain = () => {
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

// dateformat
const formatDate = (inputDate) => {
  if (!inputDate) {
    return inputDate;
  }
  const dateString = inputDate.toString();
  if (dateString.length !== 8) {
    return dateString;
  }
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);
  return `${day}/${month}/${year}`;
};

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [VATPayablestable, setVATPayablestable] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };



  const handleClickOpen = () => {
    setOpen(true);
  };
  

  const handleClose = () => {
    setVATPayablestable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleSearch();
    }
  };
  const addRow = () => {
    setVATPayablestable([...VATPayablestable, {}]);
  };

  const deleteRow = () => {
    if (VATPayablestable.length > 0) {
      const updatedData = [...VATPayablestable];
      updatedData.pop();
      setVATPayablestable(updatedData);
    }
  };
  

  useEffect(() => {
    handleSearch();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/InputTaxDetails/List/${currentPage}/${pageSize}/P`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/VATPayables/Selects/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const VATPayablesData = response.data;
        setVATPayablestable(VATPayablesData.journalEntryList);
        setFormData(VATPayablesData);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  var requestBody = {};
  function values(){
    const table = document.getElementById("VATPayablestable");
    const totalRowCount = table.tBodies[0].rows.length;
    const journalEntryList = [];
    for (let i = 0; i < totalRowCount; i++) {
      const RQ_DT = document.getElementsByName("gl_GL_DT_" + i)[0];
      const Details = {
      tdUkNo : document.getElementById("tdUkNo_" + i).value,
      gl_DO_NO : document.getElementById("gl_DO_NO_" + i).value,
      gl_DO_TY : document.getElementById("gl_DO_TY_" + i).value,
      gl_DO_CO : document.getElementById("gl_DO_CO_" + i).value,
      gl_GL_DT: RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
      gl_AC_NO : document.getElementById("gl_AC_NO_" + i).value,
      gl_LE_TY : document.getElementById("gl_LE_TY_" + i).value,
      gl_GL_PO : document.getElementById("gl_GL_PO_" + i).value, 
    };
        journalEntryList.push(Details);
      }
      requestBody ={
        journalEntryList,
        tdUkNo : FormData.tdUkNo,
        gl_DO_NO : document.getElementById("gl_DO_NO").value,
        gl_BA_NO : document.getElementById("gl_BA_NO").value,
        gl_BA_TY : document.getElementById("gl_BA_TY").value,
      }
  }
  const handleFormSubmit =  async () => {
    const  Url = `${domain}/api/VATPayables/PostErrorBatch`
    const request = {
        "tdUkNo": FormData.tdUkNo,
    }
    axios.post(Url, request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      handleResponse(resp.data);
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };

  const buttonStyles = {
    backgroundColor: "#ff0010a3",
    color: "white",
    lineHeight: "10%",
  };


  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      
    </React.Fragment>
  );

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(0);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width:"0%",

  };
  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    width:"0%",
  };

  const inputStyle = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
  };
  const checkboxStyle = {
    border: "6px solid #a7d6ff8c",
    borderRadius: "4px",
    width: "100%",
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };



  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
               <thead>
              <tr>
                <td>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["td_OR_NO"] ? filters["td_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "td_OR_NO",
                        e.target.value,
                        filters["td_OR_NO"]
                          ? filters["td_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["td_OR_TY"] ? filters["td_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "td_OR_TY",
                        e.target.value,
                        filters["td_OR_TY"]
                          ? filters["td_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["td_OR_CO"] ? filters["td_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "td_OR_CO",
                        e.target.value,
                        filters["td_OR_CO"]
                          ? filters["td_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["td_DO_NO"] ? filters["td_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "td_DO_NO",
                        e.target.value,
                        filters["td_DO_NO"]
                          ? filters["td_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["td_DO_TY"] ? filters["td_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "td_DO_TY",
                        e.target.value,
                        filters["td_DO_TY"]
                          ? filters["td_DO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["td_DO_CO"] ? filters["td_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "td_DO_CO",
                        e.target.value,
                        filters["td_DO_CO"]
                          ? filters["td_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["td_CR_CD"] ? filters["td_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "td_CR_CD",
                        e.target.value,
                        filters["td_CR_CD"]
                          ? filters["td_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["td_TX_AR"] ? filters["td_TX_AR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "td_TX_AR",
                        e.target.value,
                        filters["td_TX_AR"]
                          ? filters["td_TX_AR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["td_TX_TY"] ? filters["td_TX_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "td_TX_TY",
                        e.target.value,
                        filters["td_TX_TY"]
                          ? filters["td_TX_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["td_TR_DT"] ? filters["td_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "td_TR_DT",
                        e.target.value,
                        filters["td_TR_DT"]
                          ? filters["td_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["td_IN_DT"] ? filters["td_IN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "td_IN_DT",
                        e.target.value,
                        filters["td_IN_DT"]
                          ? filters["td_IN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["td_IN_AM"] ? filters["td_IN_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "td_IN_AM",
                        e.target.value,
                        filters["td_IN_AM"]
                          ? filters["td_IN_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["td_VA_TX"] ? filters["td_VA_TX"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "td_VA_TX",
                        e.target.value,
                        filters["td_VA_TX"]
                          ? filters["td_VA_TX"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Doc No</th>
                <th style={tableHeaderStyle}>Doc Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Tax Area</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Trans Date </th>
                <th style={tableHeaderStyle}>Invoice Date </th>
                <th style={tableHeaderStyle}>Invoice Amount</th>
                <th style={tableHeaderStyle}>VAT</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.tdUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.tdUkNo}
                        checked={selectedRows.includes(rowData.tdUkNo)}
                        onChange={() => handleRowSelect(rowData.tdUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.td_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.td_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.td_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.td_DO_NO}</td>
                    <td style={tableCellStyle}>{rowData.td_DO_TY}</td>
                    <td style={tableCellStyle}>{rowData.td_DO_CO}</td>
                    <td style={tableCellStyle}>{rowData.td_CR_CD}</td>
                    <td style={tableCellStyle}>{rowData.td_TX_AR}</td>
                    <td style={tableCellStyle}>{rowData.td_TX_TY}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.td_TR_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.td_IN_DT)}</td>
                    <td style={tableCellStyle}>{rowData.td_IN_AM}</td>
                    <td style={tableCellStyle}>{rowData.td_VA_TX}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="Pagination" style={{ marginLeft: "85%" }}>
          <span
            onClick={handleFirstPage}
            style={{
              fontSize: "200%",
              cursor: "pointer",
              whiteSpace: "pre-wrap",
              whiteSpace: "pre-wrap",
            }}
          >
            &laquo;{" "}
          </span>{" "}
          <span
            onClick={handlePreviousPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Prev{" "}
          </span>{" "}
          <span style={{ whiteSpace: "pre-wrap", fontSize: "111%" }}> </span>{" "}
          <span
            onClick={handleNextPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Next{" "}
          </span>{" "}
          <span
            onClick={handleLastPage}
            style={{ fontSize: "200%", cursor: "pointer" }}
          >
            &raquo;
          </span>
        </div>
      </div>

      <VATPayablesDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}  
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        VATPayablestable={VATPayablestable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
  
      />
    </div>
  );
};

export default VATPayablesMain;
