import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ItemPopup from "../Popups/ItemPopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import { Dropdown } from "primereact/dropdown";
import ButtonStyles from "views/common/ButtonStyles";
import { Calendar } from "primereact/calendar";
import moment from "moment";
export default function BasePriceItemGroupCustomerGroupDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  BasePricedata,
  setBasePricedata,
  bp_IT_GP,
  setbp_IT_GP, 
  bp_CU_PG,
  setbp_CU_PG, 
  bp_CR_CD,
  setbp_CR_CD, 
  bp_PR_UM,
  setbp_PR_UM,
  bp_TR_CO,
}) {
  const {
  bpUkNO,  
  bp_UN_PR,   
  bp_CR_PR,
  bp_EX_MR,
  bp_AL_DS,
  bp_FR_DT,
  bp_TO_DT,
  bp_ST_CD,   
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,multitableheader} = ButtonStyles();
  const addRow = () => {
    setBasePricedata([...BasePricedata, {}]);
  };
  
  const deleteRow = () => {
    if (BasePricedata.length > 0) {
      const updatedData = [...BasePricedata];
      updatedData.pop();
      setBasePricedata(updatedData);
    }
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
const isUpdateMode = Boolean(bpUkNO);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );  
  
//dropdown
const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
const [bp_CR_CDOptions, setbp_CR_CDOptions] = useState([]);
const [bp_PR_UMOptions, setbp_PR_UMOptions] = useState([]);
const [bp_IT_GPOptions, setbp_IT_GPOptions] = useState([]);
const [bp_CU_PGOptions, setbp_CU_PGOptions] = useState([]);

//type
  const onchangeDropDownFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbp_CR_CD(value);   
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));   
    setbp_PR_UM(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));   
    setbp_IT_GP(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));   
    setbp_CU_PG(value);
  };


  
  useEffect(() => {
    fetchDropdownBT();
    fetchDropdownBTPRUM();
    fetchDropdownBTPRIG();
    fetchDropdownBTPRCG();

  }, []);

  const fetchDropdownBT = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setbp_CR_CDOptions(Options);   
  };

  const fetchDropdownBTPRUM = async () => {    
    const Options = await fetchClassCodeCD("IN/UM");
    setbp_PR_UMOptions(Options);
  };

  const fetchDropdownBTPRIG = async () => {    
    const Options = await fetchClassCodeCD("IN/PR");
    setbp_IT_GPOptions(Options);
  };

  const fetchDropdownBTPRCG = async () => {    
    const Options = await fetchClassCodeCD("SO/PR");
    setbp_CU_PGOptions(Options);
  };

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={bpUkNO ? "Revise Item and Customer Base Price" : "Add Item and Customer Base Price"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox}>
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="BasePrice">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Item Group</th>              
              <th>Customer Group</th>              
              <th>Currency</th>
              <th>Unit Price</th>
              <th>UM</th>
              <th>Unit Cost</th>
              <th>Margin %</th>
              <th>Discount %</th>
              <th>From Date</th>
              <th>To Date</th>
              <th>Status</th>
              <th>Company</th>              
            </tr>
          </thead>
          <tbody>
            {BasePricedata.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`bpUkNO_${index}`}
                    name={`bpUkNO_${index}`}
                    value={row.bpUkNO}
                  />
                </td>
                
                <td>                        
                <Dropdown 
                  id={`bp_IT_GP_${index}`}
                  name={`bp_IT_GP_${index}`}
                  value={data[`bp_IT_GP_${index}`] ? data[`bp_IT_GP_${index}`] : row.bp_IT_GP}
                  options={bp_IT_GPOptions}
                  onChange={(e) => onchangeDropDownFormData2(`bp_IT_GP_${index}`, e.value)}
                  placeholder="Item Group"
                />
                </td>
                <td>                        
                <Dropdown 
                  id={`bp_CU_PG_${index}`}
                  name={`bp_CU_PG_${index}`}
                  value={data[`bp_CU_PG_${index}`] ? data[`bp_CU_PG_${index}`] : row.bp_CR_CD}
                  options={bp_CU_PGOptions}
                  onChange={(e) => onchangeDropDownFormData3(`bp_CU_PG_${index}`, e.value)}
                  placeholder="Customer Group"
                />
                </td>
                <td>                        
                <Dropdown 
                  id={`bp_CR_CD_${index}`}
                  name={`bp_CR_CD_${index}`}
                  value={data[`bp_CR_CD_${index}`] ? data[`bp_CR_CD_${index}`] : row.bp_CR_CD}
                  options={bp_CR_CDOptions}
                  onChange={(e) => onchangeDropDownFormData(`bp_CR_CD_${index}`, e.value)}
                  placeholder="Currency"
                />
                </td>
                <td>
                <InputText style={{width:"100px"}}
                    id={`bp_UN_PR_${index}`}
                    name={`bp_UN_PR_${index}`}
                    defaultValue={row.bp_UN_PR}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>               
                <td>                        
                  <Dropdown 
                    id={`bp_PR_UM_${index}`}
                    name={`bp_PR_UM_${index}`}
                    value={data[`bp_PR_UM_${index}`] ? data[`bp_PR_UM_${index}`] : row.bp_PR_UM}
                    options={bp_PR_UMOptions}
                    onChange={(e) => onchangeDropDownFormData1(`bp_PR_UM_${index}`, e.value)}
                    placeholder="UM"
                  />
                </td>
                <td>
                <InputText style={{width:"100px"}}
                    id={`bp_CR_PR_${index}`}
                    name={`bp_CR_PR_${index}`}
                    defaultValue={row.bp_CR_PR}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                <InputText style={{width:"100px"}}
                    id={`bp_EX_MR_${index}`}
                    name={`bp_EX_MR_${index}`}
                    defaultValue={row.bp_EX_MR}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                <InputText style={{width:"100px"}}
                    id={`bp_AL_DS_${index}`}
                    name={`bp_AL_DS_${index}`}
                    defaultValue={row.bp_AL_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Calendar style={{width:"150px"}}
                    id={`bp_FR_DT_${index}`}
                    name={`bp_FR_DT_${index}`}
                    value={ row.bp_FR_DT ? moment(row.bp_FR_DT, "YYYYMMDD").toDate() : null }
                    onChange={(e) => onDateChange({ target: { name: "bp_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </td>
                <td>
                  <Calendar style={{width:"150px"}}
                    id={`bp_TO_DT_${index}`}
                    name={`bp_TO_DT_${index}`}
                    value={ row.bp_TO_DT ? moment(row.bp_TO_DT, "YYYYMMDD").toDate() : null }
                    onChange={(e) => onDateChange({ target: { name: "bp_TO_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </td>
                <td>
                <InputText style={{width:"100px"}}
                    id={`bp_ST_CD_${index}`}
                    name={`bp_ST_CD_${index}`}
                    defaultValue={row.bp_ST_CD}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                <InputText style={{width:"100px"}} disabled
                    id={`bp_TR_CO_${index}`}
                    name={`bp_TR_CO_${index}`}
                    defaultValue={bp_TR_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div> 
      </div>
       {/* item */}
        </Dialog>
  );
}
