import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import PurchaseOrderDialog from "./PurchaseOrderDialog";
import ItemPopup from "./ItemPopup";
import axios from "axios";
import saveAs from "file-saver";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  pdUkNo:"",
  pd_OR_NO: "",
  pd_OR_TY: "",
  pd_OR_CO: "",
  pd_LN_NO:"",
  pd_HL_CD:"",
  pd_SU_NO:"",
  ph_OR_DT:"",
  sup_NAME:"",
  pd_CR_CD:"",
  pd_IT_NM: "",
  im_IT_DS: "",
  pd_OR_DT: "",
  pd_RQ_DT:"",
  pd_OR_QT:"",
  pd_TR_UM:"",
  pd_BS_UP:"",
  pd_OR_AT: "",
  pd_FR_UP: "",
  pd_FR_AT: "",
  pd_PY_TR:"",
  pd_RQ_NO:"",
  pd_RQ_TY:"",
  pd_ST_CD:"",
  ph_RQ_DT:"",
  ph_TR_CR:"",
  ph_CO_CT:"",
  pd_RC_QT:"",
  pd_OP_QT:"",
  pd_QT_NO:"",
  pd_QT_TY:"",
  ph_PY_TR:"",
  ph_OR_DT:moment(moment().toDate()).format("YYYYMMDD"),
  ph_RQ_DT:moment(moment().toDate()).format("YYYYMMDD"),
  ph_PR_DT:moment(moment().toDate()).format("YYYYMMDD"),
  ph_OR_NO:"",
  phUkNO:"",
  ph_SU_NO:"",
  ph_LN_CT:"",
  ph_SH_NO:"",
  item_NAME:"",
};

const initialFilters = {
  pd_OR_NO: { value: "", operation: "" },
  pd_OR_TY: { value: "", operation: "" },
  pd_OR_CO: { value: "", operation: "" },
  pd_LN_NO: { value: "", operation: "" },
  pd_HL_CD: { value: "", operation: "" },
  pd_SU_NO: { value: "", operation: "" },
  sup_NAME: { value: "", operation: "" },
  pd_CR_CD: { value: "", operation: "" },
  pd_IT_NM: { value: "", operation: "" },
  im_IT_DS: { value: "", operation: "" },
  pd_OR_DT: { value: "", operation: "" },
  pd_RQ_DT: { value: "", operation: "" },
  pd_OR_QT: { value: "", operation: "" },
  pd_TR_UM: { value: "", operation: "" },
  pd_BS_UP: { value: "", operation: "" },
  pd_OR_AT: { value: "", operation: "" },
  pd_FR_UP: { value: "", operation: "" },
  pd_FR_AT: { value: "", operation: "" },
  pd_PY_TR: { value: "", operation: "" },
  pd_RQ_NO: { value: "", operation: "" },
  pd_RQ_TY: { value: "", operation: "" },
  pd_ST_CD: { value: "", operation: "" },
};

const PurchaseOrderMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,inputStyle1
  } = ButtonStyles();

  const [cancelFlag, setcancelFlag] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
// Dropdown
  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl = `${urlcost}`;
  useEffect(() => {
    fetchDropdownOptions();
    
  }, [currentPage, pageSize, filters]);

  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setph_CO_CTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const [supplier, setSupplier] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [selectedAbAdNN, setSelectedAbAdNN] = useState("");
  const [selectedAbAdNNA, setSelectedAbAdNNA] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [ph_CO_CTOptions, setph_CO_CTOptions] = useState([]);
  const [ph_OR_NO, setph_OR_NO] = useState("");
  const [ph_CO_CT, setph_CO_CT] = useState("");
  const [ph_TR_CR, setph_TR_CR] = useState("");
  const [ph_TX_AP, setph_TX_AP] = useState("");
  const [pd_TR_UM, setpd_TR_UM] = useState("");
  const [pd_SC_UM, setpd_SC_UM] = useState("");
  const [pd_TX_AP, setpd_TX_AP] = useState("");
  const [ph_SU_NO, setph_SU_NO] = useState("");
  const [ph_EX_RT, setph_EX_RT] =  useState("");
  const [ph_SH_NO, setph_SH_NO] =  useState("");
  const [pd_SU_NO, setpd_SU_NO] =  useState("");
  const [sup_NAME, setsup_NAME] =  useState("");
  const [ph_PY_TR, setph_PY_TR] =  useState("");
  const [pd_IT_NM, setpd_IT_NM] = useState("");
  const [CostCenter, setCostCenter] = useState("");
  const [selecteditem, setSelecteditem] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [item, setitem] = useState("");
  const [PurchaseOrdertable, setPurchaseOrdertable] = useState([{}]);
  const [PurchaseOrdertable1, setPurchaseOrdertable1] = useState([{}]);
  const [applied, setapplied] = useState("");
  const [tax_APPLICABLE, settax_APPLICABLE] = useState("");
  const [currency, setcurrency] = useState("");
  const [ph_HL_CD, setph_HL_CD] = useState("");
  const [ph_LN_CT, setph_LN_CT] = useState("");
  const [pd_CO_CT, setpd_CO_CT] = useState("");
  const [pay_TO_SUPPLIER, setpay_TO_SUPPLIER] = useState("");
  // const [Selectedname, setSelectedname] = useState("");
  const [Selectedsupplier, setSelectedsupplier] = useState("");
  const [SelectedName, setSelectedName] = useState("");
  const [price_AMOUNT , setprice_AMOUNT] = useState("");
  const [discount_AMOUNT , setdiscount_AMOUNT] = useState("");
  const [net_AMOUNT , setnet_AMOUNT] = useState("");
  const [tax_AMOUNT , settax_AMOUNT] = useState("");
  const [total , settotal] = useState("");
  const [fr_PRICE_AMOUNT , setfr_PRICE_AMOUNT] = useState("");
  const [fr_DISCOUNT_AMOUNT , setfr_DISCOUNT_AMOUNT] = useState("");
  const [fr_NET_AMOUNT , setfr_NET_AMOUNT] = useState("");
  const [fr_tax_AMOUNT , setfr_tax_AMOUNT] = useState("");
  const [fr_TOTAL , setfr_TOTAL] = useState("");
  const [pd_LO_CN , setpd_LO_CN] = useState("");
  const [pd_LT_NO , setpd_LT_NO] = useState("");
  const [returnable , setreturnable] = useState("");
  const [pd_RL_TY , setpd_RL_TY] = useState("");
  const [pd_RL_CO , setpd_RL_CO] = useState("");
  const [ph_AL_TY , setph_AL_TY] = useState("");
  const [item_NAME , setitem_NAME] = useState("");


  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };


  const [ItemPopupVisible, setItemPopupVisible] = useState(false);
  const openitemPopup = (e) => {
    setItemPopupVisible(true);
   
  };
  const handleDataSelect1 = (data) => {
    setSelecteditem(data.im_IT_DS);
    setitem(data.im_IT_NM);
    setItemPopupVisible(false);
    updateFilters1 (data.im_IT_NM);
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  function onChnageExchangeRate (frCr,orderDate) {
    const exData={
      "cr_TO_CR": document.getElementById("ph_BS_CR").value, 
      "cr_FR_CR": frCr,
      "cr_EF_DT": orderDate
    }
    axios
        .post(`${domain}/api/ExChangeRate/Rate`, exData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          const dataExRt = resp.data.Result;
          if (dataExRt) {
            document.getElementById("ph_EX_RT").value = dataExRt.cr_CO_MR;
            setph_EX_RT(dataExRt.cr_CO_MR);
          }
  
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
  
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setPurchaseOrdertable([{}]);
    setcancelFlag(false);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedAbAdNM(null);
    setSelectedAbAdNN(null);
    setSelectedAbAdNNA(null);
    setph_EX_RT("");
    setph_PY_TR(null);
    setph_TR_CR(null);
    setph_TX_AP(null);
    setpd_TR_UM(null);
    setpd_SC_UM(null);
    setpd_TX_AP(null);
    setprice_AMOUNT(null);
    setdiscount_AMOUNT(null);
    setnet_AMOUNT(null);
    settax_AMOUNT(null);
    settotal(null);
    setfr_PRICE_AMOUNT(null);
    setfr_DISCOUNT_AMOUNT(null);
    setfr_NET_AMOUNT(null);
    setfr_tax_AMOUNT(null);
    setfr_TOTAL(null);
    setOpen(true);
  };
  const handleClearButton=()=>{
    setitem("");
    setSelecteditem("");
    setCostCenter("");
    setFilters("");
  }
  const handleClose = () => {
    setPurchaseOrdertable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedAbAdNM(null);
    setSelectedAbAdNN(null);
    setSelectedAbAdNNA(null);
    setph_PY_TR(null);
    setph_TR_CR(null);
    setph_TX_AP(null);
    setpd_TR_UM(null);
    setpd_SC_UM(null);
    setpd_TX_AP(null);
    setprice_AMOUNT(null);
    setdiscount_AMOUNT(null);
    setnet_AMOUNT(null);
    settax_AMOUNT(null);
    settotal(null);
    setfr_PRICE_AMOUNT(null);
    setfr_DISCOUNT_AMOUNT(null);
    setfr_NET_AMOUNT(null);
    setfr_tax_AMOUNT(null);
    setfr_TOTAL(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      // handleSearch();
      handleDefault();
    }
  };
  const addRow = () => {
    setPurchaseOrdertable([...PurchaseOrdertable, {}]);
  };

  const addRow1 = () => {
    setPurchaseOrdertable1([...PurchaseOrdertable1, {}]);
  };

  const deleteRow = () => {
    if (PurchaseOrdertable.length > 0) {
      const updatedData = [...PurchaseOrdertable];
      updatedData.pop();
      setPurchaseOrdertable(updatedData);
    }
  };

  const deleteRow1 = () => {
    if (PurchaseOrdertable1.length > 0) {
      const updatedData = [...PurchaseOrdertable1];
      updatedData.pop();
      setPurchaseOrdertable1(updatedData);
    }
  };
  const handleCopyData = async () => {
    setcancelFlag(false)
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/PurchaseOrder/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const PurchaseOrderData = response.data;
          delete PurchaseOrderData.purchaseQuotes.ph_OR_NO;
          delete PurchaseOrderData.purchaseQuotes.phUkNO;
          for (let i = 0; i < PurchaseOrderData.purchaseOrders.length; i++) {
            delete PurchaseOrderData.purchaseOrders[i].pdUkNo;
          }
          setFormData(PurchaseOrderData.purchaseQuotes);
          setPurchaseOrdertable(PurchaseOrderData.purchaseOrders);
          setSelectedemEMNO(PurchaseOrderData.purchaseQuotes.sup_NAME); 
          setSelectedAbAdNM(PurchaseOrderData.purchaseQuotes.shipto_NAME); 
          setSelectedAbAdNN(PurchaseOrderData.purchaseOrders.sup_NAME); 
          setph_PY_TR(PurchaseOrderData.purchaseQuotes.ph_PY_TR);
          setph_CO_CT(PurchaseOrderData.purchaseQuotes.ph_CO_CT);
          setph_TR_CR(PurchaseOrderData.purchaseQuotes.ph_TR_CR);
          // onchangeFormData("ph_TR_CR",PurchaseOrderData.purchaseQuotes.ph_TR_CR);
          setph_TX_AP(PurchaseOrderData.purchaseQuotes.ph_TX_AP);
          setpd_TR_UM(PurchaseOrderData.pd_TR_UM);
          setpd_SC_UM(PurchaseOrderData.pd_SC_UM);
          setpd_TX_AP(PurchaseOrderData.pd_TX_AP);

          setprice_AMOUNT(response.data.purchaseQuotes.price_AMOUNT);
          setdiscount_AMOUNT(response.data.purchaseQuotes.discount_AMOUNT);
          setnet_AMOUNT(response.data.purchaseQuotes.taxable_AMOUNT);
          settax_AMOUNT(response.data.purchaseQuotes.tax_AMOUNT);
          settotal(response.data.purchaseQuotes.total);
          setfr_PRICE_AMOUNT(response.data.purchaseQuotes.fr_PRICE_AMOUNT);
          setfr_DISCOUNT_AMOUNT(response.data.purchaseQuotes.fr_DISCOUNT_AMOUNT);
          setfr_NET_AMOUNT(response.data.purchaseQuotes.fr_TAXABLE_AMOUNT);
          setfr_tax_AMOUNT(response.data.purchaseQuotes.fr_tax_AMOUNT);
          setfr_TOTAL(response.data.purchaseQuotes.fr_TOTAL);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
 
  const updateFilters1 = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      pd_IT_NM: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const updateFilters2 = (sts) => {
    let updatedFilters = { ...filters };
    if (sts !== null) {
      updatedFilters = {
        ...updatedFilters,
        ph_CO_CT: {
          value: sts,
          operation: "LIKE",
        },
      };
    }

    setFilters(updatedFilters);
  };
  const [ph_OR_CO , setph_OR_CO ] = useState("");
  const [ph_OR_TY, setph_OR_TY] = useState("");
  const [ph_BS_CR, setph_BS_CR] = useState("");
  const [pd_ST_CD, setpd_ST_CD] = useState("");
  const [Selectedstock, setSelectedstock] = useState("");
  const handleDefault = async () => {
    setph_OR_CO(`${company}`);
      const linkPath = String(window.location);
      const l_array = linkPath.split("=");
      const LinkId = l_array[1];
      
      const requestBody = {
        "version_ID" : LinkId
      };
      const paginationUrl = `${domain}/api/LinkId/Select`;
      try {
        const response = await axios.post(paginationUrl, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataDefault = response.data;
        const listDefault = [];
        var count = 0;
        if (dataDefault.versionIds != null) {
          for (let i = 0; i < dataDefault.versionIds.length; i++) {
            if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
              const fName = dataDefault.versionIds[i].field_ID;
             
              if(fName === "PH_OR_TY"){
                setph_OR_TY(dataDefault.versionIds[i].default_VALUE)
              }
              if(fName === "PH_BS_CR"){
                setph_BS_CR(dataDefault.versionIds[i].default_VALUE)
              }
              if(fName === "PH_CO_CT"){
                onchangeDropDownFormData1("ph_CO_CT",dataDefault.versionIds[i].default_VALUE);
                setph_CO_CT(dataDefault.versionIds[i].default_VALUE);
  
              }
              if(fName === "PD_ST_CD"){
                setpd_ST_CD(dataDefault.versionIds[i].default_VALUE)
              }
              if(fName === "PD_CO_CT"){
                setpd_CO_CT(dataDefault.versionIds[i].default_VALUE)
              }
              if(fName === "PH_HL_CD"){
                HoldCodeDropdown("ph_HL_CD",dataDefault.versionIds[i].default_VALUE);
                setph_HL_CD(dataDefault.versionIds[i].default_VALUE);
              }
              if(fName === "PH_AL_TY"){
                setph_AL_TY(dataDefault.versionIds[i].default_VALUE)
              }
            }
            if(dataDefault.versionIds[i].field_TYPE === "LIST"){
              count++;
              const def = {
                "column" : dataDefault.versionIds[i].field_ID ,
                "value" : dataDefault.versionIds[i].default_VALUE,
                "operation" : "EQUAL"
              }
              listDefault.push(def);
            }
          }
          handleSearch(listDefault,count);
        } else {
          handleSearch(listDefault,count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  useEffect(() => {
    handleDefault();
    
  }, [currentPage, pageSize, filters]);

  const handleSearch = async ( ) => {
    const searchRequestDto = buildSearchRequestDto();
   
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PurchaseOrder/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangeDropDown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setCostCenter(value);
    updateFilters2(value);
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangedata1= (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const onchangeOrderdate = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
    onChnageExchangeRate (ph_TR_CR,value);
  };

  const OnchangeTableData = (key, value, index) => {
    const updatedData = [...PurchaseOrdertable];
    updatedData[index][key] = value;
    setPurchaseOrdertable(updatedData);
  };

  const OnchangeTableData1 = (key, value, index) => {
    const updatedData = [...PurchaseOrdertable1];
    updatedData[index][key] = value;
    setPurchaseOrdertable1(updatedData);
  };

  const onchangeFieldSetFormData = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const HoldCodeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setph_HL_CD(value);
  };

  const LOTNoDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_LT_NO(value);
  };

  const LocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_LO_CN(value);
  };

  const onchangeFormData = (name, value ,index) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const fieldName=(FormData && FormData.pdUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      PurchaseOrdertable[index][fieldName]=value;
    const fieldName1=(FormData && FormData.pdUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      PurchaseOrdertable1[index][fieldName1]=value;
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setph_CO_CT(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setph_TR_CR(value);
    
    const bsCr = document.getElementById("ph_BS_CR").value;
    const table = document.getElementById("PurchaseOrdertable");
    const totalRowCount = table.tBodies[0].rows.length;
    if(bsCr === value){
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById("pd_BS_UP_" + i).removeAttribute('disabled');
        document.getElementById("pd_FR_UP_" + i).setAttribute('disabled','disabled');
        document.getElementById("pd_FR_AT_" + i).value = '0';
        document.getElementById("pd_FR_DS_" + i).value = '0';
        document.getElementById("fr_NET_AMOUNT_" + i).value = '0';
      }
    } else {
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById("pd_FR_UP_" + i).removeAttribute('disabled');
        document.getElementById("pd_BS_UP_" + i).setAttribute('disabled','disabled');
      }
    }
    onChnageExchangeRate (value, FormData.ph_OR_DT);
  };

  const LandedCostDropdown = async(name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setph_LN_CT(value);
    const Url = `${domain}/api/PurchaseQuote/GetLandedCostItems`;
    const requestData={
      "ph_LN_CT":value,
      "ph_TR_CR":FormData.ph_TR_CR,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.landedCostItems;
  
      if (dataLead && dataLead.length > 0) {
        setPurchaseOrdertable1(dataLead.map(item => ({
          item_NAME: item.item_NAME,
          description: item.item_NAME,
          applied: item.applied,
          pay_TO_SUPPLIER: item.pay_TO_SUPPLIER,
          tax_PERCENTAGE: item.tax_PERCENTAGE,
          tax_APPLICABLE: item.tax_APPLICABLE,
          currency: item.currency,
          tax_AMOUNT: item.tax_AMOUNT,

        })));
  
      } else {
        console.error("Invalid API response:" );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setph_TX_AP(value);
   
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setpd_TR_UM(value);

  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setpd_SC_UM(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setph_PY_TR(value);
   
  };
  const onchangeDropDownFormData7= (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setpd_TX_AP(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/PurchaseOrder/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const PurchaseOrderData = response.data;
        setPurchaseOrdertable(PurchaseOrderData.purchaseOrders);
        setFormData(PurchaseOrderData.purchaseQuotes);
         setSelectedemEMNO(PurchaseOrderData.purchaseQuotes.sup_NAME);
         setSelectedAbAdNM(PurchaseOrderData.purchaseQuotes.shipto_NAME); 
         setSelectedAbAdNN(PurchaseOrderData.purchaseOrders.sup_NAME); 
          setph_PY_TR(PurchaseOrderData.purchaseQuotes.ph_PY_TR);
          setph_CO_CT(PurchaseOrderData.purchaseQuotes.ph_CO_CT);
          setph_TR_CR(PurchaseOrderData.purchaseQuotes.ph_TR_CR);
          setph_TX_AP(PurchaseOrderData.purchaseQuotes.ph_TX_AP);
          setpd_TR_UM(PurchaseOrderData.purchaseOrders.pd_TR_UM);
          setpd_SC_UM(PurchaseOrderData.purchaseOrders.pd_SC_UM);
          setpd_TX_AP(PurchaseOrderData.purchaseOrders.pd_TX_AP);

          setprice_AMOUNT(response.data.purchaseQuotes.price_AMOUNT);
          setdiscount_AMOUNT(response.data.purchaseQuotes.discount_AMOUNT);
          setnet_AMOUNT(response.data.purchaseQuotes.taxable_AMOUNT);
          settax_AMOUNT(response.data.purchaseQuotes.tax_AMOUNT);
          settotal(response.data.purchaseQuotes.total);
          setfr_PRICE_AMOUNT(response.data.purchaseQuotes.fr_PRICE_AMOUNT);
          setfr_DISCOUNT_AMOUNT(response.data.purchaseQuotes.fr_DISCOUNT_AMOUNT);
          setfr_NET_AMOUNT(response.data.purchaseQuotes.fr_TAXABLE_AMOUNT);
          setfr_tax_AMOUNT(response.data.purchaseQuotes.fr_tax_AMOUNT);
          setfr_TOTAL(response.data.purchaseQuotes.fr_TOTAL);
          
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleCancel = async () => {
    setcancelFlag(true)
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/PurchaseOrder/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const PurchaseOrderData = response.data;
        setPurchaseOrdertable(PurchaseOrderData.purchaseOrders);
        setFormData(PurchaseOrderData.purchaseQuotes);
         setSelectedemEMNO(PurchaseOrderData.purchaseQuotes.sup_NAME);
         setSelectedAbAdNM(PurchaseOrderData.purchaseQuotes.shipto_NAME); 
         setSelectedAbAdNN(PurchaseOrderData.purchaseOrders.sup_NAME); 
          setph_PY_TR(PurchaseOrderData.purchaseQuotes.ph_PY_TR);
          setph_CO_CT(PurchaseOrderData.purchaseQuotes.ph_CO_CT);
          setph_TR_CR(PurchaseOrderData.purchaseQuotes.ph_TR_CR);
          setph_TX_AP(PurchaseOrderData.purchaseQuotes.ph_TX_AP);
          setpd_TR_UM(PurchaseOrderData.purchaseOrders.pd_TR_UM);
          setpd_SC_UM(PurchaseOrderData.purchaseOrders.pd_SC_UM);
          setpd_TX_AP(PurchaseOrderData.purchaseOrders.pd_TX_AP);

          setprice_AMOUNT(response.data.purchaseQuotes.price_AMOUNT);
          setdiscount_AMOUNT(response.data.purchaseQuotes.discount_AMOUNT);
          setnet_AMOUNT(response.data.purchaseQuotes.taxable_AMOUNT);
          settax_AMOUNT(response.data.purchaseQuotes.tax_AMOUNT);
          settotal(response.data.purchaseQuotes.total);
          setfr_PRICE_AMOUNT(response.data.purchaseQuotes.fr_PRICE_AMOUNT);
          setfr_DISCOUNT_AMOUNT(response.data.purchaseQuotes.fr_DISCOUNT_AMOUNT);
          setfr_NET_AMOUNT(response.data.purchaseQuotes.fr_TAXABLE_AMOUNT);
          setfr_tax_AMOUNT(response.data.purchaseQuotes.fr_tax_AMOUNT);
          setfr_TOTAL(response.data.purchaseQuotes.fr_TOTAL);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [mo_landed_costs, setMoLandedCosts] = useState([]);

  // Function to handle changes in input fields
  const handleInputChange = (index, field, value) => {
    const updatedCosts = [...mo_landed_costs];
    if (!updatedCosts[index]) {
      updatedCosts[index] = {}; // Initialize the object if it doesn't exist
    }
    updatedCosts[index][field] = value;
    setMoLandedCosts(updatedCosts);
  };

  const table1Ref = useRef(null);
  var requestBodyInput = {};
  function values () {
      const purchaseQuotes = {
        phUkNO  : FormData.phUkNO,
        ph_OR_NO: FormData.ph_OR_NO,
        ph_OR_TY: ph_OR_TY,
        ph_OR_CO: ph_OR_CO,
        ph_OR_DT: FormData.ph_OR_DT,
        ph_RQ_DT: FormData.ph_RQ_DT,
        ph_TX_AP: FormData.ph_TX_AP,
        ph_PY_TR: ph_PY_TR,
        ph_SU_NO: FormData.ph_SU_NO,
        ph_SH_NO: FormData.ph_SH_NO,
        ph_CO_CT: ph_CO_CT,
        ph_TR_CR: ph_TR_CR,
        ph_EX_RT: ph_EX_RT,
        ph_BS_CR: ph_BS_CR,
        ph_DS_AM: FormData.ph_DS_AM,
        ph_DS_PR: FormData.ph_DS_PR,
        ph_LN_CT: ph_LN_CT,
        ph_HL_CD: ph_HL_CD,
        ph_PR_DT: FormData.ph_PR_DT,
        ph_AL_TY: ph_AL_TY,
      };
      const table = document.getElementById("PurchaseOrdertable");
      const totalRowCount = table.tBodies[0].rows.length;
      const purchaseOrders = [];
      for (let i = 0; i < totalRowCount; i++) {  
        const RQ_DT = document.getElementsByName("pd_RQ_DT_" + i)[0];
        const CN_DT = document.getElementsByName("pd_CN_DT_" + i)[0];
        const PR_DT = document.getElementsByName("pd_PR_DT_" + i)[0];
        const Details = {
            pdUkNo : document.getElementById("pdUkNo_" + i).value,
          pd_LN_NO : document.getElementById("pd_LN_NO_" + i).value,
          pd_IT_NM : document.getElementById("pd_IT_NM_" + i).value,
          im_IT_DS : document.getElementById("im_IT_DS_" + i).value,
          pd_OR_QT : document.getElementById("pd_OR_QT_" + i).value,
          pd_TR_UM : table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
          pd_LS_PR : document.getElementById("pd_LS_PR_" + i).value,
          std_COST : document.getElementById("std_COST_" + i).value,
          pd_BS_UP : document.getElementById("pd_BS_UP_" + i).value,
          pd_OR_AT : document.getElementById("pd_OR_AT_" + i).value,
          pd_DS_PR : document.getElementById("pd_DS_PR_" + i).value,
          pd_DS_AM : document.getElementById("pd_DS_AM_" + i).value,
          net_AMOUNT: document.getElementById("net_AMOUNT_" + i).value,
          pd_TX_AP : table.tBodies[0].rows[i].cells[13].children[0].children[1].children[0].value,
          pd_TX_PE : document.getElementById("pd_TX_PE_" + i).value,
          pd_TX_AM : document.getElementById("pd_TX_AM_" + i).value,
          pd_FR_UP : document.getElementById("pd_FR_UP_" + i).value,
          pd_FR_AT : document.getElementById("pd_FR_AT_" + i).value,
          pd_FR_DS : document.getElementById("pd_FR_DS_" + i).value,
          fr_NET_AMOUNT: document.getElementById("fr_NET_AMOUNT_" + i).value,
          pd_LD_CT : document.getElementById("pd_LD_CT_" + i).value,
          pd_LO_CN : table.tBodies[0].rows[i].cells[21].children[0].children[1].children[0].value,
          pd_LT_NO : document.getElementById("pd_LT_NO_" + i).value,
          returnable : table.tBodies[0].rows[i].cells[23].children[0].children[1].children[0].value,
          pd_RQ_DT : RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          pd_PR_DT : PR_DT.value?moment(PR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          pd_SC_QT : document.getElementById("pd_SC_QT_" + i).value,
          pd_SC_UM : table.tBodies[0].rows[i].cells[27].children[0].children[1].children[0].value,
          pd_CN_QT : document.getElementById("pd_CN_QT_" + i).value,
          pd_CN_DT : CN_DT.value?moment(CN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          pd_RL_NO : document.getElementById("pd_RL_NO_" + i).value,
          pd_RL_TY : table.tBodies[0].rows[i].cells[31].children[0].children[1].children[0].value,
          pd_RL_CO : table.tBodies[0].rows[i].cells[32].children[0].children[1].children[0].value,
          pd_ST_CD : document.getElementById("pd_ST_CD_" + i).value,
          pd_CO_CT : document.getElementById("pd_CO_CT_" + i).value,
        };
        purchaseOrders.push(Details);
      }

      //  const table1 = document.getElementById("PurchaseOrdertable1");
      //  const totalRowCount1 = table1.tBodies[0].rows.length;// description : document.getElementById("description_" + i).value,
            
  
      const totalRowCount1 = PurchaseOrdertable1.length;
      const mo_landed_costs = [];
      for (let i = 0; i < totalRowCount1; i++) { 
         const it_NA = FormData["item_NAME_" + i];
        const Detailstab = {
            pdUkNo :  document.getElementById("pdUkNo_" + i).value,
            item_NAME : it_NA,
            applied : FormData["applied_" + i],
            cost_AMOUNT : FormData["cost_AMOUNT_" + i],
            tax_APPLICABLE : FormData["tax_APPLICABLE_" + i],
            tax_PERCENTAGE : FormData["tax_PERCENTAGE_" + i],
            tax_AMOUNT : FormData["tax_AMOUNT_" + i],
            currency : FormData["currency_" + i],
            pay_TO_SUPPLIER : FormData["pay_TO_SUPPLIER_" + i],
            supplier : FormData["supplier_" + i],
            name : FormData["name_" + i],
            
            
        };
        mo_landed_costs.push(Detailstab);
      }
      requestBodyInput = {
        purchaseQuotes,
        purchaseOrders,   
        mo_landed_costs,
      };
    }

    const handleFormSubmit = () => {
      values();
      const orNo = FormData.ph_OR_NO;
      const ukNo = FormData.phUkNO;
      if (orNo) {
        if (ukNo) {
          const confirm = window.confirm("Do you want to Update ?");
          const update = `${domain}/api/PurchaseOrder/Update`;
      
          if (confirm) {
            axios.put(update, requestBodyInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error updating data:", error);
              });
          }
        } else {
          axios.post(`${domain}/api/PurchaseOrder/Add`, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error adding data:", error);
            });
        }
      } else {
        const IN_DT = FormData.ph_OR_DT;
        if(IN_DT){
          const currentYear = IN_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${ph_OR_TY}/${secondHalf}/${company}/${firstHalf}`;  
          const finalUrl1 = `${urlNextNumber}/${parameters}`;
  
          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            setph_OR_NO(resp.dn_DO_NO);
            FormData.ph_OR_NO = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/PurchaseOrder/Add`, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
      }
      }; 

      const handleConfirm = () => {    
        values();
        const orNo = FormData.ph_OR_NO;
        const ukNo = FormData.phUkNO;
        if (orNo) {
          if (ukNo) {
            const confirm = window.confirm("Do you want to Confirm ?");
            const update = `${domain}/api/PurchaseOrder/PurchaseOrderConfirmDetails`;
            if (confirm) {
              axios
                .post(update, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error updating data:", error);
                });
            }
          } 
        }
    };   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
      <Tooltip target=".refund-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Cancel
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-times"  
        outlined
        className="mr-2 cancel-button"
        onClick={handleCancel}
        disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  const AdvanceSearch = (
      <div className="formgrid grid">
              <div className="field col" >
    <label htmlFor="item" className="font-bold">
    Item <span style={{ color: 'red' }}>*</span>
    </label>
    <div style={{ display: "flex" }}>
      <InputText
      style={{display:"none"}}
        id="item"
        name="item"
        value={item}
      />
      <InputText
        id="im_IT_DS"
        name="im_IT_DS"
        readOnly
        style={{backgroundColor:"#ffffff"}}
        onChange={(Event) => onchangedata(Event)}
        onFocus={() => setShowSearchIcon(true)}
        value={selecteditem}
      />
       {showSearchIcon && (
      <i
        className="pi pi-search"
        style={{
          color: "var(--primary-color)",
          fontSize: "119%",
          marginTop: "4%",
          cursor: "pointer",
        }}
        onClick={openitemPopup}
      ></i>
       )}
    </div>
    </div>
            <div className="field col"   >
          <label htmlFor="CostCenter" className="font-bold">
          Cost Center
          </label>
          <Dropdown
            style={{ width: "90%" }}
            id="CostCenter"
            name="CostCenter"
            value={CostCenter}
            options={ph_CO_CTOptions}
            onChange={(e) => onchangeDropDown("CostCenter", e.value)}
            placeholder="Select Cost Centre"
          />
        </div>
            </div>
            

  );
const rightClear = (
  <React.Fragment>
  <Tooltip  target=".clear-button" position="bottom" mouseTrack>
  <div>Clear Filter</div>
  </Tooltip>
  
  <Button
  style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
  icon="pi pi-times"
  className="mr-2 clear-button"
  link
  onClick={handleClearButton}
  />
  </React.Fragment>
  );
  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
         <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_NO"] ? filters["pd_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_NO",
                        e.target.value,
                        filters["pd_OR_NO"]
                          ? filters["pd_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_TY"] ? filters["pd_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_TY",
                        e.target.value,
                        filters["pd_OR_TY"]
                          ? filters["pd_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_CO"] ? filters["pd_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_CO",
                        e.target.value,
                        filters["pd_OR_CO"]
                          ? filters["pd_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_LN_NO"] ? filters["pd_LN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_LN_NO",
                        e.target.value,
                        filters["pd_LN_NO"]
                          ? filters["pd_LN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["pd_HL_CD"] ? filters["pd_HL_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_HL_CD",
                        e.target.value,
                        filters["pd_HL_CD"]
                          ? filters["pd_HL_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_SU_NO"] ? filters["pd_SU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_SU_NO",
                        e.target.value,
                        filters["pd_SU_NO"]
                          ? filters["pd_SU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sup_NAME"] ? filters["sup_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sup_NAME",
                        e.target.value,
                        filters["sup_NAME"]
                          ? filters["sup_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_CR_CD"] ? filters["pd_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_CR_CD",
                        e.target.value,
                        filters["pd_CR_CD"]
                          ? filters["pd_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_IT_NM"] ? filters["pd_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_IT_NM",
                        e.target.value,
                        filters["pd_IT_NM"]
                          ? filters["pd_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_IT_DS"] ? filters["im_IT_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_IT_DS",
                        e.target.value,
                        filters["im_IT_DS"]
                          ? filters["im_IT_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_DT"] ? filters["pd_OR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_DT",
                        e.target.value,
                        filters["pd_OR_DT"]
                          ? filters["pd_OR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_RQ_DT"] ? filters["pd_RQ_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_RQ_DT",
                        e.target.value,
                        filters["pd_RQ_DT"]
                          ? filters["pd_RQ_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_QT"] ? filters["pd_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_QT",
                        e.target.value,
                        filters["pd_OR_QT"]
                          ? filters["pd_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_TR_UM"] ? filters["pd_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_TR_UM",
                        e.target.value,
                        filters["pd_TR_UM"]
                          ? filters["pd_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_BS_UP"] ? filters["pd_BS_UP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_BS_UP",
                        e.target.value,
                        filters["pd_BS_UP"]
                          ? filters["pd_BS_UP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_AT"] ? filters["pd_OR_AT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_AT",
                        e.target.value,
                        filters["pd_OR_AT"]
                          ? filters["pd_OR_AT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_FR_UP"] ? filters["pd_FR_UP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_FR_UP",
                        e.target.value,
                        filters["pd_FR_UP"]
                          ? filters["pd_FR_UP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_FR_AT"] ? filters["pd_FR_AT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_FR_AT",
                        e.target.value,
                        filters["pd_FR_AT"]
                          ? filters["pd_FR_AT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_RC_QT"] ? filters["pd_RC_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_RC_QT",
                        e.target.value,
                        filters["pd_RC_QT"]
                          ? filters["pd_RC_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OP_QT"] ? filters["pd_OP_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OP_QT",
                        e.target.value,
                        filters["pd_OP_QT"]
                          ? filters["pd_OP_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_QT_NO"] ? filters["pd_QT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_QT_NO",
                        e.target.value,
                        filters["pd_QT_NO"]
                          ? filters["pd_QT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_QT_TY"] ? filters["pd_QT_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_QT_TY",
                        e.target.value,
                        filters["pd_QT_TY"]
                          ? filters["pd_QT_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_ST_CD"] ? filters["pd_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_ST_CD",
                        e.target.value,
                        filters["pd_ST_CD"]
                          ? filters["pd_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
              <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Line No</th>
                <th style={tableHeaderStyle}>Hold Code</th>
                <th style={tableHeaderStyle}>Supplier No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Item Number</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Order Date</th>
                <th style={tableHeaderStyle}>Req. Date</th>
                <th style={tableHeaderStyle}>Quantity</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Unit Price</th>
                <th style={tableHeaderStyle}>Amount</th>
                <th style={tableHeaderStyle}>Fore.price</th>
                <th style={tableHeaderStyle}>Fore.Amount</th>
                <th style={tableHeaderStyle}>Receipt Quantity</th>
                <th style={tableHeaderStyle}>Open Quantity</th>
                <th style={tableHeaderStyle}>Quote No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.pdUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.pdUkNo}
                        checked={selectedRows.includes(rowData.pdUkNo)}
                        onChange={() => handleRowSelect(rowData.pdUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.pd_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.pd_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.pd_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.pd_LN_NO}</td>
                    <td style={tableCellStyle}>{rowData.pd_HL_CD}</td>
                    <td style={tableCellStyle}>{rowData.pd_SU_NO}</td>
                    <td style={tableCellStyle}>{rowData.sup_NAME}</td>
                    <td style={tableCellStyle}>{rowData.pd_CR_CD}</td>
                    <td style={tableCellStyle}>{rowData.pd_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.im_IT_DS}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.pd_OR_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.pd_RQ_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.pd_OR_QT}</td>
                    <td style={tableCellStyle}>{rowData.pd_TR_UM}</td>
                    <td style={tableCellStyle}>{rowData.pd_BS_UP}</td>
                    <td style={tableCellStyle}>{rowData.pd_OR_AT}</td>
                    <td style={tableCellStyle}>{rowData.pd_FR_UP}</td>
                    <td style={tableCellStyle}>{rowData.pd_FR_AT}</td>
                    <td style={tableCellStyle}>{rowData.pd_RC_QT}</td>
                    <td style={tableCellStyle}>{rowData.pd_OP_QT}</td>
                    <td style={tableCellStyle}>{rowData.pd_QT_NO}</td>
                    <td style={tableCellStyle}>{rowData.pd_QT_TY}</td>
                    <td style={tableCellStyle}>{rowData.pd_ST_CD}</td>
                   
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

      </div>
       <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelect1}
       
      />
      <PurchaseOrderDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        ph_OR_TY={ph_OR_TY}
        ph_OR_CO={ph_OR_CO}
        ph_BS_CR={ph_BS_CR}
        ph_CO_CT={ph_CO_CT}
        ph_TR_CR={ph_TR_CR}
        ph_TX_AP={ph_TX_AP}
        pd_TR_UM={pd_TR_UM}
        pd_SC_UM={pd_SC_UM}
        pd_TX_AP={pd_TX_AP}
        ph_SU_NO={ph_SU_NO}
        ph_EX_RT={ph_EX_RT}
        ph_SH_NO={ph_SH_NO}
        ph_PY_TR={ph_PY_TR}
        pd_SU_NO={pd_SU_NO}
        sup_NAME={sup_NAME}
        pd_ST_CD={pd_ST_CD}
        setph_TR_CR={setph_TR_CR}
        setph_PY_TR={setph_PY_TR}
        setph_EX_RT={setph_EX_RT}
        setph_SU_NO={setph_SU_NO}
        setsup_NAME={setsup_NAME}
        setpd_SU_NO={setpd_SU_NO}
        setph_SH_NO={setph_SH_NO}
        SelectedemEMNO={SelectedemEMNO}
        selectedAbAdNM={selectedAbAdNM}
        selectedAbAdNN={selectedAbAdNN}
        selectedAbAdNNA={selectedAbAdNNA}
        setSelectedemEMNO={setSelectedemEMNO}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedAbAdNN={setSelectedAbAdNN}
        setSelectedAbAdNNA={setSelectedAbAdNNA}
        onchangeOrderdate={onchangeOrderdate}
        PurchaseOrdertable={PurchaseOrdertable} 
        addRow={addRow} 
        addRow1={addRow1} 
        cancelFlag={cancelFlag}
        deleteRow={deleteRow} 
        deleteRow1={deleteRow1} 
        pd_IT_NM={pd_IT_NM}
        setpd_IT_NM={setpd_IT_NM}
        handleConfirm={handleConfirm}
        PurchaseOrdertable1={PurchaseOrdertable1}
        // setPurchaseOrdertable1={setPurchaseOrdertable1}
        setapplied={setapplied}
        applied={applied}
        tax_APPLICABLE={tax_APPLICABLE}
        settax_APPLICABLE={settax_APPLICABLE}
        currency={currency}
        setcurrency={setcurrency}
        pay_TO_SUPPLIER={pay_TO_SUPPLIER}
        setpay_TO_SUPPLIER={setpay_TO_SUPPLIER}
        setSelectedsupplier={setSelectedsupplier}
        Selectedsupplier={Selectedsupplier}
        setSelectedName={setSelectedName}
        SelectedName={SelectedName}
        OnchangeTableData={OnchangeTableData}
        OnchangeTableData1={OnchangeTableData1}
        ph_HL_CD={ph_HL_CD}
        setph_HL_CD={setph_HL_CD}
        ph_LN_CT={ph_LN_CT}
        setph_LN_CT={setph_LN_CT}
        pd_CO_CT={pd_CO_CT}
        onchangeFieldSetFormData={onchangeFieldSetFormData}
        HoldCodeDropdown={HoldCodeDropdown}
        price_AMOUNT={price_AMOUNT}
        setprice_AMOUNT={setprice_AMOUNT}
        discount_AMOUNT={discount_AMOUNT}
        setdiscount_AMOUNT={setdiscount_AMOUNT}
        setnet_AMOUNT={setnet_AMOUNT}
        net_AMOUNT={net_AMOUNT}
        settax_AMOUNT={settax_AMOUNT}
        tax_AMOUNT={tax_AMOUNT}
        settotal={settotal}
        total={total}
        fr_PRICE_AMOUNT={fr_PRICE_AMOUNT}
        setfr_PRICE_AMOUNT={setfr_PRICE_AMOUNT}
        fr_DISCOUNT_AMOUNT={fr_DISCOUNT_AMOUNT}
        setfr_DISCOUNT_AMOUNT={setfr_DISCOUNT_AMOUNT}
        fr_NET_AMOUNT={fr_NET_AMOUNT}
        setfr_NET_AMOUNT={setfr_NET_AMOUNT}
        fr_tax_AMOUNT={fr_tax_AMOUNT}
        setfr_tax_AMOUNT={setfr_tax_AMOUNT}
        fr_TOTAL={fr_TOTAL}
        setfr_TOTAL={setfr_TOTAL}
        LOTNoDropdown={LOTNoDropdown}
        LocationDropdown={LocationDropdown}
        pd_LO_CN={pd_LO_CN}
        pd_LT_NO={pd_LT_NO}
        returnable={returnable}
        setreturnable={setreturnable}
        pd_RL_TY={pd_RL_TY}
        pd_RL_CO={pd_RL_CO}
        setpd_RL_TY={setpd_RL_TY}
        setpd_RL_CO={setpd_RL_CO}
        ph_AL_TY={ph_AL_TY}
        LandedCostDropdown={LandedCostDropdown}
        setSelectedstock={setSelectedstock}
        Selectedstock={Selectedstock}
        table1Ref={table1Ref}
        item_NAME={item_NAME}
        handleInputChange={handleInputChange}
      />
    </div>
     </div>
  );
};

export default PurchaseOrderMain;
