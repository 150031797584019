import React from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';                    
import 'primeicons/primeicons.css';                                 
import 'primeflex/primeflex.css';                                   
import '../../common/flags.css';
import ActivityPlanMain from './ActivityMain';

const ActivityPlan = () => {

  return (
   <div className='saleslead'>
      <ActivityPlanMain/>
   </div>
  )
}

export default ActivityPlan