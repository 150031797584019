import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import RateDetailsDialog from "./RateDetailsDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  rdUkNo:"",
  rd_CO_CT:"",
  rd_RT_NM:"",
  rd_BI_BL:"",
  rd_PA_BL:"",
  rd_RT_TY:"",
  rd_D1_TY:"",
  rd_RT_BS:"",
  rd_D2_TY:"",
  rd_RT_UM:"",

};

const initialFilters = {
  rd_CO_CT: { value: "", operation: "" },
  rd_RT_NM: { value: "", operation: "" },
  rd_BI_BL: { value: "", operation: "" },
  rd_PA_BL: { value: "", operation: "" },
  rd_RT_TY: { value: "", operation: "" },
  rd_D1_TY: { value: "", operation: "" },
  rd_RT_BS: { value: "", operation: "" },
  rd_D2_TY: { value: "", operation: "" },
  rd_RT_UM: { value: "", operation: "" },
};

const RateDetailsMain = () => {
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,formatTime,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
// drodpdowns
const [rd_CO_CT, setrd_CO_CT ] = useState("");
const [rd_RT_NM, setrd_RT_NM ] = useState("");
const [rd_RT_TY, setrd_RT_TY ] = useState("");
const [rd_PA_BL, setrd_PA_BL ] = useState("");
const [rd_RT_ST, setrd_RT_ST ] = useState("");
const [rd_BI_BL, setrd_BI_BL ] = useState("");
const [rd_RT_BS, setrd_RT_BS ] = useState("");
const [rd_RT_LV, setrd_RT_LV ] = useState("");
const [rd_RT_UM, setrd_RT_UM ] = useState("");
const [rd_D1_TY, setrd_D1_TY ] = useState("");
const [rd_DI_LV, setrd_DI_LV ] = useState("");
const [rd_D2_TY, setrd_D2_TY ] = useState("");
const [rd_D1_UM, setrd_D1_UM ] = useState("");
const [rd_D2_UM, setrd_D2_UM ] = useState("");
const [SelectedemEMNO, setSelectedemEMNO ] = useState("");




  

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setrd_RT_NM(null);
    setrd_CO_CT(null);
    setrd_RT_TY(null);
    setrd_PA_BL(null);
    setrd_RT_ST(null);
    setrd_BI_BL(null);
    setrd_RT_BS(null);
    setrd_RT_LV(null);
    setrd_RT_UM(null);
    setrd_D1_TY(null);
    setrd_DI_LV(null);
    setrd_D2_TY(null);
    setrd_D1_UM(null);
    setrd_D2_UM(null);


    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/RateDetails/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const RateDetailsData = response.data;
          delete RateDetailsData.rdUkNo;
          setFormData(RateDetailsData);
       setrd_RT_NM(RateDetailsData.rd_RT_NM);
       setrd_CO_CT(RateDetailsData.rd_CO_CT);
       setrd_RT_TY(RateDetailsData.rd_RT_TY);
       setrd_PA_BL(RateDetailsData.rd_PA_BL);
       setrd_RT_ST(RateDetailsData.rd_RT_ST);
       setrd_BI_BL(RateDetailsData.rd_BI_BL);
       setrd_RT_BS(RateDetailsData.rd_RT_BS);
       setrd_RT_LV(RateDetailsData.rd_RT_LV);
       setrd_RT_UM(RateDetailsData.rd_RT_UM);
       setrd_D1_TY(RateDetailsData.rd_D1_TY);
       setrd_DI_LV(RateDetailsData.rd_DI_LV);
       setrd_D2_TY(RateDetailsData.rd_D2_TY);
       setrd_D1_UM(RateDetailsData.rd_D1_UM);
       setrd_D2_UM(RateDetailsData.rd_D2_UM);
       
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [rm_CO_NO , setvm_CO_NO] = useState("");
 
  const handleDefault = async () => {
    setvm_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/RateDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
 
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/RateDetails/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const RateDetailsData = response.data;
        setFormData(RateDetailsData);
        setrd_RT_NM(RateDetailsData.rd_RT_NM);
        setrd_CO_CT(RateDetailsData.rd_CO_CT);
        setrd_RT_TY(RateDetailsData.rd_RT_TY);
        setrd_PA_BL(RateDetailsData.rd_PA_BL);
        setrd_RT_ST(RateDetailsData.rd_RT_ST);
        setrd_BI_BL(RateDetailsData.rd_BI_BL);
        setrd_RT_BS(RateDetailsData.rd_RT_BS);
        setrd_RT_LV(RateDetailsData.rd_RT_LV);
        setrd_RT_UM(RateDetailsData.rd_RT_UM);
        setrd_D1_TY(RateDetailsData.rd_D1_TY);
        setrd_DI_LV(RateDetailsData.rd_DI_LV);
        setrd_D2_TY(RateDetailsData.rd_D2_TY);
        setrd_D1_UM(RateDetailsData.rd_D1_UM);
        setrd_D2_UM(RateDetailsData.rd_D2_UM);
   
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {
    if (FormData.rdUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/RateDetails/Update`;
      if (confirm) {
        axios
          .put(update, FormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.rm_CO_NO = company;
      axios
        .post(`${domain}/api/RateDetails/Add`, FormData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rd_CO_CT"] ? filters["rd_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rd_CO_CT",
                        e.target.value,
                        filters["rd_CO_CT"]
                          ? filters["rd_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rd_RT_NM"] ? filters["rd_RT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rd_RT_NM",
                        e.target.value,
                        filters["rd_RT_NM"] ? filters["rd_RT_NM"].operation 
                        : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rd_BI_BL"] ? filters["rd_BI_BL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rd_BI_BL",
                        e.target.value,
                        filters["rd_BI_BL"]
                          ? filters["rd_BI_BL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rd_PA_BL"] ? filters["rd_PA_BL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rd_PA_BL",
                        e.target.value,
                        filters["rd_PA_BL"]
                          ? filters["rd_PA_BL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rd_RT_TY"] ? filters["rd_RT_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rd_RT_TY",
                        e.target.value,
                        filters["rd_RT_TY"]
                          ? filters["rd_RT_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rd_D1_TY"] ? filters["rd_D1_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rd_D1_TY",
                        e.target.value,
                        filters["rd_D1_TY"]
                          ? filters["rd_D1_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rd_RT_BS"] ? filters["rd_RT_BS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rd_RT_BS",
                        e.target.value,
                        filters["rd_RT_BS"] ? filters["rd_RT_BS"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rd_D2_TY"] ? filters["rd_D2_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rd_D2_TY",
                        e.target.value,
                        filters["rd_D2_TY"]
                          ? filters["rd_D2_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rd_RT_UM"] ? filters["rd_RT_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rd_RT_UM",
                        e.target.value,
                        filters["rd_RT_UM"]
                          ? filters["rd_RT_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Branch</th>
                <th style={tableHeaderStyle}>Rate Name</th>
                <th style={tableHeaderStyle}>Billable</th>
                <th style={tableHeaderStyle}>Payable</th>
                <th style={tableHeaderStyle}>Rate Type</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Rate Basis</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Rate UM</th>
             
            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.rdUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.rdUkNo}
                        checked={selectedRows.includes(rowData.rdUkNo)}
                        onChange={() => handleRowSelect(rowData.rdUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.rd_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.rd_RT_NM}</td>
                    <td style={tableCellStyle}>{rowData.rd_BI_BL}</td>
                    <td style={tableCellStyle}>{rowData.rd_PA_BL}</td>
                    <td style={tableCellStyle}>{rowData.rd_RT_TY}</td> 
                    <td style={tableCellStyle}>{rowData.rd_D1_TY}</td>
                    <td style={tableCellStyle}>{rowData.rd_RT_BS}</td>
                    <td style={tableCellStyle}>{rowData.rd_D2_TY}</td> 
                    <td style={tableCellStyle}>{rowData.rd_RT_UM}</td>  
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <RateDetailsDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
       
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
       
        rd_RT_NM={rd_RT_NM}
        setrd_RT_NM={setrd_RT_NM}
        rd_CO_CT={rd_CO_CT}
        setrd_CO_CT={setrd_CO_CT}
        rd_RT_TY={rd_RT_TY}
        setrd_RT_TY={setrd_RT_TY}
        rd_PA_BL={rd_PA_BL}
        setrd_PA_BL={setrd_PA_BL}
        rd_RT_ST={rd_RT_ST}
        setrd_RT_ST={setrd_RT_ST}
        rd_BI_BL={rd_BI_BL}
        setrd_BI_BL={setrd_BI_BL}
        rd_RT_BS={rd_RT_BS}
        setrd_RT_BS={setrd_RT_BS}
        rd_RT_LV={rd_RT_LV}
        setrd_RT_LV={setrd_RT_LV}
        rd_RT_UM={rd_RT_UM}
        setrd_RT_UM={setrd_RT_UM}
        rd_D1_TY={rd_D1_TY}
        setrd_D1_TY={setrd_D1_TY}
        rd_DI_LV={rd_DI_LV}
        setrd_DI_LV={setrd_DI_LV}
        rd_D2_TY={rd_D2_TY}
        setrd_D2_TY={setrd_D2_TY}
        rd_D1_UM={rd_D1_UM}
        setrd_D1_UM={setrd_D1_UM}
        rd_D2_UM={rd_D2_UM}
        setrd_D2_UM={setrd_D2_UM}
      />
    </div>
  );
};

export default RateDetailsMain;
