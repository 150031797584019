import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import SupplierPopup from "../Popups/SupplierPopup";
import SpecificationPopup from "../Popups/SpecificationPopup";
import ItemPopup from "../Popups/ItemPopup";

import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function AttachSpecToProductDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  AttachSpecToProductTableData,
  setAttachSpecToProductTableData,
  spUkNo,
  SelectedITNM,
  setSelectedITNM,
  sp_CO_CT,
  setsp_CO_CT,
  sp_PR_GP,
  setsp_PR_GP,
  sp_ST_CD,
  setsp_ST_CD,
  sp_OP_SQ,
  setsp_OP_SQ,
}) {
  const {
    sp_IT_NM,
  
    
    

  } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, multitableheader } = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter, fetchCostCenterP } = Costcenter();
  const [sp_CO_CTOptions, setsp_CO_CTOptions] = useState([]);
  const [sp_ST_CDOptions, setsp_ST_CDOptions] = useState([]);
  const [sp_OP_SQOptions, setsp_OP_SQOptions] = useState([]);
  const [sp_PR_GPOptions, setsp_PR_GPOptions] = useState([]);

  
  // Cost Centre
  const onchangeDropDownFormDataCostCentre = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsp_CO_CT(value);
  };
  // Cost Centre
  const onchangeDropDownFormDataProductGroup = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsp_PR_GP(value);
  };

  // Status
  const onchangeDropDownFormDataStatus = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsp_ST_CD(value);
  };
  // UM
  const OperSeqDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsp_OP_SQ(value);
  };




  useEffect(() => {
    fetchDropdownCost();
    Status();
    OperSeq();
    ProductGroup();

  }, []);

  const fetchDropdownCost = async () => {
    const Options = await fetchCostCenter();
    setsp_CO_CTOptions(Options);
  };
  const OperSeq = async () => {
    const Options = await fetchClassCodeDS("TE/ST");
    setsp_OP_SQOptions(Options);
  };
  const Status = async () => {
    const Options = await fetchClassCodeCD("SP/ST");
    setsp_ST_CDOptions(Options);
  };
  const ProductGroup = async () => {
    const Options = await fetchClassCodeCD("IN/PD");
    setsp_PR_GPOptions(Options);
  };

  

  const addRow = () => {
    setAttachSpecToProductTableData([...AttachSpecToProductTableData, {}]);
  };

  const deleteRow = () => {
    if (AttachSpecToProductTableData.length > 0) {
      const updatedData = [...AttachSpecToProductTableData];
      updatedData.pop();
      setAttachSpecToProductTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(spUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
        tooltip={isUpdateMode ? "Update" : "Submit"}>
      </Button>
    </React.Fragment>
  );

  const [SupplierVisible, setSupplierVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const [SpecificationVisible, setISpecificationVisible] = useState(false);

  const [ItemPopupVisible, setItemPopupVisible] = useState(false);

  const handleDataSelect = async (data) => {
    //setselectedSupplier(data.ab_AD_NM);
    document.getElementById(`ep_PR_SU_${SelectedRowIndex}`).value = data.abAdNo;
    document.getElementById(`sup_NAME_${SelectedRowIndex}`).value = data.ab_AD_NM;
    setSupplierVisible(false);
    setShowSearchIcon(false);
  };
  const handleSpecification = async (data) => {
    document.getElementById(`sp_SP_ID_${SelectedRowIndex}`).value = data.ts_SP_ID;
    document.getElementById(`spec_DESC_${SelectedRowIndex}`).value = data.ts_SP_DS;
    onchangeDropDownFormDataCostCentre("sp_CO_CT", data.ts_CO_CT);
    onchangeDropDownFormDataStatus("ts_TS_ST", data.ts_TS_ST);

    setISpecificationVisible(false);
    setShowSearchIcon(false);
  };


  const openItemPopup = () => {
    setItemPopupVisible(true);
  };
  const handleDataSelectItem = (Result) => {
    setSelectedITNM(Result.im_IT_DS);
    document.getElementById("sp_IT_NM").value = Result.im_IT_NM;
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };

  const openSpecificationMasterPopup = (e) => {
    setISpecificationVisible(true);
    setSelectedRowIndex(e);
  };
  const openSupplierPopup = (e) => {
    setSupplierVisible(true);
    setSelectedRowIndex(e);
  };


  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "85rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={spUkNo ? "Revise AttachSpecToProduct" : "Add AttachSpecToProduct"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={inboxstyle}>
        <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
            <label htmlFor="spUkNo" className="font-bold">
              UKNO
            </label>
            <InputText
              id="spUkNo"
              name="spUkNo"
              value={spUkNo}
              onChange={(e) => onchangedata(e)}
            />
          </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="pr_IT_NM" className="font-bold">
              Item Number<span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: 'flex' }}>
              <InputText
                id="im_IT_DS"
                name="im_IT_DS"
                onChange={(event) => onchangedata(event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedITNM}
              />
              {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: 'var(--primary-color)',
                    fontSize: '119%',
                    marginTop: '4%',
                    cursor: 'pointer',
                  }}
                  onClick={openItemPopup}
                ></i>
              )}
            </div>
            <div style={{ display: 'none' }}>
              <InputText
                id="sp_IT_NM"
                name="sp_IT_NM"
                defaultValue={sp_IT_NM}
                onChange={(event) => onchangedata(event)}
              />
            </div>
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sp_CO_CT" className="font-bold">
              Cost Centre
            </label>
            <Dropdown
              name="sp_CO_CT"
              value={sp_CO_CT}
              options={sp_CO_CTOptions}
              onChange={(e) => onchangeDropDownFormDataCostCentre("sp_CO_CT", e.value)}
              placeholder="Cost Centre"
              disabled
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sp_PR_GP" className="font-bold">
            Product Group
            </label>
            <Dropdown
              name="sp_PR_GP"
              value={sp_PR_GP}
              options={sp_PR_GPOptions}
              onChange={(e) => onchangeDropDownFormDataProductGroup("sp_PR_GP", e.value)}
              placeholder=" Product Group"
              
            />
          </div>
         
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sp_ST_CD" className="font-bold">
              Status
            </label>
            <Dropdown
              name="sp_ST_CD"
              value={sp_ST_CD}
              options={sp_ST_CDOptions}
              onChange={(e) => onchangeDropDownFormDataStatus("sp_ST_CD", e.value)}
              placeholder="Status"

            />
          </div>

        </div>
      </div>
      <div style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
            <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
          )}
          {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div style={multitablestyle}>
          <table className="custom-table" id="AttachSpecToProducttable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Specification ID <span style={{ color: 'red' }}>*</span> </th>
                <th>Description</th>
                <th>Oper. Seq.</th>
                <th style={{ display: "none" }}>Company</th>
              </tr>
            </thead>
            <tbody>
              {AttachSpecToProductTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`spUkNo_${index}`}
                      name={`spUkNo_${index}`}
                      value={row.spUkNo}
                    />
                  </td>

                  <td
                    style={{ display: "flex" }}>
                    <InputText
                      style={{ width: "200px" }}
                      id={`sp_SP_ID_${index}`}
                      name={`sp_SP_ID_${index}`}
                      onChange={(e) => onchangeFormData(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.sp_SP_ID ? row.sp_SP_ID : row[`${"sp_SP_ID"}_${index}`]}

                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "15%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openSpecificationMasterPopup(index)}
                      ></i>
                    )}
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "200px" }}
                      id={`spec_DESC_${index}`}
                      name={`spec_DESC_${index}`}
                      value={row.spec_DESC ? row.spec_DESC : row[`${"ts_TS_DS"}_${index}`]}
                      readOnly
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`sp_OP_SQ_${index}`}
                      name={`sp_OP_SQ_${index}`}
                      value={data[`sp_OP_SQ_${index}`] ? data[`sp_OP_SQ_${index}`] : row.sp_OP_SQ}
                      options={sp_OP_SQOptions}
                      onChange={(e) => OperSeqDropdown(`sp_OP_SQ_${index}`, e.value)}
                      placeholder="Oper.Seq."
                     // disabled
                    />
                  </td>



                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
  
      <SpecificationPopup
        visible={SpecificationVisible}
        onClose={() => setISpecificationVisible(false)}
        onDataSelect={handleSpecification}
        formData={data}
      />
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelectItem}
        formData={data}
      />

    </Dialog>
  );
}
