import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import SupplierPopup from "./SupplierPopup";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
export default function PostPaymentsDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  // onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  addRow,
  deleteRow,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNOA,
  setSelectedemEMNOA,
  SelectedemEMNOE,
  setSelectedemEMNOE,
  PostPaymentstable,
}) {
  const { 
    ph_PM_NO,
    ph_DO_CO,
    ph_GL_BA,
    ph_PY_NO,
    ph_CR_RT,
    ph_GL_DT,
    ph_RM_EX,
    ph_PY_DT,
  ph_CR_CO,
  ph_UK_NO,
  rc_TR_RE,
  rc_CS_PR,
  rc_LO_CN,
  rc_DL_TY,
  rc_EN_DT,
  rc_CO_LV,
  rc_ST_DT,
  rc_UN_TS,
  rc_CO_ST,
  rc_GR_DE,
  rc_CE_CD,
  rc_CO_CD,
  } = data;
  const [ph_CR_COOptions, setph_CR_COOptions] = useState([]);

  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      const formattedDate1 = moment(selectedDate).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      const formattedDate1 = moment(value).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "rc_ST_DT"  || name === "rc_EN_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);
  
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };
  const url = `${domain}/api/ClassCode/All`;
  const parameter1 = "FO/CR";
  const finalUrl1 = `${url}/${parameter1}`;

  useEffect(() => {
    fetchDropdownOptions1();
  }, []);

  //Currency dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setph_CR_COOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  
const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
const openSupplierPopup = (e) => {
  setSupplierPopupVisible(true);
  setSelectedRowIndex(e);
};
const handleDataSelect = async (data) => {
  setSelectedemEMNO(data.abAdNo);
  setSelectedemEMNOE(data.ab_AD_NM);
  onchangeFormData("ph_PY_NO", data.abAdNo);
  onchangeFormData("name", data.ab_AD_NM);
  setSupplierPopupVisible(false);
};

const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
const openAccountPopup = (e) => {
  setAccountPopupVisible(true);
  setSelectedRowIndex(e);
};
const handleDataSelect1 = async (data) => {
  setSelectedemEMNOA(data.am_AC_DS);
  onchangeFormData("ph_GL_BA", data.account);
  setAccountPopupVisible(false);
};
const isUpdateMode = Boolean(ph_UK_NO);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={ph_UK_NO ? "Revise Post Payments" : "Add Post Payments"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div className="formgrid grid">
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_PM_NO" className="font-bold">
             Payment No
              </label>
              <InputText disabled
                id="ph_PM_NO"
                name="ph_PM_NO"
                value={ph_PM_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_PY_NO" className="font-bold">
                  Payee No  <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
              <InputText disabled
                id="ph_PY_NO"
                name="ph_PY_NO"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNO}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openSupplierPopup}
              ></i>
              )}
            </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="name" className="font-bold">
                  Name
                  </label>
                  <InputText disabled
                    id="name"
                    name="name"
                    value={SelectedemEMNOE}
                   readOnly
                  />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_DO_CO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="ph_DO_CO"
                    name="ph_DO_CO"
                    value={ph_DO_CO}
                    onChange={(e) => onchangedata(e)}
                  />
          </div>   
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_CR_CO" className="font-bold">
                  Currency
                  </label>
                  <Dropdown disabled
                    id="ph_CR_CO"
                    name="ph_CR_CO"
                    value={ph_CR_CO}
                    options={ph_CR_COOptions}
                    onChange={(e) => onchangeDropDownFormData1("ph_CR_CO", e.value)}
                    placeholder="Select Currency"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_GL_BA" className="font-bold">
                  Bank Account <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
              <InputText disabled
                id="accountDes"
                name="accountDes"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNOA}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openAccountPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="ph_GL_BA"
                name="ph_GL_BA"
                value={ph_GL_BA}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openAccountPopup}
              ></i>
            </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_CR_RT" className="font-bold">
                  Exchange Rate
                  </label>
                  <InputText disabled
                    id="ph_CR_RT"
                    name="ph_CR_RT"
                    value={ph_CR_RT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_GL_DT" className="font-bold">
                  GL Date
                  </label>
                  <Calendar disabled
                      id="ph_GL_DT"
                      name="ph_GL_DT"
                      value={moment(ph_GL_DT, "YYYYMMDD").toDate()}
                      onChange={(e) => onchangedata({ target: { name: "ph_GL_DT", value: moment(e.value).format("YYYYMMDD") } })}
                      showIcon
                      dateFormat="dd/mm/yy"
                    />    
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_RM_EX" className="font-bold">
                 Remarks
                  </label>
                  <InputText disabled
                    id="ph_RM_EX"
                    name="ph_RM_EX"
                    value={ph_RM_EX}
                    onChange={(e) => onchangedata(e)}
                  />
                 
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_PY_DT" className="font-bold">
                 Payment Date
                  </label>
                  <Calendar disabled
              id="ph_PY_DT"
              name="ph_PY_DT"
              value={moment(ph_PY_DT, "YYYYMMDD").toDate()}
              onChange={(e) => onchangedata({ target: { name: "ph_PY_DT", value: moment(e.value).format("YYYYMMDD") } })}
              showIcon
              dateFormat="dd/mm/yy"
            /> 
                </div>
        </div>
      </div>
      <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            marginTop: "2%",
          }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
          }}
        >
          {!isUpdateMode && (
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          )}
           {!isUpdateMode && (
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
          )}
        </div>
        <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
            position: "relative",
          }}
        >
        <table className="custom-table" id="PostPaymentstable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Payment Id</th>
          <th>Document Number</th>
          <th>Document Type</th>
          <th>Company</th>
          <th>Pay Item</th>
          <th>Due Date</th>
          <th>Gross Amount</th>
          <th>Payment Amount</th>
          <th>Discount Amount</th>
          <th>Currency Code</th>
          <th>Line No</th>
          <th>Supplier No</th>
          <th>Batch No</th>
          <th>Batch Type</th>
          <th>Batch Date</th>
          <th>Foreign Payment Amount</th>
          <th>Foreign Disccount Amount</th>
          <th>G/L Post Code</th>
          <th>Void Flag</th>
          <th>Void Date</th>
          <th>Void Reason</th>
        </tr>
      </thead>
      <tbody>
      {PostPaymentstable.map((row, index) => (
              <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
              id={`ph_UK_NO_${index}`}
              name={`ph_UK_NO_${index}`}
              defaultValue={row.ph_UK_NO}
            />
          </td>
          <td>
          <InputText  disabled style={{width:"120px"}}
            id={`pd_PY_ID_${index}`}
            name={`pd_PY_ID_${index}`}
            defaultValue={row.pd_PY_ID}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`pd_DO_NO_${index}`}
            name={`pd_DO_NO_${index}`}
            defaultValue={row.pd_DO_NO}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`pd_DO_TY_${index}`}
            name={`pd_DO_TY_${index}`}
            defaultValue={row.pd_DO_TY}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`pd_DO_CO_${index}`}
            name={`pd_DO_CO_${index}`}
            defaultValue={row.pd_DO_CO}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`pd_DO_PI_${index}`}
            name={`pd_DO_PI_${index}`}
            defaultValue={row.pd_DO_PI}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <Calendar  disabled style={{width:"150px"}}
            id={`pd_DD_DT_${index}`}
            name={`pd_DD_DT_${index}`}
            value={ row.pd_DD_DT ? moment(row.pd_DD_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "pd_DD_DT", value: moment(e.value).format("YYYYMMDD") } })}
            showIcon
            dateFormat="dd/mm/yy"
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`pd_GR_AM_${index}`}
            name={`pd_GR_AM_${index}`}
            defaultValue={row.pd_GR_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`pd_PA_AM_${index}`}
            name={`pd_PA_AM_${index}`}
            defaultValue={row.pd_PA_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`pd_DI_AM_${index}`}
            name={`pd_DI_AM_${index}`}
            defaultValue={row.pd_DI_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`pd_CR_CD_${index}`}
            name={`pd_CR_CD_${index}`}
            defaultValue={row.pd_CR_CD}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`pd_LN_NO_${index}`}
            name={`pd_LN_NO_${index}`}
            defaultValue={row.pd_LN_NO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`pd_SU_NO_${index}`}
            name={`pd_SU_NO_${index}`}
            defaultValue={row.pd_SU_NO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rc_LO_CN_${index}`}
            name={`rc_LO_CN_${index}`}
            defaultValue={row.rc_LO_CN}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rc_LO_CN_${index}`}
            name={`rc_LO_CN_${index}`}
            defaultValue={row.rc_LO_CN}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <Calendar  disabled style={{width:"150px"}}
            id={`pd_DD_DT_${index}`}
            name={`pd_DD_DT_${index}`}
            value={ row.pd_DD_DT ? moment(row.pd_DD_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "pd_DD_DT", value: moment(e.value).format("YYYYMMDD") } })}
            showIcon
            dateFormat="dd/mm/yy"
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`pd_PA_FR_${index}`}
            name={`pd_PA_FR_${index}`}
            defaultValue={row.pd_PA_FR}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`pd_Di_FR_${index}`}
            name={`pd_Di_FR_${index}`}
            defaultValue={row.pd_Di_FR}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`pd_GL_PO_${index}`}
            name={`pd_GL_PO_${index}`}
            defaultValue={row.pd_GL_PO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`rc_LO_CN_${index}`}
            name={`rc_LO_CN_${index}`}
            defaultValue={row.rc_LO_CN}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <Calendar  disabled style={{width:"150px"}}
            id={`pd_VO_DT_${index}`}
            name={`pd_VO_DT_${index}`}
            value={ row.pd_VO_DT ? moment(row.pd_VO_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "pd_VO_DT", value: moment(e.value).format("YYYYMMDD") } })}
            showIcon
            dateFormat="dd/mm/yy"
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`pd_VO_RE_${index}`}
            name={`pd_VO_RE_${index}`}
            defaultValue={row.pd_VO_RE}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          </tr>
           ))}
      </tbody>
    </table>   
    
    </div>
  </div>   
  <SupplierPopup
        visible={SupplierPopupVisible}
        onClose={() => setSupplierPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />

<AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
    </Dialog>
  );
}
