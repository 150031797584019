import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import AllocationDialog from "./AllocationDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  acUkNo:"",
  ac_DO_NO:"",
  ac_DO_TY:"",
  ac_DO_CO:"",
  ac_FR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  ac_TO_DT:"",
  ac_FR_CY: "",
  ac_EX_PL: "",
  ac_SA_TE: "",
  ac_CL_AC:"",
  ac_FR_CY:"",
  clear_DESC:"",
  ac_ST_US:"",
  ac_GL_DT:" ",
};

const initialFilters = {
  ac_DO_NO: { value: "", operation: "" },
  ac_DO_TY: { value: "", operation: "" },
  ac_DO_CO: { value: "", operation: "" },
  ac_FR_DT: { value: "", operation: "" },
  ac_TO_DT: { value: "", operation: "" },
  ac_FR_CY: { value: "", operation: "" },
  ac_EX_PL: { value: "", operation: "" },
  ac_SA_TE: { value: "", operation: "" },
  ac_CL_AC: { value: "", operation: "" },
  clear_DESC: { value: "", operation: "" },
  ac_ST_US: { value: "", operation: "" },
};

const AllocationMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [ac_ST_US, setac_ST_US] = useState("");
  const [ac_SA_TE, setac_SA_TE] = useState("");
  const [ac_FR_CY, setac_FR_CY] = useState("");
  const [Allocationtable, setAllocationtable] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setAllocationtable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setac_FR_CY(null);
    setOpen(true);
  };
  const handleClose = () => {
    setAllocationtable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setac_FR_CY(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      // handleSearch();
      handleDefault();
    }
  };
  const addRow = () => {
    setAllocationtable([...Allocationtable, {}]);
  };

  const deleteRow = () => {
    if (Allocationtable.length > 0) {
      const updatedData = [...Allocationtable];
      updatedData.pop();
      setAllocationtable(updatedData);
    }
  };
  const [ac_DO_CO , setac_DO_CO] = useState("");
  const [ac_DO_TY , setac_DO_TY] = useState("");
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;              
            setac_DO_CO(`${company}`)
            if(fName === "AC_DO_TY"){
              setac_DO_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "AC_SA_TE"){
              onchangeDropDownFormData2("ac_SA_TE",dataDefault.versionIds[i].default_VALUE);
              setac_SA_TE(response.data.versionIds[i].default_VALUE);
            }
            if(fName === "AC_ST_US"){
              onchangeDropDownFormData3("ac_ST_US",dataDefault.versionIds[i].default_VALUE);
              setac_ST_US(response.data.versionIds[i].default_VALUE);
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/Allocation/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const AllocationData = response.data;
          delete AllocationData.allocation.ac_DO_NO;
          delete AllocationData.allocation.acUkNo;
          for (let i = 0; i < AllocationData.allocationList.length; i++) {
            delete AllocationData.allocationList[i].acUkNo;
          }
          setAllocationtable(AllocationData.allocationList);
        setFormData(AllocationData.allocation);
          setSelectedemEMNO(AllocationData.clear_DESC); //ad_EM_NM
          setac_FR_CY(AllocationData.ac_FR_CY);
          setac_SA_TE(AllocationData.ac_SA_TE);
          setac_ST_US(AllocationData.allocation.ac_ST_US);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    // handleSearch();
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/Allocation/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value ,index) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const fieldName=(FormData && FormData.acUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
    Allocationtable[index][fieldName]=value;
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({  ...prevData, [name]: value, }));
    setac_FR_CY(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    // setFormData({ ...FormData, [name]: value });
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setac_SA_TE(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setac_ST_US(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/Allocation/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const AllocationData = response.data;
        setAllocationtable(AllocationData.allocationList);
        setFormData(AllocationData.allocation);
        setSelectedemEMNO(AllocationData.allocation.clear_DESC); //ad_EM_NM
        setac_FR_CY(response.data.allocation.ac_FR_CY);
        setac_SA_TE(response.data.allocation.ac_SA_TE);
        setac_ST_US(response.data.allocation.ac_ST_US);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [ac_DO_NO, setac_DO_NO] = useState("");
  var requestBodyInput = {};
  function values () {
      const allocation = {
          acUkNo: FormData.acUkNo,
        ac_DO_NO: document.getElementById("ac_DO_NO").value,
        ac_DO_TY: document.getElementById("ac_DO_TY").value,
        ac_DO_CO: document.getElementById("ac_DO_CO").value,
        ac_FR_DT: FormData.ac_FR_DT,
        ac_TO_DT: FormData.ac_TO_DT,
        ac_FR_CY: FormData.ac_FR_CY,
        ac_EX_PL: document.getElementById("ac_EX_PL").value,
        ac_SA_TE: FormData.ac_SA_TE,
        ac_GL_DT: FormData.ac_GL_DT,
        ac_CL_AC: document.getElementById("ac_CL_AC").value,
        ac_ST_US: FormData.ac_ST_US,
      };
      const table = document.getElementById("Allocationtable");
      const totalRowCount = table.tBodies[0].rows.length;
      const allocationList = [];
      for (let i = 0; i < totalRowCount; i++) {  
        const Details = {
          acUkNo: document.getElementById("acUkNo_" + i).value,
          ac_AC_NO: document.getElementById("ac_AC_NO_" + i).value,
          acnt_DESC: document.getElementById("acnt_DESC_" + i).value,
          ac_CO_NM: document.getElementById("ac_CO_NM_" + i).value,
          ac_OB_AC: document.getElementById("ac_OB_AC_" + i).value,
          ac_OB_SB: document.getElementById("ac_OB_SB_" + i).value,
          ac_AL_PR: document.getElementById("ac_AL_PR_" + i).value,
        };
        allocationList.push(Details);
      }
      requestBodyInput = {
        allocation,
        allocationList,
      };
  };
  const handleFormSubmit = () => {
    values();
    const orNo = document.getElementById("ac_DO_NO").value;
    const ukNo = FormData.acUkNo;
    if (orNo) {
      if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/Allocation/Update`;
      if (confirm) {
        axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/Allocation/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
      } else {
        const FR_DT = FormData.ac_FR_DT;
        if(FR_DT){
          const currentYear = FR_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${ac_DO_TY}/${secondHalf}/${company}/${firstHalf}`;  
          const finalUrl1 = `${urlNextNumber}/${parameters}`;
          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            setac_DO_NO(resp.dn_DO_NO);
              document.getElementById("ac_DO_NO").value = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/Allocation/Add`, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
      }
  };
  const handleConfirm = () => {    
      values();
      const orNo = document.getElementById("ac_DO_NO").value;
      const ukNo = FormData.acUkNo;
      if (orNo) {
        if (ukNo) {
          const confirm = window.confirm("Do you want to Confirm ?");
          const ConfirmUrl = `${domain}/api/Allocation/ConfirmAllocation`;
          if (confirm) {
            axios.post(ConfirmUrl, requestBodyInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error updating data:", error);
              });
          }
        } 
      }
  };
  const handleRun =  async () => {
    const  Url = `${domain}/api/Allocation/RunAllocation`
    const request = {
      "allocation": {
        "ac_DO_NO": document.getElementById("ac_DO_NO").value,
        "ac_DO_TY": document.getElementById("ac_DO_TY").value,
        "ac_DO_CO": document.getElementById("ac_DO_CO").value,
        "ac_CL_AC": document.getElementById("ac_CL_AC").value,
          "ac_FR_CY": FormData.ac_FR_CY,
          "ac_SA_TE": FormData.ac_SA_TE,
          "ac_GL_DT": FormData.ac_GL_DT,
      },
      "allocationList": [ { } ]
  }
    axios.post(Url, request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      handleResponse(resp.data);
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
       
      {/* <Tooltip target=".confirm-button" position="bottom" mouseTrack>
        <div style={{ background: 'white !important', color: 'white', fontSize: '10px' }}>
          Confirm</div>
      </Tooltip>
      <Button
        style={confirmButtonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 confirm-button"
        onClick={handleConfirm}
        disabled={!isRecordSelected}
      ></Button> */}
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ac_DO_NO"] ? filters["ac_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ac_DO_NO",
                        e.target.value,
                        filters["ac_DO_NO"]
                          ? filters["ac_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ac_DO_TY"] ? filters["ac_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ac_DO_TY",
                        e.target.value,
                        filters["ac_DO_TY"]
                          ? filters["ac_DO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ac_DO_CO"] ? filters["ac_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ac_DO_CO",
                        e.target.value,
                        filters["ac_DO_CO"]
                          ? filters["ac_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ac_FR_DT"] ? filters["ac_FR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ac_FR_DT",
                        e.target.value,
                        filters["ac_FR_DT"]
                          ? filters["ac_FR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["ac_TO_DT"] ? filters["ac_TO_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ac_TO_DT",
                        e.target.value,
                        filters["ac_TO_DT"]
                          ? filters["ac_TO_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ac_FR_CY"] ? filters["ac_FR_CY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ac_FR_CY",
                        e.target.value,
                        filters["ac_FR_CY"]
                          ? filters["ac_FR_CY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ac_EX_PL"] ? filters["ac_EX_PL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ac_EX_PL",
                        e.target.value,
                        filters["ac_EX_PL"]
                          ? filters["ac_EX_PL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ac_SA_TE"] ? filters["ac_SA_TE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ac_SA_TE",
                        e.target.value,
                        filters["ac_SA_TE"]
                          ? filters["ac_SA_TE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ac_CL_AC"] ? filters["ac_CL_AC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ac_CL_AC",
                        e.target.value,
                        filters["ac_CL_AC"]
                          ? filters["ac_CL_AC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["clear_DESC"] ? filters["clear_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "clear_DESC",
                        e.target.value,
                        filters["clear_DESC"]
                          ? filters["clear_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ac_ST_US"] ? filters["ac_ST_US"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ac_ST_US",
                        e.target.value,
                        filters["ac_ST_US"]
                          ? filters["ac_ST_US"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Doc. No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>From Date</th>
                <th style={tableHeaderStyle}>To Date</th>
                <th style={tableHeaderStyle}>Frequency</th>
                <th style={tableHeaderStyle}>Explanation</th>
                <th style={tableHeaderStyle}>State</th>
                <th style={tableHeaderStyle}>Clearing Account</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.acUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.acUkNo}
                        checked={selectedRows.includes(rowData.acUkNo)}
                        onChange={() => handleRowSelect(rowData.acUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ac_DO_NO}</td>
                    <td style={tableCellStyle}>{rowData.ac_DO_TY}</td>
                    <td style={tableCellStyle}>{rowData.ac_DO_CO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.ac_FR_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.ac_TO_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.ac_FR_CY}</td>
                    <td style={tableCellStyle}>{rowData.ac_EX_PL}</td>
                    <td style={tableCellStyle}>{rowData.ac_SA_TE}</td>
                    <td style={tableCellStyle}>{rowData.ac_CL_AC}</td>
                    <td style={tableCellStyle}>{rowData.clear_DESC}</td>
                    <td style={tableCellStyle}>{rowData.ac_ST_US}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <AllocationDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        ac_ST_US={ac_ST_US}
        ac_SA_TE={ac_SA_TE}
        ac_FR_CY={ac_FR_CY}
        ac_DO_TY={ac_DO_TY}
        ac_DO_CO={ac_DO_CO}
        handleConfirm={handleConfirm}
        handleRun={handleRun}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        Allocationtable={Allocationtable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
  
      />
    </div>
  );
};

export default AllocationMain;
