import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import PriceAdjustmentDefinitionDialog from "./PriceAdjustmentDefinitionDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import { Form } from "react-bootstrap";
const initialValue = {
  adUkNo:"",
  ad_AD_NM:"",
  ad_AD_LV:"",
  ad_IT_GP:"",
  ad_CU_GP:"",
  ad_PR_EF:"",    
};

const initialFilters = {
  ad_AD_NM: { value: "", operation: "" },
  ad_AD_LV: { value: "", operation: "" },
  ad_IT_GP: { value: "", operation: "" },
  ad_CU_GP: { value: "", operation: "" },
  ad_PR_EF: { value: "", operation: "" },    
};

const PriceAdjustmentDefinitionMain = () => {
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [LinelevelChecked, setLinelevelChecked] = useState(false);
  const [BasketChecked, setBasketChecked] = useState(false);
  const [OrderChecked, setOrderChecked] = useState(false);
  const [VolumeChecked, setVolumeChecked] = useState(false);
  const [ad_AD_NM, setad_AD_NM] = useState("");
  const [ad_AD_LV, setad_AD_LV] = useState("");
  const [ad_LV_BR, setad_LV_BR] = useState("");
  const [ad_IT_GP, setad_IT_GP] = useState("");
  const [ad_CU_GP, setad_CU_GP] = useState("");
  const [ad_PR_EF, setad_PR_EF] = useState("");
  const [ad_AD_TY, setad_AD_TY] = useState("");
  const [ad_GL_CD, setad_GL_CD] = useState("");
  const [ad_CO_NO, setad_CO_NO] = useState("");

  const [QuantityChecked, setQuantityChecked] = useState(false);
  const [AmountChecked, setAmountChecked] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setLinelevelChecked(null);
    setBasketChecked(null);
    setOrderChecked(null);
    setVolumeChecked(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/PriceAdjustmentDefinition/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const PriceAdjustmentDefinitionData = response.data;
          delete PriceAdjustmentDefinitionData.adUkNo;
          setFormData(PriceAdjustmentDefinitionData);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    setad_CO_NO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PriceAdjustmentDefinition/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/PriceAdjustmentDefinition/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const PriceAdjustmentDefinitionData = response.data;
        setFormData(PriceAdjustmentDefinitionData);
        setad_AD_NM(PriceAdjustmentDefinitionData.ad_AD_NM);
        setad_AD_LV(PriceAdjustmentDefinitionData.ad_AD_LV);
        setad_LV_BR(PriceAdjustmentDefinitionData.ad_LV_BR);
        setad_IT_GP(PriceAdjustmentDefinitionData.ad_IT_GP);
        setad_CU_GP(PriceAdjustmentDefinitionData.ad_CU_GP);
        setad_PR_EF(PriceAdjustmentDefinitionData.ad_PR_EF);
        setad_AD_TY(PriceAdjustmentDefinitionData.ad_AD_TY);
        setad_GL_CD(PriceAdjustmentDefinitionData.ad_GL_CD);
        if (PriceAdjustmentDefinitionData.ad_AD_LV === 'LN') {
          setLinelevelChecked(true);
          setBasketChecked(false);
          setOrderChecked(false);
          setVolumeChecked(false);
          setad_AD_LV('LN');
        } else if (PriceAdjustmentDefinitionData.ad_AD_LV === 'BL') {
          setLinelevelChecked(false);
          setBasketChecked(true);
          setOrderChecked(false);
          setVolumeChecked(false);
          setad_AD_LV('BL');
        } else if (PriceAdjustmentDefinitionData.ad_AD_LV === 'OR') {
          setLinelevelChecked(false);
          setBasketChecked(false);
          setOrderChecked(true);
          setVolumeChecked(false);
          setad_AD_LV('OR');
        } else if (PriceAdjustmentDefinitionData.ad_AD_LV === 'VL') {
          setLinelevelChecked(false);
          setBasketChecked(false);
          setOrderChecked(false);
          setVolumeChecked(true);
          setad_AD_LV('VL');
        } else {
          setLinelevelChecked(false);
          setBasketChecked(false);
          setOrderChecked(false);
          setVolumeChecked(false);
          setad_AD_LV('');
        }
        // level break up
        if (PriceAdjustmentDefinitionData.ad_LV_BR === 'QT') {
          setQuantityChecked(true);
          setAmountChecked(false);
          setad_LV_BR('QT');
        } else if (PriceAdjustmentDefinitionData.ad_LV_BR === 'AM') {
          setAmountChecked(true);
          setQuantityChecked(false);
          setad_LV_BR('AM');
        } else {
          setAmountChecked(false);
          setQuantityChecked(false);
          setad_LV_BR('');
        }
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {
    if (FormData.adUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/PriceAdjustmentDefinition/Update`;
      if (confirm) {
        axios
          .put(update, FormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.ad_CO_NO = company;
      axios.post(`${domain}/api/PriceAdjustmentDefinition/Add`, FormData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ad_AD_NM"] ? filters["ad_AD_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_AD_NM",
                        e.target.value,
                        filters["ad_AD_NM"]
                          ? filters["ad_AD_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ad_AD_LV"] ? filters["ad_AD_LV"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_AD_LV",
                        e.target.value,
                        filters["ad_AD_LV"]
                          ? filters["ad_AD_LV"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ad_IT_GP"] ? filters["ad_IT_GP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_IT_GP",
                        e.target.value,
                        filters["ad_IT_GP"]
                          ? filters["ad_IT_GP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ad_CU_GP"] ? filters["ad_CU_GP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_CU_GP",
                        e.target.value,
                        filters["ad_CU_GP"]
                          ? filters["ad_CU_GP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ad_PR_EF"] ? filters["ad_PR_EF"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_PR_EF",
                        e.target.value,
                        filters["ad_PR_EF"]
                          ? filters["ad_PR_EF"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Adjustment Name</th>
                <th style={tableHeaderStyle}>Adjustment Level</th>
                <th style={tableHeaderStyle}>Item Group</th>
                <th style={tableHeaderStyle}>Customer Group</th>
                <th style={tableHeaderStyle}>Preference</th>            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.adUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.adUkNo}
                        checked={selectedRows.includes(rowData.adUkNo)}
                        onChange={() => handleRowSelect(rowData.adUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ad_AD_NM}</td>
                    <td style={tableCellStyle}>{rowData.ad_AD_LV}</td>
                    <td style={tableCellStyle}>{rowData.ad_IT_GP}</td>
                    <td style={tableCellStyle}>{rowData.ad_CU_GP}</td>
                    <td style={tableCellStyle}>{rowData.ad_PR_EF}</td>            
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <PriceAdjustmentDefinitionDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        LinelevelChecked={LinelevelChecked}
        setLinelevelChecked={setLinelevelChecked}
        BasketChecked={BasketChecked}
        setBasketChecked={setBasketChecked}
        OrderChecked={OrderChecked}
        setOrderChecked={setOrderChecked}
        VolumeChecked={VolumeChecked}
        setVolumeChecked={setVolumeChecked}
        ad_AD_LV={ad_AD_LV}
        setad_AD_LV={setad_AD_LV}
        ad_CO_NO={ad_CO_NO}
        QuantityChecked={QuantityChecked}
        setQuantityChecked={setQuantityChecked}
        AmountChecked={AmountChecked}
        setAmountChecked={setAmountChecked}
        ad_LV_BR={ad_LV_BR}
        setad_LV_BR={setad_LV_BR}
        ad_AD_NM={ad_AD_NM}
        setad_AD_NM={setad_AD_NM}
        ad_IT_GP={ad_IT_GP}
        setad_IT_GP={setad_IT_GP}
        ad_CU_GP={ad_CU_GP}
        setad_CU_GP={setad_CU_GP}
        ad_PR_EF={ad_PR_EF}
        setad_PR_EF={setad_PR_EF}
        ad_AD_TY={ad_AD_TY}
        setad_AD_TY={setad_AD_TY}
        ad_GL_CD={ad_GL_CD}
        setad_GL_CD={setad_GL_CD}
      />
    </div>
  );
};

export default PriceAdjustmentDefinitionMain;
