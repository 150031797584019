import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ParentCostSimulationDialog from "./ParentCostSimulationDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  phUkNo:"", 
  ph_CO_IT:"",
  item_DESC:"",
  ph_CO_UM:"",
  ph_CO_QT:"",
  ph_UN_CT:"",
  ph_CO_AM:"",
  ph_CO_CT:"",
  ph_ST_CD:"", 
};

const initialFilters = {
  ph_CO_IT: { value: "", operation: "" }, 
  item_DESC: { value: "", operation: "" }, 
  ph_CO_UM: { value: "", operation: "" }, 
  ph_CO_QT: { value: "", operation: "" }, 
  ph_UN_CT: { value: "", operation: "" }, 
  ph_CO_AM: { value: "", operation: "" }, 
  ph_CO_CT: { value: "", operation: "" }, 
  ph_ST_CD: { value: "", operation: "" },  
};

const ParentCostSimulationMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,rightalined,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,inputStyleH,tableCellStyleH,tableHeaderStyleH,inputStyle1,formatTime
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // drodpdowns
  const [ph_CO_CT, setph_CO_CT] = useState("");
  const [pm_IT_UM, setpm_IT_UM] = useState("");
  const [pm_ST_TY, setpm_ST_TY] = useState("");
  const [pm_ST_CD, setpm_ST_CD] = useState("");
  const [pm_OP_SQ, setpm_OP_SQ] = useState("");
  const [pl_WK_CT, setpl_WK_CT] = useState("");
  const [pl_OP_SQ, setpl_OP_SQ] = useState("");
  const [ch_CO_NO, setch_CO_NO] = useState("");
  
 // Advance Search
  const [SelectedItem, setSelectedItem ] = useState("");
  const [ParentCostSimulationTable, setParentCostSimulationTable] = useState([{}]);
  const [ParentCostSimulationTable1, setParentCostSimulationTable1] = useState([{}]);
  const [SelectedITNM, setSelectedITNM ] = useState("");
  
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleAddButtonClick = () => {
    setParentCostSimulationTable([{}]);
   // CostSimulationLabourTable([{}]);
    setFormData(initialValue);
    setOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setParentCostSimulationTable([{}]);
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);



  const handleDefault = async () => {
    setch_CO_NO(`${company}`)
    
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
           
            // if(fName === "SD_BS_CR"){
            //   setsd_BS_CR(dataDefault.versionIds[i].default_VALUE)
            // }
            // if(fName === "SD_OR_TY"){
            //   setsd_OR_TY(dataDefault.versionIds[i].default_VALUE)
            // }
        
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/CostSimulationParent/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value, index) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
    const fieldName=(FormData && FormData.phUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      ParentCostSimulationTable[index][fieldName]=value;
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/CostSimulationParent/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ParentCostSimulationData = response.data;
        setParentCostSimulationTable(ParentCostSimulationData.ParentCostSimulationList);

        setFormData(ParentCostSimulationData.ParentCostSimulationList[0]);
        setSelectedItem(ParentCostSimulationData.ParentCostSimulationList.item_DESC);
       // setsd_SH_DE(ParentCostSimulationData.sd_SH_DE); 
      
        setSelectedItem(ParentCostSimulationData.item_DESC); 

       // setsd_SH_QT(CashFlowData.purchaseOrderInterfaceList[0].sd_SH_QT);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/CostSimulationParent/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const ParentCostSimulationData = response.data;
          delete ParentCostSimulationData.ParentCostSimulations[0].rc_TR_NO;
          delete ParentCostSimulationData.ParentCostSimulations[0].phUkNo;
          for (let i = 0; i < ParentCostSimulationData.ParentCostSimulations.length; i++) {
            delete ParentCostSimulationData.ParentCostSimulations[i].phUkNo;
           // ParentCostSimulationData.ParentCostSimulations[i].rc_ST_CD = rc_ST_CD;
          }
          setParentCostSimulationTable(ParentCostSimulationData.ParentCostSimulations);
          setFormData(ParentCostSimulationData.ParentCostSimulations[0]);
         // setsd_SH_DE(ParentCostSimulationData.sd_SH_DE); 
         
          setSelectedItem(ParentCostSimulationData.item_DESC); 
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
var requestBody = {};
function values () {
  const costSimulationParent = {
    phUkNo: document.getElementById("phUkNo").value,
    ch_CO_IT: document.getElementById("ch_CO_IT").value,
    ph_CO_CT: FormData.ph_CO_CT,
    ph_CO_QT: FormData.ph_CO_QT,
    ph_UN_CT: FormData.ph_UN_CT,
    ph_CO_AM: FormData.ph_CO_AM,//document.getElementById("ph_CO_AM").value,
    ph_CO_NO: company,
  };
  const table = document.getElementById("CostSimulationMaterial");
  const totalRowCount = table.tBodies[0].rows.length;
  const costSimulationParentMaterialList = [];
  for (let i = 0; i < totalRowCount; i++) {
    const details = {
      pm_UK_NO : document.getElementById("pm_UK_NO_" + i).value,
       pm_IT_NM : document.getElementById("pm_IT_NM_" + i).value,
       pm_IT_UM : table.tBodies[0].rows[i].cells[3].children[0].children[1].children[0].value,
       pm_ST_TY : table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
       pm_IT_QT : document.getElementById("pm_IT_QT_" + i).value,
       pm_IT_QT : document.getElementById("pm_IT_QT_" + i).value,
       pm_IT_AM : document.getElementById("pm_IT_AM_" + i).value,
       pm_MT_AM : document.getElementById("pm_MT_AM_" + i).value,
       pm_MC_AM : document.getElementById("pm_MC_AM_" + i).value,
       pm_LB_AM : document.getElementById("pm_LB_AM_" + i).value,
       pm_OH_AM : document.getElementById("pm_OH_AM_" + i).value,
       pm_ST_CD : table.tBodies[0].rows[i].cells[12].children[0].children[1].children[0].value,
       pm_OP_SQ : table.tBodies[0].rows[i].cells[13].children[0].children[1].children[0].value,
       cm_CO_NO: company,
    };
    costSimulationParentMaterialList.push(details);
  }
  const table1 = document.getElementById("CostSimulationLabourTable");
  const totalRowCount1 = table1.tBodies[0].rows.length;
  const costSimulationParentLabourList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const PL_UK = document.getElementById("pl_UK_NO_" + i);
          const WK_CT = FormData['pl_WK_CT_'+i];
          const OP_SQ = FormData['pl_OP_SQ_'+i];
          const OP_DS = document.getElementById("pl_OP_DS_" + i);
          const ST_HR = document.getElementById("pl_ST_HR_" + i);
          const MC_HR = document.getElementById("pl_MC_HR_" + i);
          const LB_HR = document.getElementById("pl_LB_HR_" + i);
          const MC_CL = document.getElementById("pl_MC_CL_" + i);
          const LB_CL = document.getElementById("pl_LB_CL_" + i);
          const OH_CL = document.getElementById("pl_OH_CL_" + i);
          const MC_AM = document.getElementById("pl_MC_AM_" + i);
          const LB_AM = document.getElementById("pl_LB_AM_" + i); 
          const OH_AM = document.getElementById("pl_OH_AM_" + i);
          const tot_AMT = document.getElementById("tot_AMT_" + i);

          const SalesDrive = {
            pl_UK_NO: PL_UK.value,
            pl_WK_CT: WK_CT,
            pl_OP_SQ: OP_SQ,
            pl_OP_DS: OP_DS.value,
            pl_ST_HR: ST_HR.value,
            pl_MC_HR: MC_HR.value,
            pl_LB_HR: LB_HR.value,
            pl_MC_CL: MC_CL.value,
            pl_LB_CL: LB_CL.value,
            pl_OH_CL: OH_CL.value,
            pl_MC_AM: MC_AM.value,
            pl_LB_AM: LB_AM.value,
            pl_OH_AM: OH_AM.value,
            tot_AMT: tot_AMT.value,
            CL_CO_NO :company,
          };
          costSimulationParentLabourList.push(SalesDrive);
  }
  requestBody = {
    costSimulationParent,
    costSimulationParentMaterialList,
    costSimulationParentLabourList,
    
  };
}
const handleFormSubmit = () => {
  values();
  const orNo = FormData.rc_TR_NO;
  const ukNo = FormData.phUkNo;
  if (orNo) {
    if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/CostSimulationParent/Update`;
  
      if (confirm) {
        axios.put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/CostSimulationParent/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  } 
  };



const handleConfirm =  async () => {
  const  Url = `${domain}/api/ParentCostSimulation/RNCNConfirmDetails`
  const request = {
    "rc_TR_NO": FormData.rc_TR_NO,
    "rc_TR_TY": document.getElementById("rc_TR_TY").value,
    "ch_CO_NO": document.getElementById("ch_CO_NO").value,
  }
  axios.post(Url, request, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    handleResponse(resp.data);
  })
  .catch((error) => {
    console.error("Error adding data:", error);
  });
};
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_CO_IT"] ? filters["ph_CO_IT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_CO_IT",
                        e.target.value,
                        filters["ph_CO_IT"]
                          ? filters["ph_CO_IT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["item_DESC"] ? filters["item_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "item_DESC",
                        e.target.value,
                        filters["item_DESC"] ? filters["item_DESC"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_CO_UM"] ? filters["ph_CO_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_CO_UM",
                        e.target.value,
                        filters["ph_CO_UM"]
                          ? filters["ph_CO_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_CO_QT"] ? filters["ph_CO_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_CO_QT",
                        e.target.value,
                        filters["ph_CO_QT"] ? filters["ph_CO_QT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_UN_CT"] ? filters["ph_UN_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_UN_CT",
                        e.target.value,
                        filters["ph_UN_CT"]
                          ? filters["ph_UN_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["ph_CO_AM"] ? filters["ph_CO_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_CO_AM",
                        e.target.value,
                        filters["ph_CO_AM"]
                          ? filters["ph_CO_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ph_CO_CT"] ? filters["ph_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_CO_CT",
                        e.target.value,
                        filters["ph_CO_CT"]
                          ? filters["ph_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ph_ST_CD"] ? filters["ph_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_ST_CD",
                        e.target.value,
                        filters["ph_ST_CD"]
                          ? filters["ph_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
              
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Parent Item</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>UM</th>   
                <th style={tableHeaderStyle}>Quantity</th> 
                <th style={tableHeaderStyle}>Unit Cost</th> 
                <th style={tableHeaderStyle}>Amount</th> 
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Status</th>  


                          
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.phUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.phUkNo}
                        checked={selectedRows.includes(rowData.phUkNo)}
                        onChange={() => handleRowSelect(rowData.phUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ph_CO_IT}</td>
                    <td style={tableCellStyle}>{rowData.item_DESC}</td>
                    <td style={tableCellStyle}>{rowData.ph_CO_UM}</td>
                    <td style={tableCellStyle}>{rowData.ph_CO_QT}</td>
                    <td style={tableCellStyle}>{rowData.ph_UN_CT}</td>
                    <td style={tableCellStyle}>{rowData.ph_CO_AM}</td>
                    <td style={tableCellStyle}>{rowData.ph_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.ph_ST_CD}</td> 



                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <ParentCostSimulationDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        ParentCostSimulationTable={ParentCostSimulationTable}
        setParentCostSimulationTable={setParentCostSimulationTable}
        ParentCostSimulationTable1={ParentCostSimulationTable1}
        setParentCostSimulationTable1={setParentCostSimulationTable1}
     
        SelectedItem={SelectedItem}
        setSelectedItem={setSelectedItem}
        
        SelectedITNM={SelectedITNM}
        setSelectedITNM={setSelectedITNM}
        ph_CO_CT={ph_CO_CT}
        setph_CO_CT={setph_CO_CT}
        pm_IT_UM={pm_IT_UM}
        setpm_IT_UM={setpm_IT_UM}
        pm_ST_TY={pm_ST_TY}
        setpm_ST_TY={setpm_ST_TY}
        pm_ST_CD={pm_ST_CD}
        setpm_ST_CD={setpm_ST_CD}
        pm_OP_SQ={pm_OP_SQ}
        setpm_OP_SQ={setpm_OP_SQ}
        pl_WK_CT={pl_WK_CT}
        setpl_WK_CT={setpl_WK_CT}
        pl_OP_SQ={pl_OP_SQ}
        setpl_OP_SQ={setpl_OP_SQ}
        ch_CO_NO={ch_CO_NO}
        setch_CO_NO={setch_CO_NO}
      />
    </div>
  );
};

export default ParentCostSimulationMain;
