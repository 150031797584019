import React, { useState, useEffect, useRef} from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { faLink, faPaperclip, faFileImage, faBold, faItalic, faUnderline } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { } from '@fortawesome/free-solid-svg-icons';

const EmailPopup = ({visible, onClose, av_CC_NO,smtpServer,smtpPort,password, av_FR_ML, av_TO_ML}) => {
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [EmailData, setEmailData]=useState({});
  const {
    ab_AD_NM,
    av_TE_XT,
    av_EM_SB,
    cc_UK_NO,
    carbonCopys,
    attachments,
  } = EmailData;
  const [ShowEmailPopup, setShowEmailPopup] = useState(false);

  const handleClose = () => {
    onClose();
    setShowEmailPopup(false);
  };
  useEffect(() => {
    const modal = document.getElementById("emailModal");
    if (visible) {
      modal.classList.add("show");
      modal.style.display = "block";
    } else {
      modal.classList.remove("show");
      modal.style.display = "none";
    }
  }, [visible]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const handleFileSelection = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setEmailData({ ...EmailData, [name]: value });
  };

  const sendEmail = () => {
    handleFormEmail();
    onClose();  
    setShowEmailPopup(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
    if (errorMessage.length === 0) {
      handleClose();
    }
  };
 
  const handleFormEmail = async () => {

    const m = document.getElementById("carbonCopys").value;
    var m_array = [];
    if(m){
      m_array = m.split(',');
    }
    const a = document.getElementById("attachments").value;
    var a_array = [];
    if(a){
      a_array = a.split(',');
    }
    const inputEmail ={
      "av_CC_NO" : document.getElementById("av_CC_NO").value,
      "av_FR_ML" : av_FR_ML,
      "av_TO_ML" : av_TO_ML,
      "av_EM_SB" : document.getElementById("av_EM_SB").value,
      "av_TE_XT" : document.getElementById("av_TE_XT").value,
      "smtpServer" : smtpServer,
      "password" : password,
      "smtpPort" : smtpPort,
      "carbonCopy" : m_array,
      "attachment" : a_array
    };

    try {
      const response = await axios.post(
        `${domain}/api/Email/SendEmail`,inputEmail,
        {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

    const data = response.data;
    if(data){
      setEmailData(data);
      handleResponse(data);
    }
  }
  catch (error) {
      console.error("Error fetching data:", error);
    }
};

const isUpdateMode = Boolean(cc_UK_NO);
const productDialogFooter = (
  <React.Fragment>
  <Button 
  style={{width:"20%"}}
  icon="pi pi-times" 
  variant="outlined" 
  onClick={handleClose}
  tooltip="Cancel"
  >
  </Button>
  <Button   
  style={{width:"20%"}}
  icon="pi pi-check" 
  onClick={sendEmail} 
  tooltip={isUpdateMode ? "Update" : "Save"}>
  </Button>
</React.Fragment>
);


  return (
    <div
    className="modal fade"
    id="emailModal"
    tabIndex="-1"
    aria-labelledby="noteModalLabel"
    aria-hidden="true"
    header={cc_UK_NO ? "Compose Email" : "Compose Email"}
    footer={productDialogFooter}
  >
  <div>
    <Toast ref={toast} />
    <div className="modal-dialog" style={{ maxWidth: "50%",marginTop:"5%",marginLeft:"25%" }}>
        <div className="modal-content" style={{backgroundColor:"#f9fbff"}}>
          <div className="modal-header" 
          style={{ 
            backgroundColor: "aliceblue", 
            display: "flex", 
            justifyContent: "space-between"
             }}>
            <h5 className="modal-title" id="noteModalLabel">
             Compose Email
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">
          <div className="field row" style={{display:"none"}}>
          <label htmlFor="av_FR_ML" className="font-bold">
          From
          </label>
          <InputText
              id="av_FR_ML"
              name="av_FR_ML"
              value={av_FR_ML}
              readOnly
              //onChange={(Event) => onchangedata(Event)}
            />
        </div>

        <div className="field row">
          <label htmlFor="av_TO_ML" className="font-bold">
           To
          </label>
          <InputText
              id="av_TO_ML"
              name="av_TO_ML"
              defaultValue={av_TO_ML}
              readOnly
              //onChange={(Event) => onchangedata(Event)}
            />
        </div>
        <div className="field row">
          <label htmlFor="carbonCopys" className="font-bold">
          Cc
          </label>
          <InputText
              id="carbonCopys"
              name="carbonCopys"
              value={carbonCopys}
              onChange={(Event) => onchangedata(Event)}
            />
        </div>
        <div className="field row">
          <label htmlFor="av_EM_SB" className="font-bold">
         Subject
          </label>
          <InputText
              id="av_EM_SB"
              name="av_EM_SB"
              value={av_EM_SB}
              onChange={(Event) => onchangedata(Event)}
            />
        </div>
        <div className="field row">
          <label htmlFor="av_TE_XT" className="font-bold">
        Body
          </label>
          <textarea 
            style={{ marginBottom: '10px',width:"100%",height:"150px" }}
              id="av_TE_XT"
              name="av_TE_XT"
              value={av_TE_XT}
              onChange={(Event) => onchangedata(Event)}
            />
        </div>
        <div className="field row">
          <label htmlFor="attachments" className="font-bold">
          Attachment
          </label>
          <InputText
          style={{display:"none"}}
              id="attachments"
              name="attachments"
              value={attachments}
              onChange={(Event) => onchangedata(Event)}
            />
             <FontAwesomeIcon icon={faPaperclip} onClick={handleFileSelection} style={{marginLeft:"-48%"}}/>
        </div>
        <div className="field row" style={{display:"none"}}>
          <label htmlFor="smtpServer" className="font-bold">
        Host
          </label>
          <InputText
              id="smtpServer"
              name="smtpServer"
              value={smtpServer}
              onChange={(Event) => onchangedata(Event)}
            />
        </div>
        <div className="field row" style={{display:"none"}}>
          <label htmlFor="smtpPort" className="font-bold">
        Port
          </label>
          <InputText
              id="smtpPort"
              name="smtpPort"
              value={smtpPort}
              onChange={(Event) => onchangedata(Event)}
            />
        </div>
        <div className="field row" style={{display:"none"}}>
          <label htmlFor="password" className="font-bold">
          Password
          </label>
          <InputText
              id="password"
              name="password"
              value={password}
              onChange={(Event) => onchangedata(Event)}
            />
        </div>
        <div className="field row" style={{display:"none"}}>
          <label htmlFor="av_CC_NO" className="font-bold">
          Contact NO
          </label>
          <InputText
              id="av_CC_NO"
              name="av_CC_NO"
              value={av_CC_NO}
              onChange={(Event) => onchangedata(Event)}
            />
        </div>
        <div className="field row" style={{display:"none"}}>
          <label htmlFor="ab_AD_NM" className="font-bold">
          Thanks & Regards
          </label>
          <InputText
              id="ab_AD_NM"
              name="ab_AD_NM"
              value={EmailData.ab_AD_NM}
              onChange={(Event) => onchangedata(Event)}
            />
        </div>
        {/* <div className="modal-footer">
            <Button
              style={{ width: "20%" }}
              icon="pi pi-times"
              variant="outlined"
              onClick={handleClose}
              tooltip="Cancel"
            />
            <Button
              style={{ width: "20%" }}
              icon="pi pi-check"
              onClick={sendEmail}
              tooltip="Save"
            />
          </div> */}
          </div>
          <div className="input-group-append">
          {selectedFiles.map((file, index) => (
          <div key={index}
          style={{backgroundColor:"aliceblue"}}
          >{file.name}</div>
            ))}
             <div style={{display:"flex",width:"30%",justifyContent:"space-between"}}>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
              multiple
            />
            {/* <FontAwesomeIcon icon={faBold} />
            <FontAwesomeIcon icon={faItalic} />
            <FontAwesomeIcon icon={faUnderline} />
            <FontAwesomeIcon icon={faLink} />
            <FontAwesomeIcon icon={faFileImage} /> */}
            {/* <FontAwesomeIcon icon={faPaperclip} onClick={handleFileSelection}/> */}
            </div>
            <div className="modal-footer">
            <Button
              icon="pi pi-times"
              variant="outlined"
              onClick={handleClose}
              tooltip="Cancel"
            />
            <Button
              icon="pi pi-check"
              onClick={sendEmail}
              tooltip="Save"
            />
          </div>
          </div>
      </div>
      </div>
</div>
</div>
  );
};

export default EmailPopup;
