import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function UnitInformationDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  // onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  addRow,
  deleteRow,
  UnitInformationtable,
  setUnitInformationtable,
  ui_CO_NO,
  ui_BL_NO,
  setui_BL_NO,
  ui_FL_NO,
  setui_FL_NO,
  setFormData
}) {
  const { 
  uiUkNo,
  ui_UN_NO,
  ui_UN_TY,
  ui_UN_ST,
  ui_UN_US,
  bu_DESC,
  ui_PR_NO,
  FL_DESC,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter,fetchCostCenterP } = Costcenter();

  const [ui_FL_NOOptions, setui_FL_NOOptions] = useState([]);
  const [ui_UN_TYOptions, setui_UN_TYOptions] = useState([]);
  const [ui_UN_STOptions, setui_UN_STOptions] = useState([]);
  const [ui_UN_USOptions, setui_UN_USOptions] = useState([]);
  const [ui_AR_TYOptions, setui_AR_TYOptions] = useState([]);
  const [ui_TR_UMOptions, setui_TR_UMOptions] = useState([]);
  const [ui_PR_NOOptions, setui_PR_NOOptions] = useState([]);
  const [ui_BL_NOOptions, setui_BL_NOOptions] = useState([]);

  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const handleFormDataChange2 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData2(name, value, index);
  };
  const handleFormDataChange3= (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData3(name, value, index);
  };
  const handleFormDataChange4 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData4(name, value, index);
  };
  const handleFormDataChange5 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData5(name, value, index);
  };
  const handleFormDataChange6 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData6(name, value, index);
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "rc_ST_DT"  || name === "rc_EN_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);
  
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };

  useEffect(() => {
    fetchprpty();
    fetchblding();
    fetchflor();
    fetchUNTY();
    fetchUNUSAGE();
    fetchUNUSAGE();
    fetchstatus();
    fetchareatype(); 
    fetchum();   
  }, []);

  const fetchprpty = async () => {
    const Options = await fetchCostCenterP("PT");
    setui_PR_NOOptions(Options);
  };

  const fetchblding = async () => {
    const Options = await fetchCostCenterP("BL");
    setui_BL_NOOptions(Options);
  };

  const fetchflor = async () => {
    const Options = await fetchClassCodeDS("FL/IN");
    setui_FL_NOOptions(Options);
  };

  const fetchUNTY = async () => {
    const Options = await fetchClassCodeDS("UN/TY");
    setui_UN_TYOptions(Options);
  };

  const fetchUNUSAGE = async () => {
    const Options = await fetchClassCodeDS("UN/US");
    setui_UN_USOptions(Options);
  };
  const fetchstatus = async () => {
    const Options = await fetchClassCodeDS("UN/ST");
    setui_UN_STOptions(Options);
  };

  const fetchareatype = async () => {
    const Options = await fetchClassCodeDS("BL/AR");
    setui_AR_TYOptions(Options);
  };

  const fetchum = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setui_TR_UMOptions(Options);
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

// buildinf filer
const handlebyproperty = async (v) => {
  const Url = `${domain}/api/PropertyBuildingInformation/GetBYProperty`;
  const requestData={
    "bi_PR_NO": v,
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataprty = response.data.Result;
    const options = dataprty.map((item) => ({
      label: item.bu_DESC,
      value: item.bi_BL_NO,
    }));
    setui_BL_NOOptions(options);
    
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  };

  // floor filter
  const handlebyBuilding = async (b) => {
    setUnitInformationtable([{}]);
    const Url = `${domain}/api/FloorInformation/GetFloorDtlsUnitInfo`;
    const requestData={
      "fi_PR_NO": ui_PR_NO,
      "fi_BL_NO": b,
    }
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const floorDetails = response.data.outList;
  
      if (floorDetails && floorDetails.length > 0) {
        const options = floorDetails.map(floor => ({
          label: floor.floor_DESC, 
          value: floor.fi_FL_NO,  
        }));
        setui_FL_NOOptions(options);   
      } else {
        console.error("No floor details found for the selected building:", floorDetails);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

const isUpdateMode = Boolean(uiUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{uiUkNo ? "Revise Unit Information" : "Add Unit Information"}</h5>
    <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
        tooltip={isUpdateMode ? "Update" : "Submit"}
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
            <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ui_PR_NO" className="font-bold">
                Property
                </label>
                <Dropdown
                  id="ui_PR_NO"
                  name="ui_PR_NO"
                  value={ui_PR_NO}
                  options={ui_PR_NOOptions}
                  onChange={(e) => {onchangeDropDownFormData7("ui_PR_NO", e.value);
                  handlebyproperty(e.value);}}
                  placeholder="Select Property"
                /> 
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ui_BL_NO" className="font-bold">
                Building
                </label>
                <Dropdown
                  id="ui_BL_NO"
                  name="ui_BL_NO"
                  value={ui_BL_NO}
                  options={ui_BL_NOOptions}
                  onChange={(e) => {
                  onchangeDropDownFormData8("ui_BL_NO", e.value);
                  handlebyBuilding(e.value);
                }}
                  placeholder="Select Building"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ui_CO_NO" className="font-bold">
                Company
                </label>
                <InputText disabled
                  id="ui_CO_NO"
                  name="ui_CO_NO"
                  value={ui_CO_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
            </div>
        </div>
      </div>
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="UnitInformationtable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Floor</th>
          <th>Units</th>
          <th>Unit Type</th>
          <th>Unit Usage</th>
          <th>Unit Status</th>
          <th>Area Type</th>
          <th>Area</th>
          <th>UM</th>         
        </tr>
      </thead>
      <tbody>
      {UnitInformationtable.map((row, index) => (
        <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
              id={`uiUkNo_${index}`}
              name={`uiUkNo_${index}`}
              defaultValue={row.uiUkNo}
            />
          </td>
          <td>
            <Dropdown
              id={`ui_FL_NO_${index}`}
              name={`ui_FL_NO_${index}`}
              value={data[`ui_FL_NO_${index}`] ? data[`ui_FL_NO_${index}`]:row.ui_FL_NO }
              options={ui_FL_NOOptions}
              onChange={(e) => handleFormDataChange1(`ui_FL_NO_${index}`, e.value)}
              placeholder="Select Floor"
            />
          </td>
          <td >
          <InputText 
              id={`ui_UN_NO_${index}`}
              name={`ui_UN_NO_${index}`}
              defaultValue={row.ui_UN_NO}
            />
            
          </td>
          <td>
            <Dropdown
              id={`ui_UN_TY_${index}`}
              name={`ui_UN_TY_${index}`}
              value={data[`ui_UN_TY_${index}`] ? data[`ui_UN_TY_${index}`]:row.ui_UN_TY }
              options={ui_UN_TYOptions}
              onChange={(e) => handleFormDataChange2(`ui_UN_TY_${index}`, e.value)}
              placeholder="Select Unit Type"
            />
          </td>
          <td>
            <Dropdown
              id={`ui_UN_US_${index}`}
              name={`ui_UN_US_${index}`}
              value={data[`ui_UN_US_${index}`] ? data[`ui_UN_US_${index}`]:row.ui_UN_US }
              options={ui_UN_USOptions}
              onChange={(e) => handleFormDataChange3(`ui_UN_US_${index}`, e.value)}
              placeholder="Select Unit Usage"
            />
          </td>
          <td>
            <Dropdown
              id={`ui_UN_ST_${index}`}
              name={`ui_UN_ST_${index}`}
              value={data[`ui_UN_ST_${index}`] ? data[`ui_UN_ST_${index}`]:row.ui_UN_ST }
              options={ui_UN_STOptions}
              onChange={(e) => handleFormDataChange4(`ui_UN_ST_${index}`, e.value)}
              placeholder="Select Unit Status"
            />
          </td>
            <td>
            <Dropdown
              id={`ui_AR_TY_${index}`}
              name={`ui_AR_TY_${index}`}
              value={data[`ui_AR_TY_${index}`] ? data[`ui_AR_TY_${index}`]:row.ui_AR_TY }
              options={ui_AR_TYOptions}
              onChange={(e) => handleFormDataChange5(`ui_AR_TY_${index}`, e.value)}
              placeholder="Select Area Type"
            />
          </td>
          <td>
          <InputText
            id={`ui_TR_QT_${index}`}
            name={`ui_TR_QT_${index}`}
            defaultValue={row.ui_TR_QT}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <Dropdown
              id={`ui_TR_UM_${index}`}
              name={`ui_TR_UM_${index}`}
              value={data[`ui_TR_UM_${index}`] ? data[`ui_TR_UM_${index}`]:row.ui_TR_UM }
              options={ui_TR_UMOptions}
              onChange={(e) => handleFormDataChange6(`ui_TR_UM_${index}`, e.value)}
              placeholder="Select UM"
            />
          </td>
          {/* <td>
          <Calendar style={{width:"150px"}}
              id={`ui_ST_DT_${index}`}
              name={`ui_ST_DT_${index}`}
              value={ row.ui_ST_DT ? moment(row.ui_ST_DT, "YYYYMMDD").toDate() : null }
              onChange={(e) => onDateChange({ target: { name: "ui_ST_DT", value: moment(e.value).format("YYYYMMDD") } })}
              showIcon
              dateFormat="dd/mm/yy"
            />
          </td>
          <td>
          <Calendar style={{width:"150px"}}
              id={`ui_EN_DT_${index}`}
              name={`ui_EN_DT_${index}`}
              value={ row.ui_EN_DT ? moment(row.ui_EN_DT, "YYYYMMDD").toDate() : null }
              onChange={(e) => onDateChange({ target: { name: "ui_EN_DT", value: moment(e.value).format("YYYYMMDD") } })}
              showIcon
              dateFormat="dd/mm/yy"
            />
          </td> */}
          
          </tr>
           ))}
      </tbody>
    </table>   
  </div>
      </div>   
    </Dialog>
  );
}
