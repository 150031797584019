import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import KioskDirectSalesDialog from "./KioskDirectSalesDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  shUkNo:"",
  sd_UK_NO:"",
  sh_OR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  sp_TR_CR:"",
  sh_BS_PR:"",
  sh_CU_NO:"",
  is_CO_CT:"",
  is_LT_NO:"",
  is_LO_CN:"",
  sp_EX_PR:"",
  sd_TX_AM:"",
  im_IT_DS:"",
  sd_OR_QT:"",
  sd_TR_UM:"",
  sd_UT_PR:"",
  sd_EX_PR:"",
  sd_DS_PR:"",
  sd_DS_AT:"",
  net_AMOUNT:"",
  sd_TX_PE:"",
  sp_PY_MT:"",
  sp_PY_AM:"",
  sp_TR_CR:"",
  sp_EX_RT:"",
  sh_DS_AT:"",
  Sales_Person:"",
};

const initialFilters = {
  cp_CP_NM: { value: "", operation: "" },
  cp_BI_NG: { value: "", operation: "" },
  cp_AC_ON: { value: "", operation: "" },
  cp_LO_CN: { value: "", operation: "" },
  cp_LN_GE: { value: "", operation: "" },
  cp_DM_PH: { value: "", operation: "" },
  cp_ST_DT: { value: "", operation: "" },
  cp_EN_DT: { value: "", operation: "" },
  cp_BU_GT: { value: "", operation: "" },
  cp_IM_ON: { value: "", operation: "" },
  cp_CL_CK: { value: "", operation: "" },
  cp_CN_CT: { value: "", operation: "" },
};

const KioskDirectSalesMain = () => {
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [sh_OR_NO, setsh_OR_NO] = useState("");
  const [sh_OR_TY, setsh_OR_TY] = useState("");
  // drodpdowns
  const [sh_CN_ID, setsh_CN_ID] = useState("");
  const [sd_TR_UM, setsd_TR_UM] = useState("");
  const [sp_TR_CR, setsp_TR_CR] = useState("");
  const [sh_BS_CR, setsh_BS_CR] = useState("");
  const [is_CO_CT, setis_CO_CT] = useState("");
  const [sh_CU_NO, setsh_CU_NO] = useState("");
  const [sh_EX_RT, setsh_EX_RT] = useState("");
  const [as_AD_SH, setas_AD_SH] = useState("");
  const [sh_DS_AT, setsh_DS_AT] = useState(0);
  const [sh_DS_PR, setsh_DS_PR] = useState(0);
  const [SelectedCustomer, setSelectedCustomer] = useState("");
  const [Image, setImage] = useState("");
  const [ItemTableData, setItemTableData] =useState([{}]);
  const [PaymentTableData, setPaymentTableData] =useState([{}]);
  const [finalDesc, setFinalDesc] = useState([]);
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setItemTableData([{}]);
    setPaymentTableData([{}]);
    setSelectedCustomer(null);
    setImage(null);
    setFinalDesc("");
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/KioskSales/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const KioskDirectSalesData = response.data;
          delete KioskDirectSalesData.shUkNo;
          setFormData(KioskDirectSalesData);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
    currencychnage(sp_TR_CR,FormData.sh_OR_DT);
  }, [currentPage, pageSize, filters]);

  const [sh_OR_CO , setsh_OR_CO ] = useState("");
 
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsp_TR_CR(value);
    currencychnage(value,FormData.sh_OR_DT);
  };
  const apicurr = `${domain}/api/ExChangeRate/Rate`
  const currencychnage = async (curr,orerdate) => {
    const requestData={
      "cr_FR_CR": curr,
      "cr_TO_CR": sh_BS_CR,
      "cr_EF_DT": orerdate,
    }
    try {
      const response = await axios.post(apicurr, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const datacur = response.data.Result;
      if (datacur) {
        onchangeFormData("sh_EX_RT", datacur.cr_CO_MR);
        setsh_EX_RT(datacur.cr_CO_MR);
      }
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            setsh_OR_CO(`${company}`);
            if(fName === "IS_CO_CT"){
              setis_CO_CT(response.data.versionIds[i].default_VALUE);
            }
            if(fName === "SH_BS_CR"){
              setsh_BS_CR(response.data.versionIds[i].default_VALUE);
            }
            if(fName === "SP_TR_CR"){
              onchangeDropDownFormData3("sp_TR_CR",dataDefault.versionIds[i].default_VALUE);
              setsp_TR_CR(response.data.versionIds[i].default_VALUE);
           }
           if(fName === "SH_CU_NO"){
            setsh_CU_NO(response.data.versionIds[i].default_VALUE);
          }
          if(fName === "AS_AD_SH"){
            setas_AD_SH(response.data.versionIds[i].default_VALUE);
          }
          if(fName === "SH_OR_TY"){
            setsh_OR_TY(response.data.versionIds[i].default_VALUE);
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/KioskSales/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
    if (name === "sh_DS_PR") {
      setsh_DS_PR(value);
    } else if (name === "sh_DS_AT") {
      setsh_DS_AT(value);
    }
  };
  const onchangeFormData = (name, value, index) => {
    setItemTableData(prevData => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [name]: value };
      return newData;
    });
  };
  
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/KioskDirectSales/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const KioskDirectSalesData = response.data;
        setFormData(KioskDirectSalesData);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values () {
    // header
      const salesHeader = {
        shUkNo: FormData.shUkNo,
        sh_OR_NO: document.getElementById("sh_OR_NO").value, 
        sh_OR_CO: company, 
        sh_OR_TY: document.getElementById("sh_OR_TY").value, 
        sh_OR_DT: FormData.sh_OR_DT,
        sh_BS_CR: document.getElementById("sh_BS_CR").value, 
        sh_CU_NO: document.getElementById("sh_CU_NO").value, 
        sh_DS_PR: document.getElementById("sh_DS_PR").value, 
        sh_EX_RT: document.getElementById("sh_EX_RT").value, 
        sh_DS_AT: document.getElementById("sh_DS_AT").value, 
      };
    // item table
    const table = document.getElementById("Itemtable");
    const totalRowCount = table.tBodies[0].rows.length;
    const salesDetail = [];
    for (let i = 0; i < totalRowCount; i++) {  
      const Details = {
        sd_LN_NO: document.getElementById("sd_LN_NO_" + i).value,
        sd_IT_NM: document.getElementById("sd_IT_NM_" + i).value,
        sd_OR_QT: document.getElementById("sd_OR_QT_" + i).value,
        sd_TR_UM: table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
        sd_UT_PR: document.getElementById("sd_UT_PR_" + i).value,
        sd_EX_PR: document.getElementById("sd_EX_PR_" + i).value,
        sd_DS_PR: document.getElementById("sd_DS_PR_" + i).value,
        sd_DS_AT: document.getElementById("sd_DS_AT_" + i).value,
        sd_TX_PE: document.getElementById("sd_TX_PE_" + i).value,
        sd_TX_AM: document.getElementById("sd_TX_AM_" + i).value,
      };
      salesDetail.push(Details);
    }
    // payment table
    const table1 = document.getElementById("PaymentTable");
    const totalRowCount1 = table1.tBodies[0].rows.length;
    const patientPayDetailsList= [];
    for (let i = 0; i < totalRowCount1; i++) {  
      const PayDetails = {
        sp_PY_MT: document.getElementById("sp_PY_MT_" + i).value,
        sp_PY_AM: document.getElementById("sp_PY_AM_" + i).value,
        sd_TR_UM: table1.tBodies[0].rows[i].cells[2].children[0].children[1].children[0].value,
        sp_EX_RT: document.getElementById("sp_EX_RT_" + i).value,
      };
      patientPayDetailsList.push(PayDetails);
    }
    requestBodyInput = {
      salesHeader,
      salesDetail,
      patientPayDetailsList,
    };
  };
  const handleFormSubmit = () => {
    values();
    const orNo = document.getElementById("sh_OR_NO").value;
    const ukNo = FormData.shUkNo;
    if (orNo) {
      if (ukNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/HospitalitySales/Update`;
        if (confirm) {
          axios.put(update, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
        } else {
          axios.post(`${domain}/api/HospitalitySales/Add`, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error adding data:", error);
            });
        }
      } else {
        const OR_DT = FormData.sh_OR_DT;
        if (OR_DT) {
          const currentYear = OR_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${sh_OR_TY}/${secondHalf}/1000/${firstHalf}`;
          const finalUrl1 = `${urlNextNumber}/${parameters}`;
          axios.get(finalUrl1, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              setsh_OR_NO(resp.dn_DO_NO);
              document.getElementById("sh_OR_NO").value = resp.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
              values();
              axios.post(`${domain}/api/HospitalitySales/Add`, requestBodyInput, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "http://localhost:3000",
                      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                    },
                  })
                  .then((resp) => {
                    handleResponse(resp.data);
                  })
                  .catch((error) => {
                    console.error("Error saving data:", error);
                  });
                }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      }
  };  
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_CP_NM"] ? filters["cp_CP_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_CP_NM",
                        e.target.value,
                        filters["cp_CP_NM"]
                          ? filters["cp_CP_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_BI_NG"] ? filters["cp_BI_NG"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_BI_NG",
                        e.target.value,
                        filters["cp_BI_NG"] ? filters["cp_BI_NG"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_AC_ON"] ? filters["cp_AC_ON"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_AC_ON",
                        e.target.value,
                        filters["cp_AC_ON"]
                          ? filters["cp_AC_ON"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_LO_CN"] ? filters["cp_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_LO_CN",
                        e.target.value,
                        filters["cp_LO_CN"]
                          ? filters["cp_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_LN_GE"] ? filters["cp_LN_GE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_LN_GE",
                        e.target.value,
                        filters["cp_LN_GE"]
                          ? filters["cp_LN_GE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_DM_PH"] ? filters["cp_DM_PH"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_DM_PH",
                        e.target.value,
                        filters["cp_DM_PH"]
                          ? filters["cp_DM_PH"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_ST_DT"] ? filters["cp_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_ST_DT",
                        e.target.value,
                        filters["cp_ST_DT"] ? filters["cp_ST_DT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_EN_DT"] ? filters["cp_EN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_EN_DT",
                        e.target.value,
                        filters["cp_EN_DT"]
                          ? filters["cp_EN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_BU_GT"] ? filters["cp_BU_GT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_BU_GT",
                        e.target.value,
                        filters["cp_BU_GT"]
                          ? filters["cp_BU_GT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_IM_ON"] ? filters["cp_IM_ON"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_IM_ON",
                        e.target.value,
                        filters["cp_IM_ON"]
                          ? filters["cp_IM_ON"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_CL_CK"] ? filters["cp_CL_CK"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_CL_CK",
                        e.target.value,
                        filters["cp_CL_CK"]
                          ? filters["cp_CL_CK"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_CN_CT"] ? filters["cp_CN_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_CN_CT",
                        e.target.value,
                        filters["cp_CN_CT"]
                          ? filters["cp_CN_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_BU_GT"] ? filters["cp_BU_GT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_BU_GT",
                        e.target.value,
                        filters["cp_BU_GT"]
                          ? filters["cp_BU_GT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_IM_ON"] ? filters["cp_IM_ON"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_IM_ON",
                        e.target.value,
                        filters["cp_IM_ON"]
                          ? filters["cp_IM_ON"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_CL_CK"] ? filters["cp_CL_CK"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_CL_CK",
                        e.target.value,
                        filters["cp_CL_CK"]
                          ? filters["cp_CL_CK"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_CN_CT"] ? filters["cp_CN_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_CN_CT",
                        e.target.value,
                        filters["cp_CN_CT"]
                          ? filters["cp_CN_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_BU_GT"] ? filters["cp_BU_GT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_BU_GT",
                        e.target.value,
                        filters["cp_BU_GT"]
                          ? filters["cp_BU_GT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_IM_ON"] ? filters["cp_IM_ON"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_IM_ON",
                        e.target.value,
                        filters["cp_IM_ON"]
                          ? filters["cp_IM_ON"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_CL_CK"] ? filters["cp_CL_CK"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_CL_CK",
                        e.target.value,
                        filters["cp_CL_CK"]
                          ? filters["cp_CL_CK"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_CN_CT"] ? filters["cp_CN_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_CN_CT",
                        e.target.value,
                        filters["cp_CN_CT"]
                          ? filters["cp_CN_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                 <td>
                  <input
                    type="text"
                    value={filters["cp_CN_CT"] ? filters["cp_CN_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_CN_CT",
                        e.target.value,
                        filters["cp_CN_CT"]
                          ? filters["cp_CN_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order Number</th>
                <th style={tableHeaderStyle}>Order Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Line No</th>
                <th style={tableHeaderStyle}>Order Date</th>
                <th style={tableHeaderStyle}>Customer No</th>
                <th style={tableHeaderStyle}>Customer Name</th>
                <th style={tableHeaderStyle}>Trans Currency</th>
                <th style={tableHeaderStyle}>Item Number</th>
                <th style={tableHeaderStyle}>Item Description</th>
                <th style={tableHeaderStyle}>Order Quantity</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Unit Price</th>
                <th style={tableHeaderStyle}>Amount</th>
                <th style={tableHeaderStyle}>Unit Cost</th>
                <th style={tableHeaderStyle}>Amount</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Sales Person</th>
                <th style={tableHeaderStyle}>Invoice Number</th>
                <th style={tableHeaderStyle}>Invoice Type</th>
                <th style={tableHeaderStyle}>Invoice Date</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.shUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.sd_UK_NO}
                        checked={selectedRows.includes(rowData.sd_UK_NO)}
                        onChange={() => handleRowSelect(rowData.sd_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.sdOrNo}</td>
                    <td style={tableCellStyle}>{rowData.sdOrTy}</td>
                    <td style={tableCellStyle}>{rowData.sd_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.sd_LN_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sd_OR_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.sd_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{rowData.sh_TR_CR}</td>
                    <td style={tableCellStyle}>{rowData.sd_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.temdesno}</td> 
                    <td style={tableCellStyle}>{rowData.sd_OR_QT}</td>
                    <td style={tableCellStyle}>{rowData.sd_TR_UM}</td>
                    <td style={tableCellStyle}>{rowData.sd_UT_PR}</td>
                    <td style={tableCellStyle}>{rowData.sd_EX_PR}</td>
                    <td style={tableCellStyle}>{rowData.unit_COSTno}</td>
                    <td style={tableCellStyle}>{rowData.amtno}</td>
                    <td style={tableCellStyle}>{rowData.sd_ST_CD}</td> 
                    <td style={tableCellStyle}>{rowData.sd_SA_PE}</td>
                    <td style={tableCellStyle}>{rowData.sd_IN_NO}</td>    
                    <td style={tableCellStyle}>{rowData.sd_IN_TY}</td>
                    <td style={tableCellStyle}>{rowData.sd_IN_DT}</td>               
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <KioskDirectSalesDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        sh_CN_ID={sh_CN_ID}
        setsh_CN_ID={setsh_CN_ID}
        sd_TR_UM={sd_TR_UM}
        setsd_TR_UM={setsd_TR_UM}
        sp_TR_CR={sp_TR_CR}
        setsp_TR_CR={setsp_TR_CR}
        sh_BS_CR={sh_BS_CR}
        setsh_BS_CR={setsh_BS_CR}
        is_CO_CT={is_CO_CT}
        setis_CO_CT={setis_CO_CT}
        sh_CU_NO={sh_CU_NO}
        setsh_CU_NO={setsh_CU_NO}
        sh_EX_RT={sh_EX_RT}
        setsh_EX_RT={setsh_EX_RT}
        sh_DS_AT={sh_DS_AT}
        setsh_DS_AT={setsh_DS_AT}
        sh_DS_PR={sh_DS_PR}
        setsh_DS_PR={setsh_DS_PR}
        as_AD_SH={as_AD_SH}
        setas_AD_SH={setas_AD_SH}
        SelectedCustomer={SelectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        ItemTableData={ItemTableData}
        setItemTableData={setItemTableData}
        PaymentTableData={PaymentTableData}
        setPaymentTableData={setPaymentTableData}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        Image={Image}
        setImage={setImage}
        finalDesc={finalDesc}
        setFinalDesc={setFinalDesc}
        sh_OR_NO={sh_OR_NO}
        sh_OR_TY={sh_OR_TY}
        
      />
    </div>
  );
};

export default KioskDirectSalesMain;
