import React, { useState, useRef } from "react";
import { useHistory, NavLink } from "react-router-dom";
import "./login.css";
import {
  Box,
  Button,
  Flex,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Radio,
  RadioGroup
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import elementVideo from "./element.mp4";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const isProduction = process.env.NODE_ENV === 'production';
const doma = isProduction ? "https://api-" + window.location.host : "http://localhost:8182";
const feDomain = isProduction ? "https://" + window.location.host : "http://localhost:3000";

function SignIn() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = useState(false);
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const passwordInputRef = useRef(null);

  const [typeCE, settypeCE] = useState('E');


  // const dom = window.location.host;
  // const beDom = "https://api-";
  // // const doma = beDom + dom;
  
  // const doma="http://localhost:8182";

  const handleClick = () => setShow(!show);
  const handleSignIn = async () => {
    try {
      const data = {
        username: username,
        password: password,
        rememberMe: rememberMe,
      };

      fetch(`${doma}/api/User/login`, {
        method: "POST",
        body: JSON.stringify({
          username: username,
          password: password,
        }),

        headers: {
          "content-type": "application/json",
          // "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-flag" : typeCE
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.valid === true) {
            localStorage.setItem("token", resp.token);
            localStorage.setItem("company", resp.company);
            localStorage.setItem("domain",doma);
            localStorage.setItem("username",username);
            localStorage.setItem("userType",typeCE);
            toast.success("Logged in successfully!");
            // history.push("/admin/default");
            // window.location.replace(`${feDomain}/admin`);
            if(typeCE==="C"){
              window.location.replace(`${feDomain}/admin/CustomerPortal`);
            }
            else{
              window.location.replace(`${feDomain}/admin`);
            }
            //window.location.replace("http://localhost:3000/admin/default");
          } else {
            toast.error("Invalid credentials..");
          }
        });
    } catch (error) {
      console.error("Error while signing in:", error);
    }
  };
  return (
    <Flex>
      <Flex>
        <div className="loginAnimation" style={{ marginTop: "3%" }}>
          <Heading color={textColor} fontSize="50px" mb="25px" marginLeft="10%">
            <Flex>
              <span className="welcome"  style={{ fontSize: "130%", whiteSpace: "pre-wrap" }} >
                Welcome To{" "}
              </span>{" "}
              <span  className="Do"  style={{ color: "#2690af", fontSize: "130%" }} >  {" "}
                Do
              </span>
              <span className="fort" style={{ color: "#a92424", fontSize: "130%" }} >
                Fort
              </span>
            </Flex>
          </Heading>
          <Heading
            className="takebusiness"
            color={textColor}
            fontSize="30px"
            mb="15px"
            marginLeft="10%"
            style={{ fontSize: "230%" }}
          >
            Take your business to the heights....
          </Heading>

          <video
            width={1200}
            height={500}
            autoPlay
            loop
            muted
            style={{ marginLeft: "-11%" }}
          >
            <source src={elementVideo} type="video/mp4" />
          </video>
        </div>
      </Flex>
      <Flex
        className="loginDetails"
        style={{ marginTop: "5%", marginLeft: "-15%" }}
        flexDirection="column"
      >
        <div className="rightside">
          <Box me="auto">
            <Heading
              className="signHeading"
              color={textColor}
              fontSize="320%"
              mb="10px"
            >
              Sign in to your account to continue...
            </Heading>
          </Box>
          <Flex className="inputtypes" style={{ padding: "7%" }}>
            <FormControl>
          <div style={{display:'flex',justifyContent:'end'}}>
              <RadioGroup onChange={settypeCE} value={typeCE} mb="24px">
                <Flex justifyContent='end'>
                  <Radio value="C" name="type" me="10px" colorScheme="brandScheme">Customer</Radio>
                  <Radio value="E" name="type" colorScheme="brandScheme">Employee</Radio>
                </Flex>
              </RadioGroup>    
                
          </div>
              <FormLabel display="flex" ms="7px" fontSize="120%" fontWeight="1000" color={textColor}  mb="10px"  >
                UserName<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Enter UserName"
                mb="24px"
                fontWeight="500"
                size="lg"
                id="field-username"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
              <FormLabel  ms="4px"fontSize="120%"   fontWeight="1000" color={textColor} display="flex"   >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup>
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Enter Password"
                  mb="24px"
                  size="lg"
                  fontWeight="500"
                  type={show ? "text" : "password"}
                  variant="auth"
                  id="field-password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSignIn();
                    }
                  }}
                  ref={passwordInputRef}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon color={textColorSecondary}  _hover={{ cursor: "pointer" }} as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}  onClick={handleClick} />
                </InputRightElement>
              </InputGroup>
              <Flex justifyContent="space-between" align="center" mb="24px">
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    id="remember-login"
                    colorScheme="brandScheme"
                    me="10px"
                  />
                  <FormLabel  className="remember" htmlFor="remember-login"  mb="0" fontWeight="normal" color={textColor} fontSize="105%"  >
                    Remember Me
                  </FormLabel>
                </FormControl>
                <NavLink to="/auth/forgot-password">
                  <Text className="forgot"  color={textColorBrand}  fontSize="100%"    w="124px"   fontWeight="500"    >
                    Forgot password?
                  </Text>
                </NavLink>
              </Flex>
              <div style={{display:"flex"}}>
                {/* OPTIONS DROPDOWN */}
                {/* <Dropdown
                  style={{ width: "50%", height: 'fit-content' }}
                  id="user-type"
                  mb="24px"
                  size="lg"
                  fontWeight="500"
                  value={typeCE}
                  options={typeOptions}
                  onChange={(e) => settypeCE(e.value)} // update to set the value directly
                  placeholder="Select Login Type"
                /> */}
                <Button
                  style={{ marginLeft: "25%" }}
                  className="signinbtn"
                  onClick={handleSignIn}
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="50%"
                  h="50"
                  mb="24px"
                >
                  Sign In
                </Button>
              </div>
              <ToastContainer />
            </FormControl>
          </Flex>
        </div>
      </Flex>
    </Flex>
  );
}

export default SignIn;


