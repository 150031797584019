import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import CompensationDetailsDialog from "./CompensationDetailsDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  ab_AD_NM: "",
  ec_EM_NO: "",
  name: "",
  ec_CC_NM: "",
  em_DE_PT: "",
  ec_PY_CL: "",
  ec_TO_SL: "",
  ec_LT_RE: "",
  ec_NX_RE: "",
  ec_WP_EF: "",
  em_GR_DE:"",
};

const initialFilters = {
  ec_EM_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },
  ec_EM_DS: { value: "", operation: "" },
  ec_CC_NM: { value: "", operation: "" },
  em_DE_PT: { value: "", operation: "" },
  ec_PY_CL: { value: "", operation: "" },
  ec_TO_SL: { value: "", operation: "" },
  ec_LT_RE: { value: "", operation: "" },
  ec_NX_RE: { value: "", operation: "" },
};

const CompensationDetailsMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");

  const [ec_CC_NM, setec_CC_NM] = useState([]);
  const [em_EM_DS, setem_EM_DS] = useState([]);
  const [em_DE_PT, setem_DE_PT] = useState([]);
  const [ec_PY_CL, setec_PY_CL] = useState([]);
  const [em_GR_DE, setem_GR_DE] = useState([]);
  const [em_PY_FR, setem_PY_FR] = useState([]);
  const [em_GR_EF, setem_GR_EF] = useState("");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setec_CC_NM(null);
    setem_EM_DS(null);
    setem_DE_PT(null);
    setec_PY_CL(null);
    setem_GR_DE(null);
    setem_PY_FR(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setec_CC_NM(null);
    setem_EM_DS(null);
    setem_DE_PT(null);
    setec_PY_CL(null);
    setem_GR_DE(null);
    setem_PY_FR(null);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/CompensationDetails/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const CompensationDetailsData = response.data;
          delete CompensationDetailsData.ecUkNo;
          setem_EM_DS(CompensationDetailsData.em_EM_DS);
          setem_DE_PT(CompensationDetailsData.em_DE_PT);
          setem_GR_DE(CompensationDetailsData.em_GR_DE);
          setem_PY_FR(CompensationDetailsData.em_PY_FR);
          setec_PY_CL(CompensationDetailsData.ec_PY_CL);
          setFormData(CompensationDetailsData);
          setSelectedemEMNO(CompensationDetailsData.name);

          // setSelectedAbAdNM(CompensationDetailsData.cc_EM_NM);
          // setec_CC_NM(CompensationDetailsData.ec_CC_NM);
          // setem_EM_DS(CompensationDetailsData.em_EM_DS);
          // setem_DE_PT(CompensationDetailsData.em_DE_PT);
          // setec_PY_CL(CompensationDetailsData.ec_PY_CL);
          // setem_GR_DE(CompensationDetailsData.em_GR_DE);
          // setem_PY_FR(CompensationDetailsData.em_PY_FR);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
const [ec_CO_NO, setec_CO_NO] = useState("");

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    setec_CO_NO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/CompensationDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({ ...prevData,  [name]: value,}));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };
 
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
    setec_CC_NM(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData((prevData) => ({ ...prevData,[name]: value, }));
    setem_EM_DS(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
    setem_DE_PT(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
    setec_PY_CL(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData((prevData) => ({ ...prevData,  [name]: value, }));
    setem_GR_DE(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
    setem_PY_FR(value);
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/CompensationDetails/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const CompensationDetailsData = response.data;
        setFormData(CompensationDetailsData);
        setSelectedAbAdNM(response.data.ab_AD_NM);
        setSelectedemEMNO(response.data.name);
        setec_CC_NM(response.data.ec_CC_NM);
        setem_EM_DS(response.data.em_EM_DS);
        setem_DE_PT(response.data.em_DE_PT);
        setec_PY_CL(response.data.ec_PY_CL);
        setem_GR_DE(response.data.em_GR_DE);
        setem_PY_FR(response.data.em_PY_FR);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
    // user acess for add,update,view
    const [useraccess, setuseraccess] = useState("");
    useEffect(() => {
      AccessCheck();
    }, []);
    const AccessCheck = async () => {
      try {
        const request = {
          "us_FN_NM": "Add",
          "us_AP_NM": "compensationdetails"
      }
        const response = await axios.post( `${domain}/api/UserSecurity/CheckSecurity`,request,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        const responseValue = response.data.result.us_FL_AG;
        setuseraccess(responseValue === "YES");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  const handleFormSubmit = () => {
    if (FormData.ecUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/CompensationDetails/Update`;
      if (confirm) {
        axios
          .put(update, FormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.ec_CO_NO = company;
      axios
        .post(`${domain}/api/CompensationDetails/Add`, FormData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ec_EM_NO"] ? filters["ec_EM_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ec_EM_NO",
                        e.target.value,
                        filters["ec_EM_NO"]
                          ? filters["ec_EM_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ab_AD_NM"] ? filters["ab_AD_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ab_AD_NM",
                        e.target.value,
                        filters["ab_AD_NM"] ? filters["ab_AD_NM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_EM_DS"] ? filters["em_EM_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_EM_DS",
                        e.target.value,
                        filters["em_EM_DS"]
                          ? filters["em_EM_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ec_CC_NM"] ? filters["ec_CC_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ec_CC_NM",
                        e.target.value,
                        filters["ec_CC_NM"]
                          ? filters["ec_CC_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_DE_PT"] ? filters["em_DE_PT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_DE_PT",
                        e.target.value,
                        filters["em_DE_PT"]
                          ? filters["em_DE_PT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ec_PY_CL"] ? filters["ec_PY_CL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ec_PY_CL",
                        e.target.value,
                        filters["ec_PY_CL"]
                          ? filters["ec_PY_CL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ec_TO_SL"] ? filters["ec_TO_SL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ec_TO_SL",
                        e.target.value,
                        filters["ec_TO_SL"]
                          ? filters["ec_TO_SL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ec_LT_RE"] ? filters["ec_LT_RE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ec_LT_RE",
                        e.target.value,
                        filters["ec_LT_RE"]
                          ? filters["ec_LT_RE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ec_NX_RE"] ? filters["ec_NX_RE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ec_NX_RE",
                        e.target.value,
                        filters["ec_NX_RE"]
                          ? filters["ec_NX_RE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Employee </th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Designation</th>
                <th style={tableHeaderStyle}>Cost Center</th>
                <th style={tableHeaderStyle}>Department</th>
                <th style={tableHeaderStyle}>Pay Class</th>
                <th style={tableHeaderStyle}>Total Salary</th>
                <th style={tableHeaderStyle}>Last Review</th>
                <th style={tableHeaderStyle}>Next Review</th>            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.ecUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.ecUkNo}
                        checked={selectedRows.includes(rowData.ecUkNo)}
                        onChange={() => handleRowSelect(rowData.ecUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ec_EM_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{rowData.designation}</td>
                    <td style={tableCellStyle}>{rowData.ec_CC_NM}</td>
                    <td style={tableCellStyle}>{rowData.department}</td>
                    <td style={tableCellStyle}>{rowData.payClass}</td>
                    <td style={tableCellStyle}>{rowData.ec_TO_SL}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.ec_LT_RE)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.ec_NX_RE)}</td>                    
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
{/* pagination */}
<Pagination
    currentPage={currentPage}
    totalPages={totalPages}
    setCurrentPage={setCurrentPage}
  />

      </div>

      <CompensationDetailsDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        ec_CC_NM={ec_CC_NM}
        em_EM_DS={em_EM_DS}
        em_DE_PT={em_DE_PT}
        ec_PY_CL={ec_PY_CL}
        em_GR_DE={em_GR_DE}
        em_PY_FR={em_PY_FR}
        ec_CO_NO={ec_CO_NO}
        em_GR_EF={em_GR_EF}
        selectedAbAdNM={selectedAbAdNM}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedemEMNO={setSelectedemEMNO}
        useraccess={useraccess}     
      />
    </div>
  );
};

export default CompensationDetailsMain;
