import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import moment from "moment";
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonStyles from "views/common/ButtonStyles";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
export default function OverTimeDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  setFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  Selectedprdv,
  setSelectedprdv,

}) {
  const { 
    cdUkNo,
  cd_CO_NO,
  cd_CS_CD,
  cd_CS_NM,
  cd_EF_TO,
  cd_EF_FR,
  cd_CO_ST,  cd_CS_PR,
  cd_TR_TY,
  cd_UN_TS,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [cd_TR_TYOptions, setcd_TR_TYOptions] = useState([]);
  const [cd_CS_PROptions, setcd_CS_PROptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const url = `${domain}/api/ClassCode/All`;

  const parameter1 = "TR/TY";
  const finalUrl1 = `${url}/${parameter1}`;

  const parameter2 = "CO/PR";
  const finalUrl2 = `${url}/${parameter2}`;

    useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
  }, []);
 // 1st fetchdropdown
 const fetchDropdownOptions1 = async () => {
  try {
    const response = await axios.get(finalUrl1, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcd_TR_TYOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
  };
 // 2nd fetchdropdown
 const fetchDropdownOptions2 = async () => {
  try {
    const response = await axios.get(finalUrl2, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcd_CS_PROptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
  };
  const [ProviderPopupVisible, setProviderPopupVisible] = useState(false);
  const [addressTypePrvd, setaddressTypePrvd] = useState("S");
  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const openProviderPopup = () => {
    setProviderPopupVisible(true);
  };
  const handleDataSelectProvider = (data) => {
    setSelectedprdv(data.ab_AD_NM);
    document.getElementById("cd_CS_PR").value = data.abAdNo;
    setProviderPopupVisible(false);
    setShowSearchIcon(false);
  };

  const isUpdateMode = Boolean(cdUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{cdUkNo ? "Revise Course Details" : "Add Course Details"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle} >
          <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cd_CS_CD" className="font-bold">
                Course Code
                </label>
                <InputText
                  id="cd_CS_CD"
                  name="cd_CS_CD"
                  value={cd_CS_CD}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cd_CS_NM" className="font-bold">
                Course Name
                </label>
                <InputText
                  id="cd_CS_NM"
                  name="cd_CS_NM"
                  value={cd_CS_NM}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cd_UN_TS" className="font-bold">
                  Units
                </label>
                <InputText
                  id="cd_UN_TS"
                  name="cd_UN_TS"
                  value={cd_UN_TS}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cd_EF_FR" className="font-bold">
                Effective From
                </label>
                <Calendar
                  id="cd_EF_FR"
                  name="cd_EF_FR"
                  value={moment(data.cd_EF_FR, "YYYYMMDD").isValid() ? moment(data.cd_EF_FR, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "cd_EF_FR", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cd_EF_TO" className="font-bold">
                  Effective To
                </label>
                <Calendar
                  id="cd_EF_TO"
                  name="cd_EF_TO"
                  value={moment(data.cd_EF_TO, "YYYYMMDD").isValid() ? moment(data.cd_EF_TO, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "cd_EF_TO", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cd_CO_ST" className="font-bold">
                Cost
                </label>
                <InputText
                  id="cd_CO_ST"
                  name="cd_CO_ST"
                  value={cd_CO_ST}
                  onChange={(e) => onchangedata(e)}
                />
              </div>             
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cd_CS_PR" className="font-bold">
                  Provider <span style={{color:'red'}}>*</span>
                </label>
                {/* <Dropdown
                  name="cd_CS_PR"
                  value={cd_CS_PR}
                  options={cd_CS_PROptions}
                  onChange={(e) => onchangeDropDownFormData2("cd_CS_PR", e.value)}
                  placeholder="Select a Provider"
                  /> */}
                  <div style={{ display: 'flex' }}>
                    <InputText
                      id="Selectedprdv"
                      name="Selectedprdv"
                      onChange={(event) => onchangedata(event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={Selectedprdv}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openProviderPopup}
                      ></i>
                    )}
                  </div>
                    <InputText  style={{ display: 'none' }}
                      id="cd_CS_PR"
                      name="cd_CS_PR"
                      defaultValue={cd_CS_PR}
                    />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cd_TR_TY" className="font-bold">
                Training Type
                </label>
                <Dropdown
                  id="cd_TR_TY"
                  name="cd_TR_TY"
                  value={cd_TR_TY}
                  options={cd_TR_TYOptions}
                  onChange={(e) => onchangeDropDownFormData1("cd_TR_TY", e.value)}
                  placeholder="Select Training Type"
                />
              </div>
          </div>
        </div>
      </div> 
      {/* Provider */}
      <AddressBookPopup
        visible={ProviderPopupVisible}
        onClose={() => setProviderPopupVisible(false)}
        onDataSelect={handleDataSelectProvider}
        formData={data}
        addressType={addressTypePrvd}
        setFormData={setFormData}
      /> 
    </Dialog>
  );
}
