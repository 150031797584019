import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
export default function FinancialForeCastDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  handleConfirm,
  // onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,    
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  fb_DO_CO,
  FinancialForeCasttable,
  setFinancialForeCasttable,
}) {
  const { 
  fbUkNo,
  fb_CT_RY,
  fb_FI_YR,
  fb_CO_CT,
  fb_OB_AC,
  fb_OB_SB,
  fb_AC_ID,
  fb_AC_DS,
  fb_LV_DT,
  fb_PE_CD,
  fb_BU_AM,
  fb_ST_CD,
  fb_PE_01,
  fb_PE_02,
  fb_PE_03,
  fb_PE_04,
  fb_PE_05,
  fb_PE_06,
  fb_PE_07,
  fb_PE_08,
  fb_PE_09,
  fb_PE_10,
  fb_PE_11,
  fb_PE_12,
  } = data;
  const [fb_CT_RYOptions, setfb_CT_RYOptions] = useState([]);
  const [fb_FI_YROptions, setfb_FI_YROptions] = useState([]);
  const [fb_ST_CDOptions, setfb_ST_CDOptions] = useState([]);

  const addRow = () => {
    setFinancialForeCasttable([...FinancialForeCasttable, {}]);
  };

  const deleteRow = () => {
    if (FinancialForeCasttable.length > 0) {
      const updatedData = [...FinancialForeCasttable];
      updatedData.pop();
      setFinancialForeCasttable(updatedData);
    }
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData3(name, value, index);
  };

  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "rc_ST_DT"  || name === "rc_EN_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);
  
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };
  const url = `${domain}/api/ClassCode/All`;
  // comp type
  const parameter1 = "CM/TY";
  const finalUrl1 = `${url}/${parameter1}`;
// comp level
  const parameter2 = "YE/AR";
  const finalUrl2 = `${url}/${parameter2}`;
// provider
  const parameter3 = "FA/ST";
  const finalUrl3 = `${url}/${parameter3}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
  }, []);

  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setfb_CT_RYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setfb_FI_YROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl3, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setfb_ST_CDOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const [account, setaccount] = useState([]); 
const [am_OB_AC, setam_OB_AC] = useState([]); 
const handleOnChangeRate =async () => {
  const Url = `${domain}/api/AccountMaster/GetByAccountRange`;
  const requestData={
    "am_OB_AC":document.getElementById("am_OB_AC").value,
    "account":document.getElementById("account").value,
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataLead = response.data.Result;

    if (dataLead && dataLead.length > 0) {
      setFinancialForeCasttable(dataLead.map(item => ({
        fbUkNo: item.amUkNo,
        fb_CO_CT: item.am_CO_NM,
        fb_OB_AC: item.am_OB_AC,
        fb_OB_SB: item.am_OB_SB,
        fb_AC_ID: item.am_AC_ID,
        fb_AC_DS: item.am_AC_DS,
        fb_LV_DT: item.am_AC_LD,
        fb_PE_CD: item.am_PE_CD,
      })));

    } else {
      console.error("Invalid API response:" );
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

const handlePeriod =async () => {
  const Url = `${domain}/api/FinancialForeCast/GetFIBUGetAmount`;
  const requestData={
    "fbUkNo":fbUkNo,
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataLead = response.data.Result;

    if (dataLead && dataLead.length > 0) {
      setFinancialForeCasttable(dataLead.map(item => ({
        fbUkNo: item.fbUkNo,
        fb_PE_01: item.fb_PE_01,
        // fb_OB_AC: item.am_OB_AC,
        // fb_OB_SB: item.am_OB_SB,
        // fb_AC_ID: item.am_AC_ID,
        // fb_AC_DS: item.am_AC_DS,
        // fb_LV_DT: item.am_AC_LD,
        // fb_PE_CD: item.am_PE_CD,
      })));

    } else {
      console.error("Invalid API response:" );
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}
const isUpdateMode = Boolean(fbUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
    <Button 
    style={{width:"5%",background:"transparent",color:"blue"}}
    icon="pi pi-check" 
    onClick={handleConfirm} 
    tooltip={" Confirm "}>
    </Button>
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={fbUkNo ? "Revise Financial ForeCast" : "Add Financial ForeCast"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div className="formgrid grid">
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fb_CT_RY" className="font-bold">
                  Century
                  </label>
                  <Dropdown
                    id="fb_CT_RY"
                    name="fb_CT_RY"
                    value={fb_CT_RY}
                    options={fb_CT_RYOptions}
                    onChange={(e) => onchangeDropDownFormData1("fb_CT_RY", e.value)}
                    placeholder="Select Century"
                  />
          </div> 
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="fb_FI_YR" className="font-bold">
           Fiscal Year
            </label>
            <Dropdown
                    id="fb_FI_YR"
                    name="fb_FI_YR"
                    value={fb_FI_YR}
                    options={fb_FI_YROptions}
                    onChange={(e) => onchangeDropDownFormData2("fb_FI_YR", e.value)}
                    placeholder="Select financial Year"
                  />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="fb_DO_CO" className="font-bold">
            Company
            </label>
            <InputText disabled
              id="fb_DO_CO"
              name="fb_DO_CO"
              value={fb_DO_CO}
              onChange={(e) => onchangedata(e)}
            />
           
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="am_OB_AC" className="font-bold">
            From Account
            </label>
            <InputText 
              id="am_OB_AC"
              name="am_OB_AC"
              value={am_OB_AC}
              onChange={(e) => { 
                setam_OB_AC(e.target.value); 
                handleOnChangeRate(); 
                // handlePeriod();
              }}
            />
           
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="account" className="font-bold">
            To Account
            </label>
            <InputText 
              id="account"
              name="account"
              value={account}
              onChange={(e) => { 
                setaccount(e.target.value); 
                handleOnChangeRate(); 
                // handlePeriod();
              }}
            />
           
          </div>
        </div>
      </div>
      <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            marginTop: "2%",
          }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
          }}
        >
          {!isUpdateMode && (
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          )}
           {!isUpdateMode && (
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
          )}
        </div>
        <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
            position: "relative",
          }}
        >
        <table className="custom-table" id="FinancialForeCasttable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Cost Center</th>
          <th>Account</th>
          <th>Subsidiary</th>
          <th>Account ID</th>
          <th>Account Description</th>
          <th>Level </th>
          <th>Post Code</th>
          <th>Budget Amount</th>
          <th>Status</th>
          <th>Period01</th>
          <th>Period02</th>
          <th>Period03</th>
          <th>Period04</th>
          <th>Period05</th>
          <th>Period06</th>
          <th>Period07</th>
          <th>Period08</th>
          <th>Period09</th>
          <th>Period10</th>
          <th>Period11</th>
          <th>Period12</th>
        </tr>
      </thead>
      <tbody>
      {FinancialForeCasttable.map((row, index) => (
              <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
              id={`fbUkNo_${index}`}
              name={`fbUkNo_${index}`}
              defaultValue={row.fbUkNo}
            />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_CO_CT_${index}`}
            name={`fb_CO_CT_${index}`}
            defaultValue={row.fb_CO_CT}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_OB_AC_${index}`}
            name={`fb_OB_AC_${index}`}
            defaultValue={row.fb_OB_AC}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_OB_SB_${index}`}
            name={`fb_OB_SB_${index}`}
            defaultValue={row.fb_OB_SB}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_AC_ID_${index}`}
            name={`fb_AC_ID_${index}`}
            defaultValue={row.fb_AC_ID}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_AC_DS_${index}`}
            name={`fb_AC_DS_${index}`}
            defaultValue={row.fb_AC_DS}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_LV_DT_${index}`}
            name={`fb_LV_DT_${index}`}
            defaultValue={row.fb_LV_DT}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_PE_CD_${index}`}
            name={`fb_PE_CD_${index}`}
            defaultValue={row.fb_PE_CD}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText style={{width:"90px"}}
            id={`fb_BU_AM_${index}`}
            name={`fb_BU_AM_${index}`}
            defaultValue={row.fb_BU_AM}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
            <td>
            <Dropdown
              id={`fb_ST_CD_${index}`}
              name={`fb_ST_CD_${index}`}
              value={data[`fb_ST_CD_${index}`] ? data[`fb_ST_CD_${index}`]:row.fb_ST_CD }
              options={fb_ST_CDOptions}
              onChange={(e) => handleFormDataChange(`fb_ST_CD_${index}`, e.value)}
              placeholder="Select Status"
            />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_PE_01_${index}`}
            name={`fb_PE_01_${index}`}
            defaultValue={row.fb_PE_01}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_PE_02_${index}`}
            name={`fb_PE_02_${index}`}
            defaultValue={row.fb_PE_02}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_PE_03_${index}`}
            name={`fb_PE_03_${index}`}
            defaultValue={row.fb_PE_03}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_PE_04_${index}`}
            name={`fb_PE_04_${index}`}
            defaultValue={row.fb_PE_04}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_PE_05_${index}`}
            name={`fb_PE_05_${index}`}
            defaultValue={row.fb_PE_05}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_PE_06_${index}`}
            name={`fb_PE_06_${index}`}
            defaultValue={row.fb_PE_06}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_PE_07_${index}`}
            name={`fb_PE_07_${index}`}
            defaultValue={row.fb_PE_07}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_PE_08_${index}`}
            name={`fb_PE_08_${index}`}
            defaultValue={row.fb_PE_08}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_PE_09_${index}`}
            name={`fb_PE_09_${index}`}
            defaultValue={row.fb_PE_09}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_PE_10_${index}`}
            name={`fb_PE_10_${index}`}
            defaultValue={row.fb_PE_10}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_PE_11_${index}`}
            name={`fb_PE_11_${index}`}
            defaultValue={row.fb_PE_11}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`fb_PE_12_${index}`}
            name={`fb_PE_12_${index}`}
            defaultValue={row.fb_PE_12}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          </tr>
           ))}
      </tbody>
    </table>   
    
    </div>
  </div>   
  
    </Dialog>
  );
}
