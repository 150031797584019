import React from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';                    
import 'primeicons/primeicons.css';                                 
import 'primeflex/primeflex.css';                                   
import '../../common/flags.css';
import ManageCaseMain from './ManageCaseMain';

const ManageCase = () => {

  return (
   <div className='saleslead'>
      <ManageCaseMain/>
   </div>
  )
}

export default ManageCase