import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import UpdateOverTimeDialog from "./UpdateOverTimeDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  uo_EM_NO:"",
  name:"",
  uo_CN_FM:"",
  uo_HL_OT:"",
  uo_NO_OT:"",
  uo_FY_YR:"",
  uo_CO_NO:"",
  uo_CT_RY:"",
  uo_TO_OT:"",
  em_CC_NM:"",
  em_DE_PT:"",
  uo_SL_PR:"",
  uo_PE_NO:"",
  em_CC_NM:"",
  em_DE_PT:"",
  uo_FR_DT:"",
};
const initialFilters = {
  uo_EM_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },
  em_CC_NM: { value: "", operation: "" },
  em_DE_PT: { value: "", operation: "" },
  uo_CT_RY: { value: "", operation: "" },
  uo_FY_YR: { value: "", operation: "" },
  uo_PE_NO: { value: "", operation: "" },
  uo_NO_OT: { value: "", operation: "" },
  uo_HL_OT: { value: "", operation: "" },
  uo_TO_OT: { value: "", operation: "" },
  uo_CN_FM: { value: "", operation: "" },
};
const UpdateOverTimeMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [em_CC_NM, setem_CC_NM] = useState("");
  const [uo_FY_YR, setuo_FY_YR] = useState("");
  const [uo_PE_NO, setuo_PE_NO] = useState("");
  const [em_DE_PT, setem_DE_PT] = useState("");
  const [uo_EM_NO, setuo_EM_NO] = useState("");

const buildSearchRequestDto = () => {
  return Object.keys(filters)
    .filter((column) => filters[column].value)
    .map((column) => ({
      column: column.toUpperCase(),
      value: filters[column].value,
      operation: filters[column].operation,
    }));
};
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;
    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  
const [UpdateOTtable, setUpdateOTtable] = useState([{}]);
  const [emEMNOList, setEmEMNOList] = useState([]);
  const emplist = `${domain}/api/EmployeeMaster/GetOTEmployees`
  const [leaveDaysList, setLeaveDaysList] = useState(UpdateOTtable.map(() => ''));
  const handleAddButtonClick = async () => {
    setLeaveDaysList(null);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setUpdateOTtable([{}]);
    setSelectedemEMNO(null);
    setuo_EM_NO(null);
    setem_CC_NM(null);
    setuo_FY_YR(null);
    setuo_PE_NO(null);
    const req = {
      "searchRequestDto":[]
    };
    try {
      const response = await axios.post(emplist, req, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const datalist = response.data;
      const employees = [];
      let size = datalist.length;
      for(let i =0; i< size; i++ ){
        const dtls = {
          "uo_EM_NO" : datalist[i].emEmNo,
          "name" : datalist[i].name,
          "em_CC_NM" : datalist[i].em_CC_NM,
          "em_DE_PT" : datalist[i].em_DE_PT,
        }
        employees.push(dtls);
      }
      setUpdateOTtable(employees);
      setOpen(true);
    } catch (error) {
      console.error("Error in Formatted Dates", error);
    }
    // setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setUpdateOTtable([{}]);
    setSelectedemEMNO(null);
    setuo_EM_NO(null);
    setem_CC_NM(null);
    setuo_FY_YR(null);
    setuo_PE_NO(null);
    setOpen(false);
  };
const addRow = () => {
  setUpdateOTtable([...UpdateOTtable, {}]);
};

  const deleteRow = () => {
    if (UpdateOTtable.length > 0) {
      const updatedData = [...UpdateOTtable];
      updatedData.pop();
      setUpdateOTtable(updatedData);
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [uo_CO_NO, setuo_CO_NO] = useState("");
  const handleDefault = async () => {
    setuo_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/UpdateOverTime/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const onchangeFormData = (name, value) => {
  //   setFormData({ ...FormData, [name]: value });
  // };

  const onchangeFormData = (name, value,index=null) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const fieldName=(FormData && FormData.uoUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
    UpdateOTtable[index][fieldName]=value;
  };
  const onchangeDropDownFormData1 = (name, value) => {
     setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
    setem_CC_NM(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
    setuo_FY_YR(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setuo_PE_NO(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setem_DE_PT(value);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/UpdateOverTime/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const UpdateOverTimeData = response.data;
          delete UpdateOverTimeData.uoUkNo;
          for (let i = 0; i < UpdateOverTimeData.updateOverTimes.length; i++) {
            delete UpdateOverTimeData.updateOverTimes[i].uoUkNo;
          }
          setUpdateOTtable(UpdateOverTimeData.updateOverTimes);
          setFormData(UpdateOverTimeData.updateOverTimes[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/UpdateOverTime/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const UpdateOverTimeData = response.data;
        setUpdateOTtable(UpdateOverTimeData.updateOverTimes);
        setFormData(UpdateOverTimeData.updateOverTimes[0]);
        // setUpdateOTtable(UpdateOverTimeData.updateOverTimes);
        // setFormData(UpdateOverTimeData.updateOverTimes[0]);
        // setFormData(UpdateOverTimeData);
        setSelectedemEMNO(response.data.name);
        setem_DE_PT(response.data.em_DE_PT);  
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBody = {};
  function values () {
    const table = document.getElementById("UpdateOTtable");
    const totalRowCount1 = table.tBodies[0].rows.length;
    const updateOverTimes = [];
    for (let i = 0; i < totalRowCount1; i++) {
      const Benefits = {
        uoUkNo :  document.getElementById("uoUkNo_" + i).value,
        uo_EM_NO : document.getElementById("uo_EM_NO_" + i).value,
        em_CC_NM : FormData["em_CC_NM_" + i],
        em_DE_PT : document.getElementById("em_DE_PT_" + i).value,
        uo_NO_OT : document.getElementById("uo_NO_OT_" + i).value,
        uo_HL_OT : document.getElementById("uo_HL_OT_" + i).value,
        uo_TO_OT : document.getElementById("uo_TO_OT_" + i).value,
        uo_CN_FM : document.getElementById("uo_CN_FM_" + i).value,
      };
      updateOverTimes.push(Benefits);
    }
   requestBody = {
    updateOverTimes,
    uoUkNo : FormData.uoUkNo,
    uo_CO_NO : FormData.uo_CO_NO,
    uo_FR_DT : FormData.uo_FR_DT,
    uo_FY_YR : FormData.uo_FY_YR,
    uo_TO_DT : FormData.uo_TO_DT,
    uo_PE_NO : FormData.uo_PE_NO,
    uo_CO_NO : company,
    };
  };
  const handleFormSubmit = () => {
    values ();
    if (FormData.uoUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/UpdateOverTime/Update`;
      if (confirm) {
        axios.put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.uo_CO_NO = company;
      axios.post(`${domain}/api/UpdateOverTime/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
  // confirm
  const handleConfirm = () => {    
    values();
    const ukNo = FormData.uoUkNo;
      if (ukNo) {
        FormData.uo_CO_NO = company;
        const confirm = window.confirm("Do you want to Confirm ?");
        const urlconfirm = `${domain}/api/UpdateOverTime/ConfirmUpdateOverTime`;
        if (confirm) {
          axios.post(urlconfirm, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } 
} ;
const leftToolbarTemplate = ( 
  <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack >
      <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
      Update
    </div>  
    </Tooltip>
      <Button   
      style={buttonStyles}
      icon="pi pi-check"
      outlined
      className="mr-2 update-button"
      onClick={handleUpdate}
      disabled={!isRecordSelected}
    ></Button>
    <Tooltip target=".add-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Add
      </div>
    </Tooltip>
    <Button 
      style={addButtonStyles}
      icon="pi pi-plus"     
      outlined
      className="mr-2 add-button"
      onClick={handleAddButtonClick}
    ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Copy
      </div>
  </Tooltip>
    <Button 
      style={copyButtonStyles}
      icon="pi pi-copy"
      outlined
      className="mr-2 copy-button"
      onClick={handleCopyData}
    ></Button>
  </React.Fragment>
);

const exportExcel = () => {
  import("xlsx").then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(tableData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "products_export.xlsx");
  });
};

const rightToolbarTemplate = (
  <React.Fragment>
    <Button
      style={{ height: "35px" }}
      icon="pi pi-upload"
      className="p-button-help"
      rounded
      onClick={exportExcel}
      data-pr-tooltip="XLS"
    />
  </React.Fragment>
);

return (
  <div className="tableData" style={{ marginTop: "5%" }}>
    <Toast ref={toast} />
    <div className="card">
      <Toolbar
        className="p-mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      />
      <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <thead>
            <tr>
              <td style={{width:'40px'}}> 
                <input
                  style={checkboxStyle}
                  readOnly
                  onMouseDown={(e) => e.preventDefault()}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["uo_EM_NO"] ? filters["uo_EM_NO"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "uo_EM_NO",
                      e.target.value,
                      filters["uo_EM_NO"]
                        ? filters["uo_EM_NO"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["name"] ? filters["name"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "name",
                      e.target.value,
                      filters["name"]
                        ? filters["name"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["em_CC_NM"] ? filters["em_CC_NM"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "em_CC_NM",
                      e.target.value,
                      filters["em_CC_NM"]
                        ? filters["em_CC_NM"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["em_DE_PT"] ? filters["em_DE_PT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "em_DE_PT",
                      e.target.value,
                      filters["em_DE_PT"]
                        ? filters["em_DE_PT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>              
              <td>
                <input
                  type="text"
                  value={filters["uo_CT_RY"] ? filters["uo_CT_RY"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "uo_CT_RY",
                      e.target.value,
                      filters["uo_CT_RY"]
                        ? filters["uo_CT_RY"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["uo_FY_YR"] ? filters["uo_FY_YR"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "uo_FY_YR",
                      e.target.value,
                      filters["uo_FY_YR"]
                        ? filters["uo_FY_YR"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["uo_PE_NO"] ? filters["uo_PE_NO"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "uo_PE_NO",
                      e.target.value,
                      filters["uo_PE_NO"]
                        ? filters["uo_PE_NO"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["uo_NO_OT"] ? filters["uo_NO_OT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "uo_NO_OT",
                      e.target.value,
                      filters["uo_NO_OT"]
                        ? filters["uo_NO_OT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["uo_HL_OT"] ? filters["uo_HL_OT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "uo_HL_OT",
                      e.target.value,
                      filters["uo_HL_OT"]
                        ? filters["uo_HL_OT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["uo_TO_OT"] ? filters["uo_TO_OT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "uo_TO_OT",
                      e.target.value,
                      filters["uo_TO_OT"]
                        ? filters["uo_TO_OT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["uo_CN_FM"] ? filters["uo_CN_FM"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "uo_CN_FM",
                      e.target.value,
                      filters["uo_CN_FM"]
                        ? filters["uo_CN_FM"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td> 
              <td>
                <input
                  type="text"
                  value={filters["uo_SL_PR"] ? filters["uo_SL_PR"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "uo_SL_PR",
                      e.target.value,
                      filters["uo_SL_PR"]
                        ? filters["uo_SL_PR"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>             
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Employee ID</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Department</th>
                <th style={tableHeaderStyle}>Century</th>
                <th style={tableHeaderStyle}>Financial Year</th>
                <th style={tableHeaderStyle}>Month</th>
                <th style={tableHeaderStyle}>Normal OT</th>
                <th style={tableHeaderStyle}>Holiday OT</th>
                <th style={tableHeaderStyle}>Total OT</th>
                <th style={tableHeaderStyle}>Confirm</th>
                <th style={tableHeaderStyle}>Processed</th>
              </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData.map((rowData, index) => (
                <tr
                  key={rowData.uoUkNo}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "aliceblue" : "#ffffff",
                  }}
                  className="table-row"
                >
                  <td style={tableCellStyle}>
                    <input
                      style={{ width: "10% !important" }}
                      type="checkbox"
                      value={rowData.uoUkNo}
                      checked={selectedRows.includes(rowData.uoUkNo)}
                      onChange={() => handleRowSelect(rowData.uoUkNo)}
                    />
                  </td>
                  <td style={tableCellStyle}>{rowData.uo_EM_NO}</td>
                  <td style={tableCellStyle}>{rowData.name}</td>
                  <td style={tableCellStyle}>{rowData.em_CC_NM}</td>
                  <td style={tableCellStyle}>{rowData.department}</td>
                  <td style={tableCellStyle}>{rowData.uo_CT_RY}</td>
                  <td style={tableCellStyle}>{rowData.uo_FY_YR}</td>
                  <td style={tableCellStyle}>{rowData.uo_PE_NO}</td>
                  <td style={tableCellStyle}>{rowData.uo_NO_OT}</td>
                  <td style={tableCellStyle}>{rowData.uo_HL_OT}</td>
                  <td style={tableCellStyle}>{rowData.uo_TO_OT}</td>
                  <td style={tableCellStyle}>{rowData.uo_CN_FM}</td>
                  <td style={tableCellStyle}>{rowData.uo_SL_PR}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
  {/* pagination */}
  <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

    </div>
    <UpdateOverTimeDialog
      open={open}
      handleClose={handleClose}
      data={FormData}
      handleFormSubmit={handleFormSubmit}
      onchangedata={onchangedata}
      onchangeFormData={onchangeFormData}
      UpdateOTtable={UpdateOTtable}
      addRow={addRow}
      deleteRow={deleteRow} 
      onchangeDropDownFormData1={onchangeDropDownFormData1}
      onchangeDropDownFormData2={onchangeDropDownFormData2}
      onchangeDropDownFormData3={onchangeDropDownFormData3}
      onchangeDropDownFormData4={onchangeDropDownFormData4}
      em_CC_NM={em_CC_NM}
      uo_FY_YR={uo_FY_YR}
      uo_PE_NO={uo_PE_NO}
      em_DE_PT={em_DE_PT}
      uo_CO_NO={uo_CO_NO}
      uo_EM_NO={uo_EM_NO}
      SelectedemEMNO={SelectedemEMNO}
      setSelectedemEMNO={setSelectedemEMNO}
      handleConfirm={handleConfirm}
      leaveDaysList={leaveDaysList}
      emEMNOList={emEMNOList}
    />
  </div>
);
};
export default UpdateOverTimeMain;
