import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import AddressBookPopup from "../Popups/AddressBookPopup";
export default function LoginCredentialsDialog({
  open,
  handleClose,
  data,
  onchangedata,
  setFormData,
  handleFormSubmit,
  uc_EN_FL,
  setuc_EN_FL,
  onchangeFormData,
  SelectedabNO,
  setSelectedabNO
}) {
  const {
    ucUkNO,
    uc_UR_ID,
    uc_PS_WD,
    uc_AD_NO,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle} = ButtonStyles(); 
// dropdown
  const { fetchClassCodeDS } = ClassCode();
  const [uc_EN_FLOptions, setuc_EN_FLOptions] = useState([]);
  const onchangeDropDownFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setuc_EN_FL(value);
  };
  useEffect(() => {
    fetchDropdownEnbl();
  }, []);

  const fetchDropdownEnbl = async () => {
    const Options = await fetchClassCodeDS("FO/EN");
    setuc_EN_FLOptions(Options);
  };
  // ADVANCE SEARCH
  const [AddressPopupVisible, setAddressPopupVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const [addressTypeEmp, setaddressTypeEmp] = useState("E");
  const openAdNoPopup = () => {
    setAddressPopupVisible(true);
  };
  const handleDataSelectAdNO = (data) => {
    setSelectedabNO(data.ab_AD_NM);
    onchangeFormData("uc_AD_NO", data.abAdNo);
    setAddressPopupVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(ucUkNO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{ucUkNO ? "Revise Login Credentials" : "Add Login Credentials"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
    );
  return (
    <Dialog 
      visible={open}
      onHide={handleClose}
      style={{ width: "70rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle} >
          <div className="formgrid grid">
            <div  style={{display:"none"}}>
              <label htmlFor="ucUkNO" className="font-bold" >
                UK_no
              </label>
              <InputText  disabled
                id="ucUkNO"
                name="ucUkNO"
                value={ucUkNO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
              <label htmlFor="uc_UR_ID" className="font-bold">
              User Name
              </label>
              <InputText 
                id="uc_UR_ID"
                name="uc_UR_ID"
                value={uc_UR_ID}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
              <label htmlFor="uc_PS_WD" className="font-bold">
                Password
              </label>
              <InputText 
                id="uc_PS_WD"
                name="uc_PS_WD"
                value={uc_PS_WD}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
           <div className="formgrid grid">
            <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
              <label htmlFor="uc_AD_NO" className="font-bold">
                Address No <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: 'flex' }}>
              <InputText 
                id="ab_AD_NM"
                name="ab_AD_NM"
                onChange={(e) => onchangedata(e)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedabNO}
              />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: 'var(--primary-color)',
                    fontSize: '119%',
                    marginTop: '4%',
                    cursor: 'pointer',
                  }}
                  onClick={openAdNoPopup}
                ></i>
              )}
              </div>
              <InputText 
                style={{display:"none"}}
                id="uc_AD_NO"
                name="uc_AD_NO"
                value={uc_AD_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
              <label htmlFor="uc_EN_FL" className="font-bold">
                Enabled
              </label>
              <Dropdown
                id="uc_EN_FL"
                name="uc_EN_FL"
                value={uc_EN_FL}
                options={uc_EN_FLOptions}
                onChange={(e) => onchangeDropDownFormData("uc_EN_FL", e.value)}
                placeholder="Select Enabled"
              />
            </div>
          </div>
        </div>
      </div>
      <AddressBookPopup
        visible={AddressPopupVisible}
        onClose={() => setAddressPopupVisible(false)}
        onDataSelect={handleDataSelectAdNO}
        formData={data}
        addressType={addressTypeEmp}
      />
    </Dialog>
  );
}
