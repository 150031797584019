import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ButtonStyles from "views/common/ButtonStyles";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import ClassCode from "views/common/ClassCode";
export default function CreditHoldApprovalDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  as_OR_CO,
  FormData,
  setFormData,
  as_CO_CT,
  setas_CO_CT,
  as_RT_CD,
  setas_RT_CD,
  setas_AP_ST,
  as_AP_ST,
}) {
  const {
    asUkNo,
    as_RS_CD,
    as_OR_TY,
    as_OR_NO,
    as_AP_VR,
  } = data;
  const {borderbox,inboxstyle} = ButtonStyles();
// DROPDOWNS
// cost centre dropdown
const { fetchCostCenter } = Costcenter();
const [as_CO_CTOptions, setas_CO_CTOptions] = useState([]);
const onchangeDropDownFormData1 = (name, value) => {
  setFormData({ ...data, [name]: value });
  setas_CO_CT(value);
};
const fetchCostCentre = async () => {
  const CostOptions = await fetchCostCenter();
  setas_CO_CTOptions(CostOptions);
};
useEffect(() => {
  fetchCostCentre();
  fetchDropdownRT();
  fetchDropdownDataST();
}, []);

const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();

const [as_RT_CDOptions, setas_RT_CDOptions] = useState([]);
const [as_AP_STOptions, setas_AP_STOptions] = useState([]);
// HeaderUM
const onchangeDropDownFormData2 = (name, value) => {
  setFormData({ ...data, [name]: value });
  setas_RT_CD(value);
};
// HeaderUM
const onchangeDropDownFormData3 = (name, value) => {
  setFormData({ ...data, [name]: value });
  setas_AP_ST(value);
};
const fetchDropdownRT = async () => {
  const Options = await fetchClassCodeDS("FO/HD");
  setas_RT_CDOptions(Options);
};
// HEADERum
const fetchDropdownDataST = async () => {
  const UMOptions = await fetchClassCodeDS("AP/ST");
  setas_AP_STOptions(UMOptions);
};
const isUpdateMode = Boolean(asUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={ "Revise Batch Number"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox}>
          <div style={inboxstyle}>
              <div className="formgrid grid">
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="as_AP_VR" className="font-bold">
                   Approver
                  </label>
                  <InputText 
                    id="as_AP_VR"
                    name="as_AP_VR"
                    value={as_AP_VR}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="as_OR_NO" className="font-bold">
                    Order No
                  </label>
                  <InputText 
                    id="as_OR_NO"
                    name="as_OR_NO"
                    value={as_OR_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="as_OR_TY" className="font-bold">
                    Type
                  </label>
                  <InputText 
                    id="as_OR_TY"
                    name="as_OR_TY"
                    value={as_OR_TY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="as_OR_CO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="as_OR_CO"
                    name="as_OR_CO"
                    value={as_OR_CO}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="as_RT_CD" className="font-bold">
                    Route Code
                  </label>
                  <Dropdown
                      id="as_RT_CD"
                      name="as_RT_CD"
                      value={as_RT_CD}
                      options={as_RT_CDOptions}
                      onChange={(e) => onchangeDropDownFormData2("as_RT_CD", e.value)}
                      placeholder="Select Route Code"
                    />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="as_CO_CT" className="font-bold">
                    Cost Center
                  </label>
                  <Dropdown
                    id="as_CO_CT"
                    name="as_CO_CT"
                    value={as_CO_CT}
                    options={as_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormData1("as_CO_CT", e.value)}
                    placeholder="Select Cost Centre"
                  />
                </div>
                
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="as_AP_ST" className="font-bold">
                  Status
                  </label>
                  <Dropdown
                      id="as_AP_ST"
                      name="as_AP_ST"
                      value={as_AP_ST}
                      options={as_AP_STOptions}
                      onChange={(e) => onchangeDropDownFormData3("as_AP_ST", e.value)}
                      placeholder="Select Status"
                    />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="as_RS_CD" className="font-bold">
                  Reason Code
                  </label>
                  <InputText
                    id="as_RS_CD"
                    name="as_RS_CD"
                    value={as_RS_CD}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
         </div>
     
      </div> 
    </Dialog>
  );
}
