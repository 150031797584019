import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import SalesLeadDialog from "./SalesLeadDialog";
import axios from "axios";
import "jspdf-autotable";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import '../../../components/ListStyle/ListStyle.css';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";

const SalesLeadMain = () => {
  const initialValue = {
    slLdId: "",
    sl_TR_DT: moment(moment().toDate()).format("YYYYMMDD"),
    sl_CN_NO: "",
    sl_CO_NM: "",
    sl_TI_TL: "",
    sl_LD_OW: "",
    sl_LD_ST: "",
    ab_AD_P1: "",
    sl_PR_ST: "",
    lq_QA_AN:"",
    lq_QA_CM:"",
    lq_QA_QU:"",
    lq_QU_NO:"",
    pi_PR_CT:"",
    pi_PR_NO:"",
    sl_AD_P2:"",
  };
  const initialFilters = {
    slLdId: { value: "", operation: "" },
    sl_TR_DT: { value: "", operation: "" },
    sl_CN_NO: { value: "", operation: "" },
    sl_CO_NM: { value: "", operation: "" },
    sl_TI_TL: { value: "", operation: "" },
    sl_AD_P1: { value: "", operation: "" },
    sl_AD_EM: { value: "", operation: "" },
    sl_IN_RY: { value: "", operation: "" },
    //sl_CI_TY: { value: "", operation: "" },
    //sl_LD_OW: { value: "", operation: "" },
    //sl_LD_ST: { value: "", operation: "" },
  };
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [sl_LD_ST, setsl_ld_st] = useState("");
  const [sl_IN_RY, setsl_in_ry] = useState("");
  const [sl_CI_TY, setsl_ci_ty] = useState("");
  const [sl_LD_SO, setsl_ld_so] = useState("");
  const [sl_AD_CY, setsl_ad_cy] = useState("");
  const [sl_ST_AT, setsl_st_at] = useState("");
  const [slLdId, setsl_LD_ID] = useState("");
  const [sl_TI_TL, setsl_TI_TL] = useState("");
  const [cc_EM_IL, setcc_EM_IL] = useState("");
  const [cc_PH_NO, setcc_PH_NO] = useState("");
  const [sl_LD_TY, setsl_LD_TY] = useState("");
  const [sl_UK_NO, setsl_UK_NO] = useState("");
  const [sl_LD_CO, setsl_LD_CO] = useState("");
  const [sl_CO_NM, setsl_CO_NM] = useState("");
  const [sl_CN_NO, setsl_CN_NO] = useState("");
  const [sl_PR_ST, setsl_PR_ST] = useState("");
  const [sl_AD_L1, setsl_AD_L1] = useState("");
  const [sl_AD_L2, setsl_AD_L2] = useState("");
  const [sl_PO_CD, setsl_PO_CD] = useState("");
  const [sl_WB_SI, setsl_WB_SI] = useState("");
  const [sl_AD_EM, setsl_AD_EM] = useState("");
  const [sl_AD_P1, setsl_AD_P1] = useState("");
  const [sl_QA_FN, setsl_QA_FN] = useState("");
  const [sl_TH_SC,setsl_TH_SC] = useState("");
  const [sl_LD_OW, setsl_LD_OW]= useState("");
  const [lq_QA_AN, setlq_QA_AN] = useState("");
  const [lq_QA_CM, setlq_QA_CM] = useState("");
  const [lq_QA_QU, setlq_QA_QU] = useState("");
  const [lq_QU_NO, setlq_QU_NO] = useState("");

  const [Status, setStatus] = useState("");
  const [LeadOwnerPopupVisible, setLeadOwnerVisible] = useState(false);
  const [LeadCustomerPopupVisible, setLeadCustomerVisible] = useState(false);
  const [addressTypeCust, setaddressTypeCust] = useState("C");
  const [addressTypeLead, setaddressTypeLead] = useState("E");
  const [selectedowner, setSelectedowner] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [Owner, setOwner] = useState("");
  const [Customer, setCustomer] = useState("");
  const [FDate, setFDate] = useState("");
  const [selectedAbAdNN, setSelectedAbAdNN] = useState("");
  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [selectedAbAdNo, setSelectedAbAdNo] = useState("");
  const [selectedAbAdNp, setSelectedAbAdNp] = useState("");
  const [sl_LD_STOptions, setsl_LD_STOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const openLeadOwnerPopup = () => {
    setLeadOwnerVisible(true);
  };
  const openLeadCustomerPopup = () => {
    setLeadCustomerVisible(true);
  };
  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      sl_LD_OW: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const updateFilters2 = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      sl_CO_NM: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const updateFilters1 = (sts) => {
    let updatedFilters = { ...filters };
  if (sts !== null) {
    updatedFilters = {
      ...updatedFilters,
      sl_LD_ST: {
        value: sts,
        operation: "LIKE",
      },
    };
  }

  setFilters(updatedFilters);
  };
  const url11 = `${domain}/api/ClassCode/All`;
  const parameter2 = "LD/ST";
  const finalUrl2 = `${url11}/${parameter2}`;
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsl_LD_STOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;
  
        if (column === 'slLdId') {
          constructedColumn = 'slLdId';
        } else {
          constructedColumn = column.toUpperCase();
        }
  
        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;
  
    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }
  
    const updatedFilters = { ...filters };
    
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setQualificationTableData([{}]);
    setSelectedAbAdNN(null);
    setSelectedAbAdNM(null);
    setSelectedAbAdNo(null);
    setSelectedAbAdNp(null);
    setcc_PH_NO(null);
    setcc_EM_IL(null);
    setsl_AD_L1(null);
    setsl_AD_L2(null);
    setsl_AD_P1(null);
    setsl_PO_CD(null);
    setsl_WB_SI(null);
    setsl_AD_EM(null);
    setsl_CO_NM(null);
    setsl_CN_NO(null);
    setsl_in_ry(null);
    setsl_ci_ty(null);
    setsl_ld_so(null);
    setsl_ad_cy(null);
    setsl_st_at(null);
    setsl_LD_ID(null);
    setsl_TI_TL(null);
    setsl_UK_NO(null);
    setsl_QA_FN("");
    setlq_QA_AN(null);
    setOpen(true);
    setShowSearchIcon(false);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setQualificationTableData([{}]);
    setSelectedAbAdNN(null);
    setSelectedAbAdNM(null);
    setSelectedAbAdNo(null);
    setSelectedAbAdNp(null);
    setsl_in_ry(null);
    setsl_ci_ty(null);
    setsl_ld_so(null);
    setsl_ad_cy(null);
    setsl_st_at(null);
    setsl_LD_ID(null);
    setsl_TI_TL(null);
    setOpen(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect = (data) => {
    setSelectedowner(data.ab_AD_NM);
    setOwner( data.abAdNo);
    setLeadOwnerVisible(false);
    setShowSearchIcon(false);
    updateFilters (data.abAdNo);
  };
  const handleDataSelectCustomer = (data) => {
    setSelectedCustomer(data.ab_AD_NM);
    setCustomer( data.abAdNo);
    setLeadCustomerVisible(false);
    setShowSearchIcon(false);
    updateFilters2 (data.abAdNo);
  };
  const onchangeDropDown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setStatus(value);
    updateFilters1 (value);
    console.log(value);
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/SalesLead/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const SalesLeadData = response.data;
          delete SalesLeadData.sl_UK_NO;
          delete SalesLeadData.slLdId;
          setFormData(SalesLeadData);
          setSelectedAbAdNN(SalesLeadData.ab_AD_NM);
          setSelectedAbAdNM(SalesLeadData.cc_EM_CO);
          setSelectedAbAdNo(SalesLeadData.cc_EM_NM);
          setSelectedAbAdNp(SalesLeadData.ab_LD_PA);
          setsl_CN_NO(SalesLeadData.sl_CN_NO);
          setsl_CO_NM(SalesLeadData.sl_CO_NM);
          setsl_ld_st(SalesLeadData.sl_LD_ST);
          setsl_in_ry(SalesLeadData.sl_IN_RY);
          setsl_ci_ty(SalesLeadData.sl_CI_TY);
          setsl_ld_so(SalesLeadData.sl_LD_SO);
          setsl_ad_cy(SalesLeadData.sl_AD_CY);
          setsl_st_at(SalesLeadData.sl_ST_AT);
          setsl_LD_ID(SalesLeadData.slLdId);
          setsl_TI_TL(SalesLeadData.sl_TI_TL);
          setcc_EM_IL(SalesLeadData.cc_EM_IL);
          setcc_PH_NO(SalesLeadData.cc_PH_NO);
          setsl_AD_L1(SalesLeadData.sl_AD_L1);
          setsl_AD_L2(SalesLeadData.sl_AD_L2);
          setsl_PO_CD(SalesLeadData.sl_PO_CD);
          setsl_WB_SI(SalesLeadData.sl_WB_SI);
          setsl_AD_EM(SalesLeadData.sl_AD_EM);
          setsl_AD_P1(SalesLeadData.sl_AD_P1);
          setsl_LD_OW(SalesLeadData.sl_LD_OW);
          setsl_QA_FN(SalesLeadData.sl_QA_FN);
          setsl_LD_OW(SalesLeadData.sl_LD_OW);
          setsl_UK_NO(null);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
    fetchDropdownOptions2();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/SalesLead/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            setsl_LD_CO(`${company}`)
            if(fName === "SL_LD_TY"){
              setsl_LD_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SL_LD_ST"){
              onchangeDropDownFormData("sl_LD_ST",dataDefault.versionIds[i].default_VALUE);
              setsl_ld_st(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SL_TH_SC"){
              setsl_TH_SC(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SL_PR_ST"){
              onchangeDropDownFormData7("sl_PR_ST",dataDefault.versionIds[i].default_VALUE);
              setsl_PR_ST(dataDefault.versionIds[i].default_VALUE);
            } 
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    // setFormData({ ...FormData, [name]: value });
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setFDate(value);
  };
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    if(name=="sl_CO_NM")
      setsl_CO_NM(value);
    if(name=="sl_CN_NO")
      setsl_CN_NO(value);
      if(name=="sl_LD_OW")
      setsl_LD_OW(value);
    if(name=="sl_ST_AT") { setsl_st_at(value) };
    if(name=="sl_AD_L1") { setsl_AD_L1(value) };
    if(name=="sl_AD_L2") { setsl_AD_L2(value) };
    if(name=="sl_PO_CD") { setsl_PO_CD(value) };
    if(name=="sl_WB_SI") { setsl_WB_SI(value) };
    if(name=="sl_AD_EM") { setsl_AD_EM(value) };
    if(name=="sl_AD_P1") { setsl_AD_P1(value) };
    if(name=="cc_EM_IL") { setcc_EM_IL(value) };
    if(name=="cc_PH_NO") { setcc_PH_NO(value) };
  };
  const onchangeDropDownFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsl_ld_st(value);
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsl_in_ry(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsl_ci_ty(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsl_ad_cy(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsl_st_at(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsl_ld_so(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsl_TI_TL(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsl_PR_ST(value);
  };
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsl_QA_FN(value);
  };
  const onchangeDropDownFormData9 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlq_QA_AN(value);
  };
  const [QualificationTableData, setQualificationTableData] = useState([{}]);

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/SalesLead/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const SalesLeadData = response.data;
      
        // SETTING
        const ledgerDtls = [];
        const sizeQa = SalesLeadData.leadQualificationList.length;
        if (SalesLeadData.leadQualificationList && sizeQa > 0) {
          const requestData={
            "sl_QA_FN": SalesLeadData.sl_QA_FN,
          }
          axios.post(`${domain}/api/SalesLead/onChangeOfQualification`, requestData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            const dataLead = resp.data.data;
            const size = dataLead.length;
            for (let j = 0; j < sizeQa; j++){
                if (dataLead && size > 0) {
                  for (let i = 0; i < size; i++){
                    console.log(SalesLeadData.leadQualificationList[j].lq_QU_NO);
                    console.log(dataLead[i].sqSqNo);
                    if(SalesLeadData.leadQualificationList[j].lq_QU_NO === dataLead[i].sqSqNo){
                      const ans = dataLead[i].qualificationAnswers;
                      const options = ans.map((item) => ({
                        label: item.sa_QA_AN,
                        value: item.sa_QA_AN,
                      }));
                      const dtl = {
                        lq_QU_NO: SalesLeadData.leadQualificationList[j].lq_QU_NO,
                        lq_QA_QU: SalesLeadData.leadQualificationList[j].lq_QA_QU,
                        lq_QA_AN: SalesLeadData.leadQualificationList[j].lq_QA_AN, 
                        lq_QA_CM: SalesLeadData.leadQualificationList[j].lq_QA_CM, 
                        answerOptions: options,
                      };
            
                      ledgerDtls.push(dtl);
                    }
                }
                
              } else {
                console.error("Invalid API response:" );
              }
            }
            
          setQualificationTableData(ledgerDtls);
          setFormData(ledgerDtls[0]);

          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });  
        }
          setQualificationTableData(SalesLeadData.leadQualificationList);
          setSelectedAbAdNN(response.data.ab_AD_NM);
          setSelectedAbAdNM(response.data.cc_EM_CO);
          setSelectedAbAdNo(response.data.cc_EM_NM);
          setSelectedAbAdNp(response.data.ab_LD_PA);
          setsl_CN_NO(SalesLeadData.sl_CN_NO);
          setsl_CO_NM(SalesLeadData.sl_CO_NM);
          setsl_ld_st(response.data.sl_LD_ST);
          setsl_in_ry(response.data.sl_IN_RY);
          setsl_ci_ty(response.data.sl_CI_TY);
          setsl_ld_so(response.data.sl_LD_SO);
          setsl_ad_cy(response.data.sl_AD_CY);
          setsl_st_at(response.data.sl_ST_AT);
          setsl_LD_ID(response.data.slLdId);
          setsl_TI_TL(response.data.sl_TI_TL);
          setcc_EM_IL(response.data.cc_EM_IL);
          setcc_PH_NO(SalesLeadData.cc_PH_NO);
          setsl_PR_ST(response.data.sl_PR_ST);
          setsl_AD_L1(SalesLeadData.sl_AD_L1);
          setsl_AD_L2(SalesLeadData.sl_AD_L2);
          setsl_PO_CD(SalesLeadData.sl_PO_CD);
          setsl_WB_SI(SalesLeadData.sl_WB_SI);
          setsl_AD_EM(SalesLeadData.sl_AD_EM);
          setsl_AD_P1(SalesLeadData.sl_AD_P1);
          setsl_UK_NO(SalesLeadData.sl_UK_NO);
          setsl_LD_OW(SalesLeadData.sl_LD_OW);
          setsl_QA_FN(SalesLeadData.sl_QA_FN);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  var requestBodyInput = {};
  function values() {
    const leadQualificationList = QualificationTableData.map((row, i) => ({
        lq_UK_NO : row.lq_UK_NO,
        lq_QU_NO : row.question_NO,
        lq_QA_QU : row.question,
        lq_QA_AN : FormData[`answer_${i}`],
        lq_QA_CM : FormData[`comment_${i}`],
    }));
      const piDtList = {
        pi_PR_CT : FormData.pi_PR_CT,
        pi_PR_NO : FormData.pi_PR_NO,        
      }
    requestBodyInput = {
      sl_UK_NO : document.getElementById("sl_UK_NO").value,
      slLdId: document.getElementById("slLdId").value,
      sl_TR_DT: FormData.sl_TR_DT,
      sl_CO_NM: document.getElementById("sl_CO_NM").value,
      sl_CN_NO: document.getElementById("sl_CN_NO").value,
      sl_TI_TL: sl_TI_TL,
      sl_AD_P1: sl_AD_P1,
      sl_LD_OW: document.getElementById("sl_LD_OW").value,
      sl_LD_PA: FormData.sl_LD_PA,
      sl_AD_L1: document.getElementById("sl_AD_L1").value,
      sl_AD_L2: document.getElementById("sl_AD_L2").value,
      sl_PO_CD: document.getElementById("sl_PO_CD").value,
      sl_AD_EM: document.getElementById("sl_AD_EM").value,
      sl_WB_SI: document.getElementById("sl_WB_SI").value,
      sl_PR_ST: FormData.sl_PR_ST,
      sl_LD_SO: sl_LD_SO,
      sl_LD_ST: sl_LD_ST,
      sl_IN_RY: sl_IN_RY,
      sl_CI_TY: sl_CI_TY,
      sl_ST_AT: sl_ST_AT,
      sl_AD_CY: sl_AD_CY,
      sl_LD_CO: company,
      sl_LD_TY: sl_LD_TY,
      sl_TH_SC: sl_TH_SC,
      sl_QA_SC: document.getElementById("sl_QA_SC").value,
      sl_QA_FN: FormData.sl_QA_FN,
      leadQualificationList, 
      piDtList   
    };
  };
  const CheckQualificationLead = async () => {
    const leadQualificationList = QualificationTableData.map((row, index) => ({
      "lq_QA_AN": FormData[`answer_${index}`],
      "lq_QU_NO": row.question_NO,
    }));
    const Request = {
      "sl_QA_FN": sl_QA_FN,
      "sl_TH_SC": sl_TH_SC,
      "leadQualificationList": leadQualificationList,
    };  
   try {
     const response =await axios.put(`${domain}/api/SalesLead/CheckQualificationLead`, Request, {
         headers: {
           Authorization: `Bearer ${token}`,
           "Content-Type": "application/json",
           "Access-Control-Allow-Origin": "http://localhost:3000",
           "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
         },
       })
       if (response.status !== 200) {
         throw new Error(`API request failed with status: ${response.status}`);
       }
         const dataTotal = response.data.Result;
         if (dataTotal) {
           onchangeFormData("sl_LD_ST", dataTotal.sl_LD_ST);
           onchangeFormData("sl_QA_SC", dataTotal.sl_QA_SC);
         }else {
         console.error("Invalid API response:", dataTotal);
       }
     } catch (error) {
       console.error("Error fetching data:", error);
     }
  };
  //AutoLeadAssignment
  const AutoLeadAssignment = async () => {
    const Request = {
      "sl_IN_RY": sl_IN_RY,
    };  
  try {
    const response =await axios.put(`${domain}/api/SalesLead/AutoLeadAssignment`, Request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`,
          "x-flag":  localStorage.getItem("userType"),
        },
      })
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
        const datares = response.data.Result;
        if (datares) {        
          setSelectedAbAdNo(datares.ab_AD_NM);
          document.getElementById("sl_LD_OW").value = datares.abAdNo;
        }else {
        console.error("Invalid API response:", datares);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {
    values();

    CheckQualificationLead();
    AutoLeadAssignment();
    if (FormData.slLdId) {
    if (FormData.sl_UK_NO) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/SalesLead/Update`;
      if (confirm) {
        axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
            CheckQualificationLead();
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/SalesLead/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
      } else {
        const OR_DT = FormData.sl_TR_DT;
        if(OR_DT){
          const currentYear = OR_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${sl_LD_TY}/${secondHalf}/${company}/${firstHalf}`;  
          const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
              setsl_LD_ID(resp.dn_DO_NO);
              document.getElementById("slLdId").value = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/SalesLead/Add`, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`,
                    "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
      }
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  }
  const leftToolbarTemplate = (
    <React.Fragment>
    <Tooltip target=".update-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "red !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Update
      </div>
    </Tooltip>
    <Button
      style={buttonStyles}
      icon="pi pi-check"
      outlined
      className="mr-2 update-button"
      onClick={handleUpdate}
      disabled={!isRecordSelected}
    ></Button>
   
    <Tooltip target=".add-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "white !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Add
      </div>
    </Tooltip>
    <Button
      style={addButtonStyles}
      icon="pi pi-plus"
      outlined
      className="mr-2 add-button"
      onClick={handleAddButtonClick}
    ></Button>
    <Tooltip target=".copy-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "white !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Copy
      </div>
    </Tooltip>
    <Button
      style={copyButtonStyles}
      icon="pi pi-copy"
      outlined
      className="mr-2 copy-button"
      onClick={handleCopyData}
    ></Button>
  </React.Fragment>
  );

  const tableHeaderStyle1 = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width:"0%"
  };
  const tableHeaderStyleH = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f5f4f4",
    display:"none"
  };
  const tableCellStyleH = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    display:"none"
  };

  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
    backgroundColor:"#f5f4f4"
  };

const exportExcel = () => {
import("xlsx").then((xlsx) => {
  const worksheet = xlsx.utils.json_to_sheet(tableData);
  const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  const excelBuffer = xlsx.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  const data = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });
  saveAs(data, "products_export.xlsx");
});
};
const rightToolbarTemplate = (
<React.Fragment>
  <Button style={{height: "35px",backgroundColor:"#1ed01e",border:"none"}}
    icon="pi pi-upload"
    className="p-button-help"
    rounded
    onClick={exportExcel}
    data-pr-tooltip="XLS"
  />
</React.Fragment>
);
const AdvanceSearch = (
<div className="formgrid grid">
<div className="field col-lg-4 col-md-6 col-12 pe-md-2">
<label htmlFor="Customer" className="font-bold">
Customer <span style={{ color: 'red' }}>*</span>
</label>
<div style={{ display: "flex" }}>
  <InputText
    style={{display:"none"}}
    id="Customer"
    name="Customer"
    value={Customer}
    onChange={(Event) => onchangedata(Event)}
  />
  <InputText
    id="ab_AD_NM"
    name="ab_AD_NM"
    style={{backgroundColor:"#ffffff"}}
    onChange={(Event) => onchangedata(Event)}
    onFocus={() => setShowSearchIcon(true)}
    value={selectedCustomer}
  />
  {showSearchIcon && (
  <i
    className="pi pi-search"
    style={{
      color: "var(--primary-color)",
      fontSize: "119%",
      marginTop: "4%",
      cursor: "pointer",
    }}
    onClick={openLeadCustomerPopup}
  ></i>
  )}
</div>
</div>
<div className="field col-lg-4 col-md-6 col-12 pe-md-2">
<label htmlFor="Owner" className="font-bold">
  Owner <span style={{ color: 'red' }}>*</span>
</label>
<div style={{ display: "flex" }}>
  <InputText
    style={{display:"none"}}
    id="Owner"
    name="Owner"
    value={Owner}
    onChange={(Event) => onchangedata(Event)}
  />
  <InputText
    id="cc_EM_NM"
    name="cc_EM_NM"
    style={{backgroundColor:"#ffffff"}}
    onChange={(Event) => onchangedata(Event)}
    onFocus={() => setShowSearchIcon(true)}
    value={selectedowner}
  />
  {showSearchIcon && (
  <i
    className="pi pi-search"
    style={{
      color: "var(--primary-color)",
      fontSize: "119%",
      marginTop: "4%",
      cursor: "pointer",
    }}
    onClick={openLeadOwnerPopup}
  ></i>
  )}
</div>
</div>
<div className="field col-lg-4 col-md-6 col-12 pe-md-2" style={{display:"grid"}}>
      <label htmlFor="Status" className="font-bold">
        Status
      </label>
      <Dropdown
        id="Status"
        name="Status"
        value={Status}
        options={sl_LD_STOptions}
        onChange={(e) => onchangeDropDown("Status", e.value)}
        placeholder="Select Status"
      />
</div>
</div>
);
const handleClearButton=()=>{
  setCustomer("");
  setOwner("");
  setStatus(null);
  setSelectedCustomer("");
  setSelectedowner("");
  setFilters("");
}

const rightClear = (
  <React.Fragment>
    <Button
      style={{ height: "35px" }}
      icon="pi pi-times"
      className="p-button-help"
      link
      onClick={handleClearButton}
    />
  </React.Fragment>
);
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
        <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
        </div>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["slLdId"] ? filters["slLdId"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "slLdId",
                        e.target.value,
                        filters["slLdId"]
                          ? filters["slLdId"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_TR_DT"] ? filters["sl_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_TR_DT",
                        e.target.value,
                        filters["sl_TR_DT"]
                          ? filters["sl_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_CN_NO"] ? filters["sl_CN_NO"].value : ""}
                    readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_CN_NO",
                        e.target.value,
                        filters["sl_CN_NO"]
                          ? filters["sl_CN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_CO_NM"] ? filters["sl_CO_NM"].value : ""}
                    readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_CO_NM",
                        e.target.value,
                        filters["sl_CO_NM"]
                          ? filters["sl_CO_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_TI_TL"] ? filters["sl_TI_TL"].value : ""}
                    readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_TI_TL",
                        e.target.value,
                        filters["sl_TI_TL"]
                          ? filters["sl_TI_TL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_AD_P2"] ? filters["sl_AD_P2"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_AD_P2",
                        e.target.value,
                        filters["sl_AD_P2"]
                          ? filters["sl_AD_P2"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_AD_EM"] ? filters["sl_AD_EM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_AD_EM",
                        e.target.value,
                        filters["sl_AD_EM"]
                          ? filters["sl_AD_EM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_IN_RY"] ? filters["sl_IN_RY"].value : ""}
                    readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_IN_RY",
                        e.target.value,
                        filters["sl_IN_RY"]
                          ? filters["sl_IN_RY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                {/* <td>
                  <input
                    type="text"
                    value={filters["sl_CI_TY"] ? filters["sl_CI_TY"].value : ""}
                    readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_CI_TY",
                        e.target.value,
                        filters["sl_CI_TY"]
                          ? filters["sl_CI_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td> */}
                <td style={{display:"none"}}>
                  <input
                    width="100%"
                    type="text"
                    readOnly
                    style={inputStyleH}
                    
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_EM_NM"] ? filters["cc_EM_NM"].value : ""}
                    readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_EM_NM",
                        e.target.value,
                        filters["cc_EM_NM"]
                          ? filters["cc_EM_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    width="100%"
                    type="text"
                    readOnly
                    style={inputStyleH}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle1}></th>
                <th style={tableHeaderStyle}>Lead ID</th>
                <th style={tableHeaderStyle}>Date</th>  
                <th style={tableHeaderStyle}>Lead Owner</th>                 
                <th style={{display:"none"}} >Customer</th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={{display:"none"}}>Contact number</th>
                <th style={tableHeaderStyle}>Contact</th>
                <th style={tableHeaderStyle}>Title</th>
                <th style={tableHeaderStyle}>Phone</th>
                <th style={tableHeaderStyle}>Email</th>
                <th style={tableHeaderStyle}>Industry</th>
                <th style={tableHeaderStyleH}>Lead Owner No</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.sl_UK_NO}
                    style={{
                      backgroundColor: index % 2 === 0 ? 'aliceblue' : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        type="checkbox"
                        value={rowData.sl_UK_NO}
                        checked={selectedRows.includes(rowData.sl_UK_NO)}
                        onChange={() => handleRowSelect(rowData.sl_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.slLdId}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sl_TR_DT)}</td>                     
                    <td style={tableCellStyle}>{rowData.cc_EM_NM}</td>                    
                    <td style={tableCellStyleH}>{rowData.sl_LD_OW}</td>                   
                    <td  style={{display:"none"}}>{rowData.sl_CO_NM}</td>
                    <td style={tableCellStyle}>{rowData.ab_AD_NM}</td>
                    <td style={{display:"none"}}>{rowData.sl_CN_NO}</td>
                    <td style={tableCellStyle}>{rowData.cc_EM_CO}</td>
                    {/* <td style={tableCellStyle}>{rowData.sl_CO_PE}</td> */}
                    <td style={tableCellStyle}>{rowData.cc_DE_GN}</td>
                    <td style={tableCellStyle}>{rowData.cc_PH_NO}</td>
                    <td style={tableCellStyle}>{rowData.sl_AD_EM}</td>
                    <td style={tableCellStyle}>{rowData.industry}</td>
                    <td style={tableCellStyle}>{rowData.cl_LD_ST}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      {/* lead owner */}
      <AddressBookPopup
        visible={LeadOwnerPopupVisible}
        onClose={() => setLeadOwnerVisible(false)}
        onDataSelect={handleDataSelect}
        formData={FormData}
        addressType={addressTypeLead}
        setFormData={setFormData}
      />
      {/* lead customer */}
      <AddressBookPopup
        visible={LeadCustomerPopupVisible}
        onClose={() => setLeadCustomerVisible(false)}
        onDataSelect={handleDataSelectCustomer}
        formData={FormData}
        addressType={addressTypeCust}
        setFormData={setFormData}
      />
      <SalesLeadDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData={onchangeDropDownFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        onchangeDropDownFormData8={onchangeDropDownFormData8}
        sl_IN_RY={sl_IN_RY}
        sl_CI_TY={sl_CI_TY}
        sl_LD_ST={sl_LD_ST}
        sl_AD_CY={sl_AD_CY}
        sl_ST_AT={sl_ST_AT}
        sl_LD_SO={sl_LD_SO}
        slLdId={slLdId}
        sl_TI_TL={sl_TI_TL}
        cc_EM_IL={cc_EM_IL}
        sl_LD_CO= {sl_LD_CO}
        sl_LD_TY={sl_LD_TY}
        sl_CO_NM={sl_CO_NM}
        sl_CN_NO={sl_CN_NO}
        sl_PR_ST={sl_PR_ST}
        sl_AD_L1={sl_AD_L1}
        sl_AD_L2={sl_AD_L2}
        sl_PO_CD={sl_PO_CD}
        sl_WB_SI={sl_WB_SI}
        sl_AD_EM={sl_AD_EM}
        sl_AD_P1={sl_AD_P1}
        cc_PH_NO={cc_PH_NO}
        sl_UK_NO={sl_UK_NO}
        sl_QA_FN={sl_QA_FN}
        setsl_QA_FN={setsl_QA_FN}
        setFormData={setFormData}
        selectedAbAdNM={selectedAbAdNM}
        selectedAbAdNN={selectedAbAdNN}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedAbAdNN={setSelectedAbAdNN}
        selectedAbAdNo={selectedAbAdNo}
        selectedAbAdNp={selectedAbAdNp}
        setSelectedAbAdNo={setSelectedAbAdNo}
        setSelectedAbAdNp={setSelectedAbAdNp} 
        // setsl_ci_ty={setsl_ci_ty}
        buildSearchRequestDto={buildSearchRequestDto}
        QualificationTableData={QualificationTableData}
        setQualificationTableData={setQualificationTableData}
        lq_QA_AN={lq_QA_AN}
        lq_QA_CM={lq_QA_CM}
        lq_QA_QU={lq_QA_QU}
        lq_QU_NO={lq_QU_NO}
        onchangeDropDownFormData9={onchangeDropDownFormData9}
        sl_TH_SC={sl_TH_SC}
        sl_LD_OW={sl_LD_OW}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
      />
    </div>
  );
};

export default SalesLeadMain;
