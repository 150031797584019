import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import ClassCode from "views/common/ClassCode";
import { Container, Row, Col } from 'react-bootstrap';
import ButtonStyles from "views/common/ButtonStyles";
import { InputMask } from "primereact/inputmask";
export default function ContractCoverageDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  setFormData,
  handleFormSubmit,
  cc_CO_NO,
  cc_CL_CD1,
  cc_CL_CD2,
  cc_CL_CD3,
  cc_CL_CD4,
  cc_CL_CD5,
  setcc_CL_CD1,
  setcc_CL_CD2,
  setcc_CL_CD3,
  setcc_CL_CD4,
  setcc_CL_CD5,
}) {
  const {
    ccUkNo, 
    cc_CV_DS, 
    cc_CV_TY, 
    cc_TU_ST, 
    cc_TH_ET,
    cc_SU_ST, 
    cc_WD_ST, 
    cc_WD_ET, 
    cc_TH_ST, 
    cc_FR_ET, 
    cc_SA_ST, 
    cc_SA_ET,
    cc_HO_ET, 
    cc_MO_ET, 
    cc_HO_ST,
    cc_FR_ST, 
    cc_SU_ET, 
    cc_MO_ST, 
    cc_TU_ET, 
    
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
    // Handle form data change
    const onChangeTime=(name,value,index)=>{
      value= moment(value, "HH:mm:ss").format("HHmmss");
      onchangeFormData(name, value,index);
    };
  const [activeTab, setActiveTab] = useState(0);
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [cc_CL_CD5Options, setcc_CL_CD5Options] = useState([]);
  const [cc_CL_CD4Options, setcc_CL_CD4Options] = useState([]);
  const [cc_CL_CD3Options, setcc_CL_CD3Options] = useState([]);
  const [cc_CL_CD2Options, setcc_CL_CD2Options] = useState([]);
  const [cc_CL_CD1Options, setcc_CL_CD1Options] = useState([]);
// classcode 1
 const onchangeDropDownFormData1 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setcc_CL_CD1(value);
};
// classcode 2
const onchangeDropDownFormData2 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setcc_CL_CD2(value);
};
// classcode 3
const onchangeDropDownFormData3 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setcc_CL_CD3(value);
};
// classcode 4
const onchangeDropDownFormData4 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setcc_CL_CD4(value);
};
// classcode 5
const onchangeDropDownFormData5 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setcc_CL_CD5(value);
};
useEffect(() => {
  fetchDropdownCC1();
  fetchDropdownCC2();
  fetchDropdownCC3();
  fetchDropdownCC4();
  fetchDropdownCC5();
}, []);
const fetchDropdownCC1 = async () => {
  const Options = await fetchClassCodeDS("CN/1");
  setcc_CL_CD5Options(Options);
};
const fetchDropdownCC2 = async () => {
  const Options = await fetchClassCodeDS("CN/2");
  setcc_CL_CD4Options(Options);
};
const fetchDropdownCC3 = async () => {
  const Options = await fetchClassCodeDS("CN/3");
  setcc_CL_CD3Options(Options);
};
const fetchDropdownCC4 = async () => {
  const Options = await fetchClassCodeDS("CN/4");
  setcc_CL_CD2Options(Options);
};
const fetchDropdownCC5 = async () => {
  const Options = await fetchClassCodeDS("CN/5");
  setcc_CL_CD1Options(Options);
};
const isUpdateMode = Boolean(ccUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{ ccUkNo ? "Revise Contract Coverage" : "Add Contract Coverage" }</h5>
    <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
        tooltip={isUpdateMode ? "Update" : "Submit"}
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={inboxstyle} >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="General">
            {/* tab1 general */}
            <div  style={inboxstyle} >
              <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cc_CV_TY" className="font-bold">
                    Coverage Type
                  </label>
                  <InputText
                    id="cc_CV_TY"
                    name="cc_CV_TY"
                    value={cc_CV_TY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cc_CV_DS" className="font-bold">
                    Description
                  </label>
                  <InputText
                    id="cc_CV_DS"
                    name="cc_CV_DS"
                    value={cc_CV_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
            </div>
            <div style={inboxstyle}>
            <Container fluid>
              <Row className="weekday-row">
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    
                  </label>
                </Col>
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    Monday
                  </label>
                </Col>
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    Tuesday
                  </label>
                </Col>
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    Wednesday
                  </label>
                </Col>
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    Thursday
                  </label>
                </Col>
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    Friday
                  </label>
                </Col>
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    Saturday
                  </label>
                </Col>
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    Sunday
                  </label>
                </Col>
              </Row>
              {/* 2nd */}
              <Row className="time-row" style={{marginTop:"2%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    Begining Time
                  </label>
                </Col>
                <Col md>
                <InputMask
                  id={cc_MO_ST}
                  name={cc_MO_ST}
                  mask="99:99:99"
                  onBlur={(e) => { onChangeTime("cc_MO_ST",e.target.value,);}}
                  value={moment(cc_MO_ST, "HHmmss").format("HH:mm:ss")}
                />
                </Col>
                <Col md>
                <InputMask
                  id={cc_TU_ST}
                  name={cc_TU_ST}
                  mask="99:99:99"
                  onBlur={(e) => { onChangeTime("cc_TU_ST",e.target.value,);}}
                  value={moment(cc_TU_ST, "HHmmss").format("HH:mm:ss")}
                />
                </Col>
                <Col md>
                <InputMask
                  id={cc_WD_ST}
                  name={cc_WD_ST}
                  mask="99:99:99"
                  onBlur={(e) => { onChangeTime("cc_WD_ST",e.target.value,);}}
                  value={moment(cc_WD_ST, "HHmmss").format("HH:mm:ss")}
                />
                </Col>
                <Col md>
                <InputMask
                  id={cc_TH_ST}
                  name={cc_TH_ST}
                  mask="99:99:99"
                  onBlur={(e) => { onChangeTime("cc_TH_ST",e.target.value,);}}
                  value={moment(cc_TH_ST, "HHmmss").format("HH:mm:ss")}
                />
                </Col>
                <Col md>
                <InputMask
                  id={cc_FR_ST}
                  name={cc_FR_ST}
                  mask="99:99:99"
                  onBlur={(e) => { onChangeTime("cc_FR_ST",e.target.value,);}}
                  value={moment(cc_FR_ST, "HHmmss").format("HH:mm:ss")}
                />
                </Col>
                <Col md>
                <InputMask
                  id={cc_SA_ST}
                  name={cc_SA_ST}
                  mask="99:99:99"
                  onBlur={(e) => { onChangeTime("cc_SA_ST",e.target.value,);}}
                  value={moment(cc_SA_ST, "HHmmss").format("HH:mm:ss")}
                />
                </Col>
                <Col md>
                <InputMask
                  id={cc_SU_ST}
                  name={cc_SU_ST}
                  mask="99:99:99"
                  onBlur={(e) => { onChangeTime("cc_SU_ST",e.target.value,);}}
                  value={moment(cc_SU_ST, "HHmmss").format("HH:mm:ss")}
                />
                </Col>
              </Row>
              {/* 3rd */}
              <Row className="time-row" style={{marginTop:"2%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    Ending Time
                  </label>
                </Col>
                <Col md>
                <InputMask
                  id={cc_MO_ET}
                  name={cc_MO_ET}
                  mask="99:99:99"
                  onBlur={(e) => { onChangeTime("cc_MO_ET",e.target.value,);}}
                  value={moment(cc_MO_ET, "HHmmss").format("HH:mm:ss")}
                />
                </Col>
                <Col md>
                <InputMask
                  id={cc_TU_ET}
                  name={cc_TU_ET}
                  mask="99:99:99"
                  onBlur={(e) => { onChangeTime("cc_TU_ET",e.target.value,);}}
                  value={moment(cc_TU_ET, "HHmmss").format("HH:mm:ss")}
                />
                </Col>
                <Col md>
                <InputMask
                  id={cc_WD_ET}
                  name={cc_WD_ET}
                  mask="99:99:99"
                  onBlur={(e) => { onChangeTime("cc_WD_ET",e.target.value,);}}
                  value={moment(cc_WD_ET, "HHmmss").format("HH:mm:ss")}
                />
                </Col>
                <Col md>
                <InputMask
                  id={cc_TH_ET}
                  name={cc_TH_ET}
                  mask="99:99:99"
                  onBlur={(e) => { onChangeTime("cc_TH_ET",e.target.value,);}}
                  value={moment(cc_TH_ET, "HHmmss").format("HH:mm:ss")}
                />
                </Col>
                <Col md>
                <InputMask
                  id={cc_FR_ET}
                  name={cc_FR_ET}
                  mask="99:99:99"
                  onBlur={(e) => { onChangeTime("cc_FR_ET",e.target.value,);}}
                  value={moment(cc_FR_ET, "HHmmss").format("HH:mm:ss")}
                />
                </Col>
                <Col md>
                <InputMask
                  id={cc_SA_ET}
                  name={cc_SA_ET}
                  mask="99:99:99"
                  onBlur={(e) => { onChangeTime("cc_SA_ET",e.target.value,);}}
                  value={moment(cc_SA_ET, "HHmmss").format("HH:mm:ss")}
                />
                </Col>
                <Col md>
                <InputMask
                  id={cc_SU_ET}
                  name={cc_SU_ET}
                  mask="99:99:99"
                  onBlur={(e) => { onChangeTime("cc_SU_ET",e.target.value,);}}
                  value={moment(cc_SU_ET, "HHmmss").format("HH:mm:ss")}
                />
                </Col>
              </Row>
            </Container>
            </div>  
        </TabPanel>
          {/* 2nd table */}
        <TabPanel header="Class Code">
          <div style={inboxstyle} >
            <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cc_CL_CD1" className="font-bold">
                Class Code 1
                </label>
                <Dropdown
                  name="cc_CL_CD1"
                  value={cc_CL_CD1}
                  options={cc_CL_CD1Options}
                  onChange={(e) => onchangeDropDownFormData1("cc_CL_CD1", e.value)}
                  placeholder="Select Class Code 01"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cc_CL_CD2" className="font-bold">
                Class Code 2
                </label>
                <Dropdown
                  name="cc_CL_CD2"
                  value={cc_CL_CD2}
                  options={cc_CL_CD2Options}
                  onChange={(e) => onchangeDropDownFormData2("cc_CL_CD2", e.value)}
                  placeholder="Select Class Code 02"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cc_CL_CD3" className="font-bold">
                Class Code 3
                </label>
                <Dropdown
                  name="cc_CL_CD3"
                  value={cc_CL_CD3}
                  options={cc_CL_CD3Options}
                  onChange={(e) => onchangeDropDownFormData3("cc_CL_CD3", e.value)}
                  placeholder="Select Class Code 03"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cc_CL_CD4" className="font-bold">
                Class Code 4
                </label>
                <Dropdown
                  name="cc_CL_CD4"
                  value={cc_CL_CD4}
                  options={cc_CL_CD4Options}
                  onChange={(e) => onchangeDropDownFormData4("cc_CL_CD4", e.value)}
                  placeholder="Select Class Code 04"
                />
                
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cc_CL_CD5" className="font-bold">
                Class Code 5
                </label>
                <Dropdown
                  name="cc_CL_CD5"
                  value={cc_CL_CD5}
                  options={cc_CL_CD5Options}
                  onChange={(e) => onchangeDropDownFormData5("cc_CL_CD5", e.value)}
                  placeholder="Select Class Code 05"
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
      </div>    
     
    </Dialog>
  );
}
