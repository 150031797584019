import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
export default function AlternativeDescriptionDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  AlternativeDescriptionTableData,
  setAlternativeDescriptionTableData,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  ad_AD_TY,
  setad_AD_TY,
  ad_CO_NO,
}) {
  const {
    adUkNo,
    ep_EF_DT,
    os_SQ_DS,
  } = data;
  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [ad_AD_TYOptions, setad_AD_TYOptions] = useState([]);


  //Type
  const TypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_AD_TY(value);
  };
  useEffect(() => {
    Type();
  }, []);

  const Type = async () => {
    const Options = await fetchClassCodeCD("IN/AD");
    setad_AD_TYOptions(Options);
  };


  const addRow = () => {
    setAlternativeDescriptionTableData([...AlternativeDescriptionTableData, {}]);
  };

  const deleteRow = () => {
    if (AlternativeDescriptionTableData.length > 0) {
      const updatedData = [...AlternativeDescriptionTableData];
      updatedData.pop();
      setAlternativeDescriptionTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(adUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
        tooltip={isUpdateMode ? "Update" : "Submit"}>
      </Button>
    </React.Fragment>
  );

  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleItem = async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    onchangeFormData("ad_IT_NM", data.im_IT_NM);
    onchangeFormData("itemDES", data.im_IT_DS);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={adUkNo ? "Revise Alternative Description" : "Add Alternative Description"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div className="formgrid grid">
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ad_IT_NM" className="font-bold">
              Item No <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
                id="ad_IT_NM"
                name="ad_IT_NM"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedItem}
              />
              {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openItemPopup}
                ></i>
              )}
            </div>
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="itemDES" className="font-bold">
              Description
            </label>
            <InputText disabled
              id="itemDES"
              name="itemDES"
              value={SelectedDesc}
              readOnly
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ad_CO_NO" className="font-bold">
              Company
            </label>
            <InputText disabled
              id="ad_CO_NO"
              name="ad_CO_NO"
              value={ad_CO_NO}
              onChange={(Event) => onchangedata(Event)}
            />
          </div>

        </div>
      </div>
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
      >

        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{ display: "flex", overflow: "auto" }}>
          <table className="custom-table" id="AlternativeDescriptiontable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Type</th>
                <th>Alternative Description</th>
              </tr>
            </thead>
            <tbody>
              {AlternativeDescriptionTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`adUkNo_${index}`}
                      name={`adUkNo_${index}`}
                      value={row.adUkNo}
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`ad_AD_TY_${index}`}
                      name={`ad_AD_TY_${index}`}
                      value={data[`ad_AD_TY_${index}`] ? data[`ad_AD_TY_${index}`] : row.ad_AD_TY}
                      options={ad_AD_TYOptions}
                      onChange={(e) => TypeDropdown(`ad_AD_TY_${index}`, e.value)}
                      placeholder="Select type"
                    />
                  </td>
                  <td >
                    <InputText
                      id={`ad_IT_AD_${index}`}
                      name={`ad_IT_AD_${index}`}
                      defaultValue={row.ad_IT_AD}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
