import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import Costcenter  from "views/common/CostCentreDrpDwn";
import PropertyInfoPopup from "../Popups/PropertyInformationPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function BuildingInformationDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  // onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  addRow,
  deleteRow,
  BuildingInformationtable,
  selectedFacilityaddress,
  SelectedemEMNO,
  setSelectedemEMNO,
  bi_CO_NO,
  bi_PR_NO,
  bi_TX_AR,
  bi_TX_RT,
  biUkNo,
  setFormData,
  bi_FA_AD,
  setbi_ST_DT,
  pt_DESC,
}) {
  const { 
    //biUkNo,
    bi_BL_NO,
    bi_RM_AD,
    bi_PR_CT,
    bi_TR_QT,
    bi_TR_UM,
    bi_ST_DT,
    bi_EN_DT,
    bi_PR_AR,
    bu_DESC,
    fac_name,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter, fetchCostCenterP } = Costcenter();
  const [Addresstype, setAddresstype] = useState("F");
  const [bi_TX_AROptions, setbi_TX_AROptions] = useState([]);
  const [bi_TX_RTOptions, setbi_TX_RTOptions] = useState([]);
  const [bi_PR_AROptions, setbi_PR_AROptions] = useState([]);
  const [bi_BL_NOOptions, setbi_BL_NOOptions] = useState([]);
  const [bi_TR_UMOptions,setbi_TR_UMOptions]=useState([]);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData2(name, value, index);
  };
  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData3(name, value, index);
  };
  const handleFormDataChange2 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData6(name, value, index);
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "bi_ST_DT"  || name === "bi_EN_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);
  
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };

  const [propertyPopupVisible, setpropertyPopupVisible] = useState(false);
  const openpropertyPopup = () => {
    setpropertyPopupVisible(true);
  };
  const handlePropertySelect = (content) => {
    document.getElementById("bi_PR_NO").value =  content.pi_PR_NO;
    document.getElementById("pt_DESC").value =  content.pt_DESC;
    document.getElementById("bi_FA_AD").value =  content.pi_FA_AD;
    document.getElementById("SelectedemEMNO").value =  content.fac_NAME;
    onchangeDropDownFormData5("bi_TX_RT", content.pi_TX_RT);
    onchangeDropDownFormData6("bi_TX_AR", content.pi_TX_AR);

    BuildingInformationtable.forEach(s=>{
      s['bi_ST_DT']=content.pi_ST_DT;
      s['bi_EN_DT']=content.pi_EN_DT;
    });
    setbi_ST_DT(content.pi_ST_DT)
    setpropertyPopupVisible(false);
    setShowSearchIcon(false);
  };


  useEffect(() => {
    fetchBuilding();
    fetchareatype();
    fetchUM();
    fetchtaxar();
    fetchtxrt();
  }, []);

  const fetchBuilding = async () => {
    const Options = await fetchCostCenterP("BL");
    setbi_BL_NOOptions(Options);
  };

  const fetchareatype = async () => {
    const Options = await fetchClassCodeDS("BL/AR");
    setbi_PR_AROptions(Options);
  };

  const fetchUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setbi_TR_UMOptions(Options);
  };

  const fetchtxrt = async () => {
    const Options = await fetchClassCodeDS("TX/RT");
    setbi_TX_RTOptions(Options);
  };

  const fetchtaxar = async () => {
    const Options = await fetchClassCodeDS("FO/TA");
    setbi_TX_AROptions(Options);
  };

  const isUpdateMode = Boolean(biUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{biUkNo ? "Revise Building Information" : "Add Building Information"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle} >
          <div className="formgrid grid">
         <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="bi_PR_NO" className="font-bold">
          Property <span style={{ color: 'red' }}>*</span>
          </label>
          <div style={{display:"flex"}}>
          <InputText
            style={{display:"none"}}
            id="bi_PR_NO"
            name="bi_PR_NO"
            defaultValue={bi_PR_NO}
          /> 
          <InputText
            id="pt_DESC"
            name="pt_DESC"
            onChange={(Event) => onchangedata(Event)}
            onFocus={() => setShowSearchIcon(true)}
            defaultValue={pt_DESC}
          /> 
           {showSearchIcon && (
               <i className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openpropertyPopup}
              ></i>
               )}
          </div>
          </div> 
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bi_FA_AD" className="font-bold">
            Facility Address 
            </label>
            <InputText disabled
            style={{display:"none"}}
              id="bi_FA_AD"
              name="bi_FA_AD"
              defaultValue={bi_FA_AD}
              onChange={(Event) => onchangedata(Event)}
            />
             <InputText disabled
              id="SelectedemEMNO"
              name="SelectedemEMNO"
              defaultValue={SelectedemEMNO}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bi_CO_NO" className="font-bold">
            Company
            </label>
            <InputText disabled
              id="bi_CO_NO"
              name="bi_CO_NO"
              defaultValue={bi_CO_NO}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bi_TX_RT" className="font-bold">
            Tax Rate
            </label>
            <Dropdown disabled
              id="bi_TX_RT"
              name="bi_TX_RT"
              value={bi_TX_RT}
              options={bi_TX_RTOptions}
              onChange={(e) => onchangeDropDownFormData5("bi_TX_RT", e.value)}
              placeholder="Select Tax Rate"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bi_TX_AR" className="font-bold">
            Tax Area
            </label>
            <Dropdown disabled
              id="bi_TX_AR"
              name="bi_TX_AR"
              value={bi_TX_AR}
              options={bi_TX_AROptions}
              onChange={(e) => onchangeDropDownFormData6("bi_TX_AR", e.value)}
              placeholder="Select Tax Area"
            />
          </div>
          </div>
        </div>
      </div>   
      <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
        <table className="custom-table" id="BuildingInformationtable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Building</th>
          <th>Area Type</th>
          <th>Description</th>
          <th>Area</th>
          <th>UM</th>
           <th>Start Date</th>
          <th>End Date</th>
        </tr>
      </thead>
      <tbody>
      {BuildingInformationtable.map((row, index) => (
        <tr key={index}>
          <td style={{ display: "none" }} >
            <InputText
              id={`biUkNo_${index}`}
              name={`biUkNo_${index}`}
              defaultValue={row.biUkNo}
            />
          </td>
          <td>
            <Dropdown
              id={`bi_BL_NO_${index}`}
              name={`bi_BL_NO_${index}`}
              value={data[`bi_BL_NO_${index}`] ? data[`bi_BL_NO_${index}`]:row.bi_BL_NO }
              options={bi_BL_NOOptions}
              onChange={(e) => handleFormDataChange(`bi_BL_NO_${index}`, e.value)}
              placeholder="Select Building"
            />
          </td>
            <td>
            <Dropdown
              id={`bi_PR_AR_${index}`}
              name={`bi_PR_AR_${index}`}
              value={data[`bi_PR_AR_${index}`] ? data[`bi_PR_AR_${index}`]:row.bi_PR_AR }
              options={bi_PR_AROptions}
              onChange={(e) => handleFormDataChange1(`bi_PR_AR_${index}`, e.value)}
              placeholder="Select Area"
            />
          </td>
          <td>
          <InputText 
            id={`bi_AD_DS_${index}`}
            name={`bi_AD_DS_${index}`}
            defaultValue={row.bi_AD_DS}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText 
            id={`bi_TR_QT_${index}`}
            name={`bi_TR_QT_${index}`}
            defaultValue={row.bi_TR_QT}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <Dropdown
              id={`bi_TR_UM_${index}`}
              name={`bi_TR_UM_${index}`}
              value={data[`bi_TR_UM_${index}`] ? data[`bi_TR_UM_${index}`]:row.bi_TR_UM }
              options={bi_TR_UMOptions}
              onChange={(e) => handleFormDataChange2(`bi_TR_UM_${index}`, e.value)}
              placeholder="Select UM"
            />
          </td>
          <td>
         <Calendar  
             id={`bi_ST_DT_${index}`}
             name={`bi_ST_DT_${index}`}
             value={ row.bi_ST_DT ? moment(row.bi_ST_DT, "YYYYMMDD").toDate() : null }
             onChange={(e) => onDateChange({ target: { name: "bi_ST_DT", value: moment(e.value).format("YYYYMMDD") } })}
             dateFormat="dd/mm/yy"
           />
         </td>
         <td>
         <Calendar
             id={`bi_EN_DT_${index}`}
             name={`bi_EN_DT_${index}`}
             value={ row.bi_EN_DT ? moment(row.bi_EN_DT, "YYYYMMDD").toDate() : null }
             onChange={(e) => onDateChange({ target: { name: "bi_EN_DT", value: moment(e.value).format("YYYYMMDD") } })}
             dateFormat="dd/mm/yy"
           />
         </td>
          </tr>
           ))}
      </tbody>
    </table>
    </div>
  </div>   
  {/* <AddressBookPopup
    visible={FacilityAddressPopupVisible}
    onClose={() => setFacilityAddressPopupVisible(false)}
    onDataSelect={handleDataSelect}
    addressType={Addresstype}
    formData={data}
    setFormData= {setFormData}
  /> */}
  <PropertyInfoPopup
    visible={propertyPopupVisible}
    onClose={() => setpropertyPopupVisible(false)}
    onDataSelect={handlePropertySelect}
  />
  </Dialog>
  );
}
