import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import GLDistribution from "./GLDistribution";
import moment from "moment";
import axios from "axios";
export default function SupplierLedgerDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  setFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData4,  
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  addRow,
  sl_DO_CO,
  sl_DO_TY,
  deleteRow,
  SupplierLedgertable,
  onchangeFormData,
  setSelectedemEMNO,
  SelectedemEMNO,
  sl_PY_CO,
  sl_CR_CD,
  sl_TX_CD,
  sl_CR_CO,
  sl_CO_NM,
  setsl_CO_NM,
  setsl_PY_CO,
  sl_SU_NO,
  setsl_SU_NO,
  isGLSaved,
  sl_ST_CD
}) {
  const {
    sl_UK_NO,
    slDoNo,
    sl_CR_RT,
    sl_SU_IN,
    cl_RE_FR,
    sl_BA_TY,
    sl_BA_NO,
    sl_IN_DT,
    sl_GL_DT,
    sl_DD_DT

  } = data;
  const [sl_CO_NMOptions, setsl_CO_NMOptions] = useState([]);
  const [sl_OR_TYOptions, setsl_OR_TYOptions] = useState([]);
  const [sl_CR_CDOptions, setsl_CR_CDOptions] = useState([]);
  const [sl_PY_COOptions, setsl_PY_COOptions] = useState([]);
  const [sl_TX_CDOptions, setsl_TX_CDOptions] = useState([]);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [transCurr, settransCurr] = useState("");
  const [PaymentTerm, setPaymentTerm] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");


  // const [open, setOpen] = useState(false);
  const [GLDistributionVisible, setGLDistributionVisible] = useState(false);
  const openGLDistribution = () => {
      setGLDistributionVisible(true);
  };
  const handleFormDataChange1 = (name, value) => {
    const index = name.split("_")[3];
    onchangeDropDownFormData1(name, value, index);
  };
  // const handleFormDataChange5 = (name, value) => {
  //   const index = name.split("_")[3];
  //   onchangeDropDownFormData6(name, value, index);
  // };
  const handleFormDataChange2 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData2(name, value, index);
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };
  const handleFormDataChange3 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData4(name, value, index);
    setFormData({ ...FormData, [name]: value });
  };
  const handleFormDataChange4 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData5(name, value, index);
    setFormData({ ...FormData, [name]: value });
    setPaymentTerm(value);
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    // console.log("Received input:", name, value); // Log received input
    // if (name === "sl_GL_DT" || name === "sl_DD_DT" || name === "sl_IN_DT") {
    //   const formattedDate = moment(value, "YYYYMMDD", true).format("YYYYMMDD");
    //   if (moment(formattedDate, "YYYYMMDD").isValid()) {
    //     console.log("Formatted date:", formattedDate); // Log formatted date
    //     onchangeFormData(name, formattedDate);
    //   } else {
    //     console.error("Invalid date format:", value);
    //   }
    // } else {
      onchangeFormData(name, value);
    // }
  };
  
  
  
  const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
  const [addressTypeSup, setaddressTypeSup] = useState("S");
  const openSupplierPopup = (e) => {
    setSupplierPopupVisible(true);
    setSelectedRowIndex(e);
  };
  //  startsearchpopup
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    setsl_SU_NO(data.abAdNo);
    
    const Url = `${domain}/api/SupplierMaster/GetById`;
    const requestData = {
      "su_SU_NO": data.abAdNo
    };
  
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
  
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        // Update FormData state with the received values
        onchangeDropDownFormData5("sl_PY_CO", dataCuMt.su_PT_TM);
        onchangeDropDownFormData4("sl_CR_CD", dataCuMt.su_CR_CD);  
        onchangeDropDownFormData6("sl_TX_CD", dataCuMt.su_TX_CD);
        setPaymentTerm(dataCuMt.su_PT_TM);
        settransCurr(dataCuMt.su_CR_CD);
        setsl_PY_CO(dataCuMt.su_PT_TM);
        const due={
          "pt_PY_CO": dataCuMt.su_PT_TM,
          "inp_DATE": sl_IN_DT
        }

        axios.post(`${domain}/api/PaymentTerm/onChangeOfPaymentTerm`, due, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              const dueDate = resp.data.inp1;
              if (dueDate) {
                onchangeFormData("be_DU_DT", dueDate.out_DATE);
                  const exData = {
                    "cr_TO_CR": document.getElementById("sl_CR_CO").value,
                    "cr_FR_CR": dataCuMt.su_CR_CD,
                    "cr_EF_DT": sl_IN_DT
                  };
        exchangeRate(exData);
      }
    });
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  
    setSupplierPopupVisible(false);
  };
  
  function exchangeRate(exData) {
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("sl_CR_RT").value = dataExRt.cr_CO_MR;
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  }

  const onCHangeOfTaxableAmount = async(SelectedRowIndex) => {
    const flag = 'T'
    document.getElementById (`sl_GR_AM_${SelectedRowIndex}`).value =  ''; 
	const table = document.getElementById("SupplierLedgertable");
	const taxCodeValue = table.tBodies[0].rows[SelectedRowIndex].cells[5].children[0].children[1].children[0].value;
    onchangetax(SelectedRowIndex,flag,taxCodeValue);
  };

  const onChnageOfGrossAmount = async(SelectedRowIndex) => {
    const flag = 'G'
    document.getElementById (`sl_TA_AM_${SelectedRowIndex}`).value =  ''; 
	const table = document.getElementById("SupplierLedgertable");
	const taxCodeValue = table.tBodies[0].rows[SelectedRowIndex].cells[5].children[0].children[1].children[0].value;
    onchangetax(SelectedRowIndex,flag,taxCodeValue);
  };
  const handleFormDataChange5 = (name, value,SelectedRowIndex) => {
    const index = name.split("_")[3];
    onchangeDropDownFormData6(name, value, index);
    onChangeOfTaxCode(SelectedRowIndex,value);
  };

  const onChangeOfTaxCode = async(SelectedRowIndex,taxCodeValue) => {
	  if(sl_CR_CD === sl_CR_CO){
		document.getElementById (`sl_TX_AM_${SelectedRowIndex}`).value =  ''; 
		const grossAmount  = document.getElementById (`sl_GR_AM_${SelectedRowIndex}`).value;
		  const taxableAmount = document.getElementById (`sl_TA_AM_${SelectedRowIndex}`).value;
		if(grossAmount) { 
		  document.getElementById (`sl_TA_AM_${SelectedRowIndex}`).value =  '';
		} else if(taxableAmount){
		  document.getElementById (`sl_GR_AM_${SelectedRowIndex}`).value =  ''; 
		}
		const flag = 'C';
		onchangetax(SelectedRowIndex,flag,taxCodeValue);
	  } else {
		document.getElementById (`sl_TX_FR_${SelectedRowIndex}`).value =  ''; 
		const grossAmount  = document.getElementById (`sl_CR_AM_${SelectedRowIndex}`).value;
		const taxableAmount = document.getElementById (`sl_TA_FR_${SelectedRowIndex}`).value;
		if(grossAmount) { 
		  document.getElementById (`sl_TA_FR_${SelectedRowIndex}`).value =  '';
		} else if(taxableAmount){
		  document.getElementById (`sl_CR_AM_${SelectedRowIndex}`).value =  ''; 
		}
		const flag = 'C';
		calculateTaxOnFore(SelectedRowIndex,flag,taxCodeValue);
	  }
  }

  const onchangetax = async(SelectedRowIndex,flag,taxCodeValue) => {
    const Urltax = `${domain}/api/SupplierLedger/GetTaxDetails`;
        const taxInput = {
          "sl_GR_AM": parseFloat(document.getElementById (`sl_GR_AM_${SelectedRowIndex}`).value),
          "sl_TX_CD": taxCodeValue,
          "sl_TA_AM": parseFloat(document.getElementById (`sl_TA_AM_${SelectedRowIndex}`).value),
          "sl_SU_NO": document.getElementById("sl_SU_NO").value
        }
          try {
            const response =await axios.post(Urltax, taxInput, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            });
        
            if (response.status !== 200) {
              throw new Error(`API request failed with status: ${response.status}`);
            }
            const datatax = response.data.Result;
            if (datatax ) {
              document.getElementById("sl_SU_NO").value =  datatax.sl_SU_NO;
              document.getElementById (`sl_TX_AM_${SelectedRowIndex}`).value =  datatax.sl_TX_AM; 
              if(flag === 'G' || flag === 'C'){
                document.getElementById (`sl_TA_AM_${SelectedRowIndex}`).value =  datatax.sl_TA_AM;
              }
              if(flag === 'T' || flag === 'C'){
                document.getElementById (`sl_GR_AM_${SelectedRowIndex}`).value =  datatax.sl_GR_AM;
              }
              
              document.getElementById (`sl_CR_AM_${SelectedRowIndex}`).value =  '';
              document.getElementById (`sl_TX_FR_${SelectedRowIndex}`).value =  '';
              document.getElementById (`sl_TA_FR_${SelectedRowIndex}`).value =  '';          
             
            } else {
              console.error("Invalid API response:", data);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
  };

  const onChangeOfForeTaxableAmount = async(SelectedRowIndex) => {
    const flag = 'T'
    document.getElementById (`sl_CR_AM_${SelectedRowIndex}`).value =  '';
	const table = document.getElementById("SupplierLedgertable");
	const taxCodeValue = table.tBodies[0].rows[SelectedRowIndex].cells[5].children[0].children[1].children[0].value; 
    calculateTaxOnFore(SelectedRowIndex,flag,taxCodeValue);
  };
  const onChnageOfForeGrossAmount = async(SelectedRowIndex) => {
    const flag = 'G'
    document.getElementById (`sl_TA_FR_${SelectedRowIndex}`).value =  ''; 
	const table = document.getElementById("SupplierLedgertable");
	const taxCodeValue = table.tBodies[0].rows[SelectedRowIndex].cells[5].children[0].children[1].children[0].value;
    calculateTaxOnFore(SelectedRowIndex,flag,taxCodeValue);
  };

  const calculateTaxOnFore = async(SelectedRowIndex,flag,taxCodeValue) => {
    const Urltaxfore = `${domain}/api/SupplierLedger/onChangeOfForeignGrossAmount`;
      const taxInput = {
        "sl_CR_RT" : document.getElementById ("sl_CR_RT").value,
        "sl_CR_AM": document.getElementById (`sl_CR_AM_${SelectedRowIndex}`).value,
        "sl_TX_CD": taxCodeValue,
        "sl_TA_FR": document.getElementById (`sl_TA_FR_${SelectedRowIndex}`).value,
      }
        try {
          const response =await axios.post(Urltaxfore, taxInput, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          });
      
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
          const datataxfore = response.data.Result;
          if (datataxfore ) { 
            document.getElementById (`sl_TX_FR_${SelectedRowIndex}`).value =  datataxfore.sl_TX_FR; 
            if(flag === 'G' || flag === 'C'){
              document.getElementById (`sl_TA_FR_${SelectedRowIndex}`).value =  datataxfore.sl_TA_FR;
            }
            if(flag === 'T' || flag === 'C'){
              document.getElementById (`sl_CR_AM_${SelectedRowIndex}`).value =  datataxfore.sl_CR_AM;
            }
            document.getElementById (`sl_GR_AM_${SelectedRowIndex}`).value =  datataxfore.sl_GR_AM;
            document.getElementById (`sl_TA_AM_${SelectedRowIndex}`).value =  datataxfore.sl_TA_AM;
            document.getElementById (`sl_TX_AM_${SelectedRowIndex}`).value =  datataxfore.sl_TX_AM;
          } else {
            console.error("Invalid API response:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
};


  
  const urlpayterm = `${domain}/api/PaymentTerm/GetAll`;
  const finalUrl4 = `${urlpayterm}`;
  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl1 = `${urlcost}`;


  const url = `${domain}/api/ClassCode/All`;

  const parameter2 = "FO/DT";
  const finalUrl2 = `${url}/${parameter2}`;

  const parameter3 = "FO/CR";
  const finalUrl3 = `${url}/${parameter3}`;

  // Pay Frequency
const parameter5 = "TX/RT";
const finalUrl5 = `${url}/${parameter5}`;
  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
  }, []);



  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setsl_CO_NMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setsl_OR_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 3rdd dropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setsl_CR_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 4th dropdown
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.pt_PY_DS,
        value: item.pt_PY_CO,
      }));
      setsl_PY_COOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 5th dropdown
  const fetchDropdownOptions5 = async () => {
    try {
      const response = await axios.get(finalUrl5, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setsl_TX_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };


  const isUpdateMode = Boolean(sl_UK_NO);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-plus"
        variant="outlined"
        onClick={openGLDistribution}
        tooltip="GL"
        disabled={!isUpdateMode}
      ></Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
        tooltip={isUpdateMode ? "Update" : "Submit"}
        disabled={!isGLSaved}>
      </Button>
    </React.Fragment>
  );
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "sl_IN_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
// gl date
const [selectedDateGL, setSelectedDateGL] = useState(moment().toDate());
const handleDateChangeGL = (e) => {
  setSelectedDateGL(e.value);
  onchangedata({ target: { name: "sl_GL_DT", value: moment(e.value).format("YYYYMMDD") } });
};
const isDisabled = sl_ST_CD === 20;
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={sl_UK_NO ? "Revise Supplier Ledger" : "Add Supplier Ledger"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div className="formgrid grid">
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="slDoNo" className="font-bold">
              Document No
            </label>
            <InputText disabled
              id="slDoNo"
              name="slDoNo"
              value={slDoNo}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_DO_TY" className="font-bold">
              Type
            </label>
            <InputText disabled
              id="sl_DO_TY"
              name="sl_DO_TY"
              value={sl_DO_TY}
              readOnly
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_DO_CO" className="font-bold">
              Company
            </label>
            <InputText  disabled
              id="sl_DO_CO"
              name="sl_DO_CO"
              defaultValue={sl_DO_CO}
              readOnly
            />
          </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_IN_DT" className="font-bold">
                Invoice Date
            </label>
            <Calendar disabled={isDisabled}
            id="sl_IN_DT"
            name="sl_IN_DT"
            value={moment(sl_IN_DT, "YYYYMMDD").toDate()}
            onChange={handleDateChange}
            dateFormat="dd/mm/yy"
          />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="sl_GL_DT" className="font-bold">
              GL Date
          </label>
            <Calendar disabled={isDisabled}
              id="sl_GL_DT"
              name="sl_GL_DT"
              value={moment(sl_GL_DT, "YYYYMMDD").toDate()}
              onChange={handleDateChangeGL}
              dateFormat="dd/mm/yy"
            />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_DD_DT" className="font-bold">
                Due Date
            </label>
            <Calendar disabled={isDisabled}
              id="sl_DD_DT"
              name="sl_DD_DT"
              value={moment(data.sl_DD_DT, "YYYYMMDD").isValid() ? moment(data.sl_DD_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: {name: "sl_DD_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
              dateFormat="dd/mm/yy"
            />
        </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_SU_NO" className="font-bold">
              Supplier  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText disabled={isDisabled}
                id="ab_AD_NM"
                name="ab_AD_NM"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNO}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openSupplierPopup}
              ></i>
              )}
            </div>
            <div style={{display:"none"}}>
              <InputText  disabled={isDisabled}
                id="sl_SU_NO"
                name="sl_SU_NO"
                value={sl_SU_NO}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openSupplierPopup}
              ></i>
            </div>
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_PY_CO" className="font-bold">
              Pay Term
            </label>
            <Dropdown disabled={isDisabled}
              id="sl_PY_CO"
              name="sl_PY_CO"
              value={sl_PY_CO}
              options={sl_PY_COOptions}
              onChange={(e) => handleFormDataChange4("sl_PY_CO", e.value)}
              placeholder="Select Pay Term"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_CO_NM" className="font-bold">
            Cost Centre
            </label>
            <Dropdown disabled={isDisabled}
              id="sl_CO_NM"
              name="sl_CO_NM"
              value={sl_CO_NM}
              options={sl_CO_NMOptions}
              onChange={(e) => { setsl_CO_NM(e.value);
                onchangeDropDownFormData2("sl_CO_NM", e.value); 
              }}
              placeholder="Select Cost Centre"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_CR_CD" className="font-bold">
              Currency
            </label>
            <Dropdown disabled={isDisabled}
              id="sl_CR_CD"
              name="sl_CR_CD"
              value={sl_CR_CD}
              options={sl_CR_CDOptions}
              onChange={(e) => onchangeDropDownFormData4("sl_CR_CD", e.value)}
              placeholder="Select Currency"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_CR_RT" className="font-bold">
              Exchange Rate
            </label>
            <InputText disabled
              id="sl_CR_RT"
              name="sl_CR_RT"
              defaultValue={sl_CR_RT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_CR_CO" className="font-bold">
              Base Currency
            </label>
            <InputText disabled id="sl_CR_CO" name="sl_CR_CO" value={sl_CR_CO}/>
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="sl_SU_IN" className="font-bold">
              Supplier PO
            </label>
            <InputText disabled={isDisabled}
              id="sl_SU_IN"
              name="sl_SU_IN"
              value={sl_SU_IN}
              onChange={(e) => onchangedata(e)}
            />
          </div>          
        </div>
      </div>
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
          }}
        >
            <Button
              icon="pi pi-plus"
              onClick={addRow}
              style={{
                marginRight: "8px",
                backgroundColor: "transparent",
                color: "black",
                border: "none",
              }}
              tooltip="Add"
            />
            <Button
              icon="pi pi-minus"
              onClick={deleteRow}
              style={{
                backgroundColor: "transparent",
                color: "black",
                border: "none",
              }}
              tooltip="Delete"
            />
        </div>
        <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
            position: "relative",
          }}
        >
          <table className="custom-table" id="SupplierLedgertable" style={{ overflow: "auto" }}>
            <thead>
              <tr>
                <th style={{ display: "none" }} >UK_NO</th>
                <th>Line No</th>
                <th>Gross Amount</th>
                <th>Taxable Amount</th>
                <th>Tax</th>
                <th>Tax Code</th>
                <th>Discount</th>
                <th>Fore. Gross Amount</th>
                <th>Fore. Taxable Amount</th>
                <th>Fore. Tax</th>
                <th>Fore. Discount</th>
                <th>Rel. No</th>
                <th>Rel. Type</th>
                <th>Rel. Co</th>
              </tr>
            </thead>
            <tbody>
              {SupplierLedgertable.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }} >
                    <InputText
                      id={`sl_UK_NO_${index}`}
                      name={`sl_UK_NO_${index}`}
                      defaultValue={row.sl_UK_NO}
                    />
                  </td>
                  <td>
                    <InputText style={{ width: "90px" }} disabled
                      id={`sl_DO_PI_${index}`}
                      name={`sl_DO_PI_${index}`}
                      value={index + 1}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </td>
                   <td>
                      <InputText
                        disabled={sl_ST_CD != "20" && data.sl_CR_CD==sl_CR_CO?false:true}
                        style={{ width: "110px" }}
                        id={`sl_GR_AM_${index}`}
                        name={`sl_GR_AM_${index}`}
                        defaultValue={data[`sl_GR_AM_${index}`] ? data[`sl_GR_AM_${index}`] : row.sl_GR_AM}
                        onChange={(e) => {
                          onchangedata(e);
                          onChnageOfGrossAmount(index);                      
                        }}
                      />
                    </td>
                  <td>
                  <InputText
                        disabled={ sl_ST_CD != "20" && data.sl_CR_CD==sl_CR_CO?false:true}
                        style={{ width: "110px" }}
                        id={`sl_TA_AM_${index}`}
                        name={`sl_TA_AM_${index}`}
                        defaultValue={data[`sl_TA_AM_${index}`] ? data[`sl_TA_AM_${index}`] : row.sl_TA_AM}
                        onChange={(e) => {
                        onchangedata(e);
                        onCHangeOfTaxableAmount(index);                      
                      }}
                      />
                  </td>
                  <td>
                    <InputText disabled 
                      style={{ width: "90px" }}
                      id={`sl_TX_AM_${index}`}
                      name={`sl_TX_AM_${index}`}
                      defaultValue={data[`sl_TX_AM_${index}`] ? data[`sl_TX_AM_${index}`] : row.sl_TX_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                  <Dropdown disabled={isDisabled}
                    id={`sl_TX_CD_${index}`}
                    name={`sl_TX_CD_${index}`}
                    value={ data[`sl_TX_CD_${index}`] ? data[`sl_TX_CD_${index}`]: row.sl_TX_CD}
                    options={sl_TX_CDOptions}
                    onChange={(e) =>handleFormDataChange5(`sl_TX_CD_${index}`, e.value)}
                    placeholder="Select Tax Code"
                  />
                  </td>

                  <td>
                    <InputText style={{ width: "90px" }} disabled={isDisabled}
                      id={`sl_DI_AM_${index}`}
                      name={`sl_DI_AM_${index}`}
                      defaultValue={row.sl_DI_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                 <InputText 
                 disabled={sl_ST_CD != "20" && data.sl_CR_CD!=sl_CR_CO?false:true}  
                   style={{ width: "90px" }}
                   id={`sl_CR_AM_${index}`}
                   name={`sl_CR_AM_${index}`}
                   defaultValue={data[`sl_CR_AM_${index}`] ? data[`sl_CR_AM_${index}`] : row.sl_CR_AM}
                      onChange={(e) => {
                        onchangedata(e);
                        onChnageOfForeGrossAmount(index);                      
                      }}
                 />
               </td>
               <td>
                 <InputText 
                 disabled={sl_ST_CD != "20" && data.sl_CR_CD!=sl_CR_CO?false:true}
                   style={{ width: "90px" }}
                   id={`sl_TA_FR_${index}`}
                   name={`sl_TA_FR_${index}`}
                   defaultValue={data[`sl_TA_FR_${index}`] ? data[`sl_TA_FR_${index}`] : row.sl_TA_FR}
                      onChange={(e) => {
                        onchangedata(e);
                        onChangeOfForeTaxableAmount(index);                      
                      }}
                 />
               </td>
                  <td>
                    <InputText 
                    disabled={sl_ST_CD != "20" && data.sl_CR_CD!=sl_CR_CO?false:true}
                      style={{ width: "90px" }}
                      id={`sl_TX_FR_${index}`}
                      name={`sl_TX_FR_${index}`}
                      defaultValue={data[`sl_TX_FR_${index}`] ? data[`sl_TX_FR_${index}`] : row.sl_TX_FR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <InputText 
                    disabled={sl_ST_CD != "20" && data.sl_CR_CD!=sl_CR_CO?false:true}
                      style={{ width: "90px" }}
                      id={`sl_DI_FR_${index}`}
                      name={`sl_DI_FR_${index}`}
                      defaultValue={data[`sl_DI_FR_${index}`] ? data[`sl_DI_FR_${index}`] : row.sl_DI_FR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <InputText style={{ width: "90px" }} disabled={isDisabled}
                      id={`sl_OR_NO_${index}`}
                      name={`sl_OR_NO_${index}`}
                      defaultValue={row.sl_OR_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <Dropdown disabled={isDisabled}
                    id={`sl_OR_TY_${index}`}
                    name={`sl_OR_TY_${index}`}
                    value={ data[`sl_OR_TY_${index}`] ? data[`sl_OR_TY_${index}`]: row.sl_OR_TY}
                    options={sl_OR_TYOptions}
                   onChange={(e) =>handleFormDataChange1(`sl_OR_TY_${index}`, e.value)}
                    placeholder="Select Rel Type"
                  />
                  </td>
                  <td>
                    <InputText disabled={isDisabled} style={{ width: "90px" }}
                      id={`sl_OR_CO_${index}`}
                      name={`sl_OR_CO_${index}`}
                      defaultValue={row.sl_OR_CO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>

                </tr>
              ))}
            </tbody>
          </table>

        </div>
      </div>
      <AddressBookPopup
        visible={SupplierPopupVisible}
        onClose={() => setSupplierPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypeSup}
        setFormData={setFormData}
      />
      <GLDistribution
        // open={open}
        // setOpen={setOpen}
        visible={GLDistributionVisible}
        onClose={() => setGLDistributionVisible(false)}
        data={data}
        gl_DO_NO={slDoNo}
        gl_DO_TY={sl_DO_TY}
        gl_DO_CO={sl_DO_CO}
        handleCloseMain={handleClose}
        onchangeFormData
        onchangeDropDownFormData1
        onchangeDropDownFormData2
        onchangeDropDownFormData3
        onchangeDropDownFormData4
        onchangeDropDownFormData5
        onchangeDropDownFormData6
        setSelectedDesc
        SelectedDesc
        setSelectedAcc
        SelectedAcc
        addRow
        deleteRow
        JournalEntrytable  
      />
    </Dialog>
  );
}

