import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import PurchaseOrderInterfaceDialog from "./PurchaseOrderInterfaceDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  piUkNo: "",
  pi_PR_FL: "",
  pi_TR_NO: "",
  pi_TR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  pi_CO_CT: "",
  pi_TR_CR: "",
  pi_SU_NO: "",
  cus_NAME: "",
  pi_SH_TO: "",
  shipto_NAME:"",
  pi_OR_DT: "",
  pi_RQ_DT:  moment(moment().toDate()).format("YYYYMMDD"),
  pi_PR_DT: "",
  pi_PY_TR: "",
  pi_IT_NM: "",
  pi_OR_QT: "",
  pi_TR_UM: "",
  pi_TK_RC: "",
  pi_TR_TY: "",
  pi_TR_CO:"",
};

const initialFilters = {
  pi_PR_FL: { value: "", operation: "" },
  pi_TR_NO: { value: "", operation: "" },
  pi_TR_DT: { value: "", operation: "" },
  pi_CO_CT: { value: "", operation: "" },
  pi_TR_CR: { value: "", operation: "" },
  pi_SU_NO: { value: "", operation: "" },
  cus_NAME: { value: "", operation: "" },
  pi_SH_TO: { value: "", operation: "" },
  shipto_NAME: { value: "", operation: "" },
  pi_OR_DT: { value: "", operation: "" },
  pi_RQ_DT: { value: "", operation: "" },
  pi_PR_DT: { value: "", operation: "" },
  pi_PY_TR: { value: "", operation: "" },
  pi_IT_NM: { value: "", operation: "" },
  pi_OR_QT: { value: "", operation: "" },
  pi_TR_UM: { value: "", operation: "" },
  pi_TK_RC: { value: "", operation: "" },
  so_TR_DT: { value: "", operation: "" },
};

const WorkCentreMain = () => {
  // buttonstyles
  const { formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle, tableHeaderStyle,
    inputStyle, tableCellStyle, rightalined
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData1, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [pi_TR_TY, setpi_TR_TY] = useState("");
  const [pi_OR_TY, setpi_OR_TY] = useState("");

  
  // drodpdowns
  const [pi_SU_NO, setpi_SU_NO] = useState("");
  const [pi_SH_TO, setpi_SH_TO] = useState("");
  const [ep_EX_CT, setep_EX_CT] = useState("");
  const [ep_EX_TY, setep_EX_TY] = useState("");
  const [ep_LO_CN, setep_LO_CN] = useState("");
  // AdvanceSearch
  const [selectedSupplier, setselectedSupplier] = useState("");
  const [selectedshipto, setselectedshipto] = useState("");
  const [item_DES, setitem_DES] = useState("");
  

  const [ep_PR_SU, setep_PR_SU] = useState("");
  const [pi_TR_NO, setpi_TR_NO] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [PurchaseOrderInterfaceTableData, setPurchaseOrderInterfaceTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setPurchaseOrderInterfaceTableData([{}]);
    setpi_SH_TO(null);
    setpi_SU_NO(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }

    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/PurchaseOrderInterface/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const CashFlowData = response.data;
          const updatedCashFlowData = CashFlowData.purchaseOrderInterfaceList.map((target) => {
            const UpdatedworkData = { ...target };
            delete UpdatedworkData.piUkNo;
            return UpdatedworkData;
          });
          setPurchaseOrderInterfaceTableData(updatedCashFlowData);
          setFormData(updatedCashFlowData[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [pi_TR_CO, setpi_TR_CO] = useState("");
  const [pi_OR_CO, setpi_OR_CO] = useState("");

  const [pi_OR_DT, setpi_OR_DT] = useState("");
  
  const handleDefault = async () => {
    setpi_TR_CO(`${company}`)
    setpi_OR_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];

    const requestBody = {
      "version_ID": LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if (dataDefault.versionIds[i].field_TYPE === "SCREEN") {
            const fName = dataDefault.versionIds[i].field_ID;
            if (fName === "PI_TR_TY") {
              setpi_TR_TY(dataDefault.versionIds[i].default_VALUE) 
            }
            if (fName === "PI_OR_TY") {
              setpi_OR_TY(dataDefault.versionIds[i].default_VALUE) 
            }
          }
          if (dataDefault.versionIds[i].field_TYPE === "LIST") {
            count++;
            const def = {
              "column": dataDefault.versionIds[i].field_ID,
              "value": dataDefault.versionIds[i].default_VALUE,
              "operation": "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault, count);
      } else {
        handleSearch(listDefault, count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists, nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for (let i = 0; i < nos; i++) {
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PurchaseOrderInterface/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/PurchaseOrderInterface/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const PurchaseData = response.data;
        setPurchaseOrderInterfaceTableData(PurchaseData.purchaseOrderInterfaceList);
        setFormData(PurchaseData.purchaseOrderInterfaceList[0]);
        setselectedSupplier(PurchaseData.purchaseOrderInterfaceList.cus_NAME);
        setselectedshipto(PurchaseData.purchaseOrderInterfaceList.shipto_NAME); 
        setitem_DES(PurchaseData.purchaseOrderInterfaceList.item_DES); 
        setpi_TR_NO(PurchaseData.purchaseOrderInterfaceList[0].pi_TR_NO);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBody = {};
  function values() {
    const table = document.getElementById("PurchaseOrderInterfaceTableData");
    const totalRowCount1 = table.tBodies[0].rows.length;
    const purchaseOrderInterfaceList = [];
    for (let i = 0; i < totalRowCount1; i++) {
      const PR_DT = document.getElementsByName("pi_PR_DT_" + i)[0];
      const OR_DT = document.getElementsByName("pi_OR_DT_" + i)[0];
      const RQ_DT = document.getElementsByName("pi_RQ_DT_" + i)[0];
      const PO_DT = document.getElementsByName("pi_PO_DT_" + i)[0];
      const RC_DT = document.getElementsByName("pi_RC_DT_" + i)[0];
      const IN_DT = document.getElementsByName("pi_IN_DT_" + i)[0];
      const EX_DT = document.getElementsByName("pi_EX_DT_" + i)[0];


      const details = { 
        piUkNo: document.getElementById("piUkNo_" + i).value,
        pi_LN_NO: document.getElementById("pi_LN_NO_" + i).value,
        pi_OR_NO: document.getElementById("pi_OR_NO_" + i).value,
        pi_OR_TY: document.getElementById("pi_OR_TY_" + i).value,
        pi_OR_CO : document.getElementById("pi_OR_CO_" + i).value,
        pi_CO_CT: document.getElementById("pi_CO_CT_" + i).value,
        pi_TR_CR: document.getElementById("pi_TR_CR_" + i).value,
        pi_SU_NO: document.getElementById("pi_SU_NO_" + i).value,
        pi_SH_TO: document.getElementById("pi_SH_TO_" + i).value,
        pi_OR_DT: OR_DT.value ? moment(OR_DT.value, "DD/MM/YYYY").format("YYYYMMDD") : null,
        pi_RQ_DT: RQ_DT.value ? moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD") : null,
        pi_PO_DT: PO_DT.value ? moment(PO_DT.value, "DD/MM/YYYY").format("YYYYMMDD") : null,
        pi_PY_TR: document.getElementById("pi_PY_TR_" + i).value,
        pi_PR_DT: PR_DT.value ? moment(PR_DT.value, "DD/MM/YYYY").format("YYYYMMDD") : null,
        pi_IT_NM: document.getElementById("pi_IT_NM_" + i).value,
        pi_OR_QT: document.getElementById("pi_OR_QT_" + i).value,
        pi_TR_UM: document.getElementById("pi_TR_UM_" + i).value,
        pi_UT_PR: document.getElementById("pi_UT_PR_" + i).value,
        pi_DS_PR: document.getElementById("pi_DS_PR_" + i).value,
        pi_FR_PR: document.getElementById("pi_FR_PR_" + i).value,
        pi_TX_PE: document.getElementById("pi_TX_PE_" + i).value,
        pi_RC_DT: RC_DT.value ? moment(RC_DT.value, "DD/MM/YYYY").format("YYYYMMDD") : null,
        pi_IN_NO: document.getElementById("pi_IN_NO_" + i).value,
        pi_IN_DT: IN_DT.value ? moment(IN_DT.value, "DD/MM/YYYY").format("YYYYMMDD") : null,
        pi_LO_CN: document.getElementById("pi_LO_CN_" + i).value,
        pi_LT_NO: document.getElementById("pi_LT_NO_" + i).value,
        pi_EX_DT: EX_DT.value ? moment(EX_DT.value, "DD/MM/YYYY").format("YYYYMMDD") : null,
        pi_TK_RC: document.getElementById("pi_TK_RC_" + i).value,
        pi_IV_CT: document.getElementById("pi_IV_CT_" + i).value,
        pi_PR_FL: document.getElementById("pi_PR_FL_" + i).value,

      };
      purchaseOrderInterfaceList.push(details);
    }
    requestBody = {

      purchaseOrderInterfaceList,
      piUkNo: FormData1.piUkNo,
      pi_TR_NO: FormData1.pi_TR_NO,
      pi_TR_DT: FormData1.pi_TR_DT,
      pi_TR_TY: FormData1.pi_TR_TY,
      pi_TR_CO: FormData1.pi_TR_CO,
    };
  }
  const handleFormSubmit = (type) => {
    values();
    if (FormData1.pi_TR_NO) {
      if (FormData1.piUkNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/PurchaseOrderInterface/Update`;
        if (confirm) {
          axios
            .put(update, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      }
      else if (FormData1.piUkNo && type == "process") {
        const confirm = window.confirm("Do you want to Process ?");
        const process = `${domain}/api/PurchaseOrderInterface/ProcessInterface`;
        if (confirm) {
          FormData1.pi_OR_TY = pi_OR_TY;

          axios
            .post(process, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      

      } else {
        FormData1.ep_CO_NO = company;
        axios
          .post(`${domain}/api/PurchaseOrderInterface/Add`, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    } else {
      const TR_DT = FormData1.pi_TR_DT;
      if (TR_DT) {
        const currentYear = TR_DT.toString();
        const firstHalf = currentYear.substring(0, 2);
        const secondHalf = currentYear.substring(2, 4);
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
        const parameters = `${pi_TR_TY}/${secondHalf}/${company}/${firstHalf}`; 
        const finalUrl1 = `${urlNextNumber}/${parameters}`;
        fetch(finalUrl1, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        })
          .then((resp) => {
            if (!resp.ok) {
              throw new Error(`Request failed with status: ${resp.status}`);
            }
            return resp.json();
          })
          .then((resp) => {
            onchangeFormData("pi_TR_NO", resp.dn_DO_NO);
            setpi_TR_NO(resp.dn_DO_NO);
            setFormData({ ...FormData1, ["pi_TR_NO"]: resp.dn_DO_NO });
            axios
              .post(`${domain}/api/PurchaseOrderInterface/Add`, requestBody, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error saving data:", error);
              });

          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }

  };


  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack >
        <div style={{ background: "red !important", color: "white", fontSize: "10px" }} >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  // const exportExcel = () => {
  //   import("xlsx").then((xlsx) => {
  //     const worksheet = xlsx.utils.json_to_sheet(tableData);
  //     const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  //     const excelBuffer = xlsx.write(workbook, {
  //       bookType: "xlsx",
  //       type: "array",
  //     });

  //     const data = new Blob([excelBuffer], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //     });
  //     saveAs(data, "products_export.xlsx");
  //   });
  // };

  // const rightToolbarTemplate = (
  //   <React.Fragment>
  //     <Button
  //       style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
  //       icon="pi pi-upload"
  //       className="p-button-help"
  //       rounded
  //       onClick={exportExcel}
  //       data-pr-tooltip="XLS"
  //     />
  //   </React.Fragment>
  // );
  // upload function
  const url = `${domain}/api/FileUpload/UploadPurchaseOrder`;

  const UploadExcel = (event) => {
    const FileMeta = event.target.files[0];

    if (FileMeta) {
      const fileName = FileMeta.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (fileExtension === "csv" || fileExtension === "xlsx") {
        const formData = new FormData();
        formData.append('FileMeta', FileMeta); // Append the file
        formData.append('type', 'I2'); // Assuming 'type' is a required parameter

        try {
          fetch(url, {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`, // Assuming token is defined elsewhere
              "x-company": `${company}`, "x-flag": localStorage.getItem("userType"), // Assuming company is defined elsewhere
            },
          })
            .then((resp) => {
              if (!resp.ok) {
                throw new Error('Failed to upload file');
              }
              return resp.json();
            })
            .then((resp) => {
              // Assuming you have a toast mechanism like toast.current.show
              toast.current.show({
                severity: "success",
                summary: "Import Successful",
              });
              // Assuming handleSearch is a function to trigger search after import
              handleSearch();
            })
            .catch((error) => {
              console.error("Error importing file:", error);
              toast.current.show({
                severity: "error",
                summary: "Import Error",
                detail: "Failed to import. Please try again later.",
              });
            });
        } catch (error) {
          console.error("Error parsing imported data:", error);
          toast.current.show({
            severity: "error",
            summary: "Import Error",
            detail:
              "Invalid data format. Please ensure the imported file is in the correct format.",
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Unsupported File Type",
          detail: "Please select a CSV or XLSX file for Upload.",
        });
      }
    }
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px", backgroundColor: "#1ed01e", border: "none" }}
        type="button"
        icon="pi pi-download"
        className="p-button-download"
        tooltip="Upload"
        rounded
        onClick={() => {
          const input = document.getElementById("fileInput");
          input.click();
        }}
        data-pr-tooltip="Upload"
      />
      <input
        id="fileInput"
        type="file"
        accept=".csv, .xlsx"
        onChange={UploadExcel}
        style={{ display: "none" }}
      />

    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_PR_FL"] ? filters["pi_PR_FL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_PR_FL",
                        e.target.value,
                        filters["pi_PR_FL"]
                          ? filters["pi_PR_FL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_TR_NO"] ? filters["pi_TR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_TR_NO",
                        e.target.value,
                        filters["pi_TR_NO"] ? filters["pi_TR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_TR_DT"] ? filters["pi_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_TR_DT",
                        e.target.value,
                        filters["pi_TR_DT"]
                          ? filters["pi_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["pi_CO_CT"] ? filters["pi_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_CO_CT",
                        e.target.value,
                        filters["pi_CO_CT"]
                          ? filters["pi_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_TR_CR"] ? filters["pi_TR_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_TR_CR",
                        e.target.value,
                        filters["pi_TR_CR"] ? filters["pi_TR_CR"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_SU_NO"] ? filters["pi_SU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_SU_NO",
                        e.target.value,
                        filters["pi_SU_NO"]
                          ? filters["pi_SU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cus_NAME"] ? filters["cus_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cus_NAME",
                        e.target.value,
                        filters["cus_NAME"]
                          ? filters["cus_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle} 
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_SH_TO"] ? filters["pi_SH_TO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_SH_TO",
                        e.target.value,
                        filters["pi_SH_TO"]
                          ? filters["pi_SH_TO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["shipto_NAME"] ? filters["shipto_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "shipto_NAME",
                        e.target.value,
                        filters["shipto_NAME"]
                          ? filters["shipto_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_OR_DT"] ? filters["pi_OR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_OR_DT",
                        e.target.value,
                        filters["pi_OR_DT"]
                          ? filters["pi_OR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_RQ_DT"] ? filters["pi_RQ_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_RQ_DT",
                        e.target.value,
                        filters["pi_RQ_DT"]
                          ? filters["pi_RQ_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_PR_DT"] ? filters["pi_PR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_PR_DT",
                        e.target.value,
                        filters["pi_PR_DT"]
                          ? filters["pi_PR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["pi_PY_TR"] ? filters["pi_PY_TR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_PY_TR",
                        e.target.value,
                        filters["pi_PY_TR"]
                          ? filters["pi_PY_TR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_IT_NM"] ? filters["pi_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_IT_NM",
                        e.target.value,
                        filters["pi_IT_NM"]
                          ? filters["pi_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_OR_QT"] ? filters["pi_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_OR_QT",
                        e.target.value,
                        filters["pi_OR_QT"]
                          ? filters["pi_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_TR_UM"] ? filters["pi_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_TR_UM",
                        e.target.value,
                        filters["pi_TR_UM"]
                          ? filters["pi_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_TK_RC"] ? filters["pi_TK_RC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_TK_RC",
                        e.target.value,
                        filters["pi_TK_RC"]
                          ? filters["pi_TK_RC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Processed</th>
                <th style={tableHeaderStyle}>Transaction No</th>
                <th style={tableHeaderStyle}>Tran.Date</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Supplier No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Ship To</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Order Date</th>
                <th style={tableHeaderStyle}>Requested Date</th>
                <th style={tableHeaderStyle}>Promised Date</th>
                <th style={tableHeaderStyle}>Payment Term</th>
                <th style={tableHeaderStyle}>Item Name</th>
                <th style={tableHeaderStyle}>Quantity</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Take Receipt</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.piUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.piUkNo}
                        checked={selectedRows.includes(rowData.piUkNo)}
                        onChange={() => handleRowSelect(rowData.piUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.pi_PR_FL}</td>
                    <td style={tableCellStyle}>{rowData.pi_TR_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.pi_TR_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.pi_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.pi_TR_CR}</td>
                    <td style={tableCellStyle}>{rowData.pi_SU_NO}</td>
                    <td style={tableCellStyle}>{rowData.cus_NAME}</td>
                    <td style={tableCellStyle}>{rowData.pi_SH_TO}</td>
                    <td style={tableCellStyle}>{rowData.shipto_NAME}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.pi_OR_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.pi_RQ_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.pi_PR_DT)}</td>
                    <td style={tableCellStyle}>{rowData.pi_PY_TR}</td>
                    <td style={tableCellStyle}>{rowData.pi_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.pi_OR_QT}</td>
                    <td style={rightalined}>{rowData.pi_TR_UM}</td>
                    <td style={rightalined}>{rowData.pi_TK_RC}</td>

                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <PurchaseOrderInterfaceDialog
        open={open}
        handleClose={handleClose}
        data={FormData1}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        PurchaseOrderInterfaceTableData={PurchaseOrderInterfaceTableData}
        setPurchaseOrderInterfaceTableData={setPurchaseOrderInterfaceTableData}
        pi_TR_CO={pi_TR_CO}
        pi_OR_CO={pi_OR_CO}
        ep_PR_SU={ep_PR_SU}
        pi_SU_NO={pi_SU_NO}
        setpi_SU_NO={setpi_SU_NO}
       
        pi_SH_TO={pi_SH_TO}
        setpi_SH_TO={setpi_SH_TO}
        ep_EX_TY={ep_EX_TY}
        setep_EX_TY={setep_EX_TY}
        ep_LO_CN={ep_LO_CN}
        setep_LO_CN={setep_LO_CN}
        selectedSupplier={selectedSupplier}
        setselectedSupplier={setselectedSupplier}
        selectedshipto={selectedshipto}
        setselectedshipto={setselectedshipto}
        item_DES={item_DES}
        setitem_DES={setitem_DES}
        pi_TR_TY={pi_TR_TY}
        setpi_TR_TY={setpi_TR_TY}
        pi_TR_NO={pi_TR_NO}
        pi_OR_TY={pi_OR_TY}
        setpi_OR_TY={setpi_OR_TY}
        pi_OR_DT={pi_OR_DT}
        setpi_OR_DT={setpi_OR_DT}
      />
    </div>
  );
};

export default WorkCentreMain;
