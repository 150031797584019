import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import LeaveStatusDialog from "./LeaveStatusDialog";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
const initialValue = {
  ls_TO_EL: "",
  ls_CL_EL: "",
  ls_ML_EL : "",
  ls_UL_EL:"",
  ls_OH_EL:"",
  ls_PL_EL:"",
  ls_YE_AR:"",
  ls_SL_EL:"",
  ls_BG_BL:"",
  ls_PA_EL:"",
  ls_CO_EL:"",
  ls_TO_TN:"",
  lsUkNo:"",
  ls_EM_NO:"",
  ls_CO_NO:"",
  ls_TO_EL:"",
};

const initialFilters = {
  ls_EM_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },
  ls_YE_AR: { value: "", operation: "" },
  ls_BG_BL: { value: "", operation: "" },
  ls_PL_EL: { value: "", operation: "" },
  ls_CL_EL: { value: "", operation: "" },
  ls_SL_EL: { value: "", operation: "" },
  ls_ML_EL: { value: "", operation: "" },
  ls_PA_EL: { value: "", operation: "" },
  ls_UL_EL: { value: "", operation: "" },
  ls_CO_EL: { value: "", operation: "" },
  ls_OH_EL: { value: "", operation: "" },
  ls_TO_EL: { value: "", operation: "" },
  ls_TO_TN: { value: "", operation: "" },
};

const LeaveStatusMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,checkboxStyle,tableHeaderStyle, inputStyle,tableCellStyle } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [ls_YE_AR, setls_YE_AR] = useState("");
  const [ls_EM_NO, setls_EM_NO] = useState("");
  const [ls_PL_EL, setLs_PL_EL] = useState("");
  const [ls_PL_TN, setLs_PL_TN] = useState("");
  const [ls_CL_EL, setLs_CL_EL] = useState("");
  const [ls_CL_TN, setLs_CL_TN] = useState("");
  const [ls_ML_EL, setLs_ML_EL] = useState("");
  const [ls_ML_TN, setLs_ML_TN] = useState("");
  const [ls_PA_EL, setLs_PA_EL] = useState("");
  const [ls_PA_TN, setLs_PA_TN] = useState("");
  const [ls_UL_EL, setLs_UL_EL] = useState("");
  const [ls_UL_TN, setLs_UL_TN] = useState("");
  const [ls_CO_EL, setLs_CO_EL] = useState("");
  const [ls_CO_TN, setLs_CO_TN] = useState("");
  const [ls_OH_EL, setLs_OH_EL] = useState("");
  const [ls_OH_TN, setLs_OH_TN] = useState("");
  const [ls_TO_EL, setLs_TO_EL] = useState("");
  const [ls_TO_TN, setLs_TO_TN] = useState("");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;
    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }
    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setls_YE_AR(null);
    setls_EM_NO(null);
    setLs_PL_EL(null);
    setLs_PL_TN(null);
    setLs_CL_EL(null);
    setLs_CL_TN(null);
    setLs_ML_EL(null);
    setLs_ML_TN(null);
    setLs_PA_EL(null);
    setLs_PA_TN(null);
    setLs_UL_EL(null);
    setLs_UL_TN(null);
    setLs_CO_EL(null);
    setLs_CO_TN(null);
    setLs_OH_EL(null);
    setLs_OH_TN(null);
    setLs_TO_EL(null);
    setLs_TO_TN(null);
    setOpen(true);

  };
  const handleClose = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setls_YE_AR(null);
    setOpen(false);
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/LeaveStatus/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const LeaveStatusData = response.data;
          delete LeaveStatusData.lsUkNo;
          setFormData(LeaveStatusData);
          setSelectedemEMNO(LeaveStatusData.name);
          setls_YE_AR(LeaveStatusData.ls_YE_AR.toString());
          setls_EM_NO(LeaveStatusData.ls_EM_NO);
          setLs_PL_EL(LeaveStatusData.ls_PL_EL);
          setLs_PL_TN(LeaveStatusData.ls_PL_TN);
          setLs_CL_EL(LeaveStatusData.ls_CL_EL);
          setLs_CL_TN(LeaveStatusData.ls_CL_TN);
          setLs_ML_EL(LeaveStatusData.ls_ML_EL);
          setLs_ML_TN(LeaveStatusData.ls_ML_TN);
          setLs_PA_EL(LeaveStatusData.ls_PA_EL);
          setLs_PA_TN(LeaveStatusData.ls_PA_TN);
          setLs_UL_EL(LeaveStatusData.ls_UL_EL);
          setLs_UL_TN(LeaveStatusData.ls_UL_TN);
          setLs_CO_EL(LeaveStatusData.ls_CO_EL);
          setLs_CO_TN(LeaveStatusData.ls_CO_TN);
          setLs_OH_EL(LeaveStatusData.ls_OH_EL);
          setLs_OH_TN(LeaveStatusData.ls_OH_TN);
          setLs_TO_EL(LeaveStatusData.ls_TO_EL);
          setLs_TO_TN(LeaveStatusData.ls_TO_TN);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/LeaveStatus/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
         
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value) => {
    if(name=='ls_EM_NO')
      FormData[name]=value;
    else
      setFormData({ ...FormData, [name]: value });
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setls_YE_AR(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/LeaveStatus/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const LeaveStatusData = response.data;
        setFormData(LeaveStatusData);
        setSelectedemEMNO(response.data.name);    
        setls_YE_AR(response.data.ls_YE_AR.toString());
        setls_EM_NO(LeaveStatusData.ls_EM_NO);
        setLs_PL_EL(LeaveStatusData.ls_PL_EL);
        setLs_PL_TN(LeaveStatusData.ls_PL_TN);
        setLs_CL_EL(LeaveStatusData.ls_CL_EL);
        setLs_CL_TN(LeaveStatusData.ls_CL_TN);
        setLs_ML_EL(LeaveStatusData.ls_ML_EL);
        setLs_ML_TN(LeaveStatusData.ls_ML_TN);
        setLs_PA_EL(LeaveStatusData.ls_PA_EL);
        setLs_PA_TN(LeaveStatusData.ls_PA_TN);
        setLs_UL_EL(LeaveStatusData.ls_UL_EL);
        setLs_UL_TN(LeaveStatusData.ls_UL_TN);
        setLs_CO_EL(LeaveStatusData.ls_CO_EL);
        setLs_CO_TN(LeaveStatusData.ls_CO_TN);
        setLs_OH_EL(LeaveStatusData.ls_OH_EL);
        setLs_OH_TN(LeaveStatusData.ls_OH_TN);
        setLs_TO_EL(LeaveStatusData.ls_TO_EL);
        setLs_TO_TN(LeaveStatusData.ls_TO_TN);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values() {
    requestBodyInput = {
      lsUkNo: FormData.lsUkNo,
      ls_EM_NO: document.getElementById("ls_EM_NO").value,
      ls_BG_BL: document.getElementById("ls_BG_BL").value,
      ls_YE_AR: FormData.ls_YE_AR,
      ls_PL_EL: document.getElementById("ls_PL_EL").value,
      ls_PL_TN: document.getElementById("ls_PL_TN").value,
      ls_CL_EL: document.getElementById("ls_CL_EL").value,
      ls_CL_TN: document.getElementById("ls_CL_TN").value,
      ls_ML_EL: document.getElementById("ls_ML_EL").value,
      ls_ML_TN: document.getElementById("ls_ML_TN").value,
      ls_PA_EL: document.getElementById("ls_PA_EL").value,
      ls_PA_TN: document.getElementById("ls_PA_TN").value,
      ls_UL_EL: document.getElementById("ls_UL_EL").value,
      ls_UL_TN: document.getElementById("ls_UL_TN").value,
      ls_CO_EL: document.getElementById("ls_CO_EL").value,
      ls_CO_TN: document.getElementById("ls_CO_TN").value,
      ls_OH_EL: document.getElementById("ls_OH_EL").value,
      ls_OH_TN: document.getElementById("ls_OH_TN").value,
      ls_TO_EL: document.getElementById("ls_TO_EL").value,
      ls_TO_TN: document.getElementById("ls_TO_TN").value,
    }
  };
  const handleFormSubmit = () => {
    values();
    if (FormData.lsUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/LeaveStatus/Update`;
      if (confirm) {
        axios
          .put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.ls_CO_NO = company;
      values();
      axios.post(`${domain}/api/LeaveStatus/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
        <Tooltip target=".update-button" position="bottom" mouseTrack >
        <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
    
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
        <Tooltip target=".copy-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
    backgroundColor:"#f5f4f4"
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none"}}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
return (
  <div  className="tableData" style={{ marginTop: "5%" }}>
    <Toast ref={toast} />
    <div className="card">
      <Toolbar
        className="p-mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      />
      <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        <table style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px" }}>
          <thead>
            <tr>
              <td style={{width:'40px'}}>
                <input
                  style={checkboxStyle}
                  readOnly
                  onMouseDown={(e) => e.preventDefault()}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["ls_EM_NO"] ? filters["ls_EM_NO"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "ls_EM_NO",
                      e.target.value,
                      filters["ls_EM_NO"]
                        ? filters["ls_EM_NO"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["name"] ? filters["name"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "name",
                      e.target.value,
                      filters["name"]
                        ? filters["name"].operation
                        : "equals"
                    )
                  }
                  style={inputStyleH}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["ls_YE_AR"] ? filters["ls_YE_AR"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "ls_YE_AR",
                      e.target.value,
                      filters["ls_YE_AR"]
                        ? filters["ls_YE_AR"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["ls_BG_BL"] ? filters["ls_BG_BL"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "ls_BG_BL",
                      e.target.value,
                      filters["ls_BG_BL"]
                        ? filters["ls_BG_BL"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td> 
              <td>
                <input
                  type="text"
                  value={filters["ls_PL_EL"] ? filters["ls_PL_EL"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "ls_PL_EL",
                      e.target.value,
                      filters["ls_PL_EL"]
                        ? filters["ls_PL_EL"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["ls_CL_EL"] ? filters["ls_CL_EL"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "ls_CL_EL",
                      e.target.value,
                      filters["ls_CL_EL"]
                        ? filters["ls_CL_EL"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["ls_ML_EL"] ? filters["ls_ML_EL"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "ls_ML_EL",
                      e.target.value,
                      filters["ls_ML_EL"]
                        ? filters["ls_ML_EL"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["ls_PA_EL"] ? filters["ls_PA_EL"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "ls_PA_EL",
                      e.target.value,
                      filters["ls_PA_EL"]
                        ? filters["ls_PA_EL"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["ls_UL_EL"] ? filters["ls_UL_EL"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "ls_UL_EL",
                      e.target.value,
                      filters["ls_UL_EL"]
                        ? filters["ls_UL_EL"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>   
              <td>
                <input
                  type="text"
                  value={filters["ls_CO_EL"] ? filters["ls_CO_EL"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "ls_CO_EL",
                      e.target.value,
                      filters["ls_CO_EL"]
                        ? filters["ls_CO_EL"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["ls_OH_EL"] ? filters["ls_OH_EL"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "ls_OH_EL",
                      e.target.value,
                      filters["ls_OH_EL"]
                        ? filters["ls_OH_EL"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["ls_TO_EL"] ? filters["ls_TO_EL"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "ls_TO_EL",
                      e.target.value,
                      filters["ls_TO_EL"]
                        ? filters["ls_TO_EL"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["ls_TO_TN"] ? filters["ls_TO_TN"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "ls_TO_TN",
                      e.target.value,
                      filters["ls_TO_TN"]
                        ? filters["ls_TO_TN"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
            </tr>
            <tr>
              <th style={tableHeaderStyle}></th>
              <th style={tableHeaderStyle}>Employee ID</th>
              <th style={tableHeaderStyle}>Employee </th>
              <th style={tableHeaderStyle}>Year</th>
              <th style={tableHeaderStyle}>Begining Balance</th>
              <th style={tableHeaderStyle}>Annual</th>
              <th style={tableHeaderStyle}>Emergency</th>
              <th style={tableHeaderStyle}>Maternity</th>
              <th style={tableHeaderStyle}>Paternity</th>
              <th style={tableHeaderStyle}>Unpaid</th>
              <th style={tableHeaderStyle}>Compensatory</th>
              <th style={tableHeaderStyle}>Optional</th>
              <th style={tableHeaderStyle}>Total Eligible</th>
              <th style={tableHeaderStyle}>Leave Taken</th>
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData.map((rowData, index) => (
                <tr
                  key={rowData.lsUkNo}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "aliceblue" : "#ffffff",
                  }}
                  className="table-row"
                >
                  <td style={tableCellStyle}>
                    <input
                      style={{ width: "10% !important" }}
                      type="checkbox"
                      value={rowData.lsUkNo}
                      checked={selectedRows.includes(rowData.lsUkNo)}
                      onChange={() => handleRowSelect(rowData.lsUkNo)}
                    />
                  </td>
                  <td style={tableCellStyle}>{rowData.ls_EM_NO}</td>
                  <td style={tableCellStyle}>{rowData.name}</td>
                  <td style={tableCellStyle}>{rowData.ls_YE_AR}</td>
                  <td style={tableCellStyle}>{rowData.ls_BG_BL}</td>
                  <td style={tableCellStyle}>{rowData.ls_PL_EL}</td>
                  <td style={tableCellStyle}>{rowData.ls_CL_EL}</td>
                  <td style={tableCellStyle}>{rowData.ls_ML_EL}</td>
                  <td style={tableCellStyle}>{rowData.ls_PA_EL}</td>
                  <td style={tableCellStyle}>{rowData.ls_UL_EL}</td>
                  <td style={tableCellStyle}>{rowData.ls_CO_EL}</td>
                  <td style={tableCellStyle}>{rowData.ls_OH_EL}</td>
                  <td style={tableCellStyle}>{rowData.ls_TO_EL}</td>
                  <td style={tableCellStyle}>{rowData.ls_TO_TN}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    {/* pagination */}
    <Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      setCurrentPage={setCurrentPage}
    />
    </div>

    <LeaveStatusDialog
      open={open}
      handleClose={handleClose}
      data={FormData}
      handleFormSubmit={handleFormSubmit}
      onchangedata={onchangedata}
      onchangeFormData={onchangeFormData}    
      onchangeDropDownFormData1={onchangeDropDownFormData1}
      ls_YE_AR={ls_YE_AR}
      ls_EM_NO={ls_EM_NO}
      // leaves
      ls_PL_EL={ls_PL_EL}
      ls_PL_TN={ls_PL_TN}
      ls_CL_EL={ls_CL_EL}
      ls_CL_TN={ls_CL_TN}
      ls_ML_EL={ls_ML_EL}
      ls_ML_TN={ls_ML_TN}
      ls_PA_EL={ls_PA_EL}
      ls_PA_TN={ls_PA_TN}
      ls_UL_EL={ls_UL_EL}
      ls_UL_TN={ls_UL_TN}
      ls_CO_EL={ls_CO_EL}
      ls_CO_TN={ls_CO_TN}
      ls_OH_EL={ls_OH_EL}
      ls_OH_TN={ls_OH_TN}
      ls_TO_EL={ls_TO_EL}
      ls_TO_TN={ls_TO_TN}
      setLs_PL_EL={setLs_PL_EL}
      setLs_PL_TN={setLs_PL_TN}
      setLs_CL_EL={setLs_CL_EL}
      setLs_CL_TN={setLs_CL_TN}
      setLs_ML_EL={setLs_ML_EL}
      setLs_ML_TN={setLs_ML_TN}
      setLs_PA_EL={setLs_PA_EL}
      setLs_PA_TN={setLs_PA_TN}
      setLs_UL_EL={setLs_UL_EL}
      setLs_UL_TN={setLs_UL_TN}
      setLs_CO_EL={setLs_CO_EL}
      setLs_CO_TN={setLs_CO_TN}
      setLs_OH_EL={setLs_OH_EL}
      setLs_OH_TN={setLs_OH_TN}
      setLs_TO_EL={setLs_TO_EL}
      setLs_TO_TN={setLs_TO_TN}
    />
  </div>
);
};

export default LeaveStatusMain;