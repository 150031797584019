import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function MyBills({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  billingCode
}) {

  const {borderbox,inboxstyle} = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [Rentdata, setRentdata] = useState({
    cl_CU_NO: '',
    cl_DO_NO: '',
    cl_IN_DT: '',
    cl_DD_DT: '',
    fromDate: '',
    toDate: '',
    cl_OP_AM:'',
    notes: '',
  });

  useEffect(() => {
    const GetRentList = async () => {
      const requestBody = {
        "searchRequestDto":[],
        "billingCode": billingCode
      };
      try {
        const response = await axios.post(
          `${domain}/api/CustomerLedger/GetRentPayment`, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
            const dataTotal = response.data;
            if (dataTotal && dataTotal.length > 0) {
              const defaultdata = dataTotal[0];
              setRentdata({
                cl_CU_NO : defaultdata.name || '',
                cl_DO_NO : defaultdata.cl_DO_NO || '',
                cl_IN_DT : defaultdata.cl_IN_DT || '',
                cl_DD_DT : defaultdata.cl_DD_DT || '',
                fromDate : defaultdata.fromDate || '',
                toDate   : defaultdata.toDate || '',
                cl_OP_AM : defaultdata.cl_OP_AM || '',
                notes    : defaultdata.notes || '',
              });
            }else {
            console.error("Invalid API response:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
    };
    if (billingCode) {
      GetRentList();
    }
  }, [billingCode]);

  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"8%"}}
    icon="pi pi-credit-card" 
    onClick={handleFormSubmit} 
    tooltip={"Pay"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "40rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={"Payment"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
            <div style={inboxstyle} >
                <div className="formgrid grid">              
                <div className="field col-12">
                  <InputText disabled
                    id="cl_CU_NO"
                    name="cl_CU_NO"
                    value={Rentdata.cl_CU_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                </div>
                <div className="formgrid grid">  
                <div className="field col">
                  <label htmlFor="cl_DO_NO" className="font-bold" style={{fontSize:'70%'}}>
                    Document 
                  </label>
                  <InputText disabled
                    id="cl_DO_NO"
                    name="cl_DO_NO"
                    value={Rentdata.cl_DO_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col">
                  <label htmlFor="cl_IN_DT" className="font-bold" style={{fontSize:'70%'}}>
                    Invoice Date 
                  </label>
                  <Calendar disabled
                    id="cl_IN_DT"
                    name="cl_IN_DT"
                    value={moment(Rentdata.cl_IN_DT, "YYYYMMDD").isValid() ? moment(Rentdata.cl_IN_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "cl_IN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col">
                  <label htmlFor="cl_DD_DT" className="font-bold" style={{fontSize:'70%'}}>
                    Due Date 
                  </label>
                  <Calendar disabled
                    id="cl_DD_DT"
                    name="cl_DD_DT"
                    value={moment(Rentdata.cl_DD_DT, "YYYYMMDD").isValid() ? moment(Rentdata.cl_DD_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "cl_DD_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                </div>
                <div className="formgrid grid">  
                <div className="field col">
                  <label htmlFor="fromDate" className="font-bold" style={{fontSize:'70%'}}>
                    From Date 
                  </label>
                  <Calendar disabled
                    id="fromDate"
                    name="fromDate"
                    value={moment(Rentdata.fromDate, "YYYYMMDD").isValid() ? moment(Rentdata.fromDate, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "fromDate", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col">
                  <label htmlFor="toDate" className="font-bold" style={{fontSize:'70%'}}>
                    To Date 
                  </label>
                  <Calendar disabled
                    id="toDate"
                    name="toDate"
                    value={moment(Rentdata.toDate, "YYYYMMDD").isValid() ? moment(Rentdata.toDate, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "toDate", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col">
                  <label htmlFor="cl_OP_AM" className="font-bold" style={{fontSize:'70%'}}>
                    Amount
                  </label>
                  <InputText  disabled
                    id="cl_OP_AM"
                    name="cl_OP_AM"
                    value={Rentdata.cl_OP_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                </div>
                <div className="formgrid grid">  
                <div className="field col">
                  <label htmlFor="notes" className="font-bold" style={{fontSize:'70%'}}>
                    Remarks
                  </label>
                  <InputText disabled
                    id="notes"
                    name="notes"
                    value={Rentdata.notes}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
                
            </div>
      </div>
    </Dialog>
  );
}
