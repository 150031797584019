import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function CashInOutDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  CashInOutTableData,
  setCashInOutTableData,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  ct_CS_TY,
  setct_CS_TY,
  ct_TR_CR,
  setct_TR_CR,
  ct_CN_ID,
  setct_CN_ID,
  ct_DO_CO,
}) {
  const {
    ctUkNo,
    ct_CN_AM,ct_TO_AM,
    ct_TR_DT,
    ct_RE_MK,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,multitableheader,savestyle,cancelstyle} = ButtonStyles();
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const [ct_CS_TYOptions, setct_CS_TYOptions] = useState([]);
  const [ct_TR_CROptions, setct_TR_CROptions] = useState([]);
  const [ct_CN_IDOptions, setct_CN_IDOptions] = useState([]);

  const onChangeTime=(name,value,index)=>{
    value= moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value,index);
  };
//  type
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setct_CS_TY(value);
  };
// cURRENCY
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setct_TR_CR(value);
  };
// Counter id
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setct_CN_ID(value);
  };
  useEffect(() => {
    Type();
    fetchDropdownCurr();
    fetchDropdownCID();
  }, []);

  const Type = async () => {
    const Options = await fetchClassCodeDS("CS/TY");
    setct_CS_TYOptions(Options);
  };

  const fetchDropdownCurr = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setct_TR_CROptions(Options);
  };

  const fetchDropdownCID = async () => {
    const Options = await fetchClassCodeDS("CN/ID");
    setct_CN_IDOptions(Options);
  };

  const addRow = () => {
    setCashInOutTableData([...CashInOutTableData, {}]);
  };
  const deleteRow = () => {
    if (CashInOutTableData.length > 0) {
      const updatedData = [...CashInOutTableData];
      updatedData.pop();
      setCashInOutTableData(updatedData);
    }
  };
// DATE DEFAULT
const [selectedDate, setSelectedDate] = useState(moment().toDate());
const handleDateChange = (e) => {
  setSelectedDate(e.value);
  onchangedata({ target: { name: "ct_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
};
  const isUpdateMode = Boolean(ctUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{ctUkNo ? "Revise Cash In/Out" : "Add Cash In/Out"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
    );
  return (
    <Dialog 
      visible={open}
      onHide={handleClose}
      style={{ width: "70rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
          <div className="formgrid grid">
          <div style={{display:"none"}}>
            <label htmlFor="ctUkNo" className="font-bold">
             UK_NO
            </label>
            <InputText 
              id="ctUkNo"
              name="ctUkNo"
              value={ctUkNo}
              onChange={(Event) => onchangedata(Event)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ct_CS_TY" className="font-bold">
             Type
            </label>
            <Dropdown
              id="ct_CS_TY"
              name="ct_CS_TY"
              value={ct_CS_TY}
              options={ct_CS_TYOptions}
              onChange={(e) => onchangeDropDownFormData1("ct_CS_TY", e.value)}
              placeholder="Select Type"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ct_TR_CR" className="font-bold">
              Currency
            </label>
            <Dropdown
              id="ct_TR_CR"
              name="ct_TR_CR"
              value={ct_TR_CR}
              options={ct_TR_CROptions}
              onChange={(e) => onchangeDropDownFormData2("ct_TR_CR", e.value)}
              placeholder="Select Currency"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ct_TO_AM" className="font-bold">
              Amount
            </label>
            <InputText 
              id="ct_TO_AM"
              name="ct_TO_AM"
              value={ct_TO_AM}
              onChange={(Event) => onchangedata(Event)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ct_TR_DT" className="font-bold">
          Date
          </label>
          <Calendar
            id="ct_TR_DT"
            name="ct_TR_DT"
            value={moment(ct_TR_DT, "YYYYMMDD").toDate()}
            onChange={handleDateChange}
            dateFormat="dd/mm/yy"
          />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ct_RE_MK" className="font-bold">
          Remarks
          </label>
          <InputText 
            id="ct_RE_MK"
            name="ct_RE_MK"
            value={ct_RE_MK}
            onChange={(Event) => onchangedata(Event)}
          />
          </div>
          </div>
          </div>  
          <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="CashInOuttable" style={{overflow:"auto"}}>
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Counter ID</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {CashInOutTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`ctUkNo_${index}`}
                    name={`ctUkNo_${index}`}
                    value={row.ctUkNo}
                  />
                </td>
                <td>
                  <Dropdown
                    id={`ct_CN_ID_${index}`}
                    name={`ct_CN_ID_${index}`}
                    value={ data[`ct_CN_ID_${index}`] ? data[`ct_CN_ID_${index}`]: row.ct_CN_ID}
                    options={ct_CN_IDOptions}
                    onChange={(e) =>onchangeDropDownFormData3(`ct_CN_ID_${index}`, e.value)}
                    placeholder="Select Counter ID"
                  />
                  </td>
                
                <td>
                <InputText
                    id={`ct_CN_AM_${index}`}
                    name={`ct_CN_AM_${index}`}
                    value={row.ct_CN_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
    </Dialog>
  );
}
