import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import AccountMasterDialog from "./AccountMasterDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  amUkNo: "",
  am_CO_NO: "",
  am_CO_NM: "",
  am_OB_AC: "",
  am_OB_SB: "",
  am_AC_LD: "",
  am_AC_ID: "",
  am_AC_DS: "",
  am_PA_PN: "",
  am_CR_CD: "",
};

const initialFilters = {
  am_CO_NO: { value: "", operation: "" },
  am_CO_NM: { value: "", operation: "" },
  am_OB_AC: { value: "", operation: "" },
  am_OB_SB: { value: "", operation: "" },
  am_AC_LD: { value: "", operation: "" },
  am_AC_ID: { value: "", operation: "" },
  am_AC_DS: { value: "", operation: "" },
  am_PA_PN: { value: "", operation: "" },
  am_CR_CD: { value: "", operation: "" },
};

const AccountMasterMain = () => {
    // buttonstyles
    const {formatDate, buttonStyles, addButtonStyles,
      copyButtonStyles,checkboxStyle,tableHeaderStyle,
      inputStyle,tableCellStyle,
     } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");

  const [am_CO_NM, setam_CO_NM] = useState([]);
  const [am_AC_LD, setam_AC_LD] = useState([]);
  const [am_CR_CD, setam_CR_CD] = useState([]);
  const [am_PA_PN, setam_PA_PN] = useState([]);
  const [am_CL_01, setam_CL_01] = useState([]);
  const [am_CL_02, setam_CL_02] = useState([]);
  const [am_CL_03, setam_CL_03] = useState([]);
  const [am_CL_04, setam_CL_04] = useState([]);
  const [am_CL_05, setam_CL_05] = useState([]);
  const [am_CL_06, setam_CL_06] = useState([]);
  const [am_CL_07, setam_CL_07] = useState([]);
  const [am_CL_09, setam_CL_09] = useState([]);
  const [am_CL_08, setam_CL_08] = useState([]);
  const [am_CL_10, setam_CL_10] = useState([]);
  const [am_PE_CD, setam_PE_CD] = useState([]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setam_CO_NM(null);
    setam_AC_LD(null);
    setam_CR_CD(null);
    setam_PA_PN(null);
    setam_CL_01(null);
    setam_CL_02(null);
    setam_CL_03(null);
    setam_CL_04(null);
    setam_CL_05(null);
    setam_CL_06(null);
    setam_CL_07(null);
    setam_CL_08(null);
    setam_CL_09(null);
    setam_CL_10(null);
    setam_PE_CD(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setam_CO_NM(null);
    setam_AC_LD(null);
    setam_CR_CD(null);
    setam_PA_PN(null);
    setam_CL_01(null);
    setam_CL_02(null);
    setam_CL_03(null);
    setam_CL_04(null);
    setam_CL_05(null);
    setam_CL_06(null);
    setam_CL_07(null);
    setam_CL_08(null);
    setam_CL_09(null);
    setam_CL_10(null);
    setam_PE_CD(null);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/AccountMaster/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const AccountMasterData = response.data;
          delete AccountMasterData.amUkNo;
          setam_CO_NM(AccountMasterData.am_CO_NM);
          setam_AC_LD(AccountMasterData.am_AC_LD);
          setam_CR_CD(AccountMasterData.am_CR_CD);
          setam_PA_PN(AccountMasterData.am_PA_PN);
          setam_CL_01(AccountMasterData.am_CL_01);
          setam_CL_02(AccountMasterData.am_CL_02);
          setam_CL_03(AccountMasterData.am_CL_03);
          setam_CL_04(AccountMasterData.am_CL_04);
          setam_CL_05(AccountMasterData.am_CL_05);
          setam_CL_06(AccountMasterData.am_CL_06);
          setam_CL_07(AccountMasterData.am_CL_07);
          setam_CL_08(AccountMasterData.am_CL_08);
          setam_CL_09(AccountMasterData.am_CL_09);
          setam_CL_10(AccountMasterData.am_CL_10);
          setam_PE_CD(AccountMasterData.am_PE_CD);
          setFormData(AccountMasterData);
          setSelectedemEMNO(AccountMasterData.name);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
const [am_CO_NO, setam_CO_NO] = useState("");

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    setam_CO_NO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/AccountMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
 
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setam_CO_NM(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setam_AC_LD(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setam_CR_CD(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setam_PA_PN(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setam_CL_01(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setam_CL_02(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setam_CL_03(value);
  };
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setam_CL_04(value);
  };
  const onchangeDropDownFormData9 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setam_CL_05(value);
  };
  const onchangeDropDownFormData10 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setam_CL_06(value);
  };
  const onchangeDropDownFormData11 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setam_CL_07(value);
  };
  const onchangeDropDownFormData12 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setam_CL_08(value);
  };
  const onchangeDropDownFormData13 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setam_CL_09(value);
  };
  const onchangeDropDownFormData14 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setam_CL_10(value);
  };
  const onchangeDropDownFormData15 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setam_PE_CD(value);
  };


  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/AccountMaster/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
          const AccountMasterData = response.data;
          setFormData(AccountMasterData);
          setSelectedAbAdNM(response.data.ab_AD_NM);
          setSelectedemEMNO(response.data.name);
          setam_CO_NM(response.data.am_CO_NM);
          setam_AC_LD(response.data.am_AC_LD);
          setam_CR_CD(response.data.am_CR_CD);
          setam_PA_PN(response.data.am_PA_PN);
          setam_CL_01(response.data.am_CL_01);
          setam_CL_02(response.data.am_CL_02);
          setam_CL_03(response.data.am_CL_03);
          setam_CL_04(response.data.am_CL_04);
          setam_CL_05(response.data.am_CL_05);
          setam_CL_06(response.data.am_CL_06);
          setam_CL_07(response.data.am_CL_07);
          setam_CL_08(response.data.am_CL_08);
          setam_CL_09(response.data.am_CL_09);
          setam_CL_10(response.data.am_CL_10);
          setam_PE_CD(response.data.am_PE_CD);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const handleFormSubmit = () => {
    if (FormData.amUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/AccountMaster/Update`;
      if (confirm) {
        axios
          .put(update, FormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.am_CO_NO = company;
      axios
        .post(`${domain}/api/AccountMaster/Add`, FormData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const emailStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const contactStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  const tableCellStyleC = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
    width:"0%",
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["am_CO_NO"] ? filters["am_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "am_CO_NO",
                        e.target.value,
                        filters["am_CO_NO"]
                          ? filters["am_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={contactStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["am_CO_NM"] ? filters["am_CO_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "am_CO_NM",
                        e.target.value,
                        filters["am_CO_NM"] ? filters["am_CO_NM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["am_OB_AC"] ? filters["am_OB_AC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "am_OB_AC",
                        e.target.value,
                        filters["am_OB_AC"]
                          ? filters["am_OB_AC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["am_OB_SB"] ? filters["am_OB_SB"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "am_OB_SB",
                        e.target.value,
                        filters["am_OB_SB"]
                          ? filters["am_OB_SB"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["am_AC_LD"] ? filters["am_AC_LD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "am_AC_LD",
                        e.target.value,
                        filters["am_AC_LD"]
                          ? filters["am_AC_LD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["am_AC_ID"] ? filters["am_AC_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "am_AC_ID",
                        e.target.value,
                        filters["am_AC_ID"]
                          ? filters["am_AC_ID"].operation
                          : "equals"
                      )
                    }
                    style={emailStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["am_AC_DS"] ? filters["am_AC_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "am_AC_DS",
                        e.target.value,
                        filters["am_AC_DS"]
                          ? filters["am_AC_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["am_PA_PN"] ? filters["am_PA_PN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "am_PA_PN",
                        e.target.value,
                        filters["am_PA_PN"]
                          ? filters["am_PA_PN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["am_CR_CD"] ? filters["am_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "am_CR_CD",
                        e.target.value,
                        filters["am_CR_CD"]
                          ? filters["am_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Cost Center</th>
                <th style={tableHeaderStyle}>Account Object</th>
                <th style={tableHeaderStyle}>Account Subsidiary</th>
                <th style={tableHeaderStyle}>Level</th>
                <th style={tableHeaderStyle}>Account ID</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Posting Code</th>
                <th style={tableHeaderStyle}>Currency</th>
            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.amUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.amUkNo}
                        checked={selectedRows.includes(rowData.amUkNo)}
                        onChange={() => handleRowSelect(rowData.amUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.am_CO_NO}</td>
                    <td style={tableCellStyle}>{rowData.am_CO_NM}</td>
                    <td style={tableCellStyle}>{rowData.am_OB_AC}</td>
                    <td style={tableCellStyleC}>{rowData.am_OB_SB}</td>
                    <td style={tableCellStyle}>{rowData.am_AC_LD}</td>
                    <td style={tableCellStyle}>{rowData.am_AC_ID}</td>
                    <td style={tableCellStyle}>{rowData.am_AC_DS}</td>
                    <td style={tableCellStyle}>{rowData.am_PA_PN}</td>
                    <td style={tableCellStyle}>{rowData.am_CR_CD}</td>
                                        
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <AccountMasterDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        onchangeDropDownFormData8={onchangeDropDownFormData8}
        onchangeDropDownFormData9={onchangeDropDownFormData9}
        onchangeDropDownFormData10={onchangeDropDownFormData10}
        onchangeDropDownFormData11={onchangeDropDownFormData11}
        onchangeDropDownFormData12={onchangeDropDownFormData12}
        onchangeDropDownFormData13={onchangeDropDownFormData13}
        onchangeDropDownFormData14={onchangeDropDownFormData14}
        onchangeDropDownFormData15={onchangeDropDownFormData15}
        am_CO_NM={am_CO_NM}
        am_PE_CD={am_PE_CD}
        am_AC_LD={am_AC_LD}
        am_CR_CD={am_CR_CD}
        am_PA_PN={am_PA_PN}
        am_CL_01={am_CL_01}
        am_CL_02={am_CL_02}
        am_CL_03={am_CL_03}
        am_CL_04={am_CL_04}
        am_CL_05={am_CL_05}
        am_CL_06={am_CL_06}
        am_CL_07={am_CL_07}
        am_CL_08={am_CL_08}
        am_CL_09={am_CL_09}
        am_CL_10={am_CL_10}
        am_CO_NO={am_CO_NO}
        selectedAbAdNM={selectedAbAdNM}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedemEMNO={setSelectedemEMNO}
     
      />
    </div>
  );
};

export default AccountMasterMain;
