import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import UnitAttributesDialog from "./UnitAttributesDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
    uaUkNo:"",
    ua_PR_NO:"",
    pr_DESC:"",
    ua_FL_NO:"",
    ua_BL_NO:"",
    bu_DESC:"",
    fl_DESC:"",
    ua_UN_NO:"",
    ua_PR_NO:"",
    ua_AB_01:"",
    ua_AB_02:"",
    ua_AB_07:"",
    ua_AB_08:"",
    ua_AB_09:"",
    ua_AB_10:"",
    ua_AB_11:"",
    ua_AB_12:"",
    ua_AB_13:"",
    ua_AB_14:"",
    ua_AB_15:"",
    ua_AB_16:"",
    ua_AB_17:"",
    ua_AB_18:"",
    ua_AB_19:"",
    ua_AB_20:"",
    ua_AB_21:"",
    ua_AB_22:"",
    ua_AB_23:"",
    ua_AB_24:"",
    ua_AB_25:"",
    ua_AB_26:"",
    ua_AB_27:"",
    ua_AB_28:"",
    ua_AB_29:"",
    ua_AB_30:"",
};
const initialFilters = {
  ua_BL_NO : { value: "", operation: "" },
  fl_DESC  : { value: "", operation: "" },
  ua_UN_NO : { value: "", operation: "" },
  ua_PR_NO : { value: "", operation: "" },
  bu_DESC  : { value: "", operation: "" },
  pr_DESC  : { value: "", operation: "" },
  ua_AB_01 : { value: "", operation: "" },
  ua_AB_02 : { value: "", operation: "" },
  ua_AB_08 : { value: "", operation: "" },
};

const UnitAttributesMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles, checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [ua_FL_NO, setua_FL_NO] = useState([]);
  const [ua_PR_NO, setua_PR_NO] = useState([]);
  const [ua_BL_NO, setua_BL_NO] = useState([]);
  const [PRname, setPRname] = useState([]);
  const [BLname, setBLname] = useState([]);
  const [ua_AB_01, setua_AB_01] = useState([]);
  const [ua_AB_02, setua_AB_02] = useState([]);
  const [ua_AB_07, setua_AB_07] = useState([]);
  const [ua_AB_08, setua_AB_08] = useState([]);
  const [ua_AB_09, setua_AB_09] = useState([]);
  const [ua_AB_10, setua_AB_10] = useState([]);
  const [ua_UN_NO, setua_UN_NO] = useState([]);
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setua_PR_NO(null);
    setua_BL_NO(null);
    setua_FL_NO(null);
    setua_UN_NO(null);
    setua_AB_01(null);
    setua_AB_02(null);
    setua_AB_07(null);
    setua_AB_08(null);
    setua_AB_09(null);
    setua_AB_10(null);
    setBLname(null);
    setPRname(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setua_PR_NO(null);
    setua_BL_NO(null);
    setua_FL_NO(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/UnitAttributes/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const UnitAttributesData = response.data;
          delete UnitAttributesData.uaUkNo;
          setFormData(UnitAttributesData);   
          setua_UN_NO(response.data.ua_UN_NO);
          setua_PR_NO(UnitAttributesData.ua_PR_NO);
          setua_BL_NO(UnitAttributesData.ua_BL_NO);
          setua_FL_NO(UnitAttributesData.ua_FL_NO);
          setua_AB_01(response.data.ua_AB_01);
          setua_AB_02(response.data.ua_AB_02);
          setua_AB_07(response.data.ua_AB_07);
          setua_AB_08(response.data.ua_AB_08);
          setua_AB_09(response.data.ua_AB_09);
          setua_AB_10(response.data.ua_AB_10);           
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/UnitAttributes/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    if(name=="ua_UN_NO")
      setua_UN_NO(value);
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setua_PR_NO(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setua_BL_NO(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setua_FL_NO(value);
  };
  const onChangeOfUnitSelect  = (data) => {
    setFormData({ ...FormData, ["ua_UN_NO"]: data.ui_UN_NO });
    setua_UN_NO(data.ui_UN_NO);
    setFormData({ ...FormData, ["ua_PR_NO"]: data.ui_PR_NO });
    setua_PR_NO(data.ui_PR_NO);
    setPRname(data.pr_DESC);
    setFormData({ ...FormData, ["ua_BL_NO"]: data.ui_BL_NO });
    setua_BL_NO(data.ui_BL_NO);
    setBLname(data.bu_DESC);
    setFormData({ ...FormData, ["ua_FL_NO"]: data.ui_FL_NO });
    setua_FL_NO(data.ui_FL_NO);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setua_AB_01(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setua_AB_02(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setua_AB_07(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setua_AB_08(value);
  };
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setua_AB_09(value);
  };
  const onchangeDropDownFormData9 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setua_AB_10(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/UnitAttributes/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const UnitAttributesData = response.data;
        setFormData(UnitAttributesData);
        setua_AB_01(response.data.ua_AB_01);
        setua_UN_NO(response.data.ua_UN_NO);
        setua_PR_NO(response.data.ua_PR_NO);
        setua_BL_NO(response.data.ua_BL_NO);
        setua_FL_NO(response.data.ua_FL_NO);
        setua_AB_02(response.data.ua_AB_02);
        setua_AB_07(response.data.ua_AB_07);
        setua_AB_08(response.data.ua_AB_08);
        setua_AB_09(response.data.ua_AB_09);
        setua_AB_10(response.data.ua_AB_10);
        setBLname(response.data.bu_DESC);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {
    const requestBody = {
      uaUkNo: FormData.uaUkNo,
      ua_PR_NO: ua_PR_NO,
      ua_BL_NO: ua_BL_NO,
      ua_FL_NO: ua_FL_NO,
      ua_UN_NO: ua_UN_NO,
      ua_CO_NO: company,
      ua_AB_01: FormData.ua_AB_01,
      ua_AB_02: FormData.ua_AB_02,
      ua_AB_07: FormData.ua_AB_07,
      ua_AB_08: FormData.ua_AB_08,
      ua_AB_09: FormData.ua_AB_09,
      ua_AB_10: FormData.ua_AB_10,
      ua_AB_11: FormData.ua_AB_11,
      ua_AB_12: FormData.ua_AB_12,
      ua_AB_13: FormData.ua_AB_13,
      ua_AB_14: FormData.ua_AB_14,
      ua_AB_15: FormData.ua_AB_15,
      ua_AB_16: FormData.ua_AB_16,
      ua_AB_17: FormData.ua_AB_17,
      ua_AB_18: FormData.ua_AB_18,
      ua_AB_19: FormData.ua_AB_19,
      ua_AB_20: FormData.ua_AB_20,
      ua_AB_21: FormData.ua_AB_21,
      ua_AB_22: FormData.ua_AB_22,
      ua_AB_23: FormData.ua_AB_23,
      ua_AB_24: FormData.ua_AB_24,
      ua_AB_25: FormData.ua_AB_25,
      ua_AB_26: FormData.ua_AB_26,
      ua_AB_27: FormData.ua_AB_27,
      ua_AB_28: FormData.ua_AB_28,
      ua_AB_29: FormData.ua_AB_29,
      ua_AB_30: FormData.ua_AB_30
    };

    if (FormData.uaUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/UnitAttributes/Update`;
      if (confirm) {
        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios
        .post(`${domain}/api/UnitAttributes/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
    
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const tableHeaderStyleH = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f5f4f4",
    display:"none"
  };
  const tableCellStyleH = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    display:"none"
  };
  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
    backgroundColor:"#f5f4f4"
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none"}}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["ua_PR_NO"] ? filters["ua_PR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_PR_NO",
                        e.target.value,
                        filters["ua_PR_NO"] ? filters["ua_PR_NO"].operation : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_DESC"] ? filters["pr_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_DESC",
                        e.target.value,
                        filters["pr_DESC"] ? filters["pr_DESC"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["ua_BL_NO"] ? filters["ua_BL_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_BL_NO",
                        e.target.value,
                        filters["ua_BL_NO"] ? filters["ua_BL_NO"].operation : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bu_DESC"] ? filters["bu_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bu_DESC",
                        e.target.value,
                        filters["bu_DESC"] ? filters["bu_DESC"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fl_DESC"] ? filters["fl_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fl_DESC",
                        e.target.value,
                        filters["fl_DESC"] ? filters["fl_DESC"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ua_UN_NO"] ? filters["ua_UN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_UN_NO",
                        e.target.value,
                        filters["ua_UN_NO"]
                          ? filters["ua_UN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ua_AB_01"] ? filters["ua_AB_01"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_AB_01",
                        e.target.value,
                        filters["ua_AB_01"] ? filters["ua_AB_01"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ua_AB_02"] ? filters["ua_AB_02"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_AB_02",
                        e.target.value,
                        filters["ua_AB_02"] ? filters["ua_AB_02"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ua_AB_08"] ? filters["ua_AB_08"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_AB_08",
                        e.target.value,
                        filters["ua_AB_08"]
                          ? filters["ua_AB_08"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyleH}>Property</th>
                <th style={tableHeaderStyle}>Property</th>
                <th style={tableHeaderStyleH}>Building</th>
                <th style={tableHeaderStyle}>Building</th>
                <th style={tableHeaderStyle}>Floor</th>
                <th style={tableHeaderStyle}>Unit</th>
                <th style={tableHeaderStyle}>No of Rooms</th>
                <th style={tableHeaderStyle}>No of Bathrooms </th>
                <th style={tableHeaderStyle}>No of Parking </th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.uaUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.uaUkNo}
                        checked={selectedRows.includes(rowData.uaUkNo)}
                        onChange={() => handleRowSelect(rowData.uaUkNo)}
                      />
                    </td>
                    <td style={tableCellStyleH}>{rowData.ua_PR_NO}</td>
                    <td style={tableCellStyle}>{rowData.pr_DESC}</td>
                    <td style={tableCellStyleH}>{rowData.ua_BL_NO}</td>
                    <td style={tableCellStyle}>{rowData.bu_DESC}</td>
                    <td style={tableCellStyle}>{rowData.fl_DESC}</td>                   
                    <td style={tableCellStyle}>{rowData.ua_UN_NO}</td>
                    <td style={tableCellStyle}>{rowData.ua_AB_01}</td>
                    <td style={tableCellStyle}>{rowData.ua_AB_02}</td>                   
                    <td style={tableCellStyle}>{rowData.ua_AB_08}</td>
                                      
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
  {/* pagination */}
  <Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      setCurrentPage={setCurrentPage}
    />
</div>

      <UnitAttributesDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        onchangeDropDownFormData8={onchangeDropDownFormData8}
        onchangeDropDownFormData9={onchangeDropDownFormData9}
        onChangeOfUnitSelect={onChangeOfUnitSelect}
        ua_FL_NO={ua_FL_NO}
        ua_PR_NO={ua_PR_NO}
        ua_BL_NO={ua_BL_NO}
        ua_AB_01={ua_AB_01}
        ua_AB_02={ua_AB_02}
        ua_AB_07={ua_AB_07}
        ua_AB_08={ua_AB_08}
        ua_AB_09={ua_AB_09}
        ua_AB_10={ua_AB_10}
        ua_UN_NO={ua_UN_NO}
        PRname={PRname}
        BLname={BLname}
        selectedAbAdNM={selectedAbAdNM}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedemEMNO={setSelectedemEMNO}     
      />
    </div>
  );
};

export default UnitAttributesMain;
