import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import ItemPopup from "../Popups/ItemPopup";
import ComponentPopup from "../Popups/ComponentPopup";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import ClassCode from "views/common/ClassCode";
export default function BillofMaterialDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  onchangedata,
  setBillMaterialtable,
  BillMaterialtable,
  bm_CO_NO,
  bm_BL_TY,
  setbm_BL_TY,
  parentname,
  setparentname,
  Componentname,
  setComponentname,
  bm_BT_UM,
  setbm_BT_UM,
  bm_CO_CT,
  setbm_CO_CT,
  bm_CO_UM,
  setbm_CO_UM,
  bm_FX_VA,
  setbm_FX_VA,
  bm_IS_TY,
  setbm_IS_TY,
  bm_ST_TY,
  setbm_ST_TY,
  bm_SC_UM,
  setbm_SC_UM,
  bm_SE_TY,
  setbm_SE_TY,
  bm_SE_MT,
  setbm_SE_MT,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
}) {
  const { 
    bmItNm,
    bm_BT_QT,
    bm_UK_NO,
    bm_FR_DT,
    bm_TO_DT,
    bm_CO_NM,
    bm_CO_QT,
    bmLnNo,
    bm_OP_SQ,
    bm_UN_CT,
    bm_RE_VR,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,savestyle,cancelstyle,Dialogstyle } = ButtonStyles();
  // from main page
  const addRow = () => {
    setBillMaterialtable([...BillMaterialtable, {}]);
  };
  const deleteRow = () => {
    if (BillMaterialtable.length > 0) {
      const updatedData = [...BillMaterialtable];
      updatedData.pop();
      setBillMaterialtable(updatedData);
    }
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
// cost centre dropdown
const { fetchCostCenter } = Costcenter();
const [bm_CO_CTOptions, setbm_CO_CTOptions] = useState([]);
const onchangeDropDownFormData1 = (name, value) => {
  setFormData({ ...FormData, [name]: value });
  setbm_CO_CT(value);
};
const fetchCostCentre = async () => {
  const CostOptions = await fetchCostCenter();
  setbm_CO_CTOptions(CostOptions);
};
// class code Dropdowns
const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
const [bm_BT_UMOptions, setbm_BT_UMOptions] = useState([]);
const [bm_CO_UMOptions, setbm_CO_UMOptions] = useState([]);
const [bm_FX_VAOptions, setbm_FX_VAOptions] = useState([]);
const [bm_IS_TYOptions, setbm_IS_TYOptions] = useState([]);
const [bm_ST_TYOptions, setbm_ST_TYOptions] = useState([]);
const [bm_SC_UMOptions, setbm_SC_UMOptions] = useState([]);
const [bm_SE_TYOptions, setbm_SE_TYOptions] = useState([]);
const [bm_SE_MTOptions, setbm_SE_MTOptions] = useState([]);
const [bm_BL_TYOptions, setbm_BL_TYOptions] = useState([]);
// HeaderUM
const onchangeDropDownFormData2 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setbm_BT_UM(value);
};
// Detail UM
const onchangeDropDownFormData3 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setbm_CO_UM(value);
};
// STOCKING TYPE
const onchangeDropDownFormData6 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setbm_ST_TY(value);
};
// SEC.UM
const onchangeDropDownFormData7 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setbm_SC_UM(value);
};
// SETTING TYPE
const onchangeDropDownFormData8 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setbm_SE_TY(value);
};
// SETTING METHOD
const onchangeDropDownFormData9 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setbm_SE_MT(value);
};
// type header
const onchangeDropDownFormData10 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setbm_BL_TY(value);
};
useEffect(() => {
  fetchDropdownTY();
  fetchCostCentre();
  fetchDropdownData();
  fetchDropdownDataUM();
  fetchDropdownDataFV();
  fetchDropdownDataIS();
  fetchDropdownDataSTY();
  fetchDropdownSUM();
  fetchDropdownSet();
  fetchDropdownSM();
}, []);
const fetchDropdownTY = async () => {
  const Options = await fetchClassCodeDS("BL/TY");
  setbm_BL_TYOptions(Options);
};
// HEADERum
const fetchDropdownData = async () => {
  const UMOptions = await fetchClassCodeCD("PR/UM");
  setbm_BT_UMOptions(UMOptions);
};
// detailUM
const fetchDropdownDataUM = async () => {
  const UMOptions = await fetchClassCodeCD("PR/UM");
  setbm_CO_UMOptions(UMOptions);
};
// FIXED/VARIABLE
const fetchDropdownDataFV = async () => {
  const FVOptions = await fetchClassCodeDS("BL/FV");
  setbm_FX_VAOptions(FVOptions);
};
// Issue Type
const fetchDropdownDataIS = async () => {
  const Options = await fetchClassCodeDS("IS/TY");
  setbm_IS_TYOptions(Options);
};
// Stocking Type
const fetchDropdownDataSTY = async () => {
  const Options = await fetchClassCodeDS("IN/TY");
  setbm_ST_TYOptions(Options);
};
// sec.UM
const fetchDropdownSUM = async () => {
  const UMOptions = await fetchClassCodeCD("PR/UM");
  setbm_SC_UMOptions(UMOptions);
};
// setting type
const fetchDropdownSet = async () => {
  const Options = await fetchClassCodeDS("BS/TY");
  setbm_SE_TYOptions(Options);
};
// SETTING METHOD
const fetchDropdownSM = async () => {
  const Options = await fetchClassCodeDS("BS/MT");
  setbm_SE_MTOptions(Options);
};
// parent item search starts
const [showSearchIcon, setShowSearchIcon] = useState("");
const [ParentPopupVisible, setParentPopupVisible] = useState(false);
const openParentPopup = () => {
  setParentPopupVisible(true);
};
const handleDataSelect = async (data) => {
setparentname(data.im_IT_DS);
document.getElementById("bmItNm").value = data.im_IT_NM;
const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
const requestData={
  "im_IT_NM": data.im_IT_NM
}
try {
const response =await axios.post(Url, requestData, {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "http://localhost:3000",
    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
  },
});
if (response.status !== 200) {
    throw new Error(`API request failed with status: ${response.status}`);
  }
  const dataItMt = response.data.Result;
  if (dataItMt ) {
    // document.getElementById ("bm_BT_UM").value =  dataItMt.im_PR_UM;
    onchangeDropDownFormData2("bm_BT_UM", dataItMt.im_PR_UM);
  } else {
    console.error("Invalid API response:", data);
  }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setParentPopupVisible(false);
  setShowSearchIcon(false);
  };
// parent item search ends
const [ComponentPopupVisible, setComponentPopupVisible] = useState(false);
const [SelectedRowIndex, setSelectedRowIndex] = useState("");
const openComponentPopup = (index) => {
  setSelectedRowIndex(index);
  setComponentPopupVisible(true);
};
const handleDataSelectC = async (data) => {
  setComponentname(data.im_IT_DS);
  onchangeFormData(`Componentname_${SelectedRowIndex}`, data.im_IT_DS);
  document.getElementById (`bm_CO_NM_${SelectedRowIndex}`).value =  data.im_IT_NM;
  const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
  const requestData={
      "im_IT_NM":data.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
        onchangeDropDownFormData3(`bm_CO_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setComponentPopupVisible(false);
  setShowSearchIcon(false);
};
const isUpdateMode = Boolean(bm_UK_NO);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{bm_UK_NO ? "Revise Bill of Material" : "Add Bill of Material"}</h5>
    <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
        tooltip={isUpdateMode ? "Update" : "Submit"}
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
return (
<Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div  style={borderbox} >
      <div style={inboxstyle} >
        <div className="formgrid grid">
            <div style={{display:"none"}}>
              <label htmlFor="bm_UK_NO" className="font-bold">
                uK
              </label>
              <InputText disabled
                id="bm_UK_NO"
                name="bm_UK_NO"
                value={bm_UK_NO}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="bmItNm" className="font-bold">
                    Parent Item <span style={{ color: 'red' }}>*</span>
                </label>
                <InputText style={{display:"none"}}
                  id="bmItNm"
                  name="bmItNm"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  defaultValue={bmItNm}
                />
                <div style={{display:"flex"}}>
                <InputText
                  id="im_IT_DS"
                  name="im_IT_DS"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={parentname}
                />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openParentPopup}
                  ></i>
                  )}
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bm_BL_TY" className="font-bold">
                  Type
              </label>
              <Dropdown
                id="bm_BL_TY"
                name="bm_BL_TY"
                value={bm_BL_TY}
                options={bm_BL_TYOptions}
                onChange={(e) => onchangeDropDownFormData10("bm_BL_TY", e.value)}
                placeholder="Select Type"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bm_CO_CT" className="font-bold">
                Cost Centre
              </label>
              <Dropdown disabled
              id="bm_CO_CT"
              name="bm_CO_CT"field col-lg-2 col-md-6 col-12 pe-md-2
              value={bm_CO_CT}
              options={bm_CO_CTOptions}
              onChange={(e) => onchangeDropDownFormData1("bm_CO_CT", e.value)}
              placeholder="Select Cost Centre"
            />
            </div> 
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bm_BT_QT" className="font-bold">
                Batch Quantity
              </label>
              <InputText
                id="bm_BT_QT"
                name="bm_BT_QT"
                value={bm_BT_QT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bm_BT_UM" className="font-bold">
                UM
              </label>
              <Dropdown
              id="bm_BT_UM"
              name="bm_BT_UM"
              value={bm_BT_UM}
              options={bm_BT_UMOptions}
              onChange={(e) => onchangeDropDownFormData2("bm_BT_UM", e.value)}
              placeholder="Select UM"
            />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bm_RE_VR" className="font-bold">
              Revision
              </label>
              <InputText
                id="bm_RE_VR"
                name="bm_RE_VR"
                value={bm_RE_VR}
                onChange={(e) => onchangedata(e)}
              />
            </div>
        </div>
        </div>
      </div>
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="BillMaterialtable" style={{overflow:"auto"}}>
          <thead>
            <tr>
              <th style={{display:"none"}}>UK_NO</th>
              <th>Line No</th>
              <th>Component Item <span style={{ color: 'red' }}>*</span> </th>
              <th>Description</th>
              <th>Quantity</th>
              <th>UM</th>
              <th>Fixed/Variable</th>
              <th>Issue Type</th>
              <th>Oper. Seq.</th>
              <th>Unit Cost</th>
              <th>Stocking Type</th>
              <th>Sec. Qty</th>
              <th>Sec. UM</th>
              <th style={{display:"none"}}>Setting Type</th>
              <th style={{display:"none"}}>Setting Method</th>
              <th>Rate</th>
              <th>From Date</th>
              <th>To Date</th>
              <th>Upper Tolerance</th>
              <th>Lower Tolerance</th>
            </tr>
          </thead>
          <tbody>
          {BillMaterialtable.map((row, index) => (
            <tr key={index}>
              <td  style={{display:"none"}}>
                <InputText
                  id={`bm_UK_NO_${index}`}
                  name={`bm_UK_NO_${index}`}
                  value={row.bm_UK_NO}
                />
              </td>
              <td>
                <InputText disabled style={{width:'5rem'}}
                  id={`bmLnNo_${index}`}
                  name={`bmLnNo_${index}`}
                  value={index+1}
                />
              </td>
              <td>
            <div style={{display:"flex"}}>
              <InputText  style={{width:'10rem'}}
                id={`bm_CO_NM_${index}`}
                name={`bm_CO_NM_${index}`}
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                defaultValue={row.bm_CO_NM ? row.bm_CO_NM: data[`${"bm_CO_NM"}_${index}`]}
              />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={() => openComponentPopup(index)}
                ></i>
                )}
               </div>
              </td>
              <td>
              {/* <InputText disabled
                style={{width:"250px"}}
                id={`im_IT_DS_${index}`}
                name={`im_IT_DS_${index}`}
                value={Componentname}
                onChange={(Event) => onchangedata(Event)}
              /> */}
               <InputText  disabled  style={{width:'15rem'}}
                  id={`Componentname_${index}`}
                  name={`Componentname_${index}`}
                  onChange={(e) => onchangedata('Componentname',e.value,index)}
                  value={row.Componentname ? row.Componentname : row[`${'Componentname'}_${index}`]}
                />
              </td>
              <td>
              <InputText style={{width:'7rem'}}
                id={`bm_CO_QT_${index}`}
                name={`bm_CO_QT_${index}`}
                defaultValue={row.bm_CO_QT}
                onChange={(Event) => onchangedata(Event)}
              />
              </td>
              <td>
              <Dropdown 
                id={`bm_CO_UM_${index}`}
                name={`bm_CO_UM_${index}`}
                value={data[`bm_CO_UM_${index}`] ? data[`bm_CO_UM_${index}`] : row.bm_CO_UM}
                options={bm_CO_UMOptions}
                onChange={(e) => onchangeDropDownFormData3(`bm_CO_UM_${index}`, e.value)}
                placeholder="Select UM"
              />
              </td>
              <td>
               <Dropdown 
                id={`bm_FX_VA_${index}`}
                name={`bm_FX_VA_${index}`}
                value={bm_FX_VA}
                options={bm_FX_VAOptions}
                onChange={(e) => onchangeDropDownFormData4(`bm_FX_VA_${index}`, e.value)}
                placeholder="Select F/V"
              />
              </td>
              <td>
               <Dropdown
                id={`bm_IS_TY_${index}`}
                name={`bm_IS_TY_${index}`}
                value={bm_IS_TY}
                options={bm_IS_TYOptions}
                onChange={(e) => onchangeDropDownFormData5(`bm_IS_TY_${index}`, e.value)}
                placeholder="Select Issue Type"
              />
              </td>
              <td>
              <InputText style={{width:'8rem'}}
                id={`bm_OP_SQ_${index}`}
                name={`bm_OP_SQ_${index}`}
                defaultValue={row.bm_OP_SQ}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText style={{width:'8rem'}}
                id={`bm_UN_CT_${index}`}
                name={`bm_UN_CT_${index}`}
                defaultValue={row.bm_UN_CT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <Dropdown
                id={`bm_ST_TY_${index}`}
                name={`bm_ST_TY_${index}`}
                value={data[`bm_ST_TY_${index}`] ? data[`bm_ST_TY_${index}`] : row.bm_ST_TY}
                options={bm_ST_TYOptions}
                onChange={(e) => onchangeDropDownFormData6(`bm_ST_TY_${index}`, e.value)}
                placeholder="Select Stocking Type"
              />
              </td>
              <td>
              <InputText style={{width:'8rem'}}
                id={`bm_SC_QT_${index}`}
                name={`bm_SC_QT_${index}`}
                defaultValue={row.bm_SC_QT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <Dropdown
                id={`bm_SC_UM_${index}`}
                name={`bm_SC_UM_${index}`}
                value={data[`bm_SC_UM_${index}`] ? data[`bm_SC_UM_${index}`] : row.bm_SC_UM}
                options={bm_SC_UMOptions}
                onChange={(e) => onchangeDropDownFormData7(`bm_SC_UM_${index}`, e.value)}
                placeholder="Select Sec.UM"
              />
              </td>
              <td style={{display:"none"}}>
              <Dropdown 
                id={`bm_SE_TY_${index}`}
                name={`bm_SE_TY_${index}`}
                value={data[`bm_SE_TY_${index}`] ? data[`bm_SE_TY_${index}`] : row.bm_SE_TY}
                options={bm_SE_TYOptions}
                onChange={(e) => onchangeDropDownFormData8(`bm_SE_TY_${index}`, e.value)}
                placeholder="Select Setting Type"
              />
              </td>
              <td style={{display:"none"}}>
              <Dropdown
                id={`bm_SE_MT_${index}`}
                name={`bm_SE_MT_${index}`}
                value={data[`bm_SE_MT_${index}`] ? data[`bm_SE_MT_${index}`] : row.bm_SE_MT}
                options={bm_SE_MTOptions}
                onChange={(e) => onchangeDropDownFormData9(`bm_SE_MT_${index}`, e.value)}
                placeholder="Select Setting Method"
              />
              </td>
              <td>
              <InputText style={{width:'8rem'}}
                id={`bm_LB_RT_${index}`}
                name={`bm_LB_RT_${index}`}
                defaultValue={row.bm_LB_RT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <Calendar style={{width:'8rem'}}
                id={`bm_FR_DT_${index}`}
                name={`bm_FR_DT_${index}`}
                value={row.bm_FR_DT? moment(row.bm_FR_DT, "YYYYMMDD").toDate(): null}
                onChange={(e) => onDateChange({ target: { name: "bm_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                dateFormat="dd/mm/yy"
              />
              </td>
              <td>
              <Calendar style={{width:'8rem'}}
                id={`bm_TO_DT_${index}`}
                name={`bm_TO_DT_${index}`}
                value={ row.bm_TO_DT ? moment(row.bm_TO_DT, "YYYYMMDD").toDate() : null }
                onChange={(e) => onDateChange({ target: { name: "bm_TO_DT", value: moment(e.value).format("YYYYMMDD") } })}
                dateFormat="dd/mm/yy"
              />
              </td>
              <td>
              <InputText style={{width:'8rem'}}
                id={`bm_UP_TL_${index}`}
                name={`bm_UP_TL_${index}`}
                defaultValue={row.bm_UP_TL}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText style={{width:'8rem'}}
                id={`bm_LW_TL_${index}`}
                name={`bm_LW_TL_${index}`}
                defaultValue={row.bm_LW_TL}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              </tr>
              ))}
          </tbody>
        </table>  
        </div>
      </div>

    <ItemPopup
      visible={ParentPopupVisible}
      onClose={() => setParentPopupVisible(false)}
      onDataSelect={handleDataSelect}
      formData={data}
    /> 
    <ComponentPopup
      visible={ComponentPopupVisible}
      onClose={() => setComponentPopupVisible(false)}
      onDataSelect={handleDataSelectC}
      formData={data}
    />
</Dialog>
  );  
}
