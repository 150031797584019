import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import CampaignDialog from "./PropertyListingDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  plUkNo:"",
  pl_PR_NM:"",
  pl_LS_TY:"",
  pl_LS_CT:"",
  pl_PR_CT:"",
  pl_PR_TY:"", 
  pl_PR_AR:"",
  pl_PR_UM:"",
  pl_BA_RM:"", 
  pl_BE_RM:"",
  pl_PA_SP:"", 
  pl_LO_CN:"",   
  pl_AD_CY:"",   
  pl_ST_CD:"", 
};

const initialFilters = {
  pl_PR_NM: { value: "", operation: "" },
  pl_LS_TY: { value: "", operation: "" },
  pl_LS_CT: { value: "", operation: "" },
  pl_PR_CT: { value: "", operation: "" },
  pl_PR_TY: { value: "", operation: "" }, 
  pl_PR_AR: { value: "", operation: "" },
  pl_PR_UM: { value: "", operation: "" },
  pl_BA_RM: { value: "", operation: "" }, 
  pl_BE_RM: { value: "", operation: "" },
  pl_PA_SP: { value: "", operation: "" }, 
  pl_LO_CN: { value: "", operation: "" },   
  pl_AD_CY: { value: "", operation: "" },   
  pl_ST_CD: { value: "", operation: "" }, 
};

const CampaignMain = () => {
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
// drodpdowns
  const [pl_LS_TY, setpl_LS_TY ] = useState("");
  const [pl_LS_CT, setpl_LS_CT ] = useState("");
  const [pl_PR_CT, setpl_PR_CT ] = useState("");
  const [pl_PR_TY, setpl_PR_TY ] = useState("");
  const [pl_LO_CN, setpl_LO_CN ] = useState("");
  const [pl_LO_SB, setpl_LO_SB ] = useState("");
  const [pl_WB_PU, setpl_WB_PU ] = useState("");
  const [pl_PR_NM, setpl_PR_NM ] = useState("");
  const [pl_UN_NO, setpl_UN_NO ] = useState("");
  const [pl_FL_NO, setpl_FL_NO ] = useState("");
  const [pl_ST_CD, setpl_ST_CD ] = useState("");
  

  const [pl_PR_AR, setpl_PR_AR ] = useState("");
  const [pl_BA_RM, setpl_BA_RM ] = useState("");
  const [pl_BE_RM, setpl_BE_RM ] = useState("");
  const [pl_PA_SP, setpl_PA_SP ] = useState("");
  const [propertyOwner_DESC, setpropertyOwner_DESC ] = useState("");
  
  
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");


  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setpl_WB_PU(value);
  };
  const onChangeOfUnitSelect  = (data) => {
    setFormData({ ...FormData, ["pl_UN_NO"]: data.ui_UN_NO });
    setpl_UN_NO(data.ui_UN_NO);
    setFormData({ ...FormData, ["pl_FL_NO"]: data.ui_FL_NO });
    setpl_FL_NO(data.pl_FL_NO);
    // setFormData({ ...FormData, ["ua_BL_NO"]: data.ui_BL_NO });
    // setua_BL_NO(data.ui_BL_NO);
  };

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setpl_LS_TY(null);
    setpl_LS_CT(null);
    setpl_PR_CT(null);
    setpl_PR_TY(null);
    setpl_LO_CN(null);
    setpl_LO_SB(null);
    setpl_WB_PU(null);
    setpl_UN_NO(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/PropertyListing/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const PropertyListingData = response.data;
          delete PropertyListingData.plUkNo;
          setFormData(PropertyListingData);
         setpl_LS_TY(PropertyListingData.pl_LS_TY);
         setpl_LS_CT(PropertyListingData.pl_LS_CT);
         setpl_PR_CT(PropertyListingData.pl_PR_CT);
         setpl_PR_TY(PropertyListingData.pl_PR_TY);
         setpl_LO_CN(PropertyListingData.pl_LO_CN);
         setpl_LO_SB(PropertyListingData.pl_LO_SB);
         setpl_WB_PU(PropertyListingData.pl_WB_PU);
         setpl_FL_NO(PropertyListingData.pl_FL_NO);
         setpl_PR_AR(PropertyListingData.pl_PR_AR);
         setpl_BA_RM(PropertyListingData.pl_BA_RM);
         setpl_BE_RM(PropertyListingData.pl_BE_RM);
         setpl_PA_SP(PropertyListingData.pl_PA_SP);
         setpropertyOwner_DESC(PropertyListingData.fac_NAME);

         
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [pl_CO_NO , setpl_CO_NO] = useState("");
 
  const handleDefault = async () => {
    setpl_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PropertyListing/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
 
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/PropertyListing/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const PropertyListingData = response.data;
        setFormData(PropertyListingData);
        setpl_LS_TY(PropertyListingData.pl_LS_TY);
        setpl_LS_CT(PropertyListingData.pl_LS_CT);
        setpl_PR_CT(PropertyListingData.pl_PR_CT);
        setpl_PR_TY(PropertyListingData.pl_PR_TY);
        setpl_LO_CN(PropertyListingData.pl_LO_CN);
        setpl_LO_SB(PropertyListingData.pl_LO_SB);
        setpl_WB_PU(PropertyListingData.pl_WB_PU);
        setpl_FL_NO(PropertyListingData.pl_FL_NO);
        setpl_PR_AR(PropertyListingData.pl_PR_AR);
        setpl_BA_RM(PropertyListingData.pl_BA_RM);
        setpl_BE_RM(PropertyListingData.pl_BE_RM);
        setpl_PA_SP(PropertyListingData.pl_PA_SP);
        setpropertyOwner_DESC(PropertyListingData.fac_NAME);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {
    const requestBody = {
      plUkNo: FormData.plUkNo,
      pl_CO_NO:company,
      pl_PR_NM:document.getElementById("pl_PR_NM").value,
      pl_LS_TY:pl_LS_TY,
      pl_LS_CT:pl_LS_CT,
      pl_PR_CT:pl_PR_CT,
      pl_PR_TY:pl_PR_TY,
      pl_LO_CN:pl_LO_CN,
      pl_LO_SB:pl_LO_SB,
      pl_UN_NO:pl_UN_NO,
      pl_FL_NO: document.getElementById("pl_FL_NO").value,
      pl_BL_NO: document.getElementById("pl_BL_NO").value,
      pl_PR_AR: document.getElementById("pl_PR_AR").value,
      pl_BA_RM: document.getElementById("pl_BA_RM").value,
      pl_BE_RM: document.getElementById("pl_BE_RM").value,
      pl_PA_SP: document.getElementById("pl_PA_SP").value,
      pl_ST_CD:pl_ST_CD,
      pl_WB_PU:pl_WB_PU,
      pl_WB_SI: document.getElementById("pl_WB_SI").value,
      pl_AD_L1: document.getElementById("pl_AD_L1").value,
      pl_AD_CT: document.getElementById("pl_AD_CT").value,
      pl_AD_ST: document.getElementById("pl_AD_ST").value,
      pl_ZI_CD: document.getElementById("pl_ZI_CD").value,
      pl_AD_CY: document.getElementById("pl_AD_CY").value,

    }
    if (FormData.plUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/PropertyListing/Update`;
      if (confirm) {
        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.pl_CO_NO = company;
      axios
        .post(`${domain}/api/PropertyListing/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_PR_NM"] ? filters["pl_PR_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_PR_NM",
                        e.target.value,
                        filters["pl_PR_NM"]
                          ? filters["pl_PR_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_LS_TY"] ? filters["pl_LS_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_LS_TY",
                        e.target.value,
                        filters["pl_LS_TY"] ? filters["pl_LS_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_LS_CT"] ? filters["pl_LS_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_LS_CT",
                        e.target.value,
                        filters["pl_LS_CT"]
                          ? filters["pl_LS_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_PR_CT"] ? filters["pl_PR_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_PR_CT",
                        e.target.value,
                        filters["pl_PR_CT"]
                          ? filters["pl_PR_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_PR_TY"] ? filters["pl_PR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_PR_TY",
                        e.target.value,
                        filters["pl_PR_TY"]
                          ? filters["pl_PR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_PR_AR"] ? filters["pl_PR_AR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_PR_AR",
                        e.target.value,
                        filters["pl_PR_AR"]
                          ? filters["pl_PR_AR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_PR_UM"] ? filters["pl_PR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_PR_UM",
                        e.target.value,
                        filters["pl_PR_UM"] ? filters["pl_PR_UM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_BA_RM"] ? filters["pl_BA_RM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_BA_RM",
                        e.target.value,
                        filters["pl_BA_RM"]
                          ? filters["pl_BA_RM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_BE_RM"] ? filters["pl_BE_RM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_BE_RM",
                        e.target.value,
                        filters["pl_BE_RM"]
                          ? filters["pl_BE_RM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_PA_SP"] ? filters["pl_PA_SP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_PA_SP",
                        e.target.value,
                        filters["pl_PA_SP"]
                          ? filters["pl_PA_SP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_LO_CN"] ? filters["pl_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_LO_CN",
                        e.target.value,
                        filters["pl_LO_CN"]
                          ? filters["pl_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_AD_CY"] ? filters["pl_AD_CY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_AD_CY",
                        e.target.value,
                        filters["pl_AD_CY"]
                          ? filters["pl_AD_CY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_ST_CD"] ? filters["pl_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_ST_CD",
                        e.target.value,
                        filters["pl_ST_CD"]
                          ? filters["pl_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Property Name</th>
                <th style={tableHeaderStyle}>Listing Type</th>
                <th style={tableHeaderStyle}>Listing Category</th>
                <th style={tableHeaderStyle}>Property Category</th>
                <th style={tableHeaderStyle}>Property Type</th>
                <th style={tableHeaderStyle}>Area</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Bed Room</th>
                <th style={tableHeaderStyle}>Bath Room</th>
                <th style={tableHeaderStyle}>Parking Slot</th>
                <th style={tableHeaderStyle}>Location</th>
                <th style={tableHeaderStyle}>Country</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.plUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.plUkNo}
                        checked={selectedRows.includes(rowData.plUkNo)}
                        onChange={() => handleRowSelect(rowData.plUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.pl_PR_NM}</td>
                    <td style={tableCellStyle}>{rowData.pl_LS_TY}</td>
                    <td style={tableCellStyle}>{rowData.pl_LS_CT}</td>
                    <td style={tableCellStyle}>{rowData.pl_PR_CT}</td>
                    <td style={tableCellStyle}>{rowData.pl_PR_TY}</td> 
                    <td style={tableCellStyle}>{rowData.pl_PR_AR}</td>
                    <td style={tableCellStyle}>{rowData.pl_PR_UM}</td>
                    <td style={tableCellStyle}>{rowData.pl_BA_RM}</td> 
                    <td style={tableCellStyle}>{rowData.pl_BE_RM}</td>
                    <td style={tableCellStyle}>{rowData.pl_PA_SP}</td> 
                    <td style={tableCellStyle}>{rowData.pl_LO_CN}</td>   
                    <td style={tableCellStyle}>{rowData.pl_AD_CY}</td>   
                    <td style={tableCellStyle}>{rowData.pl_ST_CD}</td>                   
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <CampaignDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
    
        pl_LS_TY={pl_LS_TY}
        setpl_LS_TY={setpl_LS_TY}
        pl_LS_CT={pl_LS_CT}
        setpl_LS_CT={setpl_LS_CT}
        pl_PR_CT={pl_PR_CT}
        setpl_PR_CT={setpl_PR_CT}
        pl_PR_TY={pl_PR_TY}
        setpl_PR_TY={setpl_PR_TY}
        pl_LO_CN={pl_LO_CN}
        setpl_LO_CN={setpl_LO_CN}
        pl_LO_SB={pl_LO_SB}
        setpl_LO_SB={setpl_LO_SB}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        pl_WB_PU={pl_WB_PU}
        setpl_WB_PU={setpl_WB_PU}
        pl_PR_NM={pl_PR_NM}
        setpl_PR_NM={setpl_PR_NM}
        onChangeOfUnitSelect={onChangeOfUnitSelect}
        pl_UN_NO={pl_UN_NO}
        setpl_UN_NO={setpl_UN_NO}
        pl_FL_NO={pl_FL_NO}
        setpl_FL_NO={setpl_FL_NO}
        pl_ST_CD={pl_ST_CD}
        setpl_ST_CD={setpl_ST_CD}
        pl_CO_NO={pl_CO_NO}
        pl_PR_AR={pl_PR_AR}
        setpl_PR_AR={setpl_PR_AR}
        pl_BA_RM={pl_BA_RM}
        setpl_BA_RM={setpl_BA_RM}
        pl_BE_RM={pl_BE_RM}
        setpl_BE_RM={setpl_BE_RM}
        pl_PA_SP={pl_PA_SP}
        setpl_PA_SP={setpl_PA_SP}
        propertyOwner_DESC={propertyOwner_DESC}
        setpropertyOwner_DESC={setpropertyOwner_DESC}
      />
    </div>
  );
};

export default CampaignMain;
