import React, { useState, useEffect ,useRef} from "react";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function PreApproval({  isOpen, handleClose, }) {

  const {tableHeaderStyle,tableCellStyle} = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  useEffect(() => {
    const Getcallgaurd = async () => {
      const requestBody = {
        "searchRequestDto":[],
      };
      try {
        const response = await axios.post(
          `${domain}/api/PropertySecurity/List/${currentPage}/${pageSize}`,
           requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
        const data = response.data;
        if (data && data.content && Array.isArray(data.content)) {
          setTableData(data.content);
          setTotalPages(data.totalPages);
        } else {
          console.error("Invalid API response:", data);
        }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
    };
      Getcallgaurd();
  }, []);
    return (
        <Dialog id="dialog-style"
          visible={isOpen}
          onHide={handleClose}
          style={{ width: "40rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header={"Call Gaurd"}
          modal
          className="p-fluid"
        >
          <div>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <th style={tableHeaderStyle} >Name</th>
                <th style={tableHeaderStyle} >Phone Number</th>
            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr key={rowData.psUkNo} className="table-row"
                    style={{ backgroundColor:  index % 2 === 0 ? "aliceblue" : "#ffffff",  }}
                  >
                    <td style={tableCellStyle} >{rowData.ps_DP_NM}</td>
                    <td style={tableCellStyle} >{rowData.ps_DP_FN}</td>            
                  </tr>
                ))}
            </tbody>
          </table>
          </div>
        </Dialog>
      );

  }