import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import PriceAdjustmentDetailDialog from "./PriceAdjustmentDetailDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  adUkNo:"",
  ad_AD_NM:"",
  ad_IT_NM:"",
  item_DESC:"",   
  ad_CU_NO:"",
  cus_NAME:"",
  ad_IT_GP:"",   
  ad_CU_GP:"", 
  ad_FR_LV:"",    
  ad_PR_UM:"",
  ad_FC_LV:"",
  ad_BA_IS:"",    
  ad_TR_CR:"",  
  ad_FR_DT:"",
  ad_TO_DT:"",    
  ad_CO_MT:"",   
};

const initialFilters = {
  ad_AD_NM: { value: "", operation: "" },
  ad_IT_NM: { value: "", operation: "" },
  item_DESC: { value: "", operation: "" },   
  ad_CU_NO: { value: "", operation: "" },
  cus_NAME: { value: "", operation: "" },
  ad_IT_GP: { value: "", operation: "" },   
  ad_CU_GP: { value: "", operation: "" }, 
  ad_FR_LV: { value: "", operation: "" },    
  ad_PR_UM: { value: "", operation: "" },
  ad_FC_LV: { value: "", operation: "" },
  ad_BA_IS: { value: "", operation: "" },    
  ad_TR_CR: { value: "", operation: "" },  
  ad_FR_DT: { value: "", operation: "" },
  ad_TO_DT: { value: "", operation: "" },    
  ad_CO_MT: { value: "", operation: "" },   
};

const PriceAdjustmentDetailMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,rightalined
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  const [ad_AD_NM, setad_AD_NM ] = useState("");
  const [ad_IT_GP, setad_IT_GP ] = useState("");
  const [ad_CU_GP, setad_CU_GP ] = useState("");
  const [ad_PR_UM, setad_PR_UM ] = useState("");
  const [ad_BA_IS, setad_BA_IS ] = useState("");
  const [ad_CO_MT, setad_CO_MT ] = useState("");
  const [ad_TR_CR, setad_TR_CR ] = useState("");
// AdvanceSearch
  const [SelectedITNM, setSelectedITNM ] = useState("");
  const [SelectedCust, setSelectedCust ] = useState("");
// Checkboxes
  const [ad_PR_MT, setad_PR_MT ] = useState("");

  const [itemcusChecked, setitemcusChecked ] = useState("");
  const [itemcusgrpChecked, setitemcusgrpChecked ] = useState("");
  const [itemGrpCusChecked, setitemGrpCusChecked ] = useState("");
  const [itemGrpCusGrpChecked, setitemGrpCusGrpChecked ] = useState("");
  const [itemChecked, setitemChecked ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [PriceAdjustmentDetailTableData, setPriceAdjustmentDetailTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setPriceAdjustmentDetailTableData([{}]);
    setad_AD_NM(null);
    setad_IT_GP(null);
    setad_CU_GP(null);
    setad_PR_UM(null);
    setad_BA_IS(null);
    setad_CO_MT(null);
    setad_TR_CR(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/PriceAdjustmentDetail/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const CashFlowData = response.data;
          const updatedCashFlowData = CashFlowData.PriceAdjustmentDetailList.map((target) => {
            const UpdatedworkData = { ...target };
            delete UpdatedworkData.adUkNo;
            return UpdatedworkData;
          });
          setPriceAdjustmentDetailTableData(updatedCashFlowData);
          setFormData(updatedCashFlowData[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [ad_TR_CO , setad_TR_CO] = useState("");
  const handleDefault = async () => {
    setad_TR_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PriceAdjustmentDetail/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value,}));
  };
  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/PriceAdjustmentDetail/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const FreeGoodsData = response.data;
        setPriceAdjustmentDetailTableData(FreeGoodsData.PriceAdjustmentDetailList);
        setFormData(FreeGoodsData.PriceAdjustmentDetailList[0]);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("PriceAdjustmentDetailtable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const priceAdjustmentDetailList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const FR_DT = document.getElementsByName("ad_FR_DT_" + i)[0]; 
    const TO_DT = document.getElementsByName("ad_TO_DT_" + i)[0];
    const details = {
        adUkNo : document.getElementById("adUkNo_" + i).value,
        ad_FR_LV : document.getElementById("ad_FR_LV_" + i).value,
        ad_PR_UM : table.tBodies[0].rows[i].cells[2].children[0].children[1].children[0].value,
        ad_FC_LV : document.getElementById("ad_FC_LV_" + i).value,
        ad_BA_IS : table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
        ad_FR_DT : FR_DT.value?moment(FR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        ad_TO_DT : TO_DT.value?moment(TO_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        ad_CO_MT : table.tBodies[0].rows[i].cells[8].children[0].children[1].children[0].value,
        ad_TR_CR : table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
        ad_TR_NO : document.getElementById("ad_TR_NO_" + i).value,
        ad_ST_CD : document.getElementById("ad_ST_CD_" + i).value,
    };
    priceAdjustmentDetailList.push(details);
  }
 requestBody = {
  priceAdjustmentDetailList,
    adUkNo:FormData.adUkNo,
    ad_AD_NM : FormData.ad_AD_NM,
    ad_IT_NM : document.getElementById("ad_IT_NM").value,
    ad_IT_GP : FormData.ad_IT_GP,
    ad_CU_NO : document.getElementById("ad_CU_NO").value,
    ad_CU_GP : FormData.ad_CU_GP,
  };
}
const handleFormSubmit = () => {
  values ();
  if (FormData.adUkNo) {
    const confirm = window.confirm("Do you want to Update ?");
    const update = `${domain}/api/PriceAdjustmentDetail/Update`;
    if (confirm) {
        axios.put(update, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });
    }
  } else {
    FormData.fr_CO_NO = company;
      axios.post(`${domain}/api/PriceAdjustmentDetail/Add`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        handleResponse(resp.data);
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  }
};
const leftToolbarTemplate = ( 
  <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack >
      <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
      Update
    </div>  
    </Tooltip>
      <Button   
      style={buttonStyles}
      icon="pi pi-check"
      outlined
      className="mr-2 update-button"
      onClick={handleUpdate}
      disabled={!isRecordSelected}
    ></Button>
    <Tooltip target=".add-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Add
      </div>
  </Tooltip>
    <Button 
      style={addButtonStyles}
      icon="pi pi-plus"     
      outlined
      className="mr-2 add-button"
      onClick={handleAddButtonClick}
    ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Copy
      </div>
  </Tooltip>
    <Button 
      style={copyButtonStyles}
      icon="pi pi-copy"
      outlined
      className="mr-2 copy-button"
      onClick={handleCopyData}
    ></Button>
  </React.Fragment>
);
const exportExcel = () => {
  import("xlsx").then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(tableData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "products_export.xlsx");
  });
};
const rightToolbarTemplate = (
  <React.Fragment>
    <Button
      style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
      icon="pi pi-upload"
      className="p-button-help"
      rounded
      onClick={exportExcel}
      data-pr-tooltip="XLS"
    />
  </React.Fragment>
);
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{  borderCollapse: "collapse", width: "100%", marginTop: "20px", }}>
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ad_AD_NM"] ? filters["ad_AD_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_AD_NM",
                        e.target.value,
                        filters["ad_AD_NM"]
                          ? filters["ad_AD_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ad_IT_NM"] ? filters["ad_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_IT_NM",
                        e.target.value,
                        filters["ad_IT_NM"] ? filters["ad_IT_NM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["item_DESC"] ? filters["item_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "item_DESC",
                        e.target.value,
                        filters["item_DESC"]
                          ? filters["item_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>      
                
                <td>
                  <input
                    type="text"
                    value={filters["ad_CU_NO"] ? filters["ad_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_CU_NO",
                        e.target.value,
                        filters["ad_CU_NO"]
                          ? filters["ad_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cus_NAME"] ? filters["cus_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cus_NAME",
                        e.target.value,
                        filters["cus_NAME"] ? filters["cus_NAME"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ad_IT_GP"] ? filters["ad_IT_GP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_IT_GP",
                        e.target.value,
                        filters["ad_IT_GP"]
                          ? filters["ad_IT_GP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["ad_CU_GP"] ? filters["ad_CU_GP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_CU_GP",
                        e.target.value,
                        filters["ad_CU_GP"]
                          ? filters["ad_CU_GP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ad_FR_LV"] ? filters["ad_FR_LV"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_FR_LV",
                        e.target.value,
                        filters["ad_FR_LV"]
                          ? filters["ad_FR_LV"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ad_PR_UM"] ? filters["ad_PR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_PR_UM",
                        e.target.value,
                        filters["ad_PR_UM"]
                          ? filters["ad_PR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ad_FC_LV"] ? filters["ad_FC_LV"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_FC_LV",
                        e.target.value,
                        filters["ad_FC_LV"]
                          ? filters["ad_FC_LV"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ad_BA_IS"] ? filters["ad_BA_IS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_BA_IS",
                        e.target.value,
                        filters["ad_BA_IS"]
                          ? filters["ad_BA_IS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["ad_TR_CR"] ? filters["ad_TR_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_TR_CR",
                        e.target.value,
                        filters["ad_TR_CR"]
                          ? filters["ad_TR_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ad_FR_DT"] ? filters["ad_FR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_FR_DT",
                        e.target.value,
                        filters["ad_FR_DT"]
                          ? filters["ad_FR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["ad_TO_DT"] ? filters["ad_TO_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_TO_DT",
                        e.target.value,
                        filters["ad_TO_DT"]
                          ? filters["ad_TO_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ad_CO_MT"] ? filters["ad_CO_MT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_CO_MT",
                        e.target.value,
                        filters["ad_CO_MT"]
                          ? filters["ad_CO_MT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Adjustment Name </th>
                <th style={tableHeaderStyle}>Item Name</th>
                <th style={tableHeaderStyle}>Description</th>  
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Item Group</th>   
                <th style={tableHeaderStyle}>Customer Group</th>  
                <th style={tableHeaderStyle}>From Level</th> 
                <th style={tableHeaderStyle}>UM</th>  
                <th style={tableHeaderStyle}>Factor Level</th>  
                <th style={tableHeaderStyle}>Basis</th> 
                <th style={tableHeaderStyle}>Currency</th>  
                <th style={tableHeaderStyle}>From Date</th>
                <th style={tableHeaderStyle}>To Date</th> 
                <th style={tableHeaderStyle}>Cost Method</th>                
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.adUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.adUkNo}
                        checked={selectedRows.includes(rowData.adUkNo)}
                        onChange={() => handleRowSelect(rowData.adUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ad_AD_NM}</td>
                    <td style={tableCellStyle}>{rowData.ad_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.item_DESC}</td>   
                    <td style={tableCellStyle}>{rowData.ad_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.cus_NAME}</td>
                    <td style={tableCellStyle}>{rowData.ad_IT_GP}</td>   
                    <td style={tableCellStyle}>{rowData.ad_CU_GP}</td> 
                    <td style={tableCellStyle}>{rowData.ad_FR_LV}</td>    
                    <td style={tableCellStyle}>{rowData.ad_PR_UM}</td>
                    <td style={tableCellStyle}>{rowData.ad_FC_LV}</td>
                    <td style={tableCellStyle}>{rowData.ad_BA_IS}</td>    
                    <td style={tableCellStyle}>{rowData.ad_TR_CR}</td>  
                    <td style={tableCellStyle}>{formatDate(rowData.ad_FR_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.ad_TO_DT)}</td>    
                    <td style={tableCellStyle}>{rowData.ad_CO_MT}</td>           
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <PriceAdjustmentDetailDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        PriceAdjustmentDetailTableData={PriceAdjustmentDetailTableData}
        setPriceAdjustmentDetailTableData={setPriceAdjustmentDetailTableData}
        ad_AD_NM={ad_AD_NM}
        setad_AD_NM={setad_AD_NM}
        ad_IT_GP={ad_IT_GP}
        setad_IT_GP={setad_IT_GP}
        ad_CU_GP={ad_CU_GP}
        setad_CU_GP={setad_CU_GP}
        ad_PR_UM={ad_PR_UM}
        setad_PR_UM={setad_PR_UM}
        ad_BA_IS={ad_BA_IS}
        setad_BA_IS={setad_BA_IS}
        ad_CO_MT={ad_CO_MT}
        setad_CO_MT={setad_CO_MT}
        ad_TR_CR={ad_TR_CR}
        setad_TR_CR={setad_TR_CR}
        ad_PR_MT={ad_PR_MT}
        setad_PR_MT={setad_PR_MT}
        SelectedITNM={SelectedITNM}
        setSelectedITNM={setSelectedITNM}
        SelectedCust={SelectedCust}
        setSelectedCust={setSelectedCust}
        itemcusChecked={itemcusChecked}
        setitemcusChecked={setitemcusChecked}
        itemcusgrpChecked={itemcusgrpChecked}
        setitemcusgrpChecked={setitemcusgrpChecked}
        itemGrpCusChecked={itemGrpCusChecked}
        setitemGrpCusChecked={setitemGrpCusChecked}
        itemGrpCusGrpChecked={itemGrpCusGrpChecked}
        setitemGrpCusGrpChecked={setitemGrpCusGrpChecked}
        itemChecked={itemChecked}
        setitemChecked={setitemChecked}
      />
    </div>
  );
};

export default PriceAdjustmentDetailMain;
