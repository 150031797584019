import React, { useState, useEffect ,useRef} from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import { InputMask } from "primereact/inputmask";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
export default function InviteVisitor({
  isOpen,
  handleClose,
}) {
  const intialvalues = {
    pv_VI_DT: moment(moment().toDate()).format("YYYYMMDD"),
    pv_VI_IT:"",
    pv_VI_OT:"",
    pv_VI_NM:"",
    pv_VI_FN:"",
    pv_CO_NO:"",
  };
  const [FormData, setFormData] = useState(intialvalues);
  const toast = useRef(null);
  const {borderbox,inboxstyle} = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const onChangeTime=(name,value,index)=>{
    value= moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value,index);
  };
  useEffect(() => {
    if (isOpen) {
      setFormData(intialvalues);
    }
  }, [isOpen]);

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
    }
  };

  const handleFormSubmit = () => {
    const request = {
      "inviteVisitor":{
        "pv_VI_DT" : FormData.pv_VI_DT,
        "pv_VI_IT" : FormData.pv_VI_IT,
        "pv_VI_OT" : FormData.pv_VI_OT,
        "pv_VI_NM" : document.getElementById("pv_VI_NM").value,
        "pv_VI_FN" : document.getElementById("pv_VI_FN").value,
        "pv_CO_NO" : company

      }
    }
    axios.post(`${domain}/api/InviteVisitor/Add`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        handleResponse(resp.data);
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  };

  const productDialogFooter = (
    <React.Fragment>
    <Button 
      style={{width:"8%"}}
      icon="pi pi-check" 
      onClick={handleFormSubmit} 
      tooltip={'Save'}>
    </Button>
  </React.Fragment>
  );
  // date default
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "pv_VI_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  return (
    <Dialog id="dialog-style"
      visible={isOpen}
      onHide={handleClose}
      style={{ width: "40rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={"Invite Visitor"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
          <div style={inboxstyle} >
          <div className="formgrid grid">  
                <div className="field col">
                  <label htmlFor="pv_VI_NM" className="font-bold" style={{fontSize:'70%'}}>
                    Name 
                  </label>
                  <InputText 
                    id="pv_VI_NM"
                    name="pv_VI_NM"
                    value={FormData.pv_VI_NM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
              <div className="formgrid grid">  
                <div className="field col">
                  <label htmlFor="pv_VI_FN" className="font-bold" style={{fontSize:'70%'}}>
                    Phone Number 
                  </label>
                  <InputText 
                    id="pv_VI_FN"
                    name="pv_VI_FN"
                    value={FormData.pv_VI_FN}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="pv_VI_DT" className="font-bold" style={{fontSize:'70%'}}>
                    Date 
                  </label>
                   <Calendar
                    id="pv_VI_DT"
                    name="pv_VI_DT"
                    value={moment(FormData.pv_VI_DT, "YYYYMMDD").toDate()}
                    onChange={handleDateChange}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                </div>
                <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="pv_VI_IT" className="font-bold" style={{fontSize:'70%'}}>
                    In Time
                  </label>
                  <InputMask
                    id="pv_VI_IT"
                    name="pv_VI_IT"
                    mask="99:99:99"
                    onBlur={(e) => { onChangeTime("pv_VI_IT",e.target.value,);}}
                    value={moment(FormData.pv_VI_IT, "HHmmss").format("HH:mm:ss")}
                  />
                </div>
                <div className="field col">
                  <label htmlFor="pv_VI_OT" className="font-bold" style={{fontSize:'70%'}}>
                    Out Time
                  </label>
                  <InputMask
                    id="pv_VI_OT"
                    name="pv_VI_OT"
                    mask="99:99:99"
                    onBlur={(e) => { onChangeTime("pv_VI_OT",e.target.value,);}}
                    value={moment(FormData.pv_VI_OT, "HHmmss").format("HH:mm:ss")}
                  />
                </div>
                </div>
               
            </div>
      </div>

    </Dialog>
  );
};
