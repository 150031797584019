import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
import { InputText } from "primereact/inputtext";
import ClassCode from "views/common/ClassCode";

const GeneratePopup = ({ visible, onClose,onchangedata,setFormData,ph_OR_TY,ph_OR_CO,ph_OR_NO,Gsh_OR_NO,Gsh_OR_TY,Gsh_OR_CO,setGsh_OR_CO,setGsh_OR_NO,setph_OR_NO,
  handleGeneratePOSO }) => {
  const [open, setOpen] = useState(false);



  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

    // buttonstyles
  const {PopupDailogStyle,borderbox,inboxstyle } = ButtonStyles();

  const handleClose = () => {
    onClose();
    setOpen(false);
  };
  useEffect(() => {
    if (visible) {
      setOpen(true);
      setGsh_OR_NO(null);
      setph_OR_NO(null);
    } else {
      setOpen(false);
    }
  }, [visible]);
// dropdowns
const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
const [sh_OR_COOptions, setsh_OR_COOptions] = useState([]);
const onchangeDropDownFormData1 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setGsh_OR_CO(value);
};
useEffect(() => {
  fetchDropdownCompany();
}, []);
const fetchDropdownCompany = async () => {
  const Options = await fetchClassCodeDS("CO/MT");
  setsh_OR_COOptions(Options);
};


// DIALOG HEADER
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{"Order Details"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button 
        style={{border:"none",backgroundColor:"transparent",color:"blue"}}
        icon="pi pi-file" 
        onClick={handleGeneratePOSO} 
        tooltip={"Generate"}>
      </Button>

    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={PopupDailogStyle}
      header={dialogHeader}
    >
        <div style={borderbox}>
          <div style={inboxstyle}>
          <h2 style={{fontSize:'1.5rem',fontWeight:'bold'}} > Sales Order </h2>
            <div className="formgrid grid">
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="Gsh_OR_NO" className="font-bold">
                   Number
                </label>
                <InputText disabled
                  id="Gsh_OR_NO"
                  name="Gsh_OR_NO"
                  value={Gsh_OR_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="Gsh_OR_TY" className="font-bold">
                  Type
                </label>
                <InputText disabled
                  id="Gsh_OR_TY"
                  name="Gsh_OR_TY"
                  value={Gsh_OR_TY}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="Gsh_OR_CO" className="font-bold">
                  Company
                </label>
                <Dropdown style={{width:'10rem'}}
                    id="Gsh_OR_CO"
                    name="Gsh_OR_CO"
                    value={Gsh_OR_CO}
                    options={sh_OR_COOptions}
                    onChange={(e) => onchangeDropDownFormData1("Gsh_OR_CO", e.value)}
                    placeholder="Select Company"
                  />
              </div>
            </div>
          </div>
          <div style={inboxstyle}>
          <h2 style={{fontSize:'1.5rem',fontWeight:'bold'}} > Purchase Order </h2>
            <div className="formgrid grid">
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ph_OR_NO" className="font-bold">
                  Number
                </label>
                <InputText disabled
                  id="ph_OR_NO"
                  name="ph_OR_NO"
                  value={ph_OR_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ph_OR_TY" className="font-bold">
                  Type
                </label>
                <InputText disabled
                  id="ph_OR_TY"
                  name="ph_OR_TY"
                  value={ph_OR_TY}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ph_OR_CO" className="font-bold">
                  Company
                </label>
                <InputText disabled
                  id="ph_OR_CO"
                  name="ph_OR_CO"
                  value={ph_OR_CO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
            </div>
          </div>
        </div>
    </Dialog>
  );
};

GeneratePopup.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDataSelect: PropTypes.func,
};

export default GeneratePopup;
