import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import Pagination from "views/common/Pagination";
import ButtonStyles from "views/common/ButtonStyles";

const initialFilters = {
    am_CO_NO: { value: "", operation: "" },
    account: { value: "", operation: "" },
    am_AC_ID: { value: "", operation: "" },
    am_AC_DS: { value: "", operation: "" },
    am_OB_AC: { value: "", operation: "" },
    am_OB_SB: { value: "", operation: "" },
    am_CO_NM: { value: "", operation: "" },
    am_AC_LD: { value: "", operation: "" },
    am_PE_CD: { value: "", operation: "" },
    am_CR_CD: { value: "", operation: "" },
};

const AccountNoPopup = ({ visible, onClose, onDataSelect, AelmId, bankFilter }) => {
    const { tableHeaderStyle, inputStyle, tableCellStyle } = ButtonStyles();
    const [tableData, setTableData] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [filters, setFilters] = useState(initialFilters);
    const [open, setOpen] = useState(false);
    const toast = useRef(null);
    const token = localStorage.getItem("token");
    const company = localStorage.getItem("company");
    const domain = localStorage.getItem("domain");

    const buildSearchRequestDto = () => {
        return Object.keys(filters)
            .filter((column) => filters[column].value)
            .map((column) => ({
                column: column.toUpperCase(),
                value: filters[column].value,
                operation: filters[column].operation,
            }));
    };

    const handleFilterChange = (column, value) => {
        let updatedOperation = "EQUAL";
        let updatedValue = value;

        if (value.startsWith(">")) {
            updatedOperation = "GREATER_THAN";
            updatedValue = value.substring(1);
        } else if (value.startsWith("<")) {
            updatedOperation = "LESS_THAN";
            updatedValue = value.substring(1);
        } else if (value.includes(",")) {
            updatedOperation = "BETWEEN";
        } else if (isNaN(value)) {
            updatedOperation = "LIKE";
        } else {
            updatedOperation = "EQUAL";
        }

        const updatedFilters = { ...filters };
        updatedFilters[column] = {
            value: updatedValue,
            operation: updatedOperation,
        };
        setFilters(updatedFilters);
    };

    const handleClose = () => {
        onClose();
        setOpen(false);
    };

    useEffect(() => {
        setOpen(visible);
        if (visible) {
            handleSearch();
            setCurrentPage(0);
        }
    }, [visible, currentPage, pageSize, filters]);

    const handleSearch = async () => {
        const searchRequestDto = buildSearchRequestDto();
        const requestBody = {
            searchRequestDto,
        };
        const Url = `${domain}/api/AccountMaster/Lists/${currentPage}/${pageSize}`;
        var paginationUrl = `${Url}`;
		if(bankFilter != null && bankFilter != ""){	
			const parameter = bankFilter;
			paginationUrl = `${domain}/api/AccountMaster/All/${currentPage}/${pageSize}/${parameter}`;
		}

        try {
            const response = await axios.post(paginationUrl, requestBody, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
            });

            if (response.status !== 200) {
                throw new Error(`API request failed with status: ${response.status}`);
            }

            const data = response.data;

            if (data && data.content && Array.isArray(data.content)) {
                setTableData(data.content);
                setTotalPages(data.totalPages);
            } else {
                console.error("Invalid API response:", data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const tableCellStyleAcc = {
        border: "1px solid #ddd",
        padding: "8px",
        textAlign: "left",
        width: "30%",
    };
    const tableCellStyleAcID = {
        border: "1px solid #eef2ff",
        padding: "8px",
        textAlign: "left",
        backgroundColor: "#e1e1e1",
        width: "15%",
    };

    return (
        <Dialog id="dialog-style"
            visible={open}
            onHide={handleClose}
            style={{ width: "75rem" }}
            header="Account Details"
        >
            <Toast ref={toast} />
            <div className="card">
                <div style={{ overflowX: "auto", maxWidth: "100%" }}>
                    <table
                        style={{
                            borderCollapse: "collapse",
                            width: "100%",
                            marginTop: "20px",
                        }}
                    >
                        <thead>
                            <tr>
                                {Object.keys(initialFilters).map((key) => (
                                    <td key={key}>
                                        <input
                                            type="text"
                                            value={filters[key] ? filters[key].value : ""}
                                            onChange={(e) =>
                                                handleFilterChange(
                                                    key,
                                                    e.target.value
                                                )
                                            }
                                            style={inputStyle}
                                        />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th style={tableHeaderStyle}>Company</th>
                                <th style={tableHeaderStyle}>Account</th>
                                <th style={tableCellStyleAcID}>Account ID</th>
                                <th style={tableHeaderStyle}>Account Description</th>
                                <th style={tableHeaderStyle}>Account Object</th>
                                <th style={tableHeaderStyle}>Account Subsidiary</th>
                                <th style={tableHeaderStyle}>Cost Center</th>
                                <th style={tableHeaderStyle}>Level of Detail</th>
                                <th style={tableHeaderStyle}>Posting Code</th>
                                <th style={tableHeaderStyle}>Currency Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData &&
                                tableData.map((rowData, index) => (
                                    <tr
                                        key={rowData.uiUkNo}
                                        onClick={() => onDataSelect(rowData, AelmId)}
                                        style={{
                                            backgroundColor:
                                                index % 2 === 0 ? "aliceblue" : "#ffffff",
                                        }}
                                        className="table-row"
                                    >
                                        <td style={tableCellStyle}>{rowData.am_CO_NO}</td>
                                        <td style={tableCellStyleAcc}>{rowData.account}</td>
                                        <td style={tableCellStyle}>{rowData.am_AC_ID}</td>
                                        <td style={tableCellStyleAcc}>{rowData.am_AC_DS}</td>
                                        <td style={tableCellStyle}>{rowData.am_OB_AC}</td>
                                        <td style={tableCellStyle}>{rowData.am_OB_SB}</td>
                                        <td style={tableCellStyle}>{rowData.am_CO_NM}</td>
                                        <td style={tableCellStyle}>{rowData.am_AC_LD}</td>
                                        <td style={tableCellStyle}>{rowData.am_PE_CD}</td>
                                        <td style={tableCellStyle}>{rowData.am_CR_CD}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </Dialog>
    );
};

AccountNoPopup.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onDataSelect: PropTypes.func,
    AelmId: PropTypes.string,
};

export default AccountNoPopup;
