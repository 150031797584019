import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import PurchaseRequisitionDialog from "./PurchaseRequisitionDialog";
import { InputText } from "primereact/inputtext";
import ItemPopup from "../Popups/ItemPopup";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  pdUkNo:"",
  phUkNO:"",
  pd_OR_NO: "",
  pd_OR_TY: "",
  pd_OR_CO: "",
  pd_LN_NO:"",
  pd_IT_NM:"",
  im_IT_DS:"",
  pd_OR_QT:"",
  pd_TR_UM:"",
  pd_OR_DT: "",
  pd_RQ_DT: "",
  pd_ST_CD:"",
  ph_OR_DT:moment(moment().toDate()).format("YYYYMMDD"),
  ph_RQ_DT:moment(moment().toDate()).format("YYYYMMDD"),
  ph_OR_NO:"",
  ph_OR_TY:"",
  ph_RL_NO:"", 
  ph_RL_TY:"", 
  ph_RL_CO:"", 
};

const initialFilters = {
  pd_OR_NO: { value: "", operation: "" },
  pd_OR_TY: { value: "", operation: "" },
  pd_OR_CO: { value: "", operation: "" },
  pd_LN_NO: { value: "", operation: "" },
  pd_IT_NM: { value: "", operation: "" },
  im_IT_DS: { value: "", operation: "" },
  pd_OR_QT: { value: "", operation: "" },
  pd_TR_UM: { value: "", operation: "" },
  pd_OR_DT: { value: "", operation: "" },
  pd_RQ_DT: { value: "", operation: "" },
  pd_CO_CT: { value: "", operation: "" },
  pd_ST_CD: { value: "", operation: "" },
 
};

const PurchaseRequisitionMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,inputStyle1,cancelButtonStyles
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");


  const [cancelFlag, setcancelFlag] = useState(false);

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedemEMNO1, setSelectedemEMNO1] = useState("");
  const [selectedAbAdNM1, setSelectedAbAdNM1] = useState("");
  const [ph_OR_NO, setph_OR_NO] = useState("");
  const [ph_DE_PT, setph_DE_PT] = useState("");
  const [pd_TR_UM, setpd_TR_UM] = useState("");
  const [pd_SC_UM, setpd_SC_UM] = useState("");
  const [ph_CO_CT, setph_CO_CT] = useState("");
  const [ph_HL_CD, setph_HL_CD] = useState("");
  
  const [PurchaseRequisitiontable, setPurchaseRequisitiontable] = useState([{}]);
  const [selecteditem, setSelecteditem] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [item, setitem] = useState("");

  const OnchangeTableData = (key, value, index) => {
    const updatedData = [...PurchaseRequisitiontable];
    updatedData[index][key] = value;
    setPurchaseRequisitiontable(updatedData);
  };

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;
  
        if (column === 'ph_OR_NO') {
          constructedColumn = 'ph_OR_NO';
        } else {
          constructedColumn = column.toUpperCase();
        }
  
        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
    };
  const [ItemPopupVisible, setItemPopupVisible] = useState(false);
  const openitemPopup = (e) => {
    setItemPopupVisible(true);
   
  };
  const handleDataSelect = (data) => {
    setSelecteditem(data.im_IT_DS);
    setitem(data.im_IT_NM);
    setItemPopupVisible(false);
    updateFilters (data.im_IT_NM);
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setPurchaseRequisitiontable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedAbAdNM1(null);
    setph_OR_NO(null);
    setph_DE_PT(null);
    setpd_TR_UM(null);
    setpd_SC_UM(null);
    setcancelFlag(false);
    setph_RL_TY(null);
    setph_RL_CO(null);
    setOpen(true);
  };
  const handleClearButton=()=>{
  setitem("");
  setSelecteditem("");
  setFilters("");
  }

  const handleClose = () => {
    setPurchaseRequisitiontable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedAbAdNM1(null);
    setph_OR_NO(null);
    setph_DE_PT(null);
    setpd_TR_UM(null);
    setpd_SC_UM(null);
    setph_RL_TY(null);
    setph_RL_CO(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      // handleSearch();
      handleDefault();
    }
  };
  const addRow = () => {
    setPurchaseRequisitiontable([...PurchaseRequisitiontable, {}]);
  };

  const deleteRow = () => {
    if (PurchaseRequisitiontable.length > 0) {
      const updatedData = [...PurchaseRequisitiontable];
      updatedData.pop();
      setPurchaseRequisitiontable(updatedData);
    }
  };
  const handleCopyData = async () => {
    setcancelFlag(false)
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/PurchaseRequisition/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const PurchaseRequisitionData = response.data;
          delete PurchaseRequisitionData.purchaseQuotes.ph_OR_NO;
          delete PurchaseRequisitionData.purchaseQuotes.phUkNO;
          for (let i = 0; i < PurchaseRequisitionData.purchaseOrders.length; i++) {
            delete PurchaseRequisitionData.purchaseOrders[i].pdUkNo;
          }
          setPurchaseRequisitiontable(PurchaseRequisitionData.purchaseOrders);
          setFormData(PurchaseRequisitionData.purchaseQuotes);
          setSelectedemEMNO(PurchaseRequisitionData.purchaseQuotes.emp_NAME); //ad_EM_NM
          setph_DE_PT(response.data.purchaseQuotes.ph_DE_PT);
          setpd_TR_UM(response.data.purchaseQuotes.pd_TR_UM);
          setpd_SC_UM(response.data.purchaseQuotes.pd_SC_UM);
          setpd_IT_NM(response.data.purchaseOrders[0].pd_IT_NM);
          setpd_TR_UM(response.data.purchaseOrders[0].pd_TR_UM);
          setpd_SC_UM(response.data.purchaseOrders[0].pd_SC_UM);
          setSelectedemEMNO1(response.data.purchaseOrders[0].im_IT_DS);
          setpd_ST_CD(response.data.purchaseOrders[0].pd_ST_CD);
          setph_RL_TY(response.data.purchaseQuotes.ph_RL_TY);
          setph_RL_CO(response.data.purchaseQuotes.ph_RL_CO);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      pd_IT_NM: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }

  setFilters(updatedFilters);


  };
const [ph_OR_CO , setph_OR_CO ] = useState("");
const [ph_OR_TY, setph_OR_TY] = useState("");
const [ph_RL_CO, setph_RL_CO] = useState("");

const handleDefault = async () => {
  setph_OR_CO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
            if(fName === "PH_OR_TY"){
              setph_OR_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "PH_CO_CT"){
              onchangeDropDownFormData2("ph_CO_CT",dataDefault.versionIds[i].default_VALUE);
              setph_CO_CT(dataDefault.versionIds[i].default_VALUE);
            } 
            if(fName === "PD_ST_CD"){
              setpd_ST_CD(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "PD_CO_CT"){
              setpd_CO_CT(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "PH_HL_CD"){
              onchangeDropDownFormData3("ph_HL_CD",dataDefault.versionIds[i].default_VALUE);
              setph_HL_CD(dataDefault.versionIds[i].default_VALUE);
            } 
           
           
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
   // handleSearch();
   handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async ( ) => {
    const searchRequestDto = buildSearchRequestDto();
    

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PurchaseRequisition/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
 const [pd_IT_NM, setpd_IT_NM] = useState("");
 const [pd_ST_CD, setpd_ST_CD] = useState("");
 const [pd_CO_CT, setpd_CO_CT] = useState("");
 const [ph_RL_TY, setph_RL_TY] = useState("");
  const onchangeFormData = (name, value, index) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // const fieldName=(FormData && FormData.pdUkNo)?`${name}`:`${name}_${index}`;
    // if(index!=null)
    //   PurchaseRequisitiontable[index][fieldName]=value;
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setph_DE_PT(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setph_CO_CT(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setph_HL_CD(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setpd_TR_UM(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setpd_SC_UM(value);
  };
 
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/PurchaseRequisition/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const PurchaseRequisitionData = response.data;
        setPurchaseRequisitiontable(PurchaseRequisitionData.purchaseOrders);
        setFormData(PurchaseRequisitionData.purchaseQuotes);
        setSelectedemEMNO(PurchaseRequisitionData.purchaseQuotes.emp_NAME); //ad_EM_NM
        setph_DE_PT(response.data.purchaseQuotes.ph_DE_PT);
        setpd_TR_UM(response.data.purchaseQuotes.pd_TR_UM);
        setpd_SC_UM(response.data.purchaseQuotes.pd_SC_UM);
        setph_RL_TY(response.data.purchaseQuotes.ph_RL_TY);
        setph_RL_CO(response.data.purchaseQuotes.ph_RL_CO);
        setpd_IT_NM(response.data.purchaseOrders[0].pd_IT_NM);
        setpd_TR_UM(response.data.purchaseOrders[0].pd_TR_UM);
        setpd_SC_UM(response.data.purchaseOrders[0].pd_SC_UM);
        setSelectedemEMNO1(response.data.purchaseOrders[0].im_IT_DS);
        setpd_ST_CD(response.data.purchaseOrders[0].pd_ST_CD);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCancel = async () => {
    setcancelFlag(true)
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/PurchaseRequisition/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const PurchaseRequisitionData = response.data;
        setPurchaseRequisitiontable(PurchaseRequisitionData.purchaseOrders);
        setFormData(PurchaseRequisitionData.purchaseQuotes);
        setSelectedemEMNO(PurchaseRequisitionData.purchaseQuotes.emp_NAME); 
        setpd_ST_CD(response.data.purchaseOrders[0].pd_ST_CD);
        setph_DE_PT(response.data.ph_DE_PT);
        setpd_TR_UM(response.data.pd_TR_UM);
        setpd_SC_UM(response.data.pd_SC_UM);
        setph_RL_TY(response.data.purchaseQuotes.ph_RL_TY);
        setph_RL_CO(response.data.purchaseQuotes.ph_RL_CO);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  var requestBodyInput = {};
  function values () {
    const purchaseQuotes = {
      phUkNO  : FormData.phUkNO,
      ph_OR_NO: FormData.ph_OR_NO,
      ph_OR_TY: ph_OR_TY,
      ph_OR_CO: ph_OR_CO,
      ph_DE_PT: FormData.ph_DE_PT,
      ph_CO_CT: ph_CO_CT,
      ph_PE_RQ: FormData.ph_PE_RQ,
      ph_HL_CD: ph_HL_CD,
      ph_OR_DT: FormData.ph_OR_DT,
      ph_RQ_DT: FormData.ph_RQ_DT,
      ph_RL_NO: FormData.ph_RL_NO,
      ph_RL_TY: FormData.ph_RL_TY,
      ph_RL_CO: FormData.ph_RL_CO,
    };
    const table = document.getElementById("PurchaseRequisitiontable");
    const totalRowCount = table.tBodies[0].rows.length;
    const purchaseOrders = [];
    for (let i = 0; i < totalRowCount; i++) {  
      const CN_DT = document.getElementsByName("pd_CN_DT_" + i)[0];
      const Details = {
        pdUkNo:  document.getElementById("pdUkNo_" + i).value,
        pd_LN_NO: document.getElementById("pd_LN_NO_" + i).value,
        pd_IT_NM: document.getElementById("pd_IT_NM_" + i).value,
        im_IT_DS: document.getElementById("im_IT_DS_" + i).value,
        pd_OR_QT: document.getElementById("pd_OR_QT_" + i).value,
        pd_TR_UM: table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
        pd_SC_QT: document.getElementById("pd_SC_QT_" + i).value,
        pd_SC_UM: table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
        pd_CN_QT: document.getElementById("pd_CN_QT_" + i).value,
        pd_CN_DT: CN_DT.value?moment(CN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        pd_RE_MK: document.getElementById("pd_RE_MK_" + i).value,
        pd_ST_CD: document.getElementById("pd_ST_CD_" + i).value,
        pd_CO_CT: document.getElementById("pd_CO_CT_" + i).value
      };
      purchaseOrders.push(Details);
    }

   
    requestBodyInput = {
      purchaseQuotes,
      purchaseOrders,
    };
  }

  const handleFormSubmit = () => {
    const i=0;
    values();
    const orNo = FormData.ph_OR_NO;
    const ukNo = FormData.phUkNO;
    if (orNo) {
      if (ukNo) {
        if(cancelFlag){
          const  Url = `${domain}/api/PurchaseRequisition/CancelPurchaseOrders`
            const request = {
              "purchaseOrders":[
                {
                  "pdUkNo" : document.getElementById(`pdUkNo_${i}`).value,
                  "pd_CN_QT" : document.getElementById(`pd_CN_QT_${i}`).value,
                }
              ]
            }
            axios.post(Url, request, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error adding data:", error);
            });
          } else {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/PurchaseRequisition/Update`;
    
        if (confirm) {
          axios.put(update, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
          }
        }
      } else {
        axios.post(`${domain}/api/PurchaseRequisition/Add`, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    } else {
      const IN_DT = FormData.ph_OR_DT;
      if(IN_DT){
        const currentYear = IN_DT.toString();
        const firstHalf = currentYear.substring(0, 2);
        const secondHalf = currentYear.substring(2, 4);
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
        const parameters = `${ph_OR_TY}/${secondHalf}/${company}/${firstHalf}`;  
        const finalUrl1 = `${urlNextNumber}/${parameters}`;

        axios.get(finalUrl1, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          setph_OR_NO(resp.dn_DO_NO);
          FormData.ph_OR_NO = resp.data.dn_DO_NO;
            if(resp.data.dn_DO_NO ===undefined)
            {
              handleResponse(resp.data);
            }else{
              values();
              axios.post(`${domain}/api/PurchaseRequisition/Add`, requestBodyInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error adding data:", error);
              });
            }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      }
    }
    };  

    const GenerateQuote = () => {    
      values();
      const orNo = FormData.ph_OR_NO;
      const ukNo = FormData.phUkNO;
      if (orNo) {
        if (ukNo) {
          const confirm = window.confirm("Do you want to Generate Quote ?");
          const update = `${domain}/api/PurchaseRequisition/GeneratePurchaseQuote`;
          if (confirm) {
            axios
              .post(update, requestBodyInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error updating data:", error);
              });
          }
        } 
      }
  } ;

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
      <Tooltip target=".refund-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Cancel
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-times"  
        outlined
        className="mr-2 cancel-button"
        onClick={handleCancel}
        disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };


    const handleImport = (Event) => {
    const file = Event.target.files[0];
    const url = `${domain}`;
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (fileExtension === "csv" || fileExtension === "xlsx") {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const importedData = JSON.parse(e.target.result);
            setTableData(importedData);
            fetch(url, {
              method: "POST",
              body: JSON.stringify(importedData),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
              .then((resp) => resp.json())
              .then((resp) => {
                handleSearch();
              });

            toast.current.show({
              severity: "success",
              summary: "Import Successful",
            });
          } catch (error) {
            console.error("Error parsing imported data:", error);
            toast.current.show({
              severity: "error",
              summary: "Import Error",
              detail:
                "Invalid data format. Please ensure the imported file is in the correct format.",
            });
          }
        };

        reader.readAsText(file);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Unsupported File Type",
          detail: "Please select a CSV or XLSX file for import.",
        });
      }
    }
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        type="button"
        icon="pi pi-download"
        className="p-button-rounded p-button-success"
        onClick={() => {
          const input = document.getElementById("fileInput");
          input.click();
        }}
        data-pr-tooltip="Import"
      />
      <input
        id="fileInput"
        type="file"
        accept=".csv, .xlsx"
        onClick={exportExcel}
        style={{ display: "none" }}
      />
    </React.Fragment>
  );
  const AdvanceSearch = (
    <div className="formgrid grid">
      <div className="field col" >
    <label htmlFor="item" className="font-bold">
    Item <span style={{ color: 'red' }}>*</span>
    </label>
    <div style={{ display: "flex" }}>
      <InputText
      style={{display:"none"}}
        id="item"
        name="item"
        value={item}
      />
      <InputText
        id="im_IT_DS"
        name="im_IT_DS"
        readOnly
        style={{backgroundColor:"#ffffff"}}
        onChange={(Event) => onchangedata(Event)}
        onFocus={() => setShowSearchIcon(true)}
        value={selecteditem}
      />
       {showSearchIcon && (
      <i
        className="pi pi-search"
        style={{
          color: "var(--primary-color)",
          fontSize: "119%",
          marginTop: "4%",
          cursor: "pointer",
        }}
        onClick={openitemPopup}
      ></i>
       )}
    </div>
    </div>

          
</div>
);
const rightClear = (
  <React.Fragment>
  <Tooltip  target=".clear-button" position="bottom" mouseTrack>
  <div>Clear Filter</div>
  </Tooltip>
  
  <Button
  style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
  // style={{ height: "35px" }}
  icon="pi pi-times"
  className="mr-2 clear-button"
  link
  onClick={handleClearButton}
  />
  </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_NO"] ? filters["pd_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_NO",
                        e.target.value,
                        filters["pd_OR_NO"]
                          ? filters["pd_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_TY"] ? filters["pd_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_TY",
                        e.target.value,
                        filters["pd_OR_TY"]
                          ? filters["pd_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_CO"] ? filters["pd_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_CO",
                        e.target.value,
                        filters["pd_OR_CO"]
                          ? filters["pd_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_LN_NO"] ? filters["pd_LN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_LN_NO",
                        e.target.value,
                        filters["pd_LN_NO"]
                          ? filters["pd_LN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["pd_IT_NM"] ? filters["pd_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_IT_NM",
                        e.target.value,
                        filters["pd_IT_NM"]
                          ? filters["pd_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_IT_DS"] ? filters["im_IT_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_IT_DS",
                        e.target.value,
                        filters["im_IT_DS"]
                          ? filters["im_IT_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_QT"] ? filters["pd_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_QT",
                        e.target.value,
                        filters["pd_OR_QT"]
                          ? filters["pd_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_TR_UM"] ? filters["pd_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_TR_UM",
                        e.target.value,
                        filters["pd_TR_UM"]
                          ? filters["pd_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_DT"] ? filters["pd_OR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_DT",
                        e.target.value,
                        filters["pd_OR_DT"]
                          ? filters["pd_OR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_RQ_DT"] ? filters["pd_RQ_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_RQ_DT",
                        e.target.value,
                        filters["pd_RQ_DT"]
                          ? filters["pd_RQ_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
                <td>
                  <input
                    type="text"
                    value={filters["pd_ST_CD"] ? filters["pd_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_ST_CD",
                        e.target.value,
                        filters["pd_ST_CD"]
                          ? filters["pd_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Line No</th>
                <th style={tableHeaderStyle}>Item Number</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Quantity</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Order Date</th>
                <th style={tableHeaderStyle}>Req. Date</th>
                <th style={tableHeaderStyle}>Status</th>
               
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.pdUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.pdUkNo}
                        checked={selectedRows.includes(rowData.pdUkNo)}
                        onChange={() => handleRowSelect(rowData.pdUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.pd_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.pd_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.pd_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.pd_LN_NO}</td>
                    <td style={tableCellStyle}>{rowData.pd_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.im_IT_DS}</td>
                    <td style={tableCellStyle}>{rowData.pd_OR_QT}</td>
                    <td style={tableCellStyle}>{rowData.pd_TR_UM}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.pd_OR_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.pd_RQ_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.pd_ST_CD}</td>
                    
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
   {/* pagination */}
   <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

      </div>
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelect}
       
      />
      <PurchaseRequisitionDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        ph_OR_TY={ph_OR_TY}
        ph_OR_CO={ph_OR_CO}
        ph_DE_PT={ph_DE_PT}
        pd_TR_UM={pd_TR_UM}
        pd_SC_UM={pd_SC_UM}
        ph_CO_CT={ph_CO_CT}
        ph_HL_CD={ph_HL_CD}
        pd_IT_NM={pd_IT_NM}
        pd_ST_CD={pd_ST_CD}
        SelectedemEMNO={SelectedemEMNO}
        SelectedemEMNO1={SelectedemEMNO1}
        setSelectedemEMNO1={setSelectedemEMNO1}
        setSelectedemEMNO={setSelectedemEMNO}
        selectedAbAdNM1={selectedAbAdNM1}
        setSelectedAbAdNM1={setSelectedAbAdNM1}
        PurchaseRequisitiontable={PurchaseRequisitiontable} 
        setPurchaseRequisitiontable={setPurchaseRequisitiontable}
        addRow={addRow} 
        deleteRow={deleteRow} 
        setpd_IT_NM={setpd_IT_NM}
        pd_CO_CT={pd_CO_CT}
        cancelFlag={cancelFlag}
        GenerateQuote={GenerateQuote}
        OnchangeTableData={OnchangeTableData}
        ph_RL_TY={ph_RL_TY}
        setph_RL_TY={setph_RL_TY}
        setFormData={setFormData}
        ph_RL_CO={ph_RL_CO}
        setph_RL_CO={setph_RL_CO}
  
      />
    </div>
    </div>
  );
};

export default PurchaseRequisitionMain;
