import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
import Costcenter  from "views/common/CostCentreDrpDwn";
export default function PickingRequestDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  PickingRequestTableData,
  setPickingRequestTableData,
  pr_LO_CN,
  setpr_LO_CN,
  pr_RQ_QT,
  setpr_RQ_QT,
  setpr_OR_TY,
  pr_CO_CT,
  setpr_CO_CT,
  setpr_TR_UM,
  setpr_AC_LO,
  setpr_LT_NO,
  setpr_TO_LO,
  pr_OP_QT,
  setpr_OP_QT,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
}) {
  const {
    prUkNo,
    pr_PK_NO,
  } = data;


  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [pr_CO_CTOptions, setpr_CO_CTOptions] = useState([]);
  const [pr_LO_CNOptions, setpr_LO_CNOptions] = useState([]);
  const [pr_RQ_QTOptions, setpr_RQ_QTOptions] = useState([]);
  const [pr_OP_QTOptions, setpr_OP_QTOptions] = useState([]);
  const [pr_OR_TYOptions, setpr_OR_TYOptions] = useState([]);
  const [pr_TO_LOOptions, setpr_TO_LOOptions] = useState([]);
  const [pr_AC_LOOptions, setpr_AC_LOOptions] = useState([]);
  const [pr_LT_NOOptions, setpr_LT_NOOptions] = useState([]);
  const [pr_TR_UMOptions, setpr_TR_UMOptions] = useState([]);

  // Expense Type
  const CostCentreDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_CO_CT(value);
};

  // Advance Curr
  const LocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_LO_CN(value);
  };

  const ToLocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_TO_LO(value);
  };

 // Reim Curr
  const ReqQuantityDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setpr_RQ_QT(value);
  };

   // Status
   const OpenQuantityDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_OP_QT(value);
    };

 // Expense Category
const OrdertypeDropdown = (name, value) => {
  const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_OR_TY(value);
};



  const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_TR_UM(value);
};

  const SuggestedLocDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_AC_LO(value);
};
const LotDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setpr_LT_NO(value);
};

  useEffect(() => {
    ReimCurrency();
    Status();
    ExpenseCategory();
    CostCenter();
    UM();
    Location();
    ToLocation();
    SuggestedLoc();
    Lot();

  }, []);



  const ReimCurrency = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setpr_RQ_QTOptions(Options);
  };


  const Status = async () => {
    const Options = await fetchClassCodeDS("EX/ST");
    setpr_OP_QTOptions(Options);
  };
  const ExpenseCategory = async () => {
    const Options = await fetchClassCodeCD("FO/DT");
    setpr_OR_TYOptions(Options);
  };

  const Location = async () => {
    const Options = await fetchClassCodeCD("EX/LN");
    setpr_LO_CNOptions(Options);
  };

  
  const ToLocation = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setpr_TO_LOOptions(Options);
  };

  const CostCenter = async () => {
    const Options = await fetchCostCenter();
    setpr_CO_CTOptions(Options);
  };



  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setpr_TR_UMOptions(Options);
  };
  const Lot = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setpr_LT_NOOptions(Options);
  };
  const SuggestedLoc = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setpr_AC_LOOptions(Options);
  };

  // Advance Search
  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    onchangeFormData("pr_IT_NM", data.im_IT_NM);
    onchangeFormData("itemDES", data.im_IT_DS);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };


  const addRow = () => {
    setPickingRequestTableData([...PickingRequestTableData, {}]);
  };
  
  const deleteRow = () => {
    if (PickingRequestTableData.length > 0) {
      const updatedData = [...PickingRequestTableData];
      updatedData.pop();
      setPickingRequestTableData(updatedData);
    }
  };  
const isUpdateMode = Boolean(prUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

 
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "85rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={prUkNo ? "Revise Picking Request" : "Add Picking Request"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
              <div className="formgrid grid">
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="pr_PK_NO" className="font-bold">
                Pick Number
                </label>
                <InputText disabled
                  id="pr_PK_NO"
                  name="pr_PK_NO"
                  defaultValue={pr_PK_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
        
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_IT_NM" className="font-bold">
              Item No <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText  disabled
                  id="pr_IT_NM"
                  name="pr_IT_NM"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedItem}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openItemPopup}
                ></i>
                )}
              </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="itemDES" className="font-bold">
              Description
              </label>
              <InputText disabled
                id="itemDES"
                name="itemDES"
                value={SelectedDesc}
                readOnly
              />
            </div>

    <          div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_CO_CT" className="font-bold">
                Cost Centre
              </label>
              <Dropdown
              name="pr_CO_CT"
              value={pr_CO_CT}
              options={pr_CO_CTOptions}
              onChange={(e) => CostCentreDropdown("pr_CO_CT", e.value)}
              placeholder="Select Cost Centre"
            />
            </div>


            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_LO_CN" className="font-bold">
                Location
              </label>
              <Dropdown
              name="pr_LO_CN"
              value={pr_LO_CN}
              options={pr_LO_CNOptions}
              onChange={(e) => LocationDropdown("pr_LO_CN", e.value)}
              placeholder="Select Location"
            />
            </div>

            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_RQ_QT" className="font-bold">
                Req Quantity
              </label>
              <Dropdown
              name="pr_RQ_QT"
              value={pr_RQ_QT}
              options={pr_RQ_QTOptions}
              onChange={(e) => ReqQuantityDropdown("pr_RQ_QT", e.value)}
              placeholder="Select Req Quantity"
            />
            </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="pr_OP_QT" className="font-bold">
            Open Quantity
            </label>
            <Dropdown
            name="pr_OP_QT"
            value={pr_OP_QT}
            options={pr_OP_QTOptions}
            onChange={(e) => OpenQuantityDropdown("pr_OP_QT", e.value)}
            placeholder="Select Open Quantity"
          />
          </div>
          </div>
          </div>  
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
        >
      
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="operationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Order No</th>
              <th>Order Type</th>
              <th>Company</th>
              <th>Line No</th>
              <th>Quantity</th>
              <th>UM</th>
              <th>To Location</th>
              <th>Lot Number</th>
              <th>Suggested Location</th>
              <th>Lot Number</th>
              <th>Stock</th>
              <th>Suggested Qty</th>
            </tr>
          </thead>
          <tbody>
            {PickingRequestTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`prUkNo_${index}`}
                    name={`prUkNo_${index}`}
                    value={row.prUkNo}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`pr_OR_NO_${index}`}
                    name={`pr_OR_NO_${index}`}
                    defaultValue={row.pr_OR_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Dropdown disabled
                    id={`pr_OR_TY_${index}`}
                    name={`pr_OR_TY_${index}`}
                    value={ data[`pr_OR_TY_${index}`] ? data[`pr_OR_TY_${index}`]: row.pr_OR_TY}
                    options={pr_OR_TYOptions}
                    onChange={(e) =>OrdertypeDropdown(`pr_OR_TY_${index}`, e.value)}
                    placeholder="Select Order Type"
                  />
                  </td>
                <td >
                  <InputText disabled style={{width:"120px"}}
                    id={`pr_OR_CO_${index}`}
                    name={`pr_OR_CO_${index}`}
                    defaultValue={row.pr_OR_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"120px"}}
                    id={`pr_LN_NO_${index}`}
                    name={`pr_LN_NO_${index}`}
                    defaultValue={row.pr_LN_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"120px"}}
                    id={`pr_OR_QT_${index}`}
                    name={`pr_OR_QT_${index}`}
                    defaultValue={row.pr_OR_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                
                <td>
                  <Dropdown 
                    id={`pr_TR_UM_${index}`}
                    name={`pr_TR_UM_${index}`}
                    value={ data[`pr_TR_UM_${index}`] ? data[`pr_TR_UM_${index}`]: row.pr_TR_UM}
                    options={pr_TR_UMOptions}
                    onChange={(e) =>UMDropdown(`pr_TR_UM_${index}`, e.value)}
                    placeholder="Select UM"
                  />
                  </td>
                 
                
                <td>
                  <Dropdown 
                    id={`pr_TO_LO_${index}`}
                    name={`pr_TO_LO_${index}`}
                    value={ data[`pr_TO_LO_${index}`] ? data[`pr_TO_LO_${index}`]: row.pr_TO_LO}
                    options={pr_TO_LOOptions}
                    onChange={(e) =>ToLocationDropdown(`pr_TO_LO_${index}`, e.value)}
                    placeholder="Select Location"
                  />
                  </td>
                  <td >
                  <InputText style={{width:"120px"}}
                    id={`pr_LT_NO_${index}`}
                    name={`pr_LT_NO_${index}`}
                    defaultValue={row.pr_LT_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Dropdown style={{width:"150px"}}
                    id={`pr_AC_LO_${index}`}
                    name={`pr_AC_LO_${index}`}
                    value={ data[`pr_AC_LO_${index}`] ? data[`pr_AC_LO_${index}`]: row.pr_AC_LO}
                    options={pr_AC_LOOptions}
                    onChange={(e) =>SuggestedLocDropdown(`pr_AC_LO_${index}`, e.value)}
                    placeholder="Select Suggested Location"
                  />
                  </td>
                  <td>
                  <Dropdown style={{width:"120px"}}
                    id={`pr_LT_NO_${index}`}
                    name={`pr_LT_NO_${index}`}
                    value={ data[`pr_LT_NO_${index}`] ? data[`pr_LT_NO_${index}`]: row.pr_LT_NO}
                    options={pr_LT_NOOptions}
                    onChange={(e) =>LotDropdown(`pr_LT_NO_${index}`, e.value)}
                    placeholder="Select Lot Number"
                  />
                  </td>
                <td >
                  <InputText style={{width:"120px"}}
                    id={`stock_${index}`}
                    name={`stock_${index}`}
                    defaultValue={row.stock}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"120px"}}
                    id={`pr_SU_QT_${index}`}
                    name={`pr_SU_QT_${index}`}
                    defaultValue={row.pr_SU_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
