import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { } from '@fortawesome/free-solid-svg-icons';
import { faLink, faPaperclip, faFileImage, faBold, faItalic, faUnderline } from '@fortawesome/free-solid-svg-icons';

const NotePopup = ({ visible, onClose, av_CC_NO }) => {
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [NoteData, setNoteData] = useState([]);
  const {
    ccCnNo,
    // av_CC_NO,
    av_TE_XT,
    cc_UK_NO,
  } = NoteData;

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
    if (errorMessage.length === 0) {
      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setNoteData({ ...NoteData, [name]: value });
  };

  const StartNote = async () => {
    const addInput = {
      av_CC_NO: av_CC_NO,
      av_TE_XT: NoteData.av_TE_XT,
    };
    try {
      const response = await axios.post(
        `${domain}/api/Activity/Notes/Add`, addInput,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      const data = response.data;
      if (data) {
        setNoteData(data);
        handleResponse(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setNoteData(prevNoteData => ({ ...prevNoteData,  av_TE_XT: ''   }));
    const modal = document.getElementById("noteModal");
    if (visible) {
      modal.classList.add("show");
      modal.style.display = "block";
    } else {
      modal.classList.remove("show");
      modal.style.display = "none";
    }
  }, [visible]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const handleFileSelection = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };

  const isUpdateMode = Boolean(cc_UK_NO);
  const productDialogFooter = (
      <React.Fragment>
      <Button 
        style={{width:"20%"}}
        icon="pi pi-times" 
        variant="outlined" 
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button   
        style={{width:"20%"}}
        icon="pi pi-check" 
        onClick={StartNote} 
        tooltip={isUpdateMode ? "Update" : "Save"}>
      </Button>
      </React.Fragment>
    );
  return (
    <div
      className="modal fade"
      id="noteModal"
      tabIndex="-1"
      aria-labelledby="noteModalLabel"
      aria-hidden="true"
      header={cc_UK_NO ? "Note" : "Note"}
      footer={productDialogFooter}
    >
    <Toast ref={toast} />
      <div className="modal-dialog">
        <div className="modal-content" style={{backgroundColor:"#f9fbff"}}>
          <div className="modal-header" 
          style={{ 
            backgroundColor: "aliceblue", 
            display: "flex", 
            justifyContent: "space-between"
             }}>
            <h5 className="modal-title" id="noteModalLabel">
              Note
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">
          <div className="field row" style={{display:"none"}}>         
          <label htmlFor="av_CC_NO" className="font-bold">
          Contact No
          </label>
          <InputText
              id="av_CC_NO"
              name="av_CC_NO"
              value={av_CC_NO}
              onChange={(Event) => onchangedata(Event)}
            />
          </div>
            <div className="field row" >
              {/* <textarea
                id="av_TE_XT"
                name="av_TE_XT"
                value={NoteData.av_TE_XT}
                rows="3"
                onChange={(event) => onchangedata(event)}
              /> */}
               <div>
               <textarea 
                  id="av_TE_XT"
                  name="av_TE_XT"
                  value={NoteData.av_TE_XT}
                  rows="3"
                  onChange={(event) => onchangedata(event)}
                  style={{ marginBottom: '10px',width:"100%",height:"200px" }}
                  placeholder="Start typing to leave a note..."
                />
                {NoteData.av_TE_XT && NoteData.av_TE_XT.split('\n').map((paragraph, index) => (
                  <p key={index}
                  style={{display:"none"}}
                  >{paragraph}</p>
                ))}
            </div>
            </div>
          </div>
          <div className="input-group-append">
          {selectedFiles.map((file, index) => (
          <div key={index}
          style={{backgroundColor:"aliceblue"}}
          >{file.name}</div>
            ))}
             <div style={{display:"flex",width:"30%",justifyContent:"space-between"}}>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
              multiple
            />
            {/* <FontAwesomeIcon icon={faBold} />
            <FontAwesomeIcon icon={faItalic} />
            <FontAwesomeIcon icon={faUnderline} />
            <FontAwesomeIcon icon={faLink} />
            <FontAwesomeIcon icon={faFileImage} /> */}
            <FontAwesomeIcon icon={faPaperclip} onClick={handleFileSelection}/>
            </div>
            <div className="modal-footer">
            <Button
              icon="pi pi-times"
              variant="outlined"
              onClick={handleClose}
              tooltip="Cancel"
            />
            <Button
              icon="pi pi-check"
              onClick={StartNote}
              tooltip="Save"
            />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotePopup;
