import React from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import 'primeflex/primeflex.css';     
import "views/common/flags.css";

import AutomotiveOverview from './AutomotiveOverview';

const Automotive = () => {

  return (
   <div className='saleslead'>
      <AutomotiveOverview/>
   </div>
  )
}

export default Automotive