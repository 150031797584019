import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import FreeGoodsRevisionDialog from "./FreeGoodsRevisionDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  frUkNO:"",
  fr_AD_NM:"",
  fr_AD_IT:"",
  fr_TR_CR:"",
  fr_OR_QT:"",
  fr_TR_UM:"",   
  fr_FR_DT:"", 
  fr_TO_DT:"",    
  fr_IT_NM:"",
  fr_QT_OR:"",    
  fr_IT_UM:"",  
  fr_RL_PR:"",
  fr_LN_TY:"",    
  fr_IT_PT:"",    
  fr_PR_QR:"",    
  fr_CA_LG:"", 
  fr_IT_GP:"", 
};

const initialFilters = {
  fr_AD_NM: { value: "", operation: "" },
  fr_AD_IT: { value: "", operation: "" },
  des: { value: "", operation: "" },   
  fr_TR_CR: { value: "", operation: "" },
  fr_OR_QT: { value: "", operation: "" },
  fr_TR_UM: { value: "", operation: "" },   
  fr_FR_DT: { value: "", operation: "" }, 
  fr_TO_DT: { value: "", operation: "" },    
  fr_IT_NM: { value: "", operation: "" },
  item_DESC: { value: "", operation: "" },
  fr_QT_OR: { value: "", operation: "" },    
  fr_IT_UM: { value: "", operation: "" },  
  fr_RL_PR: { value: "", operation: "" },
  fr_LN_TY: { value: "", operation: "" },    
  fr_IT_PT: { value: "", operation: "" },    
  fr_PR_QR: { value: "", operation: "" },    
  fr_CA_LG: { value: "", operation: "" },  
};

const PriceAdjustmentDetailMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,rightalined
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  const [fr_AD_NM, setfr_AD_NM ] = useState("");
  const [fr_TR_CR, setfr_TR_CR ] = useState("");
  const [fr_IT_GP, setfr_IT_GP ] = useState("");
  const [fr_CU_GP, setfr_CU_GP ] = useState("");
  const [fr_IT_UM, setfr_IT_UM ] = useState("");
  const [fr_LN_TY, setfr_LN_TY ] = useState("");
  const [fr_CA_LG, setfr_CA_LG ] = useState("");
// AdvanceSearch
  const [SelectedITNM, setSelectedITNM ] = useState("");
  const [SelectedCust, setSelectedCust ] = useState("");
// Checkboxes
  const [fr_PR_MT, setfr_PR_MT] = useState("");

  const [itemcusChecked, setitemcusChecked ] = useState("");
  const [itemcusgrpChecked, setitemcusgrpChecked ] = useState("");
  const [itemGrpCusChecked, setitemGrpCusChecked ] = useState("");
  const [itemGrpCusGrpChecked, setitemGrpCusGrpChecked ] = useState("");
  const [itemChecked, setitemChecked ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [FreeGoodsRevisionTableData, setFreeGoodsRevisionTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setFreeGoodsRevisionTableData([{}]);
    setfr_AD_NM(null);
    setfr_IT_GP(null);
    setfr_CU_GP(null);
    setfr_IT_UM(null);
    setfr_LN_TY(null);
    setfr_CA_LG(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/FreeGoodsRevision/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const CashFlowData = response.data;
          const updatedCashFlowData = CashFlowData.FreeGoodsRevisionList.map((target) => {
            const UpdatedworkData = { ...target };
            delete UpdatedworkData.frUkNO;
            return UpdatedworkData;
          });
          setFreeGoodsRevisionTableData(updatedCashFlowData);
          setFormData(updatedCashFlowData[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [fr_CO_NO , setfr_CO_NO] = useState("");
  const handleDefault = async () => {
    setfr_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/FreeGoodsRevision/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value,}));
  };
  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/FreeGoodsRevision/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const FreeGoodsData = response.data;
        setFreeGoodsRevisionTableData(FreeGoodsData.FreeGoodsRevisionList);
        setFormData(FreeGoodsData.FreeGoodsRevisionList[0]);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("FreeGoodsRevisiontable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const freeGoodsRevisionList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const details = {
        frUkNO : document.getElementById("frUkNO_" + i).value,
        fr_IT_NM : document.getElementById("fr_IT_NM_" + i).value,
        fr_QT_OR : document.getElementById("fr_QT_OR_" + i).value,
        fr_IT_UM : table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
        fr_RL_PR : document.getElementById("fr_RL_PR_" + i).value,
        fr_LN_TY : table.tBodies[0].rows[i].cells[6].children[0].children[1].children[0].value,
        fr_IT_PT : document.getElementById("fr_IT_PT_" + i).value,
        fr_PR_QR : document.getElementById("fr_PR_QR_" + i).value,
        fr_CA_LG : table.tBodies[0].rows[i].cells[9].children[0].children[1].children[0].value,
    };
    freeGoodsRevisionList.push(details);
  }
 requestBody = {
  freeGoodsRevisionList,
    frUkNO:FormData.frUkNO,
    fr_AD_NM : FormData.fr_AD_NM,
    fr_IT_NM : document.getElementById("fr_IT_NM").value,
    fr_IT_GP : FormData.fr_IT_GP,
    fr_CU_NO : document.getElementById("fr_CU_NO").value,
    fr_CU_GP : FormData.fr_CU_GP,
    fr_FR_DT : FormData.fr_FR_DT,
    fr_TO_DT : FormData.fr_TO_DT,
    fr_OR_QT : document.getElementById("fr_OR_QT").value,
    fr_TR_CR : document.getElementById("fr_TR_CR").value,
    fr_PR_MT : FormData.fr_PR_MT,
  };
}
const handleFormSubmit = () => {
  values ();
  if (FormData.frUkNO) {
    const confirm = window.confirm("Do you want to Update ?");
    const update = `${domain}/api/FreeGoodsRevision/Update`;
    if (confirm) {
        axios.put(update, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });
    }
  } else {
    FormData.fr_CO_NO = company;
      axios.post(`${domain}/api/FreeGoodsRevision/Add`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        handleResponse(resp.data);
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  }
};
const leftToolbarTemplate = ( 
  <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack >
      <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
      Update
    </div>  
    </Tooltip>
      <Button   
      style={buttonStyles}
      icon="pi pi-check"
      outlined
      className="mr-2 update-button"
      onClick={handleUpdate}
      disabled={!isRecordSelected}
    ></Button>
    <Tooltip target=".add-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Add
      </div>
  </Tooltip>
    <Button 
      style={addButtonStyles}
      icon="pi pi-plus"     
      outlined
      className="mr-2 add-button"
      onClick={handleAddButtonClick}
    ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Copy
      </div>
  </Tooltip>
    <Button 
      style={copyButtonStyles}
      icon="pi pi-copy"
      outlined
      className="mr-2 copy-button"
      onClick={handleCopyData}
    ></Button>
  </React.Fragment>
);
const exportExcel = () => {
  import("xlsx").then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(tableData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "products_export.xlsx");
  });
};
const rightToolbarTemplate = (
  <React.Fragment>
    <Button
      style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
      icon="pi pi-upload"
      className="p-button-help"
      rounded
      onClick={exportExcel}
      data-pr-tooltip="XLS"
    />
  </React.Fragment>
);
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{  borderCollapse: "collapse", width: "100%", marginTop: "20px", }}>
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fr_AD_NM"] ? filters["fr_AD_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_AD_NM",
                        e.target.value,
                        filters["fr_AD_NM"]
                          ? filters["fr_AD_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fr_AD_IT"] ? filters["fr_AD_IT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_AD_IT",
                        e.target.value,
                        filters["fr_AD_IT"] ? filters["fr_AD_IT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ep_EX_CT"] ? filters["ep_EX_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ep_EX_CT",
                        e.target.value,
                        filters["ep_EX_CT"]
                          ? filters["ep_EX_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>      
                
                <td>
                  <input
                    type="text"
                    value={filters["fr_TR_CR"] ? filters["fr_TR_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_TR_CR",
                        e.target.value,
                        filters["fr_TR_CR"]
                          ? filters["fr_TR_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fr_OR_QT"] ? filters["fr_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_OR_QT",
                        e.target.value,
                        filters["fr_OR_QT"] ? filters["fr_OR_QT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fr_TR_UM"] ? filters["fr_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_TR_UM",
                        e.target.value,
                        filters["fr_TR_UM"]
                          ? filters["fr_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["fr_FR_DT"] ? filters["fr_FR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_FR_DT",
                        e.target.value,
                        filters["fr_FR_DT"]
                          ? filters["fr_FR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["fr_TO_DT"] ? filters["fr_TO_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_TO_DT",
                        e.target.value,
                        filters["fr_TO_DT"]
                          ? filters["fr_TO_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["fr_IT_NM"] ? filters["fr_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_IT_NM",
                        e.target.value,
                        filters["fr_IT_NM"]
                          ? filters["fr_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["item_DESC"] ? filters["item_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "item_DESC",
                        e.target.value,
                        filters["item_DESC"]
                          ? filters["item_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["fr_QT_OR"] ? filters["fr_QT_OR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_QT_OR",
                        e.target.value,
                        filters["fr_QT_OR"]
                          ? filters["fr_QT_OR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["fr_IT_UM"] ? filters["fr_IT_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_IT_UM",
                        e.target.value,
                        filters["fr_IT_UM"]
                          ? filters["fr_IT_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["fr_RL_PR"] ? filters["fr_RL_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_RL_PR",
                        e.target.value,
                        filters["fr_RL_PR"]
                          ? filters["fr_RL_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["fr_LN_TY"] ? filters["fr_LN_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_LN_TY",
                        e.target.value,
                        filters["fr_LN_TY"]
                          ? filters["fr_LN_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["fr_IT_PT"] ? filters["fr_IT_PT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_IT_PT",
                        e.target.value,
                        filters["fr_IT_PT"]
                          ? filters["fr_IT_PT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["fr_PR_QR"] ? filters["fr_PR_QR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_PR_QR",
                        e.target.value,
                        filters["fr_PR_QR"]
                          ? filters["fr_PR_QR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["fr_CA_LG"] ? filters["fr_CA_LG"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_CA_LG",
                        e.target.value,
                        filters["fr_CA_LG"]
                          ? filters["fr_CA_LG"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 


              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Adjustment Name </th>
                <th style={tableHeaderStyle}>Item Number</th>
                <th style={tableHeaderStyle}>Description</th>  
                <th style={tableHeaderStyle}>Currency</th>   
                <th style={tableHeaderStyle}>Order Qty</th>  
                <th style={tableHeaderStyle}>UM</th> 
                <th style={tableHeaderStyle}>From Date</th>  
                <th style={tableHeaderStyle}>To Date</th>  
                <th style={tableHeaderStyle}>Component Item</th> 
                <th style={tableHeaderStyle}>Description</th>  
                <th style={tableHeaderStyle}>Qty Ordered</th>
                <th style={tableHeaderStyle}>UM</th> 
                <th style={tableHeaderStyle}>Related Price</th>  
                <th style={tableHeaderStyle}>Line Type</th>  
                <th style={tableHeaderStyle}>PT</th> 
                <th style={tableHeaderStyle}>Qty Per Ordered</th>  
                <th style={tableHeaderStyle}>Catalog</th>                  
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.frUkNO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.frUkNO}
                        checked={selectedRows.includes(rowData.frUkNO)}
                        onChange={() => handleRowSelect(rowData.frUkNO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.fr_AD_NM}</td>
                    <td style={tableCellStyle}>{rowData.fr_AD_IT}</td>
                    <td style={tableCellStyle}>{rowData.des}</td>   
                    <td style={tableCellStyle}>{rowData.fr_TR_CR}</td>
                    <td style={tableCellStyle}>{rowData.fr_OR_QT}</td>
                    <td style={tableCellStyle}>{rowData.fr_TR_UM}</td>   
                    <td style={tableCellStyle}>{formatDate(rowData.fr_FR_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.fr_TO_DT)}</td>    
                    <td style={tableCellStyle}>{rowData.fr_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.item_DESC}</td>
                    <td style={tableCellStyle}>{rowData.fr_QT_OR}</td>    
                    <td style={tableCellStyle}>{rowData.fr_IT_UM}</td>  
                    <td style={tableCellStyle}>{rowData.fr_RL_PR}</td>
                    <td style={tableCellStyle}>{rowData.fr_LN_TY}</td>    
                    <td style={tableCellStyle}>{rowData.fr_IT_PT}</td>    
                    <td style={tableCellStyle}>{rowData.fr_PR_QR}</td>    
                    <td style={tableCellStyle}>{rowData.fr_CA_LG}</td>            
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <FreeGoodsRevisionDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        FreeGoodsRevisionTableData={FreeGoodsRevisionTableData}
        setFreeGoodsRevisionTableData={setFreeGoodsRevisionTableData}
        fr_CO_NO={fr_CO_NO}
        fr_AD_NM={fr_AD_NM}
        setfr_AD_NM={setfr_AD_NM}
        fr_TR_CR={fr_TR_CR}
        setfr_TR_CR={setfr_TR_CR}
        fr_IT_GP={fr_IT_GP}
        setfr_IT_GP={setfr_IT_GP}
        fr_CU_GP={fr_CU_GP}
        setfr_CU_GP={setfr_CU_GP}
        fr_IT_UM={fr_IT_UM}
        setfr_IT_UM={setfr_IT_UM}
        fr_LN_TY={fr_LN_TY}
        setfr_LN_TY={setfr_LN_TY}
        fr_CA_LG={fr_CA_LG}
        setfr_CA_LG={setfr_CA_LG}
        SelectedITNM={SelectedITNM}
        setSelectedITNM={setSelectedITNM}
        SelectedCust={SelectedCust}
        setSelectedCust={setSelectedCust}
        itemcusChecked={itemcusChecked}
        setitemcusChecked={setitemcusChecked}
        itemcusgrpChecked={itemcusgrpChecked}
        setitemcusgrpChecked={setitemcusgrpChecked}
        itemGrpCusChecked={itemGrpCusChecked}
        setitemGrpCusChecked={setitemGrpCusChecked}
        itemGrpCusGrpChecked={itemGrpCusGrpChecked}
        setitemGrpCusGrpChecked={setitemGrpCusGrpChecked}
        itemChecked={itemChecked}
        setitemChecked={setitemChecked}
        fr_PR_MT={fr_PR_MT}
        setfr_PR_MT={setfr_PR_MT}
      />
    </div>
  );
};

export default PriceAdjustmentDetailMain;
