import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import EquipmentPopup from "../Popups/EquipmentPopup";
const initialValue = {
  emUkNo:"",
  em_EQ_NM:"",
  em_EQ_DS:"",
  em_PD_MD:"",
  em_PD_FM:"",
  em_IT_NM:"",
  item_NAME:"",
};
const initialFilters = {
  em_EQ_NM: { value: "", operation: "" },
  em_EQ_DS: { value: "", operation: "" },
  em_PD_MD: { value: "", operation: "" },
  em_PD_FM: { value: "", operation: "" },
  em_IT_NM: { value: "", operation: "" },
  item_NAME: { value: "", operation: "" },
};
const EquipmentParentMain = () => {
// buttonstyles
  const {checkboxStyle,tableHeaderStyle,inputStyle,tableCellStyle,} = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  // FILTER
  const [ParentEQno, setParentEQno] = useState("");
  const [selectedParentEQno, setSelectedParentEQno] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [ParentEqunoPopupVisible, setParentEqunoPopupVisible] = useState(false);
  const openEqnoPopup = () => {
    setParentEqunoPopupVisible(true);
  };
  const handleDataSelect = (data) => {
    setSelectedParentEQno(data.em_EQ_DS);
    setParentEQno( data.em_EQ_NM);
    setParentEqunoPopupVisible(false);
    setShowSearchIcon(false);
    updateFilters (data.em_EQ_NM);
  };
  const updateFilters = (eqno) => {
    let updatedFilters = { ...filters };
  if (eqno !== null) {
    updatedFilters = {
      ...updatedFilters,
      em_EQ_NM: {
        value: eqno,
        operation: "EQUAL",
      },
    };
  }

  setFilters(updatedFilters);
  };
  const AdvanceSearch = (
    <div className="formgrid grid">
    <div className="field col">
      <label htmlFor="ParentEQno" className="font-bold">
        Parent Equipment No <span style={{ color: 'red' }}>*</span>
      </label>
      <div style={{ display: "flex" }}>
        <InputText
        style={{display:"block"}}
          id="ParentEQno"
          name="ParentEQno"
          onChange={(Event) => onchangedata(Event)}
          onFocus={() => setShowSearchIcon(true)}
          value={ParentEQno}
        />
         {showSearchIcon && (
        <i
          className="pi pi-search"
          style={{
            color: "var(--primary-color)",
            fontSize: "119%",
            marginTop: "4%",
            cursor: "pointer",
          }}
          onClick={openEqnoPopup}
        ></i>
        )}
         <InputText disabled
          id="em_EQ_NM"
          name="em_EQ_NM"
          onChange={(Event) => onchangedata(Event)}
          onFocus={() => setShowSearchIcon(true)}
          value={selectedParentEQno}
        />
       
      </div>
    </div>
  </div>
);
const handleClearButton=()=>{
  setSelectedParentEQno("");
  setParentEQno("");
  setFilters("");
  setShowSearchIcon(false);
}

const rightClear = (
  <React.Fragment>
    <Button
      style={{ height: "35px" }}
      icon="pi pi-times"
      className="p-button-help"
      link
      onClick={handleClearButton}
    />
  </React.Fragment>
);
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [cp_CO_NO , setcp_CO_NO] = useState("");
 
  const handleDefault = async () => {
    setcp_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/EquipmentMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
         <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
        </div>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_EQ_NM"] ? filters["em_EQ_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_EQ_NM",
                        e.target.value,
                        filters["em_EQ_NM"]
                          ? filters["em_EQ_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_EQ_DS"] ? filters["em_EQ_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_EQ_DS",
                        e.target.value,
                        filters["em_EQ_DS"] ? filters["em_EQ_DS"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_PD_MD"] ? filters["em_PD_MD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_PD_MD",
                        e.target.value,
                        filters["em_PD_MD"]
                          ? filters["em_PD_MD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_PD_FM"] ? filters["em_PD_FM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_PD_FM",
                        e.target.value,
                        filters["em_PD_FM"]
                          ? filters["em_PD_FM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_IT_NM"] ? filters["em_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_IT_NM",
                        e.target.value,
                        filters["em_IT_NM"]
                          ? filters["em_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["item_NAME"] ? filters["item_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "item_NAME",
                        e.target.value,
                        filters["item_NAME"]
                          ? filters["item_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
               
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Equipment No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Product Model</th>
                <th style={tableHeaderStyle}>Product Family</th>
                <th style={tableHeaderStyle}>Item Number</th>
                <th style={tableHeaderStyle}>Description</th>
            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.emUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.emUkNo}
                        checked={selectedRows.includes(rowData.emUkNo)}
                        onChange={() => handleRowSelect(rowData.emUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.em_EQ_NM}</td>
                    <td style={tableCellStyle}>{rowData.em_EQ_DS}</td>
                    <td style={tableCellStyle}>{rowData.em_PD_MD}</td>
                    <td style={tableCellStyle}>{rowData.em_PD_FM}</td>
                    <td style={tableCellStyle}>{rowData.em_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.item_NAME}</td>               
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <EquipmentPopup
        visible={ParentEqunoPopupVisible}
        onClose={() => setParentEqunoPopupVisible(false)}
        onDataSelect={handleDataSelect}
      />
    </div>
  );
};

export default EquipmentParentMain;
