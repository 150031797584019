import React, { useState, useEffect, useRef } from "react";
import "components/ListStyle/ListStyle.css";
import Chart from "chart.js/auto";
import { Pie } from 'react-chartjs-2';
import { Dropdown } from "primereact/dropdown";
import { Select } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
const  StageSourceDetails = ({ 
    visible,
    onClose,
    open, 
    setOpen,
    selectedYear,
    selectedMonth,
    inputFromDate,
    inputToDate,
    inputSalesPerson,
    inputTeam,
    inputLeadStage,
    inputLeadSource,
    inputOpportunityStatus,
    inputOpportunityReason
    }) => {
const {formatDate,tableHeaderStyle,tableCellStyle, } = ButtonStyles();
// front
const [dashboardData, setDashboardData] = useState({
  saLdPopupList: [],
});
const token = localStorage.getItem("token");
const company = localStorage.getItem("company");
const domain = localStorage.getItem("domain");

const dashboard = `${domain}/api/Dashboard/LeadDashboard`;
const CRMdashboard = async() =>{
  const requestinput ={
          "year":selectedYear || "",
          "month":selectedMonth || "",
          "from_Date":inputFromDate || "",
          "to_Date":inputToDate || "",
          "sales_Person":inputSalesPerson || "",
          "team": inputTeam || "",
          "lead_Stage":inputLeadStage || "",
          "lead_Source":inputLeadSource || "",
          "opportunity_Status":inputOpportunityStatus || "",
          "opportunity_Reason":inputOpportunityReason || "",
        }
  try {
    const response = await axios.post(dashboard, requestinput, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const data = response.data;
    setDashboardData(data);
    } catch (error) {
    console.error('Error fetching data:', error);
  }
};
const handleClose = () => {
    onClose();
    setOpen(false);
  };
   useEffect(() => {
      CRMdashboard();
        setOpen(visible);
    }, [visible, selectedMonth, selectedYear]);

return (
<Dialog id="dialog-style"
  visible={open}
  onHide={handleClose}
  style={{ width: "75rem", height: "53rem"}}
  header={'Source Details'}
  headerStyle={{textAlign:"center"}}
>
    {/* Table1&2 */}
    <div style={{ display: "flex",height:'100%',overflowY:'auto'  }}>
        <table id="Table2" style={{ border: "1px solid", borderRadius: "10px", width: "100%", tableLayout: "fixed", height: "auto" }}>
            <thead style={{position: "sticky", top: "0", zIndex: "1", backgroundColor: "#e1e1e1"}}>
                <tr>
                    <th style={tableHeaderStyle} >Date</th>
                    <th style={tableHeaderStyle} >Lead No</th>
                    <th style={tableHeaderStyle} >Customer</th>
                    <th style={tableHeaderStyle} >Lead Owner</th>
                    <th style={tableHeaderStyle} >Product Interest</th>
                    <th style={tableHeaderStyle} >Status</th>
                </tr>
            </thead>
            <tbody style={{ backgroundColor: "white",overflowY: "auto", maxHeight: "calc(80vh - 80px)" }}>
            {dashboardData.saLdPopupList && dashboardData.saLdPopupList.map((rowData, index) => (
                    <tr key={index} className="table-row" 
                    style={{ backgroundColor:index % 2 === 0 ? "aliceblue" : "#ffffff"}} >
                        <td style={tableCellStyle} >{formatDate(rowData.SL_TR_DT)}</td>
                        <td style={tableCellStyle} >{rowData.SL_LD_ID}</td>
                        <td style={tableCellStyle} >{rowData.SL_LD_NM}</td>
                        <td style={tableCellStyle} >{rowData.SL_LD_OW}</td>
                        <td style={tableCellStyle} >{rowData.SL_PR_IN}</td>
                        <td style={tableCellStyle} >{rowData.SL_ST_DS}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>

</Dialog>
  );
};

export default StageSourceDetails;
