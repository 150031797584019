import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function CarrierMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  FormData,
  setFormData,
  eg_GP_NM,
  seteg_GP_NM,
  eg_PO_NM,
  seteg_PO_NM,
  eg_AP_RQ,
  seteg_AP_RQ,
  eg_RM_ME,
  seteg_RM_ME,
  eg_CO_NO,
}) {
  const {
    egUkNo,
    cp_CP_NM,
    cp_ST_DT,
    cp_EN_DT,
    cp_BU_GT,
    cp_IM_ON,
    cp_CL_CK,
    cp_CN_CT,
    cm_EN_DT,
   

  } = data;
  const {borderbox,inboxstyle} = ButtonStyles();
  // dropdowns

  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  
  const [eg_GP_NMOptions, seteg_GP_NMOptions] = useState([]);
  const [eg_PO_NMOptions, seteg_PO_NMOptions] = useState([]);
  const [eg_AP_RQOptions, seteg_AP_RQOptions] = useState([]);
  const [eg_RM_MEOptions, seteg_RM_MEOptions] = useState([]);
// BIDDING type
  const TrackingTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seteg_GP_NM(value);
  };
// Acq
  const LicenseTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seteg_PO_NM(value);
  };
// loc
  const PerformanceRatingDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seteg_AP_RQ(value);
  };
// lang
  const CountryDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seteg_RM_ME(value);
  };


  useEffect(() => {
    fetchDropdownBT();
    fetchDropdownAQ();
    fetchDropdownLN();
    fetchDropdownLG();

  }, []);

  const fetchDropdownBT = async () => {
    const Options = await fetchClassCodeDS("EX/GP");
    seteg_GP_NMOptions(Options);
  };

  const fetchDropdownAQ = async () => {
    const Options = await fetchClassCodeDS("EX/PO");
    seteg_PO_NMOptions(Options);
  };

  const fetchDropdownLN = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    seteg_AP_RQOptions(Options);
  };

  const fetchDropdownLG = async () => {
    const Options = await fetchClassCodeDS("EX/RM");
    seteg_RM_MEOptions(Options);
  };



const isUpdateMode = Boolean(egUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={egUkNo ? "Revise Carrier Master" : "Add Carrier Master"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
            <div
                style={{
                  marginTop: "2%",
                  border: "1px solid #e8e8e8",
                  padding: "16px",
                  borderRadius: "15px",
                  backgroundColor: "aliceblue",
                  overflow: "auto",
                }}
              >
                <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="eg_CO_NO" className="font-bold">
              Company
              </label>
              <InputText disabled
                id="eg_CO_NO"
                name="eg_CO_NO"
                value={eg_CO_NO}
                // onChange={(e) => onchangedata(e)}
                readOnly
              />
          </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="eg_GP_NM" className="font-bold">
                     Tracking Type
                    </label>
                    <Dropdown
                    name="eg_GP_NM"
                    value={eg_GP_NM}
                    options={eg_GP_NMOptions}
                    onChange={(e) => TrackingTypeDropdown("eg_GP_NM", e.value)}
                    placeholder="Select Tracking Type"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="eg_PO_NM" className="font-bold">
                    License Type
                    </label>
                    <Dropdown
                    name="eg_PO_NM"
                    value={eg_PO_NM}
                    options={eg_PO_NMOptions}
                    onChange={(e) => LicenseTypeDropdown("eg_PO_NM", e.value)}
                    placeholder="Select License Type"
                  />
                  </div>

                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="eg_AP_RQ" className="font-bold">
                     Performance Rating
                    </label>
                    <Dropdown
                    name="eg_AP_RQ"
                    value={eg_AP_RQ}
                    options={eg_AP_RQOptions}
                    onChange={(e) => PerformanceRatingDropdown("eg_AP_RQ", e.value)}
                    placeholder="Select Performance Rating"
                  />
                  </div>
                  
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="eg_RM_ME" className="font-bold">
                    Country
                    </label>
                    <Dropdown
                    name="eg_RM_ME"
                    value={eg_RM_ME}
                    options={eg_RM_MEOptions}
                    onChange={(e) => CountryDropdown("eg_RM_ME", e.value)}
                    placeholder="Select Country"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="eg_CO_NO" className="font-bold">
                  Carrier Code
                  </label>
                  <InputText 
                    id="eg_CO_NO"
                    name="eg_CO_NO"
                    value={eg_CO_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="eg_CO_NO" className="font-bold">
                  Registration No
                  </label>
                  <InputText 
                    id="eg_CO_NO"
                    name="eg_CO_NO"
                    value={eg_CO_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                  </div>

                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="eg_CO_NO" className="font-bold">
                  Reference No
                  </label>
                  <InputText 
                    id="eg_CO_NO"
                    name="eg_CO_NO"
                    value={eg_CO_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                  </div>

                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="eg_CO_NO" className="font-bold">
                 Issuing Agency
                  </label>
                  <InputText 
                    id="eg_CO_NO"
                    name="eg_CO_NO"
                    value={eg_CO_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cm_EN_DT" className="font-bold">
                  Effective Date
                  </label>
                  <Calendar
                    id="cm_EN_DT"
                    name="cm_EN_DT"
                    value={moment(cm_EN_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "cm_EN_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cm_EN_DT" className="font-bold">
                  Expired Date
                  </label>
                  <Calendar
                    id="cm_EN_DT"
                    name="cm_EN_DT"
                    value={moment(cm_EN_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "cm_EN_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </div>


                  
                </div>
            </div>
      </div>
    </Dialog>
  );
}
