import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ReplenishmentRequestDialog from "./ReplenishmentRequestDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  prUkNo:"",
  er_EX_NO:"",
  er_TY_PE:"",
  er_EX_CO:"",
  er_RP_DT:"",
  er_EM_NO:"",
  employee_NAME:"",
  er_TY_PE:"",
  expanseTypeDES:"",
  pr_OR_TY:"",
  expanseCategoryDES:"",
  er_EX_AM:"",
  pr_LO_CN:"",
  pr_OP_QT:"",
  pr_CO_CT:"",
};

const initialFilters = {
  er_EX_NO: { value: "", operation: "" },
  er_TY_PE: { value: "", operation: "" },
  er_EX_CO: { value: "", operation: "" },
  er_RP_DT: { value: "", operation: "" },
  er_EM_NO: { value: "", operation: "" },
  employee_NAME: { value: "", operation: "" },
  er_TY_PE: { value: "", operation: "" },
  expanseTypeDES: { value: "", operation: "" },
  pr_OR_TY: { value: "", operation: "" },
  expanseCategoryDES: { value: "", operation: "" },
  er_EX_AM: { value: "", operation: "" },
  pr_LO_CN: { value: "", operation: "" },
  pr_OP_QT: { value: "", operation: "" },
};

const WorkCentreMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  const [pr_RQ_QT, setpr_RQ_QT ] = useState("");
  const [pr_LO_CN, setpr_LO_CN ] = useState("");
  const [pr_OR_TY, setpr_OR_TY ] = useState("");
  const [pr_CO_CT, setpr_CO_CT ] = useState("");
  const [pr_TO_LO, setpr_TO_LO ] = useState("");
  const [pr_OP_QT, setpr_OP_QT ] = useState("");
  const [pr_LT_NO, setpr_LT_NO] = useState("");
  const [pr_AC_LO, setpr_AC_LO ] = useState("");
  const [pr_TR_UM, setpr_TR_UM ] = useState("");
  
// AdvanceSearch
const [SelectedItem, setselectedItem ] = useState("");
const [SelectedDesc, setselectedDesc ] = useState("");

// Next No
  const [er_EX_NO, seter_EX_NO ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [ReplenishmentRequestTableData, setReplenishmentRequestTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setReplenishmentRequestTableData([{}]);
    setpr_RQ_QT(null);
    setpr_LO_CN(null);
    setpr_OR_TY(null);
    setpr_CO_CT(null);
    setpr_TO_LO(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ReplenishmentRequest/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const WorkCentreData = response.data;
          delete WorkCentreData.ReplenishmentRequestList[0].er_EX_NO;
          delete WorkCentreData.ReplenishmentRequestList[0].prUkNo;
          for (let i = 0; i < WorkCentreData.ReplenishmentRequestList.length; i++) {
            delete WorkCentreData.ReplenishmentRequestList[i].pdUkNo;
          }
          setReplenishmentRequestTableData(WorkCentreData.ReplenishmentRequestList);
          setFormData(WorkCentreData.ReplenishmentRequestList[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [er_EX_CO , seter_EX_CO] = useState("");
  const [er_TY_PE , seter_TY_PE] = useState("");

  const handleDefault = async () => {
    seter_EX_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "ER_TY_PE"){
              seter_TY_PE(dataDefault.versionIds[i].default_VALUE)
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ReplenishmentRequest/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ReplenishmentRequest/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const WorkCentreData = response.data;
        setReplenishmentRequestTableData(WorkCentreData.ReplenishmentRequestList);
        setFormData(WorkCentreData.ReplenishmentRequestList[0]);
        setselectedItem(WorkCentreData.ReplenishmentRequestList[0].pr_IT_NM)
        setselectedDesc(WorkCentreData.ReplenishmentRequestList[0].itemDES)
        setpr_CO_CT(WorkCentreData.ReplenishmentRequestList[0].pr_CO_CT);
        setpr_LO_CN(WorkCentreData.ReplenishmentRequestList[0].pr_LO_CN);
        setpr_RQ_QT(WorkCentreData.ReplenishmentRequestList[0].pr_RQ_QT);
        setpr_OP_QT(WorkCentreData.ReplenishmentRequestList[0].pr_OP_QT);

        setpr_OR_TY(WorkCentreData.ReplenishmentRequestList.pr_OR_TY);
        setpr_TR_UM(WorkCentreData.ReplenishmentRequestList.pr_TR_UM);
        setpr_TO_LO(WorkCentreData.ReplenishmentRequestList.pr_TO_LO);
        setpr_LT_NO(WorkCentreData.ReplenishmentRequestList.pr_LT_NO);
        setpr_AC_LO(WorkCentreData.ReplenishmentRequestList.pr_AC_LO);
       
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("operationtable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const ReplenishmentRequestList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const RQ_DT = document.getElementsByName("er_TR_DT_" + i)[0];
    const details = {
        prUkNo : document.getElementById("prUkNo_" + i).value,
        pr_OR_TY : table.tBodies[0].rows[i].cells[1].children[0].children[1].children[0].value,
        er_TR_DT : RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        er_CO_CT : table.tBodies[0].rows[i].cells[3].children[0].children[1].children[0].value,
        pr_TO_LO : table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
        er_TR_QT : document.getElementById("er_TR_QT_" + i).value,
        er_RA_TE : document.getElementById("er_RA_TE_" + i).value,
        er_EX_AM : document.getElementById("er_EX_AM_" + i).value,
        er_TR_CR :table.tBodies[0].rows[i].cells[8].children[0].children[1].children[0].value,
        er_AL_AM : document.getElementById("er_AL_AM_" + i).value,
    };
    ReplenishmentRequestList.push(details);
  }
  requestBody = {
  ReplenishmentRequestList,
    prUkNo   : FormData.prUkNo,
    er_EX_NO : document.getElementById("er_EX_NO").value,
    er_TY_PE : document.getElementById("er_TY_PE").value,
    er_EX_CO : document.getElementById("er_EX_CO").value,
    er_EM_NO : document.getElementById("er_EM_NO").value,
    er_MN_NO : document.getElementById("er_MN_NO").value,
    er_RP_DT : FormData.er_RP_DT,
    pr_CO_CT : FormData.pr_CO_CT,
    pr_LO_CN : FormData.pr_LO_CN,
    pr_RQ_QT : FormData.pr_RQ_QT,
    pr_OP_QT : FormData.pr_OP_QT,
    er_BU_PR : document.getElementById("er_BU_PR").value,
  };
}
const handleFormSubmit = () => {
  values();
  const orNo = document.getElementById("er_EX_NO").value;
  const ukNo = FormData.prUkNo;
  if (orNo) {
    if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/ReplenishmentRequest/Update`;
  
      if (confirm) {
        axios.put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/ReplenishmentRequest/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  } else {
      
      const OR_DT = FormData.er_RP_DT;
      if (OR_DT) {
        const currentYear = OR_DT.toString();
        const firstHalf = currentYear.substring(0, 2);
        const secondHalf = currentYear.substring(2, 4);
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
        const parameters = `${er_TY_PE}/${secondHalf}/1000/${firstHalf}`;
        const finalUrl1 = `${urlNextNumber}/${parameters}`;
        fetch(finalUrl1, {
          method: "GET",
          headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
        })
          .then((resp) => {
            if (!resp.ok) {
              throw new Error(`Request failed with status: ${resp.status}`);
            }
            return resp.json();
          })
          .then((resp) => {
            seter_EX_NO(resp.dn_DO_NO);
            document.getElementById("er_EX_NO").value = resp.dn_DO_NO;

            values();
            axios.post(`${domain}/api/ReplenishmentRequest/Add`, requestBody, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error saving data:", error);
                });

          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }
  };
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["er_EX_NO"] ? filters["er_EX_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_EX_NO",
                        e.target.value,
                        filters["er_EX_NO"]
                          ? filters["er_EX_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["er_TY_PE"] ? filters["er_TY_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_TY_PE",
                        e.target.value,
                        filters["er_TY_PE"] ? filters["er_TY_PE"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["er_EX_CO"] ? filters["er_EX_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_EX_CO",
                        e.target.value,
                        filters["er_EX_CO"]
                          ? filters["er_EX_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>      
                
                <td>
                  <input
                    type="text"
                    value={filters["er_RP_DT"] ? filters["er_RP_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_RP_DT",
                        e.target.value,
                        filters["er_RP_DT"]
                          ? filters["er_RP_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["er_EM_NO"] ? filters["er_EM_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_EM_NO",
                        e.target.value,
                        filters["er_EM_NO"] ? filters["er_EM_NO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["employee_NAME"] ? filters["employee_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "employee_NAME",
                        e.target.value,
                        filters["employee_NAME"]
                          ? filters["employee_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["er_TY_PE"] ? filters["er_TY_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_TY_PE",
                        e.target.value,
                        filters["er_TY_PE"]
                          ? filters["er_TY_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["expanseTypeDES"] ? filters["expanseTypeDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "expanseTypeDES",
                        e.target.value,
                        filters["expanseTypeDES"]
                          ? filters["expanseTypeDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["pr_OR_TY"] ? filters["pr_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_OR_TY",
                        e.target.value,
                        filters["pr_OR_TY"]
                          ? filters["pr_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["expanseCategoryDES"] ? filters["expanseCategoryDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "expanseCategoryDES",
                        e.target.value,
                        filters["expanseCategoryDES"]
                          ? filters["expanseCategoryDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["er_EX_AM"] ? filters["er_EX_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "er_EX_AM",
                        e.target.value,
                        filters["er_EX_AM"]
                          ? filters["er_EX_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["pr_LO_CN"] ? filters["pr_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_LO_CN",
                        e.target.value,
                        filters["pr_LO_CN"]
                          ? filters["pr_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["pr_OP_QT"] ? filters["pr_OP_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_OP_QT",
                        e.target.value,
                        filters["pr_OP_QT"]
                          ? filters["pr_OP_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Batch No</th>
                <th style={tableHeaderStyle}>Item No</th>
                <th style={tableHeaderStyle}>Quanity</th>  
                <th style={tableHeaderStyle}>UM</th>  
                <th style={tableHeaderStyle}>Status</th>   
                <th style={tableHeaderStyle}>Cost Centre</th>  
                <th style={tableHeaderStyle}>Order No</th> 
                <th style={tableHeaderStyle}>Order Type</th>  
                <th style={tableHeaderStyle}>Company</th>  
                <th style={tableHeaderStyle}>Line No</th> 
                <th style={tableHeaderStyle}>Location</th>  
                <th style={tableHeaderStyle}>Lot Number</th>
                <th style={tableHeaderStyle}>To Location</th>   
                <th style={tableHeaderStyle}>Open Quantity</th>               
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.prUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.prUkNo}
                        checked={selectedRows.includes(rowData.prUkNo)}
                        onChange={() => handleRowSelect(rowData.prUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.pr_BA_NO}</td>
                    <td style={tableCellStyle}>{rowData.pr_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.pr_OR_QT}</td>   
                    <td style={tableCellStyle}>{rowData.pr_TR_UM}</td>  
                    <td style={tableCellStyle}>{rowData.pr_ST_AT}</td>  
                    <td style={tableCellStyle}>{rowData.pr_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.pr_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.pr_OR_TY}</td> 
                    <td style={tableCellStyle}>{rowData.pr_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.pr_LN_NO}</td>
                    <td style={tableCellStyle}>{rowData.pr_LO_CN}</td>    
                    <td style={tableCellStyle}>{rowData.pr_LT_NO}</td>       
                    <td style={tableCellStyle}>{rowData.pr_TO_LO}</td>    
                    <td style={tableCellStyle}>{rowData.pr_OP_QT}</td>                    
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <ReplenishmentRequestDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        ReplenishmentRequestTableData={ReplenishmentRequestTableData}
        setReplenishmentRequestTableData={setReplenishmentRequestTableData}
        er_EX_CO={er_EX_CO}
        er_TY_PE={er_TY_PE}
        pr_LO_CN={pr_LO_CN}
        er_EX_NO={er_EX_NO}
        setpr_LO_CN={setpr_LO_CN}
        pr_RQ_QT={pr_RQ_QT}
        setpr_RQ_QT={setpr_RQ_QT}
        pr_OR_TY={pr_OR_TY}
        setpr_OR_TY={setpr_OR_TY}
        pr_CO_CT={pr_CO_CT}
        setpr_CO_CT={setpr_CO_CT}
        pr_TO_LO={pr_TO_LO}
        setpr_TO_LO={setpr_TO_LO}
        pr_OP_QT={pr_OP_QT}
        setpr_OP_QT={setpr_OP_QT}
        pr_AC_LO={pr_AC_LO}
        setpr_AC_LO={setpr_AC_LO}
        pr_LT_NO={pr_LT_NO}
        setpr_LT_NO={setpr_LT_NO}
        pr_TR_UM={pr_TR_UM}
        setpr_TR_UM={setpr_TR_UM}
        SelectedItem={SelectedItem}
        setselectedItem={setselectedItem}
        SelectedDesc={SelectedDesc}
        setselectedDesc={setselectedDesc}
      />
    </div>
  );
};

export default WorkCentreMain;
