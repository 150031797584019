import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import OverTimeDialog from "./OverTimeDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  eo_EC_NO: "",
  name : "",
  eo_SH_FT:"",
  eo_OV_TM:"",
  eo_CO_NO:"",
  eo_EM_NO:"",
  eo_OV_DT:"",
  eo_HO_TM:"",
};
const initialFilters = {
  eo_EC_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },
  eo_SH_FT: { value: "", operation: "" },
  eo_OV_TM: { value: "", operation: "" },
  eo_OV_DT: { value: "", operation: "" },
  eo_HO_TM: { value: "", operation: "" },
  eo_CO_NO: { value: "", operation: "" },
};

const OverTimeMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [OvertimeTableData, setOvertimeTableData] = useState([{}]);
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [eo_SH_FT, seteo_SH_FT] = useState("");

const buildSearchRequestDto = () => {
  return Object.keys(filters)
    .filter((column) => filters[column].value)
    .map((column) => ({
      column: column.toUpperCase(),
      value: filters[column].value,
      operation: filters[column].operation,
    }));
};

const handleFilterChange = (column, value, operation) => {
  let updatedOperation = operation;
  let updatedValue = value;
  if (value.startsWith(">")) {
    updatedOperation = "GREATER_THAN";
    updatedValue = value.substring(1);
  } else if (value.startsWith("<")) {
    updatedOperation = "LESS_THAN";
    updatedValue = value.substring(1);
  } else if (value.includes(",")) {
    updatedOperation = "BETWEEN";
  } else if (!isNaN(value)) {
    updatedOperation = "EQUAL";
  } else {
    if (value === value.toUpperCase()) {
      updatedOperation = "LIKE";
    } else {
      updatedOperation = "LIKE";
    }
  }

  const updatedFilters = { ...filters };
  updatedFilters[column] = {
    value: updatedValue,
    operation: updatedOperation,
  };
  setFilters(updatedFilters);
};
const onchangedata = (e) => {
  const { value, name } = e.target;
  setFormData({ ...FormData, [name]: value });
};
const onchangeFormData = (name, value,index=null) => {
  setFormData({ ...FormData, [name]: value });
  const fieldName=(FormData && FormData.eoUkNo)?`${name}`:`${name}_${index}`;
  if(index!=null)
  OvertimeTableData[index][fieldName]=value;
};
// const onchangeDropDownFormData1 = (name, value) => {
//   setFormData({ ...FormData, [name]: value });
//   seteo_SH_FT(value);
// };

const onchangeDropDownFormData1 = (name, value) => {
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

const handleClickOpen = () => {
  setOpen(true);
};
const handleAddButtonClick = () => {
  setOvertimeTableData([{}]);
  setFormData(initialValue);
  setSelectedemEMNO(null);
  seteo_SH_FT(null);
  setOpen(true);
};

const handleClose = () => {
  setFormData(initialValue);
  setSelectedemEMNO(null);
  seteo_SH_FT(null);
  setOpen(false);
};
const addRow = () => {
  setOvertimeTableData([...OvertimeTableData, {}]);
};

const deleteRow = () => {
  if (OvertimeTableData.length > 0) {
    const updatedData = [...OvertimeTableData];
    updatedData.pop();
    setOvertimeTableData(updatedData);
  }
};

  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/OverTime/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const OverTimeData = response.data;
          delete OverTimeData.eoUkNo;
          const updatedoverTimes = OverTimeData.overTimes.map(target => {
            const UpdatedOverTimeData = { ...target };
            delete UpdatedOverTimeData.eoUkNo;
            return UpdatedOverTimeData;
          });
          // setFormData(OverTimeData);
          // setSelectedemEMNO(OverTimeData.ad_EM_NM);
          // seteo_SH_FT(OverTimeData.eo_SH_FT);
          setOvertimeTableData(updatedoverTimes);
          setFormData(updatedoverTimes[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/OverTime/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/OverTime/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const OverTimeData = response.data;
        setOvertimeTableData(OverTimeData.overTimes);
        setFormData(OverTimeData.overTimes[0]);

        // setFormData(OverTimeData);
        // setSelectedemEMNO(response.data.ad_EM_NM);
        // seteo_SH_FT(response.data.eo_SH_FT);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFormSubmit = () => { 
      if (FormData && FormData.eoUkNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/OverTime/Update`;
        if (confirm) {
          const table = document.getElementById('OvertimeTable');
          const totalRowCount = table.tBodies[0].rows.length;

          const overtimeentry = [];
          for (let i = 0; i < totalRowCount; i++) { 
            const UK_NO = document.getElementById('eoUkNo_' + i);
            const EM_NO = document.getElementById('eo_EM_NO_' + i);
            const ame = document.getElementById('name_' + i);
            const SH_FT = FormData["eo_SH_FT_" + i];
            const OV_DT = document.getElementsByName("eo_OV_DT_" + i)[0];
            const OV_TM = document.getElementById('eo_OV_TM_' + i);
            const HO_TM = document.getElementById('eo_HO_TM_' + i);

            const Overtimes = {
              eoUkNo: UK_NO.value,
              eo_EM_NO: EM_NO.value,
              name: ame.value,
              eo_OV_DT: moment(OV_DT.value, "DD/MM/YYYY").format("YYYYMMDD"),
              eo_SH_FT: SH_FT,
              eo_OV_TM: OV_TM.value,
              eo_HO_TM: HO_TM.value,
              eo_CO_NO: company,
            };
            overtimeentry.push(Overtimes);
          } 
          const requestBody = {
            overTimes : overtimeentry,
          };
          axios
            .put(update, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      
      } else {
        const table = document.getElementById('OvertimeTable');
        const totalRowCount = table.tBodies[0].rows.length;
        const overTimes = [];
        for (let i = 0; i < totalRowCount; i++) {
          const ame = document.getElementById('name_' + i);
          const EM_NO = document.getElementById('eo_EM_NO_' + i);
          const SH_FT = FormData["eo_SH_FT_" + i];
          const OV_DT = document.getElementsByName("eo_OV_DT_" + i)[0];
          const OV_TM = document.getElementById('eo_OV_TM_' + i);
          const HO_TM = document.getElementById('eo_HO_TM_' + i);
          const Overtime = {
            name: ame.value,    
            eo_OV_DT: moment(OV_DT.value, "DD/MM/YYYY").format("YYYYMMDD"),
            eo_EM_NO: EM_NO.value,
            eo_SH_FT: SH_FT,
            eo_OV_TM: OV_TM.value,
            eo_HO_TM: HO_TM.value,
            eo_CO_NO: company,
          };
          overTimes.push(Overtime);
        } 
        const requestBody = {
          overTimes,
        };
        axios
          .post(`${domain}/api/OverTime/Add`, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    } ;
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const tableHeaderStyleH = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f5f4f4",
    display:"none"
  };

  const tableCellStyleH = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    display:"none"
  };

  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
    backgroundColor:"#f5f4f4"
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["eo_EM_NO"] ? filters["eo_EM_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eo_EM_NO",
                        e.target.value,
                        filters["eo_EM_NO"]
                          ? filters["eo_EM_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["eo_OV_DT"] ? filters["eo_OV_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eo_OV_DT",
                        e.target.value,
                        filters["eo_OV_DT"]
                          ? filters["eo_OV_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["eo_SH_FT"] ? filters["eo_SH_FT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eo_SH_FT",
                        e.target.value,
                        filters["eo_SH_FT"]
                          ? filters["eo_SH_FT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["eo_OV_TM"] ? filters["eo_OV_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eo_OV_TM",
                        e.target.value,
                        filters["eo_OV_TM"]
                          ? filters["eo_OV_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["eo_HO_TM"] ? filters["eo_HO_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eo_HO_TM",
                        e.target.value,
                        filters["eo_HO_TM"]
                          ? filters["eo_HO_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>                       
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyleH}>Employee Id</th>
                <th style={tableHeaderStyle}>Employee ID</th>
                <th style={tableHeaderStyle}>Date</th>
                <th style={tableHeaderStyle}>Shift</th>
                <th style={tableHeaderStyle}>Normal OT</th>
                <th style={tableHeaderStyle}>Holiday OT</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.eoUkNo}
                    style={{
                      backgroundColor: index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.eoUkNo}
                        checked={selectedRows.includes(rowData.eoUkNo)}
                        onChange={() => handleRowSelect(rowData.eoUkNo)}
                      />
                    </td>
                    <td style={tableCellStyleH}>{rowData.eo_EM_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.eo_OV_DT)}</td>
                    <td style={tableCellStyle}>{rowData.shift}</td>
                    <td style={tableCellStyle}>{rowData.eo_OV_TM}</td>
                    <td style={tableCellStyle}>{rowData.eo_HO_TM}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <OverTimeDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        eo_SH_FT={eo_SH_FT}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        addRow={addRow}
        deleteRow={deleteRow}
        OvertimeTableData={OvertimeTableData}
      />
    </div>
  );
};
export default OverTimeMain;
