import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function IndustryGroupingDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  ig_CO_NO,
  Industrytable,
  setIndustrytable,
  setFormData,
  ig_IN_RY,
  setig_IN_RY,
}) {
  const { igUkNo,ig_IN_TY} = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle} = ButtonStyles();
  const addRow = () => {
    setIndustrytable([...Industrytable, {}]);
  };
  const deleteRow = () => {
    if (Industrytable.length > 0) {
      const updatedData = [...Industrytable];
      updatedData.pop();
      setIndustrytable(updatedData);
    }
  };
// drodpdowns
const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
  const [ig_IN_RYOptions, setig_IN_RYOptions] = useState([]);
// INDUSTRY
const onchangeDropDownFormData1 = (name, value) => {
  setFormData({ ...data, [name]: value });
  setig_IN_RY(value);
};
  useEffect(() => {
    fetchDropdownIN();
  }, []);
  const fetchDropdownIN = async () => {
    const Options = await fetchClassCodeDS("FO/HD");
    setig_IN_RYOptions(Options);
  };
const isUpdateMode = Boolean(igUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={igUkNo ? "Revise Industry Grouping" : "Add Industry Grouping"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
            <div style={borderbox} >
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid"> 
              <div style={{display:"none"}}>
                  <label htmlFor="igUkNo" className="font-bold">
                    uk_no
                  </label>
                  <InputText disabled
                    id="igUkNo"
                    name="igUkNo"
                    value={igUkNo}
                  />
                </div>             
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ig_CO_NO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="ig_CO_NO"
                    name="ig_CO_NO"
                    defaultValue={ig_CO_NO}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ig_IN_RY" className="font-bold">
                    Industry
                  </label>
                  <Dropdown
                    id="ig_IN_RY"
                    name="ig_IN_RY"
                    value={ig_IN_RY}
                    options={ig_IN_RYOptions}
                    onChange={(e) => onchangeDropDownFormData1("ig_IN_RY", e.value)}
                    placeholder="Select Industry"
                  />
                </div>
              </div>
         </div>
      </div> 
      <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            marginTop: "2%",
          }}
        >
            <div
              className="table-buttons"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "0 10px 10px 0",
              }}
            >
              <Button
                icon="pi pi-plus"
                onClick={addRow}
                style={{
                  marginRight: "8px",
                  backgroundColor: "transparent",
                  color: "black",
                  border: "none",
                }}
                tooltip="Add"
              />
              <Button
                icon="pi pi-minus"
                onClick={deleteRow}
                style={{
                  backgroundColor: "transparent",
                  color: "black",
                  border: "none",
                }}
                tooltip="Delete"
              />
            </div>
            <div
              style={{
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
                position: "relative",
              }}
            >
            <table className="custom-table" id="Industrytable" style={{overflow:"auto"}}>
          <thead>
            <tr>
              <th style={{display:"none"}}>UK_NO</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
          {Industrytable.map((row, index) => (
            <tr key={index}>
              <td  style={{display:"none"}}>
                <InputText
                  id={`igUkNo_${index}`}
                  name={`igUkNo_${index}`}
                  value={row.igUkNo}
                />
              </td>
              <td>
              <InputText
                  id={`ig_IN_TY_${index}`}
                  name={`ig_IN_TY_${index}`}
                  defaultValue={row.ig_IN_TY ? row.ig_IN_TY : row[`ig_IN_TY_${index}`]}
                  onChange={(e) => onchangedata(e)} 
                />
              </td>
              </tr>
              ))}
          </tbody>
        </table>  
      </div>
    </div>
    </Dialog>
  );
}
