import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Costcenter from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
import '../../common/TabStyles.css';
export default function ReceiptFromTransitDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  setFormData,
  SelectedParent,
  setSelectedParent,
  onchangeFormData,
  ho_CO_CT,
  setho_CO_CT,
  ho_LO_CN,
  setho_LO_CN,
  ho_ST_CD,
  setho_ST_CD,
  ho_AP_VR,
  setho_AP_VR,
  ho_HL_CD,
  setho_HL_CD,
  ho_BL_QT,
  SelectedDesc,
  setSelectedDesc,
  SelectedItem,
  setSelectedItem,
}) {
  const {
    hoUkNo,
    ho_OR_NO,
    ho_OR_TY,
    ho_OR_CO,
    ho_IT_NM,
    ho_OK_QT,
    ho_RL_QT,
    ho_CO_QT,
    ho_LT_NO,
    ho_JB_NO,
   

  } = data;
  const { borderbox, inboxstyle, savestyle, cancelstyle } = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter, fetchCostCenterP } = Costcenter();

  const [ho_CO_CTOptions, setho_CO_CTOptions] = useState([]);
  const [ho_LO_CNOptions, setho_LO_CNOptions] = useState([]);
  const [ho_ST_CDOptions, setho_ST_CDOptions] = useState([]);
  const [ho_AP_VROptions, setho_AP_VROptions] = useState([]);
  const [ho_HL_CDOptions, setho_HL_CDOptions] = useState([]);

  // advance search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [ItemVisible, setItemVisible] = useState(false);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ParentPopupVisible, setParentPopupVisible] = useState(false);

  // Cost  Center 
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setho_CO_CT(value);
  };
  //Location
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setho_LO_CN(value);
  };
  // Status
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setho_ST_CD(value);
  };
  // Approver
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setho_AP_VR(value);
  };
  // Hold Code
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setho_HL_CD(value);
  };

  useEffect(() => {
    fetchDropdownCOST();
    fetchDropdownLocation();
    fetchDropdownStatus();
    fetchDropdownApprover();
    fetchDropdownHoldCode();


  }, []);


  const fetchDropdownCOST = async () => {
    const Options = await fetchCostCenter();
    setho_CO_CTOptions(Options);
  };
  const fetchDropdownLocation = async () => {
    const Options = await fetchClassCodeDS("LO/CN");
    setho_LO_CNOptions(Options);
  };
  const fetchDropdownStatus = async () => {
    const Options = await fetchClassCodeDS("SO/ST");
    setho_ST_CDOptions(Options);
  };
  const fetchDropdownApprover = async () => {
    const Options = await fetchClassCodeDS("FO/HD");
    setho_AP_VROptions(Options);
  };
  const fetchDropdownHoldCode = async () => {
    const Options = await fetchClassCodeDS("FO/HD");
    setho_HL_CDOptions(Options);
  };
  const isUpdateMode = Boolean(hoUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
        tooltip={isUpdateMode ? "Update" : "Submit"}>
      </Button>
    </React.Fragment>
  );
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{hoUkNo ? "Revise ReceiptFromTransit" : "Add ReceiptFromTransit"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );

  const handleItem = async (data) => {
    setSelectedItem(data.im_IT_NM)
    setSelectedDesc(data.im_IT_DS)
    onchangeFormData("ho_IT_NM", data.im_IT_NM);
    onchangeFormData("itemDES", data.im_IT_DS);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const openParentPopup = () => {
    setParentPopupVisible(true);
  };

  const handleSelectParent = (data) => {
    setSelectedParent(data.em_EQ_DS);
    onchangeFormData("ho_IT_NM", data.em_EQ_NM);
    setParentPopupVisible(false);
    setShowSearchIcon(false);
  };

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle} >
          <div className="formgrid grid">
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ho_OR_NO" className="font-bold">
                Order No
              </label>
              <InputText disabled
                id="ho_OR_NO"
                name="ho_OR_NO"
                value={ho_OR_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ho_OR_TY" className="font-bold">
                Type
              </label>
              <InputText disabled
                id="ho_OR_TY"
                name="ho_OR_TY"
                value={ho_OR_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ho_OR_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="ho_OR_CO"
                name="ho_OR_CO"
                value={ho_OR_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ho_JB_NO" className="font-bold">
                Job No
              </label>
              <InputText disabled
                id="ho_JB_NO"
                name="ho_JB_NO"
                value={ho_JB_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>

            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ho_CO_CT" className="font-bold">
                Cost Center
              </label>
              <Dropdown disabled
                id="ho_CO_CT"
                name="ho_CO_CT"
                value={ho_CO_CT}
                options={ho_CO_CTOptions}
                onChange={(e) => onchangeDropDownFormData1("ho_CO_CT", e.value)}
                placeholder="Cost Centre"
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ho_IT_NM" className="font-bold">
                Item  <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText disabled
                  id="ho_IT_NM"
                  name="ho_IT_NM"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedItem}
                />
                {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openItemPopup}
                  ></i>
                )}
              </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="itemDES" className="font-bold">
                Description
              </label>
              <InputText disabled
                id="itemDES"
                name="itemDES"
                value={SelectedDesc}
                readOnly
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ho_LO_CN" className="font-bold">
                Location
              </label>
              <Dropdown
                name="ho_LO_CN"
                value={ho_LO_CN}
                options={ho_LO_CNOptions}
                onChange={(e) => onchangeDropDownFormData2("ho_LO_CN", e.value)}
                placeholder="Location"
                disabled
              />
            </div>

            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ho_LT_NO" className="font-bold">
                Lot Number
              </label>
              <InputText disabled
                id="ho_LT_NO"
                name="ho_LT_NO"
                value={ho_LT_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ho_CO_QT" className="font-bold">
                Approved Qty
              </label>
              <InputText disabled
                id="ho_CO_QT"
                name="ho_CO_QT"
                value={ho_CO_QT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ho_RL_QT" className="font-bold">
                Released Qty
              </label>
              <InputText disabled
                id="ho_RL_QT"
                name="ho_RL_QT"
                value={ho_RL_QT}
                onChange={(e) => onchangedata(e)}
              />
            </div>

            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ho_OK_QT" className="font-bold">
                Ok Quantity
              </label>
              <InputText
                id="ho_OK_QT"
                name="ho_OK_QT"
                value={ho_OK_QT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ho_BL_QT" className="font-bold">
                Balance
              </label>
              <InputText disabled
                id="ho_BL_QT"
                name="ho_BL_QT"
                value={ho_BL_QT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ho_ST_CD" className="font-bold">
                Status
              </label>
              <Dropdown
                name="ho_ST_CD"
                value={ho_ST_CD}
                options={ho_ST_CDOptions}
                onChange={(e) => onchangeDropDownFormData3("ho_ST_CD", e.value)}
                placeholder="Status"
                disabled
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ho_AP_VR" className="font-bold">
                Approver
              </label>
              <Dropdown
                name="ho_AP_VR"
                value={ho_AP_VR}
                options={ho_AP_VROptions}
                onChange={(e) => onchangeDropDownFormData4("ho_AP_VR", e.value)}
                placeholder="Approver"
                disabled
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ho_HL_CD" className="font-bold">
                Hold Code
              </label>
              <Dropdown
                name="ho_HL_CD"
                value={ho_HL_CD}
                options={ho_HL_CDOptions}
                onChange={(e) => onchangeDropDownFormData5("ho_HL_CD", e.value)}
                placeholder="  Hold Code"
                disabled
              />
            </div>


          </div>
        </div>
      </div>
      {/* Item */}
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
