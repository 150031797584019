import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function FloorInformationDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  // onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  addRow,
  deleteRow,
  FloorInformationtable,
  fi_CO_NO,
  fi_PR_NO,
}) {
  const { 
  fi_BL_NO,
  fi_FL_NO,
  fi_AR_TY,
  fi_AR_GP,
  fiUkNo,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const handleFormDataChange3= (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const handleFormDataChange4= (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData4(name, value, index);
  };
 
  const handleFormDataChange5= (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData5(name, value, index);
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "fi_ST_DT"  || name === "fi_EN_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);
  
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter,fetchCostCenterP } = Costcenter();
  const [fi_FL_NOOptions, setfi_FL_NOOptions] = useState([]);
  const [fi_AR_TYOptions, setfi_AR_TYOptions] = useState([]);
  const [fi_TR_UMOptions, setfi_TR_UMOptions] = useState([]);
  const [fi_PR_NOOptions, setfi_PR_NOOptions] = useState([]);
  const [fi_BL_NOOptions, setfi_BL_NOOptions] = useState([]);
  useEffect(() => {
    fetchprpty();
    fetchblding();
    fetchflor();
    fetchAREA();
    fetchUM();   
  }, []);

  const fetchprpty = async () => {
    const Options = await fetchCostCenterP("PT");
    setfi_PR_NOOptions(Options);
  };

  const fetchblding = async () => {
    const Options = await fetchCostCenterP("BL");
    setfi_BL_NOOptions(Options);
  };

  const fetchflor = async () => {
    const Options = await fetchClassCodeDS("FL/IN");
    setfi_FL_NOOptions(Options);
  };

  const fetchAREA = async () => {
    const Options = await fetchClassCodeDS("BL/AR");
    setfi_AR_TYOptions(Options);
  };

  const fetchUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setfi_TR_UMOptions(Options);
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handlebyproperty = async (v) => {
  const Url = `${domain}/api/PropertyBuildingInformation/GetBYProperty`;
  const requestData={
    "bi_PR_NO": v,
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataprty = response.data.Result;
    const options = dataprty.map((item) => ({
      label: item.bu_DESC,
      value: item.bi_BL_NO,
    }));
    setfi_BL_NOOptions(options);
    
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  };
const isUpdateMode = Boolean(fiUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{fiUkNo ? "Revise Floor Information" : "Add Floor Information"}</h5>
    <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
        tooltip={isUpdateMode ? "Update" : "Submit"}
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >   
          <div  style={inboxstyle}>
            <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="fi_PR_NO" className="font-bold">
                Property
                </label>
                <Dropdown
                  id="fi_PR_NO"
                  name="fi_PR_NO"
                  value={fi_PR_NO}
                  options={fi_PR_NOOptions}
                  onChange={(e) => {onchangeDropDownFormData2("fi_PR_NO", e.value);
                  handlebyproperty(e.value);}}
                  placeholder="Select Property"
                /> 
                </div>    
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fi_BL_NO" className="font-bold">
                Building
                  </label>
                  <Dropdown
                    id="fi_BL_NO"
                    name="fi_BL_NO"
                    value={fi_BL_NO}
                    options={fi_BL_NOOptions}
                    onChange={(e) => onchangeDropDownFormData3("fi_BL_NO", e.value) }
                    placeholder="Select Building"
                  /> 
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fi_CO_NO" className="font-bold">
                Company
                  </label>
                  <InputText disabled
                    id="fi_CO_NO"
                    name="fi_CO_NO"
                    value={fi_CO_NO}
                    readOnly
                  />
              </div>
            </div>
          </div>
          <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
            <table className="custom-table" id="FloorInformationtable" style={{overflow:"auto"}}>
              <thead>
                <tr>
                  <th style={{ display: "none" }} >UK_NO</th>
                  <th>Floor</th>
                  <th>Area Type</th>
                  <th>Area</th>
                  <th>UM</th>
                </tr>
              </thead>
              <tbody>
              {FloorInformationtable.map((row, index) => (
                      <tr key={index}>
                    <td style={{ display: "none" }} >
                    <InputText
                      id={`fiUkNo_${index}`}
                      name={`fiUkNo_${index}`}
                      defaultValue={row.fiUkNo}
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`fi_FL_NO_${index}`}
                      name={`fi_FL_NO_${index}`}
                      value={data[`fi_FL_NO_${index}`] ? data[`fi_FL_NO_${index}`]:row.fi_FL_NO }
                      options={fi_FL_NOOptions}
                      onChange={(e) => handleFormDataChange3(`fi_FL_NO_${index}`, e.value)}
                      placeholder="Select Floor"
                    />

                  </td>
                    <td>
                    <Dropdown
                      id={`fi_AR_TY_${index}`}
                      name={`fi_AR_TY_${index}`}
                      value={data[`fi_AR_TY_${index}`] ? data[`fi_AR_TY_${index}`]:row.fi_AR_TY }
                      options={fi_AR_TYOptions}
                      onChange={(e) => handleFormDataChange4(`fi_AR_TY_${index}`, e.value)}
                      placeholder="Select Area Type"
                    />
                  </td>
                  <td>
                  <InputText
                    id={`fi_TR_QT_${index}`}
                    name={`fi_TR_QT_${index}`}
                    defaultValue={row.fi_TR_QT}
                    onChange={(Event) => onchangedata(Event)}
                  />
                  </td>
                  <td>
                    <Dropdown
                      id={`fi_TR_UM_${index}`}
                      name={`fi_TR_UM_${index}`}
                      value={data[`fi_TR_UM_${index}`] ? data[`fi_TR_UM_${index}`]:row.fi_TR_UM }
                      options={fi_TR_UMOptions}
                      onChange={(e) => handleFormDataChange5(`fi_TR_UM_${index}`, e.value)}
                      placeholder="Select UM"
                    />
                  </td>         
                  </tr>
                  ))}
              </tbody>
            </table> 
          </div>
          </div>   
      </div>
    </Dialog>
  );
}

