import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { InputMask } from "primereact/inputmask";
import ButtonStyles from "views/common/ButtonStyles";
import AddressBookPopup from "../Popups/AddressBookPopup";
import MaintainanceOrders from "../Popups/MaintainOrdersPopup";
export default function MaintainanceTimeEntryDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  MaintainanceTimeEntryTableData,
  setMaintainanceTimeEntryTableData,
  sr_WK_TM,

}) {
  const {
    mt_OR_NO,
    mt_EQ_NM,
    mt_OR_CO,
    mt_OR_TY,
    mt_SH_FT,
    mtEmNo,mtWkDt,
    mt_UK_NO,
  } = data;
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Handle form data change
  const onChangeTime=(name,value,index)=>{
    value= moment(value, "HH:mm:ss").format("HHmmss");
    // const formattedTime = value.replace(/:/g, "");
    // onchangeFormData(name, formattedTime, index);
    onchangeFormData(name, value,index);
  }
  const addRow = () => {
    setMaintainanceTimeEntryTableData([...MaintainanceTimeEntryTableData, {}]);
  };

  const deleteRow = () => {
    if (MaintainanceTimeEntryTableData.length > 0) {
      const updatedData = [...MaintainanceTimeEntryTableData];
      updatedData.pop();
      setMaintainanceTimeEntryTableData(updatedData);
    }
  };
  const {borderbox,plusstyle,inboxstyle,minusstyle,tablebutton,tablestyle,multitablestyle} = ButtonStyles();

//advance search
const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);
const [OrderPopupVisible, setOrderPopupVisible] = useState(false);
const [addressType, setaddressType] = useState("E");
const [showSearchIcon, setShowSearchIcon] = useState("");
const [SelectedRowIndex, setSelectedRowIndex] = useState("");
const openEmployeePopup = (e) => {
  setEmployeePopupVisible(true);
  setSelectedRowIndex(e);
  setShowSearchIcon(false);
};
const openOrderPopup = () => {
  setOrderPopupVisible(true);
  setShowSearchIcon(false);
};


const handleSelectEmployee = (data) => {
  document.getElementById(`mtEmNo_${SelectedRowIndex}`).value = data.abAdNo;
  document.getElementById(`name_${SelectedRowIndex}`).value = data.ab_AD_NM;
  setEmployeePopupVisible(false);
  setShowSearchIcon(false);
};
const handleSelectOrder = (content) => {
  onchangeFormData("mt_OR_NO", content.wm_OR_NO);
  onchangeFormData("mt_OR_TY", content.wm_OR_TY);
  onchangeFormData("mt_OR_CO", content.wm_OR_CO);
  onchangeFormData("mt_EQ_NM", content.wm_EQ_NM);
  setOrderPopupVisible(false);
  setShowSearchIcon(false);
};


const isUpdateMode = Boolean(mt_UK_NO);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={mt_UK_NO ? "Revise Maintainance Time Entry" : "Add Maintainance Time Entry"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
            <div style={inboxstyle} >
                <div className="formgrid grid">
                <div style={{display:"none"}}>
                    <label htmlFor="mt_UK_NO" className="font-bold">
                  uk_no
                    </label>
                    <InputText 
                      id="mt_UK_NO"
                      name="mt_UK_NO"
                      defaultValue={mt_UK_NO}
                    />
                  </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="mt_OR_NO" className="font-bold">
                  Order No <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText 
                      id="mt_OR_NO"
                      name="mt_OR_NO"
                      onFocus={() => setShowSearchIcon(true)}
                      onChange={(e) => onchangedata(e)}
                      value={mt_OR_NO}
                    />
                     {showSearchIcon && (
                      <i 
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "7%",
                        cursor: "pointer",
                        
                      }}
                      onClick={(e) => openOrderPopup(e)}
                    ></i>
                     )}
                     </div>
                    </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="mt_OR_TY" className="font-bold">
                     Type
                    </label>
                    <InputText disabled
                      id="mt_OR_TY"
                      name="mt_OR_TY"
                      value={mt_OR_TY}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="mt_OR_CO" className="font-bold">
                     Company
                    </label>
                    <InputText disabled
                      id="mt_OR_CO"
                      name="mt_OR_CO"
                      value={mt_OR_CO}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="mt_EQ_NM" className="font-bold">
                      Equipment Name
                    </label>
                    <InputText disabled
                      id="mt_EQ_NM"
                      name="mt_EQ_NM"
                      value={mt_EQ_NM}
                    />
                  </div>             
                </div>
            </div>
      </div>
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="MaintainanceTimeEntryTable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Employee No <span style={{color:"red"}}>*</span> </th>    
          <th>Employee Name</th>
          <th>Work Date</th>
          <th>Shift</th>    
          <th>Time</th>          
        </tr>
      </thead>
      <tbody>
      {MaintainanceTimeEntryTableData.map((row, index) => (
              <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
              id={`mt_UK_NO_${index}`}
              name={`mt_UK_NO_${index}`}
              value={row.mt_UK_NO}
            />
          </td>
          <td>
            <div style={{display:"flex"}}>
          <InputText 
            style={{width:"100px"}}
            id={`mtEmNo_${index}`}
            name={`mtEmNo_${index}`}
            onChange={(Event) => onchangedata(Event)}
            onFocus={() => setShowSearchIcon(true)}
            defaultValue={row.mtEmNo ? row.mtEmNo : row[`${'mtEmNo'}_${index}`]}
          />
           {showSearchIcon && (
           <i
              className="pi pi-search"
              style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "7%",
                cursor: "pointer",
              }}
              onClick={(e)=>openEmployeePopup(index)}
            ></i>
           )}
            </div>
          </td>
          <td>
          <InputText disabled
            style={{width:"150px"}}
            id={`name_${index}`}
            name={`name_${index}`}
            defaultValue={row.name}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <div style={{display:"flex"}}>
          <Calendar 
            id={`mtWkDt_${index}`}
            name={`mtWkDt_${index}`}
            value={ row.mtWkDt ? moment(row.mtWkDt, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "mtWkDt", value: moment(e.value).format("YYYYMMDD") } })}
            dateFormat="dd/mm/yy"
          />
          </div>
          </td>
          <td>
          <InputText
            style={{width:"150px"}} 
            id={`mt_SH_FT_${index}`}
            name={`mt_SH_FT_${index}`}
            defaultValue={row.mt_SH_FT}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          {/* <InputText 
          style={{width:"100px"}}
            id={`mt_WK_TM_${index}`}
            name={`mt_WK_TM_${index}`}
            defaultValue={row.mt_WK_TM}
            onChange={(Event) => onchangedata(Event)}
          /> */}
           <InputMask
              id={`mt_WK_TM_${index}`}
              name={`mt_WK_TM_${index}`}
              mask="99:99:99"
              value={ row.mt_WK_TM ? moment(row.mt_WK_TM, "HHmmss").format("HH:mm:ss") : null }
              onChange={(e) => onChangeTime("mt_WK_TM", e.target.value, index) }
            />
            {/* <InputMask
                id={`mt_WK_TM_${index}`}
                name={`mt_WK_TM_${index}`}
                mask="99:99:99"
                value={moment(row.mt_WK_TM, "HHmmss").format("HH:mm:ss")}
                onBlur={(e) => {
                  onChangeTime("mt_WK_TM",e.target.value,index);
                }}/> */}
          </td>
          </tr>
           ))}
      </tbody>
    </table> 
  </div>
      </div>
      <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleSelectEmployee}
        formData={data}
        addressType={addressType}
      /> 
       <MaintainanceOrders
        visible={OrderPopupVisible}
        onClose={() => setOrderPopupVisible(false)}
        onDataSelect={handleSelectOrder}
        formData={data}
      />  
    </Dialog>
  );
}
