import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import ButtonStyles from "views/common/ButtonStyles";
export default function CompensationDetailsDialog({
  // useraccess,
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  ec_CC_NM,
  em_EM_DS,
  em_DE_PT,
  ec_PY_CL,
  em_GR_DE,
  ec_CO_NO,
  em_PY_FR,
  em_GR_EF,
  SelectedemEMNO,
  setSelectedemEMNO,
}) {
  // useraccess
  // const toast = useRef(null);
  // const handleSubmit = () => {
  //   if (useraccess === "YES") {
  //     handleFormSubmit();
  //   } else {
  //     toast.current.show({
  //       severity: "error",
  //       summary: "Error",
  //       detail: "User does not have access to this functionality",
  //     });
  //   }
  // };
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle,TabBoxStyle} = ButtonStyles();

  const {
    ec_TO_SL,
    ec_NX_RE,
    ec_LT_RE,
    ecUkNo,
    ec_BA_SC,
    ec_HO_RA,
    ec_TR_AL,
    ec_FL_AL,
    ec_FD_AL,
    ec_OT_AL,
    ec_WP_BS,
    ec_WP_HR,
    ec_WP_TA,
    ec_WP_OT,
    ec_WP_EF,
    ec_EM_NO,
  } = data;
  const [ec_CC_NMOptions, setec_CC_NMOptions] = useState([]);
  const [em_EM_DSOptions, setem_EM_DSOptions] = useState([]);
  const [em_DE_PTOptions, setem_DE_PTOptions] = useState([]);
  const [ec_PY_CLOptions, setec_PY_CLOptions] = useState([]);
  const [em_GR_DEOptions, setem_GR_DEOptions] = useState([]);
  const [em_PY_FROptions, setem_PY_FROptions] = useState([]);

  const [activeTab, setActiveTab] = useState(0);
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl1 = `${urlcost}`;

  const url = `${domain}/api/ClassCode/All`;
  // designation
  const parameter2 = "DE/SG";
  const finalUrl2 = `${url}/${parameter2}`;
  // departmemt
  const parameter3 = "EM/DP";
  const finalUrl3 = `${url}/${parameter3}`;
  // pay class
  const parameter4 = "PY/CL";
  const finalUrl4 = `${url}/${parameter4}`;
  // grade
  const parameter5 = "EM/GR";
  const finalUrl5 = `${url}/${parameter5}`;
  // Pay Frequency
  const parameter6 = "EM/PF";
  const finalUrl6 = `${url}/${parameter6}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
  }, []);

// 1st dropdowns
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setec_CC_NMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 2nd dropdowns
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_EM_DSOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_DE_PTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 4th dropdown
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setec_PY_CLOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 5th dropdown
  const fetchDropdownOptions5 = async () => {
    try {
      const response = await axios.get(finalUrl5, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_GR_DEOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 6th dropdown
  const fetchDropdownOptions6 = async () => {
    try {
      const response = await axios.get(finalUrl6, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_PY_FROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);  
  const [addressType, setaddressType] = useState("E");
  const openEmployeePopup = () => {
    setEmployeePopupVisible(true);
  };
  const handleDataSelect = async(data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("ec_EM_NO", data.abAdNo);
    const  Url = `${domain}/api/EmployeeMaster/GetByEmployee`
    const requestData = {
      "emEmNo": data.abAdNo
    };
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data.employeeDetails;
      if(data){
        onchangeDropDownFormData1("ec_CC_NM", data.em_CC_NM);
        onchangeDropDownFormData2("ec_EM_DS", data.em_EM_DS); 
        onchangeDropDownFormData3("em_DE_PT", data.em_DE_PT); 
        onchangeDropDownFormData5("em_GR_DE", data.em_GR_DE); 
        onchangeDropDownFormData6("ec_PY_FR", data.em_PY_FR); 
        onchangeFormData("em_GR_EF", data.em_GR_EF);
      }
      else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  const [showSearchIcon, setShowSearchIcon] = useState("");

// tab enable and disable
const [showWPSTab, setShowWPSTab] = useState("");
useEffect(() => {
  fetchTab();
  fetchfields();
}, []);
const fetchTab = async () => {
  try {
    const request ={
        name: "DISPLAY_WPS_TAB",
        value: "FALSE",
    }
    const response = await axios.post( `${domain}/api/ErpParameters/GetByRole`,request,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      }
    );
    const responseValue = response.data.value;
    setShowWPSTab(responseValue === "true");
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
// fields disable enable
const [showfoodallow, setshowfoodallow] = useState("");
// const [showfuelallow, setshowfuelallow] = useState("");
const fetchfields = async () => {
  try {
    const request ={
        name: "DISPLAY_FOOD_ALLOW_FIELD",
        value: "FALSE",
    }
    const response = await axios.post( `${domain}/api/ErpParameters/GetByRole`,request,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      }
    );
      const responseValue = response.data.value;
      setshowfoodallow(responseValue === "true");
      // setshowfuelallow(responseValue === "true");
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const isUpdateMode = Boolean(ecUkNo);
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{ecUkNo ? "Revise Compensation Details" : "Add Compensation Details"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      {activeTab === 0 && (
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
      )}
       {activeTab === 0 && (
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
       )}
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
  <Toast ref={toast} />
      <div style={TabBoxStyle} >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="General">
            {/* tab1 general */}
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_EM_NO" className="font-bold">
                    Employee <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedemEMNO}
                    />
                     {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openEmployeePopup}
                    ></i>
                     )}
                  </div>
                  <div style={{display:"none"}}>
                    <InputText 
                      id="ec_EM_NO"
                      name="ec_EM_NO"
                      value={ec_EM_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_CO_NO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="ec_CO_NO"
                    name="ec_CO_NO"
                    value={ec_CO_NO}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_CC_NM" className="font-bold">
                    Cost Centre
                  </label>
                  <Dropdown disabled
                    name="ec_CC_NM"
                    value={ec_CC_NM}
                    options={ec_CC_NMOptions}
                    onChange={(e) => onchangeDropDownFormData1("ec_CC_NM", e.value)}
                    placeholder="Select Cost Center"
                  />
                  
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_EM_DS" className="font-bold">
                    Designation
                  </label>
                  <Dropdown disabled
                    name="em_EM_DS"
                    value={em_EM_DS}
                    options={em_EM_DSOptions}
                    onChange={(e) => onchangeDropDownFormData2("em_EM_DS", e.value)}
                    placeholder="Select a Designation"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_DE_PT" className="font-bold">
                    Department
                  </label>
                  <Dropdown disabled
                    name="em_DE_PT"
                    value={em_DE_PT}
                    options={em_DE_PTOptions}
                    onChange={(e) => onchangeDropDownFormData3("em_DE_PT", e.value)}
                    placeholder="Select a Department"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PY_FR" className="font-bold">
                    Pay Frequency 
                  </label>
                  <Dropdown disabled
                    id="em_PY_FR"
                    name="em_PY_FR"
                    value={em_PY_FR}
                    options={ em_PY_FROptions}
                    onChange={(e) => onchangeDropDownFormData6("em_PY_FR", e.value)}
                    placeholder="Select a Pay Frequency"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_GR_DE" className="font-bold">
                   Grade
                  </label>
                  <Dropdown disabled
                    id="em_GR_DE"
                    name="em_GR_DE"
                    value={em_GR_DE}
                    options={em_GR_DEOptions}
                    onChange={(e) => onchangeDropDownFormData5("em_GR_DE", e.value)}
                    placeholder="Select a Grade"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_GR_EF" className="font-bold">
                    Effective From
                  </label>
                  <Calendar disabled
                    id="em_GR_EF"
                    name="em_GR_EF"
                    value={moment(data.em_GR_EF, "YYYYMMDD").isValid() ? moment(data.em_GR_EF, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: { name: "em_GR_EF",value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "",  }, }) }
                    dateFormat="dd/mm/yy"
                  />
                </div>                
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_PY_CL" className="font-bold">
                    Pay Class
                  </label>
                  <Dropdown
                    name="ec_PY_CL"
                    value={ec_PY_CL}
                    options={ec_PY_CLOptions}
                    onChange={(e) => onchangeDropDownFormData4("ec_PY_CL", e.value)}
                    placeholder="Select a Pay Class"

                  />
                </div>
                </div>
              </div>
              <div style={inboxstyle}>
              <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_TO_SL" className="font-bold">
                   Total Salary
                  </label>
                  <InputText
                    id="ec_TO_SL"
                    name="ec_TO_SL"
                    value={ec_TO_SL}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_BA_SC" className="font-bold">
                    Basic
                  </label>
                  <InputText
                    id="ec_BA_SC"
                    name="ec_BA_SC"
                    value={ec_BA_SC}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_HO_RA" className="font-bold">
                   HRA
                  </label>
                  <InputText
                    id="ec_HO_RA"
                    name="ec_HO_RA"
                    value={ec_HO_RA}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_TR_AL" className="font-bold">
                   Travel Allowance
                  </label>
                  <InputText
                    id="ec_TR_AL"
                    name="ec_TR_AL"
                    value={ec_TR_AL}
                    onChange={(e) => onchangedata(e)}
                  />

                  
                </div>
                {/* {showfuelallow && ( */}
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_FL_AL" className="font-bold">
                   Fuel Allowance
                  </label>
                  <InputText
                    id="ec_FL_AL"
                    name="ec_FL_AL"
                    value={ec_FL_AL}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                {/*  )} */}
                {showfoodallow && (
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_FD_AL" className="font-bold">
                   Food Allowance
                  </label>
                  <InputText
                    id="ec_FD_AL"
                    name="ec_FD_AL"
                    value={ec_FD_AL}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                )}
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_OT_AL" className="font-bold">
                   Other Allowance
                  </label>
                  <InputText
                    id="ec_OT_AL"
                    name="ec_OT_AL"
                    value={ec_OT_AL}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_NX_RE" className="font-bold">
                    Next Review
                  </label>
                  <Calendar
                    id="ec_NX_RE"
                    name="ec_NX_RE"
                    value={moment(data.ec_NX_RE, "YYYYMMDD").isValid() ? moment(data.ec_NX_RE, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: { name: "ec_NX_RE",value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "",   }, }) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_LT_RE" className="font-bold">
                    Last Review
                  </label>
                   <Calendar
                    id="ec_LT_RE"
                    name="ec_LT_RE"
                    value={moment(data.ec_LT_RE, "YYYYMMDD").isValid() ? moment(data.ec_LT_RE, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "ec_LT_RE",  value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },  }) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          {/* tab2 */}
          {/* {showWPSTab && ( */}
          <TabPanel header="WPS">
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_WP_BS" className="font-bold">
                  Basic Salary
                  </label>
                    <InputText
                      id="ec_WP_BS"
                      name="ec_WP_BS"
                      value={ec_WP_BS}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_WP_HR" className="font-bold">
                  HRA
                  </label>
                  <InputText
                    id="ec_WP_HR"
                    name="ec_WP_HR"
                    value={ec_WP_HR}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_WP_TA" className="font-bold">
                  Travel Allowance
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ec_WP_TA"
                      name="ec_WP_TA"
                      value={ec_WP_TA}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_WP_OT" className="font-bold">
                  Other Allowance
                  </label>
                  <InputText
                    id="ec_WP_OT"
                    name="ec_WP_OT"
                    value={ec_WP_OT}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ec_WP_EF" className="font-bold">
                   Effective From
                  </label>
                  <Calendar
                    id="ec_WP_EF"
                    name="ec_WP_EF"
                    value={moment(data.ec_WP_EF, "YYYYMMDD").isValid() ? moment(data.ec_WP_EF, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "ec_WP_EF", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                  
                </div>
                </div>
            </div>
          </TabPanel>
          {/* )} */}
      </TabView>
      </div>    
      <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressType}
      />
    </Dialog>
  );
}
