import React, { useState ,useEffect} from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Container, Row, Col } from 'react-bootstrap';
import SupplierPopup from "./SupplierPopup";
import { Calendar } from "primereact/calendar";
import CustomerPopup from "../Popups/CustomerPopup";
export default function ARAPNettingDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData1,
  addARTable1Row,
  deleteARTable1Row,  
  addAPTable2Row,
  deleteAPTable2Row,
  SelectedemEMNOA,
  setSelectedemEMNOA,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNOS,
  setSelectedemEMNOS,
  SelectedemEMNOP,
  setSelectedemEMNOP,
  ndUkNo,
  sqQfNm,
  nd_NT_TY,
  nd_NT_CO,
  ARTableData1,
  setARTableData1,
  APTableData2,
  setAPTableData2,
}) {
  const { 
  nd_NT_ID,
  nd_OP_AM,
  nd_TR_CR,
  nd_OP_FR,
  nd_CU_NO,
  nd_GL_DT,
  ap_AMT,
  ar_AMT,
  net_AMT,
  total_AR_AMT,
  total_AP_AMT,
  total_NET_AMT,
  } = data;
  const isUpdateMode = Boolean(ndUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
// select question
const token = localStorage.getItem("token");
const company = localStorage.getItem("company");
const domain = localStorage.getItem("domain");

const onDateChange = (name, index, value) => {
  if (value && value.target) {
    const selectedDate = value.target.value;
    const formattedDate = moment(selectedDate).format("YYYYMMDD");
    const formattedDate1 = moment(selectedDate).format("DD/MM/YYYY");
    onchangeFormData(name, formattedDate,index);
  } else if (value){
    const formattedDate = moment(value).format("YYYYMMDD");
    const formattedDate1 = moment(value).format("DD/MM/YYYY");
    onchangeFormData(name, formattedDate, index);
  }
};

const [nd_TR_CROptions, setnd_TR_CROptions] = useState([]);
// DROPDOWN
const url = `${domain}/api/ClassCode/All`;
const parameter1 = "FO/CR";
const finalUrl1 = `${url}/${parameter1}`;
useEffect(() => {
  fetchDropdownOptions1();
}, []);
const fetchDropdownOptions1 = async () => {
  try {
    const response = await axios.get(finalUrl1, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setnd_TR_CROptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

const [selectedCheckbox, setSelectedCheckbox] = useState(null);
const [showSearchIcon, setShowSearchIcon] = useState("");
const [SelectedRowIndex, setSelectedRowIndex] = useState("");
const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
const openCustomerPopup = () => {
  setCustomerPopupVisible(true);
};
const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
const openSupplierPopup = (e) => {
  setSupplierPopupVisible(true);
  setSelectedRowIndex(e);
};
// const [ARTableData1, setARTableData1] = useState([]); 
// const [APTableData2, setAPTableData2] = useState([]); 

const handleDataSelect1 = async(data) => {
  setSelectedemEMNOA(data.ab_AD_NM);
  setSelectedemEMNO(data.abAdNo);
  onchangeFormData("nd_CU_NO", data.abAdNo);
  onchangeFormData("address_NAME", data.ab_AD_NM);  

  setSelectedemEMNOS(data.abAdNo);
  setSelectedemEMNOP(data.ab_AD_NM);
  onchangeFormData("nd_SU_NO", data.abAdNo);
  onchangeFormData("address_NAMES", data.ab_AD_NM);
  const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
  const requestData={
      "cu_CU_NO": data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        onchangeDropDownFormData1("nd_TR_CR", dataCuMt.cu_CR_CD);
      } else {
        console.error("Invalid API response:", data);
      }
      await ARDetails(dataCuMt.cu_CR_CD);
      await APDetails(dataCuMt.cu_CR_CD);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  setCustomerPopupVisible(false);
  setShowSearchIcon(false);
};

const handleDataSelect = async (data) => {
  setSelectedemEMNOS(data.abAdNo);
  setSelectedemEMNOP(data.ab_AD_NM);
  onchangeFormData("nd_SU_NO", data.abAdNo);
  onchangeFormData("address_NAMES", data.ab_AD_NM);
  const Url = `${domain}/api/SupplierMaster/GetById`;
  const requestData={
    "su_SU_NO":data.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });  
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCuMt = response.data.Result;
      if (dataCuMt) {
        document.getElementById("nd_SU_NO").value = dataCuMt.su_SU_NO;
        
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setSupplierPopupVisible(false);
};
const ARDetails =async (currency) => {
  const Url = `${domain}/api/ARAPNetting/GetFetchARDetails`;
  const requestData={
    "nd_CU_NO": document.getElementById("nd_CU_NO").value,
    "nd_TR_CR": currency,
}
try {
  const response =await axios.post(Url, requestData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  });

  if (response.status !== 200) {
    throw new Error(`API request failed with status: ${response.status}`);
  }
  const dataLead = response.data.Result;

  if (dataLead && dataLead.length > 0) {
    setARTableData1(dataLead.map(item => ({
      nd_DO_NO: item.nd_DO_NO,
      nd_IN_DT: item.nd_IN_DT,
      nd_GL_DT: item.nd_GL_DT,
      nd_DO_CO: item.nd_DO_CO,
      address_NAME: item.address_NAME,
      nd_OP_FR: item.nd_OP_FR,
      nd_OP_AM: item.nd_OP_AM,
      nd_GR_AM: item.nd_GR_AM,
      nd_DU_DT: item.nd_DU_DT,
      nd_GR_FR: item.nd_GR_FR,
      nd_DO_PI: item.nd_DO_PI,
      nd_DO_TY: item.nd_DO_TY,
    })));


    } else {
      console.error("Invalid API response:", data );
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
 
};

const APDetails =async (currency) => {
  const Url = `${domain}/api/ARAPNetting/GetFetchAPDetails`;
  const requestData={
    "nd_SU_NO": document.getElementById("nd_SU_NO").value,
    "nd_TR_CR": currency,
}
try {
  const response =await axios.post(Url, requestData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  });

  if (response.status !== 200) {
    throw new Error(`API request failed with status: ${response.status}`);
  }
  const dataLead = response.data.Result;

  if (dataLead && dataLead.length > 0) {
    setAPTableData2(dataLead.map(item => ({
      nd_DO_NO: item.nd_DO_NO,
      nd_DO_TY: item.nd_DO_TY,
      nd_DO_CO: item.nd_DO_CO,
      nd_DO_PI: item.nd_DO_PI,
      nd_GL_DT: item.nd_GL_DT,
      nd_IN_DT: item.nd_IN_DT,
      nd_DU_DT: item.nd_DU_DT,
      nd_GR_AM: item.nd_GR_AM,
      nd_OP_AM: item.nd_OP_AM,
      nd_GR_FR: item.nd_GR_FR,
      nd_OP_FR: item.nd_OP_FR,
      nd_SU_NO: item.nd_SU_NO,
      address_NAMES: item.address_NAMES,
      nd_CU_PO: item.nd_CU_PO,
      nd_OR_NO: item.nd_OR_NO,
      nd_OR_TY: item.nd_OR_TY,
    })));


    } else {
      console.error("Invalid API response:", data );
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
 
};

const handleARAPGrandTotal =  async (SelectedRowIndex) => {
  const  Url = `${domain}/api/ARAPNetting/GetGrandTotalForAPNetting`
  const request = {
      "arNettingList":[
          {
            "nd_TR_CR"  : nd_TR_CR,
            "nd_OP_AM"  : document.getElementById(`nd_OP_AM_${SelectedRowIndex}`).value,
            "nd_OP_FR"  : document.getElementById(`nd_OP_FR_${SelectedRowIndex}`).value,
            "check_BOX" : SelectedRowIndex
          }
      ],
    "apNettingLists":[
          {
            "nd_TR_CR"  : nd_TR_CR,
            "nd_OP_AM"  : document.getElementById(`nd_OP_AM_${SelectedRowIndex}`).value,
            "nd_OP_FR"  : document.getElementById(`nd_OP_FR_${SelectedRowIndex}`).value,
            "check_BOX" : SelectedRowIndex
          }
      ]
  }
  try {
    const response =await axios.post(Url, request, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const datatax = response.data.Result;
    if (datatax ) {
      document.getElementById ("ap_AMT").value =  response.data.Result.ap_AMT;
      document.getElementById ("ar_AMT").value =  response.data.Result.ar_AMT; 
      document.getElementById ("net_AMT").value =  response.data.Result.net_AMT;             
      document.getElementById ("total_AR_AMT").value =  response.data.Result.total_AP_AMT;   
      document.getElementById ("total_AP_AMT").value =  response.data.Result.total_AR_AMT;
      document.getElementById ("total_NET_AMT").value =  response.data.Result.total_NET_AMT;      
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const [selectedCheckbox1, setSelectedCheckbox1] = useState(null);
const [selectedCheckbox2, setSelectedCheckbox2] = useState(null);

const handleCheckboxChange = (index, tableId) => {
  if (tableId === 1) {
    setSelectedCheckbox1(index);
  } else if (tableId === 2) {
    setSelectedCheckbox2(index);
  }
};

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={ndUkNo ? "Revise AR/AP Netting" : "Add AR/AP Netting"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
       <div className="formgrid grid" >
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="nd_NT_ID" className="font-bold">
           Netting ID
          </label>
          <InputText disabled
            id="nd_NT_ID"
            name="nd_NT_ID"
            defaultValue={nd_NT_ID}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="nd_NT_TY" className="font-bold">
           Type 
          </label>
          <InputText disabled
            id="nd_NT_TY"
            name="nd_NT_TY"
            value={nd_NT_TY}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="nd_NT_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="nd_NT_CO"
            name="nd_NT_CO"
            value={nd_NT_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="nd_GL_DT" className="font-bold">
          GL Date
            </label>
            <Calendar
          id="nd_GL_DT"
          name="nd_GL_DT"
          value={moment(nd_GL_DT, "YYYYMMDD").toDate()}
          onChange={(e) => onchangedata({ target: { name: "nd_GL_DT", value: moment(e.value).format("YYYYMMDD") } })}
          showIcon
          dateFormat="dd/mm/yy"
          />
          </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="nd_CU_NO" className="font-bold">
                  Customer<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText 
                    id="nd_CU_NO"
                    name="nd_CU_NO"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={SelectedemEMNO}
                  />
                   {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openCustomerPopup}
                    ></i>
                   )}
              </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="address_NAME" className="font-bold">
                  Name
                  </label>
                  <InputText disabled
                    id="address_NAME"
                    name="address_NAME"
                    value={SelectedemEMNOA}
                   readOnly
                  />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="nd_TR_CR" className="font-bold">
              Currency
            </label>
            <Dropdown
              id="nd_TR_CR"
              name="nd_TR_CR"
              value={nd_TR_CR}
              options={nd_TR_CROptions}
              onChange={(e) => onchangeDropDownFormData1("nd_TR_CR", e.value)}
              placeholder="Select Currency"
            />
            
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="nd_SU_NO" className="font-bold">
                  Supplier<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText 
                    id="nd_SU_NO"
                    name="nd_SU_NO"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={SelectedemEMNOS}
                  />
                   {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openSupplierPopup}
                    ></i>
                   )}
              </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="address_NAMES" className="font-bold">
                  Name
                  </label>
                  <InputText disabled
                    id="address_NAMES"
                    name="address_NAMES"
                    value={SelectedemEMNOP}
                   readOnly
                  />
            </div>
            
      </div>
      </div>
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
      >
         <span>AR Details</span>
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addARTable1Row}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteARTable1Row}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="ARTable1">
          <thead>
            <tr>
              <th style={{display:"none"}}>UK NO</th>
              <th></th>
              <th>Doc No</th>
              <th>Type</th>
              <th>Company </th>
              <th>Pay Item</th>
              <th>GL Date</th>
              <th>Invoice Date</th>
              <th>Due Date</th>
              <th>Gross Amount</th>
              <th>Open Amount</th>
              <th>Fore.Gross Amount</th>
              <th>Fore.Open Amount</th>
              <th>Customer No </th>
              <th>Name</th>
              <th>Customer Po </th>
              <th>SO</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
          {ARTableData1.map((row, index) => (
              <tr key={index}>
                <td style={{display:"none"}}>
                  <InputText style={{width:"90px"}}
                    id={`ndUkNo_${index}`}
                    name={`ndUkNo_${index}`}
                    defaultValue={row.ndUkNo}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <input
                  type="radio"
                  id={`radio_${index}`}
                  name={`radio_${index}`}
                  checked={selectedCheckbox1 === index} 
                  onChange={() => handleCheckboxChange(index, 1)} 
                  onClick={() => handleARAPGrandTotal(index)} 
                  />
                    </td>
                <td>
                  <InputText  disabled style={{width:"90px"}}
                    id={`nd_DO_NO_${index}`}
                    name={`nd_DO_NO_${index}`}
                    defaultValue={row.nd_DO_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_DO_TY_${index}`}
                    name={`nd_DO_TY_${index}`}
                    defaultValue={row.nd_DO_TY}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_DO_CO_${index}`}
                    name={`nd_DO_CO_${index}`}
                    defaultValue={row.nd_DO_CO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_DO_PI_${index}`}
                    name={`nd_DO_PI_${index}`}
                    defaultValue={row.nd_DO_PI}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                <Calendar disabled style={{width:"150px"}}
                id={`nd_GL_DT_${index}`}
                name={`nd_GL_DT_${index}`}
                value={row.nd_GL_DT? moment(row.nd_GL_DT, "YYYYMMDD").toDate(): null}
                onChange={(e) => onDateChange({ target: { name: "nd_GL_DT", value: moment(e.value).format("YYYYMMDD") } })}
                showIcon
                dateFormat="dd/mm/yy"
                />
                </td>
                <td>
                <Calendar disabled style={{width:"150px"}}
                id={`nd_IN_DT_${index}`}
                name={`nd_IN_DT_${index}`}
                value={row.nd_IN_DT? moment(row.nd_IN_DT, "YYYYMMDD").toDate(): null}
                onChange={(e) => onDateChange({ target: { name: "nd_IN_DT", value: moment(e.value).format("YYYYMMDD") } })}
                showIcon
                dateFormat="dd/mm/yy"
                />
                </td>
                <td>
                <Calendar disabled style={{width:"150px"}}
                id={`nd_DU_DT_${index}`}
                name={`nd_DU_DT_${index}`}
                value={row.nd_DU_DT? moment(row.nd_DU_DT, "YYYYMMDD").toDate(): null}
                onChange={(e) => onDateChange({ target: { name: "nd_DU_DT", value: moment(e.value).format("YYYYMMDD") } })}
                showIcon
                dateFormat="dd/mm/yy"
                />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_GR_AM_${index}`}
                    name={`nd_GR_AM_${index}`}
                    defaultValue={row.nd_GR_AM}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_OP_AM_${index}`}
                    name={`nd_OP_AM_${index}`}
                    value={row.nd_OP_AM}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_GR_FR_${index}`}
                    name={`nd_GR_FR_${index}`}
                    defaultValue={row.nd_GR_FR}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_OP_FR_${index}`}
                    name={`nd_OP_FR_${index}`}
                    value={row.nd_OP_FR}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_CU_NO_${index}`}
                    name={`nd_CU_NO_${index}`}
                    defaultValue={row.nd_CU_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`address_NAME_${index}`}
                    name={`address_NAME_${index}`}
                    defaultValue={row.address_NAME}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_CU_PO_${index}`}
                    name={`nd_CU_PO_${index}`}
                    defaultValue={row.nd_CU_PO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_OR_NO_${index}`}
                    name={`nd_OR_NO_${index}`}
                    defaultValue={row.nd_OR_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_OR_TY_${index}`}
                    name={`nd_OR_TY_${index}`}
                    defaultValue={row.nd_OR_TY}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
      >
        <span>AP Details</span>
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addAPTable2Row}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteAPTable2Row}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="APTable2">
          <thead>
            <tr>
              <th style={{display:"none"}}>UK_NO</th>
              <th></th>
              <th>Doc No</th>
              <th>Type</th>
              <th>Company </th>
              <th>Pay Item</th>
              <th>GL Date</th>
              <th>Invoice Date</th>
              <th>Due Date</th>
              <th>Gross Amount</th>
              <th>Open Amount</th>
              <th>Fore.Gross Amount</th>
              <th>Fore.Open Amount</th>
              <th>Supplier No </th>
              <th>Name</th>
              <th>Customer Po </th>
              <th>SO</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
          {APTableData2.map((row, index) => (
              <tr key={index}>
                <td style={{display:"none"}}>
                  <InputText style={{width:"90px"}}
                    id={`ndUkNo_${index}`}
                    name={`ndUkNo_${index}`}
                    defaultValue={row.ndUkNo}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <input
                  type="radio"
                  id={`radio1_${index}`}
                  name={`radio1_${index}`}
                  checked={selectedCheckbox2 === index} 
                  onChange={() => handleCheckboxChange(index, 2)} 
                  onClick={() => handleARAPGrandTotal(index)} 
                  />
                  </td>
                <td>
                  <InputText  disabled style={{width:"90px"}}
                    id={`nd_DO_NO_${index}`}
                    name={`nd_DO_NO_${index}`}
                    defaultValue={row.nd_DO_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_DO_TY_${index}`}
                    name={`nd_DO_TY_${index}`}
                    defaultValue={row.nd_DO_TY}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_DO_CO_${index}`}
                    name={`nd_DO_CO_${index}`}
                    defaultValue={row.nd_DO_CO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_DO_PI_${index}`}
                    name={`nd_DO_PI_${index}`}
                    defaultValue={row.nd_DO_PI}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                <Calendar disabled style={{width:"150px"}}
                id={`nd_GL_DT_${index}`}
                name={`nd_GL_DT_${index}`}
                value={row.nd_GL_DT? moment(row.nd_GL_DT, "YYYYMMDD").toDate(): null}
                onChange={(e) => onDateChange({ target: { name: "nd_GL_DT", value: moment(e.value).format("YYYYMMDD") } })}
                showIcon
                dateFormat="dd/mm/yy"
                />
                </td>
                <td>
                <Calendar disabled style={{width:"150px"}}
                id={`nd_IN_DT_${index}`}
                name={`nd_IN_DT_${index}`}
                value={row.nd_IN_DT? moment(row.nd_IN_DT, "YYYYMMDD").toDate(): null}
                onChange={(e) => onDateChange({ target: { name: "nd_IN_DT", value: moment(e.value).format("YYYYMMDD") } })}
                showIcon
                dateFormat="dd/mm/yy"
                />
                </td>
                <td>
                <Calendar disabled style={{width:"150px"}}
                id={`nd_DU_DT_${index}`}
                name={`nd_DU_DT_${index}`}
                value={row.nd_DU_DT? moment(row.nd_DU_DT, "YYYYMMDD").toDate(): null}
                onChange={(e) => onDateChange({ target: { name: "nd_DU_DT", value: moment(e.value).format("YYYYMMDD") } })}
                showIcon
                dateFormat="dd/mm/yy"
                />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_GR_AM_${index}`}
                    name={`nd_GR_AM_${index}`}
                    defaultValue={row.nd_GR_AM}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_OP_AM_${index}`}
                    name={`nd_OP_AM_${index}`}
                    defaultValue={row.nd_OP_AM}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_GR_FR_${index}`}
                    name={`nd_GR_FR_${index}`}
                    defaultValue={row.nd_GR_FR}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                <InputText disabled style={{width:"90px"}}
                    id={`nd_OP_FR_${index}`}
                    name={`nd_OP_FR_${index}`}
                    value={row.nd_OP_FR}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_SU_NO_${index}`}
                    name={`nd_SU_NO_${index}`}
                    defaultValue={row.nd_SU_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`address_NAMES_${index}`}
                    name={`address_NAMES_${index}`}
                    defaultValue={row.address_NAMES}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_CU_PO_${index}`}
                    name={`nd_CU_PO_${index}`}
                    defaultValue={row.nd_CU_PO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_OR_NO_${index}`}
                    name={`nd_OR_NO_${index}`}
                    defaultValue={row.nd_OR_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
                <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`nd_OR_TY_${index}`}
                    name={`nd_OR_TY_${index}`}
                    defaultValue={row.nd_OR_TY}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      {/* footer */}
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop:"2%",
        }}
        >
    <Container>
        <Row>
          <Col md={3}>
          <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                
          </label>
          </Col>
          <Col md={3}>
              <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
               AR Total
              </label>
          </Col>
          <Col md={3}>
                <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                AP Total
                </label>
          </Col>
          <Col md={3}>
                <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                  Net
                </label>
          </Col>
        </Row>
        {/* 2nd */}
        <Row style={{marginTop:"2%"}} >
          <Col md={3}>
          <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
               Selected Records
          </label>
          </Col>
          <Col md={3}>
          <InputText disabled
                id="ar_AMT"
                name="ar_AMT"
                value={ar_AMT}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={3}>
          <InputText disabled
                id="ap_AMT"
                name="ap_AMT"
                value={ap_AMT}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={3}>
          <InputText disabled
                id="net_AMT"
                name="net_AMT"
                value={net_AMT}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
        </Row>
        {/* 3rd */}
        <Row style={{marginTop:"2%"}}>
          <Col md={3}>
          <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
         All the Records
          </label>
          </Col>
          <Col md={3}>
          <InputText disabled
                id="total_AR_AMT"
                name="total_AR_AMT"
                value={total_AR_AMT}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={3}>
          <InputText disabled
                id="total_AP_AMT"
                name="total_AP_AMT"
                value={total_AP_AMT}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={3}>
          <InputText disabled
                id="total_NET_AMT"
                name="total_NET_AMT"
                value={total_NET_AMT}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
        </Row>
        
      </Container>
      </div>

      <CustomerPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
        <SupplierPopup
        visible={SupplierPopupVisible}
        onClose={() => setSupplierPopupVisible(false)}
        formData={data}
        onDataSelect={handleDataSelect}
      />
    </Dialog>
  );
}
