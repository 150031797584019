import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  gi_UK_NO:"",
  giIdNo:"",
  gi_CO_NO:"",
  gi_TO_DT:"",
  gi_ST_TM:"",
  gi_EN_TM:"",
  gi_VE_SP:"",
  gi_VE_ST: "",
};

const initialFilters = {
  giIdNo: { value: "", operation: "" },
  gi_CO_NO: { value: "", operation: "" },
  gi_TO_DT: { value: "", operation: "" },
  gi_ST_TM: { value: "", operation: "" },
  gi_EN_TM: { value: "", operation: "" },
  gi_VE_SP: { value: "", operation: "" },
  gi_VE_ST: { value: "", operation: "" },
};

const GPSInterfaceMain = () => {
  // buttonstyles
const {formatDate, formatTime,buttonStyles, addButtonStyles,
  copyButtonStyles, checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,rightalined,
 } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  
  
  useEffect(() => {
    handleSearch();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/GPSInterface/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["giIdNo"] ? filters["giIdNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "giIdNo",
                        e.target.value,
                        filters["giIdNo"]
                          ? filters["giIdNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gi_CO_NO"] ? filters["gi_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gi_CO_NO",
                        e.target.value,
                        filters["gi_CO_NO"]
                          ? filters["gi_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["gi_TO_DT"] ? filters["gi_TO_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gi_TO_DT",
                        e.target.value,
                        filters["gi_TO_DT"]
                          ? filters["gi_TO_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>         
                <td>
                  <input
                    type="text"
                    value={filters["gi_ST_TM"] ? filters["gi_ST_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gi_ST_TM",
                        e.target.value,
                        filters["gi_ST_TM"]
                          ? filters["gi_ST_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gi_EN_TM"] ? filters["gi_EN_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gi_EN_TM",
                        e.target.value,
                        filters["gi_EN_TM"]
                          ? filters["gi_EN_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
               
                <td>
                  <input
                    type="text"
                    value={filters["gi_VE_SP"] ? filters["gi_VE_SP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gi_VE_SP",
                        e.target.value,
                        filters["gi_VE_SP"]
                          ? filters["gi_VE_SP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gi_VE_ST"] ? filters["gi_VE_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gi_VE_ST",
                        e.target.value,
                        filters["gi_VE_ST"]
                          ? filters["gi_VE_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>            
                <th style={tableHeaderStyle}>Vehicle ID</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Date</th>
                <th style={tableHeaderStyle}>Latitude</th>
                <th style={tableHeaderStyle}>Longitude</th>
                <th style={tableHeaderStyle}>Speed</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.gi_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.gi_UK_NO}
                        checked={selectedRows.includes(rowData.gi_UK_NO)}
                        onChange={() => handleRowSelect(rowData.gi_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.giIdNo}</td>
                    <td style={tableCellStyle}>{rowData.gi_CO_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.gi_TO_DT)}</td>
                    <td style={tableCellStyle}>{formatTime(rowData.gi_ST_TM)}</td>
                    <td style={tableCellStyle}>{formatTime(rowData.gi_EN_TM)}</td> 
                    <td style={tableCellStyle}>{rowData.gi_VE_SP}</td>
                    <td style={tableCellStyle}>{rowData.gi_VE_ST}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
     {/* pagination */}
     <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      
    </div>
  );
};

export default GPSInterfaceMain;
