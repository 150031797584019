import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css'
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import axios from "axios";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
export default function FinalSettlementDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  SelectedemEMNO,
  setSelectedemEMNO,
  fs_EM_DS,
  setfs_EM_DS,
  handleConfirm
}) {
  const { 
  fs_CN_BK,
  fs_BA_SA,
  fs_PY_OT,
  fs_DY_GR,
  fs_LS_OT,
  fs_VS_EP,
  fs_VE_FN, 
  fs_AB_DY,
  fs_PR_SA,
  fs_GR_DY,
  fsUkNo,
  fs_EM_NO,
  fs_MN_SA,
  fs_HO_RA,
  fs_LV_SA,
  fs_CU_DT, 
  fs_PS_NO,
  fs_NT_PR,
  fs_JN_DT,
  fs_LO_AM,
  fs_CU_SA,
  fs_LJ_DT,
  fs_EM_DY,
  fs_AC_MO, 
  fs_GR_AM,
  fs_TO_PY,
  fs_AL_WN,
  fs_SA_ED,
  fs_OT_HR,
  fs_TO_SA,
  fs_AD_RS,
  fs_TO_LS,
  fs_DE_RS,
  fs_LS_CO,
  fs_LW_DT,
  fs_NT_PD,
  fs_NT_PY,
  fs_ME_FE,
  fs_TO_AB,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();

  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");   
  const [addressType, setaddressType] = useState("E");
  // dropdowns
  const [fs_EM_DSOptions, setfs_EM_DSOptions] = useState([]);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const openEmployeePopup = () => {
    setEmployeePopupVisible(true);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("fs_EM_NO", data.abAdNo);
    const  Url = `${domain}/api/EmployeeMaster/GetByEmployee`
    const requestData = {
      "emEmNo": data.abAdNo
    };
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data.employeeDetails;
      if(data){
        onchangeDropDownFormData1("fs_EM_DS", data.em_EM_DS);
        const input = {
          "fs_EM_NO": data.emEmNo
        }
        employeeDetails(input);
        GetTotal(data.emEmNo);
      }
      else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
    GetTotal(data.abAdNo);
  };
  const employeeDetails = async (input) => {
    try {
      const response =await axios.post(`${domain}/api/FinalSettlement/GetProcessFinalSettlement`, input, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
          const dataeMmt = response.data.Result;
          if (dataeMmt) {
            // onchangeFormData("fs_PS_NO", data.fs_PS_NO);
            document.getElementById("fs_PS_NO").value = response.data.Result.fs_PS_NO;
            document.getElementById("fs_AB_DY").value = response.data.Result.fs_AB_DY;
            document.getElementById("fs_JN_DT").value = response.data.Result.fs_JN_DT;
            document.getElementById("fs_EM_DY").value = response.data.Result.fs_EM_DY;
            document.getElementById("fs_LJ_DT").value = response.data.Result.fs_LJ_DT;
            document.getElementById("fs_BA_SA").value = response.data.Result.fs_BA_SA;
            document.getElementById("fs_HO_RA").value = response.data.Result.fs_HO_RA;
            document.getElementById("fs_AL_WN").value = response.data.Result.fs_AL_WN;
            document.getElementById("fs_TO_SA").value = response.data.Result.fs_TO_SA;
            document.getElementById("fs_MN_SA").value = response.data.Result.fs_MN_SA;
            document.getElementById("fs_TO_AB").value = response.data.Result.fs_TO_AB;
          }else {
          console.error("Invalid API response:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };
  const url = `${domain}/api/ClassCode/All`;
  // DESIGNATION
  const parameter1 = "DE/SG";
  const finalUrl1 = `${url}/${parameter1}`;

  useEffect(() => {
  fetchDropdownOptions1();

  }, []);
  // 1st fetchdropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setfs_EM_DSOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const GetTotal = async (fs_EM_NO) => {
    const request = {
      "fs_EM_NO" : fs_EM_NO,
    }
    try {
      const response =await axios.post(`${domain}/api/FinalSettlement/overtime`, request, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
          const dataTotal = response.data.Result;
          if (dataTotal) {
            onchangeFormData("fs_OT_HR", dataTotal.Amount);
            onchangeFormData("fs_NT_PY", dataTotal.Net);
          }else {
          console.error("Invalid API response:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);

        HolidayOTAmount(fs_EM_NO);
      }
  };
  //Holiday OT Amount
  const HolidayOTAmount = async (ls_EM_NO) => {
    const request = {
      "fs_EM_NO" : ls_EM_NO,
    }
    try {
      const response =await axios.post(`${domain}/api/FinalSettlement/GetChangeOfCurrHolOT`, request, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
          const dataTotal = response.data.Result;
          if (dataTotal) {
            onchangeFormData("fs_HO_RA", dataTotal.Amount);
          }else {
          console.error("Invalid API response:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };
  const GetNetpayable = async () => {
    const request = {
      "addTot" : document.getElementById("fs_TO_PY").value,
      "deducTot" : document.getElementById("fs_TO_LS").value,
    }
    try {
      const response =await axios.post(`${domain}/api/FinalSettlement/GetCalculate`, request, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
          const dataTotal = response.data.Result;
          if (dataTotal) {
            document.getElementById("fs_NT_PY").value = dataTotal.NetPayable;
          }else {
          console.error("Invalid API response:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };
  const [activeTab, setActiveTab] = useState(0);
  const isUpdateMode = Boolean(fsUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{fsUkNo ? "Revise Final Settlement" : "Add Final Settlement"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
         {isUpdateMode && (
        <div style={{ display: 'flex',width:"5%" }}>
        <Button 
          style={{width:"100%"}}
          icon="pi pi-check-circle" 
          onClick={handleConfirm} 
          tooltip={"Confirm"}>
        </Button>
        </div>
        )}
      </div>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
    <div style={{ display: 'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end', width: '100%' }}>
     <div style={{ display: 'flex',width:"10%" }}>
     <Button 
       style={{width:"100%"}}
       icon="pi pi-times" 
       variant="outlined" 
       onClick={handleClose}
       tooltip="Cancel"
     >
     </Button>
     <Button 
       style={{width:"100%"}}
       icon="pi pi-check" 
       onClick={handleFormSubmit} 
       tooltip={isUpdateMode ? "Update" : "Submit"}>
     </Button>
     </div>
     {isUpdateMode && (
     <div style={{ display: 'flex',width:"5%" }}>
     <Button 
       style={{width:"100%"}}
       icon="pi pi-check-circle" 
       onClick={handleConfirm} 
       tooltip={"Confirm"}>
     </Button>
     </div>
    )}
 </div>
    </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        {/* tabs start */}

        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="Basic Details">
            {/* tab1 general */}
            <div style={inboxstyle} >
              <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_EM_NO" className="font-bold">
                  Employee No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedemEMNO}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openEmployeePopup}
                    ></i>
                    )}
                  </div>
                  <div style={{ display: "none" }} >
                    <InputText
                      id="fs_EM_NO"
                      name="fs_EM_NO"
                      value={fs_EM_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_PS_NO" className="font-bold">
                  Personal No
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="fs_PS_NO"
                      name="fs_PS_NO"
                      value={fs_PS_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_CU_DT" className="font-bold">
                    Date
                  </label>
                  <Calendar
                    id="fs_CU_DT"
                    name="fs_CU_DT"
                    value={moment(data.fs_CU_DT, "YYYYMMDD").isValid() ? moment(data.fs_CU_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "fs_CU_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_EM_DS" className="font-bold">
                    Designation
                  </label>
                   <Dropdown disabled
                    id="fs_EM_DS"
                    name="fs_EM_DS"
                    value={fs_EM_DS}
                    options={fs_EM_DSOptions}
                    onChange={(e) => onchangeDropDownFormData1("fs_EM_DS", e.value)}
                    placeholder="Select Designation"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_AB_DY" className="font-bold">
                    Absent Days
                  </label>
                  <InputText disabled
                    id="fs_AB_DY"
                    name="fs_AB_DY"
                    value={fs_AB_DY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_JN_DT" className="font-bold">
                  Joining Date
                  </label>
                  <Calendar disabled
                    id="fs_JN_DT"
                    name="fs_JN_DT"
                    value={moment(data.fs_JN_DT, "YYYYMMDD").isValid() ? moment(data.fs_JN_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "fs_JN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_EM_DY" className="font-bold">
                  Emergency Leave Days
                  </label>
                  <InputText disabled
                    id="fs_EM_DY"
                    name="fs_EM_DY"
                    value={fs_EM_DY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_LW_DT" className="font-bold">
                  Last Day Of Working
                  </label>
                  <Calendar 
                    id="fs_LW_DT"
                    name="fs_LW_DT"
                    value={moment(data.fs_LW_DT, "YYYYMMDD").isValid() ? moment(data.fs_LW_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "fs_LW_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },} ) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_TO_AB" className="font-bold">
                  Total Absent
                  </label>
                  <InputText disabled
                    id="fs_TO_AB"
                    name="fs_TO_AB"
                    value={fs_TO_AB}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_LJ_DT" className="font-bold">
                 Last Leave Joining
                  </label>
                  <Calendar disabled
                    id="fs_LJ_DT"
                    name="fs_LJ_DT"
                    value={moment(data.fs_LJ_DT, "YYYYMMDD").isValid() ? moment(data.fs_LJ_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "fs_LJ_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_BA_SA" className="font-bold">
                  Basic Salary
                  </label>
                  <InputText disabled
                    id="fs_BA_SA"
                    name="fs_BA_SA"
                    value={fs_BA_SA}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_GR_DY" className="font-bold">
                  Gratuity Eligible Days
                  </label>
                  <InputText disabled
                    id="fs_GR_DY"
                    name="fs_GR_DY"
                    value={fs_GR_DY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_HO_RA" className="font-bold">
                  HRA
                  </label>
                  <InputText disabled
                    id="fs_HO_RA"
                    name="fs_HO_RA"
                    value={fs_HO_RA}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_DY_GR" className="font-bold">
                  21 Days Gratuity Days
                  </label>
                  <InputText disabled
                    id="fs_DY_GR"
                    name="fs_DY_GR"
                    value={fs_DY_GR}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_AL_WN" className="font-bold">
                  Allowance
                  </label>
                  <InputText disabled
                    id="fs_AL_WN"
                    name="fs_AL_WN"
                    value={fs_AL_WN}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_SA_ED" className="font-bold">
                  Leave Salary Eligible Days
                  </label>
                  <InputText disabled
                    id="fs_SA_ED"
                    name="fs_SA_ED"
                    value={fs_SA_ED}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_MN_SA" className="font-bold">
                 Total Monthly Salary
                  </label>
                  <InputText disabled
                    id="fs_MN_SA"
                    name="fs_MN_SA"
                    value={fs_MN_SA}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_TO_SA" className="font-bold">
                  Total Salary
                  </label>
                  <InputText disabled
                    id="fs_TO_SA"
                    name="fs_TO_SA"
                    value={fs_TO_SA}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
            </div>              
          </TabPanel>
          {/* tab2 */}
          <TabPanel header="Payment Calculation">
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_GR_AM" className="font-bold">
                 Gratuity
                  </label>
                    <InputText disabled
                      id="fs_GR_AM"
                      name="fs_GR_AM"
                      value={fs_GR_AM}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_LV_SA" className="font-bold">
                  Leave Salary
                  </label>
                  <InputText disabled
                    id="fs_LV_SA"
                    name="fs_LV_SA"
                    value={fs_LV_SA}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_CU_SA" className="font-bold">
                  Current Month Salary
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText disabled
                      id="fs_CU_SA"
                      name="fs_CU_SA"
                      value={fs_CU_SA}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_PR_SA" className="font-bold">
                    Cash Salary Of Previous Month
                  </label>
                  <InputText
                    id="fs_PR_SA"
                    name="fs_PR_SA"
                    value={fs_PR_SA}
                    onChange={(e) =>  onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_OT_HR" className="font-bold">
                 Normal OT Amount
                 </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="fs_OT_HR"
                      name="fs_OT_HR"
                      value={fs_OT_HR}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_PY_OT" className="font-bold">
                  Any Other
                  </label>
                  <InputText
                    id="fs_PY_OT"
                    name="fs_PY_OT"
                    value={fs_PY_OT}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_HO_RA" className="font-bold">
                 Holiday OT Amount
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="fs_HO_RA"
                      name="fs_HO_RA"
                      value={fs_HO_RA}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_AD_RS" className="font-bold">
                  Reason
                  </label>
                  <InputText
                    id="fs_AD_RS"
                    name="fs_AD_RS"
                    value={fs_AD_RS}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_NT_PD" className="font-bold">
                  Notice Period Paid
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="fs_NT_PD"
                      name="fs_NT_PD"
                      value={fs_NT_PD}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_TO_PY" className="font-bold">
                  Total
                  </label>
                    <InputText 
                      id="fs_TO_PY"
                      name="fs_TO_PY"
                      value={fs_TO_PY}
                      onChange={(e) => {
                        onchangedata(e);
                        GetNetpayable();
                      }}
                    />
                </div>
              </div>
            </div>
            {/* 2nd div */}
            <div style={inboxstyle}>
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_AC_MO" className="font-bold">
                 Accomadation
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="fs_AC_MO"
                      name="fs_AC_MO"
                      value={fs_AC_MO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_ME_FE" className="font-bold">
                  Mess Fee
                  </label>
                  <InputText
                      id="fs_ME_FE"
                      name="fs_ME_FE"
                      defaultValue={fs_ME_FE}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_VE_FN" className="font-bold">
                  Fines (Vehicle/other)
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="fs_VE_FN"
                      name="fs_VE_FN"
                      value={fs_VE_FN}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_LO_AM" className="font-bold">
                  Outstanding  Loan Amount
                  </label>
                  <InputText
                    id="fs_LO_AM"
                    name="fs_LO_AM"
                    value={fs_LO_AM}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_NT_PR" className="font-bold">
                  Notice Period ShortFall
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="fs_NT_PR"
                      name="fs_NT_PR"
                      value={fs_NT_PR}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_CN_BK" className="font-bold">
                 Payable For Breaking Contract
                  </label>
                  <InputText
                    id="fs_CN_BK"
                    name="fs_CN_BK"
                    value={fs_CN_BK}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_LS_CO" className="font-bold">
                  Payable Due to other loss
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="fs_LS_CO"
                      name="fs_LS_CO"
                      value={fs_LS_CO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_VS_EP" className="font-bold">
                  Other Expenses If Any
                  </label>
                  <InputText
                    id="fs_VS_EP"
                    name="fs_VS_EP"
                    value={fs_VS_EP}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_LS_OT" className="font-bold">
                  Any Other
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="fs_LS_OT"
                      name="fs_LS_OT"
                      value={fs_LS_OT}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_DE_RS" className="font-bold">
                  Reason
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="fs_DE_RS"
                      name="fs_DE_RS"
                      value={fs_DE_RS}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_TO_LS" className="font-bold">
                 Total
                  </label>
                    <InputText 
                      id="fs_TO_LS"
                      name="fs_TO_LS"
                      value={fs_TO_LS}
                      onChange={(e) => {
                        onchangedata(e);
                        GetNetpayable();
                      }}
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fs_NT_PY" className="font-bold">
                  Net Payable
                  </label>
                    <InputText disabled
                      id="fs_NT_PY"
                      name="fs_NT_PY"
                      value={fs_NT_PY}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div>
              </div>
            </div>
          </TabPanel>          
        </TabView>
        {/* tabs end */}
      </div>
      <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}        
        addressType={addressType}
      />
    </Dialog>
  );
}
