import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function LeaveDetailsDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData4,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  ld_CO_NO,
  SelectedemEMNO,
  setSelectedemEMNO,
  addRow,
  deleteRow,
  LeaveDetailstable,
}) {
  const {  
    ld_EM_NO,
    ld_FR_DT,
    ld_TO_DT,
    ld_NO_DA,
    ld_LV_TY,
    ld_ST_CD,
    ld_RJ_DT,
    ld_AJ_DT,
    ld_AN_LV,
    ld_RE_MK,
    name,
    ldUkNo,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [ld_LV_TYOptions, setld_LV_TYOptions] = useState([]);
  const [ld_ST_CDOptions, setld_ST_CDOptions] = useState([]);
  const [ld_AN_LVOptions, setld_AN_LVOptions] = useState([]);
  
  // const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData2(name, value, index);
  };
  const handleFormDataChange2 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData3(name, value, index);
  };
  const handleFormDataChange3 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData4(name, value, index);
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const url = `${domain}/api/ClassCode/All`;
  // LEAVEtype
  const parameter1 = "LV/TY";
  const finalUrl1 = `${url}/${parameter1}`;
//STATUS
  const parameter2 = "LV/ST";
  const finalUrl2 = `${url}/${parameter2}`;
// ANNAUL LEAVE
  const parameter3 = "ME/PL";
  const finalUrl3 = `${url}/${parameter3}`;


  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    
  }, []);

  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setld_LV_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setld_ST_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setld_AN_LVOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false); 
const [addressType, setaddressType] = useState("E");
const openEmployeePopup = (e) => {
  setEmployeePopupVisible(true);
  setSelectedRowIndex(e);
};
//  startsearchpopup
const handleDataSelect = (data) => {
  setSelectedemEMNO(data.ab_AD_NM);
  onchangeFormData(`ld_EM_NO_${SelectedRowIndex}`, data.abAdNo);
  onchangeFormData(`name_${SelectedRowIndex}`, data.ab_AD_NM);
  // onchangeFormData("name", data.ab_AD_NM, SelectedRowIndex);
  setEmployeePopupVisible(false);
  setShowSearchIcon(false);
};
const isUpdateMode = Boolean(ldUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{ldUkNo ? "Revise Leave Details" : "Add Leave Details"}</h5>
    <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
        tooltip={isUpdateMode ? "Update" : "Submit"}
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
          <table className="custom-table" id="LeaveDetailstable" style={{overflow:"auto"}}>
        <thead>
          <tr>
            <th style={{ display: "none" }} >UK_NO</th>
            <th style={{display:"none"}}>Employee </th>
            <th>Employee <span style={{ color: 'red', }}>*</span></th>
            <th>From Date</th>
            <th>To Date</th>
            <th>No of Days</th>
            <th>Leave Type</th>
            <th>Status</th>
            <th>Rejoining date</th>
            <th>Actual Rejoining</th>
            <th>Annual Leave</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
        {LeaveDetailstable.map((row, index) => (
          <tr key={index}>
                  <td style={{ display: "none" }} >
                    <InputText
                      id={`ldUkNo_${index}`}
                      name={`ldUkNo_${index}`}
                      defaultValue={row.ldUkNo}
                    />
                  </td>
                  <td style={{display:"none"}}> 
                  <InputText
                    id={`ld_EM_NO_${index}`}
                    name={`ld_EM_NO_${index}`}
                    onFocus={() => setShowSearchIcon(true)}
                    value={row.ld_EM_NO ? row.ld_EM_NO: data[`${"ld_EM_NO"}_${index}`]}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <div style={{ display: "flex",width:"150px"}}>
                    <InputText
                      id={`name_${index}`}
                      name={`name_${index}`}
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.name ? row.name : data[`${"name"}_${index}`]}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "7%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openEmployeePopup(index)}
                      ></i>
                        )}
                    </div>
                  </td>
                  <td>
                    <Calendar style={{width:"150px"}}
                      id={`ld_FR_DT_${index}`}
                      name={`ld_FR_DT_${index}`}
                      value={ row.ld_FR_DT ? moment(row.ld_FR_DT, "YYYYMMDD").toDate() : null }
                      onChange={(e) => onDateChange({ target: { name: "ld_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                      dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td>
                  <Calendar style={{width:"150px"}}
                    id={`ld_TO_DT_${index}`}
                    name={`ld_TO_DT_${index}`}
                    value={ row.ld_TO_DT ? moment(row.ld_TO_DT, "YYYYMMDD").toDate() : null }
                    onChange={(e) => onDateChange({ target: { name: "ld_TO_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td>
                  <InputText style={{width:"150px"}}
                    id={`ld_NO_DA_${index}`}
                    name={`ld_NO_DA_${index}`}
                    defaultValue={row.ld_NO_DA}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <Dropdown
                    id={`ld_LV_TY_${index}`}
                    name={`ld_LV_TY_${index}`}
                    value={data[`ld_LV_TY_${index}`] ? data[`ld_LV_TY_${index}`]:row.ld_LV_TY }
                    options={ld_LV_TYOptions}
                    onChange={(e) => handleFormDataChange1(`ld_LV_TY_${index}`, e.value)}
                    placeholder="Select Leave type"
                  />
                  </td>
                  <td>
                  <Dropdown
                    id={`ld_ST_CD_${index}`}
                    name={`ld_ST_CD_${index}`}
                    value={data[`ld_ST_CD_${index}`] ? data[`ld_ST_CD_${index}`]:row.ld_ST_CD }
                    options={ld_ST_CDOptions}
                    onChange={(e) => handleFormDataChange2(`ld_ST_CD_${index}`, e.value)}
                    placeholder="Select status"
                  />
                  </td>
                  <td>
                  <Calendar style={{width:"150px"}}
                      id={`ld_RJ_DT_${index}`}
                      name={`ld_RJ_DT_${index}`}
                      value={ row.ld_RJ_DT ? moment(row.ld_RJ_DT, "YYYYMMDD").toDate() : null }
                      onChange={(e) => onDateChange({ target: { name: "ld_RJ_DT", value: moment(e.value).format("YYYYMMDD") } })}
                      dateFormat="dd/mm/yy"
                    />
                  </td>
                  <td>
                  <Calendar style={{width:"150px"}}
                      id={`ld_AJ_DT_${index}`}
                      name={`ld_AJ_DT_${index}`}
                      value={ row.ld_AJ_DT ? moment(row.ld_AJ_DT, "YYYYMMDD").toDate() : null }
                      onChange={(e) => onDateChange({ target: { name: "ld_AJ_DT", value: moment(e.value).format("YYYYMMDD") } })}
                      dateFormat="dd/mm/yy"
                    />
                  </td>
                  <td>
                  <Dropdown
                    id={`ld_AN_LV_${index}`}
                    name={`ld_AN_LV_${index}`} 
                    value={data[`ld_AN_LV_${index}`] ? data[`ld_AN_LV_${index}`] : row.ld_AN_LV} 
                    options={[
                    { label: "Yes", value: "y" },
                    { label: "No", value: "n" },
                    ]}
                    onChange={(e) => handleFormDataChange3(`ld_AN_LV_${index}`, e.value)} 
                    placeholder="Select Annual Leave"
                  />
                  </td>
                  <td>
                  <InputText style={{width:"150px"}}
                    id={`ld_RE_MK_${index}`}
                    name={`ld_RE_MK_${index}`}
                    defaultValue={row.ld_RE_MK}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
            </tr>
            ))}
        </tbody>
          </table> 
        </div>  
      </div>
      <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect}
        addressType={addressType}
        formData={data}
      />
    </Dialog>
  );
}
