import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import Amenities from "./Amenities";
import { ChakraProvider, IconButton } from '@chakra-ui/react';
import { GiTennisRacket } from 'react-icons/gi';
import { GiMeditation } from 'react-icons/gi';
import { GiTwoCoins } from 'react-icons/gi';

export default function GeneralServices({ isOpen, handleClose, onchangedata }) {
  const [AmenitiesOpen, setAmenitiesOpen] = useState(false);

  const [amenityType, setAmenityType] = useState("");
  const handleCloseAmenities = () => {
    setAmenitiesOpen(false);
  };
  const handleOpenAmenity= (type) => {
    setAmenityType(type)
    setAmenitiesOpen(true);
  };

  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "8%" }}
        icon="pi pi-credit-card"
        onClick={"handleFormSubmit"}
        tooltip="Pay"
      />
    </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={isOpen}
      onHide={handleClose}
      style={{ width: "40rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Amenities"
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
      <ChakraProvider>
        {/* badminton */}
      <IconButton
        icon={<GiTennisRacket />}
        variant="outline"
        onClick={() => handleOpenAmenity("HC")}
        aria-label="Open Badminton"
      />
      {/* yoga room */}
      <IconButton
       icon={<GiMeditation />}
        variant="outline"
        onClick={() => handleOpenAmenity("YG")}
        aria-label="Open Yoga Room"
      />
      {/* billiards */}
      <IconButton
        // icon={<PoolCueIcon />}
        variant="outline"
        onClick={() => handleOpenAmenity("BL")}
        aria-label="Open Billiards"
      />
      {/* swimming poool */}
      <IconButton
        // icon={<GiSwimmingPool />} 
        variant="outline"
        onClick={() => handleOpenAmenity("SP")}
        aria-label="Open Swimming Pool"
      />
      {/* party hall */}
      <IconButton
        // icon={<MdPartyRoom />}
        variant="outline"
        onClick={() => handleOpenAmenity("PH")}
        aria-label="Open Party Hall"
      />
      {/* guest room */}
      <IconButton
       icon={<GiTwoCoins />}
        variant="outline"
        onClick={() => handleOpenAmenity("GR")}
        aria-label="Open Guest Room"
      />
    </ChakraProvider>
        </div>
        <Amenities 
          isOpen={AmenitiesOpen} 
          handleClose={handleCloseAmenities}
          AmenityType={amenityType}
        />
        
    </Dialog>
  );
}
