import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import axios from "axios";
import ConrtactPopup from "../Popups/ContractPopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ProjectMasterPopup from "views/admin/Popups/ProjectMasterPopup";
import { Calendar } from "primereact/calendar";
import ClassCode from "views/common/ClassCode";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import PayTerm from "views/common/PayTermDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function ContractMasterDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  CurrencyDropDown,
  onchangegldate, 
  CostCentreDropDown,
  ContractStatusDropDown,
  cm_CN_CO,
  cm_PR_TY,
  setcm_PR_TY,
  cm_CN_ST,
  cm_CO_CT,
  cm_CR_CD,
  cm_CN_TY,
  cm_BS_CR,
  cm_PY_TM,
  setcm_PY_TM,
  cm_HL_CD,
  setcm_HL_CD,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedProjectMaster,
  setSelectedProjectMaster,
  SelectedProjectMaster1,
  setSelectedProjectMaster1,
  setSelectedProjectDesc,
  SelectedProjectDesc,
  SelectedemEMNO1,
  setSelectedemEMNO1,
  cm_EX_RT,
  setcm_EX_RT,
  cm_PC_NO,
  setcm_PC_NO,
}) {
  const {
    cmUkNo,
    cm_ST_DT,
    cm_EN_DT,
    cm_CU_NO,
    cm_CU_RF,
    cm_AC_DT,
    cm_AS_DT,
    cm_RT_PE,
    cm_SC_RF,
    cm_PR_NO,
    cm_SC_NO,
    cm_CN_NO,
    cm_PA_PR,
    cm_PC_TY,
    cm_PC_CO,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle,TabBoxStyle,} = ButtonStyles();

  const [activeTab, setActiveTab] = useState(0);
  const [AddresstypeC, setAddresstypeC] = useState("C");
  // DROPDOWNS
  const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const { fetchPayTerm } = PayTerm();
  const [cm_PR_TYOptions, setcm_PR_TYOptions] = useState([]);
  const [cm_CN_STOptions, setcm_CN_STOptions] = useState([]);
  const [cm_CO_CTOptions, setcm_CO_CTOptions] = useState([]);
  const [cm_CR_CDOptions, setcm_CR_CDOptions] = useState([]);
  const [cm_PY_TMOptions, setcm_PY_TMOptions] = useState([]);
  const [cm_HL_CDOptions, setcm_HL_CDOptions] = useState([]);
  const ProjectTypeDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcm_PR_TY(value);
  };
  const PaymentTermDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcm_PY_TM(value);
  };
  const HoldCodeDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcm_HL_CD(value);
  };
  useEffect(() => {
    ProjectType();
    ContractStatus();
    CostCentre();
    Currency();
    PaymentTerm();
    HoldCode();
  }, []);

  const ProjectType = async () => {
    const CostOptions = await fetchClassCodeDS("PR/TY");
    setcm_PR_TYOptions(CostOptions);
  };
  const ContractStatus = async () => {
    const Options = await fetchClassCodeDS("PR/ST");
    setcm_CN_STOptions(Options);
  };
  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    setcm_CO_CTOptions(Options);
  };
  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setcm_CR_CDOptions(Options);
  };
  const PaymentTerm = async () => {
    const Options = await fetchPayTerm();
    setcm_PY_TMOptions(Options);
  };
  const HoldCode = async () => {
    const Options = await fetchClassCodeDS("FO/HD");
    setcm_HL_CDOptions(Options);
  };
  const [ContractPopupVisible, setContractPopupVisible] = useState(false);
  const openContractPopup = () => {
    setContractPopupVisible(true);
  };
  const handleDataSelect1 = (data) => {
    setcm_PC_NO(data.cm_CN_NO);
    // document.getElementById("cm_PC_NO").value =  data.cm_CN_NO;
    onchangeFormData("cm_PC_TY", data.cm_CN_TY);
    onchangeFormData("cm_PC_CO", data.cm_CN_CO);
    setContractPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [SiteAddressPopupVisible, setSiteAddressPopupVisible] = useState(false);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const openSiteAddressPopup = () => {
    setSiteAddressPopupVisible(true);
  };
  const handleSiteAddress = async (data) => {
    setSelectedemEMNO1(data.ab_AD_NM);
    onchangeFormData("cm_SC_NO", data.abAdNo);
    setSiteAddressPopupVisible(false);
    setShowSearchIcon(false);
  };
  // Advance Search
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("cm_CU_NO", data.abAdNo);
    const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
      const requestData={
      "cu_CU_NO":  data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        PaymentTermDropDown("cm_PY_TM", dataCuMt.cu_PT_TM);
        CurrencyDropDown(`cm_CR_CD`, dataCuMt.cu_CR_CD);
        const exData={
          "cr_TO_CR": document.getElementById("cm_BS_CR").value, //base curr
          "cr_FR_CR": dataCuMt.cu_CR_CD, //currency
          "cr_EF_DT": data.cm_ST_DT
        }
        exchangeRate (exData);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setCustomerPopupVisible(false);
  };
  function exchangeRate(exData) {
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("cm_EX_RT").value = dataExRt.cr_CO_MR;
          setcm_EX_RT(dataExRt.cr_CO_MR);
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  }; 
  // Advance Search
  const [ProjectMasterPopupVisible, setProjectMasterPopupVisible] = useState(false);
  const openProjectMasterPopup = (e) => {
    setProjectMasterPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleProjectMaster = async (data) => {
    setSelectedProjectMaster(data.pm_PR_DS);
    onchangeFormData("cm_PR_NO", data.pm_PR_NO);

    setSelectedProjectMaster1(data.proj_NAME);
    setSelectedProjectDesc(data.pm_PA_PR);
    onchangeFormData("cm_PA_PR", data.pm_PA_PR);
    onchangeFormData("proj_NAME1", data.proj_NAME);
    const Url = `${domain}/api/ProjectMaster/onChangeOfProjectNo`;
    const requestData={
    "pm_PR_NO":  data.pm_PR_NO
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCuMt = response.data.response;
    if (dataCuMt) {
      ProjectTypeDropDown("cm_PR_TY", dataCuMt.pm_PR_TY);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setProjectMasterPopupVisible(false);
  };
  const [ProjectMaster1PopupVisible, setProjectMaster1PopupVisible] = useState(false);
  const openProjectMaster1Popup = (e) => {
    setProjectMaster1PopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleProjectMaster1 = async (data) => {
    setSelectedProjectMaster1(data.proj_NAME);
    setSelectedProjectDesc(data.pm_PA_PR);
    onchangeFormData("cm_PA_PR", data.pm_PA_PR);
    onchangeFormData("proj_NAME1", data.proj_NAME);
    setProjectMaster1PopupVisible(false);
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "cm_ST_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const isUpdateMode = Boolean(cmUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{cmUkNo ? "Revise Contract Master" : "Add Contract Master"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
      {activeTab === 0 && (
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
      )}
       {activeTab === 0 && (
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
       )}
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle}>
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="General">
            {/* tab1 general */}
            <div style={inboxstyle} >
              <div className="formgrid grid">
                 <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cm_CN_NO" className="font-bold">
                  Contract No
                  </label>
                  <InputText disabled
                    id="cm_CN_NO"
                    name="cm_CN_NO"
                    value={cm_CN_NO}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cm_CN_TY" className="font-bold">
                  Type
                </label>
                <InputText disabled
                  id="cm_CN_TY"
                  name="cm_CN_TY"
                  value={cm_CN_TY}
                  onChange={(e) => onchangedata(e)}
                />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_CN_CO" className="font-bold">
              Company
              </label>
              <InputText disabled
                id="cm_CN_CO"
                name="cm_CN_CO"
                value={cm_CN_CO}
                onChange={(e) => onchangedata(e)}
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_PC_NO" className="font-bold">
                  Parent Contract  <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText
                    id="cm_PC_NO"
                    name="cm_PC_NO"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={cm_PC_NO}
                  />
                   {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openContractPopup}
                    ></i>
                   )}
              </div>
                </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cm_PC_TY" className="font-bold">
                Type
                  </label>
                  <InputText disabled
                    id="cm_PC_TY"
                    name="cm_PC_TY"
                    value={cm_PC_TY}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_PC_CO" className="font-bold">
              Company
              </label>
              <InputText disabled
                id="cm_PC_CO"
                name="cm_PC_CO"
                value={cm_PC_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>                
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_ST_DT" className="font-bold">
                Start Date
              </label>
              <Calendar
              id="cm_ST_DT"
              name="cm_ST_DT"
              value={moment(cm_ST_DT, "YYYYMMDD").toDate()}
              onChange={(e) => {
                handleDateChange(e);
                onchangegldate({ target: { name: "cm_ST_DT", value: moment(e.value).format("YYYYMMDD") } });
              }}
              dateFormat="dd/mm/yy"
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cm_EN_DT" className="font-bold">
                End Date
                </label>
                <Calendar
                  id="cm_EN_DT"
                  name="cm_EN_DT"
                  value={moment(cm_EN_DT, "YYYYMMDD").toDate()}
                  onChange={(e) => onchangedata({ target: { name: "cm_EN_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  dateFormat="dd/mm/yy"
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cm_CO_CT" className="font-bold">
                    Cost Centre
                  </label>
                  <Dropdown disabled
                    name="cm_CO_CT"
                    value={cm_CO_CT}
                    options={cm_CO_CTOptions}
                    onChange={(e) => CostCentreDropDown("cm_CO_CT", e.value)}
                    placeholder="Select CostCentre"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cm_CU_NO" className="font-bold">
                  Customer  <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText
                    id="ab_AD_NM"
                    name="ab_AD_NM"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedemEMNO}
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openCustomerPopup}
                  ></i>
                  )}
                </div>
                  <InputText style={{ display: "none" }}
                    id="cm_CU_NO"
                    name="cm_CU_NO"
                    defaultValue={cm_CU_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cm_PY_TM" className="font-bold">
                  Payment Term
                </label>
                <Dropdown
                  name="cm_PY_TM"
                  value={cm_PY_TM}
                  options={cm_PY_TMOptions}
                  onChange={(e) => PaymentTermDropDown("cm_PY_TM", e.value)}
                  placeholder="Select Payment Term"
                />
              </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cm_CN_ST" className="font-bold">
                   Contract Status
                  </label>
                  <Dropdown
                    name="cm_CN_ST"
                    value={cm_CN_ST}
                    options={cm_CN_STOptions}
                    onChange={(e) => ContractStatusDropDown("cm_CN_ST", e.value)}
                    placeholder="Select Contract Status"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cm_CR_CD" className="font-bold">
                 Currency
                  </label>
                  <Dropdown
                    name="cm_CR_CD"
                    value={cm_CR_CD}
                    options={cm_CR_CDOptions}
                    onChange={(e) =>CurrencyDropDown("cm_CR_CD", e.value)}
                    placeholder="Select Currency"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cm_EX_RT" className="font-bold">
                   Exchange Rate
                  </label>
                  <InputText disabled
                    id="cm_EX_RT"
                    name="cm_EX_RT"
                    defaultValue={cm_EX_RT}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cm_BS_CR" className="font-bold">
                    Base Currency
                  </label>
                  <InputText disabled
                    id="cm_BS_CR"
                    name="cm_BS_CR"
                    value={cm_BS_CR}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cm_PR_NO" className="font-bold">
                  Project No  <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText
                    id="proj_NAME"
                    name="proj_NAME"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedProjectMaster}
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openProjectMasterPopup}
                  ></i>
                  )}
                </div>
                <InputText style={{ display: "none" }}
                  id="cm_PR_NO"
                  name="cm_PR_NO"
                  value={cm_PR_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cm_PA_PR" className="font-bold">
              Parent Project<span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText disabled
                id="proj_NAME1"
                name="proj_NAME1"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedProjectMaster1}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openProjectMaster1Popup}
              ></i>
              )}
            </div>
              <InputText
                id="cm_PA_PR"
                name="cm_PA_PR"
                value={cm_PA_PR}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_PR_TY" className="font-bold">
                Project Type
              </label>
              <Dropdown disabled
                name="cm_PR_TY"
                value={cm_PR_TY}
                options={cm_PR_TYOptions}
                onChange={(e) => ProjectTypeDropDown("cm_PR_TY", e.value)}
                placeholder="Select  Project Type"
              />
            </div>
            <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_CU_RF" className="font-bold">
                Customer Ref#
              </label>
              <InputText 
              style={{ width:"500px" }}
                id="cm_CU_RF"
                name="cm_CU_RF"
                value={cm_CU_RF}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            </div>
            </div>
          </TabPanel>
          {/* tab2 */}
          <TabPanel header="Additional Details">
            <div style={inboxstyle} >
             <div className="formgrid grid">
             <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_SC_NO" className="font-bold">
                Site Address<span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="subName"
                  name="subName"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}                      
                  value={SelectedemEMNO1}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openSiteAddressPopup}
                ></i>
                )}
              </div>
                <InputText style={{ display: "none" }}
                  id="cm_SC_NO"
                  name="cm_SC_NO"
                  value={cm_SC_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
            </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cm_SC_RF" className="font-bold">
                  Sub Contractor Ref#
                </label>
                <InputText 
                  id="cm_SC_RF"
                  name="cm_SC_RF"
                  value={cm_SC_RF}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cm_RT_PE" className="font-bold">
                  Retainage %
                </label>
                <InputText 
                  id="cm_RT_PE"
                  name="cm_RT_PE"
                  value={cm_RT_PE}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cm_HL_CD" className="font-bold">
                  Hold Code
                  </label>
                  <Dropdown
                    name="cm_HL_CD"  
                    value={cm_HL_CD}
                    options={cm_HL_CDOptions}
                    onChange={(e) => HoldCodeDropDown("cm_HL_CD", e.value)}
                    placeholder="Select Hold Code"
                  />
                </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cm_AS_DT" className="font-bold">
                   Actual Start Date
                  </label>
                  <Calendar
                    id="cm_AS_DT"
                    name="cm_AS_DT"
                    value={moment(cm_AS_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "cm_AS_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cm_AC_DT" className="font-bold">
                   Actual Compl. Date
                  </label>
                  <Calendar
                    id="cm_AC_DT"
                    name="cm_AC_DT"
                    value={moment(cm_AC_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "cm_AC_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                
              </div>
              </div>
          </TabPanel>
      </TabView>
      </div>    
      <AddressBookPopup
        visible={SiteAddressPopupVisible}
        onClose={() => setSiteAddressPopupVisible(false)}
        onDataSelect={handleSiteAddress}
        formData={data}
        addressType={AddresstypeC}
      />

        <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={AddresstypeC}
      />

        <ProjectMasterPopup
        visible={ProjectMasterPopupVisible}
        onClose={() => setProjectMasterPopupVisible(false)}
        onDataSelect={handleProjectMaster}
        formData={data}
      />

        <ProjectMasterPopup
        visible={ProjectMaster1PopupVisible}
        onClose={() => setProjectMaster1PopupVisible(false)}
        onDataSelect={handleProjectMaster1}
        formData={data}
      />

      <ConrtactPopup
        visible={ContractPopupVisible}
        onClose={() => setContractPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />

    </Dialog>
  );
}
