import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import NotificationSetupDialog from "./NotificationSetupDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  nsUkNo:"",
  ns_EV_NT:"",
  ns_AD_NO:"",
  name:"",   
  ns_MS_AG:"",   
  ns_CO_NO:"",  
};

const initialFilters = {
  ns_EV_NT: { value: "", operation: "" },
  ns_AD_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },   
  ns_MS_AG: { value: "", operation: "" },   
  ns_CO_NO: { value: "", operation: "" },    
};

const WorkCentreMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,rightalined
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  
  const [ns_EV_NT, setns_EV_NT ] = useState("");

  

 
  

// AdvanceSearch
const [SelectedITNM, setSelectedITNM ] = useState("");
const [SelectedemEMNO, setSelectedemEMNO] = useState("");



  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [NotificationSetupTableData, setNotificationSetupTableData] = useState([{}]);



  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setNotificationSetupTableData([{}]);
    setSelectedITNM(null);
    setns_EV_NT(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/NotificationSetup/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const CashFlowData = response.data;
          const updatedCashFlowData = CashFlowData.NotificationSetupList.map((target) => {
            const UpdatedworkData = { ...target };
            delete UpdatedworkData.nsUkNo;
     
            return UpdatedworkData;
          });
          setNotificationSetupTableData(updatedCashFlowData);
          setFormData(CashFlowData.NotificationSetupList[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [dp_CO_NO , setdp_CO_NO] = useState("");

  const handleDefault = async () => {
    setdp_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/NotificationSetup/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/NotificationSetup/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const CashFlowData = response.data;
        setNotificationSetupTableData(CashFlowData.NotificationSetupList);
        setFormData(CashFlowData.NotificationSetupList[0]);
        // setdp_PO_IT(CashFlowData.NotificationSetup.dp_PO_IT);
        // setdp_DO_TY(CashFlowData.NotificationSetupList.dp_DO_TY);
        // setdp_OR_TY(CashFlowData.NotificationSetupList.dp_OR_TY);
        // setdp_GL_CD(CashFlowData.NotificationSetupList.dp_GL_CD);
        // setdp_CO_NM(CashFlowData.NotificationSetupList.dp_CO_NM);
        // setdp_TY_PE(CashFlowData.NotificationSetupList.dp_TY_PE);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("NotificationSetuptable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const NotificationSetupList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const details = {
        nsUkNo : document.getElementById("nsUkNo_" + i).value,
        ns_EV_NT : table.tBodies[0].rows[i].cells[2].children[0].children[1].children[0].value,
        ns_MS_AG : document.getElementById("ns_MS_AG_" + i).value,
    };
    NotificationSetupList.push(details);
  }
 requestBody = {
  NotificationSetupList,
  };
}
  const handleFormSubmit = () => {
    values ();
    if (FormData.nsUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/NotificationSetup/Update`;
      if (confirm) {
        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.dp_CO_NO = company;
      axios
        .post(`${domain}/api/NotificationSetup/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ns_EV_NT"] ? filters["ns_EV_NT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ns_EV_NT",
                        e.target.value,
                        filters["ns_EV_NT"]
                          ? filters["ns_EV_NT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ns_AD_NO"] ? filters["ns_AD_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ns_AD_NO",
                        e.target.value,
                        filters["ns_AD_NO"] ? filters["ns_AD_NO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ns_MS_AG"] ? filters["ns_MS_AG"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ns_MS_AG",
                        e.target.value,
                        filters["ns_MS_AG"] ? filters["ns_MS_AG"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ns_CO_NO"] ? filters["ns_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ns_CO_NO",
                        e.target.value,
                        filters["ns_CO_NO"]
                          ? filters["ns_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Event</th>
                <th style={tableHeaderStyle}>Address No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Message</th>
                <th style={tableHeaderStyle}>Company</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.nsUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.nsUkNo}
                        checked={selectedRows.includes(rowData.nsUkNo)}
                        onChange={() => handleRowSelect(rowData.nsUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ns_EV_NT}</td>
                    <td style={tableCellStyle}>{rowData.ns_AD_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>   
                    <td style={tableCellStyle}>{rowData.ns_MS_AG}</td>   
                    <td style={tableCellStyle}>{rowData.ns_CO_NO}</td>          
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <NotificationSetupDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        NotificationSetupTableData={NotificationSetupTableData}
        setNotificationSetupTableData={setNotificationSetupTableData}
        dp_CO_NO={dp_CO_NO}
        SelectedITNM={SelectedITNM}
        setSelectedITNM={setSelectedITNM}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO} 
        ns_EV_NT={ns_EV_NT}
        setns_EV_NT={setns_EV_NT}

      />
    </div>
  );
};

export default WorkCentreMain;
