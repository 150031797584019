import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import ClassCodePopup from "views/admin/Popups/ClassCodePopup";
export default function OrderGroupsDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  OrderGroupsTableData,
  setOrderGroupsTableData,
  ogOrGp,
  setogOrGp,
  og_CO_NO,
  SelectedType,
  setselectedType,
  SelectedDesc,
  setselectedDesc,
}) {
  const {
    ogUkNo,
    ogOrTy,
  } = data;


  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const [ogOrGpOptions, setogOrGpOptions] = useState([]);

  // BIDDING type
  const OrderGroupDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setogOrGp(value);
  };

  useEffect(() => {
    fetchDropdownBT();
  }, []);
  const fetchDropdownBT = async () => {
    const Options = await fetchClassCodeDS("OR/GP");
    setogOrGpOptions(Options);
  };
  const addRow = () => {
    setOrderGroupsTableData([...OrderGroupsTableData, {}]);
  };
  
  const deleteRow = () => {
    if (OrderGroupsTableData.length > 0) {
      const updatedData = [...OrderGroupsTableData];
      updatedData.pop();
      setOrderGroupsTableData(updatedData);
    }
  };

  const [TypeVisible, setTypeVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleType =async (data) => {
    setselectedType(data.cl_CL_CD)
    setselectedDesc(data.cl_CL_DS)
    onchangeFormData("ogOrTy", data.cl_CL_CD, SelectedRowIndex);
    onchangeFormData("og_OR_DS", data.cl_CL_DS, SelectedRowIndex);
    setTypeVisible(false);
    setShowSearchIcon(false);
  };
  const openTypePopup = (e) => {
    setTypeVisible(true);
    setSelectedRowIndex(e);
  };
const isUpdateMode = Boolean(ogUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );


  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={ogUkNo ? "Revise Order Groups" : "Add Order Groups"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
                <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ogOrGp" className="font-bold">
                  Order Group
                  </label>
                  <Dropdown
                  name="ogOrGp"
                  value={ogOrGp}
                  options={ogOrGpOptions}
                  onChange={(e) => OrderGroupDropdown("ogOrGp", e.value)}
                  placeholder="Select Order Group"
                />
                </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2" >
                  <label htmlFor="og_CO_NO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="og_CO_NO"
                    name="og_CO_NO"
                    value={og_CO_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                  </div>
                </div>
                </div>  
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
        >
      
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="OrderGroupstable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Order Type</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {OrderGroupsTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`ogUkNo_${index}`}
                    name={`ogUkNo_${index}`}
                    value={row.ogUkNo}
                  />
                </td>
                <td style={{ display: "flex" }}>
          <InputText 
          id={`ogOrTy_${index}`}
          name={`ogOrTy_${index}`}
          onChange={(e) => onchangeFormData(e)}
          onFocus={() => setShowSearchIcon(true)}
          value={row.ogOrTy ? row.ogOrTy: row[`${"ogOrTy"}_${index}`]}
          />
          {showSearchIcon && (
         <i
         className="pi pi-search"
         style={{
         color: "var(--primary-color)",
         fontSize: "119%",
         marginTop: "15%",
         cursor: "pointer",
        }}
        onClick={(e) => openTypePopup(index)}
        ></i>
        )}
      </td>
        <td>
        <InputText disabled
        id={`og_OR_DS_${index}`}
        name={`og_OR_DS_${index}`}
        value={row.og_OR_DS ? row.og_OR_DS : row[`${"og_OR_DS"}_${index}`]}
        readOnly
        />
        </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <ClassCodePopup
        visible={TypeVisible}
        onClose={() => setTypeVisible(false)}
        onDataSelect={handleType}
        formData={data}
      />
    </Dialog>
  );
}
