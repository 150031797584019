import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import LeasePopup from "../Popups/LeasePopup";
import TenantPopup from "./TenantPopup";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function LeaseInformationDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  // onchangedata,
  onchangeFormData,
  rb_LE_CO,
  onchangeDropDownFormData1,
  setSelectedLeaseNo,
  SelectedLeaseNo,
  setSelectedLeaseType,
  SelectedLeaseType,
  setSelectedLeaseCompany,
  SelectedLeaseCompany,
  SelectedliTwNt,
  setSelectedliTwNt,
}) {
  const { 
  rbUkNo,
  rb_TE_NT,
  rb_LE_NO,
  rb_LE_TY, 
  rb_IN_DT,
  rb_ST_DT,
  rb_EN_DT,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "li_ST_DT"  || name === "li_EN_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);
  
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };
  const url = `${domain}/api/ClassCode/All`;
  const parameter1 = "UN/ST";
  const finalUrl1 = `${url}/${parameter1}`;
  useEffect(() => {
    fetchDropdownOptions1();
  }, []);
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      // setOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const [LeaseNoPopupVisible, setLeaseNoPopupVisible] = useState(false);
  const openLeaseNoPopup = () => {
    setLeaseNoPopupVisible(true)
  };
  const handleDataSelect = (data) => {
    setSelectedLeaseNo(data.liLeNo);  
    setSelectedLeaseType(data.li_LE_TY);
    setSelectedLeaseCompany(data.li_LE_CO);
    onchangeFormData("rb_LE_NO", data.liLeNo);   
    onchangeFormData("rb_LE_TY", data.li_LE_TY);
    // onchangeFormData("rb_LE_CO", data.li_LE_CO);
    setLeaseNoPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [TenantPopupVisible, setTenantPopupVisible] = useState(false);
  const openTenantPopup = () => {
    setTenantPopupVisible(true);
  };
  const handleDataSelect1 =async (dataAb) => {
    setSelectedliTwNt(dataAb.ab_AD_NM);
    onchangeFormData("rb_TE_NT", dataAb.abAdNo);
    setTenantPopupVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(rbUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      {/* <h5>{ rbUkNo ? "Revise Property Information" : "Add  Property Information"}</h5> */}
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rb_ST_DT" className="font-bold">
                  Bill From
              </label>
              <Calendar
                id="rb_ST_DT"
                name="rb_ST_DT"
                value={moment(data.rb_ST_DT, "YYYYMMDD").isValid() ? moment(data.rb_ST_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "rb_ST_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rb_EN_DT" className="font-bold">
              Bill To
              </label>
              <Calendar
                  id="rb_EN_DT"
                  name="rb_EN_DT"
                  value={moment(data.rb_EN_DT, "YYYYMMDD").isValid() ? moment(data.rb_EN_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "rb_EN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="rb_IN_DT" className="font-bold">
                Invoice Date
                </label>
                <Calendar
                  id="rb_IN_DT"
                  name="rb_IN_DT"
                  value={moment(data.rb_IN_DT, "YYYYMMDD").isValid() ? moment(data.rb_IN_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "rb_IN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
            </div>  
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rb_LE_NO" className="font-bold">
              Lease Number <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{display:"flex"}}>
                <InputText
                    id="rb_LE_NO"
                    name="rb_LE_NO"
                    value={SelectedLeaseNo}
                    onFocus={() => setShowSearchIcon(true)}
                  />
                  {showSearchIcon && (
                    <i  className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "7%",
                      cursor: "pointer",
                    }}
                    onClick={(e) => openLeaseNoPopup(e)}
                  ></i>
                  )}
                </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="rb_LE_TY" className="font-bold">
                  Type
                </label>
                <InputText  disabled
                  id="rb_LE_TY"
                  name="rb_LE_TY"
                  value={SelectedLeaseType} 
                />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rb_LE_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="rb_LE_CO"
                name="rb_LE_CO"
                value={rb_LE_CO}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rb_TE_NT" className="font-bold">
                Tenant <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{display:"flex"}}>
            <InputText
              id="tenant_NAME"
              name="tenant_NAME"
              onChange={(e) => onchangedata(e)}
              onFocus={() => setShowSearchIcon(true)}
              value={SelectedliTwNt}
            />
              {showSearchIcon && (
            <i
              className="pi pi-search"
              style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "4%",
                cursor: "pointer",
              }}
              onClick={openTenantPopup}
            ></i>
              )}
              </div>
              <div style={{display:"none"}}>
                <InputText
                    id="rb_TE_NT"
                    name="rb_TE_NT"
                    value={rb_TE_NT}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
        <LeasePopup
          visible={LeaseNoPopupVisible}
          onClose={() => setLeaseNoPopupVisible(false)}
          onDataSelect={handleDataSelect}
          formData={data}
          leaseType={SelectedLeaseType}
        /> 
        <TenantPopup
          visible={TenantPopupVisible}
          onClose={() => setTenantPopupVisible(false)}
          onDataSelect={handleDataSelect1}
          formData={data}
        />
    </Dialog>
  );
}
