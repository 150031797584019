import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import BuildingInformationDialog from "./BuildingInformationDialog";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
const initialValue = {
  ar_DESC:"",
  bi_BL_NO: "",
  bi_PR_NO: "",
  pt_DESC: "",
  bu_DESC: "",
  bi_CO_NO: "",
  biUkNo: "",
  bi_TR_QT: "",
  bi_TR_UM: "",
  bi_ST_DT: "",
  bi_EN_DT: "",
  bi_PR_AR: "",
  bi_FA_AD: "",
  bi_RM_AD: "",
  bi_AD_DS: "",
  bi_TR_UM: "",
  bi_TX_RT: "",
  bi_TX_AR: "",
};
const initialFilters = {
  pt_DESC: { value: "", operation: "" },
  bu_DESC: { value: "", operation: "" },
  ar_DESC: { value: "", operation: "" },
  bi_TR_QT: { value: "", operation: "" },
  bi_CO_NO: { value: "", operation: "" },
  bi_TR_UM: { value: "", operation: "" },
};
const BuildingInformationMain = () => {
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [pt_DESC, setpt_DESC] = useState("");
  const [bi_TX_RT, setbi_TX_RT] = useState("");
  const [bi_PR_AR, setbi_PR_AR] = useState("");
  const [update, setupdate] = useState("");
  const [bi_BL_NO, setbi_BL_NO] = useState("");
  const [bi_PR_NO, setbi_PR_NO] = useState("");
  const [bi_TX_AR, setbi_TX_AR] = useState("");
  const [bi_TR_UM, setbi_TR_UM] = useState("");
  const [bi_FA_AD, setbi_FA_AD] = useState("");
  const [bi_ST_DT, setbi_ST_DT] = useState("");
  const [BuildingInformationtable, setBuildingInformationtable] = useState([{}]);
  const [selectedFacilityaddress, setselectedFacilityaddress] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setBuildingInformationtable([{}]);
    setSelectedemEMNO(null);
    setbi_TX_RT(null);
    setbi_PR_AR(null);
    setbi_PR_NO(null);
    setbi_BL_NO(null);
    setbi_TX_AR(null);
    setbi_TR_UM(null);
    setpt_DESC(null);
    setupdate(null);
    setOpen(true);
  };
  const handleClose = () => {
    setBuildingInformationtable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setbi_TX_RT(null);
    setbi_PR_AR(null);
    setbi_BL_NO(null);
    setbi_PR_NO(null);
    setbi_TX_AR(null);
    setbi_TR_UM(null);
    setupdate(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }

    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const addRow = () => {
    var startdate=null;
    var enddate=null;
    if(BuildingInformationtable[0]!=undefined){
       startdate=BuildingInformationtable[0]['bi_ST_DT']==undefined?null:BuildingInformationtable[0]['bi_ST_DT'];
       enddate=BuildingInformationtable[0]['bi_EN_DT']==undefined?null:BuildingInformationtable[0]['bi_EN_DT'];
    }
    setBuildingInformationtable([...BuildingInformationtable, {'bi_ST_DT':startdate,'bi_EN_DT':enddate}]);
  };
  const deleteRow = () => {
    if (BuildingInformationtable.length > 0) {
      const updatedData = [...BuildingInformationtable];
      updatedData.pop();
      setBuildingInformationtable(updatedData);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/PropertyBuildingInformation/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const BuildingInformationData = response.data;
          delete BuildingInformationData.biUkNo;
          for (let i = 0; i < BuildingInformationData.propertyBuildingInformations.length; i++) {
            delete BuildingInformationData.propertyBuildingInformations[i].biUkNo;
          }
          
          const header = BuildingInformationData.propertyBuildingInformations[0];
          setBuildingInformationtable(BuildingInformationData.propertyBuildingInformations);
          setSelectedemEMNO(response.data.fac_NAME);
          setbi_TX_RT(header.bi_TX_RT);
          setbi_PR_NO(header.bi_PR_NO);
          setbi_TX_AR(header.bi_TX_AR);
          setFormData(header);        
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const [bi_CO_NO_DUMMY, setbi_CO_NO_DUMMY] = useState("");
  const handleDefault = async () => {
    setbi_CO_NO_DUMMY(`${company}`);

    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PropertyBuildingInformation/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setbi_TX_AR(value);
  };
  const onchangeDropDownFormData2 = (name, value, index) => {
    setFormData({ ...FormData, [name]: value });
    setbi_BL_NO(value);
    setBuildingInformationtable((prevData) =>
      prevData.map((item, idx) =>
        idx === index ? { ...item, [name]: value } : item
      )
    );
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setbi_PR_NO(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setbi_PR_AR(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setbi_TX_RT(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setbi_TX_AR(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    setupdate(`${selectedRows[0]}`);
    try {
      const response = await axios.get(
        `${domain}/api/PropertyBuildingInformation/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const BuildingInformationData = response.data;
        const header = BuildingInformationData.propertyBuildingInformations[0];
        setBuildingInformationtable(BuildingInformationData.propertyBuildingInformations);
        setSelectedemEMNO(response.data.fac_NAME);
        setbi_TX_RT(BuildingInformationData.bi_TX_RT);
        setbi_PR_NO(header.bi_PR_NO);
        setbi_TX_AR(header.bi_TX_AR);
        setbi_FA_AD(header.bi_FA_AD);
        setpt_DESC(response.data.pt_DESC);
        setFormData(header);        
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {
    const table = document.getElementById("BuildingInformationtable");
        const totalRowCount = table.tBodies[0].rows.length;
        const propertyBuildingInformations = [];
        for (let i = 0; i < totalRowCount; i++) {
          const ST_DT = document.getElementsByName("bi_ST_DT_" + i)[0];
          const EN_DT = document.getElementsByName("bi_EN_DT_" + i)[0];

          const Details = {
            biUkNo: document.getElementById("biUkNo_" + i).value,
            bi_BL_NO: table.tBodies[0].rows[i].cells[1].children[0].children[1].children[0].value,
            bi_PR_AR: table.tBodies[0].rows[i].cells[2].children[0].children[1].children[0].value,
            bi_AD_DS: document.getElementById("bi_AD_DS_" + i).value,
            bi_TR_QT: document.getElementById("bi_TR_QT_" + i).value,
            bi_TR_UM: table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
            bi_ST_DT: ST_DT.value? moment(ST_DT.value, "DD/MM/YYYY").format("YYYYMMDD"): null,
            bi_EN_DT: EN_DT.value? moment(EN_DT.value, "DD/MM/YYYY").format("YYYYMMDD") : null,
          };
          propertyBuildingInformations.push(Details);
        }
        const requestBody = {
          biUkNo:  FormData.biUkNo,
          bi_CO_NO: document.getElementById("bi_CO_NO").value,
          bi_PR_NO: document.getElementById("bi_PR_NO").value,
          bi_TX_AR: FormData.bi_TX_AR,
          bi_TX_RT: bi_TX_RT,
          bi_FA_AD: document.getElementById("bi_FA_AD").value,
          propertyBuildingInformations,
        };

    if (update) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/PropertyBuildingInformation/Update`;
      if (confirm) {
        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/PropertyBuildingInformation/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack>
        <div style={{ background: "red !important",  color: "white", fontSize: "10px",  }} >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack>
        <div style={{ background: "white !important", color: "white",  fontSize: "10px", }} >
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack>
        <div style={{ background: "white !important", color: "white", fontSize: "10px", }} >
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const tableHeaderStyleC = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#e1e1e1",
    width: "0%",
  };
  const tableCellStyleA = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "right",
    width:"0%",
  };
  const tableCellStyleC = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
    width:"0%",
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px", backgroundColor: "#1ed01e", border: "none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div  className="tableData"  style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table  style={{ borderCollapse: "collapse",  width: "100%",  marginTop: "20px",  }} >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pt_DESC"] ? filters["pt_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pt_DESC",
                        e.target.value,
                        filters["pt_DESC"]
                          ? filters["pt_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bu_DESC"] ? filters["bu_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bu_DESC",
                        e.target.value,
                        filters["bu_DESC"]
                          ? filters["bu_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ar_DESC"] ? filters["ar_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ar_DESC",
                        e.target.value,
                        filters["ar_DESC"]
                          ? filters["ar_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bi_TR_QT"] ? filters["bi_TR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bi_TR_QT",
                        e.target.value,
                        filters["bi_TR_QT"]
                          ? filters["bi_TR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bi_TR_UM"] ? filters["bi_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bi_TR_UM",
                        e.target.value,
                        filters["bi_TR_UM"]
                          ? filters["bi_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fac_Name"] ? filters["fac_Name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fac_Name",
                        e.target.value,
                        filters["fac_Name"]
                          ? filters["fac_Name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bi_CO_NO"] ? filters["bi_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bi_CO_NO",
                        e.target.value,
                        filters["bi_CO_NO"]
                          ? filters["bi_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Property</th>
                <th style={tableHeaderStyle}>Building</th>
                <th style={tableHeaderStyle}>Area Type</th>
                <th style={tableHeaderStyle}>Area</th>
                <th style={tableHeaderStyleC}>UM</th>
                <th style={tableHeaderStyle}>Facility Address</th>
                <th style={tableHeaderStyle}>Company</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.biUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.biUkNo}
                        checked={selectedRows.includes(rowData.biUkNo)}
                        onChange={() => handleRowSelect(rowData.biUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.pt_DESC}</td>
                    <td style={tableCellStyle}>{rowData.bu_DESC}</td>
                    <td style={tableCellStyle}>{rowData.ar_DESC}</td>
                    <td style={tableCellStyleA}>{rowData.bi_TR_QT}</td>
                    <td style={tableCellStyleC}>{rowData.bi_TR_UM}</td>
                    <td style={tableCellStyle}>{rowData.fac_NAME}</td>
                    <td style={tableCellStyle}>{rowData.bi_CO_NO}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <BuildingInformationDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        bi_PR_AR={bi_PR_AR}
        bi_TX_RT={bi_TX_RT}
        bi_PR_NO={bi_PR_NO}
        bi_TX_AR={bi_TX_AR}
        bi_BL_NO={bi_BL_NO}
        bi_TR_UM={bi_TR_UM}
        bi_FA_AD={bi_FA_AD}
        bi_CO_NO={bi_CO_NO_DUMMY}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        BuildingInformationtable={BuildingInformationtable}
        addRow={addRow}
        deleteRow={deleteRow}
        biUkNo={update}
        setbi_ST_DT={setbi_ST_DT}
        bi_ST_DT={bi_ST_DT}
        selectedFacilityaddress={selectedFacilityaddress}
        setselectedFacilityaddress={setselectedFacilityaddress}
        pt_DESC={pt_DESC}
        setpt_DESC={setpt_DESC}
      />
    </div>
  );
};

export default BuildingInformationMain;
