import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import SupplierPopup from "./SupplierPopup";
import CarrierPopup from "./CarrierPopup";
import ApproverPopup from "./ApproverPopup";
import axios from "axios";
export default function SupplierMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  onchangeDropDownFormData9,
  onchangeDropDownFormData10,
  onchangeDropDownFormData11,
  onchangeDropDownFormData12,
  onchangeDropDownFormData13,
  onchangeDropDownFormData14,
  onchangeDropDownFormData15,
  onchangeDropDownFormData16,
  onchangeDropDownFormData17,
  su_CO_NO,
  su_TX_CD,
  SelectedemEMNO,
  setSelectedemEMNO,
  selectedAbAdNM2,
  setSelectedAbAdNM2,
  selectedAbAdNM1,
  setSelectedAbAdNM1,
}) {
  const {
    suUkNo,
    su_SU_NO,
    su_CR_CD,
    su_PT_TM,
    su_PY_IN,
    su_GL_CD,
    su_HO_PY,
    su_WH_PE,
    su_NT_FL,
    su_CR_NO,
    su_AP_NO,
    su_HL_CD,
    su_RT_CD,
    su_IT_RT,
    su_TR_DY,
    su_LD_CR,
    su_FR_HC,
    su_TX_AP,
    su_TX_TY,
    su_TX_RT,
    
    su_TX_AR,
  } = data;
  const [su_CR_CDOptions, setsu_CR_CDOptions] = useState([]);
  const [su_PT_TMOptions, setsu_PT_TMOptions] = useState([]);
  const [su_PY_INOptions, setsu_PY_INOptions] = useState([]);
  const [su_GL_CDOptions, setsu_GL_CDOptions] = useState([]);
  const [su_HO_PYOptions, setsu_HO_PYOptions] = useState([]);
  const [su_WH_PEOptions, setsu_WH_PEOptions] = useState([]);
  const [su_NT_FLOptions, setsu_NT_FLOptions] = useState([]);
  const [su_HL_CDOptions, setsu_HL_CDOptions] = useState([]);
  const [su_RT_CDOptions, setsu_RT_CDOptions] = useState([]);
  const [su_IT_RTOptions, setsu_IT_RTOptions] = useState([]);
  const [su_LD_CROptions, setsu_LD_CROptions] = useState([]);
  const [su_FR_HCOptions, setsu_FR_HCOptions] = useState([]);
  const [su_TX_APOptions, setsu_TX_APOptions] = useState([]);
  const [su_TX_TYOptions, setsu_TX_TYOptions] = useState([]);
  const [su_TX_CDOptions, setsu_TX_CDOptions] = useState([]);
  const [su_TX_AROptions, setsu_TX_AROptions] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const urlcost = `${domain}/api/CostCenter/DropDown`;

  // cost center
  // const parameter1 = "CC/TY";
  const finalUrl1 = `${urlcost}`;

  const url = `${domain}/api/ClassCode/All`;
 // designation
 const parameter2 = "FO/CR";
 const finalUrl2 = `${url}/${parameter2}`;
  // departmemt
  const urlpayterm = `${domain}/api/PaymentTerm/GetAll`;
  const finalUrl3 = `${urlpayterm}`;
  // pay class
  const parameter4 = "FO/PI";
  const finalUrl4 = `${url}/${parameter4}`;
  // grade
  const parameter5 = "GL/GC";
  const finalUrl5 = `${url}/${parameter5}`;
  // Pay Frequency
  const parameter6 = "ME/PL";
  const finalUrl6 = `${url}/${parameter6}`;
  // Pay Frequency
  const parameter7 = "ME/PL";
  const finalUrl7 = `${url}/${parameter7}`;
   // Pay Frequency
  const parameter8 = "ME/PL";
  const finalUrl8 = `${url}/${parameter8}`;
  // Pay Frequency
  const parameter9 = "FO/HD";
  const finalUrl9 = `${url}/${parameter9}`;
  // Pay Frequency
  const parameter10= "FO/RC";
  const finalUrl10 = `${url}/${parameter10}`;
  // Pay Frequency
  const parameter11 = "ME/PL";
  const finalUrl11= `${url}/${parameter11}`;
   // Pay Frequency
   const parameter12 = "AP/LC";
   const finalUrl12= `${url}/${parameter12}`;
   // Pay Frequency
   const parameter13 = "FO/FH";
   const finalUrl13= `${url}/${parameter13}`;
   // Pay Frequency
const parameter14 = "ME/PL";
const finalUrl14 = `${url}/${parameter14}`;
// Pay Frequency
const parameter15 = "TA/TY";
const finalUrl15 = `${url}/${parameter15}`;
// Pay Frequency
const parameter16 = "TX/RT";
const finalUrl16 = `${url}/${parameter16}`;
// Pay Frequency
const parameter17 = "FO/TA";
const finalUrl17= `${url}/${parameter17}`;


  useEffect(() => {
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
    fetchDropdownOptions8();
    fetchDropdownOptions9();
    fetchDropdownOptions10();
    fetchDropdownOptions11();
    fetchDropdownOptions12();
    fetchDropdownOptions13();
    fetchDropdownOptions14();
    fetchDropdownOptions15();
    fetchDropdownOptions16();
    fetchDropdownOptions17();
  
  }, []);

// 2nd dropdowns
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setsu_CR_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl3, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.pt_PY_DS,
        value: item.pt_PY_CO,
    }));
    setsu_PT_TMOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 4th dropdown
const fetchDropdownOptions4 = async () => {
  try {
    const response = await axios.get(finalUrl4, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setsu_PY_INOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 5th dropdown
const fetchDropdownOptions5 = async () => {
  try {
    const response = await axios.get(finalUrl5, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setsu_GL_CDOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 6th dropdown
const fetchDropdownOptions6 = async () => {
  try {
    const response = await axios.get(finalUrl6, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setsu_HO_PYOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 7th dropdown
const fetchDropdownOptions7 = async () => {
  try {
    const response = await axios.get(finalUrl7, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setsu_WH_PEOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 8th dropdown
const fetchDropdownOptions8 = async () => {
  try {
    const response = await axios.get(finalUrl8, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setsu_NT_FLOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 9th dropdown
const fetchDropdownOptions9 = async () => {
  try {
    const response = await axios.get(finalUrl9, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setsu_HL_CDOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 10th dropdown
const fetchDropdownOptions10 = async () => {
  try {
    const response = await axios.get(finalUrl10, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setsu_RT_CDOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 11th dropdown
const fetchDropdownOptions11 = async () => {
  try {
    const response = await axios.get(finalUrl11, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setsu_IT_RTOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 12th dropdown
const fetchDropdownOptions12 = async () => {
  try {
    const response = await axios.get(finalUrl12, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setsu_LD_CROptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 13th dropdown
const fetchDropdownOptions13 = async () => {
  try {
    const response = await axios.get(finalUrl13, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setsu_FR_HCOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 14th dropdown
const fetchDropdownOptions14= async () => {
  try {
    const response = await axios.get(finalUrl14, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_DS,
    }));
    setsu_TX_APOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

// 15th dropdown
const fetchDropdownOptions15= async () => {
  try {
    const response = await axios.get(finalUrl15, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setsu_TX_TYOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 16th dropdown
const fetchDropdownOptions16= async () => {
  try {
    const response = await axios.get(finalUrl16, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setsu_TX_CDOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

// 17th dropdown
const fetchDropdownOptions17= async () => {
  try {
    const response = await axios.get(finalUrl17, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setsu_TX_AROptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};


const GetTaxCode =  async (su_TX_CD) => {
  const  Url = `${domain}/api/TaxRate/GetByTaxCode`
  const requestData={
    "tx_TX_CD": su_TX_CD,
}
try {
  const response =await axios.post(Url, requestData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  });

  if (response.status !== 200) {
    throw new Error(`API request failed with status: ${response.status}`);
  }
  const dataItMt = response.data.Result;
  if (dataItMt ) {
    onchangeFormData("su_TX_RT", dataItMt.tx_TX_RT);
  }
} catch (error) {
  console.error("Error fetching data:", error);
}
};
const isUpdateMode = Boolean(suUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
  const openSupplierPopup = (e) => {
    setSupplierPopupVisible(true);
    setSelectedRowIndex(e);
  };
  //  startsearchpopup
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("su_SU_NO", data.abAdNo);
    setSupplierPopupVisible(false);
  };

  const [CarrierPopupVisible, setCarrierPopupVisible] = useState(false);
const openCarrierPopup = (e) => {
  setCarrierPopupVisible(true);
  setSelectedRowIndex(e);
};
//  carrier popup
const handleDataSelect2 = async (data) => {
  setSelectedAbAdNM2(data.ab_AD_NM);
  onchangeFormData("su_CR_NO", data.abAdNo);
  setCarrierPopupVisible(false);
};
const [ApproverPopupVisible, setApproverPopupVisible] = useState(false);
const openApproverPopup = (e) => {
  setApproverPopupVisible(true);
  setSelectedRowIndex(e);
};
//  carrier popup
const handleDataSelect1 = async (data) => {
  setSelectedAbAdNM1(data.ab_AD_NM);
  onchangeFormData("su_AP_NO", data.abAdNo);
  setApproverPopupVisible(false);
};

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={suUkNo ? "Revise Supplier Master" : "Add Supplier Master"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="General">
            {/* tab1 general */}

            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_SU_NO" className="font-bold">
                  Supplier  <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
              <InputText
                id="name"
                name="name"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNO}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openSupplierPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="su_SU_NO"
                name="su_SU_NO"
                value={su_SU_NO}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openSupplierPopup}
              ></i>
            </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_CR_CD" className="font-bold">
                    Currency
                  </label>
                  <Dropdown
                    name="su_CR_CD"
                    value={su_CR_CD}
                    options={su_CR_CDOptions}
                    onChange={(e) => onchangeDropDownFormData2("su_CR_CD", e.value)}
                    placeholder="Select Currency"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_CO_NO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="su_CO_NO"
                    name="su_CO_NO"
                    value={su_CO_NO}
                    readOnly={true}
                    
                  />
                </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_PT_TM" className="font-bold">
                    Payment Term
                  </label>
                  <Dropdown
                    name="su_PT_TM"
                    value={su_PT_TM}
                    options={su_PT_TMOptions}
                    onChange={(e) => onchangeDropDownFormData3("su_PT_TM", e.value)}
                    placeholder="Select Payment Term"
                  />
                </div>
            
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_PY_IN" className="font-bold">
                    Payment Instrument
                  </label>
                  <Dropdown
                    name="su_PY_IN"
                    value={su_PY_IN}
                    options={su_PY_INOptions}
                    onChange={(e) => onchangeDropDownFormData4("su_PY_IN", e.value)}
                    placeholder="Select Payment Instrument"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_GL_CD" className="font-bold">
                    GL Code
                  </label>
                  <Dropdown
                    name="su_GL_CD"
                    value={su_GL_CD}
                    options={su_GL_CDOptions}
                    onChange={(e) => onchangeDropDownFormData5("su_GL_CD", e.value)}
                    placeholder="Select GL Code"
                  />
                </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_HO_PY" className="font-bold">
                   Hold Payment
                  </label>
                  <Dropdown
              id="su_HO_PY"
              name="su_HO_PY"
              value={su_HO_PY}
              options={[
                { label: "Yes", value: "y" },
                { label: "No", value: "n" },
              ]}
              onChange={(e) => onchangeDropDownFormData6("su_HO_PY", e.value)}
              placeholder="Select Hold Payment"
            />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_WH_PE" className="font-bold">
                    With Hold%
                  </label>
                  {/* <Dropdown
              id="su_WH_PE"
              name="su_WH_PE"
              value={su_WH_PE}
              options={[
                { label: "Yes", value: "y" },
                { label: "No", value: "n" },
              ]}
              onChange={(e) => onchangeDropDownFormData7("su_WH_PE", e.value)}
              placeholder="Select With Hold%"
            /> */}
             <InputText
                    id="su_WH_PE"
                    name="su_WH_PE"
                    value={su_WH_PE}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
         
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_NT_FL" className="font-bold">
                    Ap/AR Netting
                  </label>
                  <Dropdown
              id="su_NT_FL"
              name="su_NT_FL"
              value={su_NT_FL}
              options={[
                { label: "Yes", value: "y" },
                { label: "No", value: "n" },
              ]}
              onChange={(e) => onchangeDropDownFormData8("su_NT_FL", e.value)}
              placeholder="Select AR/AP Netting"
            />
                </div>
                </div>
   
         </div>
           
          </TabPanel>
          {/* tab2 */}

          <TabPanel header="Instructions">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="su_AP_NO" className="font-bold">
              Approver <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
                id="approvalName"
                name="approvalName"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={selectedAbAdNM1}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openApproverPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="su_AP_NO"
                name="su_AP_NO"
                value={su_AP_NO}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openApproverPopup}
              ></i>
            </div>
          </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_HL_CD" className="font-bold">
                  Hold Code
                  </label>
                  <Dropdown
                    name="su_HL_CD"
                    value={su_HL_CD}
                    options={su_HL_CDOptions}
                    onChange={(e) => onchangeDropDownFormData9("su_HL_CD", e.value)}
                    placeholder="Select Hold Code"
                  />
                </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="su_CR_NO" className="font-bold">
              Carrier  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
                id="carrierName"
                name="carrierName"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={selectedAbAdNM2}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCarrierPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="su_CR_NO"
                name="su_CR_NO"
                value={su_CR_NO}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCarrierPopup}
              ></i>
            </div>
          </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_RT_CD" className="font-bold">
                  Route Code
                  </label>
                  <Dropdown
                    name="su_RT_CD"
                    value={su_RT_CD}
                    options={su_RT_CDOptions}
                    onChange={(e) => onchangeDropDownFormData10("su_RT_CD", e.value)}
                    placeholder="Select Route Code"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_IT_RT" className="font-bold">
                  Item Restriction
                  </label>
                  <Dropdown
              id="su_IT_RT"
              name="su_IT_RT"
              value={su_IT_RT}
              options={[
                { label: "Yes", value: "y" },
                { label: "No", value: "n" },
              ]}
              onChange={(e) => onchangeDropDownFormData11("su_IT_RT", e.value)}
              placeholder="Select Item Restriction"
            />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_TR_DY" className="font-bold">
                  Transit Days
                  </label>
                  <InputText
                    id="su_TR_DY"
                    name="su_TR_DY"
                    value={su_TR_DY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_LD_CR" className="font-bold">
                  Landed Cost
                  </label>
                  <Dropdown
                    name="su_LD_CR"
                    value={su_LD_CR}
                    options={su_LD_CROptions}
                    onChange={(e) => onchangeDropDownFormData12("su_LD_CR", e.value)}
                    placeholder="Select Landed Cost"

                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_FR_HC" className="font-bold">
                  Freight Handling
                  </label>
                  <Dropdown
                    name="su_FR_HC"
                    value={su_FR_HC}
                    options={su_FR_HCOptions}
                    onChange={(e) => onchangeDropDownFormData13("su_FR_HC", e.value)}
                    placeholder="Select Freight Handling"

                  />
                </div>
                
              </div>
              </div>
        

          </TabPanel>
          <TabPanel header="Tax">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_TX_AP" className="font-bold">
                   Tax Applicable
                  </label>
                  <Dropdown
              id="su_TX_AP"
              name="su_TX_AP"
              value={su_TX_AP}
              options={[
                { label: "Yes", value: "y" },
                { label: "No", value: "n" },
              ]}
              onChange={(e) => onchangeDropDownFormData14("su_TX_AP", e.value)}
              placeholder="Select Tax Applicable"
            />
                </div>

                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_TX_TY" className="font-bold">
                    Tax Type
                  </label>
                  <Dropdown
                    name="su_TX_TY"
                    value={su_TX_TY}
                    options={su_TX_TYOptions}
                    onChange={(e) => onchangeDropDownFormData15("su_TX_TY", e.value)}
                    placeholder="Select Tax Type"

                  />
                </div>
                
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_TX_CD" className="font-bold">
                   Tax Code
                  </label>
                  <Dropdown
                    name="su_TX_CD"
                    value={su_TX_CD}
                    options={su_TX_CDOptions}
                    onChange={(e) => {
                      const selectedValue = e.value;
                      onchangeDropDownFormData16("su_TX_CD", selectedValue);
                      GetTaxCode(selectedValue);
                    }}
                    placeholder="Select Tax Code"

                  />
                  
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_TX_RT" className="font-bold">
                   Tax Rate
                  </label>
                  <InputText disabled
                    id="su_TX_RT"
                    name="su_TX_RT"
                    defaultValue={su_TX_RT}
                    onChange={(e) => onchangedata(e)}
                  /> 
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="su_TX_AR" className="font-bold">
                   Tax Area
                  </label>
                  <Dropdown
                    name="su_TX_AR"
                    value={su_TX_AR}
                    options={su_TX_AROptions}
                    onChange={(e) => onchangeDropDownFormData17("su_TX_AR", e.value)}
                    placeholder="Select Tax Area"

                  />
                </div>
              </div>
              </div>
        

          </TabPanel>
      
      </TabView>

      </div>    
      <SupplierPopup
        visible={SupplierPopupVisible}
        onClose={() => setSupplierPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
    <CarrierPopup
        visible={CarrierPopupVisible}
        onClose={() => setCarrierPopupVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
      />
      <ApproverPopup
        visible={ApproverPopupVisible}
        onClose={() => setApproverPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
    </Dialog>
  );
}
