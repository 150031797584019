import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import axios from "axios";
import AssetPopup from "views/admin/Popups/AssetPopup";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
const initialValue = {
  fmUkNo: "",
  fm_AT_NO: "",
  fm_AT_D1: "",
  asset_COST: "",
  acc_DEPR: "",
  net_VAL: "",
  ytd_DEPRECIATION: "",
  cur_PRD: "",
  fm_DP_MT: "",
  fm_AT_LF: "",
  fm_DP_SD:"",
  fm_RS_VL:"",
};

const initialFilters = {
  fm_AT_NO: { value: "", operation: "" },
  fm_AT_D1: { value: "", operation: "" },
  asset_COST: { value: "", operation: "" },
  acc_DEPR: { value: "", operation: "" },
  net_VAL: { value: "", operation: "" },
  ytd_DEPRECIATION: { value: "", operation: "" },
  cur_PRD: { value: "", operation: "" },
  fm_DP_MT: { value: "", operation: "" },
  fm_AT_LF: { value: "", operation: "" },
  fm_DP_SD: { value: "", operation: "" },
  fm_RS_VL: { value: "", operation: "" },
};

const AssetLedgerMain = () => {
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [Asset, setAsset] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
// dateformat
const formatDate = (inputDate) => {
  if (!inputDate) {
    return inputDate;
  }
  const dateString = inputDate.toString();
  if (dateString.length !== 8) {
    return dateString;
  }
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);

  return `${day}/${month}/${year}`;
};
const [years, setyears] = useState([]);
const [period, setperiod] = useState([]);
const [yearOptions, setyearOptions] = useState("");
const [periodOptions, setperiodOptions] = useState("");
// Dropdown
const url = `${domain}/api/ClassCode/All`;
const parameter1 = "YE/AR";
const finalUrl = `${url}/${parameter1}`;

const parameter2 = "YE/AR";
const finalUr2 = `${url}/${parameter2}`;
useEffect(() => {
  fetchDropdownOptions();
  fetchDropdownOptions1();
  
}, [currentPage, pageSize, filters]);

const fetchDropdownOptions = async () => {
  try {
    const response = await axios.get(finalUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setyearOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

const fetchDropdownOptions1 = async () => {
  try {
    const response = await axios.get(finalUr2, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setperiodOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const [AssetPopupVisible, setAssetPopupVisible] = useState(false);
  const openAssetPopup = (e) => {
    setAssetPopupVisible(true);
   
  };
  const handleDataSelect = (data) => {
    setSelectedAsset(data.fm_AT_D1);
    setAsset(data.fm_AT_NO);
    setAssetPopupVisible(false);
    updateFilters (data.fm_AT_NO);
  }
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      fm_AT_NO: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const updateFilters2 = (sts) => {
    let updatedFilters = { ...filters };
    if (sts !== null) {
      updatedFilters = {
        ...updatedFilters,
        year: {
          value: sts,
          operation: "LIKE",
        },
      };
    }

    setFilters(updatedFilters);
  };
  const updateFilters1 = (sts) => {
    let updatedFilters = { ...filters };
    if (sts !== null) {
      updatedFilters = {
        ...updatedFilters,
        period: {
          value: sts,
          operation: "LIKE",
        },
      };
    }

    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClearButton=()=>{
    setperiod("");
    setSelectedAsset("");
    setyears(null);
    setAsset("");
    setFilters(initialFilters);
  }

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/FixedAssetMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const onchangeDropDown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setyears(value);
    updateFilters2(value);
  };
  const onchangeDropDown1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setperiod(value);
    updateFilters1(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

 
  
  
  
  const leftToolbarTemplate = ( 
    <React.Fragment>
      
      
    </React.Fragment>
  );

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(0);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
  };
  const tableHeaderStyleC = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#e1e1e1",
  };
  const emailStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const contactStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    width:"0%",
  };
  const tableCellStyleC = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
    width:"0%",
  };
  const tableCellStyleT = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "right",
    width:"0%",
  };

  const inputStyle = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
  };


  const checkboxStyle = {
    border: "6px solid #a7d6ff8c",
    borderRadius: "4px",
    width: "100%",
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  const AdvanceSearch = (
    <div className="formgrid grid">
      <div className="field col" >
            <label htmlFor="asset" className="font-bold">
              Asset No <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
              style={{width:"125px"}}
                id="asset"
                name="asset"
                onFocus={() => setShowSearchIcon(true)}
                value={Asset}
              />
                {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openAssetPopup}
              ></i>
              )}
              <div>
              <InputText
                id="fm_AT_D1"
                name="fm_AT_D1"
                readOnly
                value={selectedAsset}
                style={{width:"250px",backgroundColor:"#f5f4f4"}}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            </div>
            </div>
            {/* <div className="field col"   >
        <label htmlFor="years" className="font-bold">
         Year
        </label>
        <Dropdown
          style={{ width: "90%" }}
          id="years"
          name="years"
          value={years}
          options={yearOptions}
          onChange={(e) => {
            onchangeDropDown("years", e.value);
          }}
          placeholder="Select Year"
        />
      </div>
      <div className="field col"   >
        <label htmlFor="period" className="font-bold">
         Period
        </label>
        <Dropdown
          style={{ width: "90%" }}
          id="period"
          name="period"
          value={period}
          options={periodOptions}
          onChange={(e) => {
            onchangeDropDown1("period", e.value);
          }}
          placeholder="Select Period"
        />
      </div> */}
          </div>
          

);
const rightClear = (
  <React.Fragment>
  <Tooltip  target=".clear-button" position="bottom" mouseTrack>
  <div>Clear Filter</div>
  </Tooltip>
  
  <Button
  style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
  icon="pi pi-times"
  className="mr-2 clear-button"
  link
  onClick={handleClearButton}
  />
  </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_NO"] ? filters["fm_AT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_NO",
                        e.target.value,
                        filters["fm_AT_NO"]
                          ? filters["fm_AT_NO"].operation
                          : "equals"
                      )
                    }
                    style={contactStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_D1"] ? filters["fm_AT_D1"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_D1",
                        e.target.value,
                        filters["fm_AT_D1"] ? filters["fm_AT_D1"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["asset_COST"] ? filters["asset_COST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "asset_COST",
                        e.target.value,
                        filters["asset_COST"] ? filters["asset_COST"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["acc_DEPR"] ? filters["acc_DEPR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "acc_DEPR",
                        e.target.value,
                        filters["acc_DEPR"]
                          ? filters["acc_DEPR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["net_VAL"] ? filters["net_VAL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "net_VAL",
                        e.target.value,
                        filters["net_VAL"]
                          ? filters["net_VAL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ytd_DEPRECIATION"] ? filters["ytd_DEPRECIATION"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ytd_DEPRECIATION",
                        e.target.value,
                        filters["ytd_DEPRECIATION"]
                          ? filters["ytd_DEPRECIATION"].operation
                          : "equals"
                      )
                    }
                    style={emailStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cur_PRD"] ? filters["cur_PRD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cur_PRD",
                        e.target.value,
                        filters["cur_PRD"]
                          ? filters["cur_PRD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_DP_MT"] ? filters["fm_DP_MT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_DP_MT",
                        e.target.value,
                        filters["fm_DP_MT"]
                          ? filters["fm_DP_MT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_LF"] ? filters["fm_AT_LF"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_LF",
                        e.target.value,
                        filters["fm_AT_LF"]
                          ? filters["fm_AT_LF"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />   
                  </td>  
                  <td>
                  <input
                    type="text"
                    value={filters["fm_DP_SD"] ? filters["fm_DP_SD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_DP_SD",
                        e.target.value,
                        filters["fm_DP_SD"]
                          ? filters["fm_DP_SD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["fm_RS_VL"] ? filters["fm_RS_VL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_RS_VL",
                        e.target.value,
                        filters["fm_RS_VL"]
                          ? filters["fm_RS_VL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Asset No </th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Asset Cost</th>
                <th style={tableHeaderStyleC}>Acc. Depreciation</th>
                <th style={tableHeaderStyle}>Net Book Value</th>
                <th style={tableHeaderStyle}>YTD depreciation</th>
                <th style={tableHeaderStyle}>Current period</th>
                <th style={tableHeaderStyle}>Depreciation Method</th>
                <th style={tableHeaderStyle}>Life in Months</th>
                <th style={tableHeaderStyle}>Depre. Start Date</th>
                <th style={tableHeaderStyle}>Residual value</th>
            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.fmUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.fmUkNo}
                        checked={selectedRows.includes(rowData.fmUkNo)}
                        onChange={() => handleRowSelect(rowData.fmUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.fm_AT_NO}</td>
                    <td style={tableCellStyle}>{rowData.fm_AT_D1}</td>
                    <td style={tableCellStyle}>{rowData.asset_COST}</td>
                    <td style={tableCellStyleC}>{rowData.acc_DEPR}</td>
                    <td style={tableCellStyle}>{rowData.net_VAL}</td>
                    <td style={tableCellStyle}>{rowData.ytd_DEPRECIATION}</td>
                    <td style={tableCellStyleT}>{rowData.cur_PRD}</td>
                    <td style={tableCellStyleT}>{rowData.fm_DP_MT}</td>
                    <td style={tableCellStyleT}>{rowData.fm_AT_LF}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.fm_DP_SD)}</td>
                    <td style={tableCellStyleT}>{rowData.fm_RS_VL}</td>                 
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="Pagination" style={{ marginLeft: "85%" }}>
          <span
            onClick={handleFirstPage}
            style={{
              fontSize: "200%",
              cursor: "pointer",
              whiteSpace: "pre-wrap",
              whiteSpace: "pre-wrap",
            }}
          >
            &laquo;{" "}
          </span>{" "}
          <span
            onClick={handlePreviousPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Prev{" "}
          </span>{" "}
          <span style={{ whiteSpace: "pre-wrap", fontSize: "111%" }}> </span>{" "}
          <span
            onClick={handleNextPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Next{" "}
          </span>{" "}
          <span
            onClick={handleLastPage}
            style={{ fontSize: "200%", cursor: "pointer" }}
          >
            &raquo;
          </span>
        </div>
      </div>
      <AssetPopup
        visible={AssetPopupVisible}
        onClose={() => setAssetPopupVisible(false)}
        onDataSelect={handleDataSelect}
       
      />
      
    </div>
    </div>
    
  );
};

export default AssetLedgerMain;
