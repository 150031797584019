import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ManagerPopup from "../Popups/ManagerPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import Costcenter  from "views/common/CostCentreDrpDwn";
import ItemPopup from "../Popups/ItemPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function WorkOrderRoutingDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  WorkOrderRoutingTable,
  setWorkOrderRoutingTable,
  er_AD_CR,
  seter_AD_CR,
  er_RE_CR,
  seter_RE_CR,
  er_EX_CT,
  seter_EX_CT,
  er_EX_TY,
  seter_EX_TY,
  er_CO_CT,
  seter_CO_CT,
  er_LO_CN,
  seter_LO_CN,
  er_ST_AT,
  seter_ST_AT,
  er_TR_CR,
  seter_TR_CR,
  wr_OR_CO,
}) {
  const {
    wr_UK_NO,
    wrOrNo,
    wr_OR_TY,
    wr_IT_NM,
    er_RP_DT,
    er_BU_PR,
  } = data;
  // buttonstyles
  const {plusstyle,minusstyle,tablebutton,tablestyle  } = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [er_EX_TYOptions, seter_EX_TYOptions] = useState([]);
  const [er_AD_CROptions, seter_AD_CROptions] = useState([]);
  const [er_RE_CROptions, seter_RE_CROptions] = useState([]);
  const [er_ST_ATOptions, seter_ST_ATOptions] = useState([]);
  const [er_EX_CTOptions, seter_EX_CTOptions] = useState([]);
  const [er_LO_CNOptions, seter_LO_CNOptions] = useState([]);
  const [er_CO_CTOptions, seter_CO_CTOptions] = useState([]);
  const [er_TR_CROptions, seter_TR_CROptions] = useState([]);

// Expense Type
const ExpenseTypeDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  seter_EX_TY(value);
};

// Advance Curr
const AdvanceCurrDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  seter_AD_CR(value);
};

// Reim Curr
const ReimburseCurrDropdown = (name, value) => {
setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
seter_RE_CR(value);
};

// Status
const StatusDropdown = (name, value) => {
setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
seter_ST_AT(value);
};

 // Expense Category
const ExpenseCategoryDropdown = (name, value) => {
  const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seter_EX_CT(value);
};
// CostCentre
const CostCentreDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  seter_CO_CT(value);
};


  // Location
const LocationDropdown = (name, value) => {
  const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seter_LO_CN(value);
};

  // Currency
  const CurrencyDropdown = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      seter_TR_CR(value);
  };

  useEffect(() => {
    ExpenseType();
    ReimCurrency();
    AdvanceCurrency();
    Status();
    ExpenseCategory();
    Location();
    CostCenter();
    Currency();

  }, []);

  const ExpenseType = async () => {
    const Options = await fetchClassCodeDS("EX/TY");
    seter_EX_TYOptions(Options);
  };

  const ReimCurrency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    seter_RE_CROptions(Options);
  };

  const AdvanceCurrency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    seter_AD_CROptions(Options);
  };
  const Status = async () => {
    const Options = await fetchClassCodeDS("EX/ST");
    seter_ST_ATOptions(Options);
  };
  const ExpenseCategory = async () => {
    const Options = await fetchClassCodeDS("EX/CT");
    seter_EX_CTOptions(Options);
  };

  const Location = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    seter_LO_CNOptions(Options);
  };

  const CostCenter = async () => {
    const Options = await fetchCostCenter();
    seter_CO_CTOptions(Options);
  };

  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    seter_TR_CROptions(Options);
  };

  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedEmployee, setSelectedEmployee] = useState("");
  const [SelectedManager, setSelectedManager] = useState("");
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);
  const [ManagerPopupVisible, setManagerPopupVisible] = useState(false);
  const openItemPopup = () => {
    setEmployeePopupVisible(true);
  }
  const openManagerPopup = () => {
    setManagerPopupVisible(true);
  }
  const handleEmployee = async (data) => {
    setSelectedEmployee(data.ab_AD_NM);
    onchangeFormData("er_EM_NO", data.abAdNo);
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleManager = async (data) => {
    setSelectedManager(data.ab_AD_NM);
    onchangeFormData("er_MN_NO", data.abAdNo);
    setManagerPopupVisible(false);
    setShowSearchIcon(false);
  };


  const addRow = () => {
    setWorkOrderRoutingTable([...WorkOrderRoutingTable, {}]);
  };
  
  const deleteRow = () => {
    if (WorkOrderRoutingTable.length > 0) {
      const updatedData = [...WorkOrderRoutingTable];
      updatedData.pop();
      setWorkOrderRoutingTable(updatedData);
    }
  };  
const isUpdateMode = Boolean(wr_UK_NO);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

 
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={wr_UK_NO ? "Revise Expense Report" : "Add Expense Report"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="wrOrNo" className="font-bold">
           Order No
          </label>
          <InputText disabled
            id="wrOrNo"
            name="wrOrNo"
            value={wrOrNo}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="wr_OR_TY" className="font-bold">
           Order Type 
          </label>
          <InputText disabled
            id="wr_OR_TY"
            name="wr_OR_TY"
            value={wr_OR_TY}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="wr_OR_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="wr_OR_CO"
            name="wr_OR_CO"
            value={wr_OR_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="er_EM_NO" className="font-bold">
              Item Number <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="employee_NAME"
                      name="employee_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedEmployee}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItemPopup}
                    ></i>
                    )}
                  </div>
                <InputText style={{ display: "block" }}
                  id="wr_IT_NM"
                  name="wr_IT_NM"
                  value={wr_IT_NM}
                  onChange={(Event) => onchangedata(Event)}
                />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="er_MN_NO" className="font-bold">
              Cost Center 
              </label>
              <Dropdown
                name="er_EX_TY"
                value={er_EX_TY}
                options={er_EX_TYOptions}
                onChange={(e) => ExpenseTypeDropdown("er_EX_TY", e.value)}
                placeholder="Select Expense Type"
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="er_EX_TY" className="font-bold">
            Type
            </label>
            <Dropdown
              name="er_EX_TY"
              value={er_EX_TY}
              options={er_EX_TYOptions}
              onChange={(e) => ExpenseTypeDropdown("er_EX_TY", e.value)}
              placeholder="Select Expense Type"
            />
          </div>
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="er_EX_TY" className="font-bold">
           UM
          </label>
            <Dropdown
              name="er_EX_TY"
              value={er_EX_TY}
              options={er_EX_TYOptions}
              onChange={(e) => ExpenseTypeDropdown("er_EX_TY", e.value)}
              placeholder="Select Expense Type"
            />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="er_AD_CR" className="font-bold">
               Quantity
              </label>
              <Dropdown
              name="er_AD_CR"
              value={er_AD_CR}
              options={er_AD_CROptions}
              onChange={(e) => AdvanceCurrDropdown("er_AD_CR", e.value)}
              placeholder="Select Currency"
            />
            </div> 
          </div>
          </div>
{/*TABLE  */}
      <div style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={plusstyle}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={minusstyle}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="WorkOrderRoutingTable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th> Line No </th>
              <th> Work Center </th>
              <th> Description </th>
              <th> Oper. Seq. </th>
              <th> Description </th>
              <th> Instruction </th>
              <th> Labour Hrs </th>
              <th> Machine Hrs </th>
              <th> Setup Hrs </th>
              <th> Time Basis </th>
              <th> From Date </th>
              <th> To Date </th>
            </tr>
          </thead>
          <tbody>
            {WorkOrderRoutingTable.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`wr_UK_NO_${index}`}
                    name={`wr_UK_NO_${index}`}
                    value={row.wr_UK_NO}
                  />
                </td>
                <td>
                  <Dropdown
                    id={`er_EX_CT_${index}`}
                    name={`er_EX_CT_${index}`}
                    value={ data[`er_EX_CT_${index}`] ? data[`er_EX_CT_${index}`]: row.er_EX_CT}
                    options={er_EX_CTOptions}
                    onChange={(e) =>ExpenseCategoryDropdown(`er_EX_CT_${index}`, e.value)}
                    placeholder="Select Exp Category"
                  />
                </td>
              <td>
                <Calendar style={{width:"150px"}}
                  id={`er_TR_DT_${index}`}
                  name={`er_TR_DT_${index}`}
                  value={row.er_TR_DT? moment(row.er_TR_DT, "YYYYMMDD").toDate(): null}
                  onChange={(e) => onDateChange({ target: { name: "er_TR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  showIcon
                  dateFormat="dd/mm/yy"
                />
                </td>
                <td>
                  <Dropdown style={{width:"120px"}}
                    id={`er_CO_CT_${index}`}
                    name={`er_CO_CT_${index}`}
                    value={ data[`er_CO_CT_${index}`] ? data[`er_CO_CT_${index}`]: row.er_CO_CT}
                    options={er_CO_CTOptions}
                    onChange={(e) =>CostCentreDropdown(`er_CO_CT_${index}`, e.value)}
                    placeholder="Select Cost Centre"
                  />
                  </td>
                <td>
                  <Dropdown
                    id={`er_LO_CN_${index}`}
                    name={`er_LO_CN_${index}`}
                    value={ data[`er_LO_CN_${index}`] ? data[`er_LO_CN_${index}`]: row.er_LO_CN}
                    options={er_LO_CNOptions}
                    onChange={(e) =>LocationDropdown(`er_LO_CN_${index}`, e.value)}
                    placeholder="Select Location"
                  />
                  </td>
                  <td >
                  <InputText style={{width:"90px"}}
                    id={`er_TR_QT_${index}`}
                    name={`er_TR_QT_${index}`}
                    defaultValue={row.er_TR_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`er_RA_TE_${index}`}
                    name={`er_RA_TE_${index}`}
                    defaultValue={row.er_RA_TE}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`er_EX_AM_${index}`}
                    name={`er_EX_AM_${index}`}
                    defaultValue={row.er_EX_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Dropdown style={{width:"120px"}}
                    id={`er_TR_CR_${index}`}
                    name={`er_TR_CR_${index}`}
                    value={ data[`er_TR_CR_${index}`] ? data[`er_TR_CR_${index}`]: row.er_TR_CR}
                    options={er_TR_CROptions}
                    onChange={(e) =>CurrencyDropdown(`er_TR_CR_${index}`, e.value)}
                    placeholder="Select Currency"
                  />
                  </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`er_AL_AM_${index}`}
                    name={`er_AL_AM_${index}`}
                    defaultValue={row.er_AL_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <InputText style={{width:"90px"}}
                    id={`er_AL_AM_${index}`}
                    name={`er_AL_AM_${index}`}
                    defaultValue={row.er_AL_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                <Calendar style={{width:"150px"}}
                  id={`er_TR_DT_${index}`}
                  name={`er_TR_DT_${index}`}
                  value={row.er_TR_DT? moment(row.er_TR_DT, "YYYYMMDD").toDate(): null}
                  onChange={(e) => onDateChange({ target: { name: "er_TR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  showIcon
                  dateFormat="dd/mm/yy"
                />
                </td>
                <td>
                <Calendar style={{width:"150px"}}
                  id={`er_TR_DT_${index}`}
                  name={`er_TR_DT_${index}`}
                  value={row.er_TR_DT? moment(row.er_TR_DT, "YYYYMMDD").toDate(): null}
                  onChange={(e) => onDateChange({ target: { name: "er_TR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  showIcon
                  dateFormat="dd/mm/yy"
                />
                </td>

         
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <ItemPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleEmployee}
        formData={data}
      />
      <ManagerPopup
        visible={ManagerPopupVisible}
        onClose={() => setManagerPopupVisible(false)}
        onDataSelect={handleManager}
        formData={data}
      />
    </Dialog>
  );
}
