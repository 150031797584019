import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import SupplierPopup from "../Popups/SupplierPopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import Costcenter from "views/common/CostCentreDrpDwn";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
import ItemPopup from "../Popups/ItemPopup";
export default function CycleCountProcessDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  CycleCountProcessTableData,
  setCycleCountProcessTableData,
  setep_EX_CT,
  cc_CO_CT,
  setcc_CO_CT,
  cc_PR_UM,
  setcc_PR_UM,
  cc_LO_CN,
  setcc_LO_CN,
  cc_CN_TY,
  cc_CN_CO,
  cc_CN_NO,
}) {
  const {
    ccUkNo,
    cc_CN_DT,
    cc_IT_NM,
    pi_OR_TY,
    pi_OR_NO,
    pi_CO_CT,
    pi_TR_CR,
    pi_SU_NO,
    cus_NAME,
    pi_SH_TO,
    shipto_NAME,
    pi_PR_DT,
    pi_OR_DT,
    pi_RQ_DT,
    pi_PY_TR,
    pi_PO_DT,
    pi_IT_NM,
    item_DES,
    pi_OR_QT,
    pi_TR_UM,
    pi_UT_PR,
    pi_DS_PR,
    pi_FR_PR,
    pi_TX_PE,
    pi_RC_DT,
    pi_IN_NO,
    pi_IN_DT,
    pi_LO_CN,
    pi_LT_NO,
    pi_EX_DT,
    pi_IV_CT,
    pi_TR_DT,

  } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, multitableheader } = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "cc_CN_DT", value: moment(e.value).format("YYYYMMDD") } });
    // onDateChange({ target: { name: "pi_RQ_DT", value: moment(e.value).format("YYYYMMDD") } });
    //  onDateChange({ target: { name: "pi_PO_DT", value: moment(e.value).format("YYYYMMDD") } });
  };

  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter, fetchCostCenterP } = Costcenter();
  const [cc_CO_CTOptions, setcc_CO_CTOptions] = useState([]);
  const [cc_PR_UMOptions, setcc_PR_UMOptions] = useState([]);
  const [cc_LO_CNOptions, setcc_LO_CNOptions] = useState([]);

  



   // Cost Center
   const CostCenterDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcc_CO_CT(value);
  };
  // Location
  const LocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcc_LO_CN(value);
  };
  // Location
  const UMDropdown = (name, value) => {
    const index = name.split("_")[3];
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setcc_PR_UM(value);
  };

  useEffect(() => { 
    Location();
    UM();
    CostCenter();

  }, []);



  const CostCenter = async () => {
    const Options = await fetchCostCenter();
    setcc_CO_CTOptions(Options);
  };
  // const Location = async () => {
  //   const Options = await fetchClassCodeCD("FO/CR");
  //   setcc_LO_CNOptions(Options);
  // };

  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setcc_PR_UMOptions(Options);
  };
  const addRow = () => {
    setCycleCountProcessTableData([...CycleCountProcessTableData, {}]);
  };

  const deleteRow = () => {
    if (CycleCountProcessTableData.length > 0) {
      const updatedData = [...CycleCountProcessTableData];
      updatedData.pop();
      setCycleCountProcessTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(ccUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
        tooltip={isUpdateMode ? "Update" : "Submit"}>
      </Button>
      {isUpdateMode && (
        <Button
          style={{ width: "5%" }}
          icon="pi pi-circle"
          onClick={(e) => handleFormSubmit("process")}
          tooltip="Process"
        />
      )}
    </React.Fragment>
  );

  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ItemVisible, setItemVisible] = useState(false);





  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");




  const handleItem = async (data) => {
    document.getElementById(`cc_IT_NM_${SelectedRowIndex}`).value = data.im_IT_NM;
    document.getElementById(`item_DES_${SelectedRowIndex}`).value = data.im_IT_DS;

    setItemVisible(false);
    setShowSearchIcon(false);
  };

  const urlloc = `${domain}/api/LocationMaster/DropDown`;
  const finalUrl1 = `${urlloc}`;
  const finalUrl2 = `${urlloc}`;
   
  const Location = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.lm_LO_CN,
        value: item.lm_LO_CN,
      }));
      setcc_LO_CNOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const Tolocation = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.lm_LO_CN,
        value: item.lm_LO_CN,
      }));
      setcc_LO_CNOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };


  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "85rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={ccUkNo ? "Revise Cycle Count Process" : "Add Cycle Count Process"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={inboxstyle}>
        <div className="formgrid grid">

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cc_CN_NO" className="font-bold">
              Count No
            </label>
            <InputText disabled
              id="cc_CN_NO"
              name="cc_CN_NO"
              value={cc_CN_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cc_CN_TY" className="font-bold">
              Type
            </label>
            <InputText disabled
              id="cc_CN_TY"
              name="cc_CN_TY"
              value={cc_CN_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cc_CN_CO" className="font-bold">
              Company
            </label>
            <InputText disabled
              id="cc_CN_CO"
              name="cc_CN_CO"
              value={cc_CN_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cc_CN_DT" className="font-bold">
              Date
            </label>
            <Calendar
              id="cc_CN_DT"
              name="cc_CN_DT"
              value={moment(cc_CN_DT, "YYYYMMDD").toDate()}
              onChange={handleDateChange}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cc_CO_CT" className="font-bold">
            Cost Centre
            </label>
            <Dropdown
              name="cc_CO_CT"
              value={cc_CO_CT}
              options={cc_CO_CTOptions}
              onChange={(e) => CostCenterDropdown("cc_CO_CT", e.value)}
              placeholder="Cost Centre"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cc_LO_CN" className="font-bold">
            Location
            </label>
            <Dropdown
              name="cc_LO_CN"
              value={cc_LO_CN}
              options={cc_LO_CNOptions}
              onChange={(e) => LocationDropdown("cc_LO_CN", e.value)}
              placeholder="Location"
            />
          </div>

        </div>
      </div>
      <div style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
            <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
          )}
          {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div style={multitablestyle}>
          <table className="custom-table" id="CycleCountProcessTableData">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Line NO</th>
                <th>Item Numbe<span style={{ color: 'red' }}>*</span></th>
                <th>Description</th>
                <th>Lot Number</th>
                <th>On Hand Qty</th>
                <th>UM</th>
                <th>Counted Qty</th>
                <th>Unit Cost	</th> 
                <th>On Hand Amount</th>
                <th>Counted Amount</th>
                <th>Sec Qty</th>
                <th>Counted Sec Qty</th>
              </tr>
            </thead>
            <tbody>
              {CycleCountProcessTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`ccUkNo_${index}`}
                      name={`ccUkNo_${index}`}
                      value={row.ccUkNo}
                    />
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "90px" }}
                      id={`cc_LN_NO_${index}`}
                      name={`cc_LN_NO_${index}`}
                      value={index + 1}
                      readOnly
                    />
                  </td>
                  <td
                    style={{ display: "flex" }}>
                    <InputText
                      style={{ width: "110px" }}
                      id={`cc_IT_NM_${index}`}
                      name={`cc_IT_NM_${index}`}
                      onChange={(e) => onchangeFormData(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.cc_IT_NM ? row.cc_IT_NM : row[`${"cc_IT_NM"}_${index}`]}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "15%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openItemPopup(index)}
                      ></i>
                    )}
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "200px" }}
                      id={`item_DES_${index}`}
                      name={`item_DES_${index}`}
                      value={row.item_DES ? row.item_DES : row[`${"item_DES"}_${index}`]}
                      readOnly
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`cc_LT_NO_${index}`}
                      name={`cc_LT_NO_${index}`}
                      defaultValue={row.cc_LT_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`cc_ON_QT_${index}`}
                      name={`cc_ON_QT_${index}`}
                      defaultValue={row.cc_ON_QT}
                      onChange={(e) => onchangedata(e)}
                      
                    />
                  </td>
                  <td>
                  <Dropdown
                    id={`cc_PR_UM_${index}`}
                    name={`cc_PR_UM_${index}`}
                    value={ data[`cc_PR_UM_${index}`] ? data[`cc_PR_UM_${index}`]: row.cc_PR_UM}
                    options={cc_PR_UMOptions}
                    onChange={(e) =>UMDropdown(`cc_PR_UM_${index}`, e.value)}
                    placeholder="UM"
                  />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`cc_CN_QT_${index}`}
                      name={`cc_CN_QT_${index}`}
                      defaultValue={row.cc_CN_QT}
                      onChange={(e) => onchangedata(e)}
                      
                    />
                  </td>
                  
                  <td >
                    <InputText style={{ width: "100px" }} 
                      id={`cc_IT_CT_${index}`}
                      name={`cc_IT_CT_${index}`}
                      defaultValue={row.cc_IT_CT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td> 
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`cc_ON_AM_${index}`}
                      name={`cc_ON_AM_${index}`}
                      defaultValue={row.cc_ON_AM}
                      onChange={(e) => onchangedata(e)}
                      
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`cc_CN_AM_${index}`}
                      name={`cc_CN_AM_${index}`}
                      defaultValue={row.cc_CN_AM}
                      onChange={(e) => onchangedata(e)}
                      
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`cc_SC_QT_${index}`}
                      name={`cc_SC_QT_${index}`}
                      defaultValue={row.cc_SC_QT}
                      onChange={(e) => onchangedata(e)}
                     // disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`cc_CN_SC_${index}`}
                      name={`cc_CN_SC_${index}`}
                      defaultValue={row.cc_CN_SC}
                      onChange={(e) => onchangedata(e)}
                      
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* item */}
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
