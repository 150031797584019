import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import moment from "moment";
import axios from "axios";
export default function BankReconcilationDialog({
  open,
  handleClose,
  data,
  onchangeFormData,
  onchangeDropDownFormData1,
  addRow,
  deleteRow,
}) {
  const { 
  brUkNo,
  br_AC_NO,
  } = data;

  const [br_RE_FLOptions, setbr_RE_FLOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      const formattedDate1 = moment(selectedDate).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      const formattedDate1 = moment(value).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "rc_ST_DT"  || name === "rc_EN_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);
  
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };
  const url = `${domain}/api/ClassCode/All`;
  // comp type
  const parameter1 = "ME/PL";
  const finalUrl1 = `${url}/${parameter1}`;

  useEffect(() => {
    fetchDropdownOptions1();
  }, []);

// 1st dropdown
const fetchDropdownOptions1 = async () => {
  try {
    const response = await axios.get(finalUrl1, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setbr_RE_FLOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

// Advance Search
const [SelectedemEMNO, setSelectedemEMNO] = useState("");
const [SelectedRowIndex, setSelectedRowIndex] = useState("");
const [showSearchIcon, setShowSearchIcon] = useState("");
const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
const openAccountPopup = (e) => {
  setAccountPopupVisible(true);
  setSelectedRowIndex(e);
};
const handleDataSelect1 = async (data) => {
  setSelectedemEMNO(data.am_AC_DS);
  onchangeFormData("br_AC_NO", data.account);
  setAccountPopupVisible(false);
};


// Function
const [BankReconcilationtable, setBankReconcilationtable] = useState([]); 
const Refresh = async () => {
const Url = `${domain}/api/BankReconManual/RefreshBankRecon`;
    const requestData={
      "br_AC_NO": document.getElementById("br_AC_NO").value,
      
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.Result;
  
      if (dataLead && dataLead.length > 0) {
        setBankReconcilationtable(dataLead.map(item => ({
          brUkNo: item.brUkNo,
          br_RF_DT: item.br_RF_DT,
          br_RE_FL: item.br_RE_FL,
          br_RE_DT: item.br_RE_DT,
          br_AC_AM: item.br_AC_AM,
          br_RC_DT: item.br_RC_DT,
          br_GL_DT: item.br_GL_DT,
          br_AB_NO: item.br_AB_NO,
          br_DO_NO: item.br_DO_NO,
          br_DO_TY: item.br_DO_TY,
          br_DO_CO: item.br_DO_CO,
        })));
  
      } else {
        console.error("Invalid API response:", data );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
const isUpdateMode = Boolean(brUkNo);
  const productDialogFooter = (
    <React.Fragment>
   
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      // header={brUkNo ? "Bank Reconcilation" : "Add Bank Reconcilation"}
      header={"Bank BankReconcilation"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
    <div className="formgrid grid" style={{display:"flex",justifyContent:"space-between"}}>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="br_AC_NO" className="font-bold">
            Bank Account <span style={{ color: 'red' }}>*</span>
            </label>
                <div style={{ display: "flex" }}>
                    <InputText
                      id="am_AC_DS"
                      name="am_AC_DS"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedemEMNO}
                    />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openAccountPopup}
                  ></i>
                  )}
                </div>
                <div style={{ display: "none" }}>
                  <InputText
                    id="br_AC_NO"
                    name="br_AC_NO"
                    value={br_AC_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
        </div> 
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2" style={{marginTop:"3%"}}>
          <Button
            style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "#03a9f485",
                border: "none", 
                width:"40%"
            }}
            type="button"
            label="Refresh"
            icon="pi pi-refresh"
            onClick={Refresh}
            // tooltip={isUpdateMode ? "Update" : "Submit"}
          >
      </Button>
        </div>
    </div>
  </div>
      
      <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            marginTop: "2%",
          }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
          }}
        >
          {!isUpdateMode && (
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          )}
           {!isUpdateMode && (
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
          )}
        </div>
        <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
            position: "relative",
          }}
        >
        <table className="custom-table" id="BankReconcilationtable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Date</th>
          <th>Reconsiled Flag</th>
          <th>Reconciled Date</th>
          <th>Amount</th>
          <th>Cheque Date</th>
          <th>GL Date</th>
          <th>Address No</th>
          <th>Description</th>
          <th>Document No</th>
          <th>Type</th>
          <th>Company</th>
        </tr>
      </thead>
      <tbody>
      {BankReconcilationtable.map((row, index) => (
              <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
              id={`brUkNo_${index}`}
              name={`brUkNo_${index}`}
              defaultValue={row.brUkNo}
            />
          </td>
          <td>
          <Calendar  disabled style={{width:"150px"}}
            id={`br_RF_DT_${index}`}
            name={`br_RF_DT_${index}`}
            value={ row.br_RF_DT ? moment(row.br_RF_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "br_RF_DT", value: moment(e.value).format("YYYYMMDD") } })}
            showIcon
            dateFormat="dd/mm/yy"
          />
          </td>
          <td>
          <Dropdown 
          id={`br_RE_FL_${index}`}
          name={`br_RE_FL_${index}`} 
          value={data[`br_RE_FL_${index}`] ? data[`br_RE_FL_${index}`] : row.br_RE_FL} 
          options={[
          { label: "Yes", value: "y" },
          { label: "No", value: "n" },
          ]}
          onChange={(e) => handleFormDataChange(`br_RE_FL_${index}`, e.value)} 
          placeholder="Select Reco.Flag"
          />
          </td>
          <td>
          <Calendar disabled style={{width:"150px"}}
            id={`br_RE_DT_${index}`}
            name={`br_RE_DT_${index}`}
            value={ row.br_RE_DT ? moment(row.br_RE_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "br_RE_DT", value: moment(e.value).format("YYYYMMDD") } })}
            showIcon
            dateFormat="dd/mm/yy"
          />
          
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`br_AC_AM_${index}`}
            name={`br_AC_AM_${index}`}
            defaultValue={row.br_AC_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <Calendar disabled style={{width:"150px"}}
            id={`br_RC_DT_${index}`}
            name={`br_RC_DT_${index}`}
            value={ row.br_RC_DT ? moment(row.br_RC_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "br_RC_DT", value: moment(e.value).format("YYYYMMDD") } })}
            showIcon
            dateFormat="dd/mm/yy"
          />
          </td>
          <td>
          <Calendar disabled style={{width:"150px"}}
            id={`br_GL_DT_${index}`}
            name={`br_GL_DT_${index}`}
            value={ row.br_GL_DT ? moment(row.br_GL_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "br_GL_DT", value: moment(e.value).format("YYYYMMDD") } })}
            showIcon
            dateFormat="dd/mm/yy"
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`br_AB_NO_${index}`}
            name={`br_AB_NO_${index}`}
            defaultValue={row.br_AB_NO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`rc_CO_ST_${index}`}
            name={`rc_CO_ST_${index}`}
            defaultValue={row.rc_CO_ST}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`br_DO_NO_${index}`}
            name={`br_DO_NO_${index}`}
            defaultValue={row.br_DO_NO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText disabled  style={{width:"90px"}}
            id={`br_DO_TY_${index}`}
            name={`br_DO_TY_${index}`}
            defaultValue={row.br_DO_TY}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}}
            id={`br_DO_CO_${index}`}
            name={`br_DO_CO_${index}`}
            defaultValue={row.br_DO_CO}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          </tr>
           ))}
      </tbody>
    </table>   
    
    </div>
  </div>   
  <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
    </Dialog>
  );
}
