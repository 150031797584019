import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ManagerPopup from "../Popups/ManagerPopup";
import EmployeePopup from "../Popups/EmployeePopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import Costcenter  from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function ExpenseReportDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  ExpenseReportTableData,
  setExpenseReportTableData,
  er_AD_CR,
  seter_AD_CR,
  er_RE_CR,
  seter_RE_CR,
  er_EX_CT,
  seter_EX_CT,
  er_EX_TY,
  seter_EX_TY,
  er_CO_CT,
  seter_CO_CT,
  er_LO_CN,
  seter_LO_CN,
  er_ST_AT,
  seter_ST_AT,
  er_TR_CR,
  seter_TR_CR,
  er_EX_CO,
  er_TY_PE,
  StatusDropdown,
  SelectedEmployee,
  setSelectedEmployee,
  SelectedManager,
  setSelectedManager,
}) {
  const {
    erUkNo,
    er_EX_NO,
    er_EM_NO,
    er_MN_NO,
    er_RP_DT,
    er_BU_PR,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,multitableheader} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [er_EX_TYOptions, seter_EX_TYOptions] = useState([]);
  const [er_AD_CROptions, seter_AD_CROptions] = useState([]);
  const [er_RE_CROptions, seter_RE_CROptions] = useState([]);
  const [er_ST_ATOptions, seter_ST_ATOptions] = useState([]);
  const [er_EX_CTOptions, seter_EX_CTOptions] = useState([]);
  const [er_LO_CNOptions, seter_LO_CNOptions] = useState([]);
  const [er_CO_CTOptions, seter_CO_CTOptions] = useState([]);
  const [er_TR_CROptions, seter_TR_CROptions] = useState([]);

  // Expense Type
  const ExpenseTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seter_EX_TY(value);
};
  // Advance Curr
  const AdvanceCurrDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seter_AD_CR(value);
  };
 // Reim Curr
  const ReimburseCurrDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  seter_RE_CR(value);
  };
 // Expense Category
const ExpenseCategoryDropdown = (name, value) => {
  const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seter_EX_CT(value);
};
// CostCentre
const CostCentreDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  seter_CO_CT(value);
};
  // Location
const LocationDropdown = (name, value) => {
  const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seter_LO_CN(value);
};
  // Currency
  const CurrencyDropdown = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      seter_TR_CR(value);
  };

  useEffect(() => {
    ExpenseType();
    ReimCurrency();
    AdvanceCurrency();
    Status();
    ExpenseCategory();
    Location();
    CostCenter();
    Currency();

  }, []);

  const ExpenseType = async () => {
    const Options = await fetchClassCodeDS("EX/TY");
    seter_EX_TYOptions(Options);
  };

  const ReimCurrency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    seter_RE_CROptions(Options);
  };

  const AdvanceCurrency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    seter_AD_CROptions(Options);
  };
  const Status = async () => {
    const Options = await fetchClassCodeDS("EX/ST");
    seter_ST_ATOptions(Options);
  };
  const ExpenseCategory = async () => {
    const Options = await fetchClassCodeDS("EX/CT");
    seter_EX_CTOptions(Options);
  };

  const Location = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    seter_LO_CNOptions(Options);
  };

  const CostCenter = async () => {
    const Options = await fetchCostCenter();
    seter_CO_CTOptions(Options);
  };

  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    seter_TR_CROptions(Options);
  };

  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);
  const [ManagerPopupVisible, setManagerPopupVisible] = useState(false);
  const openEmployeePopup = () => {
    setEmployeePopupVisible(true);
  }
  const openManagerPopup = () => {
    setManagerPopupVisible(true);
  }
  const handleEmployee = async (data) => {
    setSelectedEmployee(data.ab_AD_NM);
    onchangeFormData("er_EM_NO", data.abAdNo);
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleManager = async (data) => {
    setSelectedManager(data.ab_AD_NM);
    onchangeFormData("er_MN_NO", data.abAdNo);
    setManagerPopupVisible(false);
    setShowSearchIcon(false);
  };


  const addRow = () => {
    setExpenseReportTableData([...ExpenseReportTableData, {}]);
  };
  
  const deleteRow = () => {
    if (ExpenseReportTableData.length > 0) {
      const updatedData = [...ExpenseReportTableData];
      updatedData.pop();
      setExpenseReportTableData(updatedData);
    }
  }; 
  // DATE DFEFAULT
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "er_RP_DT", value: moment(e.value).format("YYYYMMDD") } });
  }; 
const isUpdateMode = Boolean(erUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

 
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={erUkNo ? "Revise Expense Report" : "Add Expense Report"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div  style={inboxstyle}>
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="er_EX_NO" className="font-bold">
           Expense No
          </label>
          <InputText disabled
            id="er_EX_NO"
            name="er_EX_NO"
            defaultValue={er_EX_NO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="er_TY_PE" className="font-bold">
           Type 
          </label>
          <InputText disabled
            id="er_TY_PE"
            name="er_TY_PE"
            value={er_TY_PE}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="er_EX_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="er_EX_CO"
            name="er_EX_CO"
            value={er_EX_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="er_EM_NO" className="font-bold">
              Employee <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="employee_NAME"
                      name="employee_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedEmployee}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openEmployeePopup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="er_EM_NO"
                      name="er_EM_NO"
                      value={er_EM_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="er_MN_NO" className="font-bold">
              Manager <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="manager_NAME"
                      name="manager_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedManager}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openManagerPopup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="er_MN_NO"
                      name="er_MN_NO"
                      value={er_MN_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="er_RP_DT" className="font-bold">
                Date
            </label>
            <Calendar
              id="er_RP_DT"
              name="er_RP_DT"
              value={moment(er_RP_DT, "YYYYMMDD").toDate()}
              onChange={handleDateChange}
              dateFormat="dd/mm/yy"
            />
          </div>
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="er_EX_TY" className="font-bold">
                     Expense type
                    </label>
                    <Dropdown
                    name="er_EX_TY"
                    value={er_EX_TY}
                    options={er_EX_TYOptions}
                    onChange={(e) => ExpenseTypeDropdown("er_EX_TY", e.value)}
                    placeholder="Select Expense Type"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="er_AD_CR" className="font-bold">
                     Advance Currency
                    </label>
                    <Dropdown
                    name="er_AD_CR"
                    value={er_AD_CR}
                    options={er_AD_CROptions}
                    onChange={(e) => AdvanceCurrDropdown("er_AD_CR", e.value)}
                    placeholder="Select Currency"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="er_RE_CR" className="font-bold">
                     ReimBurse Currency
                    </label>
                    <Dropdown
                    id="er_RE_CR"
                    name="er_RE_CR"
                    value={er_RE_CR}
                    options={er_RE_CROptions}
                    onChange={(e) => ReimburseCurrDropdown("er_RE_CR", e.value)}
                    placeholder="Select Currency"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="er_ST_AT" className="font-bold">
                    Status
                    </label>
                    <Dropdown
                    id="er_ST_AT"
                    name="er_ST_AT"
                    value={er_ST_AT}
                    options={er_ST_ATOptions}
                    onChange={(e) => StatusDropdown("er_ST_AT", e.value)}
                    placeholder="Select Status"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="er_BU_PR" className="font-bold">
                    Buisiness Purpose
                    </label>
                    <InputText
                      id="er_BU_PR"
                      name="er_BU_PR"
                      value={er_BU_PR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>    
          </div>
          </div>  
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="operationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Expense Category</th>
              <th>Date</th>
              <th>Cost Centre</th>
              <th>Location</th>
              <th>Quantity</th>
              <th>Rate</th>
              <th>Amount</th>
              <th>Currency</th>
              <th>Allowed Amount </th>
            </tr>
          </thead>
          <tbody>
            {ExpenseReportTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`erUkNo_${index}`}
                    name={`erUkNo_${index}`}
                    value={row.erUkNo}
                  />
                </td>
                <td>
                  <Dropdown
                    id={`er_EX_CT_${index}`}
                    name={`er_EX_CT_${index}`}
                    value={ data[`er_EX_CT_${index}`] ? data[`er_EX_CT_${index}`]: row.er_EX_CT}
                    options={er_EX_CTOptions}
                    onChange={(e) =>ExpenseCategoryDropdown(`er_EX_CT_${index}`, e.value)}
                    placeholder="Select Exp Category"
                  />
                  </td>
                  <td>
                <Calendar style={{width:"150px"}}
                id={`er_TR_DT_${index}`}
                name={`er_TR_DT_${index}`}
                value={row.er_TR_DT? moment(row.er_TR_DT, "YYYYMMDD").toDate(): null}
                onChange={(e) => onDateChange({ target: { name: "er_TR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                dateFormat="dd/mm/yy"
                />
                </td>
                <td>
                  <Dropdown style={{width:"120px"}}
                    id={`er_CO_CT_${index}`}
                    name={`er_CO_CT_${index}`}
                    value={ data[`er_CO_CT_${index}`] ? data[`er_CO_CT_${index}`]: row.er_CO_CT}
                    options={er_CO_CTOptions}
                    onChange={(e) =>CostCentreDropdown(`er_CO_CT_${index}`, e.value)}
                    placeholder="Select Cost Centre"
                  />
                  </td>
                <td>
                  <Dropdown
                    id={`er_LO_CN_${index}`}
                    name={`er_LO_CN_${index}`}
                    value={ data[`er_LO_CN_${index}`] ? data[`er_LO_CN_${index}`]: row.er_LO_CN}
                    options={er_LO_CNOptions}
                    onChange={(e) =>LocationDropdown(`er_LO_CN_${index}`, e.value)}
                    placeholder="Select Location"
                  />
                  </td>
                  <td >
                  <InputText style={{width:"90px"}}
                    id={`er_TR_QT_${index}`}
                    name={`er_TR_QT_${index}`}
                    defaultValue={row.er_TR_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`er_RA_TE_${index}`}
                    name={`er_RA_TE_${index}`}
                    defaultValue={row.er_RA_TE}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`er_EX_AM_${index}`}
                    name={`er_EX_AM_${index}`}
                    defaultValue={row.er_EX_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Dropdown style={{width:"120px"}}
                    id={`er_TR_CR_${index}`}
                    name={`er_TR_CR_${index}`}
                    value={ data[`er_TR_CR_${index}`] ? data[`er_TR_CR_${index}`]: row.er_TR_CR}
                    options={er_TR_CROptions}
                    onChange={(e) =>CurrencyDropdown(`er_TR_CR_${index}`, e.value)}
                    placeholder="Select Currency"
                  />
                  </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`er_AL_AM_${index}`}
                    name={`er_AL_AM_${index}`}
                    defaultValue={row.er_AL_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
         
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <EmployeePopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleEmployee}
        formData={data}
      />
        <ManagerPopup
        visible={ManagerPopupVisible}
        onClose={() => setManagerPopupVisible(false)}
        onDataSelect={handleManager}
        formData={data}
      />
    </Dialog>
  );
}
