import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import PropertyInspectionDialog from "./PropertyInspectionDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  piUkNo:"",
  pi_TR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  pi_TR_NO:"",
  pi_TR_TY:"",
  pi_TR_CO:"",
  pi_UN_NO:"", 
  pi_FL_NO:"",
  pi_BL_NO:"",
  pi_PR_NO:"",
  pi_LE_NO:"",
  pi_LE_TY:"", 
  pi_TE_NT:"",
  pi_IT_NM:"", 
  pi_ST_AT:"", 
};

const initialFilters = {
  pi_TR_DT: { value: "", operation: "" },
  pi_TR_NO: { value: "", operation: "" },
  pi_TR_TY: { value: "", operation: "" },
  pi_TR_CO: { value: "", operation: "" },
  pi_UN_NO: { value: "", operation: "" },
  pi_FL_NO: { value: "", operation: "" },
  pi_BL_NO: { value: "", operation: "" },
  pi_PR_NO: { value: "", operation: "" },
  pi_LE_NO: { value: "", operation: "" },
  pi_LE_TY: { value: "", operation: "" },
  pi_TE_NT: { value: "", operation: "" },
  pi_IT_NM: { value: "", operation: "" },
  pi_ST_AT: { value: "", operation: "" },
};

const PropertyInspectionMain = () => {
    // buttonstyles
    const {formatDate, buttonStyles, addButtonStyles,
      copyButtonStyles,
      checkboxStyle,tableHeaderStyle,
      inputStyle,tableCellStyle,
     } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
// drodpdowns
  const [pi_LE_TY, setpi_LE_TY ] = useState("");
  const [pi_ST_AT, setpi_ST_AT ] = useState("");

  const [Propertytable, setPropertytable] = useState([{}]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setPropertytable([{}]);
    setSelectedleaseno(null);
    //setSelectedleaseType(null);
    setSelectedTenent(null);
    setSelectedUnit(null);
    setSelectedProperty(null);
    setSelectedBuilding(null);
    setSelectedFloor(null);
    setSelectedTenentname(null);
    setpi_LE_TY(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/PropertyInspection/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const PropertyInspectionData = response.data;
          delete PropertyInspectionData.piUkNo;
          delete PropertyInspectionData.pi_TR_NO;
          delete PropertyInspectionData.propertyInspectionList[0].pi_TR_NO;
          for (let i = 0; i < PropertyInspectionData.propertyInspectionList.length; i++) {
            delete PropertyInspectionData.propertyInspectionList[i].piUkNo;
          }
          setFormData(PropertyInspectionData);
          setPropertytable(PropertyInspectionData.propertyInspectionList);
          setpi_LE_TY(PropertyInspectionData.pi_LE_TY);
          setpi_ST_AT(PropertyInspectionData.pi_ST_AT);
          setSelectedleaseno(PropertyInspectionData.pi_LE_NO);
          setSelectedleaseType(PropertyInspectionData.pi_LE_TY);
          setSelectedTenent(PropertyInspectionData.pi_TE_NT);
          setSelectedUnit(PropertyInspectionData.pi_UN_NO);
          setSelectedProperty(PropertyInspectionData.pi_PR_NO);
          setSelectedBuilding(PropertyInspectionData.pi_BL_NO);
          setSelectedFloor(PropertyInspectionData.pi_FL_NO);
          setSelectedTenentname(PropertyInspectionData.name);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [pi_TR_CO , setpi_TR_CO] = useState("");
  const [pi_TR_TY , setpi_TR_TY] = useState("");
  // status
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpi_ST_AT(value);
  };
    const handleDefault = async () => {
      setpi_TR_CO(`${company}`)
      const linkPath = String(window.location);
      const l_array = linkPath.split("=");
      const LinkId = l_array[1];
      
      const requestBody = {
        "version_ID" : LinkId
      };
      const paginationUrl = `${domain}/api/LinkId/Select`;
      try {
        const response = await axios.post(paginationUrl, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataDefault = response.data;
        const listDefault = [];
        var count = 0;
        if (dataDefault.versionIds != null) {
          for (let i = 0; i < dataDefault.versionIds.length; i++) {
            if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
              const fName = dataDefault.versionIds[i].field_ID;
              if(fName === "PI_TR_TY"){
                setpi_TR_TY(dataDefault.versionIds[i].default_VALUE)
              }
              if(fName === "PI_ST_AT"){
                onchangeDropDownFormData2("pi_ST_AT",dataDefault.versionIds[i].default_VALUE);
                setpi_ST_AT(dataDefault.versionIds[i].default_VALUE);
              } 
              if(fName === "PI_LE_TY"){
                setSelectedleaseType(dataDefault.versionIds[i].default_VALUE);
              }
            }
            if(dataDefault.versionIds[i].field_TYPE === "LIST"){
              count++;
              const def = {
                "column" : dataDefault.versionIds[i].field_ID ,
                "value" : dataDefault.versionIds[i].default_VALUE,
                "operation" : "EQUAL"
              }
              listDefault.push(def);
            }
          }
          handleSearch(listDefault,count);
        } else {
          handleSearch(listDefault,count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };


  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PropertyInspection/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const [Selectedleaseno,setSelectedleaseno] =useState("");
  const [SelectedleaseType,setSelectedleaseType] =useState("");
  const [SelectedTenent,setSelectedTenent] =useState("");
  const [SelectedUnit,setSelectedUnit] =useState("");
  const [SelectedProperty,setSelectedProperty] =useState("");
  const [SelectedBuilding,setSelectedBuilding] =useState("");
  const [SelectedFloor,setSelectedFloor] =useState("");
  const [SelectedTenentname,setSelectedTenentname]=useState("");

  const onchangeFormData = (name, value) => {
    // setFormData({ ...FormData, [name]: value });
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };
 
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/PropertyInspection/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const PropertyInspectionData = response.data;
        setFormData(PropertyInspectionData);
        setPropertytable(PropertyInspectionData.propertyInspectionList);
        setpi_LE_TY(PropertyInspectionData.pi_LE_TY);
        setpi_ST_AT(PropertyInspectionData.pi_ST_AT);
        setSelectedleaseno(PropertyInspectionData.pi_LE_NO);
        setSelectedleaseType(PropertyInspectionData.pi_LE_TY);
        setSelectedTenent(PropertyInspectionData.pi_TE_NT);
        setSelectedUnit(PropertyInspectionData.pi_UN_NO);
        setSelectedProperty(PropertyInspectionData.pi_PR_NO);
        setSelectedBuilding(PropertyInspectionData.pi_BL_NO);
        setSelectedFloor(PropertyInspectionData.pi_FL_NO);
        setSelectedTenentname(PropertyInspectionData.name);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  var requestBodyAPIInput = {};
  function values  (){
    const table = document.getElementById("Propertytable");
    const totalRowCount = table.tBodies[0].rows.length;
          
          const propertyInspectionList = [];
          for (let i = 0; i < totalRowCount; i++) {
            const Details = {
              piUkNo: document.getElementById("piUkNo_" + i).value,
              pi_IT_NM: document.getElementById("pi_IT_NM_" + i).value,
              pi_CN_ON: document.getElementById("pi_CN_ON_" + i).value,
              pi_RE_MK: document.getElementById("pi_RE_MK_" + i).value,           
            
            };
            propertyInspectionList.push(Details);
          }
          requestBodyAPIInput = {
            propertyInspectionList,
            piUkNo  : document.getElementById("piUkNo").value,
            pi_TR_NO: document.getElementById("pi_TR_NO").value,
            pi_TR_TY: document.getElementById("pi_TR_TY").value,
            pi_TR_CO: FormData.pi_TR_CO,
            pi_LE_NO: document.getElementById("pi_LE_NO").value,
            pi_LE_TY: document.getElementById("pi_LE_TY").value,
            pi_TR_DT: FormData.pi_TR_DT,
            pi_TE_NT: document.getElementById("pi_TE_NT").value,
            pi_UN_NO: document.getElementById("pi_UN_NO").value,
            pi_FL_NO: document.getElementById("pi_FL_NO").value,
            pi_BL_NO: document.getElementById("pi_BL_NO").value,
            pi_ST_AT: FormData.pi_ST_AT,
            pi_PR_NO: document.getElementById("pi_PR_NO").value    
          };
  }

  const handleFormSubmit = () => {    
    values ();
   
    if (FormData.pi_TR_NO) {
      if (FormData.piUkNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/PropertyInspection/Update`;
        if (confirm) {
          axios
            .put(update, requestBodyAPIInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        axios
          .post(`${domain}/api/PropertyInspection/Add`, requestBodyAPIInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
          handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }

    } else {

      const OR_DT = FormData.pi_TR_DT;
        if (OR_DT) {
          const currentYear = OR_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${pi_TR_TY}/${secondHalf}/${company}/${firstHalf}`; 
          const finalUrl1 = `${urlNextNumber}/${parameters}`;
          fetch(finalUrl1, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
          })
            .then((resp) => {
              if (!resp.ok) {
                throw new Error(`Request failed with status: ${resp.status}`);
              }
              return resp.json();
            })
            .then((resp) => {
              onchangeFormData("pi_TR_NO", resp.dn_DO_NO);
              setFormData({ ...FormData, ["pi_TR_NO"]: resp.dn_DO_NO });

              values ();

              axios
                  .post(`${domain}/api/PropertyInspection/Add`, requestBodyAPIInput, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "http://localhost:3000",
                      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                    },
                  })
                  .then((resp) => {
                    handleResponse(resp.data);
                  })
                  .catch((error) => {
                    console.error("Error saving data:", error);
                  });

            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
          }
    }
    } ;

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_TR_DT"] ? filters["pi_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_TR_DT",
                        e.target.value,
                        filters["pi_TR_DT"]
                          ? filters["pi_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_TR_NO"] ? filters["pi_TR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_TR_NO",
                        e.target.value,
                        filters["pi_TR_NO"] ? filters["pi_TR_NO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_TR_TY"] ? filters["pi_TR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_TR_TY",
                        e.target.value,
                        filters["pi_TR_TY"]
                          ? filters["pi_TR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_TR_CO"] ? filters["pi_TR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_TR_CO",
                        e.target.value,
                        filters["pi_TR_CO"]
                          ? filters["pi_TR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_UN_NO"] ? filters["pi_UN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_UN_NO",
                        e.target.value,
                        filters["pi_UN_NO"]
                          ? filters["pi_UN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_FL_NO"] ? filters["pi_FL_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_FL_NO",
                        e.target.value,
                        filters["pi_FL_NO"]
                          ? filters["pi_FL_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_BL_NO"] ? filters["pi_BL_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_BL_NO",
                        e.target.value,
                        filters["pi_BL_NO"] ? filters["pi_BL_NO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_PR_NO"] ? filters["pi_PR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_PR_NO",
                        e.target.value,
                        filters["pi_PR_NO"]
                          ? filters["pi_PR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_LE_NO"] ? filters["pi_LE_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_LE_NO",
                        e.target.value,
                        filters["pi_LE_NO"]
                          ? filters["pi_LE_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_LE_TY"] ? filters["pi_LE_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_LE_TY",
                        e.target.value,
                        filters["pi_LE_TY"]
                          ? filters["pi_LE_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_TE_NT"] ? filters["pi_TE_NT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_TE_NT",
                        e.target.value,
                        filters["pi_TE_NT"]
                          ? filters["pi_TE_NT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_CN_CT"] ? filters["cp_CN_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_CN_CT",
                        e.target.value,
                        filters["cp_CN_CT"]
                          ? filters["cp_CN_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_IT_NM"] ? filters["pi_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_IT_NM",
                        e.target.value,
                        filters["pi_IT_NM"]
                          ? filters["pi_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pi_ST_AT"] ? filters["pi_ST_AT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pi_ST_AT",
                        e.target.value,
                        filters["pi_ST_AT"]
                          ? filters["pi_ST_AT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Date</th>
                <th style={tableHeaderStyle}>Trans. No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Unit</th>
                <th style={tableHeaderStyle}>Floor</th>
                <th style={tableHeaderStyle}>Building</th>
                <th style={tableHeaderStyle}>Property</th>
                <th style={tableHeaderStyle}>Lease No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Tenant</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Item Name</th>
                <th style={tableHeaderStyle}>Status</th>
            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.piUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.piUkNo}
                        checked={selectedRows.includes(rowData.piUkNo)}
                        onChange={() => handleRowSelect(rowData.piUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{formatDate(rowData.pi_TR_DT)}</td>
                    <td style={tableCellStyle}>{rowData.pi_TR_NO}</td>
                    <td style={tableCellStyle}>{rowData.pi_TR_TY}</td>
                    <td style={tableCellStyle}>{rowData.pi_TR_CO}</td>
                    <td style={tableCellStyle}>{rowData.pi_UN_NO}</td> 
                    <td style={tableCellStyle}>{rowData.pi_FL_NO}</td>
                    <td style={tableCellStyle}>{rowData.pi_BL_NO}</td>
                    <td style={tableCellStyle}>{rowData.pi_PR_NO}</td>
                    <td style={tableCellStyle}>{rowData.pi_LE_NO}</td>
                    <td style={tableCellStyle}>{rowData.pi_LE_TY}</td> 
                    <td style={tableCellStyle}>{rowData.pi_TE_NT}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{rowData.pi_IT_NM}</td> 
                    <td style={tableCellStyle}>{rowData.pi_ST_AT}</td>                
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <PropertyInspectionDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        pi_TR_TY={pi_TR_TY}
        pi_TR_CO={pi_TR_CO}
        pi_ST_AT={pi_ST_AT}
        setpi_ST_AT={setpi_ST_AT}
        Propertytable={Propertytable}
        setPropertytable={setPropertytable}
        Selectedleaseno={Selectedleaseno}
        setSelectedleaseno={setSelectedleaseno}
        SelectedleaseType={SelectedleaseType}
        setSelectedleaseType={setSelectedleaseType}
        SelectedTenent={SelectedTenent}
        setSelectedTenent={setSelectedTenent}
        SelectedUnit={SelectedUnit}
        setSelectedUnit={setSelectedUnit}
        SelectedProperty={SelectedProperty}
        setSelectedProperty={setSelectedProperty}
        SelectedBuilding={SelectedBuilding}
        setSelectedBuilding={setSelectedBuilding}
        SelectedFloor={SelectedFloor}
        setSelectedFloor={setSelectedFloor}  
        SelectedTenentname={SelectedTenentname} 
        setSelectedTenentname={setSelectedTenentname}   
        onchangeDropDownFormData2={onchangeDropDownFormData2} 
      />
    </div>
  );
};

export default PropertyInspectionMain;
