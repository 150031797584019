import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ContactOwnerPopup from "./ContactOwnerPopup";
import LeadIDPopup from "./LeadIDPopup";
import axios from "axios";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
export default function VisitPlanDialog({
  open,
  handleClose,
  data,
  setFormData,
  // onchangedata,
  handleFormSubmit,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  addRow,
  deleteRow,
  VisitPlanTableData,
  onchangeFormData,
  selectedabAdNM,
  setselectedabAdNM, //CUSTOMER
  selectedccCnNm,
  setselectedccCnNm, //contact
  selectedvpEmNo,
  setselectedvpEmNo, //lead owner
}) {
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [vp_VI_TYOptions, setvp_VI_TYOptions] = useState([]);
  const [vp_CI_TYOptions, setvp_CI_TYOptions] = useState([]);
  const [vp_LD_SCOptions, setvp_LD_SCOptions] = useState([]);
  const [LeadOwnerPopupVisible, setLeadOwnerVisible] = useState(false);
  const [ContactOwnerPopupVisible, setContactOwnerPopupVisible] =useState(false);
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [LeadIDPopupVisible, setLeadIDPopupVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [addressTypeCust, setaddressTypecust] = useState("C");
  const [addressTypeLDOW, setaddressTypeLDOW] = useState("E");
  const { 
    vpUkNo,
    cus_NAME,
   } = data;
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const url = `${domain}/api/ClassCode/All`;

  const parameter = "DV/PR";
  const finalUrl = `${url}/${parameter}`;

  const parameter1 = "FO/CI";
  const finalUrl1 = `${url}/${parameter1}`;

  const parameter2 = "LD/SO";
  const finalUrl2 = `${url}/${parameter2}`;


  const openLeadIDPopup = (e) => {
    setLeadIDPopupVisible(true);
    setSelectedRowIndex(e);
  };

  const openLeadOwnerPopup = (e) => {
    setLeadOwnerVisible(true);
    setSelectedRowIndex(e);
  };

  const openContactOwnerPopup = (e) => {
    setContactOwnerPopupVisible(true);
    setSelectedRowIndex(e);
  };

  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };

  const handleDataSelect = (data) => {
    setselectedvpEmNo(data.ab_AD_NM);  
    document.getElementById (`vp_EM_NM_${SelectedRowIndex}`).value =  data.ab_AD_NM;
    onchangeFormData("vp_EM_NO", data.abAdNo, SelectedRowIndex);
    setLeadOwnerVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect1 = async (result) => {
    setselectedccCnNm(result.cc_CN_NM);
    onchangeFormData("vp_CN_NO", result.ccCnNo, SelectedRowIndex);
    const Url = `${domain}/api/Contact/onChangeOfContactPerson`;
    const requestData={
      "ccCnNo":result.ccCnNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
  
      const data = response.data;
  
      if (data && data.contact ) {
         document.getElementById(`vp_DE_GN_${SelectedRowIndex}`).value = data.contact.cc_TL_DS; //cc_DE_GN
        // const title = document.getElementById(`vp_DE_GN_${SelectedRowIndex}`);
        // title.value = data.contact.cc_DE_GN;
        document.getElementById (`vp_PH_NO_${SelectedRowIndex}`).value =  data.contact.cc_PH_NO;
        document.getElementById (`email_${SelectedRowIndex}`).value =  data.contact.cc_EM_IL;
        document.getElementById (`cc_CC_NM_${SelectedRowIndex}`).value =  data.contact.cc_CN_NM; //cc_CN_NM
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setContactOwnerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect2 = async (data) => {
    setselectedabAdNM(data.ab_AD_NM);
    onchangeFormData("vp_CT_NO", data.abAdNo, SelectedRowIndex);
    const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
    const requestData={
      "abAdNo":data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const result = response.data;
      if (result && result.data ) {
        // document.getElementById (`vp_CI_TY_${SelectedRowIndex}`).value =  result.data.ab_AD_CT; //ab_AD_CT
        onchangeDropDownFormData2(`vp_CI_TY_${SelectedRowIndex}`, result.data.ab_AD_CT);
        document.getElementById (`ab_CL_11_${SelectedRowIndex}`).value =  result.data.cl_CL_11; //ab_CL_11
        document.getElementById (`ab_CL_12_${SelectedRowIndex}`).value =  result.data.cl_CL_12;
        document.getElementById (`ab_CL_13_${SelectedRowIndex}`).value =  result.data.cl_CL_13;
        document.getElementById (`vp_CU_NM_${SelectedRowIndex}`).value =  result.data.ab_AD_NM;
      } else {
        console.error("Invalid API response:", result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect3 = async (Result) => {
    onchangeFormData("vp_LD_NO", Result.slLdId, SelectedRowIndex);
    onchangeDropDownFormData3(`vp_LD_SC_${SelectedRowIndex}`, Result.sl_LD_SO); 
    setLeadIDPopupVisible(false);
    setShowSearchIcon(false);
  };
  useEffect(() => {
    fetchDropdownOptions();
    fetchDropdownOptions1();
    fetchDropdownOptions2();
  }, []);
  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setvp_VI_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setvp_CI_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setvp_LD_SCOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "vp_TR_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format(
        "YYYYMMDD"
      );
      console.log(`Formatted date for ${name}:`, formattedDate);

      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };
  const handleFormDataChange = (name, value) => {
    const index = name.split("_")[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const isUpdateMode = Boolean(vpUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{vpUkNo ? "Revise Visit Plan" : "Add Visit Plan"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
            <div style={{ marginTop: "3%" }}>
              <table className="custom-table" id="VisitPlanTable">
                <thead>
                  <tr>
                    <th style={{display:"none"}}>UKNO</th>
                    <th>Date</th>
                    <th>Customer<span style={{ color: 'red' }}>*</span></th>
                    <th>Contact<span style={{ color: 'red' }}>*</span></th>
                    <th>Lead Owner<span style={{ color: 'red' }}>*</span></th>
                    <th>Lead ID<span style={{ color: 'red' }}>*</span></th>
                    <th>Lead Source</th>
                    <th>Title </th>
                    <th>Mobile</th>
                    <th>Email</th>
                    <th>Purpose</th>
                    <th>Industry</th>
                    <th>Verticle</th>
                    <th>Area</th>
                    <th>City</th>
                  </tr>
                </thead>
                <tbody>
                  {VisitPlanTableData.map((row, index) => (
                    <tr key={index}>
                      <td style={{display:"none"}}>
                        <InputText
                          id={`vpUkNo_${index}`}
                          name={`vpUkNo_${index}`}
                          defaultValue={row.vpUkNo}
                        />
                      </td>
                      <td>
                        <Calendar
                          style={{ width: "150px" }}
                          id={`vp_TR_DT_${index}`}
                          name={`vp_TR_DT_${index}`}
                          value={row.vp_TR_DT? moment(row.vp_TR_DT, "YYYYMMDD").toDate():null}
                          onChange={(e) => onDateChange("vp_TR_DT", index, e.value)}
                          dateFormat="dd/mm/yy"
                        />
                      </td>
                      <td>
                        <div style={{ display: "flex" }}>
                        <InputText
                            style={{ width: "200px" }}
                            id={`vp_CU_NM_${index}`}
                            name={`vp_CU_NM_${index}`}
                            onChange={(e) => onchangedata(e, index)}
                            onFocus={() => setShowSearchIcon(true)}
                            defaultValue={row.cus_NAME ? row.cus_NAME : row.vp_CU_NM ? row.vp_CU_NM : row[`${'vp_CU_NM'}_${index}`]}
                          />
                          <InputText style={{display:"none"}}
                            id={`vp_CT_NO_${index}`}
                            name={`vp_CT_NO_${index}`}
                            onChange={(e) => onchangedata(e, index)}
                            onFocus={() => setShowSearchIcon(true)}
                            value={row.vp_CT_NO ? row.vp_CT_NO : row[`${'vp_CT_NO'}_${index}`]}
                          />
                          {showSearchIcon && (
                          <i
                            className="pi pi-search"
                            style={{
                              color: "var(--primary-color)",
                              fontSize: "119%",
                              marginTop: "7%",
                              cursor: "pointer",
                            }}
                            onClick={(e) => openCustomerPopup(index)}
                          ></i>
                          )}
                        </div>
                      </td>
                      <td>
                        <div style={{ display: "flex" }}>
                        <InputText
                            style={{ width: "200px",}}
                            id={`cc_CC_NM_${index}`}
                            name={`cc_CC_NM_${index}`}
                            onChange={(e) => onchangedata(e, index)}
                            onFocus={() => setShowSearchIcon(true)}
                            defaultValue={row.contact_NAME ? row.contact_NAME : row.cc_CC_NM ? row.cc_CC_NM : row[`${'cc_CC_NM'}_${index}`]}
                          />
                          <InputText style={{display:"none"}}
                            id={`vp_CN_NO_${index}`}
                            name={`vp_CN_NO_${index}`}
                            value={row.vp_CN_NO ? row.vp_CN_NO : row[`${'vp_CN_NO'}_${index}`]}
                            onChange={(e) => onchangedata(e, index)}
                          />
                          {showSearchIcon && (
                              <i
                                className="pi pi-search"
                                style={{
                                  color: "var(--primary-color)",
                                  fontSize: "119%",
                                  marginTop: "7%",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => openContactOwnerPopup(index)}
                              ></i>
                          )}
                        </div>
                      </td>
                      <td>
                      <div style={{ display: "flex" }}>
                      <InputText
                        style={{ width: "200px",}}
                        id={`vp_EM_NM_${index}`}
                        name={`vp_EM_NM_${index}`}
                        // defaultValue={selectedvpEmNo || ""}
                        onChange={(e) => onchangedata(e, index)}
                        onFocus={() => setShowSearchIcon(true)}
                        defaultValue={row.emp_NAME ? row.emp_NAME : row.vp_EM_NM ? row.vp_EM_NM : row[`${'vp_EM_NM'}_${index}`]}
                      />
                        <InputText style={{display:"none"}}
                        id={`vp_EM_NO_${index}`}
                        name={`vp_EM_NO_${index}`}
                        value={row.vp_EM_NO ? row.vp_EM_NO: row[`${"vp_EM_NO"}_${index}`]}
                        onChange={(e) => onchangedata(e, index)}
                      />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "7%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openLeadOwnerPopup(index)}
                      ></i>
                      )}
                        </div>
                      </td>
                      <td>
                        <div style={{ display: "flex" }}>                       
                          <InputText
                            style={{ width: "100px" }}
                            id={`vp_LD_NO_${index}`}
                            name={`vp_LD_NO_${index}`}
                            onChange={(e) => onchangedata(e)}
                            onFocus={() => setShowSearchIcon(true)}
                            value={row.vp_LD_NO ? row.vp_LD_NO : row[`${'vp_LD_NO'}_${index}`]}
                          />
                          {showSearchIcon && (
                          <i
                            className="pi pi-search"
                            style={{
                              color: "var(--primary-color)",
                              fontSize: "119%",
                              marginTop: "15%",
                              cursor: "pointer",
                            }}
                            onClick={(e) => openLeadIDPopup(index)}
                          ></i>
                          )}
                        </div>
                      </td>
                      <td>                        
                        <Dropdown disabled
                          id={`vp_LD_SC_${index}`}
                          name={`vp_LD_SC_${index}`}
                          value={data[`vp_LD_SC_${index}`] ? data[`vp_LD_SC_${index}`] : row.vp_LD_SC}
                          options={vp_LD_SCOptions}
                          onChange={(e) => onchangeDropDownFormData3(`vp_LD_SC_${index}`, e.value)}
                          placeholder="Select Source"
                        />
                      </td>
                    
                      <td>
                        <InputText disabled
                          style={{ width: "150px" }}
                          id={`vp_DE_GN_${index}`}
                          name={`vp_DE_GN_${index}`}
                          defaultValue={row.vp_DE_GN}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td>
                        <InputText
                          style={{ width: "100px" }}
                          id={`vp_PH_NO_${index}`}
                          name={`vp_PH_NO_${index}`}
                          defaultValue={row.vp_PH_NO}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td>
                        <InputText
                          style={{ width: "170px" }}
                          id={`email_${index}`}
                          name={`email_${index}`}
                          defaultValue={row.email}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td>
                        <Dropdown
                          id={`vp_VI_PR_${index}`}
                          name={`vp_VI_PR_${index}`}
                          value={
                            data[`vp_VI_PR_${index}`]
                              ? data[`vp_VI_PR_${index}`]
                              : row.vp_VI_PR
                          }
                          options={vp_VI_TYOptions}
                          onChange={(e) =>
                            handleFormDataChange(`vp_VI_PR_${index}`, e.value)
                          }
                          placeholder="Select Purpose"
                        />
                      </td>
                      <td>
                        <InputText disabled
                          style={{ width: "100px" }}
                          id={`ab_CL_11_${index}`}
                          name={`ab_CL_11_${index}`}
                          defaultValue={row.ab_CL_11}
                          onChange={(e) => onchangedata(e)}
                        />
                        
                      </td>
                      <td>
                        <InputText disabled
                          style={{ width: "100px" }}
                          id={`ab_CL_12_${index}`}
                          name={`ab_CL_12_${index}`}
                          defaultValue={row.ab_CL_12}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td>
                        <InputText disabled
                          style={{ width: "100px" }}
                          id={`ab_CL_13_${index}`}
                          name={`ab_CL_13_${index}`}
                          defaultValue={row.ab_CL_13}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td>
                        {/* <InputText disabled
                          style={{ width: "100px" }}
                          id={`vp_CI_TY_${index}`}
                          name={`vp_CI_TY_${index}`}
                          defaultValue={row.vp_CI_TY}
                          onChange={(e) => onchangedata(e)}
                        /> */}
                        <Dropdown disabled
                          id={`vp_CI_TY_${index}`}
                          name={`vp_CI_TY_${index}`}
                          value={data[`vp_CI_TY_${index}`] ? data[`vp_CI_TY_${index}`] : row.vp_CI_TY}
                          options={vp_CI_TYOptions}
                          onChange={(e) => onchangeDropDownFormData2(`vp_CI_TY_${index}`, e.value)}
                          placeholder="Select City"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Customer */}
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
        addressType={addressTypeCust}
        setFormData={setFormData}
      />
      {/* Lead Owner */}
      <AddressBookPopup
        visible={LeadOwnerPopupVisible}
        onClose={() => setLeadOwnerVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypeLDOW}
        setFormData={setFormData}
      />
      <ContactOwnerPopup
        visible={ContactOwnerPopupVisible}
        onClose={() => setContactOwnerPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
      <LeadIDPopup
        visible={LeadIDPopupVisible}
        onClose={() => setLeadIDPopupVisible(false)}
        onDataSelect={handleDataSelect3}
        formData={data}
      />
    </Dialog>
  );
}

