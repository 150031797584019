import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ProjectMasterDialog from "./ProjectMasterDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  pmUkNo:"",
  pm_TR_NO:"",
  pm_TR_TY:"",
  pm_PR_CO:"",
  pm_PR_NO:"",
  pm_PR_DS:"",
  pm_PR_TY:"",
  proj_TYPE:"",
  pm_PR_ST:"",
  proj_STATUS:"",
  pm_PA_PR:"",
  pm_PS_DT:"",
  pm_PC_DT:"",
  pm_AS_DT:"",
  pm_AC_DT:"",
  pm_CO_RY:"",
  country:"",
  pm_PR_ST:"",
  pm_SI_AD:"",
  sitedDES:"",
  pm_OW_AD:"",
  ownerDES:"",
  pm_AC_CD:"",
};

const initialFilters = {
  pm_TR_NO:{ value: "", operation: "" },
  pm_TR_TY:{ value: "", operation: "" },
  pm_PR_CO:{ value: "", operation: "" },
  pm_PR_NO:{ value: "", operation: "" },
  pm_PR_DS:{ value: "", operation: "" },
  pm_PR_TY:{ value: "", operation: "" },
  proj_TYPE:{ value: "", operation: "" },
  pm_PR_ST:{ value: "", operation: "" },
  proj_STATUS:{ value: "", operation: "" },
  pm_PA_PR:{ value: "", operation: "" },
  pm_PS_DT:{ value: "", operation: "" },
  pm_PC_DT:{ value: "", operation: "" },
  pm_AS_DT:{ value: "", operation: "" },
  pm_AC_DT:{ value: "", operation: "" },
  pm_CO_RY:{ value: "", operation: "" },
  country:{ value: "", operation: "" },
  pm_PR_ST:{ value: "", operation: "" },
  pm_SI_AD:{ value: "", operation: "" },
  sitedDES:{ value: "", operation: "" },
  pm_OW_AD:{ value: "", operation: "" },
  ownerDES:{ value: "", operation: "" },
};

const ProjectMasterMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,inputStyleH,tableCellStyleH,tableHeaderStyleH
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [pm_PR_TY, setpm_PR_TY] = useState([]);
  const [pm_PR_ST, setpm_PR_ST] = useState([]);
  const [pm_CO_RY, setpm_CO_RY] = useState([]);
  const [pm_CC_01, setpm_CC_01] = useState([]);
  const [pm_CC_02, setpm_CC_02] = useState([]);
  const [pm_CC_03, setpm_CC_03] = useState([]);
  const [pm_CC_04, setpm_CC_04] = useState([]);
  const [pm_PA_PR, setpm_PA_PR] = useState([]);
  const [pm_CC_05, setpm_CC_05 ] = useState("");
  const [pm_CC_06, setpm_CC_06 ] = useState("");
  const [pm_CC_07, setpm_CC_07 ] = useState("");
  const [pm_CC_08, setpm_CC_08 ] = useState("");
  const [pm_CC_09, setpm_CC_09 ] = useState("");
  const [pm_CC_10, setpm_CC_10 ] = useState("");
  const [pm_AC_CD, setpm_AC_CD ] = useState("");
  const [pm_PM_TY, setpm_PM_TY ] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO ] = useState("");
  const [SelectedemEMNO1, setSelectedemEMNO1 ] = useState("");
  const [SelectedAccount, setSelectedAccount ] = useState("");
  const [SelectedAccountNo, setSelectedAccountNo ] = useState("");
  const [SelectedProjectMaster, setSelectedProjectMaster ] = useState("");
  const [Selectedsite, setSelectedsite ] = useState("");
  const [Selectedowner, setSelectedowner ] = useState("");
  const ProjectStatusDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_PR_ST(value);
  };
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedemEMNO1(null);
    setpm_TR_NO(null);
    setpm_PR_TY(null);
    setpm_CO_RY(null);
    setpm_CC_01(null);
    setpm_CC_02(null);
    setpm_PA_PR(null);
    setpm_CC_05(null);
    setpm_CC_06(null);
    setpm_CC_07(null);
    setpm_CC_08(null);
    setpm_CC_09(null);
    setpm_CC_10(null);
    setSelectedProjectMaster(null);
    setSelectedowner(null);
    setSelectedsite(null);
    setSelectedemEMNO(null);
    setSelectedemEMNO1(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedemEMNO1(null);
    setpm_TR_NO(null);
    setpm_PR_TY(null);
    setpm_CO_RY(null);
    setpm_PA_PR(null);
    setpm_CC_01(null);
    setpm_CC_02(null);
    setpm_CC_05(null);
    setpm_CC_06(null);
    setpm_CC_07(null);
    setpm_CC_08(null);
    setpm_CC_09(null);
    setpm_CC_10(null);
    setSelectedProjectMaster(null);
    setSelectedowner(null);
    setSelectedsite(null);
    setSelectedemEMNO(null);
    setSelectedemEMNO1(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ProjectMaster/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const ProjectMasterData = response.data;
          delete ProjectMasterData.pmUkNo;
          delete ProjectMasterData.pm_TR_NO;
          delete ProjectMasterData.pm_PR_NO;
          setFormData(ProjectMasterData);
          setSelectedAccount(response.data.accntDES);
          setSelectedAccountNo(response.data.pm_AC_CD);
          setpm_PR_TY(response.data.pm_PR_TY);
            setpm_PR_ST( response.data.pm_PR_ST);
            setpm_CO_RY( response.data.pm_CO_RY);
            setpm_CC_01( response.data.pm_CC_01);
            setpm_CC_02( response.data.pm_CC_02);
            setpm_PA_PR( response.data.pm_PA_PR);
            setpm_CC_04( response.data.pm_CC_04);
            setpm_CC_03( response.data.pm_CC_03);
            setpm_CC_05( response.data.pm_CC_05);
            setpm_CC_06( response.data.pm_CC_06);
            setpm_CC_07( response.data.pm_CC_07);
            setpm_CC_08( response.data.pm_CC_08);
            setpm_CC_09( response.data.pm_CC_09);
            setpm_CC_10( response.data.pm_CC_10);
            setpm_TR_NO( response.data.pm_TR_NO);
            setpm_PR_NO( response.data.pm_PR_NO);
            setSelectedProjectMaster( response.data.proj_NAME);
          setSelectedowner( response.data.ownerDES);
          setSelectedsite( response.data.sitedDES);
          setSelectedemEMNO( response.data.cus_NAME);
          setSelectedemEMNO1( response.data.sales_PERSON);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [pm_PR_CO, setpm_PR_CO] = useState("");
  const [pm_TR_TY, setpm_TR_TY] = useState("");
  const [pm_TR_NO, setpm_TR_NO] = useState("");
  const [pm_PR_NO, setpm_PR_NO] = useState("");
  const handleDefault = async () => {
    setpm_PR_CO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "PM_PM_TY"){
              setpm_PM_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "PM_TR_TY"){
              setpm_TR_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "PM_PR_ST"){
              ProjectStatusDropDown("pm_PR_ST",dataDefault.versionIds[i].default_VALUE);
              setpm_PR_ST(dataDefault.versionIds[i].default_VALUE);
            } 
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ProjectMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({ ...prevData,   [name]: value, }));
  };
  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ProjectMaster/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ProjectMasterData = response.data;
        setFormData(ProjectMasterData);
        setSelectedAccount(response.data.accntDES);
        setSelectedAccountNo(response.data.pm_AC_CD);
          setpm_PR_TY(response.data.pm_PR_TY);
          setpm_PR_ST( response.data.pm_PR_ST);
          setpm_CO_RY( response.data.pm_CO_RY);
          setpm_CC_02( response.data.pm_CC_02);
          setpm_CC_03( response.data.pm_CC_03);
          setpm_CC_05( response.data.pm_CC_05);
          setpm_CC_06( response.data.pm_CC_06);
          setpm_CC_07( response.data.pm_CC_07);
          setpm_CC_08( response.data.pm_CC_08);
          setpm_CC_09( response.data.pm_CC_09);
          setpm_CC_10( response.data.pm_CC_10);
          setpm_TR_NO( response.data.pm_TR_NO);
          setpm_PR_NO( response.data.pm_PR_NO);
          setSelectedProjectMaster( response.data.proj_NAME);
          setSelectedowner( response.data.ownerDES);
          setSelectedsite( response.data.sitedDES);
          setSelectedemEMNO( response.data.cus_NAME);
          setSelectedemEMNO1( response.data.sales_PERSON);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values() {
    const PR_DS = FormData.pm_PR_DS;
    const CO_RY = FormData.pm_CO_RY;
    const PS_DT = FormData.pm_PS_DT;
    const PC_DT = FormData.pm_PC_DT;
    const AS_DT = FormData.pm_AS_DT;
    const AC_DT = FormData.pm_AC_DT;
    const CC_01 = FormData.pm_CC_01;
    const CC_02 = FormData.pm_CC_02;
    const PA_PR = FormData.pm_PA_PR;
    const CC_04 = FormData.pm_CC_04;
    const CC_05 = FormData.pm_CC_05;
    const CC_06 = FormData.pm_CC_06;
    const CC_07 = FormData.pm_CC_07;
    const CC_08 = FormData.pm_CC_08;
    const CC_09 = FormData.pm_CC_09;
    const CC_10 = FormData.pm_CC_10;
    requestBodyInput = {
      pmUkNo: FormData.pmUkNo,
      pm_TR_NO: FormData.pm_TR_NO,
      pm_TR_TY: pm_TR_TY,
      pm_PR_CO: pm_PR_CO,
      pm_PR_NO: FormData.pm_PR_NO,
      pm_PM_TY: pm_PM_TY,
      pm_PR_TY: pm_PR_TY,
      pm_PR_ST: pm_PR_ST,
      pm_PR_DS: PR_DS,
      pm_CO_RY: CO_RY,
      pm_PS_DT: PS_DT,
      pm_PC_DT: PC_DT,
      pm_AS_DT: AS_DT,
      pm_AC_DT: AC_DT,
      pm_CC_01: CC_01,
      pm_CC_02: CC_02,
      pm_PA_PR: PA_PR,
      pm_CC_04: CC_04,
      pm_CC_05: CC_05,
      pm_CC_06: CC_06,
      pm_CC_07: CC_07,
      pm_CC_08: CC_08,
      pm_CC_09: CC_09,
      pm_CC_10: CC_10,
      pm_AC_CD: FormData.pm_AC_CD,
      pm_SI_AD: FormData.pm_SI_AD,
      pm_OW_AD: FormData.pm_OW_AD,
    };
  };
  const handleFormSubmit = () => {
    values();
    if (FormData.pm_TR_NO) {
    if (FormData.pmUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/ProjectMaster/Update`;
      if (confirm) {
        axios
          .put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/ProjectMaster/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
      } else {
        const OR_DT = FormData.pm_PS_DT;
        if(OR_DT){
          const currentYear = OR_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${pm_TR_TY}/${secondHalf}/${company}/${firstHalf}`;  
          const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
              setpm_TR_NO(resp.data.dn_DO_NO);
              FormData.pm_TR_NO = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/ProjectMaster/Add`, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
      }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pm_TR_NO"] ? filters["pm_TR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pm_TR_NO",
                        e.target.value,
                        filters["pm_TR_NO"]
                          ? filters["pm_TR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pm_TR_TY"] ? filters["pm_TR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pm_TR_TY",
                        e.target.value,
                        filters["pm_TR_TY"] ? filters["pm_TR_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pm_PR_CO"] ? filters["pm_PR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pm_PR_CO",
                        e.target.value,
                        filters["pm_PR_CO"]
                          ? filters["pm_PR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pm_PR_NO"] ? filters["pm_PR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pm_PR_NO",
                        e.target.value,
                        filters["pm_PR_NO"]
                          ? filters["pm_PR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pm_PR_DS"] ? filters["pm_PR_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pm_PR_DS",
                        e.target.value,
                        filters["pm_PR_DS"]
                          ? filters["pm_PR_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["pm_PR_TY"] ? filters["pm_PR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pm_PR_TY",
                        e.target.value,
                        filters["pm_PR_TY"]
                          ? filters["pm_PR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["proj_TYPE"] ? filters["proj_TYPE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "proj_TYPE",
                        e.target.value,
                        filters["proj_TYPE"]
                          ? filters["proj_TYPE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["pm_PR_ST"] ? filters["pm_PR_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pm_PR_ST",
                        e.target.value,
                        filters["pm_PR_ST"]
                          ? filters["pm_PR_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["proj_STATUS"] ? filters["proj_STATUS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "proj_STATUS",
                        e.target.value,
                        filters["proj_STATUS"]
                          ? filters["proj_STATUS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pm_PA_PR"] ? filters["pm_PA_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pm_PA_PR",
                        e.target.value,
                        filters["pm_PA_PR"]
                          ? filters["pm_PA_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pm_PS_DT"] ? filters["pm_PS_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pm_PS_DT",
                        e.target.value,
                        filters["pm_PS_DT"]
                          ? filters["pm_PS_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["pm_PC_DT"] ? filters["pm_PC_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pm_PC_DT",
                        e.target.value,
                        filters["pm_PC_DT"]
                          ? filters["pm_PC_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pm_AS_DT"] ? filters["pm_AS_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pm_AS_DT",
                        e.target.value,
                        filters["pm_AS_DT"]
                          ? filters["pm_AS_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["pm_AC_DT"] ? filters["pm_AC_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pm_AC_DT",
                        e.target.value,
                        filters["pm_AC_DT"]
                          ? filters["pm_AC_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["pm_CO_RY"] ? filters["pm_CO_RY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pm_CO_RY",
                        e.target.value,
                        filters["pm_CO_RY"]
                          ? filters["pm_CO_RY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["country"] ? filters["country"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "country",
                        e.target.value,
                        filters["country"]
                          ? filters["country"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pm_SI_AD"] ? filters["pm_SI_AD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pm_SI_AD",
                        e.target.value,
                        filters["pm_SI_AD"]
                          ? filters["pm_SI_AD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>    
                <td>
                  <input
                    type="text"
                    value={filters["sitedDES"] ? filters["sitedDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sitedDES",
                        e.target.value,
                        filters["sitedDES"]
                          ? filters["sitedDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>      
                <td>
                  <input
                    type="text"
                    value={filters["pm_OW_AD"] ? filters["pm_OW_AD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pm_OW_AD",
                        e.target.value,
                        filters["pm_OW_AD"]
                          ? filters["pm_OW_AD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["ownerDES"] ? filters["ownerDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ownerDES",
                        e.target.value,
                        filters["ownerDES"]
                          ? filters["ownerDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>      
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Transaction No </th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Project No</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyleH}>Project Type</th>
                <th style={tableHeaderStyle}>Project Type</th>
                <th style={tableHeaderStyleH}>Project Status</th>
                <th style={tableHeaderStyle}>Project Status</th>
                <th style={tableHeaderStyle}>Parent Project</th>
                <th style={tableHeaderStyle}>Planned Start Date</th>
                <th style={tableHeaderStyle}>Planned Compl. Date</th>
                <th style={tableHeaderStyle}>Actual Start Date</th>
                <th style={tableHeaderStyle}>Actual Compl. Date</th>
                <th style={tableHeaderStyleH}>Country</th>
                <th style={tableHeaderStyle}>Country</th>
                <th style={tableHeaderStyle}>Site Address</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Owner Address</th>
                <th style={tableHeaderStyle}>Description</th>
            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.pmUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.pmUkNo}
                        checked={selectedRows.includes(rowData.pmUkNo)}
                        onChange={() => handleRowSelect(rowData.pmUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.pm_TR_NO}</td>
                    <td style={tableCellStyle}>{rowData.pm_TR_TY}</td>
                    <td style={tableCellStyle}>{rowData.pm_PR_CO}</td>
                    <td style={tableCellStyle}>{rowData.pm_PR_NO}</td>
                    <td style={tableCellStyle}>{rowData.pm_PR_DS}</td>
                    <td style={tableCellStyleH}>{rowData.pm_PR_TY}</td>
                    <td style={tableCellStyle}>{rowData.proj_TYPE}</td>
                    <td style={tableCellStyleH}>{rowData.pm_PR_ST}</td>
                    <td style={tableCellStyle}>{rowData.proj_STATUS}</td>
                    <td style={tableCellStyle}>{rowData.pm_PA_PR}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.pm_PS_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.pm_PC_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.pm_AS_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.pm_AC_DT)}</td> 
                    <td style={tableCellStyleH}>{rowData.pm_CO_RY}</td> 
                    <td style={tableCellStyle}>{rowData.country}</td>  
                    <td style={tableCellStyle}>{rowData.pm_SI_AD}</td>
                    <td style={tableCellStyle}>{rowData.sitedDES}</td>
                    <td style={tableCellStyle}>{rowData.pm_OW_AD}</td> 
                    <td style={tableCellStyle}>{rowData.ownerDES}</td>                
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <ProjectMasterDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        pm_TR_TY={pm_TR_TY}
        pm_PR_CO={pm_PR_CO}
        pm_PR_TY={pm_PR_TY}
        pm_TR_NO={pm_TR_NO}
        setpm_PR_TY={setpm_PR_TY}
        pm_PR_ST={pm_PR_ST}
        setpm_PR_ST={setpm_PR_ST}
        pm_CO_RY={pm_CO_RY}
        setpm_CO_RY={setpm_CO_RY}
        pm_PA_PR={pm_PA_PR}
        setpm_PA_PR={setpm_PA_PR}
        pm_CC_01={pm_CC_01}
        setpm_CC_01={setpm_CC_01}
        pm_CC_02={pm_CC_02}
        setpm_CC_02={setpm_CC_02}
        pm_CC_03={pm_CC_03}
        setpm_CC_03={setpm_CC_03}
        pm_CC_04={pm_CC_04}
        setpm_CC_04={setpm_CC_04}
        pm_CC_05={pm_CC_05}
        setpm_CC_05={setpm_CC_05}
        pm_CC_06={pm_CC_06}
        setpm_CC_06={setpm_CC_06}
        pm_CC_07={pm_CC_07}
        setpm_CC_07={setpm_CC_07}
        pm_CC_08={pm_CC_08}
        setpm_CC_08={setpm_CC_08}
        pm_CC_09={pm_CC_09}
        setpm_CC_09={setpm_CC_09}
        pm_CC_10={pm_CC_10}
        setpm_CC_10={setpm_CC_10}
        pm_AC_CD={pm_AC_CD}
        setpm_AC_CD={setpm_AC_CD}
        pm_PR_NO={pm_PR_NO}
        pm_PM_TY={pm_PM_TY}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedemEMNO1={SelectedemEMNO1}
        setSelectedemEMNO1={setSelectedemEMNO1}
        SelectedAccount={SelectedAccount}
        setSelectedAccount={setSelectedAccount}
        SelectedAccountNo={SelectedAccountNo}
        setSelectedAccountNo={setSelectedAccountNo}
        SelectedProjectMaster={SelectedProjectMaster}
        setSelectedProjectMaster={setSelectedProjectMaster}
        Selectedsite={Selectedsite}
        setSelectedsite={setSelectedsite}
        Selectedowner={Selectedowner}
        setSelectedowner={setSelectedowner}
        ProjectStatusDropDown={ProjectStatusDropDown}
      />
    </div>
  );
};

export default ProjectMasterMain;
