import React, { useState ,useEffect ,useRef} from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";

export default function Amenities({ isOpen, handleClose, AmenityType }) {
  const { borderbox, inboxstyle } = ButtonStyles();
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const intialvalues = {
    pm_VI_DT:"",
    pm_VI_IT:"",
    pm_VI_OT:"",
    pm_VI_PH:"",
    pm_CO_NO:"",
    pm_AM_TY:"",
  };
  const [FormData, setFormData] = useState(intialvalues);
  
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const onChangeTime=(name,value,index)=>{
    value= moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value,index);
  };
  useEffect(() => {
    if (isOpen) {
      setFormData(intialvalues);
    }
  }, [isOpen]);

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
    }
  };
  const handleFormSubmit = () => {
    const request = {
      "propertyAmenities":{
        "pm_VI_NM" : FormData.pm_VI_NM,
        "pm_VI_PH" : FormData.pm_VI_PH,
        "pm_VI_DT" : FormData.pm_VI_DT,
        "pm_VI_IT" : FormData.pm_VI_IT,
        "pm_VI_OT" : FormData.pm_VI_OT,
        "pm_CO_NO" : company,
        "pm_AM_TY" : AmenityType,
      }
    }
    axios.post(`${domain}/api/PropertyAmenities/Add`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        handleResponse(resp.data);
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  };

  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "8%" }}
        icon="pi pi-credit-card"
        onClick={handleFormSubmit}
        tooltip="Save"
      />
    </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={isOpen}
      onHide={handleClose}
      style={{ width: "40rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Amenities"
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
       <div style={borderbox} >
          <div style={inboxstyle} >
            <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="pm_VI_NM" className="font-bold" style={{fontSize:'70%'}}>
                Name 
              </label>
              <InputText
                id="pm_VI_NM"
                name="pm_VI_NM"
                value={FormData.pm_VI_NM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col">
              <label htmlFor="pm_VI_PH" className="font-bold" style={{fontSize:'70%'}}>
                Apartment 
              </label>
              <InputText
                id="pm_VI_PH"
                name="pm_VI_PH"
                value={FormData.pm_VI_PH}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            </div>
            <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="pm_VI_DT" className="font-bold" style={{fontSize:'70%'}}>
                Date 
              </label>
              <Calendar
                id="pm_VI_DT"
                name="pm_VI_DT"
                value={moment(FormData.pm_VI_DT, "YYYYMMDD").isValid() ? moment(FormData.pm_VI_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "pm_VI_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
            </div>
            </div>
            <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="pm_VI_IT" className="font-bold" style={{fontSize:'70%'}}>
                In Time
              </label>
              <InputMask
                id="pm_VI_IT"
                name="pm_VI_IT"
                mask="99:99:99"
                onBlur={(e) => { onChangeTime("pm_VI_IT",e.target.value,);}}
                value={moment(FormData.pm_VI_IT, "HHmmss").format("HH:mm:ss")}
              />
            </div>
            <div className="field col">
              <label htmlFor="pm_VI_OT" className="font-bold" style={{fontSize:'70%'}}>
                Out Time
              </label>
              <InputMask
                id="pm_VI_OT"
                name="pm_VI_OT"
                mask="99:99:99"
                onBlur={(e) => { onChangeTime("pm_VI_OT",e.target.value,);}}
                value={moment(FormData.pm_VI_OT, "HHmmss").format("HH:mm:ss")}
              />
            </div>
            </div>
          </div>
      </div>
    </Dialog>
  );
}
