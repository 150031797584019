import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
// import TenentPopup from "./TenentPopup";
// import TransCurrencyPopup from "./TransCurrencyPopup";
import LeasePopup from "../Popups/LeasePopup";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function SecurityDepositeDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  handleConfirm,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  SelectedTenent,
  setSelectedTenent,
  SelectedTenentname,
  setSelectedTenentname,
  addRow,
  deleteRow,
  SecurityDepositTable,
  setSecurityDepositTable,
  se_LE_CO,
  se_BS_CR,
  se_TR_CR,
  se_LE_TY,
  se_DO_TY,
  se_DO_CO,
}) {
  const { 
    seUkNo,
    se_TR_DT,
    se_EX_RT,
    se_GL_DT,
    se_DO_NO,
    seLeNo,
    se_EX_PR,
    se_FR_AM,
    se_CN_FM,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,savestyle,cancelstyle,confirmstyle,Dialogstyle} = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [LeaseNoPopupVisible, setLeaseNoPopupVisible] = useState(false);
  const openLeaseNoPopup = () => {
    setLeaseNoPopupVisible(true); 
  };
  const handleDataSelect2 = async(data) => {
    // document.getElementById("seUkNo").value =  data.li_UK_NO;
    document.getElementById("seLeNo").value =  data.liLeNo;
    document.getElementById("se_BS_CR").value =  data.li_BS_CR;
    document.getElementById("se_TR_CR").value =  data.li_TR_CR;
    setSelectedTenent(data.li_TE_NT); 
    setSelectedTenentname(data.teanat_NAME);
    document.getElementById("se_LE_TY").value =  data.li_LE_TY;
    document.getElementById("se_EX_RT").value =  data.li_EX_RT;
    onchangeFormData("se_TE_NT", data.li_TE_NT);   
    onchangeFormData("teanat_NAME", data.teanat_NAME);
    try {
      const response = await axios.get(
        `${domain}/api/LeaseInformation/Select/${data.li_UK_NO}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const LeaseInformationData = response.data.leaseInformations;
        const led = [];
        const size = LeaseInformationData.length;
        for(let i = 0 ;  i < size ; i++) {
        const s = {
          "se_FR_DT" : data.li_ST_DT,
          "se_EN_DT" : data.li_EN_DT,
          "se_UN_TS" : data.li_UN_TS,
          "se_FL_NO" : data.li_FL_NO,
          "se_BL_NO" : data.li_BL_NO,
          "se_PR_NO" : data.li_PR_NO
        }
        led.push(s);
        }
      setSecurityDepositTable(led);
    
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLeaseNoPopupVisible(false);
    setShowSearchIcon(false);
  };
  const onchangeFormUnitPrice = async(e,index) => {
    onchangedata(e);
    Amount(index);
  };
  const Amount = async (li_EX_RT,SelectedRowIndex) => {
    const Url = `${domain}/api/RentalSecurityDeposit/GetAmountDetails`;
      const requestData={
              "rs_DP_AM":se_EX_PR,
              "rs_FD_AM":se_FR_AM,
              "rs_EX_RT":li_EX_RT,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        document.getElementById(`se_EX_PR_${SelectedRowIndex}`).value = dataCuMt.rs_DP_AM;
      } else {
        console.error("Invalid API response:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormDataChange5 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData5(name, value, index);
  };
  const handleFormDataChange6 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData6(name, value, index);
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // const onchangedata = (e) => {
  //   const { name, value } = e.target;
  //     onchangeFormData(name, value);
  // };
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [se_BL_CDOptions, setse_BL_CDOptions] = useState([]);
  const [se_AS_NOOptions,setse_AS_NOOptions] = useState([]);
  const fetchBuilding = async () => {
    const Options = await fetchClassCodeDS("BL/CD");
    setse_BL_CDOptions(Options);
  };
  const fetchtype = async () => {
    const Options = await fetchClassCodeDS("DE/TY");
    setse_AS_NOOptions(Options);
  };
  useEffect(() => {
    fetchBuilding();
    fetchtype();
  }, []);
  const [showSearchIcon, setShowSearchIcon] = useState(false);

  const [selectedTRDate, setSelectedTRDate] = useState(moment().toDate());
  const handleDateTRChange = (e) => {
    setSelectedTRDate(e.value);
    onchangedata({ target: { name: "se_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const [selectedGLDate, setSelectedGLDate] = useState(moment().toDate());
  const handleDateGLChange = (e) => {
    setSelectedGLDate(e.value);
    onchangedata({ target: { name: "se_GL_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
// disabled after confirm
const isDisabled = se_CN_FM === "C";
const isUpdateMode = Boolean(seUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{seUkNo ? "Revise Security Deposit" : "Add Security Deposit"}</h5>
      <div style={{ display:'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end',}}>
        <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
          <Button
            style={savestyle}
            icon="pi pi-check"
            onClick={handleFormSubmit} 
            tooltip={isUpdateMode ? "Update" : "Submit"}
          />
          <Button
            style={cancelstyle}
            icon="pi pi-times"
            onClick={handleClose}
            tooltip="Cancel"
          />
        {isUpdateMode && (
          <Button 
            style={confirmstyle}
            icon="pi pi-check-circle" 
            onClick={handleConfirm} 
            tooltip={"Confirm"}>
          </Button>
        )}
      </div>
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
       <div style={borderbox} >
          <div style={inboxstyle}>
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="seLeNo" className="font-bold">
                Lease Number <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText disabled={isDisabled}
                    id="seLeNo"
                    name="seLeNo"
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={seLeNo}
                    readOnly
                  />
                  {showSearchIcon && (
                    <i 
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "7%",
                      cursor: "pointer",
                      
                    }}
                    onClick={(e) => openLeaseNoPopup(e)}
                  ></i>
                  )}
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="se_LE_TY" className="font-bold">
                Type
                  </label>
                  <InputText disabled
                      id="se_LE_TY"
                      name="se_LE_TY"
                      defaultValue={se_LE_TY}
                      readOnly
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="se_LE_CO" className="font-bold">
                Company
                  </label>
                  <InputText disabled
                    id="se_LE_CO"
                    name="se_LE_CO"
                    defaultValue={se_LE_CO}
                    readOnly
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="se_DO_NO" className="font-bold">
                  Document No
                </label>
                <InputText disabled
                  id="se_DO_NO"
                  name="se_DO_NO"
                  value={se_DO_NO}
                  onChange={(e) => onchangedata(e)}
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="se_DO_TY" className="font-bold">
                    Document Type
                  </label>
                  <InputText disabled
                    id="se_DO_TY"
                    name="se_DO_TY"
                    value={se_DO_TY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="se_DO_CO" className="font-bold">
                    Document Company
                  </label>
                  <InputText  disabled
                    id="se_DO_CO"
                    name="se_DO_CO"
                    defaultValue={se_DO_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
                <label htmlFor="se_TE_NT" className="font-bold" >
                Tenant
                </label>
                <div style={{    display:"flex"}}>
                <InputText style={{display : "none"}}
                  id="se_TE_NT"
                  name="se_TE_NT"
                  value={SelectedTenent}
                  readOnly
                />
                  <InputText disabled
                  id="teanat_NAME"
                  name="teanat_NAME"
                  value={SelectedTenentname}
                  readOnly
                />
              </div>
                </div> 
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="se_TR_DT" className="font-bold">
                    Trans Date
                  </label>
                  <Calendar disabled={isDisabled}
                  id="se_TR_DT"
                  name="se_TR_DT"
                  value={moment(se_TR_DT, "YYYYMMDD").toDate()}
                  onChange={handleDateTRChange}
                  dateFormat="dd/mm/yy"
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="se_GL_DT" className="font-bold">
                    GL Date
                  </label>
                  <Calendar disabled={isDisabled}
                  id="se_GL_DT"
                  name="se_GL_DT"
                  value={moment(se_GL_DT, "YYYYMMDD").toDate()}
                  onChange={handleDateGLChange}
                  dateFormat="dd/mm/yy"
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="se_TR_CR" className="font-bold">
                      Trans. Currency
                  </label>
                    <InputText disabled
                      id="se_TR_CR"
                      name="se_TR_CR"
                      value={se_TR_CR}
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="se_EX_RT" className="font-bold">
                  Exchange Rate
                  </label>
                  <InputText disabled
                    id="se_EX_RT"
                    name="se_EX_RT"
                    value={se_EX_RT}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="se_BS_CR" className="font-bold">
                  Base Currency
                  </label>
                  <InputText disabled
                    id="se_BS_CR"
                    name="se_BS_CR"
                    value={se_BS_CR}
                  />
                </div>
              </div>
          </div>
        </div>
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
          {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
      <div  style={multitablestyle}>
    <table className="custom-table" id="SecurityDepositTable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th> 
          <th>Unit </th>
          <th>Floor </th>
          <th>Building </th>
          <th>Property </th>
          <th>Billing Code</th>
          <th style={{ display: "none" }}>Deposit Type</th>
          <th>Amount</th>
          <th>Fore. Amount</th>
          <th>From Date </th>
          <th>End Date</th>
        </tr>
      </thead>
      <tbody>
      {SecurityDepositTable.map((row, index) => (
              <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
              id={`seUkNo_${index}`}
              name={`seUkNo_${index}`}
            value={row.seUkNo}
            />
          </td>
          <td>
          <InputText disabled 
            id={`se_UN_TS_${index}`}
            name={`se_UN_TS_${index}`}
            defaultValue={row.se_UN_TS}
          />
          </td>
          <td>
          <InputText disabled 
            id={`se_FL_NO_${index}`}
            name={`se_FL_NO_${index}`}
            defaultValue={row.se_FL_NO}
          />
          </td>
          <td>
          <InputText disabled
            id={`se_BL_NO_${index}`}
            name={`se_BL_NO_${index}`}
            defaultValue={row.se_BL_NO}
          />
          </td>
          
          <td>
          <InputText disabled 
            id={`se_PR_NO_${index}`}
            name={`se_PR_NO_${index}`}
            defaultValue={row.se_PR_NO}
          />
          </td>
          
          <td> 
            <Dropdown disabled={isDisabled}
              id={`se_BL_CD_${index}`}
              name={`se_BL_CD_${index}`}
              value={data[`se_BL_CD_${index}`] ? data[`se_BL_CD_${index}`]:row.se_BL_CD }
              options={se_BL_CDOptions}
              onChange={(e) => handleFormDataChange5(`se_BL_CD_${index}`, e.value)}
              placeholder="Select Billing Code"
            />
          </td>
          <td style={{ display: "none" }}>
            <Dropdown  disabled={isDisabled}
              id={`se_AS_NO_${index}`}
              name={`se_AS_NO_${index}`}
              value={data[`se_AS_NO_${index}`] ? data[`se_AS_NO_${index}`]:row.se_AS_NO }
              options={se_AS_NOOptions}
              onChange={(e) => handleFormDataChange6(`se_AS_NO_${index}`, e.value)}
              placeholder="Select Deposit Type"
            />
          </td>
          <td>
          <InputText  
            disabled={data.se_TR_CR === se_BS_CR}
            id={`se_EX_PR_${index}`}
            name={`se_EX_PR_${index}`}
            defaultValue={row.se_EX_PR}
            onChange={(e) => onchangedata(e)}
          />
          </td>

          <td>
          <InputText  
            disabled={data.se_TR_CR === se_BS_CR}
            // disabled={data.se_TR_CR !== se_BS_CR}
            id={`se_FR_AM_${index}`}
            name={`se_FR_AM_${index}`}
            defaultValue={row.se_FR_AM }
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <Calendar disabled 
            id={`se_FR_DT_${index}`}
            name={`se_FR_DT_${index}`}
            value={ row.se_FR_DT ? moment(row.se_FR_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "se_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
            dateFormat="dd/mm/yy"
          />
          </td>
          <td>
          <Calendar disabled 
            id={`se_EN_DT_${index}`}
            name={`se_EN_DT_${index}`}
            value={ row.se_EN_DT ? moment(row.se_EN_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "se_EN_DT", value: moment(e.value).format("YYYYMMDD") } })}
            dateFormat="dd/mm/yy"
          />
          </td>
          </tr>
          ))}
      </tbody>
    </table>   
    </div>
      </div>  
    <LeasePopup
      visible={LeaseNoPopupVisible}
      onClose={() => setLeaseNoPopupVisible(false)}
      onDataSelect={handleDataSelect2}
      formData={data}
      leaseType={se_LE_TY}
    /> 
    </Dialog>
  );
}
