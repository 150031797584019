import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { InputMask } from "primereact/inputmask";
import ButtonStyles from "views/common/ButtonStyles";
import PatientDetailsPopup from "views/admin/Popups/PatientDetailsPopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function OperationDetailsDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  SelectedItem,
  setselectedItem,
  SelectedItem1,
  setselectedItem1,
  SelectedItem2,
  setselectedItem2,
  SelectedItem3,
  setselectedItem3,
  SelectedItem4,
  setselectedItem4,
  selectedSymptom,
  setselectedSymptom,
  ot_OP_TY,
  setot_OP_TY,
  ot_OP_NM,
  setot_OP_NM,
  ot_CO_NO,
}) {
  const {
    otUkNo,
    ot_PA_ID,
    dm_AD_NO,
    ot_TR_DT,
    ot_AS_01,
    ot_AS_02,
    ot_AN_TH,
    ot_TE_CH,
    ot_OP_CH,
    ot_TY_PE,

  } = data;
  const {borderbox,inboxstyle} = ButtonStyles();
// Handle form data change
const onChangeTime=(name,value,index)=>{
  value= moment(value, "HH:mm:ss").format("HHmmss");
  onchangeFormData(name, value,index);
};
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [ot_OP_TYOptions, setot_OP_TYOptions] = useState([]);
  const [ot_OP_NMOptions, setot_OP_NMOptions] = useState([]);


  const TestTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setot_OP_TY(value);
  };
  
  const ReportDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setot_OP_NM(value);
  };


  useEffect(() => {
    TestType();
    Report();

  }, []);

  const TestType = async () => {
    const Options = await fetchClassCodeDS("LB/TY");
    setot_OP_TYOptions(Options);
  };

  const Report = async () => {
    const Options = await fetchClassCodeDS("HO/RP");
    setot_OP_NMOptions(Options);
  };

  
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AddresstypeC, setAddresstype] = useState("E");
// Doctor
  const [AddressVisible, setAddressVisible] = useState(false);
  const handleAddress =async (data) => {
    setselectedItem(data.ab_AD_NM)
    onchangeFormData("dm_AD_NO", data.abAdNo);
    setAddressVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setAddressVisible(true);
    setSelectedRowIndex(e);
  };

  // ASS1
  const [Address1Visible, setAddress1Visible] = useState(false);
  const handleAddress1 =async (data) => {
    setselectedItem1(data.ab_AD_NM)
    onchangeFormData("ot_AS_01", data.abAdNo);
    setAddress1Visible(false);
    setShowSearchIcon(false);
  };
  const openItem1Popup = (e) => {
    setAddress1Visible(true);
    setSelectedRowIndex(e);
  };
  // };ASS2
  const [Address2Visible, setAddress2Visible] = useState(false);
  const handleAddress2 =async (data) => {
    setselectedItem2(data.ab_AD_NM)
    onchangeFormData("ot_AS_02", data.abAdNo);
    setAddress2Visible(false);
    setShowSearchIcon(false);
  };
  const openItem2Popup = (e) => {
    setAddress2Visible(true);
    setSelectedRowIndex(e);
  };
  // AN
  const [Address3Visible, setAddress3Visible] = useState(false);
  const handleAddress3 =async (data) => {
    setselectedItem3(data.ab_AD_NM)
    onchangeFormData("ot_AN_TH", data.abAdNo);
    setAddress3Visible(false);
    setShowSearchIcon(false);
  };
  const openItem3Popup = (e) => {
    setAddress3Visible(true);
    setSelectedRowIndex(e);
  };
  // };OT
  const [Address4Visible, setAddress4Visible] = useState(false);
  const handleAddress4 =async (data) => {
    setselectedItem4(data.ab_AD_NM)
    onchangeFormData("ot_TE_CH", data.abAdNo);
    setAddress4Visible(false);
    setShowSearchIcon(false);
  };
  const openItem4Popup = (e) => {
    setAddress4Visible(true);
    setSelectedRowIndex(e);
  };

    // Address
    const [SymptomVisible, setSymptomVisible] = useState(false);
    const handleSymptom =async (data) => {
      setselectedSymptom(data.pd_PA_NM)
      onchangeFormData("ot_PA_ID", data.pd_PA_ID, SelectedRowIndex);
      setSymptomVisible(false);
      setShowSearchIcon(false);
    };
    const openAddressPopup = (e) => {
      setSymptomVisible(true);
      setSelectedRowIndex(e);
    };

const isUpdateMode = Boolean(otUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "70rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={otUkNo ? "Revise Operation Details" : "Add Operation Details"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
            <div
                style={{
                  marginTop: "2%",
                  border: "1px solid #e8e8e8",
                  padding: "16px",
                  borderRadius: "15px",
                  backgroundColor: "aliceblue",
                  overflow: "auto",
                }}
              >
                <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ot_PA_ID" className="font-bold">
              Patient ID <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="pa_NAME"
                      name="pa_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={selectedSymptom}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openAddressPopup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="ot_PA_ID"
                      name="ot_PA_ID"
                      value={ot_PA_ID}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                    <label htmlFor="ot_CO_NO" className="font-bold" >
                     Company
                    </label>
                    <InputText  disabled
                      id="ot_CO_NO"
                      name="ot_CO_NO"
                      value={ot_CO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ot_TR_DT" className="font-bold">
                  Date
                    </label>
                    <Calendar
                      id="ot_TR_DT"
                      name="ot_TR_DT"
                      value={moment(ot_TR_DT, "YYYYMMDD").toDate()}
                      onChange={(e) => onchangedata({ target: { name: "ot_TR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ot_TR_TM" className="font-bold">
                  Time
                    </label>
                    <InputMask
                    id="ot_TR_TM"
                    name="ot_TR_TM"
                    mask="99:99:99"
                    onBlur={(e) => { onChangeTime("ot_TR_TM",e.target.value,);}}
                    value={moment("ot_TR_TM", "HHmmss").format("HH:mm:ss")}
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ot_OP_TY" className="font-bold">
                    Operation Type
                    </label>
                    <Dropdown
                    name="ot_OP_TY"
                    value={ot_OP_TY}
                    options={ot_OP_TYOptions}
                    onChange={(e) => TestTypeDropdown("ot_OP_TY", e.value)}
                    placeholder="Select Operation Type"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ot_OP_NM" className="font-bold">
                    Operation Name
                    </label>
                    <Dropdown
                    name="ot_OP_NM"
                    value={ot_OP_NM}
                    options={ot_OP_NMOptions}
                    onChange={(e) => ReportDropdown("ot_OP_NM", e.value)}
                    placeholder="Select Operation Name"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ot_OP_CH" className="font-bold">
                   Charges
                    </label>
                    <InputText disabled
                      id="ot_OP_CH"
                      name="ot_OP_CH"
                      value={ot_OP_CH}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ot_TY_PE" className="font-bold">
                   Type
                    </label>
                    <InputText
                      id="ot_TY_PE"
                      name="ot_TY_PE"
                      value={ot_TY_PE}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="dm_AD_NO" className="font-bold">
              Doctor No <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="dname"
                      name="dname"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedItem}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItemPopup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="dm_AD_NO"
                      name="dm_AD_NO"
                      value={dm_AD_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ot_AS_01" className="font-bold">
             Assistant 1 <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="as1_NAME"
                      name="as1_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedItem1}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItem1Popup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="ot_AS_01"
                      name="ot_AS_01"
                      value={ot_AS_01}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ot_AS_02" className="font-bold">
             Assistant 2 <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="as2_NAME"
                      name="as2_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedItem2}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItem2Popup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="ot_AS_02"
                      name="ot_AS_02"
                      value={ot_AS_02}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ot_AN_TH" className="font-bold">
              Anesthetist<span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="an_NAME"
                      name="an_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedItem3}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItem3Popup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="ot_AN_TH"
                      name="ot_AN_TH"
                      value={ot_AN_TH}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ot_TE_CH" className="font-bold">
              OT Techician<span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="ot_NAME"
                      name="ot_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedItem4}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItem4Popup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="ot_TE_CH"
                      name="ot_TE_CH"
                      value={ot_TE_CH}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
                
                
                  
                 
                </div>
            </div>
      </div>
      <AddressBookPopup
        visible={AddressVisible}
        onClose={() => setAddressVisible(false)}
        onDataSelect={handleAddress}
        formData={data}
        addressType={AddresstypeC}
        setFormData= {setFormData}
      />
            <AddressBookPopup
        visible={Address1Visible}
        onClose={() => setAddress1Visible(false)}
        onDataSelect={handleAddress1}
        formData={data}
        addressType={AddresstypeC}
        setFormData= {setFormData}
      />
            <AddressBookPopup
        visible={Address2Visible}
        onClose={() => setAddress2Visible(false)}
        onDataSelect={handleAddress2}
        formData={data}
        addressType={AddresstypeC}
        setFormData= {setFormData}
      />
            <AddressBookPopup
        visible={Address3Visible}
        onClose={() => setAddress3Visible(false)}
        onDataSelect={handleAddress3}
        formData={data}
        addressType={AddresstypeC}
        setFormData= {setFormData}
      />
            <AddressBookPopup
        visible={Address4Visible}
        onClose={() => setAddress4Visible(false)}
        onDataSelect={handleAddress4}
        formData={data}
        addressType={AddresstypeC}
        setFormData= {setFormData}
      />
        <PatientDetailsPopup
        visible={SymptomVisible}
        onClose={() => setSymptomVisible(false)}
        onDataSelect={handleSymptom}
        formData={data}
      />
    </Dialog>
  );
}
