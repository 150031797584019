import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import AssetCostDialog from "./AssetCostDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  fmUkNo: "",
  fm_AT_NO: "",
  fm_AT_D1: "",
  fm_AT_D2: "",
  fm_AT_ST: "",
  fm_AT_SL: "",
  fm_AT_LO: "",
  fm_CO_NO: "",
  fm_CO_NM: "",
  fm_DT_AC: "",
  fm_CO_AC:"",
  assetCostAccountDes: "",
  fm_AD_AC: "",
  assetAccumulatedDes: "",
  fm_DP_AC: "",
  assetDepreciationDes: "",
  fm_DP_SD: "",
  fm_DP_MT: "",
  fm_AT_LF: "",
  fm_CT_FL:"",
  glUkNo:"",
};

const initialFilters = {
  fm_CT_FL: { value: "", operation: "" },
  fm_AT_NO: { value: "", operation: "" },
  fm_AT_D1: { value: "", operation: "" },
  fm_AT_D2: { value: "", operation: "" },
  fm_AT_ST: { value: "", operation: "" },
  fm_AT_SL: { value: "", operation: "" },
  fm_AT_LO: { value: "", operation: "" },
  fm_CO_NO: { value: "", operation: "" },
  fm_CO_NM: { value: "", operation: "" },
  fm_DT_AC: { value: "", operation: "" },
  fm_CO_AC: { value: "", operation: "" },
  assetCostAccountDes: { value: "", operation: "" },
  fm_AD_AC: { value: "", operation: "" },
  assetAccumulatedDes: { value: "", operation: "" },
  fm_DP_AC: { value: "", operation: "" },
  assetDepreciationDes: { value: "", operation: "" },
  fm_DP_SD: { value: "", operation: "" },
  fm_DP_MT: { value: "", operation: "" },
  fm_AT_LF: { value: "", operation: "" },
};

const AssetCostMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,inputStyle1
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);


  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedemEMNO1, setSelectedemEMNO1] = useState("");
  // AdvanceSearch
  const [SelectedAsset, setselectedAsset ] = useState("");
  const [SelectedDesc, setselectedDesc ] = useState("");
  const [SelectedemEMNOA, setSelectedemEMNOA] = useState("");
  const [glUkNo, setglUkNo] = useState("");
  const [AssetCosttable, setAssetCosttable] = useState([{}]);
  const [Account, setAccount] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setAssetCosttable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setselectedAsset(null);
    setselectedDesc(null);
    setOpen(true);
  };

  const handleClose = () => {
    setAssetCosttable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleSearch();
    }
  };
  const addRow = () => {
    setAssetCosttable([...AssetCosttable, {}]);
  };

  const deleteRow = () => {
    if (AssetCosttable.length > 0) {
      const updatedData = [...AssetCosttable];
      updatedData.pop();
      setAssetCosttable(updatedData);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/AssetCost/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const ResopurceCompetencyData = response.data;
          delete ResopurceCompetencyData.fmUkNo;
          setFormData(ResopurceCompetencyData);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleSearch();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/FixedAssetMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    let updatedSelectedRows;
  
    if (!isRowSelected) {
      updatedSelectedRows = [...selectedRows, rowId];
    } else {
      updatedSelectedRows = selectedRows.filter(id => id !== rowId);
    }
  
    setSelectedRows(updatedSelectedRows);
    setIsRecordSelected(updatedSelectedRows.length > 0);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/FixedAssetMaster/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ResopurceCompetencyData = response.data;
        setFormData(ResopurceCompetencyData);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
            <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_CT_FL"] ? filters["fm_CT_FL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_CT_FL",
                        e.target.value,
                        filters["fm_CT_FL"]
                          ? filters["fm_CT_FL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_NO"] ? filters["fm_AT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_NO",
                        e.target.value,
                        filters["fm_AT_NO"]
                          ? filters["fm_AT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_D1"] ? filters["fm_AT_D1"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_D1",
                        e.target.value,
                        filters["fm_AT_D1"] ? filters["fm_AT_D1"].operation : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_D2"] ? filters["fm_AT_D2"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_D2",
                        e.target.value,
                        filters["fm_AT_D2"]
                          ? filters["fm_AT_D2"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_ST"] ? filters["fm_AT_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_ST",
                        e.target.value,
                        filters["fm_AT_ST"]
                          ? filters["fm_AT_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_SL"] ? filters["fm_AT_SL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_SL",
                        e.target.value,
                        filters["fm_AT_SL"]
                          ? filters["fm_AT_SL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_LO"] ? filters["fm_AT_LO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_LO",
                        e.target.value,
                        filters["fm_AT_LO"]
                          ? filters["fm_AT_LO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_CO_NO"] ? filters["fm_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_CO_NO",
                        e.target.value,
                        filters["fm_CO_NO"]
                          ? filters["fm_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_CO_NM"] ? filters["fm_CO_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_CO_NM",
                        e.target.value,
                        filters["fm_CO_NM"]
                          ? filters["fm_CO_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_DT_AC"] ? filters["fm_DT_AC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_DT_AC",
                        e.target.value,
                        filters["fm_DT_AC"]
                          ? filters["fm_DT_AC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>         
                <td>
                  <input
                    type="text"
                    value={filters["fm_CO_AC"] ? filters["fm_CO_AC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_CO_AC",
                        e.target.value,
                        filters["fm_CO_AC"]
                          ? filters["fm_CO_AC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["assetCostAccountDes"] ? filters["assetCostAccountDes"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "assetCostAccountDes",
                        e.target.value,
                        filters["assetCostAccountDes"] ? filters["assetCostAccountDes"].operation : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AD_AC"] ? filters["fm_AD_AC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AD_AC",
                        e.target.value,
                        filters["fm_AD_AC"]
                          ? filters["fm_AD_AC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["assetAccumulatedDes"] ? filters["assetAccumulatedDes"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "assetAccumulatedDes",
                        e.target.value,
                        filters["assetAccumulatedDes"]
                          ? filters["assetAccumulatedDes"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_DP_AC"] ? filters["fm_DP_AC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_DP_AC",
                        e.target.value,
                        filters["fm_DP_AC"]
                          ? filters["fm_DP_AC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["assetDepreciationDes"] ? filters["assetDepreciationDes"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "assetDepreciationDes",
                        e.target.value,
                        filters["assetDepreciationDes"]
                          ? filters["assetDepreciationDes"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_DP_SD"] ? filters["fm_DP_SD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_DP_SD",
                        e.target.value,
                        filters["fm_DP_SD"]
                          ? filters["fm_DP_SD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_DP_MT"] ? filters["fm_DP_MT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_DP_MT",
                        e.target.value,
                        filters["fm_DP_MT"]
                          ? filters["fm_DP_MT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_LF"] ? filters["fm_AT_LF"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_LF",
                        e.target.value,
                        filters["fm_AT_LF"]
                          ? filters["fm_AT_LF"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
              </tr>
              <tr>
              <th style={tableHeaderStyle}></th>
              <th style={tableHeaderStyle}>Cost Flag</th>
                <th style={tableHeaderStyle}>Asset No </th>
                <th style={tableHeaderStyle}>Asset Description</th>
                <th style={tableHeaderStyle}>Asset Description2</th>
                <th style={tableHeaderStyle}>Asset Status</th>
                <th style={tableHeaderStyle}>Asset Serial No</th>
                <th style={tableHeaderStyle}>Location</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Cost Center</th>
                <th style={tableHeaderStyle}>Date Acquired</th>
                <th style={tableHeaderStyle}>Asset Cost Account</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Asset Accumulated Dep Account</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Asset Deprication Account</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Deprication Start Date</th>
                <th style={tableHeaderStyle}>Deprication Method</th>
                <th style={tableHeaderStyle}>Life in Months</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.fmUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.fmUkNo}
                        checked={selectedRows.includes(rowData.fmUkNo)}
                        onChange={() => handleRowSelect(rowData.fmUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.fm_CT_FL}</td>
                    <td style={tableCellStyle}>{rowData.fm_AT_NO}</td>
                    <td style={tableCellStyle}>{rowData.fm_AT_D1}</td>
                    <td style={tableCellStyle}>{rowData.fm_AT_D2}</td>
                    <td style={tableCellStyle}>{rowData.fm_AT_ST}</td>
                    <td style={tableCellStyle}>{rowData.fm_AT_SL}</td>
                    <td style={tableCellStyle}>{rowData.fm_AT_LO}</td>
                    <td style={tableCellStyle}>{rowData.fm_CO_NO}</td>
                    <td style={tableCellStyle}>{rowData.fm_CO_NM}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.fm_DT_AC)}</td>
                    <td style={tableCellStyle}>{rowData.fm_CO_AC}</td>
                    <td style={tableCellStyle}>{rowData.assetCostAccountDes}</td>
                    <td style={tableCellStyle}>{rowData.fm_AD_AC}</td>
                    <td style={tableCellStyle}>{rowData.assetAccumulatedDes}</td>
                    <td style={tableCellStyle}>{rowData.fm_DP_AC}</td>
                    <td style={tableCellStyle}>{rowData.assetDepreciationDes}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.fm_DP_SD)}</td>  
                    <td style={tableCellStyle}>{rowData.fm_DP_MT}</td>
                    <td style={tableCellStyle}>{rowData.fm_AT_LF}</td>  
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
    {/* pagination */}
    <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <AssetCostDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleResponse={handleResponse}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}   
        setFormData={setFormData}
        SelectedemEMNO={SelectedemEMNO}
        SelectedemEMNO1={SelectedemEMNO1}
        setSelectedemEMNO={setSelectedemEMNO}
        setSelectedemEMNO1={setSelectedemEMNO1}
        AssetCosttable={AssetCosttable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
        SelectedAsset={SelectedAsset}
        setselectedAsset={setselectedAsset}
        SelectedDesc={SelectedDesc}
        setselectedDesc={setselectedDesc}
        setSelectedemEMNOA={setSelectedemEMNOA}
        SelectedemEMNOA={SelectedemEMNOA}
        Account={Account}
        setAccount={setAccount}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
      />
    </div>
  );
};

export default AssetCostMain;
