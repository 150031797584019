import React, { useState ,useEffect ,useRef} from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";

export default function ServiceRequest({ isOpen, handleClose }) {
  const { borderbox, inboxstyle } = ButtonStyles();
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const intialvalues = {
    ps_SR_DT:"",
    ps_SR_TM:"",
    ps_SR_AP:"",
    ps_SR_NM:"",
    ps_SR_PH:"",
    ps_SR_TY:"",
    ps_SR_AS:"",
    ps_SR_ST:"",
    ps_CO_NO:"",
  };
  const [FormData, setFormData] = useState(intialvalues);
  
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const onChangeTime=(name,value,index)=>{
    value= moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value,index);
  };
  useEffect(() => {
    if (isOpen) {
      setFormData(intialvalues);
    }
  }, [isOpen]);

//   DROPDOWNS
const [ps_SR_TY, setps_SR_TY] =useState("");
const [ps_SR_ST, setps_SR_ST] =useState("");
const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
const [ps_SR_TYOptions, setps_SR_TYOptions] = useState([]);
const [ps_SR_STOptions, setps_SR_STOptions] = useState([]);

const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setps_SR_TY(value);
  };
// Acq
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setps_SR_ST(value);
  };
  useEffect(() => {
    DropdownServicetype();
    DropdownStatus();
  }, []);

  const DropdownServicetype = async () => {
    const Options = await fetchClassCodeDS("SR/TY");
    setps_SR_TYOptions(Options);
  };

  const DropdownStatus = async () => {
    const Options = await fetchClassCodeDS("SR/ST");
    setps_SR_STOptions(Options);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
    }
  };
  const handleFormSubmit = () => {
    const request = {
      "propertyServiceRequest":{
        "ps_SR_DT" : FormData.ps_SR_DT,
        "ps_SR_TM" : FormData.ps_SR_TM,
        "ps_SR_AP" : FormData.ps_SR_AP,
        "ps_SR_NM" : FormData.ps_SR_NM,
        "ps_SR_PH" : FormData.ps_SR_PH,
        "ps_SR_TY" : FormData.ps_SR_TY,
        "ps_SR_AS" : FormData.ps_SR_AS,
        "ps_SR_ST" : FormData.ps_SR_ST,
        "ps_CO_NO" : company,
      }
    }
    axios.post(`${domain}/api/PropertyServiceRequest/Add`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        handleResponse(resp.data);
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  };

  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "8%" }}
        icon="pi pi-credit-card"
        onClick={handleFormSubmit}
        tooltip="Save"
      />
    </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={isOpen}
      onHide={handleClose}
      style={{ width: "40rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Amenities"
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
       <div style={borderbox} >
          <div style={inboxstyle} >
            <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="ps_SR_AP" className="font-bold" style={{fontSize:'70%'}}>
                Apartment 
              </label>
              <InputText
                id="ps_SR_AP"
                name="ps_SR_AP"
                value={FormData.ps_SR_AP}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            </div>
            <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="pm_VI_PH" className="font-bold" style={{fontSize:'70%'}}>
                Name 
              </label>
              <InputText
                id="pm_VI_PH"
                name="pm_VI_PH"
                value={FormData.pm_VI_PH}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col">
              <label htmlFor="ps_SR_PH" className="font-bold" style={{fontSize:'70%'}}>
                Phone 
              </label>
              <InputText
                id="ps_SR_PH"
                name="ps_SR_PH"
                value={FormData.ps_SR_PH}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            </div>
            <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="ps_SR_DT" className="font-bold" style={{fontSize:'70%'}}>
                Date 
              </label>
              <Calendar
                id="ps_SR_DT"
                name="ps_SR_DT"
                value={moment(FormData.ps_SR_DT, "YYYYMMDD").isValid() ? moment(FormData.ps_SR_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "ps_SR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col">
              <label htmlFor="ps_SR_TM" className="font-bold" style={{fontSize:'70%'}}>
                Time
              </label>
              <InputMask
                id="ps_SR_TM"
                name="ps_SR_TM"
                mask="99:99:99"
                onBlur={(e) => { onChangeTime("ps_SR_TM",e.target.value,);}}
                value={moment(FormData.ps_SR_TM, "HHmmss").format("HH:mm:ss")}
              />
            </div>
            </div>
            <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="ps_SR_TY" className="font-bold" style={{fontSize:'70%'}}>
                Service Type 
              </label>
              <Dropdown
                id="ps_SR_TY"
                name="ps_SR_TY"
                value={ps_SR_TY}
                options={ps_SR_TYOptions}
                onChange={(e) => onchangeDropDownFormData1("ps_SR_TY", e.value)}
                placeholder="Select Service Type"
            />
            </div>
            {/* <div className="field col">
              <label htmlFor="ps_SR_TM" className="font-bold" style={{fontSize:'70%'}}>
                Remarks
              </label>
              <InputText
                id="pm_VI_PH"
                name="pm_VI_PH"
                value={FormData.pm_VI_PH}
                onChange={(e) => onchangedata(e)}
              />
            </div> */}
            <div className="field col">
              <label htmlFor="ps_SR_AS" className="font-bold" style={{fontSize:'70%'}}>
                Assigned
              </label>
              <InputText
                id="ps_SR_AS"
                name="ps_SR_AS"
                value={FormData.ps_SR_AS}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            </div>
            <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="ps_SR_ST" className="font-bold" style={{fontSize:'70%'}}>
                Status
              </label>
              <Dropdown
                id="ps_SR_ST"
                name="ps_SR_ST"
                value={ps_SR_ST}
                options={ps_SR_STOptions}
                onChange={(e) => onchangeDropDownFormData2("ps_SR_ST", e.value)}
                placeholder="Select Status"
                />
            </div>
            </div>
          </div>
      </div>
    </Dialog>
  );
}
