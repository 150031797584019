import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import SupplierPopup from "../Popups/SupplierPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function PaymentReversalDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  PaymentReversalTableData,
  setPaymentReversalTableData,
  ph_VO_RE,
  setph_VO_RE,
}) {
  const {
    ph_UK_NO,
    ph_VO_DT,
  } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, multitableheader } = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();

  const [ph_VO_REOptions, setph_VO_REOptions] = useState([]);



  //  Reason
  const  ReasonDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setph_VO_RE(value);
  };



  useEffect(() => {
    Reason();

  }, []);


  const Reason = async () => {
    const Options = await fetchClassCodeDS("RV/RE");
    setph_VO_REOptions(Options);
  };



  const addRow = () => {
    setPaymentReversalTableData([...PaymentReversalTableData, {}]);
  };

  const deleteRow = () => {
    if (PaymentReversalTableData.length > 0) {
      const updatedData = [...PaymentReversalTableData];
      updatedData.pop();
      setPaymentReversalTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(ph_UK_NO);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
        tooltip={isUpdateMode ? "Update" : "Submit"}>
      </Button>
    </React.Fragment>
  );




  return (
    <Dialog
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={ph_UK_NO ? "Revise Expense Policy" : "Add Expense Policy"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={inboxstyle}>
        <div className="formgrid grid">

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_VO_DT" className="font-bold">
              Reversal G/L Date
            </label>
            <Calendar
              id="ph_VO_DT"
              name="ph_VO_DT"
              value={moment(data.ph_VO_DT, "YYYYMMDD").isValid() ? moment(data.ph_VO_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: { name: "ph_VO_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_VO_RE" className="font-bold">
            Reason
            </label>
            <Dropdown
              name="ph_VO_RE"
              value={ph_VO_RE}
              options={ph_VO_REOptions}
              onChange={(e) => ReasonDropdown("ph_VO_RE", e.value)}
              placeholder=" Reason"
            />
          </div>

        </div>
      </div>
    </Dialog>
  );
}
