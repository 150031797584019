import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { InputMask } from "primereact/inputmask";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import AddressBookPopup from "../Popups/AddressBookPopup";
import axios from "axios";
import ProjectMasterPopup from "views/admin/Popups/ProjectMasterPopup";
export default function TaskManagementDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  onchangeFormData1,
  handleFormSubmit,
  setFormData,
  td_PR_CD,
  settd_PR_CD,
  td_PR_TY,
  settd_PR_TY,
  td_ST_CD,
  td_RE_SN,
  settd_RE_SN,
  td_ST_AG,
  SelectedAssign,
  setSelectedAssign,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  settd_ES_TM,
  td_CO_NO,
  handleConfirm,
  td_ST_TM,
  settd_ST_TM,
  td_EN_TM,
  settd_EN_TM,
  td_ES_TM,
  td_DE_PT,
  settd_DE_PT,
  SelectedProjectMaster,
  setSelectedProjectMaster,
}) {
  const {
    tdUkNo,
    // td_ES_TM,
    td_TS_ID,
    // td_DE_PT, 
    td_AS_TM,
    td_AE_TM,
    td_RM_RM,
    td_AS_GN,
    td_TA_SK,
  } = data;
  const [td_ST_DT, settd_ST_DT ] = useState("");
  const [td_EN_DT, settd_EN_DT ] = useState("");

  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();

   // Handle form data change
   const onChangeTime=(name,value,index)=>{
    value= moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value,index);
  };

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // dropdowns
  const { fetchClassCodeDS } = ClassCode();
  const [td_PR_CDOptions, settd_PR_CDOptions] = useState([]);
  const [td_DE_PTOptions, settd_DE_PTOptions] = useState([]);
  const [td_PR_TYOptions, settd_PR_TYOptions] = useState([]);
  const [td_ST_CDOptions, settd_ST_CDOptions] = useState([]);
  const [td_ST_AGOptions, settd_ST_AGOptions] = useState([]);
  const [td_RE_SNOptions, settd_RE_SNOptions] = useState([]);
// Project Code
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settd_PR_CD(value);
  };
// Priority
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settd_PR_TY(value);
  };
// Reason
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settd_RE_SN(value);
  };

  const DepartmentDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settd_DE_PT(value);
  };

  useEffect(() => {
    fetchDropdownPRCD();
    fetchDropdownPRTY();
    fetchDropdownSTCD();
    fetchDropdownSTAG();
    fetchDropdownRESN();
    Department();

  }, []);

  const fetchDropdownPRCD = async () => {
    const Options = await fetchClassCodeDS("PR/CD");
    settd_PR_CDOptions(Options);
  };

  const fetchDropdownPRTY = async () => {
    const Options = await fetchClassCodeDS("TA/PR");
    settd_PR_TYOptions(Options);
  };

  const fetchDropdownSTCD = async () => {
    const Options = await fetchClassCodeDS("TA/ST");
    settd_ST_CDOptions(Options);
  };

  const Department = async () => {
    const Options = await fetchClassCodeDS("EM/DP");
    settd_DE_PTOptions(Options);
  };

  const fetchDropdownSTAG = async () => {
    const Options = await fetchClassCodeDS("TA/SG");
    settd_ST_AGOptions(Options);
  };

  const fetchDropdownRESN = async () => {
    const Options = await fetchClassCodeDS("TA/RE");
    settd_RE_SNOptions(Options);
  };

  const [ProjectMasterPopupVisible, setProjectMasterPopupVisible] = useState(false);
  const openProjectMasterPopup = () => {
    setProjectMasterPopupVisible(true);
  };
  const handleProjectMaster = async (data) => {
    setSelectedProjectMaster(data.pm_PR_DS);
    document.getElementById("td_PR_CD").value = data.pm_PR_NO;
    // onchangeFormData("eh_PR_NO", data.pm_PR_NO);
    setProjectMasterPopupVisible(false);
  };

// assigned to
const [AssignedPopupVisible, setAssignedPopupVisible] =useState(false);
const [addressTypeAssign, setaddressTypeAssign] = useState("E");
const [showSearchIcon, setShowSearchIcon] = useState(false);

const openASGNPopup = () => {
  setAssignedPopupVisible(true);
};
const handleSelectAssign = (data) => {
  setSelectedAssign(data.ab_AD_NM);
  onchangeFormData("td_AS_GN", data.abAdNo);
  setAssignedPopupVisible(false);
  setShowSearchIcon(false);
};

const handleTdEsTmChange = (e) => {
  settd_ES_TM(e.target.value); 
  calculated(); 
};

useEffect(() => {
  if (td_ST_DT && td_ST_TM && td_ES_TM) {
    calculateEndDateAndTime();
  } else if (td_ST_DT && td_ST_TM && td_EN_DT && td_EN_TM) {
    calculateEstimatedTime();
  }
}, [td_ST_DT, td_ST_TM, td_ES_TM, td_EN_DT, td_EN_TM]);

const calculated = async () => {
  const Url = `${domain}/api/TaskManagement/calculated`;
  const requestData = {
    "td_ST_DT": td_ST_DT || "" ,
    "td_ST_TM": td_ST_TM || "" ,
    "td_ES_TM": td_ES_TM || "" ,
  };

  try {
    const response = await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data;
    if (dataItMt) {
      onchangeFormData("td_EN_DT", dataItMt.EndDate);
      onchangeFormData("td_EN_TM", dataItMt.EndTime);
      settd_EN_TM(dataItMt.EndTime);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const calculateEndDateAndTime = async () => {
  const Url = `${domain}/api/TaskManagement/calculated`;
  const requestData = {
    td_ST_DT: td_ST_DT || "",
    td_ST_TM: td_ST_TM || "",
    td_ES_TM: td_ES_TM || "",
  };

  try {
    const response = await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data;
    if (dataItMt) {
      onchangeFormData("td_EN_DT", dataItMt.EndDate);
      onchangeFormData("td_EN_TM", dataItMt.EndTime);
      settd_EN_TM(dataItMt.EndTime);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const calculateEstimatedTime = async () => {
  const Url = `${domain}/api/TaskManagement/recalculate`;
  const requestData = {
    td_ST_DT: td_ST_DT || "",
    td_ST_TM: td_ST_TM || "",
    td_EN_DT: td_EN_DT || "",
    td_EN_TM: td_EN_TM || "",
  };

  try {
    const response = await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data;
    if (dataItMt) {
      onchangeFormData("td_ES_TM", dataItMt.EstimatedTime);
      settd_ES_TM(dataItMt.EstimatedTime);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const handleStartDateChange = (e) => {
  const value = moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "";
  settd_ST_DT(value);
  onchangedata({ target: { name: "td_ST_DT", value } });
  EstimatedTime();
};
const handleStartTimeChange = (e) => {
  const value = moment(e.target.value, "HH:mm:ss").format("HHmmss");
  settd_ST_TM(value);
  onchangedata({ target: { name: "td_ST_TM", value } });
  EstimatedTime();
};
const handleEndDateChange = (e) => {
  const value = moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "";
  settd_EN_DT(value);
  onchangedata({ target: { name: "td_EN_DT", value } });
  calculateEstimatedTime();
};
const handleEndTimeChange = (e) => {
  const value = moment(e.target.value, "HH:mm:ss").format("HHmmss");
  settd_EN_TM(value);
  onchangedata({ target: { name: "td_EN_TM", value } });
  calculateEstimatedTime();
};
const EstimatedTime = async () => {
  const Url = `${domain}/api/TaskManagement/recalculate`;
  const requestData = {
    "td_ST_DT": td_ST_DT || "" ,
    "td_ST_TM": td_ST_TM || "" ,
    "td_EN_DT": td_EN_DT || "" ,
    "td_EN_TM": td_EN_TM || "" ,
  };

  try {
    const response = await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data;
    if (dataItMt) {
      document.getElementById("td_ES_TM").value = dataItMt.EstimatedTime;
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const isUpdateMode = Boolean(tdUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '70%' }}>
    <h5>{tdUkNo ? "Revise Task Management" : "Add Task Management"}</h5>
    <div style={{ display:'flex', justifyContent: isUpdateMode ? 'flex-start' : 'flex-start', width: '70%'}}>
    {isUpdateMode && (
      <div style={{ display: 'flex',width:"10%" }}>
        <Button 
          style={{ width:"50%", marginRight: "8px",backgroundColor:"transparent",color:"blue",border:'none'}}
          icon="pi pi-check-circle" 
          onClick={handleConfirm} 
          tooltip={"Confirm"}>
        </Button>
      </div>
      )}
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
        tooltip={isUpdateMode ? "Update" : "Submit"}
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);

  // const productDialogFooter = (
  //   <React.Fragment>
  //   <div style={{ display: 'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end', width: '100%' }}>
  //   {isUpdateMode && (
  //   <div style={{ display: 'flex',width:"10%" }}>
  //   <Button 
  //   style={{ width:"50%", marginRight: "8px",backgroundColor:"transparent",color:"blue",}}
  //   icon="pi pi-check-circle" 
  //   onClick={handleConfirm} 
  //   tooltip={"Confirm"}>
  //   </Button>
  //   </div>
  //     )}
  //   <div style={{ display: 'flex', width: "10%" }}>
  //   <Button 
  //  style={{ width: "100%", marginRight: "8px", }}
  //   icon="pi pi-times" 
  //   variant="outlined" 
  //   onClick={handleClose}
  //   tooltip="Cancel"
  //   >
  //   </Button>
  //   <Button 
  //    style={{ width: "100%" }}
  //   icon="pi pi-check" 
  //   onClick={handleFormSubmit} 
  //   tooltip={isUpdateMode ? "Update" : "Submit"}>
  //   </Button>
  //   </div>
  //   </div>
  // </React.Fragment>
  // );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
            <div style={inboxstyle} >
                <div className="formgrid grid">
                  <div  style={{display:"none"}}>
                    <label htmlFor="td_TS_ID" className="font-bold">
                     Task ID
                    </label>
                    <InputText disabled
                      id="td_TS_ID"
                      name="td_TS_ID"
                      value={td_TS_ID}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_TA_SK" className="font-bold">
                     Task 
                    </label>
                    <InputText 
                      id="td_TA_SK"
                      name="td_TA_SK"
                      value={td_TA_SK}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_CO_NO" className="font-bold">
                     Company 
                    </label>
                    <InputText disabled
                      id="td_CO_NO"
                      name="td_CO_NO"
                      value={td_CO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_DE_PT" className="font-bold">
                    Department
                    </label>
                    <Dropdown
                      id="td_DE_PT"
                      name="td_DE_PT"
                      value={td_DE_PT}
                      options={td_DE_PTOptions}
                      onChange={(e) => DepartmentDropdown("td_DE_PT", e.value)}
                      placeholder="Select Department"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_ST_DT" className="font-bold">
                      Start Date
                    </label>
                    <Calendar
                      id="td_ST_DT"
                      name="td_ST_DT"
                      value={moment(data.td_ST_DT, "YYYYMMDD").isValid() ? moment(data.td_ST_DT, "YYYYMMDD").toDate() : null}
                      onChange={handleStartDateChange}
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_ST_TM" className="font-bold">
                   Start Time
                    </label>
                    <InputMask
                      id={td_ST_TM}
                      name={td_ST_TM}
                      mask="99:99:99"
                      onBlur={(e) => handleStartTimeChange(e)}
                      value={moment(td_ST_TM, "HHmmss").format("HH:mm:ss")}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_ES_TM" className="font-bold">
                      Estimated Time
                    </label>
                    <InputText
                      id="td_ES_TM"
                      name="td_ES_TM"
                      value={td_ES_TM}
                      onChange={(e) => handleTdEsTmChange(e)} 
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_EN_DT" className="font-bold">
                      End Date
                    </label>
                    <Calendar
                      id="td_EN_DT"
                      name="td_EN_DT"
                      value={moment(data.td_EN_DT, "YYYYMMDD").isValid() ? moment(data.td_EN_DT, "YYYYMMDD").toDate() : null}
                      onChange={handleEndDateChange}
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_EN_TM" className="font-bold">
                      End Time
                    </label>
                    <InputMask
                      id={td_EN_TM}
                      name={td_EN_TM}
                      mask="99:99:99"
                      onBlur={(e) => handleEndTimeChange(e)}
                      value={moment(td_EN_TM, "HHmmss").format("HH:mm:ss")}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_PR_CD" className="font-bold">
                      Project Code <span style={{color:'red'}}>*</span>
                    </label>
                    {/* <Dropdown
                      id="td_PR_CD"
                      name="td_PR_CD"
                      value={td_PR_CD}
                      options={td_PR_CDOptions}
                      onChange={(e) => onchangeDropDownFormData1("td_PR_CD", e.value)}
                      placeholder="Select Project Code"
                    /> */}
                    <div style={{ display: "flex" }}>
                      <InputText
                        id="proj_NAME"
                        name="proj_NAME"
                        onChange={(Event) => onchangedata(Event)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={SelectedProjectMaster}
                      />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openProjectMasterPopup}
                      ></i>
                      )}
                      <InputText  style={{ display: "block" }}
                        id="td_PR_CD"
                        name="td_PR_CD"
                        value={td_PR_CD}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </div>
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_AS_GN" className="font-bold">
                      Assigned To <span style={{color:'red'}}>*</span>
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText
                      id="name"
                      name="name"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedAssign}
                    />
                     {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openASGNPopup}
                      ></i>
                    )}
                    </div>
                    <div style={{ display: 'none' }}>
                      <InputText
                        id="td_AS_GN"
                        name="td_AS_GN"
                        value={td_AS_GN}
                        onChange={(event) => onchangedata(event)}
                      />
                    </div>
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_PR_TY" className="font-bold">
                      Priority
                    </label>
                    <Dropdown
                      id="td_PR_TY"
                      name="td_PR_TY"
                      value={td_PR_TY}
                      options={td_PR_TYOptions}
                      onChange={(e) => onchangeDropDownFormData2("td_PR_TY", e.value)}
                      placeholder="Select Priority"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_ST_CD" className="font-bold">
                      Status
                    </label>
                    <Dropdown
                      id="td_ST_CD"
                      name="td_ST_CD"
                      value={td_ST_CD}
                      options={td_ST_CDOptions}
                      onChange={(e) => onchangeDropDownFormData3("td_ST_CD", e.value)}
                      placeholder="Select Status"
                    />
                  </div>
                </div>
              </div>
                <div style={inboxstyle} >
                <div className="formgrid grid">
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_AS_DT" className="font-bold">
                      Actual Start Date
                    </label>
                    <Calendar
                      id="td_AS_DT"
                      name="td_AS_DT"
                      value={moment(data.td_AS_DT, "YYYYMMDD").isValid() ? moment(data.td_AS_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "td_AS_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_AE_DT" className="font-bold">
                      Actual End Date
                    </label>
                    <Calendar
                      id="td_AE_DT"
                      name="td_AE_DT"
                      value={moment(data.td_AE_DT, "YYYYMMDD").isValid() ? moment(data.td_AE_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "td_AE_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_ST_AG" className="font-bold">
                      Stage
                    </label>
                    <Dropdown
                      id="td_ST_AG"
                      name="td_ST_AG"
                      value={td_ST_AG}
                      options={td_ST_AGOptions}
                      onChange={(e) => onchangeDropDownFormData4("td_ST_AG", e.value)}
                      placeholder="Select Stage"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_AS_TM" className="font-bold">
                      Actual Start Time
                    </label>
                    <InputMask
                      id={td_AS_TM}
                      name={td_AS_TM}
                      mask="99:99:99"
                      onBlur={(e) => { onChangeTime("td_AS_TM",e.target.value,);}}
                      value={moment(td_AS_TM, "HHmmss").format("HH:mm:ss")}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_AE_TM" className="font-bold">
                    Actual End Time
                    </label>
                    <InputMask
                      id={td_AE_TM}
                      name={td_AE_TM}
                      mask="99:99:99"
                      onBlur={(e) => { onChangeTime("td_AE_TM",e.target.value,);}}
                      value={moment(td_AE_TM, "HHmmss").format("HH:mm:ss")}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_RE_SN" className="font-bold">
                      Reason
                    </label>
                    <Dropdown
                      id="td_RE_SN"
                      name="td_RE_SN"
                      value={td_RE_SN}
                      options={td_RE_SNOptions}
                      onChange={(e) => onchangeDropDownFormData5("td_RE_SN", e.value)}
                      placeholder="Select Reason"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_RM_RM" className="font-bold">
                   Remarks
                   </label>
                    <InputText
                      style={{width:"100ch"}}
                      id="td_RM_RM"
                      name="td_RM_RM"
                      value={td_RM_RM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
            </div>
      </div>
      <AddressBookPopup
        visible={AssignedPopupVisible}
        onClose={() => setAssignedPopupVisible(false)}
        onDataSelect={handleSelectAssign}
        formData={data}
        addressType={addressTypeAssign}
        setFormData={setFormData}
      />
       <ProjectMasterPopup
        visible={ProjectMasterPopupVisible}
        onClose={() => setProjectMasterPopupVisible(false)}
        onDataSelect={handleProjectMaster}
        formData={data}
      />
    </Dialog>
  );
}
