import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import VisitPlanDialog from "./VisitPlanDialog";
import "jspdf-autotable";
import saveAs from "file-saver";
import axios from "axios";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
const initialValue = {
  vp_EM_NO: "",
  vp_TR_DT: "",
  vp_LD_NO: "",
  vp_CT_NO: "",
  vp_CO_PH: "",
  vp_DE_GN: "",
  vp_VI_TY: "",
  vp_CN_NO: "",
  vp_PH_NO: "",
  vp_CI_TY: "",
  vp_ST_AT: "",
  email:"",
  cc_EM_IL:"",
};

const initialFilters = {
  vp_TR_DT: { value: "", operation: "" },
  vp_LD_NO: { value: "", operation: "" },
  vp_EM_NO: { value: "", operation: "" },
  vp_CT_NO: { value: "", operation: "" },
  vp_CN_NO: { value: "", operation: "" },
  vp_DE_GN: { value: "", operation: "" },
  vp_PH_NO: { value: "", operation: "" },
  email: { value: "", operation: "" },
  vp_VI_TY: { value: "", operation: "" },
  ab_CL_11: { value: "", operation: "" },
  ab_CL_12: { value: "", operation: "" },
  ab_CL_13: { value: "", operation: "" },
  vp_CI_TY: { value: "", operation: "" },
  vp_ST_AT: { value: "", operation: "" }
};


const VisitPlanMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,inputStyle1,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [vp_VI_TY, setvp_VI_TY] = useState("");
  const [ab_CL_11, setab_CL_11] = useState("");
  const [ab_CL_12, setab_CL_12] = useState("");
  const [ab_CL_13, setab_CL_13] = useState("");
  const [vp_CI_TY, setvp_CI_TY] = useState("");
  const [vp_ST_AT, setvp_ST_AT] = useState("");
  const [vp_CT_NO, setvp_CT_NO] =useState("");
  const [vp_EM_NO, setvp_EM_NO] =useState("");
  const [vp_CN_NO, setvp_CN_NO] =useState("");
  const [vp_VI_PR, setvp_VI_PR] =useState("");
  const [vp_LD_SC, setvp_LD_SC] =useState("");
  const [LeadOwnerPopupVisible, setLeadOwnerVisible] = useState(false);
  const [addressTypeLDOW, setaddressTypeLDOW] = useState("E");
  const [Owner, setOwner] = useState("");
  const [selectedowner, setSelectedowner] = useState("");
  
  const [VisitPlanTableData, setVisitPlanTableData] = useState([{}]);
  const [selectedabAdNM,setselectedabAdNM] = useState("");
  const [cc_EM_IL, setcc_EM_IL] = useState("");
  const [selectedccCnNm,setselectedccCnNm] = useState("");
  const [selectedvpEmNo,setselectedvpEmNo] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const openLeadOwnerPopup = () => {
    setLeadOwnerVisible(true);
  };
  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      vp_EM_NO: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAddButtonClick = () => {
    setVisitPlanTableData([{}]);
    setFormData(initialValue);
    setselectedvpEmNo(null);
    setselectedccCnNm(null);
    setselectedabAdNM(null);
    setvp_VI_TY(null);
    setab_CL_11(null);
    setab_CL_12(null);
    setab_CL_13(null);
    setvp_CI_TY(null);
    setvp_ST_AT(null);
    setvp_VI_PR(null);
    setvp_LD_SC(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setselectedvpEmNo(null);
    setselectedccCnNm(null);
    setselectedabAdNM(null);
    setvp_VI_TY(null);
    setab_CL_11(null);
    setab_CL_12(null);
    setab_CL_13(null);
    setvp_CI_TY(null);
    setvp_ST_AT(null);
    setvp_VI_PR(null);
    setvp_LD_SC(null);
    setOpen(false);
    
  };
  const addRow = () => {
    setVisitPlanTableData([...VisitPlanTableData, {}]);
    setselectedabAdNM(null);
    setselectedccCnNm(null);
    setselectedvpEmNo(null);
  };
  const deleteRow = () => {
    if (VisitPlanTableData.length > 0) {
      const updatedData = [...VisitPlanTableData];
      updatedData.pop();
      setVisitPlanTableData(updatedData);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/VisitPlan/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const VisitPlan = response.data;
          const updatedVisitPlan = VisitPlan.visitPlanList.map(target => {
            const updatedplan = { ...target };
            delete updatedplan.vpUkNo;
            return updatedplan;
          });
          setVisitPlanTableData(updatedVisitPlan);
          setFormData(updatedVisitPlan[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };  
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/VisitPlan/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  // const onchangeFormData = (name, value) => {
  //   setFormData({ ...FormData, [name]: value });
  // };
  const onchangeFormData = (name, value,index=null) => {
    setFormData({ ...FormData, [name]: value });
    const fieldName=(FormData && FormData.vpUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
    VisitPlanTableData[index][fieldName]=value;
    if(name=="vp_CT_NO")
      setvp_CT_NO(value);
    if(name=="vp_CN_NO")
      setvp_CN_NO(value);
    if(name=="vp_EM_NO")
      setvp_EM_NO(value);
    if(name=="vp_EM_NO")
      setcc_EM_IL(value);
  };

  const onchangeDropDownFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setvp_VI_TY(value);
  };
  // const onchangeDropDownFormData1 = (name, value) => {
  //   setFormData({ ...FormData, [name]: value });
  //   setab_CL_11(value);
  // };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_12(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setvp_LD_SC(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setvp_CI_TY(value);
  };
  // const onchangeDropDownFormData5 = (name, value) => {
  //   setFormData({ ...FormData, [name]: value });
  //   setvp_ST_AT(value);
  // };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/VisitPlan/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const VisitPlan = response.data;
        setVisitPlanTableData(VisitPlan.visitPlanList);
        setFormData(VisitPlan.visitPlanList[0]);
        setselectedvpEmNo(VisitPlan.visitPlanList.emp_NAME);
        setselectedccCnNm(VisitPlan.visitPlanList.contact_NAME);
        setselectedabAdNM(VisitPlan.visitPlanList.cus_NAME);
        setvp_LD_SC(VisitPlan.visitPlanList.vp_LD_SC);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleDataSelect = (data) => {
    setSelectedowner(data.ab_AD_NM);
    setOwner( data.abAdNo);
    setLeadOwnerVisible(false);
    setShowSearchIcon(false);
    updateFilters (data.abAdNo);
  };

  const handleFormSubmit = () => {
	  FormData.vp_LD_TY = "LD";
        FormData.vp_LD_CO = company;       
    const table = document.getElementById("VisitPlanTable");
    const totalRowCount1 = table.tBodies[0].rows.length;
    const visitPlanList = [];
    for (let i = 0; i < totalRowCount1; i++) {
      const LN_NO = document.getElementsByName("vp_TR_DT_" + i)[0];
      const IT_NM = document.getElementById("vp_EM_NO_" + i);
      const IT_DS = document.getElementById("vp_LD_NO_" + i);
      // const OR_QT = document.getElementById("vp_LD_SC_" + i);
      const TR_UM = document.getElementById("vp_CT_NO_" + i);
      const UT_PR = document.getElementById("vp_CN_NO_" + i);
      const EX_PR = document.getElementById("vp_DE_GN_" + i);
      const PH_NO = document.getElementById("vp_PH_NO_" + i);
      const SH_DT = document.getElementById("email_" + i);
      const PR_DT = table.tBodies[0].rows[i].cells[10].children[0].children[1].children[0].value;
      const FR_PR = document.getElementById("ab_CL_11_" + i);
      const FR_AT = document.getElementById("ab_CL_12_" + i);
      const TX_AP = document.getElementById("ab_CL_13_" + i);
      const TX_PE = table.tBodies[0].rows[i].cells[14].children[0].children[1].children[0].value;
      const Details = {
        vpUkNo: document.getElementById("vpUkNo_" + i).value,
        vp_TR_DT:moment(LN_NO.value, "DD/MM/YYYY").format("YYYYMMDD"),
        vp_EM_NO: IT_NM.value,
        vp_LD_NO: IT_DS.value,
        vp_LD_SC: table.tBodies[0].rows[i].cells[6].children[0].children[1].children[0].value,
        vp_CT_NO: TR_UM.value,
        vp_CN_NO: UT_PR.value,
        vp_DE_GN: EX_PR.value,
        vp_PH_NO: PH_NO.value,
        cc_EM_IL: SH_DT.value,
        vp_VI_PR: PR_DT,
        ab_CL_11: FR_PR.value,
        ab_CL_12: FR_AT.value,
        ab_CL_13: TX_AP.value,
        vp_CI_TY: TX_PE,
      };
      visitPlanList.push(Details);
    }
    const requestBody = {
      visitPlanList,
    };
	
    if (FormData && FormData.vpUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/VisitPlan/Update`;
      if (confirm) {
        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
            })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios
        .post(`${domain}/api/VisitPlan/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
            })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };

  const leftToolbarTemplate = (
    <React.Fragment>
    <Tooltip target=".update-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "red !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Update
      </div>
    </Tooltip>
    <Button
      style={buttonStyles}
      icon="pi pi-check"
      outlined
      className="mr-2 update-button"
      onClick={handleUpdate}
      disabled={!isRecordSelected}
    ></Button>
    {/* <Tooltip target=".search-button" position="bottom" mouseTrack>
      <div
        style={{
          backgroundColor: "transparent !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Search
      </div>
    </Tooltip>
    <Button
      style={searchButtonStyles}
      icon="pi pi-search"
      outlined
      className="mr-2 search-button"
    ></Button> */}
    <Tooltip target=".add-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "white !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Add
      </div>
    </Tooltip>
    <Button
      style={addButtonStyles}
      icon="pi pi-plus"
      outlined
      className="mr-2 add-button"
      onClick={handleAddButtonClick}
    ></Button>
    <Tooltip target=".copy-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "white !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Copy
      </div>
    </Tooltip>
    <Button
      style={copyButtonStyles}
      icon="pi pi-copy"
      outlined
      className="mr-2 copy-button"
      onClick={handleCopyData}
    ></Button>
  </React.Fragment>
  );

  const statusStyle = {
    border: "6px solid #a7d6ff8c",
    color: "white",
    width: "100%",
  };

  const emailStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const contactStyle = {
    // width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  const AdvanceSearch = (
    <div className="formgrid grid">
  <div className="field col">
      <label htmlFor="Owner" className="font-bold">
        Owner <span style={{ color: 'red' }}>*</span>
      </label>
      <div style={{ display: "flex" }}>
        <InputText
        style={{display:"none"}}
          id="Owner"
          name="Owner"
          value={Owner}
          onChange={(Event) => onchangedata(Event)}
        />
        <InputText
          id="ab_AD_NM"
          name="ab_AD_NM"
          readOnly
          style={{backgroundColor:"#ffffff"}}
          onChange={(Event) => onchangedata(Event)}
          onFocus={() => setShowSearchIcon(true)}
          value={selectedowner}
        />
         {showSearchIcon && (
          <i
            className="pi pi-search"
            style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
            }}
            onClick={openLeadOwnerPopup}
          ></i>
         )}
    </div>
    </div>
</div>
);
const handleClearButton=()=>{
  setSelectedowner("");
  setOwner("");
  setFilters(initialFilters);
}
const rightClear = (
  <React.Fragment>
    <Button
      style={{ height: "35px" }}
      icon="pi pi-times"
      className="p-button-help"
      link
      onClick={handleClearButton}
    />
  </React.Fragment>
);
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
       <Toast ref={toast} />
        <div className="card">
        <Toolbar
            className="p-mb-4"
            left={leftToolbarTemplate} 
            right={rightToolbarTemplate}
          />
           <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
        </div>
          <div style={{ overflowX: "auto", maxWidth: "100%", }}>
          <table style={{  borderCollapse: "collapse", width: "100%",marginTop: "20px",}} >
            <thead>
            <tr>
            <td style={{width:"40px"}}>
              <input type="text"  style={checkboxStyle} readOnly onMouseDown={(e) => e.preventDefault()}/>
            </td>
            <td >
                  <input
                    type="text"
                    value={filters["vp_TR_DT"] ? filters["vp_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "vp_TR_DT",
                        e.target.value,
                        filters["vp_TR_DT"]
                          ? filters["vp_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td >
                  <input
                    type="text"
                    value={filters["vp_LD_NO"] ? filters["vp_LD_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "vp_LD_NO",
                        e.target.value,
                        filters["vp_LD_NO"]
                          ? filters["vp_LD_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["vp_EM_NO"] ? filters["vp_EM_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "vp_EM_NO",
                        e.target.value,
                        filters["vp_EM_NO"]
                          ? filters["vp_EM_NO"].operation
                          : "equals"
                      )
                    }
                    style={contactStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["vp_CT_NO"] ? filters["vp_CT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "vp_CT_NO",
                        e.target.value,
                        filters["vp_CT_NO"]
                          ? filters["vp_CT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["vp_CN_NO"] ? filters["vp_CN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "vp_CN_NO",
                        e.target.value,
                        filters["vp_CN_NO"]
                          ? filters["vp_CN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["vp_DE_GN"] ? filters["vp_DE_GN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "vp_DE_GN",
                        e.target.value,
                        filters["vp_DE_GN"]
                          ? filters["vp_DE_GN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["vp_PH_NO"] ? filters["vp_PH_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "vp_PH_NO",
                        e.target.value,
                        filters["vp_PH_NO"]
                          ? filters["vp_PH_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input 
                    type="text"
                    value={filters["email"] ? filters["email"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "email",
                        e.target.value,
                        filters["email"]
                          ? filters["email"].operation
                          : "equals"
                      )
                    }
                    style={emailStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["vp_VI_TY"] ? filters["vp_VI_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "vp_VI_TY",
                        e.target.value,
                        filters["vp_VI_TY"]
                          ? filters["vp_VI_TY"].operation
                          : "equals"
                      )
                    }
                    style={statusStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ab_CL_11"] ? filters["ab_CL_11"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ab_CL_11",
                        e.target.value,
                        filters["ab_CL_11"]
                          ? filters["ab_CL_11"].operation
                          : "equals"
                      )
                    }
                    style={statusStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ab_CL_12"] ? filters["ab_CL_12"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ab_CL_12",
                        e.target.value,
                        filters["ab_CL_12"]
                          ? filters["ab_CL_12"].operation
                          : "equals"
                      )
                    }
                    style={statusStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ab_CL_13"] ? filters["ab_CL_13"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ab_CL_13",
                        e.target.value,
                        filters["ab_CL_13"]
                          ? filters["ab_CL_13"].operation
                          : "equals"
                      )
                    }
                    style={statusStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["vp_CI_TY"] ? filters["vp_CI_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "vp_CI_TY",
                        e.target.value,
                        filters["vp_CI_TY"]
                          ? filters["vp_CI_TY"].operation
                          : "equals"
                      )
                    }
                    style={statusStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Date</th>
                <th style={tableHeaderStyle}>Lead ID</th>
                <th style={tableHeaderStyle}>Lead Owner</th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyle}>Contact</th>
                <th style={tableHeaderStyle}>Title</th>
                <th style={tableHeaderStyle}>Phone</th>
                <th style={tableHeaderStyle}>Email</th>
                <th style={tableHeaderStyle}>Purpose</th>
                <th style={tableHeaderStyle}>Industry</th>
                <th style={tableHeaderStyle}>Vertical</th>
                <th style={tableHeaderStyle}>Area</th>
                <th style={tableHeaderStyle}>City</th>
              </tr>
              
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr key={rowData.vpUkNo}  style={{ backgroundColor: index % 2 === 0 ? 'aliceblue' : '#ffffff' }} className="table-row">
                    <td style={tableCellStyle}>
                      <input style={{width:"10% !important"}}
                        type="checkbox"
                        value={rowData.vpUkNo}
                        checked={selectedRows.includes(rowData.vpUkNo)}
                        onChange={() => handleRowSelect(rowData.vpUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{formatDate(rowData.vp_TR_DT)}</td>
                    <td style={tableCellStyle}>{rowData.vp_LD_NO}</td>        
                    {/* <td style={tableCellStyle}>{rowData.vp_LD_CO}</td> */}
                    <td style={tableCellStyle}>{rowData.emp_NAME}</td>
                    <td style={tableCellStyle}>{rowData.cus_NAME}</td>
                    <td style={tableCellStyle}>{rowData.contact_NAME}</td>
                    <td style={tableCellStyle}>{rowData.vp_DE_GN}</td>
                    <td style={tableCellStyle}>{rowData.vp_PH_NO}</td>
                    {/* <td style={tableCellStyle}>{rowData.vp_CO_PH}</td> */}
                    <td style={tableCellStyle}>{rowData.email}</td>
                    <td style={tableCellStyle}>{rowData.purpose}</td>
                    <td style={tableCellStyle}>{rowData.industry}</td>
                    <td style={tableCellStyle}>{rowData.vertical}</td>
                    <td style={tableCellStyle}>{rowData.area}</td>
                    <td style={tableCellStyle}>{rowData.city}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
        </div>
      {/* Lead Owner */}
      <AddressBookPopup
        visible={LeadOwnerPopupVisible}
        onClose={() => setLeadOwnerVisible(false)}
        onDataSelect={handleDataSelect}
        formData={FormData}
        addressType={addressTypeLDOW}
        setFormData={setFormData}
      />
      <VisitPlanDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeDropDownFormData={onchangeDropDownFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        vp_VI_TY={vp_VI_TY}
        ab_CL_11={ab_CL_11}
        ab_CL_12={ab_CL_12}
        ab_CL_13={ab_CL_13}
        vp_CI_TY={vp_CI_TY}
        vp_ST_AT={vp_ST_AT}
        vp_CT_NO={vp_CT_NO}
        vp_CN_NO={vp_CN_NO}
        vp_EM_NO={vp_EM_NO}
        vp_VI_PR={vp_VI_PR}
        cc_EM_IL={cc_EM_IL}
        vp_LD_SC={vp_LD_SC}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        addRow={addRow}
        deleteRow={deleteRow}
        VisitPlanTableData={VisitPlanTableData}
        onchangeFormData={onchangeFormData}
        // SelectedemEMNO={SelectedemEMNO}
        // setSelectedemEMNO={setSelectedemEMNO}
        selectedabAdNM={selectedabAdNM}
        // cus_NAME={cus_NAME}
        // setcus_NAME={setcus_NAME}
        setselectedabAdNM={setselectedabAdNM}
        selectedccCnNm={selectedccCnNm}
        setselectedccCnNm={setselectedccCnNm}
        selectedvpEmNo={selectedvpEmNo}
        setselectedvpEmNo={setselectedvpEmNo}        
      />
    </div>
  );
};

export default VisitPlanMain;
