import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function CompetencyDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  cd_LV_EL
}) {
  const { 
    cd_CO_DS,
    cd_CO_NO,
    cd_CO_CD,
    cd_CO_TY,
    cdUkNo,
    cd_CO_SC,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [cd_CO_TYOptions, setcd_CO_TYOptions] = useState([]);
  const [cd_CO_SCOptions, setcd_CO_SCOptions] = useState([]);
  const [cd_LV_ELOptions, setcd_LV_ELOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const url = `${domain}/api/ClassCode/All`;

  const parameter1 = "CM/TY";
  const finalUrl1 = `${url}/${parameter1}`;

  const parameter2 = "CO/SC";
  const finalUrl2 = `${url}/${parameter2}`;

  const parameter3 = "CO/LV";
  const finalUrl3 = `${url}/${parameter3}`;

    useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
  }, []);
  // 1st fetchdropdown
  const fetchDropdownOptions1 = async () => {
  try {
    const response = await axios.get(finalUrl1, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcd_CO_TYOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
  };
  // 2nd fetchdropdown
  const fetchDropdownOptions2 = async () => {
  try {
    const response = await axios.get(finalUrl2, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcd_CO_SCOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
  };
  // 3rd fetchdropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setcd_LV_ELOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const isUpdateMode = Boolean(cdUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{cdUkNo ? "Revise Competency Details" : "Add Competency Details"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
  <div style={borderbox} >
      <div style={inboxstyle} >
        <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cd_CO_CD" className="font-bold">
            Competency Code
            </label>
            <InputText
              id="cd_CO_CD"
              name="cd_CO_CD"
              value={cd_CO_CD}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cd_CO_DS" className="font-bold">
            Description
            </label>
            <InputText
              id="cd_CO_DS"
              name="cd_CO_DS"
              value={cd_CO_DS}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cd_CO_TY" className="font-bold">
            Competency Type
            </label>
            <Dropdown
                name="cd_CO_TY"
                value={cd_CO_TY}
                options={cd_CO_TYOptions}
                onChange={(e) => onchangeDropDownFormData1("cd_CO_TY", e.value)}
                placeholder="Select a Compentency Type"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cd_LV_EL" className="font-bold">
            Competency Level
            </label>
            <Dropdown
            id="cd_LV_EL"
                name="cd_LV_EL"
                value={cd_LV_EL}
                options={cd_LV_ELOptions}
                onChange={(e) => onchangeDropDownFormData3("cd_LV_EL", e.value)}
                placeholder="Select Compentency Level"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cd_CO_SC" className="font-bold">
            Scale
            </label>
            <Dropdown
                name="cd_CO_SC"
                value={cd_CO_SC}
                options={cd_CO_SCOptions}
                onChange={(e) => onchangeDropDownFormData2("cd_CO_SC", e.value)}
                placeholder="Select a Scale"
            />
          </div>  
        </div> 
      </div>           
  </div>  
    </Dialog>
  );
}
