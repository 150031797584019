import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import RentalItemDialog from "./RentalItemDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  riUkNo:"",
  ri_IT_NO:"",
  item_DES:"",
  ri_CO_CT:"",
  ri_LO_CN:"",
  locationDES:"",
  ri_SR_NO:"",
  ri_ST_DT:"",
  ri_EN_DT:"",
  ri_BL_CY:"",
  billingfreq:"",
  ri_IT_ST:"",
  itemStatus_DES:"",
  ri_DP_TY:"",
  disposableType_DES:"",
  ri_DP_DT:moment(moment().toDate()).format("YYYYMMDD"),
  ri_DP_RE:"",
};

const initialFilters = {
  ri_IT_NO: { value: "", operation: "" },
  item_DES: { value: "", operation: "" },
  ri_CO_CT: { value: "", operation: "" },
  ri_LO_CN: { value: "", operation: "" },
  locationDES: { value: "", operation: "" },
  ri_SR_NO: { value: "", operation: "" },
  ri_ST_DT: { value: "", operation: "" },
  ri_EN_DT: { value: "", operation: "" },
  ri_BL_CY: { value: "", operation: "" },
  billingfreq: { value: "", operation: "" },
  ri_IT_ST: { value: "", operation: "" },
  itemStatus_DES: { value: "", operation: "" },
  ri_DP_TY: { value: "", operation: "" },
  disposableType_DES: { value: "", operation: "" },
  ri_DP_DT: { value: "", operation: "" },
  ri_DP_RE: { value: "", operation: "" },
};

const RentalItemMain = () => {
    // buttonstyles
    const {formatDate, buttonStyles, addButtonStyles,
      copyButtonStyles,
      checkboxStyle,tableHeaderStyle,
      inputStyle,tableCellStyle,inputStyleH,tableCellStyleH,tableHeaderStyleH,inputStyle1
     } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
// drodpdowns
  const [ri_LO_CN, setri_LO_CN ] = useState("");
  const [ri_CO_CT, setri_CO_CT ] = useState("");
  const [ri_BL_CY, setri_BL_CY ] = useState("");
  const [ri_IT_ST, setri_IT_ST ] = useState("");
  const [ri_DP_TY, setri_DP_TY ] = useState("");

  const CostCentreDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setri_CO_CT(value);
  };

  const ItemStatusDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setri_IT_ST(value);
  };

  // AdvanceSearch
  const [SelectedItem, setselectedItem ] = useState("");
  const [SelectedDesc, setselectedDesc ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setselectedItem(null);
    setselectedDesc(null);
    // setri_CO_CT(null);
    setri_LO_CN(null);
    setri_BL_CY(null);
    // setri_IT_ST(null);
    setri_DP_TY(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setselectedItem(null);
    setselectedDesc(null);
    // setri_CO_CT(null);
    setri_LO_CN(null);
    setri_BL_CY(null);
    // setri_IT_ST(null);
    setri_DP_TY(null);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/RentalItem/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const RentalItemData = response.data;
          delete RentalItemData.riUkNo;
          setFormData(RentalItemData);
          setselectedItem(response.data.ri_IT_NO);
          setselectedDesc(response.data.item_DES);
          setri_LO_CN(response.data.ri_LO_CN);
          setri_CO_CT(response.data.ri_CO_CT);
          setri_BL_CY(response.data.ri_BL_CY);
          setri_IT_ST(response.data.ri_IT_ST);
          setri_DP_TY(response.data.ri_DP_TY);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [ri_CO_NO , setri_CO_NO] = useState("");
 
    const handleDefault = async () => {
      setri_CO_NO(`${company}`)
      const linkPath = String(window.location);
      const l_array = linkPath.split("=");
      const LinkId = l_array[1];
      
      const requestBody = {
        "version_ID" : LinkId
      };
      const paginationUrl = `${domain}/api/LinkId/Select`;
      try {
        const response = await axios.post(paginationUrl, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataDefault = response.data;
        const listDefault = [];
        var count = 0;
        if (dataDefault.versionIds != null) {
          for (let i = 0; i < dataDefault.versionIds.length; i++) {
            if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
              const fName = dataDefault.versionIds[i].field_ID;
              if(fName === "RI_CO_CT"){
                CostCentreDropDown("ri_CO_CT",dataDefault.versionIds[i].default_VALUE);
                setri_CO_CT(dataDefault.versionIds[i].default_VALUE);
              } 
              if(fName === "RI_IT_ST"){
                ItemStatusDropdown("ri_IT_ST",dataDefault.versionIds[i].default_VALUE);
                setri_IT_ST(dataDefault.versionIds[i].default_VALUE);
              } 
           
            }
            if(dataDefault.versionIds[i].field_TYPE === "LIST"){
              count++;
              const def = {
                "column" : dataDefault.versionIds[i].field_ID ,
                "value" : dataDefault.versionIds[i].default_VALUE,
                "operation" : "EQUAL"
              }
              listDefault.push(def);
            }
          }
          handleSearch(listDefault,count);
        } else {
          handleSearch(listDefault,count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };


  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/RentalItem/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };
 
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/RentalItem/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const RentalItemData = response.data;
        setFormData(RentalItemData);
        setri_LO_CN(response.data.ri_LO_CN);
        setri_CO_CT(response.data.ri_CO_CT);
        setri_BL_CY(response.data.ri_BL_CY);
        setri_IT_ST(response.data.ri_IT_ST);
        setri_DP_TY(response.data.ri_DP_TY);
        setselectedItem(response.data.ri_IT_NO);
        setselectedDesc(response.data.item_DES);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {
    if (FormData.riUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/RentalItem/Update`;
      if (confirm) {
        axios
          .put(update, FormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.ri_CO_NO = company;
      axios
        .post(`${domain}/api/RentalItem/Add`, FormData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ri_IT_NO"] ? filters["ri_IT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_IT_NO",
                        e.target.value,
                        filters["ri_IT_NO"]
                          ? filters["ri_IT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["item_DES"] ? filters["item_DES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "item_DES",
                        e.target.value,
                        filters["item_DES"] ? filters["item_DES"].operation : "equals"
                      )
                    }
                    style={inputStyle1}
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ri_CO_CT"] ? filters["ri_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_CO_CT",
                        e.target.value,
                        filters["ri_CO_CT"]
                          ? filters["ri_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["ri_LO_CN"] ? filters["ri_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_LO_CN",
                        e.target.value,
                        filters["ri_LO_CN"]
                          ? filters["ri_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["locationDES"] ? filters["locationDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "locationDES",
                        e.target.value,
                        filters["locationDES"]
                          ? filters["locationDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ri_SR_NO"] ? filters["ri_SR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_SR_NO",
                        e.target.value,
                        filters["ri_SR_NO"]
                          ? filters["ri_SR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ri_ST_DT"] ? filters["ri_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_ST_DT",
                        e.target.value,
                        filters["ri_ST_DT"]
                          ? filters["ri_ST_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ri_EN_DT"] ? filters["ri_EN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_EN_DT",
                        e.target.value,
                        filters["ri_EN_DT"] ? filters["ri_EN_DT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["ri_BL_CY"] ? filters["ri_BL_CY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_BL_CY",
                        e.target.value,
                        filters["ri_BL_CY"] ? filters["ri_BL_CY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["billingfreq"] ? filters["billingfreq"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "billingfreq",
                        e.target.value,
                        filters["billingfreq"] ? filters["billingfreq"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["ri_IT_ST"] ? filters["ri_IT_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_IT_ST",
                        e.target.value,
                        filters["ri_IT_ST"] ? filters["ri_IT_ST"].operation : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["itemStatus_DES"] ? filters["itemStatus_DES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "itemStatus_DES",
                        e.target.value,
                        filters["itemStatus_DES"] ? filters["itemStatus_DES"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["ri_DP_TY"] ? filters["ri_DP_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_DP_TY",
                        e.target.value,
                        filters["ri_DP_TY"] ? filters["ri_DP_TY"].operation : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["disposableType_DES"] ? filters["disposableType_DES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "disposableType_DES",
                        e.target.value,
                        filters["disposableType_DES"] ? filters["disposableType_DES"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ri_DP_DT"] ? filters["ri_DP_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_DP_DT",
                        e.target.value,
                        filters["ri_DP_DT"] ? filters["ri_DP_DT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ri_DP_RE"] ? filters["ri_DP_RE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_DP_RE",
                        e.target.value,
                        filters["ri_DP_RE"] ? filters["ri_DP_RE"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
               
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Item No</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyleH}>Location</th>
                <th style={tableHeaderStyle}>Location</th>
                <th style={tableHeaderStyle}>Serial No</th>
                <th style={tableHeaderStyle}>Rental Start Date</th>
                <th style={tableHeaderStyle}>Rental End Date</th>
                <th style={tableHeaderStyleH}>Billing Cycle</th>
                <th style={tableHeaderStyle}>Billing Cycle</th>
                <th style={tableHeaderStyleH}>Item Status</th>
                <th style={tableHeaderStyle}>Item Status</th>
                <th style={tableHeaderStyleH}>Disposition Type</th>
                <th style={tableHeaderStyle}>Disposition Type</th>
                <th style={tableHeaderStyle}>Disposition Date</th>
                <th style={tableHeaderStyle}>Disposition Reason</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.riUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.riUkNo}
                        checked={selectedRows.includes(rowData.riUkNo)}
                        onChange={() => handleRowSelect(rowData.riUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ri_IT_NO}</td>
                    <td style={tableCellStyle}>{rowData.item_DES}</td>
                    <td style={tableCellStyle}>{rowData.ri_CO_CT}</td>
                    <td style={tableCellStyleH}>{rowData.ri_LO_CN}</td>
                    <td style={tableCellStyle}>{rowData.locationDES}</td>
                    <td style={tableCellStyle}>{rowData.ri_SR_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.ri_ST_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.ri_EN_DT)}</td> 
                    <td style={tableCellStyleH}>{rowData.ri_BL_CY}</td>
                    <td style={tableCellStyle}>{rowData.billingfreq}</td>
                    <td style={tableCellStyleH}>{rowData.ri_IT_ST}</td>   
                    <td style={tableCellStyle}>{rowData.itemStatus_DES}</td>     
                    <td style={tableCellStyleH}>{rowData.ri_DP_TY}</td> 
                    <td style={tableCellStyle}>{rowData.disposableType_DES}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.ri_DP_DT)}</td>  
                    <td style={tableCellStyle}>{rowData.ri_DP_RE}</td>           
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <RentalItemDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        ri_CO_NO={ri_CO_NO}
        ri_LO_CN={ri_LO_CN}
        setri_LO_CN={setri_LO_CN}
        ri_CO_CT={ri_CO_CT}
        setri_CO_CT={setri_CO_CT}
        ri_BL_CY={ri_BL_CY}
        setri_BL_CY={setri_BL_CY}
        ri_IT_ST={ri_IT_ST}
        setri_IT_ST={setri_IT_ST}
        ri_DP_TY={ri_DP_TY}
        setri_DP_TY={setri_DP_TY}
        SelectedItem={SelectedItem}
        setselectedItem={setselectedItem}
        SelectedDesc={SelectedDesc}
        setselectedDesc={setselectedDesc}
        CostCentreDropDown={CostCentreDropDown}
        ItemStatusDropdown={ItemStatusDropdown}
      />
    </div>
  );
};

export default RentalItemMain;
