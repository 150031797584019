import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ReceiptDetailsDialog from "./ReceiptDetailsDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  rh_UK_NO:"",
  rd_UK_NO:"",
  rh_RC_NO: "",
  rh_RD_TY: "",
  rh_DO_CO: "",
  rh_CU_NO:"",
  name:"",
  rh_RC_DT:moment(moment().toDate()).format("YYYYMMDD"),
  rh_GL_DT:moment(moment().toDate()).format("YYYYMMDD"),
  rh_GL_PO:"",
  rh_RC_AM: "",
  rh_OP_AM: "",
  rh_CR_CO: "",
  rh_CR_RT:"",
  rh_RC_FR:"",
  rh_OP_FR:"",
  rh_BA_NO:"",
  rh_BA_TY:"",
  rd_Di_FR:"",
 
};

const initialFilters = {
  rh_RC_NO: { value: "", operation: "" },
  rh_RD_TY: { value: "", operation: "" },
  rh_DO_CO: { value: "", operation: "" },
  rh_CU_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },
  rh_RC_DT: { value: "", operation: "" },
  rh_GL_DT: { value: "", operation: "" },
  rh_GL_PO: { value: "", operation: "" },
  rh_RC_AM: { value: "", operation: "" },
  rh_OP_AM: { value: "", operation: "" },
  rh_CR_CO: { value: "", operation: "" },
  rh_CR_RT: { value: "", operation: "" },
  rh_RC_FR: { value: "", operation: "" },
  rh_OP_FR: { value: "", operation: "" },
  rh_BA_NO: { value: "", operation: "" },
  rh_BA_TY: { value: "", operation: "" },
};

const  ReceiptDetailsMain = () => {
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,rightalined,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [rh_RC_NO, setrh_RC_NO] = useState("");
  const [rh_CR_RT, setrh_CR_RT] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedemEMNOA, setSelectedemEMNOA] = useState("");
  const [rh_CR_CO, setrh_CR_CO] = useState("");
  const [rd_WO_CO, setrd_WO_CO] = useState("");
  const [rh_GL_BA, setrh_GL_BA] = useState("");
  const [auto_POST, setauto_POST] = useState("");
  const [ReceiptDetailstable, setReceiptDetailstable] = useState([{}]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedemEMNO("");
    setrh_CR_CO(null);
    setrd_WO_CO(null);
    setrh_GL_BA(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setSelectedemEMNO("");
    setrh_CR_CO(null);
    setrd_WO_CO(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      // handleSearch();
      handleDefault();
    }
  };
  const addRow = () => {
    setReceiptDetailstable([...ReceiptDetailstable, {}]);
  };

  const deleteRow = () => {
    if (ReceiptDetailstable.length > 0) {
      const updatedData = [...ReceiptDetailstable];
      updatedData.pop();
      setReceiptDetailstable(updatedData);
    }
  };

  const AutoPostDropdown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setauto_POST(value);
  };
 

  const [rh_DO_CO , setrh_DO_CO] = useState("");
  const [rh_RD_TY , setrh_RD_TY] = useState("");
  const [rh_CR_CD , setrh_CR_CD] = useState("");
    const handleDefault = async () => {
      const linkPath = String(window.location);
      const l_array = linkPath.split("=");
      const LinkId = l_array[1];
      
      const requestBody = {
        "version_ID" : LinkId
      };
      const paginationUrl = `${domain}/api/LinkId/Select`;
      try {
        const response = await axios.post(paginationUrl, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataDefault = response.data;
        const listDefault = [];
        var count = 0;
        if (dataDefault.versionIds != null) {
          for (let i = 0; i < dataDefault.versionIds.length; i++) {
            if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
              const fName = dataDefault.versionIds[i].field_ID;
              setrh_DO_CO(`${company}`)
              if(fName === "RH_RD_TY"){
                setrh_RD_TY(dataDefault.versionIds[i].default_VALUE)
              }
              if(fName === "RH_CR_CD"){
                setrh_CR_CD(dataDefault.versionIds[i].default_VALUE)
              }
              if(fName === "AUTO_POST"){
                AutoPostDropdown("auto_POST",dataDefault.versionIds[i].default_VALUE);
                setauto_POST(dataDefault.versionIds[i].default_VALUE);
              } 
  
            }
            if(dataDefault.versionIds[i].field_TYPE === "LIST"){
              count++;
              const def = {
                "column" : dataDefault.versionIds[i].field_ID ,
                "value" : dataDefault.versionIds[i].default_VALUE,
                "operation" : "EQUAL"
              }
              listDefault.push(def);
            }
          }
          handleSearch(listDefault,count);
        } else {
          handleSearch(listDefault,count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  useEffect(() => {
    // handleSearch();
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ReceiptsEntry/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
    if(name=="rh_CR_CO")
    setrh_CR_CO(value);
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeReceivedate = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
    onChnageExchangeRate (rh_CR_CO,value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrd_WO_CO(value);
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrh_CR_CO(value);
    onChnageExchangeRate (value, FormData.rh_RC_DT);
  };


  function onChnageExchangeRate(frCr, ReceiveDate) {
    const exData = {
      "cr_TO_CR":  document.getElementById("rh_CR_CD").value, 
      "cr_FR_CR": frCr,
      "cr_EF_DT": ReceiveDate
    }
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("rh_CR_RT").value = dataExRt.cr_CO_MR;
          setrh_CR_RT(dataExRt.cr_CO_MR);
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });

  }
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ReceiptsEntry/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ReceiptDetailsData = response.data;
        setReceiptDetailstable(ReceiptDetailsData.receiptDetailsList);
        setFormData(ReceiptDetailsData.receiptsEntry);
        setSelectedemEMNO(ReceiptDetailsData.receiptsEntry.am_AC_DS); 
        setSelectedemEMNOA(ReceiptDetailsData.receiptsEntry.accountDes); 
        setrh_CR_CO(ReceiptDetailsData.receiptsEntry.rh_CR_CO);
        setrd_WO_CO(ReceiptDetailsData.receiptDetailsList.rd_WO_CO);
        handleClickOpen();
        
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handlepostGAGL = async () => {
    const requestbody ={
      "receiptsEntry":{
        "rh_UK_NO": FormData.rh_UK_NO
      }
      
    }
    try {
      const response = await axios.post(
        `${domain}/api/ReceiptsEntry/PostReceiptsToGAGL`, requestbody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      const data = response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlepostGABL = async () => {
    const requestbody ={
      "receiptsEntry":{
        "rh_UK_NO": FormData.rh_UK_NO
      }
      
    }
    
    try {
      const response = await axios.post(
        `${domain}/api/ReceiptsEntry/PostReceiptsToGABL`, requestbody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      const data = response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const executeGAGLandGABL = async () => {
    try {
      await Promise.all([
        handlepostGAGL(),
        handlepostGABL()
      ]);
      console.log("Both GAGL and GABL functions executed successfully.");
    } catch (error) {
      console.error("Error executing GAGL and GABL functions:", error);
    }
  };
  
  var requestBodyInput = {};
  function values () {
      const receiptsEntry = {
        rh_UK_NO: document.getElementById("rh_UK_NO").value,
        rh_RC_NO: document.getElementById("rh_RC_NO").value,
        rh_RD_TY: document.getElementById("rh_RD_TY").value,
        rh_DO_CO: document.getElementById("rh_DO_CO").value,
        rh_RC_DT: FormData.rh_RC_DT,
        rh_CU_NO: document.getElementById("rh_CU_NO").value,
            name: document.getElementById("name").value,
        rh_RC_AM: document.getElementById("rh_RC_AM").value,
        rh_RC_FR: document.getElementById("rh_RC_FR").value,
        rh_GL_DT: FormData.rh_GL_DT,
        rh_CR_CO: rh_CR_CO,
        rh_CR_CD: document.getElementById("rh_CR_CD").value,
        rh_CQ_NO: document.getElementById("rh_CQ_NO").value,
        rh_CR_RT: document.getElementById("rh_CR_RT").value,
        rh_AC_BL: document.getElementById("rh_AC_BL").value,
        rh_GL_BA: document.getElementById("rh_GL_BA").value,
        rh_RM_EX: document.getElementById("rh_RM_EX").value,
        auto_POST: auto_POST,
      };
      const table = document.getElementById("ReceiptDetailstable");
      const totalRowCount = table.tBodies[0].rows.length;
      const receiptDetailsList = [];
      for (let i = 0; i < totalRowCount; i++) {  
        const Details = {
          rd_UK_NO: document.getElementById("rd_UK_NO_" + i).value,
          rd_PY_ID: document.getElementById("rd_PY_ID_" + i).value,
          rd_PA_AM: document.getElementById("rd_PA_AM_" + i).value,
          rd_DI_AM: document.getElementById("rd_DI_AM_" + i).value,
          rd_WO_AM: document.getElementById("rd_WO_AM_" + i).value,
          rd_WO_CO: table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
          rd_GR_AM: document.getElementById("rd_GR_AM_" + i).value,
          rd_OP_AM: document.getElementById("rd_OP_AM_" + i).value,
          rd_DO_NO: document.getElementById("rd_DO_NO_" + i).value,
          rd_DO_TY: document.getElementById("rd_DO_TY_" + i).value,
          rd_DO_CO: document.getElementById("rd_DO_CO_" + i).value,
          rd_DO_PI: document.getElementById("rd_DO_PI_" + i).value,
          rd_PA_FR: document.getElementById("rd_PA_FR_" + i).value,
          rd_Di_FR: document.getElementById("rd_Di_FR_" + i).value,
          rd_WO_FR: document.getElementById("rd_WO_FR_" + i).value,
        };
        receiptDetailsList.push(Details);
      }
      requestBodyInput = {
        receiptsEntry,
        receiptDetailsList,
      };
    }

    const handleFormSubmit = () => {
      values();
      const orNo = document.getElementById("rh_RC_NO").value;
      const ukNo = document.getElementById("rh_UK_NO").value;
      if (orNo) {
        if (ukNo) {
          const confirm = window.confirm("Do you want to Update ?");
          const update = `${domain}/api/ReceiptsEntry/Update`;
      
          if (confirm) {
            axios.put(update, requestBodyInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error updating data:", error);
              });
          }
        } else {
          axios.post(`${domain}/api/ReceiptsEntry/Add`, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error adding data:", error);
            });
        }
      } else {
          
          const OR_DT = FormData.rh_RC_DT;
          if (OR_DT) {
            const currentYear = OR_DT.toString();
            const firstHalf = currentYear.substring(0, 2);
            const secondHalf = currentYear.substring(2, 4);
            const urlNextNumber = `${domain}/api/DocumentNumber/All`;
            const parameters = `${rh_RD_TY}/${secondHalf}/1000/${firstHalf}`;
            const finalUrl1 = `${urlNextNumber}/${parameters}`;
            fetch(finalUrl1, {
              method: "GET",
              headers: {
                  "Authorization": `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
            })
              .then((resp) => {
                if (!resp.ok) {
                  throw new Error(`Request failed with status: ${resp.status}`);
                }
                return resp.json();
              })
              .then((resp) => {
                setrh_RC_NO(resp.dn_DO_NO);
                document.getElementById("rh_RC_NO").value = resp.dn_DO_NO;
  
                values();
                axios.post(`${domain}/api/ReceiptsEntry/Add`, requestBodyInput, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "http://localhost:3000",
                        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                      },
                    })
                    .then((resp) => {
                      handleResponse(resp.data);
                    })
                    .catch((error) => {
                      console.error("Error saving data:", error);
                    });
  
              })
              .catch((error) => {
                console.error("Error fetching data:", error);
              });
          }
        }
      };


  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
    
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
        <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Post
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 copy-button"
        onClick={executeGAGLandGABL}
        disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );


  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" , backgroundColor: "#1ed01e", border: "none"}}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
     
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_RC_NO"] ? filters["rh_RC_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_RC_NO",
                        e.target.value,
                        filters["rh_RC_NO"]
                          ? filters["rh_RC_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_RD_TY"] ? filters["rh_RD_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_RD_TY",
                        e.target.value,
                        filters["rh_RD_TY"]
                          ? filters["rh_RD_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_DO_CO"] ? filters["rh_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_DO_CO",
                        e.target.value,
                        filters["rh_DO_CO"]
                          ? filters["rh_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_CU_NO"] ? filters["rh_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_CU_NO",
                        e.target.value,
                        filters["rh_CU_NO"]
                          ? filters["rh_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_RC_DT"] ? filters["rh_RC_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_RC_DT",
                        e.target.value,
                        filters["rh_RC_DT"]
                          ? filters["rh_RC_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_GL_DT"] ? filters["rh_GL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_GL_DT",
                        e.target.value,
                        filters["rh_GL_DT"]
                          ? filters["rh_GL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_GL_PO"] ? filters["rh_GL_PO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_GL_PO",
                        e.target.value,
                        filters["rh_GL_PO"]
                          ? filters["rh_GL_PO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_RC_AM"] ? filters["rh_RC_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_RC_AM",
                        e.target.value,
                        filters["rh_RC_AM"]
                          ? filters["rh_RC_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_OP_AM"] ? filters["rh_OP_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_OP_AM",
                        e.target.value,
                        filters["rh_OP_AM"]
                          ? filters["rh_OP_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_CR_CO"] ? filters["rh_CR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_CR_CO",
                        e.target.value,
                        filters["rh_CR_CO"]
                          ? filters["rh_CR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_CR_RT"] ? filters["rh_CR_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_CR_RT",
                        e.target.value,
                        filters["rh_CR_RT"]
                          ? filters["rh_CR_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_RC_FR"] ? filters["rh_RC_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_RC_FR",
                        e.target.value,
                        filters["rh_RC_FR"]
                          ? filters["rh_RC_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_OP_FR"] ? filters["rh_OP_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_OP_FR",
                        e.target.value,
                        filters["rh_OP_FR"]
                          ? filters["rh_OP_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_BA_NO"] ? filters["rh_BA_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_BA_NO",
                        e.target.value,
                        filters["rh_BA_NO"]
                          ? filters["rh_BA_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_BA_TY"] ? filters["rh_BA_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_BA_TY",
                        e.target.value,
                        filters["rh_BA_TY"]
                          ? filters["rh_BA_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Receipt No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Receipt Date</th>
                <th style={tableHeaderStyle}>GL Date</th>
                <th style={tableHeaderStyle}>Post Code</th>
                <th style={tableHeaderStyle}>Amount</th>
                <th style={tableHeaderStyle}>Unapplied Amount</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Exchange Rate</th>
                <th style={tableHeaderStyle}>Fore.Amount</th>
                <th style={tableHeaderStyle}>Fore. Unapplied</th>
                <th style={tableHeaderStyle}>Batch Number</th>
                <th style={tableHeaderStyle}>Batch Type</th>
           
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.rh_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.rd_UK_NO}
                        checked={selectedRows.includes(rowData.rh_UK_NO)}
                        onChange={() => handleRowSelect(rowData.rh_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.rh_RC_NO}</td>
                    <td style={tableCellStyle}>{rowData.rh_RD_TY}</td>
                    <td style={tableCellStyle}>{rowData.rh_DO_CO}</td>
                    <td style={tableCellStyle}>{rowData.rh_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{rowData.rh_RC_DT}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.rh_GL_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.rh_GL_PO}</td>
                    <td style={rightalined}>{rowData.rh_RC_AM}</td>
                    <td style={rightalined}>{rowData.rh_OP_AM}</td>
                    <td style={tableCellStyle}>{rowData.rh_CR_CO}</td>
                    <td style={tableCellStyle}>{rowData.rh_CR_RT}</td>
                    <td style={rightalined}>{rowData.rh_RC_FR}</td>
                    <td style={rightalined}>{rowData.rh_OP_FR}</td>
                    <td style={tableCellStyle}>{rowData.rh_BA_NO}</td>
                    <td style={tableCellStyle}>{rowData.rh_BA_TY}</td>
                    
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

      </div>

      < ReceiptDetailsDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        rh_CR_CO={rh_CR_CO}
        rh_RD_TY={rh_RD_TY}
        rh_CR_CD={rh_CR_CD}
        rh_DO_CO={rh_DO_CO}
        rd_WO_CO={rd_WO_CO}
        setFormData={setFormData}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        ReceiptDetailstable={ReceiptDetailstable} 
        onchangeReceivedate={onchangeReceivedate}
        addRow={addRow} 
        deleteRow={deleteRow} 
        setReceiptDetailstable = {setReceiptDetailstable}
        setrh_CR_CO={setrh_CR_CO}
        handleResponse={handleResponse}
        setSelectedemEMNOA={setSelectedemEMNOA}
        SelectedemEMNOA={SelectedemEMNOA}
        auto_POST={auto_POST}
        setauto_POST={setauto_POST}
        AutoPostDropdown={AutoPostDropdown}
      />
    </div>
  );
};

export default  ReceiptDetailsMain;
