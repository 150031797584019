import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import ClassCode from "views/common/ClassCode";
import { Container, Row, Col } from 'react-bootstrap';
import { Checkbox } from "primereact/checkbox";
import ButtonStyles from "views/common/ButtonStyles";
export default function CashDeskClosingDialog({
  open,
  handleClose,
  data,
  onchangedata,
  setFormData,
  onchangeFormData,
  handleFormSubmit,
}) {
  const {
    cdUkNO, 
    cd_CN_ID,
    cd_TR_CR,
    cd_CO_NO,
    cd_TY_PE,
    cd_PY_TY,
    cd_TR_QT,
    cd_UN_PR,
    cd_TR_AM, 
    bs_MS_CR,
    bs_VI_SA,
    bs_AM_EX,
    bs_PA_PL,
    bs_VO_AM,
    bs_CR_AM,
      // 2nd tab
    usd_MS_CR,
    usd_VI_SA,
    usd_AM_EX,
    usd_PA_PL,
    usd_VO_AM,
    usd_CR_AM,
      //3rdtab
       bs_TG_CS, bs_DF_CS,
      bs_AC_MC, bs_TG_MC, bs_DF_MC,
      bs_AC_VI, bs_TG_VI, bs_DF_VI,
      bs_AC_EX, bs_TG_EX, bs_DF_EX,
      bs_AC_PP, bs_TG_PP, bs_DF_PP,
      bs_AC_VO, bs_TG_VO, bs_DF_VO,
      bs_AC_CR, bs_TG_CR, bs_DF_CR,
      usd_DF_TL, usd_TG_CS, usd_DF_CS,
      usd_AC_MC, usd_TG_MC, usd_DF_MC,
      usd_AC_VI, usd_TG_VI, usd_DF_VI,
      usd_AC_EX, usd_TG_EX, usd_DF_EX,
      usd_AC_PP, usd_TG_PP, usd_DF_PP,
      usd_AC_VO, usd_TG_VO, usd_DF_VO,
      usd_AC_CR, usd_TG_CR, usd_DF_CR,
  } = data;
  // buttonstyles
  const {inboxstyle,savestyle,cancelstyle,borderbox } = ButtonStyles();
  const [activeTab, setActiveTab] = useState(0);
//   1st tab
// base Notes
  const [bs_NT_10, setbs_NT_10] = useState("");
  const [bs_NT_20, setbs_NT_20] = useState("");
  const [bs_NT_50, setbs_NT_50] = useState("");
  const [bs_NT_100, setbs_NT_100] = useState("");
  const [bs_NT_200, setbs_NT_200] = useState("");
  const [bs_NT_500, setbs_NT_500] = useState("");
  const [bs_NT_1000, setbs_NT_1000] = useState("");
  const [totalNotes, settotalNotes] = useState("");
  const [bs_NT_QTY_10, setbs_NT_QTY_10] = useState("");
  const [bs_NT_QTY_20, setbs_NT_QTY_20] = useState("");
  const [bs_NT_QTY_50, setbs_NT_QTY_50] = useState("");
  const [bs_NT_QTY_100, setbs_NT_QTY_100] = useState("");
  const [bs_NT_QTY_200, setbs_NT_QTY_200] = useState("");
  const [bs_NT_QTY_500, setbs_NT_QTY_500] = useState("");
  const [bs_NT_QTY_1000, setbs_NT_QTY_1000] = useState("");
// base Coins
  const [bs_CO_05, setbs_CO_05] = useState("");
  const [bs_CO_10, setbs_CO_10] = useState("");
  const [bs_CO_20, setbs_CO_20] = useState("");
  const [bs_CO_50, setbs_CO_50] = useState("");
  const [bs_CO_1, setbs_CO_1] = useState("");
  const [bs_CO_2, setbs_CO_2] = useState("");
  const [bs_CO_5, setbs_CO_5] = useState("");

  const [bs_CO_QTY_05, setbs_CO_QTY_05] = useState("");
  const [TotalCoins, setTotalCoins] = useState("");
  const [bs_CO_QTY_10, setbs_CO_QTY_10] = useState("");
  const [bs_CO_QTY_20, setbs_CO_QTY_20] = useState("");
  const [bs_CO_QTY_50, setbs_CO_QTY_50] = useState("");
  const [bs_CO_QTY_1, setbs_CO_QTY_1] = useState("");
  const [bs_CO_QTY_2, setbs_CO_QTY_2] = useState("");
  const [bs_CO_QTY_5, setbs_CO_QTY_5] = useState("");
// 2nd tab
// USD Notes
const [usd_NT_10, setusd_NT_10] = useState("");
const [usd_NT_20, setusd_NT_20] = useState("");
const [usd_NT_50, setusd_NT_50] = useState("");
const [usd_NT_100, setusd_NT_100] = useState("");
const [usd_NT_200, setusd_NT_200] = useState("");
const [usd_NT_500, setusd_NT_500] = useState("");
const [usd_NT_1000, setusd_NT_1000] = useState("");
const [usdTotalNotes, setusdTotalNotes] = useState("");
const [usd_NT_QTY_10, setusd_NT_QTY_10] = useState("");
const [usd_NT_QTY_20, setusd_NT_QTY_20] = useState("");
const [usd_NT_QTY_50, setusd_NT_QTY_50] = useState("");
const [usd_NT_QTY_100, setusd_NT_QTY_100] = useState("");
const [usd_NT_QTY_200, setusd_NT_QTY_200] = useState("");
const [usd_NT_QTY_500, setusd_NT_QTY_500] = useState("");
const [usd_NT_QTY_1000, setusd_NT_QTY_1000] = useState("");
// USD cOINS

const [usd_CO_05, setusd_CO_05] = useState("");
const [usd_CO_10, setusd_CO_10] = useState("");
const [usd_CO_20, setusd_CO_20] = useState("");
const [usd_CO_50, setusd_CO_50] = useState("");
const [usd_CO_1, setusd_CO_1] = useState("");
const [usd_CO_2, setusd_CO_2] = useState("");
const [usd_CO_5, setusd_CO_5] = useState("");

const [usd_CO_QTY_05, setusd_CO_QTY_05] = useState("");
const [usdTotalCoins, setusdTotalCoins] = useState("");
const [usd_CO_QTY_10, setusd_CO_QTY_10] = useState("");
const [usd_CO_QTY_20, setusd_CO_QTY_20] = useState("");
const [usd_CO_QTY_50, setusd_CO_QTY_50] = useState("");
const [usd_CO_QTY_1, setusd_CO_QTY_1] = useState("");
const [usd_CO_QTY_2, setusd_CO_QTY_2] = useState("");
const [usd_CO_QTY_5, setusd_CO_QTY_5] = useState("");


//   3rd tab things
 const [bs_AC_CS, setbs_AC_CS] = useState("");
 const [bs_AC_TL, setbs_AC_TL] = useState("");
 const [bs_TG_TL, setbs_TG_TL] = useState("");

 const [usd_AC_CS, setusd_AC_CS] = useState("");
 const [usd_AC_TL, setusd_AC_TL] = useState("");
 const [usd_TG_TL, setusd_TG_TL] = useState("");
const calculateResult = (quantity, multiplier) => quantity * multiplier;

const handleQuantityChange = (e, multiplier, setResult, resultSetter) => {
const quantity = parseFloat(e.target.value) || 0;
const result = calculateResult(quantity, multiplier);
setResult(result);
resultSetter(result);
};
  
useEffect(() => {
      // Calculate and set total notes
      const newTotalNotes = bs_NT_10 + bs_NT_20 + bs_NT_50 + bs_NT_100 + bs_NT_200 + bs_NT_500 + bs_NT_1000;
      settotalNotes(newTotalNotes);
  
      // Calculate and set total coins
      const newTotalCoins = bs_CO_05 + bs_CO_10 + bs_CO_20 + bs_CO_50 + bs_CO_1 + bs_CO_2 + bs_CO_5;
      setTotalCoins(newTotalCoins);

      // 2nd tab
      const newTotalNotesUsd = usd_NT_10 + usd_NT_20 + usd_NT_50 + usd_NT_100 + usd_NT_200 + usd_NT_500 + usd_NT_1000;
      setusdTotalNotes(newTotalNotesUsd);

      const newTotalCoinsUsd = usd_CO_05 + usd_CO_10 + usd_CO_20 + usd_CO_50 + usd_CO_1 + usd_CO_2 + usd_CO_5;
      setusdTotalCoins(newTotalCoinsUsd);

      // Calculate total & usdtotal in 3rd tab
      const totalNotesValue = Number(totalNotes) || 0;
      const totalCoinsValue = Number(TotalCoins) || 0;
      const newCashTotal = totalNotesValue + totalCoinsValue;
      setbs_AC_CS(newCashTotal);

      // Calculate and set Actual Total
      const newTotalSumAct = bs_AC_CS + bs_AC_MC + bs_AC_VI + bs_AC_EX + bs_AC_PP + bs_AC_VO + bs_AC_CR;
      setbs_AC_TL(newTotalSumAct);
       
      // Calculate and set Actual Total
      const newTotalSumTar = bs_TG_CS + bs_TG_MC + bs_TG_VI + bs_TG_EX + bs_TG_PP + bs_TG_VO + bs_TG_CR;
      setbs_TG_TL(newTotalSumTar);

      const totalusdNotesValue = Number(usdTotalNotes) || 0;
      const totalusdCoinsValue = Number(usdTotalCoins) || 0;
      const newusdCashTotal = totalusdNotesValue + totalusdCoinsValue;
      setusd_AC_CS(newusdCashTotal);

      // Calculate and set USD Actual Total
      const newTotalUSDSumAct = bs_AC_CS + bs_AC_MC + bs_AC_VI + bs_AC_EX + bs_AC_PP + bs_AC_VO + bs_AC_CR;
      setusd_AC_TL(newTotalUSDSumAct);
      
      // Calculate and set USD Actual Total
      const newTotalUSDSumTar = bs_TG_CS + bs_TG_MC + bs_TG_VI + bs_TG_EX + bs_TG_PP + bs_TG_VO + bs_TG_CR;
      setusd_TG_TL(newTotalUSDSumTar);

  }, [
      bs_NT_10, bs_NT_20, bs_NT_50, bs_NT_100, bs_NT_200, bs_NT_500, bs_NT_1000,
      bs_CO_05, bs_CO_10, bs_CO_20, bs_CO_50, bs_CO_1, bs_CO_2, bs_CO_5,
      // 2nd tab
      usd_CO_05, usd_CO_10, usd_CO_20, usd_CO_50, usd_CO_1, usd_CO_2, usd_CO_5,
      // 3rd tab
      totalNotes,TotalCoins,
      bs_AC_CS, bs_AC_MC, bs_AC_VI, bs_AC_EX, bs_AC_PP, bs_AC_VO, bs_AC_CR,
      bs_TG_CS, bs_TG_MC, bs_TG_VI, bs_TG_EX, bs_TG_PP, bs_TG_VO, bs_TG_CR,
      // 3rd USD
      usdTotalNotes,usdTotalCoins,
  ]);
  const isUpdateMode = Boolean(cdUkNO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{cdUkNO ? "Revise Cash Desk Closing" : "Add Cash Desk Closing"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
    );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{width:"84rem"}}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="INR">
              <div style={{display:"block"}}>
                  <label htmlFor="cdUkNO" className="font-bold">
                    UK
                  </label>
                  <InputText
                    id="cdUkNO"
                    name="cdUkNO"
                    value={cdUkNO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>  
             <div style={{ display:"flex"}}>
              <div  style={inboxstyle} className="field col-lg-6 col-md-6 col-12 pe-md-2" >
                <div className="formgrid grid"> 
                <div className="field col-12">
                  <h3 className="font-bold">Notes</h3>
                </div>
                <table class="table borderless search-table well" style ={{marginRight:"5rem"}}>
                  {/* 10 */}
                <tr> 
			        <td style={{width:"10%",textAlign:"center"}}>10.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="bs_NT_QTY_10"  name="bs_NT_QTY_10"  defaultValue={bs_NT_QTY_10}   onChange={(e) => handleQuantityChange(e, 10, setbs_NT_QTY_10,setbs_NT_10)} />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="bs_NT_10"  name="bs_NT_10"  value={bs_NT_10}  onChange={(e) => onchangedata(e)} disabled />
                      </td>
                </tr>
                {/* 20 */}
                <tr> 
                  <td style={{width:"10%",textAlign:"center"}}>20.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="bs_NT_QTY_20"  name="bs_NT_QTY_20"  defaultValue={bs_NT_QTY_20}  onChange={(e) => handleQuantityChange(e, 20, setbs_NT_QTY_20, setbs_NT_20)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="bs_NT_20"  name="bs_NT_20"  value={bs_NT_20}  onChange={(e) => onchangedata(e)}  disabled/>
                      </td>
                </tr>
                {/* 50 */}
                <tr> 
			              <td style={{width:"10%",textAlign:"center"}}>50.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="bs_NT_QTY_50"  name="bs_NT_QTY_50"  defaultValue={bs_NT_QTY_50}  onChange={(e) => handleQuantityChange(e, 50, setbs_NT_QTY_50 , setbs_NT_50)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="bs_NT_50"  name="bs_NT_50"  value={bs_NT_50}  onChange={(e) => onchangedata(e)} disabled />
                      </td>
                </tr>
                {/* 100 */}
                <tr> 
			              <td style={{width:"10%",textAlign:"center"}}>100.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="bs_NT_QTY_100"  name="bs_NT_QTY_100"  defaultValue={bs_NT_QTY_100}  onChange={(e) => handleQuantityChange(e, 100, setbs_NT_QTY_100, setbs_NT_100)} />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="bs_NT_100"  name="bs_NT_100"  value={bs_NT_100}  onChange={(e) => onchangedata(e)}  disabled/>
                      </td>
                </tr>
                {/* 200  */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>200.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="bs_NT_QTY_200"  name="bs_NT_QTY_200"  defaultValue={bs_NT_QTY_200}  onChange={(e) => handleQuantityChange(e, 200, setbs_NT_QTY_200, setbs_NT_200)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="bs_NT_200"  name="bs_NT_200"  value={bs_NT_200}  onChange={(e) => onchangedata(e)}  disabled/>
                      </td>
                </tr>
                {/* 500 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>500.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="bs_NT_QTY_500"  name="bs_NT_QTY_500"  defaultValue={bs_NT_QTY_500}  onChange={(e) => handleQuantityChange(e, 500, setbs_NT_QTY_500, setbs_NT_500)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="bs_NT_500"  name="bs_NT_500"  value={bs_NT_500}  onChange={(e) => onchangedata(e)} disabled />
                      </td>
                </tr>
                {/* 1000 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>1000.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="bs_NT_QTY_1000"  name="bs_NT_QTY_1000"  defaultValue={bs_NT_QTY_1000}  onChange={(e) => handleQuantityChange(e, 1000, setbs_NT_QTY_1000, setbs_NT_1000)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="bs_NT_1000"  name="bs_NT_1000"  value={bs_NT_1000}  onChange={(e) => onchangedata(e)}  disabled />
                      </td>
                </tr>
                <tr>
                  <td> </td> 
                  <td> </td>
                  <td> </td>
                  <td style={{width:"10%"}}>
                        <InputText  id="totalNotes"  name="totalNotes"  value={totalNotes}  onChange={(e) => onchangedata(e)} disabled />
                 </td>
                </tr>
                </table>   
                </div>
              </div>
              <div  style={inboxstyle} className="field col-lg-6 col-md-6 col-12 pe-md-2" >
                <div className="formgrid grid"> 
                <div className="field col-12">
                  <h3 className="font-bold">Coins</h3>
                </div>
                <table class="table borderless search-table well" style ={{marginRight:"5rem"}}>
                  {/* 10 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>0.05 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="bs_CO_QTY_05"  name="bs_CO_QTY_05"  defaultValue={bs_CO_QTY_05}  onChange={(e) => handleQuantityChange(e, 0.05, setbs_CO_QTY_05 , setbs_CO_05)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="bs_CO_05"  name="bs_CO_05"  value={bs_CO_05}  disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 20 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>0.10 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="bs_CO_QTY_10"  name="bs_CO_QTY_10"  defaultValue={bs_CO_QTY_10}  onChange={(e) => handleQuantityChange(e, 0.10, setbs_CO_QTY_10 , setbs_CO_10)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="bs_CO_10"  name="bs_CO_10"  value={bs_CO_10} disabled  onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 50 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>0.20 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="bs_CO_QTY_20"  name="bs_CO_QTY_20"  defaultValue={bs_CO_QTY_20}  onChange={(e) => handleQuantityChange(e, 0.20, setbs_CO_QTY_20 , setbs_CO_20)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="bs_CO_20"  name="bs_CO_20"  value={bs_CO_20} disabled  onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 100 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>0.50 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="bs_CO_QTY_50"  name="bs_CO_QTY_50"  defaultValue={bs_CO_QTY_50}  onChange={(e) => handleQuantityChange(e, 0.50, setbs_CO_QTY_50 , setbs_CO_50)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="bs_CO_50"  name="bs_CO_50"  value={bs_CO_50}  disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 200  */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>1.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="bs_CO_QTY_1"  name="bs_CO_QTY_1"  defaultValue={bs_CO_QTY_1} onChange={(e) => handleQuantityChange(e, 1.00, setbs_CO_QTY_1 , setbs_CO_1)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="bs_CO_1"  name="bs_CO_1"  value={bs_CO_1} disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 500 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>2.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="bs_CO_QTY_2"  name="bs_CO_QTY_2"  defaultValue={bs_CO_QTY_2}  onChange={(e) => handleQuantityChange(e, 2.00, setbs_CO_QTY_2 , setbs_CO_2)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="bs_CO_2"  name="bs_CO_2"  value={bs_CO_2} disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 1000 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>5.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="bs_CO_QTY_5"  name="bs_CO_QTY_5"  defaultValue={bs_CO_QTY_5}  onChange={(e) => handleQuantityChange(e, 5.00, setbs_CO_QTY_5 , setbs_CO_5)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="bs_CO_5"  name="bs_CO_5"  value={bs_CO_5} disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                <tr>
                  <td> </td> 
                  <td> </td>
                  <td> </td>
                  <td style={{width:"10%"}}>
                        <InputText  id="TotalCoins"  name="TotalCoins"  value={TotalCoins} disabled  onChange={(e) => onchangedata(e)}  />
                 </td>
                </tr>
                </table>   
                </div>
              </div>
             </div>
              <div style={{ display:"flex"}}>
                  <div  style={inboxstyle}  className="field col-lg-5 col-md-6 col-12 pe-md-2" >
                    <div className="formgrid grid"> 
                    <div className="field col-12">
                      <h3 className="font-bold">Card Payment</h3>
                    </div>
                    <table class="table borderless search-table well" style ={{marginRight:"5rem"}}>
                      {/* 10 */}
                    <tr> 
                        <td style={{width:"10%",textAlign:"center"}}>Master Card</td>
                          <td style={{width:"10%"}}> 
                                <InputText  id="bs_MS_CR"  name="bs_MS_CR"  value={bs_MS_CR}  onChange={(e) => onchangedata(e)}  />
                          </td>
                    </tr> 
                    <tr> 
                        <td style={{width:"10%",textAlign:"center"}}>VISA</td>
                          <td style={{width:"10%"}}> 
                                <InputText  id="bs_VI_SA"  name="bs_VI_SA"  value={bs_VI_SA}  onChange={(e) => onchangedata(e)}  />
                          </td>
                    </tr> 
                    <tr> 
                        <td style={{width:"10%",textAlign:"center"}}>American Express</td>
                          <td style={{width:"10%"}}> 
                                <InputText  id="bs_AM_EX"  name="bs_AM_EX"  value={bs_AM_EX}  onChange={(e) => onchangedata(e)}  />
                          </td>
                    </tr> 
                    <tr> 
          <td style={{width:"10%",textAlign:"center"}}>PayPal</td>
                          <td style={{width:"10%"}}> 
                                <InputText  id="bs_PA_PL"  name="bs_PA_PL"  value={bs_PA_PL}  onChange={(e) => onchangedata(e)}  />
                          </td>
                    </tr> 
                    </table>
                    </div>
                  </div>
                  <div  style={inboxstyle}  className="field col-lg-5 col-md-6 col-12 pe-md-2" >
                    <div className="formgrid grid"> 
                    <div className="field col-12">
                      <h3 className="font-bold">Vouchers and Credit</h3>
                    </div>
                    <table class="table borderless search-table well" style ={{marginRight:"5rem"}}>
                      {/* 10 */}
                    <tr> 
          <td style={{width:"10%",textAlign:"center"}}>Voucher</td>
                          <td style={{width:"10%"}}> 
                                <InputText  id="bs_VO_AM"  name="bs_VO_AM"  value={bs_VO_AM}  onChange={(e) => onchangedata(e)}  />
                          </td>
                    </tr> 
                    <tr> 
          <td style={{width:"10%",textAlign:"center"}}>Credit</td>
                          <td style={{width:"10%"}}> 
                                <InputText  id="bs_CR_AM"  name="bs_CR_AM"  value={bs_CR_AM}  onChange={(e) => onchangedata(e)}  />
                          </td>
                    </tr> 
                    </table>
                    </div>
                  </div>
              </div>
          </TabPanel>

          <TabPanel header="USD">
          <div style={{ display:"flex"}}>
              <div  style={inboxstyle} className="field col-lg-6 col-md-6 col-12 pe-md-2" >
                <div className="formgrid grid"> 
                <div className="field col-12">
                  <h3 className="font-bold">Notes</h3>
                </div>
                <table class="table borderless search-table well" style ={{marginRight:"5rem"}}>
                  {/* 10 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>10.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_NT_QTY_10"  name="bs_NT_QTY_10"  defaultValue={usd_NT_QTY_10}  onChange={(e) => handleQuantityChange(e, 10, setusd_NT_QTY_10 , setusd_NT_10)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="usd_NT_10"  name="usd_NT_10"  value={usd_NT_10} disabled  onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 20 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>20.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_NT_QTY_20"  name="usd_NT_QTY_20"  defaultValue={usd_NT_QTY_20}  onChange={(e) => handleQuantityChange(e, 20, setusd_NT_QTY_20 , setusd_NT_20)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="usd_NT_20"  name="usd_NT_20"  value={usd_NT_20} disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 50 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>50.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_NT_QTY_50"  name="usd_NT_QTY_50"  defaultValue={usd_NT_QTY_50}  onChange={(e) => handleQuantityChange(e, 50, setusd_NT_QTY_50 , setusd_NT_50)} />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="usd_NT_50"  name="usd_NT_50"  value={usd_NT_50} disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 100 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>100.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_NT_QTY_100"  name="usd_NT_QTY_100"  defaultValue={usd_NT_QTY_100}  onChange={(e) => handleQuantityChange(e, 100, setusd_NT_QTY_100 , setusd_NT_100)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="usd_NT_100"  name="usd_NT_100"  value={usd_NT_100} disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 200  */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>200.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_NT_QTY_200"  name="usd_NT_QTY_200"  defaultValue={usd_NT_QTY_200}  onChange={(e) => handleQuantityChange(e, 200, setusd_NT_QTY_200 , setusd_NT_200)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="usd_NT_200"  name="usd_NT_200"  value={usd_NT_200} disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 500 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>500.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_NT_QTY_500"  name="usd_NT_QTY_500"  defaultValue={usd_NT_QTY_500}  onChange={(e) => handleQuantityChange(e, 500, setusd_NT_QTY_500 , setusd_NT_500)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="usd_NT_500"  name="usd_NT_500"  value={usd_NT_500} disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 1000 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>1000.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_NT_QTY_1000"  name="usd_NT_QTY_1000"  defaultValue={usd_NT_QTY_1000}  onChange={(e) => handleQuantityChange(e, 1000, setusd_NT_QTY_1000 , setusd_NT_1000)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="usd_NT_1000"  name="usd_NT_1000"  value={usd_NT_1000} disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                <tr>
                  <td> </td> 
                  <td> </td>
                  <td> </td>
                  <td style={{width:"10%"}}>
                        <InputText  id="usdTotalNotes"  name="usdTotalNotes"  value={usdTotalNotes}  onChange={(e) => onchangedata(e)} disabled />
                 </td>
                </tr>
                </table>   
                </div>
              </div>
              <div  style={inboxstyle} className="field col-lg-6 col-md-6 col-12 pe-md-2" >
                <div className="formgrid grid"> 
                <div className="field col-12">
                  <h3 className="font-bold">Coins</h3>
                </div>
                <table class="table borderless search-table well" style ={{marginRight:"5rem"}}>
                  {/* 10 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>0.05 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_CO_QTY_05"  name="usd_CO_QTY_05"  defaultValue={usd_CO_QTY_05}  onChange={(e) => handleQuantityChange(e, 0.05, setusd_CO_QTY_05, setusd_CO_05)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="usd_CO_05"  name="usd_CO_05"  value={usd_CO_05} disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 20 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>0.10 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_CO_QTY_10"  name="usd_CO_QTY_10"  defaultValue={usd_CO_QTY_10}  onChange={(e) => handleQuantityChange(e, 0.10, setusd_CO_QTY_10 , setusd_CO_10)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="usd_CO_10"  name="usd_CO_10"  value={usd_CO_10} disabled  onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 50 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>0.20 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_CO_QTY_20"  name="usd_CO_QTY_20"  defaultValue={usd_CO_QTY_20}  onChange={(e) => handleQuantityChange(e, 0.20, setusd_CO_QTY_20 , setusd_CO_20)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="usd_CO_20"  name="usd_CO_20"  value={usd_CO_20} disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 100 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>0.50 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_CO_QTY_50"  name="usd_CO_QTY_50"  defaultValue={usd_CO_QTY_50} onChange={(e) => handleQuantityChange(e, 0.50, setusd_CO_QTY_50 , setusd_CO_50)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="usd_CO_50"  name="usd_CO_50"  value={usd_CO_50} disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 200  */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>1.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_CO_QTY_1"  name="usd_CO_QTY_1"  defaultValue={usd_CO_QTY_1}  onChange={(e) => handleQuantityChange(e, 1.00, setusd_CO_QTY_1 , setusd_CO_1)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="usd_CO_1"  name="usd_CO_1"  value={usd_CO_1} disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 500 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>2.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_CO_QTY_2"  name="usd_CO_QTY_2"  defaultValue={usd_CO_QTY_2}  onChange={(e) => handleQuantityChange(e, 2.00, setusd_CO_QTY_2, setusd_CO_2)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="usd_CO_2"  name="usd_CO_2"  value={usd_CO_2} disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                {/* 1000 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>5.00 x</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_CO_QTY_5"  name="usd_CO_QTY_5"  defaultValue={usd_CO_QTY_5}  onChange={(e) => handleQuantityChange(e, 5.00, setusd_CO_QTY_5, setusd_CO_5)}  />
                      </td>
                      <td style={{width:"10%",textAlign:"center"}}><label>=</label></td>
                      <td style={{width:"10%"}}>
                            <InputText  id="usd_CO_5"  name="usd_CO_5"  value={usd_CO_5} disabled onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr>
                <tr>
                  <td> </td> 
                  <td> </td>
                  <td> </td>
                  <td style={{width:"10%"}}>
                        <InputText  id="usdTotalCoins"  name="usdTotalCoins"  value={usdTotalCoins}  onChange={(e) => onchangedata(e)} disabled />
                 </td>
                </tr>
                </table>   
                </div>
              </div>
          </div>
          <div style={{ display:"flex"}}>
              <div  style={inboxstyle}  className="field col-lg-5 col-md-6 col-12 pe-md-2" >
                <div className="formgrid grid"> 
                <div className="field col-12">
                  <h3 className="font-bold">Card Payment</h3>
                </div>
                <table class="table borderless search-table well" style ={{marginRight:"5rem"}}>
                  {/* 10 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>Master Card</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_MS_CR"  name="usd_MS_CR"  value={usd_MS_CR}  onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr> 
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>VISA</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_VI_SA"  name="usd_VI_SA"  value={usd_VI_SA}  onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr> 
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>American Express</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_AM_EX"  name="usd_AM_EX"  value={usd_AM_EX}  onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr> 
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>PayPal</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_PA_PL"  name="usd_PA_PL"  value={usd_PA_PL}  onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr> 
                </table>
                </div>
              </div>
              <div  style={inboxstyle}  className="field col-lg-5 col-md-6 col-12 pe-md-2" >
                <div className="formgrid grid"> 
                <div className="field col-12">
                  <h3 className="font-bold">Vouchers and Credit</h3>
                </div>
                <table class="table borderless search-table well" style ={{marginRight:"5rem"}}>
                  {/* 10 */}
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>Voucher</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_VO_AM"  name="usd_VO_AM"  value={usd_VO_AM}  onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr> 
                <tr> 
			<td style={{width:"10%",textAlign:"center"}}>Credit</td>
                      <td style={{width:"10%"}}> 
                            <InputText  id="usd_CR_AM"  name="usd_CR_AM"  value={usd_CR_AM}  onChange={(e) => onchangedata(e)}  />
                      </td>
                </tr> 
                </table>
                </div>
              </div>
          </div>
          </TabPanel>

          <TabPanel header="Summary">
            <div style={inboxstyle} >
            <Container fluid>
              <Row className="weekday-row">
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    
                  </label>
                </Col>
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    Actual
                  </label>
                </Col>
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    Target
                  </label>
                </Col>
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    Difference
                  </label>
                </Col>
              </Row>
              {/* 2nd */}
              <Row className="time-row" style={{marginTop:"1%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    Cash
                  </label>
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_AC_CS"
                    name="bs_AC_CS"
                    value={bs_AC_CS}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_TG_CS"
                    name="bs_TG_CS"
                    value={bs_TG_CS}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_DF_CS"
                    name="bs_DF_CS"
                    value={bs_AC_CS-bs_TG_CS}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
              {/* 3rd */}
              <Row className="time-row" style={{marginTop:"1%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    Master Card
                  </label>
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_AC_MC"
                    name="bs_AC_MC"
                    value={bs_AC_MC}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_TG_MC"
                    name="bs_TG_MC"
                    value={bs_TG_MC}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_DF_MC"
                    name="bs_DF_MC"
                    value={bs_DF_MC}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
              {/* 4th */}
              <Row className="time-row" style={{marginTop:"1%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    VISA
                  </label>
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_AC_VI"
                    name="bs_AC_VI"
                    value={bs_AC_VI}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_TG_VI"
                    name="bs_TG_VI"
                    value={bs_TG_VI}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_DF_VI"
                    name="bs_DF_VI"
                    value={bs_DF_VI}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
              {/* 5th */}
              <Row className="time-row" style={{marginTop:"1%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    American Express
                  </label>
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_AC_EX"
                    name="bs_AC_EX"
                    value={bs_AC_EX}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_TG_EX"
                    name="bs_TG_EX"
                    value={bs_TG_EX}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_DF_EX"
                    name="bs_DF_EX"
                    value={bs_DF_EX}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
              {/* 6th */}
              <Row className="time-row" style={{marginTop:"1%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    PayPal
                  </label>
                </Col>
                <Col md> 
                <InputText disabled
                    id="bs_AC_PP"
                    name="bs_AC_PP"
                    value={bs_AC_PP}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_TG_PP"
                    name="bs_TG_PP"
                    value={bs_TG_PP}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_DF_PP"
                    name="bs_DF_PP"
                    value={bs_DF_PP}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
              {/* 7th */}
              <Row className="time-row" style={{marginTop:"1%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    Voucher
                  </label>
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_AC_VO"
                    name="bs_AC_VO"
                    value={bs_AC_VO}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_TG_VO"
                    name="bs_TG_VO"
                    value={bs_TG_VO}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_DF_VO"
                    name="bs_DF_VO"
                    value={bs_DF_VO}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
              {/* 8th */}
              <Row className="time-row" style={{marginTop:"1%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    Credit
                  </label>
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_AC_CR"
                    name="bs_AC_CR"
                    value={bs_AC_CR}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_TG_CR"
                    name="bs_TG_CR"
                    value={bs_TG_CR}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_DF_CR"
                    name="bs_DF_CR"
                    value={bs_DF_CR}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
              {/* 9th */}
              <Row className="time-row" style={{marginTop:"1%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    Total
                  </label>
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_AC_TL"
                    name="bs_AC_TL"
                    value={bs_AC_TL}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="bs_TG_TL"
                    name="bs_TG_TL"
                    value={bs_TG_TL}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_DF_TL"
                    name="usd_DF_TL"
                    value={bs_AC_TL-bs_TG_TL}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
            </Container>
            </div>
            <div style={inboxstyle} >
            <Container fluid>
                  <label htmlFor="vertical" className="font-bold">
                    USD
                  </label>
              <Row className="weekday-row">
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    
                  </label>
                </Col>
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    Actual
                  </label>
                </Col>
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    Target
                  </label>
                </Col>
                <Col md>
                  <label htmlFor="vertical" className="font-bold">
                    Difference
                  </label>
                </Col>
              </Row>
              {/* 2nd */}
              <Row className="time-row" style={{marginTop:"1%"}}>
                  <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    Cash
                  </label>
                  </Col>
                <Col md>
                <InputText disabled
                    id="usd_AC_CS"
                    name="usd_AC_CS"
                    value={usd_AC_CS}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_TG_CS"
                    name="usd_TG_CS"
                    value={usd_TG_CS}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_DF_CS"
                    name="usd_DF_CS"
                    value={usd_AC_CS-usd_TG_CS}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
              {/* 3rd */}
              <Row className="time-row" style={{marginTop:"1%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    Master Card
                  </label>
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_AC_MC"
                    name="usd_AC_MC"
                    value={usd_AC_MC}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_TG_MC"
                    name="usd_TG_MC"
                    value={usd_TG_MC}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_DF_MC"
                    name="usd_DF_MC"
                    value={usd_DF_MC}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
              {/* 4th */}
              <Row className="time-row" style={{marginTop:"1%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    VISA
                  </label>
                </Col>
                <Col md> 
                <InputText disabled
                    id="usd_AC_VI"
                    name="usd_AC_VI"
                    value={usd_AC_VI}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_TG_VI"
                    name="usd_TG_VI"
                    value={usd_TG_VI}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_DF_VI"
                    name="usd_DF_VI"
                    value={usd_DF_VI}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
              {/* 5th */}
              <Row className="time-row" style={{marginTop:"1%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    American Express
                  </label>
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_AC_EX"
                    name="usd_AC_EX"
                    value={usd_AC_EX}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_TG_EX"
                    name="usd_TG_EX"
                    value={usd_TG_EX}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_DF_EX"
                    name="usd_DF_EX"
                    value={usd_DF_EX}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
              {/* 6th */}
              <Row className="time-row" style={{marginTop:"1%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    PayPal
                  </label>
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_AC_PP"
                    name="usd_AC_PP"
                    value={usd_AC_PP}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_TG_PP"
                    name="usd_TG_PP"
                    value={usd_TG_PP}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_DF_PP"
                    name="usd_DF_PP"
                    value={usd_DF_PP}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
              {/* 7th */}
              <Row className="time-row" style={{marginTop:"1%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    Voucher
                  </label>
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_AC_VO"
                    name="usd_AC_VO"
                    value={usd_AC_VO}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_TG_VO"
                    name="usd_TG_VO"
                    value={usd_TG_VO}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_DF_VO"
                    name="usd_DF_VO"
                    value={usd_DF_VO}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
              {/* 8th */}
              <Row className="time-row" style={{marginTop:"1%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    Credit
                  </label>
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_AC_CR"
                    name="usd_AC_CR"
                    value={usd_AC_CR}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_TG_CR"
                    name="usd_TG_CR"
                    value={usd_TG_CR}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_DF_CR"
                    name="usd_DF_CR"
                    value={usd_DF_CR}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
              {/* 9th */}
              <Row className="time-row" style={{marginTop:"1%"}}>
              <Col className="weekday-col">
                  <label htmlFor="vertical" className="font-bold">
                    Total
                  </label>
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_AC_TL"
                    name="usd_AC_TL"
                    value={usd_AC_TL}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_TG_TL"
                    name="usd_TG_TL"
                    value={usd_TG_TL}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
                <Col md>
                <InputText disabled
                    id="usd_DF_TL"
                    name="usd_DF_TL"
                    value={usd_AC_TL-usd_TG_TL}
                    onChange={(e) => onchangedata(e)}
                  />
                </Col>
              </Row>
            </Container>
            </div>
          </TabPanel>
      </TabView>
      </div>    
     
    </Dialog>
  );
}
