import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
import ItemPopup from "../Popups/ItemPopup";
import '../../common/TabStyles.css';
export default function PriceAdjustmentDetailDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  PriceAdjustmentDetailTableData,
  setPriceAdjustmentDetailTableData,
  ad_AD_NM,
  setad_AD_NM,
  ad_IT_GP,
  setad_IT_GP,
  ad_CU_GP,
  setad_CU_GP,
  ad_PR_UM,
  setad_PR_UM,
  ad_BA_IS,
  setad_BA_IS,
  ad_CO_MT,
  setad_CO_MT,
  SelectedITNM,
  setSelectedITNM,
  SelectedCust,
  setSelectedCust,
  ad_TR_CR,
  setad_TR_CR,
  ad_TR_NO,
  ad_PR_MT,
  setad_PR_MT,
  itemcusChecked,
  setitemcusChecked,
  itemcusgrpChecked,
  setitemcusgrpChecked,
  itemGrpCusChecked,
  setitemGrpCusChecked,
  itemGrpCusGrpChecked,
  setitemGrpCusGrpChecked,
  itemChecked,
  setitemChecked
}) {
  const { adUkNo,ad_IT_NM,ad_CU_NO,ad_FR_DT,ad_TO_DT, } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();

  const [ad_AD_NMOptions, setad_AD_NMOptions] = useState([]);
  const [ad_IT_GPOptions, setad_IT_GPOptions] = useState([]);
  const [ad_CU_GPOptions, setad_CU_GPOptions] = useState([]);
  const [ad_PR_UMOptions, setad_PR_UMOptions] = useState([]);
  const [ad_BA_ISOptions, setad_BA_ISOptions] = useState([]);
  const [ad_CO_MTOptions, setad_CO_MTOptions] = useState([]);
  const [ad_TR_CROptions, setad_TR_CROptions] = useState([]);

  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_AD_NM(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_IT_GP(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_CU_GP(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_PR_UM(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_BA_IS(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_CO_MT(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_TR_CR(value);
  };
  useEffect(() => {
    FetchDropdownAdjustment();
    FetchDropdownItemGrp();
    FetchDropdownCusGrp();
    FetchDropdownBasis();
    FetchDropdownUM();
    FetchDropdownCostMtd();
    FetchDropdownCurr();
  }, []);

  const FetchDropdownAdjustment = async () => {
    const Options = await fetchClassCodeDS("AD/NM");
    setad_AD_NMOptions(Options);
  };
  const FetchDropdownItemGrp = async () => {
    const Options = await fetchClassCodeCD("IN/PR");
    setad_IT_GPOptions(Options);
  };
  const FetchDropdownCusGrp = async () => {
    const Options = await fetchClassCodeDS("SO/PR");
    setad_CU_GPOptions(Options);
  };
  const FetchDropdownUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setad_PR_UMOptions(Options);
  };
  const FetchDropdownBasis = async () => {
    const Options = await fetchClassCodeDS("AD/BS");
    setad_BA_ISOptions(Options);
  };
  const FetchDropdownCostMtd = async () => {
    const Options = await fetchClassCodeDS("IN/CT");
    setad_CO_MTOptions(Options);
  };
  const FetchDropdownCurr = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setad_TR_CROptions(Options);
  };
  const addRow = () => {
    setPriceAdjustmentDetailTableData([...PriceAdjustmentDetailTableData, {}]);
  };
  const deleteRow = () => {
    if (PriceAdjustmentDetailTableData.length > 0) {
      const updatedData = [...PriceAdjustmentDetailTableData];
      updatedData.pop();
      setPriceAdjustmentDetailTableData(updatedData);
    }
  };
// advance searchs
  const [ItemPopupVisible, setItemPopupVisible] =useState(false);
  const [CustomerPopupVisible, setCustomerPopupVisible] =useState(false);
  const [addressTypecust, setaddressTypecust] = useState("C");

  const [ItemMulPopupVisible, setItemMulPopupVisible] =useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const openItemPopup = () => {
    setItemPopupVisible(true);
  };
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };

  const handleDataSelectItem = (Result) => {
    setSelectedITNM(Result.im_IT_DS);
    document.getElementById("ad_IT_NM").value = Result.im_IT_NM;
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelectCustomer = (data) => {
    setSelectedCust(data.ab_AD_NM);
    document.getElementById("ad_CU_NO").value = data.abAdNo;
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
   // State to handle which fields are enabled or disabled
   const [disabledFields, setDisabledFields] = useState({
    item: true,
    customer: true,
    customerGroup: true,
    itemGroup: true,
  });
// Pricing level check boxes
const handleCheckboxChange = (event) => {
  const { name, checked } = event.target;
  if (name === 'item_CUS') {
    setitemcusChecked(checked);
    if (checked) {
      setitemcusgrpChecked(false);
      setitemGrpCusChecked(false);
      setitemGrpCusGrpChecked(false);
      setitemChecked(false);
      setad_PR_MT('IC');
      setFormData((prevFormData) => ({ ...prevFormData, fr_PR_MT: 'IC' }));

      // Enable item and customer fields
      setDisabledFields({
        item: false,
        customer: false,
        customerGroup: true,
        itemGroup: true,
      });
    } else {
      resetFields();
    }
  } else if (name === 'item_CUSGRP') {
    setitemcusgrpChecked(checked);
    if (checked) {
      setitemGrpCusChecked(false);
      setitemGrpCusGrpChecked(false);
      setitemChecked(false);
      setitemcusChecked(false);
      setad_PR_MT('ICG');
      setFormData((prevFormData) => ({ ...prevFormData, fr_PR_MT: 'ICG' }));

      // Enable item and customer group fields
      setDisabledFields({
        item: false,
        customer: true,
        customerGroup: false,
        itemGroup: true,
      });
    } else {
      resetFields();
    }
  } else if (name === 'itemGrp_Cus') {
    setitemGrpCusChecked(checked);
    if (checked) {
      setitemcusgrpChecked(false);
      setitemGrpCusGrpChecked(false);
      setitemChecked(false);
      setitemcusChecked(false);
      setad_PR_MT('IGC');
      setFormData((prevFormData) => ({ ...prevFormData, fr_PR_MT: 'IGC' }));

      // Enable item group and customer fields
      setDisabledFields({
        item: true,
        customer: false,
        customerGroup: true,
        itemGroup: false,
      });
    } else {
      resetFields();
    }
  } else if (name === 'itemGrp_CusGrp') {
    setitemGrpCusGrpChecked(checked);
    if (checked) {
      setitemGrpCusChecked(false);
      setitemcusgrpChecked(false);
      setitemChecked(false);
      setitemcusChecked(false);
      setad_PR_MT('IGCG');
      setFormData((prevFormData) => ({ ...prevFormData, fr_PR_MT: 'IGCG' }));

      // Enable item group and customer group fields
      setDisabledFields({
        item: true,
        customer: true,
        customerGroup: false,
        itemGroup: false,
      });
    } else {
      resetFields();
    }
  } else if (name === 'item') {
    setitemChecked(checked);
    if (checked) {
      setitemGrpCusChecked(false);
      setitemcusgrpChecked(false);
      setitemcusChecked(false);
      setitemGrpCusGrpChecked(false);
      setad_PR_MT('I');
      setFormData((prevFormData) => ({ ...prevFormData, fr_PR_MT: 'I' }));

      // Enable only the item field
      setDisabledFields({
        item: false,
        customer: true,
        customerGroup: true,
        itemGroup: true,
      });
    } else {
      resetFields();
    }
  }
};

const resetFields = () => {
  setDisabledFields({
    item: true,
    customer: true,
    customerGroup: true,
    itemGroup: true,
  });
  setad_PR_MT('');
  setFormData((prevFormData) => ({ ...prevFormData, fr_PR_MT: '' }));
};

  // DIALOG HEADER
  const isUpdateMode = Boolean(adUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{adUkNo ? "Revise Price Adjustment Detail" : "Add Price Adjustment Detail"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div  style={inboxstyle}>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ad_AD_NM" className="font-bold">
                    Adjustment Name
                  </label>
                    <Dropdown
                      id="ad_AD_NM"
                      name="ad_AD_NM"
                      value={ad_AD_NM}
                      options={ad_AD_NMOptions}
                      onChange={(e) => onchangeDropDownFormData1("ad_AD_NM", e.value)}
                      placeholder="Select Adjustment Name"
                    />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ad_IT_NM" className="font-bold">
                    Item Number <span style={{ color: 'red' }}>*</span>
                  </label>
                    <div style={{ display: 'flex' }}>
                        <InputText disabled={disabledFields.item}
                          id="SelectedITNM"
                          name="SelectedITNM"
                          onChange={(event) => onchangedata(event)}
                          onFocus={() => setShowSearchIcon(true)}
                          value={SelectedITNM} 
                        />
                        {showSearchIcon && (
                          <i
                            className="pi pi-search"
                            style={{
                              color: 'var(--primary-color)',
                              fontSize: '119%',
                              marginTop: '4%',
                              cursor: 'pointer',
                            }}
                            onClick={openItemPopup}
                          ></i>
                        )}
                    </div>
                    <div style={{ display: 'none' }}>
                      <InputText
                        id="ad_IT_NM"
                        name="ad_IT_NM"
                        value={ad_IT_NM}
                        onChange={(event) => onchangedata(event)}
                      />
                    </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ad_IT_GP" className="font-bold">
                    Item Group
                  </label>
                    <Dropdown  disabled={disabledFields.itemGroup}
                      id="ad_IT_GP"
                      name="ad_IT_GP"
                      value={ad_IT_GP}
                      options={ad_IT_GPOptions}
                      onChange={(e) => onchangeDropDownFormData2("ad_IT_GP", e.value)}
                      placeholder="Select Item Group"
                    />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ad_CU_NO" className="font-bold">
                    Customer Number <span style={{ color: 'red' }}>*</span>
                  </label>
                    <div style={{ display : 'flex' }}>
                        <InputText disabled={disabledFields.customer}
                          id="SelectedCust"
                          name="SelectedCust"
                          onChange={(event) => onchangedata(event)}
                          onFocus={() => setShowSearchIcon(true)}
                          value={SelectedCust}
                        />
                        {showSearchIcon && (
                          <i
                            className="pi pi-search"
                            style={{
                              color: 'var(--primary-color)',
                              fontSize: '119%',
                              marginTop: '4%',
                              cursor: 'pointer',
                            }}
                            onClick={openCustomerPopup}
                          ></i>
                        )}
                    </div>
                    <div style={{ display: 'none' }}>
                      <InputText
                        id="ad_CU_NO"
                        name="ad_CU_NO"
                        value={ad_CU_NO}
                        onChange={(event) => onchangedata(event)}
                      />
                    </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ad_CU_GP" className="font-bold">
                    Customer Group
                  </label>
                    <Dropdown  disabled={disabledFields.customerGroup}
                      id="ad_CU_GP"
                      name="ad_CU_GP"
                      value={ad_CU_GP}
                      options={ad_CU_GPOptions}
                      onChange={(e) => onchangeDropDownFormData3("ad_CU_GP", e.value)}
                      placeholder="Select Customer Group"
                    />
                </div>

              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="formgrid grid" style={{width:'20rem',marginLeft:'2rem'}} >
              <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
              <label htmlFor="fr_PR_MT" className="font-bold" style={{ marginRight: '15px' }}>
                  Item & Customer
                </label>
                <input
                  type="checkbox"
                  id="item_CUS"
                  name="item_CUS"
                  checked={itemcusChecked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
              <label htmlFor="fr_PR_MT" className="font-bold" style={{ marginRight: '15px' }}>
                  Item & Customer Group
                </label>
                <input
                  type="checkbox"
                  name="item_CUSGRP"
                  id="item_CUSGRP"
                  checked={itemcusgrpChecked}
                  onChange={handleCheckboxChange}
                />
              </div> 
              <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
                <label htmlFor="fr_PR_MT" className="font-bold" style={{ marginRight: '15px' }}>
                  Item Group & Customer
                </label>
                <input
                  type="checkbox"
                  id="itemGrp_Cus"
                  name="itemGrp_Cus"
                  checked={itemGrpCusChecked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
                <label htmlFor="fr_PR_MT" className="font-bold" style={{ marginRight: '15px' }}>
                  Item Group & Customer Group
                </label>
                <input
                  type="checkbox"
                  name="itemGrp_CusGrp"
                  id="itemGrp_CusGrp"
                  checked={itemGrpCusGrpChecked}
                  onChange={handleCheckboxChange}
                />               
              </div> 
              <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
                <label htmlFor="fr_PR_MT" className="font-bold" style={{ marginRight: '15px' }}>
                  Item Only
                </label>
                <input
                  type="checkbox"
                  name="item"
                  id="item"
                  checked={itemChecked}
                  onChange={handleCheckboxChange}
                />
              </div>
              </div>
            </div>
          </div>
        </div>  
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="PriceAdjustmentDetailtable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>From Level</th>
                <th>UM</th>
                <th>Factor Value</th>
                <th>Basis</th>
                <th>Currency</th>
                <th>From Date</th>
                <th>To Date</th>
                <th>Cost Method</th>
                <th>Trans. No</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {PriceAdjustmentDetailTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`adUkNo_${index}`}
                      name={`adUkNo_${index}`}
                      value={row.adUkNo}
                    />
                  </td>
                  <td>
                    <InputText style={{width:'10rem'}}
                        id={`ad_FR_LV_${index}`}
                        name={`ad_FR_LV_${index}`}
                        value={row.ad_FR_LV ? row.ad_FR_LV : data[`${"ad_FR_LV"}_${index}`]}
                        onChange={(e) => onchangedata(e)}
                      />
                      </td>
                      <td >
                        <Dropdown style={{width:'7rem'}}
                          id={`ad_PR_UM_${index}`}
                          name={`ad_PR_UM_${index}`}
                          value={ data[`ad_PR_UM_${index}`] ? data[`ad_PR_UM_${index}`]: row.ad_PR_UM}
                          options={ad_PR_UMOptions}
                          onChange={(e) =>onchangeDropDownFormData4(`ad_PR_UM_${index}`, e.value)}
                          placeholder="Select UM"
                        />
                      </td>
                      <td >
                      <InputText style={{width:'7rem'}}
                        id={`ad_FC_LV_${index}`}
                        name={`ad_FC_LV_${index}`}
                        value={row.ad_FC_LV ? row.ad_FC_LV : data[`${"ad_FC_LV"}_${index}`]}
                        onChange={(e) => onchangedata(e)}
                      />
                      </td>
                      <td >
                      <Dropdown style={{width:'7rem'}}
                        id={`ad_BA_IS_${index}`}
                        name={`ad_BA_IS_${index}`}
                        value={ data[`ad_BA_IS_${index}`] ? data[`ad_BA_IS_${index}`]: row.ad_BA_IS}
                        options={ad_BA_ISOptions}
                        onChange={(e) =>onchangeDropDownFormData5(`ad_BA_IS_${index}`, e.value)}
                        placeholder="Select Basis"
                      />
                      </td>
                      <td >
                      <Dropdown style={{width:'7rem'}}
                        id={`ad_TR_CR_${index}`}
                        name={`ad_TR_CR_${index}`}
                        value={ data[`ad_TR_CR_${index}`] ? data[`ad_TR_CR_${index}`]: row.ad_TR_CR}
                        options={ad_TR_CROptions}
                        onChange={(e) =>onchangeDropDownFormData7(`ad_TR_CR_${index}`, e.value)}
                        placeholder="Select Basis"
                      />
                      </td>
                      <td >
                      <Calendar  style={{width:"150px"}}
                        id={`ad_FR_DT_${index}`}
                        name={`ad_FR_DT_${index}`}
                        value={ row.ad_FR_DT ? moment(row.ad_FR_DT, "YYYYMMDD").toDate() : null }
                        onChange={(e) => onDateChange({ target: { name: "ad_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                        dateFormat="dd/mm/yy"
                      />
                      </td>
                      <td >
                      <Calendar  style={{width:"150px"}}
                        id={`ad_TO_DT_${index}`}
                        name={`ad_TO_DT_${index}`}
                        value={ row.ad_TO_DT ? moment(row.ad_TO_DT, "YYYYMMDD").toDate() : null }
                        onChange={(e) => onDateChange({ target: { name: "ad_TO_DT", value: moment(e.value).format("YYYYMMDD") } })}
                        dateFormat="dd/mm/yy"
                      />
                      </td>
                      <td >
                      <Dropdown style={{width:'7rem'}}
                        id={`ad_CO_MT_${index}`}
                        name={`ad_CO_MT_${index}`}
                        value={ data[`ad_CO_MT_${index}`] ? data[`ad_CO_MT_${index}`]: row.ad_CO_MT}
                        options={ad_CO_MTOptions}
                        onChange={(e) =>onchangeDropDownFormData6(`ad_CO_MT_${index}`, e.value)}
                        placeholder="Select Cost Method"
                      />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`ad_TR_NO_${index}`}
                          name={`ad_TR_NO_${index}`}
                          value={row.ad_TR_NO}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>                  
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`ad_ST_CD_${index}`}
                          name={`ad_ST_CD_${index}`}
                          value={row.ad_ST_CD}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
     {/* item */}
     <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelectItem}
        formData={data}
      />
      {/* Customer */}
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelectCustomer}
        formData={data}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
    </Dialog>
  );
}
