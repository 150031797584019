import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import RentalContractDialog from "./RentalContractDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  rcUkNo:"",
  rc_TR_NO:"",
  rc_TR_TY:"",
  rc_TR_CO:"",
  rc_ST_DT:"",
  rc_EN_DT:"",
  rc_IT_NM:"",
  itemDES:"",
  rc_LO_CN:"",
  rc_OR_QT:"",
  rc_TR_UM:"",
  rc_UN_PR:"",
  rc_UP_AM:"",
  rc_UN_CT:"",
  rc_UC_AM:"",
  rc_DS_PE:"",
  rc_DS_AM:"",
  rc_BL_FR:"",
  rc_HD_ST:"",
  status:"",
  rc_TR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  rc_RQ_DT: moment(moment().toDate()).format("YYYYMMDD"),
  rc_RT_CD:"",
 
};

const initialFilters = {
  rc_TR_NO:{ value: "", operation: "" },
  rc_TR_TY:{ value: "", operation: "" },
  rc_TR_CO:{ value: "", operation: "" },
  rc_ST_DT:{ value: "", operation: "" },
  rc_EN_DT:{ value: "", operation: "" },
  rc_IT_NM:{ value: "", operation: "" },
  itemDES:{ value: "", operation: "" },
  rc_LO_CN:{ value: "", operation: "" },
  rc_OR_QT:{ value: "", operation: "" },
  rc_TR_UM:{ value: "", operation: "" },
  rc_UN_PR:{ value: "", operation: "" },
  rc_UP_AM:{ value: "", operation: "" },
  rc_UN_CT:{ value: "", operation: "" },
  rc_UC_AM:{ value: "", operation: "" },
  rc_DS_PE:{ value: "", operation: "" },
  rc_DS_AM:{ value: "", operation: "" },
  rc_BL_FR:{ value: "", operation: "" },
  rc_HD_ST:{ value: "", operation: "" },
  status:{ value: "", operation: "" },
};

const RentalContractMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,rightalined,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,inputStyleH,tableCellStyleH,tableHeaderStyleH,inputStyle1
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // drodpdowns
  const [rc_CO_CT, setrc_CO_CT] = useState("");
  const [rc_TR_CR, setrc_TR_CR ] = useState("");
  const [rc_PY_TR, setrc_PY_TR] = useState("");
  const [rc_BL_FR, setrc_BL_FR ] = useState("");
  const [rc_TX_AP, setrc_TX_AP ] = useState("");
  const [rc_HD_ST, setrc_HD_ST] = useState("");
  const [ed_LN_TY, seted_LN_TY] = useState("");
  const [rc_TR_UM, setrc_TR_UM] = useState("");
  const [rc_EX_RT, setrc_EX_RT] = useState("");
  const [bd_DO_TY, setbd_DO_TY] = useState("");
  const [rc_TR_NO, setrc_TR_NO] = useState("");

  const onchangegldate = (Event) => {
    const { value, name } = Event.target;
     setFormData({ ...FormData, [name]: value });
     onChnageExchangeRate(rc_TR_CR,value);
  };

  const CostCentreDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_CO_CT(value);
  };

  const StatusDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_HD_ST(value);
  };

  const CurrencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_TR_CR(value);
    onChnageExchangeRate(value, FormData.rc_TR_DT);
  };


  function onChnageExchangeRate(frCr, ReceiveDate) {
    const exData = {
      "cr_TO_CR":  document.getElementById("rc_BS_CR").value, 
      "cr_FR_CR": frCr,
      "cr_EF_DT": ReceiveDate
    }
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("rc_EX_RT").value = dataExRt.cr_CO_MR;
          setrc_EX_RT(dataExRt.cr_CO_MR);
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });

  };
  
// Advancesearch
  const [SelectedemEMNO, setSelectedemEMNO ] = useState("");
  const [SelectedemEMNOC, setSelectedemEMNOC ] = useState("");
  const [SelectedemEMNOD, setSelectedemEMNOD ] = useState("");
  const [SelectedemEMNOE, setSelectedemEMNOE ] = useState("");
  const [SelectedemEMNO1, setSelectedemEMNO1 ] = useState("");
  const [selectedAbAdNM, setSelectedAbAdNM ] = useState("");
  const [SelectedItem, setselectedItem ] = useState("");
  const [SelectedDesc, setselectedDesc ] = useState("");
  const [selectedSeNO, setselectedSeNO ] = useState("");
  const [selectedLoc, setselectedLoc ] = useState("");

// Next No
  const [rc_DV_RT, setrc_DV_RT ] = useState("");
  const [rc_SH_TO, setrc_SH_TO ] = useState("");
  const [rc_RL_TY, setrc_RL_TY ] = useState("");

  const [RentalContractTable, setRentalContractTable] = useState([{}]);
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleAddButtonClick = () => {
    setRentalContractTable([{}]);
    setFormData(initialValue);
    setrc_PY_TR(null);
    setrc_TX_AP(null);
    setrc_EX_RT(null);
    setrc_BL_FR(null);
    setrc_TR_CR(null);
    setrc_ZN_CD(null);
    setrc_MD_TR(null);
    setrc_RT_CD(null);
    setrc_LO_CN(null);
    setrc_TR_UM(null);
    setrc_DV_RT(null);
    setSelectedemEMNO(null);
    setSelectedAbAdNM(null);
    setSelectedemEMNOE(null);
    setSelectedemEMNOD(null);
    setSelectedemEMNOC(null);
    setselectedItem(null);
    setselectedDesc(null);
    setprice_AMOUNT(null);
    setdiscount_AMOUNT(null);
    setnet_AMOUNT(null);
    settax_AMOUNT(null);
    settotal(null);
    setfr_PRICE_AMOUNT(null);
    setfr_DISCOUNT_AMOUNT(null);
    setfr_NET_AMOUNT(null);
    setfr_TAX_AMOUNT(null);
    setfr_TOTAL(null);
    setOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setRentalContractTable([{}]);
    setFormData(initialValue);
    setrc_PY_TR(null);
    setrc_TX_AP(null);
    setrc_EX_RT(null);
    setrc_BL_FR(null);
    setrc_TR_CR(null);
    setrc_ZN_CD(null);
    setrc_MD_TR(null);
    setrc_RT_CD(null);
    setrc_LO_CN(null);
    setrc_TR_UM(null);
    setrc_DV_RT(null);
    setSelectedemEMNO(null);
    setSelectedAbAdNM(null);
    setSelectedemEMNOE(null);
    setSelectedemEMNOD(null);
    setSelectedemEMNOC(null);
    setselectedItem(null);
    setselectedDesc(null);
    setprice_AMOUNT(null);
    setdiscount_AMOUNT(null);
    setnet_AMOUNT(null);
    settax_AMOUNT(null);
    settotal(null);
    setfr_PRICE_AMOUNT(null);
    setfr_DISCOUNT_AMOUNT(null);
    setfr_NET_AMOUNT(null);
    setfr_TAX_AMOUNT(null);
    setfr_TOTAL(null);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [rc_TR_CO , setrc_TR_CO] = useState("");
  const [rc_TR_TY , setrc_TR_TY] = useState("");
  const [rc_BS_CR , setrc_BS_CR] = useState("");
  const [rc_RL_CO , setrc_RL_CO] = useState("");
  const [rc_LO_CN , setrc_LO_CN] = useState("");

  const [rc_ZN_CD , setrc_ZN_CD] = useState("");
  const [rc_MD_TR , setrc_MD_TR] = useState("");
  const [rc_RT_CD , setrc_RT_CD] = useState("");
  const [rc_ST_CD , setrc_ST_CD] = useState("");
  const [price_AMOUNT , setprice_AMOUNT] = useState("");
  const [discount_AMOUNT , setdiscount_AMOUNT] = useState("");
  const [net_AMOUNT , setnet_AMOUNT] = useState("");
  const [tax_AMOUNT , settax_AMOUNT] = useState("");
  const [total , settotal] = useState("");
  const [fr_PRICE_AMOUNT , setfr_PRICE_AMOUNT] = useState("");
  const [fr_DISCOUNT_AMOUNT , setfr_DISCOUNT_AMOUNT] = useState("");
  const [fr_NET_AMOUNT , setfr_NET_AMOUNT] = useState("");
  const [fr_TAX_AMOUNT , setfr_TAX_AMOUNT] = useState("");
  const [fr_TOTAL , setfr_TOTAL] = useState("");


  const handleDefault = async () => {
    setrc_TR_CO(`${company}`)
    setrc_RL_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "RC_TR_TY"){
              setrc_TR_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "RC_BS_CR"){
              setrc_BS_CR(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "RC_RL_TY"){
              setrc_RL_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "RC_ST_CD"){
              setrc_ST_CD(dataDefault.versionIds[i].default_VALUE)
            }
            
            if(fName === "RC_CO_CT"){
              CostCentreDropDown("rc_CO_CT",dataDefault.versionIds[i].default_VALUE);
              setrc_CO_CT(dataDefault.versionIds[i].default_VALUE);
            } 
            if(fName === "RC_HD_ST"){
              StatusDropdown("rc_HD_ST",dataDefault.versionIds[i].default_VALUE);
              setrc_HD_ST(dataDefault.versionIds[i].default_VALUE);
            } 
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/RentalContract/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value, index) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
    const fieldName=(FormData && FormData.rcUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      RentalContractTable[index][fieldName]=value;
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/RentalContract/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const RentalContractData = response.data;
        setRentalContractTable(RentalContractData.rentalContracts);
        setFormData(RentalContractData.rentalContracts[0]);
        setrc_CO_CT(RentalContractData.rentalContracts[0].rc_CO_CT);
        setrc_PY_TR(RentalContractData.rentalContracts[0].rc_PY_TR);
        setrc_TX_AP(RentalContractData.rentalContracts[0].rc_TX_AP);
        setrc_EX_RT(RentalContractData.rentalContracts[0].rc_EX_RT);
        setrc_TR_CR(RentalContractData.rentalContracts[0].rc_TR_CR);
        setrc_HD_ST(RentalContractData.rentalContracts[0].rc_HD_ST);
        setrc_ZN_CD(RentalContractData.rentalContracts[0].rc_ZN_CD);
        setrc_MD_TR(RentalContractData.rentalContracts[0].rc_MD_TR);
        setrc_RT_CD(RentalContractData.rentalContracts[0].rc_RT_CD);
        setrc_SH_TO(RentalContractData.rentalContracts[0].rc_SH_TO);
        setrc_BL_FR(RentalContractData.rentalContracts.rc_BL_FR);
        setrc_LO_CN(RentalContractData.rentalContracts.rc_LO_CN);
        setrc_TR_UM(RentalContractData.rentalContracts.rc_TR_UM);
        setSelectedemEMNO(RentalContractData.rentalContracts[0].cus_NAME);
        setSelectedAbAdNM(RentalContractData.rentalContracts[0].shipto_NAME);
        setSelectedemEMNOE(RentalContractData.rentalContracts[0].sales_PERSON);
        setSelectedemEMNOD(RentalContractData.rentalContracts[0].deliver_TO);
        setrc_DV_RT(RentalContractData.rentalContracts[0].rc_DV_RT);
        setSelectedemEMNOC(RentalContractData.rentalContracts[0].carrier_NO);
        setselectedItem(RentalContractData.rentalContracts.rc_IT_NM);
        setselectedDesc(RentalContractData.rentalContracts.itemDES);

          setprice_AMOUNT(RentalContractData.rentalContracts[0].matUnit);
          setdiscount_AMOUNT(RentalContractData.rentalContracts[0].disAmt);
          setnet_AMOUNT(RentalContractData.rentalContracts[0].net_AMOUNT);
          settax_AMOUNT(RentalContractData.rentalContracts[0].taxAmt);
          settotal(RentalContractData.rentalContracts[0].total_AMOUNT);
          setfr_PRICE_AMOUNT(RentalContractData.rentalContracts[0].foreAmount);
          setfr_DISCOUNT_AMOUNT(RentalContractData.rentalContracts[0].forematDisamt);
          setfr_NET_AMOUNT(RentalContractData.rentalContracts[0].frNET_AMOUNT);
          setfr_TAX_AMOUNT(RentalContractData.rentalContracts[0].foreTaxamt);
          setfr_TOTAL(RentalContractData.rentalContracts[0].f_TOTAL_AMOUNT);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/RentalContract/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const RentalContractData = response.data;
          delete RentalContractData.rentalContracts[0].rc_TR_NO;
          delete RentalContractData.rentalContracts[0].rcUkNo;
          for (let i = 0; i < RentalContractData.rentalContracts.length; i++) {
            delete RentalContractData.rentalContracts[i].rcUkNo;
            RentalContractData.rentalContracts[i].rc_ST_CD = rc_ST_CD;
          }
          setRentalContractTable(RentalContractData.rentalContracts);
          setFormData(RentalContractData.rentalContracts[0]);
          setrc_CO_CT(RentalContractData.rentalContracts[0].rc_CO_CT);
          setrc_PY_TR(RentalContractData.rentalContracts[0].rc_PY_TR);
          setrc_TX_AP(RentalContractData.rentalContracts[0].rc_TX_AP);
          setrc_EX_RT(RentalContractData.rentalContracts[0].rc_EX_RT);
          setrc_TR_CR(RentalContractData.rentalContracts[0].rc_TR_CR);
          setrc_HD_ST(RentalContractData.rentalContracts[0].rc_HD_ST);
          setrc_ZN_CD(RentalContractData.rentalContracts[0].rc_ZN_CD);
          setrc_MD_TR(RentalContractData.rentalContracts[0].rc_MD_TR);
          setrc_RT_CD(RentalContractData.rentalContracts[0].rc_RT_CD);
          setrc_SH_TO(RentalContractData.rentalContracts[0].rc_SH_TO);
          setrc_BL_FR(RentalContractData.rentalContracts.rc_BL_FR);
          setrc_LO_CN(RentalContractData.rentalContracts.rc_LO_CN);
          setrc_TR_UM(RentalContractData.rentalContracts.rc_TR_UM);
          setSelectedemEMNO(RentalContractData.rentalContracts[0].cus_NAME);
          setSelectedAbAdNM(RentalContractData.rentalContracts[0].shipto_NAME);
          setSelectedemEMNOE(RentalContractData.rentalContracts[0].sales_PERSON);
          setSelectedemEMNOD(RentalContractData.rentalContracts[0].deliver_TO);
          setrc_DV_RT(RentalContractData.rentalContracts[0].rc_DV_RT);
          setSelectedemEMNOC(RentalContractData.rentalContracts[0].carrier_NO);
          setselectedItem(RentalContractData.rc_IT_NM);
          setselectedDesc(RentalContractData.itemDES);
          
          setprice_AMOUNT(RentalContractData.rentalContracts[0].matUnit);
          setdiscount_AMOUNT(RentalContractData.rentalContracts[0].disAmt);
          setnet_AMOUNT(RentalContractData.rentalContracts[0].net_AMOUNT);
          settax_AMOUNT(RentalContractData.rentalContracts[0].taxAmt);
          settotal(RentalContractData.rentalContracts[0].total_AMOUNT);
          setfr_PRICE_AMOUNT(RentalContractData.rentalContracts[0].foreAmount);
          setfr_DISCOUNT_AMOUNT(RentalContractData.rentalContracts[0].forematDisamt);
          setfr_NET_AMOUNT(RentalContractData.rentalContracts[0].frNET_AMOUNT);
          setfr_TAX_AMOUNT(RentalContractData.rentalContracts[0].foreTaxamt);
          setfr_TOTAL(RentalContractData.rentalContracts[0].f_TOTAL_AMOUNT);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("RentalContractTable");
  const totalRowCount = table.tBodies[0].rows.length;
  const rentalContracts = [];
  for (let i = 0; i < totalRowCount; i++) {
    const ST_DT = document.getElementsByName("rc_ST_DT_" + i)[0];
    const EN_DT = document.getElementsByName("rc_EN_DT_" + i)[0];
    const details = {
        rcUkNo : document.getElementById("rcUkNo_" + i).value,
        rc_LN_NO : document.getElementById("rc_LN_NO_" + i).value,
        rc_IT_NM : document.getElementById("rc_IT_NM_" + i).value,
        itemDES : document.getElementById("itemDES_" + i).value,
        rc_LO_CN : table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
        rc_SR_NO : document.getElementById("rc_SR_NO_" + i).value,
        rc_OR_QT : document.getElementById("rc_OR_QT_" + i).value,
        rc_TR_UM : table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
        rc_UN_PR : document.getElementById("rc_UN_PR_" + i).value,
        rc_UP_AM : document.getElementById("rc_UP_AM_" + i).value,
        rc_UN_CT : document.getElementById("rc_UN_CT_" + i).value,
        rc_UC_AM : document.getElementById("rc_UC_AM_" + i).value,
        rc_DS_PE : document.getElementById("rc_DS_PE_" + i).value,
        rc_DS_AM : document.getElementById("rc_DS_AM_" + i).value,
        rc_NT_MT : document.getElementById("rc_NT_MT_" + i).value,
        rc_TX_AP : document.getElementById("rc_TX_AP_" + i).value,
        rc_TX_PE : document.getElementById("rc_TX_PE_" + i).value,
        rc_TX_AM : document.getElementById("rc_TX_AM_" + i).value,
        rc_FR_UP : document.getElementById("rc_FR_UP_" + i).value,
        rc_FR_AM : document.getElementById("rc_FR_AM_" + i).value,
        rc_FR_DS : document.getElementById("rc_FR_DS_" + i).value,
        rc_FN_MT : document.getElementById("rc_FN_MT_" + i).value,
        rc_ST_DT: ST_DT.value?moment(ST_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        rc_EN_DT: EN_DT.value?moment(EN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        rc_BL_FR : table.tBodies[0].rows[i].cells[24].children[0].children[1].children[0].value,
        rc_ST_CD : document.getElementById("rc_ST_CD_" + i).value,
    };
    rentalContracts.push(details);
  }
  requestBody = {
    rentalContracts,
    rcUkNo   : FormData.rcUkNo,
    rc_TR_NO : document.getElementById("rc_TR_NO").value,
    rc_TR_TY : document.getElementById("rc_TR_TY").value,
    rc_TR_CO : document.getElementById("rc_TR_CO").value,
    rc_RL_NO : document.getElementById("rc_RL_NO").value,
    rc_RL_TY : document.getElementById("rc_RL_TY").value,
    rc_RL_CO : document.getElementById("rc_RL_CO").value,
    rc_TR_DT : FormData.rc_TR_DT,
    rc_RQ_DT : FormData.rc_RQ_DT,
    rc_CO_CT : rc_CO_CT,
    rc_CU_NO : document.getElementById("rc_CU_NO").value,
    rc_PY_TR : FormData.rc_PY_TR,
    rc_TX_AP : FormData.rc_TX_AP,
    rc_TR_CR : rc_TR_CR,
    rc_EX_RT : document.getElementById("rc_EX_RT").value,
    rc_BS_CR : document.getElementById("rc_BS_CR").value,
    rc_SH_TO : document.getElementById("rc_SH_TO").value,
    rc_SL_PR : document.getElementById("rc_SL_PR").value,
    rc_HD_ST : rc_HD_ST,
    rc_DS_PE : document.getElementById("rc_DS_PE").value,
    rc_DS_AM : document.getElementById("rc_DS_AM").value,
    rc_RF_NC : document.getElementById("rc_RF_NC").value,
    rc_DV_RT : document.getElementById("rc_DV_RT").value,
    rc_CR_NO : document.getElementById("rc_CR_NO").value,
    rc_ZN_CD : FormData.rc_ZN_CD,
    rc_MD_TR : FormData.rc_MD_TR,
    rc_RT_CD : FormData.rc_RT_CD,
    rc_IN_TR : FormData.rc_IN_TR,
  };
}
const handleFormSubmit = () => {
  values();
  const orNo = FormData.rc_TR_NO;
  const ukNo = FormData.rcUkNo;
  if (orNo) {
    if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/RentalContract/Update`;
  
      if (confirm) {
        axios.put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/RentalContract/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  } else {
      
      const OR_DT = FormData.rc_TR_DT;
      if (OR_DT) {
        const currentYear = OR_DT.toString();
        const firstHalf = currentYear.substring(0, 2);
        const secondHalf = currentYear.substring(2, 4);
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
        const parameters = `${rc_TR_TY}/${secondHalf}/1000/${firstHalf}`;
        const finalUrl1 = `${urlNextNumber}/${parameters}`;
        fetch(finalUrl1, {
          method: "GET",
          headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
        })
          .then((resp) => {
            if (!resp.ok) {
              throw new Error(`Request failed with status: ${resp.status}`);
            }
            return resp.json();
          })
          .then((resp) => {
            onchangeFormData("rc_TR_NO", resp.dn_DO_NO);
            setFormData({ ...FormData, ["rc_TR_NO"]: resp.dn_DO_NO });
            values();
            axios.post(`${domain}/api/RentalContract/Add`, requestBody, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error saving data:", error);
                });

          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }
  };



const handleConfirm =  async () => {
  const  Url = `${domain}/api/RentalContract/RNCNConfirmDetails`
  const request = {
    "rc_TR_NO": FormData.rc_TR_NO,
    "rc_TR_TY": document.getElementById("rc_TR_TY").value,
    "rc_TR_CO": document.getElementById("rc_TR_CO").value,
  }
  axios.post(Url, request, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    handleResponse(resp.data);
  })
  .catch((error) => {
    console.error("Error adding data:", error);
  });
};
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_NO"] ? filters["rc_TR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_NO",
                        e.target.value,
                        filters["rc_TR_NO"]
                          ? filters["rc_TR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_TY"] ? filters["rc_TR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_TY",
                        e.target.value,
                        filters["rc_TR_TY"] ? filters["rc_TR_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_CO"] ? filters["rc_TR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_CO",
                        e.target.value,
                        filters["rc_TR_CO"]
                          ? filters["rc_TR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_DT"] ? filters["rc_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_DT",
                        e.target.value,
                        filters["rc_TR_DT"] ? filters["rc_TR_DT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_ST_DT"] ? filters["rc_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_ST_DT",
                        e.target.value,
                        filters["rc_ST_DT"]
                          ? filters["rc_ST_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["rc_EN_DT"] ? filters["rc_EN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_EN_DT",
                        e.target.value,
                        filters["rc_EN_DT"]
                          ? filters["rc_EN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rc_IT_NM"] ? filters["rc_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_IT_NM",
                        e.target.value,
                        filters["rc_IT_NM"]
                          ? filters["rc_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["itemDES"] ? filters["itemDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "itemDES",
                        e.target.value,
                        filters["itemDES"]
                          ? filters["itemDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                    disabled
                  />
                </td>   
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["rc_LO_CN"] ? filters["rc_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_LO_CN",
                        e.target.value,
                        filters["rc_LO_CN"]
                          ? filters["rc_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["locationDES"] ? filters["locationDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "locationDES",
                        e.target.value,
                        filters["locationDES"]
                          ? filters["locationDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rc_OR_QT"] ? filters["rc_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_OR_QT",
                        e.target.value,
                        filters["rc_OR_QT"]
                          ? filters["rc_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_UM"] ? filters["rc_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_UM",
                        e.target.value,
                        filters["rc_TR_UM"]
                          ? filters["rc_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rc_UN_PR"] ? filters["rc_UN_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_UN_PR",
                        e.target.value,
                        filters["rc_UN_PR"]
                          ? filters["rc_UN_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rc_UP_AM"] ? filters["rc_UP_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_UP_AM",
                        e.target.value,
                        filters["rc_UP_AM"]
                          ? filters["rc_UP_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rc_UN_CT"] ? filters["rc_UN_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_UN_CT",
                        e.target.value,
                        filters["rc_UN_CT"]
                          ? filters["rc_UN_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rc_UC_AM"] ? filters["rc_UC_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_UC_AM",
                        e.target.value,
                        filters["rc_UC_AM"]
                          ? filters["rc_UC_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rc_DS_PE"] ? filters["rc_DS_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_DS_PE",
                        e.target.value,
                        filters["rc_DS_PE"]
                          ? filters["rc_DS_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rc_DS_AM"] ? filters["rc_DS_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_DS_AM",
                        e.target.value,
                        filters["rc_DS_AM"]
                          ? filters["rc_DS_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["rc_BL_FR"] ? filters["rc_BL_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_BL_FR",
                        e.target.value,
                        filters["rc_BL_FR"]
                          ? filters["rc_BL_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["freqDES"] ? filters["freqDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "freqDES",
                        e.target.value,
                        filters["freqDES"]
                          ? filters["freqDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>   
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["rc_HD_ST"] ? filters["rc_HD_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_HD_ST",
                        e.target.value,
                        filters["rc_HD_ST"]
                          ? filters["rc_HD_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["status"] ? filters["status"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "status",
                        e.target.value,
                        filters["status"]
                          ? filters["status"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>   
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Contract No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>   
                <th style={tableHeaderStyle}>Date</th> 
                <th style={tableHeaderStyle}>Start Date</th> 
                <th style={tableHeaderStyle}>End Date</th> 
                <th style={tableHeaderStyle}>Item No</th> 
                <th style={tableHeaderStyle}>Description</th>  
                <th style={tableHeaderStyleH}>Location</th> 
                <th style={tableHeaderStyle}>Location</th> 
                <th style={tableHeaderStyle}>Quantity</th>  
                <th style={tableHeaderStyle}>UM</th> 
                <th style={tableHeaderStyle}>Unit Price</th> 
                <th style={tableHeaderStyle}>Amount</th> 
                <th style={tableHeaderStyle}>Unit Cost</th> 
                <th style={tableHeaderStyle}>Amount</th> 
                <th style={tableHeaderStyle}>Discount %</th> 
                <th style={tableHeaderStyle}>Discount Amount</th>  
                <th style={tableHeaderStyleH}>Billing Frequency</th>
                <th style={tableHeaderStyle}>Billing Frequency</th>
                <th style={tableHeaderStyleH}>Status</th>
                <th style={tableHeaderStyle}>Status</th> 
                          
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.rcUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.rcUkNo}
                        checked={selectedRows.includes(rowData.rcUkNo)}
                        onChange={() => handleRowSelect(rowData.rcUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.rc_TR_NO}</td>
                    <td style={tableCellStyle}>{rowData.rc_TR_TY}</td>
                    <td style={tableCellStyle}>{rowData.rc_TR_CO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.rc_TR_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.rc_ST_DT)}</td>  
                    <td style={tableCellStyle}>{formatDate(rowData.rc_EN_DT)}</td>
                    <td style={tableCellStyle}>{rowData.rc_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.itemDES}</td>   
                    <td style={tableCellStyleH}>{rowData.rc_LO_CN}</td>  
                    <td style={tableCellStyle}>{rowData.locationDES}</td>   
                    <td style={rightalined}>{rowData.rc_OR_QT}</td>   
                    <td style={tableCellStyle}>{rowData.rc_TR_UM}</td>   
                    <td style={rightalined}>{rowData.rc_UN_PR}</td>
                    <td style={rightalined}>{rowData.rc_UP_AM}</td>    
                    <td style={rightalined}>{rowData.rc_UN_CT}</td> 
                    <td style={rightalined}>{rowData.rc_UC_AM}</td>   
                    <td style={tableCellStyle}>{rowData.rc_DS_PE}</td> 
                    <td style={rightalined}>{rowData.rc_DS_AM}</td>    
                    <td style={tableCellStyleH}>{rowData.rc_BL_FR}</td>   
                    <td style={tableCellStyle}>{rowData.freqDES}</td>   
                    <td style={tableCellStyleH}>{rowData.rc_HD_ST}</td>
                    <td style={tableCellStyle}>{rowData.status}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <RentalContractDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        RentalContractTable={RentalContractTable}
        setRentalContractTable={setRentalContractTable}
        CurrencyDropDown={CurrencyDropDown}
        onchangegldate={onchangegldate}
        rc_TR_CO={rc_TR_CO}
        rc_TR_TY={rc_TR_TY}
        rc_BS_CR={rc_BS_CR}
        rc_CO_CT={rc_CO_CT}
        setrc_CO_CT={setrc_CO_CT}
        rc_TR_CR={rc_TR_CR}
        setrc_TR_CR={setrc_TR_CR}
        rc_PY_TR={rc_PY_TR}
        setrc_PY_TR={setrc_PY_TR}
        rc_BL_FR={rc_BL_FR}
        setrc_BL_FR={setrc_BL_FR}
        rc_HD_ST={rc_HD_ST}
        setrc_HD_ST={setrc_HD_ST}
        ed_LN_TY={ed_LN_TY}
        seted_LN_TY={seted_LN_TY}
        rc_EX_RT={rc_EX_RT}
        setrc_EX_RT={setrc_EX_RT}
        bd_DO_TY={bd_DO_TY}
        setbd_DO_TY={setbd_DO_TY}
        rc_TX_AP={rc_TX_AP}
        setrc_TX_AP={setrc_TX_AP}
        rc_TR_UM={rc_TR_UM}
        setrc_TR_UM={setrc_TR_UM}
        SelectedItem={SelectedItem}
        setselectedItem={setselectedItem}
        SelectedDesc={SelectedDesc}
        setselectedDesc={setselectedDesc}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedemEMNOC={SelectedemEMNOC}
        setSelectedemEMNOC={setSelectedemEMNOC}
        SelectedemEMNOD={SelectedemEMNOD}
        setSelectedemEMNOD={setSelectedemEMNOD}
        SelectedemEMNOE={SelectedemEMNOE}
        setSelectedemEMNOE={setSelectedemEMNOE}
        SelectedemEMNO1={SelectedemEMNO1}
        setSelectedemEMNO1={setSelectedemEMNO1}
        selectedAbAdNM={selectedAbAdNM}
        setSelectedAbAdNM={setSelectedAbAdNM}
        rc_SH_TO={rc_SH_TO}
        setrc_SH_TO={setrc_SH_TO}
        rc_DV_RT={rc_DV_RT}
        setrc_DV_RT={setrc_DV_RT}
        rc_RL_TY={rc_RL_TY}
        setrc_RL_TY={setrc_RL_TY}
        rc_RL_CO={rc_RL_CO}
        setrc_RL_CO={setrc_RL_CO}
        rc_LO_CN={rc_LO_CN}
        setrc_LO_CN={setrc_LO_CN}
        rc_ZN_CD={rc_ZN_CD}
        setrc_ZN_CD={setrc_ZN_CD}
        rc_MD_TR={rc_MD_TR}
        setrc_MD_TR={setrc_MD_TR}
        rc_RT_CD={rc_RT_CD}
        setrc_RT_CD={setrc_RT_CD}
        rc_ST_CD={rc_ST_CD}
        price_AMOUNT={price_AMOUNT}
        setprice_AMOUNT={setprice_AMOUNT}
        discount_AMOUNT={discount_AMOUNT}
        setdiscount_AMOUNT={setdiscount_AMOUNT}
        setnet_AMOUNT={setnet_AMOUNT}
        net_AMOUNT={net_AMOUNT}
        settax_AMOUNT={settax_AMOUNT}
        tax_AMOUNT={tax_AMOUNT}
        settotal={settotal}
        total={total}
        fr_PRICE_AMOUNT={fr_PRICE_AMOUNT}
        setfr_PRICE_AMOUNT={setfr_PRICE_AMOUNT}
        fr_DISCOUNT_AMOUNT={fr_DISCOUNT_AMOUNT}
        setfr_DISCOUNT_AMOUNT={setfr_DISCOUNT_AMOUNT}
        fr_NET_AMOUNT={fr_NET_AMOUNT}
        setfr_NET_AMOUNT={setfr_NET_AMOUNT}
        fr_TAX_AMOUNT={fr_TAX_AMOUNT}
        setfr_TAX_AMOUNT={setfr_TAX_AMOUNT}
        fr_TOTAL={fr_TOTAL}
        setfr_TOTAL={setfr_TOTAL}
       
        handleConfirm={handleConfirm}
        setselectedSeNO={setselectedSeNO}
        selectedSeNO={selectedSeNO}
        selectedLoc={selectedLoc}
        setselectedLoc={setselectedLoc}
        CostCentreDropDown={CostCentreDropDown}
        StatusDropdown={StatusDropdown}
        
      />
    </div>
  );
};

export default RentalContractMain;
