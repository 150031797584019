import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import TenantWorkOrderCostDialog from "./TenantWorkOrderCostDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  wcUkNo:"",
  wc_OR_TY: "",
  wc_LE_NO: "",
  wc_OR_CO: "",
  wc_OR_NO: "",
  wc_TE_NT:"",
  wc_BL_NO:"",
  wc_UN_TS:"",
  wc_TR_DT:"",
  wc_GL_DT:"",
  wc_CO_CT:"",
  wc_ES_HR:"",
  wc_ES_AM:"",
  wc_AC_AM:"",
  wc_WO_DS:"",
  wc_DO_NO:"",
  wc_DO_TY:"",
  wc_AC_HR:"",
  wc_PA_NO:"",
  wc_PA_DS:"",
  wc_TR_QT:"",
  wc_TR_UM:"",
  wc_UN_RT:"",
  wc_EX_CT:"",
};

const initialFilters = {
  wc_OR_NO: { value: "", operation: "" },
  wc_OR_TY: { value: "", operation: "" },
  wc_LE_NO: { value: "", operation: "" },
  wc_OR_CO: { value: "", operation: "" },
  wc_TE_NT: { value: "", operation: "" },
  wc_BL_NO: { value: "", operation: "" },
  wc_UN_TS: { value: "", operation: "" },
  wc_TR_DT: { value: "", operation: "" },
  wc_GL_DT: { value: "", operation: "" },
  wc_CO_CT: { value: "", operation: "" },
  wc_ES_HR: { value: "", operation: "" },
 
};

const TenantWorkOrderCostMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();

  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [wc_DO_TY, setwc_DO_TY] = useState("");
 
  const [TenantWorkOrderCosttable, setTenantWorkOrderCosttable] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setwc_DO_TY(null);
   
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setwc_DO_TY(null);
    
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleSearch();
    }
  };
  const addRow = () => {
    setTenantWorkOrderCosttable([...TenantWorkOrderCosttable, {}]);
  };

  const deleteRow = () => {
    if (TenantWorkOrderCosttable.length > 0) {
      const updatedData = [...TenantWorkOrderCosttable];
      updatedData.pop();
      setTenantWorkOrderCosttable(updatedData);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/TenantWorkOrderCost/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const TenantWorkOrderCostData = response.data;
          delete TenantWorkOrderCostData.wcUkNo;
          setFormData(TenantWorkOrderCostData);
          setSelectedemEMNO(TenantWorkOrderCostData.ad_AD_NM); //ad_EM_NM
          setwc_DO_TY(TenantWorkOrderCostData.wc_DO_TY);
          
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const url = `${domain}/api/Contact/AllFields`;

  useEffect(() => {
    handleSearch();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/TenantWorkOrderCost/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
 const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setwc_DO_TY(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/TenantWorkOrderCost/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const TenantWorkOrderCostData = response.data;
        setTenantWorkOrderCosttable(TenantWorkOrderCostData.tenantWorkOrderCosts);
        setFormData(TenantWorkOrderCostData.tenantWorkOrderCosts[0]);
       
        setwc_DO_TY(response.data.wc_DO_TY);
       
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleFormSubmit = () => {    
      if (FormData.wcUkNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/TenantWorkOrderCost/Update`;
        if (confirm) {
          const table = document.getElementById("TenantWorkOrderCosttable");
          const totalRowCount = table.tBodies[0].rows.length;
          const OR_NO = document.getElementById("wc_OR_NO");
          const OR_TY = document.getElementById("wc_OR_TY");
          const TE_NT = document.getElementById("wc_TE_NT");
          const LE_NO = document.getElementById("wc_LE_NO");
          const BL_NO = document.getElementById("wc_BL_NO");
          const UN_TS = document.getElementById("wc_UN_TS");
          const TR_DT = document.getElementsByName("wc_TR_DT");
          const GL_DT = document.getElementsByName("wc_GL_DT");
          const ES_HR = document.getElementById("wc_ES_HR");
          const ES_AM = document.getElementById("wc_ES_AM");
          const tenantWorkOrderCosts = [];
          for (let i = 0; i < totalRowCount; i++) {
            const UK_NO = document.getElementById("wcUkNo_" + i);
            const WO_DS = document.getElementById("wc_WO_DS_" + i);
            const DO_NO = document.getElementById("wc_DO_NO_" + i);
           const DO_TY = document.getElementById("wc_DO_TY_" + i);
            const OR_CO = document.getElementById("wc_OR_CO_" + i);
            const PA_NO = document.getElementById("wc_PA_NO_" + i);
            const PA_DS = document.getElementById("wc_PA_DS_" + i);
            const TR_QT = document.getElementById("wc_TR_QT_" + i);
            const TR_UM = document.getElementById("wc_TR_UM_" + i);
            const UN_RT = document.getElementById("wc_UN_RT_" + i);
            const EX_CT = document.getElementById("wc_EX_CT_" + i);
           
            const Details = {
              wcUkNo: UK_NO.value,
              wc_WO_DS_:WO_DS.value,
              wc_DO_NO_: DO_NO.value,
              wc_DO_TY_: DO_TY.value,
              wc_OR_CO_: OR_CO.value,
              wc_PA_NO_: PA_NO.value,
              wc_PA_DS_: PA_DS.value,
              wc_TR_QT_: TR_QT.value,
              wc_TR_UM_: TR_UM.value,
              wc_UN_RT_: UN_RT.value,
              wc_EX_CT_: EX_CT.value,
              
          wc_OR_NO: OR_NO.value,
          wc_OR_TY: OR_TY,
          wc_OR_CO: OR_CO.value,
          wc_TE_NT: TE_NT.value,
          wc_LE_NO: LE_NO.value,
          wc_BL_NO: BL_NO.value,
          wc_UN_TS: UN_TS.value,
          wc_TR_DT: TR_DT.value?moment(TR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          wc_GL_DT: GL_DT.value?moment(GL_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          wc_ES_HR: ES_HR.value,
          wc_ES_AM: ES_AM.value,
            };
            tenantWorkOrderCosts.push(Details);
          }
          const requestBody = {
            tenantWorkOrderCosts,
           
            
          };
          axios
            .put(update, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        const table = document.getElementById("TenantWorkOrderCosttable");
        const totalRowCount = table.tBodies[0].rows.length;
        const UK_NO = FormData.wcUkNo;
          const OR_NO = document.getElementById("wc_OR_NO");
          const OR_TY = document.getElementById("wc_OR_TY");
          const OR_CO = document.getElementById("wc_OR_CO");
          const TE_NT = document.getElementById("wc_TE_NT");
          const LE_NO = document.getElementById("wc_LE_NO");
          const BL_NO = document.getElementById("wc_BL_NO");
          const UN_TS = document.getElementById("wc_UN_TS");
          const TR_DT = document.getElementsByName("wc_TR_DT");
          const GL_DT = document.getElementsByName("wc_GL_DT");
          const ES_HR = document.getElementById("wc_ES_HR");
          const ES_AM = document.getElementById("wc_ES_AM");
        const tenantWorkOrderCosts = [];
        for (let i = 0; i < totalRowCount; i++) {
          const UK_NO = document.getElementById("wcUkNo_" + i);
            const WO_DS = document.getElementById("wc_WO_DS_" + i);
            const DO_NO = document.getElementById("wc_DO_NO_" + i);
           const DO_TY = document.getElementById("wc_DO_TY_" + i);
            const OR_CO = document.getElementById("wc_OR_CO_" + i);
            const PA_NO = document.getElementById("wc_PA_NO_" + i);
            const PA_DS = document.getElementById("wc_PA_DS_" + i);
            const TR_QT = document.getElementById("wc_TR_QT_" + i);
            const TR_UM = document.getElementById("wc_TR_UM_" + i);
            const UN_RT = document.getElementById("wc_UN_RT_" + i);
            const EX_CT = document.getElementById("wc_EX_CT_" + i);


          const Details = {
            wcUkNo: UK_NO.value,
              wc_WO_DS_:WO_DS.value,
              wc_DO_NO_: DO_NO.value,
              wc_DO_TY_: DO_TY.value,
              wc_OR_CO_: OR_CO.value,
              wc_PA_NO_: PA_NO.value,
              wc_PA_DS_: PA_DS.value,
              wc_TR_QT_: TR_QT.value,
              wc_TR_UM_: TR_UM.value,
              wc_UN_RT_: UN_RT.value,
              wc_EX_CT_: EX_CT.value,
              wc_OR_NO: OR_NO.value,
              wc_OR_TY: OR_TY,
              wc_OR_CO: OR_CO.value,
              wc_TE_NT: TE_NT.value,
              wc_LE_NO: LE_NO.value,
              wc_BL_NO: BL_NO.value,
              wc_UN_TS: UN_TS.value,
              wc_TR_DT: TR_DT.value?moment(TR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
              wc_GL_DT: GL_DT.value?moment(GL_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
              wc_ES_HR: ES_HR.value,
              wc_ES_AM: ES_AM.value,
          };

          tenantWorkOrderCosts.push(Details);
        }
        const requestBody = {
          tenantWorkOrderCosts,
         
        };
        axios
          .post(`${domain}/api/TenantWorkOrderCost/Add`, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
          // error
          //   const infos = resp.data.infos;
          //  document.write(infos);
          handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    } ;

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
    
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
     
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_OR_NO"] ? filters["wc_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_OR_NO",
                        e.target.value,
                        filters["wc_OR_NO"]
                          ? filters["wc_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_OR_TY"] ? filters["wc_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_OR_TY",
                        e.target.value,
                        filters["wc_OR_TY"]
                          ? filters["wc_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_LE_NO"] ? filters["wc_LE_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_LE_NO",
                        e.target.value,
                        filters["wc_LE_NO"]
                          ? filters["wc_LE_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
                <td>
                  <input
                    type="text"
                    value={filters["wc_OR_CO"] ? filters["wc_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_OR_CO",
                        e.target.value,
                        filters["wc_OR_CO"]
                          ? filters["wc_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                
                <td>
                  <input
                    type="text"
                    value={filters["wc_TE_NT"] ? filters["wc_TE_NT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_TE_NT",
                        e.target.value,
                        filters["wc_TE_NT"]
                          ? filters["wc_TE_NT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_BL_NO"] ? filters["wc_BL_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_BL_NO",
                        e.target.value,
                        filters["wc_BL_NO"]
                          ? filters["wc_BL_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_UN_TS"] ? filters["wc_UN_TS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_UN_TS",
                        e.target.value,
                        filters["wc_UN_TS"]
                          ? filters["wc_UN_TS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_TR_DT"] ? filters["wc_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_TR_DT",
                        e.target.value,
                        filters["wc_TR_DT"]
                          ? filters["wc_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_GL_DT"] ? filters["wc_GL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_GL_DT",
                        e.target.value,
                        filters["wc_GL_DT"]
                          ? filters["wc_GL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              
                <td>
                  <input
                    type="text"
                    value={filters["wc_ES_HR"] ? filters["wc_ES_HR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_ES_HR",
                        e.target.value,
                        filters["wc_ES_HR"]
                          ? filters["wc_ES_HR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_ES_AM"] ? filters["wc_ES_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_ES_AM",
                        e.target.value,
                        filters["wc_ES_AM"]
                          ? filters["wc_ES_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Work Order</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Tenant Name</th>
                <th style={tableHeaderStyle}>Lease Number</th>
                <th style={tableHeaderStyle}>Building No</th>
                <th style={tableHeaderStyle}>Unit</th>
                <th style={tableHeaderStyle}>Trans Date</th>
                <th style={tableHeaderStyle}>GL Date</th>
                <th style={tableHeaderStyle}>Estimated Hours</th>
               
                <th style={tableHeaderStyle}>Estimated Amount</th>
               
                
           
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.wcUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.wcUkNo}
                        checked={selectedRows.includes(rowData.wcUkNo)}
                        onChange={() => handleRowSelect(rowData.wcUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.wc_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.wc_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.wc_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.wc_LE_NO}</td>
                    <td style={tableCellStyle}>{rowData.wc_TE_NT}</td>
                    <td style={tableCellStyle}>{rowData.wc_BL_NO}</td>
                    <td style={tableCellStyle}>{rowData.wc_UN_TS}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.wc_TR_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.wc_GL_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.wc_ES_HR}</td>
                    <td style={tableCellStyle}>{rowData.wc_ES_AM}</td>
                    
                   
                   
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
 {/* pagination */}
 <Pagination
    currentPage={currentPage}
    totalPages={totalPages}
    setCurrentPage={setCurrentPage}
  />
        
      </div>

      <TenantWorkOrderCostDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        wc_DO_TY={wc_DO_TY}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        TenantWorkOrderCosttable={TenantWorkOrderCosttable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
  
      />
    </div>
  );
};

export default TenantWorkOrderCostMain;
