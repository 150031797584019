import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';

export default function ConvertDialog({
  data,
  onClose,
  visible,
  onchangedata,
  onchangeFormData,
  handleForm
}) {
  const { 
  rh_GL_BA,
  rh_UK_NO,
  } = data;


  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState(data);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // const [open, setOpen] = useState(visible);
  // useEffect(() => {
  //   setOpen(visible);
  // }, [visible]);

  const handleClose = () => {
    onClose();
    // setOpen(false);
  };
  useEffect(() => {
    setFormData(data); // Initialize form data with passed data prop
  }, [data]);

  const handleClick = () => {
    handleForm(formData, handleClose); 
  };

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const openAccountPopup = (e) => {
    setAccountPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect1 = async (data) => {
    setSelectedemEMNO(data.am_AC_DS);
    onchangeFormData("rh_GL_BA", data.account);
    setAccountPopupVisible(false);
  };
  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl1 = `${urlcost}`;
  const url = `${domain}/api/ClassCode/All`;
  useEffect(() => {
    
  }, []);
  
const isUpdateMode = Boolean(rh_UK_NO);
  const productDialogFooter = (
    <React.Fragment>
    {/* <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button> */}
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleClick} 
    tooltip={isUpdateMode ? "Convert" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={visible}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={rh_UK_NO ? "Convert" : "Convert1"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
     <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rh_GL_BA" className="font-bold">
                  Bank Account <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
              <InputText
                id="am_AC_DS"
                name="am_AC_DS"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNO}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openAccountPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "block" }}>
              <InputText
                id="rh_GL_BA"
                name="rh_GL_BA"
                value={rh_GL_BA}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openAccountPopup}
              ></i>
            </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2" style={{display:"block"}}>
            <label htmlFor="rh_UK_NO" className="font-bold">
              UKNO
            </label>
            <InputText
              id="rh_UK_NO"
              name="rh_UK_NO"
              defaultValue={rh_UK_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>  
                
              </div>
      </div>  
      <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
    </Dialog>
  );
}
