import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ContactOwnerPopup from "./ContactOwnerPopup";
import LeadIDPopup from "./LeadIDPopup";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import ButtonStyles from "views/common/ButtonStyles";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
export default function SalesLeadDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData,
  onchangeDropDownFormData2,
  onchangeDropDownFormData1,
  handleFormSubmit,
  so_OP_ID,
  so_TR_CR,
  so_OP_ST,
  so_LO_RE,
  so_OP_CO,
  so_OP_TY,
  so_LD_TY,
  selectedAbAdNo, //LEAD OWNER
  selectedAbAdNp, //partner
  setSelectedAbAdNo,
  setSelectedAbAdNp,
  selectedAbAdNM, //influncer
  selectedAbAdNN, //CUSTOMER
  setSelectedAbAdNM,
  setSelectedAbAdNN,
  setSelectedAbAdNc,
  selectedAbAdNc, //CONTACT
  selectedSLLDID,
  setSelectedSLLDID,
  so_CU_NO,
  setso_CU_NO,
  so_LD_OW,
  setso_LD_OW,
  so_CO_PE,
  setso_CO_PE,
  soUkNo,
}) {
  const {
    so_LD_ID,
    so_PO_AM,
    so_BU_AM,
    so_GO_AL,
    so_CL_DT,
    so_IN_FL,
    so_PR_TY,
    so_OP_PA,
    so_SC_OR,
    so_TR_DT,
    so_CU_NM,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [InfluencerPopupVisible, setInfluencerPopupVisible] = useState(false);
  const [LeadOwnerPopupVisible, setLeadOwnerVisible] = useState(false);
  const [PartnerPopupVisible, setPartnerPopupVisible] = useState(false);
  const [ContactOwnerPopupVisible, setContactOwnerPopupVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [LeadIDPopupVisible, setLeadIDPopupVisible] = useState(false);

  const [addressTypeCust, setaddressTypecust] = useState("C");
  const [addressTypeINFL, setaddressTypeINFL] = useState("I");
  const [addressTypeLDOW, setaddressTypeLDOW] = useState("E");
  const [addressTypePtnr, setaddressTypePtnr] = useState("P");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };

  const openTnfluencerPopup = () => {
    setInfluencerPopupVisible(true);
  };

  const openContactOwnerPopup = () => {
    setContactOwnerPopupVisible(true);
  };
  const openLeadOwnerPopup = () => {
    setLeadOwnerVisible(true);
  };

  const openPartnerPopup = () => {
    setPartnerPopupVisible(true);
  };

  const openLeadIDPopup = () => {
    setLeadIDPopupVisible(true);
  };
  const [so_TR_CROptions, setso_TR_CROptions] = useState([]);
  const [so_LO_REOptions, setso_LO_REOptions] = useState([]);
  const [so_OP_STOptions, setso_OP_STOptions] = useState([]);

  const url = `${domain}/api/ClassCode/All`;

  const parameter = "FO/CR";
  const finalUrl = `${url}/${parameter}`;

  const parameter1 = "LO/RE";
  const finalUrl1 = `${url}/${parameter1}`;

  const parameter2 = "OP/ST";
  const finalUrl2 = `${url}/${parameter2}`;

  useEffect(() => {
    fetchDropdownOptions();
    fetchDropdownOptions1();
    fetchDropdownOptions2();
  }, []);
  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setso_TR_CROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setso_LO_REOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setso_OP_STOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const handleDataSelect = (data) => {
    setSelectedAbAdNN(data.ab_AD_NM);
    document.getElementById("so_CU_NO").value = data.abAdNo;
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect1 = (data) => {
    setSelectedAbAdNM(data.ab_AD_NM);
    onchangeFormData("so_IN_FL", data.abAdNo);
    setInfluencerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect2 = (data) => {
    setSelectedAbAdNo(data.ab_AD_NM);
    onchangeFormData("so_LD_OW", data.abAdNo);
    setLeadOwnerVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect3 = (data) => {
    setSelectedAbAdNp(data.ab_AD_NM);
    onchangeFormData("so_OP_PA", data.abAdNo);
    setPartnerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect4 = (data) => {
    setSelectedAbAdNc(data.cc_CN_NM);
    document.getElementById("so_CO_PE").value = data.ccCnNo;
    setContactOwnerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect5 = async (data) => {
    onchangeFormData("so_LD_ID", data.slLdId);
    const Url = `${domain}/api/SalesLead/onChangeOfLeadId`;
    const requestData={
      "slLdId":data.slLdId,
      "sl_LD_TY":data.sl_LD_TY
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.Result;
      if (dataLead) {
        setso_LD_OW(dataLead.sl_LD_OW);
        setso_CU_NO(dataLead.sl_CO_NM);
        setSelectedAbAdNN(dataLead.ab_AD_NM);
        setSelectedAbAdNo(dataLead.lead_NAME);
        setso_CO_PE(dataLead.sl_CN_NO);
        setSelectedAbAdNc(dataLead.contact_NAME);
        onchangeFormData("so_LD_ID", dataLead.slLdId);
      } else {
        console.error("Invalid API response:", dataLead);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLeadIDPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "so_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const isUpdateMode = Boolean(soUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{soUkNo ? " Revise Sales Oppurtunies" : "Add Sales Oppurtunies"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_OP_ID" className="font-bold">
                Oppurtunity ID
              </label>
              <InputText disabled
                id="so_OP_ID"
                name="so_OP_ID"
                value={so_OP_ID}
               />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_OP_TY" className="font-bold">
                Type
              </label>
              <InputText disabled
                id="so_OP_TY"
                name="so_OP_TY"
                value={so_OP_TY}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_OP_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="so_OP_CO"
                name="so_OP_CO"
                value={so_OP_CO}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display : "none"}}>
              <label htmlFor="soUkNo" className="font-bold">
              soUkNo
              </label>
              <InputText disabled
                id="soUkNo"
                name="soUkNo"
                value={soUkNo}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_LD_ID" className="font-bold">
                Lead ID<span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
              <InputText 
                  id="so_LD_ID"
                  name="so_LD_ID"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={so_LD_ID}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openLeadIDPopup}
                ></i>
                )}
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_LD_TY" className="font-bold">
                Lead Type
              </label>
              <InputText disabled
                id="so_LD_TY"
                name="so_LD_TY"
                value={so_LD_TY}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_TR_DT" className="font-bold">
                Date
              </label>
              <Calendar
                id="so_TR_DT"
                name="so_TR_DT"
                value={moment(so_TR_DT, "YYYYMMDD").toDate()}
                onChange={handleDateChange}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_CU_NO" className="font-bold">
                Customer <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={selectedAbAdNN}
                />
                  {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openCustomerPopup}
                ></i>
                  )}
              </div>
              <div style={{display:"none"}} >
                <InputText
                  id="so_CU_NO"
                  name="so_CU_NO"
                  defaultValue={so_CU_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_SC_OR" className="font-bold">
                Score
              </label>
              <InputText
                id="so_SC_OR"
                name="so_SC_OR"
                value={so_SC_OR}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_PR_TY" className="font-bold">
                Probability
              </label>
              <InputText
                id="so_PR_TY"
                name="so_PR_TY"
                value={so_PR_TY}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_PO_AM" className="font-bold">
                Potential Amount
              </label>
              <InputText
                id="so_PO_AM"
                name="so_PO_AM"
                value={so_PO_AM}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_BU_AM" className="font-bold">
                Budget Amount
              </label>
              <InputText
                id="so_BU_AM"
                name="so_BU_AM"
                value={so_BU_AM}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_TR_CR" className="font-bold">
                Currency
              </label>
              <Dropdown
                id="so_TR_CR"
                name="so_TR_CR"
                value={so_TR_CR}
                options={so_TR_CROptions}
                onChange={(e) => onchangeDropDownFormData("cc_DE_GN", e.value)}
                placeholder="Select Currency"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_LD_OW" className="font-bold">
                Lead Owner <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={selectedAbAdNo}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openLeadOwnerPopup}
                ></i>
                )}
              </div>
              <div style={{display:"none"}}>
                <InputText
                  id="so_LD_OW"
                  name="so_LD_OW"
                  value={so_LD_OW}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_CO_PE" className="font-bold">
                Contact <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="cc_CN_NM"
                  name="cc_CN_NM"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={selectedAbAdNc}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openContactOwnerPopup}
                ></i>
                )}
              </div>
              <div  style={{display:"none"}} >
                <InputText
                  id="so_CO_PE"
                  name="so_CO_PE"
                  defaultValue={so_CO_PE}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_IN_FL" className="font-bold">
                Influencer <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="cc_EM_NM"
                  name="cc_EM_NM"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={selectedAbAdNM}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openTnfluencerPopup}
                ></i>
                )}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="so_IN_FL"
                  name="so_IN_FL"
                  value={so_IN_FL}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_OP_PA" className="font-bold">
                Partner <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="ab_AD_NP"
                  name="ab_AD_NP"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}                
                  value={selectedAbAdNp}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openPartnerPopup}
                ></i>
                )}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="so_OP_PA"
                  name="so_OP_PA"
                  value={so_OP_PA}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>      
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_LO_RE" className="font-bold">
              Reason
              </label>
              <Dropdown
              id="so_LO_RE"
              name="so_LO_RE"
              value={so_LO_RE}
              options={so_LO_REOptions}
              onChange={(e) => onchangeDropDownFormData1("so_LO_RE", e.value)}
              placeholder="Select Reason"
            />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_OP_ST" className="font-bold">
              Status
              </label>
              <Dropdown
              id="so_OP_ST"
              name="so_OP_ST"
              value={so_OP_ST}
              options={so_OP_STOptions}
              onChange={(e) => onchangeDropDownFormData2("so_OP_ST", e.value)}
              placeholder="Select Status"
            />
            </div>
          </div>
        </div>
      </div>

      {/* Customer */}
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypeCust}
        setFormData={setFormData}
      />
      {/* Lead Owner */}
      <AddressBookPopup
        visible={LeadOwnerPopupVisible}
        onClose={() => setLeadOwnerVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
        addressType={addressTypeLDOW}
        setFormData={setFormData}
      />
      {/* Influncer */}
      <AddressBookPopup
        visible={InfluencerPopupVisible}
        onClose={() => setInfluencerPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={addressTypeINFL}
        setFormData={setFormData}
      />
      {/* Partner */}
      <AddressBookPopup
        visible={PartnerPopupVisible}
        onClose={() => setPartnerPopupVisible(false)}
        onDataSelect={handleDataSelect3}
        formData={data}
        addressType={addressTypePtnr}
        setFormData={setFormData}
      />
      <ContactOwnerPopup
        visible={ContactOwnerPopupVisible}
        onClose={() => setContactOwnerPopupVisible(false)}
        onDataSelect={handleDataSelect4}
        formData={data}
      />
       <LeadIDPopup
        visible={LeadIDPopupVisible}
        onClose={() => setLeadIDPopupVisible(false)}
        onDataSelect={handleDataSelect5}
        formData={data}
      />
    </Dialog>
  );
}
