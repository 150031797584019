import React, { useState, useEffect,useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import SecondPopup from "views/admin/Popups/AddressBookPopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import axios from "axios";
import { Toast } from "primereact/toast";
import '../../../components/ListStyle/ListStyle.css';
import ButtonStyles from "views/common/ButtonStyles";
export default function CrmContactDialog({
  open,
  setOpen,  
  data,
  FormData,
  initialValue,
  cc_CN_TY,
  ccCnNo,
  setcc_CN_NO,
  cc_LD_ST,
  cc_DE_GN,
  setcc_CN_TY,
  setcc_DE_GN,
  setcc_LD_ST,
  selectedAbAdNM,
  selectedAbAdNN,
  setSelectedAbAdNM,
  setSelectedAbAdNN,
  handleDefault,
  onchangeDropDownFormData2,
  setFormData,
  addStatus,
}) {
  const {
    cc_UK_NO,
    cc_CN_NM,
    cc_PH_NO,
    cc_EM_IL,
    cc_AD_NO,
    cc_EM_NO,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [secondPopupVisible, setSecondPopupVisible] = useState(false);
  const [ContactOwnerPopupVisible, setContactOwnerPopupVisible] =useState(false);
  const [addressType, setaddressType] = useState("E");
  const [addressTypeC, setaddressTypeC] = useState("C");
  const [cc_DE_GNOptions, setCcTitleOptions] = useState([]);
  const [cc_CN_TYOptions, setcc_CN_TYOptions] = useState([]);
  const [cc_LD_STOptions, setcc_LD_STOptions] = useState([]);
  const toast = useRef(null);
  const handleClose = () => {
    setFormData(initialValue);
    setcc_CN_NO(null);
    setOpen(false);
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const url = `${domain}/api/ClassCode/All`;

  const parameter = "CC/TY";
  const finalUrl = `${url}/${parameter}`;

  const parameter1 = "TI/TL";
  const finalUrl1 = `${url}/${parameter1}`;

  const parameter2 = "EM/ST";
  const finalUrl2 = `${url}/${parameter2}`;

  useEffect(() => {
    fetchDropdownOptions();
    fetchDropdownOptions1();
    fetchDropdownOptions2();
  }, []);

  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setcc_CN_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setCcTitleOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setcc_LD_STOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeDropDownFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_CN_TY(value);
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_DE_GN(value);
  };
  const urlNextNumber = `${domain}/api/BaseNumber/All`;
  const parameters = `CO/${company}`;
  const finallink = `${urlNextNumber}/${parameters}`;

  var requestBodyInput = {};
  function values() {
    requestBodyInput = {
      "cc_UK_NO" : document.getElementById("cc_UK_NO").value,
      "cc_CO_NO"   : company,
      "ccCnNo" : document.getElementById("ccCnNo").value,
      "cc_CN_NM" : document.getElementById("cc_CN_NM").value,
      "cc_DE_GN" : cc_DE_GN,
      "cc_PH_NO" : document.getElementById("cc_PH_NO").value,
      "cc_EM_IL" : document.getElementById("cc_EM_IL").value,
      "cc_AD_NO" : document.getElementById("cc_AD_NO").value,
      "cc_CN_TY" : cc_CN_TY,
      "cc_EM_NO" : FormData.cc_EM_NO,
      "cc_LD_ST" : cc_LD_ST
    }
  };
  const handleFormSubmit = () => {
    values();
    const cnNO = document.getElementById("ccCnNo");
    if (cnNO.value) {
      const ukNO = document.getElementById("cc_UK_NO");
      if (ukNO.value) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/Contact/Update`;
        if (confirm) {
          axios
            .put(update, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        axios.post(`${domain}/api/Contact/Add`, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    } else {
      axios.get(finallink, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          setcc_CN_NO(resp.bn_BA_NO);
          document.getElementById("ccCnNo").value = resp.data.bn_BA_NO;
          if(resp.data.bn_BA_NO ===undefined)
          {
            handleResponse(resp.data);
          }else{
            values();
            axios.post(`${domain}/api/Contact/Add`, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error adding data:", error);
            });
          }
         
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };  
  //   const history = useHistory();
  //   const handleAddButtonClick = () => {
  //     history.push("/your-desired-path");
  //   };
  // const rightToolbarTemplate = (
  //   <React.Fragment>
  //    <Tooltip target=".add-button" position="bottom" mouseTrack>
  //       <div>Add</div>
  //     </Tooltip>
  //     <Button
  //       icon="pi pi-plus"
  //       link
  //       style={{ color: "darkcyan", transition: "color 0.3s", backgroundColor: "transparent" }}
  //       className="mr-2 add-button"
  //       onClick={handleAddButtonClick}
  //       onMouseEnter={(e) => { e.target.style.color = '#0e7e5c'; e.target.style.backgroundColor = '#C9E7D9'; }}
  //       onMouseLeave={(e) => { e.target.style.color = 'darkcyan'; e.target.style.backgroundColor = 'transparent'; }}
  //     ></Button>
  //   </React.Fragment>
  // );
  
  const handleDataSelect = (data) => {
    setSelectedAbAdNN(data.ab_AD_NM);
    onchangeFormData("cc_AD_NO", data.abAdNo);
    setSecondPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect1 = (data) => {
    setSelectedAbAdNM(data.ab_AD_NM);
    onchangeFormData("cc_EM_NO", data.abAdNo);
    setContactOwnerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openSecondPopup = () => {
    setSecondPopupVisible(true);
  };
  const openContactOwnerPopup = () => {
    setContactOwnerPopupVisible(true);
  };
  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const isUpdateMode = Boolean(cc_UK_NO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{cc_UK_NO ? "Revise Contact" : "Add Contact"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
    <Toast ref={toast} />
      <div style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="cc_CN_NM" className="font-bold">
                Contact Name
              </label>
              <InputText
                id="cc_CN_NM"
                name="cc_CN_NM"
                value={cc_CN_NM}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
              <label htmlFor="cc_UK_NO" className="font-bold">
                UK_NO
              </label>
              <InputText
                id="cc_UK_NO"
                name="cc_UK_NO"
                defaultValue={cc_UK_NO}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="cc_DE_GN" className="font-bold">
                Title
              </label>
              <Dropdown
                id="cc_DE_GN"
                name="cc_DE_GN"
                value={cc_DE_GN}
                options={cc_DE_GNOptions}
                onChange={(e) => onchangeDropDownFormData1("cc_DE_GN", e.value)}
                placeholder="Select Title"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="cc_PH_NO" className="font-bold">
                Phone
              </label>
              <InputText
                id="cc_PH_NO"
                name="cc_PH_NO"
                value={cc_PH_NO}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="cc_EM_IL" className="font-bold">
                Email
              </label>
              <InputText
                id="cc_EM_IL"
                name="cc_EM_IL"
                value={cc_EM_IL}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cc_AD_NO" className="font-bold">
              Customer <span style={{ color: 'red' }}>*</span>
            </label>
            <div id="ab_AD_NM_icon" style={{ display: 'flex' }}>
                <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  onChange={(event) => onchangedata(event)}
                  onFocus={() => setShowSearchIcon(true)}
                  // onBlur={() => setShowSearchIcon(false)}
                  value={selectedAbAdNN}
                />
                {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: 'var(--primary-color)',
                      fontSize: '119%',
                      marginTop: '4%',
                      cursor: 'pointer',
                    }}
                    onClick={openSecondPopup}
                  ></i>
                )}
              </div>
              <div style={{ display: 'none' }}>
                <InputText
                  id="cc_AD_NO"
                  name="cc_AD_NO"
                  value={cc_AD_NO}
                  onChange={(event) => onchangedata(event)}
                />
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="cc_CN_TY" className="font-bold">
                Type
              </label>
              <Dropdown
                id="cc_CN_TY"
                name="cc_CN_TY"
                value={cc_CN_TY}
                options={cc_CN_TYOptions}
                onChange={(e) => onchangeDropDownFormData("cc_CN_TY", e.value)}
                placeholder="Select Type"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="cc_EM_NO" className="font-bold">
                Contact Owner <span style={{ color: 'red' }}>*</span>
              </label>
              <div id="cc_EM_NM_icon" style={{ display: "flex" }}>
                <InputText
                  id="cc_EM_NM"
                  name="cc_EM_NM"
                  onChange={(event) => onchangedata(event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={selectedAbAdNM}
                />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openContactOwnerPopup}
                  ></i>
                  )}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="cc_EM_NO"
                  name="cc_EM_NO"
                  defaultValue={cc_EM_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="cc_LD_ST" className="font-bold">
                Status
              </label>
              <Dropdown
                id="cc_LD_ST"
                name="cc_LD_ST"
                value={cc_LD_ST}
                options={cc_LD_STOptions}
                onChange={(e) => onchangeDropDownFormData2("cc_LD_ST", e.value)}
                placeholder="Select Status"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
              <label htmlFor="ccCnNo" className="font-bold">
                Contact Id
              </label>
              <InputText
                id="ccCnNo"
                name="ccCnNo"
                value={ccCnNo}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
          </div>
        </div>
      </div>
      <SecondPopup
        visible={secondPopupVisible}
        onClose={() => setSecondPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypeC}
		    addStatus={addStatus}
        setFormData={setFormData}
      />
      <AddressBookPopup
        visible={ContactOwnerPopupVisible}
        onClose={() => setContactOwnerPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={addressType}
		    addStatus={addStatus}
        setFormData={setFormData}
      />
    </Dialog>
  );
}
