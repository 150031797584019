import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import AccountNoPopup from "views/admin/Popups/AccountNoPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function SetOffTaxDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onChangeData,
  onchangeFormData,
  handleFormSubmit,
  Inspectiondetailstable,
  setInspectiondetailstable,
  SelectedemEMNOA,
  setSelectedemEMNOA,
  th_TR_DT,
  setth_TR_DT,
  th_CR_CD,
  setth_CR_CD,
  onchangeReceivedate,
}) {
  const {
    thUkNo,
    account,
    th_TR_ID,
    output_TAX,
    input_TAX,
    th_TX_PD,
    th_RM_RK,
    inputTax,
    outputTax,
    inputInvoice,
    outputInvoice,
     netAmount,
  } = data;

  const taxData = [
    { area: 'Abu Dhabi', outputInvoice: '', outputTax: '', inputInvoice: '', inputTax: '',netAmount:'' },
    { area: 'Dubai', outputInvoice: '', outputTax: '', inputInvoice: '', inputTax: '' ,netAmount:''},
    { area: 'Sharjah', outputInvoice: '', outputTax: '', inputInvoice: '', inputTax: '',netAmount:'' },
    { area: 'Ajman', outputInvoice: '', outputTax: '', inputInvoice: '', inputTax: '' ,netAmount:''},
    { area: 'Umm Al Quwain', outputInvoice: '', outputTax: '', inputInvoice: '', inputTax: '',netAmount:'' },
    { area: 'Ras Al Khaimah', outputInvoice: '', outputTax: '', inputInvoice: '', inputTax: '' ,netAmount:''},
    { area: 'Fujairah', outputInvoice: '', outputTax: '', inputInvoice: '', inputTax: '' ,netAmount:''},
  ];

  const { plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle } = ButtonStyles();
  // Dropdown
  const { fetchClassCodeDS  } = ClassCode();
  const [th_CR_CDOptions, setth_CR_CDOptions] = useState([]);

  //Currency
  const onchangeDropDownFormDataCurrency = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setth_CR_CD(value);
  };
  useEffect(() => {
    Currency();
  }, []);

  const Currency = async () => {
    const Options = await fetchClassCodeDS("FO/CR");
    setth_CR_CDOptions(Options);
  };

  const [bankFilter, setbankFilter] = useState("BA");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // Advance Search
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const [showSearchIcon, setShowSearchIcon] = useState("");

  const [selectedRcDate, setSelectedRcDate] = useState(moment().toDate());
  const handleRCDateChange = (e) => {
    setSelectedRcDate(e.value);
    onchangedata({ target: { name: "th_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };

  const handleDataSelect1 = async (data) => {
    setSelectedemEMNOA(data.am_AC_DS);
    onchangeFormData("account", data.account);
    changeaccount(data);
    setAccountPopupVisible(false);
  };

  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const openAccountPopup = (e) => {
    setAccountPopupVisible(true);
    setSelectedRowIndex(e);
  };

  // Account Advance Search
  const accapi = `${domain}/api/JournalLedger/GetSummaryFromGABL`
  const changeaccount = async (data) => {
    const request = {
      "account": data.account,
      "gl_DATE": th_TR_DT
    }
    try {
      const response = await axios.post(accapi, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      const dataacc = response.data.Result;
      if (dataacc) {
        onchangeFormData("rh_AC_BL", dataacc.gb_BG_BL);
      }
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const addRow = () => {
    setInspectiondetailstable([...Inspectiondetailstable, {}]);
  };
  const deleteRow = () => {
    if (Inspectiondetailstable.length > 0) {
      const updatedData = [...Inspectiondetailstable];
      updatedData.pop();
      setInspectiondetailstable(updatedData);
    }
  };
  // confirm
  const handleConfirm = async () => {
    const Url = `${domain}/api/SetOffTax/ConfirmDetailsSetOffTax`;
    const request = {
      id_TR_NO: document.getElementById("id_TR_NO").value,
      id_TR_TY: document.getElementById("id_TR_TY").value,
    }
    try {
      const response = await axios.post(Url, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // generate
  const handleGenerate = async () => {
    const Url = `${domain}/api/SetOffTax/GenerateWODetails`;
    const request = {
      id_TR_NO: document.getElementById("id_TR_NO").value,
      id_TR_TY: document.getElementById("id_TR_TY").value,
    }
    try {
      const response = await axios.post(Url, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // footer values
  const handlefooter = async () => {
    const parseValue = (value) => {
      return value !== undefined && value !== null && !isNaN(value) ? parseFloat(value) : 0;
    };

    const getElementValue = (id) => {
      const element = document.getElementById(id);
      return element ? parseValue(element.value) : 0;
    };

    const from_Date = getElementValue("from_Date");
    const to_Date = getElementValue("to_Date");

    const Url = `${domain}/api/SetOffTax/GetTaxBreakUp`;
    const request = {
      "inspectionDetails": [
        {
          "td_IN_DT": from_Date,
          "to_Date": to_Date
        }
      ]
    };
    try {
      const response = await axios.post(Url, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataFT = response.data.Result;
      if (dataFT) {
        document.getElementById("material_COST").value = dataFT.material_COST;
        document.getElementById("labour_COST").value = dataFT.labour_COST;
        document.getElementById("discount").value = dataFT.discount;
        document.getElementById("total_COST").value = dataFT.netAmount; //total_COST
        document.getElementById("tax_AMOUNT").value = dataFT.tax_AMOUNT;
        document.getElementById("total").value = dataFT.total;
      } else {
        console.error("Invalid API response:", dataFT);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const isUpdateMode = Boolean(thUkNo);
  const productDialogFooter = (
    <React.Fragment>
      {/* confirm */}
      <Button
        style={{
          width: "5%",
          backgroundColor: "transparent",
          color: "blue",
          marginLeft: "60%", height: "45px",
          display: isUpdateMode ? 'block' : 'none'
        }}
        icon="pi pi-check-circle"
        tooltip="Confirm"
        onClick={handleConfirm}
      >
      </Button>
      {/*  Set Off */}
      <Button
        style={{ width: "5%", backgroundColor: "transparent", color: "blue" }}
        icon="pi pi-book"
        variant="outlined"
        onClick={handleGenerate}
        tooltip="Set Off"
      ></Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
        tooltip={isUpdateMode ? "Update" : "Submit"}>
      </Button>
    </React.Fragment>
  );
  // date default
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "id_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  return (
    <Dialog 
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={thUkNo ? "Revise Vat Set Off" : "Add Vat Set Off"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div className="formgrid grid">
          <div style={{ display: "none" }}>
            <label htmlFor="thUkNo" className="font-bold">
              UK_no
            </label>
            <InputText
              id="thUkNo"
              name="thUkNo"
              value={thUkNo}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="th_TR_ID" className="font-bold">
              Transaction ID
            </label>
            <InputText disabled
              id="th_TR_ID"
              name="th_TR_ID"
              value={th_TR_ID}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="TD_TR_DT" className="font-bold">
              Receipt Date
            </label>
            <Calendar
              id="TD_TR_DT"
              name="TD_TR_DT"
              // value={moment(rh_RC_DT, "YYYYMMDD").toDate()}
              value={moment(data.TD_TR_DT, "YYYYMMDD").isValid() ? moment(data.TD_TR_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => {
                handleRCDateChange(e);
                onchangeReceivedate({ target: { name: "TD_TR_DT", value: moment(e.value).format("YYYYMMDD") } })
              }}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="th_CR_CD" className="font-bold">
              Currency
            </label>
            <Dropdown
              name="th_CR_CD"
              value={th_CR_CD}
              options={th_CR_CDOptions}
              onChange={(e) => onchangeDropDownFormDataCurrency("th_CR_CD", e.value)}
              placeholder="Currency"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="from_Date" className="font-bold">
              From Date
            </label>
            <Calendar
              id="from_Date"
              name="from_Date"
              value={moment(data.from_Date, "YYYYMMDD").isValid() ? moment(data.from_Date, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: { name: "from_Date", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
              dateFormat="dd/mm/yy"
            />
          </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="output_TAX" className="font-bold">
              Total Output Tax
            </label>
            <InputText disabled
              id="output_TAX"
              name="output_TAX"
              value={output_TAX}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="to_Date" className="font-bold">
              TO Date
            </label>
            <Calendar
              id="to_Date"
              name="to_Date"
              value={moment(data.to_Date, "YYYYMMDD").isValid() ? moment(data.to_Date, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: { name: "to_Date", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="input_TAX" className="font-bold">
              Total Input Tax
            </label>
            <InputText disabled
              id="input_TAX"
              name="input_TAX"
              value={input_TAX}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="th_TX_PD" className="font-bold">
              Net Tax
            </label>
            <InputText disabled
              id="th_TX_PD"
              name="th_TX_PD"
              value={th_TX_PD}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="th_TX_PD" className="font-bold">
              Amount To Pay
            </label>
            <InputText
              id="th_TX_PD"
              name="th_TX_PD"
              value={th_TX_PD}
              onChange={(e) => onchangedata(e)}
            />
          </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="account" className="font-bold">
              Tax Control Account <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText disabled
                id="accountDes"
                name="accountDes"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNOA}
              />
              {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openAccountPopup}
                ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="account"
                name="account"
                value={account}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openAccountPopup}
              ></i>
            </div>
          </div>
          <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
            <label htmlFor="th_RM_RK" className="font-bold">
              Remark
            </label>
            <InputText
              id="th_RM_RK"
              name="th_RM_RK"
              value={th_RM_RK}
              onChange={(e) => onchangedata(e)}
            />
          </div>

        </div>
      </div>
      <div style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
            <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
          )}
          {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div style={multitablestyle}>
          <table className="custom-table" id="SetOffTaxTable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Invoice No</th>
                <th>Type</th>
                <th>Company</th>
                <th>Invoice Date</th>
                <th>Tax Amount</th>
                <th>Currency</th>
                <th>Status</th>
                <th>Address No</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {Inspectiondetailstable.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`idUkNo_${index}`}
                      name={`idUkNo_${index}`}
                      value={row.thUkNo}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`td_DO_NO_${index}`}
                      name={`td_DO_NO_${index}`}
                      defaultValue={row.td_DO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`td_DO_TY_${index}`}
                      name={`td_DO_TY_${index}`}
                      defaultValue={row.td_DO_TY}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`td_DO_CO_${index}`}
                      name={`td_DO_CO_${index}`}
                      defaultValue={row.td_DO_CO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`td_IN_DT_${index}`}
                      name={`td_IN_DT_${index}`}
                      defaultValue={row.td_IN_DT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`td_TX_AM_${index}`}
                      name={`td_TX_AM_${index}`}
                      defaultValue={row.td_TX_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`td_CR_CD_${index}`}
                      name={`td_CR_CD_${index}`}
                      defaultValue={row.td_CR_CD}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`td_TX_ST_${index}`}
                      name={`td_TX_ST_${index}`}
                      defaultValue={row.td_TX_ST}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`td_AD_NO_${index}`}
                      name={`td_AD_NO_${index}`}
                      defaultValue={row.td_AD_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`cus_DESC_${index}`}
                      name={`cus_DESC_${index}`}
                      defaultValue={row.cus_DESC}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* footer */}
      <div
        style={{
          marginTop: "2%",
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div className="formgrid grid">

          <table border="1" cellPadding="10" style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th>Tax Area</th>
                <th>Output Tax Invoice Amount</th>
                <th>Output Tax Amount</th>
                <th>Input Tax Invoice Amount</th>
                <th>Input Tax Amount</th>
                <th>Net Amount</th>
              </tr>
            </thead>
            <tbody>
              {taxData.map((tax, index) => (
                <tr key={index}>
                  <td>{tax.area} </td>
                  <td>{tax.outputInvoice}
                  <InputText  
                      id="outputInvoice"
                      name="outputInvoice"
                      value={outputInvoice}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>{tax.outputTax}
                  <InputText  
                      id="outputTax"
                      name="outputTax"
                      value={outputTax}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>{tax.inputInvoice}
                  <InputText  
                      id="inputInvoice"
                      name="inputInvoice"
                      value={inputInvoice}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>{tax.inputTax}
                  <InputText  
                      id="inputTax"
                      name="inputTax"
                      value={inputTax}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>{tax.netAmount}
                  <InputText  
                      id="netAmount"
                      name="netAmount"
                      value={netAmount}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        bankFilter={bankFilter}
      />
    </Dialog>
  );
}
