import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import WorkCentrePopup from "../Popups/WorkCentrePopup";
import ButtonStyles from "views/common/ButtonStyles";
import ClassCode from "views/common/ClassCode";
import  Costcenter  from "views/common/CostCentreDrpDwn";
export default function CoByProductsDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  CoByproducttable,
  setCoByproducttable,
  cb_CO_CT,
  setcb_CO_CT,
  cb_BT_UM,
  setcb_BT_UM,
  cb_BL_TY,
  setcb_BL_TY,  
  cb_CO_BY,
  setcb_CO_BY,
  cb_CO_UM,
  setcb_CO_UM,
  cb_ST_TY,
  setcb_ST_TY,
  cb_CO_NO,
}) {
  const {
    cbUkNo,
    cb_IT_NM,
    cb_CO_NM, 
    cb_BT_QT, 
    cb_CO_QT,
    cb_LN_NO,
    cb_OP_SQ,
    cb_UN_CT,
    cb_FR_DT,
    cb_TO_DT,
  } = data;
  const {borderbox} = ButtonStyles();
  const addRow = () => {
    setCoByproducttable([...CoByproducttable, {}]);
  };
  
  const deleteRow = () => {
    if (CoByproducttable.length > 0) {
      const updatedData = [...CoByproducttable];
      updatedData.pop();
      setCoByproducttable(updatedData);
    }
  };

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter, } = Costcenter();

  const [cb_CO_CTOptions, setcb_CO_CTOptions] = useState([]);
  const [cb_BT_UMOptions, setcb_BT_UMOptions] = useState([]);
  const [cb_BL_TYOptions, setcb_BL_TYOptions] = useState([]);
  const [cb_CO_BYOptions, setcb_CO_BYOptions] = useState([]);
  const [cb_CO_UMOptions, setcb_CO_UMOptions] = useState([]);
  const [cb_ST_TYOptions, setcb_ST_TYOptions] = useState([]);


  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcb_CO_CT(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcb_BT_UM(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcb_BL_TY(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcb_CO_BY(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcb_CO_UM(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcb_ST_TY(value);
  };

  useEffect(() => {
    fetchCost();
    fetchUM();
    fetchType();
    fetchCOby();
    fetchUMdtl();
    fetchStockType();
  }, []);

  const fetchCost = async () => {
    const Options = await fetchCostCenter();
    setcb_CO_CTOptions(Options);
  };

  const fetchUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setcb_BT_UMOptions(Options);
  };

  const fetchType = async () => {
    const Options = await fetchClassCodeDS("MF/TY");
    setcb_BL_TYOptions(Options);
  };

  const fetchCOby = async () => {
    const Options = await fetchClassCodeDS("MF/CB");
    setcb_CO_BYOptions(Options);
  };

  const fetchUMdtl = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setcb_CO_UMOptions(Options);
  };

  const fetchStockType = async () => {
    const Options = await fetchClassCodeDS("IN/TY");
    setcb_ST_TYOptions(Options);
  };


const isUpdateMode = Boolean(cbUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  const [workcentreVisible, setworkcentreVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleDataSelect = (content) => {
    document.getElementById (`os_WK_CT_${SelectedRowIndex}`).value =  content.wc_WK_CT;
    // onchangeFormData("os_WK_CT", content.wc_WK_CT,SelectedRowIndex);
    setworkcentreVisible(false);
    // setShowSearchIcon(false);
  };
  const openWorkcentrePopup = (e) => {
    setworkcentreVisible(true);
    setSelectedRowIndex(e);
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={cbUkNo ? "Revise Co By Products" : "Add Co By Products"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >

        <div style={borderbox} >
            <div
                style={{
                  marginTop: "2%",
                  border: "1px solid #e8e8e8",
                  padding: "16px",
                  borderRadius: "15px",
                  backgroundColor: "aliceblue",
                  overflow: "auto",
                }}
              >
                <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                        <label htmlFor="cbUkNo" className="font-bold">
                   UK_NO
                        </label>
                        <InputText 
                          id="cbUkNo"
                          name="cbUkNo"
                          value={cbUkNo}
                        />
                      </div>
                    <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                        <label htmlFor="cb_IT_NM" className="font-bold">
                     Process
                        </label>
                        <InputText 
                          id="cb_IT_NM"
                          name="cb_IT_NM"
                          value={cb_IT_NM}
                          onChange={(e) => onchangedata(e)}
                        />
                      </div>
                      <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                        <label htmlFor="cb_CO_CT" className="font-bold">
                        Cost Centre
                        </label>
                        <Dropdown
                            name="cb_CO_CT"
                            value={cb_CO_CT}
                            options={cb_CO_CTOptions}
                            onChange={(e) => onchangeDropDownFormData1("cb_CO_CT", e.value)}
                            placeholder="Select Cost Centre"
                        />
                      </div>
                      <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                        <label htmlFor="cb_CO_NO" className="font-bold">
                          Company
                        </label>
                        <InputText 
                          id="cb_CO_NO"
                          name="cb_CO_NO"
                          value={cb_CO_NO}
                          onChange={(e) => onchangedata(e)}
                        />
                      </div>
                      <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                        <label htmlFor="cb_BT_QT" className="font-bold">
                        Batch Quantity
                        </label>
                        <InputText 
                          id="cb_BT_QT"
                          name="cb_BT_QT"
                          value={cb_BT_QT}
                          onChange={(e) => onchangedata(e)}
                        />
                      </div>
                      <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                        <label htmlFor="cb_BT_UM" className="font-bold">
                       UM
                        </label>
                        <Dropdown
                            name="cb_BT_UM"
                            value={cb_BT_UM}
                            options={cb_BT_UMOptions}
                            onChange={(e) => onchangeDropDownFormData2("cb_BT_UM", e.value)}
                            placeholder="Select UM"
                        />
                      </div>
                      <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                        <label htmlFor="cb_BL_TY" className="font-bold">
                       Type
                        </label>
                        <Dropdown
                          name="cb_BL_TY"
                          value={cb_BL_TY}
                          options={cb_BL_TYOptions}
                          onChange={(e) => onchangeDropDownFormData3("cb_BL_TY", e.value)}
                          placeholder="Select Type"
                        />
                      </div>




                </div>
            </div>
        </div> 






      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop:"2%",
        }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
            position: "relative",
          }}
        >
        <table className="custom-table" id="CoByproducttable">
          <thead>
            <tr>
              <th style={{ display: "block" }}>UK NO</th>
              <th> CoBy </th>
              <th> Process </th>
              <th> Description </th>
              <th> Quantity </th>
              <th> UM </th>
              <th> Line No </th>
              <th> Oper. Seq. </th>
              <th> Unit Cost </th>
              <th> Stocking Type </th>
              <th> From Date </th>
              <th> To Date </th>
            </tr>
          </thead>
          <tbody>
            {CoByproducttable.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "block" }}>
                  <InputText
                    id={`cbUkNo_${index}`}
                    name={`cbUkNo_${index}`}
                    value={row.cbUkNo}
                  />
                </td>
                <td>
                <Dropdown
                  id={`cb_CO_BY_${index}`}
                  name={`cb_CO_BY_${index}`}
                  value={data[`cb_CO_BY_${index}`] ? data[`cb_CO_BY_${index}`]:row.cb_CO_BY }
                  options={cb_CO_BYOptions}
                  onChange={(e) => onchangeDropDownFormData4(`cb_CO_BY_${index}`, e.value)}
                  placeholder="Select CoBy"
                />
                </td>
                <td>
                <InputText 
                style={{width:"120px"}}
                  id={`cb_CO_NM_${index}`}
                  name={`cb_CO_NM_${index}`}
                  value={row.cb_CO_NM}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText 
                style={{width:"150px"}}
                  id={`cb_CO_NM_${index}`}
                  name={`cb_CO_NM_${index}`}
                  value={row.cb_CO_NM}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
              <InputText 
              style={{width:"90px"}}
                id={`cb_CO_QT_${index}`}
                name={`cb_CO_QT_${index}`}
                value={row.cb_CO_QT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
                <td>
                <Dropdown
                  id={`cb_CO_UM_${index}`}
                  name={`cb_CO_UM_${index}`}
                  value={data[`cb_CO_UM_${index}`] ? data[`cb_CO_UM_${index}`]:row.cb_CO_UM }
                  options={cb_CO_UMOptions}
                  onChange={(e) => onchangeDropDownFormData5(`cb_CO_UM_${index}`, e.value)}
                  placeholder="Select UM"
                />
                </td>
                <td>
                    <InputText 
                    style={{width:"90px"}}
                      id={`cb_LN_NO_${index}`}
                      name={`cb_LN_NO_${index}`}
                      value={row.cb_LN_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText 
                    style={{width:"90px"}}
                      id={`cb_OP_SQ_${index}`}
                      name={`cb_OP_SQ_${index}`}
                      value={row.cb_OP_SQ}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText 
                    style={{width:"90px"}}
                      id={`cb_UN_CT_${index}`}
                      name={`cb_UN_CT_${index}`}
                      value={row.cb_UN_CT}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                <td>
                <Dropdown
                  id={`cb_ST_TY_${index}`}
                  name={`cb_ST_TY_${index}`}
                  value={data[`cb_ST_TY_${index}`] ? data[`cb_ST_TY_${index}`]:row.cb_ST_TY }
                  options={cb_ST_TYOptions}
                  onChange={(e) => onchangeDropDownFormData6(`cb_ST_TY_${index}`, e.value)}
                  placeholder="Select Stocking Type"
                />
                </td>
                <td>
                  <Calendar style={{width:"150px"}}
                    id={`cb_FR_DT_${index}`}
                    name={`cb_FR_DT_${index}`}
                    value={ row.cb_FR_DT ? moment(row.cb_FR_DT, "YYYYMMDD").toDate() : null }
                    onChange={(e) => onDateChange({ target: { name: "cb_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    showIcon
                    dateFormat="dd/mm/yy"
                  />
                </td>
                <td>
                <Calendar style={{width:"150px"}}
                  id={`cb_TO_DT_${index}`}
                  name={`cb_TO_DT_${index}`}
                  value={ row.cb_TO_DT ? moment(row.cb_TO_DT, "YYYYMMDD").toDate() : null }
                  onChange={(e) => onDateChange({ target: { name: "cb_TO_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  showIcon
                  dateFormat="dd/mm/yy"
                />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <WorkCentrePopup
        visible={workcentreVisible}
        onClose={() => setworkcentreVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
    </Dialog>
  );
}
