import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
export default function LeaseInformationDialog({
  visible,
  onClose,
}) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    onClose();
    setOpen(false);
  };
  useEffect(() => {
    setOpen(visible);
   
  },);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      modal
      className="p-fluid"
    >
      <div style={{ border: "1px solid #e8e8e8", padding: "16px", borderRadius: "15px", backgroundColor: "aliceblue", }}>
      <div className="formgrid grid">
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="li_LE_NO" className="font-bold">
          Unit
          </label>
          <InputText
            id="li_LE_NO"
            name="li_LE_NO"
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="li_LE_TY" className="font-bold">
            Floor
            </label>
            <InputText disabled
              id="li_LE_TY"
              name="li_LE_TY"
            />
        </div> 
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
        <label htmlFor="li_LE_CO" className="font-bold">
        Building
        </label>
        <InputText disabled
          id="li_LE_CO"
          name="li_LE_CO"
        />
        </div>   
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
        <label htmlFor="li_LE_CO" className="font-bold">
        Property
        </label>
        <InputText disabled
          id="li_LE_CO"
          name="li_LE_CO"
        />
        </div>  
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
        <label htmlFor="li_LE_CO" className="font-bold">
        Tenant name
        </label>
        <InputText disabled
          id="li_LE_CO"
          name="li_LE_CO"
        />
        </div>   
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
        <label htmlFor="li_ST_DT" className="font-bold">
        Start Date
        </label>
        <Calendar
            id="li_ST_DT"
            name="li_ST_DT"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
      <label htmlFor="li_EN_DT" className="font-bold">
      End Date  
      </label>
      <Calendar
          id="li_EN_DT"
          showIcon
          dateFormat="dd/mm/yy"
        />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="li_LE_TM" className="font-bold">
          Area Type
          </label>
          <InputText disabled
            id="li_LE_CO"
            name="li_LE_CO"
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
        <label htmlFor="li_LE_TM" className="font-bold">
        Area
        </label>
        <InputText disabled
          id="li_LE_CO"
          name="li_LE_CO"
        />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
        <label htmlFor="li_LE_TM" className="font-bold">
        UM
        </label>
        <InputText disabled
          id="li_LE_CO"
          name="li_LE_CO"
        />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
        <label htmlFor="li_LE_TM" className="font-bold">
        Annual Amount
        </label>
        <InputText disabled
          id="li_LE_CO"
          name="li_LE_CO"
        />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
        <label htmlFor="li_LE_TM" className="font-bold">
        Monthly Amount
        </label>
        <InputText disabled
          id="li_LE_CO"
          name="li_LE_CO"
        />
        </div>
      </div>
    </div>
 
    </Dialog>
  );
}
