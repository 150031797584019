import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
const initialValue = {
  cd_CO_DS: "",
  cd_CO_NO: "",
  cd_CO_CD : "",
  cd_CO_TY:"",
  riUkNo:"",
  cd_CO_SC:"",

};

const initialFilters = {
  cd_CO_NO: { value: "", operation: "" },
  cd_CO_CD: { value: "", operation: "" },
  cd_CO_DS: { value: "", operation: "" },
  cd_CO_TY: { value: "", operation: "" },
  cd_CO_SC: { value: "", operation: "" },

};
const BankRecoAutoMain = () => {
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [cd_CO_TY, setcd_CO_TY] = useState("");
  const [cd_CO_SC, setcd_CO_SC] = useState([]);


  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleReconcile = async () => {
    const Url = `${domain}/api/BankReconAuto/ReconcileStatement`;
    const request = {
    };
    try {
      const response = await axios.post(Url, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const data = response.data;
      console.log("Generated successfully:", data);
    } catch (error) {
      console.error("Error generating data:", error);
    }
  };
  const handleRefresh = async () => {
    const Url = `${domain}/api/BankReconAuto/RefreshReconciliation`;
    const request = {
    };
    try {
      const response = await axios.post(Url, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const data = response.data;
      console.log("Generated successfully:", data);
    } catch (error) {
      console.error("Error generating data:", error);
    }
  };
  const upload = `${domain}/api/FileUpload/ImportLead`;
  const UploadExcel = (Event) => {
    const file = Event.target.files[0];
  
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
  
      if (fileExtension === "csv" || fileExtension === "xlsx") {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', '12');
  
        try {
          fetch(upload, {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
            .then((resp) => resp.json())
            .then((resp) => {
              toast.current.show({
                severity: "success",
                summary: "Upload Successful",
              });
              handleSearch();
            });
  
        } catch (error) {
          console.error("Error parsing imported data:", error);
          toast.current.show({
            severity: "error",
            summary: "Import Error",
            detail:
              "Invalid data format. Please ensure the imported file is in the correct format.",
          });
        }
  
  
      } else {
        toast.current.show({
          severity: "error",
          summary: "Unsupported File Type",
          detail: "Please select a CSV or XLSX file for Upload.",
        });
      }
    }
  };
  useEffect(() => {
    handleSearch();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/BankRecoAuto/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const buttonStyles = {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "#03a9f485",
    border: "none", 
    marginTop:"13%",
    width:"50%",
  };

  const buttonStyles1 = {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "#03a9f485",
    border: "none", 
    marginTop:"13%",
    width:"50%",
    marginleft:"5%"
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
      <Tooltip target=".refresh-button" position="bottom" mouseTrack >
      <div style={{ background: "red", color: "white", fontSize: "10px" }}>
        Refresh
      </div>
    </Tooltip>
    <Button
      style={buttonStyles}
      icon="pi pi-refresh"
      // outlined
      // className="mr-2 refresh-button"
      type="button"
      label="Refresh"
      onClick={handleRefresh}
    ></Button>
    <Tooltip target=".process-button" position="bottom" mouseTrack >
      <div style={{ background: "red", color: "white", fontSize: "10px" }}>
        Reconcile
      </div>
    </Tooltip>
    <Button
      style={buttonStyles1}
      icon="pi pi-cog"
      // outlined
      // className="mr-2 process-button"
      type="button"
      label="Reconcile"
      onClick={handleReconcile}
    ></Button>
       
      <Button
        style={buttonStyles1}
        type="button"
        icon="pi pi-upload"
        label="Upload"
        // className="p-button-upload"
        // tooltip="Upload"
        // rounded
        onClick={() => {
          const input = document.getElementById("fileInput");
          input.click();
        }}
        data-pr-tooltip="Upload"
      />
      <input
        id="fileInput"
        type="file"
        accept=".csv, .xlsx"
        onChange={UploadExcel}
        style={{ display: "none" }}
      />
      
    </React.Fragment>
  ); 

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
      </div>

      
    </div>
  );
};

export default BankRecoAutoMain;
