import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ContractBillingDetailsDialog from "./ContractBillingDetailsDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  cbUkNo:"",
  cb_CN_NO:"",
  cb_CN_TY:"",
  cb_CN_CO:"",
  cb_PR_NO:"",
  cb_PR_TY:"",
  proj_TYPE:"",
  cb_CU_NO:"",
  cb_BL_NM:"",
  cb_BL_TY:"",
  cb_BL_AM:"",
  cb_BL_ED:"",
  cb_BL_CE:"",
  cb_ST_CD:"",
  status:"",
};

const initialFilters = {
  cb_CN_NO: { value: "", operation: "" },
  cb_CN_TY: { value: "", operation: "" },
  cb_CN_CO: { value: "", operation: "" },
  cb_PR_NO: { value: "", operation: "" },
  cb_PR_TY: { value: "", operation: "" },
  proj_TYPE: { value: "", operation: "" },
  cb_CU_NO: { value: "", operation: "" },
  cb_BL_NM: { value: "", operation: "" },
  cb_BL_TY: { value: "", operation: "" },
  cb_BL_AM: { value: "", operation: "" },
  cb_BL_ED: { value: "", operation: "" },
  cb_BL_CE: { value: "", operation: "" },
  cb_ST_CD: { value: "", operation: "" },
  status: { value: "", operation: "" },
};

const WorkCentreMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,tableHeaderStyleH,
    inputStyleH,tableCellStyleH,
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  const [cb_PR_TY, setcb_PR_TY] = useState([]);
  const [cb_ST_CD, setcb_ST_CD] = useState([]);
  const [cb_CR_CD, setcb_CR_CD ] = useState("");
  const [cb_EX_RT, setcb_EX_RT] = useState("");
  const [cb_BL_TY, setcb_BL_TY] = useState("");
  const [cb_CN_TY, setcb_CN_TY] = useState("");
  const [cb_CN_CO, setcb_CN_CO] = useState("");
  const [cb_BS_CR, setcb_BS_CR] = useState("");
  const [cb_CN_NO, setcb_CN_NO] = useState("");
  const [cb_BL_NM, setcb_BL_NM] = useState("");
  const [itemDES, setitemDES] = useState("");
// AdvanceSearch
const [SelectedemEMNO, setSelectedemEMNO ] = useState("");
const [SelectedProjectMaster, setSelectedProjectMaster ] = useState("");
  const [ep_PR_SU, setep_PR_SU ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [ContractBillingDetailsTableData, setContractBillingDetailsTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setContractBillingDetailsTableData([{}]);
    setSelectedemEMNO(null);
    setSelectedProjectMaster(null);
    setcb_PR_TY(null);
    setcb_CR_CD(null);
    setcb_EX_RT(null);
    setcb_CN_TY(null);
    setcb_CN_CO(null);
    setcb_BS_CR(null);
    setcb_ST_CD(null);
    setcb_BL_NM(null);
    setcb_CN_NO(null);
    setitemDES(null);
    setcb_BL_TY(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedProjectMaster(null);
    setcb_PR_TY(null);
    setcb_CR_CD(null);
    setcb_EX_RT(null);
    setcb_CN_TY(null);
    setcb_CN_CO(null);
    setcb_BS_CR(null);
    setcb_ST_CD(null);
    setcb_BL_NM(null);
    setitemDES(null);
    setcb_CN_NO(null);
    setcb_BL_TY(null);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ContractBillingDetails/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const CashFlowData = response.data;
          delete CashFlowData.contractBillingDetailsList[0].cbUkNo;
          for (let i = 0; i < CashFlowData.contractBillingDetailsList.length; i++) {
            delete CashFlowData.contractBillingDetailsList[i].cbUkNo;
          }
          setContractBillingDetailsTableData(CashFlowData.contractBillingDetailsList);
        setFormData(CashFlowData.contractBillingDetailsList[0]);
        setSelectedemEMNO(response.data.contractBillingDetails.name);
        setSelectedProjectMaster(response.data.contractBillingDetails.proj_NAME);
        setcb_PR_TY(CashFlowData.contractBillingDetailsList[0].cb_PR_TY);
        setcb_CR_CD(CashFlowData.contractBillingDetailsList[0].cb_CR_CD);
        setcb_EX_RT(CashFlowData.contractBillingDetailsList[0].cb_EX_RT);
        setcb_CN_TY(CashFlowData.contractBillingDetailsList[0].cb_CN_TY);
        setcb_CN_CO(CashFlowData.contractBillingDetailsList[0].cb_CN_CO);
        setcb_BS_CR(CashFlowData.contractBillingDetailsList[0].cb_BS_CR);
        setcb_ST_CD(CashFlowData.contractBillingDetailsList[0].cb_ST_CD);
        setcb_BL_NM(CashFlowData.contractBillingDetailsList[0].cb_BL_NM);
        setitemDES(CashFlowData.contractBillingDetailsList[0].itemDES);
        setcb_BL_TY(CashFlowData.contractBillingDetailsList[0].cb_BL_TY);
        setcb_CN_NO(CashFlowData.contractBillingDetailsList[0].cb_CN_NO);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [ep_CO_NO , setep_CO_NO] = useState("");

  const handleDefault = async () => {
    setep_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ContractBillingDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value, index) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
    const fieldName=(FormData && FormData.cbUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      ContractBillingDetailsTableData[index][fieldName]=value;
    
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ContractBillingDetails/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const CashFlowData = response.data;
        setContractBillingDetailsTableData(CashFlowData.contractBillingDetailsList);
        setFormData(CashFlowData.contractBillingDetailsList[0]);
        setSelectedemEMNO(response.data.contractBillingDetails.name);
        setSelectedProjectMaster(response.data.contractBillingDetails.proj_NAME);
        setcb_PR_TY(CashFlowData.contractBillingDetailsList[0].cb_PR_TY);
        setcb_CR_CD(CashFlowData.contractBillingDetailsList[0].cb_CR_CD);
        setcb_EX_RT(CashFlowData.contractBillingDetailsList[0].cb_EX_RT);
        setcb_CN_TY(CashFlowData.contractBillingDetailsList[0].cb_CN_TY);
        setcb_CN_CO(CashFlowData.contractBillingDetailsList[0].cb_CN_CO);
        setcb_BS_CR(CashFlowData.contractBillingDetailsList[0].cb_BS_CR);
        setcb_ST_CD(CashFlowData.contractBillingDetailsList[0].cb_ST_CD);
        setcb_BL_NM(CashFlowData.contractBillingDetailsList[0].cb_BL_NM);
        setitemDES(CashFlowData.contractBillingDetailsList[0].itemDES);
        setcb_BL_TY(CashFlowData.contractBillingDetailsList[0].cb_BL_TY);
        setcb_CN_NO(CashFlowData.contractBillingDetails.cb_CN_NO);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("ContractBillingDetailstable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const contractBillingDetailsList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const details = {
        cbUkNo : document.getElementById("cbUkNo_" + i).value,
        cb_BL_NM : document.getElementById("cb_BL_NM_" + i).value,
        itemDES : document.getElementById("itemDES_" + i).value,
        cb_BL_TY : table.tBodies[0].rows[i].cells[3].children[0].children[1].children[0].value,
        cb_DS_AM : document.getElementById("cb_DS_AM_" + i).value,
        // cb_BL_CE : document.getElementById("cb_BL_CE_" + i).value,
        cb_BL_AM : document.getElementById("cb_BL_AM_" + i).value,
        cb_DS_PE : document.getElementById("cb_DS_PE_" + i).value,
        cb_TX_PE : document.getElementById("cb_TX_PE_" + i).value,
        cb_TX_AM : document.getElementById("cb_TX_AM_" + i).value,
        // cb_BL_ED : document.getElementById("cb_BL_ED_" + i).value,
        cb_FR_AM : document.getElementById("cb_FR_AM_" + i).value,
        cb_DS_FR : document.getElementById("cb_DS_FR_" + i).value,
        cb_TX_FR : document.getElementById("cb_TX_FR_" + i).value,
        // cb_FR_BL : document.getElementById("cb_FR_BL_" + i).value,
        // cb_FR_BA : document.getElementById("cb_FR_BA_" + i).value,
    };
    contractBillingDetailsList.push(details);
  }
  const contractBillingDetails={
    cbUkNo   : FormData.cbUkNo,
    cb_CN_NO : document.getElementById("cb_CN_NO").value,
    cb_CN_TY : document.getElementById("cb_CN_TY").value,
    cb_CN_CO : document.getElementById("cb_CN_CO").value,
    cb_CU_NO : document.getElementById("cb_CU_NO").value,
    cb_PR_NO : document.getElementById("cb_PR_NO").value,
    cb_PR_TY : FormData.cb_PR_TY,
    cb_CR_CD : FormData.cb_CR_CD,
    cb_EX_RT : document.getElementById("cb_EX_RT").value,
    cb_BS_CR : document.getElementById("cb_BS_CR").value,
    cb_ST_CD : FormData.cb_ST_CD,
    // cb_DS_PE : document.getElementById("cb_DS_PE").value,

  }
 requestBody = {
  contractBillingDetails,
  contractBillingDetailsList,
   
  };
}
  const handleFormSubmit = () => {
    values ();
    if (FormData.cbUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/ContractBillingDetails/Update`;
      if (confirm) {
        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.cb_CN_CO = company;
      axios
        .post(`${domain}/api/ContractBillingDetails/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cb_CN_NO"] ? filters["cb_CN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cb_CN_NO",
                        e.target.value,
                        filters["cb_CN_NO"]
                          ? filters["cb_CN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cb_CN_TY"] ? filters["cb_CN_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cb_CN_TY",
                        e.target.value,
                        filters["cb_CN_TY"] ? filters["cb_CN_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cb_CN_CO"] ? filters["cb_CN_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cb_CN_CO",
                        e.target.value,
                        filters["cb_CN_CO"]
                          ? filters["cb_CN_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>      
                
                <td>
                  <input
                    type="text"
                    value={filters["cb_PR_NO"] ? filters["cb_PR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cb_PR_NO",
                        e.target.value,
                        filters["cb_PR_NO"]
                          ? filters["cb_PR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["cb_PR_TY"] ? filters["cb_PR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cb_PR_TY",
                        e.target.value,
                        filters["cb_PR_TY"] ? filters["cb_PR_TY"].operation : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["proj_TYPE"] ? filters["proj_TYPE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "proj_TYPE",
                        e.target.value,
                        filters["proj_TYPE"] ? filters["proj_TYPE"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cb_CU_NO"] ? filters["cb_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cb_CU_NO",
                        e.target.value,
                        filters["cb_CU_NO"]
                          ? filters["cb_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["cb_BL_NM"] ? filters["cb_BL_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cb_BL_NM",
                        e.target.value,
                        filters["cb_BL_NM"]
                          ? filters["cb_BL_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["cb_BL_TY"] ? filters["cb_BL_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cb_BL_TY",
                        e.target.value,
                        filters["cb_BL_TY"]
                          ? filters["cb_BL_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["cb_BL_AM"] ? filters["cb_BL_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cb_BL_AM",
                        e.target.value,
                        filters["cb_BL_AM"]
                          ? filters["cb_BL_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["cb_BL_ED"] ? filters["cb_BL_ED"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cb_BL_ED",
                        e.target.value,
                        filters["cb_BL_ED"]
                          ? filters["cb_BL_ED"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["cb_BL_CE"] ? filters["cb_BL_CE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cb_BL_CE",
                        e.target.value,
                        filters["cb_BL_CE"]
                          ? filters["cb_BL_CE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["cb_ST_CD"] ? filters["cb_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cb_ST_CD",
                        e.target.value,
                        filters["cb_ST_CD"]
                          ? filters["cb_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["status"] ? filters["status"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "status",
                        e.target.value,
                        filters["status"]
                          ? filters["status"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Contract No </th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Project No</th>
                <th style={tableHeaderStyleH}>Project Type</th>
                <th style={tableHeaderStyle}>Project Type</th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyle}>Billing Item</th>
                <th style={tableHeaderStyle}>Bill Type</th>  
                <th style={tableHeaderStyle}>Amount</th>   
                <th style={tableHeaderStyle}>Billed</th>  
                <th style={tableHeaderStyle}>Balance</th> 
                <th style={tableHeaderStyleH}>Status</th>    
                <th style={tableHeaderStyle}>Status</th>             
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.cbUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.cbUkNo}
                        checked={selectedRows.includes(rowData.cbUkNo)}
                        onChange={() => handleRowSelect(rowData.cbUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.cb_CN_NO}</td>
                    <td style={tableCellStyle}>{rowData.cb_CN_TY}</td>
                    <td style={tableCellStyle}>{rowData.cb_CN_CO}</td>   
                    <td style={tableCellStyle}>{rowData.cb_PR_NO}</td>
                    <td style={tableCellStyleH}>{rowData.cb_PR_TY}</td>
                    <td style={tableCellStyle}>{rowData.proj_TYPE}</td>
                    <td style={tableCellStyle}>{rowData.cb_CU_NO}</td>   
                    <td style={tableCellStyle}>{rowData.cb_BL_NM}</td>   
                    <td style={tableCellStyle}>{rowData.cb_BL_TY}</td>
                    <td style={tableCellStyle}>{rowData.cb_BL_AM}</td>
                    <td style={tableCellStyle}>{rowData.cb_BL_ED}</td>    
                    <td style={tableCellStyle}>{rowData.cb_BL_CE}</td>
                    <td style={tableCellStyleH}>{rowData.cb_ST_CD}</td>
                    <td style={tableCellStyle}>{rowData.status}</td>             
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <ContractBillingDetailsDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        ContractBillingDetailsTableData={ContractBillingDetailsTableData}
        setContractBillingDetailsTableData={setContractBillingDetailsTableData}
        ep_CO_NO={ep_CO_NO}
        ep_PR_SU={ep_PR_SU}
        cb_PR_TY={cb_PR_TY}
        setcb_PR_TY={setcb_PR_TY}
        cb_ST_CD={cb_ST_CD}
        setcb_ST_CD={setcb_ST_CD}
        cb_CR_CD={cb_CR_CD}
        setcb_CR_CD={setcb_CR_CD}
        cb_EX_RT={cb_EX_RT}
        setcb_EX_RT={setcb_EX_RT}
        cb_BL_TY={cb_BL_TY}
        setcb_BL_TY={setcb_BL_TY}
        cb_BL_NM={cb_BL_NM}
        itemDES={itemDES}
        setcb_BL_NM={setcb_BL_NM}
        setitemDES={setitemDES}
        cb_CN_TY={cb_CN_TY}
        cb_CN_CO={cb_CN_CO}
        cb_BS_CR={cb_BS_CR}
        cb_CN_NO={cb_CN_NO}
        setcb_CN_NO={setcb_CN_NO}
        SelectedProjectMaster={SelectedProjectMaster}
        setSelectedProjectMaster={setSelectedProjectMaster}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
      />
    </div>
  );
};

export default WorkCentreMain;
