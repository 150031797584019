import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import axios from "axios";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function EmployeeMasterDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  wr_RQ_NO,
  wr_RQ_TY,
  wr_RQ_CO,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedAbAdNN,
  setSelectedAbAdNN,
  wr_PY_GR,
  wr_JB_TY,
  wr_JB_PO, 
  wr_TR_CR,
  setFormData,
  wr_ST_CD,
  wr_DE_PT
}) {
  const { 
    wr_EX_SL,  
    wr_RQ_DS, 
    wr_PY_CL, 
    wr_RQ_DT,
    wrUkNo,
    wr_RQ_BY, 
    wr_AP_BY,
  } = data;

  const {borderbox,inboxstyle,Dialogstyle,savestyle,cancelstyle} = ButtonStyles();

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [ApprovedBYPopupVisible, setApprovedBYPopupVisible] = useState(false);
  const [RequestedBYPopupVisible, setRequestedBYPopupVisible] = useState(false);
  const [addressTypeA, setaddressTypeA] = useState("E");
  const [addressTypeR, setaddressTypeR] = useState("E");

  // dropdowns
  const [wr_DE_PTOptions, setwr_DE_PTOptions] = useState([]);
  const [wr_PY_GROptions, setwr_PY_GROptions] = useState([]);
  const [wr_PY_CLOptions, setwr_PY_CLOptions] = useState([]);
  const [wr_ST_CDOptions, setwr_ST_CDOptions] = useState([]);
  const [wr_JB_POOptions, setwr_JB_POOptions] = useState([]);
  const [wr_JB_TYOptions, setwr_JB_TYOptions] = useState([]);
  const [wr_TR_CROptions, setwr_TR_CROptions] = useState([]);

  const openApprovedBYPopup = () => {
    setApprovedBYPopupVisible(true);
  };

  const openRequestedBYPopup = () => {
    setRequestedBYPopupVisible(true);
  };
//  startsearchpopup
const handleDataSelect = (data) => {
  setSelectedemEMNO(data.ab_AD_NM);
  onchangeFormData("wr_AP_BY", data.abAdNo);
  setApprovedBYPopupVisible(false);
};

const handleDataSelect1 = (data) => {
  setSelectedAbAdNN(data.ab_AD_NM);
  onchangeFormData("wr_RQ_BY", data.abAdNo);
  setRequestedBYPopupVisible(false);
};
// endsearchpopup
  
const url = `${domain}/api/ClassCode/All`;
// department
const parameter1 = "EM/DP";
const finalUrl1 = `${url}/${parameter1}`;
// pay GRADE
  const parameter2 = "EM/GR";
  const finalUrl2 = `${url}/${parameter2}`;
// pay class
  const parameter3 = "PY/CL";
  const finalUrl3 = `${url}/${parameter3}`;
// status
  const parameter4 = "WR/SC";
  const finalUrl4 = `${url}/${parameter4}`;
// pOSITION
  const parameter5 = "JB/PO";
  const finalUrl5 = `${url}/${parameter5}`;
// Job Type
  const parameter6 = "JB/TY";
  const finalUrl6 = `${url}/${parameter6}`;
// Currency
  const parameter7 = "FO/CR";
  const finalUrl7 = `${url}/${parameter7}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
   
  }, []);
  // 1st fetchdropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setwr_DE_PTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  //2nd fetchdropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setwr_PY_GROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  //3rd fetchdropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setwr_PY_CLOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  
  };
  //4TH fetchdropdown
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setwr_ST_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions5 = async () => {
    try {
      const response = await axios.get(finalUrl5, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setwr_JB_POOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions6 = async () => {
    try {
      const response = await axios.get(finalUrl6, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setwr_JB_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  const fetchDropdownOptions7 = async () => {
    try {
      const response = await axios.get(finalUrl7, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setwr_TR_CROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
const isUpdateMode = Boolean(wrUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{wrUkNo ? "Revise Work Requisition" : "Add Work Requisition"}</h5>
    <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
        tooltip={isUpdateMode ? "Update" : "Submit"}
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);

  // date default
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "wr_RQ_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  // disabled FOR STATUS
const isDisabled = wr_ST_CD === "O"/ "R";
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
    <div style={borderbox} >
      <div style={inboxstyle} >
        <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_RQ_NO" className="font-bold">
            Requisition No
            </label>
            <div style={{ display: "flex" }}>
            <InputText disabled
                id="wr_RQ_NO"
                name="wr_RQ_NO"
                value={wr_RQ_NO}
              />  
            </div>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_RQ_TY" className="font-bold">
           Type
            </label>
            <InputText disabled
                id="wr_RQ_TY"
                name="wr_RQ_TY"
                value={wr_RQ_TY}
              />  
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_RQ_CO" className="font-bold">
           Company
            </label>
            <InputText disabled
              id="wr_RQ_CO"
              name="wr_RQ_CO"
              defaultValue={wr_RQ_CO}
            />  
          </div>
          {/* <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_CO_CT" className="font-bold">
            Cost Centre
            </label>
            <Dropdown
              id="wr_CO_CT"
              name="wr_CO_CT"
              value={wr_CO_CT}
              options={wr_CO_CTOptions}
              onChange={(e) => onchangeDropDownFormData1("wr_CO_CT", e.value)}
              placeholder="Select Cost Center"
            />
          </div> */}
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_DE_PT" className="font-bold">
            Department
            </label>
            <Dropdown
              id="wr_DE_PT"
              name="wr_DE_PT"
              value={wr_DE_PT}
              options={wr_DE_PTOptions}
              onChange={(e) => onchangeDropDownFormData1("wr_DE_PT", e.value)}
              placeholder="Select Department"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_RQ_DS" className="font-bold">
            Description
            </label>
            <InputText
              id="wr_RQ_DS"
              name="wr_RQ_DS"
              value={wr_RQ_DS}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_RQ_DT" className="font-bold">
            Requisition Date
            </label>
            <Calendar
              id="wr_RQ_DT"
              name="wr_RQ_DT"
              value={moment(wr_RQ_DT, "YYYYMMDD").toDate()}
              onChange={handleDateChange}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_EF_DT" className="font-bold">
            Effective From
            </label>
            <Calendar
              id="wr_EF_DT"
              name="wr_EF_DT"
              value={moment(data.wr_EF_DT, "YYYYMMDD").isValid() ? moment(data.wr_EF_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: {name: "wr_EF_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_ET_DT" className="font-bold">
            Effective Thru
            </label>
            <Calendar
              id="wr_ET_DT"
              name="wr_ET_DT"
              value={moment(data.wr_ET_DT, "YYYYMMDD").isValid() ? moment(data.wr_ET_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: {name: "wr_ET_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_JB_PO" className="font-bold">
            Position 
            </label>
            <Dropdown
              id="wr_JB_PO"
              name="wr_JB_PO"
              value={wr_JB_PO}
              options={wr_JB_POOptions}
              onChange={(e) => onchangeDropDownFormData5("wr_JB_PO", e.value)}
              placeholder="Select Position"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_JB_TY" className="font-bold">
            Job Type
            </label>
            <Dropdown
              id="wr_JB_TY"
              name="wr_JB_TY"
              value={wr_JB_TY}
              options={wr_JB_TYOptions}
              onChange={(e) => onchangeDropDownFormData6("wr_JB_TY", e.value)}
              placeholder="Select Job Type"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_PY_GR" className="font-bold">
            Pay Grade
            </label>
            <Dropdown
              id="wr_PY_GR"
              name="wr_PY_GR"
              value={wr_PY_GR}
              options={wr_PY_GROptions}
              onChange={(e) => onchangeDropDownFormData2("wr_PY_GR", e.value)}
              placeholder="Select Pay Grade"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ab_AD_NM" className="font-bold">
            Requested By <span style={{color:"red"}}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
                id="ab_AD_NM"
                name="ab_AD_NM"
                onChange={(e) => onchangedata(e)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedAbAdNN}
              />
            {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openRequestedBYPopup}
              ></i>
            )}
            </div>
            <div style={{display:"none"}}>
              <InputText
                id="wr_RQ_BY"
                name="wr_RQ_BY"
                value={wr_RQ_BY}
                onChange={(Event) => onchangedata(Event)}
              />
              </div>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_PY_CL" className="font-bold">
            Pay Class
            </label>
            <Dropdown
              id="wr_PY_CL"
              name="wr_PY_CL"
              value={wr_PY_CL}
              options={wr_PY_CLOptions}
              onChange={(e) => onchangeDropDownFormData3("wr_PY_CL", e.value)}
              placeholder="Select Pay Class"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ab_AD_NM" className="font-bold">
            Approved By <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
                id="ab_AD_NM"
                name="ab_AD_NM"
                onChange={(e) => onchangedata(e)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNO}
              />
                {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openApprovedBYPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="wr_AP_BY"
                name="wr_AP_BY"
                value={wr_AP_BY}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_AP_DT" className="font-bold">
            Approval Date
            </label>
             <Calendar
              id="wr_AP_DT"
              name="wr_AP_DT"
              value={moment(data.wr_AP_DT, "YYYYMMDD").isValid() ? moment(data.wr_AP_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: {name: "wr_AP_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_EX_SL" className="font-bold">
            Expected Salary
            </label>
            <InputText
              id="wr_EX_SL"
              name="wr_EX_SL"
              value={wr_EX_SL}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_TR_CR" className="font-bold">
            Currency
            </label>
            <Dropdown
              id="wr_TR_CR"
              name="wr_TR_CR"
              value={wr_TR_CR}
              options={wr_TR_CROptions}
              onChange={(e) => onchangeDropDownFormData7("wr_TR_CR", e.value)}
              placeholder="Select Currency"
            />
            
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_ST_CD" className="font-bold">
            Status
            </label>
            <Dropdown
              id="wr_ST_CD"
              name="wr_ST_CD"
              value={wr_ST_CD}
              options={wr_ST_CDOptions}
              onChange={(e) => onchangeDropDownFormData4("wr_ST_CD", e.value)}
              placeholder="Select Status"
            />
            
          </div>
        </div>
      </div>
    </div>
      <AddressBookPopup
        visible={ApprovedBYPopupVisible}
        onClose={() => setApprovedBYPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypeA}
        setFormData={setFormData}
      />
      <AddressBookPopup
        visible={RequestedBYPopupVisible}
        onClose={() => setRequestedBYPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={addressTypeR}
        setFormData={setFormData}
      />
    </Dialog>
  );

}
