import React, { useState } from "react";
import PropTypes from "prop-types";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import AddressDialog from "views/admin/AddressBook/AddressDialog";
import ButtonStyles from "views/common/ButtonStyles";

const RentalContractPopupData = ({
  tableData,
  filters,
  handleFirstPage,
  handlePreviousPage,
  handleNextPage,
  handleLastPage,
  handleFilterChange,
  toast,
  handleClose,
  //onDataSelect,
  handleSearch,
  onchangedata,
  handleCloseContact,
  openContact,
  opensubmit,
  selectedRows,
  setSelectedRows,
}) => {
  const {formatDate, 
  } = ButtonStyles();
  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
  };
  const tableHeaderStyle1 = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    display:"none"
  };

  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const checkboxStyle = {
    border: "6px solid #a7d6ff8c",
    borderRadius: "4px",
    width: "100%",
  };
  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };
  const tableCellStyle1 = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    display:"none"
  };
  const initialValue = {
    abAdNo: "",
    rcUkNo:"",
    ab_CL_12: "",
    ab_AD_CT: "",
    ab_AD_CY: "",
    ab_AD_P1: "",
    ab_AD_NM: "",
    ab_AD_EM: "",
    ab_CL_11: "",
    ab_AC_IN: "",
  };
  const [FormData, setFormData] = useState(initialValue);
  const [abAdNo, setab_AD_NO] = useState("");
  const [ab_AD_CT, setab_AD_CT] = useState("");
  const [ab_AD_CY, setab_AD_CY] = useState("");
  const [ab_CL_12, setab_CL_12] = useState("");
  const [ab_CL_11, setab_CL_11] = useState("");
  const [ab_CL_13, setab_CL_13] = useState("");
  const [ab_CL_07, setab_CL_07] = useState("");
  const [ab_AD_TY, setab_AD_TY] = useState("");
  const [ab_AC_IN, setab_AC_IN] = useState("");
  const [ab_AD_NM, setab_AD_NM] = useState("");
  const [open, setOpen] = useState(false);
  const [showaddressdialog, setshowaddressdialog] = useState(false);
  // const opendialog = () => {
  //   setshowaddressdialog(true);
  // };
  // const opensubmit = () => {
  //   alert(5);
  // };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  return (
    <Dialog id="dialog-style"
    visible={openContact}
    onHide={handleCloseContact}
      style={{ width: "85rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Rental Contract Details"
      modal
      className="p-fluid"
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        {/* <Button
            style={{
              width: "6.5%",
              border: "none",
              backgroundColor: "#a0c1cdcf",
            }}
            icon="pi pi-plus"
            className="p-button-secondary"
            onClick={opendialog}
          /> */}
          <Button
            style={{
              width: "6.5%",
              border: "none",
              backgroundColor: "pink",
              marginLeft:"20PX",
            }}
            icon="pi pi-check"
            className="p-button-secondary"
            onClick={opensubmit}
          />
          <table id="RentalContractTable"
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
             <thead>
              <tr>
              <td>
              <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_NO"] ? filters["rc_TR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_NO",
                        e.target.value,
                        filters["rc_TR_NO"]
                          ? filters["rc_TR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_TY"] ? filters["rc_TR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_TY",
                        e.target.value,
                        filters["rc_TR_TY"]
                          ? filters["rc_TR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_CO"] ? filters["rc_TR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_CO",
                        e.target.value,
                        filters["rc_TR_CO"]
                          ? filters["rc_TR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_CU_NO"] ? filters["rc_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_CU_NO",
                        e.target.value,
                        filters["rc_CU_NO"]
                          ? filters["rc_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cus_NAME"] ? filters["cus_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cus_NAME",
                        e.target.value,
                        filters["cus_NAME"]
                          ? filters["cus_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_IT_NM"] ? filters["rc_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_IT_NM",
                        e.target.value,
                        filters["rc_IT_NM"]
                          ? filters["rc_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["itemDES"] ? filters["itemDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "itemDES",
                        e.target.value,
                        filters["itemDES"]
                          ? filters["itemDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_UM"] ? filters["rc_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_UM",
                        e.target.value,
                        filters["rc_TR_UM"]
                          ? filters["rc_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_OR_QT"] ? filters["rc_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_OR_QT",
                        e.target.value,
                        filters["rc_OR_QT"]
                          ? filters["rc_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_UN_PR"] ? filters["rc_UN_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_UN_PR",
                        e.target.value,
                        filters["rc_UN_PR"]
                          ? filters["rc_UN_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_UP_AM"] ? filters["rc_UP_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_UP_AM",
                        e.target.value,
                        filters["rc_UP_AM"]
                          ? filters["rc_UP_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_DS_PE"] ? filters["rc_DS_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_DS_PE",
                        e.target.value,
                        filters["rc_DS_PE"]
                          ? filters["rc_DS_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_DS_AM"] ? filters["rc_DS_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_DS_AM",
                        e.target.value,
                        filters["rc_DS_AM"]
                          ? filters["rc_DS_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TX_PE"] ? filters["rc_TX_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TX_PE",
                        e.target.value,
                        filters["rc_TX_PE"]
                          ? filters["rc_TX_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TX_AM"] ? filters["rc_TX_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TX_AM",
                        e.target.value,
                        filters["rc_TX_AM"]
                          ? filters["rc_TX_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_FR_AM"] ? filters["rc_FR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_FR_AM",
                        e.target.value,
                        filters["rc_FR_AM"]
                          ? filters["rc_FR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_FR_DS"] ? filters["rc_FR_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_FR_DS",
                        e.target.value,
                        filters["rc_FR_DS"]
                          ? filters["rc_FR_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_FR_TX"] ? filters["rc_FR_TX"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_FR_TX",
                        e.target.value,
                        filters["rc_FR_TX"]
                          ? filters["rc_FR_TX"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_PY_TR"] ? filters["rc_PY_TR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_PY_TR",
                        e.target.value,
                        filters["rc_PY_TR"]
                          ? filters["rc_PY_TR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TX_AP"] ? filters["rc_TX_AP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TX_AP",
                        e.target.value,
                        filters["rc_TX_AP"]
                          ? filters["rc_TX_AP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_CR"] ? filters["rc_TR_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_CR",
                        e.target.value,
                        filters["rc_TR_CR"]
                          ? filters["rc_TR_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_EX_RT"] ? filters["rc_EX_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_EX_RT",
                        e.target.value,
                        filters["rc_EX_RT"]
                          ? filters["rc_EX_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_BS_CR"] ? filters["rc_BS_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_BS_CR",
                        e.target.value,
                        filters["rc_BS_CR"]
                          ? filters["rc_BS_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_ST_DT"] ? filters["rc_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_ST_DT",
                        e.target.value,
                        filters["rc_ST_DT"]
                          ? filters["rc_ST_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_EN_DT"] ? filters["rc_EN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_EN_DT",
                        e.target.value,
                        filters["rc_EN_DT"]
                          ? filters["rc_EN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_HD_ST"] ? filters["rc_HD_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_HD_ST",
                        e.target.value,
                        filters["rc_HD_ST"]
                          ? filters["rc_HD_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_RF_NC"] ? filters["rc_RF_NC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_RF_NC",
                        e.target.value,
                        filters["rc_RF_NC"]
                          ? filters["rc_RF_NC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle1}>UK</th>
                <th style={tableHeaderStyle}>Contract No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Customer No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Item No</th> 
                <th style={tableHeaderStyle}>Description</th> 
                <th style={tableHeaderStyle}>UM</th> 
                <th style={tableHeaderStyle}>Quantity</th>
                <th style={tableHeaderStyle}>Unit Price</th>
                <th style={tableHeaderStyle}>Amount</th> 
                <th style={tableHeaderStyle}>Dicount %</th>
                <th style={tableHeaderStyle}>Discount Amount</th> 
                <th style={tableHeaderStyle}>Tax %</th>   
                <th style={tableHeaderStyle}>Tax Amount</th>  
                <th style={tableHeaderStyle}>Fore. Amount</th>  
                <th style={tableHeaderStyle}>Fore. Discount</th>
                <th style={tableHeaderStyle}>Fore. Tax</th>
                <th style={tableHeaderStyle}>Payment Term</th>
                <th style={tableHeaderStyle}>Tax Applicable</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Exchange Rate</th>
                <th style={tableHeaderStyle}>Base Currency</th>
                <th style={tableHeaderStyle}>Start Date</th>
                <th style={tableHeaderStyle}>End Date</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Reference</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                key={rowData.uiUkNo}
                //onClick={() => onDataSelect(rowData)}
                style={{
                  backgroundColor:
                    index % 2 === 0 ? "aliceblue" : "#ffffff",
                }}
                className="table-row"
              >
                <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.rcUkNo}
                        checked={selectedRows.includes(rowData.rcUkNo)}
                        onChange={() => handleRowSelect(rowData.rcUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle1}>{rowData.rcUkNo}</td>
                      <td style={tableCellStyle}>{rowData.rc_TR_NO}</td>
                      <td style={tableCellStyle}>{rowData.rc_TR_TY}</td>
                      <td style={tableCellStyle}>{rowData.rc_TR_CO}</td>
                      <td style={tableCellStyle}>{rowData.rc_CU_NO}</td>
                      <td style={tableCellStyle}>{rowData.cus_NAME}</td>
                      <td style={tableCellStyle}>{rowData.rc_IT_NM}</td>
                      <td style={tableCellStyle}>{rowData.itemDES}</td>
                      <td style={tableCellStyle}>{rowData.rc_TR_UM}</td>
                      <td style={tableCellStyle}>{rowData.rc_OR_QT}</td>
                      <td style={tableCellStyle}>{rowData.rc_UN_PR}</td>
                      <td style={tableCellStyle}>{rowData.rc_UP_AM}</td>
                      <td style={tableCellStyle}>{rowData.rc_DS_PE}</td>
                      <td style={tableCellStyle}>{rowData.rc_DS_AM}</td>
                      <td style={tableCellStyle}>{rowData.rc_TX_PE}</td>
                      <td style={tableCellStyle}>{rowData.rc_TX_AM}</td>
                      <td style={tableCellStyle}>{rowData.rc_FR_AM}</td>
                      <td style={tableCellStyle}>{rowData.rc_FR_DS}</td>
                      <td style={tableCellStyle}>{rowData.rc_NT_MT}</td>
                      <td style={tableCellStyle}>{rowData.rc_PY_TR}</td>
                      <td style={tableCellStyle}>{rowData.rc_TX_AP}</td>
                      <td style={tableCellStyle}>{rowData.rc_TR_CR}</td>
                      <td style={tableCellStyle}>{rowData.rc_EX_RT}</td>
                      <td style={tableCellStyle}>{rowData.rc_BS_CR}</td>
                      <td style={tableCellStyle}>{formatDate(rowData.rc_ST_DT)}</td>
                      <td style={tableCellStyle}>{formatDate(rowData.rc_EN_DT)}</td>
                      <td style={tableCellStyle}>{rowData.rc_HD_ST}</td>
                      <td style={tableCellStyle}>{rowData.rc_RF_NC}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="Pagination" style={{ marginLeft: "85%" }}>
          <span
            onClick={handleFirstPage}
            style={{
              fontSize: "200%",
              cursor: "pointer",
              whiteSpace: "pre-wrap",
            }}
          >
            &laquo;{" "}
          </span>{" "}
          <span
            onClick={handlePreviousPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Prev{" "}
          </span>{" "}
          <span style={{ whiteSpace: "pre-wrap", fontSize: "111%" }}> </span>{" "}
          <span
            onClick={handleNextPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Next{" "}
          </span>{" "}
          <span
            onClick={handleLastPage}
            style={{ fontSize: "200%", cursor: "pointer" }}
          >
            &raquo;
          </span>
        </div>
      </div>
      <AddressDialog
        // open={showaddressdialog}
        // handleClose={() => setshowaddressdialog(false)}
        data={FormData}
        onchangedata={onchangedata}
        handleSearch={handleSearch}
        ab_AD_CT={ab_AD_CT}
        ab_AD_CY={ab_AD_CY}
        ab_CL_12={ab_CL_12}
        ab_CL_11={ab_CL_11}
        ab_CL_13={ab_CL_13}
        ab_CL_07={ab_CL_07}
        ab_AD_TY={ab_AD_TY}
        ab_AC_IN={ab_AC_IN}
        abAdNo={abAdNo}
        ab_AD_NM = {ab_AD_NM}
        // setOpen={() => setshowaddressdialog(false)}
        initialValue={initialValue}
        setab_AD_CT={setab_AD_CT}
        setab_AD_CY={setab_AD_CY}
        setab_CL_12={setab_CL_12}
        setab_CL_11={setab_CL_11}
        setab_CL_13={setab_CL_13}
        setab_CL_07={setab_CL_07}
        setab_AD_TY={setab_AD_TY}
        setab_AC_IN={setab_AC_IN}
        setab_AD_NO={setab_AD_NO}
        setab_AD_NM= {setab_AD_NM}
        FormData={FormData}
        setFormData={setFormData}
      />
    </Dialog>
  );
};

RentalContractPopupData.propTypes = {
  tableData: PropTypes.array,
  filters: PropTypes.object,
  handleFirstPage: PropTypes.func,
  handlePreviousPage: PropTypes.func,
  handleNextPage: PropTypes.func,
  handleLastPage: PropTypes.func,
  handleFilterChange: PropTypes.func,
  toast: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
 // onDataSelect: PropTypes.func,
};

export default RentalContractPopupData;
