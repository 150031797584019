import React, { useState } from "react";
import PropTypes from "prop-types";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import AddressDialog from "views/admin/AddressBook/AddressDialog";
const EquipmentPopupData = ({
  tableData,
  filters,
  handleFirstPage,
  handlePreviousPage,
  handleNextPage,
  handleLastPage,
  handleFilterChange,
  toast,
  handleClose,
  onDataSelect,
  handleSearch,
  onchangedata,
  handleCloseContact,
  openContact,
}) => {
  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
  };

  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };
  const initialValue = {
    abAdNo: "",
    ab_CL_12: "",
    ab_AD_CT: "",
    ab_AD_CY: "",
    ab_AD_P1: "",
    ab_AD_NM: "",
    ab_AD_EM: "",
    ab_CL_11: "",
    ab_AC_IN: "",
  };
  const [FormData, setFormData] = useState(initialValue);
  const [abAdNo, setab_AD_NO] = useState("");
  const [ab_AD_CT, setab_AD_CT] = useState("");
  const [ab_AD_CY, setab_AD_CY] = useState("");
  const [ab_CL_12, setab_CL_12] = useState("");
  const [ab_CL_11, setab_CL_11] = useState("");
  const [ab_CL_13, setab_CL_13] = useState("");
  const [ab_CL_07, setab_CL_07] = useState("");
  const [ab_AD_TY, setab_AD_TY] = useState("");
  const [ab_AC_IN, setab_AC_IN] = useState("");
  const [ab_AD_NM, setab_AD_NM] = useState("");
  const [open, setOpen] = useState(false);
  const [showaddressdialog, setshowaddressdialog] = useState(false);
  const opendialog = () => {
    setshowaddressdialog(true);
  };
  return (
    <Dialog id="dialog-style"
      visible={openContact}
      onHide={handleCloseContact}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Equipment Details"
      modal
      className="p-fluid"
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        {/* <Button
            style={{
              width: "6.5%",
              border: "none",
              backgroundColor: "#a0c1cdcf",
            }}
            icon="pi pi-plus"
            className="p-button-secondary"
            onClick={opendialog}
          /> */}
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    value={filters["em_EQ_NM"] ? filters["em_EQ_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_EQ_NM",
                        e.target.value,
                        filters["em_EQ_NM"]
                          ? filters["em_EQ_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_EQ_DS"] ? filters["em_EQ_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_EQ_DS",
                        e.target.value,
                        filters["em_EQ_DS"]
                          ? filters["em_EQ_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Description</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                key={rowData.emUkNo}
                onClick={() => onDataSelect(rowData)}
                style={{
                  backgroundColor:
                    index % 2 === 0 ? "aliceblue" : "#ffffff",
                }}
                className="table-row"
              >
                      <td style={tableCellStyle}>{rowData.em_EQ_NM}</td>
                      <td style={tableCellStyle}>{rowData.em_EQ_DS}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="Pagination" style={{ marginLeft: "85%" }}>
          <span
            onClick={handleFirstPage}
            style={{
              fontSize: "200%",
              cursor: "pointer",
              whiteSpace: "pre-wrap",
            }}
          >
            &laquo;{" "}
          </span>{" "}
          <span
            onClick={handlePreviousPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Prev{" "}
          </span>{" "}
          <span style={{ whiteSpace: "pre-wrap", fontSize: "111%" }}> </span>{" "}
          <span
            onClick={handleNextPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Next{" "}
          </span>{" "}
          <span
            onClick={handleLastPage}
            style={{ fontSize: "200%", cursor: "pointer" }}
          >
            &raquo;
          </span>
        </div>
      </div>
      <AddressDialog
        open={showaddressdialog}
        handleClose={() => setshowaddressdialog(false)}
        data={FormData}
        onchangedata={onchangedata}
        handleSearch={handleSearch}
        ab_AD_CT={ab_AD_CT}
        ab_AD_CY={ab_AD_CY}
        ab_CL_12={ab_CL_12}
        ab_CL_11={ab_CL_11}
        ab_CL_13={ab_CL_13}
        ab_CL_07={ab_CL_07}
        ab_AD_TY={ab_AD_TY}
        ab_AC_IN={ab_AC_IN}
        abAdNo={abAdNo}
        ab_AD_NM = {ab_AD_NM}
        setOpen={() => setshowaddressdialog(false)}
        initialValue={initialValue}
        setab_AD_CT={setab_AD_CT}
        setab_AD_CY={setab_AD_CY}
        setab_CL_12={setab_CL_12}
        setab_CL_11={setab_CL_11}
        setab_CL_13={setab_CL_13}
        setab_CL_07={setab_CL_07}
        setab_AD_TY={setab_AD_TY}
        setab_AC_IN={setab_AC_IN}
        setab_AD_NO={setab_AD_NO}
        setab_AD_NM= {setab_AD_NM}
        FormData={FormData}
        setFormData={setFormData}
      />
    </Dialog>
  );
};

EquipmentPopupData.propTypes = {
  tableData: PropTypes.array,
  filters: PropTypes.object,
  handleFirstPage: PropTypes.func,
  handlePreviousPage: PropTypes.func,
  handleNextPage: PropTypes.func,
  handleLastPage: PropTypes.func,
  handleFilterChange: PropTypes.func,
  toast: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onDataSelect: PropTypes.func,
};

export default EquipmentPopupData;
