import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import LeaseInvoiceDetailsDialog from "./LeaseInvoiceDetailsDialog"
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  li_LE_NO: "",
  li_TY_PE: "",
  li_LE_CO: "",
  li_TE_NT:"",
  name:"",
  li_ST_DT:"",
  li_EN_DT:"",
  li_LE_ST:"",
  rb_IN_DT:moment(moment().toDate()).format("YYYYMMDD"),
};

const initialFilters = {
  li_LE_NO: { value: "", operation: "" },
  li_TY_PE: { value: "", operation: "" },
  li_LE_CO: { value: "", operation: "" },
  li_TE_NT: { value: "", operation: "" },
  name: { value: "", operation: "" },
  li_ST_DT: { value: "", operation: "" },
  li_EN_DT: { value: "", operation: "" },
  li_LE_ST: { value: "", operation: "" },
};

const LeaseInvoiceDetailsMain = () => {
    // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,postStyles
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [rb_ST_DT,setrb_ST_DT] =useState("");
  const [rb_EN_DT,setrb_EN_DT] =useState("");
  const [rb_IN_DT,setrb_IN_DT] =useState("");
  const [SelectedLeaseNo,setSelectedLeaseNo] =useState("");
  const [SelectedLeaseType,setSelectedLeaseType] =useState("");
  const [SelectedLeaseCompany,setSelectedLeaseCompany] =useState("");
  const [SelectedliTwNt, setSelectedliTwNt] = useState("");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedLeaseNo(null);
    //setSelectedLeaseType(null);
    setSelectedLeaseCompany(null);
    setSelectedliTwNt(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setSelectedLeaseNo(null);
    //setSelectedLeaseType(null);
    setSelectedLeaseCompany(null);
    setSelectedliTwNt(null);
    setOpen(false);
  };
  const [rb_LE_CO,setrb_LE_CO] =useState("");
  const handleDefault = async () => {
    setrb_LE_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "RB_LE_TY"){
              setSelectedLeaseType(dataDefault.versionIds[i].default_VALUE)
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }

    const requestBodyAPIInput = {
      "rbUkNo" : `${selectedRows[0]}`,
    }
    axios
    .post(`${domain}/api/LeaseBillingDetails/PostRecurringBill`, requestBodyAPIInput, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
    handleResponse(resp.data);
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleFormSubmit =  async () => {
    const  Url = `${domain}/api/LeaseBillingDetails/ProcessRecurringBill`
    const request = {
        "rb_ST_DT": FormData.rb_ST_DT,
        "rb_EN_DT": FormData.rb_EN_DT,
        "rb_IN_DT": FormData.rb_IN_DT,
        "rb_TE_NT": document.getElementById("rb_TE_NT").value,
        "rb_LE_NO": document.getElementById("rb_LE_NO").value,
        "rb_LE_TY": document.getElementById("rb_LE_TY").value,
        "rb_LE_CO": document.getElementById("rb_LE_CO").value,
    }
    axios.post(Url, request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      handleResponse(resp.data);
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };
  
 
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/LeaseBillingDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
  
       <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".post-button" position="bottom" mouseTrack >
  <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
  Post
  </div>
    </Tooltip>
      <Button 
        style={postStyles}
        icon="pi pi-send"
        outlined
        className="mr-2 post-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />

    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_LE_NO"] ? filters["li_LE_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_LE_NO",
                        e.target.value,
                        filters["li_LE_NO"]
                          ? filters["li_LE_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_TY_PE"] ? filters["li_TY_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_TY_PE",
                        e.target.value,
                        filters["li_TY_PE"]
                          ? filters["li_TY_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_LE_CO"] ? filters["li_LE_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_LE_CO",
                        e.target.value,
                        filters["li_LE_CO"]
                          ? filters["li_LE_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_TE_NT"] ? filters["li_TE_NT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_TE_NT",
                        e.target.value,
                        filters["li_TE_NT"]
                          ? filters["li_TE_NT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_ST_DT"] ? filters["li_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_ST_DT",
                        e.target.value,
                        filters["li_ST_DT"]
                          ? filters["li_ST_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_EN_DT"] ? filters["li_EN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_EN_DT",
                        e.target.value,
                        filters["li_EN_DT"]
                          ? filters["li_EN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_LE_ST"] ? filters["li_LE_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_LE_ST",
                        e.target.value,
                        filters["li_LE_ST"]
                          ? filters["li_LE_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_LE_NO"] ? filters["li_LE_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_LE_NO",
                        e.target.value,
                        filters["li_LE_NO"]
                          ? filters["li_LE_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_TY_PE"] ? filters["li_TY_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_TY_PE",
                        e.target.value,
                        filters["li_TY_PE"]
                          ? filters["li_TY_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_LE_CO"] ? filters["li_LE_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_LE_CO",
                        e.target.value,
                        filters["li_LE_CO"]
                          ? filters["li_LE_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_TE_NT"] ? filters["li_TE_NT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_TE_NT",
                        e.target.value,
                        filters["li_TE_NT"]
                          ? filters["li_TE_NT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_ST_DT"] ? filters["li_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_ST_DT",
                        e.target.value,
                        filters["li_ST_DT"]
                          ? filters["li_ST_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_EN_DT"] ? filters["li_EN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_EN_DT",
                        e.target.value,
                        filters["li_EN_DT"]
                          ? filters["li_EN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_LE_ST"] ? filters["li_LE_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_LE_ST",
                        e.target.value,
                        filters["li_LE_ST"]
                          ? filters["li_LE_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_ST_DT"] ? filters["li_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_ST_DT",
                        e.target.value,
                        filters["li_ST_DT"]
                          ? filters["li_ST_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_EN_DT"] ? filters["li_EN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_EN_DT",
                        e.target.value,
                        filters["li_EN_DT"]
                          ? filters["li_EN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>


              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Invoice No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Lease No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Tenant</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Payee </th>                
                <th style={tableHeaderStyle}>Name</th>                
                <th style={tableHeaderStyle}>Bill From</th>
                <th style={tableHeaderStyle}>Bill To</th>
                <th style={tableHeaderStyle}>Due Date </th>                
                <th style={tableHeaderStyle}>Currency</th>                
                <th style={tableHeaderStyle}>Amount</th>
                <th style={tableHeaderStyle}>Tax </th>                
                <th style={tableHeaderStyle}>Fore. Amount</th>
                <th style={tableHeaderStyle}>Fore.Tax</th>                
                <th style={tableHeaderStyle}>Post</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.rbUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.rbUkNo}
                        checked={selectedRows.includes(rowData.rbUkNo)}
                        onChange={() => handleRowSelect(rowData.rbUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.rb_IN_NO}</td>
                    <td style={tableCellStyle}>{rowData.rb_IN_TY}</td>
                    <td style={tableCellStyle}>{rowData.rb_LE_CO}</td>
                    <td style={tableCellStyle}>{rowData.rb_LE_NO}</td>
                    <td style={tableCellStyle}>{rowData.rb_LE_TY}</td>
                    <td style={tableCellStyle}>{rowData.rb_TE_NT}</td>
                    <td style={tableCellStyle}>{rowData.tenant_NAME}</td> 
                    <td style={tableCellStyle}>{rowData.rb_PA_NO}</td>
                    <td style={tableCellStyle}>{rowData.payee_NAME}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.rb_ST_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.rb_EN_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.rb_DU_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.rb_TR_CR}</td>
                    <td style={tableCellStyle}>{rowData.rb_RN_AM}</td>
                    <td style={tableCellStyle}>{rowData.rb_TX_AM}</td>
                    <td style={tableCellStyle}>{rowData.rb_FR_RN}</td> 
                    <td style={tableCellStyle}>{rowData.rb_FR_TX}</td> 
                    <td style={tableCellStyle}>{rowData.rb_PO_ST}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

     {/* pagination */}
     <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <LeaseInvoiceDetailsDialog
        rb_ST_DT={rb_ST_DT}
        rb_EN_DT={rb_EN_DT}
        rb_IN_DT={rb_IN_DT}
        rb_LE_CO={rb_LE_CO}
        open={open}
        handleClose={handleClose}
        data={FormData}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData} 
        SelectedliTwNt={SelectedliTwNt}
        setSelectedliTwNt={setSelectedliTwNt}
        handleFormSubmit={handleFormSubmit}
        setSelectedLeaseNo={setSelectedLeaseNo}
        SelectedLeaseNo={SelectedLeaseNo}
        setSelectedLeaseType={setSelectedLeaseType}
        SelectedLeaseType={SelectedLeaseType}
        setSelectedLeaseCompany={setSelectedLeaseCompany}
        SelectedLeaseCompany={SelectedLeaseCompany}
      />
    </div>
  );
};

export default LeaseInvoiceDetailsMain;
