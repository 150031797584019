import React, { useState, useEffect ,useRef} from "react";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import ItemPopup from "../Popups/ItemPopup";
import StockPopup from "../Popups/StockPopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import CashPopup from "./CashPopup";
import CardPopup from "./CardPopup";
import CouponPopup from "./CouponPopup";
import CreditPopup from "./CreditPopup";
import OkPopup from "./OkPopup";
export default function DirectSalesDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  setFormData,
  // form main page
  sh_CN_ID,
  setsh_CN_ID,
  sd_TR_UM,
  setsd_TR_UM,
  sp_TR_CR,
  setsp_TR_CR,
  SelectedCustomer,
  setSelectedCustomer,
  ItemTableData,
  setItemTableData,
  PaymentTableData,
  setPaymentTableData,
  is_CO_CT,
  sh_OR_NO,
  sh_CO_CT,
  sd_CO_CT,
  sd_LO_CN,
  sd_ST_CD,
  sh_OR_TY,
  sh_BS_CR,
  sh_EX_RT,
  onchangeDropDownFormData3,
  Image,
  setImage,
  sh_CU_NO,
  setsh_CU_NO,
  sh_DS_PR,
  sh_DS_AT,
  as_AD_SH,
  finalDesc,
  setFinalDesc,
  tax,
  price,
}) {
  const {
    sd_UK_NO,is_LT_NO,sp_EX_RT,sp_PY_AM,sp_PY_MT,sd_IT_NM,im_IT_DS,sd_UT_PR,sd_EX_PR,sd_OR_QT,sd_DS_PR,sd_DS_AT,net_AMOUNT,sd_TX_PE,sd_TX_AM,
    is_LO_CN,sh_OR_DT,sh_TR_CR,sh_PY_TR,
    PRICE_AMOUNT,DISCOUNT_AMOUNT,
    NET_AMOUNT,TAX_AMOUNT,TOTAL,im_IT_NM,PAID_BALANCE,PAID_TOTAL
  } = data;
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [discountVisible, setdiscountVisible] = useState("");

const [selecttotal, setselecttotal] = useState("");
const [selectPaidtotal, setselectPaidtotal] = useState("");
const {borderbox,inboxstylesales,offerbtnstyles,pricestyles,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle} = ButtonStyles();

// 1st row related
const {fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
const [sh_CN_IDOptions, setsh_CN_IDOptions] = useState([]);
const [sd_TR_UMOptions, setsd_TR_UMOptions] = useState([]);
const [sp_TR_CROptions, setsp_TR_CROptions] = useState([]);
const onchangeDropDownFormData1 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setsh_CN_ID(value);
};
const onchangeDropDownFormData2 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setsd_TR_UM(value);
};

useEffect(() => {
  fetchDropdownBT();
  fetchDropdownUM();
  fetchDropdownCR();
}, []);

const fetchDropdownBT = async () => {
  const Options = await fetchClassCodeDS("CP/BD");
  setsh_CN_IDOptions(Options);
};
const fetchDropdownUM = async () => {
  const Options = await fetchClassCodeCD("IN/UM");
  setsd_TR_UMOptions(Options);
};
const fetchDropdownCR = async () => {
  const Options = await fetchClassCodeCD("FO/CR");
  setsp_TR_CROptions(Options);
};
const addRow = () => {
  setItemTableData([...ItemTableData, {}]);
};
const deleteRow = () => {
  if (ItemTableData.length > 0) {
    const updatedData = [...ItemTableData];
    updatedData.pop();
    setItemTableData(updatedData);
  }
}; 
const [CashPopupVisible, setCashPopupVisible] = useState(false);
const [CardPopupVisible, setCardPopupVisible] = useState(false);
const [CouponPopupVisible, setCouponPopupVisible] = useState(false);
const [CreditPopupVisible, setCreditPopupVisible] = useState(false);
const [OkPopupVisible, setOkPopupVisible] = useState(false);
const openCashPopup = () => {
  setCashPopupVisible(true);
};
const openCardPopup = () => {
  setCardPopupVisible(true);
};
const openCouponPopup = () => {
  setCouponPopupVisible(true);
};
const openCreditPopup = () => {
  setCreditPopupVisible(true);
};
const openOkPopup = () => {
  setOkPopupVisible(true);
};
const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
const [SalesPopupVisible, setSalesPopupVisible] = useState(false);
const [ItemPopupVisible, setItemPopupVisible] = useState(false);
const [StockPopupVisible, setStockPopupVisible] = useState(false);
const [addressTypecust, setaddressTypecust] = useState("C");
const [addressTypeSales, setaddressTypeSales] = useState("E");
const [showSearchIcon, setShowSearchIcon] = useState(false);
const [SelectedRowIndex, setSelectedRowIndex] = useState(null); 
const [itemImageUrl, setItemImageUrl] = useState("");

const openSalesPopup = () => {
  setSalesPopupVisible(true);
};

const openCustomerPopup = () => {
  setCustomerPopupVisible(true);
};
const openStockPopup = () => {
  setStockPopupVisible(true);
};
const openItemPopup = (e) => {
  setItemPopupVisible(true);
  setSelectedRowIndex(e);
};
// const handleSelectSales = async (data) => {
//   document.getElementById("sh_CU_NO").value = data.abAdNo;
//   setCustomerPopupVisible(false);
//   setShowSearchIcon(false);
// };
const handleResponse = (data) => {
  const { warnings = [], errorMessage = [], infos = [] } = data;

  if (warnings.length > 0) {
    const warningMessage = warnings.map((item) => `${item}\n`).join("");
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: warningMessage,
    });
  }

  if (infos.length > 0) {
    const infoMessage = infos.map((item) => `${item}\n`).join("");
    toast.current.show({
      severity: "info",
      summary: "Info",
      detail: infoMessage,
    });
  }

  if (errorMessage.length > 0) {
    const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMessages,
    });
  }
};
const handleSelectStock = async (content) => {
  document.getElementById("is_LT_NO").value = content.is_LT_NO;
  const Url = `${domain}/api/StockAvailability/GetItemStockDetails`;
  const requestData={
      "is_LT_NO": content.is_LT_NO,
      "is_LO_CN": content.is_LO_CN,
      "is_CO_CT": document.getElementById("is_CO_CT").value,
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItSt = response.data.Result;
    if (dataItSt ) {
      var inputIndex = document.getElementById("Itemtable").tBodies[0].rows.length;
      var itemIndex = inputIndex - 1;
      const item = document.getElementById(`sd_IT_NM_${itemIndex}`).value;
      if(item){
        setItemTableData([...ItemTableData, {}]);
      } else {
        inputIndex = inputIndex - 1;
      }
      handleSelectItem(dataItSt.is_IT_NM,inputIndex, dataItSt.is_LT_NO);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setStockPopupVisible(false);
  setShowSearchIcon(false);
};
const handleSelectItemPopup = async (Result) => {
  handleSelectItem(Result.im_IT_NM,SelectedRowIndex, "");
};
const handleSelectItem = async (Result,inputIndex, LotNumber) => {
  onchangeFormData("sd_IT_NM", Result, inputIndex);
  const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
  const requestData = {
    "im_IT_NM": Result 
  };
  try {
    const response = await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": company,
        "x-flag": localStorage.getItem("userType"),
      },
    });
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt) {
      setItemTableData(prevData => 
        prevData.map((row, index) =>
          index === inputIndex
            ? {  ...row, 
                sd_IT_NM: dataItMt.im_IT_NM, 
                im_IT_DS: dataItMt.im_IT_DS,
                sd_TR_UM: dataItMt.im_PR_UM,
                sd_TX_PE: dataItMt.im_TO_TX,
                // default
                sd_OR_QT:  1,
                sd_DS_PR:  0,
                sd_LO_NO: LotNumber
              }
            : row
        )
      );
      const imagePath = `${domain}/External/Images/${dataItMt.im_PH_TO}`;
      setItemImageUrl(imagePath);
      setImage(dataItMt.image_SRC);
      onchangebspr(inputIndex);
      await GetOffers(dataItMt.im_IT_NM, dataItMt.im_PR_UM, 1,dataItMt.im_PD_GP,inputIndex);
    } else {
      console.error("Invalid API response: No result data", response.data);
    }
  } catch (error) {
    console.error("Error fetching data:", error.message);
  }
  setItemPopupVisible(false);
  setShowSearchIcon(false);
};
// getting Offers
const GetOffers = async (im_IT_NM,im_PR_UM,Quantity,im_PD_GP,index) => {
  const UrlPrice = `${domain}/api/PriceAdjustmentSchedule/GetOffers`;
  const OffersInput = {
    "item":im_IT_NM,
    "item_GRP":im_PD_GP,
    "qty":Quantity,
    "price":document.getElementById(`sd_UT_PR_${index}`).value,
    "um":im_PR_UM,
    "cus_GRP":im_PD_GP,
    "as_AD_SH": document.getElementById("as_AD_SH").value,
    "cost_CENTER": document.getElementById("is_CO_CT").value,
    "as_FR_DT": sh_OR_DT,
    "curr": sh_BS_CR,
    "address_NO": sh_CU_NO,
    "exchangeRate_RATE":document.getElementById("sh_EX_RT").value, 
  };

  try {
    const response = await axios.post(UrlPrice, OffersInput, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": company,
        "x-flag": localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }

    const dataOffers = response.data.Result;
    if (dataOffers && dataOffers.finalDesc) {
      // setFinalDesc(dataOffers.finalDesc); 
    const finalDescLines = dataOffers.finalDesc.split('#');
      setFinalDesc(finalDescLines);
      setdiscountVisible(true);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
// base price
const onchangebspr = async(SelectedRowIndex) => {
  // price 
  if(price == 1){
    const table = document.getElementById("Itemtable");
  const UrlPrice = `${domain}/api/BasePrice/GetUnitPriceForSales`;
    const priceInput1 = {
      "bp_CO_CT" : document.getElementById("is_CO_CT").value,
      "bp_IT_NM" : document.getElementById (`sd_IT_NM_${SelectedRowIndex}`).value,
      "bp_FR_DT" : sh_OR_DT,
      "bp_TO_DT" : sh_OR_DT,
      "bp_CU_NO" : sh_CU_NO,
      "bp_PR_UM" : table.tBodies[0].rows[SelectedRowIndex].cells[4].children[0].children[1].children[0].value,        
      "bp_CR_CD" : document.getElementById ("sh_BS_CR").value,
    }
    try {
      const response =await axios.post(UrlPrice, priceInput1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });      
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataItMt = response.data;
      if (dataItMt ) {
        handleResponse (response.data);
        if(dataItMt.price){
          document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value =  dataItMt.price.bp_UN_PR;
          // TNCLUSIVE TAX
          if(tax == "I"){
            const UrlPrice = `${domain}/api/ItemSellingPrice/GetTaxInclusivePrice`;
            const SellingTaxInput = {
              "is_IT_NM" : document.getElementById (`sd_IT_NM_${SelectedRowIndex}`).value,
              "is_SL_PR" : dataItMt.price.bp_UN_PR      
            }
            console.log("SellingTaxInput " + SellingTaxInput)
            try {
              const response =await axios.post(UrlPrice, SellingTaxInput, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              });      
              if (response.status !== 200) {
                throw new Error(`API request failed with status: ${response.status}`);
              }
              const dataItMt = response.data;
              if (dataItMt ) {
                handleResponse (response.data);
                if(dataItMt.price){
                  document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value =  dataItMt.price.is_SL_PR;
                  onChangeOfPrice(SelectedRowIndex);
                }
              } else {
                console.error("Invalid API response:", data);
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          }else{
            onChangeOfPrice(SelectedRowIndex);
          }
        }
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

  }else{
    const UrlPrice = `${domain}/api/ItemSellingPrice/GetSellingPrice`;
    const SellingpriceInput = {
      "is_CO_CT" : document.getElementById("is_CO_CT").value,
      "is_IT_NM" : document.getElementById (`sd_IT_NM_${SelectedRowIndex}`).value,
      "is_LO_CN" : sd_LO_CN,
      "is_LT_NO" : document.getElementById (`sd_LO_NO_${SelectedRowIndex}`).value,
    }
    console.log("SellingpriceInput " + SellingpriceInput)
    try {
      const response =await axios.post(UrlPrice, SellingpriceInput, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });      
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataItMt = response.data;
      if (dataItMt ) {
        handleResponse (response.data);
        if(dataItMt.price){
          document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value =  dataItMt.price.is_SL_PR;
      // inclusive tax
          if(tax == "I"){
            const UrlPrice = `${domain}/api/ItemSellingPrice/GetTaxInclusivePrice`;
            const SellingTaxInput = {
              "is_IT_NM" : document.getElementById (`sd_IT_NM_${SelectedRowIndex}`).value,
              "is_SL_PR" : dataItMt.price.is_SL_PR      
            }
            try {
              const response =await axios.post(UrlPrice, SellingTaxInput, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              });      
              if (response.status !== 200) {
                throw new Error(`API request failed with status: ${response.status}`);
              }
              const dataItMt = response.data;
              if (dataItMt ) {
                handleResponse (response.data);
                if(dataItMt.price){
                  document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value =  dataItMt.price.is_SL_PR;
                  onChangeOfPrice(SelectedRowIndex);
                }
  
              } else {
                console.error("Invalid API response:", data);
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          }else{
            onChangeOfPrice(SelectedRowIndex);
          }
        }

        
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
	
};
const  onChangeOfPrice= async(SelectedRowIndex) => {
  const UrlCalulate = `${domain}/api/SalesQuote/GetCalculatedPrice`;
  const priceInput = {
    "exchange_RATE": sp_EX_RT,// n-do
    "fr_UNIT_PRICE": document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value,//DONE
    "tax_PERCENTAGE": document.getElementById (`sd_TX_PE_${SelectedRowIndex}`).value, // n-do
    "cost_CENTER":   document.getElementById ("is_CO_CT").value, //DONE
    "item_NAME":document.getElementById (`sd_IT_NM_${SelectedRowIndex}`).value, //DONE
    "row_NUM": SelectedRowIndex, //DONE
    "discount_PERCENTAGE": document.getElementById (`sd_DS_PR_${SelectedRowIndex}`).value, //n-DO
    "unit_PRICE": document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value,
    "order_QUANTITY": document.getElementById (`sd_OR_QT_${SelectedRowIndex}`).value //n-do 
  }
  try {
    const response =await axios.post(UrlCalulate, priceInput, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCalulate = response.data.out1;
    if (dataCalulate ) {
      document.getElementById (`sd_DS_AT_${SelectedRowIndex}`).value =  dataCalulate.discount_AMOUNT;
      document.getElementById (`sd_EX_PR_${SelectedRowIndex}`).value =  dataCalulate.price_AMOUNT;
      document.getElementById (`sd_TX_AM_${SelectedRowIndex}`).value =  dataCalulate.tax_AMOUNT;
      document.getElementById (`net_AMOUNT_${SelectedRowIndex}`).value = dataCalulate.net_AMOUNT;
      const salesHeader = {
        sh_TR_CR: sp_TR_CR,
        sh_EX_RT: document.getElementById("sh_EX_RT").value,
        sh_DS_AT: document.getElementById("sh_DS_AT").value,
        sh_BS_CR: document.getElementById("sh_BS_CR").value,
      };
      const table = document.getElementById("Itemtable");
      const totalRowCount = table.tBodies[0].rows.length;
      const salesDetail = [];
      for (let i = 0; i < totalRowCount; i++) {  
        const Details = {
          sd_TX_PE: document.getElementById("sd_TX_PE_" + i).value, //NOT
          sd_TX_AM: document.getElementById("sd_TX_AM_" + i).value,
          // sd_DS_AT: document.getElementById("sd_DS_AT_" + i).value, 
          sd_EX_PR: document.getElementById("sd_EX_PR_" + i).value, 
        };
        salesDetail.push(Details);
      }
      const requestBodyNet = {
        salesHeader,
        salesDetail,
      };
      axios.post(`${domain}/api/SalesQuote/GetPopulateNetAmountRetail`, requestBodyNet, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((respNet) => {
        document.getElementById("TAX_AMOUNT").value =  respNet.data.header.tax_AMOUNT;
        document.getElementById("PRICE_AMOUNT").value = respNet.data.header.price_AMOUNT;
        document.getElementById("DISCOUNT_AMOUNT").value = respNet.data.header.discount_AMOUNT;
        document.getElementById("TOTAL").value = respNet.data.header.total;
        document.getElementById("NET_AMOUNT").value = respNet.data.header.net_AMOUNT;
        setselecttotal(respNet.data.header.total);
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
      } else {
        console.error("Invalid API response:", dataCalulate);
      }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
};
// disocunt feilds 
const onchangeHeaderDisPer = async(e) => {
  const disPer = e.currentTarget.value;
  const table = document.getElementById("Itemtable");
  const totalRowCount = table.tBodies[0].rows.length;
  if(disPer){
    for (let i = 0; i < totalRowCount; i++) { 
      const disPerMul = document.getElementById ("sd_DS_PR_" + i).value;
      if(!disPerMul || disPerMul === '0'){
        document.getElementById ("sd_DS_PR_" + i).value = disPer;
        onChangeOfPrice(i);
      }
    }
  }
  onchangedata(e);
}; 
const onchangeFormUnitPrice = async(e,index) => {
  onchangedata(e);
  onChangeOfPrice(index);
};
const handleSelectCust = async (data) => {
  setSelectedCustomer(data.ab_AD_NM);
  document.getElementById("sh_CU_NO").value = data.abAdNo;
  const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
  const requestData={
    "cu_CU_NO": data.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCuMt = response.data.Result;
    document.getElementById("sh_PY_TR").value = dataCuMt.cu_PT_TM;
    document.getElementById("sh_TR_CR").value = dataCuMt.cu_CR_CD;
    if (dataCuMt) {
      const exData={
        "cr_TO_CR": document.getElementById("sh_BS_CR").value, //base curr
        "cr_FR_CR": dataCuMt.cu_CR_CD, //currency
        "cr_EF_DT": sh_OR_DT
      }
      exchangeRate (exData);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setCustomerPopupVisible(false);
  setShowSearchIcon(false);
};
function exchangeRate (exData){
  axios.post(`${domain}/api/ExChangeRate/Rate`, exData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    const dataExRt = resp.data.Result;
    if (dataExRt) {
      document.getElementById("sp_EX_RT").value = dataExRt.cr_CO_MR;
    }
  })
  .catch((error) => {
    console.error("Error saving data:", error);
  });
};
const [selectedDate, setSelectedDate] = useState(moment().toDate());
const handleORDateChange = (e) => {
  setSelectedDate(e.value);
  onchangedata({ target: { name: "sh_OR_DT", value: moment(e.value).format("YYYYMMDD") } });
};
const isUpdateMode = Boolean(sd_UK_NO);
const productDialogFooter = (
  <React.Fragment>
  <Button 
  style={{width:"5%"}}
  icon="pi pi-times" 
  variant="outlined" 
  onClick={handleClose}
  tooltip="Cancel"
  >
  </Button>
  <Button 
  style={{width:"5%"}}
  icon="pi pi-check" 
  onClick={handleFormSubmit} 
  tooltip={isUpdateMode ? "Update" : "Submit"}>
  </Button>
</React.Fragment>
);
// payment table
const [Cashsp_TR_CR, setCashsp_TR_CR ] = useState(sh_BS_CR);
function handlebal (){
  var inputIndex = document.getElementById("PaymentTable").tBodies[0].rows.length;
      var itemIndex = inputIndex - 1;
      const item = document.getElementById(`sp_PY_MT_${itemIndex}`).value;

      setPaymentTableData(prevData => {
      const updatedData = [...prevData];
      if(item){
        updatedData.push({
          sp_PY_MT: "CASH",
          sp_PY_AM: document.getElementById("CashPaid_amt").value,
          sp_TR_CR: Cashsp_TR_CR,
          sp_EX_RT: document.getElementById("CashExchangeRate").value,
        });
      } else {
        updatedData[itemIndex] = {...updatedData[itemIndex],
          sp_PY_MT: "CASH",
          sp_PY_AM: document.getElementById("CashPaid_amt").value,
          sp_TR_CR: Cashsp_TR_CR,
          sp_EX_RT: document.getElementById("CashExchangeRate").value,
        };
      }
      return updatedData;
    });

    const salesDetail = [];
    const newRow = {
      payAmount: document.getElementById("CashPaid_amt").value,
      grossAmount: document.getElementById("TOTAL").value,
      exchangeRate: document.getElementById("CashExchangeRate").value
    }
    salesDetail.push(newRow);

    const table = document.getElementById("PaymentTable");
    const totalRowCount = table.tBodies[0].rows.length;
    for (let i = 0; i < totalRowCount; i++) {  
      const Details = {
        payAmount: document.getElementById("sp_PY_AM_" + i).value,
        grossAmount: document.getElementById("TOTAL").value,
        exchangeRate: document.getElementById("sp_EX_RT_" + i).value
      };
      salesDetail.push(Details);
    }
    const BalInput = {
      salesDetail,
    };

  axios.post(`${domain}/api/HospitalitySales/GetCalculatePaidTotal`, BalInput, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    const balanceResult = resp.data;
    document.getElementById("PAID_BALANCE").value = balanceResult.balance;
    document.getElementById("PAID_TOTAL").value = balanceResult.paid;
    setselectPaidtotal(balanceResult.paid);
  })
  .catch((error) => {
    console.error("Error saving data:", error);
  });
};
// Card
const [Cardsp_TR_CR, setCardsp_TR_CR ] = useState(sh_BS_CR);
function handlebalCard (){
  var inputIndex = document.getElementById("PaymentTable").tBodies[0].rows.length;
      var itemIndex = inputIndex - 1;
      const item = document.getElementById(`sp_PY_MT_${itemIndex}`).value;

      setPaymentTableData(prevData => {
      const updatedData = [...prevData];
      if(item){
        updatedData.push({
          sp_PY_MT: "CARD",
          sp_PY_AM: document.getElementById("CardPaid_amt").value,
          sp_TR_CR: Cardsp_TR_CR,
          sp_EX_RT: document.getElementById("CardExchangeRate").value,
        });
      } else {
        updatedData[itemIndex] = {...updatedData[itemIndex],
          sp_PY_MT: "CARD",
          sp_PY_AM: document.getElementById("CardPaid_amt").value,
          sp_TR_CR: Cardsp_TR_CR,
          sp_EX_RT: document.getElementById("CardExchangeRate").value,
        };
      }
      return updatedData;
    });

    const salesDetail = [];
    const newRow = {
      payAmount: document.getElementById("CardPaid_amt").value,
      grossAmount: document.getElementById("TOTAL").value,
      exchangeRate: document.getElementById("CardExchangeRate").value
    }
    salesDetail.push(newRow);

    const table = document.getElementById("PaymentTable");
    const totalRowCount = table.tBodies[0].rows.length;
    for (let i = 0; i < totalRowCount; i++) {  
      const Details = {
        payAmount: document.getElementById("sp_PY_AM_" + i).value,
        grossAmount: document.getElementById("TOTAL").value,
        exchangeRate: document.getElementById("sp_EX_RT_" + i).value
      };
      salesDetail.push(Details);
    }
    const BalInput = {
      salesDetail,
    };
  axios.post(`${domain}/api/HospitalitySales/GetCalculatePaidTotal`, BalInput, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    const balanceResult = resp.data;
    document.getElementById("PAID_BALANCE").value = balanceResult.balance;
    document.getElementById("PAID_TOTAL").value = balanceResult.paid;
    setselectPaidtotal(balanceResult.paid);
  })
  .catch((error) => {
    console.error("Error saving data:", error);
  });
};
// credit
const [Creditsp_TR_CR, setCreditsp_TR_CR ] = useState(sh_BS_CR);
function handlebalCredit (){
  var inputIndex = document.getElementById("PaymentTable").tBodies[0].rows.length;
      var itemIndex = inputIndex - 1;
      const item = document.getElementById(`sp_PY_MT_${itemIndex}`).value;

      setPaymentTableData(prevData => {
      const updatedData = [...prevData];
      if(item){
        updatedData.push({
          sp_PY_MT: "Credit",
          sp_PY_AM: document.getElementById("CreditPaid_amt").value,
          sp_TR_CR: Creditsp_TR_CR,
          sp_EX_RT: document.getElementById("CreditExchangeRate").value,
        });
      } else {
        updatedData[itemIndex] = {...updatedData[itemIndex],
          sp_PY_MT: "Credit",
          sp_PY_AM: document.getElementById("CreditPaid_amt").value,
          sp_TR_CR: Creditsp_TR_CR,
          sp_EX_RT: document.getElementById("CreditExchangeRate").value,
        };
      }
      return updatedData;
    });

    const salesDetail = [];
    const newRow = {
      payAmount: document.getElementById("CreditPaid_amt").value,
      grossAmount: document.getElementById("TOTAL").value,
      exchangeRate: document.getElementById("CreditExchangeRate").value
    }
    salesDetail.push(newRow);

    const table = document.getElementById("PaymentTable");
    const totalRowCount = table.tBodies[0].rows.length;
    for (let i = 0; i < totalRowCount; i++) {  
      const Details = {
        payAmount: document.getElementById("sp_PY_AM_" + i).value,
        grossAmount: document.getElementById("TOTAL").value,
        exchangeRate: document.getElementById("sp_EX_RT_" + i).value
      };
      salesDetail.push(Details);
    }
    const BalInput = {
      salesDetail,
    };
  axios.post(`${domain}/api/HospitalitySales/GetCalculatePaidTotal`, BalInput, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    const balanceResult = resp.data;
    document.getElementById("PAID_BALANCE").value = balanceResult.balance;
    document.getElementById("PAID_TOTAL").value = balanceResult.paid;
    setselectPaidtotal(balanceResult.paid);
  })
  .catch((error) => {
    console.error("Error saving data:", error);
  });
};
return (
  <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={{ width: "115rem", marginLeft:"20rem",height:"100rem"}}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={sd_UK_NO ? "Revise Direct Sales" : "Add Direct Sales"}
    modal
    className="p-fluid"
    footer={productDialogFooter}
  >
    <div style={{display:"none"}} >
      {/* currency from cu_mt */}
      <InputText 
        id="sh_TR_CR"  name="sh_TR_CR"   defaultValue={sh_TR_CR}  />
      {/* PAY term */}
      <InputText 
        id="sh_PY_TR"  name="sh_PY_TR"   defaultValue={sh_PY_TR}  />
      {/* cost centre */}
      <InputText 
        id="is_CO_CT"  name="is_CO_CT"   value={is_CO_CT}  />
      {/* so_hd cost center */}
      <InputText 
        id="sh_CO_CT"  name="sh_CO_CT" defaultValue={sh_CO_CT}  />
      {/* ORDER no */}
      <InputText 
        id="sh_OR_NO"  name="sh_OR_NO" value={sh_OR_NO} /> 
      {/* order type */}
      <InputText 
        id="sh_OR_TY" name="sh_OR_TY" value={sh_OR_TY} />
      {/* base currnecy */}
      <InputText 
        id="sh_BS_CR" name="sh_BS_CR"  value={sh_BS_CR}  />
      {/* Exchange Rate */}
      <InputText  
        id="sh_EX_RT" name="sh_EX_RT"   value={sh_EX_RT} />
      {/* order date */}
      <Calendar  id="sh_OR_DT"  name="sh_OR_DT" value={moment(sh_OR_DT, "YYYYMMDD").toDate()}   onChange={handleORDateChange}    dateFormat="dd/mm/yy"  />
    </div>
    <div className="container-fluid" style={{ border: "1px solid #e8e8e8", padding: "16px", borderRadius: "15px", width:"100%" }} >
      <div style={inboxstylesales} >
    {/* row 1 */}
        <div className="row d-flex bordered-div" >
          <div className="col-9  d-flex flex-wrap">
          <div className="field col-lg-2 col-md-6 me-3 mb-3 ">
            <label htmlFor="sh_CU_NO" className="font-bold">
              Customer<span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: 'flex' }}>
                <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  onChange={(event) => onchangedata(event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedCustomer}
                />
                {showSearchIcon && (
                  <i className="pi pi-search"
                      style={{
                        color: 'var(--primary-color)',
                        fontSize: '119%',
                        marginTop: '4%',
                        cursor: 'pointer',
                      }}
                    onClick={openCustomerPopup}
                  ></i>
                )}
                <InputText style={{ display: 'none' }}
                  id="sh_CU_NO"
                  name="sh_CU_NO"
                  defaultValue={sh_CU_NO}
                  onChange={(event) => onchangedata(event)}
                />
              </div>
                
          </div>
          <div className="field col-lg-2 col-md-6 me-3 mb-3 ">
            <label htmlFor="sh_DS_PR" className="font-bold">
            Discount % 
            </label>
            <div style={{display:"flex"}}>
            <InputText
              id="sh_DS_PR"
              name="sh_DS_PR"
              value={sh_DS_PR}
              onChange={(e) => onchangeHeaderDisPer(e)}
            />
            </div>
          </div>
          <div className="field col-lg-2 col-md-6 me-3 mb-3 ">
             <label htmlFor="sh_DS_PR" className="font-bold">
              Discount Amount
            </label>
              <InputText
              id="sh_DS_AT"
              name="sh_DS_AT"
              value={sh_DS_AT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 me-3 mb-3 ">
          <label htmlFor="sh_CN_ID" className="font-bold">
              Counter
            </label>
            <Dropdown disabled
              id="sh_CN_ID"
              name="sh_CN_ID"
              value={sh_CN_ID}
              options={sh_CN_IDOptions}
              onChange={(e) => onchangeDropDownFormData1("sh_CN_ID", e.value)}
              placeholder="Counter"
            />
          </div>
          </div>
          <div className="col-lg-3">
              <div className="field col-3">
                <label htmlFor="is_LT_NO" className="font-bold">
                  Stock<span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: 'flex' }}>
                    <InputText
                    style={{width:"10rem"}}
                      id="is_LT_NO"
                      name="is_LT_NO"
                      onChange={(event) => onchangedata(event)}
                      onFocus={() => setShowSearchIcon(true)}
                      defaultValue={is_LT_NO}
                    />
                    {showSearchIcon && (
                      <i className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openStockPopup}
                      ></i>
                    )}
                  </div>
                </div>
          </div>
        </div>
    {/* row 2 */}
        <div className="row bordered-div" style={{marginTop:"1rem"}}>
          <div className="col-lg-9 col-md-12" style={{height:"30vh"}}>
            <div className="table-responsive" style={{height:"28vh"}}>
            <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
              <table className="table table-bordered" id="Itemtable" style={{display:"block",overflow:"scroll",height:"13rem"}}>
                <thead>
                  <tr>
                    <th>Line No</th>
                    <th>Item Number</th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>UM</th>
                    <th>Unit Price</th>
                    <th>Amount</th>
                    <th>Discount %</th>
                    <th>Discount</th>
                    <th>Net Amount</th>
                    <th>Tax %</th>
                    <th>Tax</th>
                    <th style={{display:"none"}}>Cost Centre</th>
                    <th style={{display:"none"}}>Location</th>
                  </tr>
                </thead>
                <tbody>
                {ItemTableData.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <InputText disabled
                        style={{ width: "60px" }}
                        id={`sd_LN_NO_${index}`}
                        name={`sd_LN_NO_${index}`}
                        value={index + 1}
                      />
                    </td>
                    <td style={{ display: "flex" }}>
                    <InputText
                      style={{ width: "110px" }}
                      id={`sd_IT_NM_${index}`}
                      name={`sd_IT_NM_${index}`}
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.sd_IT_NM ? row.sd_IT_NM: row[`${"sd_IT_NM"}_${index}`]}
                    />
                      {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "15%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => openItemPopup(index)}
                    ></i>
                      )}
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "200px" }}
                        id={`im_IT_DS_${index}`}
                        name={`im_IT_DS_${index}`}
                        defaultValue={row.im_IT_DS ? row.im_IT_DS: row[`${"im_IT_DS"}_${index}`]}
                      />
                    </td>
                    <td>
                      <InputText
                        style={{ width: "70px" }}
                        id={`sd_OR_QT_${index}`}
                        name={`sd_OR_QT_${index}`}
                        defaultValue={row.sd_OR_QT}
                        onChange={(e) => onchangeFormUnitPrice(e,index)}
                      />
                    </td>
                    <td>
                      <Dropdown disabled
                        id={`sd_TR_UM_${index}`}
                        name={`sd_TR_UM_${index}`}
                        value={ data[`sd_TR_UM_${index}`] ? data[`sd_TR_UM_${index}`]: row.sd_TR_UM}
                        options={sd_TR_UMOptions}
                        onChange={(e) =>onchangeDropDownFormData2(`sd_TR_UM_${index}`, e.value)}
                        placeholder="Select UM"
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "80px" }}
                        id={`sd_UT_PR_${index}`}
                        name={`sd_UT_PR_${index}`}
                        value={row.sd_UT_PR}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "80px" }}
                        id={`sd_EX_PR_${index}`}
                        name={`sd_EX_PR_${index}`}
                        value={row.sd_EX_PR}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <InputText
                        style={{ width: "70px" }}
                        id={`sd_DS_PR_${index}`}
                        name={`sd_DS_PR_${index}`}
                        defaultValue={row.sd_DS_PR}
                        onChange={(e) => onchangeFormUnitPrice(e,index)}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "100px" }}
                        id={`sd_DS_AT_${index}`}
                        name={`sd_DS_AT_${index}`}
                        value={row.sd_DS_AT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "90px" }}
                        id={`net_AMOUNT_${index}`}
                        name={`net_AMOUNT_${index}`}
                        value={row.net_AMOUNT}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "70px" }}
                        id={`sd_TX_PE_${index}`}
                        name={`sd_TX_PE_${index}`}
                        value={row.sd_TX_PE}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "70px" }}
                        id={`sd_TX_AM_${index}`}
                        name={`sd_TX_AM_${index}`}
                        value={row.sd_TX_AM}
                      />
                    </td>
                    <td style={{display:"none"}}>
                      <InputText disabled
                        style={{ width: "70px" }}
                        id={`sd_CO_CT_${index}`}
                        name={`sd_CO_CT_${index}`}
                        value={sd_CO_CT}
                      />
                    </td>
                    <td style={{display:"none"}}>
                      <InputText disabled
                        style={{ width: "70px" }}
                        id={`sd_LO_CN_${index}`}
                        name={`sd_LO_CN_${index}`}
                        value={sd_LO_CN}
                      />
                     {/* LOT NUMBER */}
                     <InputText disabled
                        style={{ width: "70px" }}
                        id={`sd_LO_NO_${index}`}
                        name={`sd_LO_NO_${index}`}
                        value={row.sd_LO_NO}
                      />
                    </td>
                    <td style={{display:"none"}}>
                    <InputText disabled
                        style={{ width: "50px" }}
                        id={`sd_ST_CD_${index}`}
                        name={`sd_ST_CD_${index}`}
                        value={ row.sd_ST_CD ? row.sd_ST_CD : sd_ST_CD}
                        readOnly
                      />
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 "> {/* d-flex flex-column align-items-center*/}
          <div>
          {/* <InputText disabled
            style={{ width: "200px" }}
            defaultValue={im_IT_DS}
          /> */}
          </div>
            {itemImageUrl && (
              <img
                src={`data:image/jpeg;base64,${Image}`} 
                alt={"image"}
                style={{
                  width: "60%",
                  height: "auto",
                  padding: "1rem"
                }}
            />
            )}
          </div>
        </div>
    {/* row 3 */}
        <div className="row bordered-div">
          <div className="col-9 d-flex">
              <div className="field col-lg-2 me-3 mb-2">
                <label htmlFor="PRICE_AMOUNT" className="font-bold">
                  Total
                </label>
                <InputText disabled
                  id="PRICE_AMOUNT"
                  name="PRICE_AMOUNT"
                  value={PRICE_AMOUNT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 me-3 mb-2">
                <label htmlFor="DISCOUNT_AMOUNT" className="font-bold">
                  Discount
                </label>
                <InputText disabled
                  id="DISCOUNT_AMOUNT"
                  name="DISCOUNT_AMOUNT"
                  value={DISCOUNT_AMOUNT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 me-3 mb-2">
                <label htmlFor="NET_AMOUNT" className="font-bold">
                  Net Amount
                </label>
                <InputText disabled
                  id="NET_AMOUNT"
                  name="NET_AMOUNT"
                  value={NET_AMOUNT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 me-3 mb-2">
                <label htmlFor="TAX_AMOUNT" className="font-bold">
                  Tax
                </label>
                <InputText disabled
                  id="TAX_AMOUNT"
                  name="TAX_AMOUNT"
                  value={TAX_AMOUNT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 me-3 mb-2">
                <label htmlFor="TOTAL" className="font-bold">
                  Gross Amount
                </label>
                <InputText disabled
                  id="TOTAL"
                  name="TOTAL"
                  value={TOTAL}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
          </div>
          <div className="col-3 ">
          <div className="col-12 d-flex align-items-center justify-content-between">
          <Button onClick={openSalesPopup} label="Sales Person" className="p-button-outlined" style={{ width: '10rem' }} />
          <Button label="Offers" className="p-button-outlined" style={{ width: '10rem' }} />
          </div>
          </div>

        </div>
    {/* row 4 */}
        <div className="row bordered-div">
          <div className="col-6 ">
          <div className="table-container d-flex flex-column">
          <div className="d-flex">
            <div style={{maxHeight:"200px",overflowY:"scroll"}}>
              <table className="table table-bordered flex-grow-1" id="PaymentTable">
                <thead>
                  <tr>
                    <th>Payment Method</th>
                    <th>Amount</th>
                    <th>Currency</th>
                    <th>Exchange Rate</th>
                  </tr>
                </thead>
                <tbody>
                {PaymentTableData.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <InputText disabled
                        style={{ width: "70px" }}
                        id={`sp_PY_MT_${index}`}
                        name={`sp_PY_MT_${index}`}
                        defaultValue={row.sp_PY_MT}
                      />
                      </td>
                    <td>
                      <InputText disabled
                        style={{ width: "70px" }}
                        id={`sp_PY_AM_${index}`}
                        name={`sp_PY_AM_${index}`}
                        defaultValue={row.sp_PY_AM}
                      />
                    </td>
                    <td>
                      <Dropdown disabled
                          id={`sp_TR_CR_${index}`}
                          name={`sp_TR_CR_${index}`}
                          value={data[`sp_TR_CR_${index}`] ? data[`sp_TR_CR_${index}`] : row.sp_TR_CR} 
                          options={sp_TR_CROptions}
                          onChange={(e) =>onchangeDropDownFormData3(`sp_TR_CR_${index}`, e.value)}
                          placeholder="Select Currency"
                        />
                    </td>
                    <td >
                      <InputText disabled
                        style={{ width: "70px" }}
                        id={`sp_EX_RT_${index}`}
                        name={`sp_EX_RT_${index}`}
                        defaultValue={row.sp_EX_RT}
                      />
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
            <div className="button-container" style={{width:"20%"}}>
              <div className="d-flex flex-column" style={{width:"12rem"}}>
                <button className="btn btn-primary w-50 ml-3 mb-1" onClick={openCashPopup}> Cash </button>
                <button className="btn btn-primary w-50 ml-3 mb-1" onClick={openCardPopup}> Card </button>
                <button className="btn btn-primary w-50 ml-3 mb-1" onClick={openCouponPopup}> Coupon </button>
                <button className="btn btn-primary w-50 ml-3 mb-1" onClick={openCreditPopup}> Credit </button>
                <button className="btn btn-primary w-50 ml-3 mb-1" onClick={openOkPopup}> OK </button>
                <button className="btn btn-primary w-50 ml-3 mb-1" onClick={handleClose}> Cancel </button>
              </div>
            </div>
          </div>
          <div className="table table-bordered mt-3 d-flex justify-content-between align-items-center p-1 w-75">
            <label htmlFor="PAID_TOTAL" className="font-bold">
              Total
            </label>
            <InputText disabled
              style={{width:"10rem"}}
              id="PAID_TOTAL"
              name="PAID_TOTAL"
              defaultValue={PAID_TOTAL}
              onChange={(e) => onchangedata(e)}
            />
            <label htmlFor="PAID_BALANCE" className="font-bold">
              Balance
            </label>
            <InputText disabled
              style={{width:"10rem"}}
              id="PAID_BALANCE"
              name="PAID_BALANCE"
              defaultValue={PAID_BALANCE}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          </div>
          </div>
          <div className="col-2 ">
            {/* Placeholder for Discount */}
           <div style={{  marginTop: "1rem",  padding: "1rem",   border: "1px solid #ddd",  borderRadius: "8px",
                height:  "20rem", // Fixed height when visible
                overflowY: discountVisible ? "auto" : "hidden", // Enable vertical scrolling
                transition: "height 0.3s ease",
              }}>
                {discountVisible && (
                <div style={{ maxHeight: "140px", overflowY: "auto" }} >
                 {Array.isArray(finalDesc) ? (
                   finalDesc.map((line, index) => (
                <p key={index}>{line}</p>
                    ))
                  ): (  <p> </p> )}
              </div> )}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ItemPopup
        visible={ItemPopupVisible}
        onDataSelect={handleSelectItemPopup}
        onClose={() => setItemPopupVisible(false)}
      />
       <StockPopup
        visible={StockPopupVisible}
        onDataSelect={handleSelectStock}
        onClose={() => setStockPopupVisible(false)}
      />
      {/* custoemr */}
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onDataSelect={handleSelectCust}
        onClose={() => setCustomerPopupVisible(false)}
        formData={data}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
      {/* sales person */}
      {/* <AddressBookPopup
        visible={SalesPopupVisible}
        onDataSelect={handleSelectSales}
        onClose={() => setSalesPopupVisible(false)}
        formData={data}
        addressType={addressTypeSales}
        setFormData={setFormData}
      /> */}
      {/* payment popups */}
      <CashPopup
        visible={CashPopupVisible}
        onClose={() => setCashPopupVisible(false)}
        formData={data}
        grossAmount={selecttotal}
        paidTotal={PAID_TOTAL}
        sh_BS_CR={sh_BS_CR}
        sh_EX_RT={sh_EX_RT}
        sh_OR_DT={sh_OR_DT}
        handlebal={handlebal}
        Cashsp_TR_CR={Cashsp_TR_CR}
        setCashsp_TR_CR={setCashsp_TR_CR}
      />
      <CardPopup
        visible={CardPopupVisible}
        onClose={() => setCardPopupVisible(false)}
        formData={data}
        grossAmount={selecttotal}
        paidTotal={PAID_TOTAL}
        sh_BS_CR={sh_BS_CR}
        sh_EX_RT={sh_EX_RT}
        sh_OR_DT={sh_OR_DT}
        handlebalCard={handlebalCard}
        Cardsp_TR_CR={Cardsp_TR_CR}
        setCardsp_TR_CR={setCardsp_TR_CR}
      />
       <CouponPopup
        visible={CouponPopupVisible}
        onClose={() => setCouponPopupVisible(false)}
      />
     <CreditPopup
        visible={CreditPopupVisible}
        onClose={() => setCreditPopupVisible(false)}
        formData={data}
        grossAmount={selecttotal}
        paidTotal={PAID_TOTAL}
        sh_BS_CR={sh_BS_CR}
        sh_EX_RT={sh_EX_RT}
        sh_OR_DT={sh_OR_DT}
        handlebalCredit={handlebalCredit}
        Creditsp_TR_CR={Creditsp_TR_CR}
        setCreditsp_TR_CR={setCreditsp_TR_CR}
      />
      <OkPopup
        visible={OkPopupVisible}
        onClose={() => setOkPopupVisible(false)}
      />
  </Dialog>
);
}
