import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import LeasePopup from "../Popups/LeasePopup";
export default function PropertyInspectionDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  pi_TR_TY,
  pi_TR_CO,
  setpi_LE_TY,
  pi_ST_AT, 
  setpi_ST_AT,
  Propertytable,
  setPropertytable,
  Selectedleaseno,
  setSelectedleaseno,
  SelectedleaseType,
  setSelectedleaseType,
  SelectedTenent,
  setSelectedTenent,
  SelectedUnit,
  setSelectedUnit,
  SelectedProperty,
  setSelectedProperty,
  SelectedBuilding,
  setSelectedBuilding,
  SelectedFloor,
  setSelectedFloor,
  SelectedTenentname,
  setSelectedTenentname,
  onchangeDropDownFormData2,
}) {
  const {
    piUkNo,
    pi_TR_DT,
    pi_TR_NO,
    pi_UN_NO, 
    pi_FL_NO,
    pi_BL_NO,
    pi_PR_NO,
    pi_LE_NO,
    pi_TE_NT,
    pi_IT_NM, 
    pi_LE_TY,  
  } = data;
  const addRow = () => {
    setPropertytable([...Propertytable, {}]);
  };
  const deleteRow = () => {
    if (Propertytable.length > 0) {
      const updatedData = [...Propertytable];
      updatedData.pop();
      setPropertytable(updatedData);
    }
  };
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  // dropdowns
  const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
  const [pi_LE_TYOptions, setpi_LE_TYOptions] = useState([]);
  const [pi_ST_ATOptions, setpi_ST_ATOptions] = useState([]);
// lease type
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpi_LE_TY(value);
  };
  useEffect(() => {
    fetchDropdownTY();
    fetchDropdownST();
  }, []);
  const fetchDropdownTY = async () => {
    const Options = await fetchClassCodeDS("FO/DT");
    setpi_LE_TYOptions(Options);
  };
  const fetchDropdownST = async () => {
    const Options = await fetchClassCodeDS("IN/ST");
    setpi_ST_ATOptions(Options);
  };
//advance search
const [LeaseNoPopupVisible, setLeaseNoPopupVisible] = useState(false);
const [showSearchIcon, setShowSearchIcon] = useState("");
const openLeaseNoPopup = () => {
  setLeaseNoPopupVisible(true);
  setShowSearchIcon(false);
};
const handleDataSelect = (data) => {
  setSelectedleaseno(data.liLeNo); 
  setSelectedleaseType(data.li_LE_TY); 
  setSelectedTenent(data.li_TE_NT); 
  setSelectedProperty(data.li_PR_NO); 
  setSelectedBuilding(data.li_BL_NO); 
  setSelectedFloor(data.li_FL_NO); 
  setSelectedUnit(data.li_UN_TS); 
  setSelectedTenentname(data.teanat_NAME);
  onchangeFormData("pi_LE_NO", data.liLeNo);
  onchangeFormData("pi_LE_TY", data.li_LE_TY);
  onchangeFormData("pi_TE_NT", data.li_TE_NT);
  onchangeFormData("pi_PR_NO", data.li_PR_NO);
  onchangeFormData("pi_BL_NO", data.li_BL_NO);
  onchangeFormData("pi_FL_NO", data.li_FL_NO);   
  onchangeFormData("pi_UN_NO", data.li_UN_TS);  
  setLeaseNoPopupVisible(false);
};
const [selectedDate, setSelectedDate] = useState(moment().toDate());
const handleDateChange = (e) => {
  setSelectedDate(e.value);
  onchangedata({ target: { name: "pi_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
};

const isUpdateMode = Boolean(piUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{ piUkNo ? "Revise Property Inspection" : "Add Property Inspection" }</h5>
    <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
        tooltip={isUpdateMode ? "Update" : "Submit"}
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
            <div style={inboxstyle} >
                <div className="formgrid grid">
                <div style={{display:"none"}}>
                    <label htmlFor="piUkNo" className="font-bold">
                      uk_no
                    </label>
                    <InputText 
                      id="piUkNo"
                      name="piUkNo"
                      defaultValue={piUkNo}
                    />
                  </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pi_TR_NO" className="font-bold">
                     Transaction No
                    </label>
                    <InputText disabled
                      id="pi_TR_NO"
                      name="pi_TR_NO"
                      value={pi_TR_NO}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pi_TR_TY" className="font-bold">
                     Type
                    </label>
                    <InputText disabled
                      id="pi_TR_TY"
                      name="pi_TR_TY"
                      value={pi_TR_TY}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pi_TR_CO" className="font-bold">
                      Company
                    </label>
                    <InputText disabled
                      id="pi_TR_CO"
                      name="pi_TR_CO"
                      value={pi_TR_CO}
                    />
                  </div>

                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pi_LE_NO" className="font-bold">
                      Lease No <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText 
                      id="pi_LE_NO"
                      name="pi_LE_NO"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={Selectedleaseno}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openLeaseNoPopup}
                    ></i>
                    )}
                    </div>
                  </div>                  
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pi_LE_TY" className="font-bold">
                      Type
                    </label>
                   <InputText disabled
                      id="pi_LE_TY"
                      name="pi_LE_TY"
                      value={SelectedleaseType}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pi_TR_DT" className="font-bold">
                      Date
                    </label>
                    <Calendar
                      id="pi_TR_DT"
                      name="pi_TR_DT"
                      value={moment(pi_TR_DT, "YYYYMMDD").toDate()}
                      onChange={handleDateChange}
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pi_TE_NT" className="font-bold">
                     Tenant
                    </label>
                    <InputText  disabled
                    style={{display:"none"}}
                      id="pi_TE_NT"
                      name="pi_TE_NT"
                      value={SelectedTenent}
                    />
                     <InputText  disabled
                      id="name"
                      name="name"
                      value={SelectedTenentname}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pi_UN_NO" className="font-bold">
                   Unit
                    </label>
                    <InputText  disabled
                      id="pi_UN_NO"
                      name="pi_UN_NO"
                      value={SelectedUnit}
                    />
                  </div>

                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pi_FL_NO" className="font-bold">
                      Floor
                    </label>
                    <InputText disabled
                      id="pi_FL_NO"
                      name="pi_FL_NO"
                      value={SelectedFloor}
                    />
                  </div>
                  
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pi_BL_NO" className="font-bold">
                      Building
                    </label>
                    <InputText disabled
                      id="pi_BL_NO"
                      name="pi_BL_NO"
                      value={SelectedBuilding}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pi_PR_NO" className="font-bold">
                      Property
                    </label>
                    <InputText disabled
                      id="pi_PR_NO"
                      name="pi_PR_NO"
                      value={SelectedProperty}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pi_ST_AT" className="font-bold">
                   Status
                    </label>
                    <Dropdown
                    name="pi_ST_AT"
                    value={pi_ST_AT}
                    options={pi_ST_ATOptions}
                    onChange={(e) => onchangeDropDownFormData2("pi_ST_AT", e.value)}
                    placeholder="Select Status"
                  />
                  </div>
                </div>
            </div>
      </div>
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="Propertytable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Item</th>    
          <th>Condition</th>
          <th>Remarks</th>
        </tr>
      </thead>
      <tbody>
      {Propertytable.map((row, index) => (
              <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
              id={`piUkNo_${index}`}
              name={`piUkNo_${index}`}
              value={row.piUkNo}
            />
          </td>
          <td>
          <InputText 
            id={`pi_IT_NM_${index}`}
            name={`pi_IT_NM_${index}`}
            defaultValue={row.pi_IT_NM}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText 
            id={`pi_CN_ON_${index}`}
            name={`pi_CN_ON_${index}`}
            defaultValue={row.pi_CN_ON}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText 
            id={`pi_RE_MK_${index}`}
            name={`pi_RE_MK_${index}`}
            defaultValue={row.pi_RE_MK}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          </tr>
           ))}
      </tbody>
    </table> 
  </div>
</div>
      <LeasePopup
        visible={LeaseNoPopupVisible}
        onClose={() => setLeaseNoPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        leaseType={SelectedleaseType}
      /> 
    </Dialog>
  );
}
