import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import ClassCode from "views/common/ClassCode";
export default function WorkOrderCompletionDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit, 
  wd_WO_CO, 
  wd_WO_TY,
  wd_CO_CT,
  setwd_CO_CT,
  wd_WO_ST,
  setwd_WO_ST,
  wd_TR_UM,
  setwd_TR_UM,
  wd_LO_CN,
  setwd_LO_CN,
}) {
  const {
    wdUkNo,
    wd_IT_NM,
    wd_WO_NO,
    wd_WO_QT,
    wd_CO_QT,
    wd_CO_DT,
    wd_SR_QT,
    wd_LT_NO,
    wd_ST_DT,
    wd_SC_QT,
  } = data;

// DROPDOWNS
const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
const [wd_WO_STOptions, setwd_WO_STOptions] = useState([]);
const [wd_TR_UMOptions, setwd_TR_UMOptions] = useState([]);
const [wd_LO_CNOptions, setwd_LO_CNOptions] = useState([]);

// cost centre dropdown
const { fetchCostCenter } = Costcenter();
const [wd_CO_CTOptions, setwd_CO_CTOptions] = useState([]);

const onchangeDropDownFormData1 = (name, value) => {
  setFormData({ ...FormData, [name]: value });
  setwd_CO_CT(value);
};
const fetchCostCentre = async () => {
  const CostOptions = await fetchCostCenter();
  setwd_CO_CTOptions(CostOptions);
};
// class code
// status
const onchangeDropDownFormData2 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setwd_WO_ST(value);
};
// um
const onchangeDropDownFormData3 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setwd_TR_UM(value);
};
// location
const onchangeDropDownFormData4 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setwd_LO_CN(value);
};
  useEffect(() => {
    fetchCostCentre();
    fetchDropdown1();
    fetchDropdown2();
    fetchDropdown3();
  }, []);

  const fetchDropdown1 = async () => {
    const Options = await fetchClassCodeDS("WO/ST");
    setwd_WO_STOptions(Options);
  };
  const fetchDropdown2 = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setwd_TR_UMOptions(Options);
  };
  const fetchDropdown3 = async () => {
    const Options = await fetchClassCodeDS("WO/ST");
    setwd_LO_CNOptions(Options);
  };

const isUpdateMode = Boolean(wdUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={wdUkNo ? "Revise Work Order Completion" : "Add Work Order Completion"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
        }}
      >
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
              <div style={{display:"none"}}>
                  <label htmlFor="wdUkNo" className="font-bold">
                    UK_NO
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="wdUkNo"
                      name="wdUkNo"
                      value={wdUkNo}
                    />
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_WO_NO" className="font-bold">
                    Order No
                  </label>
                  <InputText
                    id="wd_WO_NO"
                    name="wd_WO_NO"
                    value={wd_WO_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_WO_TY" className="font-bold">
                    Type
                  </label>
                  <InputText
                    id="wd_WO_TY"
                    name="wd_WO_TY"
                    value={wd_WO_TY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_WO_CO" className="font-bold">
                    Company
                  </label>
                  <InputText
                    id="wd_WO_CO"
                    name="wd_WO_CO"
                    value={wd_WO_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_CO_CT" className="font-bold">
                   Cost Centre
                  </label>
                  <Dropdown
                    name="wd_CO_CT"
                    value={wd_CO_CT}
                    options={wd_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormData1("wd_CO_CT", e.value)}
                    placeholder="Select Cost Centre"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_IT_NM" className="font-bold">
                   Item Number
                  </label>
                  <InputText
                    id="wd_IT_NM"
                    name="wd_IT_NM"
                    value={wd_IT_NM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_WO_QT" className="font-bold">
                  Order Quantity
                  </label>
                  <InputText
                    id="wd_WO_QT"
                    name="wd_WO_QT"
                    value={wd_WO_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_WO_ST" className="font-bold">
                    Status
                  </label>
                  <Dropdown
                    name="wd_WO_ST"
                    value={wd_WO_ST}
                    options={wd_WO_STOptions}
                    onChange={(e) => onchangeDropDownFormData2("wd_WO_ST", e.value)}
                    placeholder="Select Status"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_CO_QT" className="font-bold">
                    Completed Qty
                  </label>
                  <InputText
                    id="wd_CO_QT"
                    name="wd_CO_QT"
                    value={wd_CO_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_CO_QT" className="font-bold">
                   Completion Qty
                  </label>
                  <InputText
                    id="wd_CO_QT"
                    name="wd_CO_QT"
                    value={wd_CO_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_CO_DT" className="font-bold">
                   Completion Date
                  </label>
                  <Calendar
                    id="wd_CO_DT"
                    name="wd_CO_DT"
                    value={moment(wd_CO_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "wd_CO_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    showIcon
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="wd_SC_QT" className="font-bold">
                  Sec. Qty
                  </label>
                  <InputText
                    id="wd_SC_QT"
                    name="wd_SC_QT"
                    value={wd_SC_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="wd_TR_UM" className="font-bold">
                  UM
                  </label>
                  <Dropdown
                    name="wd_TR_UM"
                    value={wd_TR_UM}
                    options={wd_TR_UMOptions}
                    onChange={(e) => onchangeDropDownFormData3("wd_TR_UM", e.value)}
                    placeholder="Select UM"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="LOT_EXPIRY_DATE" className="font-bold">
                   Expiration Date
                  </label>
                  <Calendar
                    id="LOT_EXPIRY_DATE"
                    name="LOT_EXPIRY_DATE"
                    value={moment("LOT_EXPIRY_DATE", "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "LOT_EXPIRY_DATE", value: moment(e.value).format("YYYYMMDD") } })}
                    showIcon
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="wd_SR_QT" className="font-bold">
                  Scrap Quantity
                  </label>
                  <InputText
                    id="wd_SR_QT"
                    name="wd_SR_QT"
                    value={wd_SR_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
             
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_LT_NO" className="font-bold">
                 Lot / Serial No.
                  </label>
                  <InputText
                    id="wd_LT_NO"
                    name="wd_LT_NO"
                    value={wd_LT_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_LO_CN" className="font-bold">
                  Location
                  </label>
                  <Dropdown
                    name="wd_LO_CN"
                    value={wd_LO_CN}
                    options={wd_LO_CNOptions}
                    onChange={(e) => onchangeDropDownFormData4("wd_LO_CN", e.value)}
                    placeholder="Select Location"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_ST_DT" className="font-bold">
                  Scrapped Date
                  </label>
                  <Calendar
                    id="wd_ST_DT"
                    name="wd_ST_DT"
                    value={moment(wd_ST_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "wd_ST_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    showIcon
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="AUTO_POST" className="font-bold">
                 Auto Post
                  </label>
                  <InputText
                    id="AUTO_POST"
                    name="AUTO_POST"
                    value={"AUTO_POST"}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
            </div>
      </div> 
    </Dialog>
  );
}
