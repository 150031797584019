import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function PriceAdjustmentDefinitionDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  ad_AD_NM,
  setad_AD_NM,
  ad_AD_LV,
  setad_AD_LV,
  ad_LV_BR,
  setad_LV_BR,
  LinelevelChecked,
  setLinelevelChecked,
  BasketChecked,
  setBasketChecked,
  OrderChecked,
  setOrderChecked,
  VolumeChecked,
  setVolumeChecked,
  AmountChecked,
  setAmountChecked,
  QuantityChecked,
  setQuantityChecked,
  ad_IT_GP,
  setad_IT_GP,
  ad_CU_GP,
  setad_CU_GP,
  ad_PR_EF,
  setad_PR_EF,
  ad_AD_TY,
  setad_AD_TY,
  ad_GL_CD,
  setad_GL_CD
}) {
  const {
    adUkNo,
    ad_DS_AM,
    ad_CE_AM,
    ad_DS_PR,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle,} = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [ad_AD_NMOptions, setad_AD_NMOptions] = useState([]);

  const [ad_IT_GPOptions, setad_IT_GPOptions] = useState([]);
  const [ad_CU_GPOptions, setad_CU_GPOptions] = useState([]);
  const [ad_PR_EFOptions, setad_PR_EFOptions] = useState([]);
  const [ad_AD_TYOptions, setad_AD_TYOptions] = useState([]);
  const [ad_GL_CDOptions, setad_GL_CDOptions] = useState([]);
  // ad name
  const onchangeDropDownFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_AD_NM(value);
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_IT_GP(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_CU_GP(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_PR_EF(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_AD_TY(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_GL_CD(value);
  };
  useEffect(() => {
    fetchDropdownRGST();
    fetchDropdownITGP();
    fetchDropdownCUGP();
    fetchDropdownPREF();
    fetchDropdownTYPE();
    fetchDropdownGLCD();
  }, []);

const fetchDropdownRGST = async () => {
  const Options = await fetchClassCodeDS("AD/NM");
  setad_AD_NMOptions(Options);
};
const fetchDropdownITGP = async () => {
  const Options = await fetchClassCodeDS("IN/PR");
  setad_IT_GPOptions(Options);
};
const fetchDropdownCUGP = async () => {
  const Options = await fetchClassCodeDS("SO/PR");
  setad_CU_GPOptions(Options);
};
const fetchDropdownPREF = async () => {
  const Options = await fetchClassCodeDS("PF/TY");
  setad_PR_EFOptions(Options);
};
const fetchDropdownTYPE = async () => {
  const Options = await fetchClassCodeDS("AD/TY");
  setad_AD_TYOptions(Options);
};
const fetchDropdownGLCD = async () => {
  const Options = await fetchClassCodeCD("IN/GC");
  setad_GL_CDOptions(Options);
};

const token = localStorage.getItem("token");
const company = localStorage.getItem("company");
const domain = localStorage.getItem("domain");
// adjustment level checkboxes
const handleCheckboxChange = (event) => {
  const { name, checked } = event.target;
  if (name === 'line_level') {
    setLinelevelChecked(checked);
    if (checked) {
      setBasketChecked(false);
      setOrderChecked(false);
      setVolumeChecked(false);
      setad_AD_LV('LN');
      setFormData(prevFormData => ({ ...prevFormData, ad_AD_LV: 'LN' }));
    } else {
      setad_AD_LV('');
      setFormData(prevFormData => ({ ...prevFormData, ad_AD_LV: '' }));
    }
  } else if (name === 'basket_level') {
    setBasketChecked(checked);
    if (checked) {
      setLinelevelChecked(false);
      setOrderChecked(false);
      setVolumeChecked(false);
      setad_AD_LV('BL');
      setFormData(prevFormData => ({ ...prevFormData, ad_AD_LV: 'BL' }));
    } else {
      setad_AD_LV('');
      setFormData(prevFormData => ({ ...prevFormData, ad_AD_LV: '' }));
    }
  }else if (name === 'order_level') {
    setOrderChecked(checked);
    if (checked) {
      setLinelevelChecked(false);
      setBasketChecked(false);
      setVolumeChecked(false);
      setad_AD_LV('OR');
      setFormData(prevFormData => ({ ...prevFormData, ad_AD_LV: 'OR' }));
    } else {
      setad_AD_LV('');
      setFormData(prevFormData => ({ ...prevFormData, ad_AD_LV: '' }));
    }
  }else if (name === 'volume_level') {
    setVolumeChecked(checked);
    if (checked) {
      setLinelevelChecked(false);
      setBasketChecked(false);
      setOrderChecked(false);
      setad_AD_LV('VL');
      setFormData(prevFormData => ({ ...prevFormData, ad_AD_LV: 'VL' }));
    } else {
      setad_AD_LV('');
      setFormData(prevFormData => ({ ...prevFormData, ad_AD_LV: '' }));
    }
  }
};
// level  break type checkboxes
const handleCheckboxBreakType = (event) => {
  const { name, checked } = event.target;
  if (name === 'quantity_level') {
    setQuantityChecked(checked);
    if (checked) {
      setAmountChecked(false);
      setad_LV_BR('QT');
      setFormData(prevFormData => ({ ...prevFormData, ad_LV_BR: 'QT' }));
    } else {
      setad_LV_BR('');
      setFormData(prevFormData => ({ ...prevFormData, ad_LV_BR: '' }));
    }
  } else if (name === 'amount_level') {
    setAmountChecked(checked);
    if (checked) {
      setQuantityChecked(false);
      setad_LV_BR('AM');
      setFormData(prevFormData => ({ ...prevFormData, ad_LV_BR: 'AM' }));
    } else {
      setad_LV_BR('');
      setFormData(prevFormData => ({ ...prevFormData, ad_LV_BR: '' }));
    }
  }
};
const isUpdateMode = Boolean(adUkNo);
// DIALOG HEADER
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{adUkNo ? "Revise Price Adjustment Definition" : "Add Price Adjustment Definition"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
        tooltip={isUpdateMode ? "Update" : "Submit"}
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
return (
  <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
    modal
    className="p-fluid"
  >
    <div style={borderbox} >
      <div style={borderbox}>
        <div style={inboxstyle}>
            <div className="formgrid grid">    
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ad_AD_NM" className="font-bold">
                  Adjustment Name
                </label>
                <Dropdown
                  id="ad_AD_NM"
                  name="ad_AD_NM"
                  value={ad_AD_NM}
                  options={ad_AD_NMOptions}
                  onChange={(e) => onchangeDropDownFormData("ad_AD_NM", e.value)}
                  placeholder="Select Name"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div style={inboxstyle}>
              <h1 style={{fontWeight:"bold",fontSize:'1.5rem'}}>Adjustment Level</h1>
                <div className="formgrid grid" >
                  <div style={{width:"20rem"}}>
                    <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
                      <label htmlFor="line_level" className="font-bold">
                        Line Level
                      </label>
                      <input
                        type="checkbox"
                        id="line_level"
                        name="line_level"
                        checked={LinelevelChecked}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
                      <label htmlFor="basket_level" className="font-bold ">
                        Basket Level
                      </label>
                      <input
                        type="checkbox"
                        id="basket_level"
                        name="basket_level"
                        checked={BasketChecked}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
                      <label htmlFor="order_level" className="font-bold ">
                        Order Level
                      </label>
                      <input
                        type="checkbox"
                        id="order_level"
                        name="order_level"
                        checked={OrderChecked}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
                      <label htmlFor="volume_level" className="font-bold">
                        Volume Level
                      </label>
                      <input
                        type="checkbox"
                        id="volume_level"
                        name="volume_level"
                        checked={VolumeChecked}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div style={inboxstyle}>
                <div className="formgrid grid" >
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ad_IT_GP" className="font-bold">
                      Item Group
                    </label>
                    <Dropdown
                      id="ad_IT_GP"
                      name="ad_IT_GP"
                      value={ad_IT_GP}
                      options={ad_IT_GPOptions}
                      onChange={(e) => onchangeDropDownFormData1("ad_IT_GP", e.value)}
                      placeholder="Select Item Group"
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ad_CU_GP" className="font-bold">
                      Customer Group
                    </label>
                    <Dropdown
                      id="ad_CU_GP"
                      name="ad_CU_GP"
                      value={ad_CU_GP}
                      options={ad_CU_GPOptions}
                      onChange={(e) => onchangeDropDownFormData2("ad_CU_GP", e.value)}
                      placeholder="Select Customer Group"
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ad_PR_EF" className="font-bold">
                      Preference
                    </label>
                    <Dropdown
                      id="ad_PR_EF"
                      name="ad_PR_EF"
                      value={ad_PR_EF}
                      options={ad_PR_EFOptions}
                      onChange={(e) => onchangeDropDownFormData3("ad_PR_EF", e.value)}
                      placeholder="Select Preference"
                    />
                  </div>
                  </div>
                  <div className="formgrid grid" >
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ad_AD_TY" className="font-bold">
                      Type
                    </label>
                    <Dropdown
                      id="ad_AD_TY"
                      name="ad_AD_TY"
                      value={ad_AD_TY}
                      options={ad_AD_TYOptions}
                      onChange={(e) => onchangeDropDownFormData4("ad_AD_TY", e.value)}
                      placeholder="Select Type"
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ad_GL_CD" className="font-bold">
                      GL Code
                    </label>
                    <Dropdown
                      id="ad_GL_CD"
                      name="ad_GL_CD"
                      value={ad_GL_CD}
                      options={ad_GL_CDOptions}
                      onChange={(e) => onchangeDropDownFormData5("ad_GL_CD", e.value)}
                      placeholder="Select GL Code"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div style={inboxstyle}>
              <h1 style={{fontWeight:"bold",fontSize:'1.5rem'}}>Level Break Type</h1>
                <div className="formgrid grid" >
                  <div style={{width:"20rem"}}>
                    <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
                      <label htmlFor="quantity_level" className="font-bold d-block mb-1">
                        Quantity Level
                      </label>
                      <input
                        type="checkbox"
                        id="quantity_level"
                        name="quantity_level"
                        checked={QuantityChecked}
                        onChange={handleCheckboxBreakType}
                      />
                    </div>
                    <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
                      <label htmlFor="amount_level" className="font-bold d-block mb-1">
                        Amount Level
                      </label>
                      <input
                        type="checkbox"
                        id="amount_level"
                        name="amount_level"
                        checked={AmountChecked}
                        onChange={handleCheckboxBreakType}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div style={inboxstyle}>
                <div className="formgrid grid" >
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ad_CE_AM" className="font-bold">
                    Ceiling Amount
                  </label>
                    <InputText
                      id="ad_CE_AM"
                      name="ad_CE_AM"
                      value={ad_CE_AM}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div> 
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ad_DS_PR" className="font-bold">
                    Discount %
                  </label>
                    <InputText
                      id="ad_DS_PR"
                      name="ad_DS_PR"
                      value={ad_DS_PR}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div> 
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ad_DS_AM" className="font-bold">
                    Discount Amount
                  </label>
                    <InputText
                      id="ad_DS_AM"
                      name="ad_DS_AM"
                      value={ad_DS_AM}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div> 


                </div>
              </div>
            </div>
          </div>
      </div>
    </div>

  </Dialog>
);
}
