import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import moment from "moment";
import { InputMask } from "primereact/inputmask";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function TimeEntryDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangeFormData,
  onchangeDropDownFormData1,
  timeentryTableData,
  settimeentryTableData,
  deleteRow,
  addRow,
}) {

  const {plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  // search
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");

  // dropdown
  const [et_SH_FTOptions, setet_SH_FTOptions] = useState("");
  const { etUkNo } = data;
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const onDateChange = (name, index, value) => {
    if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const handleFormDataChange = (name, value) => {
    const index = name.split("_")[1];
    onchangeDropDownFormData1(name, value, index);
  };
  useEffect(() => {
    fetchDropdownOptions1();
  }, []);

  const url = `${domain}/api/ClassCode/All`;
  // SHIFT---EM/SH
  const parameter1 = "EM/SH";
  const finalUrl1 = `${url}/${parameter1}`;
  // Fetch dropdown options
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setet_SH_FTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);
  const [addressType, setaddressType] = useState("E");
  const openEmployeePopup = (e) => {
    setEmployeePopupVisible(true);
    setSelectedRowIndex(e);
  };
  // Handle data selection in employee popup
  const handleDataSelect = (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("et_EM_NO", data.abAdNo, SelectedRowIndex);
    onchangeFormData("name", data.ab_AD_NM, SelectedRowIndex);
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  // Handle form data change
  const onChangeTime=(name,value,index)=>{
      value= moment(value, "HH:mm:ss").format("HHmmss");
      onchangeFormData(name, value,index);
  }
  const onchangedata = (e) => {
    const { name, value } = e.target;

    if (name === "et_IN_DT" || name === "et_OU_DT") {
      const formattedDate = moment(value, "DD/MM/YYYY", true).format( "YYYYMMDD" );
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    }else {
      onchangeFormData(name, value);
    }
  };
  
  function updateTotalHours(index) {
    
    let et_IN_DT = timeentryTableData[index].et_IN_DT;
    let et_OU_DT = timeentryTableData[index].et_OU_DT;
    let et_OU_TM = timeentryTableData[index].et_OU_TM;
    let et_IN_TM = timeentryTableData[index].et_IN_TM;
      if(et_IN_DT!=undefined && et_OU_DT!=undefined && et_OU_DT!="" && et_IN_DT!="" ){
        let inTime=moment(et_IN_TM, "HHmmss").format("HH:mm:ss");
        let outTime=moment(et_OU_TM, "HHmmss").format("HH:mm:ss");
        let indate = moment(et_IN_DT, "YYYYMMDD").format("YYYY-MM-DD");
        let outDate = moment(et_OU_DT, "YYYYMMDD").format("YYYY-MM-DD");
        const checkInDateTime = moment(`${indate} ${inTime}`, "YYYY-MM-DD HH:mm:ss");
        const checkOutDate = moment(`${outDate} ${outTime}`, "YYYY-MM-DD HH:mm:ss");
        const duration = moment.duration(checkOutDate.diff(checkInDateTime));
        const totalHours = duration.asHours();
        var value = totalHours.toFixed(2);
        onchangeFormData("et_TO_HR",value,index);
      }else{
        onchangeFormData("et_TO_HR","",index);
      }
  }
// const totalhrs = `${domain}/api/TimeEntry/GetOnChangeOfTime`
//   const calculateTotalHours = async (requestData) => {
//     try {
//       const response = await axios.post(totalhrs, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//           "Access-Control-Allow-Origin": "http://localhost:3000",
//           "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
//         },
//         params: requestData,
//       });

//       return response.data.timeDifference;
//     } catch (error) {
//       console.error("Error fetching total hours:", error);
//       return null;
//     }
//   };
const isUpdateMode = Boolean(etUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{ etUkNo ? "Revise Time Entry" : "Add Time Entry" }</h5>
    <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
        tooltip={isUpdateMode ? "Update" : "Submit"}
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
       <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
          <table className="custom-table" id="timeentryTable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th style={{ display: "none" }}>Employee </th>
                <th>Employee <span style={{ color: "red" }}>*</span> </th>
                <th>Shift</th>
                <th>In-Date</th>
                <th>In Time</th>
                <th>Out Date</th>
                <th>Out Time</th>
                <th>Total Hrs </th>
              </tr>
            </thead>
            <tbody>
              {timeentryTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`etUkNo_${index}`}
                      name={`etUkNo_${index}`}
                      defaultValue={row.etUkNo}
                    />
                  </td>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`et_EM_NO_${index}`}
                      name={`et_EM_NO_${index}`}
                      value={row.et_EM_NO ? row.et_EM_NO : row[`et_EM_NO_${index}`]}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <InputText
                        id={`name_${index}`}
                        name={`name_${index}`}
                        onChange={(e) => onchangedata(e)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={row.name ? row.name : row[`name_${index}`] }
                      />
                      {showSearchIcon && (
                        <i
                          className="pi pi-search"
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "119%",
                            marginTop: "7%",
                            cursor: "pointer",
                          }}
                          onClick={(e) => openEmployeePopup(index)}
                        ></i>
                      )}
                    </div>
                  </td>
                  <td>
                    <Dropdown
                      id={`et_SH_FT_${index}`}
                      name={`et_SH_FT_${index}`}
                      value={data[`et_SH_FT_${index}`] ? data[`et_SH_FT_${index}`] : row.et_SH_FT}
                      options={et_SH_FTOptions}
                      onChange={(e) => handleFormDataChange(`et_SH_FT_${index}`, e.value)}
                      placeholder="Select SHIFT"
                    />
                  </td>
                  <td>
                    <Calendar
                      id={`et_IN_DT_${index}`}
                      name={`et_IN_DT_${index}`}
                      value={row.et_IN_DT ? moment(row.et_IN_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => {
                        onDateChange("et_IN_DT", index, e.value);
                        updateTotalHours(index);
                      }}
                      dateFormat="dd/mm/yy"
                    />
                  </td>
                  <td>
                    <InputMask
                      id={`et_IN_TM_${index}`}
                      name={`et_IN_TM_${index}`}
                      mask="99:99:99"
                      value={moment(row.et_IN_TM, "HHmmss").format("HH:mm:ss")}
                      onBlur={(e) => {
                        onChangeTime("et_IN_TM",e.target.value,index);
                        updateTotalHours(index);
                      }}
                    />
                  </td>
                  <td>
                    <Calendar
                      id={`et_OU_DT_${index}`}
                      name={`et_OU_DT_${index}`}
                      value={row.et_OU_DT ? moment(row.et_OU_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => {
                        onDateChange("et_OU_DT", index, e.value);
                        updateTotalHours(index);
                      }}
                      dateFormat="dd/mm/yy"
                    />
                  </td>
                  <td>
                    <InputMask
                      id={`et_OU_TM_${index}`}
                      name={`et_OU_TM_${index}`}
                      mask="99:99:99"
                      onBlur={(e) => {
                        onChangeTime("et_OU_TM",e.target.value,index);
                        updateTotalHours(index);
                      }}
                      value={moment(row.et_OU_TM, "HHmmss").format("HH:mm:ss")}
                    />
                  </td>
                  <td>
                    <InputText
                      id={`et_TO_HR_${index}`}
                      name={`et_TO_HR_${index}`}
                      defaultValue={row.et_TO_HR}
                      readOnly
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressType}
      />
    </Dialog>
  );
}
