import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import FinancialForeCastDialog from "./FinancialForeCastDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  fbUkNo: "",
  fb_DO_CO: "",
  fb_CT_RY: "",
  fb_FI_YR:"",   
  fb_AC_ID:"",
  fb_CO_CT:"",
  fb_OB_AC:"",
  fb_OB_SB:"",
  fb_LV_DT:"",
  fb_PE_CD: "",
  fb_BU_AM: "",
  fb_ST_CD: "",
};

const initialFilters = {
  fb_DO_CO: { value: "", operation: "" },
  fb_CT_RY: { value: "", operation: "" },
  fb_FI_YR: { value: "", operation: "" },
  fb_AC_ID: { value: "", operation: "" },
  fb_CO_CT: { value: "", operation: "" },
  fb_OB_AC: { value: "", operation: "" },
  fb_OB_SB: { value: "", operation: "" },
  fb_LV_DT: { value: "", operation: "" },
  fb_PE_CD: { value: "", operation: "" },
  fb_BU_AM: { value: "", operation: "" },
  fb_ST_CD: { value: "", operation: "" },
};

const FinancialForeCastMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
        copyButtonStyles,checkboxStyle,tableHeaderStyle,
        inputStyle,tableCellStyle,
       } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);


  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [FinancialForeCasttable, setFinancialForeCasttable] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFinancialForeCasttable([{}]);
    setFormData(initialValue);
    setOpen(true);
  };

  const handleClose = () => {
    setFinancialForeCasttable([{}]);
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/FinancialForeCast/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const FinancialForeCastData = response.data;
          delete FinancialForeCastData.fbUkNo;
          setFormData(FinancialForeCastData);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [fb_DO_CO, setfb_DO_CO] = useState("");
  const handleDefault = async () => {
    setfb_DO_CO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/FinancialForeCast/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/FinancialForeCast/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const FinancialForeCastData = response.data;
        setFinancialForeCasttable(FinancialForeCastData.FinancialForeCastList);
        setFormData(FinancialForeCastData);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values () {
    const UK_NO = FormData.fbUkNo;
    const AD_NO = FormData.fb_CT_RY;
    const CO_TY = FormData.fb_FI_YR;
    const CO_CD = document.getElementById("fb_DO_CO");
    // const FM_AC = document.getElementById("ACCOUNT");
    // const TO_AC = document.getElementById("rc_CO_LV");
    
      const table = document.getElementById("FinancialForeCasttable");
      const totalRowCount = table.tBodies[0].rows.length;
      const FinancialForeCastList = [];
      for (let i = 0; i < totalRowCount; i++) {  
        const Details = {
          fbUkNo:  document.getElementById("fbUkNo_" + i).value,
              fb_CO_CT: document.getElementById("fb_CO_CT_" + i).value,
              fb_OB_AC: document.getElementById("fb_OB_AC_" + i).value,
              fb_OB_SB: document.getElementById("fb_OB_SB_" + i).value,
              fb_AC_ID: document.getElementById("fb_AC_ID_" + i).value,
              fb_AC_DS: document.getElementById("fb_AC_DS_" + i).value,
              fb_LV_DT :document.getElementById("fb_LV_DT_" + i).value,
              fb_PE_CD :document.getElementById("fb_PE_CD_" + i).value,
              fb_BU_AM :document.getElementById("fb_BU_AM_" + i).value,
              fb_ST_CD :table.tBodies[0].rows[i].cells[9].children[0].children[1].children[0].value,
              fb_PE_01 :document.getElementById("fb_PE_01_" + i).value,
              fb_PE_02: document.getElementById("fb_PE_02_" + i).value,
              fb_PE_03 :document.getElementById("fb_PE_03_" + i).value,
              fb_PE_04 :document.getElementById("fb_PE_04_" + i).value,
              fb_PE_05 :document.getElementById("fb_PE_05_" + i).value,
              fb_PE_06 :document.getElementById("fb_PE_06_" + i).value,
              fb_PE_07: document.getElementById("fb_PE_07_" + i).value,
              fb_PE_08 :document.getElementById("fb_PE_08_" + i).value,
              fb_PE_09 :document.getElementById("fb_PE_09_" + i).value,
              fb_PE_10 :document.getElementById("fb_PE_10_" + i).value,
              fb_PE_11 :document.getElementById("fb_PE_11_" + i).value,
              fb_PE_12 :document.getElementById("fb_PE_12_" + i).value,
        };
        FinancialForeCastList.push(Details);
      }
      requestBodyInput = {
        FinancialForeCastList,
        fbUkNo: UK_NO,
            fb_CT_RY: AD_NO,
            fb_FI_YR: CO_TY,
            fb_DO_CO: CO_CD.value,
      };
    }

  const handleFormSubmit = () => {    
    values();
      if (FormData.fbUkNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/FinancialForeCast/Update`;
        if (confirm) {
          axios
            .put(update, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        axios
          .post(`${domain}/api/FinancialForeCast/Add`, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
          handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    } ;

    const handleConfirm = () => {    
      values();
      const ukNo = FormData.fbUkNo;
        if (ukNo) {
          const confirm = window.confirm("Do you want to Confirm ?");
          const update = `${domain}/api/FinancialForeCast/ConfirmFinancialForeCast`;
          if (confirm) {
            axios
              .post(update, requestBodyInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error updating data:", error);
              });
          }
      }
    };


  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_DO_CO"] ? filters["fb_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_DO_CO",
                        e.target.value,
                        filters["fb_DO_CO"]
                          ? filters["fb_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_CT_RY"] ? filters["fb_CT_RY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_CT_RY",
                        e.target.value,
                        filters["fb_CT_RY"]
                          ? filters["fb_CT_RY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_FI_YR"] ? filters["fb_FI_YR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_FI_YR",
                        e.target.value,
                        filters["fb_FI_YR"]
                          ? filters["fb_FI_YR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_AC_ID"] ? filters["fb_AC_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_AC_ID",
                        e.target.value,
                        filters["fb_AC_ID"]
                          ? filters["fb_AC_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["fb_CO_CT"] ? filters["fb_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_CO_CT",
                        e.target.value,
                        filters["fb_CO_CT"]
                          ? filters["fb_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_OB_AC"] ? filters["fb_OB_AC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_OB_AC",
                        e.target.value,
                        filters["fb_OB_AC"]
                          ? filters["fb_OB_AC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_OB_SB"] ? filters["fb_OB_SB"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_OB_SB",
                        e.target.value,
                        filters["fb_OB_SB"]
                          ? filters["fb_OB_SB"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_LV_DT"] ? filters["fb_LV_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_LV_DT",
                        e.target.value,
                        filters["fb_LV_DT"]
                          ? filters["fb_LV_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_PE_CD"] ? filters["fb_PE_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_PE_CD",
                        e.target.value,
                        filters["fb_PE_CD"]
                          ? filters["fb_PE_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_BU_AM"] ? filters["fb_BU_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_BU_AM",
                        e.target.value,
                        filters["fb_BU_AM"]
                          ? filters["fb_BU_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fb_ST_CD"] ? filters["fb_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fb_ST_CD",
                        e.target.value,
                        filters["fb_ST_CD"]
                          ? filters["fb_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Century</th>
                <th style={tableHeaderStyle}>Financial Year</th>
                <th style={tableHeaderStyle}>Account ID</th>
                <th style={tableHeaderStyle}>Cost Center</th>
                <th style={tableHeaderStyle}>Account </th>
                <th style={tableHeaderStyle}>Subsidary</th>
                <th style={tableHeaderStyle}>Level</th>
                <th style={tableHeaderStyle}>post Code </th>
                <th style={tableHeaderStyle}>Budget Amount</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.fbUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.fbUkNo}
                        checked={selectedRows.includes(rowData.fbUkNo)}
                        onChange={() => handleRowSelect(rowData.fbUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.fb_DO_CO}</td>
                    <td style={tableCellStyle}>{rowData.fb_CT_RY}</td>
                    <td style={tableCellStyle}>{rowData.fb_FI_YR}</td>
                    <td style={tableCellStyle}>{rowData.fb_AC_ID}</td>
                    <td style={tableCellStyle}>{rowData.fb_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.fb_OB_AC}</td>
                    <td style={tableCellStyle}>{rowData.fb_OB_SB}</td>
                    <td style={tableCellStyle}>{rowData.fb_LV_DT}</td>
                    <td style={tableCellStyle}>{rowData.fb_PE_CD}</td>
                    <td style={tableCellStyle}>{rowData.fb_BU_AM}</td>
                    <td style={tableCellStyle}>{rowData.fb_ST_CD}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <FinancialForeCastDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        fb_DO_CO={fb_DO_CO}
        handleConfirm={handleConfirm}
        FinancialForeCasttable={FinancialForeCasttable} 
        setFinancialForeCasttable={setFinancialForeCasttable}  
      />
    </div>
  );
};

export default FinancialForeCastMain;
