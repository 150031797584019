import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
import Costcenter  from "views/common/CostCentreDrpDwn";
export default function InventoryAdjustmentDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  InventoryAdjustmentTableData,
  setInventoryAdjustmentTableData,
  setic_TR_UM,
  ic_CO_CT,
  setic_LO_CN,
  ic_DO_CO,
  ic_DO_TY,
  FromCostCentreDropdown,
  ic_CO_TY,
}) {
  const {
    icUkNo,
    ic_DO_NO,
    ic_TR_DT,
    ic_GL_DT,
    ic_RF_CE,
  } = data;

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [ic_CO_CTOptions, setic_CO_CTOptions] = useState([]);
  const [ic_TR_UMOptions, setic_TR_UMOptions] = useState([]);
  const [ic_LO_CNOptions, setic_LO_CNOptions] = useState([]);
  const [ic_LT_NOOptions, setic_LT_NOOptions] = useState([]);



  //   const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
  //    const requestData={
  //     "lm_CO_CT":value,
  // }
  // try {
  //   const response = await  axios.post(Url, requestData, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //       "Access-Control-Allow-Origin": "http://localhost:3000",
  //       "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
  //     },
  //   });

  //   if (response.status !== 200) {
  //     throw new Error(`API request failed with status: ${response.status}`);
  //   }
  //   const dataItMt = response.data.Result;
  //   if (dataItMt ) {
  //     for (var i=0; i < InventoryAdjustmentTableData.length; i++) {
  //       LocationDropdown(`ic_LO_CN_${i}`, dataItMt.lm_LO_CN); 
  //     }
      
  //     console.error("Invalid API response:", data);
  //   }
  // } catch (error) {
  //   console.error("Error fetching data:", error);
  // }

 const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setic_TR_UM(value);
};





const LocationDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setic_LO_CN(value);
};


;

  useEffect(() => {
    FromCostCentre();
    UM();
    Location();

  }, []);

  const FromCostCentre = async () => {
    const Options = await fetchCostCenter();
    setic_CO_CTOptions(Options);
  };

  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setic_TR_UMOptions(Options);
  };

  const Location = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setic_LO_CNOptions(Options);
  };




  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ItemPopupVisible, setItemVisible] = useState(false);
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const [ic_IT_NM, setic_IT_NM] = useState("");
  const [itemDES, setitemDES] = useState("");
  const handleItem = async (Result) => {
    setic_IT_NM(Result.im_IT_NM);
    setitemDES(Result.im_IT_DS);
    onchangeFormData("ic_IT_NM", Result.im_IT_NM, SelectedRowIndex);
    onchangeFormData("itemDES", Result.im_IT_DS, SelectedRowIndex);
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
      "im_IT_NM":Result.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      UMDropdown(`ic_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
    } else {
      console.error("Invalid API response:", data);
    }
    await UnitCost(Result.im_IT_NM);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItemVisible(false);
    setShowSearchIcon(false);
  };

  const UnitCost =async (im_IT_NM) => {
    const Url = `${domain}/api/ItemCost/GetUnitCost`;
    const requestData={
      "ic_IT_NM": im_IT_NM,
      "ic_CO_CT": ic_CO_CT,
      "ic_CO_TY": document.getElementById (`ic_CO_TY_${SelectedRowIndex}`).value,
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
  
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data;
    if (dataItMt ) {
      document.getElementById (`ic_UN_CT_${SelectedRowIndex}`).value =  dataItMt.ic_CO_ST;
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
   
  };



  const addRow = () => {
    setInventoryAdjustmentTableData([...InventoryAdjustmentTableData, {}]);
  };
  
  const deleteRow = () => {
    if (InventoryAdjustmentTableData.length > 0) {
      const updatedData = [...InventoryAdjustmentTableData];
      updatedData.pop();
      setInventoryAdjustmentTableData(updatedData);
    }
  };  
const isUpdateMode = Boolean(icUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  const [selectedTRDate, setSelectedTRDate] = useState(moment().toDate());
  const handleDateTRChange = (e) => {
    setSelectedTRDate(e.value);
    onchangedata({ target: { name: "ic_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
    onchangedata({ target: { name: "ic_GL_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const [selectedGLDate, setSelectedGLDate] = useState(moment().toDate());
  const handleDateGLChange = (e) => {
    setSelectedGLDate(e.value);
    onchangedata({ target: { name: "ic_GL_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "80rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={icUkNo ? "Revise Inventory Adjustment" : "Add Inventory Adjustment"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ic_DO_NO" className="font-bold">
          Transaction No
          </label>
          <InputText disabled
            id="ic_DO_NO"
            name="ic_DO_NO"
            defaultValue={ic_DO_NO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ic_DO_TY" className="font-bold">
           Type 
          </label>
          <InputText disabled
            id="ic_DO_TY"
            name="ic_DO_TY"
            value={ic_DO_TY}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ic_DO_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="ic_DO_CO"
            name="ic_DO_CO"
            value={ic_DO_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ic_TR_DT" className="font-bold">
           Transfer Date
           </label>
              <Calendar
              id="ic_TR_DT"
              name="ic_TR_DT"
              value={moment(ic_TR_DT, "YYYYMMDD").toDate()}
              onChange={handleDateTRChange}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ic_GL_DT" className="font-bold">
           GL Date
            </label>
            <Calendar disabled
              id="ic_GL_DT"
              name="ic_GL_DT"
              value={moment(ic_GL_DT, "YYYYMMDD").toDate()}
              onChange={handleDateGLChange}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="auto_POST" className="font-bold">
            Auto Post
            </label>
            <InputText disabled
                    id="auto"
                    name="auto"
                    value={"Yes"}
            />
          </div> 
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ic_CO_CT" className="font-bold">
                    Cost Centre
                    </label>
                    <Dropdown
                    name="ic_CO_CT"
                    value={ic_CO_CT}
                    options={ic_CO_CTOptions}
                    onChange={(e) => FromCostCentreDropdown("ic_CO_CT", e.value)}
                    placeholder="Select Cost Centre"
                  />
                  </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ic_RF_CE" className="font-bold">
            Reference
            </label>
            <InputText
              id="ic_RF_CE"
              name="ic_RF_CE"
              value={ic_RF_CE}
              onChange={(e) => onchangedata(e)}
            />
          </div>   
          
          </div>
          </div>  
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
        >
      
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="operationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Item <span style={{ color: 'red' }}>*</span> </th>
              <th>Description</th>
              <th>Quantity</th>
              <th>UM</th>
              <th>Location</th>
              <th>Lot No</th>
              <th>Unit Cost</th>
              <th>Amount</th>
              <th>Expiry Date</th>
              <th>Remarks</th>
              <th style={{ display: "none" }}>Type</th>
            </tr>
          </thead>
          <tbody>
            {InventoryAdjustmentTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`icUkNo_${index}`}
                    name={`icUkNo_${index}`}
                    value={row.icUkNo}
                  />
                </td>
                <td style={{ display: "flex" }}>
                <InputText
               style={{ width: "130px" }}
               id={`ic_IT_NM_${index}`}
               name={`ic_IT_NM_${index}`}
               onChange={(e) => onchangeFormData(e)}
               onFocus={() => setShowSearchIcon(true)}
               value={row.ic_IT_NM ? row.ic_IT_NM: row[`${"ic_IT_NM"}_${index}`]}
               />
              {showSearchIcon && (
               <i
               className="pi pi-search"
               style={{
               color: "var(--primary-color)",
               fontSize: "119%",
               marginTop: "15%",
               cursor: "pointer",
                }}
               onClick={(e) => openItemPopup(index)}
              ></i>
              )}
             </td>
             <td>
             <InputText disabled
              style={{ width: "200px" }}
               id={`itemDES_${index}`}
               name={`itemDES_${index}`}
               defaultValue={row.itemDES ? row.itemDES : row[`${"itemDES"}_${index}`]}
               readOnly
               />
              </td>

               <td >
              <InputText  style={{width:"90px"}}
              id={`ic_TR_QT_${index}`}
              name={`ic_TR_QT_${index}`}
              defaultValue={row.ic_TR_QT}
              onChange={(e) => onchangedata(e)}
             />
             </td>

            <td>
            <Dropdown
            id={`ic_TR_UM_${index}`}
            name={`ic_TR_UM_${index}`}
            value={ data[`ic_TR_UM_${index}`] ? data[`ic_TR_UM_${index}`]: row.ic_TR_UM}
            options={ic_TR_UMOptions}
            onChange={(e) =>UMDropdown(`ic_TR_UM_${index}`, e.value)}
            placeholder="Select UM"
          />
          </td>

          <td>
          <Dropdown
          id={`ic_LO_CN_${index}`}
          name={`ic_LO_CN_${index}`}
          value={data[`ic_LO_CN_${index}`] ? data[`ic_LO_CN_${index}`] : row.ic_LO_CN}
          options={ic_LO_CNOptions}
          onChange={(e) => LocationDropdown(`ic_LO_CN_${index}`, e.value)} // Pass index here
          placeholder="Select Location"
        />
        </td>

        <td>
        <InputText  style={{width:"90px"}}
        id={`ic_LT_NO_${index}`}
        name={`ic_LT_NO_${index}`}
        defaultValue={row.ic_LT_NO}
        onChange={(e) => onchangedata(e)}
         />
        </td>
          
       
        <td >
        <InputText  style={{width:"90px"}}
        id={`ic_UN_CT_${index}`}
        name={`ic_UN_CT_${index}`}
        defaultValue={row.ic_UN_CT}
        onChange={(e) => onchangedata(e)}
         />
        </td>

        <td >
        <InputText disabled style={{width:"90px"}}
        id={`ic_FR_AM_${index}`}
        name={`ic_FR_AM_${index}`}
        defaultValue={row.ic_FR_AM}
        onChange={(e) => onchangedata(e)}
        />
        </td>

        <td>
          <Calendar style={{width:"150px"}}
           id={`LOT_EXPIRY_DATE_${index}`}
           name={`LOT_EXPIRY_DATE_${index}`}
           value={row.LOT_EXPIRY_DATE? moment(row.LOT_EXPIRY_DATE, "YYYYMMDD").toDate(): null}
           onChange={(e) => onDateChange({ target: { name: "LOT_EXPIRY_DATE", value: moment(e.value).format("YYYYMMDD") } })}
           dateFormat="dd/mm/yy"
          />
          </td>

          <td >
          <InputText style={{width:"300px"}}
          id={`ic_RM_RK_${index}`}
          name={`ic_RM_RK_${index}`}
          defaultValue={row.ic_RM_RK}
           onChange={(e) => onchangedata(e)}
          />
          </td>
          <td style={{display:"none"}} >
          <InputText  disabled style={{width:"90px" }}
            id={`ic_CO_TY_${index}`}
            name={`ic_CO_TY_${index}`}
            defaultValue={ic_CO_TY}
          />
        </td>
                
                  
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
