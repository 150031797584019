import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import UpdateAttendenceDialog from "./UpdateAttendenceDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  uaUkNo:"",
  name:"",
  em_CC_NM:"",
  em_DE_PT:"",
  ua_CO_NO:"",
  ua_EM_NO:"",
  ua_AV_DA:"",
  ua_TO_DT:"",
  ua_PE_NO:"",
  ua_FY_YR:"",
  ua_FR_DT:"",
  ua_CT_RY:"",
  ua_HL_DA:"",
  ua_AB_DA:"",
};
const initialFilters = {
  ua_EM_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },
  em_CC_NM: { value: "", operation: "" },
  em_DE_PT: { value: "", operation: "" },
  ua_CT_RY: { value: "", operation: "" },
  ua_FY_YR: { value: "", operation: "" },
  ua_PE_NO: { value: "", operation: "" },
  ua_FR_DT: { value: "", operation: "" },
  ua_TO_DT: { value: "", operation: "" },
  ua_AV_DA: { value: "", operation: "" },
  ua_HL_DA: { value: "", operation: "" },
  ua_AB_DA: { value: "", operation: "" }
};
const UpdateAttendenceMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,inputStyle1
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [em_CC_NM, setem_CC_NM] = useState("");
  const [ua_FY_YR, setua_FY_YR] = useState("");
  const [ua_PE_NO, setua_PE_NO] = useState("");
  const [em_DE_PT, setem_DE_PT] = useState("");
  const [UpdateAttendencetable, setUpdateAttendencetable] = useState([{}]);

const buildSearchRequestDto = () => {
return Object.keys(filters)
  .filter((column) => filters[column].value)
  .map((column) => ({
    column: column.toUpperCase(),
    value: filters[column].value,
    operation: filters[column].operation,
  }));
};
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;
    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [emEMNOList, setEmEMNOList] = useState([]);
  const emplist = `${domain}/api/EmployeeMaster/employees`
  const [leaveDaysList, setLeaveDaysList] = useState(UpdateAttendencetable.map(() => ''));
  const handleAddButtonClick = async () => {
    setLeaveDaysList(null)
    setUpdateAttendencetable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);  
    setem_CC_NM(null);
    setua_FY_YR(null);
    setua_PE_NO(null);
    setem_DE_PT(null); 
    const req = {
      "searchRequestDto":[]
    };
    try {
      const response = await axios.post(emplist, req, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const datalist = response.data;
      const employees = [];
      let size = datalist.length;
      for(let i =0; i< size; i++ ){
        const dtls = {
          "ua_EM_NO" : datalist[i].emEmNo,
          "name" : datalist[i].name,
          "em_CC_NM" : datalist[i].em_CC_NM,
          "em_DE_PT" : datalist[i].em_DE_PT,
        }
        employees.push(dtls);
      }
      setUpdateAttendencetable(employees);
      setOpen(true);
    } catch (error) {
      console.error("Error in Formatted Dates", error);
    }
  };
  const handleClose = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setem_CC_NM(null);
    setua_FY_YR(null);
    setua_PE_NO(null);
    setem_DE_PT(null);
    setOpen(false);
  };
  // const addRow = () => {
  //   setUpdateAttendencetable([...UpdateAttendencetable, {}]);
  // };
  // const deleteRow = () => {
  //   if (UpdateAttendencetable.length > 0) {
  //     const updatedData = [...UpdateAttendencetable];
  //     updatedData.pop();
  //     setUpdateAttendencetable(updatedData);
  //   }
  // };
  // const deleteRow = (index) => {
  //   const updatedTable = [...UpdateAttendencetable];
  //   updatedTable.splice(index, 1);
  //   setUpdateAttendencetable(updatedTable);
  // };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/UpdateAttendance/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const UpAtteendenceData = response.data;
          delete UpAtteendenceData.uaUkNo;
          const updatedAtteendence = UpAtteendenceData.updateAttendances.map(target => {
            const UpdatedUpAtteendenceData = { ...target };
            delete UpdatedUpAtteendenceData.uaUkNo;
            return UpdatedUpAtteendenceData;
          });
          setUpdateAttendencetable(updatedAtteendence);
          setFormData(updatedAtteendence[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleSearch();
  }, [currentPage, pageSize, filters]);
  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/UpdateAttendance/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data;
      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    } 
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleSearch();
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value,index=null) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const fieldName=(FormData && FormData.uaUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
    UpdateAttendencetable[index][fieldName]=value;
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({ ...prevData,[name]: value,}));
    setem_CC_NM(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData((prevData) => ({ ...prevData,[name]: value,}));
    setua_FY_YR(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData((prevData) => ({ ...prevData,[name]: value,}));
    setua_PE_NO(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData((prevData) => ({ ...prevData,[name]: value,}));
    setem_DE_PT(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/UpdateAttendance/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const UpdateAtteendenceData = response.data;
        setUpdateAttendencetable(UpdateAtteendenceData.updateAttendances);
        setFormData(UpdateAtteendenceData.updateAttendances[0]);
        // setFormData(UpdateAtteendenceData);
        setSelectedemEMNO(response.data.name);
        // setem_CC_NM(response.data.em_CC_NM);
        // setua_FY_YR(response.data.ua_FY_YR);
        setua_PE_NO(response.data.ua_PE_NO);
        // setem_DE_PT(response.data.em_DE_PT);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBody ={};
function values () {
   const table = document.getElementById("UpdateAttendencetable");
          const totalRowCount = table.tBodies[0].rows.length; 
          const updateAttendances = [];
          for (let i = 0; i < totalRowCount; i++) {
            const upAttend = {
              uaUkNo: FormData.uaUkNo,
              ua_EM_NO:  document.getElementById("ua_EM_NO_" + i).value,
              name: document.getElementById("name_" + i).value,
              em_CC_NM: FormData["em_CC_NM_" + i],
              em_DE_PT: FormData["em_DE_PT_" + i],
              ua_AB_DA: document.getElementById("ua_AB_DA_" + i).value,
              ua_EL_DA: document.getElementById("ua_EL_DA_" + i).value,
            };
            updateAttendances.push(upAttend);
          }
        requestBody = {
            updateAttendances,
            uaUkNo  : FormData.uaUkNo,
            ua_FR_DT: FormData.ua_FR_DT,
            ua_HL_DA: document.getElementById("ua_HL_DA").value,
            ua_TO_DT: FormData.ua_TO_DT,
            ua_FY_YR: FormData.ua_FY_YR,
            ua_PE_NO: FormData.ua_PE_NO,
            ua_AV_DA: document.getElementById("ua_AV_DA").value,
          };
}
  const handleFormSubmit = () => { 
    values ();
      if ( FormData.uaUkNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/UpdateAttendance/Update`;
        if (confirm) {
          axios.put(update, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        values ()
        axios.post(`${domain}/api/UpdateAttendance/Add`, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
  };

    // confirm
    const handleConfirm = () => {    
      values();
      const ukNo = FormData.uaUkNo;
        if (ukNo) {
          FormData.ua_CO_NO = company;
          const confirm = window.confirm("Do you want to Confirm ?");
          const urlconfirm = `${domain}/api/UpdateAttendance/ConfirmUpdateAttendance`;
          if (confirm) {
            axios.post(urlconfirm, requestBody, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error updating data:", error);
              });
          }
        } 
  } ;

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={() => {
          handleAddButtonClick();
          // EmplList();
        }}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ua_EM_NO"] ? filters["ua_EM_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_EM_NO",
                        e.target.value,
                        filters["ua_EM_NO"]
                          ? filters["ua_EM_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_CC_NM"] ? filters["em_CC_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_CC_NM",
                        e.target.value,
                        filters["em_CC_NM"]
                          ? filters["em_CC_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_DE_PT"] ? filters["em_DE_PT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_DE_PT",
                        e.target.value,
                        filters["em_DE_PT"]
                          ? filters["em_DE_PT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ua_CT_RY"] ? filters["ua_CT_RY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_CT_RY",
                        e.target.value,
                        filters["ua_CT_RY"]
                          ? filters["ua_CT_RY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ua_FY_YR"] ? filters["ua_FY_YR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_FY_YR",
                        e.target.value,
                        filters["ua_FY_YR"]
                          ? filters["ua_FY_YR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ua_PE_NO"] ? filters["ua_PE_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_PE_NO",
                        e.target.value,
                        filters["ua_PE_NO"]
                          ? filters["ua_PE_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ua_FR_DT"] ? filters["ua_FR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_FR_DT",
                        e.target.value,
                        filters["ua_FR_DT"]
                          ? filters["ua_FR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ua_TO_DT"] ? filters["ua_TO_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_TO_DT",
                        e.target.value,
                        filters["ua_TO_DT"]
                          ? filters["ua_TO_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ua_AV_DA"] ? filters["ua_AV_DA"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_AV_DA",
                        e.target.value,
                        filters["ua_AV_DA"]
                          ? filters["ua_AV_DA"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ua_HL_DA"] ? filters["ua_HL_DA"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_HL_DA",
                        e.target.value,
                        filters["ua_HL_DA"]
                          ? filters["ua_HL_DA"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ua_AB_DA"] ? filters["ua_AB_DA"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_AB_DA",
                        e.target.value,
                        filters["ua_AB_DA"]
                          ? filters["ua_AB_DA"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ua_SL_PR"] ? filters["ua_AB_DA"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_AB_DA",
                        e.target.value,
                        filters["ua_AB_DA"]
                          ? filters["ua_AB_DA"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ua_SL_PR"] ? filters["ua_SL_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ua_SL_PR",
                        e.target.value,
                        filters["ua_SL_PR"]
                          ? filters["ua_SL_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Employee ID</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Department</th>
                <th style={tableHeaderStyle}>Century</th>
                <th style={tableHeaderStyle}>Year</th>
                <th style={tableHeaderStyle}>Month</th>
                <th style={tableHeaderStyle}>From Date</th>
                <th style={tableHeaderStyle}>To Date</th>
                <th style={tableHeaderStyle}>Available Days</th>
                <th style={tableHeaderStyle}>Holidays</th>
                <th style={tableHeaderStyle}>Leave</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Processed</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.uaUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.uaUkNo}
                        checked={selectedRows.includes(rowData.uaUkNo)}
                        onChange={() => handleRowSelect(rowData.uaUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ua_EM_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{rowData.em_CC_NM}</td>
                    <td style={tableCellStyle}>{rowData.department}</td>
                    <td style={tableCellStyle}>{rowData.ua_CT_RY}</td>
                    <td style={tableCellStyle}>{rowData.ua_FY_YR}</td>
                    <td style={tableCellStyle}>{rowData.ua_PE_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.ua_FR_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.ua_TO_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.ua_AV_DA}</td>
                    <td style={tableCellStyle}>{rowData.ua_HL_DA}</td>
                    <td style={tableCellStyle}>{rowData.ua_AB_DA}</td>
                    <td style={tableCellStyle}>{rowData.ua_CN_FM}</td>
                    <td style={tableCellStyle}>{rowData.ua_SL_PR}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <UpdateAttendenceDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}   
        UpdateAttendencetable = {UpdateAttendencetable} 
        setUpdateAttendencetable={setUpdateAttendencetable}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        em_CC_NM={em_CC_NM}
        ua_FY_YR={ua_FY_YR}
        ua_PE_NO={ua_PE_NO}
        em_DE_PT={em_DE_PT}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        emEMNOList={emEMNOList}
        leaveDaysList={leaveDaysList}
        handleConfirm={handleConfirm}
      />
      
    </div>
  );
};
export default UpdateAttendenceMain;
