
import axios from "axios";

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const Costcenter = () => {
// ALL LIST
  const fetchCostCenter = async () => {
    const urlcost = `${domain}/api/CostCenter/DropDown`;
    try {
      const response = await axios.get(urlcost, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      return response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
      return [];
    }
  };
// PARTICULAR PARAMETERS
const fetchCostCenterP = async (parameter) => {
  const urlcost = `${domain}/api/CostCenter/All`;
  const finalUrl =  `${urlcost}/${parameter}`
  try {
    const response = await axios.get(finalUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    return response.data.map((item) => ({
      label: item.cc_CC_DS,
      value: item.cc_CC_NM,
    }));
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
    return [];
  }
};


  return {fetchCostCenter,fetchCostCenterP};
  };

export default Costcenter;