import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import VisitDetailsDialog from "./VisitDetailsDialog";
import axios from "axios";
import '../../../components/ListStyle/ListStyle.css';
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import moment from "moment";
const initialValue = {
  vr_VI_NO: "",
  vr_VI_TY: "",
  vr_TR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  vr_LD_NO: "",
  vr_CN_NO: "",
  vr_VI_CO: "",
  vr_EM_NO: "",
  ab_AD_NM: "",
  vr_AP_ST: "",
  vr_LD_TY:"",
  vr_CN_CO:"",
  vr_AD_P1:"",
  vr_PR_NO:"",
  vr_OT_LO:"",
  vr_IN_LO:"",
  vr_EM_IL:"",
  vrUkNo:"",
  vr_TR_DT:"",
  vr_PR_NO:"",
  vr_FB_SU:"",
};
const VisitDetailsMain = () => {
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [vr_VI_NO, setvr_VI_NO] = useState("");
  const [vr_VI_PR, setvr_VI_PR] =useState("");
  const [vrUkNo, setvrUkNo] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [capturedImage, setCapturedImage] = useState(null);
  const [capturedImageSave, setcapturedImageSave] = useState(null);

  const initialFilters = {
    vr_VI_NO: { value: "", operation: "" },
    vr_TR_DT: { value: "", operation: "" },
    vr_VI_TY: { value: "", operation: "" },
    vr_VI_CO: { value: "", operation: "" },
    vr_EM_NO: { value: "", operation: "" },
    vr_CN_NO: { value: "", operation: "" },
    vr_AP_ST: { value: "", operation: "" },
  };
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [selectedAbAdNM, setselectedAbAdNM] = useState("");
  const [selectedAbAdNN,setselectedAbAdNN] = useState("");
  const [selectedvrEmNo,setselectedvrEmNo] = useState(""); 

  const [vr_CN_NO , setvr_CN_NO] = useState("");
  const [vr_EM_NO , setvr_EM_NO] = useState("");
  const [vr_LD_NO , setvr_LD_NO] = useState("");
  const [vr_CN_CO , setvr_CN_CO] = useState("");
  const [vr_AD_P1 , setvr_AD_P1] = useState("");
  const [vr_EM_IL , setvr_EM_IL] = useState("");
  const [ab_CL_11 , setab_CL_11] = useState("");
  
  const[vr_VI_TY, setvr_VI_TY ] = useState("");
  const[vr_VI_CO, setvr_VI_CO ] = useState("");
  const[vr_LD_CO, setvr_LD_CO ] = useState("");
  const[vr_LD_TY, setvr_LD_TY ] = useState("");
  const[vr_IN_TM, setvr_IN_TM] = useState("");
  const[vr_OT_TM, setvr_OT_TM] = useState("");
  const[cc_EM_IL, setcc_EM_IL] = useState("");
  const[vr_IN_LO, setvr_IN_LO] = useState("");
  const[vr_OT_LO, setvr_OT_LO] = useState("");
  const[cc_PH_NO, setcc_PH_NO] = useState("");
  const[ab_CL_12, setab_CL_12] = useState("");

  const[vr_NX_AC, setvr_NX_AC] = useState("");
  const[vr_FD_ST, setvr_FD_ST] = useState("");

  const [secondPopupVisible, setSecondPopupVisible] = useState(false);
  const [ContactOwnerPopupVisible, setContactOwnerPopupVisible] =useState(false);
  const openSecondPopup = () => {
    setSecondPopupVisible(true);
  };
  const openContactOwnerPopup = () => {
    setContactOwnerPopupVisible(true);
  };
  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      cc_AD_NO: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }

  setFilters(updatedFilters);
  };
  const updateFilters1 = (own) => {
    let updatedFilters = { ...filters };
  if (own !== null) {
    updatedFilters = {
      ...updatedFilters,
      cc_EM_NO: {
        value: own,
        operation: "EQUAL",
      },
    };
  }

  setFilters(updatedFilters);
  };
  const updateFilters2 = (sts) => {
    let updatedFilters = { ...filters };
  if (sts !== null) {
    updatedFilters = {
      ...updatedFilters,
      cc_LD_ST: {
        value: sts,
        operation: "LIKE",
      },
    };
  }

  setFilters(updatedFilters);
  };
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;
  
        if (column === 'vr_VI_NO') {
          constructedColumn = 'VR_VI_NO';
        } else {
          constructedColumn = column.toUpperCase();
        }
  
        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;
  
    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }
  
    const updatedFilters = { ...filters };
    
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setselectedAbAdNM(null);
    setselectedAbAdNN(null);
    setselectedvrEmNo(null);
    setvr_VI_PR(null);
    setvr_LD_NO(null);
    setvr_VI_NO(null);
    setvr_IN_TM(null);
    setvr_OT_TM(null);
    setvr_IN_LO(null);
    setvr_OT_LO(null);
    setvrUkNo(null);
    setcc_PH_NO(null);
    setcc_EM_IL(null);
    setvr_AD_P1(null);
    setab_CL_11(null);
    setvr_EM_IL(null);
    setab_CL_12(null);
    setvr_NX_AC(null);
    setCapturedImage(null);
    setcapturedImageSave(null);
    setOpen(true);
  };
  const handleClose = () =>  {
    setFormData(initialValue);
    setselectedAbAdNM(null);
    setselectedAbAdNN(null);
    setselectedvrEmNo(null);
    setvr_VI_PR(null);
    setvr_LD_NO(null);
    setvr_VI_NO(null);
    setvr_IN_TM(null);
    setvr_OT_TM(null);
    setvr_IN_LO(null);
    setvr_OT_LO(null);
    setvrUkNo(null);
    setcc_PH_NO(null);
    setcc_EM_IL(null);
    setvr_AD_P1(null);
    setab_CL_11(null);
    setvr_EM_IL(null);
    setab_CL_12(null);
    setCapturedImage(null);
    setcapturedImageSave(null);
    setOpen(false);
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/VisitDetails/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const VisitDetailsData = response.data;
          delete VisitDetailsData.vrUkNo;
          delete VisitDetailsData.vr_VI_NO;
          setFormData(VisitDetailsData);
          setselectedAbAdNM(response.data.cc_CN_NM);
          setselectedAbAdNN(response.data.ab_AD_NM);
          setselectedvrEmNo(response.data.ab_EM_NO);
          setvr_LD_NO(response.data.vr_LD_NO);
          setvr_CN_NO(response.data.vr_CN_NO);
          setvr_CN_CO(response.data.vr_CN_CO);
          setvr_VI_PR(VisitDetailsData.vr_VI_PR);
          setvr_IN_TM(VisitDetailsData.vr_IN_TM);
          setvr_OT_TM(VisitDetailsData.vr_OT_TM);
          setvr_IN_LO(VisitDetailsData.vr_IN_LO);
          setvr_OT_LO(VisitDetailsData.vr_OT_LO);
          setcc_PH_NO(VisitDetailsData.cc_PH_NO);
          setcc_EM_IL(VisitDetailsData.cc_EM_IL);
          setvr_AD_P1(VisitDetailsData.vr_AD_P1);
          setab_CL_11(VisitDetailsData.ab_CL_11);
          setvr_EM_IL(VisitDetailsData.vr_EM_IL);
          setab_CL_12(VisitDetailsData.ab_CL_12);
          var img1 = 'data:image/jpeg;base64,' + VisitDetailsData.image_SRC1;
          setcapturedImageSave(null);
          setCapturedImage(img1);
          setvrUkNo(null);
          setvr_VI_NO(null);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
 const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/VisitDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
    if(name === "vr_IN_TM"){
      setvr_IN_TM(value);
    }
    if(name === "vr_OT_TM"){
      setvr_OT_TM(value);
    }
    if(name === "vr_IN_LO"){
      setvr_IN_LO(value);
    }
    if(name === "vr_OT_LO"){
      setvr_OT_LO(value);
    }
  };
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    if(name=="vr_CN_NO")
    setvr_CN_NO(value);
    if(name=="vr_EM_NO")
    setvr_EM_NO(value);
    if(name=="vr_LD_NO")
    setvr_LD_NO(value);
    if(name=="vr_CN_CO")
    setvr_CN_CO(value);
  };
  const onchangeUKFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setvr_VI_NO(value);
  };
  const onchangeDropDownFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setvr_VI_PR(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/VisitDetails/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const VisitDetailsData = response.data;
        setFormData(VisitDetailsData);
        setselectedAbAdNM(response.data.cc_CN_NM);
        setselectedAbAdNN(response.data.ab_AD_NM);
        setselectedvrEmNo(response.data.ab_EM_NO);
        setvr_VI_NO(response.data.vr_VI_NO);
        setvr_LD_NO(response.data.vr_LD_NO);
        setvr_CN_NO(response.data.vr_CN_NO);
        setvr_CN_CO(response.data.vr_CN_CO);
        setvrUkNo(VisitDetailsData.vrUkNo);
        setvr_VI_PR(VisitDetailsData.vr_VI_PR);
        setvr_IN_TM(VisitDetailsData.vr_IN_TM);
        setvr_OT_TM(VisitDetailsData.vr_OT_TM);
        setvr_IN_LO(VisitDetailsData.vr_IN_LO);
        setvr_OT_LO(VisitDetailsData.vr_OT_LO);
        setcc_PH_NO(VisitDetailsData.cc_PH_NO);
        setcc_EM_IL(VisitDetailsData.cc_EM_IL);
        setvr_AD_P1(VisitDetailsData.vr_AD_P1);
        setab_CL_11(VisitDetailsData.ab_CL_11);
        setvr_EM_IL(VisitDetailsData.vr_EM_IL);
        setab_CL_12(VisitDetailsData.ab_CL_12);
        var img1 = 'data:image/jpeg;base64,' + VisitDetailsData.image_SRC1;
        setCapturedImage(img1);
        setcapturedImageSave(null);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
              setvr_VI_CO(`${company}`)
            if(fName === "VR_VI_TY"){
              setvr_VI_TY(dataDefault.versionIds[i].default_VALUE)
            }  
            if(fName === "VR_LD_TY"){
              setvr_LD_TY(dataDefault.versionIds[i].default_VALUE)
            }  
            setvr_LD_CO(`${company}`) 
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const urlNextNumber = `${domain}/api/BaseNumber/All`;
  const parameters = `${vr_VI_TY}/${company}`;
  const finalUrl1 = `${urlNextNumber}/${parameters}`;

  var requestBodyInput = {};
  function values() {
    const TR_DT = FormData.vr_TR_DT;
    const IN_TM = FormData.vr_IN_TM;
    // const IN_LO = document.getElementById("vr_IN_LO");
    const OT_TM = FormData.vr_OT_TM;
    // // const OT_LO = document.getElementById("vr_OT_LO");
    // const PR_NO = document.getElementById("vr_PR_NO");
    // const LD_NO = document.getElementById("vr_LD_NO");
    // const LD_TY = document.getElementById("vr_LD_TY");

    requestBodyInput = {
      vrUkNo: FormData.vrUkNo,
      vr_VI_NO: FormData.vr_VI_NO,
      vr_VI_TY: FormData.vr_VI_TY,
      vr_VI_CO: FormData.vr_VI_CO,
      vr_TR_DT: TR_DT,
      vr_IN_TM: IN_TM,
      vr_IN_LO: FormData.vr_IN_LO,
      vr_OT_TM: OT_TM,
      vr_OT_LO: FormData.vr_OT_LO,
      vr_PR_NO: FormData.vr_PR_NO,
      vr_CN_NO: FormData.vr_CN_NO,
      vr_LD_NO: FormData.vr_LD_NO,
      vr_CN_CO: FormData.vr_CN_CO,
      vr_LD_TY: FormData.vr_LD_TY,
      vr_LD_CO: company,
      vr_VI_PR: vr_VI_PR,
      vr_EM_IL: FormData.vr_EM_IL,
      vr_EM_NO: FormData.vr_EM_NO,
      vr_AD_P1: FormData.vr_AD_P1,
      vr_FB_SU: FormData.vr_FB_SU,
      vr_NX_MT: FormData.vr_NX_MT,
      vr_FD_ST: FormData.vr_FD_ST,
      vr_NX_AC: FormData.vr_NX_AC,
      image_SRC1: capturedImageSave
    };
  };
  const handleFormSubmit = () => {
    values();
    if(FormData.vr_VI_NO){
      if (FormData.vrUkNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/VisitDetails/Update`;
        if (confirm) {
          axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
        } else {
          axios.post(`${domain}/api/VisitDetails/Add`, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error adding data:", error);
            });
        }
      }
    } else {
          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            setvr_VI_NO(resp.bn_BA_NO);
            FormData.vr_VI_NO = resp.data.bn_BA_NO;
            if(resp.data.bn_BA_NO ===undefined)
            {
              handleResponse(resp.data);
            }else{
              values();
              axios.post(`${domain}/api/VisitDetails/Add`, requestBodyInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error adding data:", error);
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
    } 
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "red !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "white !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "white !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const emailStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const contactStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
       <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        {/* <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
        </div> */}
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                <input
                    type="text"
                    value={filters["vr_VI_NO"] ? filters["vr_VI_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "vr_VI_NO",
                        e.target.value,
                        filters["vr_VI_NO"]
                          ? filters["vr_VI_NO"].operation
                          : "equals"
                      )
                    }
                    style={contactStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["vr_TR_DT"] ? filters["vr_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "vr_TR_DT",
                        e.target.value,
                        filters["vr_TR_DT"]
                          ? filters["vr_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={contactStyle}
                  />
                </td>              
                <td>
                  <input readOnly
                    type="text"
                    value={filters["vr_EM_NO"] ? filters["vr_EM_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "vr_EM_NO",
                        e.target.value,
                        filters["vr_EM_NO"]
                          ? filters["vr_EM_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input readOnly
                    type="text"
                    value={filters["vr_CN_NO"] ? filters["vr_CN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "vr_CN_NO",
                        e.target.value,
                        filters["vr_CN_NO"]
                          ? filters["vr_CN_NO"].operation
                          : "equals"
                      )
                    }
                    style={emailStyle}
                  />
                </td>
                {/* <td>
                <input disabled
                    type="text"
                    value={filters["ab_CL_13"] ? filters["ab_CL_13"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ab_CL_13",
                        e.target.value,
                        filters["ab_CL_13"]
                          ? filters["ab_CL_13"].operation
                          : "equals"
                      )
                    }
                    style={contactStyle}
                  />
                </td> */}
                <td>
                <input disabled
                    type="text"
                    value={filters["ab_CL_11"] ? filters["ab_CL_11"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ab_CL_11",
                        e.target.value,
                        filters["ab_CL_11"]
                          ? filters["ab_CL_11"].operation
                          : "equals"
                      )
                    }
                    style={contactStyle}
                  />
                </td>
                <td>
                <input disabled
                    type="text"
                    value={filters["ab_CL_12"] ? filters["ab_CL_12"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ab_CL_12",
                        e.target.value,
                        filters["ab_CL_12"]
                          ? filters["ab_CL_12"].operation
                          : "equals"
                      )
                    }
                    style={contactStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Visit No</th>
                <th style={tableHeaderStyle}>Date</th>
                <th style={tableHeaderStyle}>Lead Owner</th>
                <th style={tableHeaderStyle}>Customer</th>
                {/* <th style={tableHeaderStyleH}>Area</th> */}
                <th style={tableHeaderStyle}>Industry</th>
                <th style={tableHeaderStyle}>Vertical</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.vrUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.vrUkNo}
                        checked={selectedRows.includes(rowData.vrUkNo)}
                        onChange={() => handleRowSelect(rowData.vrUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.vr_VI_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.vr_TR_DT)}</td>
                    <td style={tableCellStyle}>{rowData.ab_EM_NO}</td>
                    <td style={tableCellStyle}>{rowData.ab_AD_NM}</td>
                    {/* <td style={tableCellStyle}>{rowData.ab_CL_13}</td> */}
                    <td style={tableCellStyle}>{rowData.ab_CL_11}</td>
                    <td style={tableCellStyle}>{rowData.ab_CL_12}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <VisitDetailsDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData={onchangeDropDownFormData}
        vr_VI_NO={vr_VI_NO}
        vrUkNo={vrUkNo}
        vr_VI_PR={vr_VI_PR}
        selectedAbAdNM={selectedAbAdNM}
        setselectedAbAdNM={setselectedAbAdNM}
        // selectedSLLDID={selectedSLLDID}
        // setSelectedSLLDID={setSelectedSLLDID}
        onchangeUKFormData={onchangeUKFormData}
        selectedAbAdNN={selectedAbAdNN}
        setselectedAbAdNN={setselectedAbAdNN}
        // selectedvpEmNo={selectedvpEmNo}
        selectedvrEmNo={selectedvrEmNo}
        setselectedvrEmNo={setselectedvrEmNo}
        // selectedccCnNm={selectedccCnNm}
        // setselectedccCnNm={setselectedccCnNm}
        vr_VI_TY={vr_VI_TY}
        vr_VI_CO={vr_VI_CO}
        vr_CN_NO={vr_CN_NO}
        vr_EM_NO={vr_EM_NO}
        setvr_CN_NO={setvr_CN_NO}
        setvr_EM_NO={setvr_EM_NO}
        vr_LD_NO={vr_LD_NO}
        vr_CN_CO={vr_CN_CO}
        setvr_CN_CO={setvr_CN_CO}
        vr_LD_TY={vr_LD_TY}
        vr_LD_CO={vr_LD_CO}
        vr_IN_TM={vr_IN_TM}
        vr_OT_TM={vr_OT_TM}
        cc_EM_IL={cc_EM_IL}
        setcc_EM_IL={setcc_EM_IL}
        vr_IN_LO={vr_IN_LO}
        vr_OT_LO={vr_OT_LO}
        cc_PH_NO={cc_PH_NO}
        setcc_PH_NO={setcc_PH_NO}
        ab_CL_11={ab_CL_11}
        setab_CL_11={setab_CL_11}
        ab_CL_12={ab_CL_12}
        setab_CL_12={setab_CL_12}
        vr_EM_IL={vr_EM_IL}
        vr_AD_P1={vr_AD_P1}
        setvr_EM_IL={setvr_EM_IL}
        setvr_AD_P1={setvr_AD_P1}
        vr_NX_AC={vr_NX_AC}
        setvr_NX_AC={setvr_NX_AC}
        vr_FD_ST={vr_FD_ST}
        setvr_FD_ST={setvr_FD_ST}
        capturedImage={capturedImage}
        setCapturedImage={setCapturedImage}
        capturedImageSave={capturedImageSave}
        setcapturedImageSave={setcapturedImageSave}
      />
    </div>
  );
};

export default VisitDetailsMain;
