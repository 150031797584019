import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { Pie } from 'react-chartjs-2';
import { Dropdown } from "primereact/dropdown";
import { Select } from "@chakra-ui/react";
import { Progress } from '@chakra-ui/react';
import './styledash.css';
import axios from "axios";
import OccupiedUnitDetails from "./OccupiedUnitDetails";
import UnoccupiedUnitDetails from "./UnoccupiedUnitDetails";
import LeaseNearExpiry from "./LeaseNearExpiry";
import LeaseHistory from "./LeaseHistory";

const PropertyOverview = () => {
const [open, setOpen] = useState(false);
const [OccupiedUnitDetailsVisible, setOccupiedUnitDetailsVisible] = useState(false);
const [UnoccupiedUnitDetailsVisible, setUnoccupiedUnitDetailsVisible] = useState(false);
const [LeaseNearExpiryVisible, setLeaseNearExpiryVisible] = useState(false);
const [LeaseHistoryVisible, setLeaseHistoryVisible] = useState(false);
const openOccupiedunit = () => {
    setOccupiedUnitDetailsVisible(true);
};
const openUnoccupiedunit = () => {
    setUnoccupiedUnitDetailsVisible(true);
};
const openLeaseNearExpiry = () => {
  setLeaseNearExpiryVisible(true);
};
const openLeaseHistory = () => {
  setLeaseHistoryVisible(true);
};
// front
const [dashboardData, setDashboardData] = useState({
  rental_feb:"",
  rental_jun:"",
  salesMth:"",
  OCCUPIED_UNITS: [],
  rental_jul:"",
  rental_dec:"",
  ales_aug:"",
  salesYtd:"",
  unoccupied:"",
  leasYear:"",
  rental_jan:"",
  lease_mar:"",
  lease_sep:"",
  lease_nov:"",
  sales_mar:"",
  sales_sep:"",
  lease_aug:"",
  rental_may:"",
  sales_nov:"",
  rental_mar:"",
  rental_sep:"",
  openLead:"",
  wonLead:"",
  lostLead:"",
  sales_may:"",
  leaseMonth:"",
  sales_dec:"",
  sales_jul:"",
  lease_oct:"",
  totalDeals:"",
  lease_apr:"",
  rental_nov:"",
  lease_jan:"",
  sales_feb:"",
  sales_jun:"",
  rentalYtd:"",
  occupied:"",
  LEASE_NEARING_EXPIRY: [],
  occupancyPercentage:"",
  rental_apr:"",
  rentalMth:"",
  sales_oct:"",
  rental_oct:"",
  lease_may:"",
  sales_jan:"",
  rental_aug:"",
  lease_feb:"",
  lease_jun:"",
  lease_dec:"",
});

const token = localStorage.getItem("token");
const company = localStorage.getItem("company");
const domain = localStorage.getItem("domain");
const [selectedMonth, setSelectedMonth] = useState("");
const [selectedYear, setSelectedYear] = useState("");


const dashboard = `${domain}/api/Dashboard/RealEstateDashboard`;
const REdashboard = async() =>{
  const requestinput ={
          "year":selectedYear,
          "month":selectedMonth,
        }
  try {
    const response = await axios.post(dashboard, requestinput, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const data = response.data;
    setDashboardData(data);
    } catch (error) {
    console.error('Error fetching data:', error);
  }
};
useEffect(() => {
  
const UpdateBarchart1Data=()=>{
  if(!barChartRef1.current) return;
  const ctx = barChartRef1.current.getContext("2d");
    const chart = Chart.getChart(ctx);
  if(chart){
      chart.data.datasets=[{
        label: "Lease",
        backgroundColor: ["Blue","Blue","Blue","Blue","Blue","Blue","Blue","Blue","Blue","Blue","Blue","Blue"],
        data: [
          dashboardData.lease_jan,
          dashboardData.lease_feb,
          dashboardData.lease_mar,
          dashboardData.lease_apr,
          dashboardData.lease_may,
          dashboardData.lease_jun,
          dashboardData.lease_jul,
          dashboardData.lease_aug,
          dashboardData.lease_sep,
          dashboardData.lease_oct,
          dashboardData.lease_nov,
          dashboardData.lease_dec
         ],
        stack: true,
    },
    {
        label: "Rental",
        backgroundColor: "skyblue",
        data: [
          dashboardData.rental_jan,
          dashboardData.rental_feb,
          dashboardData.rental_mar,
          dashboardData.rental_apr,
          dashboardData.rental_may,
          dashboardData.rental_jun,
          dashboardData.rental_jul,
          dashboardData.rental_aug,
          dashboardData.rental_sep,
          dashboardData.rental_oct,
          dashboardData.rental_nov,
          dashboardData.rental_dec
        ],
        stack: true,
    },
    {
      label: "Sales",
      backgroundColor: "#e9e9e9",
      data: [
          dashboardData.sales_jan,
          dashboardData.sales_feb,
          dashboardData.sales_mar,
          dashboardData.sales_apr,
          dashboardData.sales_may,
          dashboardData.sales_jun,
          dashboardData.sales_jul,
          dashboardData.sales_aug,
          dashboardData.sales_sep,
          dashboardData.sales_oct,
          dashboardData.sales_nov,
          dashboardData.sales_dec
      ],
       stack: true,
  }];
  chart.update();
  }
}
  if(dashboardData)
    UpdateBarchart1Data();
},[dashboardData]);

useEffect(() => {
  let newDate = new Date()
  if (selectedMonth === null || selectedMonth === "" ) {
  let month = newDate.getMonth() + 1;
  setSelectedMonth(month);
  }
  if (selectedYear === null || selectedYear === "") {
    let year = newDate.getFullYear();
    setSelectedYear(year);
  }  
  if (selectedMonth !== null && selectedYear !== null) {
    REdashboard();
  }

}, [selectedMonth,selectedYear]);

// stylings
const boxtextstyle ={
    fontFamily: "DM Sans",
    color: "black",
    fontSize:"small",
    fontWeight:"bold"
};
const boxtextstyle1 ={
  fontFamily: "DM Sans",
  color: "black",
  fontSize:"smaller",
  fontWeight:"bold"
};
const Progretextstyle ={
  display:"flex",
  alignItems: "center",
  justifyContent:"space-between",
  padding:"3% 3% 3% 3%"
};
const Progressstyle = {
  marginLeft:"3%",
  width:"94%",
  backgroundColor:"#f4f4f4"
};
const barChartRef1 = useRef(null);
const lineChartRef = useRef(null);
const piechartRef1=  useRef(null);
// Data for occupancy analysis  
const data = {
    labels: ['Occupied', 'Unoccupied'],
    datasets: [
      {
        label: 'Occupied vs Unoccupied',
        data: [dashboardData.occupied,dashboardData.unoccupied], 
        backgroundColor: ['#33FFFF', '#00CCFF'], 
      },
    ],
};
const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right', 
      },
    },
};
// 3 LEVEL BARS
const barchart1 = () => {
  if (!barChartRef1.current) return;
  const ctx = barChartRef1.current.getContext("2d");
  new Chart(ctx, {
      type: "bar",
      data: {
          labels: ["Jan","Feb","Mar", "Apr","May","Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: []
      },
      options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
              x: {
                  grid: { drawOnChartArea: false, display: false, drawBorder: false },
                  ticks: { color: "black", font: { size: 8 } },
              },
              y: {
                  grid: { drawBorder: true, drawOnChartArea: false, display: false },
                  beginAtZero: true,
                  min: 0,
                  ticks: { stepSize: 1000000, font: { size: 8 }, color: "black" }, 
              },
          },
          plugins: {
              legend: {
                  display: false,
                  labels: { font: { size: 8 } } 
              },
              indexAxis: 'y', 
              interaction: {
                  intersect: false,
              },
          },
          elements: {
            bar: {
              borderRadius: 15,
            }
          },
          barThickness: 15,
      },
  });
};
const linechart = () => {
    if (!lineChartRef.current) return;
    const ctx = lineChartRef.current.getContext("2d");
    const myChart = new Chart(ctx, {
        type: "line",
        data: {
            labels:["SEP", "OCT", "NOV", "DEC", "JAN", "FEB"], 
            datasets: [
                {
                    label: "Lease", 
                    backgroundColor: "rgba(135, 206, 250, 0.3)",
                    borderColor: "blue",
                    borderWidth: 4,
                    data: [12000, 14000, 2000, 14000, 2000, 14000,2000, 14000], 
                    tension: 0.4,
                    fill:true
                },
                {
                    label: "Rental",
                    backgroundColor: "rgba(255, 182, 193, 0.3)",
                    borderColor: "skyblue",
                    borderWidth: 4,
                    data: [3000, 9000, 3000, 5000, 3000, 5000,3000, 5000], 
                    tension: 0.4,
                    fill:true
                },
                {
                    label: "Sales",
                    backgroundColor: "rgba(255, 182, 193, 0.3)",
                    borderColor: "Lightpink",
                    borderWidth: 4,
                    data: [4000, 16000, 4000, 16000, 4000, 16000,4000, 16000], 
                    tension: 0.4,
                    fill:true
                }
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: { 
                    grid: { drawOnChartArea: false, display: false, drawBorder: false },
                    ticks: { color: "black", font: { size: 12 } }
                },
                y: {
                    grid: { drawBorder: false, drawOnChartArea: false, display: false },
                    beginAtZero: true,
                    min: 0,
                    ticks: { stepSize: 2000, font: { size: 9 }, color: "black", display: true }
                }
            },
            plugins: {
              tooltip:{
                mode: 'index',
                intersect: false

              },
              legend: {
                display: false,
            }
            },
            elements: {
              point: {
                radius: 0 
            },
                line: {
                    borderCapStyle: 'round'
                }
            },
            layout: {
                padding: {
                    left: 10, 
                    bottom: 40,
                    top: 50 
                }
            },
        }
    });
};
// const piechart1 = () => {
//   if (!piechartRef1.current) return;
//   const ctx = piechartRef1.current.getContext("2d");
//   const totalLeads = dashboardData.openLead + dashboardData.wonLead + dashboardData.lostLead;
//   piechartRef1.current.width = 50; 
//   piechartRef1.current.height = 50; 

//   new Chart(ctx, {
//     type: "pie",
//     data: {
//       labels: ["Open Deals", "Won Deals", "Lost Deals"],
//       datasets: [{
//         label: "Total Leads",
//         data: [dashboardData.openLead, dashboardData.wonLead, dashboardData.lostLead], 
//         backgroundColor: [
//           "#c5f2ffcc", 
//           "#0000FF", 
//           "#00008B" 
//         ]
//       }]
//     },
//     options: {
//       responsive: true,
//       maintainAspectRatio: false,
//       plugins: {
//         legend: {
//           position: 'right', 
//           labels: {
//             font: {
//               size: 14 
//             }
//           }
//         },
//         tooltip: {
//           callbacks: {
//             label: function(context) {
//               return context.label + ': ' + context.parsed + ' Leads';
//             }
//           }
//         },
//         datalabels: {
//           formatter: (value, ctx) => {
//             const percentage = ((value / totalLeads) * 100).toFixed(2) + '%';
//             return `${percentage} (${value} Leads)`; 
//           },
//           color: '#fff',
//           font: {
//             size: 10 
//           }
//         }
//       },
//       layout: {
//         padding: {
//           left: 10,
//           right: 10,
//           top: 10, 
//           bottom: 10 
//         }
//       }
//     }
//   });
// };


const data1 = {
  labels: ['Open leads', 'Won Leads', 'Lost Deals'],
  datasets: [
    {
      label: 'Total Leads',
      data: [dashboardData.openLead,dashboardData.wonLead,dashboardData.lostLead], 
      backgroundColor: ['#99FFFF', '#d5fff2', '#66CCFF '],
    },
  ],
};
const options1 = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'right', 
    },
  },
};
useEffect(() => {  
    linechart();
    barchart1();
    // piechart1();
  }, []);
// dropdwons

const handleMonthChange = (e) => {
  setSelectedMonth(e.value);
};

const months = [
      { label: "January", value: 1 },
      { label: "February", value: 2 },
      { label: "March", value: 3 },
      { label: "April", value: 4 },
      { label: "May", value: 5 },
      { label: "June", value: 6 },
      { label: "July", value: 7 },
      { label: "August", value: 8 },
      { label: "September", value: 9 },
      { label: "October", value: 10 },
      { label: "November", value: 11 },
      { label: "December", value: 12 }
];

const handleYearChange = (e) => {
  setSelectedYear(e.value);
};
const years = [
    { label: 2022, value: 2022 },
    { label: 2023, value: 2023 },
    { label: 2024, value: 2024 }
];
return (
   
  <div style={{ marginTop: "5%",marginLeft:"1%" }}>
  {/* 1st Box List */}
  <div className="month-year d-flex justify-content-between text-white text-center">
  <div className="box"
  style={{ 
    backgroundColor: "#c5f2ffcc",
    color: "#000000", 
    borderRadius: "17px", 
    width: "11%", 
    height: "80px",
    padding: "15px 20px", 
    display: "flex",
    justifyContent: "center", 
}} >
  <div>
  <div style={boxtextstyle}>Lease</div>
      <div style={boxtextstyle}>{dashboardData.leaseMonth}</div>
  </div>
  </div>

  <div className="box" style={{ 
      backgroundColor: "#d5fff2",
      color: "#000000", 
      borderRadius: "17px", 
      width: "11%", 
      height: "80px",
      padding: "15px 20px", 
      display: "flex",
      justifyContent: "center",     
  }}>
  <div>
  <div style={boxtextstyle}>Rental</div>
  <div style={boxtextstyle}>{dashboardData.rentalMth}</div>
  </div>
  </div>

  <div className="box" style={{ 
      backgroundColor: "#c5f2ffcc",
      color: "#000000", 
      borderRadius: "17px", 
      width: "11%", 
      height: "80px",
      padding: "15px 20px", 
      display: "flex",
      justifyContent: "center", 
  }}>
  <div>
  <div style={boxtextstyle}>Sales</div>
      <div style={boxtextstyle}>{dashboardData.salesMth}</div>
  </div>
  </div>

  <div className="box" style={{ 
      backgroundColor: "#d5fff2",
      color: "#000000", 
      borderRadius: "17px", 
      width: "11%", 
      height: "80px",
      padding: "15px 20px", 
      display: "flex",
      justifyContent: "center", 
  }}>
  <div>
      <div style={{ fontSize: "18px", fontFamily: "DM Sans" , color: "#000000" }}>
      <label htmlFor="months"></label>
      <Dropdown
        style={{ height:"30%" , width:"100%",alignContent:"center",backgroundColor: "#d5fff2",border:"none",fontWeight:"bold",fontSize:"1%"}}
        id="months"
        value={selectedMonth}
        options={months}
        onChange={handleMonthChange}
        placeholder="Select Month"
      />
      {selectedMonth && <p>{selectedMonth.label}</p>}
      </div>
  </div>
  </div>

  <div className="box" style={{ 
      backgroundColor: "#c5f2ffcc",
      color: "#000000", 
      borderRadius: "17px", 
      width: "11%", 
      height: "80px",
      padding: "15px 20px", 
      display: "flex",
      justifyContent: "center", 
  }}>
  <div>
      <div style={boxtextstyle}>Lease</div>
      <div style={boxtextstyle}>{dashboardData.leasYear}</div>

  </div>
  </div>

  <div className="box" style={{ 
      backgroundColor: "#d5fff2",
      color: "#000000", 
      borderRadius: "17px", 
      width: "11%", 
      height: "80px",
      padding: "15px 20px", 
      display: "flex",
      justifyContent: "center", 
  }}>
  <div>
      <div style={boxtextstyle}>Rental</div>
      <div style={boxtextstyle}>{dashboardData.rentalYtd}</div>

  </div>
  </div>

  <div className="box" style={{ 
      backgroundColor: "#c5f2ffcc",
      color: "#000000", 
      borderRadius: "17px", 
      width: "11%", 
      height: "80px",
      padding: "15px 20px", 
      display: "flex",
      justifyContent: "center", 
  }}>
  <div>
      <div style={boxtextstyle}>Sales</div>
      <div style={boxtextstyle}>{dashboardData.salesYtd}</div>

  </div>
  </div>

  <div className="box" style={{ 
      backgroundColor: "#d5fff2",
      color: "#000000", 
      borderRadius: "17px", 
      width: "11%", 
      height: "80px",
      padding: "15px 20px", 
      display: "flex",
      justifyContent: "center", 
  }}>
  <div>
      <label htmlFor="Years"></label>
      <Dropdown
        style={{border:"none" , height:"100%" , width:"100%",backgroundColor: "#d5fff2",border:"none",fontWeight:"bold",fontSize:"1%"}}
        id="years"
        value={selectedYear}
        options={years}
        onChange={handleYearChange}
        placeholder="Select Year"
      />
      {selectedYear && <p>{selectedYear.label}</p>}
      </div>
  </div>
  </div> 

  {/* Line&Pie Chart*/}
  <div style={{ display: "flex", marginTop: "2%",height:"30vh" }}>
    <div style={{ flex: 1, marginRight: "10px" ,marginBottom:"10px",borderRadius:"10px",backgroundColor:"white"}}>
    <div style={{ height: "13%", textAlign: "center", fontSize: "150%", fontWeight: "bold", marginTop: "3%" }}>Income Analytics</div>
    <div style={{height:"75%",marginTop:"1%"}}>
      <canvas ref={barChartRef1} id="barChart" style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF"}} />
      </div>
    </div>
    <div style={{ flex: 1, marginLeft:"1%",marginBottom:"10px",borderRadius:"10px",backgroundColor:"white"}}>
    <div style={{ height: "13%", textAlign: "center", fontSize: "150%", fontWeight: "bold", marginTop: "3%",marginRight:"18%" }}>Lead Analysis</div>
      <div style={{height:"75%",marginTop:"1%"}}>
      {/* <canvas ref={piechartRef1} id="pieChart" style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF"}} /> */}
      <Pie data={data1} options={options1} style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF", backgroundColor: "white", height: "50%" }} />
      </div>
    </div>
  </div>

  {/* 2nd Box List */}
  <div className="month-year d-flex justify-content-between text-white text-center" style={{marginTop:"1%"}} >

  <div className="box" style={{ 
        backgroundColor: "#c5f2ffcc",
        color: "#000000", 
        borderRadius: "17px", 
        width: "11%", 
        height: "80px",
        padding: "15px 20px", 
        display: "flex",
        justifyContent: "center", 
  }}>
    <div>
    <div style={boxtextstyle}>Total Deals</div>
      <div style={boxtextstyle}>{dashboardData.totalDeals}</div>
    
  </div>
  </div>

  <div className="box" style={{ 
        backgroundColor: "#d5fff2",
        color: "#000000", 
        borderRadius: "17px", 
        width: "11%", 
        height: "80px",
        padding: "15px 20px", 
        display: "flex",
        justifyContent: "center", 
  }}>
    <div>
    <div style={boxtextstyle}>Won Deals</div>
      <div style={boxtextstyle}>{dashboardData.wonLead}</div>
    
  </div>
  </div>

  <div className="box" style={{ 
        backgroundColor: "#c5f2ffcc",
        color: "#000000", 
        borderRadius: "17px", 
        width: "11%", 
        height: "80px",
        padding: "15px 20px", 
        display: "flex",
        justifyContent: "center", 
  }}>
    <div>
    <div style={boxtextstyle1}>Open Deals</div>
      <div style={boxtextstyle}>{dashboardData.openLead}</div>
      
  </div>
  </div>

  <div className="box" style={{ 
        backgroundColor: "#d5fff2",
        color: "#000000", 
        borderRadius: "17px", 
        width: "11%", 
        height: "80px",
        padding: "15px 20px", 
        display: "flex",
        justifyContent: "center", 
  }}>
    <div>
    <div style={boxtextstyle}>Lost Deals</div>
      <div style={boxtextstyle}>{dashboardData.lostLead}</div>   
  </div>
  </div>

  <div className="box" style={{ 
      backgroundColor: "#c5f2ffcc",
      color: "#000000", 
      borderRadius: "17px", 
      width: "11%", 
      height: "80px",
      padding: "15px 20px", 
      display: "flex",
      justifyContent: "center", 
  }}>
  <div>
  <div style={boxtextstyle1}>Properties</div>
  <div style={boxtextstyle}>{dashboardData.properties}</div>
  </div>
  </div>

  <div className="box" style={{ 
        backgroundColor: "#d5fff2",
        color: "#000000", 
        borderRadius: "17px", 
        width: "11%", 
        height: "80px",
        padding: "15px 20px", 
        display: "flex",
        justifyContent: "center", 
    
  }}>
    <div>
    <div style={boxtextstyle}>Occupied</div>
      <div style={boxtextstyle}>{dashboardData.occupied} </div>  
  </div>
  </div>

  <div className="box" style={{ 
        backgroundColor: "#c5f2ffcc",
        color: "#000000", 
        borderRadius: "17px", 
        width: "11%", 
        height: "80px",
        padding: "15px 20px", 
        display: "flex",
        justifyContent: "center", 
  }}>
    <div>
    <div style={boxtextstyle}>UnOccupied</div>
      <div style={boxtextstyle}>{dashboardData.unoccupied}</div>
      
  </div>
  </div>

  <div className="box" style={{ 
        backgroundColor: "#d5fff2",
        color: "#000000", 
        borderRadius: "17px", 
        width: "11%", 
        height: "80px",
        padding: "15px 20px", 
        display: "flex",
        justifyContent: "center", 
  }}>
    <div>
      <div style={boxtextstyle}>Occupancy</div>
      <div style={boxtextstyle}>{dashboardData.occupancyPercentage} %</div>
  </div>
  </div>
  </div>

  {/* progress bar */}
  <div style={{ display: "flex", marginTop: "20px",height:"30vh" }} className="divprgs">
  <div style={{ flex: 1, backgroundColor: "white", borderRadius: "10px" }}>
    <h1 style={{textAlign:"center",fontWeight:"bold",marginTop:"1%",fontSize:"medium"}}>Sales Analytics</h1>
        <div style={Progretextstyle} id="progrs">
            <h2> From Agent </h2> <span>65%</span>
        </div>
            <Progress colorScheme="green" style={Progressstyle} value={40} />
        <div style={Progretextstyle} id="progrs">
          <h2> From  Sales Team </h2>  <span>98%</span>
        </div>
            <Progress  colorScheme="cyan" style={Progressstyle}  value={50} />
        <div style={Progretextstyle} id="progrs">
          <h2> From Reference </h2>  <span>78%</span>
        </div>
            <Progress colorScheme="teal" style={Progressstyle} value={40} />
        <div style={Progretextstyle} id="progrs">
          <h2> From Partner </h2>  <span>68%</span>
        </div>
            <Progress colorScheme="red" style={Progressstyle} value={90} />
  </div>

  <div style={{ flex: 1, backgroundColor: "white", borderRadius: "10px",marginLeft:"1%",marginRight:"1%", }}>
  <h1 style={{textAlign:"center",fontWeight:"bold",marginTop:"1%",fontSize:"medium"}}>Property Refferals</h1>
        <div style={Progretextstyle} id="progrs">
            <h2> Social Media </h2> <span>95%</span>
        </div>
            <Progress colorScheme="green" style={Progressstyle} value={40} />
        <div style={Progretextstyle} id="progrs">
          <h2> Marketplaces</h2>  <span>98%</span>
        </div>
            <Progress  colorScheme="cyan" style={Progressstyle}  value={50} />
        <div style={Progretextstyle} id="progrs">
          <h2> Websites </h2>  <span>78%</span>
        </div>
            <Progress colorScheme="teal" style={Progressstyle} value={40} />
        <div style={Progretextstyle} id="progrs" >
          <h2> Others </h2>  <span>68%</span>
        </div>
            <Progress colorScheme="red" style={Progressstyle} value={90} />
  </div>
  {/* 2nd pie chart */}
  <div style={{ flex: 1, marginLeft:"1%", marginBottom: "10px", backgroundColor: "white", borderRadius: "10px", position: 'relative' }}>
  <div style={{ height: "13%", textAlign: "center", fontSize: "110%", fontWeight: "bold", marginTop: "1%" }} className="ocpncy">Occupancy Analysis</div>
  <div style={{ position: 'absolute', top: '0', right: '0' }}>
      <Select
          fontSize='sm'
          variant='subtle'
          defaultValue='monthly'
          width='20%'
          fontWeight='700'>
          <option value='daily'>Daily</option>
          <option value='monthly'>Weekly</option>
          <option value='yearly'>Monthly</option>
      </Select>
  </div>
  <div style={{ backgroundColor: "white", height: "85%", marginTop: "3%" }}>
      <Pie data={data} options={options} style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF", backgroundColor: "white", height: "50%" }} />
  </div>
  </div>
  </div>

  <div style={{ display: "flex", height: "5vh" ,marginTop:"20px",display:"flex"}}>
    <div style={{ flex: "1 0 20%", marginRight: "2%", backgroundColor: "#bcf0ff",borderRadius:"10px" }}>
    <div style={{ height: "13%", textAlign: "center", fontSize: "110%", fontWeight: "bold", marginTop: "1%" }}>  {" "}
          <button><h3 onClick={openOccupiedunit}>Occupied Units - {dashboardData.occupied}</h3></button>
      </div>
      </div>
      <div style={{ flex: "1 0 20%", marginRight: "2%", backgroundColor: "#d5fff2",borderRadius:"10px" }}>
      <div style={{ height: "13%", textAlign: "center", fontSize: "110%", fontWeight: "bold", marginTop: "1%" }} >  {" "}
          <button><h3 onClick={openUnoccupiedunit}>Unoccupied Units - {dashboardData.unoccupied}</h3></button>
      </div>
      </div>
      <div style={{ flex: "1 0 20%", backgroundColor: "#bcf0ff",borderRadius:"10px" }}>
      <div style={{ marginLeft:"25%", fontSize: "110%", fontWeight: "bold" }} id="leasenear">  {" "}
          <button><h3 onClick={openLeaseNearExpiry}> Lease Expiry </h3></button>
          {/* <Dropdown
            style={{border:"none",backgroundColor:"#bcf0ff",marginLeft:"5%"}}
            id="days"
            value={selecteddays}
            options={days}
            onChange={handledayChange}
            placeholder="Select Days"
          /> */}
      </div>
  </div>
  <div style={{ flex: "1 0 20%", marginLeft: "2%", backgroundColor: "#d5fff2",borderRadius:"10px" }}>
      <div style={{ height: "13%", textAlign: "center", fontSize: "110%", fontWeight: "bold", marginTop: "1%" }} >  {" "}
          <button><h3 onClick={openLeaseHistory}> Lease History </h3></button>
      </div>
      </div>
  </div>
  <OccupiedUnitDetails
      selectedMonth={selectedMonth}
      selectedYear={selectedYear}
      open={open}
      setOpen={setOpen}
      visible={OccupiedUnitDetailsVisible}
      onClose={() => setOccupiedUnitDetailsVisible(false)}
  />
  <UnoccupiedUnitDetails
      selectedMonth={selectedMonth}
      selectedYear={selectedYear}
      visible={UnoccupiedUnitDetailsVisible}
      onClose={() => setUnoccupiedUnitDetailsVisible(false)}
  />
   <LeaseNearExpiry
      selectedMonth={selectedMonth}
      selectedYear={selectedYear}
      visible={LeaseNearExpiryVisible}
      onClose={() => setLeaseNearExpiryVisible(false)}
  />
  <LeaseHistory
      selectedMonth={selectedMonth}
      selectedYear={selectedYear}
      visible={LeaseHistoryVisible}
      onClose={() => setLeaseHistoryVisible(false)}
  />

  </div>
  );
};
export default PropertyOverview;
