import React, { useState } from "react";
import PropTypes from "prop-types";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import AddressDialog from "views/admin/AddressBook/AddressDialog";

const CustomerPopupData = ({
  tableData,
  filters,
  handleFirstPage,
  handlePreviousPage,
  handleNextPage,
  handleLastPage,
  handleFilterChange,
  toast,
  handleClose,
  //onDataSelect,
  handleSearch,
  onchangedata,
  handleCloseContact,
  openContact,
  opensubmit,
  selectedRowsInvoices,
  setSelectedRowsInvoices,
}) => {
  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
  };

  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const checkboxStyle = {
    border: "6px solid #a7d6ff8c",
    borderRadius: "4px",
    width: "100%",
  };
  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };
  const initialValue = {
    abAdNo: "",
    clUkNo:"",
    ab_CL_12: "",
    ab_AD_CT: "",
    ab_AD_CY: "",
    ab_AD_P1: "",
    ab_AD_NM: "",
    ab_AD_EM: "",
    ab_CL_11: "",
    ab_AC_IN: "",
  };
  const [FormData, setFormData] = useState(initialValue);
  const [abAdNo, setab_AD_NO] = useState("");
  const [ab_AD_CT, setab_AD_CT] = useState("");
  const [ab_AD_CY, setab_AD_CY] = useState("");
  const [ab_CL_12, setab_CL_12] = useState("");
  const [ab_CL_11, setab_CL_11] = useState("");
  const [ab_CL_13, setab_CL_13] = useState("");
  const [ab_CL_07, setab_CL_07] = useState("");
  const [ab_AD_TY, setab_AD_TY] = useState("");
  const [ab_AC_IN, setab_AC_IN] = useState("");
  const [ab_AD_NM, setab_AD_NM] = useState("");
  const [open, setOpen] = useState(false);
  const [showaddressdialog, setshowaddressdialog] = useState(false);
  const opendialog = () => {
    setshowaddressdialog(true);
  };
  // const opensubmit = () => {
  //   alert(5);
  // };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const table = document.getElementById("customerLedger");
    const totalRowCount = table.tBodies[0].rows.length;
    var  selected = [];
    for (let i = 0; i < totalRowCount; i++) {
      if(table.tBodies[0].rows[i].cells[0].children[0].checked){
        var v = Number (table.tBodies[0].rows[i].cells[0].children[0].value);
        selected.push(v);
      }
    }
    const isRowSelected = selectedRowsInvoices.includes(rowId);
    if (!isRowSelected) {
      setSelectedRowsInvoices(rowId);
    } else {
      setSelectedRowsInvoices([]);
    }
    setSelectedRowsInvoices(selected);
    //setIsRecordSelected(!isRowSelected);
  };
  return (
    <Dialog id="dialog-style"
    visible={openContact}
    onHide={handleCloseContact}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Customer Details"
      modal
      className="p-fluid"
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        
          <Button
            style={{
              width: "6.5%",
              border: "none",
              backgroundColor: "pink",
              marginLeft:"20PX",
            }}
            // label="Select Invoice"
            icon="pi pi-check"
            className="p-button-secondary"
            onClick={opensubmit}
          />
          <table id="customerLedger"
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td  style={{display:"none"}}>
                <input
                    type="text"
                    value={filters["clUkNo"] ? filters["clUkNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "clUkNo",
                        e.target.value,
                        filters["clUkNo"]
                          ? filters["clUkNo"].operation
                          : "equals"
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_CU_NO"] ? filters["cl_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CU_NO",
                        e.target.value,
                        filters["cl_CU_NO"]
                          ? filters["cl_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_CR_CD"] ? filters["cl_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CR_CD",
                        e.target.value,
                        filters["cl_CR_CD"]
                          ? filters["cl_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_CR_RT"] ? filters["cl_CR_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CR_RT",
                        e.target.value,
                        filters["cl_CR_RT"]
                          ? filters["cl_CR_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DO_NO"] ? filters["cl_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DO_NO",
                        e.target.value,
                        filters["cl_DO_NO"]
                          ? filters["cl_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DO_TY"] ? filters["cl_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DO_TY",
                        e.target.value,
                        filters["cl_DO_TY"]
                          ? filters["cl_DO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DO_CO"] ? filters["cl_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DO_CO",
                        e.target.value,
                        filters["cl_DO_CO"]
                          ? filters["cl_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_GR_AM"] ? filters["cl_GR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_GR_AM",
                        e.target.value,
                        filters["cl_GR_AM"]
                          ? filters["cl_GR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_OP_AM"] ? filters["cl_OP_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_OP_AM",
                        e.target.value,
                        filters["cl_OP_AM"]
                          ? filters["cl_OP_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DO_PI"] ? filters["cl_DO_PI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DO_PI",
                        e.target.value,
                        filters["cl_DO_PI"]
                          ? filters["cl_DO_PI"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
              <th style={{display:"none"}}>UK</th>
              <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Exchange Rate</th>
                <th style={tableHeaderStyle}>Document Number</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Gross Amount</th>
                <th style={tableHeaderStyle}>Open Amount</th>
                <th style={tableHeaderStyle}>Pay Item</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                key={rowData.uiUkNo}
                //onClick={() => onDataSelect(rowData)}
                style={{
                  backgroundColor:
                    index % 2 === 0 ? "aliceblue" : "#ffffff",
                }}
                className="table-row"
              >
                <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.clUkNo}
                        checked={selectedRowsInvoices.includes(rowData.clUkNo)}
                        onChange={() => handleRowSelect(rowData.clUkNo)}
                      />
                    </td>
                      <td style={{display:"none"}}>{rowData.clUkNo}</td>
                      <td style={tableCellStyle}>{rowData.cl_CU_NO}</td>
                      <td style={tableCellStyle}>{rowData.name}</td>
                      <td style={tableCellStyle}>{rowData.cl_CR_CD}</td>
                      <td style={tableCellStyle}>{rowData.cl_CR_RT}</td>
                      <td style={tableCellStyle}>{rowData.cl_DO_NO}</td>
                      <td style={tableCellStyle}>{rowData.cl_DO_TY}</td>
                      <td style={tableCellStyle}>{rowData.cl_DO_CO}</td>
                      <td style={tableCellStyle}>{rowData.cl_GR_AM}</td>
                      <td style={tableCellStyle}>{rowData.cl_OP_AM}</td>
                      <td style={tableCellStyle}>{rowData.cl_DO_PI}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="Pagination" style={{ marginLeft: "85%" }}>
          <span
            onClick={handleFirstPage}
            style={{
              fontSize: "200%",
              cursor: "pointer",
              whiteSpace: "pre-wrap",
            }}
          >
            &laquo;{" "}
          </span>{" "}
          <span
            onClick={handlePreviousPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Prev{" "}
          </span>{" "}
          <span style={{ whiteSpace: "pre-wrap", fontSize: "111%" }}> </span>{" "}
          <span
            onClick={handleNextPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Next{" "}
          </span>{" "}
          <span
            onClick={handleLastPage}
            style={{ fontSize: "200%", cursor: "pointer" }}
          >
            &raquo;
          </span>
        </div>
      </div>
      <AddressDialog
        open={showaddressdialog}
        handleClose={() => setshowaddressdialog(false)}
        data={FormData}
        onchangedata={onchangedata}
        handleSearch={handleSearch}
        ab_AD_CT={ab_AD_CT}
        ab_AD_CY={ab_AD_CY}
        ab_CL_12={ab_CL_12}
        ab_CL_11={ab_CL_11}
        ab_CL_13={ab_CL_13}
        ab_CL_07={ab_CL_07}
        ab_AD_TY={ab_AD_TY}
        ab_AC_IN={ab_AC_IN}
        abAdNo={abAdNo}
        ab_AD_NM = {ab_AD_NM}
        setOpen={() => setshowaddressdialog(false)}
        initialValue={initialValue}
        setab_AD_CT={setab_AD_CT}
        setab_AD_CY={setab_AD_CY}
        setab_CL_12={setab_CL_12}
        setab_CL_11={setab_CL_11}
        setab_CL_13={setab_CL_13}
        setab_CL_07={setab_CL_07}
        setab_AD_TY={setab_AD_TY}
        setab_AC_IN={setab_AC_IN}
        setab_AD_NO={setab_AD_NO}
        setab_AD_NM= {setab_AD_NM}
        FormData={FormData}
        setFormData={setFormData}
      />
    </Dialog>
  );
};

CustomerPopupData.propTypes = {
  tableData: PropTypes.array,
  filters: PropTypes.object,
  handleFirstPage: PropTypes.func,
  handlePreviousPage: PropTypes.func,
  handleNextPage: PropTypes.func,
  handleLastPage: PropTypes.func,
  handleFilterChange: PropTypes.func,
  toast: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
 // onDataSelect: PropTypes.func,
};

export default CustomerPopupData;
