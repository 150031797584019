import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import SupplierPopup from "../Popups/SupplierPopup";
import ItemPopup from "../Popups/ItemPopup";
import { Dropdown } from "primereact/dropdown";
import Costcenter from "views/common/CostCentreDrpDwn";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function WorkCenterProductCapacityDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  WorkCenterProductCapacityTableData,
  setWorkCenterProductCapacityTableData,
  ep_TR_CR,
  setep_TR_CR,
  ep_PO_NM,
  setep_PO_NM,
  ep_EX_CT,
  setep_EX_CT,
  ep_EX_TY,
  setep_EX_TY,
  ep_LO_CN,
  setep_LO_CN,
  selectedSupplier,
  setselectedSupplier,
  ep_CO_NO,
  wp_WK_CT,
  setwp_WK_CT,
  wp_CO_CT,
  setwp_CO_CT,
  wp_PD_GP,
  setwp_PD_GP,
  wp_TR_UM,
  setwp_TR_UM,
  wp_CO_NO,
  item_DES,
}) {
  const {
    wpUkNo,
    wp_IT_NM,
    wp_PL_QT,
    wp_RQ_TM,
    wp_ST_HR,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,multitableheader} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [ep_PO_NMOptions, setep_PO_NMOptions] = useState([]);
  const [ep_TR_CROptions, setep_TR_CROptions] = useState([]);
  const [ep_EX_CTOptions, setep_EX_CTOptions] = useState([]);
  const [ep_EX_TYOptions, setep_EX_TYOptions] = useState([]);
  const [ep_LO_CNOptions, setep_LO_CNOptions] = useState([]);

  const [wp_WK_CTOptions, setwp_WK_CTOptions] = useState([]);
  const [wp_CO_CTOptions, setwp_CO_CTOptions] = useState([]);
  const [wp_PD_GPOptions, setwp_PD_GPOptions] = useState([]);
  const [wp_TR_UMOptions, setwp_TR_UMOptions] = useState([]);
  
  //AdvanceSearch
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ItemVisible, setItemVisible] = useState(false);


  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  
  const handleItem = async (data) => {
    document.getElementById(`wp_IT_NM_${SelectedRowIndex}`).value = data.im_IT_NM;
    document.getElementById(`item_DES_${SelectedRowIndex}`).value = data.im_IT_DS;
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  //Work Center
  const onchangeDropDownFormDataWorkCentre = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwp_WK_CT(value);
  };
    //Cost Center
    const onchangeDropDownFormDataCostCentre = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setwp_CO_CT(value);
     
    };
    //Product Group
    const onchangeDropDownFormDataProductGroup = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setwp_PD_GP(value);
    };
      // QuantityUM
  const onchangeDropDownFormDataQuantityUM = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwp_TR_UM(value);
  };
  // Policy Name
  const GroupProfileDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_PO_NM(value);
  };


  // Curr
  const CurrencyDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_TR_CR(value);
  };

 // Expense Category
const ExpenseCategoryDropdown = (name, value) => {
  const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_EX_CT(value);
};
  // Expense Type
const ExpenseTypeDropdown = (name, value) => {
  const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_EX_TY(value);
};

  // Location
const LocationDropdown = (name, value) => {
  const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_LO_CN(value);
};

  useEffect(() => {
    GroupProfile();
    PolicyName();
    ExpenseCategory();
    ExpenseType();
    Location();
    WorkCenter();
    Costcenter1();
    ProductGroup();
    QuantityUM();

  }, []);

  const Costcenter1 = async () => {
    const Options = await fetchCostCenter();
    setwp_CO_CTOptions(Options);
  };
  const WorkCenter = async () => {
    const Options = await fetchCostCenter();
    setwp_WK_CTOptions(Options);
  };
  const ProductGroup = async () => {
    const Options = await fetchClassCodeDS("IN/PD");
    setwp_PD_GPOptions(Options);
  };
  const QuantityUM = async () => {
    const Options = await fetchClassCodeDS("IN/UM");
    setwp_TR_UMOptions(Options);
  };
  const GroupProfile = async () => {
    const Options = await fetchClassCodeDS("EX/PO");
    setep_PO_NMOptions(Options);
  };

  const PolicyName = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setep_TR_CROptions(Options);
  };
  const ExpenseCategory = async () => {
    const Options = await fetchClassCodeDS("EX/CT");
    setep_EX_CTOptions(Options);
  };
  const ExpenseType = async () => {
    const Options = await fetchClassCodeDS("EX/TY");
    setep_EX_TYOptions(Options);
  };
  const Location = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setep_LO_CNOptions(Options);
  };


  const addRow = () => {
    setWorkCenterProductCapacityTableData([...WorkCenterProductCapacityTableData, {}]);
  };
  
  const deleteRow = () => {
    if (WorkCenterProductCapacityTableData.length > 0) {
      const updatedData = [...WorkCenterProductCapacityTableData];
      updatedData.pop();
      setWorkCenterProductCapacityTableData(updatedData);
    }
  };
const isUpdateMode = Boolean(wpUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  const [SupplierVisible, setSupplierVisible] = useState(false);

  const handleDataSelect =async (data) => {
    setselectedSupplier(data.ab_AD_NM);
    document.getElementById (`ep_PR_SU_${SelectedRowIndex}`).value =  data.abAdNo;
    document.getElementById (`sup_NAME_${SelectedRowIndex}`).value =  data.ab_AD_NM;
    setSupplierVisible(false);
    setShowSearchIcon(false);
  };
  const openSupplierPopup = (e) => {
    setSupplierVisible(true);
    setSelectedRowIndex(e);
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "80rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={wpUkNo ? "Revise Work Center Wise Product Capacity" : "Add Work Center Wise  Product Capacity"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div  style={inboxstyle}>
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wp_WK_CT" className="font-bold">   
                    Work Centre
                    </label>
                    <Dropdown
                    name="wp_WK_CT"
                    value={wp_WK_CT}
                    options={wp_WK_CTOptions}
                    onChange={(e) => onchangeDropDownFormDataWorkCentre("wp_WK_CT", e.value)}
                    placeholder=" Work Centre"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wp_CO_CT" className="font-bold">
                    Cost Centre
                    </label>
                    <Dropdown
                    name="wp_CO_CT"
                    value={wp_CO_CT}
                    options={wp_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormDataCostCentre("wp_CO_CT", e.value)}
                    placeholder="Cost Centre"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wp_PD_GP" className="font-bold">
                    Product Group
                    </label>
                    <Dropdown
                    name="wp_PD_GP"
                    value={wp_PD_GP}
                    options={wp_PD_GPOptions}
                    onChange={(e) => onchangeDropDownFormDataProductGroup("wp_PD_GP", e.value)}
                    placeholder="Product Group"
                  />
                  </div>
                  
          </div>
          </div>  
          <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="WorkCenterProductCapacitytable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Item Number</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>UM</th>
              <th>Required Time (Hrs)</th>
              <th>Set Up Hrs</th>
              <th>Company</th>
            </tr>
          </thead>
          <tbody>
            {WorkCenterProductCapacityTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`wpUkNo_${index}`}
                    name={`wpUkNo_${index}`}
                    value={row.wpUkNo}
                  />
                </td>
                <td
                    style={{ display: "flex" }}>
                    <InputText
                      style={{ width: "110px" }}
                      id={`wp_IT_NM_${index}`}
                      name={`wp_IT_NM_${index}`}
                      onChange={(e) => onchangeFormData(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.wp_IT_NM ? row.wp_IT_NM : row[`${"wp_IT_NM"}_${index}`]}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "15%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openItemPopup(index)}
                      ></i>
                    )}
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "200px" }}
                      id={`item_DES_${index}`}
                      name={`item_DES_${index}`}
                      value={row.item_DES ? row.item_DES : row[`${"item_DES"}_${index}`]}
                      readOnly
                    />
                  </td>
                  <td >
                  <InputText style={{width:"100px"}}
                    id={`wp_PL_QT_${index}`}
                    name={`wp_PL_QT_${index}`}
                    defaultValue={row.wp_PL_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                  <td>
                  <Dropdown
                    id={`wp_TR_UM_${index}`}
                    name={`wp_TR_UM_${index}`}
                    value={ data[`wp_TR_UM_${index}`] ? data[`wp_TR_UM_${index}`]: row.wp_TR_UM}
                    options={wp_TR_UMOptions}
                    onChange={(e) =>onchangeDropDownFormDataQuantityUM(`wp_TR_UM_${index}`, e.value)}
                    placeholder="Quantity UM"
                  />
                  </td>
             
                  <td >
                  <InputText style={{width:"100px"}}
                    id={`wp_RQ_TM_${index}`}
                    name={`wp_RQ_TM_${index}`}
                    defaultValue={row.wp_RQ_TM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`wp_ST_HR_${index}`}
                    name={`wp_ST_HR_${index}`}
                    defaultValue={row.wp_ST_HR}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`wp_CO_NO_${index}`}
                    name={`wp_CO_NO_${index}`}
                    defaultValue={wp_CO_NO}
                    onChange={(e) => onchangedata(e)}
                    disabled
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <SupplierPopup
        visible={SupplierVisible}
        onClose={() => setSupplierVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
            {/* item */}
            <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
