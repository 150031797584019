import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
import { Dropdown } from "primereact/dropdown";
import ButtonStyles from "views/common/ButtonStyles";
import { Calendar } from "primereact/calendar";
import moment from "moment";
export default function FreeGoodsCatalogDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  BasePricedata,
  setBasePricedata,
  fc_CA_LG,
  setfc_CA_LG,  
  fc_CO_NO,
}) {
  const {
  fcUkNo, 
  fc_IT_NM,  
  fc_UN_PR,     
  fc_FR_DT,
  fc_TO_DT,   
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,multitableheader} = ButtonStyles();
  const addRow = () => {
    setBasePricedata([...BasePricedata, {}]);
  };
  
  const deleteRow = () => {
    if (BasePricedata.length > 0) {
      const updatedData = [...BasePricedata];
      updatedData.pop();
      setBasePricedata(updatedData);
    }
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
const isUpdateMode = Boolean(fcUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ItemVisible, setItemVisible] = useState(false);

  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

  const handleItem =async (data) => {
    document.getElementById(`fc_IT_NM_${SelectedRowIndex}`).value = data.im_IT_NM;
    document.getElementById(`item_DES_${SelectedRowIndex}`).value = data.im_IT_DS;

    setItemVisible(false);
    setShowSearchIcon(false);
  };
  //dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [fc_CA_LGOptions, setfc_CA_LGOptions] = useState([]);  
  

//type
  const onchangeDropDownFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setfc_CA_LG(value);   
  };
 
  
  useEffect(() => {
    fetchDropdownBT();
   
  }, []);

  const fetchDropdownBT = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setfc_CA_LGOptions(Options);   
  };

  
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={fcUkNo ? "Revise Item Catalogue" : "Add Item Catalogue"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox}>
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="BasePrice">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Catalogue</th>
              <th>Item Number</th>                             
              <th>Unit Price</th>              
              <th>From Date</th>
              <th>To Date</th>              
              <th>Company</th>              
            </tr>
          </thead>
          <tbody>
            {BasePricedata.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`fcUkNO_${index}`}
                    name={`fcUkNO_${index}`}
                    value={row.fcUkNo}
                  />
                </td>
                 <td>                        
                <Dropdown 
                  id={`fc_CA_LG_${index}`}
                  name={`fc_CA_LG_${index}`}
                  value={data[`fc_CA_LG_${index}`] ? data[`fc_CA_LG_${index}`] : row.fc_CA_LG}
                  options={fc_CA_LGOptions}
                  onChange={(e) => onchangeDropDownFormData(`fc_CA_LG_${index}`, e.value)}
                  placeholder="Currency"
                />
                </td>

                <td style={{ display: "flex" }}>
              <InputText 
              disabled={!!fcUkNo}
              style={{ width: "110px" }}
              id={`fc_IT_NM_${index}`}
              name={`fc_IT_NM_${index}`}
              onChange={(e) => onchangeFormData(e)}
              onFocus={() => setShowSearchIcon(true)}
              defaultValue={row.fc_IT_NM ? row.bp_IT_NM: row[`${"fc_IT_NM"}_${index}`]}
              />
              {showSearchIcon && (
            <i
            className="pi pi-search"
            style={{
            color: "var(--primary-color)",
            fontSize: "119%",
            marginTop: "15%",
            cursor: "pointer",
            }}
            onClick={(e) => openItemPopup(index)}
            ></i>
            )}
                </td> 
                              
                <td>
                <InputText style={{width:"100px"}}
                    id={`fc_UN_PR_${index}`}
                    name={`fc_UN_PR_${index}`}
                    defaultValue={row.fc_UN_PR}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>               
                
                <td>
                  <Calendar style={{width:"150px"}}
                    id={`fc_FR_DT_${index}`}
                    name={`fc_FR_DT_${index}`}
                    value={ row.fc_FR_DT ? moment(row.fc_FR_DT, "YYYYMMDD").toDate() : null }
                    onChange={(e) => onDateChange({ target: { name: "fc_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </td>
                <td>
                  <Calendar style={{width:"150px"}}
                    id={`fc_TO_DT_${index}`}
                    name={`fc_TO_DT_${index}`}
                    value={ row.fc_TO_DT ? moment(row.fc_TO_DT, "YYYYMMDD").toDate() : null }
                    onChange={(e) => onDateChange({ target: { name: "bp_TO_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </td>
               
                <td>
                <InputText style={{width:"100px"}} disabled
                    id={`fc_CO_NO_${index}`}
                    name={`fc_CO_NO_${index}`}
                    defaultValue={fc_CO_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div> 
      </div>
       {/* item */}
    <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
