import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
// import AccountNoPopup from "views/admin/Popups/AccountNoPopup";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
export default function AllocationDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  handleConfirm,
  handleRun,
  onchangedata,
  onchangeFormData,
  ac_DO_TY,
  ac_DO_CO,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  addRow,
  deleteRow,
  Allocationtable,
  ac_ST_US,
  ac_SA_TE,
  SelectedemEMNO,
  setSelectedemEMNO,
}) {
  const { 
  acUkNo,
  ac_DO_NO,
  ac_FR_DT,
  ac_TO_DT,
  ac_FR_CY,
  ac_EX_PL,
  ac_CL_AC,
  ac_GL_DT,

  } = data;
  const [ac_FR_CYOptions, setac_FR_CYOptions] = useState([]);
  const [ac_SA_TEOptions, setac_SA_TEOptions] = useState([]);
  const [ac_ST_USOptions, setac_ST_USOptions] = useState([]);
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,multitableheader} = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const url = `${domain}/api/ClassCode/All`;
  // comp type
  const parameter1 = "RN/TY";
  const finalUrl1 = `${url}/${parameter1}`;
// comp level
  const parameter2 = "AB/ST";
  const finalUrl2 = `${url}/${parameter2}`;
// provider
  const parameter3 = "AL/ST";
  const finalUrl3 = `${url}/${parameter3}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
  }, []);

  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setac_FR_CYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setac_SA_TEOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl3, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setac_ST_USOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const [SelectedRowIndex, setSelectedRowIndex] = useState("");
const [showSearchIcon, setShowSearchIcon] = useState("");
const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
const openAccountPopup = (e) => {
  setAccountPopupVisible(true);
  setSelectedRowIndex(e);
};
const [AccountclrPopupVisible, setAccountclrPopupVisible] = useState(false);
const openAccountclrPopup = (e) => {
  setAccountclrPopupVisible(true);
  setSelectedRowIndex(e);
};

const handleDataSelect1 = (data) => {
  setSelectedemEMNO(data.am_AC_DS);
  onchangeFormData("ac_CL_AC", data.account);
  onchangeFormData("clear_DESC", data.am_AC_DS);
  // changeaccount(data);
  setAccountclrPopupVisible(false);
  setShowSearchIcon(false);
};
const handleDataSelect = (data) => {
  onchangeFormData("ac_AC_NO", data.account, SelectedRowIndex);
  onchangeFormData("acnt_DESC", data.am_AC_DS, SelectedRowIndex);
  onchangeFormData("ac_CO_NM", data.am_CO_NM, SelectedRowIndex);
  onchangeFormData("ac_OB_AC", data.am_OB_AC, SelectedRowIndex);
  onchangeFormData("ac_OB_SB", data.am_OB_SB, SelectedRowIndex);
  document.getElementById (`ac_CO_NM_${SelectedRowIndex}`).value =  data.am_CO_NM;
  document.getElementById (`ac_OB_AC_${SelectedRowIndex}`).value =  data.am_OB_AC;
  document.getElementById (`ac_OB_SB_${SelectedRowIndex}`).value =  data.am_OB_SB;
  setAccountPopupVisible(false);
  setShowSearchIcon(false);
};

const isUpdateMode = Boolean(acUkNo);
  const productDialogFooter = (
    <React.Fragment>
       <div style={{ display: 'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end', width: '100%' }}>
       {isUpdateMode && (
        <div style={{ display: 'flex',width:"10%" }}>
        <Button 
          style={{width:"100%"}}
          icon="pi pi-check-circle" 
          onClick={handleConfirm} 
          tooltip={"Confirm"}>
        </Button>
        <Button 
          style={{width:"100%"}}
          icon="pi pi-play" 
          onClick={handleRun} 
          tooltip={"Run"}>
        </Button>
        </div>
       )}
        <div style={{ display: 'flex',width:"10%" }}>
        <Button 
          style={{width:"100%"}}
          icon="pi pi-times" 
          variant="outlined" 
          onClick={handleClose}
          tooltip="Cancel"
        >
        </Button>
        <Button 
          style={{width:"100%"}}
          icon="pi pi-check" 
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}>
        </Button>
        </div>
    </div>
  </React.Fragment>
  );
// date default
const [selectedDate, setSelectedDate] = useState(moment().toDate());
const handleDateChange = (e) => {
  setSelectedDate(e.value);
  onchangedata({ target: { name: "ac_FR_DT", value: moment(e.value).format("YYYYMMDD") } });
};
// disabled after confirm
const isDisabled = ac_ST_US === "C";
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={acUkNo ? "Revise Allocation" : "Add Allocation"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
    <div style={borderbox} >
      <div style={inboxstyle}>
        <div className="formgrid grid">
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ac_DO_NO" className="font-bold">
              Document No
              </label>
              <InputText disabled
                id="ac_DO_NO"
                name="ac_DO_NO"
                value={ac_DO_NO}
                onChange={(e) => onchangedata(e)}
              />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ac_DO_TY" className="font-bold">
                Type
            </label>
            <InputText disabled
              id="ac_DO_TY"
              name="ac_DO_TY"
              value={ac_DO_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div> 
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ac_DO_CO" className="font-bold">
            Company
            </label>
            <InputText disabled
              id="ac_DO_CO"
              name="ac_DO_CO"
              value={ac_DO_CO}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ac_FR_DT" className="font-bold">
           From Date
            </label>
            <Calendar disabled={isDisabled}
              id="ac_FR_DT"
              name="ac_FR_DT"
              value={moment(ac_FR_DT, "YYYYMMDD").toDate()}
              onChange={handleDateChange}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ac_TO_DT" className="font-bold">
           To Date
            </label>
              <Calendar disabled={isDisabled}
                id="ac_TO_DT"
                name="ac_TO_DT"
                value={moment(data.ac_TO_DT, "YYYYMMDD").isValid() ? moment(data.ac_TO_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "ac_TO_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ac_FR_CY" className="font-bold">
              Frequency
            </label>
            <Dropdown disabled={isDisabled}
              id="ac_FR_CY"
              name="ac_FR_CY"
              value={ac_FR_CY}
              options={ac_FR_CYOptions}
              onChange={(e) => onchangeDropDownFormData1("ac_FR_CY", e.value)}
              placeholder="Select Frequency"
            />
            
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ac_EX_PL" className="font-bold">
            Explanation
            </label>
            <InputText disabled={isDisabled}
              id="ac_EX_PL"
              name="ac_EX_PL"
              value={ac_EX_PL}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ac_SA_TE" className="font-bold">
              State
            </label>
            <Dropdown disabled={isDisabled}
              id="ac_SA_TE"
              name="ac_SA_TE"
              value={ac_SA_TE}
              options={ac_SA_TEOptions}
              onChange={(e) => onchangeDropDownFormData2("ac_SA_TE", e.value)}
              placeholder="Select State"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ac_GL_DT" className="font-bold">
           GL Date
            </label>
            <Calendar disabled={isDisabled}
                id="ac_GL_DT"
                name="ac_GL_DT"
                value={moment(data.ac_GL_DT, "YYYYMMDD").isValid() ? moment(data.ac_GL_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "ac_GL_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="ac_CL_AC" className="font-bold">
                  Clearing Account <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText disabled={isDisabled}
                  id="am_AC_DS"
                  name="am_AC_DS"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedemEMNO}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openAccountclrPopup}
                ></i>
                )}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="ac_CL_AC"
                  name="ac_CL_AC"
                  value={ac_CL_AC}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="ac_ST_US" className="font-bold">
              Status
            </label>
            <Dropdown disabled
              id="ac_ST_US"
              name="ac_ST_US"
              value={ac_ST_US}
              options={ac_ST_USOptions}
              onChange={(e) => onchangeDropDownFormData3("ac_ST_US", e.value)}
              placeholder="Select Status"
            />
          </div>
        </div>
      </div>
    </div>
    <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="Allocationtable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Account <span style={{color:"red"}}>*</span> </th>
          <th>Description</th>
          <th>Cost Centre</th>
          <th>Object Account</th>
          <th>Subsidiary</th>
          <th>Percentage</th>
        </tr>
      </thead>
      <tbody>
      {Allocationtable.map((row, index) => (
              <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
              id={`acUkNo_${index}`}
              name={`acUkNo_${index}`}
              defaultValue={row.acUkNo}
            />
          </td>
          <td>
          <div style={{display:"flex"}}>
            <InputText disabled={isDisabled}
              id={`ac_AC_NO_${index}`}
              name={`ac_AC_NO_${index}`}
              onChange={(e) => onchangedata(e)}
              onFocus={() => setShowSearchIcon(true)}
              value={row.ac_AC_NO ? row.ac_AC_NO: row[`${"ac_AC_NO"}_${index}`]}
            />
            {showSearchIcon && (
              <i 
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "7%",
                  cursor: "pointer",
                }}
                onClick={(e) => openAccountPopup(index)}
              ></i>
                )}
          </div>
        </td>
        <td style={{ width: "200px" }}>
        <InputText disabled
          id={`acnt_DESC_${index}`}
          name={`acnt_DESC_${index}`}
          value={row.acnt_DESC ? row.acnt_DESC : row[`${"acnt_DESC"}_${index}`]}
          onChange={(e) => onchangedata(e)}
        />
        </td>
          <td style={{width:"100px"}}>
          <InputText  disabled 
            id={`ac_CO_NM_${index}`}
            name={`ac_CO_NM_${index}`}
            defaultValue={row.ac_CO_NM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          <td style={{width:"100px"}}>
          <InputText  disabled 
            id={`ac_OB_AC_${index}`}
            name={`ac_OB_AC_${index}`}
            defaultValue={row.ac_OB_AC}
           readOnly
          />
          </td>
          <td style={{width:"100px"}}>
          <InputText  disabled
            id={`ac_OB_SB_${index}`}
            name={`ac_OB_SB_${index}`}
            defaultValue={row.ac_OB_SB}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td style={{width:"100px"}}>
          <InputText disabled={isDisabled}
            id={`ac_AL_PR_${index}`}
            name={`ac_AL_PR_${index}`}
            defaultValue={row.ac_AL_PR}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          </tr>
           ))}
      </tbody>
    </table>   
    
    </div>
    </div>  
    <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      /> 
    <AccountNoPopup
        visible={AccountclrPopupVisible}
        onClose={() => setAccountclrPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
  
    </Dialog>
  );
}
