import React from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import 'primeflex/primeflex.css';                                   // css utility
import '../../common/flags.css';
import SupplierLedgeMain from './SupplierLedgerMain';

const SupplierLedgeDialog = () => {

  return (
   <div className='saleslead'>
      <SupplierLedgeMain/>
   </div>
  )
}

export default SupplierLedgeDialog