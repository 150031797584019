import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import moment from "moment";

const Buttonstyles = () => {
// multirow styles table svroll bar
  const multirowstyle = {
  overflow:'scroll',
  display:'block',
  maxHeight:'20rem'
  };
// STYLES FOR BUTTONS(EXTRA)
  const GenerateStyles = {
    backgroundColor: "#75ceff",
    color: "white",
    lineHeight: "10%",
  };
  const postStyles = {
    backgroundColor: "#0099FF",
    color: "white",
    lineHeight: "10%",
  };  
  const cancelButtonStyles = {
    backgroundColor: "#FF3366",
    color: "white",
    lineHeight: "10%",
  };
  const poststyles = {
    backgroundColor: "#0099FF",
    color: "white",
    lineHeight: "10%",
    width:"100%",
    border:"none"
  }; 

  const buttonStyles = {
    backgroundColor: "#82E0AA",
    color: "white",
    lineHeight: "10%",
  };
  const addButtonStyles = {
    backgroundColor: "#76D7C4",
    color: "white",
    lineHeight: "10%",
  };
  const copyButtonStyles = {
    backgroundColor: "#AED6F1",
    color: "white",
    lineHeight: "10%",
  };
  const exportButtonStyles = {
    height: "35px",
    backgroundColor:"#1ed01e",
    border:"none"
  };
  const checkboxStyle = {
    border: "6px solid #a7d6ff8c",
    borderRadius: "4px",
    width: "100%",
  };
  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
  };
  const tableHeaderStyle1 = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width:"100%",
  };
  const inputStyle = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%"
  };
  const checkStyle = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"0%"
  };
  const inputStyle1 = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };
  const tableCellStyle1 = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    width:"5rem"
  };
  // ALINED STYLES
  const rightalined = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "right",
  };
  const tableHeaderStyleAC = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width:"10%"
  };
  const tableCellStyleH = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    display:"none"
  };
  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
    backgroundColor:"#f5f4f4"
  };
  const tableHeaderStyleH = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f5f4f4",
    display:"none"
  };
  // dialog
  const Dialogstyle = {
    width: "110rem",margin:"0px",position:"fixed",top:"5rem",left:"22rem",height:'-webkit-fill-available',
  }
  const borderbox = {
    border: "1px solid #e8e8e8",
    padding: "8px",
    borderRadius: "15px",
    overflowY:'auto',
  };
  const inboxstyle ={
    marginTop: "2%",
    border: "1px solid #e8e8e8",
    padding: "16px",
    borderRadius: "15px",
    backgroundColor: "aliceblue",
    overflow: "auto",
  };
  const TabBoxStyle ={
    border: "1px solid #e8e8e8",
    padding: "16px",
    borderRadius: "15px",
    backgroundColor: "aliceblue",
  };
//dialog table
  const plusstyle = {
    marginRight: "8px",
    backgroundColor: "transparent",
    color: "black",
    border: "none",
  };
  const minusstyle ={
    backgroundColor: "transparent",
    color: "black",
    border: "none",
  };
  const tablebutton ={
    display: "flex",
    justifyContent: "flex-end",
    // margin: "0 10px 10px 0",
    // marginTop: "2%",
  };
  const tablestyle ={
    border: "1px solid #e8e8e8",
    padding: "16px",
    borderRadius: "15px",
    backgroundColor:"aliceblue",
    marginTop: "2%",
  };
  const multitablestyle ={
    border: "1px solid #e8e8e8",
    padding: "16px",
    borderRadius: "15px",
    backgroundColor: "aliceblue",
    overflow: "auto",
    position: "relative",
  };
  // direct sales screen styles
  const borderboxdir = {
    border: "1px solid #e8e8e8",
    padding: "10px",
    borderRadius: "15px",
  };
  const inboxstyledir ={
    border: "1px solid #e8e8e8",
    padding: "5px",
    borderRadius: "15px",
    backgroundColor: "aliceblue",
    overflow: "auto",
  };
 const offerbtnstyles = {
  backgroundColor:"#B5EFF9",
  border:"none",
  color:"#000000c4",
  fontSize:"90%"
 };
 const pricestyles ={
  backgroundColor: '#f9f9f9',
  padding: '1rem',
  border: '1px solid #ddd',
  height: '16vh',
  marginTop: '5rem'
 };
 const inboxstylesales ={
  border: "1px solid #e8e8e8",
  padding: "16px",
  borderRadius: "15px",
  backgroundColor: "aliceblue",
  overflow: "auto",
 };
const inputboxstyles = {
  width:'10rem',
  height:'2rem',
  border:"none"
};
const savestyle = {
  width: "5%",
  marginRight: "0.5rem",
  border:"none",
  backgroundColor:"transparent",
  color:"#2e632e",
};
const cancelstyle = {
  width: "5%",
  border:"none",
  backgroundColor:"transparent",
  color:"red",
};
const confirmstyle = {
  border:"none",
  backgroundColor:"transparent",
  color:"blue",
};
const savestyleW = {
  marginRight: "0.5rem",
  border:"none",
  backgroundColor:"transparent",
  color:"#2e632e",
};
const cancelstyleW = {
  border:"none",
  backgroundColor:"transparent",
  color:"red",
};
const PopupDailogStyle = {
  width:"90rem",
  height:"50rem",
}

 // footer styles
  const footergridstyle ={
    display: "flex", flexWrap: "wrap"
  };
  const footerfieldstyle ={
    flex: "1 1 20%", padding: "8px"
  };
  // dateformat
  const formatDate = (inputDate) => {
    if (!inputDate) {
      return inputDate;
    }
    const dateString = inputDate.toString();
    if (dateString.length !== 8) {
      return dateString;
    }
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${day}/${month}/${year}`;
  };
  //TIMEFORMAT
  const formatTime = (inputTime) => {
    if (!inputTime) {
      return inputTime;
    }
    return moment(inputTime, "HH:mm:ss").format("HH:mm:ss");
  };
  return { 
    // multirow style
    multirowstyle,
    // save & cancel styles
    savestyle,cancelstyle,confirmstyle,Dialogstyle,PopupDailogStyle,TabBoxStyle, savestyleW,cancelstyleW,

    formatTime,formatDate,
    buttonStyles, addButtonStyles, copyButtonStyles,poststyles,
    exportButtonStyles,checkboxStyle,tableHeaderStyle,tableHeaderStyle1,GenerateStyles,cancelButtonStyles,
    inputStyle,tableCellStyle,tableCellStyle1,postStyles,rightalined,tableHeaderStyleAC,inputStyle1,checkStyle,
    // dialog styles
    borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,
    // footer styles
    footergridstyle,footerfieldstyle,tableHeaderStyleH,tableCellStyleH,inputStyleH,
    // direct sales screen styles
    borderboxdir,inboxstyledir,offerbtnstyles,pricestyles,inboxstylesales,inputboxstyles
   };
};

export default Buttonstyles;
