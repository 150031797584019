import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import FloorInformationDialog from "./FloorInformationDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  ar_DESC:"",
  fi_BL_NO: "",
  fi_BL_DIS: "",
  fi_FL_NO: "",
  fi_PR_NO:"",
  fi_PR_DIS:"",
  fi_AR_GP:"",
  fi_CO_NO:"",
  bu_DESC:"",
  floor_DESC:"",
  Ar_Disc:"",
  bi_TR_UM:"",
  fi_ST_DT:"",
  fi_EN_DT:"",
  fiUkNo:"",
  fi_AR_GP:"",
  fi_TR_QT:"",
  UK_NO:"",
  fi_AR_TY: "",
};

const initialFilters = {
  fi_AR_TY: { value: "", operation: "" },
  fi_TR_QT: { value: "", operation: "" },
  fi_PR_NO: { value: "", operation: "" },
  fi_BL_NO: { value: "", operation: "" },
  bu_DESC: { value: "", operation: "" },
  fi_FL_NO: { value: "", operation: "" },
  fi_CO_NO: { value: "", operation: "" },  
  fi_TR_UM: { value: "", operation: "" },  
};

const FloorInformationMain = () => {
      // buttonstyles
      const {formatDate, buttonStyles, addButtonStyles,
        copyButtonStyles,
        checkboxStyle,tableHeaderStyle,
        inputStyle,tableCellStyle,
       } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [fi_FL_NO,setfi_FL_NO] =useState([]);
  const [fi_AR_GP,setfi_AR_GP] = useState([]);
  const [fi_BL_NO, setfi_BL_NO] = useState("");
  const [fi_AR_TY, setfi_AR_TY] = useState("");
  const [fi_TR_UM, setfi_TR_UM] = useState("");
  const [fi_PR_NO, setfi_PR_NO] = useState("");
  const [FloorInformationtable, setFloorInformationtable] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFloorInformationtable([{}]);
    setFormData(initialValue);
    setfi_BL_NO(null);
    setfi_AR_TY(null);
    setfi_TR_UM(null);
    setfi_FL_NO(null);
    setfi_PR_NO(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFloorInformationtable([{}]);
    setFormData(initialValue);
    setfi_BL_NO(null);
    setfi_AR_TY(null);
    setfi_TR_UM(null);
    setfi_FL_NO(null);
    setfi_PR_NO(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const addRow = () => {
    setFloorInformationtable([...FloorInformationtable, {}]);
  };

  const deleteRow = () => {
    if (FloorInformationtable.length > 0) {
      const updatedData = [...FloorInformationtable];
      updatedData.pop();
      setFloorInformationtable(updatedData);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/FloorInformation/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const FloorInformationData = response.data;
          delete FloorInformationData.fiUkNo;
          for (let i = 0; i < FloorInformationData.floorInformationList.length; i++) {
            delete FloorInformationData.floorInformationList[i].fiUkNo;
          }
          setFloorInformationtable(FloorInformationData.floorInformationList);
          setFormData(FloorInformationData.floorInformationList[0]);
          setfi_BL_NO(FloorInformationData.fi_BL_NO);
          setfi_PR_NO(FloorInformationData.fi_PR_NO);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const url = `${domain}/api/Contact/AllFields`;
  const [fi_CO_NO , setfi_CO_NO ] = useState("");
    const handleDefault = async () => {
      setfi_CO_NO(`${company}`);
      const linkPath = String(window.location);
      const l_array = linkPath.split("=");
      const LinkId = l_array[1];
      
      const requestBody = {
        "version_ID" : LinkId
      };
      const paginationUrl = `${domain}/api/LinkId/Select`;
      try {
        const response = await axios.post(paginationUrl, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataDefault = response.data;
        const listDefault = [];
        var count = 0;
        if (dataDefault.versionIds != null) {
          for (let i = 0; i < dataDefault.versionIds.length; i++) {
            if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
              const fName = dataDefault.versionIds[i].field_ID;
              // setfi_CO_NO(`${company}`);
              // if(fName === "SD_OR_TY"){
              //   setsdOrTy(dataDefault.versionIds[i].default_VALUE)
              // }
  
            }
            if(dataDefault.versionIds[i].field_TYPE === "LIST"){
              count++;
              const def = {
                "column" : dataDefault.versionIds[i].field_ID ,
                "value" : dataDefault.versionIds[i].default_VALUE,
                "operation" : "EQUAL"
              }
              listDefault.push(def);
            }
          }
          handleSearch(listDefault,count);
        } else {
          handleSearch(listDefault,count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/FloorInformation/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  // const onchangeDropDownFormData1 = (name, value) => {
  //   setFormData({ ...FormData, [name]: value });
  //   setrc_CO_TY(value);
  // };
  
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setfi_FL_NO(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setfi_PR_NO(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setfi_BL_NO(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setfi_AR_TY(value);
  };
  const onchangeDropDownFormData5= (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setfi_TR_UM(value);
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/FloorInformation/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const FloorInformationData = response.data;
        setFloorInformationtable(FloorInformationData.floorInformationList);
        setFormData(FloorInformationData.floorInformationList[0]);
        setfi_BL_NO(response.data.fi_BL_NO);
        setfi_PR_NO(response.data.fi_PR_NO);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleFormSubmit = () => {   
    const table = document.getElementById("FloorInformationtable");
    const totalRowCount = table.tBodies[0].rows.length;
    const UK_NO = FormData.fiUkNo;
    const PR_NO = FormData.fi_PR_NO;
    const BL_NO = FormData.fi_BL_NO;
    const CO_NO = document.getElementById("fi_CO_NO");
    const floorInformationList = [];
    for (let i = 0; i < totalRowCount; i++) {
      const UK_NO = document.getElementById("fiUkNo_" + i);
      const FL_NO = table.tBodies[0].rows[i].cells[1].children[0].children[1].children[0].value;
      const AR_TY = table.tBodies[0].rows[i].cells[2].children[0].children[1].children[0].value;        
      const TR_QT = document.getElementById("fi_TR_QT_" + i);
      const TR_UM = table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value;
      // const ST_DT = document.getElementsByName("fi_ST_DT_" + i)[0];
      // const EN_DT = document.getElementsByName("fi_EN_DT_" + i)[0];
            
      const Details = {
        fiUkNo: UK_NO.value,
        fi_FL_NO: FL_NO,
        fi_AR_TY: AR_TY,
        fi_TR_QT: TR_QT.value,
        fi_TR_UM: TR_UM,
        // fi_ST_DT: ST_DT.value?moment(ST_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        // fi_EN_DT: EN_DT.value?moment(EN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
      };
      floorInformationList.push(Details);
    }
    const requestBody = {
      floorInformationList,
      fiUkNo : UK_NO,
      fi_PR_NO: PR_NO,
      fi_BL_NO: BL_NO,
      fi_CO_NO: CO_NO.value,
    };

      if (FormData.fiUkNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/FloorInformation/Update`;
        if (confirm) {
          axios
            .put(update, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        axios
          .post(`${domain}/api/FloorInformation/Add`, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    } ;

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(0);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };
  const tableHeaderStyleC = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#e1e1e1",
    width:"0%",

  };
  const tableHeaderStyleH = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f5f4f4",
    display:"none"
  };
  const tableCellStyleA = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "right",
    width:"0%",
  };
  const tableCellStyleC = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
    width:"0%",
  };
  const tableCellStyleH = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    display:"none"
  };
  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
    backgroundColor:"#f5f4f4"
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const handleImport = (Event) => {
    const file = Event.target.files[0];

    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (fileExtension === "csv" || fileExtension === "xlsx") {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const importedData = JSON.parse(e.target.result);
            setTableData(importedData);
            fetch(url, {
              method: "POST",
              body: JSON.stringify(importedData),
              headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
              },
            })
              .then((resp) => resp.json())
              .then((resp) => {
                handleDefault();
              });

            toast.current.show({
              severity: "success",
              summary: "Import Successful",
            });
          } catch (error) {
            console.error("Error parsing imported data:", error);
            toast.current.show({
              severity: "error",
              summary: "Import Error",
              detail:
                "Invalid data format. Please ensure the imported file is in the correct format.",
            });
          }
        };

        reader.readAsText(file);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Unsupported File Type",
          detail: "Please select a CSV or XLSX file for import.",
        });
      }
    }
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" , backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ar_DESC"] ? filters["ar_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ar_DESC",
                        e.target.value,
                        filters["ar_DESC"]
                          ? filters["ar_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fi_TR_QT"] ? filters["fi_TR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fi_TR_QT",
                        e.target.value,
                        filters["fi_TR_QT"]
                          ? filters["fi_TR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fi_PR_NO"] ? filters["fi_PR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fi_PR_NO",
                        e.target.value,
                        filters["fi_PR_NO"]
                          ? filters["fi_PR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["fi_BL_NO"] ? filters["fi_BL_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fi_BL_NO",
                        e.target.value,
                        filters["fi_BL_NO"]
                          ? filters["fi_BL_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["bu_DESC"] ? filters["bu_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bu_DESC",
                        e.target.value,
                        filters["bu_DESC"]
                          ? filters["bu_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fi_FL_NO"] ? filters["fi_FL_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fi_FL_NO",
                        e.target.value,
                        filters["fi_FL_NO"]
                          ? filters["fi_FL_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fi_CO_NO"] ? filters["fi_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fi_CO_NO",
                        e.target.value,
                        filters["fi_CO_NO"]
                          ? filters["fi_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fi_TR_UM"] ? filters["fi_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fi_TR_UM",
                        e.target.value,
                        filters["fi_TR_UM"]
                          ? filters["fi_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Property</th>
                <th style={tableHeaderStyleH}>Building</th>
                <th style={tableHeaderStyle}>Building</th>
                <th style={tableHeaderStyle}>Floor</th>
                <th style={tableHeaderStyle}>Area Type</th>
                <th style={tableHeaderStyle}>Area</th>
                <th style={tableHeaderStyleC}>UM</th>
                <th style={tableHeaderStyle}>Company</th>
               
           
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.fiUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.fiUkNo}
                        checked={selectedRows.includes(rowData.fiUkNo)}
                        onChange={() => handleRowSelect(rowData.fiUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.pt_DESC}</td>
                    <td style={tableCellStyleH}>{rowData.fi_BL_NO}</td>
                    <td style={tableCellStyle}>{rowData.bu_DESC}</td>
                    <td style={tableCellStyle}>{rowData.floor_DESC}</td>
                    <td style={tableCellStyle}>{rowData.ar_DESC}</td>
                    <td style={tableCellStyleA}>{rowData.fi_TR_QT}</td>
                    <td style={tableCellStyleC}>{rowData.fi_TR_UM}</td>
                    <td style={tableCellStyle}>{rowData.fi_CO_NO}</td>
                    
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

      </div>
      

      <FloorInformationDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData} 
        onchangeDropDownFormData1={onchangeDropDownFormData1} 
        onchangeDropDownFormData2={onchangeDropDownFormData2}  
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}       
        fi_FL_NO={fi_FL_NO}      
        fi_PR_NO={fi_PR_NO}   
        fi_AR_TY={fi_AR_TY}
        fi_TR_UM={fi_TR_UM}
        fi_BL_NO={fi_BL_NO}
        fi_CO_NO={fi_CO_NO}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        FloorInformationtable={FloorInformationtable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
  
      />
    </div>
  );
};

export default FloorInformationMain;

