import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import SalesInvoiceDialog from "./SalesInvoiceDialog";
import axios from "axios";
import "jspdf-autotable";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import '../../../components/ListStyle/ListStyle.css';
import { InputText } from "primereact/inputtext";
import moment from "moment";
import CustomerPopup from "../Popups/CustomerPopup";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";

const token = localStorage.getItem("token");
const company = localStorage.getItem("company");
const domain = localStorage.getItem("domain");
const SalesInvoiceMain = () => {
  const initialValue = {
    sd_UK_NO: "",
    sd_TR_CR: "",
    sd_IT_NM: "",
    im_IT_DS: "",
    sd_SH_QT: "",
    sd_TR_UM: "",
    sd_UT_PR: "",
    sd_EX_PR: "",
    sd_DS_AT: "",
    sd_TX_PE: "",
    sd_TX_AM: "",
    sd_FR_PR: "",
    sd_FR_AT: "",
    sd_FR_DS: "",
    sd_SH_NO: "",
    sd_SH_DT: "",
    sd_CU_PO: "",
    sd_PO_DT: "",
    sd_ST_CD:"",
    sd_CU_NO:"",
    sd_IN_DT: "",
    sd_GL_DT:"",
  };

  const initialFilters = {
    slLdId: { value: "", operation: "" },
    sl_TR_DT: { value: "", operation: "" },
    sl_CN_NO: { value: "", operation: "" },
    sl_CO_NM: { value: "", operation: "" },
    sl_TI_TL: { value: "", operation: "" },
    sl_AD_P1: { value: "", operation: "" },
    sl_AD_EM: { value: "", operation: "" },
    sl_IN_RY: { value: "", operation: "" },
    //sl_CI_TY: { value: "", operation: "" },
    //sl_LD_OW: { value: "", operation: "" },
    //sl_LD_ST: { value: "", operation: "" },
  };
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,inputStyle1,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [Customer, setCustomer] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SalesInvoiceTable, setSalesInvoiceTable] = useState([{}]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedShipto, setselectedShipto ] = useState("");
  const [auto_POST, setauto_POST] = useState("");
  const [sd_SH_NO, setsd_SH_NO ] = useState("");

  const addRow = () => {
    setSalesInvoiceTable([...SalesInvoiceTable, {}]);
  };
  const deleteRow = () => {
    if (SalesInvoiceTable.length > 0) {
      const updatedData = [...SalesInvoiceTable];
      updatedData.pop();
      setSalesInvoiceTable(updatedData);
    }
  };
  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      sd_CU_NO: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
const buildSearchRequestDto = () => {
  return Object.keys(filters)
    .filter((column) => filters[column].value)
    .map((column) => {
      let constructedColumn = column;

      if (column === 'sdOrNo') {
        constructedColumn = 'sdOrNo';
      } else if(column === 'sdOrTy') {
        constructedColumn = 'sdOrTy';
      } else {
        constructedColumn = column.toUpperCase();
      }

      return {
        column: constructedColumn,
        value: filters[column].value,
        operation: filters[column].operation,
      };
    });
};
const handleFilterChange = (column, value, operation) => {
  let updatedOperation = operation;
  let updatedValue = value;

  if (value.startsWith(">")) {
    updatedOperation = "GREATER_THAN";
    updatedValue = value.substring(1);
  } else if (value.startsWith("<")) {
    updatedOperation = "LESS_THAN";
    updatedValue = value.substring(1);
  } else if (value.includes(",")) {
    updatedOperation = "BETWEEN";
  } else if (!isNaN(value)) {
    updatedOperation = "EQUAL";
  } else {
    if (value === value.toUpperCase()) {
      updatedOperation = "LIKE";
    } else {
      updatedOperation = "LIKE";
    }
  }

  const updatedFilters = { ...filters };
  
  updatedFilters[column] = {
    value: updatedValue,
    operation: updatedOperation,
  };
  setFilters(updatedFilters);
};
const handleClickOpen = () => {
  setOpen(true);
};
const handleClose = () => {
  setFormData(initialValue);
  setOpen(false);
  setShowSearchIcon(false);
};
const handleResponse = (data) => {
  const { warnings = [], errorMessage = [], infos = [] } = data;

  if (warnings.length > 0) {
    const warningMessage = warnings.map((item) => `${item}\n`).join("");
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: warningMessage,
    });
  }

  if (infos.length > 0) {
    const infoMessage = infos.map((item) => `${item}\n`).join("");
    toast.current.show({
      severity: "info",
      summary: "Info",
      detail: infoMessage,
    });
  }

  if (errorMessage.length > 0) {
    const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMessages,
    });
  }

  if (errorMessage.length === 0) {
    handleClose();
    handleDefault();
  }
};
const handleDefault = async () => {
  const linkPath = String(window.location);
  const l_array = linkPath.split("=");
  const LinkId = l_array[1];
  
  const requestBody = {
    "version_ID" : LinkId
  };
  const paginationUrl = `${domain}/api/LinkId/Select`;
  try {
    const response = await axios.post(paginationUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataDefault = response.data;
    const listDefault = [];
    var count = 0;
    if (dataDefault.versionIds != null) {
      for (let i = 0; i < dataDefault.versionIds.length; i++) {
        if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
          const fName = dataDefault.versionIds[i].field_ID;
          if(fName === "AUTO_POST"){
            setauto_POST(dataDefault.versionIds[i].default_VALUE)
          }
        }
        if(dataDefault.versionIds[i].field_TYPE === "LIST"){
          count++;
          const def = {
            "column" : dataDefault.versionIds[i].field_ID ,
            "value" : dataDefault.versionIds[i].default_VALUE,
            "operation" : "EQUAL"
          }
          listDefault.push(def);
        }
      }
      handleSearch(listDefault,count);
    } else {
      handleSearch(listDefault,count);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
useEffect(() => {
  handleDefault();
}, [currentPage, pageSize, filters]);

const handleSearch = async (defaultLists,nos) => {
  const searchRequestDto = buildSearchRequestDto();
  for(let i = 0; i < nos ; i++){
    searchRequestDto.push(defaultLists[i]);
  }
  const requestBody = {
    searchRequestDto,
  };
  const paginationUrl = `${domain}/api/InvoiceGeneration/List/${currentPage}/${pageSize}`;
  try {
    const response = await axios.post(paginationUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }

    const data = response.data;

    if (data && data.content && Array.isArray(data.content)) {
      setTableData(data.content);
      setTotalPages(data.totalPages);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value,index=null) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const fieldName=(FormData && FormData.sd_UK_NO)?`${name}`:`${name}_${index}`;
    if(index!=null)
    SalesInvoiceTable[index][fieldName]=value;
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);
  // const handleRowSelect = (rowId) => {
  //   const isRowSelected = selectedRows.includes(rowId);
  //   if (!isRowSelected) {
  //     setSelectedRows([rowId]);
  //   } else {
  //     setSelectedRows([]);
  //   }
  //   setIsRecordSelected(!isRowSelected);
  // };
  const handleRowSelect = (rowId) => {

    const table = document.getElementById("SalesInvoiceList");
    const totalRowCount = table.tBodies[0].rows.length;
    var  selected = [];
    for (let i = 0; i < totalRowCount; i++) {
      if(table.tBodies[0].rows[i].cells[0].children[0].checked){
        var v = Number (table.tBodies[0].rows[i].cells[0].children[0].value);
        selected.push(v);
      }
    }
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setSelectedRows(selected);
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);

    const table = document.getElementById("SalesInvoiceList");
    const totalRowCount = table.tBodies[0].rows.length;
    if(selectedRows){
    const salesDetail = [];
    const ukNos = selectedRows.toString().split(",");
    const size = ukNos.length;

    for (let i = 0; i < size; i++) {  
      const Details = {
        sd_UK_NO: ukNos[i],
      }
      salesDetail.push(Details);
    };
    const RequestInv = {
      "salesDetail":salesDetail
    }
    try {
      const response = await axios.post(
        `${domain}/api/InvoiceGeneration/Select`, RequestInv,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {

        const salesinvoiceData = response.data.Result;
        setSalesInvoiceTable(salesinvoiceData.salesDetail);
        setsd_SH_NO(salesinvoiceData.salesDetail[0].sd_SH_NO);
        setSelectedCustomer(salesinvoiceData.salesHeader.cus_NAME);
        setselectedShipto(salesinvoiceData.salesHeader.shipto_NAME);
        setFormData(salesinvoiceData.salesHeader);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  };

const leftToolbarTemplate = (
  <React.Fragment>
  <Tooltip target=".update-button" position="bottom" mouseTrack>
    <div
      style={{
        background: "red !important",
        color: "white",
        fontSize: "10px",
      }}
    >
      Update
    </div>
  </Tooltip>
  <Button
    style={buttonStyles}
    icon="pi pi-check"
    outlined
    className="mr-2 update-button"
    onClick={handleUpdate}
    disabled={!isRecordSelected}
  ></Button>
</React.Fragment>
);
const exportExcel = () => {
  import("xlsx").then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(tableData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "products_export.xlsx");
  });
};
const rightToolbarTemplate = (
  <React.Fragment>
    <Button style={{height: "35px",backgroundColor:"#1ed01e",border:"none"}}
      icon="pi pi-upload"
      className="p-button-help"
      rounded
      onClick={exportExcel}
      data-pr-tooltip="XLS"
    />
  </React.Fragment>
);
const handleDataSelectCustomer = (data) => {
  setselectedCustomerFIL(data.ab_AD_NM);
  setCustomer( data.abAdNo);
  setCustomerPopupVisible(false);
  setShowSearchIcon(false);
  updateFilters (data.abAdNo);
};
const openLeadCustomerPopup = () => {
  setCustomerPopupVisible(true);
};  
const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
const [selectedCustomerFIL, setselectedCustomerFIL] = useState("");
const AdvanceSearch = (
<div className="formgrid grid">
<div className="field col">
  <label htmlFor="Customer" className="font-bold">
  Customer <span style={{ color: 'red' }}>*</span>
  </label>
  <div style={{ display: "flex" }}>
    <InputText
      style={{display:"none"}}
      id="Customer"
      name="Customer"
      value={Customer}
      onChange={(Event) => onchangedata(Event)}
    />
    <InputText 
      id="ab_AD_NM"
      name="ab_AD_NM"
      onChange={(Event) => onchangedata(Event)}
      onFocus={() => setShowSearchIcon(true)}
      value={selectedCustomerFIL}
    />
    {showSearchIcon && (
    <i
      className="pi pi-search"
      style={{
        color: "var(--primary-color)",
        fontSize: "119%",
        marginTop: "4%",
        cursor: "pointer",
      }}
      onClick={openLeadCustomerPopup}
    ></i>
    )}
  </div>
</div>

</div>
);
const handleClearButton=()=>{
  setCustomer("");
  setselectedCustomerFIL("");
  setFilters("");
};
const rightClear = (
  <React.Fragment>
    <Button
      style={{ height: "35px" }}
      icon="pi pi-times"
      className="p-button-help"
      link
      onClick={handleClearButton}
    />
  </React.Fragment>
);
return (
  <div className="tableData" style={{ marginTop: "6%" }}>
    <Toast ref={toast} />
    <div className="card">
      <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
      <div style={{ marginTop: "2%"}}>
      <Toolbar
        className="p-mb-4"
        left={AdvanceSearch}
        right={rightClear}
      />
      </div>
      <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        <table id="SalesInvoiceList"
          style={{
            borderCollapse: "collapse",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <thead>
            <tr>
              <td style={{width:'40px'}}>
                <input
                  type="text"
                  style={checkboxStyle}
                  readOnly
                  onMouseDown={(e) => e.preventDefault()}
                />
              </td><td>
                <input
                  type="text"
                  value={filters["sdOrNo"] ? filters["sdOrNo"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sdOrNo",
                      e.target.value,
                      filters["sdOrNo"]
                        ? filters["sdOrNo"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sdOrTy"] ? filters["sdOrTy"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sdOrTy",
                      e.target.value,
                      filters["sdOrTy"]
                        ? filters["sdOrTy"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_OR_CO"] ? filters["sd_OR_CO"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_OR_CO",
                      e.target.value,
                      filters["sd_OR_CO"]
                        ? filters["sd_OR_CO"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_CU_NO"] ? filters["sd_CU_NO"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_CU_NO",
                      e.target.value,
                      filters["sd_CU_NO"]
                        ? filters["sd_CU_NO"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input disabled
                  type="text"
                  value={filters["cus_NAME"] ? filters["cus_NAME"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "cus_NAME",
                      e.target.value,
                      filters["cus_NAME"]
                        ? filters["cus_NAME"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle1}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_SH_TO"] ? filters["sd_SH_TO"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_SH_TO",
                      e.target.value,
                      filters["sd_SH_TO"]
                        ? filters["sd_SH_TO"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_TR_CR"] ? filters["sd_TR_CR"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_TR_CR",
                      e.target.value,
                      filters["sd_TR_CR"]
                        ? filters["sd_TR_CR"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input 
                  type="text"
                  value={filters["sd_IT_NM"] ? filters["sd_IT_NM"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_IT_NM",
                      e.target.value,
                      filters["sd_IT_NM"]
                        ? filters["sd_IT_NM"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input disabled
                  type="text"
                  value={filters["item_DESC"] ? filters["item_DESC"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "item_DESC",
                      e.target.value,
                      filters["item_DESC"]
                        ? filters["item_DESC"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle1}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_SH_QT"] ? filters["sd_SH_QT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_SH_QT",
                      e.target.value,
                      filters["sd_SH_QT"]
                        ? filters["sd_SH_QT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_TR_UM"] ? filters["sd_TR_UM"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_TR_UM",
                      e.target.value,
                      filters["sd_TR_UM"]
                        ? filters["sd_TR_UM"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_UT_PR"] ? filters["sd_UT_PR"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_UT_PR",
                      e.target.value,
                      filters["sd_UT_PR"]
                        ? filters["sd_UT_PR"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_EX_PR"] ? filters["sd_EX_PR"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_EX_PR",
                      e.target.value,
                      filters["sd_EX_PR"]
                        ? filters["sd_EX_PR"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_DS_AT"] ? filters["sd_DS_AT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_DS_AT",
                      e.target.value,
                      filters["sd_DS_AT"]
                        ? filters["sd_DS_AT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_TX_PE"] ? filters["sd_TX_PE"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_TX_PE",
                      e.target.value,
                      filters["sd_TX_PE"]
                        ? filters["sd_TX_PE"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_TX_AM"] ? filters["sd_TX_AM"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_TX_AM",
                      e.target.value,
                      filters["sd_TX_AM"]
                        ? filters["sd_TX_AM"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_FR_PR"] ? filters["sd_FR_PR"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_FR_PR",
                      e.target.value,
                      filters["sd_FR_PR"]
                        ? filters["sd_FR_PR"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_FR_AT"] ? filters["sd_FR_AT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_FR_AT",
                      e.target.value,
                      filters["sd_FR_AT"]
                        ? filters["sd_FR_AT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_FR_DS"] ? filters["sd_FR_DS"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_FR_DS",
                      e.target.value,
                      filters["sd_FR_DS"]
                        ? filters["sd_FR_DS"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_SH_NO"] ? filters["sd_SH_NO"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_SH_NO",
                      e.target.value,
                      filters["sd_SH_NO"]
                        ? filters["sd_SH_NO"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_SH_DT"] ? filters["sd_SH_DT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_SH_DT",
                      e.target.value,
                      filters["sd_SH_DT"]
                        ? filters["sd_SH_DT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_CU_PO"] ? filters["sd_CU_PO"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_CU_PO",
                      e.target.value,
                      filters["sd_CU_PO"]
                        ? filters["sd_CU_PO"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_PO_DT"] ? filters["sd_PO_DT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_PO_DT",
                      e.target.value,
                      filters["sd_PO_DT"]
                        ? filters["sd_PO_DT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_ST_CD"] ? filters["sd_ST_CD"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_ST_CD",
                      e.target.value,
                      filters["sd_ST_CD"]
                        ? filters["sd_ST_CD"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>

            </tr>
            <tr>
              <th style={tableHeaderStyle}></th>
              <th style={tableHeaderStyle}>Order No</th>
              <th style={tableHeaderStyle}>Type</th>
              <th style={tableHeaderStyle}>Company</th>
              <th style={tableHeaderStyle}>Customer</th>
              <th style={tableHeaderStyle}>Name</th>
              <th style={tableHeaderStyle}>Ship To</th> 
              <th style={tableHeaderStyle}>Currency</th>                 
              <th style={tableHeaderStyle}>Item No</th>
              <th style={tableHeaderStyle}>Description</th>
              <th style={tableHeaderStyle}>Quantity</th>
              <th style={tableHeaderStyle}>UM</th>
              <th style={tableHeaderStyle}>Unit Price</th>
              <th style={tableHeaderStyle}>Amount</th>
              <th style={tableHeaderStyle}>Discount </th>
              <th style={tableHeaderStyle}>Tax %</th>
              <th style={tableHeaderStyle}>Tax</th>
              <th style={tableHeaderStyle}>Foreign Price</th>
              <th style={tableHeaderStyle}>Foreign Amount</th>
              <th style={tableHeaderStyle}>Foreign Discount</th>
              <th style={tableHeaderStyle}>Shipment No</th>
              <th style={tableHeaderStyle}>Ship Date </th>
              <th style={tableHeaderStyle}>Customer PO</th>
              <th style={tableHeaderStyle}>PO Date</th>
              <th style={tableHeaderStyle}>Status</th>
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData.map((rowData, index) => (
                <tr
                  key={rowData.sd_UK_NO}
                  style={{
                    backgroundColor: index % 2 === 0 ? 'aliceblue' : "#ffffff",
                  }}
                  className="table-row"
                >
                  <td style={tableCellStyle}>
                    <input
                      type="checkbox"
                      value={rowData.sd_UK_NO}
                      checked={selectedRows.includes(rowData.sd_UK_NO)}
                      onChange={() => handleRowSelect(rowData.sd_UK_NO)}
                    />
                  </td>
                  <td style={tableCellStyle}>{rowData.sdOrNo}</td>
                  <td style={tableCellStyle}>{rowData.sdOrTy}</td>
                  <td style={tableCellStyle}>{rowData.sd_OR_CO}</td>
                  <td style={tableCellStyle}>{rowData.sd_CU_NO}</td>
                  <td style={tableCellStyle}>{rowData.cus_NAME}</td>
                  <td style={tableCellStyle}>{rowData.sd_SH_TO}</td>                    
                  <td style={tableCellStyle}>{rowData.sd_TR_CR}</td>                    
                  <td style={tableCellStyle}>{rowData.sd_IT_NM}</td>                   
                  <td style={tableCellStyle}>{rowData.item_DESC}</td>
                  <td style={tableCellStyle}>{rowData.sd_SH_QT}</td>
                  <td style={tableCellStyle}>{rowData.sd_TR_UM}</td>
                  <td style={tableCellStyle}>{rowData.sd_UT_PR}</td>
                  <td style={tableCellStyle}>{rowData.sd_EX_PR}</td>
                  <td style={tableCellStyle}>{rowData.sd_DS_AT}</td>
                  <td style={tableCellStyle}>{rowData.sd_TX_PE}</td>
                  <td style={tableCellStyle}>{rowData.sd_TX_AM}</td>                    
                  <td style={tableCellStyle}>{rowData.sd_FR_PR}</td>
                  <td style={tableCellStyle}>{rowData.sd_FR_AT}</td>
                  <td style={tableCellStyle}>{rowData.sd_FR_DS}</td>
                  <td style={tableCellStyle}>{rowData.sd_SH_NO}</td>
                  <td style={tableCellStyle}>{formatDate(rowData.sd_SH_DT)}</td>
                  <td style={tableCellStyle}>{rowData.sd_CU_PO}</td>
                  <td style={tableCellStyle}>{formatDate(rowData.sd_PO_DT)}</td>
                  <td style={tableCellStyle}>{rowData.sd_ST_CD}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

    </div>
    <CustomerPopup
      visible={CustomerPopupVisible}
      onClose={() => setCustomerPopupVisible(false)}
      onDataSelect={handleDataSelectCustomer}
    />
    <SalesInvoiceDialog
      open={open}
      handleClose={handleClose}
      data={FormData}
      onchangedata={onchangedata}
      onchangeFormData={onchangeFormData}
      SalesInvoiceTable={SalesInvoiceTable}
      setSalesInvoiceTable={setSalesInvoiceTable}
      addRow={addRow}
      deleteRow={deleteRow}
      handleDefault={handleDefault}
      handleResponse={handleResponse}
      auto_POST={auto_POST}
      sd_SH_NO={sd_SH_NO}
      selectedShipto={selectedShipto}
      selectedCustomer={selectedCustomer}

    />
  </div>
);
};

export default SalesInvoiceMain;
