import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import '../../common/TabStyles.css';
import axios from "axios";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import ButtonStyles from "views/common/ButtonStyles";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
export default function SalesInvoiceDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  SalesInvoiceTable,
  setSalesInvoiceTable,
  addRow,deleteRow,
  onchangeFormData,
  handleDefault,
  handleResponse,
  selectedCustomer,
  setSelectedCustomer,
  selectedShipto,
  setselectedShipto,
  auto_POST,
  sd_SH_NO,
}) {
  const { 
    sl_TR_DT,
    sh_CU_NO,sh_SH_TO,
    sd_UK_NO,
    sd_CU_NO,
    sd_SH_DT,
    sd_PO_DT,   net_AMOUNT,
    price_AMOUNT,
    discount_AMOUNT,
    tax_AMOUNT,
    total,
    fr_PRICE_AMOUNT,
    fr_DISCOUNT_AMOUNT,
    fr_NET_AMOUNT,
    fr_TAX_AMOUNT,
    fr_TOTAL,
  } = data;
  const { footergridstyle,footerfieldstyle,multirowstyle,
    borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle } 
    = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // advance search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
  const [addressTypeCust, setaddressTypeCust] = useState("C");

  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const openShipToPopup = () => {
    setShipToPopupVisible(true);
  };
  const handleSelectCustomer = (data) => {
    setSelectedCustomer(data.ab_AD_NM);
    document.getElementById("sh_CU_NO").value = data.abAdNo;
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectSHipto = (data) => {
    setselectedShipto(data.ab_AD_NM);
    document.getElementById("sh_SH_TO").value = data.abAdNo;
    setShipToPopupVisible(false);
    setShowSearchIcon(false);
  };

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  // DATES DEFAULT
  const [sd_IN_DT, setsd_IN_DT] = useState(moment(moment().toDate()).format("YYYYMMDD"));
  const [sd_GL_DT, setsd_GL_DT] = useState(moment(moment().toDate()).format("YYYYMMDD"));
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleINDateChange = (e) => {
    setSelectedDate(e.value);
    setsd_IN_DT(e.value);
    onchangedata({ target: { name: "sd_IN_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const [selectedGLDate, setSelectedGLDate] = useState(moment().toDate());
  const handleGLDateChange = (e) => {
    setSelectedGLDate(e.value);
    setsd_GL_DT(e.value);
    onchangedata({ target: { name: "sd_GL_DT", value: moment(e.value).format("YYYYMMDD") } });
  };



  const invoicegenerate =  async () => {
    const  Urlin = `${domain}/api/InvoiceGeneration/GenerateSalesInvoices`
    const table1 = document.getElementById("SalesInvoiceTable");
        const totalRowCount = table1.tBodies[0].rows.length;

        const salesDetail = [];
        for (let i = 0; i < totalRowCount; i++) {
          const Details = {
            "sd_GL_DT": sd_GL_DT,
            "sd_IN_DT": sd_IN_DT,
            "sd_UK_NO": document.getElementById("sd_UK_NO_" + i).value
          };
          salesDetail.push(Details);
        }
    const requestinge = {
      salesDetail
    }
    axios.post(Urlin, requestinge, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      if(resp.data.errorMessage.length > 0){
        handleResponse(resp.data);
      } else {
        generate();
      }
      
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };
  const generate =  async () => {
  const  Url = `${domain}/api/InvoiceGeneration/PostInvoice`;
  const table1 = document.getElementById("SalesInvoiceTable");
        const totalRowCount = table1.tBodies[0].rows.length;
      var ukNo = "";
        for (let i = 0; i < totalRowCount; i++) {
          ukNo = document.getElementById("sd_UK_NO_" + i).value;

        }
  const request = {
    "detail":{
      "sd_GL_DT": sd_GL_DT,
      "sd_IN_DT": sd_IN_DT,
      "sd_UK_NO": ukNo,
      "inter_BR_CUS": ""
  }
  }
  axios.post(Url, request, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    handleResponse(resp.data);
    
  })
  .catch((error) => {
    console.error("Error adding data:", error);
  });
  };
  const isUpdateMode = Boolean(sd_UK_NO);
  // DIALOG HEADER
  const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{sd_UK_NO ? "Revise Sales Invoice" : "Add Sales Invoice"}</h5>
    <div style={{width:'60rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={() => {  invoicegenerate();}} 
        tooltip={isUpdateMode ? "Update" : "Submit"}
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
    <div style={borderbox}  >
      <div  style={inboxstyle}  >
        <div className="formgrid grid" >
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_CU_NO" className="font-bold">
            Customer <span style={{ color: 'red' }}>*</span>
          </label>
          <div style={{ display: "flex" }}>
            <InputText disabled
              id="selectedCustomer"
              name="selectedCustomer"
              onChange={(Event) => onchangedata(Event)}
              onFocus={() => setShowSearchIcon(true)}
              value={selectedCustomer}
            />
            {showSearchIcon && (
            <i
              className="pi pi-search"
              style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "4%",
                cursor: "pointer",
              }}
              onClick={openCustomerPopup}
            ></i>
            )}
          </div>
          <div style={{display:"none"}}>
            <InputText 
              id="sh_CU_NO"
              name="sh_CU_NO"
              value={sh_CU_NO}
              onChange={(Event) => onchangedata(Event)}
            />
          </div>
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="sh_SH_TO" className="font-bold">
            Ship To <span style={{ color: 'red' }}>*</span>
          </label>
          <div style={{ display: "flex" }}>
            <InputText disabled
              id="selectedShipto"
              name="selectedShipto"
              onChange={(Event) => onchangedata(Event)}
              onFocus={() => setShowSearchIcon(true)}
              value={selectedShipto}
            />
            {showSearchIcon && (
            <i
              className="pi pi-search"
              style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "4%",
                cursor: "pointer",
              }}
              onClick={openShipToPopup}
            ></i>
            )}
          </div>
          <div style={{display:"none"}}>
            <InputText
              id="sh_SH_TO"
              name="sh_SH_TO"
              value={sh_SH_TO}
              onChange={(Event) => onchangedata(Event)}
            />
          </div>
        </div>
        <div className="field col-lg-2 col-md-6 col-12">
            <label htmlFor="sd_IN_DT" className="font-bold">
            Invoice Date
            </label>
            <Calendar
              id="sd_IN_DT"
              name="sd_IN_DT"
              value={moment(sd_IN_DT, "YYYYMMDD").toDate()}
              onChange={handleINDateChange}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12">
            <label htmlFor="sd_GL_DT" className="font-bold">
              GL Date
            </label>
            <Calendar
              id="sd_GL_DT"
              name="sd_GL_DT"
              value={moment(sd_GL_DT, "YYYYMMDD").toDate()}
              onChange={handleGLDateChange}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sd_SH_NO" className="font-bold">
                Shipment No
            </label>
            <InputText disabled
              id="sd_SH_NO"
              name="sd_SH_NO"
              value={sd_SH_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="auto_POST" className="font-bold">
                Auto Post
            </label>
            <InputText disabled
              id="auto_POST"
              name="auto_POST"
              value={auto_POST}
              onChange={(e) => onchangedata(e)}
            />
          </div>
        </div>        
      </div>
        <div  style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
              {(
              <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
              )}
                {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
              )}
            </div>
            <div  style={multitablestyle}>
              <table className="custom-table" id="SalesInvoiceTable" style={multirowstyle} >
              <thead>
                <tr>
                  <th style={{display:"none"}}>UK NO </th>
                  <th>Company </th>
                  <th>Line No </th>
                  <th>Item No </th>
                  <th>Description </th>
                  <th>Quantity </th>
                  <th>UM </th>
                  <th>Unit Price </th>
                  <th>Amount</th>
                  <th>Discount </th>
                  <th>Net Amount </th>
                  <th>Tax % </th>
                  <th>Tax </th>
                  <th>Fore. Price</th>
                  <th>Fore. Amount</th>
                  <th>Fore. Discount</th>
                  <th>Net Amount</th>
                  <th>Shipment No.</th>
                  <th>Ship Date</th>
                  <th>Customer PO</th>
                  <th>PO Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
              {SalesInvoiceTable && SalesInvoiceTable.map((row, index) => (
                  <tr key={index}>
                    <td style={{display:"none"}}>
                    <InputText
                      style={{width:"10rem"}}
                      id={`sd_UK_NO_${index}`} name={`sd_UK_NO_${index}`} value={row.sd_UK_NO} onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                      style={{width:"7rem"}}
                      id={`sd_OR_CO_${index}`} name={`sd_OR_CO_${index}`} value={row.sd_OR_CO} onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                      style={{width:"5rem"}}
                      id={`sdLnNo_${index}`} name={`sdLnNo_${index}`} value={row.sdLnNo} onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                      style={{width:"10rem"}}
                      id={`sd_IT_NM_${index}`}  name={`sd_IT_NM_${index}`}value={row.sd_IT_NM} onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                      style={{width:"15rem"}}
                      id={`item_DESC_${index}`} name={`item_DESC_${index}`} value={row.item_DESC} onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                      style={{width:"7rem"}}
                      id={`sd_SH_QT_${index}`} name={`sd_SH_QT_${index}`} value={row.sd_SH_QT} onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                      style={{width:"5rem"}}
                      id={`sd_TR_UM_${index}`} name={`sd_TR_UM_${index}`} value={row.sd_TR_UM}onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                      style={{width:"8rem"}}
                      id={`sd_UT_PR_${index}`} name={`sd_UT_PR_${index}`} value={row.sd_UT_PR} onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                    style={{width:"8rem"}}
                      id={`sd_EX_PR_${index}`} name={`sd_EX_PR_${index}`} value={row.sd_EX_PR}  onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                    style={{width:"7rem"}}
                      id={`sd_DS_AT_${index}`} name={`sd_DS_AT_${index}`}  value={row.sd_DS_AT}  onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                      style={{width:"10rem"}}
                      id={`net_AMOUNT_${index}`} name={`net_AMOUNT_${index}`} value={row.net_AMOUNT}onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                      style={{width:"6rem"}}
                      id={`sd_TX_PE_${index}`} name={`sd_TX_PE_${index}`}  value={row.sd_TX_PE} onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                    style={{width:"7rem"}}
                      id={`sd_TX_AM_${index}`} name={`sd_TX_AM_${index}`} value={row.sd_TX_AM}  onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                    style={{width:"8rem"}}
                      id={`sd_FR_PR_${index}`} name={`sd_FR_PR_${index}`} value={row.sd_FR_PR} onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                    style={{width:"10rem"}}
                      id={`sd_FR_AT_${index}`} name={`sd_FR_AT_${index}`}  value={row.sd_FR_AT} onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                    style={{width:"10rem"}}
                      id={`sd_FR_DS_${index}`}  name={`sd_FR_DS_${index}`} value={row.sd_FR_DS}  onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                    style={{width:"8rem"}}
                      id={`fr_NET_AMOUNT_${index}`} name={`fr_NET_AMOUNT_${index}`}  value={row.fr_NET_AMOUNT} onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText disabled
                    style={{width:"8rem"}}
                      id={`sd_SH_NO_${index}`} name={`sd_SH_NO_${index}`}  value={row.sd_SH_NO}  onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <Calendar disabled
                    style={{width:"10rem"}}
                        id={`sd_SH_DT_${index}`}
                        name={`sd_SH_DT_${index}`}
                        value={ row.sd_SH_DT ? moment(row.sd_SH_DT, "YYYYMMDD").toDate() : null }
                        onChange={(e) => onDateChange("sd_SH_DT", index, e.value)}
                        dateFormat="dd/mm/yy"
                      />
                    </td>
                    <td>
                    <InputText disabled
                    style={{width:"8rem"}}
                      id={`sd_CU_PO_${index}`}
                      name={`sd_CU_PO_${index}`}
                      value={row.sd_CU_PO}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>          
                    <td>
                    <Calendar disabled
                        style={{width:"10rem"}}
                        id={`sd_PO_DT_${index}`}
                        name={`sd_PO_DT_${index}`}
                        value={ row.sd_PO_DT ? moment(row.sd_PO_DT, "YYYYMMDD").toDate() : null }
                        onChange={(e) => onDateChange("sd_PO_DT", index, e.value)}
                        dateFormat="dd/mm/yy"
                      />
                    </td>          
                    <td>
                    <InputText disabled
                      style={{width:"6rem"}}
                      id={`sd_ST_CD_${index}`}
                      name={`sd_ST_CD_${index}`}
                      value={row.sd_ST_CD}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                  </tr>
                  ))}
              </tbody>
              </table>       
            </div>
          </div>
          {/* footer */}
          <div style={inboxstyle} >
          <div className="formgrid grid" style={footergridstyle} >
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="price_AMOUNT" className="font-bold">
                Amount
              </label>
              <InputText disabled
                id="price_AMOUNT"
                name="price_AMOUNT"
                value={price_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="discount_AMOUNT" className="font-bold">
                Discount
              </label>
              <InputText disabled
                id="discount_AMOUNT"
                name="discount_AMOUNT"
                value={discount_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="net_AMOUNT" className="font-bold">
                Net Amount
              </label>
              <InputText disabled
                id="net_AMOUNT"
                name="net_AMOUNT"
                value={net_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="tax_AMOUNT" className="font-bold">
                Tax
              </label>
              <InputText disabled
                id="tax_AMOUNT"
                name="tax_AMOUNT"
                value={tax_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="TOTAL" className="font-bold">
                Total
              </label>
              <InputText disabled
                id="total"
                name="total"
                value={total}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            </div>
            <div className="formgrid grid" style={footergridstyle} >
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_PRICE_AMOUNT" className="font-bold">
                Fore.Amount
              </label>
              <InputText disabled
                id="fr_PRICE_AMOUNT"
                name="fr_PRICE_AMOUNT"
                value={fr_PRICE_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="FR_DISCOUNT_AMOUNT" className="font-bold">
                Fore.Discount
              </label>
              <InputText disabled
                id="fr_DISCOUNT_AMOUNT"
                name="fr_DISCOUNT_AMOUNT"
                value={fr_DISCOUNT_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="FR_NET_AMOUNT" className="font-bold">
                Net Amount
              </label>
              <InputText disabled
                id="fr_NET_AMOUNT"
                name="fr_NET_AMOUNT"
                value={fr_NET_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="FR_TAX_AMOUNT" className="font-bold">
                Fore.Tax
              </label>
              <InputText disabled
                id="fr_TAX_AMOUNT"
                name="fr_TAX_AMOUNT"
                value={fr_TAX_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_TOTAL" className="font-bold">
                Fore.Total
              </label>
              <InputText disabled
                id="fr_TOTAL"
                name="fr_TOTAL"
                value={fr_TOTAL}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
        </div>
  </div>
        {/* CUSTOMER */}
        <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleSelectCustomer}
        formData={data}
        addressType={addressTypeCust}
        setFormData={setFormData}
      />
         {/* ship to */}
      <AddressBookPopup
        visible={ShipToPopupVisible}
        onClose={() => setShipToPopupVisible(false)}
        onDataSelect={handleSelectSHipto}
        formData={data}
        addressType={addressTypeCust}
        setFormData={setFormData}
      />
</Dialog>
  );
}

