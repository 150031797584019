import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"; 
import AuthLayout from "views/auth/signIn";
import AdminLayout from "layouts/admin";
import CustomerPortal from "views/admin/CustomerPortal";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
const type = localStorage.getItem("userType");
const hasToken = () => {
  const token = localStorage.getItem("token");
  if (token === null) {
    
    return true;
    
  } else {
    return false;
  }
};

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/auth" component={AuthLayout} />
            <Route
              path="/admin"
              render={() =>
                hasToken() ? <Redirect to="/auth" />  :  type === 'E' ? <AdminLayout /> :  <CustomerPortal /> 
              }
            />
            <Redirect from="/" to="/auth" />
          </Switch>
        </BrowserRouter>
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
