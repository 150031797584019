import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import TenantWorkOrderDialog from "./TenantWorkOrderDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  twUkNo:"",
  tenant_NAME: "",
  tw_OR_NO:"",
  tw_LE_TY:"",
  tw_LE_CO:"",
  tw_TE_NT:"",
  tw_LE_NO:"",
  tw_TR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  tw_LE_CO:"",
  tw_BL_NO:"",
  tw_UN_TS:"",
  tw_PR_TY:"",
  tw_FL_NO:"",
  tw_CO_NO:"",
  tw_PR_NO:"",
  tw_OR_TY:"",
  tw_OR_CO:"",
  ab_AD_NM: "",
  ec_EM_NO: "",
  name: "",
  ec_CC_NM: "",
  em_DE_PT: "",
  ec_PY_CL: "",
  ec_TO_SL: "",
  ec_LT_RE: "",
  ec_NX_RE: "",
  ec_WP_EF: "",
};

const initialFilters = {
  tw_OR_NO: { value: "", operation: "" },
  tw_LE_TY: { value: "", operation: "" },
  tw_LE_CO: { value: "", operation: "" },
  tw_TE_NT: { value: "", operation: "" },
  tenant_NAME: { value: "", operation: "" },
  tw_LE_NO: { value: "", operation: "" },
  tw_BL_NO: { value: "", operation: "" },
  tw_UN_TS: { value: "", operation: "" },
  tw_PR_TY: { value: "", operation: "" },
};

const TenantWorkOrderMain = () => {
  // buttonstyles
const { buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [selectedLease, setSelectedLease] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [Selectedsupervisor, setSelectedsupervisor] = useState("");
  const [tw_OR_NO, settw_OR_NO] = useState("");
  const [tw_ST_AT, settw_ST_AT] = useState("");
  const [tw_SR_TY, settw_SR_TY] = useState("");
  const [tw_CO_CT, settw_CO_CT] = useState("");
  const [tw_PR_NO, settw_PR_NO] = useState("");
  const [tw_BL_NO, settw_BL_NO] = useState("");
  const [tw_FL_NO, settw_FL_NO] = useState("");
  const [tw_PR_TY, settw_PR_TY] = useState("");
  const [tw_TX_AR, settw_TX_AR] = useState("");
  const [tw_TX_RT, settw_TX_RT] = useState("");
  const [tw_UN_TS, settw_UN_TS] = useState("");
  const [tw_OR_TY, settw_OR_TY] = useState("");
  const [tw_LE_NO, settw_LE_NO] = useState("");
  const [tw_LE_TY, settw_LE_TY] = useState("");
  const [tw_LE_CO, settw_LE_CO] = useState("");
  const [tw_TE_NT, settw_TE_NT] = useState("");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;

        if (column === 'tw_OR_NO') {
          constructedColumn = 'tw_OR_NO';
        } else {
          constructedColumn = column.toUpperCase();
        }

        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    settw_ST_AT(null);
    settw_SR_TY(null);
    settw_PR_NO(null);
    settw_BL_NO(null);
    settw_FL_NO(null);
    settw_PR_TY(null);
    settw_OR_NO(null);
    settw_TX_AR(null);
    settw_TX_RT(null);
    settw_UN_TS(null);
    settw_TE_NT(null);
    settw_LE_NO(null);
    setSelectedsupervisor(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    settw_ST_AT(null);
    settw_SR_TY(null);
    settw_PR_NO(null);
    settw_BL_NO(null);
    settw_FL_NO(null);
    settw_PR_TY(null);
    settw_OR_NO(null);
    settw_TX_AR(null);
    settw_TX_RT(null);
    settw_UN_TS(null);
    settw_TE_NT(null);
    settw_LE_NO(null);
    setSelectedsupervisor(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/TenantWorkOrder/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const TenantWorkOrderData = response.data;
        delete TenantWorkOrderData.twUkNo;
        delete TenantWorkOrderData.tw_OR_NO;
        setFormData(TenantWorkOrderData);
       
        settw_ST_AT(response.data.tw_ST_AT);
        settw_SR_TY(response.data.tw_SR_TY);
        settw_CO_CT(response.data.tw_CO_CT);
        settw_OR_TY(response.data.tw_OR_TY);
        settw_PR_NO(response.data.tw_PR_NO);
        settw_BL_NO(response.data.tw_BL_NO);
        settw_FL_NO(response.data.tw_FL_NO);
        settw_PR_TY(response.data.tw_PR_TY);
        settw_OR_NO(response.data.tw_OR_NO);
        settw_TX_AR(response.data.tw_TX_AR);
        settw_TX_RT(response.data.tw_TX_RT);
        settw_UN_TS(response.data.tw_UN_TS);
        settw_TE_NT(response.data.tw_TE_NT);
        settw_LE_NO(response.data.tw_LE_NO);
        settw_LE_TY(response.data.tw_LE_TY);
        settw_LE_CO(response.data.tw_LE_CO);
        setSelectedsupervisor(response.data.sup_NAME);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
const [tw_OR_CO, settw_OR_CO] =useState("");

  const handleDefault = async () => {
    settw_OR_CO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
         
            if(fName === "TW_OR_TY"){
              // alert(dataDefault.versionIds[i].default_VALUE);
               settw_OR_TY(dataDefault.versionIds[i].default_VALUE);
              //onchangeDropDownFormData1("tw_OR_TY",dataDefault.versionIds[i].default_VALUE);
            }
            if(fName === "TW_CO_CT"){
              onchangeDropDownFormData2("tw_CO_CT",dataDefault.versionIds[i].default_VALUE);
              settw_CO_CT(dataDefault.versionIds[i].default_VALUE);
            }
            if(fName === "TW_LE_TY"){
               settw_LE_TY(dataDefault.versionIds[i].default_VALUE);
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/TenantWorkOrder/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    if(name=="tw_LE_NO")
    settw_LE_NO(value);
    if(name=="tw_LE_CO")
    settw_LE_CO(value);
    if(name=="tw_LE_TY")
    settw_LE_TY(value);
    if(name=="tw_TE_NT")
    settw_TE_NT(value);
    if(name=="tw_UN_TS")
    settw_UN_TS(value);
  };
 
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    settw_OR_TY(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    settw_CO_CT(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    settw_PR_NO(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    settw_BL_NO(value);
  };
  const onchangeDropDownFormData5= (name, value) => {
    setFormData({ ...FormData, [name]: value });
    settw_SR_TY(value);
  };
  const onchangeDropDownFormData6= (name, value) => {
    setFormData({ ...FormData, [name]: value });
    settw_ST_AT(value);
  };
  const onchangeDropDownFormData7= (name, value) => {
    setFormData({ ...FormData, [name]: value });
    settw_FL_NO(value);
  };
  const onchangeDropDownFormData8= (name, value) => {
    setFormData({ ...FormData, [name]: value });
    settw_PR_TY(value);
  };
  const onchangeDropDownFormData9= (name, value) => {
    setFormData({ ...FormData, [name]: value });
    settw_TX_AR(value);
  };
  const onchangeDropDownFormData10= (name, value) => {
    setFormData({ ...FormData, [name]: value });
    settw_TX_RT(value);
  };
  const onchangeDropDownFormData11= (name, value) => {
    setFormData({ ...FormData, [name]: value });
    settw_UN_TS(value);
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/TenantWorkOrder/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const TenantWorkOrderData = response.data;
        setFormData(TenantWorkOrderData);
       
        settw_ST_AT(response.data.tw_ST_AT);
        settw_SR_TY(response.data.tw_SR_TY);
        settw_CO_CT(response.data.tw_CO_CT);
        settw_OR_TY(response.data.tw_OR_TY);
        settw_PR_NO(response.data.tw_PR_NO);
        settw_BL_NO(response.data.tw_BL_NO);
        settw_FL_NO(response.data.tw_FL_NO);
        settw_PR_TY(response.data.tw_PR_TY);
        settw_OR_NO(response.data.tw_OR_NO);
        settw_TX_AR(response.data.tw_TX_AR);
        settw_TX_RT(response.data.tw_TX_RT);
        settw_UN_TS(response.data.tw_UN_TS);
        settw_TE_NT(response.data.tw_TE_NT);
        settw_LE_NO(response.data.tw_LE_NO);
        settw_LE_TY(response.data.tw_LE_TY);
        settw_LE_CO(response.data.tw_LE_CO);
        setSelectedsupervisor(response.data.sup_NAME);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  var requestBodyAPIInput = {};
  function values() {
    requestBodyAPIInput = {
      twUkNo: FormData.twUkNo,
      tw_OR_NO: document.getElementById("tw_OR_NO").value,
      tw_OR_TY: tw_OR_TY,
      tw_OR_CO: document.getElementById("tw_OR_CO").value,
      tw_LE_NO: document.getElementById("tw_LE_NO").value,
      tw_LE_TY: document.getElementById("tw_LE_TY").value,
      tw_LE_CO: document.getElementById("tw_LE_CO").value,
      tw_TE_NT: document.getElementById("tw_TE_NT").value,
      tw_CO_CT: tw_CO_CT,
      tw_SU_VR: document.getElementById("tw_SU_VR").value,
      tw_TR_DT: FormData.tw_TR_DT,
      tw_ST_DT: FormData.tw_ST_DT,
      tw_CO_DT: FormData.tw_CO_DT,
      tw_PR_NO: tw_PR_NO,
      tw_BL_NO: tw_BL_NO,
      tw_FL_NO: tw_FL_NO,
      tw_UN_TS: document.getElementById("tw_UN_TS").value,
      tw_SR_TY: tw_SR_TY,
      tw_PR_TY: tw_PR_TY,
      tw_ES_HR: document.getElementById("tw_ES_HR").value,
      tw_ES_AM: document.getElementById("tw_ES_AM").value,
      tw_TX_AR: tw_TX_AR,
      tw_TX_RT: tw_TX_RT,
      tw_ST_AT: tw_ST_AT
    };
  }


  const handleFormSubmit = () => {
    values ();
    if (document.getElementById("tw_OR_NO").value) {
    if (FormData.twUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/TenantWorkOrder/Update`;
      if (confirm) {
        axios
          .put(update, requestBodyAPIInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios
        .post(`${domain}/api/TenantWorkOrder/Add`, requestBodyAPIInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
      } else {

        const tr_dt = FormData.tw_TR_DT;
        if(tr_dt){
          const currentDate = tr_dt.toString();
          const firstHalf = currentDate.substring(0, 2);
          const secondHalf = currentDate.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${tw_OR_TY}/${secondHalf}/${company}/${firstHalf}`; 
          const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios
          .get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
              settw_OR_NO(resp.dn_DO_NO);
              const OR_NO = document.getElementById("tw_OR_NO");
              OR_NO.value = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values ();
                axios
                  .post(`${domain}/api/TenantWorkOrder/Add`, requestBodyAPIInput, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "http://localhost:3000",
                      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                    },
                  })
                  .then((resp) => {
                    handleResponse(resp.data);
                  })
                  .catch((error) => {
                    console.error("Error saving data:", error);
                  });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
        
        
        
      }
    };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
     
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tw_OR_NO"] ? filters["tw_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tw_OR_NO",
                        e.target.value,
                        filters["tw_OR_NO"]
                          ? filters["tw_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tw_LE_TY"] ? filters["tw_LE_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tw_LE_TY",
                        e.target.value,
                        filters["tw_LE_TY"]
                          ? filters["tw_LE_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tw_LE_CO"] ? filters["tw_LE_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tw_LE_CO",
                        e.target.value,
                        filters["tw_LE_CO"]
                          ? filters["tw_LE_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tw_TE_NT"] ? filters["tw_TE_NT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tw_TE_NT",
                        e.target.value,
                        filters["tw_TE_NT"]
                          ? filters["tw_TE_NT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["tenant_NAME"] ? filters["tenant_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tenant_NAME",
                        e.target.value,
                        filters["tenant_NAME"]
                          ? filters["tenant_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tw_LE_NO"] ? filters["tw_LE_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tw_LE_NO",
                        e.target.value,
                        filters["tw_LE_NO"]
                          ? filters["tw_LE_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tw_BL_NO"] ? filters["tw_BL_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tw_BL_NO",
                        e.target.value,
                        filters["tw_BL_NO"]
                          ? filters["tw_BL_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tw_UN_TS"] ? filters["tw_UN_TS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tw_UN_TS",
                        e.target.value,
                        filters["tw_UN_TS"]
                          ? filters["tw_UN_TS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tw_PR_TY"] ? filters["tw_PR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tw_PR_TY",
                        e.target.value,
                        filters["tw_PR_TY"]
                          ? filters["tw_PR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
              <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Work Order</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Tenant</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Lease Number</th>
                <th style={tableHeaderStyle}>Building No</th>
                <th style={tableHeaderStyle}>Unit</th>
                <th style={tableHeaderStyle}>Priority</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.ecUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.twUkNo}
                        checked={selectedRows.includes(rowData.twUkNo)}
                        onChange={() => handleRowSelect(rowData.twUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.tw_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.tw_LE_TY}</td>
                    <td style={tableCellStyle}>{rowData.tw_LE_CO}</td>
                    <td style={tableCellStyle}>{rowData.tw_TE_NT}</td>                    
                    <td style={tableCellStyle}>{rowData.tenant_NAME}</td>
                    <td style={tableCellStyle}>{rowData.tw_LE_NO}</td> 
                    <td style={tableCellStyle}>{rowData.tw_BL_NO}</td>
                    <td style={tableCellStyle}>{rowData.tw_UN_TS}</td>
                    <td style={tableCellStyle}>{rowData.priority_DESc}</td>                
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

       {/* pagination */}
 <Pagination
    currentPage={currentPage}
    totalPages={totalPages}
    setCurrentPage={setCurrentPage}
  />
      </div>

      <TenantWorkOrderDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        onchangeDropDownFormData8={onchangeDropDownFormData8}
        onchangeDropDownFormData9={onchangeDropDownFormData9}
        onchangeDropDownFormData10={onchangeDropDownFormData10}
        onchangeDropDownFormData11={onchangeDropDownFormData11}
        tw_CO_CT={tw_CO_CT}
        tw_ST_AT={tw_ST_AT}
        tw_SR_TY={tw_SR_TY}
        tw_PR_NO={tw_PR_NO}
        tw_BL_NO={tw_BL_NO}
        tw_FL_NO={tw_FL_NO}
        tw_PR_TY={tw_PR_TY}  
        tw_OR_NO={tw_OR_NO}  
        tw_TX_AR={tw_TX_AR}
        tw_TX_RT={tw_TX_RT}
        tw_UN_TS={tw_UN_TS}
        tw_OR_TY={tw_OR_TY}
        tw_OR_CO={tw_OR_CO}
        tw_LE_NO={tw_LE_NO}
        tw_LE_TY={tw_LE_TY}
        tw_LE_CO={tw_LE_CO}
        tw_TE_NT={tw_TE_NT}
        selectedLease={selectedLease}
        setSelectedLease={setSelectedLease}
        setSelectedemEMNO={ setSelectedemEMNO}
        Selectedsupervisor={Selectedsupervisor}
        setSelectedsupervisor={setSelectedsupervisor}     
      />
    </div>
  );
};

export default TenantWorkOrderMain;
