import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import Pagination from "views/common/Pagination";
import ButtonStyles from "views/common/ButtonStyles";

const initialFilters = {
  rc_TR_NO: { value: "", operation: "" },
  rc_TR_TY: { value: "", operation: "" },
  rc_TR_CO: { value: "", operation: "" },
  rc_CU_NO: { value: "", operation: "" },
  cus_NAME: { value: "", operation: "" },
  rc_PY_TR: { value: "", operation: "" },
  rc_TX_AP: { value: "", operation: "" },
  rc_TR_CR: { value: "", operation: "" },
  rc_EX_RT: { value: "", operation: "" },
  rc_BS_CR: { value: "", operation: "" },
  rc_ST_DT: { value: "", operation: "" },
  rc_EN_DT: { value: "", operation: "" },
  rc_HD_ST: { value: "", operation: "" },
  rc_RF_NC: { value: "", operation: "" },
};

const RentalConrtactPopup = ({ visible, onClose, onDataSelect, ItemlmId}) => {
  const {formatDate, 
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [open, setOpen] = useState(false);
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;

        if (column === 'rc_TR_NO') {
          constructedColumn = 'rc_TR_NO';
        } else {
          constructedColumn = column.toUpperCase();
        }

        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(visible);
    handleSearch();
    setCurrentPage(0);
  }, [currentPage, pageSize, filters, visible]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/RentalContract/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
  };

  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{width:"85rem"}}
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_NO"] ? filters["rc_TR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_NO",
                        e.target.value,
                        filters["rc_TR_NO"]
                          ? filters["rc_TR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_TY"] ? filters["rc_TR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_TY",
                        e.target.value,
                        filters["rc_TR_TY"]
                          ? filters["rc_TR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_CO"] ? filters["rc_TR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_CO",
                        e.target.value,
                        filters["rc_TR_CO"]
                          ? filters["rc_TR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_CU_NO"] ? filters["rc_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_CU_NO",
                        e.target.value,
                        filters["rc_CU_NO"]
                          ? filters["rc_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cus_NAME"] ? filters["cus_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cus_NAME",
                        e.target.value,
                        filters["cus_NAME"]
                          ? filters["cus_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_IT_NM"] ? filters["rc_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_IT_NM",
                        e.target.value,
                        filters["rc_IT_NM"]
                          ? filters["rc_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["itemDES"] ? filters["itemDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "itemDES",
                        e.target.value,
                        filters["itemDES"]
                          ? filters["itemDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_UM"] ? filters["rc_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_UM",
                        e.target.value,
                        filters["rc_TR_UM"]
                          ? filters["rc_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_OR_QT"] ? filters["rc_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_OR_QT",
                        e.target.value,
                        filters["rc_OR_QT"]
                          ? filters["rc_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_UN_PR"] ? filters["rc_UN_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_UN_PR",
                        e.target.value,
                        filters["rc_UN_PR"]
                          ? filters["rc_UN_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_UP_AM"] ? filters["rc_UP_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_UP_AM",
                        e.target.value,
                        filters["rc_UP_AM"]
                          ? filters["rc_UP_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_DS_PE"] ? filters["rc_DS_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_DS_PE",
                        e.target.value,
                        filters["rc_DS_PE"]
                          ? filters["rc_DS_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_DS_AM"] ? filters["rc_DS_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_DS_AM",
                        e.target.value,
                        filters["rc_DS_AM"]
                          ? filters["rc_DS_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TX_PE"] ? filters["rc_TX_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TX_PE",
                        e.target.value,
                        filters["rc_TX_PE"]
                          ? filters["rc_TX_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TX_AM"] ? filters["rc_TX_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TX_AM",
                        e.target.value,
                        filters["rc_TX_AM"]
                          ? filters["rc_TX_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_FR_AM"] ? filters["rc_FR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_FR_AM",
                        e.target.value,
                        filters["rc_FR_AM"]
                          ? filters["rc_FR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_FR_DS"] ? filters["rc_FR_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_FR_DS",
                        e.target.value,
                        filters["rc_FR_DS"]
                          ? filters["rc_FR_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_FR_TX"] ? filters["rc_FR_TX"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_FR_TX",
                        e.target.value,
                        filters["rc_FR_TX"]
                          ? filters["rc_FR_TX"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_PY_TR"] ? filters["rc_PY_TR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_PY_TR",
                        e.target.value,
                        filters["rc_PY_TR"]
                          ? filters["rc_PY_TR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TX_AP"] ? filters["rc_TX_AP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TX_AP",
                        e.target.value,
                        filters["rc_TX_AP"]
                          ? filters["rc_TX_AP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_CR"] ? filters["rc_TR_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_CR",
                        e.target.value,
                        filters["rc_TR_CR"]
                          ? filters["rc_TR_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_EX_RT"] ? filters["rc_EX_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_EX_RT",
                        e.target.value,
                        filters["rc_EX_RT"]
                          ? filters["rc_EX_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_BS_CR"] ? filters["rc_BS_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_BS_CR",
                        e.target.value,
                        filters["rc_BS_CR"]
                          ? filters["rc_BS_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_ST_DT"] ? filters["rc_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_ST_DT",
                        e.target.value,
                        filters["rc_ST_DT"]
                          ? filters["rc_ST_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_EN_DT"] ? filters["rc_EN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_EN_DT",
                        e.target.value,
                        filters["rc_EN_DT"]
                          ? filters["rc_EN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_HD_ST"] ? filters["rc_HD_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_HD_ST",
                        e.target.value,
                        filters["rc_HD_ST"]
                          ? filters["rc_HD_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_RF_NC"] ? filters["rc_RF_NC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_RF_NC",
                        e.target.value,
                        filters["rc_RF_NC"]
                          ? filters["rc_RF_NC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}>Contract No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Customer No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Item No</th> 
                <th style={tableHeaderStyle}>Description</th> 
                <th style={tableHeaderStyle}>UM</th> 
                <th style={tableHeaderStyle}>Quantity</th>
                <th style={tableHeaderStyle}>Unit Price</th>
                <th style={tableHeaderStyle}>Amount</th> 
                <th style={tableHeaderStyle}>Dicount %</th>
                <th style={tableHeaderStyle}>Discount Amount</th> 
                <th style={tableHeaderStyle}>Tax %</th>   
                <th style={tableHeaderStyle}>Tax Amount</th>  
                <th style={tableHeaderStyle}>Fore. Amount</th>  
                <th style={tableHeaderStyle}>Fore. Discount</th>
                <th style={tableHeaderStyle}>Fore. Tax</th>
                <th style={tableHeaderStyle}>Payment Term</th>
                <th style={tableHeaderStyle}>Tax Applicable</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Exchange Rate</th>
                <th style={tableHeaderStyle}>Base Currency</th>
                <th style={tableHeaderStyle}>Start Date</th>
                <th style={tableHeaderStyle}>End Date</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Reference</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                      key={rowData.imItNo}
                      onClick={() => onDataSelect(rowData,ItemlmId)}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "aliceblue" : "#ffffff",
                      }}
                      className="table-row"
                    >
                      <td style={tableCellStyle}>{rowData.rc_TR_NO}</td>
                      <td style={tableCellStyle}>{rowData.rc_TR_TY}</td>
                      <td style={tableCellStyle}>{rowData.rc_TR_CO}</td>
                      <td style={tableCellStyle}>{rowData.rc_CU_NO}</td>
                      <td style={tableCellStyle}>{rowData.cus_NAME}</td>
                      <td style={tableCellStyle}>{rowData.rc_IT_NM}</td>
                      <td style={tableCellStyle}>{rowData.itemDES}</td>
                      <td style={tableCellStyle}>{rowData.rc_TR_UM}</td>
                      <td style={tableCellStyle}>{rowData.rc_OR_QT}</td>
                      <td style={tableCellStyle}>{rowData.rc_UN_PR}</td>
                      <td style={tableCellStyle}>{rowData.rc_UP_AM}</td>
                      <td style={tableCellStyle}>{rowData.rc_DS_PE}</td>
                      <td style={tableCellStyle}>{rowData.rc_DS_AM}</td>
                      <td style={tableCellStyle}>{rowData.rc_TX_PE}</td>
                      <td style={tableCellStyle}>{rowData.rc_TX_AM}</td>
                      <td style={tableCellStyle}>{rowData.rc_FR_AM}</td>
                      <td style={tableCellStyle}>{rowData.rc_FR_DS}</td>
                      <td style={tableCellStyle}>{rowData.rc_NT_MT}</td>
                      <td style={tableCellStyle}>{rowData.rc_PY_TR}</td>
                      <td style={tableCellStyle}>{rowData.rc_TX_AP}</td>
                      <td style={tableCellStyle}>{rowData.rc_TR_CR}</td>
                      <td style={tableCellStyle}>{rowData.rc_EX_RT}</td>
                      <td style={tableCellStyle}>{rowData.rc_BS_CR}</td>
                      <td style={tableCellStyle}>{formatDate(rowData.rc_ST_DT)}</td>
                      <td style={tableCellStyle}>{formatDate(rowData.rc_EN_DT)}</td>
                      <td style={tableCellStyle}>{rowData.rc_HD_ST}</td>
                      <td style={tableCellStyle}>{rowData.rc_RF_NC}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
        />      
      </div>
    </Dialog>
  );
};
RentalConrtactPopup.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDataSelect: PropTypes.func,
};

export default RentalConrtactPopup;
