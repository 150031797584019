import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import BasePriceItemDialog from "./BasePriceItemCustomerGroupDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  bpUkNO:"",
  bp_IT_NM:"",
  item_DES:"", 
  bp_CU_PG:"",
  bp_CR_CD:"",
  bp_UN_PR:"", 
  bp_PR_UM:"",
  bp_CR_PR:"",
  bp_EX_MR:"",
  bp_AL_DS:"",
  bp_FR_DT:"",
  bp_TO_DT:"",
  bp_ST_CD:"",
  bp_TR_CO:"",
};

const initialFilters = {
  bp_IT_NM: { value: "", operation: "" },
  item_DES: { value: "", operation: "" },  
  bp_CU_PG: { value: "", operation: "" },
  bp_CR_CD: { value: "", operation: "" },
  bp_UN_PR: { value: "", operation: "" },  
  bp_PR_UM: { value: "", operation: "" },
  bp_CR_PR: { value: "", operation: "" },
  bp_EX_MR: { value: "", operation: "" },
  bp_AL_DS: { value: "", operation: "" },
  bp_FR_DT: { value: "", operation: "" },
  bp_TO_DT: { value: "", operation: "" },
  bp_ST_CD: { value: "", operation: "" },
  bp_TR_CO: { value: "", operation: "" },
};

const BasePriceItemCustomerGroupMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [bp_CU_PG, setbp_CU_PG] = useState([]);
  const [bp_CR_CD, setbp_CR_CD] = useState([]);
  const [bp_PR_UM, setbp_PR_UM] = useState([]);
  const [SelectedCustomer, setSelectedCustomer] = useState([]);
  
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [BasePricedata, setBasePricedata] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setBasePricedata([{}]);
    setbp_CU_PG(null);
    setbp_CR_CD(null);
    setbp_PR_UM(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ItemAndCustomerGroup/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const BasePriceData = response.data;
          delete BasePriceData.bpUkNO;
          for (let i = 0; i < BasePriceData.basePriceList.length; i++) {
            delete BasePriceData.basePriceList[i].bpUkNO;
          }
          setBasePricedata(BasePriceData.basePriceList);
          setFormData(BasePriceData.basePriceList[0]);
          setbp_CU_PG(BasePriceData.bp_CU_PG);
          setbp_CR_CD(BasePriceData.bp_CR_CD);
          setbp_PR_UM(BasePriceData.bp_PR_UM);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
const [bp_TR_CO, setbp_TR_CO] = useState("");
  const handleDefault = async () => {
    setbp_TR_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ItemAndCustomerGroup/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ItemAndCustomerGroup/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const BasePriceData = response.data;
        setBasePricedata(BasePriceData.basePriceList);
        setFormData(BasePriceData.basePriceList[0]);
        setbp_CR_CD(BasePriceData.bp_CR_CD);
        setbp_PR_UM(BasePriceData.bp_PR_UM);  
        setbp_CU_PG(BasePriceData.bp_CU_PG); 
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("BasePrice");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const basePriceList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const ST_DT = document.getElementsByName("bp_FR_DT_" + i)[0];
    const EN_DT = document.getElementsByName("bp_TO_DT_" + i)[0];
    const details = {
      bpUkNO : document.getElementById("bpUkNO_" + i).value,
      bp_IT_NM : document.getElementById("bp_IT_NM_" + i).value,  
      bp_CU_PG : table.tBodies[0].rows[i].cells[3].children[0].children[1].children[0].value, 
      bp_CR_CD : table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,       
      bp_UN_PR :document.getElementById("bp_UN_PR_" + i).value,
      bp_PR_UM : table.tBodies[0].rows[i].cells[6].children[0].children[1].children[0].value,
      bp_CR_PR : document.getElementById("bp_CR_PR_" + i).value,
      bp_EX_MR : document.getElementById("bp_EX_MR_" + i).value,
      bp_AL_DS : document.getElementById("bp_AL_DS_" + i).value,
      bp_FR_DT : ST_DT.value?moment(ST_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
      bp_TO_DT : EN_DT.value?moment(EN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
      bp_ST_CD : document.getElementById("bp_ST_CD_" + i).value,
      bp_TR_CO : company     
    };
    basePriceList.push(details);
  }
 requestBody = {
  basePriceList,
  };
}
  const handleFormSubmit = () => {
    values ();
    if (FormData.bpUkNO) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/ItemAndCustomerGroup/Update`;
      if (confirm) {
        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.bp_TR_CO = company;
      axios
        .post(`${domain}/api/ItemAndCustomerGroup/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bp_IT_NM"] ? filters["bp_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bp_IT_NM",
                        e.target.value,
                        filters["bp_IT_NM"]
                          ? filters["bp_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["item_DES"] ? filters["item_DES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "item_DES",
                        e.target.value,
                        filters["item_DES"] ? filters["item_DES"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                {/* <td>
                  <input
                    type="text"
                    value={filters["bp_CU_NO"] ? filters["bp_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bp_CU_NO",
                        e.target.value,
                        filters["bp_CU_NO"]
                          ? filters["bp_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> */}
                {/* <td>
                  <input
                    type="text"
                    value={filters["cus_NAME"] ? filters["cus_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cus_NAME",
                        e.target.value,
                        filters["item_DES"] ? filters["cus_NAME"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> */}
                <td>
                  <input
                    type="text"
                    value={filters["bp_CU_PG"] ? filters["bp_CU_PG"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bp_CU_PG",
                        e.target.value,
                        filters["bp_CU_PG"]
                          ? filters["bp_CU_PG"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["bp_CR_CD"] ? filters["bp_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bp_CR_CD",
                        e.target.value,
                        filters["bp_CR_CD"]
                          ? filters["bp_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["bp_UN_PR"] ? filters["bp_UN_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bp_UN_PR",
                        e.target.value,
                        filters["bp_UN_PR"]
                          ? filters["bp_UN_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["bp_PR_UM"] ? filters["bp_PR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bp_PR_UM",
                        e.target.value,
                        filters["bp_PR_UM"]
                          ? filters["bp_PR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["bp_CR_PR"] ? filters["bp_CR_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bp_CR_PR",
                        e.target.value,
                        filters["bp_CR_PR"]
                          ? filters["bp_CR_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["bp_EX_MR"] ? filters["bp_EX_MR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bp_EX_MR",
                        e.target.value,
                        filters["bp_EX_MR"]
                          ? filters["bp_EX_MR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["bp_AL_DS"] ? filters["bp_AL_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bp_AL_DS",
                        e.target.value,
                        filters["bp_AL_DS"]
                          ? filters["bp_AL_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["bp_FR_DT"] ? filters["bp_FR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bp_FR_DT",
                        e.target.value,
                        filters["bp_FR_DT"]
                          ? filters["bp_FR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["bp_TO_DT"] ? filters["bp_TO_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bp_TO_DT",
                        e.target.value,
                        filters["bp_TO_DT"]
                          ? filters["bp_TO_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["bp_ST_CD"] ? filters["bp_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bp_ST_CD",
                        e.target.value,
                        filters["bp_ST_CD"]
                          ? filters["bp_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["bp_TR_CO"] ? filters["bp_TR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bp_TR_CO",
                        e.target.value,
                        filters["bp_TR_CO"]
                          ? filters["bp_TR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                         
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Item Name</th>
                <th style={tableHeaderStyle}>Description</th>               
                <th style={tableHeaderStyle}>Customer Group</th>
                <th style={tableHeaderStyle}>Currency</th>    
                <th style={tableHeaderStyle}>Unit Price</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Unit Cost</th>  
                <th style={tableHeaderStyle}>Margin %</th>
                <th style={tableHeaderStyle}>Discount %</th>
                <th style={tableHeaderStyle}>From Date</th>
                <th style={tableHeaderStyle}>To Date</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Company</th>        
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.bpUkNO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.bpUkNO}
                        checked={selectedRows.includes(rowData.bpUkNO)}
                        onChange={() => handleRowSelect(rowData.bpUkNO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.bp_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.item_DES}</td>                   
                    <td style={tableCellStyle}>{rowData.bp_CU_PG}</td>
                    <td style={tableCellStyle}>{rowData.bp_CR_CD}</td> 
                    <td style={tableCellStyle}>{rowData.bp_UN_PR}</td>
                    <td style={tableCellStyle}>{rowData.bp_PR_UM}</td>
                    <td style={tableCellStyle}>{rowData.bp_CR_PR}</td> 
                    <td style={tableCellStyle}>{rowData.bp_EX_MR}</td>
                    <td style={tableCellStyle}>{rowData.bp_AL_DS}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.bp_FR_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.bp_TO_DT)}</td>                    
                    <td style={tableCellStyle}>{rowData.bp_ST_CD}</td>
                    <td style={tableCellStyle}>{rowData.bp_TR_CO}</td>               
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <BasePriceItemDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        BasePricedata={BasePricedata}
        setBasePricedata={setBasePricedata}
        bp_CU_PG={bp_CU_PG}
        setbp_CU_PG={setbp_CU_PG}
        bp_CR_CD={bp_CR_CD}
        setbp_CR_CD={setbp_CR_CD}
        bp_PR_UM={bp_PR_UM}
        setbp_PR_UM={setbp_PR_UM}
        bp_TR_CO={bp_TR_CO}
        SelectedCustomer={SelectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
      />
    </div>
  );
};

export default BasePriceItemCustomerGroupMain;
