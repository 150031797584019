import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import EmployeeMasterDialog from "./EmployeeMasterDialog";
import saveAs from "file-saver";
import axios from "axios";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import EmployeeMasterPopup from "views/admin/Popups/EmployeeMasterPopup"
const initialValue = {
  em_CC_NM: "",
  em_DE_PT: "",
  em_GR_DE: "",
  em_EM_DS: "",
  em_EM_ST: "",
  em_PY_FR: "",
  em_QA_FN: "",
  em_GN_DR: "",
  em_PB_PE: "",
  em_CT_BR: "",
  em_NA_TY: "",
  em_NO_NE: "",
  em_RG_TY: "",
  emEmNo: "",
  em_CO_NO: "",
  em_CO_NM:"",
  em_EF_DT:"",
};

const initialFilters = {
  emEmNo: { value: "", operation: "" },
  name: { value: "", operation: "" },
  em_EM_DS: { value: "", operation: "" },
  em_CC_NM: { value: "", operation: "" },
  em_DE_PT: { value: "", operation: "" },
  em_GR_DE: { value: "", operation: "" },
  em_GN_DR: { value: "", operation: "" },
  em_HR_DT: { value: "", operation: "" },
  em_EM_ST: { value: "", operation: "" },
};

const EmployeeMasterMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,checkboxStyle,tableHeaderStyle,
        inputStyle,tableCellStyle,
       } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [currentPage, setCurrentPage] = useState(0); // Current page
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [emEmNo, setemEmNo] = useState("");

  // DROPDOWNS
  const [em_CO_NO,  setem_CO_NO] = useState("");
  const [em_CC_NM, setem_CC_NM] = useState([]);
  const [em_EM_DS, setem_EM_DS] = useState([]);
  const [em_DE_PT, setem_DE_PT] = useState([]);
  const [em_GR_DE, setem_GR_DE] = useState([]);
  const [em_PY_FR, setem_PY_FR] = useState([]);
  const [em_GN_DR, setem_GN_DR] = useState([]);
  const [em_MR_ST, setem_MR_ST] = useState([]);
  const [em_PB_PE, setem_PB_PE] = useState([]);
  const [em_CT_BR, setem_CT_BR] = useState([]);
  const [em_NA_TY, setem_NA_TY] = useState([]);
  const [em_RG_TY, setem_RG_TY] = useState([]);
  const [em_EM_ST, setem_EM_ST] = useState([]);
  // TAB-2-DROPDOWNS
  const [em_PA_CT, setem_PA_CT] = useState([]);
  const [em_PA_ST, setem_PA_ST] = useState([]);
  const [em_PA_CY, setem_PA_CY] = useState([]);
  const [em_CA_CT, setem_CA_CT] = useState([]);
  const [em_CA_ST, setem_CA_ST] = useState([]);
  const [em_CA_CY, setem_CA_CY] = useState([]);
  // TAB-4-DROPDOWNS
  const [em_OT_EL, setem_OT_EL] = useState([]);
  const [em_CO_TY, setem_CO_TY] = useState([]);
  const [em_CO_NM, setem_CO_NM] = useState([]);

  // searches
  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [selectedAbAdNN, setSelectedAbAdNN] = useState("");
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;
  
        if (column === 'emEmNo') {
          constructedColumn = 'emEmNo';
        } else {
          constructedColumn = column.toUpperCase();
        }
        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  useEffect(() => {
    handleDefault();
    // handleSearch();
  }, [currentPage, pageSize, token]);
  const handleDefault = async () => {
    setem_CO_NO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/EmployeeMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedAbAdNN(null);
    setem_CC_NM(null);
    setem_EM_DS(null);
    setem_DE_PT(null);
    setem_GR_DE(null);
    setem_PY_FR(null);
    setem_GN_DR(null);
    setem_MR_ST(null);
    setem_PB_PE(null);
    setem_CT_BR(null);
    setem_NA_TY(null);
    setem_RG_TY(null);
    setem_EM_ST(null);
    setem_PA_CT(null);
    setem_PA_ST(null);
    setem_PA_CY(null);
    setem_CA_CT(null);
    setem_CA_ST(null);
    setem_CA_CY(null);
    setem_OT_EL(null);
    setem_CO_TY(null);
    setem_CO_NM(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedAbAdNN(null);
    setem_CC_NM(null);
    setem_EM_DS(null);
    setem_DE_PT(null);
    setem_GR_DE(null);
    setem_PY_FR(null);
    setem_GN_DR(null);
    setem_MR_ST(null);
    setem_PB_PE(null);
    setem_CT_BR(null);
    setem_NA_TY(null);
    setem_RG_TY(null);
    setem_EM_ST(null);
    setem_PA_CT(null);
    setem_PA_ST(null);
    setem_PA_CY(null);
    setem_CA_CT(null);
    setem_CA_ST(null);
    setem_CA_CY(null);
    setem_OT_EL(null);
    setem_CO_TY(null);
    setOpen(false);
  };
  // copy function
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/EmployeeMaster/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const EmployeeMasterData = response.data;
          delete EmployeeMasterData.em_UK_NO;
          setFormData(EmployeeMasterData);
          setSelectedAbAdNM(EmployeeMasterData.name);
          setSelectedAbAdNN(EmployeeMasterData.rp_NAME);
          setem_CC_NM(EmployeeMasterData.em_CC_NM);
          setem_EM_DS(EmployeeMasterData.em_EM_DS);
          setem_DE_PT(EmployeeMasterData.em_DE_PT);
          setem_GR_DE(EmployeeMasterData.em_GR_DE);
          setem_PY_FR(EmployeeMasterData.em_PY_FR);
          setem_GN_DR(EmployeeMasterData.em_GN_DR);
          setem_MR_ST(EmployeeMasterData.em_MR_ST);
          setem_PB_PE(EmployeeMasterData.em_PB_PE.toString());
          setem_CT_BR(EmployeeMasterData.em_CT_BR);
          setem_NA_TY(EmployeeMasterData.em_NA_TY);
          setem_RG_TY(EmployeeMasterData.em_RG_TY);
          setem_EM_ST(EmployeeMasterData.em_EM_ST);
          setem_CO_NO(EmployeeMasterData.em_CO_NO);
          setem_CO_NM(EmployeeMasterData.em_CO_NM);
          // TAB-2-DROPDOWNS
          setem_PA_CT(EmployeeMasterData.em_PA_CT);
          setem_PA_ST(EmployeeMasterData.em_PA_ST);
          setem_PA_CY(EmployeeMasterData.em_PA_CY);
          setem_CA_CT(EmployeeMasterData.em_CA_CT);
          setem_CA_ST(EmployeeMasterData.em_CA_ST);
          setem_CA_CY(EmployeeMasterData.em_CA_CY);
          // TAB-4-DROPDOWNS
          setem_OT_EL(EmployeeMasterData.em_OT_EL);
          setem_CO_TY(EmployeeMasterData.em_CO_TY);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      // handleSearch();
      handleDefault();
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };
  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
    if(name=="em_PB_PE") { setem_PB_PE(value) };
  };
  const onchangeUKFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setemEmNo(value);
  };
  // dropdowns--------
  // 1st
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_CC_NM(value);
  };
  // 2nd
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_EM_DS(value);
  };
  // 3rd
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_DE_PT(value);
  };
  // 4th
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_GR_DE(value);
  };
  // 5th
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_PY_FR(value);
  };
  // 6th
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_GN_DR(value);
  };
  // 7th
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_MR_ST(value);
  };
  // 8th
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_PB_PE(value);
  };
  // 9th
  const onchangeDropDownFormData9 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_CT_BR(value);
  };
  // 10th
  const onchangeDropDownFormData10 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_NA_TY(value);
  };
  // 12th
  const onchangeDropDownFormData12 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_RG_TY(value);
  };
  // 13th
  const onchangeDropDownFormData13 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_EM_ST(value);
  };
  // 14th
  const onchangeDropDownFormData14 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_PA_CT(value);
  };
  // 15th
  const onchangeDropDownFormData15 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_PA_ST(value);
  };
  // 16th
  const onchangeDropDownFormData16 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_PA_CY(value);
  };
  // 17th
  const onchangeDropDownFormData17 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_CA_CT(value);
  };
  // 18th
  const onchangeDropDownFormData18 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_CA_ST(value);
  };
  // 19th
  const onchangeDropDownFormData19 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_CA_CY(value);
  };
  // 20th
  const onchangeDropDownFormData20 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setem_OT_EL(value);
  };
    // 21th
    const onchangeDropDownFormData21 = (name, value) => {
      setFormData({ ...FormData, [name]: value });
      setem_CO_NM(value);
    };
    // 22th
    const onchangeDropDownFormData22 = (name, value) => {
      setFormData({ ...FormData, [name]: value });
      setem_CO_TY(value);
    };
  // select api
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/EmployeeMaster/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const EmployeeMasterData = response.data;
        setFormData(EmployeeMasterData);
        setSelectedAbAdNM(response.data.name);
        setSelectedAbAdNN(response.data.rp_NAME);
        setem_CO_NO(response.data.em_CO_NO);
        setem_CC_NM(response.data.em_CC_NM);
        setem_EM_DS(response.data.em_EM_DS);
        setem_DE_PT(response.data.em_DE_PT);
        setem_GR_DE(response.data.em_GR_DE);
        setem_PY_FR(response.data.em_PY_FR);
        setem_GN_DR(response.data.em_GN_DR);
        setem_MR_ST(response.data.em_MR_ST);
        setem_PB_PE(response.data.em_PB_PE.toString());
        setem_CT_BR(response.data.em_CT_BR);
        setem_NA_TY(response.data.em_NA_TY);
        setem_RG_TY(response.data.em_RG_TY);
        setem_EM_ST(response.data.em_EM_ST);
        setem_CO_NM(response.data.em_CO_NM);
        // TAB-2-DROPDOWNS
        setem_PA_CT(response.data.em_PA_CT);
        setem_PA_ST(response.data.em_PA_ST);
        setem_PA_CY(response.data.em_PA_CY);
        setem_CA_CT(response.data.em_CA_CT);
        setem_CA_ST(response.data.em_CA_ST);
        setem_CA_CY(response.data.em_CA_CY);
        // TAB-4-DROPDOWNS
        setem_OT_EL(response.data.em_OT_EL);
        setem_CO_TY(response.data.em_CO_TY);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // update & add api
  const handleFormSubmit = () => {
    if (FormData.em_UK_NO) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/EmployeeMaster/Update`;
      if (confirm) {
        axios
          .put(update, FormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.em_CO_No = company;
      axios
        .post(`${domain}/api/EmployeeMaster/Add`, FormData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack>
        <div style={{ background: "red !important", color: "white", fontSize: "10px",  }} >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack>
        <div style={{ background: "white !important", color: "white", fontSize: "10px", }} >
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack>
        <div style={{  background: "white !important",  color: "white",    fontSize: "10px",   }}>
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const tableHeaderStyleC = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#e1e1e1",
  };
  const statusStyle = {
    border: "6px solid #a7d6ff8c",
    color: "white",
    width: "100%",
  };
  const emailStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const contactStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const tableCellStyleC = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  // const handleImport = (Event) => {
  //   const file = Event.target.files[0];

  //   if (file) {
  //     const fileName = file.name;
  //     const fileExtension = fileName.split(".").pop().toLowerCase();

  //     if (fileExtension === "csv" || fileExtension === "xlsx") {
  //       const reader = new FileReader();
  //       reader.onload = (e) => {
  //         try {
  //           const importedData = JSON.parse(e.target.result);
  //           setTableData(importedData);
  //           fetch(url, {
  //             method: "POST",
  //             body: JSON.stringify(importedData),
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${token}`,
  //               "Access-Control-Allow-Origin": "http://localhost:3000",
  //               "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
  //             },
  //           })
  //             .then((resp) => resp.json())
  //             .then((resp) => {
  //               handleSearch();
  //             });

  //           toast.current.show({
  //             severity: "success",
  //             summary: "Import Successful",
  //           });
  //         } catch (error) {
  //           console.error("Error parsing imported data:", error);
  //           toast.current.show({
  //             severity: "error",
  //             summary: "Import Error",
  //             detail:
  //               "Invalid data format. Please ensure the imported file is in the correct format.",
  //           });
  //         }
  //       };

  //       reader.readAsText(file);
  //     } else {
  //       toast.current.show({
  //         severity: "error",
  //         summary: "Unsupported File Type",
  //         detail: "Please select a CSV or XLSX file for import.",
  //       });
  //     }
  //   }
  // };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none"}}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      {/* <Button
        style={{ height: "35px" }}
        type="button"
        icon="pi pi-download"
        className="p-button-rounded p-button-success"
        onClick={() => {
          const input = document.getElementById("fileInput");
          input.click();
        }}
        data-pr-tooltip="Import"
      />
      <input
        id="fileInput"
        type="file"
        accept=".csv, .xlsx"
        onChange={handleImport}
        style={{ display: "none" }}
      /> */}
    </React.Fragment>
  );
  // filter fields in the header main page

  const updateFiltersStUS = (sts) => {
    let updatedFilters = { ...filters };
  if (sts !== null) {
    updatedFilters = {
      ...updatedFilters,
      em_EM_ST: {
        value: sts,
        operation: "LIKE",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const updateFiltersDept = (Dpt) => {
    let updatedFilters = { ...filters };
  if (Dpt !== null) {
    updatedFilters = {
      ...updatedFilters,
      em_DE_PT: {
        value: Dpt,
        operation: "LIKE",
      },
    };
  }
  setFilters(updatedFilters);
  };
  
  const [Employee, setEmployee] = useState("");
  const [Department, setDepartment] = useState("");
  const [Status, setStatus] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedEmployee, setSelectedEmployee] = useState("");
  const {fetchClassCodeDS } = ClassCode();
  const [em_DE_PTOptions, setem_DE_PTOptions] = useState([]);
  const [em_EM_STOptions, setem_EM_STOptions] = useState([]);
  const [EmpPopupVisible, setEmpPopupVisible] = useState(false);
  const openEmployeePopup = () => {
    setEmpPopupVisible(true);
  };
  const handleDataSelectEmp = (data) => {
    setSelectedEmployee(data.name);
    setEmployee(data.emEmNo);
    setEmpPopupVisible(false);
    setShowSearchIcon(false);
    updateFilters (data.emEmNo);
  };
  const updateFilters = (emp) => {
    let updatedFilters = { ...filters };
  if (emp !== null) {
    updatedFilters = {
      ...updatedFilters,
      emEmNo: {
        value: emp,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const onchangeDropDownDept = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setDepartment(value);
    updateFiltersDept(value);
    console.log(value);
  };
  const onchangeDropDownStatus = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setStatus(value);
    updateFiltersStUS(value);
    console.log(value);
  };
  useEffect(() => {
    FetchDropdownDEPT();
    FetchDropdownSTUS();
  }, []);
  const FetchDropdownDEPT = async () => {
    const Options = await fetchClassCodeDS("EM/DP");
    setem_DE_PTOptions(Options);
  };
  const FetchDropdownSTUS = async () => {
    const Options = await fetchClassCodeDS("AB/ST");
    setem_EM_STOptions(Options);
  };
  const handleClearButton=()=>{
    setEmployee("");
    setSelectedEmployee("");
    setStatus(null);
    setDepartment(null);
    setFilters("");
  };
  const AdvanceSearch = (
    <div className="formgrid grid" id="headerfilters">
      <div className="field col" >
        <label htmlFor="Employee" className="font-bold">
          Employee <span style={{ color: 'red' }}>*</span>
        </label>
        <div>
        </div>
        <div style={{ display: "flex", width:"50%" }}>
          <InputText 
          style={{display:"none"}}
            id="Employee"
            name="Employee"
            value={Employee}
            onChange={(Event) => onchangedata(Event)}
          />
          <InputText 
            id="ab_AD_NM"
            name="ab_AD_NM"
            onChange={(event) => onchangedata(event)}
            onFocus={() => setShowSearchIcon(true)}
            value={SelectedEmployee}
            style={{backgroundColor:"#ffffff"}}
          />
            {showSearchIcon && (
          <i className="pi pi-search"
            style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
            }}
            onClick={openEmployeePopup}
          ></i>
            )}
        </div>
      </div>
      <div className="field col" style={{marginLeft:"3%"}}>
        <label htmlFor="status" className="font-bold">
          Status
        </label>
        <Dropdown
        style={{width:"110%"}}
          id="Status"
          name="Status"
          value={Status}
          options={em_EM_STOptions}
          onChange={(e) => onchangeDropDownStatus("Status", e.value)}
          placeholder="Select Status"
        />
      </div>
      <div className="field col" style={{marginLeft:"3%"}}>
        <label htmlFor="Department" className="font-bold">
          Department
        </label>
        <Dropdown
        style={{width:"110%"}}
          id="Department"
          name="Department"
          value={Department}
          options={em_DE_PTOptions}
          onChange={(e) => onchangeDropDownDept("Status", e.value)}
          placeholder="Select Department"
        />
      </div>
    </div>
  );
  const rightClear = (
    <React.Fragment>
      <Tooltip  target=".clear-button" position="bottom" mouseTrack>
        <div>Clear Filter</div>
      </Tooltip>
    <Button
      style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
      icon="pi pi-times"
      className="mr-2 clear-button"
      link
      onClick={handleClearButton}
    />
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4" 
          left={AdvanceSearch}
          right={rightClear}
        />
        </div>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["emEmNo"] ? filters["emEmNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "emEmNo",
                        e.target.value,
                        filters["emEmNo"]
                          ? filters["emEmNo"].operation
                          : "equals"
                      )
                    }
                    style={contactStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"] ? filters["name"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_EM_DS"] ? filters["em_EM_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_EM_DS",
                        e.target.value,
                        filters["em_EM_DS"]
                          ? filters["em_EM_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_CC_NM"] ? filters["em_CC_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_CC_NM",
                        e.target.value,
                        filters["em_CC_NM"]
                          ? filters["em_CC_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_DE_PT"] ? filters["em_DE_PT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_DE_PT",
                        e.target.value,
                        filters["em_DE_PT"]
                          ? filters["em_DE_PT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_GR_DE"] ? filters["em_GR_DE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_GR_DE",
                        e.target.value,
                        filters["em_GR_DE"]
                          ? filters["em_GR_DE"].operation
                          : "equals"
                      )
                    }
                    style={emailStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_GN_DR"] ? filters["em_GN_DR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_GN_DR",
                        e.target.value,
                        filters["em_GN_DR"]
                          ? filters["em_GN_DR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_HR_DT"] ? filters["em_HR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_HR_DT",
                        e.target.value,
                        filters["em_HR_DT"]
                          ? filters["em_HR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_EM_ST"] ? filters["em_EM_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_EM_ST",
                        e.target.value,
                        filters["em_EM_ST"]
                          ? filters["em_EM_ST"].operation
                          : "equals"
                      )
                    }
                    style={statusStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Employee</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Designation</th>
                <th style={tableHeaderStyleC}>Cost Center</th>
                <th style={tableHeaderStyle}>Department</th>
                <th style={tableHeaderStyle}>Grade</th>
                <th style={tableHeaderStyle}>Gender</th>
                <th style={tableHeaderStyle}>Hire Date</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.em_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.em_UK_NO}
                        checked={selectedRows.includes(rowData.em_UK_NO)}
                        onChange={() => handleRowSelect(rowData.em_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.emEmNo}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{rowData.designation}</td>
                    <td style={tableCellStyleC}>{rowData.em_CC_NM}</td>
                    <td style={tableCellStyle}>{rowData.department}</td>
                    <td style={tableCellStyle}>{rowData.grade}</td>
                    <td style={tableCellStyle}>{rowData.gender}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.em_HR_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.status}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <EmployeeMasterPopup
        visible={EmpPopupVisible}
        onClose={() => setEmpPopupVisible(false)}
        onDataSelect={handleDataSelectEmp}
      />
      <EmployeeMasterDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        onchangeDropDownFormData8={onchangeDropDownFormData8}
        onchangeDropDownFormData9={onchangeDropDownFormData9}
        onchangeDropDownFormData10={onchangeDropDownFormData10}
        onchangeDropDownFormData12={onchangeDropDownFormData12}
        onchangeDropDownFormData13={onchangeDropDownFormData13}
        onchangeDropDownFormData14={onchangeDropDownFormData14}
        onchangeDropDownFormData15={onchangeDropDownFormData15}
        onchangeDropDownFormData16={onchangeDropDownFormData16}
        onchangeDropDownFormData17={onchangeDropDownFormData17}
        onchangeDropDownFormData18={onchangeDropDownFormData18}
        onchangeDropDownFormData19={onchangeDropDownFormData19}
        onchangeDropDownFormData20={onchangeDropDownFormData20}
        onchangeDropDownFormData21={onchangeDropDownFormData21}
        onchangeDropDownFormData22={onchangeDropDownFormData22}
        em_CO_NO={em_CO_NO}
        em_CO_NM={em_CO_NM}
        em_CC_NM={em_CC_NM}
        em_EM_DS={em_EM_DS}
        em_DE_PT={em_DE_PT}
        em_GR_DE={em_GR_DE}
        em_PY_FR={em_PY_FR}
        em_GN_DR={em_GN_DR}
        em_MR_ST={em_MR_ST}
        em_PB_PE={em_PB_PE}
        em_CT_BR={em_CT_BR}
        em_NA_TY={em_NA_TY}
        em_CO_TY={em_CO_TY}
        em_RG_TY={em_RG_TY}
        em_EM_ST={em_EM_ST}
        em_PA_CT={em_PA_CT}
        em_PA_ST={em_PA_ST}
        em_PA_CY={em_PA_CY}
        em_CA_CT={em_CA_CT}
        em_CA_ST={em_CA_ST}
        em_CA_CY={em_CA_CY}
        em_OT_EL={em_OT_EL}
        selectedAbAdNM={selectedAbAdNM}
        selectedAbAdNN={selectedAbAdNN}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedAbAdNN={setSelectedAbAdNN}
        onchangeUKFormData={onchangeUKFormData}
      />
    </div>
  );
};

export default EmployeeMasterMain;
