import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ItemPopup from "../Popups/ItemPopup";
import axios from "axios";
import { TabView, TabPanel } from "primereact/tabview";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
export default function FixedLocationDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  FixedLocationTableData,
  setFixedLocationTableData,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  fl_LO_CN,
  setfl_LO_CN,
  fl_CO_NO,
  fl_CO_CT,
  setfl_CO_CT,
  fl_PR_UM,
  setfl_PR_UM,
}) {
  const {
    flUkNo,
    fl_IT_NM,
    os_SQ_DS,
  } = data;
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [AddresstypeC, setAddresstype] = useState("C");
  const [activeTab, setActiveTab] = useState(0);
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [fl_LO_CNOptions, setfl_LO_CNOptions] = useState([]);
  const [fl_PR_UMOptions, setfl_PR_UMOptions] = useState([]);
  const [fl_CO_CTOptions, setfl_CO_CTOptions] = useState([]);

// Cross Type
const LocationDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setfl_LO_CN(value);
  };

// CostCentreDropDown
const CostCentreDropDown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setfl_CO_CT(value);
};

//UMDropDown
const UMDropDown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setfl_PR_UM(value);
};

  useEffect(() => {
    Location();
    CostCentre();
    UM();
  }, []);

  const Location = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setfl_LO_CNOptions(Options);
  }
  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    setfl_CO_CTOptions(Options);
  };

  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setfl_PR_UMOptions(Options);
  };

  const addRow = () => {
    setFixedLocationTableData([...FixedLocationTableData, {}]);
  };
  
  const deleteRow = () => {
    if (FixedLocationTableData.length > 0) {
      const updatedData = [...FixedLocationTableData];
      updatedData.pop();
      setFixedLocationTableData(updatedData);
    }
  };
const isUpdateMode = Boolean(flUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleItem =async (Result) => {
    setselectedItem(Result.im_IT_NM)
    setselectedDesc(Result.im_IT_DS)
    onchangeFormData("fl_IT_NM", Result.im_IT_NM);
    onchangeFormData("itemDES", Result.im_IT_DS);
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
      "im_IT_NM":Result.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
        UMDropDown("fl_PR_UM", dataItMt.im_PR_UM);
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

  
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={flUkNo ? "Revise Fixed Location" : "Add Fixed Location"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Fixed Picking">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="fl_CO_CT" className="font-bold">
                     Cost Centre
                    </label>
                    <Dropdown
                    name="fl_CO_CT"
                    value={fl_CO_CT}
                    options={fl_CO_CTOptions}
                    onChange={(e) => CostCentreDropDown("fl_CO_CT", e.value)}
                    placeholder="Select  Cost Centre"
                  />
                  </div>
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fl_IT_NM" className="font-bold">
                 Item No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText 
                      id="fl_IT_NM"
                      name="fl_IT_NM"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedItem}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItemPopup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="itemDES" className="font-bold">
                  Description
                  </label>
                  <InputText disabled
                    id="itemDES"
                    name="itemDES"
                    value={SelectedDesc}
                   readOnly
                  />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fl_CO_NO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="fl_CO_NO"
                    name="fl_CO_NO"
                    value={fl_CO_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="fl_PR_UM" className="font-bold">
                    Primary UM
                    </label>
                    <Dropdown disabled
                    name="fl_PR_UM"
                    value={fl_PR_UM}
                    options={fl_PR_UMOptions}
                    onChange={(e) => UMDropDown("fl_PR_UM", e.value)}
                    placeholder="Select UM"
                  />
                  </div>        
         
          </div>  

          <div
          style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
        >
      
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>

        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="FixedLocationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Location</th>
              <th>Max pick Qty</th>
              <th>Max Replenish Qty</th>
              <th>Normal Replenish Point</th>
              <th>Max Replenish Point</th>
            </tr>
          </thead>
          <tbody>
            {FixedLocationTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`flUkNo_${index}`}
                    name={`flUkNo_${index}`}
                    value={row.flUkNo}
                  />
                </td>
                <td>
                  <Dropdown
                    id={`fl_LO_CN_${index}`}
                    name={`fl_LO_CN_${index}`}
                    value={ data[`fl_LO_CN_${index}`] ? data[`fl_LO_CN_${index}`]: row.fl_LO_CN}
                    options={fl_LO_CNOptions}
                    onChange={(e) =>LocationDropdown(`fl_LO_CN_${index}`, e.value)}
                    placeholder="Select Location"
                  />
                  </td>
                  <td >
                  <InputText 
                    id={`fl_PK_QT_${index}`}
                    name={`fl_PK_QT_${index}`}
                    defaultValue={row.fl_PK_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText 
                    id={`fl_RP_QT_${index}`}
                    name={`fl_RP_QT_${index}`}
                    defaultValue={row.fl_RP_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText 
                    id={`fl_NR_PO_${index}`}
                    name={`fl_NR_PO_${index}`}
                    defaultValue={row.fl_NR_PO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText 
                    id={`fl_MR_PO_${index}`}
                    name={`fl_MR_PO_${index}`}
                    defaultValue={row.fl_MR_PO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        </div>
              </div>
              
          </TabPanel>
          <TabPanel header="Fixed Putaway">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="fl_CO_CT" className="font-bold">
                     Cost Centre
                    </label>
                    <Dropdown disabled
                    name="fl_CO_CT"
                    value={fl_CO_CT}
                    options={fl_CO_CTOptions}
                    onChange={(e) => CostCentreDropDown("fl_CO_CT", e.value)}
                    placeholder="Select  Cost Centre"
                  />
                  </div>
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fl_IT_NM" className="font-bold">
                 Item No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText  disabled
                      id="fl_IT_NM"
                      name="fl_IT_NM"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedItem}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItemPopup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="itemDES" className="font-bold">
                  Description
                  </label>
                  <InputText disabled
                    id="itemDES"
                    name="itemDES"
                    value={SelectedDesc}
                   readOnly
                  />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="fl_PR_UM" className="font-bold">
                    Primary UM
                    </label>
                    <Dropdown disabled
                    name="fl_PR_UM"
                    value={fl_PR_UM}
                    options={fl_PR_UMOptions}
                    onChange={(e) => UMDropDown("fl_PR_UM", e.value)}
                    placeholder="Select UM"
                  />
                  </div>        
         
          </div>  
          <div
          style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
        >
      
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="FixedLocationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {FixedLocationTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`flUkNo_${index}`}
                    name={`flUkNo_${index}`}
                    value={row.flUkNo}
                  />
                </td>
                <td>
                  <Dropdown
                    id={`fl_LO_CN_${index}`}
                    name={`fl_LO_CN_${index}`}
                    value={ data[`fl_LO_CN_${index}`] ? data[`fl_LO_CN_${index}`]: row.fl_LO_CN}
                    options={fl_LO_CNOptions}
                    onChange={(e) =>LocationDropdown(`fl_LO_CN_${index}`, e.value)}
                    placeholder="Select Location"
                  />
                  </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
              </div>
              
          </TabPanel>
      </TabView>
      
     
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
       
    </Dialog>
  );
}
