import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import MyBills from "./MyBills";
import InviteVisitor from "./InviteVisitor";
import Deliveries from "./Deliveries";
import PreApproval from "./PreApproval";
import CallGaurd from "./CallGaurd";
import GeneralServices from "./GeneralServices";
import ServiceRequest from "./ServiceRequest";
import { Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { FaSignOutAlt } from "react-icons/fa"; 
import "./CustomerPortal.css";
const MyBillsSection = () => {
  const [open, setOpen] = useState(false);
  const [data, setdata] = useState("");
  const toast = useRef(null);
  const [billingCode, setBillingCode] = useState("");

  const onchangedata = (e) => {
    const { name, value } = e.target;
    setdata((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleClickOpen = (billingCode) => {
    setBillingCode(billingCode);
    setOpen(true);
  };
  const handleRent = () => {
    handleClickOpen("R");
  };
  const handleMaintainance = () => {
    handleClickOpen("M");
  };
  const handleUtility = () => {
    handleClickOpen("U");
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      // handleDefault();
    }
  };

  const handleFormSubmit =  async () => {
    const  Url = `${domain}/api/GeneratePayment/GetGeneratePayment`
    const request = {
        "name": document.getElementById("cl_CU_NO").value,
        "doc_NO": document.getElementById("cl_DO_NO").value,
        "invoice_DATE": document.getElementById("cl_IN_DT").value,
        "due_DATE": document.getElementById("cl_DD_DT").value,
        "from_DATE": document.getElementById("fromDate").value,
        "to_DATE": document.getElementById("toDate").value,
        "amount": document.getElementById("cl_OP_AM").value,
        "notes": document.getElementById("notes").value,
    }
    axios.post(Url, request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      handleResponse(resp.data);
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };

  return (
    <div className="container">
       <div className="row">
        <div className="col-12 text-center mb-4">
          <h1 style={{ fontWeight: 'bold',fontSize:"110%" }}>My Bills</h1>
        </div>
       </div>
       <div className="row justify-content-center">
          <div className="col-lg-6 d-flex justify-content-between flex-nowrap mb-2">
          <Tooltip target=".Rent-button" position="bottom" mouseTrack >
            <div style={{ background: "white !important", color: "white",borderRadius:"50%", fontSize: "10px"}}>
            Rent Payment
            </div>
          </Tooltip>
          <Button 
            icon="pi pi-home"     
            outlined
            className="mr-2 Rent-button"
            onClick={handleRent}
          ></Button>
          <Tooltip target=".Maintainance-button" position="bottom" mouseTrack >
            <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
            Maintainance Payment
            </div>
          </Tooltip>
          <Button 
            icon="pi pi-wrench"     
            outlined
            className="mr-2 Maintainance-button"
            onClick={handleMaintainance}
          ></Button>
          <Tooltip target=".Utility-button" position="bottom" mouseTrack >
            <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
            Utility Payments
            </div>
          </Tooltip>
          <Button 
            icon="pi pi-money-bill"     
            outlined
            className="mr-2 Utility-button"
            onClick={handleUtility}
          ></Button>
          <Tooltip target=".Customer-button" position="bottom" mouseTrack >
            <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
            Customer Ledger
            </div>
          </Tooltip>
          <Button 
            icon="pi pi-book"     
            outlined
            className="mr-2 Customer-button"
          ></Button>
          </div>
        </div>
        <MyBills
          open={open}
          handleClose={handleClose}
          handleFormSubmit={handleFormSubmit}
          onchangedata={onchangedata}
          billingCode={billingCode}
        />
    </div>
  );
};
const GatePassSection = () => {
  const [InviteVisitorOpen, setInviteVisitorOpen] = useState(false);
  const [preapprovalOpen, setpreapprovalOpen] = useState(false);
  const [CallGaurdOpen, setCallGaurdOpen] = useState(false);
  const [DeliveriesOpen, setDeliveriesOpen] = useState(false);
  const handleCloseInviteVisitor = () => {
    setInviteVisitorOpen(false);
  };
  const handleInviteVisitor = () => {
    setInviteVisitorOpen(true);
  };
  const handleOpenapproval = () => {
    setpreapprovalOpen(true);
  };
  const handleCloseapproval = () => {
    setpreapprovalOpen(false);
  };
  const handleOpenCallGaurd = () => {
    setCallGaurdOpen(true);
  };
  const handleCloseCallGaurd = () => {
    setCallGaurdOpen(false);
  };
  const handleOpenDeliveries = () => {
    setDeliveriesOpen(true);
  };
  const handleCloseDeliveries = () => {
    setDeliveriesOpen(false);
  };
  return (
    <div className="container">
       <div className="row">
        <div className="col-12 text-center mb-4">
          <h1 style={{ fontWeight: 'bold',fontSize:"110%" }}>Gate Pass</h1>
        </div>
       </div>
       <div className="row justify-content-center">
          <div className="col-lg-6 d-flex justify-content-between flex-nowrap mb-2 p-0">
          <Tooltip target=".visitor-button" position="bottom" mouseTrack >
            <div style={{ background: "white !important", color: "white",borderRadius:"50%", fontSize: "10px" }}>
            Invite Visitor
            </div>
          </Tooltip>
          <Button 
            icon="pi pi-home"     
            outlined
            className="mr-2 visitor-button"
            onClick={handleInviteVisitor}
          ></Button>
          <Tooltip target=".deliveries-button" position="bottom" mouseTrack >
            <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
            Deliveries
            </div>
          </Tooltip>
          <Button 
            icon="pi pi-gift"     
            outlined
            className="mr-2 deliveries-button"
            onClick={handleOpenDeliveries}
          ></Button>
          <Tooltip target=".approval-button" position="bottom" mouseTrack >
            <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
            Pre Approval
            </div>
          </Tooltip>
          <Button 
            icon="pi pi-money-bill"     
            outlined
            className="mr-2 approval-button"
            onClick={handleOpenapproval}
          ></Button>
          <Tooltip target=".callgaurd-button" position="bottom" mouseTrack >
            <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
            Call Gaurd
            </div>
          </Tooltip>
          <Button 
            icon="pi pi-book"     
            outlined
            className="mr-2 callgaurd-button"
            onClick={handleOpenCallGaurd}
          ></Button>
          <Tooltip target=".visit-button" position="bottom" mouseTrack >
            <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
            Visit Report
            </div>
          </Tooltip>
          <Button 
            icon="pi pi-book"     
            outlined
            className="mr-2 viist-button"
          ></Button>
          </div>
        </div>
        <InviteVisitor 
          isOpen={InviteVisitorOpen} 
          handleClose={handleCloseInviteVisitor}
        />
        <Deliveries 
          isOpen={DeliveriesOpen} 
          handleClose={handleCloseDeliveries}
        />
         <PreApproval 
          isOpen={preapprovalOpen} 
          handleClose={handleCloseapproval}
        />
         <CallGaurd 
          isOpen={CallGaurdOpen} 
          handleClose={handleCloseCallGaurd}
        />
    </div>
  );
};
const GeneralServiceSection = () => {
  const [AmenitiesOpen, setAmenitiesOpen] = useState(false);
  const handleCloseAmenities = () => {
    setAmenitiesOpen(false);
  };
  const handleOpenAmenities = () => {
    setAmenitiesOpen(true);
  };
  const [ServiceOpen, setServiceOpen] = useState(false);
  const handleopenService = () => {
    setServiceOpen(true);
  };
  const handleCloseService = () => {
    setServiceOpen(false);
  };
  return (
    <div className="container">
      <div className="row ">
        <div className="col-12 text-center mb-4">
          <h1 style={{ fontWeight: "bold" ,fontSize:"110%"}}>General Services</h1>
        </div>
      </div>
      <div className="row justify-content-center">
      <div className="col-lg-6 d-flex justify-content-between flex-nowrap mb-2 p-0">
          <Tooltip target=".amenities-button" position="bottom" mouseTrack >
            <div style={{ background: "white !important", color: "white",borderRadius:"50%", fontSize: "10px" }}>
              Amenities 
            </div>
          </Tooltip>
          <Button 
            icon="pi pi-home"     
            outlined
            className="mr-2 amenities-button"
            onClick={handleOpenAmenities}
          ></Button>
          <Tooltip target=".service-button" position="bottom" mouseTrack >
            <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
              Service Request
            </div>
          </Tooltip>
          <Button 
            icon="pi pi-wrench"     
            outlined
            className="mr-2 service-button"
            onClick={handleopenService}
          ></Button>
          <Tooltip target=".society-button" position="bottom" mouseTrack >
            <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
              Society Forum
            </div>
          </Tooltip>
          <Button 
            icon="pi pi-users"     
            outlined
            className="mr-2 society-button"
          ></Button>
          <Tooltip target=".groups-button" position="bottom" mouseTrack >
            <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
              Society Groups
            </div>
          </Tooltip>
          <Button 
            icon="pi pi-users"     
            outlined
            className="mr-2 groups-button"
          ></Button>
          <Tooltip target=".notice-button" position="bottom" mouseTrack >
            <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
              Notice Board
            </div>
          </Tooltip>
          <Button 
            icon="pi pi-bell"     
            outlined
            className="mr-2 notice-button"
          ></Button>
          <Tooltip target=".document-button" position="bottom" mouseTrack >
            <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
              Document Repository
            </div>
          </Tooltip>
          <Button 
            icon="pi pi-book"     
            outlined
            className="mr-2 document-button"
          ></Button>
          </div>
      </div>
      <GeneralServices 
          isOpen={AmenitiesOpen} 
          handleClose={handleCloseAmenities}
        />
        <ServiceRequest 
          isOpen={ServiceOpen} 
          handleClose={handleCloseService}
        />
    </div>
  );
};
const CustomerPortalMain = () => {
  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/auth";
  };
  return (
    <div>
      {/* <NavLink to="/auth/sign-in" onClick={handleLogout}>
        <Text fontSize="sm">Log out</Text>
      </NavLink> */}
      <NavLink to="/auth/sign-in" onClick={handleLogout} className="logout-link">
        <FaSignOutAlt className="logout-icon" />
        <Text className="logout-text">Log out</Text>
      </NavLink>
      <div className="card mt-8" >
        <MyBillsSection/>
      </div>
      <div className="card mt-5" >
        <GatePassSection/>
      </div>
      <div className="card mt-5" >
        <GeneralServiceSection/>
      </div>      
    </div>    
  );
};

export default CustomerPortalMain;
