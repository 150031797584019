import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import ItemPopup from "../Popups/ItemPopup";
import moment from "moment";
import Costcenter  from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function ItemCostDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  ic_CO_CT,
  setic_CO_CT,
  ic_CO_TY,
  setic_CO_TY,
  ic_LO_CN,
  setic_LO_CN,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  eg_CO_NO,
}) {
  const {
    icItNo,
    ic_LO_TN,
    ic_CO_ST,
  } = data;
  const {borderbox,inboxstyle} = ButtonStyles();
  // dropdowns

  const { fetchClassCodeDS  } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  
  const [ic_CO_CTOptions, setic_CO_CTOptions] = useState([]);
  const [ic_CO_TYOptions, setic_CO_TYOptions] = useState([]);
  const [ic_LO_CNOptions, setic_LO_CNOptions] = useState([]);
// BIDDING type
  const CostCentreDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setic_CO_CT(value);
  };
// Acq
  const LocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setic_LO_CN(value);
  };
// loc
  const CostTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setic_CO_TY(value);
  };


  useEffect(() => {
    CostCentre();
    Location();
    CostType();

  }, []);

  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    setic_CO_CTOptions(Options);
  };

  const Location = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setic_LO_CNOptions(Options);
  };

  const CostType = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setic_CO_TYOptions(Options);
  };



  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    onchangeFormData("ic_IT_NM", data.im_IT_NM);
    onchangeFormData("name", data.im_IT_DS);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

const isUpdateMode = Boolean(icItNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={icItNo ? "Revise ItemCost" : "Add ItemCost"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
            <div
                style={{
                  marginTop: "2%",
                  border: "1px solid #e8e8e8",
                  padding: "16px",
                  borderRadius: "15px",
                  backgroundColor: "aliceblue",
                  overflow: "auto",
                }}
              >
                <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ic_IT_NM" className="font-bold">
                 Item No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText 
                      id="ic_IT_NM"
                      name="ic_IT_NM"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedItem}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItemPopup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="name" className="font-bold">
                  Description
                  </label>
                  <InputText disabled
                    id="name"
                    name="name"
                    value={SelectedDesc}
                   readOnly
                  />
            </div>
                
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ic_CO_CT" className="font-bold">
                    Cost Centre
                    </label>
                    <Dropdown
                    name="ic_CO_CT"
                    value={ic_CO_CT}
                    options={ic_CO_CTOptions}
                    onChange={(e) => CostCentreDropdown("ic_CO_CT", e.value)}
                    placeholder="Select Cost Centre"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ic_LO_CN" className="font-bold">
                    Location
                    </label>
                    <Dropdown
                    name="ic_LO_CN"
                    value={ic_LO_CN}
                    options={ic_LO_CNOptions}
                    onChange={(e) => LocationDropdown("ic_LO_CN", e.value)}
                    placeholder="Select Location"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ic_LO_TN" className="font-bold">
           LOT No
          </label>
          <InputText 
            id="ic_LO_TN"
            name="ic_LO_TN"
            defaultValue={ic_LO_TN}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="ic_CO_ST" className="font-bold">
           Unit Cost
          </label>
          <InputText 
            id="ic_CO_ST"
            name="ic_CO_ST"
            defaultValue={ic_CO_ST}
            onChange={(e) => onchangedata(e)}
          />
        </div>

                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ic_CO_TY" className="font-bold">
                    Cost Type
                    </label>
                    <Dropdown
                    name="ic_CO_TY"
                    value={ic_CO_TY}
                    options={ic_CO_TYOptions}
                    onChange={(e) => CostTypeDropdown("ic_CO_TY", e.value)}
                    placeholder="Select Cost Type"
                  />
                  </div>
                </div>
            </div>
      </div>
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
