import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import AssetPopup from "views/admin/Popups/AssetPopup";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
export default function AccountCostDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  // onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  addRow,
  deleteRow,
  SelectedAsset,
  setselectedAsset,
  SelectedDesc,
  setselectedDesc,
}) {
  const { 
  fmUkNo,
  fm_AT_NO,
  fm_DS_DT,
  } = data;
  const [rc_CO_TYOptions, setrc_CO_TYOptions] = useState([]);
  const [rc_CO_LVOptions, setrc_CO_LVOptions] = useState([]);
  const [rc_CS_PROptions, setrc_CS_PROptions] = useState([]);
  const [rc_GR_DEOptions, setrc_GR_DEOptions] = useState([]);
  const [rc_DL_TYOptions, setrc_DL_TYOptions] = useState([]);
  const [rc_TR_REOptions, setrc_TR_REOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "rc_ST_DT"  || name === "rc_EN_DT") {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);
  
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };
  const url = `${domain}/api/ClassCode/All`;
  // comp type
  const parameter1 = "CM/TY";
  const finalUrl1 = `${url}/${parameter1}`;
// comp level
  const parameter2 = "CO/LV";
  const finalUrl2 = `${url}/${parameter2}`;
// provider
  const parameter3 = "TR/PR";
  const finalUrl3 = `${url}/${parameter3}`;
// grade
  const parameter4 = "EM/GR";
  const finalUrl4 = `${url}/${parameter4}`;
// delivery type
  const parameter5 = "DL/TY";
  const finalUrl5 = `${url}/${parameter5}`;
// traiaining reason
  const parameter6 = "TR/RE";
  const finalUrl6 = `${url}/${parameter6}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
  }, []);

  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setrc_CO_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setrc_CO_LVOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl3, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setrc_CS_PROptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 4th dropdown
const fetchDropdownOptions4 = async () => {
  try {
    const response = await axios.get(finalUrl4, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setrc_GR_DEOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 5th dropdown
const fetchDropdownOptions5 = async () => {
  try {
    const response = await axios.get(finalUrl5, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setrc_DL_TYOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 6th dropdown
const fetchDropdownOptions6 = async () => {
  try {
    const response = await axios.get(finalUrl6, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setrc_TR_REOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const [showSearchIcon, setShowSearchIcon] = useState("");
const [SelectedRowIndex, setSelectedRowIndex] = useState("");
const [AssetPopupVisible, setAssetPopupVisible] = useState(false);
const openAssetPopup = (e) => {
  setAssetPopupVisible(true);
  setSelectedRowIndex(e);
};
const [AssetDisposaltable, setAssetDisposaltable] = useState([]); 
const handleDataSelect1 =async (data) => {
  setselectedAsset(data.fm_AT_NO)
  setselectedDesc(data.fm_AT_D1)
  onchangeFormData("fm_AT_NO", data.fm_AT_NO);
  onchangeFormData("fm_AT_D1", data.fm_AT_D1);
  const Url = `${domain}/api/AssetDisposal/GetFABAGetAssetDisposeAccounts`;
  const requestData={
    "fixedAssetMaster":{
      "fm_AT_NO":document.getElementById("fm_AT_NO").value,
      "fm_DS_DT":fm_DS_DT
    }
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataLead = response.data.acMtList;

    if (dataLead && dataLead.length > 0) {
      setAssetDisposaltable(dataLead.map(item => ({
        ACCOUNT: item.account,
        AMOUNT: item.amount,
        DESCRIPTION: item.am_AC_DS
      })));

    } else {
      console.error("Invalid API response:", data );
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setAssetPopupVisible(false);
  setShowSearchIcon(false);
};
const isUpdateMode = Boolean(fmUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={fmUkNo ? "Revise Asset Disposal" : "Add  Asset Disposal"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_AT_NO" className="font-bold">
                Asset No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText 
                      id="fm_AT_NO"
                      name="fm_AT_NO"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedAsset}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openAssetPopup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_AT_D1" className="font-bold">
                  Description
                  </label>
                  <InputText disabled
                    id="fm_AT_D1"
                    name="fm_AT_D1"
                    value={SelectedDesc}
                   readOnly
                  />
            </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="fm_DS_DT" className="font-bold">
                 Disposal GL Date
                  </label>
                  <Calendar
                    id="fm_DS_DT"
                    name="fm_DS_DT"
                    value={moment(fm_DS_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "fm_DS_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    showIcon
                    dateFormat="dd/mm/yy"
                  />
                </div>
                
                </div>
              </div>
      <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            marginTop: "2%",
          }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
          }}
        >
          {!isUpdateMode && (
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          )}
           {!isUpdateMode && (
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
          )}
        </div>
        <div
          style={{
            border: "1px solid #e8e8e8",
            padding: "16px",
            borderRadius: "15px",
            backgroundColor: "aliceblue",
            overflow: "auto",
            position: "relative",
          }}
        >
        <table className="custom-table" id="AssetDisposaltable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Account</th>
          <th>Description</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
  {AssetDisposaltable.map((row, index) => (
    <tr key={index}>
      <td style={{ display: "none" }}>
        <InputText
          id={`fmUkNo_${index}`}
          name={`fmUkNo_${index}`}
          defaultValue={row.fmUkNo}
        />
      </td>
      <td>
        <InputText
          disabled
          style={{ width: "300px" }}
          id={`ACCOUNT_${index}`}
          name={`ACCOUNT_${index}`}
          value={row.ACCOUNT}
          readOnly
        />
      </td>
      <td>
        <InputText
          disabled
          style={{ width: "300px" }}
          id={`DESCRIPTION_${index}`}
          name={`DESCRIPTION_${index}`}
          value={row.DESCRIPTION}
          readOnly
        />
      </td>
      <td>
        <InputText
          disabled
          style={{ width: "300px" }}
          id={`AMOUNT_${index}`}
          name={`AMOUNT_${index}`}
          value={row.AMOUNT}
          readOnly
        />
      </td>
    </tr>
  ))}
</tbody>

    </table>   
    
    </div>
  </div>   
  <AssetPopup
        visible={AssetPopupVisible}
        onClose={() => setAssetPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />  
    </Dialog>
  );
}
