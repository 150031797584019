import React, { useState, useEffect, useRef} from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

const EmailPopup = ({
  visible,
   onClose, 
   ei_AD_NO,
   name,
   ei_PO_RT,
   ei_HO_ST,
   ei_PS_WD,
   av_FR_ML,
   ei_UK_NO,
  }) => {
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [EmailData, setEmailData]=useState({});
  const [ShowEmailPopup, setShowEmailPopup] = useState(false);
  const handleClose = () => {
    onClose();
    setShowEmailPopup(false);
  };
  useEffect(() => {
    setShowEmailPopup(visible);
  });
  
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setEmailData({ ...EmailData, [name]: value });
  };


  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
    if (errorMessage.length === 0) {
      onClose();
    }
  };
 
  const sendEmail = async () => {
    const inputEmail ={
      "ei_UK_NO" : ei_UK_NO,
      "ei_AD_NO" : ei_AD_NO,
      "ei_HO_ST" : ei_HO_ST,
      "ei_PO_RT" : ei_PO_RT,
      "ei_PS_WD" : document.getElementById("ei_PS_WD").value,
    };
    if(ei_UK_NO){
          try {
            const response = await axios.put(
              `${domain}/api/Email/Update`,inputEmail,
              {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            });     
          const data = response.data;
          if(data){
            setEmailData(data);
            handleResponse(data);
          }
        }
        catch (error) {
            console.error("Error fetching data:", error);
          }
    } else {
        try {
            const response = await axios.post(
              `${domain}/api/Email/Add`,inputEmail,
              {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            });
      
          const data = response.data;
          if(data){
            setEmailData(data);
            handleResponse(data);
          }
        }
        catch (error) {
            console.error("Error fetching data:", error);
          }
    }
    
};

const isUpdateMode = Boolean(ei_UK_NO);
const productDialogFooter = (
  <React.Fragment>
  <Button 
  style={{width:"5%"}}
  icon="pi pi-times" 
  variant="outlined" 
  onClick={handleClose}
  tooltip="Cancel"
  >
  </Button>
  <Button   
  style={{width:"5%"}}
  icon="pi pi-check" 
  onClick={sendEmail} 
  tooltip={isUpdateMode ? "Update" : "Save"}>
  </Button>
</React.Fragment>
);


  return (
    <Dialog id="dialog-style"
    style={{width:"50%"}}
    visible={ShowEmailPopup}
    onHide={handleClose}
      modal
      header={ei_UK_NO ? "Email Setup" : "Email Setup"}
      footer={productDialogFooter}
    >
  <div>
    <Toast ref={toast} />
    <div style={{border:"1px solid grey", padding:"2%",backgroundColor:"aliceblue"}}>
    <div className="field row" style={{display:"none"}}>
          <label htmlFor="ei_AD_NO" className="font-bold">
          Address NO
          </label>
          <InputText
              id="ei_AD_NO"
              name="ei_AD_NO"
              value={ei_AD_NO}
              onChange={(Event) => onchangedata(Event)}
            />
        </div>
        <div className="field row" >
          <label htmlFor="name" className="font-bold">
         Name
          </label>
          <InputText
              id="name"
              name="name"
              value={name}
              // onChange={(Event) => onchangedata(Event)}
              readOnly
            />
        </div>
      <div className="field row" style={{display:"none"}}>
          <label htmlFor="av_FR_ML" className="font-bold">
          From
          </label>
          <InputText
              id="av_FR_ML"
              name="av_FR_ML"
              value={av_FR_ML}
              readOnly
              //onChange={(Event) => onchangedata(Event)}
            />
        </div>
        <div className="field row" style={{display:"none"}}>
          <label htmlFor="ei_HO_ST" className="font-bold">
        Host
          </label>
          <InputText
              id="ei_HO_ST"
              name="ei_HO_ST"
              value={ei_HO_ST}
              readOnly
            />
        </div>
        <div className="field row" style={{display:"none"}} >
          <label htmlFor="ei_PO_RT" className="font-bold">
        Port
          </label>
          <InputText
              id="ei_PO_RT"
              name="ei_PO_RT"
              value={ei_PO_RT}
             readOnly
            />
        </div>
        <div className="field row" >
          <label htmlFor="ei_PS_WD" className="font-bold">
          Password
          </label>
          <InputText
              id="ei_PS_WD"
              name="ei_PS_WD"
              defaultValue={ei_PS_WD}
              onChange={(Event) => onchangedata(Event)}
            />
        </div>
      </div>
      </div>
    </Dialog>
  );
};

export default EmailPopup;
