import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import TenantPopup from "views/admin/Popups/AddressBookPopup";
import PayeeNumberPopup from "views/admin/Popups/AddressBookPopup";
import SalesPersonPopup from "views/admin/Popups/AddressBookPopup";
import UnitFPopup from "../Popups/UnitFPopup";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import PayTerm from "views/common/PayTermDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function LeaseInformationDialog({
  open,
  handleConfirm,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  onchangeDropDownFormData9,
  onchangeDropDownFormData10,
  onchangeDropDownFormData11,
  onchangeDropDownFormData12,
  onchangeDropDownFormData13,
  onchangeDropDownFormData14,
  onChangeOfUnitSelect,
  addRow,
  deleteRow,
  LeaseInformationtable,
  SelectedliTwNt,
  setSelectedliTwNt, //tenant
  SelectedliPaNo,
  setSelectedliPaNo,//payee number
  SelectedliSePa,
  setSelectedliSePa, // sales person
  // SelectedliUnTs,
  // setSelectedliUnTs,
  li_LE_CO,
  li_LE_TY,
  li_UN_TS,
  setPaymentTerm,
  li_PY_TR,
  li_TR_CR,
  li_TE_NT,
  li_PA_NO,
  li_SE_PA,
  li_BS_CR,
  li_HL_CD,
  setli_LE_ST,
  li_LE_ST,
}) {
  const { 
    liLeNo,
    li_LE_TM,
    li_ST_DT,
    li_EN_DT,
    li_EX_RT,
    li_LG_NO,
    li_UK_NO,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [li_LE_STOptions, setli_LE_STOptions] = useState([]);
  const [li_LE_TMOptions, setli_LE_TMOptions] = useState([]);
  const [li_UN_TYOptions, setli_UN_TYOptions] = useState([]);
  const [li_UN_GLOptions, setli_UN_GLOptions] = useState([]);
  const [li_UN_USOptions, setli_UN_USOptions] = useState([]);
  const [li_RN_TYOptions, setli_RN_TYOptions] = useState([]);
  const [li_BL_STOptions, setli_BL_STOptions] = useState([]);
  const [li_TX_APOptions, setli_TX_APOptions] = useState([]);
  const [li_TX_PROptions, setli_TX_PROptions] = useState([]);
  const [li_PY_TROptions, setli_PY_TROptions] = useState([]);
  const [li_TR_CROptions, setli_TR_CROptions] = useState([]);

  const [showSearchIcon, setShowSearchIcon] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const handleFormDataChange2 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData2(name, value, index);
  };
  const handleFormDataChange3 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData3(name, value, index);
  };
  const handleFormDataChange14 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData14(name, value, index);
  };
  const handleFormDataChange4= (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData4(name, value, index);
  };
  const handleFormDataChange5 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData5(name, value, index);
  };
  const handleFormDataChange6 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData6(name, value, index);
  };
  const handleFormDataChange7 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData7(name, value, index);
  };
  const handleFormDataChange8 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData8(name, value, index);
  };
  const handleFormDataChange9 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData9(name, value, index);
  };
  const handleFormDataChange10 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData10(name, value, index);
  };
  const handleFormDataChange11 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData11(name, value, index);
  };
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchPayTerm } = PayTerm();
  useEffect(() => {
    fetchTermLease();
    fetchleastatus();
    fetchpayterm();
    fetchtranscurr();
    fetchUNITgrp();
    fetchunittype();
    fetchunitusage();
    fetchfrequency();
    fetchSTATUS();
    fetchtxAPL();
    fetchTAXPER();
  }, []);
// LEASESTATUS
  const fetchleastatus = async () => {
    const Options = await fetchClassCodeDS("LS/ST");
    setli_LE_STOptions(Options);
  };
// TERM LEASE
  const fetchTermLease = async () => {
    const Options = await fetchClassCodeDS("LE/TM");
    setli_LE_TMOptions(Options);
  };
  // PAY TERM
  const fetchpayterm = async () => {
    const Options = await fetchPayTerm();
    setli_PY_TROptions(Options);
  };
// TRANS CURRNCY
  const fetchtranscurr = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setli_TR_CROptions(Options);
  };
  // UNITGRP
  const fetchUNITgrp = async () => {
    const Options = await fetchClassCodeDS("UN/GP");
    setli_UN_GLOptions(Options);
  };
// UNITTYPE
  const fetchunittype = async () => {
    const Options = await fetchClassCodeDS("UN/TY");
    setli_UN_TYOptions(Options);
  };
  // UNITUSAGE
  const fetchunitusage = async () => {
    const Options = await fetchClassCodeDS("UN/US");
    setli_UN_USOptions(Options);
  };
  // FREQUENCY
  const fetchfrequency = async () => {
    const Options = await fetchClassCodeDS("RN/TY");
    setli_RN_TYOptions(Options);
  };
  // STATUS
  const fetchSTATUS = async () => {
    const Options = await fetchClassCodeDS("LS/BL");
    setli_BL_STOptions(Options);
  };
// TAX APPLICABLE
const fetchtxAPL = async () => {
  const Options = await fetchClassCodeDS("TX/AP");
  setli_TX_APOptions(Options);
};
// TAX % 
const fetchTAXPER = async () => {
  const Options = await fetchClassCodeDS("TX/PE");
  setli_TX_PROptions(Options);
};

const [TenantPopupVisible, setTenantPopupVisible] = useState(false);
const [PayeeNumberPopupVisible, setPayeeNumberPopupVisible] = useState(false);
const [SalesPersonPopupVisible, setSalesPersonPopupVisible] = useState(false);
const [UnitPopupVisible, setUnitPopupVisible] = useState("");
const [SelectedRowIndex, setSelectedRowIndex] = useState("");

const openTenantPopup = () => {
  setTenantPopupVisible(true);
};
const handleDataSelect =async (dataAb) => {
  setSelectedliTwNt(dataAb.ab_AD_NM);
  onchangeFormData("li_TE_NT", dataAb.abAdNo);

  const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
  const requestData={
    "cu_CU_NO": dataAb.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCuMt = response.data.Result;
    if (dataCuMt) {
      onchangeDropDownFormData12("li_PY_TR", dataCuMt.cu_PT_TM);
      onchangeDropDownFormData13("li_TR_CR", dataCuMt.cu_CR_CD);

      const exData={
        "cr_TO_CR": document.getElementById("li_BS_CR").value, //base curr
        "cr_FR_CR": dataCuMt.cu_CR_CD, //currency
        "cr_EF_DT": data.li_ST_DT
      }
      exchangeRate (exData);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setTenantPopupVisible(false);
  setShowSearchIcon(false);
};
function exchangeRate (exData){
  axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("li_EX_RT").value = dataExRt.cr_CO_MR;
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
};
const [addressTypeE, setaddressTypeE] = useState("E");
const [addressTypeC, setaddressTypeC] = useState("C");
const openPayeeNumberPopup = () => {
  setPayeeNumberPopupVisible(true);
};
const handleDataSelect1 =async (data) => {
  setSelectedliPaNo(data.ab_AD_NM);
  onchangeFormData("li_PA_NO", data.abAdNo);
  setPayeeNumberPopupVisible(false);
  setShowSearchIcon(false);
};
const openSalesPersonPopup = () => {
  setSalesPersonPopupVisible(true);
};
const handleDataSelect2 =async (data) => {
  setSelectedliSePa(data.ab_AD_NM);
  onchangeFormData("li_SE_PA", data.abAdNo);
  setSalesPersonPopupVisible(false);
  setShowSearchIcon(false);
};
const openUnitPopup = (e) => {
  setUnitPopupVisible(true);
  setSelectedRowIndex(e);
};
const handleDataSelect3 =async (content) => {
  document.getElementById (`li_UN_TS_${SelectedRowIndex}`).value = content.ui_UN_NO;
  document.getElementById (`li_FL_NO_${SelectedRowIndex}`).value =  content.ui_FL_NO;
  document.getElementById (`li_BL_NO_${SelectedRowIndex}`).value =  content.ui_BL_NO;
  document.getElementById (`bu_DESC_${SelectedRowIndex}`).value =  content.bu_DESC;
  document.getElementById (`li_PR_NO_${SelectedRowIndex}`).value =  content.ui_PR_NO;
  document.getElementById (`pr_DESC_${SelectedRowIndex}`).value =  content.pr_DESC;
  document.getElementById (`li_RN_AR_${SelectedRowIndex}`).value =  content.ui_TR_QT;
  document.getElementById (`li_US_AR_${SelectedRowIndex}`).value =  content.ui_TR_QT;
  document.getElementById (`li_UN_GL_${SelectedRowIndex}`).value =  content.ui_UN_NO;
  document.getElementById (`li_UN_TY_${SelectedRowIndex}`).value =  content.ui_UN_TY;
  onchangeDropDownFormData14(`li_UN_US_${SelectedRowIndex}`, content.ui_UN_US);
  LeaseInformationtable.forEach(s=>{
    s['li_UN_TY']=content.ui_UN_TY;
  });
  setUnitPopupVisible(false);
  setShowSearchIcon(false);
};
const isUpdateMode = Boolean(li_UK_NO);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{li_UK_NO ? "Revise Lease Inoformation" : "Add Lease Information"}</h5>
      <div style={{ display: 'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end', width: '100%' }}>
        {isUpdateMode && (
          <div style={{ display: 'flex',width:"5%" }}>
          <Button 
            style={{width:"100%"}}
            icon="pi pi-check-circle" 
            onClick={handleConfirm} 
            tooltip={"Confirm"}>
          </Button>
          </div>
        )}
        <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
          <Button
            style={savestyle}
            icon="pi pi-check"
            onClick={handleFormSubmit} 
            tooltip={isUpdateMode ? "Update" : "Submit"}
          />
          <Button
            style={cancelstyle}
            icon="pi pi-times"
            onClick={handleClose}
            tooltip="Cancel"
          />
        </div>
    </div>
  </div>
);
  // const productDialogFooter = (
  //   <React.Fragment>
  //      <div style={{ display: 'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end', width: '100%' }}>
  //      {isUpdateMode && (
  //       <div style={{ display: 'flex',width:"5%" }}>
  //       <Button 
  //         style={{width:"100%"}}
  //         icon="pi pi-check-circle" 
  //         onClick={handleConfirm} 
  //         tooltip={"Confirm"}>
  //       </Button>
  //       </div>
  //      )}
  //       <div style={{ display: 'flex',width:"10%" }}>
  //       <Button 
  //         style={{width:"100%"}}
  //         icon="pi pi-times" 
  //         variant="outlined" 
  //         onClick={handleClose}
  //         tooltip="Cancel"
  //       >
  //       </Button>
  //       <Button 
  //         style={{width:"100%"}}
  //         icon="pi pi-check" 
  //         onClick={handleFormSubmit} 
  //         tooltip={isUpdateMode ? "Update" : "Submit"}>
  //       </Button>
  //       </div>
  //   </div>
  // </React.Fragment>
  // );
  const isDisabled = li_LE_ST !== "O";

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
          <div style={{display:"none"}}>
              <label htmlFor="li_HL_CD" className="font-bold">
            HL_CODE
              </label>
              <InputText disabled
                id="li_HL_CD"
                name="li_HL_CD"
                value={li_HL_CD}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="liLeNo" className="font-bold">
              Lease Number
              </label>
              <InputText disabled
                id="liLeNo"
                name="liLeNo"
                defaultValue={liLeNo}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="li_LE_TY" className="font-bold">
                Type
                </label>
                <InputText disabled
                  id="li_LE_TY"
                  name="li_LE_TY"
                  value={li_LE_TY}
                />
            </div> 
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="li_LE_CO" className="font-bold">
            Company
            </label>
            <InputText disabled
              id="li_LE_CO"
              name="li_LE_CO"
              value={li_LE_CO}
              readOnly
            />
            </div>     
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="li_ST_DT" className="font-bold">
            Start Date
            </label>
            <Calendar disabled={isDisabled}
                id="li_ST_DT"
                name="li_ST_DT"
                value={moment(data.li_ST_DT, "YYYYMMDD").isValid() ? moment(data.li_ST_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "li_ST_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="li_EN_DT" className="font-bold">
            End Date  
            </label>
            <Calendar disabled={isDisabled}
                id="li_EN_DT"
                name="li_EN_DT"
                value={moment(data.li_EN_DT, "YYYYMMDD").isValid() ? moment(data.li_EN_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "li_EN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="li_LE_TM" className="font-bold">
                Terms Of Lease
                </label>
                    <Dropdown disabled={isDisabled}
                      id="li_LE_TM"
                      name="li_LE_TM"
                      value={li_LE_TM}
                      options={li_LE_TMOptions}
                      onChange={(e) => onchangeDropDownFormData2("li_LE_TM", e.value)}
                      placeholder="Select Lease"
                    />
                </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="li_TE_NT" className="font-bold">
                    Tenant <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText disabled={isDisabled}
                      id="tenant_NAME"
                      name="tenant_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedliTwNt}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openTenantPopup}
                    ></i>
                    )}
                </div>
                <div style={{display:"none"}}>
                  <InputText disabled={isDisabled}
                      id="li_TE_NT"
                      name="li_TE_NT"
                      value={li_TE_NT}
                      onChange={(e) => onchangedata(e)}
                    />
                </div>
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="li_PY_TR" className="font-bold">
                    Payment Term
                    </label>
                    <Dropdown disabled={isDisabled}
                      id="li_PY_TR"
                      name="li_PY_TR"
                      value={li_PY_TR}
                      options={li_PY_TROptions}
                      onChange={(e) => onchangeDropDownFormData12("li_PY_TR", e.value)}
                      placeholder="Select Pay Term"
                    />
                  </div>   
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="li_LE_ST" className="font-bold">
                Lease Status
                </label>
                <Dropdown
                  id="li_LE_ST"
                  name="li_LE_ST"
                  value={li_LE_ST}
                  options={li_LE_STOptions}
                  onChange={(e) => onchangeDropDownFormData1("li_LE_ST", e.value)}
                  placeholder="Select Leave Status"
                />
                </div> 
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="li_TR_CR" className="font-bold">
                    Trans. Currency
                    </label>
                    <Dropdown disabled={isDisabled}
                  id="li_TR_CR"
                  name="li_TR_CR"
                  value={li_TR_CR}
                  options={li_TR_CROptions}
                  onChange={(e) => onchangeDropDownFormData13("li_TR_CR", e.value)}
                  placeholder="Select Currency"
                />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="li_EX_RT" className="font-bold">
                    Exchange Rate
                    </label>
                    <InputText disabled
                      id="li_EX_RT"
                      name="li_EX_RT"
                      value={li_EX_RT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="li_BS_CR" className="font-bold">
                    Base Currency
                    </label>
                    <InputText disabled
                      id="li_BS_CR"
                      name="li_BS_CR"
                      value={li_BS_CR}
                    />
                  </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="li_SE_PA" className="font-bold">
                    Sales Person   <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText disabled={isDisabled}
                      id="sales_NAME"
                      name="sales_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedliSePa}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openSalesPersonPopup}
                    ></i>
                    )}
                </div>
                <div style={{display:"none"}}>
                  <InputText disabled={isDisabled}
                    id="li_SE_PA"
                    name="li_SE_PA"
                    value={li_SE_PA}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="li_LG_NO" className="font-bold">
                Legal Notice
                </label>
                <InputText disabled={isDisabled}
                  id="li_LG_NO"
                  name="li_LG_NO"
                  value={li_LG_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>  
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="li_PA_NO" className="font-bold">
                Payee   <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{display:"flex"}}>
                <InputText disabled={isDisabled}
                  id="payee_NAME"
                  name="payee_NAME"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedliPaNo}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openPayeeNumberPopup}
                ></i>
                )}
                <div style={{display:"none"}}>
                  <InputText disabled={isDisabled}
                    id="li_PA_NO"
                    name="li_PA_NO"
                    value={li_PA_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
        <table className="custom-table" id="LeaseInformationtable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Unit <span style={{ color: 'red' }}>*</span></th>
          <th>Floor</th>
          <th style={{ display: "none" }} >Buliding</th>
          <th>Buliding</th>
          <th style={{ display: "none" }} >Property</th>
          <th>Property</th>
          <th>Rentable Area</th>
          <th>Usable Area</th>
          <th style={{display:"none"}}>Unit Group</th>
          <th>Unit Type</th>
          <th>Unit Usage</th>
          <th>Annual Amount</th>
          <th>Monthly Amount</th>
          <th>Base Rent</th>
          <th>Other</th>
          <th>Frequency</th>
          <th style={{ display: "none" }}>Status</th>
          <th>Tax Applicable</th>
          <th>Tax %</th>
        </tr>
      </thead>
      <tbody>
      {LeaseInformationtable.map((row, index) => (
          <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
              id={`li_UK_NO_${index}`}
              name={`li_UK_NO_${index}`}
              defaultValue={row.li_UK_NO}
            />
          </td>
          <td>
          <div style={{ display: "flex" }}>
           <InputText disabled={isDisabled}
              style={{ width: "100px",}}
              id={`li_UN_TS_${index}`}
              name={`li_UN_TS_${index}`}
              onChange={(e) => onchangedata(e)}
              onFocus={() => setShowSearchIcon(true)}
              defaultValue={row.li_UN_TS ? row.li_UN_TS : row[`${'li_UN_TS'}_${index}`]}
            />
             {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={(e) => openUnitPopup(index)}
              ></i>
             )}
              </div>
          </td>
          <td>
            <InputText disabled style={{width:"90px"}}
            id={`li_FL_NO_${index}`}
            name={`li_FL_NO_${index}`}
            defaultValue={row.li_FL_NO}
          />
          </td>
          <td  style={{ display: "none" }} >
            <InputText style={{width:"90px"}}
            id={`li_BL_NO_${index}`}
            name={`li_BL_NO_${index}`}
            defaultValue={row.li_BL_NO}
          />
          </td>
          <td>
            <InputText disabled style={{width:"90px"}}
            id={`bu_DESC_${index}`}
            name={`bu_DESC_${index}`}
            defaultValue={row.bu_DESC}
          />
          </td>
            <td  style={{ display: "none" }} >
            <InputText style={{width:"90px"}}
            id={`li_PR_NO_${index}`}
            name={`li_PR_NO_${index}`}
            defaultValue={row.li_PR_NO}
          />
          </td>
            <td>
            <InputText disabled style={{width:"90px"}}
            id={`pr_DESC_${index}`}
            name={`pr_DESC_${index}`}
            defaultValue={row.pr_DESC}
          />
          </td>
          <td>
          <InputText disabled style={{width:"90px"}} 
            id={`li_RN_AR_${index}`}
            name={`li_RN_AR_${index}`}
            defaultValue={row.li_RN_AR}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"90px"}}
            id={`li_US_AR_${index}`}
            name={`li_US_AR_${index}`}
            defaultValue={row.li_US_AR}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td style={{display:"none"}}>
          <Dropdown disabled={isDisabled}
            id={`li_UN_GL_${index}`}
            name={`li_UN_GL_${index}`}
            value={data[`li_UN_GL_${index}`] ? data[`li_UN_GL_${index}`]:row.li_UN_GL }
            options={li_UN_GLOptions}
            onChange={(e) => handleFormDataChange14(`li_UN_GL_${index}`, e.value)}
            placeholder="Select Unit Group"
          />
          </td>
          
          <td>
          {/* <InputText style={{width:"120px"}} disabled
            id={`li_UN_TY_${index}`}
            name={`li_UN_TY_${index}`}
            defaultValue={row.li_UN_TY}
            readOnly
          /> */}
             <Dropdown disabled
            id={`li_UN_TY_${index}`}
            name={`li_UN_TY_${index}`}
            value={data[`li_UN_TY_${index}`] ? data[`li_UN_TY_${index}`]:row.li_UN_TY }
            options={li_UN_TYOptions}
            onChange={(e) => handleFormDataChange3(`li_UN_TY_${index}`, e.value)}
            placeholder="Select Unit Type"
          />
          </td>
          <td>
          {/* <InputText style={{width:"120px"}} disabled
            id={`li_UN_US_${index}`}
            name={`li_UN_US_${index}`}
            defaultValue={row.li_UN_US}
            readOnly
          /> */}
          <Dropdown disabled
            id={`li_UN_US_${index}`}
            name={`li_UN_US_${index}`}
            value={data[`li_UN_US_${index}`] ? data[`li_UN_US_${index}`]:row.li_UN_US }
            options={li_UN_USOptions}
            onChange={(e) => handleFormDataChange4(`li_UN_US_${index}`, e.value)}
            placeholder="Select Unit Usage"
          />
          </td>
          <td>
          <InputText style={{width:"90px"}} disabled={isDisabled}
            id={`li_AN_RN_${index}`}
            name={`li_AN_RN_${index}`}
            defaultValue={row.li_AN_RN}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"90px"}} disabled={isDisabled}
            id={`li_MN_RN_${index}`}
            name={`li_MN_RN_${index}`}
            defaultValue={row.li_MN_RN}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"90px"}} disabled={isDisabled}
            id={`li_BS_RN_${index}`}
            name={`li_BS_RN_${index}`}
            defaultValue={row.li_BS_RN}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"90px"}} disabled={isDisabled}
            id={`li_OT_RN_${index}`}
            name={`li_OT_RN_${index}`}
            defaultValue={row.li_OT_RN}
            onChange={(e) => onchangedata(e)}
          />
          </td>          
          <td>
          <Dropdown disabled={isDisabled}
            id={`li_RN_TY_${index}`}
            name={`li_RN_TY_${index}`}
            value={data[`li_RN_TY_${index}`] ? data[`li_RN_TY_${index}`]:row.li_RN_TY }
            options={li_RN_TYOptions}
            onChange={(e) => handleFormDataChange5(`li_RN_TY_${index}`, e.value)}
            placeholder="Select Frequency"
          />
          </td>
          <td style={{ display: "none" }}>
          <Dropdown disabled={isDisabled}
            id={`li_BL_ST_${index}`}
            name={`li_BL_ST_${index}`}
            value={data[`li_BL_ST_${index}`] ? data[`li_BL_ST_${index}`]:row.li_BL_ST }
            options={li_BL_STOptions}
            onChange={(e) => handleFormDataChange6(`li_BL_ST_${index}`, e.value)}
            placeholder="Select Status"
          />
          </td>
          <td>
          <Dropdown disabled={isDisabled}
            id={`li_TX_AP_${index}`}
            name={`li_TX_AP_${index}`}
            value={data[`li_TX_AP_${index}`] ? data[`li_TX_AP_${index}`]:row.li_TX_AP }
            options={li_TX_APOptions}
            onChange={(e) => handleFormDataChange7(`li_TX_AP_${index}`, e.value)}
            placeholder="Select Tax Applicable"
          />
          </td>
          <td>
          <Dropdown disabled={isDisabled}
            id={`li_TX_PR_${index}`}
            name={`li_TX_PR_${index}`}
            value={data[`li_TX_PR_${index}`] ? data[`li_TX_PR_${index}`]:row.tax_PER }
            options={li_TX_PROptions}
            onChange={(e) => handleFormDataChange8(`li_TX_PR_${index}`, e.value)}
            placeholder="Select Tax %"
          />
          </td>
          </tr>
           ))}
      </tbody>
    </table> 
    </div>
  </div>  
      <TenantPopup
        visible={TenantPopupVisible}
        onClose={() => setTenantPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypeC}
      />
       <PayeeNumberPopup
        visible={PayeeNumberPopupVisible}
        onClose={() => setPayeeNumberPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={addressTypeC}
      />
       <SalesPersonPopup
        visible={SalesPersonPopupVisible}
        onClose={() => setSalesPersonPopupVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
        addressType={addressTypeE}
      />
      <UnitFPopup
        visible={UnitPopupVisible}
        onClose={() => setUnitPopupVisible(false)}
        onDataSelect={handleDataSelect3}
        formData={data}
      />
    </Dialog>
  );
}
