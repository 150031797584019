import React from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import 'primeflex/primeflex.css';                                   // css utility
import '../../common/flags.css';
import EstimateEntryMain from './EstimateEntryMain';

const EstimateEntry= () => {

  return (
   <div className='saleslead'>
      <EstimateEntryMain/>
   </div>
  )
}

export default EstimateEntry