import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css'
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function LeaveSettlementDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  SelectedemEMNO,
  setSelectedemEMNO,
  em_EM_DS,
  setem_EM_DS,
  handleConfirm,
}) {
  // dropdowns
  const [em_EM_DSOptions, setem_EM_DSOptions] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const { 
    ls_EM_NO,
    ls_ST_DT,
    lsUkNo,
    ls_RJ_DT,
    ls_AB_DY,
    ls_WK_DY,
    ls_TO_SA,
    ls_WP_SA,
    ls_JN_DT,
    ls_SA_ED,
    ls_AD_DY,
    ls_PY_OT,
    ls_LJ_DT,
    ls_EM_DY,
    ls_HO_RA,
    ls_BA_SA,
    ls_OT_HR,
    ls_AL_SA,
    ls_OT_AL,
    ls_PY_TO,
    ls_LO_ST,
    ls_LS_OT,
    ls_LS_TO,
    ls_CO_NO,
    ls_NT_PY,
    ls_CS_SA,
    ls_PR_WP,
    ls_DE_RS,
    ls_MS_FE,
    ls_AR_TT,
    ls_VH_FN,
    ls_EM_DS,
    ls_SE_DP,
    ls_VS_EX,
    ls_AD_RS,
    em_CC_NM,
    ls_HO_HR,
    ls_BA_SC,
    ls_MN_SA,
    leavedate,
    joindate,
    rejoindate,
    lastleavedate

  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle,TabBoxStyle} = ButtonStyles();
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);  
  const [addressType, setaddressType] = useState("E");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  
  const openEmployeePopup = () => {
    setEmployeePopupVisible(true);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("ls_EM_NO", data.abAdNo);
    const  Url = `${domain}/api/AnnualLeaveSalarySettlement/GetProcessAnnualLeaveSettlement`
    const requestData = {
      "ls_EM_NO": data.abAdNo
    };
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data.warnings;
      if(data){
        onchangeDropDownFormData1("em_EM_DS", data.ls_EM_DS);
        onchangeFormData("ls_JN_DT", data.ls_JN_DT);
        onchangeFormData("ls_LJ_DT", data.ls_LJ_DT);
        onchangeFormData("ls_AD_DY", data.ls_AD_DY);
        onchangeFormData("ls_AB_DY", data.ls_AB_DY);
        onchangeFormData("ls_HO_RA", data.ls_HO_RA);
        onchangeFormData("ls_ST_DT", data.ls_ST_DT);
        onchangeFormData("ls_EM_DY", data.ls_EM_DY);
        onchangeFormData("ls_RJ_DT", data.ls_RJ_DT);
        onchangeFormData("ls_TO_SA", data.ls_TO_SA);
        onchangeFormData("ls_WK_DY", data.ls_WK_DY);
        onchangeFormData("ls_WP_SA", data.ls_WP_SA);
        onchangeFormData("ls_PY_TO", data.ls_PY_TO);
        onchangeFormData("ls_PR_WP", data.ls_PR_WP);
        onchangeFormData("ls_SA_ED", data.ls_SA_ED);
        onchangeFormData("ls_AL_SA", data.ls_AL_SA);
        onchangeFormData("ls_OT_AL", data.ls_OT_AL);
        onchangeFormData("ls_LO_ST", data.ls_LO_ST);
        onchangeFormData("ls_NT_PY", data.ls_NT_PY);
        onchangeFormData("ls_SE_DP", data.ls_SE_DP);
        onchangeFormData("ls_MS_FE", data.ls_MS_FE);
        onchangeFormData("ls_LS_TO", data.ls_LS_TO);
        onchangeFormData("ls_VH_FN", data.ls_VH_FN);
        onchangeFormData("ls_OT_HR", data.ls_OT_HR);
        onchangeFormData("ls_HO_HR", data.ls_HO_HR);
        onchangeFormData("ls_AD_RS", data.ls_AD_RS);
        onchangeFormData("ls_VS_EX", data.ls_VS_EX);
        onchangeFormData("ls_DE_RS", data.ls_DE_RS);
        onchangeFormData("ls_BA_SA", data.ls_BA_SA);
      }
      else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    //HolidayOTAmount(data.abAdNo);

    GetTotal(data.abAdNo);
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  const url = `${domain}/api/ClassCode/All`;
  // DESIGNATION
const parameter1 = "DE/SG";
const finalUrl1 = `${url}/${parameter1}`;

  // const parameter1 = "FO/CT";
  // const finalUrl1 = `${url}/${parameter1}`;

  // const parameter2 = "EM/DP";
  // const finalUrl2 = `${url}/${parameter2}`;


  useEffect(() => {
    fetchDropdownOptions1();
  }, []);

  // 1st fetchdropdown
const fetchDropdownOptions1 = async () => {
  try {
    const response = await axios.get(finalUrl1, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setem_EM_DSOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }

};

  // 2nd fetchdropdown
  // const fetchDropdownOptions2 = async () => {


  //   try {
  //     const response = await axios.get(finalUrl2);

  //     const options = response.data.map((item) => ({
  //       label: item.cl_CL_DS,
  //       value: item.cl_CL_CD,
  //     }));
  //     setem_DE_PTOptions(options);
  //   } catch (error) {
  //     console.error("Error fetching dropdown options:", error);
  //   }

  // };
  //Normal OT Amount
  const GetTotal = async (ls_EM_NO) => {
   // const currMonthOTHrs = fs_PR_SA;
    const request = {
      "fs_EM_NO" : ls_EM_NO,
      //"currMonthOTHrs" : currMonthOTHrs
    }
    try {
      const response =await axios.post(`${domain}/api/FinalSettlement/overtime`, request, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
          const dataTotal = response.data.Result;
          if (dataTotal) {
            onchangeFormData("ls_OT_HR", dataTotal.Amount);

            // document.getElementById("fs_TO_PY").value = dataTotal.Amount;
            //document.getElementById("fs_NT_PY").value = dataTotal.Net;
          }else {
          console.error("Invalid API response:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      HolidayOTAmount(ls_EM_NO)
  };
  //Holiday OT Amount
  const HolidayOTAmount = async (ls_EM_NO) => {
    // const currMonthOTHrs = fs_PR_SA;
     const request = {
       "fs_EM_NO" : ls_EM_NO,
       //"currMonthOTHrs" : currMonthOTHrs
     }
     try {
       const response =await axios.post(`${domain}/api/FinalSettlement/GetChangeOfCurrHolOT`, request, {
           headers: {
             Authorization: `Bearer ${token}`,
             "Content-Type": "application/json",
             "Access-Control-Allow-Origin": "http://localhost:3000",
             "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
           },
         })
         if (response.status !== 200) {
           throw new Error(`API request failed with status: ${response.status}`);
         }
           const dataTotal = response.data.Result;
           if (dataTotal) {
             onchangeFormData("ls_HO_HR", dataTotal.Amount);
 
             // document.getElementById("fs_TO_PY").value = dataTotal.Amount;
             //document.getElementById("fs_NT_PY").value = dataTotal.Net;
           }else {
           console.error("Invalid API response:", data);
         }
       } catch (error) {
         console.error("Error fetching data:", error);
       }
   };
  // completedfetchdropdowns
  
  const [activeTab, setActiveTab] = useState(0);
  const isUpdateMode = Boolean(lsUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{lsUkNo ? "Revise Leave Settlement" : "Add Leave Settlement"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Save"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
         {isUpdateMode && (
        <div style={{ display: 'flex',width:"5%" }}>
        <Button 
          style={{width:"100%"}}
          icon="pi pi-check-circle" 
          onClick={handleConfirm} 
          tooltip={"Confirm"}>
        </Button>
        </div>
        )}
      </div>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
    <div style={{ display: 'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end', width: '100%' }}>
    {isUpdateMode && (
     <div style={{ display: 'flex',width:"5%" }}>
     <Button 
       style={{width:"100%"}}
       icon="pi pi-check-circle" 
       onClick={handleConfirm} 
       tooltip={"Confirm"}>
     </Button>
     </div>
    )}
     <div style={{ display: 'flex',width:"10%" }}>
     <Button 
       style={{width:"100%"}}
       icon="pi pi-times" 
       variant="outlined" 
       onClick={handleClose}
       tooltip="Cancel"
     >
     </Button>
     <Button 
       style={{width:"100%"}}
       icon="pi pi-check" 
       onClick={handleFormSubmit} 
       tooltip={isUpdateMode ? "Update" : "Submit"}>
     </Button>
     </div>
 </div>
    </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle}>
        {/* tabs start */}
        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="Basic Details">
            {/* tab1 general */}
            <div style={inboxstyle}>
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_EM_NO" className="font-bold">
                  Employee No<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedemEMNO}
                    />
                     {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openEmployeePopup}
                    ></i>
                     )}
                  </div>
                  <div style={{ display: "none" }}>
                    <InputText
                      id="ls_EM_NO"
                      name="ls_EM_NO"
                      value={ls_EM_NO}
                      onChange={(Event) =>  onchangedata(Event)}//;GetTotal(ls_EM_NO);}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_EM_DS" className="font-bold">
                    Designation
                  </label>
                  <Dropdown
                    id="em_EM_DS"
                    name="em_EM_DS"
                    value={em_EM_DS}
                    options={em_EM_DSOptions}
                    onChange={(e) => onchangeDropDownFormData1("em_EM_DS", e.value)}
                    placeholder="Select Designation"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_ST_DT" className="font-bold">
                  Leave Starting From
                  </label>
                  <Calendar
                    id="ls_ST_DT"
                    name="ls_ST_DT"
                    value={moment(data.ls_ST_DT, "YYYYMMDD").isValid() ? moment(data.ls_ST_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "ls_ST_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                 
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_JN_DT" className="font-bold">
                    Joining Date
                  </label>
                  <Calendar
                    id="ls_JN_DT"
                    name="ls_JN_DT"
                    value={moment(data.ls_JN_DT, "YYYYMMDD").isValid() ? moment(data.ls_JN_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: { name: "ls_JN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },  }) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_RJ_DT" className="font-bold">
                    Rejoining Date
                  </label>
                  <Calendar
                    id="ls_RJ_DT"
                    name="ls_RJ_DT"
                    value={moment(data.ls_RJ_DT, "YYYYMMDD").isValid() ? moment(data.ls_RJ_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: { name: "ls_RJ_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "",  },  }) }
                    dateFormat="dd/mm/yy"
                  />
                 
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_LJ_DT" className="font-bold">
                  Last Annual Leave Joining 
                  </label>
                  <Calendar
                    id="ls_LJ_DT"
                    name="ls_LJ_DT"
                    value={moment(data.ls_LJ_DT, "YYYYMMDD").isValid() ? moment(data.ls_LJ_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({target: {name: "ls_LJ_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "",  },  })  }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_AB_DY" className="font-bold">
                  Absent Days
                  </label>
                  <InputText
                    id="ls_AB_DY"
                    name="ls_AB_DY"
                    value={ls_AB_DY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_EM_DY" className="font-bold">
                  Emergency Leave Days
                  </label>
                  <InputText
                    id="ls_EM_DY"
                    name="ls_EM_DY"
                    value={ls_EM_DY}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_BA_SA" className="font-bold">
                  Basic Salary
                  </label>
                  <InputText
                    id="ls_BA_SA"
                    name="ls_BA_SA"
                    defaultValue={ls_BA_SA}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_OT_AL" className="font-bold">
                  Other Allowance
                  </label>
                  <InputText
                    id="ls_OT_AL"
                    name="ls_OT_AL"
                    value={ls_OT_AL}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_HO_RA" className="font-bold">
                  HRA
                  </label>
                  <InputText
                    id="ls_HO_RA"
                    name="ls_HO_RA"
                    value={ls_HO_RA}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_TO_SA" className="font-bold">
                  Total Salary
                  </label>
                  <InputText
                    id="ls_TO_SA"
                    name="ls_TO_SA"
                    value={ls_TO_SA}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_WP_SA" className="font-bold">
                  WPS Salary
                  </label>
                  <InputText
                    id="ls_WP_SA"
                    name="ls_WP_SA"
                    value={ls_WP_SA}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_AD_DY" className="font-bold">
                  Leave Adjusted Days
                  </label>
                  <InputText
                    id="ls_AD_DY"
                    name="ls_AD_DY"
                    value={ls_AD_DY}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_WK_DY" className="font-bold">
                  Current Work Month Days
                  </label>
                  <InputText
                    id="ls_WK_DY"
                    name="ls_WK_DY"
                    value={ls_WK_DY}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_SA_ED" className="font-bold">
                  Leave Salary Eligible Days
                  </label>
                  <InputText
                    id="ls_SA_ED"
                    name="ls_SA_ED"
                    value={ls_SA_ED}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
              </div>
              </div>       
          </TabPanel>
          {/* tab2 */}
          <TabPanel header="Payment Calculation">      
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_AL_SA" className="font-bold">
                 Annual Leave Salary
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ls_AL_SA"
                      name="ls_AL_SA"
                      value={ls_AL_SA}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_MN_SA" className="font-bold">
                  Current Month Salary
                  </label>
                  <InputText
                      id="ls_MN_SA"
                      name="ls_MN_SA"
                      defaultValue={ls_MN_SA}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_OT_HR" className="font-bold">
                  Normal OT Amount
                  </label>
                  <div style={{ display: "flex" }}>
                  <InputText
                      id="ls_OT_HR"
                      name="ls_OT_HR"
                      defaultValue={ls_OT_HR}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_CS_SA" className="font-bold">
                 Cash Salary of Previous Month
                  </label>
                  <InputText
                      id="ls_CS_SA"
                      name="ls_CS_SA"
                      value={ls_CS_SA}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_HO_HR" className="font-bold">
                  Holiday OT Amount
                  </label>
                  <div style={{ display: "flex" }}>
                  <InputText
                      id="ls_HO_HR"
                      name="ls_HO_HR"
                      defaultValue={ls_HO_HR}
                     // value={ls_HO_HR}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_AR_TT" className="font-bold">
                  Air Ticket
                  </label>
                  <InputText
                      id="ls_AR_TT"
                      name="ls_AR_TT"
                      value={ls_AR_TT}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_PY_OT" className="font-bold">
                 Any Other
                  </label>
                  <div style={{ display: "flex" }}>
                  <InputText
                      id="ls_PY_OT"
                      name="ls_PY_OT"
                      value={ls_PY_OT}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_AD_RS" className="font-bold">
                  Reason
                  </label>
                  <InputText
                      id="ls_AD_RS"
                      name="ls_AD_RS"
                      value={ls_AD_RS}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_PY_TO" className="font-bold">
                  Total
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ls_PY_TO"
                      name="ls_PY_TO"
                      value={ls_PY_TO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
            </div>
            </div>
            {/* 2nd div */}
            <div  style={inboxstyle}  >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_PR_WP" className="font-bold">
                 WPS Salary Hold
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ls_PR_WP"
                      name="ls_PR_WP"
                      value={ls_PR_WP}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_LO_ST" className="font-bold">
                  Loan Outstanding
                  </label>
                  <InputText
                    id="ls_LO_ST"
                    name="ls_LO_ST"
                    value={ls_LO_ST}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_MS_FE" className="font-bold">
                  Mess Fee
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ls_MS_FE"
                      name="ls_MS_FE"
                      value={ls_MS_FE}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_VH_FN" className="font-bold">
                  Vehicle Fine
                  </label>
                  <InputText
                    id="ls_VH_FN"
                    name="ls_VH_FN"
                    value={ls_VH_FN}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_SE_DP" className="font-bold">
                  Security Deposit
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ls_SE_DP"
                      name="ls_SE_DP"
                      value={ls_SE_DP}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_VS_EX" className="font-bold">
                  Additional Expense
                  </label>
                  <InputText
                    id="ls_VS_EX"
                    name="ls_VS_EX"
                    value={ls_VS_EX}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_LS_OT" className="font-bold">
                 Any Other
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ls_LS_OT"
                      name="ls_LS_OT"
                      value={ls_LS_OT}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_DE_RS" className="font-bold">
                  Reason
                  </label>
                  <InputText
                    id="ls_DE_RS"
                    name="ls_DE_RS"
                    value={ls_DE_RS}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_LS_TO" className="font-bold">
                  Total
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ls_LS_TO"
                      name="ls_LS_TO"
                      value={ls_LS_TO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                </div>
            </div>
            <div style={inboxstyle}  >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ls_NT_PY" className="font-bold">
                  Net Payable
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ls_NT_PY"
                      name="ls_NT_PY"
                      value={ls_NT_PY}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
              </div>
            </div>

          </TabPanel>          
        </TabView>
        {/* tabs end */}

      </div>
      <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressType}
      />
    </Dialog>
  );
}
