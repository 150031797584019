import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import CustomerPopup from "./CustomerPopup";
import EquipmentPopup from "./EquipmentPopup";
import ContactOwnerPopup from "./AssignePopup";
import ItemPopup from "./ItemPopup";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import ServiceContractPopup from "../Popups/ServiceContractPopup";
export default function ManageCaseDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  cs_PR_TY,
  cs_ST_AT,
  cs_QA_SC,
  cs_PR_GP,
  cs_PR_MD,
  cs_CS_ID,
  cs_CS_TY,
  cs_CS_CO,
  csUkNo,
  eq_NAME,
  item_NAME,
  seteq_NAME,
  setitem_NAME,
  as_NAME,
  cus_NAME,
  setcus_NAME,
  setas_NAME,
  cs_CU_NO,
  cs_AS_GN,
  setcs_CU_NO,
  setcs_AS_GN,
  cs_AD_PH,
  cs_AD_EM,
  setcs_AD_EM,
  setcs_AD_PH
}) {
  const { 
    cs_CB_NO,  
    cs_CA_MB ,
    cs_TR_DT,
    cs_CA_NM,
    cs_QA_FN,
    cs_ST_DT,
    cs_ST_TM,
    cs_EN_DT,
    cs_EN_TM,
    cs_IT_NM,
    cs_LO_TN,
    cs_EQ_NM,
    sol_DESC,
    issue_DESC,
    cs_CS_SU,
  }
     = data;
     const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
     const [ContactOwnerPopupVisible, setContactOwnerPopupVisible] = useState(false);
     const [EquipmentPopupVisible, setEquipmentVisible] = useState(false);
     const [ItemPopupVisible, setItemPopupVisible] = useState(false);
     const [cs_QA_SCOptions, setcs_QA_SCOptions] = useState([]);
     const [cs_PR_TYOptions, setcs_PR_TYOptions] = useState([]);
     const [cs_ST_ATOptions, setcs_ST_ATOptions] = useState([]);
     const [cs_PR_GPOptions, setcs_PR_GPOptions] = useState([]);
     const [cs_PR_MDOptions, setcs_PR_MDOptions] = useState([]);
     const [showSearchIcon, setShowSearchIcon] = useState("");

     const openCustomerPopup = () => {
      setCustomerPopupVisible(true);
    };
  
    const openContactOwnerPopup = () => {
      setContactOwnerPopupVisible(true);
    };
    const openEquipmentPopup = () => {
      setEquipmentVisible(true);
    };
  
    const openItemPopup = () => {
      setItemPopupVisible(true);
    };

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const url =`${domain}/api/ClassCode/All`;
  const parameter = "RN/RC";
  const finalUrl = `${url}/${parameter}`;
  const parameter1 = "WO/PR";
  const finalUrl1 = `${url}/${parameter1}`;
  const parameter2 = "MG/ST";
  const finalUrl2 = `${url}/${parameter2}`;
  const parameter3 = "AS/GP";
  const finalUrl3 = `${url}/${parameter3}`;
  const parameter4 = "IN/PM";
  const finalUrl4 = `${url}/${parameter4}`;
  useEffect(() => {
    fetchDropdownOptions();
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
  }, []);
  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setcs_QA_SCOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  
  };
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setcs_PR_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
       setcs_ST_ATOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  
  }; 
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setcs_PR_GPOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setcs_PR_MDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
 
  const isUpdateMode = Boolean(csUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  const handleDataSelect = async (data) => {
    onchangeFormData("cs_CU_NO", data.abAdNo);
    onchangeFormData("cus_NAME", data.ab_AD_NM);
    setcs_CU_NO(data.abAdNo);
    setcus_NAME(data.ab_AD_NM);
	
	const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
    const requestData={
      "abAdNo":data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const adMt = response.data.data;
      if (adMt) {
        setcs_AD_EM(adMt.ab_AD_EM);
        setcs_AD_PH(adMt.ab_AD_P1);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
	
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };  

  const handleDataSelect1 = (data) => {
    setas_NAME(data.ab_AD_NM);
    onchangeFormData("cs_AS_GN", data.abAdNo);
    setcs_AS_GN(data.abAdNo);
    setContactOwnerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect2 = async (data) => {
    seteq_NAME(data.em_EQ_DS);
    onchangeFormData("cs_EQ_NM", data.em_EQ_NM);

    const Url = `${domain}/api/EquipmentMaster/GetByEquipmentName`;
    const requestData={
      "em_EQ_NM":data.em_EQ_NM
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataEQ = response.data.result;
      if (dataEQ ) {
        document.getElementById("item_NAME").value = dataEQ.item_NAME;
        document.getElementById("cs_IT_NM").value = dataEQ.em_IT_NM;
        document.getElementById("cs_LO_TN").value = dataEQ.em_SR_NO;
        document.getElementById("cs_PR_MD").value = dataEQ.em_PD_MD;
      } else {
        console.error("Invalid API response:", data);
      }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setEquipmentVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect3 = (data) => {
    setitem_NAME(data.im_IT_DS);
    onchangeFormData("cs_IT_NM", data.im_IT_NM);
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [ContractPopupVisible, setContractPopupVisible] = useState(false);
  const openContractPopup = () => {
    setContractPopupVisible(true);
  };
  const handleDataSelectSrcnt = (data) => {
    // onchangeFormData("cs_CB_NO", data.cd_CO_NO);
    document.getElementById("cs_CB_NO").value = data.cd_CO_NO;
    setContractPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "cs_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={csUkNo ? "Revise Manage Case" : "Add Manage Case"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div className="formgrid grid">
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
            <label htmlFor="csUkNo" className="font-bold">
              UKNO
            </label>
            <InputText
              id="csUkNo"
              name="csUkNo"
              value={csUkNo}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
            <label htmlFor="cs_CS_TY" className="font-bold">
              Case Type
            </label>
            <div>
              <InputText disabled
                id="cs_CS_TY"
                name="cs_CS_TY"
                value={cs_CS_TY}
              />
            </div>
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
            <label htmlFor="cs_CS_CO" className="font-bold">
              Case Company
            </label>
            <div>
              <InputText disabled
                id="cs_CS_CO"
                name="cs_CS_CO"
                value={cs_CS_CO}
              />
            </div>
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_CS_ID" className="font-bold">
              Case ID
            </label>
            <div style={{ display: "flex" }}>
              <InputText disabled
                id="cs_CS_ID"
                name="cs_CS_ID"
                value={cs_CS_ID}
                readOnly
              />
            </div>
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_TR_DT" className="font-bold">
              Date
            </label>
            <Calendar
              id="cs_TR_DT"
              name="cs_TR_DT"
              value={moment(cs_TR_DT, "YYYYMMDD").toDate()}
              onChange={handleDateChange}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_CB_NO" className="font-bold">
              Contract ID <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
                id="cs_CB_NO"
                name="cs_CB_NO"
                onChange={(e) => onchangedata(e)}
                onFocus={() => setShowSearchIcon(true)}
                defaultValue={cs_CB_NO}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openContractPopup}
              ></i>
              )}
            </div>
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_CU_NO" className="font-bold">
              Customer <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText readonly
                id="cus_NAME"
                name="cus_NAME"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={cus_NAME}
              />
              {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCustomerPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="cs_CU_NO"
                name="cs_CU_NO"
                value={cs_CU_NO}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_AD_PH" className="font-bold">
              Phone
            </label>
              <InputText disabled
                id="cs_AD_PH"
                name="cs_AD_PH"
                defaultValue={cs_AD_PH}
                onChange={(e) => onchangedata(e)}
              />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_AD_EM" className="font-bold">
              Email
            </label>
              <InputText disabled
                id="cs_AD_EM"
                name="cs_AD_EM"
                defaultValue={cs_AD_EM}
                onChange={(e) => onchangedata(e)}
              />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_CA_NM" className="font-bold">
              Caller Name
            </label>
              <InputText
                id="cs_CA_NM"
                name="cs_CA_NM"
                value={cs_CA_NM}
                onChange={(e) => onchangedata(e)}
              />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_CA_MB" className="font-bold">
              Mobile
            </label>
              <InputText
                id="cs_CA_MB"
                name="cs_CA_MB"
                value={cs_CA_MB}
                onChange={(e) => onchangedata(e)}
              />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_QA_FN" className="font-bold">
              Problem
            </label>
              <InputText
                id="cs_QA_FN"
                name="cs_QA_FN"
                value={cs_QA_FN}
                onChange={(e) => onchangedata(e)}
              />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_QA_SC" className="font-bold">
              Reason
            </label>
            <Dropdown
              name="cs_QA_SC"
              value={cs_QA_SC}
              options={cs_QA_SCOptions}
              onChange={(e) => onchangeDropDownFormData("cs_QA_SC", e.value)}
              placeholder="Select Reason"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_ST_DT" className="font-bold">
              Start Date
            </label>
            <Calendar
              id="cs_ST_DT"
              name="cs_ST_DT"
              value={moment(data.cs_ST_DT, "YYYYMMDD").isValid() ? moment(data.cs_ST_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: {name: "cs_ST_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_ST_TM" className="font-bold">
              Start Time
            </label>
             <InputMask
              id={cs_ST_TM}
              name={cs_ST_TM}
              mask="99:99:99"
              value={moment(cs_ST_TM, "HHmmss").format("HH:mm:ss")}
              onBlur={(e) => {e.target.name='cs_ST_TM';onchangedata(e);}}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_EN_DT" className="font-bold">
              End Date
            </label>
            <Calendar
              id="cs_EN_DT"
              name="cs_EN_DT"
              value={moment(data.cs_EN_DT, "YYYYMMDD").isValid() ? moment(data.cs_EN_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: {name: "cs_EN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_EN_TM" className="font-bold">
              End Time
            </label>
           <InputMask
              id={cs_EN_TM}
              name={cs_EN_TM}
              mask="99:99:99"
              value={moment(cs_EN_TM, "HHmmss").format("HH:mm:ss")}
              onBlur={(e) => {e.target.name='cs_EN_TM';onchangedata(e);}}
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_PR_TY" className="font-bold">
              Priority
            </label>
            <Dropdown
              name="cs_PR_TY"
              value={cs_PR_TY}
              options={cs_PR_TYOptions}
              onChange={(e) => onchangeDropDownFormData1("cs_PR_TY", e.value)}
              placeholder="Select a Priority"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_ST_AT" className="font-bold">
              Status
            </label>
            <Dropdown
              name="cs_ST_AT"
              value={cs_ST_AT}
              options={cs_ST_ATOptions}
              onChange={(e) => onchangeDropDownFormData2("cs_ST_AT", e.value)}
              placeholder="Select a Status"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_AS_GN" className="font-bold">
              Assignee <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText readonly
                id="as_NAME"
                name="as_NAME"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={as_NAME}
              />
                 {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openContactOwnerPopup}
              ></i>
                 )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="cs_AS_GN"
                name="cs_AS_GN"
                value={cs_AS_GN}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_PR_GP" className="font-bold">
              Provider Grp
            </label>
            <Dropdown
              name="cs_PR_GP"
              value={cs_PR_GP}
              options={cs_PR_GPOptions}
              onChange={(e) => onchangeDropDownFormData3("cs_PR_GP", e.value)}
              placeholder="Select a  Provider Grp"
            />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_EQ_NM" className="font-bold">
              Equipment <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText readonly
                id="eq_NAME"
                name="eq_NAME"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={eq_NAME}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openEquipmentPopup}
              ></i>
               )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="cs_EQ_NM"
                name="cs_EQ_NM"
                value={cs_EQ_NM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_PR_MD" className="font-bold">
              Product Model
            </label>
            <InputText disabled
                id="cs_PR_MD"
                name="cs_PR_MD"
                defaultValue={cs_PR_MD}
                onChange={(e) => onchangedata(e)}
              />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_IT_NM" className="font-bold">
              Item Name <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText readonly
                id="item_NAME"
                name="item_NAME"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={item_NAME}
              />
              {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openItemPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="cs_IT_NM"
                name="cs_IT_NM"
                value={cs_IT_NM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="cs_LO_TN" className="font-bold">
              Serial Number
            </label>
            <InputText disabled
              id="cs_LO_TN"
              name="cs_LO_TN"
              value={cs_LO_TN}
              onChange={(e) => onchangedata(e)}
            />
          </div>

      </div>
      </div>
      <div
        style={{
          marginTop: "2%",
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div className="field col">
          <label htmlFor="issue_DESC" className="font-bold">
            Issue Description
          </label>
          <div style={{ display: "flex" }}>
            <textarea
             id="issue_DESC"
              name="issue_DESC"
              value={issue_DESC}
              style={{ width: "900px", height: "100px" }}
            />
          </div>
        </div>
        <div className="field col">
          <label htmlFor="cs_CS_SU" className="font-bold">
            Solution
          </label>
          <div style={{ display: "flex" }}>
            <InputText
              id="cs_CS_SU"
              name="cs_CS_SU"
              value={cs_CS_SU}
              onChange={(e) => onchangedata(e)}
            />
          </div>
        </div>
        <div className="field col">
        <label htmlFor="sol_DESC" className="font-bold">
          Description
          </label>
          <div style={{ display: "flex" }}>
            <textarea
             id="sol_DESC"
              name="sol_DESC"
              value={sol_DESC}
              style={{ width: "900px", height: "100px" }}
            />
          </div>
        </div>

      </div>
      <CustomerPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
      <ContactOwnerPopup
        visible={ContactOwnerPopupVisible}
        onClose={() => setContactOwnerPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
      <EquipmentPopup
        visible={EquipmentPopupVisible}
        onClose={() => setEquipmentVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
      />
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelect3}
        formData={data}
      />
         <ServiceContractPopup
          visible={ContractPopupVisible}
          onClose={() => setContractPopupVisible(false)}
          onDataSelect={handleDataSelectSrcnt}
          formData={data}
        />
    </Dialog>
  );
}
