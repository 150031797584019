import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import LeaveSettlementDialog from "./LeaveSettlementDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  ls_EM_NO: "",
  ls_ST_DT: "",
  lsUkNo : "",
  ls_RJ_DT:"",
  ls_AB_DY:"",
  ls_MN_SA:"",
  ls_WK_DY:"",
  ls_TO_SA:"",
  ls_WP_SA:"",
  ls_JN_DT:"",
  ls_SA_ED:"",
  ls_AD_DY:"",
  ls_PY_OT:"",
  ls_LJ_DT:"",
  ls_EM_DY:"",
  ls_HO_RA:"",
  ls_BA_SA:"",
  ls_OT_HR:"",
  ls_AL_SA:"",
  ls_OT_AL:"",
  ls_PY_TO:"",
  ls_LO_ST:"",
  ls_LS_OT:"",
  ls_LS_TO:"",
  ls_CO_NO:"",
  ls_NT_PY:"",
  ls_CS_SA:"",
  ls_PR_WP:"",
  ls_DE_RS:"",
  ls_MS_FE:"",
  ls_AR_TT:"",
  ls_VH_FN:"",
  ls_EM_DS:"",
  ls_SE_DP:"",
  ls_VS_EX:"",
  ls_AD_RS:"",
  em_CC_NM:"",
  em_EM_DS:"",
  em_CO_NO:"",
  em_DE_PT:"",
  ls_HO_HR:"",
};

const initialFilters = {
  eo_EC_NO: { value: "", operation: "" },
  eo_OV_DT: { value: "", operation: "" },
  name: { value: "", operation: "" },
  eo_SH_FT: { value: "", operation: "" },
  eo_OV_TM: { value: "", operation: "" },
  eo_OV_DT: { value: "", operation: "" },
  eo_HO_TM: { value: "", operation: "" },
  eo_CO_NO: { value: "", operation: "" },
};

const LeaveSettlementMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [em_EM_DS, setem_EM_DS] = useState("");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setem_EM_DS(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setem_EM_DS(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
    // copy function
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/AnnualLeaveSalarySettlement/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const LeaveData = response.data;
          delete LeaveData.lsUkNo;
          setFormData(LeaveData);
          setSelectedemEMNO(LeaveData.name);
          setem_EM_DS(LeaveData.ls_EM_DS);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/AnnualLeaveSalarySettlement/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({  ...prevData, [name]: value, }));
  };
  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
    setem_EM_DS(value);
  };
  // const onchangeDropDownFormData2 = (name, value) => {
  //   setFormData({ ...FormData, [name]: value });
  //   seteo_SH_FT(value);
  // };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/AnnualLeaveSalarySettlement/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const leavedata = response.data;
        setFormData(leavedata);
        setSelectedemEMNO(leavedata.name);
        setem_EM_DS(leavedata.ls_EM_DS);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {     
      if (FormData.lsUkNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/AnnualLeaveSalarySettlement/Update`;
        if (confirm) {
          axios
            .put(update, FormData, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        FormData.ls_CO_NO = company;
        axios
          .post(`${domain}/api/AnnualLeaveSalarySettlement/Add`, FormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
        })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
  };
  const handleConfirm = () => {
    const ukNo = FormData.lsUkNo;
      if (ukNo) {
        const confirm = window.confirm("Do you want to Confirm ?");
        const ConfirmUrl = `${domain}/api/AnnualLeaveSalarySettlement/ConfirmAnnualLeaveSettlement`;
        if (confirm) {
          axios.post(ConfirmUrl, FormData, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } 
};
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ls_EM_NO"] ? filters["ls_EM_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ls_EM_NO",
                        e.target.value,
                        filters["ls_EM_NO"]
                          ? filters["ls_EM_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ls_EM_DS"] ? filters["ls_EM_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ls_EM_DS",
                        e.target.value,
                        filters["ls_EM_DS"]
                          ? filters["ls_EM_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_CC_NM"] ? filters["em_CC_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_CC_NM",
                        e.target.value,
                        filters["em_CC_NM"]
                          ? filters["em_CC_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["em_DE_PT"] ? filters["em_DE_PT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_DE_PT",
                        e.target.value,
                        filters["em_DE_PT"]
                          ? filters["em_DE_PT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["em_EM_DS"] ? filters["em_EM_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "em_EM_DS",
                        e.target.value,
                        filters["em_EM_DS"]
                          ? filters["em_EM_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>        
                <td>
                  <input
                    type="text"
                    value={filters["ls_AB_DY"] ? filters["ls_AB_DY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ls_AB_DY",
                        e.target.value,
                        filters["ls_AB_DY"]
                          ? filters["ls_AB_DY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ls_TO_SA"] ? filters["ls_TO_SA"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ls_TO_SA",
                        e.target.value,
                        filters["ls_TO_SA"]
                          ? filters["ls_TO_SA"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={{display:"none"}}>UK</th>
                <th style={tableHeaderStyle}>Employee No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Department</th>
                <th style={tableHeaderStyle}>Designation</th>
                <th style={tableHeaderStyle}>Absent Days</th>
                <th style={tableHeaderStyle}>Total Salary</th>      
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.lsUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.lsUkNo}
                        checked={selectedRows.includes(rowData.lsUkNo)}
                        onChange={() => handleRowSelect(rowData.lsUkNo)}
                      />
                    </td>
                    <td style={{display:"none"}}>{rowData.lsUkNo}</td>
                    <td style={tableCellStyle}>{rowData.ls_EM_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{rowData.em_CC_NM}</td>
                    <td style={tableCellStyle}>{rowData.em_DE_PT}</td>
                    <td style={tableCellStyle}>{rowData.em_EM_DS}</td>
                    <td style={tableCellStyle}>{rowData.ls_AB_DY}</td>
                    <td style={tableCellStyle}>{rowData.ls_TO_SA}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

      </div>

      <LeaveSettlementDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        em_EM_DS={em_EM_DS}
        setem_EM_DS={setem_EM_DS}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedemEMNO={SelectedemEMNO}
        handleConfirm={handleConfirm}
  
      />
    </div>
  );
};

export default LeaveSettlementMain;
