import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import Costcenter  from "views/common/CostCentreDrpDwn";
import ClassCode from "views/common/ClassCode";
import ItemPopup from "../Popups/ItemPopup";
import CustomerPopup from "../Popups/CustomerPopup";
import SupervisorPopup from "../Popups/SupervisorPopup";
import PlannerPopup from "../Popups/SupervisorPopup";
export default function WorkOrderEntryDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData2,
  wd_WO_CO,
  wd_WO_TY,
  wd_CO_CT,  
  wd_WO_NO,
  setwd_CO_CT,
  wd_WO_ST,
  setwd_WO_ST,
  wd_PR_TY,
  setwd_PR_TY,
  wd_CL_01,
  setwd_CL_01,
  wd_CL_02,
  setwd_CL_02,
  wd_CL_03,
  setwd_CL_03,
  wd_CL_04,
  setwd_CL_04,
  wd_CL_05,
  setwd_CL_05,
  itemdesc,
  setitemdesc,
  wd_AT_PL,
  setwd_AT_PL,
  wd_AT_RT,
  setwd_AT_RT,
}) {
  const {
    wdUkNo,
    wd_TR_UM,
    wd_IT_NM,
    wd_OR_DT,
    wd_WO_QT,
    wd_RQ_DT,
    wd_ST_DT,
    wd_WT_QT,
    wd_SR_QT,
    wd_CN_QT,
    wd_CU_NO,
    wd_PL_NO,
    wd_CO_QT,
    wd_IN_ST,
    wd_SU_NO,
    IT_DES1,
    wd_RL_NO,
  } = data;
    
  const [activeTab, setActiveTab] = useState(0);

  // DROPDOWNS
  const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
  const [wd_WO_STOptions, setwd_WO_STOptions] = useState([]);
  const [wd_PR_TYOptions, setwd_PR_TYOptions] = useState([]);
  const [wd_CL_01Options, setwd_CL_01Options] = useState([]);
  const [wd_CL_02Options, setwd_CL_02Options] = useState([]);
  const [wd_CL_03Options, setwd_CL_03Options] = useState([]);
  const [wd_CL_04Options, setwd_CL_04Options] = useState([]);
  const [wd_CL_05Options, setwd_CL_05Options] = useState([]);


// cost centre dropdown
const { fetchCostCenter } = Costcenter();
const [wd_CO_CTOptions, setwd_CO_CTOptions] = useState([]);
const onchangeDropDownFormData1 = (name, value) => {
  setFormData({ ...FormData, [name]: value });
  setwd_CO_CT(value);
};
const fetchCostCentre = async () => {
  const CostOptions = await fetchCostCenter();
  setwd_CO_CTOptions(CostOptions);
};
// class code

const onchangeDropDownFormData3 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setwd_PR_TY(value);
};

const onchangeDropDownFormData4 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setwd_CL_01(value);
};
const onchangeDropDownFormData5 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setwd_CL_02(value);
};

const onchangeDropDownFormData6 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setwd_CL_03(value);
};
const onchangeDropDownFormData7 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setwd_CL_04(value);
};
const onchangeDropDownFormData8 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setwd_CL_05(value);
};
const onchangeDropDownFormData9 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setwd_AT_PL(value);
};

const onchangeDropDownFormData10 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setwd_AT_RT(value);
};
  useEffect(() => {
    fetchCostCentre();
    fetchDropdown1();
    fetchDropdown2();
    fetchDropdown3();
    fetchDropdown4();
    fetchDropdown5();
    fetchDropdown6();
    fetchDropdown7();
  }, []);


  const fetchDropdown1 = async () => {
    const Options = await fetchClassCodeDS("WO/ST");
    setwd_WO_STOptions(Options);
  };

  const fetchDropdown2 = async () => {
    const Options = await fetchClassCodeDS("WO/PR");
    setwd_PR_TYOptions(Options);
  };

  const fetchDropdown3 = async () => {
    const Options = await fetchClassCodeDS("IN/M1");
    setwd_CL_01Options(Options);
  };

  const fetchDropdown4 = async () => {
    const Options = await fetchClassCodeDS("IN/M2");
    setwd_CL_02Options(Options);
  };

  const fetchDropdown5 = async () => {
    const Options = await fetchClassCodeDS("IN/M3");
    setwd_CL_03Options(Options);
  };

  const fetchDropdown6 = async () => {
    const Options = await fetchClassCodeDS("IN/M4");
    setwd_CL_04Options(Options);
  };

  const fetchDropdown7 = async () => {
    const Options = await fetchClassCodeDS("IN/M5");
    setwd_CL_05Options(Options);
  };

const isUpdateMode = Boolean(wdUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [itempopupVisible, setitempopupVisible] = useState(false);
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [SupervisorPopupVisible, setSupervisorPopupVisible] = useState(false);
  const [PlannerPopupVisible, setPlannerPopupVisible] = useState(false);

  const openItemPopup = () => {
    setitempopupVisible(true);
  };
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const openSupervisorPopup = () => {
    setSupervisorPopupVisible(true);
  };
  const openPlannerPopup = () => {
    setPlannerPopupVisible(true);
  };

  const handleDataSelect = async(Result) => {
    setitemdesc(Result.im_IT_DS)
    onchangeFormData("wd_IT_NM", Result.im_IT_NM);
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
        "im_IT_NM":Result.im_IT_NM
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataItMt = response.data.Result;
      if (dataItMt ) {
        onchangeFormData("wd_TR_UM", dataItMt.im_PR_UM);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setitempopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect1 = async(data) => {
    onchangeFormData("wd_CU_NO", data.abAdNo);
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect2 = async(data) => {
    onchangeFormData("wd_SU_NO", data.abAdNo);
    setSupervisorPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect3 = async(data) => {
    onchangeFormData("wd_PL_NO", data.abAdNo);
    setPlannerPopupVisible(false);
    setShowSearchIcon(false);
  };

  const [showSearchIcon, setShowSearchIcon] = useState("");
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={wdUkNo ? "Revise WO Entry" : "Add WO Entry"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="General">
            {/* tab1 general */}
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
              <div style={{display:"none"}}>
                  <label htmlFor="wdUkNo" className="font-bold">
                    UK_NO
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="wdUkNo"
                      name="wdUkNo"
                      value={wdUkNo}
                    />
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_WO_NO" className="font-bold">
                    Order No
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="wd_WO_NO"
                      name="wd_WO_NO"
                      value={wd_WO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_WO_TY" className="font-bold">
                    Type
                  </label>
                  <InputText disabled
                    id="wd_WO_TY"
                    name="wd_WO_TY"
                    value={wd_WO_TY}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_WO_CO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="wd_WO_CO"
                    name="wd_WO_CO"
                    value={wd_WO_CO}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_IT_NM" className="font-bold">
                    Item Number
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText
                    id="wd_IT_NM"
                    name="wd_IT_NM"
                    value={itemdesc}
                    onChange={(e) => onchangedata(e)}
                  />
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openItemPopup}
                  ></i>
                  </div>
                    <InputText
                    id="wd_IT_NM"
                    name="wd_IT_NM"
                    value={wd_IT_NM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_CO_CT" className="font-bold">
                    Cost Center
                  </label>
                  <Dropdown
                    name="wd_CO_CT"
                    value={wd_CO_CT}
                    options={wd_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormData1("wd_CO_CT", e.value)}
                    placeholder="Select Cost Centre"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_OR_DT" className="font-bold">
                    Order Date
                  </label>
                  <Calendar
                    id="wd_OR_DT"
                    name="wd_OR_DT"
                    value={moment(wd_OR_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "wd_OR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    showIcon
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_WO_QT" className="font-bold">
                   Order Quantity
                  </label>
                  <InputText
                    id="wd_WO_QT"
                    name="wd_WO_QT"
                    value={wd_WO_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_TR_UM" className="font-bold">
                  UM
                  </label>
                  <InputText
                    id="wd_TR_UM"
                    name="wd_TR_UM"
                    value={wd_TR_UM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_RQ_DT" className="font-bold">
                    Requested Date
                  </label>
                  <Calendar
                    id="wd_RQ_DT"
                    name="wd_RQ_DT"
                    value={moment(wd_RQ_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "wd_RQ_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    showIcon
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_CO_QT" className="font-bold">
                    Completed Qty
                  </label>
                  <InputText
                    id="wd_CO_QT"
                    name="wd_CO_QT"
                    value={wd_CO_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_ST_DT" className="font-bold">
                   Start Date
                  </label>
                  <Calendar
                    id="wd_ST_DT"
                    name="wd_ST_DT"
                    value={moment(wd_ST_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "wd_ST_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    showIcon
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_WT_QT" className="font-bold">
                    Sec.Quantity
                  </label>
                  <InputText
                    id="wd_WT_QT"
                    name="wd_WT_QT"
                    value={wd_WT_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_SR_QT" className="font-bold">
                   Scrapped Qty
                  </label>
                  <InputText
                    id="wd_SR_QT"
                    name="wd_SR_QT"
                    value={wd_SR_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_WO_ST" className="font-bold">
                   Status
                  </label>
                  <Dropdown
                    name="wd_WO_ST"
                    value={wd_WO_ST}
                    options={wd_WO_STOptions}
                    onChange={(e) => onchangeDropDownFormData2("wd_WO_ST", e.value)}
                    placeholder="Select Status"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_CN_QT" className="font-bold">
                   Cancelled Qty
                  </label>
                  <InputText
                    id="wd_CN_QT"
                    name="wd_CN_QT"
                    value={wd_CN_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_AT_PL" className="font-bold">
                  Attach Part List
                  </label>
                  {/* <InputText
                    id="wd_AT_PL"
                    name="wd_AT_PL"
                    value={wd_AT_PL}
                    onChange={(e) => onchangedata(e)}
                  /> */}
                   <Dropdown
                      id="wd_AT_PL"
                      name="wd_AT_PL"
                      value={wd_AT_PL}
                      options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                      ]}
                      onChange={(e) => onchangeDropDownFormData9("wd_AT_PL", e.value)}
                      />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_PR_TY" className="font-bold">
                    Priority
                  </label>
                  <Dropdown
                    name="wd_PR_TY"
                    value={wd_PR_TY}
                    options={wd_PR_TYOptions}
                    onChange={(e) => onchangeDropDownFormData3("wd_PR_TY", e.value)}
                    placeholder="Select Priority"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_AT_RT" className="font-bold">
                    Attach Routing
                  </label>
                  {/* <InputText
                    id="wd_AT_RT"
                    name="wd_AT_RT"
                    value={wd_AT_RT}
                    onChange={(e) => onchangedata(e)}
                  /> */}
                  <Dropdown
                      id="wd_AT_RT"
                      name="wd_AT_RT"
                      value={wd_AT_RT}
                      options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                      ]}
                      onChange={(e) => onchangeDropDownFormData10("wd_AT_RT", e.value)}
                      />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_IN_ST" className="font-bold">
                  Instruction
                  </label>
                  <InputText
                    id="wd_IN_ST"
                    name="wd_IN_ST"
                    value={wd_IN_ST}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                </div>
                </div>
          </TabPanel>
          {/* tab2 */}
          <TabPanel header="Additional Information">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="wd_CU_NO" className="font-bold">
                Customer 
                </label>
                <div style={{display:"flex"}}>
                  <InputText
                    id="wd_CU_NO"
                    name="wd_CU_NO"
                    value={wd_CU_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                 <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openCustomerPopup}
                  ></i>
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_CL_01" className="font-bold">
                 Class Code 1
                  </label>
                  <Dropdown
                    name="wd_CL_01"
                    value={wd_CL_01}
                    options={wd_CL_01Options}
                    onChange={(e) => onchangeDropDownFormData4("wd_CL_01", e.value)}
                    placeholder="Select Class Code 1"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_SU_NO" className="font-bold">
                 Supervisor
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="wd_SU_NO"
                      name="wd_SU_NO"
                      value={wd_SU_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                     <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openSupervisorPopup}
                  ></i>
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_CL_02" className="font-bold">
                  Class Code 2
                  </label>
                  <Dropdown
                    name="wd_CL_02"
                    value={wd_CL_02}
                    options={wd_CL_02Options}
                    onChange={(e) => onchangeDropDownFormData5("wd_CL_02", e.value)}
                    placeholder="Select Acquisition"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_PL_NO" className="font-bold">
                   Planner
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText
                    id="wd_PL_NO"
                    name="wd_PL_NO"
                    value={wd_PL_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                   <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openPlannerPopup}
                  ></i>
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_CL_03" className="font-bold">
                 Class Code 3
                  </label>
                  <Dropdown
                    name="wd_CL_03"
                    value={wd_CL_03}
                    options={wd_CL_03Options}
                    onChange={(e) => onchangeDropDownFormData6("wd_CL_03", e.value)}
                    placeholder="Select Class Code 3 "
                  />
                </div>
                {/* <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_RL_NO" className="font-bold">
                  Related Order
                  </label>
                  <InputText
                    id="wd_RL_NO"
                    name="wd_RL_NO"
                    value={wd_RL_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_RL_NO" className="font-bold">
                  Related Type
                  </label>
                  <InputText
                    id="wd_RL_NO"
                    name="wd_RL_NO"
                    value={wd_RL_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_RL_NO" className="font-bold">
                  Related Company
                  </label>
                  <InputText
                    id="wd_RL_NO" 
                    name="wd_RL_NO"
                    value={wd_RL_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div> */}
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_CL_04" className="font-bold">
                   Class Code 4
                  </label>
                  <Dropdown
                    name="wd_CL_04"
                    value={wd_CL_04}
                    options={wd_CL_04Options}
                    onChange={(e) => onchangeDropDownFormData7("wd_CL_04", e.value)}
                    placeholder="Select Class Code 4"
                  />
                  
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="IT_DES1" className="font-bold">
                 Alt. Description
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="IT_DES1"
                      name="IT_DES1"
                      value={IT_DES1}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wd_CL_05" className="font-bold">
                  Class Code 5
                  </label>
                  <Dropdown
                    name="wd_CL_05"
                    value={wd_CL_05}
                    options={wd_CL_05Options}
                    onChange={(e) => onchangeDropDownFormData8("wd_CL_05", e.value)}
                    placeholder="Select Class Code 5"
                  />
                </div>
                </div>
              </div>
          </TabPanel>
      </TabView>
      </div>    
      <ItemPopup
        visible={itempopupVisible}
        onClose={() => setitempopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
        <CustomerPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
        <SupervisorPopup
        visible={SupervisorPopupVisible}
        onClose={() => setSupervisorPopupVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
      />
        <PlannerPopup
        visible={PlannerPopupVisible}
        onClose={() => setPlannerPopupVisible(false)}
        onDataSelect={handleDataSelect3}
        formData={data}
      />
    </Dialog>
  );
}
