import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import LeadQualificationDialog from "./LeadQualificationDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import '../../../components/ListStyle/ListStyle.css';
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  sq_LD_TH: "",
  sq_QA_TY: "",
  sq_QA_DS: "",
  sqQfNm: "",
  sq_OP_TH: "",
  sqSqNo: "",
  sq_QA_WT: "",
};

const initialFilters = {
  sq_LD_TH: { value: "", operation: "" },
  sq_QA_TY: { value: "", operation: "" },
  sq_QA_DS: { value: "", operation: "" },
  sqQfNm: { value: "", operation: "" },
  sq_OP_TH: { value: "", operation: "" },
  sqSqNo: { value: "", operation: "" },
  sq_QA_WT: { value: "", operation: "" },
};
const LeadQualificationMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const [LeadTableData1, setLeadTableData1] = useState([{}]);
  const [LeadTableData2, setLeadTableData2] = useState([{}]);

  const [sqQfNm, setsqQfNm] = useState([]);
  const [sq_UK_NO, setsq_UK_NO] = useState([]);
  const [sq_LD_TH, setsq_LD_TH] = useState([]);
  const [sq_OP_TH, setsq_OP_TH] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAddButtonClick = () => {
    setLeadTableData1([{}]);
    setLeadTableData2([{}]);
    setFormData(initialValue);
    setsq_UK_NO(null);
    setsqQfNm(null);
    setsq_LD_TH(null);
    setsq_OP_TH(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setsq_UK_NO(null);
    setsqQfNm(null);
    setsq_LD_TH(null);
    setsq_OP_TH(null);
    setOpen(false);
  };

  const addLeadTable1Row = () => {
    setLeadTableData1([...LeadTableData1, {}]);
  };

  const deleteLeadTable1Row = () => {
    if (LeadTableData1.length > 0) {
      const updatedData = [...LeadTableData1];
      updatedData.pop();
      setLeadTableData1(updatedData);
    }
  };

  const addLeadTable2Row = () => {
    setLeadTableData2([...LeadTableData2, {}]);
  };

  const deleteLeadTable2Row = () => {
    if (LeadTableData2.length > 0) {
      const updatedData = [...LeadTableData2];
      updatedData.pop();
      setLeadTableData2(updatedData);
    }
  };

  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/LeadQualification/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const LeadQualificationData = response.data;
          delete LeadQualificationData.sq_UK_NO;
          setFormData(LeadQualificationData);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const url = "http://localhost:8182/api/le_qu/getAlLLead_Qualification";

  useEffect(() => {
    handleSearch();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/LeadQualification/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/LeadQualification/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const LeadQualificationData = response.data;
        setLeadTableData1(LeadQualificationData.leadQualifications);
        setLeadTableData2(LeadQualificationData.qualificationAnswers);
        setFormData(LeadQualificationData.leadQualifications[0]);
        // setFormData(LeadQualificationData.qualificationAnswers[0]);
        setsq_UK_NO(response.data.sq_UK_NO);
        setsqQfNm(response.data.sqQfNm);
        setsq_LD_TH(response.data.sq_LD_TH);
        setsq_OP_TH(response.data.sq_OP_TH);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFormSubmit = () => {
    // if (sq_UK_NO !=='') {
      if (FormData.sq_UK_NO) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/LeadQualification/Update`;

      if (confirm) {
        const table = document.getElementById("LeadTable1");
        const totalRowCount = table.tBodies[0].rows.length;

        const Qname = document.getElementById("sqQfNm");
        const LD_TH = document.getElementById("sq_LD_TH");
        const OP_TH = document.getElementById("sq_OP_TH");

        const leadQualifications = [];
        for (let i = 0; i < totalRowCount; i++) {
          const SQ_UK = document.getElementById("sq_UK_NO_" + i);
          const SQ_NO = document.getElementById("sqSqNo_" + i);
          const QA_TY = document.getElementById("sq_QA_TY_" + i);
          const QA_DS = document.getElementById("sq_QA_DS_" + i);
          const QA_WT = document.getElementById("sq_QA_WT_" + i);

          const Qualification = {
            sq_UK_NO: SQ_UK.value,
            sqSqNo: SQ_NO.value,
            sq_QA_TY: QA_TY.value,
            sq_QA_DS: QA_DS.value,
            sq_QA_WT: QA_WT.value,
            sqQfNm: Qname.value,
            sq_LD_TH: LD_TH.value,
            sq_OP_TH: OP_TH.value,
            sq_CO_NO: company,
          };
          leadQualifications.push(Qualification);
        }

        const table1 = document.getElementById("LeadTable2");
        const totalRowCount1 = table1.tBodies[0].rows.length;

        const qualificationAnswers = [];
        for (let i = 0; i < totalRowCount1; i++) {
          const UK_NO = document.getElementById("sa_UK_NO_" + i);
          const AN_SQ = document.getElementById("sa_AN_SQ_" + i);
          const QA_AN = document.getElementById("sa_QA_AN_" + i);
          const WE_PE = document.getElementById("sa_WE_PE_" + i);

          const Answers = {
            sa_UK_NO: UK_NO.value,
            sa_AN_SQ: AN_SQ.value,
            sa_QA_AN: QA_AN.value,
            sa_WE_PE: WE_PE.value,
            sa_CO_NO: company,
          };
          qualificationAnswers.push(Answers);
        }
        const requestBody = {
          leadQualifications,
          qualificationAnswers,
          sqQfNm: Qname.value,
          sq_LD_TH: LD_TH.value,
          sq_OP_TH: OP_TH.value,

          // leadQualifications: leadEdit,
          // qualificationAnswers: qualificationEdit,
        };

        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      const table = document.getElementById("LeadTable1");
      const totalRowCount = table.tBodies[0].rows.length;
      const Qname = document.getElementById("sqQfNm");
      const LD_TH = document.getElementById("sq_LD_TH");
      const OP_TH = document.getElementById("sq_OP_TH");
      const leadQualifications = [];
      for (let i = 0; i < totalRowCount; i++) {
        const SQ_NO = document.getElementById("sqSqNo_" + i);
        const QA_TY = document.getElementById("sq_QA_TY_" + i);
        const QA_DS = document.getElementById("sq_QA_DS_" + i);
        const QA_WT = document.getElementById("sq_QA_WT_" + i);
        const Qualification = {
          sqSqNo: SQ_NO.value,
          sq_QA_TY: QA_TY.value,
          sq_QA_DS: QA_DS.value,
          sqQfNm: Qname.value,
          sq_QA_WT: QA_WT.value,
          sq_LD_TH: LD_TH.value,
          sq_OP_TH: OP_TH.value,
          sq_CO_NO: company,
        };
        leadQualifications.push(Qualification);
      }
      const table1 = document.getElementById("LeadTable2");
      const totalRowCount1 = table1.tBodies[0].rows.length;

      const qualificationAnswers = [];
      for (let i = 0; i < totalRowCount1; i++) {
        const AN_SQ = document.getElementById("sa_AN_SQ_" + i);
        const QA_AN = document.getElementById("sa_QA_AN_" + i);
        const WE_PE = document.getElementById("sa_WE_PE_" + i);

        const Answers = {
          sa_AN_SQ: AN_SQ.value,
          sa_QA_AN: QA_AN.value,
          sa_WE_PE: WE_PE.value,
          sa_CO_NO: company,
        };
        qualificationAnswers.push(Answers);
      }
      const requestBody = {
        sqQfNm: Qname.value,
        leadQualifications,
        qualificationAnswers,
      };
      axios
        .post(`${domain}/api/LeadQualification/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          //<handleResponse/>
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
    }
  };

  const handleResponse = (data) => {
    const { warnings = [], errors = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errors.length > 0) {
      const errorMessage = errors.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
      });
    }

    if (errors.length === 0) {
      handleClose();
      handleSearch();
    }
  };
  const leftToolbarTemplate = (
    <React.Fragment>
    <Tooltip target=".update-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "red !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Update
      </div>
    </Tooltip>
    <Button
      style={buttonStyles}
      icon="pi pi-check"
      outlined
      className="mr-2 update-button"
      onClick={handleUpdate}
      disabled={!isRecordSelected}
    ></Button>
    {/* <Tooltip target=".search-button" position="bottom" mouseTrack>
      <div
        style={{
          backgroundColor: "transparent !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Search
      </div>
    </Tooltip>
    <Button
      style={searchButtonStyles}
      icon="pi pi-search"
      outlined
      className="mr-2 search-button"
    ></Button> */}
    <Tooltip target=".add-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "white !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Add
      </div>
    </Tooltip>
    <Button
      style={addButtonStyles}
      icon="pi pi-plus"
      outlined
      className="mr-2 add-button"
      onClick={handleAddButtonClick}
    ></Button>
    <Tooltip target=".copy-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "white !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Copy
      </div>
    </Tooltip>
    <Button
      style={copyButtonStyles}
      icon="pi pi-copy"
      outlined
      className="mr-2 copy-button"
      onClick={handleCopyData}
    ></Button>
  </React.Fragment>
  );

  const tableHeaderStyleLOP = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#e1e1e1",
  };

  const tableHeaderStyleCheck = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width: "0%",
  };
  const tableCellStyleW = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "right",
  };
  const tableCellStyleLO = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sqQfNm"] ? filters["sqQfNm"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sqQfNm",
                        e.target.value,
                        filters["sqQfNm"]
                          ? filters["sqQfNm"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sq_LD_TH"] ? filters["sq_LD_TH"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sq_LD_TH",
                        e.target.value,
                        filters["sq_LD_TH"]
                          ? filters["sq_LD_TH"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sq_OP_TH"] ? filters["sq_OP_TH"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sq_OP_TH",
                        e.target.value,
                        filters["sq_OP_TH"]
                          ? filters["sq_OP_TH"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sqSqNo"] ? filters["sqSqNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sqSqNo",
                        e.target.value,
                        filters["sqSqNo"]
                          ? filters["sqSqNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sq_QA_TY"] ? filters["sq_QA_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sq_QA_TY",
                        e.target.value,
                        filters["sq_QA_TY"]
                          ? filters["sq_QA_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sq_QA_DS"] ? filters["sq_QA_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sq_QA_DS",
                        e.target.value,
                        filters["sq_QA_DS"]
                          ? filters["sq_QA_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sq_QA_WT"] ? filters["sq_QA_WT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sq_QA_WT",
                        e.target.value,
                        filters["sq_QA_WT"]
                          ? filters["sq_QA_WT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyleCheck}></th>
                <th style={tableHeaderStyle}>Qualification Name</th>
                <th style={tableHeaderStyleLOP}>Lead Threshhold %</th>
                <th style={tableHeaderStyleLOP}>Opportunity Threshhold %</th>
                <th style={tableHeaderStyleLOP}>Sequence</th>
                <th style={tableHeaderStyle}>Question Type</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Weightage</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.sq_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        type="checkbox"
                        value={rowData.sq_UK_NO}
                        checked={selectedRows.includes(rowData.sq_UK_NO)}
                        onChange={() => handleRowSelect(rowData.sq_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.sqQfNm}</td>
                    <td style={tableCellStyleLO}>{rowData.sq_LD_TH}</td>
                    <td style={tableCellStyleLO}>{rowData.sq_OP_TH}</td>
                    <td style={tableCellStyleLO}>{rowData.sqSqNo}</td>
                    <td style={tableCellStyle}>{rowData.sq_QA_TY}</td>
                    <td style={tableCellStyle}>{rowData.sq_QA_DS}</td>
                    <td style={tableCellStyleW}>{rowData.sq_QA_WT}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <LeadQualificationDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        LeadTableData1={LeadTableData1}
        LeadTableData2={LeadTableData2}
        setLeadTableData2={setLeadTableData2}
        addLeadTable2Row={addLeadTable2Row}
        deleteLeadTable2Row={deleteLeadTable2Row}
        addLeadTable1Row={addLeadTable1Row}
        deleteLeadTable1Row={deleteLeadTable1Row}
        sq_UK_NO={sq_UK_NO}
        sqQfNm={sqQfNm}
        sq_LD_TH={sq_LD_TH}
        sq_OP_TH={sq_OP_TH}
      />
    </div>
  );
};

export default LeadQualificationMain;
