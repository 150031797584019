import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import RentalInvoiceDialog from "./RentalInvoiceDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  rmUkNo:"",
  rm_DO_NO:"",
  rm_DO_TY:"",
  rm_DO_CO:"",
  rm_IN_DT:"",
  rm_GL_DT:"",
  rm_CN_NO:"",
  rm_CN_TY:"",
  rm_CO_NO:"",
  rm_CU_NO:"",
  cus_NAME:"",
  rm_FR_DT:"",
  rm_TO_DT:"",             
  rm_EX_AM:"",
  rm_FR_AM:"",
  rm_IN_DT:moment(moment().toDate()).format("YYYYMMDD"),
  rm_GL_DT:moment(moment().toDate()).format("YYYYMMDD"),
 
};

const initialFilters = {
  rm_DO_NO:{ value: "", operation: "" },
  rm_DO_TY:{ value: "", operation: "" },
  rm_DO_CO:{ value: "", operation: "" },
  rm_IN_DT:{ value: "", operation: "" },
  rm_GL_DT:{ value: "", operation: "" },
  rm_CN_NO:{ value: "", operation: "" },
  rm_CN_TY:{ value: "", operation: "" },
  rm_CO_NO:{ value: "", operation: "" },
  rm_CU_NO:{ value: "", operation: "" },
  cus_NAME:{ value: "", operation: "" },
  rm_FR_DT:{ value: "", operation: "" },
  rm_TO_DT:{ value: "", operation: "" },
  rm_EX_AM:{ value: "", operation: "" },
  rm_FR_AM:{ value: "", operation: "" },
};

const WorkCentreMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,postStyles
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // drodpdowns
  const [rc_CO_CT, setrc_CO_CT] = useState("");
  const [rm_TR_CR, setrm_TR_CR ] = useState("");
  const [rm_PY_TM, setrm_PY_TM] = useState("");
  const [rc_BL_FR, setrc_BL_FR ] = useState("");
  const [rm_TX_AP, setrm_TX_AP ] = useState("");
  const [rm_ST_AT, setrm_ST_AT] = useState("");
  const [ed_LN_TY, seted_LN_TY] = useState("");
  const [rm_TR_UM, setrm_TR_UM] = useState("");
  const [rm_EX_RT, setrm_EX_RT] = useState("");
  const [bd_DO_TY, setbd_DO_TY] = useState("");
  const [rm_DO_NO, setrm_DO_NO] = useState("");

  const onchangegldate = (Event) => {
    const { value, name } = Event.target;
     setFormData({ ...FormData, [name]: value });
     onChnageExchangeRate(rm_TR_CR,value);
  };

  const CurrencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrm_TR_CR(value);
    onChnageExchangeRate(value, FormData.rm_IN_DT);
  };


  function onChnageExchangeRate(frCr, ReceiveDate) {
    const exData = {
      "cr_TO_CR":  document.getElementById("rm_BS_CR").value, 
      "cr_FR_CR": frCr,
      "cr_EF_DT": ReceiveDate
    }
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("rm_EX_RT").value = dataExRt.cr_CO_MR;
          setrm_EX_RT(dataExRt.cr_CO_MR);
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });

  }
  
// Advancesearch
  const [SelectedemEMNO, setSelectedemEMNO ] = useState("");
  const [SelectedemEMNOE, setSelectedemEMNOE ] = useState("");
  const [SelectedemEMNO1, setSelectedemEMNO1 ] = useState("");
  const [selectedAbAdNM, setSelectedAbAdNM ] = useState("");
  const [rm_IT_NM, setrm_IT_NM ] = useState("");
  const [SelectedDesc, setselecteditemDES ] = useState("");

// Next No
  const [er_EX_NO, seter_EX_NO ] = useState("");
  const [rc_SH_TO, setrc_SH_TO ] = useState("");
  const [rc_RL_TY, setrc_RL_TY ] = useState("");
  const [rm_CN_TY, setrm_CN_TY ] = useState("");
  const [rm_CO_NO, setrm_CO_NO ] = useState("");
  const [price_AMOUNT , setprice_AMOUNT] = useState("");
  const [discount_AMOUNT , setdiscount_AMOUNT] = useState("");
  const [net_AMOUNT , setnet_AMOUNT] = useState("");
  const [tax_AMOUNT , settax_AMOUNT] = useState("");
  const [total , settotal] = useState("");
  const [fr_PRICE_AMOUNT , setfr_PRICE_AMOUNT] = useState("");
  const [fr_DISCOUNT_AMOUNT , setfr_DISCOUNT_AMOUNT] = useState("");
  const [fr_NET_AMOUNT , setfr_NET_AMOUNT] = useState("");
  const [fr_TAX_AMOUNT , setfr_TAX_AMOUNT] = useState("");
  const [fr_TOTAL , setfr_TOTAL] = useState("");





  const [RentalInvoiceTableData, setRentalInvoiceTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleAddButtonClick = () => {
    setRentalInvoiceTableData([{}]);
    setFormData(initialValue);
    setrm_TR_CR(null);
    setrm_ST_AT(null);
    setrm_PY_TM(null);
    setrm_TX_AP(null);
    setrm_CN_TY(null);
    setrm_CO_NO(null);
    setSelectedemEMNO(null);
    setselecteditemDES(null);
    setprice_AMOUNT(null);
    setdiscount_AMOUNT(null);
    setnet_AMOUNT(null);
    settax_AMOUNT(null);
    settotal(null);
    setfr_PRICE_AMOUNT(null);
    setfr_DISCOUNT_AMOUNT(null);
    setfr_NET_AMOUNT(null);
    setfr_TAX_AMOUNT(null);
    setfr_TOTAL(null);
    setOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setRentalInvoiceTableData([{}]);
    setFormData(initialValue);
    setrm_TR_CR(null);
    setrm_ST_AT(null);
    setrm_PY_TM(null);
    setrm_TX_AP(null);
    setrm_CN_TY(null);
    setrm_CO_NO(null);
    setSelectedemEMNO(null);
    setselecteditemDES(null);
    setprice_AMOUNT(null);
    setdiscount_AMOUNT(null);
    setnet_AMOUNT(null);
    settax_AMOUNT(null);
    settotal(null);
    setfr_PRICE_AMOUNT(null);
    setfr_DISCOUNT_AMOUNT(null);
    setfr_NET_AMOUNT(null);
    setfr_TAX_AMOUNT(null);
    setfr_TOTAL(null);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };


  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [rm_DO_CO , setrm_DO_CO] = useState("");
  const [rm_DO_TY , setrm_DO_TY] = useState("");
  const [rm_BS_CR , setrm_BS_CR] = useState("");
  const [rc_RL_CO , setrc_RL_CO] = useState("");
  const [rc_LO_CN , setrc_LO_CN] = useState("");
  const [rm_TR_QT , setrm_TR_QT] = useState("");
  const [rm_EX_AM , setrm_EX_AM] = useState("");
  const [rm_RA_TE , setrm_RA_TE] = useState("");
  const [rm_DS_PE , setrm_DS_PE] = useState("");
  const [rm_DS_AM , setrm_DS_AM] = useState("");
  const [rm_TX_PE , setrm_TX_PE] = useState("");
  const [rm_TX_AM , setrm_TX_AM] = useState("");
  const [rm_FR_AM , setrm_FR_AM] = useState("");
  const [rm_FR_DS , setrm_FR_DS] = useState("");

  const handleDefault = async () => {
    setrm_DO_CO(`${company}`)
    setrc_RL_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "RM_DO_TY"){
              setrm_DO_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "rm_BS_CR"){
              setrm_BS_CR(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "RC_RL_TY"){
              setrc_RL_TY(dataDefault.versionIds[i].default_VALUE)
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/RentalInvoice/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handlePost = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }

    const requestBodyAPIInput = {
      "rmUkNo" : `${selectedRows[0]}`,
    }
    axios
    .post(`${domain}/api/RentalInvoice/PostRentalInvoice`, requestBodyAPIInput, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
    handleResponse(resp.data);
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };

  
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/RentalInvoice/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const WorkCentreData = response.data;
        setRentalInvoiceTableData(WorkCentreData.rentalInvoices);
        setFormData(WorkCentreData.rentalInvoices[0]);
        setrm_TR_CR(WorkCentreData.rentalInvoices[0].rm_TR_CR);
        setrm_ST_AT(WorkCentreData.rentalInvoices[0].rm_ST_AT);
        setrm_PY_TM(WorkCentreData.rentalInvoices[0].rm_PY_TM);
        setrm_TX_AP(WorkCentreData.rentalInvoices[0].rm_TX_AP);
        setrm_CN_TY(WorkCentreData.rentalInvoices[0].rm_CN_TY);
        setrm_CO_NO(WorkCentreData.rentalInvoices[0].rm_CO_NO);
        setSelectedemEMNO(WorkCentreData.rentalInvoices[0].cus_NAME);
        setselecteditemDES(WorkCentreData.rentalInvoices.cus_NAME);
        setprice_AMOUNT(WorkCentreData.matUnit);
        setdiscount_AMOUNT(WorkCentreData.matDisamt);
        setnet_AMOUNT(WorkCentreData.netAmt);
        settax_AMOUNT(WorkCentreData.matTaxamt);
        settotal(WorkCentreData.total);
        setfr_PRICE_AMOUNT(WorkCentreData.matForeAmt);
        setfr_DISCOUNT_AMOUNT(WorkCentreData.forematDisamt);
        setfr_NET_AMOUNT(WorkCentreData.forenetAmt);
        setfr_TAX_AMOUNT(WorkCentreData.foreTax);
        setfr_TOTAL(WorkCentreData.foretotal);
        setrm_IT_NM(WorkCentreData.rentalInvoices.rm_IT_NM)
        setselecteditemDES(WorkCentreData.rentalInvoices.item_DES)
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/RentalInvoice/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const WorkCentreData = response.data;
          delete WorkCentreData.rentalInvoices[0].rm_DO_NO;
          delete WorkCentreData.rentalInvoices[0].rmUkNo;
          setRentalInvoiceTableData(WorkCentreData.rentalInvoices);
        setFormData(WorkCentreData.rentalInvoices[0]);
        setrm_TR_CR(WorkCentreData.rentalInvoices[0].rm_TR_CR);
        setrm_ST_AT(WorkCentreData.rentalInvoices[0].rm_ST_AT);
        setrm_PY_TM(WorkCentreData.rentalInvoices[0].rm_PY_TM);
        setrm_TX_AP(WorkCentreData.rentalInvoices[0].rm_TX_AP);
        setrm_CN_TY(WorkCentreData.rentalInvoices[0].rm_CN_TY);
        setrm_CO_NO(WorkCentreData.rentalInvoices[0].rm_CO_NO);
        setSelectedemEMNO(WorkCentreData.rentalInvoices[0].cus_NAME);
        setselecteditemDES(WorkCentreData.rentalInvoices.cus_NAME);
        setprice_AMOUNT(WorkCentreData.matUnit);
        setdiscount_AMOUNT(WorkCentreData.matDisamt);
        setnet_AMOUNT(WorkCentreData.netAmt);
        settax_AMOUNT(WorkCentreData.matTaxamt);
        settotal(WorkCentreData.total);
        setfr_PRICE_AMOUNT(WorkCentreData.matForeAmt);
        setfr_DISCOUNT_AMOUNT(WorkCentreData.forematDisamt);
        setfr_NET_AMOUNT(WorkCentreData.forenetAmt);
        setfr_TAX_AMOUNT(WorkCentreData.foreTax);
        setfr_TOTAL(WorkCentreData.foretotal);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("operationtable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const rentalInvoices = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const details = {
        rmUkNo : document.getElementById("rmUkNo_" + i).value,
        rm_IT_NM : document.getElementById("rm_IT_NM_" + i).value,
        itemDES : document.getElementById("itemDES_" + i).value,
        rm_TR_QT : document.getElementById("rm_TR_QT_" + i).value,
        rm_TR_UM : table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
        rm_RA_TE : document.getElementById("rm_RA_TE_" + i).value,
        rm_EX_AM : document.getElementById("rm_EX_AM_" + i).value,
        rm_DS_PE : document.getElementById("rm_DS_PE_" + i).value,
        rm_DS_AM : document.getElementById("rm_DS_AM_" + i).value,
        rm_TX_AP : document.getElementById("rm_TX_AP_" + i).value,
        rm_TX_PE : document.getElementById("rm_TX_PE_" + i).value,
        rm_TX_AM : document.getElementById("rm_TX_AM_" + i).value,
        rm_FR_AM : document.getElementById("rm_FR_AM_" + i).value,
        rm_FR_DS : document.getElementById("rm_FR_DS_" + i).value,
        rm_FR_TX : document.getElementById("rm_FR_TX_" + i).value,
    };
    rentalInvoices.push(details);
  }
  requestBody = {
    rentalInvoices,
    rmUkNo   : FormData.rmUkNo,
    rm_DO_NO : document.getElementById("rm_DO_NO").value,
    rm_DO_TY : rm_DO_TY,
    rm_DO_CO : document.getElementById("rm_DO_CO").value,
    rm_CN_NO : document.getElementById("rm_CN_NO").value,
    rm_CN_TY : document.getElementById("rm_CN_TY").value,
    rm_CO_NO : document.getElementById("rm_CO_NO").value,
    rm_IN_DT : FormData.rm_IN_DT,
    rm_GL_DT : FormData.rm_GL_DT,
    rm_ST_AT : FormData.rm_ST_AT,
    rm_CU_NO : document.getElementById("rm_CU_NO").value,
    rm_PY_TM : FormData.rm_PY_TM,
    rm_TX_AP : FormData.rm_TX_AP,
    rm_TR_CR : FormData.rm_TR_CR,
    rm_EX_RT : document.getElementById("rm_EX_RT").value,
    rm_BS_CR : document.getElementById("rm_BS_CR").value,
    rm_FR_DT : FormData.rm_FR_DT,
    rm_TO_DT : FormData.rm_TO_DT,
    rm_RF_CE : document.getElementById("rm_RF_CE").value,
  };
}
const handleFormSubmit = () => {
  values();
  const orNo = document.getElementById("rm_DO_NO").value;
  const ukNo = FormData.rmUkNo;
  if (orNo) {
    if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/RentalInvoice/Update`;
  
      if (confirm) {
        axios.put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/RentalInvoice/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  } else {
      
      const OR_DT = FormData.rm_IN_DT;
      if (OR_DT) {
        const currentYear = OR_DT.toString();
        const firstHalf = currentYear.substring(0, 2);
        const secondHalf = currentYear.substring(2, 4);
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
        const parameters = `${rm_DO_TY}/${secondHalf}/1000/${firstHalf}`;
        const finalUrl1 = `${urlNextNumber}/${parameters}`;
        fetch(finalUrl1, {
          method: "GET",
          headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
        })
          .then((resp) => {
            if (!resp.ok) {
              throw new Error(`Request failed with status: ${resp.status}`);
            }
            return resp.json();
          })
          .then((resp) => {
            setrm_DO_NO(resp.dn_DO_NO);
            document.getElementById("rm_DO_NO").value = resp.dn_DO_NO;

            values();
            axios.post(`${domain}/api/RentalInvoice/Add`, requestBody, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error saving data:", error);
                });

          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }
  };


const handleConfirm =  async () => {
  const  Url = `${domain}/api/RentalInvoice/ConfirmRentalInvoice`
  const request = {
    "rm_DO_NO": FormData.rm_DO_NO,
    "rm_DO_TY" : rm_DO_TY,
    "rm_DO_CO" : document.getElementById("rm_DO_CO").value,
  }
  axios.post(Url, request, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    handleResponse(resp.data);
  })
  .catch((error) => {
    console.error("Error adding data:", error);
  });
};
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
      <Tooltip target=".post-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Post
      </div>
    </Tooltip>
      <Button 
        style={postStyles}
        icon="pi pi-send"
        outlined
        className="mr-2 post-button"
        onClick={handlePost}
        disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rm_DO_NO"] ? filters["rm_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rm_DO_NO",
                        e.target.value,
                        filters["rm_DO_NO"]
                          ? filters["rm_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rm_DO_TY"] ? filters["rm_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rm_DO_TY",
                        e.target.value,
                        filters["rm_DO_TY"] ? filters["rm_DO_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rm_DO_CO"] ? filters["rm_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rm_DO_CO",
                        e.target.value,
                        filters["rm_DO_CO"]
                          ? filters["rm_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rm_IN_DT"] ? filters["rm_IN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rm_IN_DT",
                        e.target.value,
                        filters["rm_IN_DT"] ? filters["rm_IN_DT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rm_GL_DT"] ? filters["rm_GL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rm_GL_DT",
                        e.target.value,
                        filters["rm_GL_DT"]
                          ? filters["rm_GL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["rm_CN_NO"] ? filters["rm_CN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rm_CN_NO",
                        e.target.value,
                        filters["rm_CN_NO"]
                          ? filters["rm_CN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rm_CN_TY"] ? filters["rm_CN_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rm_CN_TY",
                        e.target.value,
                        filters["rm_CN_TY"]
                          ? filters["rm_CN_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rm_CO_NO"] ? filters["rm_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rm_CO_NO",
                        e.target.value,
                        filters["rm_CO_NO"]
                          ? filters["rm_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rm_CU_NO"] ? filters["rm_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rm_CU_NO",
                        e.target.value,
                        filters["rm_CU_NO"]
                          ? filters["rm_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["cus_NAME"] ? filters["cus_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cus_NAME",
                        e.target.value,
                        filters["cus_NAME"]
                          ? filters["cus_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rm_FR_DT"] ? filters["rm_FR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rm_FR_DT",
                        e.target.value,
                        filters["rm_FR_DT"]
                          ? filters["rm_FR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rm_TO_DT"] ? filters["rm_TO_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rm_TO_DT",
                        e.target.value,
                        filters["rm_TO_DT"]
                          ? filters["rm_TO_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rm_EX_AM"] ? filters["rm_EX_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rm_EX_AM",
                        e.target.value,
                        filters["rm_EX_AM"]
                          ? filters["rm_EX_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["rm_FR_AM"] ? filters["rm_FR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rm_FR_AM",
                        e.target.value,
                        filters["rm_FR_AM"]
                          ? filters["rm_FR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
              
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Invoice No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>   
                <th style={tableHeaderStyle}>Invoice Date</th> 
                <th style={tableHeaderStyle}>GL Date</th> 
                <th style={tableHeaderStyle}>Contract No</th> 
                <th style={tableHeaderStyle}>Type</th> 
                <th style={tableHeaderStyle}>Company</th>  
                <th style={tableHeaderStyle}>Customer No</th> 
                <th style={tableHeaderStyle}>Name</th>  
                <th style={tableHeaderStyle}>From Date</th> 
                <th style={tableHeaderStyle}>To Date</th> 
                <th style={tableHeaderStyle}>Amount</th> 
                <th style={tableHeaderStyle}>Fore. Amount</th> 
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.rmUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.rmUkNo}
                        checked={selectedRows.includes(rowData.rmUkNo)}
                        onChange={() => handleRowSelect(rowData.rmUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.rm_DO_NO}</td>
                    <td style={tableCellStyle}>{rowData.rm_DO_TY}</td>
                    <td style={tableCellStyle}>{rowData.rm_DO_CO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.rm_IN_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.rm_GL_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.rm_CN_NO}</td>
                    <td style={tableCellStyle}>{rowData.rm_CN_TY}</td>   
                    <td style={tableCellStyle}>{rowData.rm_CO_NO}</td>   
                    <td style={tableCellStyle}>{rowData.rm_CU_NO}</td>   
                    <td style={tableCellStyle}>{rowData.cus_NAME}</td>   
                    <td style={tableCellStyle}>{formatDate(rowData.rm_FR_DT)}</td>   
                    <td style={tableCellStyle}>{formatDate(rowData.rm_TO_DT)}</td>   
                    <td style={tableCellStyle}>{rowData.rm_EX_AM}</td>   
                    <td style={tableCellStyle}>{rowData.rm_FR_AM}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <RentalInvoiceDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        RentalInvoiceTableData={RentalInvoiceTableData}
        setRentalInvoiceTableData={setRentalInvoiceTableData}
        CurrencyDropDown={CurrencyDropDown}
        onchangegldate={onchangegldate}
        rm_DO_CO={rm_DO_CO}
        rm_DO_TY={rm_DO_TY}
        rm_BS_CR={rm_BS_CR}
        rc_CO_CT={rc_CO_CT}
        setrc_CO_CT={setrc_CO_CT}
        rm_TR_CR={rm_TR_CR}
        setrm_TR_CR={setrm_TR_CR}
        rm_PY_TM={rm_PY_TM}
        setrm_PY_TM={setrm_PY_TM}
        rc_BL_FR={rc_BL_FR}
        setrc_BL_FR={setrc_BL_FR}
        rm_ST_AT={rm_ST_AT}
        setrm_ST_AT={setrm_ST_AT}
        ed_LN_TY={ed_LN_TY}
        seted_LN_TY={seted_LN_TY}
        rm_EX_RT={rm_EX_RT}
        setrm_EX_RT={setrm_EX_RT}
        bd_DO_TY={bd_DO_TY}
        setbd_DO_TY={setbd_DO_TY}
        rm_TX_AP={rm_TX_AP}
        setrm_TX_AP={setrm_TX_AP}
        rm_TR_UM={rm_TR_UM}
        setrm_TR_UM={setrm_TR_UM}
        rm_IT_NM={rm_IT_NM}
        setrm_IT_NM={setrm_IT_NM}
        SelectedDesc={SelectedDesc}
        setselecteditemDES={setselecteditemDES}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedemEMNOE={SelectedemEMNOE}
        setSelectedemEMNOE={setSelectedemEMNOE}
        SelectedemEMNO1={SelectedemEMNO1}
        setSelectedemEMNO1={setSelectedemEMNO1}
        selectedAbAdNM={selectedAbAdNM}
        setSelectedAbAdNM={setSelectedAbAdNM}
        rc_SH_TO={rc_SH_TO}
        setrc_SH_TO={setrc_SH_TO}
        rc_RL_TY={rc_RL_TY}
        setrc_RL_TY={setrc_RL_TY}
        rc_RL_CO={rc_RL_CO}
        setrc_RL_CO={setrc_RL_CO}
        rc_LO_CN={rc_LO_CN}
        setrc_LO_CN={setrc_LO_CN}
        rm_TR_QT={rm_TR_QT}
        setrm_TR_QT={setrm_TR_QT}
        rm_EX_AM={rm_EX_AM}
        setrm_EX_AM={setrm_EX_AM}
        rm_RA_TE={rm_RA_TE}
        setrm_RA_TE={setrm_RA_TE}
        rm_DS_PE={rm_DS_PE}
        setrm_DS_PE={setrm_DS_PE}
        rm_DS_AM={rm_DS_AM}
        setrm_DS_AM={setrm_DS_AM}
        rm_TX_PE={rm_TX_PE}
        setrm_TX_PE={setrm_TX_PE}
        rm_TX_AM={rm_TX_AM}
        setrm_TX_AM={setrm_TX_AM}
        rm_FR_AM={rm_FR_AM}
        setrm_FR_AM={setrm_FR_AM}
        rm_FR_DS={rm_FR_DS}
        setrm_FR_DS={setrm_FR_DS}
        rm_CO_NO={rm_CO_NO}
        setrm_CO_NO={setrm_CO_NO}
        setrm_CN_TY={setrm_CN_TY}
        rm_CN_TY={rm_CN_TY}
        price_AMOUNT={price_AMOUNT}
        setprice_AMOUNT={setprice_AMOUNT}
        discount_AMOUNT={discount_AMOUNT}
        setdiscount_AMOUNT={setdiscount_AMOUNT}
        setnet_AMOUNT={setnet_AMOUNT}
        net_AMOUNT={net_AMOUNT}
        settax_AMOUNT={settax_AMOUNT}
        tax_AMOUNT={tax_AMOUNT}
        settotal={settotal}
        total={total}
        fr_PRICE_AMOUNT={fr_PRICE_AMOUNT}
        setfr_PRICE_AMOUNT={setfr_PRICE_AMOUNT}
        fr_DISCOUNT_AMOUNT={fr_DISCOUNT_AMOUNT}
        setfr_DISCOUNT_AMOUNT={setfr_DISCOUNT_AMOUNT}
        fr_NET_AMOUNT={fr_NET_AMOUNT}
        setfr_NET_AMOUNT={setfr_NET_AMOUNT}
        fr_TAX_AMOUNT={fr_TAX_AMOUNT}
        setfr_TAX_AMOUNT={setfr_TAX_AMOUNT}
        fr_TOTAL={fr_TOTAL}
        setfr_TOTAL={setfr_TOTAL}
        handleConfirm={handleConfirm}
        handleResponse={handleResponse}
      />
    </div>
  );
};

export default WorkCentreMain;
