import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function PreferenceHierarchyDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  FormData,
  setFormData,
  ph_PF_TY,
  setph_PF_TY,
  ph_CO_NO,
}) {
  const {
    phUkNo,
    ph_CU_IT,
    ph_CU_IG,
    ph_CG_IT,
    ph_CG_IG,
    ph_AL_IT,
    ph_AL_IG,    
  } = data;
  const {borderbox,inboxstyle} = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [ph_PF_TYOptions, setph_PF_TYOptions] = useState([]);
//  type
  const onchangeDropDownFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setph_PF_TY(value);
  };


  useEffect(() => {
    fetchDropdownBT();

  }, []);

  const fetchDropdownBT = async () => {
    const Options = await fetchClassCodeDS("PF/TY");
    setph_PF_TYOptions(Options);
  };


const isUpdateMode = Boolean(phUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={phUkNo ? "Revise PreferenceHierarchy" : "Add PreferenceHierarchy"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
            <div style={inboxstyle} >
                <div className="formgrid grid">
                <div style={{display:"block"}}>
                    <label htmlFor="phUkNo" className="font-bold">
                      uk_no
                    </label>
                    <InputText disabled
                      id="phUkNo"
                      name="phUkNo"
                      defaultValue={phUkNo}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ph_PF_TY" className="font-bold">
                    Preference Type
                    </label>
                    <Dropdown
                    id="ph_PF_TY"
                    name="ph_PF_TY"
                    value={ph_PF_TY}
                    options={ph_PF_TYOptions}
                    onChange={(e) => onchangeDropDownFormData("ph_PF_TY", e.value)}
                    placeholder="Preference Type"
                  />
                  </div>
                  <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ph_CO_NO" className="font-bold">
                      Company
                    </label>
                    <InputText disabled
                      id="ph_CO_NO"
                      name="ph_CO_NO"
                      value={ph_CO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ph_CU_IT" className="font-bold">
                      Customer & Item
                    </label>
                    <InputText
                      id="ph_CU_IT"
                      name="ph_CU_IT"
                      value={ph_CU_IT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ph_CU_IG" className="font-bold">
                      Customer & Item Group
                    </label>
                    <InputText
                      id="ph_CU_IG"
                      name="ph_CU_IG"
                      value={ph_CU_IG}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ph_CG_IT" className="font-bold">
                      Customer Group & Item
                    </label>
                    <InputText
                      id="ph_CG_IT"
                      name="ph_CG_IT"
                      value={ph_CG_IT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ph_CG_IG" className="font-bold">
                      Customer Group & Item Group
                    </label>
                    <InputText
                      id="ph_CG_IG"
                      name="ph_CG_IG"
                      value={ph_CG_IG}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ph_AL_IT" className="font-bold">
                      Item
                    </label>
                    <InputText
                      id="ph_AL_IT"
                      name="ph_AL_IT"
                      value={ph_AL_IT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ph_AL_IG" className="font-bold">
                      Item Group
                    </label>
                    <InputText
                      id="ph_AL_IG"
                      name="ph_AL_IG"
                      value={ph_AL_IG}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                 
                </div>
            </div>
      </div>
    </Dialog>
  );
}
