import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import WorkOrderRoutingDialog from "./WorkOrderRoutingDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  wr_UK_NO:"",
  wrOrNo:"",
  wr_OR_TY:"",
  wr_OR_CO:"",
  wr_CO_CT:"",
  wr_IT_NM:"",
  wr_BT_QT:"",
  wr_BT_UM:"",
  wr_WK_CT:"",
  wr_OP_SQ:"",
  wr_LB_HR:"",
  wr_MC_HR:"",
  wr_ST_HR:"",
  wrLnNo:"",
};

const initialFilters = {
  wrOrNo: { value: "", operation: "" },
  wr_OR_TY: { value: "", operation: "" },
  wr_OR_CO: { value: "", operation: "" },
  wr_CO_CT: { value: "", operation: "" },
  wr_IT_NM: { value: "", operation: "" },
  wr_IT_NMDES: { value: "", operation: "" },
  wr_BT_QT: { value: "", operation: "" },
  wr_BT_UM: { value: "", operation: "" },
  wr_WK_CT: { value: "", operation: "" },
  wr_OP_SQ: { value: "", operation: "" },
  wr_OP_SQDES: { value: "", operation: "" },
  wr_LB_HR: { value: "", operation: "" },
  wr_MC_HR: { value: "", operation: "" },
  wr_ST_HR: { value: "", operation: "" },
  wrLnNo: { value: "", operation: "" },
};

const WorkCentreMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  const [er_RE_CR, seter_RE_CR ] = useState("");
  const [er_AD_CR, seter_AD_CR ] = useState("");
  const [er_EX_CT, seter_EX_CT ] = useState("");
  const [er_EX_TY, seter_EX_TY ] = useState("");
  const [er_LO_CN, seter_LO_CN ] = useState("");
  const [er_ST_AT, seter_ST_AT ] = useState("");
  const [er_CO_CT, seter_CO_CT ] = useState("");
  const [er_TR_CR, seter_TR_CR ] = useState("");
  
// Advancesearch
  const [SelectedEmployee, setSelectedEmployee ] = useState("");
  const [SelectedManager, setSelectedManager ] = useState("");

// Next No
  const [er_EX_NO, seter_EX_NO ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [WorkOrderRoutingTable, setWorkOrderRoutingTable] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setWorkOrderRoutingTable([{}]);
    seter_RE_CR(null);
    seter_AD_CR(null);
    seter_EX_CT(null);
    seter_EX_TY(null);
    seter_LO_CN(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/WorkOrderRouting/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const WorkCentreData = response.data;
          delete WorkCentreData.WorkOrderRoutingList[0].wr_UK_NO;
          setFormData(WorkCentreData.WorkOrderRoutingList[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [wr_OR_CO , setwr_OR_CO] = useState("");

  const handleDefault = async () => {
    setwr_OR_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/WorkOrderRouting/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/WorkOrderRouting/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const WorkCentreData = response.data;
        setWorkOrderRoutingTable(WorkCentreData.WorkOrderRoutingList);
        setFormData(WorkCentreData.WorkOrderRoutingList[0]);       
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("WorkOrderRoutingTable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const WorkOrderRoutingList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const RQ_DT = document.getElementsByName("er_TR_DT_" + i)[0];
    const details = {
        wr_UK_NO : document.getElementById("wr_UK_NO_" + i).value,
        er_EX_CT : table.tBodies[0].rows[i].cells[1].children[0].children[1].children[0].value,
        er_TR_DT : RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        er_CO_CT : table.tBodies[0].rows[i].cells[3].children[0].children[1].children[0].value,
        er_LO_CN : table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
        er_TR_QT : document.getElementById("er_TR_QT_" + i).value,
        er_RA_TE : document.getElementById("er_RA_TE_" + i).value,
        er_EX_AM : document.getElementById("er_EX_AM_" + i).value,
        er_TR_CR :table.tBodies[0].rows[i].cells[8].children[0].children[1].children[0].value,
        er_AL_AM : document.getElementById("er_AL_AM_" + i).value,
    };
    WorkOrderRoutingList.push(details);
  }
  requestBody = {
  WorkOrderRoutingList,
    wr_UK_NO   : FormData.wr_UK_NO,
    er_EX_NO : document.getElementById("er_EX_NO").value,
    er_TY_PE : document.getElementById("er_TY_PE").value,
    er_EX_CO : document.getElementById("er_EX_CO").value,
    er_EM_NO : document.getElementById("er_EM_NO").value,
    er_MN_NO : document.getElementById("er_MN_NO").value,
    er_RP_DT : FormData.er_RP_DT,
    er_EX_TY : FormData.er_EX_TY,
    er_AD_CR : FormData.er_AD_CR,
    er_RE_CR : FormData.er_RE_CR,
    er_ST_AT : FormData.er_ST_AT,
    er_BU_PR : document.getElementById("er_BU_PR").value,
  };
}
const handleFormSubmit = () => {
  values();
  const ukNo = FormData.wr_UK_NO;
    if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/WorkOrderRouting/Update`;
  
      if (confirm) {
        axios.put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/WorkOrderRouting/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wrOrNo"] ? filters["wrOrNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wrOrNo",
                        e.target.value,
                        filters["wrOrNo"]
                          ? filters["wrOrNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wr_OR_TY"] ? filters["wr_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_OR_TY",
                        e.target.value,
                        filters["wr_OR_TY"] ? filters["wr_OR_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wr_OR_CO"] ? filters["wr_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_OR_CO",
                        e.target.value,
                        filters["wr_OR_CO"]
                          ? filters["wr_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wr_CO_CT"] ? filters["wr_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_CO_CT",
                        e.target.value,
                        filters["wr_CO_CT"]
                          ? filters["wr_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wr_IT_NM"] ? filters["wr_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_IT_NM",
                        e.target.value,
                        filters["wr_IT_NM"] ? filters["wr_IT_NM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wr_IT_NMDES"] ? filters["wr_IT_NMDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_IT_NMDES",
                        e.target.value,
                        filters["wr_IT_NMDES"]
                          ? filters["wr_IT_NMDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["wr_BT_QT"] ? filters["wr_BT_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_BT_QT",
                        e.target.value,
                        filters["wr_BT_QT"]
                          ? filters["wr_BT_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["wr_BT_UM"] ? filters["wr_BT_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_BT_UM",
                        e.target.value,
                        filters["wr_BT_UM"]
                          ? filters["wr_BT_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["wr_WK_CT"] ? filters["wr_WK_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_WK_CT",
                        e.target.value,
                        filters["wr_WK_CT"]
                          ? filters["wr_WK_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["wr_OP_SQ"] ? filters["wr_OP_SQ"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_OP_SQ",
                        e.target.value,
                        filters["wr_OP_SQ"]
                          ? filters["wr_OP_SQ"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["wr_OP_SQDES"] ? filters["wr_OP_SQDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_OP_SQDES",
                        e.target.value,
                        filters["wr_OP_SQDES"]
                          ? filters["wr_OP_SQDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["wr_LB_HR"] ? filters["wr_LB_HR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_LB_HR",
                        e.target.value,
                        filters["wr_LB_HR"]
                          ? filters["wr_LB_HR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["wr_MC_HR"] ? filters["wr_MC_HR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_MC_HR",
                        e.target.value,
                        filters["wr_MC_HR"]
                          ? filters["wr_MC_HR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["wr_ST_HR"] ? filters["wr_ST_HR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wr_ST_HR",
                        e.target.value,
                        filters["wr_ST_HR"]
                          ? filters["wr_ST_HR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["wrLnNo"] ? filters["wrLnNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wrLnNo",
                        e.target.value,
                        filters["wrLnNo"]
                          ? filters["wrLnNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No</th>
                <th style={tableHeaderStyle}>Order Type</th>
                <th style={tableHeaderStyle}>Company</th>  
                <th style={tableHeaderStyle}>Cost Center</th>   
                <th style={tableHeaderStyle}>Item Number</th>  
                <th style={tableHeaderStyle}>Description</th> 
                <th style={tableHeaderStyle}>Batch Qty</th>  
                <th style={tableHeaderStyle}>UM</th>  
                <th style={tableHeaderStyle}>Work Center</th> 
                <th style={tableHeaderStyle}>Oper. Seq.</th>  
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Labour Hrs</th>  
                <th style={tableHeaderStyle}>Machine Hrs</th>   
                <th style={tableHeaderStyle}>Setup Hrs</th>  
                <th style={tableHeaderStyle}>Line No</th>              
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.wr_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.wr_UK_NO}
                        checked={selectedRows.includes(rowData.wr_UK_NO)}
                        onChange={() => handleRowSelect(rowData.wr_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.wrOrNo}</td>
                    <td style={tableCellStyle}>{rowData.wr_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.wr_OR_CO}</td>   
                    <td style={tableCellStyle}>{rowData.wr_CO_CT}</td>   
                    <td style={tableCellStyle}>{rowData.wr_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.wr_IT_NMDES}</td>
                    <td style={tableCellStyle}>{rowData.wr_BT_QT}</td> 
                    <td style={tableCellStyle}>{rowData.wr_BT_UM}</td>
                    <td style={tableCellStyle}>{rowData.wr_WK_CT}</td>
                    <td style={tableCellStyle}>{rowData.wr_OP_SQ}</td>    
                    <td style={tableCellStyle}>{rowData.wr_OP_SQDES}</td>       
                    <td style={tableCellStyle}>{rowData.wr_LB_HR}</td>    
                    <td style={tableCellStyle}>{rowData.wr_MC_HR}</td> 
                    <td style={tableCellStyle}>{rowData.wr_ST_HR}</td>    
                    <td style={tableCellStyle}>{rowData.wrLnNo}</td>                     
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <WorkOrderRoutingDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        WorkOrderRoutingTable={WorkOrderRoutingTable}
        setWorkOrderRoutingTable={setWorkOrderRoutingTable}
        wr_OR_CO={wr_OR_CO}
        er_AD_CR={er_AD_CR}
        er_EX_NO={er_EX_NO}
        seter_AD_CR={seter_AD_CR}
        er_RE_CR={er_RE_CR}
        seter_RE_CR={seter_RE_CR}
        er_EX_CT={er_EX_CT}
        seter_EX_CT={seter_EX_CT}
        er_EX_TY={er_EX_TY}
        seter_EX_TY={seter_EX_TY}
        er_LO_CN={er_LO_CN}
        seter_LO_CN={seter_LO_CN}
        er_ST_AT={er_ST_AT}
        seter_ST_AT={seter_ST_AT}
        er_CO_CT={er_CO_CT}
        seter_CO_CT={seter_CO_CT}
        er_TR_CR={er_TR_CR}
        seter_TR_CR={seter_TR_CR}
        SelectedEmployee={SelectedEmployee}
        setSelectedEmployee={setSelectedEmployee}
        SelectedManager={SelectedManager}
        setSelectedManager={setSelectedManager}
      />
    </div>
  );
};

export default WorkCentreMain;
