import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import AssetPopup from "../Popups/AssetPopup";
import AddressBookPopup from "../Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
import Costcenter  from "views/common/CostCentreDrpDwn";
export default function MaterialRequestDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  MaterialRequestTableData,
  setMaterialRequestTableData,
  mr_DP_MT,
  setmr_DP_MT,
  mr_TR_UM,
  setmr_TR_UM,
  mr_CO_CT,
  setmr_CO_CT,
  mr_RL_TY,
  setmr_RL_TY,
  mrAVST,
  setmrAVST,
  mr_DO_CO,
  mr_DO_TY,
  SelectedRequestBy,
  setSelectedRequestBy,
}) {
  const {
    mr_UK_NO,
    mrDoNo,
    mr_EN_DT,
    mr_RQ_BY,
    ic_RF_CE,
  } = data;

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [mr_CO_CTOptions, setmr_CO_CTOptions] = useState([]);
  const [mr_DP_MTOptions, setmr_DP_MTOptions] = useState([]);
  const [mr_TR_UMOptions, setmr_TR_UMOptions] = useState([]);
  const [mr_RL_TYOptions, setmr_RL_TYOptions] = useState([]);

  // Expense Type
  const FromCostCentreDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmr_CO_CT(value);
};

  // Advance Curr
  const Departmentdropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmr_DP_MT(value);
  };

   // Expense Category
 const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmr_TR_UM(value);
};

// CostCentre
const TypeDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setmr_RL_TY(value);
};



  useEffect(() => {
    FromCostCentre();
    Department();
    UM();
    Type();

  }, []);

  const FromCostCentre = async () => {
    const Options = await fetchCostCenter();
    setmr_CO_CTOptions(Options);
  };

  const Department = async () => {
    const Options =  await fetchClassCodeDS("PO/DP");
    setmr_DP_MTOptions(Options);
  };

  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setmr_TR_UMOptions(Options);
  };
  const Type = async () => {
    const Options = await fetchClassCodeCD("FO/DT");
    setmr_RL_TYOptions(Options);
  };

  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ItemPopupVisible, setItemVisible] = useState(false);
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const [mr_IT_NM, setmr_IT_NM] = useState("");
  const [itemDES, setitemDES] = useState("");
  const handleItem = async (Result) => {
    setmr_IT_NM(Result.im_IT_NM);
    setitemDES(Result.im_IT_DS);
    onchangeFormData("mr_IT_NM", Result.im_IT_NM, SelectedRowIndex);
    onchangeFormData("itemDES", Result.im_IT_DS, SelectedRowIndex);
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
      "im_IT_NM":Result.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      UMDropdown(`mr_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
      const Stock={
        "stockAvailabilityList":[
          {
                  "is_IT_NM": document.getElementById (`mr_IT_NM_${SelectedRowIndex}`).value,
                  "is_CO_CT": mr_CO_CT,  
          }
      ]
      }

      axios.post(`${domain}/api/StockAvailability/GetAvailableByNameAndCostCentre`, Stock, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            const StockAVl = resp.data.it;
            if (StockAVl) {
              document.getElementById (`mr_AV_ST_${SelectedRowIndex}`).value =  StockAVl.is_AV_QT;
              // setmrAVST(StockAVl.is_AV_QT);
                
    }
  });
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  // adv req by
  const [Addresstype, setAddresstype] = useState("C");
  const [RequestedByPopupVisible, setRequestedByPopupVisible] = useState(false);
  const openRequestedByPopup = () => {
    setRequestedByPopupVisible(true);
  }
  const handleRequestedBy = async (data) => {
    setSelectedRequestBy(data.ab_AD_NM);
    onchangeFormData("mr_RQ_BY", data.abAdNo);
    setRequestedByPopupVisible(false);
    setShowSearchIcon(false);
  };

  // Asset
  const [AssetPopupVisible, setAssetVisible] = useState(false);
  const openAssetPopup = (e) => {
    setAssetVisible(true);
    setSelectedRowIndex(e);
  };
  const [mr_AT_NO, setmr_AT_NO] = useState("");
  const [assetDES, setassetDES] = useState("");
  const handleAsset = async (Result) => {
    setmr_AT_NO(Result.fm_AT_NO);
    setassetDES(Result.fm_AT_D1);
    onchangeFormData("mr_AT_NO", Result.fm_AT_NO, SelectedRowIndex);
    onchangeFormData("assetDES", Result.fm_AT_D1, SelectedRowIndex);
    setAssetVisible(false);
    setShowSearchIcon(false);
  };
  

  const addRow = () => {
    setMaterialRequestTableData([...MaterialRequestTableData, {}]);
  };
  
  const deleteRow = () => {
    if (MaterialRequestTableData.length > 0) {
      const updatedData = [...MaterialRequestTableData];
      updatedData.pop();
      setMaterialRequestTableData(updatedData);
    }
  };  
const isUpdateMode = Boolean(mr_UK_NO);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
  const [selectedTRDate, setSelectedTRDate] = useState(moment().toDate());
  const handleDateTRChange = (e) => {
    setSelectedTRDate(e.value);
    onchangedata({ target: { name: "mr_EN_DT", value: moment(e.value).format("YYYYMMDD") } });
  };

 
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={mr_UK_NO ? "Revise Material Request" : "Add Material Request"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="mrDoNo" className="font-bold">
          Document No
          </label>
          <InputText disabled
            id="mrDoNo"
            name="mrDoNo"
            defaultValue={mrDoNo}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="mr_DO_TY" className="font-bold">
           Type 
          </label>
          <InputText disabled
            id="mr_DO_TY"
            name="mr_DO_TY"
            value={mr_DO_TY}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="mr_DO_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="mr_DO_CO"
            name="mr_DO_CO"
            value={mr_DO_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="mr_EN_DT" className="font-bold">
           Transfer Date
           </label>
              <Calendar
              id="mr_EN_DT"
              name="mr_EN_DT"
              value={moment(mr_EN_DT, "YYYYMMDD").toDate()}
              onChange={handleDateTRChange}
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          
             <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="mr_CO_CT" className="font-bold">
                    Cost Centre
                    </label>
                    <Dropdown
                    name="mr_CO_CT"
                    value={mr_CO_CT}
                    options={mr_CO_CTOptions}
                    onChange={(e) => FromCostCentreDropdown("mr_CO_CT", e.value)}
                    placeholder="Select Cost Centre"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="mr_DP_MT" className="font-bold">
                    Department
                    </label>
                    <Dropdown
                    name="mr_DP_MT"
                    value={mr_DP_MT}
                    options={mr_DP_MTOptions}
                    onChange={(e) => Departmentdropdown("mr_DP_MT", e.value)}
                    placeholder="Select Department"
                  />
                  </div>
                  
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="mr_RQ_BY" className="font-bold">
              RequestedBy <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="RequestedBy_NAME"
                      name="RequestedBy_NAME"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedRequestBy}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openRequestedByPopup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="mr_RQ_BY"
                      name="mr_RQ_BY"
                      value={mr_RQ_BY}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
          </div>
          </div>  
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
        >
      
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="operationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Line No</th>
              <th>Item <span style={{ color: 'red' }}>*</span> </th>
              <th>Description</th>
              <th>Additional Description</th>
              <th>Req Quantity</th>
              <th>UM</th>
              <th>Available Stock</th>
              <th>Requested Date</th>
              <th>Re Order No</th>
              <th>Type</th>
              <th>Asset No <span style={{ color: 'red' }}>*</span> </th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {MaterialRequestTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`mr_UK_NO_${index}`}
                    name={`mr_UK_NO_${index}`}
                    value={row.mr_UK_NO}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`mrLnNo_${index}`}
                    name={`mrLnNo_${index}`}
                    value={index+1}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td style={{ display: "flex" }}>
          <InputText
          style={{ width: "110px" }}
          id={`mr_IT_NM_${index}`}
          name={`mr_IT_NM_${index}`}
          onChange={(e) => onchangeFormData(e)}
          onFocus={() => setShowSearchIcon(true)}
          value={row.mr_IT_NM ? row.mr_IT_NM: row[`${"mr_IT_NM"}_${index}`]}
          />
          {showSearchIcon && (
         <i
         className="pi pi-search"
         style={{
         color: "var(--primary-color)",
         fontSize: "119%",
         marginTop: "15%",
         cursor: "pointer",
        }}
        onClick={(e) => openItemPopup(index)}
        ></i>
        )}
      </td>
        <td>
        <InputText disabled
        style={{ width: "200px" }}
        id={`itemDES_${index}`}
        name={`itemDES_${index}`}
        defaultValue={row.itemDES ? row.itemDES : row[`${"itemDES"}_${index}`]}
        readOnly
        />
        </td>
        <td >
                  <InputText style={{width:"90px"}}
                    id={`mr_AD_DS_${index}`}
                    name={`mr_AD_DS_${index}`}
                    defaultValue={row.mr_AD_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  style={{width:"90px"}}
                    id={`mr_RQ_QT_${index}`}
                    name={`mr_RQ_QT_${index}`}
                    defaultValue={row.mr_RQ_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
          <td>
          <Dropdown
          id={`mr_TR_UM_${index}`}
          name={`mr_TR_UM_${index}`}
          value={ data[`mr_TR_UM_${index}`] ? data[`mr_TR_UM_${index}`]: row.mr_TR_UM}
          options={mr_TR_UMOptions}
          onChange={(e) =>UMDropdown(`mr_TR_UM_${index}`, e.value)}
          placeholder="Select UM"
          />
          </td>
          <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`mr_AV_ST_${index}`}
                    name={`mr_AV_ST_${index}`}
                    // value={mrAVST}
                    defaultValue={row.mr_AV_ST}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
          <Calendar style={{width:"150px"}}
           id={`mr_RQ_DT_${index}`}
           name={`mr_RQ_DT_${index}`}
           value={row.mr_RQ_DT? moment(row.mr_RQ_DT, "YYYYMMDD").toDate(): null}
           onChange={(e) => onDateChange({ target: { name: "mr_RQ_DT", value: moment(e.value).format("YYYYMMDD") } })}
           showIcon
           dateFormat="dd/mm/yy"
          />
          </td>
          <td >
                  <InputText  style={{width:"90px"}}
                    id={`mr_RL_NO_${index}`}
                    name={`mr_RL_NO_${index}`}
                    defaultValue={row.mr_RL_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Dropdown 
                    id={`mr_RL_TY_${index}`}
                    name={`mr_RL_TY_${index}`}
                    value={ data[`mr_RL_TY_${index}`] ? data[`mr_RL_TY_${index}`]: row.mr_RL_TY}
                    options={mr_RL_TYOptions}
                    onChange={(e) =>TypeDropdown(`mr_RL_TY_${index}`, e.value)}
                    placeholder="Select Type"
                  />
                  </td>
                  <td style={{ display: "flex" }}>
          <InputText
          style={{ width: "110px" }}
          id={`mr_AT_NO_${index}`}
          name={`mr_AT_NO_${index}`}
          onChange={(e) => onchangeFormData(e)}
          onFocus={() => setShowSearchIcon(true)}
          value={row.mr_AT_NO ? row.mr_AT_NO: row[`${"mr_AT_NO"}_${index}`]}
          />
          {showSearchIcon && (
         <i
         className="pi pi-search"
         style={{
         color: "var(--primary-color)",
         fontSize: "119%",
         marginTop: "15%",
         cursor: "pointer",
        }}
        onClick={(e) => openAssetPopup(index)}
        ></i>
        )}
      </td>
        <td>
        <InputText disabled
        style={{ width: "130px" }}
        id={`assetDES_${index}`}
        name={`assetDES_${index}`}
        defaultValue={row.assetDES ? row.assetDES : row[`${"assetDES"}_${index}`]}
        readOnly
        />
        </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
        <AddressBookPopup
        visible={RequestedByPopupVisible}
        onClose={() => setRequestedByPopupVisible(false)}
        onDataSelect={handleRequestedBy}
        formData={data}
        addressType={Addresstype}
      />

        <AssetPopup
        visible={AssetPopupVisible}
        onClose={() => setAssetVisible(false)}
        onDataSelect={handleAsset}
        formData={data}
      />
    </Dialog>
  );
}
