import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import { TabView, TabPanel } from "primereact/tabview";
import ItemPopup from "../Popups/ItemPopup";
import Costcenter from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import PayTerm from "views/common/PayTermDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
import { InputMask } from "primereact/inputmask";
export default function ComponentCostSimulationDialog({
  open,
  handleClose,
  data,
  FormData,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  ComponentCostSimulationTable,
  setComponentCostSimulationTable,
  ComponentCostSimulationTable1,
  setComponentCostSimulationTable1,


  handleConfirm,
  material_TOT,



  // ADSVANCSE SEACRH
  SelectedItem,
  setSelectedItem,

  SelectedITNM,
  setSelectedITNM,
  ch_CO_CT,
  setch_CO_CT,
  cm_IT_UM,
  setcm_IT_UM,
  cm_ST_TY,
  setcm_ST_TY,
  cm_OP_SQ,
  setcm_OP_SQ,
  cm_ST_CD,
  setcm_ST_CD,
  cl_WK_CT,
  setcl_WK_CT,
  cl_OP_SQ,
  setcl_OP_SQ,

}) {
  const {
    chUkNo,
    ch_CO_IT,
    ch_CO_QT,
    ch_UN_CT,
    ch_CO_AM,
   
  } = data;

  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, multitableheader, footergridstyle, footerfieldstyle } = ButtonStyles();

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "sd_DA_TE", value: moment(e.value).format("YYYYMMDD") } });

  };

  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const { fetchPayTerm } = PayTerm();



  const [ch_CO_CTOptions, setch_CO_CTOptions] = useState([]);
  const [cm_IT_UMOptions, setcm_IT_UMOptions] = useState([]);
  const [cm_ST_TYOptions, setcm_ST_TYOptions] = useState([]);
  const [cm_ST_CDOptions, setcm_ST_CDOptions] = useState([]);
  const [cm_OP_SQOptions, setcm_OP_SQOptions] = useState([]);
  const [cl_WK_CTOptions, setcl_WK_CTOptions] = useState([]);
  const [cl_OP_SQOptions, setcl_OP_SQOptions] = useState([]);


  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");


  //Cost Centre
  const onchangeDropDownFormDataCostCentre = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setch_CO_CT(value);
  };
  //UM
  const onchangeDropDownFormDataUM = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcm_IT_UM(value);
  };
  //Type
  const onchangeDropDownFormDataType = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcm_ST_TY(value);
  };
  //Status
  const onchangeDropDownFormDataStatus = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcm_ST_CD(value);
  };
  //OperSeq 
  const onchangeDropDownFormDataOperSeq = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcm_OP_SQ(value);
  };
  //WorkCenter
  const onchangeDropDownFormDataWorkCenter = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcl_WK_CT(value);
  };
  //OperSeq1 
  const onchangeDropDownFormDataOperSeq1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcl_OP_SQ(value);
  };


  useEffect(() => {


    CostCentre();
    UM();
    Type();
    OperSeq();
    Status();
    WorkCenter();
    OperSeq1();
  

  }, []);

  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    setch_CO_CTOptions(Options);
  };
  const UM = async () => {
    const Options = await fetchClassCodeDS("IN/UM");
    setcm_IT_UMOptions(Options);
  };
  const Type = async () => {
    const Options = await fetchClassCodeCD("IN/TY");
    setcm_ST_TYOptions(Options);
  };
  const OperSeq = async () => {
    const Options = await fetchCostCenter();
    setcm_OP_SQOptions(Options);
  };
  const Status = async () => {
    const Options = await fetchClassCodeDS("CS/ST");
    setcm_ST_CDOptions(Options);
  };
  const OperSeq1 = async () => {
    const Options = await fetchCostCenter();
    setcl_OP_SQOptions(Options);
  };

  const WorkCenter = async () => {
    const Options = await fetchCostCenter();
    setcl_WK_CTOptions(Options);
  };







  // Advance Search
  const [SoldToPopupVisible, setSoldToPopupVisible] = useState(false);
  const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
  const [CarrierPopupVisible, setCarrierPopupVisible] = useState(false);
  const [ItemVisible, setItemVisible] = useState(false);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ItemPopupVisible, setItemPopupVisible] = useState(false);

  const [showSearchIcon, setShowSearchIcon] = useState("");

  const openItemPopup1 = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

  const handleItem = async (data) => {
    onchangeFormData("item_DES", data.im_IT_DS, SelectedRowIndex);
    onchangeFormData("cm_IT_NM", data.im_IT_NM, SelectedRowIndex);
    setItemVisible(false);
    setShowSearchIcon(false);
  };


  const openItemPopup = () => {
    setItemPopupVisible(true);
  };
  const handleDataSelectItem = (Result) => {
    setSelectedITNM(Result.im_IT_DS);
    document.getElementById("ch_CO_IT").value = Result.im_IT_NM;
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };
  const addRow = () => {
    setComponentCostSimulationTable([...ComponentCostSimulationTable, {}]);
  };
  const deleteRow = () => {
    if (ComponentCostSimulationTable.length > 0) {
      const updatedData = [...ComponentCostSimulationTable];
      updatedData.pop();
      setComponentCostSimulationTable(updatedData);
    }
  };
  const addRow1 = () => {
    setComponentCostSimulationTable1([...ComponentCostSimulationTable1, {}]);
  };
  const deleteRow1 = () => {
    if (ComponentCostSimulationTable1.length > 0) {
      const updatedData1 = [...ComponentCostSimulationTable1];
      updatedData1.pop();
      setComponentCostSimulationTable1(updatedData1);
    }
  };

  const [activeTab, setActiveTab] = useState(0);
  const isUpdateMode = Boolean(chUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
        tooltip={isUpdateMode ? "Update" : "Submit"}>
      </Button>
      <Button
        style={{
          width: "5%",
          backgroundColor: "transparent",
          color: "blue",
          marginLeft: "82%", marginBottom: "-1%", marginTop: "-4%",
          display: isUpdateMode ? 'block' : 'none'
        }}
        icon="pi pi-check-circle"
        onClick={handleConfirm}
        tooltip={"Confirm"}>
      </Button>
    </React.Fragment>
  );

  const onChangeOfPrice = async (e) => {
    //const table = document.getElementById("RentalContractTable");
    //const totalRowCount = table.tBodies[0].rows.length;
    // rentalContracts = [];
    const table = document.getElementById("ComponentCostSimulationTable");
    const totalRowCount = table.tBodies[0].rows.length;
    const ComponentCostSimulationList = [];
    for (let i = 0; i < totalRowCount; i++) {
      const details = {
        chUkNo: document.getElementById("chUkNo_" + i).value,
        sd_EX_PR: document.getElementById("sd_EX_PR_" + i).value,
        sd_EX_CT: document.getElementById("sd_EX_CT_" + i).value,
        sd_FR_PR: document.getElementById("sd_FR_PR_" + i).value,
      };
      ComponentCostSimulationList.push(details);
    }
    const requestBodyNet = {
      ComponentCostSimulationList,
    };

    const response = await axios.post(`${domain}/api/ComponentCostSimulation/GetCalculateTotalPrices`, requestBodyNet, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
      },
    });
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const respNet = response.data.header;
    if (respNet) {
      document.getElementById("F_EXT_PRICE").value = respNet.f_EXT_PRICE;
      document.getElementById("F_EXT_COST").value = respNet.f_EXT_COST;
      document.getElementById("F_FORE_PRICE").value = respNet.f_FORE_PRICE;

    } else {
      console.error("Invalid API response:", data);
    }
    //onchangedata(e); 
  };
  // Handle form data change
  const onChangeTime = (name, value, index) => {
    value = moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value, index);
  };

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "85rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={chUkNo ? "Revise Component Cost Simulation" : "Add Component Cost Simulation"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >

        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="Material">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
                  <label htmlFor="chUkNo" className="font-bold">
                    UK NO
                  </label>
                  <InputText disabled
                    id="chUkNo"
                    name="chUkNo"
                    defaultValue={chUkNo}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ch_CO_IT" className="font-bold">
                    Component Item<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: 'flex' }}>
                    <InputText
                      id="im_IT_DS"
                      name="im_IT_DS"
                      onChange={(event) => onchangedata(event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedITNM}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openItemPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: 'none' }}>
                    <InputText
                      id="ch_CO_IT"
                      name="ch_CO_IT"
                      defaultValue={ch_CO_IT}
                      onChange={(event) => onchangedata(event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ch_CO_CT" className="font-bold">
                    Cost Centre
                  </label>
                  <Dropdown
                    name="sd_RT_CD"
                    value={ch_CO_CT}
                    options={ch_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormDataCostCentre("ch_CO_CT", e.value)}
                    placeholder="	Cost Centre"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ch_CO_QT" className="font-bold">
                    Quantity
                  </label>
                  <InputText
                    id="ch_CO_QT"
                    name="ch_CO_QT"
                    value={ch_CO_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ch_UN_CT" className="font-bold">
                    Unit Cost
                  </label>
                  <InputText disabled
                    id="ch_UN_CT"
                    name="ch_UN_CT"
                    value={ch_UN_CT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ch_CO_AM" className="font-bold">
                    Amount
                  </label>
                  <InputText disabled
                    id="ch_CO_AM"
                    name="ch_CO_AM"
                    value={ch_CO_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>


              </div>
            </div>
            <div style={tablestyle} >
              <div className="table-buttons" style={tablebutton} >
                {(
                  <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
                )}
                {(
                  <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
                )}
              </div>
              <div style={multitablestyle}>
                <table className="custom-table" id="CostSimulationMaterial">
                  <thead>
                    <tr>
                      <th style={{ display: "none" }}>UK NO</th>
                      <th>Item Number <span style={{ color: 'red' }}>*</span></th>
                      <th>ItemDes </th>
                      <th>UM</th>
                      <th>Type</th>
                      <th>Quantity</th>
                      <th>Unit Cost</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Oper. Seq.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ComponentCostSimulationTable.map((row, index) => (
                      <tr key={index}>
                        <td style={{ display: "none" }}>
                          <InputText
                            id={`cm_UK_NO_${index}`}
                            name={`cm_UK_NO_${index}`}
                            defaultValue={row.cm_UK_NO}
                          />
                        </td>
                        <td style={{ display: "flex" }}>
                          <InputText
                            style={{ width: "110px" }}
                            id={`cm_IT_NM_${index}`}
                            name={`cm_IT_NM_${index}`}
                            onChange={(e) => onchangeFormData(e)}
                            onFocus={() => setShowSearchIcon(true)}
                            value={row.cm_IT_NM ? row.cm_IT_NM : row[`${"cm_IT_NM"}_${index}`]}
                          />
                          {showSearchIcon && (
                            <i
                              className="pi pi-search"
                              style={{
                                color: "var(--primary-color)",
                                fontSize: "119%",
                                marginTop: "15%",
                                cursor: "pointer",
                              }}
                              onClick={(e) => openItemPopup1(index)}
                            ></i>
                          )}
                        </td>
                        <td>
                          <InputText disabled
                            style={{ width: "200px" }}
                            id={`item_DES_${index}`}
                            name={`item_DES_${index}`}
                            value={row.item_DES ? row.item_DES : row[`${"item_DES"}_${index}`]}
                            readOnly
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`cm_IT_UM_${index}`}
                            name={`cm_IT_UM_${index}`}
                            value={data[`cm_IT_UM_${index}`] ? data[`cm_IT_UM_${index}`] : row.cm_IT_UM}
                            options={cm_IT_UMOptions}
                            onChange={(e) => onchangeDropDownFormDataUM(`cm_IT_UM_${index}`, e.value)}
                            placeholder=" UM"
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`cm_ST_TY_${index}`}
                            name={`cm_ST_TY_${index}`}
                            value={data[`cm_ST_TY_${index}`] ? data[`cm_ST_TY_${index}`] : row.cm_ST_TY}
                            options={cm_ST_TYOptions}
                            onChange={(e) => onchangeDropDownFormDataType(`cm_ST_TY_${index}`, e.value)}
                            placeholder=" Type"
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`cm_IT_QT_${index}`}
                            name={`cm_IT_QT_${index}`}
                            defaultValue={row.cm_IT_QT}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`cm_IT_CT_${index}`}
                            name={`cm_IT_CT_${index}`}
                            defaultValue={row.cm_IT_CT}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`cm_IT_AM_${index}`}
                            name={`cm_IT_AM_${index}`}
                            defaultValue={row.cm_IT_AM}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`cm_ST_CD_${index}`}
                            name={`cm_ST_CD_${index}`}
                            value={data[`cm_ST_CD_${index}`] ? data[`cm_ST_CD_${index}`] : row.cm_ST_CD}
                            options={cm_ST_CDOptions}
                            onChange={(e) => onchangeDropDownFormDataStatus(`cm_ST_CD_${index}`, e.value)}
                            placeholder="Status"
                          />
                        </td>

                        <td>
                          <Dropdown
                            id={`cm_OP_SQ_${index}`}
                            name={`cm_OP_SQ_${index}`}
                            value={data[`cm_OP_SQ_${index}`] ? data[`cm_OP_SQ_${index}`] : row.cm_OP_SQ}
                            options={cm_OP_SQOptions}
                            onChange={(e) => onchangeDropDownFormDataOperSeq(`cm_OP_SQ_${index}`, e.value)}
                            placeholder="Oper. Seq."
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div style={inboxstyle}  >
              <div className="formgrid grid" style={footergridstyle} >
                <div className="field" style={footerfieldstyle} >
                  <label htmlFor="material_TOT" className="font-bold">
                    Total Amount
                  </label>
                  <InputText disabled
                    id="material_TOT"
                    name="material_TOT"
                    value={material_TOT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>

              </div>

            </div>
          </TabPanel>
          <TabPanel header="Machine & Labour ">
            <div style={inboxstyle}  >
              <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
                  <label htmlFor="chUkNo" className="font-bold">
                    UK NO
                  </label>
                  <InputText disabled
                    id="chUkNo"
                    name="chUkNo"
                    defaultValue={chUkNo}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ch_CO_IT" className="font-bold">
                    Component Item<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: 'flex' }}>
                    <InputText
                      id="im_IT_DS"
                      name="im_IT_DS"
                      onChange={(event) => onchangedata(event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedITNM}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openItemPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: 'none' }}>
                    <InputText
                      id="ch_CO_IT"
                      name="ch_CO_IT"
                      defaultValue={ch_CO_IT}
                      onChange={(event) => onchangedata(event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ch_CO_CT" className="font-bold">
                    Cost Centre
                  </label>
                  <Dropdown
                    name="sd_RT_CD"
                    value={ch_CO_CT}
                    options={ch_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormDataCostCentre("ch_CO_CT", e.value)}
                    placeholder="	Cost Centre"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ch_CO_QT" className="font-bold">
                    Quantity
                  </label>
                  <InputText
                    id="ch_CO_QT"
                    name="ch_CO_QT"
                    value={ch_CO_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
            </div>
            <div style={tablestyle} >
              <div className="table-buttons" style={tablebutton} >
              
                {(
                    <Button icon="pi pi-plus" onClick={addRow1} style={plusstyle} tooltip="Add" />
                )}
               
                 {(
                  <Button icon="pi pi-minus" onClick={deleteRow1} style={minusstyle} tooltip="Delete" />
                )}
              </div>
              <div style={multitablestyle}>
                <table className="custom-table" id="CostSimulationLabourTable">
                  <thead>
                    <tr>
                      <th style={{ display: "none" }}>UK NO</th>
                      <th>Work Centre</th>
                      <th>Oper. Seq.</th>
                      <th>Description </th>
                      <th>Setup Hrs</th>
                      <th>Machine Hrs	</th>
                      <th>Labour Hrs</th>
                      <th>M/C Rate</th>
                      <th>Lab. Rate</th>
                      <th>O/H Rate</th>
                      <th>M/C Amt</th>
                      <th>Lab. Amt</th>
                      <th>O/H Amt</th>
                      <th>Total Amt</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ComponentCostSimulationTable1.map((row, index) => (
                      <tr key={index}>
                        <td style={{ display: "none" }}>
                          <InputText
                            id={`cl_UK_NO_${index}`}
                            name={`cl_UK_NO_${index}`}
                            defaultValue={row.cl_UK_NO}
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`cl_WK_CT_${index}`}
                            name={`cl_WK_CT_${index}`}
                            value={data[`cl_WK_CT_${index}`] ? data[`cl_WK_CT_${index}`] : row.cl_WK_CT}
                            options={cl_WK_CTOptions}
                            onChange={(e) => onchangeDropDownFormDataWorkCenter(`cl_WK_CT_${index}`, e.value)}
                            placeholder=" WorkCenter "
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`cl_OP_SQ_${index}`}
                            name={`cl_OP_SQ_${index}`}
                            value={data[`cl_OP_SQ_${index}`] ? data[`cl_OP_SQ_${index}`] : row.cl_OP_SQ}
                            options={cl_OP_SQOptions}
                            onChange={(e) => onchangeDropDownFormDataOperSeq1(`cl_OP_SQ_${index}`, e.value)}
                            placeholder=" Oper. Seq."
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`cl_OP_DS_${index}`}
                            name={`cl_OP_DS_${index}`}
                            defaultValue={row.cl_OP_DS}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`cl_ST_HR_${index}`}
                            name={`cl_ST_HR_${index}`}
                            defaultValue={row.cl_ST_HR}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`cl_MC_HR_${index}`}
                            name={`cl_MC_HR_${index}`}
                            defaultValue={row.cl_MC_HR}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`cl_LB_HR_${index}`}
                            name={`cl_LB_HR_${index}`}
                            defaultValue={row.cl_LB_HR}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`cl_MC_CL_${index}`}
                            name={`cl_MC_CL_${index}`}
                            defaultValue={row.cl_MC_CL}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`cl_LB_CL_${index}`}
                            name={`cl_LB_CL_${index}`}
                            defaultValue={row.cl_LB_CL}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`cl_OH_CL_${index}`}
                            name={`cl_OH_CL_${index}`}
                            defaultValue={row.cl_OH_CL}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`cl_MC_AM_${index}`}
                            name={`cl_MC_AM_${index}`}
                            defaultValue={row.cl_MC_AM}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`cl_LB_AM_${index}`}
                            name={`cl_LB_AM_${index}`}
                            defaultValue={row.cl_LB_AM}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`cl_OH_AM_${index}`}
                            name={`cl_OH_AM_${index}`}
                            defaultValue={row.cl_OH_AM}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`tot_AMT_${index}`}
                            name={`tot_AMT_${index}`}
                            defaultValue={row.tot_AMT}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div style={inboxstyle}  >
              <div className="formgrid grid" style={footergridstyle} >
                <div className="field" style={footerfieldstyle} >
                  <label htmlFor="material_TOT" className="font-bold">
                    Total Amount
                  </label>
                  <InputText disabled
                    id="material_TOT"
                    name="material_TOT"
                    value={material_TOT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>

              </div>

            </div>
          </TabPanel>
        </TabView>
      </div>

      {/* item */}
      {<ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />}
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelectItem}
        formData={data}
      />

    </Dialog>

  );
}

