import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { Pie } from 'react-chartjs-2';
import { Dropdown } from "primereact/dropdown";
import './styledash.css';
import axios from "axios";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import StageSourceDetails from "./StageSourceDetails";
import StatusReasonDetails from "./StatusReasonDetails";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
const CRMOverview = () => {
  
const [dashboardData, setDashboardData] = useState({
  date:"",
  salesTargetMonth:"",
  salesTargetYear:"",
  salesActualMonth:"",  
  salesActualYear:"",
  salesAchievedMonth:"",
  salesAchievedYear:"",
  opportunityTotal:"",
  opportunityConverted:"",
  opportunityLost:"",
  inputLeadStage:"",
  sourceClCdList:[],
  sourceCountClCd:"",
  opportunityStatusList:[],
  inputOpportunityReason:"",
  opportunityLost:"",
  saOpPopupList:[],
  saLdPopupList:[],
  salesTargetYear:"",
  salesAchievedYear:"",
  opportunityLostDetailsList:[],
  salesPersonName:"",
  stageClCdList:[],
  salesTargetMonth:"",
  stageSaLdList:[],
  inputLeasSource:"",
  inputCompany:"",
  salesActualYear:"",
  opportunityConversion:"",
  inputYear:"",
  sourceSaLdList:"",
  inputToDate:"",
  salesActualMonth:"",
  inputMonth:"",
  inputOpportunityStatus:"",
  inputFromDate:"",
  salesAchievedMonth:"",
  opportunityTotal:"",
  opportunityConverted:"",
  inputTeam:"",
  inputSalesPerson:"", 
  opportunityStatusList:"",
  // source list
  email:"",
  call:"",
  whatsapp:"",
  website:"",
  referals:"",
  sourceCountClCd:"",
});
// stylings
const headerbox_odd = {
  backgroundColor: "#c5f2ffcc",
  color: "#000000", 
  borderRadius: "17px", 
  width: "24%", 
  height: "80px",
  padding: "15px 20px", 
  display: "flex",
  justifyContent: "center", 
};
const headerbox_even = {
  backgroundColor: "#d5fff2",
  color: "#000000", 
  borderRadius: "17px", 
  width: "24%", 
  height: "80px",
  padding: "15px 20px", 
  display: "flex",
  justifyContent: "center", 
}
const headerbox_odd1 = {
  backgroundColor: "#c5f2ffcc",
  color: "#000000", 
  borderRadius: "17px", 
  width: "19%", 
  height: "80px",
  padding: "15px 20px", 
  display: "flex",
  justifyContent: "center", 
};
const headerbox_even1 = {
  backgroundColor: "#d5fff2",
  color: "#000000", 
  borderRadius: "17px", 
  width: "19%", 
  height: "80px",
  padding: "15px 20px", 
  display: "flex",
  justifyContent: "center", 
};
const boxtextstyle ={
  fontFamily: "DM Sans",
  color: "black",
  fontSize:"small",
  fontWeight:"bold"
};
const boxtextstyle1 ={
  fontFamily: "DM Sans",
  color: "black",
  fontSize:"smaller",
  fontWeight:"bold"
}; 
const headerbox_odd2 = {
  backgroundColor: "#c6fbf1",
  color: "#000000", 
  borderRadius: "17px", 
  width: "19%", 
  height: "80px",
  padding: "15px 20px", 
  display: "flex",
  justifyContent: "center", 
};
const headerbox_even2 = {
  backgroundColor: "#c8ffe0",
  color: "#000000", 
  borderRadius: "17px", 
  width: "19%", 
  height: "80px",
  padding: "15px 20px", 
  display: "flex",
  justifyContent: "center", 
};
const lossanalysisstyle = {
  fontSize: '14px', // Adjust font size as needed
  lineHeight: '1.2', // Adjust line height for two lines
};
const token = localStorage.getItem("token");
const company = localStorage.getItem("company");
const domain = localStorage.getItem("domain");

const [selectedYear, setSelectedYear] = useState("");
// dropdwons
const getCurrentMonth = () => {
  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  const currentMonthIndex = new Date().getMonth();
  return monthNames[currentMonthIndex];
};
const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
const years = [
  { label: 2022, value: 2022 },
  { label: 2023, value: 2023 },
  { label: 2024, value: 2024 }
];

useEffect(() => {
  let newDate = new Date()
  if (selectedMonth === null || selectedMonth === "" ) {
  let month = newDate.getMonth() + 1;
  setSelectedMonth(month);
  }
  if (selectedYear === null || selectedYear === "") {
    let year = newDate.getFullYear();
    setSelectedYear(year);
  }  
  if (selectedMonth !== null && selectedYear !== null) {
    CRMdashboard();
  }

}, [selectedMonth,selectedYear]);
const handleYearChange = (e) => {
  setSelectedYear(e.value);
};
const [open, setOpen] = useState(false);
const [StageSourceDetailsVisible, setStageSourceDetailsVisible] = useState(false);
const [StatusReasonDetailsVisible, setStatusReasonDetailsVisible] = useState(false);

const openStageSource = () => {
  setStageSourceDetailsVisible(true);
};
const openStatusReason = () => {
  setStatusReasonDetailsVisible(true);
}; 

const dashboard = `${domain}/api/Dashboard/LeadDashboard`;
const CRMdashboard = async() =>{
  const requestinput ={
          "year":selectedYear || "",
          "month":selectedMonth || "",
          "from_Date":inputFromDate || "",
          "to_Date":inputToDate || "",
          "sales_Person":inputSalesPerson || "",
          "team": inputTeam || "",
          "lead_Stage":inputLeadStage || "",
          "lead_Source":inputLeadSource || "",
          "opportunity_Status":inputOpportunityStatus || "",
          "opportunity_Reason":inputOpportunityReason || "",
        }
  try {
    const response = await axios.post(dashboard, requestinput, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const data = response.data;
    setDashboardData(data);
    } catch (error) {
    console.error('Error fetching data:', error);
  }
};
// BARCHART 
const barChartRef1 = useRef(null);
const barchartOptions = {
  indexAxis: 'y',
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: { drawOnChartArea: false, display: false, drawBorder: false },
      ticks: { color: 'black', font: { size: 8 }, },
    },
    y: {
      grid: { drawBorder: true, drawOnChartArea: false, display: false },
      beginAtZero: true,
      min: 0,
      ticks: { stepSize: 10000, font: { size: 8 }, color: 'black', },
    },
  },
  plugins: {
    legend: {
      display: false,
      labels: { font: { size: 8 } },
    },
    interaction: {
      intersect: false,
    },
  },
};
const initializeBarchart = (ctx, labels, dataValues) => {
  new Chart(ctx, {
    type: 'bar',
    data: {
      labels: labels,
      datasets: [{
        label: 'Value',
        data: dataValues,
        backgroundColor: '#66CCFF',
        barThickness: 15,
        borderRadius: 15,
      }],
    },
    options: barchartOptions,
  });
};
useEffect(() => {
  const UpdateBarchart = () => {
    if (!barChartRef1.current || !dashboardData.opportunityLostDetailsList) return;

    const ctx = barChartRef1.current.getContext("2d");
    let chart = Chart.getChart(ctx);

    if (chart) {
      // Update existing chart
      const labels = dashboardData.opportunityLostDetailsList.map(item => item.opportunityLostDetailsDesc);
      const dataValues = dashboardData.opportunityLostDetailsList.map(item => parseFloat(item.opportunityAmount));

      chart.data.labels = labels;
      chart.data.datasets[0].data = dataValues;
      chart.update();
    } else {
      // Initialize new chart
      const labels = dashboardData.opportunityLostDetailsList.map(item => item.opportunityLostDetailsDesc);
      const dataValues = dashboardData.opportunityLostDetailsList.map(item => parseFloat(item.opportunityAmount));

      initializeBarchart(ctx, labels, dataValues);
    }
  };

  if (dashboardData && dashboardData.opportunityLostDetailsList) {
    UpdateBarchart();
  }
}, [dashboardData]);




// piechart 1
const [data1, setData1] = useState({
  labels: [],
  datasets: [
    {
      label: 'Lead By Stage',
      data: [],
      backgroundColor: [ 'rgba(75,192,192,1)', '#FF6384', '#36A2EB', '#FFCE56', '#B833FF', '#E7E9ED'],
    },
  ],
});
useEffect(() => {
  if (!dashboardData.stageSaLdList || !dashboardData.stageSaLdList.stageDescSaLd) return;

  setData1({
    labels: dashboardData.stageSaLdList.stageDescSaLd,
    datasets: [
      {
        label: 'Lead By Stage',
        data: dashboardData.stageSaLdList.stageCountSaLd,
        backgroundColor: ['#99FFFF', '#d5fff2', '#66CCFF', 'cyan', '#EBEDEF', '#AED6F1'],
      },
    ],
  });
}, [dashboardData.stageSaLdList]);
const options1 = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'right',
    },
  },
};
// pie chart2
const [data2, setData2] = useState({
  labels: [],
  datasets: [
    {
      label: 'Lead By Source',
      data: [],
      backgroundColor: ['#99FFFF', '#d5fff2', '#66CCFF', 'cyan', '#EBEDEF', '#AED6F1'],
    },
  ],
});
useEffect(() => {
  if (!dashboardData.sourceSaLdList || !dashboardData.sourceSaLdList.sourceDescSaLd) return;

  setData2({
    labels: dashboardData.sourceSaLdList.sourceDescSaLd,
    datasets: [
      {
        label: 'Lead By Stage',
        data: dashboardData.sourceSaLdList.sourceCountSaLd,
        backgroundColor: ['#99FFFF', '#d5fff2', '#66CCFF', 'cyan', '#EBEDEF', '#AED6F1'],
      },
    ],
  });
}, [dashboardData.sourceSaLdList]);
const options2 = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'right', 
    },
  },
};
// pie chart3
const [data3, setData3] = useState({
  labels: [],
  datasets: [
    {
      label: 'Opportunity',
      data: [],
      backgroundColor: ['#99FFFF', '#d5fff2', '#66CCFF', 'cyan', '#EBEDEF', '#AED6F1'],
    },
  ],
});

useEffect(() => {
  if (!dashboardData.opportunityStatusList || !dashboardData.opportunityStatusList.opportunityStatusDesc) return;

  // Ensure opportunityAmount is converted to numbers
  const opportunityAmounts = dashboardData.opportunityStatusList.opportunityAmount.map(amount => parseFloat(amount.replace(',', '')));

  setData3({
    labels: dashboardData.opportunityStatusList.opportunityStatusDesc,
    datasets: [
      {
        label: 'Lead By Stage',
        data: opportunityAmounts,
        backgroundColor: ['#99FFFF', '#d5fff2', '#66CCFF', 'cyan', '#EBEDEF', '#AED6F1'].slice(0, dashboardData.opportunityStatusList.opportunityAmount.length),
      },
    ],
  });
}, [dashboardData.opportunityStatusList]);

const options3 = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'right',
    },
  },
};


const onchangedata = (e) => {
  const { name, value } = e.target;
  if (name === 'inputFromDate') {
    setinputFromDate(value);
  } else if (name === 'inputToDate') {
    setinputToDate(value);
  } else if (name === 'inputTeam') {
    setInputTeam(value);
  } else if (name === 'inputSalesPerson') {
    setInputSalesPerson(value);
  }
};
// date filters
const [formData, setFormData] = useState({
  inputFromDate: '',
  inputToDate: '',
  inputTeam: '',
  inputSalesPerson: '',
  inputLeadStage:'',
  inputLeadSource:'',
  inputOpportunityStatus:'',
  inputOpportunityReason:'',
});
const [inputFromDate, setinputFromDate] = useState(null);
const [inputToDate, setinputToDate] = useState(null);
const [inputSalesPerson, setInputSalesPerson] = useState('');
const [SalesPersonname, setSalesPersonname] = useState('');
const [inputTeam, setInputTeam] = useState('');
const [inputLeadStage, setInputLeadStage] = useState('');
const [inputLeadSource, setInputLeadSource] = useState('');
const [inputOpportunityStatus, setInputOpportunityStatus] = useState('');
const [inputOpportunityReason, setInputOpportunityReason] = useState('');

// drodpdoen team
const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
const [inputTeamOptions, setinputTeamOptions] = useState([]);
const onchangeDropDown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setInputTeam(value);
  CRMdashboard();
};
useEffect(() => {
  fetchDropdownTeam();
}, []);
const fetchDropdownTeam = async () => {
  const Options = await fetchClassCodeDS("AB/10");
  setinputTeamOptions(Options);
};

useEffect(() => {
  CRMdashboard();
}, [inputFromDate, inputToDate, inputSalesPerson, inputTeam]);


// ADVANCE SEARCH
const [SalesPersonPopupVisible, setSalesPersonPopupVisible] = useState(false);
const [addressTypeSales, setaddressTypeSales] = useState("E");
const [showSearchIcon, setShowSearchIcon] = useState(false);

const openSalesPersonPopup = () => {
  setSalesPersonPopupVisible(true);
};

const handleSelectSalesPerson = (data) => {
  setSalesPersonname(data.ab_AD_NM);
  setInputSalesPerson(data.abAdNo);
  setSalesPersonPopupVisible(false);
  setShowSearchIcon(false);
};

return (
   
  <div style={{ marginTop: "5%",marginLeft:"1%" }}>
  {/* 1st Box List */}
  <div className="col-12 d-flex">
    <div className="col-6 d-flex flex-column">
      <div className="month-year d-flex justify-content-between text-white text-center">
        <div className="box" style={headerbox_odd} >
        <div>
        <div style={boxtextstyle}>Sales Target</div>
        <div style={boxtextstyle}>{dashboardData.salesTargetMonth}</div>
        </div>
        </div>
        <div className="box" style={headerbox_even}>
        <div>
        <div style={boxtextstyle}>Actual</div>
        <div style={boxtextstyle}>{dashboardData.salesActualMonth}</div>
        </div>
        </div>
        <div className="box" style={headerbox_odd}>
        <div>
        <div style={boxtextstyle}>Achieved</div>
        <div style={boxtextstyle}>{dashboardData.salesAchievedMonth} %</div>
        </div>
        </div>
        <div className="box" style={headerbox_even}>
        <div>
            <div style={{ fontSize: "18px", fontFamily: "DM Sans" , color: "#000000" }}>
            <label htmlFor="months"></label>
             <Dropdown
              style={{ height:"30%" ,alignContent:"center",backgroundColor: "#d5fff2",border:"none",fontWeight:"bold",fontSize:"1%"}}
              value={selectedMonth}
              options={[
                { label: 'January', value: 'JAN' },
                { label: 'February', value: 'FEB' },
                { label: 'March', value: 'MAR' },
                { label: 'April', value: 'APR' },
                { label: 'May', value: 'MAY' },
                { label: 'June', value: 'JUN' },
                { label: 'July', value: 'JUL' },
                { label: 'August', value: 'AUG' },
                { label: 'September', value: 'SEP' },
                { label: 'Octomber', value: 'OCT' },
                { label: 'November', value: 'NOV' },
                { label: 'December', value: 'DEC' }
              ]}
              onChange={(e) => setSelectedMonth(e.value)}
              placeholder="Select a Month"
              className="p-mb-2"
            />
            </div>
        </div>
        </div>
      </div>
    </div>
    <div className="col-6 d-flex flex-column">
      <div className="month-year d-flex justify-content-between text-white text-center">
        <div className="box" style={headerbox_odd}>
        <div>
            <div style={boxtextstyle}>Sales Target</div>
            <div style={boxtextstyle}>{dashboardData.salesTargetYear}</div>
        </div>
        </div>
        <div className="box" style={headerbox_even}>
        <div>
            <div style={boxtextstyle}>Actual</div>
            <div style={boxtextstyle}>{dashboardData.salesActualYear}</div>
        </div>
        </div>
        <div className="box" style={headerbox_odd}>
        <div>
            <div style={boxtextstyle}>Achieved</div>
            <div style={boxtextstyle}>{dashboardData.salesAchievedYear}%</div>
        </div>
        </div>
        <div className="box" style={headerbox_even}>
          <div>
            <label htmlFor="Years"></label>
            <Dropdown
              style={{border:"none" , height:"100%" , width:"100%",backgroundColor: "#d5fff2",border:"none",fontWeight:"bold",fontSize:"1%"}}
              id="years"
              value={selectedYear}
              options={years}
              onChange={handleYearChange}
              placeholder="Select Year"
            />
            {selectedYear && <p>{selectedYear.label}</p>}
          </div>
        </div>
      </div>
    </div>
  </div> 

  {/* 1stbox Chart*/}
  <div className="row">
    <div className="col-12 d-flex align-items-center" style={{display:'flex',justifyContent:'space-between'}}>
    <div style={{display:"flex",justifyContent:"space-between",marginLeft:"1%"}}>
        <div className="field col-lg-4">
            <Calendar
            style={{height:"3vh"}}
              id="inputFromDate"
              name="inputFromDate"
              value={moment(inputFromDate, "YYYYMMDD").isValid() ? moment(inputFromDate, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: {name: "inputFromDate", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
              dateFormat="dd/mm/yy"
              placeholder="From Date"
            />
        </div>
        <div className="field col-lg-4">
              <Calendar
               style={{height:"3vh"}}
                id="inputToDate"
                name="inputToDate"
                value={moment(inputToDate, "YYYYMMDD").isValid() ? moment(inputToDate, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "inputToDate", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
                placeholder="To Date"
              />
        </div>
        </div>
        <div style={{display:"flex",justifyContent:"space-around",marginRight:"5%"}}>
          <div className="field col-lg-4" style={{ display: 'flex', alignItems: 'center' }}>
            <Dropdown
            style={{height:"3vh",paddingTop: "0",lineHeight: "0vh"}}
              id="inputTeam"
              name="inputTeam"
              value={inputTeam}
              options={inputTeamOptions}
              onChange={(e) => onchangeDropDown("inputTeam", e.value)}
              placeholder="Select Team"
            />
            </div>
            <div className="field col-lg-4">
                <div style={{ display: "flex" }}>
                <InputText
                style={{height:"3vh"}}
                    id="SalesPersonname"
                    name="SalesPersonname"
                    onFocus={() => setShowSearchIcon(true)}
                    onChange={(e) => setInputSalesPerson(e.target.value)}
                    value={SalesPersonname}
                    placeholder="Select Sales Person"
                  />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openSalesPersonPopup}
                      ></i>
                    )}
                    <InputText
                    style={{display:"none"}}
                        id="inputSalesPerson"
                        name="inputSalesPerson"
                        onFocus={() => setShowSearchIcon(true)}
                        onChange={(e) => setInputSalesPerson(e.target.value)}
                        value={inputSalesPerson}
                      />
                </div>
            </div>
        </div>
    </div>
  </div>
  <div style={{ display: "flex", height:"30vh" }}>
    {/* analysis by stage */}
    <div style={{ flex: 1,marginLeft:"1%", marginRight: "10px" ,marginBottom:"10px",borderRadius:"10px",backgroundColor:"white"}}>
      <div style={{ height: "13%", textAlign: "center", fontSize: "150%", fontWeight: "bold", marginTop: "3%", marginRight:"15%" }}>Lead By Stage</div>
        <div style={{height:"75%",marginTop:"1%"}}>
          <Pie data={data1} options={options1} style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF", backgroundColor: "white", height: "50%" }} />
        </div>
    </div>
    {/* analysis by source */}
    <div style={{ flex: 1, marginLeft:"1%",marginBottom:"10px",borderRadius:"10px",backgroundColor:"white"}}>
      <div style={{ height: "13%", textAlign: "center", fontSize: "150%", fontWeight: "bold", marginTop: "3%", marginRight:"15%" }}>Lead By Source</div>
        <div style={{height:"75%",marginTop:"1%"}}>
           <Pie data={data2} options={options2} style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF", backgroundColor: "white", height: "50%" }} />
        </div>
    </div>
  </div>

  {/* 2nd Box List */}
  <div className="col-12 d-flex">
    <div className="col-6 d-flex flex-column">
      <div className="month-year d-flex justify-content-between text-white text-center" style={{marginTop:"1%"}} >
        <div className="box" style={headerbox_odd} onClick={openStageSource}>
        <div>
          <div style={boxtextstyle}>Lead Capture</div>
          <div style={boxtextstyle}>{dashboardData.stageLeadCaptureCount}</div>
        </div>
        </div>
        <div className="box" style={headerbox_even} onClick={openStageSource}>
          <div>
          <div style={boxtextstyle}>Lead Nourishment</div>
            <div style={boxtextstyle}>{dashboardData.stageLeadNourishingCount} </div>  
        </div>
        </div>
        <div className="box" style={headerbox_odd} onClick={openStageSource}>
          <div>
          <div style={boxtextstyle}>Lead Qualified</div>
            <div style={boxtextstyle}>{dashboardData.stageLeadQualificationCount}</div>
        </div>
        </div>
        <div className="box" style={headerbox_even} onClick={openStageSource}>
          <div>
            <div style={boxtextstyle}>Lead Conversion</div>
            <div style={boxtextstyle}>{dashboardData.stageLeadConversionCount}</div>
        </div>
        </div>
      </div>
    </div>
    <div className="col-6 d-flex flex-column">
      <div className="month-year d-flex justify-content-between text-white text-center" style={{marginTop:"1%"}} >
        <div className="box" style={headerbox_odd1}>
          <div>
          <div style={boxtextstyle}>Email</div>
            <div style={boxtextstyle}>{dashboardData.sourceEmailCount}</div>   
        </div>
        </div>
        <div className="box" style={headerbox_even1}>
          <div>
          <div style={boxtextstyle}>Calls</div>
            <div style={boxtextstyle}>{dashboardData.sourceCallCount}</div>
        </div>
        </div>
        <div className="box" style={headerbox_odd1}>
          <div>
          <div style={boxtextstyle1}>Whatsapp</div>
            <div style={boxtextstyle}>{dashboardData.sourceWhatsappCount}</div>
        </div>
        </div>
        <div className="box" style={headerbox_even1}>
          <div>
          <div style={boxtextstyle}>Website</div>
            <div style={boxtextstyle}>{dashboardData.sourceWebsiteCount}</div>   
        </div>
        </div>
        <div className="box" style={headerbox_odd1}>
          <div>
          <div style={boxtextstyle1}>Referral</div>
            <div style={boxtextstyle}>{dashboardData.sourceReferralCount}</div>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div className="col-12 d-flex">
    <div className="col-6 d-flex flex-column">
      <div style={{ textAlign: "center", fontSize: "150%", fontWeight: "bold", marginTop: "3%" }}>Opportunity Analysis</div>
        <div className="month-year d-flex justify-content-between text-white text-center" style={{marginTop:"1%"}} >
        <div className="box" style={headerbox_odd2} onClick={openStatusReason}>
        <div>
          <div style={boxtextstyle}>Total</div>
          <div style={boxtextstyle}>{dashboardData.opportunityTotal}</div>
        </div>
        </div>
        <div className="box" style={headerbox_even2}>
          <div>
          <div style={boxtextstyle}>Converted</div>
            <div style={boxtextstyle}>{dashboardData.opportunityConverted} </div>  
        </div>
        </div>
        <div className="box" style={headerbox_odd2}>
          <div>
          <div style={boxtextstyle}>Lost</div>
            <div style={boxtextstyle}>{dashboardData.opportunityLost}</div>
        </div>
        </div>
        <div className="box" style={headerbox_even2}>
          <div>
            <div style={boxtextstyle}>Conversion</div>
            <div style={boxtextstyle}>{dashboardData.opportunityConversion}</div>
        </div>
        </div>
        </div>
        <div style={{ height: "27vh", marginTop: "1%" }}>
          <Pie data={data3} options={options3} style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF", backgroundColor: "white", height: "50%" }} />
        </div>
    </div>
    {/* header right */}
    <div className="col-6 d-flex flex-column">
      <div style={{ textAlign: "center", fontSize: "150%", fontWeight: "bold", marginTop: "3%" }}>Loss Analysis</div>
        <div className="month-year d-flex justify-content-between text-white text-center" style={{marginTop:"1%"}} >
        <div className="box" style={headerbox_odd2}>
          <div style={lossanalysisstyle}>
            <div style={boxtextstyle}>{dashboardData.lostBetterAlternativeReasonDesc}</div>
            <div style={boxtextstyle}>{dashboardData.lostBetterAlternativeAmount}</div>
          </div>
        </div>
        <div className="box" style={headerbox_even2}>
          <div style={lossanalysisstyle}>
            <div style={boxtextstyle}>{dashboardData.lostBudgetConstraintReasonDesc} </div>  
            <div style={boxtextstyle}>{dashboardData.lostBudgetConstraintAmount}</div>
          </div>
        </div>
        <div className="box" style={headerbox_odd2}>
          <div style={lossanalysisstyle}>
            <div style={boxtextstyle}>{dashboardData.lostIntegrationChallengeReasonDesc}</div>
            <div style={boxtextstyle}>{dashboardData.lostIntegrationChallengeAmount}</div>
          </div>
        </div>
        <div className="box" style={headerbox_even2}>
          <div style={lossanalysisstyle}>
            <div style={boxtextstyle}>{dashboardData.lostPriorityMismatchReasonDesc}</div>
            <div style={boxtextstyle}>{dashboardData.lostPriorityMismatchAmount}</div>
          </div>
        </div>
        <div className="box" style={headerbox_odd2}>
          <div style={lossanalysisstyle}>
            <div style={boxtextstyle}>{dashboardData.lostTimeConstraintReasonDesc}</div>
            <div style={boxtextstyle}>{dashboardData.lostTimeConstraintAmount}</div>
          </div>
        </div>
        </div>
        <div style={{ flex: 1, marginLeft:"1%",marginTop:"1%",borderRadius:"10px",backgroundColor:"white"}}>
          <div style={{height:"25vh",marginTop:"1%"}}>
            <canvas ref={barChartRef1} id="barChart1"  style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF"}} />
          </div>
        </div>
    </div>
  </div>

  <StageSourceDetails
    selectedMonth={selectedMonth}
    selectedYear={selectedYear}
    inputFromDate={inputFromDate}
    inputToDate={inputToDate}
    inputSalesPerson={inputSalesPerson}
    inputTeam={inputTeam}
    inputLeadStage={inputLeadStage}
    inputLeadSource={inputLeadSource}
    inputOpportunityStatus={inputOpportunityStatus}
    inputOpportunityReason={inputOpportunityReason}
    open={open}
    setOpen={setOpen}
    visible={StageSourceDetailsVisible}
    onClose={() => setStageSourceDetailsVisible(false)}
  />
 <StatusReasonDetails
    selectedMonth={selectedMonth}
    selectedYear={selectedYear}
    inputFromDate={inputFromDate}
    inputToDate={inputToDate}
    inputSalesPerson={inputSalesPerson}
    inputTeam={inputTeam}
    inputLeadStage={inputLeadStage}
    inputLeadSource={inputLeadSource}
    inputOpportunityStatus={inputOpportunityStatus}
    inputOpportunityReason={inputOpportunityReason}
    visible={StatusReasonDetailsVisible}
    onClose={() => setStatusReasonDetailsVisible(false)}
  />
    {/* sales person */}
    <AddressBookPopup
      visible={SalesPersonPopupVisible}
      onClose={() => setSalesPersonPopupVisible(false)}
      onDataSelect={handleSelectSalesPerson}
      addressType={addressTypeSales}
    />
  </div>
  );
};
export default CRMOverview;
