import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function DisruptionMasterDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  dm_CL_01,
  dm_CL_02,
  dm_CL_03,
  dm_CL_04,
  dm_CL_05,
  setdm_CL_01,
  setdm_CL_02,
  setdm_CL_03,
  setdm_CL_04,
  setdm_CL_05,
}) {
  const {
    dmUkNo,
    dm_DI_CD,
    dm_DI_DS,
    dm_AR_EA,
  } = data;

// DROPDOWNS
const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();

const [dm_CL_01Options, setdm_CL_01Options] = useState([]);
const [dm_CL_02Options, setdm_CL_02Options] = useState([]);
const [dm_CL_03Options, setdm_CL_03Options] = useState([]);
const [dm_CL_04Options, setdm_CL_04Options] = useState([]);
const [dm_CL_05Options, setdm_CL_05Options] = useState([]);

const onchangeDropDownFormData1 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setdm_CL_01(value);
};
const onchangeDropDownFormData2 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setdm_CL_02(value);
};
const onchangeDropDownFormData3 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setdm_CL_03(value);
};
const onchangeDropDownFormData4 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setdm_CL_04(value);
};
const onchangeDropDownFormData5 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setdm_CL_05(value);
};
  useEffect(() => {
    fetchDropdown1();
    fetchDropdown2();
    fetchDropdown3();
    fetchDropdown4();
    fetchDropdown5();
    }, []);

  const fetchDropdown1 = async () => {
    const Options = await fetchClassCodeDS("DI/1");
    setdm_CL_01Options(Options);
  };
  const fetchDropdown2 = async () => {
    const Options = await fetchClassCodeDS("DI/2");
    setdm_CL_02Options(Options);
  };
  const fetchDropdown3 = async () => {
    const Options = await fetchClassCodeDS("DI/3");
    setdm_CL_03Options(Options);
  };
  const fetchDropdown4 = async () => {
    const Options = await fetchClassCodeDS("DI/4");
    setdm_CL_04Options(Options);
  };
  const fetchDropdown5 = async () => {
    const Options = await fetchClassCodeDS("DI/5");
    setdm_CL_05Options(Options);
  };

const isUpdateMode = Boolean(dmUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dmUkNo ? "Revise Disruption Master" : "Add Disruption Master"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
        }}
      >
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
              <div style={{display:"none"}}>
                  <label htmlFor="dmUkNo" className="font-bold">
                    UK_NO
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="dmUkNo"
                      name="dmUkNo"
                      value={dmUkNo}
                    />
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dm_DI_CD" className="font-bold">
                    Disruption Code
                  </label>
                    <InputText
                      id="dm_DI_CD"
                      name="dm_DI_CD"
                      value={dm_DI_CD}
                      onChange={(e) => onchangedata(e)}
                    />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dm_DI_DS" className="font-bold">
                    Description
                  </label>
                  <InputText
                      id="dm_DI_DS"
                      name="dm_DI_DS"
                      value={dm_DI_DS}
                      onChange={(e) => onchangedata(e)}
                    />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dm_AR_EA" className="font-bold">
                    Area
                  </label>
                  <InputText
                      id="dm_AR_EA"
                      name="dm_AR_EA"
                      value={dm_AR_EA}
                      onChange={(e) => onchangedata(e)}
                    />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dm_CL_01" className="font-bold">
                    Class Code 1
                  </label>
                  <Dropdown
                    name="dm_CL_01"
                    value={dm_CL_01}
                    options={dm_CL_01Options}
                    onChange={(e) => onchangeDropDownFormData1("dm_CL_01", e.value)}
                    placeholder="Select Class Code 1"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dm_CL_02" className="font-bold">
                   Class Code 2
                  </label>
                  <Dropdown
                    name="dm_CL_02"
                    value={dm_CL_02}
                    options={dm_CL_02Options}
                    onChange={(e) => onchangeDropDownFormData2("dm_CL_02", e.value)}
                    placeholder="Select Class Code 2"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dm_CL_03" className="font-bold">
                 Class Code 3
                  </label>
                  <Dropdown
                    name="dm_CL_03"
                    value={dm_CL_03}
                    options={dm_CL_03Options}
                    onChange={(e) => onchangeDropDownFormData3("dm_CL_03", e.value)}
                    placeholder="Select Class Code 3"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dm_CL_04" className="font-bold">
                    Class Code 4
                  </label>
                  <Dropdown
                    name="dm_CL_04"
                    value={dm_CL_04}
                    options={dm_CL_04Options}
                    onChange={(e) => onchangeDropDownFormData4("dm_CL_04", e.value)}
                    placeholder="Select Class Code 4"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dm_CL_05" className="font-bold">
                    Class Code 5
                  </label>
                  <Dropdown
                    name="dm_CL_05"
                    value={dm_CL_05}
                    options={dm_CL_05Options}
                    onChange={(e) => onchangeDropDownFormData5("dm_CL_05", e.value)}
                    placeholder="Select Class Code 4"
                  />
                </div>
             
              </div>
            </div>
      </div> 
    </Dialog>
  );
}
