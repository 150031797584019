import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { Pie } from 'react-chartjs-2';
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import './styledash.css';
import LeaveStatusDetails from "./LeaveStatusDetails";
import PassportDetails from "./PassportDetails";
import VisaDetails from "./VisaDetails";

const HCMOverview = () => {
  const [dashboardData, setDashboardData] = useState({
    totalHeadCount: "",
    totalHireCount: "",
    totalAttritionCount: "",
    totalEmployeeTurnOver: "",
    totalSalary: "",
    totalOvertime: "",
    departmentSalaryDetails: {
      salaryDepartmentDesc: [],
      salaryTotal: [],
      salaryOvertime: [],
    },
    departmentLeaveDetails:[],
    departmentPassportDetails:[],
    departmentVisaDetails:[],
    totalVisa:"",

  });
  const [open, setOpen] = useState(false);
  const [LeaveStatusDetailsVisible, setLeaveStatusDetailsVisible] = useState(false);
  const [PassportDetailsVisible, setPassportDetailsVisible] = useState(false);
  const [VisaDetailsVisible, setVisaDetailsVisible] = useState(false);
  const openLeaveStatus = () => {
    setLeaveStatusDetailsVisible(true);
  };
  const openPassportDetails = () => {
    setPassportDetailsVisible(true);
  };
  const openVisaDetails = () => {
    setVisaDetailsVisible(true);
  };
// stylings
const headerbox_odd = {
  backgroundColor: "#c5f2ffcc",
  color: "#000000", 
  borderRadius: "17px", 
  width: "11%", 
  height: "80px",
  padding: "15px 20px", 
  display: "flex",
  justifyContent: "center", 
};
const headerbox_even = {
  backgroundColor: "#d5fff2",
  color: "#000000", 
  borderRadius: "17px", 
  width: "11%", 
  height: "80px",
  padding: "15px 20px", 
  display: "flex",
  justifyContent: "center", 
}
const headerbox_odd1 = {
  backgroundColor: "#c5f2ffcc",
  color: "#000000", 
  borderRadius: "17px", 
  width: "33%", 
  height: "80px",
  padding: "15px 20px", 
  display: "flex",
  justifyContent: "center", 
};
const headerbox_even1 = {
  backgroundColor: "#d5fff2",
  color: "#000000", 
  borderRadius: "17px", 
  width: "33%", 
  height: "80px",
  padding: "15px 20px", 
  display: "flex",
  justifyContent: "center", 
};
const boxtextstyle ={
  fontFamily: "DM Sans",
  color: "black",
  fontSize:"small",
  fontWeight:"bold"
}; 
const boxtextstyle1 ={
  fontFamily: "DM Sans",
  color: "black",
  fontSize:"small",
  fontWeight:"bold",
  width:"120%",
  marginLeft:"-10%",
};  
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const getCurrentMonth = () => {
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const currentMonthIndex = new Date().getMonth();
    return monthNames[currentMonthIndex];
  };
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [selectedYear, setSelectedYear] = useState("");
  const handleYearChange = (e) => {
    setSelectedYear(e.value);
    HCMDashboard();
  };
  const years = [
    { label: 2022, value: 2022 },
    { label: 2023, value: 2023 },
    { label: 2024, value: 2024 }
  ];
  useEffect(() => {
    let newDate = new Date();
    if (selectedMonth === null || selectedMonth === "") {
      let month = newDate.getMonth() + 1;
      setSelectedMonth(month);
    }
    if (selectedYear === null || selectedYear === "") {
      let year = newDate.getFullYear();
      setSelectedYear(year);
    }
    if (selectedMonth !== null && selectedYear !== null) {
      HCMDashboard();
    }
  }, [selectedMonth, selectedYear]);
  const dashboard = `${domain}/api/Dashboard/HCMDashboard`;

  const HCMDashboard = async () => {
    const requestinput = { "year": selectedYear, "month": selectedMonth };
    try {
      const response = await axios.post(dashboard, requestinput, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const data = response.data;
      setDashboardData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  const barChartRef1 = useRef(null);
  const barChartRef2 = useRef(null);
  const barChartRef3 = useRef(null);
  const barChartRef4 = useRef(null);
  const barChartRef5 = useRef(null);

  const barchartOptions = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: { drawOnChartArea: false, display: false, drawBorder: false },
        ticks: { color: 'black', font: { size: 8 }, },
      },
      y: {
        grid: { drawBorder: true, drawOnChartArea: false, display: false },
        beginAtZero: true,
        min: 0,
        ticks: { stepSize: 10000, font: { size: 8 }, color: 'black', },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: { font: { size: 8 } },
      },
      interaction: {
        intersect: false,
      },
    },
  };
  const initializeBarchart = (ctx, labels, dataValues) => {
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'Value',
          data: dataValues,
          backgroundColor: '#66CCFF',
          barThickness: 15,
          borderRadius: 15,
        }],
      },
      options: barchartOptions,
    });
  };

  useEffect(() => {
    // Salary bar chart(horizontal)
    const UpdateBarchartSalary = () => {
      if (!barChartRef1.current || !dashboardData.departmentSalaryDetails.salaryDepartmentDesc) return;
  
      const ctx = barChartRef1.current.getContext("2d");
      const chart = Chart.getChart(ctx);
  
      if (chart) {
        const labels = dashboardData.departmentSalaryDetails.salaryDepartmentDesc;
        const dataValues = dashboardData.departmentSalaryDetails.salaryTotal.map(item => parseFloat(item));
  
        chart.data.labels = labels;
        chart.data.datasets[0].data = dataValues;
        chart.update();
      } else {
        const labels = dashboardData.departmentSalaryDetails.salaryDepartmentDesc;
        const dataValues = dashboardData.departmentSalaryDetails.salaryTotal.map(item => parseFloat(item));
  
        initializeBarchart(ctx, labels, dataValues);
      }
    };
    // overtime barchart(horizontal)
    const UpdateBarchartOverTime = () => {
      if (!barChartRef2.current || !dashboardData.departmentSalaryDetails.salaryDepartmentDesc) return;
  
      const ctx = barChartRef2.current.getContext("2d");
      const chart = Chart.getChart(ctx);
  
      if (chart) {
        const labels = dashboardData.departmentSalaryDetails.salaryDepartmentDesc;
        const dataValues = dashboardData.departmentSalaryDetails.salaryOvertime.map(item => parseFloat(item));
  
        chart.data.labels = labels;
        chart.data.datasets[0].data = dataValues;
        chart.update();
      } else {
        const labels = dashboardData.departmentSalaryDetails.salaryDepartmentDesc;
        const dataValues = dashboardData.departmentSalaryDetails.salaryOvertime.map(item => parseFloat(item));
  
        initializeBarchart(ctx, labels, dataValues);
      }
    };
    if (dashboardData && dashboardData.departmentSalaryDetails) {
      UpdateBarchartSalary();
      UpdateBarchartOverTime();
    }
  }, [dashboardData]);
// 2nd row bar charts
const barchartOptions1 = {
  indexAxis: 'y',
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: { drawOnChartArea: false, display: false, drawBorder: false },
      ticks: { color: 'black', font: { size: 8 }, },
    },
    y: {
      grid: { drawBorder: true, drawOnChartArea: false, display: false },
      beginAtZero: true,
      min: 0,
      ticks: { stepSize: 10000, font: { size: 8 }, color: 'black' },
    },
  },
  plugins: {
    legend: {
      display: false,
      labels: { font: { size: 8 } },
    },
    interaction: {
      intersect: false,
    },
  },
};

const initializeBarchart1 = (ctx, labels, dataValues) => {
  new Chart(ctx, {
    type: 'bar',
    data: {
      labels: labels,
      datasets: [{
        label: 'Value',
        data: dataValues,
        backgroundColor: '#66CCFF',
        barThickness: 15,
        borderRadius: 15,
      }],
    },
    options: barchartOptions1,
  });
}
  useEffect(() => {
    // Leave Status bar chart(vertical)
    const UpdateBarchartLeaveSttaus = () => {
      if (!barChartRef3.current || !dashboardData.departmentLeaveDetails.leaveDepartmentDesc) return;
  
      const ctx = barChartRef3.current.getContext("2d");
      const chart = Chart.getChart(ctx);
  
      if (chart) {
        const labels = dashboardData.departmentLeaveDetails.leaveDepartmentDesc;
        const dataValues = dashboardData.departmentLeaveDetails.leaveCount.map(item => parseFloat(item));
  
        chart.data.labels = labels;
        chart.data.datasets[0].data = dataValues;
        chart.update();
      } else {
        const labels = dashboardData.departmentLeaveDetails.leaveDepartmentDesc;
        const dataValues = dashboardData.departmentLeaveDetails.leaveCount.map(item => parseFloat(item));
  
        initializeBarchart1(ctx, labels, dataValues);
      }
    };
    // Passport barchart(vertical)
    const UpdateBarchartPassport = () => {
      if (!barChartRef4.current || !dashboardData.departmentPassportDetails.passportDepartmentDesc) return;
  
      const ctx = barChartRef4.current.getContext("2d");
      const chart = Chart.getChart(ctx);
  
      if (chart) {
        const labels = dashboardData.departmentPassportDetails.passportDepartmentDesc;
        const dataValues = dashboardData.departmentPassportDetails.passportCount.map(item => parseFloat(item));
  
        chart.data.labels = labels;
        chart.data.datasets[0].data = dataValues;
        chart.update();
      } else {
        const labels = dashboardData.departmentPassportDetails.passportDepartmentDesc;
        const dataValues = dashboardData.departmentPassportDetails.passportCount.map(item => parseFloat(item));
  
        initializeBarchart1(ctx, labels, dataValues);
      }
    };
    // visa bar chart (vertical)
    const UpdateBarchartvisa = () => {
      if (!barChartRef5.current || !dashboardData.departmentVisaDetails.visaDepartmentDesc) return;
  
      const ctx = barChartRef5.current.getContext("2d");
      const chart = Chart.getChart(ctx);
  
      if (chart) {
        const labels = dashboardData.departmentVisaDetails.visaDepartmentDesc;
        const dataValues = dashboardData.departmentVisaDetails.visaCount.map(item => parseFloat(item));
  
        chart.data.labels = labels;
        chart.data.datasets[0].data = dataValues;
        chart.update();
      } else {
        const labels = dashboardData.departmentVisaDetails.visaDepartmentDesc;
        const dataValues = dashboardData.departmentVisaDetails.visaCount.map(item => parseFloat(item));
  
        initializeBarchart1(ctx, labels, dataValues);
      }
    };

    if (dashboardData && dashboardData.departmentLeaveDetails && dashboardData.departmentPassportDetails && dashboardData.departmentVisaDetails) {
      UpdateBarchartLeaveSttaus();
      UpdateBarchartPassport();
      UpdateBarchartvisa();
    }
  }, [dashboardData]);
  
// pie chart
  const [data1, setData1] = useState({
    labels: [],
    datasets: [
      {
        label: 'Attritions',
        data: [],
        backgroundColor: [ '#FBE9E7','#F1F8E9','#E8F5E9','#E0F7FA','#E3F2FD','#E3F2FD','#FFEBEE','#D5F5E3','#E8F8F5','#EAF2F8'],
      },
    ],
  });
  
  useEffect(() => {
    if (!dashboardData.departmentAttritionDetails || !dashboardData.departmentAttritionDetails.attritionDepartmentDesc) return;
  
    setData1({
      labels: dashboardData.departmentAttritionDetails.attritionDepartmentDesc,
      datasets: [
        {
          label: 'Attritions',
          data: dashboardData.departmentAttritionDetails.attritionPercentage,
          // backgroundColor: ['#99FFFF', '#d5fff2', '#66CCFF', 'red', 'green', 'blue'],
          backgroundColor: generateBackgroundColors(dashboardData.departmentAttritionDetails.attritionDepartmentDesc.length),
        },
      ],
    });
  }, [dashboardData.departmentAttritionDetails]);

  const generateBackgroundColors = (length) => {
    // Example of generating random colors, adjust as needed
    const colors = ['#FBE9E7', '#F1F8E9', '#E8F5E9', '#E0F7FA', '#E3F2FD', '#E3F2FD', '#FFEBEE', '#D5F5E3', '#E8F8F5', '#EAF2F8'];
    return colors.slice(0, length);
  };
  const options1 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
    },
  };

  return (
    <div style={{ marginTop: "5%", marginLeft: "1%" }}>
      {/* 1st Box List */}
      <div className="month-year d-flex justify-content-between text-white text-center">
      <div className="box" style={headerbox_odd} >
      <div>
      <div style={boxtextstyle1}>Head Counts</div>
      <div style={boxtextstyle}>{dashboardData.totalHeadCount}</div>
      </div>
      </div>
      <div className="box" style={headerbox_even}>
      <div>
      <div style={boxtextstyle}>Hires</div>
      <div style={boxtextstyle}>{dashboardData.totalHireCount}</div>
      </div>
      </div>
      <div className="box" style={headerbox_odd}>
      <div>
      <div style={boxtextstyle}>Attritions</div>
      <div style={boxtextstyle}>{dashboardData.totalAttritionCount}</div>
      </div>
      </div>
      <div className="box" style={headerbox_even}>
      <div>
          <div style={boxtextstyle}>Attrition </div>
          <div style={boxtextstyle}>{dashboardData.totalAttritionCount}  % </div>
      </div>
      </div>
      <div className="box" style={headerbox_odd}>
      <div>
          <div style={boxtextstyle}>Salary</div>
          <div style={boxtextstyle}>{dashboardData.totalSalary}</div>
      </div>
      </div>
      <div className="box" style={headerbox_even}>
      <div>
          <div style={boxtextstyle}>Overtime</div>
          <div style={boxtextstyle}>{dashboardData.totalOvertime}</div>
      </div>
      </div>
      <div className="box" style={headerbox_odd}>
      <div>
        <div style={{ fontSize: "18px", fontFamily: "DM Sans" , color: "#000000" }}>
        <label htmlFor="months"></label>
          <Dropdown
           style={{ height:"30%" ,alignContent:"center",backgroundColor: "#c5f2ffcc",border:"none",fontWeight:"bold",fontSize:"1%"}}
            value={selectedMonth}
            options={[
              { label: 'January', value: 'JAN' },
              { label: 'February', value: 'FEB' },
              { label: 'March', value: 'MAR' },
              { label: 'Apirl', value: 'APR' },
              { label: 'May', value: 'MAY' },
              { label: 'June', value: 'JUN' },
              { label: 'July', value: 'JUL' },
              { label: 'August', value: 'AUG' },
              { label: 'September', value: 'SEP' },
              { label: 'Octomber', value: 'OCT' },
              { label: 'November', value: 'NOV' },
              { label: 'December', value: 'DEC' }
            ]}
            onChange={(e) => setSelectedMonth(e.value)}
            placeholder="Select a Month"
            className="p-mb-2"
          />
        </div>
      </div>
      </div>
      <div className="box" style={headerbox_even}>
      <div>
          <label htmlFor="Years"></label>
          <Dropdown
            style={{border:"none" , height:"100%" , width:"100%",backgroundColor: "#d5fff2",border:"none",fontWeight:"bold",fontSize:"1%"}}
            id="years"
            value={selectedYear}
            options={years}
            onChange={handleYearChange}
            placeholder="Select Year"
          />
          {selectedYear && <p>{selectedYear.label}</p>}
          </div>
      </div>
      </div> 

      {/* 1stbox Chart */}
      <div style={{ display: "flex", marginTop: "2%", height: "30vh" }}>
        <div style={{ flex: 1, marginRight: "10px", marginBottom: "10px", borderRadius: "10px", backgroundColor: "white" }}>
          <div style={{ height: "13%", textAlign: "center", fontSize: "120%", fontWeight: "bold", marginTop: "3%" }}>Salary By Department </div>
            <div style={{ height: "75%", marginTop: "1%" }}>
              <canvas ref={barChartRef1} id="barChart1" style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF" }} />
            </div>
        </div>

        <div style={{ flex: 1, marginRight: "10px",marginBottom: "10px", backgroundColor: "white", borderRadius: "10px" }}>
          <div style={{ height: "13%", textAlign: "center", fontSize: "120%", fontWeight: "bold", marginTop: "3%" }}>OverTime By Department </div>
            <div style={{ height: "75%", marginTop: "1%" }}>
              <canvas ref={barChartRef2} id="barChart2" style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF" }} />
            </div>
          </div>

        <div style={{ flex: 1, marginBottom: "10px", borderRadius: "10px", backgroundColor: "white" }}>
          <div style={{ height: "13%", textAlign: "center", fontSize: "120%", fontWeight: "bold", marginTop: "3%" }}>Attrition By Department</div>
            <div style={{ height: "75%", marginTop: "1%" }}>
              <Pie data={data1} options={options1} style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF", backgroundColor: "white", height: "50%" }} />
            </div>
        </div>
      </div>

      {/* 2nd Box List */}
      <div className="month-year d-flex justify-content-between text-white text-center" style={{marginTop:"1%"}} >
      <div className="box" style={headerbox_odd1} onClick={openLeaveStatus}>
      <div>
        <div style={boxtextstyle}>Leave</div>
        <div style={boxtextstyle}>{dashboardData.totalLeave}</div>
      </div>
      </div>
      <div className="box" style={headerbox_even1} onClick={openPassportDetails}>
        <div>
        <div style={boxtextstyle}>Passport Expiry</div>
          <div style={boxtextstyle}>{dashboardData.totalPassport} </div>  
      </div>
      </div>
      <div className="box" style={headerbox_odd1} onClick={openVisaDetails}>
        <div>
        <div style={boxtextstyle}>Visa Expiry</div>
          <div style={boxtextstyle}>{dashboardData.totalVisa}</div>
        </div>
      </div>
      </div>

      {/* Line graph and second pie chart */}
      <div style={{ display: "flex", marginTop: "2%", height: "30vh" }}>
        <div style={{ flex: 1, marginRight: "10px", marginBottom: "10px", borderRadius: "10px", backgroundColor: "white" }}>
          <div style={{ height: "13%", textAlign: "center", fontSize: "120%", fontWeight: "bold", marginTop: "3%" }}></div>
            <div style={{ height: "25vh", marginTop: "-5%" }}>
              <canvas ref={barChartRef3} id="barChart1" style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF" }} />
            </div>
        </div>

        <div style={{ flex: 1, marginRight: "10px", marginBottom: "10px", backgroundColor: "white", borderRadius: "10px" }}>
          <div style={{ height: "13%", textAlign: "center", fontSize: "120%", fontWeight: "bold", marginTop: "3%" }}></div>
            <div style={{ height: "25vh", marginTop: "-5%" }}>
              <canvas ref={barChartRef4} id="barChart2" style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF" }} />
            </div>
        </div>

        <div style={{ flex: 1, marginBottom: "10px", borderRadius: "10px", backgroundColor: "white" }}>
          <div style={{ height: "13%", textAlign: "center", fontSize: "120%", fontWeight: "bold", marginTop: "3%" }}></div>
            <div style={{ height: "25vh", marginTop: "-5%" }}>
              <canvas ref={barChartRef5} id="barChart2" style={{ borderRadius: "10px", border: "1px solid", color: "#FFFFFF" }} />
            </div>
        </div>
      </div>
      {/* leave status popup */}
      <LeaveStatusDetails
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        open={open}
        setOpen={setOpen}
        visible={LeaveStatusDetailsVisible}
        onClose={() => setLeaveStatusDetailsVisible(false)}
      />
      {/* Passport popup */}
      <PassportDetails
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        visible={PassportDetailsVisible}
        onClose={() => setPassportDetailsVisible(false)}
      />
      {/* visa popup */}
      <VisaDetails
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        visible={VisaDetailsVisible}
        onClose={() => setVisaDetailsVisible(false)}
      />
    </div>
  );
};

export default HCMOverview;
