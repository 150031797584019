import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
import ItemPopup from "../Popups/ItemPopup";
import '../../common/TabStyles.css';
export default function DetailPriceBreakupDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  DetailPriceBreakupTableData,
  setDetailPriceBreakupTableData,
  pd_PR_CP,
  setpd_PR_CP,
  pd_IT_PG,
  setpd_IT_PG,
  pd_CR_CD,
  setpd_CR_CD,
  pd_BS_CD,
  setpd_BS_CD,
  pd_PR_UM,
  setpd_PR_UM,
  pd_AC_IN,
  setpd_AC_IN,
  SelectedITNM,
  setSelectedITNM,
  SelectedCust,
  setSelectedCust,
  pd_CO_NO,
}) {
  const {
    pdUkNo,pd_IT_NM,pd_CU_NO,pd_FR_DT,pd_TO_DT,pd_PR_AM, } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();

  const [pd_PR_CPOptions, setpd_PR_CPOptions] = useState([]);
  const [pd_IT_PGOptions, setpd_IT_PGOptions] = useState([]);
  const [pd_CU_PGOptions, setpd_CU_PGOptions] = useState([]);
  const [pd_CR_CDOptions, setpd_CR_CDOptions] = useState([]);
  const [pd_BS_CDOptions, setpd_BS_CDOptions] = useState([]);
  const [pd_AC_INOptions, setpd_AC_INOptions] = useState([]);
  const [pd_PR_UMOptions, setpd_PR_UMOptions] = useState([]);

  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_PR_CP(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_IT_PG(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_CR_CD(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_BS_CD(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_PR_UM(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_AC_IN(value);
  };
  useEffect(() => {
    FetchDropdownPRCO();
    FetchDropdownItemGrp();
    FetchDropdownCustGrp();
    FetchDropdownCurrency();
    FetchDropdownBasis();
    FetchDropdownUM();
    FetchDropdownAC();
  }, []);

  const FetchDropdownPRCO = async () => {
    const Options = await fetchClassCodeDS("PR/CP");
    setpd_PR_CPOptions(Options);
  };
  const FetchDropdownItemGrp = async () => {
    const Options = await fetchClassCodeDS("IN/PR");
    setpd_IT_PGOptions(Options);
  };
  const FetchDropdownCustGrp = async () => {
    const Options = await fetchClassCodeDS("SO/PR");
    setpd_CU_PGOptions(Options);
  };
  const FetchDropdownCurrency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setpd_CR_CDOptions(Options);
  };
  const FetchDropdownBasis = async () => {
    const Options = await fetchClassCodeDS("PR/BC");
    setpd_BS_CDOptions(Options);
  };
  const FetchDropdownUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setpd_PR_UMOptions(Options);
  };
  const FetchDropdownAC= async () => {
    const Options = await fetchClassCodeDS("EM/ST");
    setpd_AC_INOptions(Options);
  };
  const addRow = () => {
    setDetailPriceBreakupTableData([...DetailPriceBreakupTableData, {}]);
  };
  const deleteRow = () => {
    if (DetailPriceBreakupTableData.length > 0) {
      const updatedData = [...DetailPriceBreakupTableData];
      updatedData.pop();
      setDetailPriceBreakupTableData(updatedData);
    }
  };
// advance searchs
  const [CustomerPopupVisible, setCustomerPopupVisible] =useState(false);
  const [addressTypecust, setaddressTypecust] = useState("C");
  const [ItemMulPopupVisible, setItemMulPopupVisible] =useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelectCustomer = (data) => {
    document.getElementById (`pd_CU_NO_${SelectedRowIndex}`).value =  data.abAdNo;
    document.getElementById (`SelectedCust_${SelectedRowIndex}`).value =  data.ab_AD_NM;
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openItemMulPopup = (e) => {
    setItemMulPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelectItemMul =async (Result) => {
    document.getElementById (`pd_IT_NM_${SelectedRowIndex}`).value =  Result.im_IT_NM;
    document.getElementById (`item_DESC_${SelectedRowIndex}`).value =  Result.im_IT_DS;
    setItemMulPopupVisible(false);
    setShowSearchIcon(false);
  };
  // DIALOG HEADER
  const isUpdateMode = Boolean(pdUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{pdUkNo ? "Revise Detail Price Breakup" : "Add Detail Price Breakup"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div  style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="pd_PR_CP" className="font-bold">
                  Price Component
                </label>
                  <Dropdown
                    id="pd_PR_CP"
                    name="pd_PR_CP"
                    value={pd_PR_CP}
                    options={pd_PR_CPOptions}
                    onChange={(e) => onchangeDropDownFormData1("pd_PR_CP", e.value)}
                    placeholder="Select Price Component"
                  />
              </div>
          </div>
        </div>  
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="DetailPriceBreakuptable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Item Number <span style={{color:'red'}}>*</span> </th>
                <th>Description</th>
                <th>Customer <span style={{color:'red'}}>*</span> </th>
                <th>Name</th>
                <th>Item Group</th>
                <th>Customer Group</th>
                <th>From Date</th>
                <th>To Date</th>
                <th>Currency</th>
                <th>Amount</th>
                <th>Basis</th>
                <th>UM</th>
                <th>Active/Inactive</th>
              </tr>
            </thead>
            <tbody>
              {DetailPriceBreakupTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`pdUkNo_${index}`}
                      name={`pdUkNo_${index}`}
                      value={row.pdUkNo}
                    />
                  </td>
                      <td >
                        <div style={{ display: "flex",}}>
                          <InputText style={{width:'10rem'}}
                              id={`pd_IT_NM_${index}`}
                              name={`pd_IT_NM_${index}`}
                              onChange={(e) => onchangedata(e)}
                              onFocus={() => setShowSearchIcon(true)}
                              value={row.pd_IT_NM ? row.pd_IT_NM : data[`${"pd_IT_NM"}_${index}`]}
                            />
                            {showSearchIcon && (
                            <i
                              className="pi pi-search"
                              style={{color: "var(--primary-color)", fontSize: "119%",marginTop: "7%", cursor: "pointer",}}
                              onClick={(e) => openItemMulPopup(index)}
                            ></i>
                            )}
                          </div>
                      </td>
                      <td >
                        <InputText disabled style={{ width:"15rem"}}
                          id={`item_DESC_${index}`}
                          name={`item_DESC_${index}`}
                          value={row.item_DESC ? row.item_DESC : data[`${"item_DESC"}_${index}`]}
                        />
                      </td>
                      <td >
                        <div style={{ display: "flex"}}>
                          <InputText style={{width:'10rem'}}
                              id={`pd_CU_NO_${index}`}
                              name={`pd_CU_NO_${index}`}
                              onChange={(e) => onchangedata(e)}
                              onFocus={() => setShowSearchIcon(true)}
                              value={row.pd_CU_NO ? row.pd_CU_NO : data[`${"pd_CU_NO"}_${index}`]}
                            />
                            {showSearchIcon && (
                            <i
                              className="pi pi-search"
                              style={{color: "var(--primary-color)", fontSize: "119%",marginTop: "7%", cursor: "pointer",}}
                              onClick={(e) => openCustomerPopup(index)}
                            ></i>
                            )}
                          </div>
                      </td>
                      <td >
                        <InputText disabled style={{ width:"15rem"}}
                          id={`SelectedCust_${index}`}
                          name={`SelectedCust_${index}`}
                          value={row.SelectedCust ? row.SelectedCust : data[`${"SelectedCust"}_${index}`]}
                        />
                      </td>
                      <td >
                      <Dropdown style={{width:'10rem'}}
                        id={`pd_IT_PG_${index}`}
                        name={`pd_IT_PG_${index}`}
                        value={ data[`pd_IT_PG_${index}`] ? data[`pd_IT_PG_${index}`]: row.pd_IT_PG}
                        options={pd_IT_PGOptions}
                        onChange={(e) =>onchangeDropDownFormData2(`pd_IT_PG_${index}`, e.value)}
                        placeholder="Select Item Group"
                      />
                      </td>
                      <td >
                      <Dropdown style={{width:'10rem'}}
                        id={`pd_CU_PG_${index}`}
                        name={`pd_CU_PG_${index}`}
                        value={ data[`pd_CU_PG_${index}`] ? data[`pd_CU_PG_${index}`]: row.pd_CU_PG}
                        options={pd_CU_PGOptions}
                        onChange={(e) =>onchangeDropDownFormData2(`pd_CU_PG_${index}`, e.value)}
                        placeholder="Select Customer Group"
                      />
                      </td>
                      <td >
                      <Calendar  style={{width:'8rem'}}
                        id={`pd_FR_DT_${index}`}
                        name={`pd_FR_DT_${index}`}
                        value={ row.pd_FR_DT ? moment(row.pd_FR_DT, "YYYYMMDD").toDate() : null }
                        onChange={(e) => onDateChange({ target: { name: "pd_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                        dateFormat="dd/mm/yy"
                      />
                      </td>
                      <td >
                      <Calendar style={{width:'8rem'}}
                        id={`pd_TO_DT_${index}`}
                        name={`pd_TO_DT_${index}`}
                        value={ row.pd_TO_DT ? moment(row.pd_TO_DT, "YYYYMMDD").toDate() : null }
                        onChange={(e) => onDateChange({ target: { name: "pd_TO_DT", value: moment(e.value).format("YYYYMMDD") } })}
                        dateFormat="dd/mm/yy"
                      />
                      </td>
                      <td >
                        <Dropdown style={{width:'10rem'}}
                          id={`pd_CR_CD_${index}`}
                          name={`pd_CR_CD_${index}`}
                          value={ data[`pd_CR_CD_${index}`] ? data[`pd_CR_CD_${index}`]: row.pd_CR_CD}
                          options={pd_CR_CDOptions}
                          onChange={(e) =>onchangeDropDownFormData3(`pd_CR_CD_${index}`, e.value)}
                          placeholder="Select Currency"
                        />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`pd_PR_AM_${index}`}
                          name={`pd_PR_AM_${index}`}
                          value={row.pd_PR_AM}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>                  
                      <td >
                        <Dropdown style={{width:'10rem'}}
                          id={`pd_BS_CD_${index}`}
                          name={`pd_BS_CD_${index}`}
                          value={ data[`pd_BS_CD_${index}`] ? data[`pd_BS_CD_${index}`]: row.pd_BS_CD}
                          options={pd_BS_CDOptions}
                          onChange={(e) =>onchangeDropDownFormData4(`pd_BS_CD_${index}`, e.value)}
                          placeholder="Select Basis"
                        />
                      </td>
                      <td >
                        <Dropdown style={{width:'10rem'}}
                          id={`pd_PR_UM_${index}`}
                          name={`pd_PR_UM_${index}`}
                          value={ data[`pd_PR_UM_${index}`] ? data[`pd_PR_UM_${index}`]: row.pd_PR_UM}
                          options={pd_PR_UMOptions}
                          onChange={(e) =>onchangeDropDownFormData5(`pd_PR_UM_${index}`, e.value)}
                          placeholder="Select Basis"
                        />
                      </td>
                      <td >
                        <Dropdown  style={{width:'10rem'}}
                          id={`pd_AC_IN_${index}`}
                          name={`pd_AC_IN_${index}`}
                          value={ data[`pd_AC_IN_${index}`] ? data[`pd_AC_IN_${index}`]: row.pd_AC_IN}
                          options={pd_AC_INOptions}
                          onChange={(e) =>onchangeDropDownFormData6(`pd_AC_IN_${index}`, e.value)}
                          placeholder="Select Basis"
                        />
                      </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
      {/* Customer */}
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelectCustomer}
        formData={data}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
       {/* item Multirow*/}
     <ItemPopup
        visible={ItemMulPopupVisible}
        onClose={() => setItemMulPopupVisible(false)}
        onDataSelect={handleDataSelectItemMul}
        formData={data}
      />
    </Dialog>
  );
}
