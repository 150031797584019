import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function CrossReferenceDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  CrossReferenceTableData,
  setCrossReferenceTableData,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  selectedAddress,
  setselectedAddress,
  selectedName,
  setselectedName,
  cr_CR_TY,
  setcr_CR_TY,
  cr_CO_NO,
}) {
  const {
    crItNo,
    cr_IT_NM,
    os_SQ_DS,
  } = data;
  const [AddresstypeC, setAddresstype] = useState("C");
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const [cr_CR_TYOptions, setcr_CR_TYOptions] = useState([]);

// Cross Type
const CrosstypeDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setcr_CR_TY(value);
  };

  useEffect(() => {
    CrossType();
  }, []);

  const CrossType = async () => {
    const Options = await fetchClassCodeCD("IN/CR");
    setcr_CR_TYOptions(Options);
  }


  const addRow = () => {
    setCrossReferenceTableData([...CrossReferenceTableData, {}]);
  };
  
  const deleteRow = () => {
    if (CrossReferenceTableData.length > 0) {
      const updatedData = [...CrossReferenceTableData];
      updatedData.pop();
      setCrossReferenceTableData(updatedData);
    }
  };
const isUpdateMode = Boolean(crItNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    onchangeFormData("cr_IT_NM", data.im_IT_NM);
    onchangeFormData("itemDES", data.im_IT_DS);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

  // Address
  const [AddressVisible, setAddressVisible] = useState(false);
  const handleAddress =async (data) => {
    setselectedAddress(data.abAdNo)
    setselectedName(data.ab_AD_NM)
    onchangeFormData("cr_AD_NO", data.abAdNo, SelectedRowIndex);
    onchangeFormData("name", data.ab_AD_NM, SelectedRowIndex);
    setAddressVisible(false);
    setShowSearchIcon(false);
  };
  const openAddressPopup = (e) => {
    setAddressVisible(true);
    setSelectedRowIndex(e);
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={crItNo ? "Revise Cross Reference" : "Add Cross Reference"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cr_IT_NM" className="font-bold">
                 Item No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText 
                      id="cr_IT_NM"
                      name="cr_IT_NM"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedItem}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItemPopup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="itemDES" className="font-bold">
                  Description
                  </label>
                  <InputText disabled
                    id="itemDES"
                    name="itemDES"
                    value={SelectedDesc}
                   readOnly
                  />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cr_CO_NO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="cr_CO_NO"
                    name="cr_CO_NO"
                    value={cr_CO_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
            </div>
                  
          </div>
          </div>  
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
        >
      
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="CrossReferencetable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>CR Name</th>
              <th>CR Description</th>
              <th>CR Type</th>
              <th>Address No <span style={{ color: 'red' }}>*</span> </th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {CrossReferenceTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`crItNo_${index}`}
                    name={`crItNo_${index}`}
                    value={row.crItNo}
                  />
                </td>
                  <td >
                  <InputText 
                    id={`cr_CR_NM_${index}`}
                    name={`cr_CR_NM_${index}`}
                    defaultValue={row.cr_CR_NM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText 
                    id={`cr_CR_DS_${index}`}
                    name={`cr_CR_DS_${index}`}
                    defaultValue={row.cr_CR_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Dropdown
                    id={`cr_CR_TY_${index}`}
                    name={`cr_CR_TY_${index}`}
                    value={ data[`cr_CR_TY_${index}`] ? data[`cr_CR_TY_${index}`]: row.cr_CR_TY}
                    options={cr_CR_TYOptions}
                    onChange={(e) =>CrosstypeDropdown(`cr_CR_TY_${index}`, e.value)}
                    placeholder="Select Cross Type"
                  />
                  </td>
                
          <td style={{ display: "flex" }}>
          <InputText
          style={{ width: "110px" }}
          id={`cr_AD_NO_${index}`}
          name={`cr_AD_NO_${index}`}
          onChange={(e) => onchangeFormData(e)}
          onFocus={() => setShowSearchIcon(true)}
          value={row.cr_AD_NO ? row.cr_AD_NO: row[`${"cr_AD_NO"}_${index}`]}
          />
          {showSearchIcon && (
         <i
         className="pi pi-search"
         style={{
         color: "var(--primary-color)",
         fontSize: "119%",
         marginTop: "15%",
         cursor: "pointer",
        }}
        onClick={(e) => openAddressPopup(index)}
        ></i>
        )}
      </td>
        <td>
        <InputText disabled
        style={{ width: "200px" }}
        id={`name_${index}`}
        name={`name_${index}`}
        defaultValue={row.name ? row.name : row[`${"name"}_${index}`]}
        readOnly
        />
        </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
        <AddressBookPopup
        visible={AddressVisible}
        onClose={() => setAddressVisible(false)}
        onDataSelect={handleAddress}
        formData={data}
        addressType={AddresstypeC}
        setFormData= {setFormData}
      />
    </Dialog>
  );
}
