import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { Calendar } from "primereact/calendar";


const CouponPopup = ({ visible,onClose, data, onchangeFormData}) => {
//   const { } = data;
  const [formData, setFormData] = useState("");
  const toast = useRef(null);
  const { borderbox, inboxstyle } = ButtonStyles();

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const onchangedata = (event) => {
    const { value, name } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleClose = () => {
    onClose();
  };
  useEffect(() => {
    setFormData();
  }, []);

  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "10%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      />
       <Button
        style={{ width: "10%" }}
        icon="pi pi-check"
        variant="outlined"
        onClick={"handleTerminateClick"}
        tooltip="Confirm"
      />
    </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={visible}
      onHide={handleClose}
      style={{ width: "40rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Pay By Coupon"
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >      
    <Toast ref={toast} />
        <div style={borderbox}>
            <div style={inboxstyle}>
                <div className="formgrid grid">
                    <div className="field col-lg-12 col-md-6 col-12 pe-md-2 d-flex justify-content-between align-items-center " >
                        <label htmlFor="li_TR_PN" className="font-bold w-50" style={{ marginRight: '10px' }}>
                        Currency
                        </label>
                        <InputText style={{height:"3vh"}}
                            id="li_TR_PN"
                            name="li_TR_PN"
                            value={"li_TR_PN"}
                            onChange={(e) => onchangedata(e)}
                        />
                    </div>
                    </div>
                <div className="formgrid grid">
                    <div className="field col-lg-12 col-md-6 col-12 pe-md-2 d-flex justify-content-between align-items-center ">
                        <label htmlFor="li_TR_PN" className="font-bold w-50" style={{ marginRight: '10px' }}>
                        Open Amount
                        </label>
                        <InputText style={{height:"3vh"}}
                            id="li_TR_PN"
                            name="li_TR_PN"
                            value={"li_TR_PN"}
                            onChange={(e) => onchangedata(e)}
                        />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col-lg-12 col-md-6 col-12 pe-md-2 d-flex justify-content-between align-items-center ">
                        <label htmlFor="li_TR_PN" className="font-bold w-50" style={{ marginRight: '10px' }}>
                        Paid
                        </label>
                        <InputText style={{height:"3vh"}}
                            id="li_TR_PN"
                            name="li_TR_PN"
                            value={"li_TR_PN"}
                            onChange={(e) => onchangedata(e)}
                        />
                    </div>
                    </div>
                <div className="formgrid grid">
                    <div className="field col-lg-12 col-md-6 col-12 pe-md-2 d-flex justify-content-between align-items-center ">
                        <label htmlFor="li_TR_PN" className="font-bold w-50" style={{ marginRight: '10px' }}>
                        Difference
                        </label>
                        <InputText style={{height:"3vh"}}
                            id="li_TR_PN"
                            name="li_TR_PN"
                            value={"li_TR_PN"}
                            onChange={(e) => onchangedata(e)}
                        />
                    </div>
                </div>                
            </div>
        </div>
    </Dialog>
  );
};

CouponPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CouponPopup;
