import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import { faLink, faFileImage, faPaperclip, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ActivityPopup = ({ visible, onClose, av_CC_NO }) => {
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [tableData, setTableData] = useState(null);
  const [ActivityData, setActivityData] = useState([]);

  const handleClose = () => {
    onClose();
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const StartActivity = async () => {
    try {
      const response = await axios.post(
        `${domain}/api/Activity/List/${currentPage}/${pageSize}`,
        { searchRequestDto: [] },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      const data = response.data;
      if (data && data.content && Array.isArray(data.content) && data.content.length > 0) {
        setTableData(data.content);
        console.log(ActivityData);
        // setActivityData(data.content[0]);
        setActivityData(data.content);

      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
    
  useEffect(() => {
    const modal = document.getElementById("activityModal");
    if (visible) {
      modal.classList.add("show");
      modal.style.display = "block";
      StartActivity();
    } else {
      modal.classList.remove("show");
      modal.style.display = "none";
    }
  }, [visible]);
const filteredData = ActivityData.filter(item => item.av_CC_NO === av_CC_NO);
// blogs
const [showContent, setShowContent] = useState(false);
const blogs = [
  {
    id: 1,
    title: "Email",
    content: filteredData.filter(item => item.av_TY_PE === "E") 
  },
  {
    id: 2,
    title: "Call",
    content: filteredData.filter(item => item.av_TY_PE === "C")
  },
  {
    id: 3,
    title: "Whatsapp",
    content: filteredData.filter(item => item.av_TY_PE === "W")
  },
  {
    id: 4,
    title: "Note",
    content: filteredData.filter(item => item.av_TY_PE === "N")
  },
  {
    id: 5,
    title: "Task",
    content: filteredData.filter(item => item.av_TY_PE === "T")
  },
];
const handleBlogClick = (index) => {
  setShowContent(index === showContent ? null : index);
};
  // dateformat
  const formatDate = (inputDate) => {
    if (!inputDate) {
      return inputDate;
    }
    const dateString = inputDate.toString();
    if (dateString.length !== 8) {
      return dateString;
    }
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${day}/${month}/${year}`;
  };
  // Time format
     const formatTime = (timeStr) => {
      return moment(timeStr, "HH:mm:ss").format("HH:mm:ss");
  };
  
  const textstyles ={
    marginTop:"1%",
  };
  return (
    <div className={`modal fade ${visible ? "show" : ""}`} id="activityModal" tabIndex="-1" aria-labelledby="activityModalLabel" aria-hidden={!visible} 
    style={{ display: 'block', position: 'fixed', zIndex: 1050, top: 0, bottom: 0, left: 0, right: 0, overflow: 'auto' }}>
    <Toast ref={toast} />
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "70%",marginTop:"0%",marginLeft:"15%" }}>
        <div className="modal-content" style={{ backgroundColor: "#f9fbff"}}>
          <div className="modal-header" style={{ backgroundColor: "aliceblue",display: "flex",justifyContent: "space-between", }}>
            <h5 className="modal-title" id="activityModalLabel">
              Activity
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}> </button>
          </div>
          <div className="modal-body" style={{ maxHeight: "400px", overflowY: "auto" }}>
          {/* <div className="field row" >         
          <label htmlFor="av_CC_NO" className="font-bold">
          Contact No
          </label>
            <InputText
              id="av_CC_NO"
              name="av_CC_NO"
              value={av_CC_NO}
            />
          </div> */}
          {blogs.map((blog, index) => (
              <div key={blog.id}>
                <div style={{ marginBottom: "10px" }}>
                  <div style={{ display: "flex", alignItems: "center", border:"1px solid #bce8f1", height: "70px"}}>
                    <FontAwesomeIcon icon={showContent === index ? faChevronUp : faChevronDown} onClick={() => handleBlogClick(index)} style={{ cursor: "pointer", marginRight: "5px" }} />
                    <div><span style={{ fontSize: "1rem", color: "#000" }}>{blog.title}</span></div>
                  </div>
                  {showContent === index && (
                    <div style={{paddingTop: "10px", border:"1px solid #bce8f1"}}>
                      {blog.content.map((activity, activityIndex) => (
                        <div key={activityIndex}>
                          {/* Email */}
                          {activity.av_TY_PE === "E" && (
                            <div style={{marginTop: "3%",border:"1px solid #f7f7f7",backgroundColor:"white",padding:"1%"}}>
                              <div style={{display:"flex",marginLeft:"40%",justifyContent:"space-evenly"}}>
                              <div style={{border:"1px solid #ececec"}}>{formatDate(activity.last_UPDATED_DATE)}</div>
                              <div style={{border:"1px solid #ececec"}}>{formatTime(activity.last_UPDATED_TIME)}</div>
                              </div>                             
                              <div style={textstyles}>{activity.av_EM_SB}</div>
                              <div style={textstyles}>{activity.av_TE_XT}</div>
                            </div>
                          )}
                          {/* Whatsapp */}
                          {activity.av_TY_PE === "W" && (
                            <div  style={{marginTop: "3%",border:"1px solid #f7f7f7",backgroundColor:"white",padding:"1%"}}>
                              <div style={{display:"flex",marginLeft:"40%",justifyContent:"space-evenly"}}>
                             <div style={{border:"1px solid #ececec"}}>{formatDate(activity.last_UPDATED_DATE)}</div>
                              <div style={{border:"1px solid #ececec"}}>{formatTime(activity.last_UPDATED_TIME)}</div>
                              </div>
                              <div >{activity.av_TE_XT}</div>
                            </div>
                          )}
                          {/* Note */}
                            {activity.av_TY_PE === "N" && (
                            <div style={{marginTop: "3%",border:"1px solid #f7f7f7",backgroundColor:"white",padding:"1%"}}>
                              <div>{activity.av_TE_XT}</div>
                            </div>
                          )}
                          {/* Task */}
                            {activity.av_TY_PE === "T" && (
                            <div style={{marginTop: "3%",border:"1px solid #f7f7f7",backgroundColor:"white",padding:"1%"}}>
                            <div style={{display:"flex",marginLeft:"40%",justifyContent:"space-evenly"}}>
                              <div style={{border:"1px solid #ececec"}}>{formatDate(activity.last_UPDATED_DATE)}</div>
                              <div style={{border:"1px solid #ececec"}}>{formatTime(activity.last_UPDATED_TIME)}</div>
                            </div>
                            <table style={{ width: "40%" }}>
                                <thead>
                                  <tr>
                                  <th  style={{ width: "20%" }}> Priority </th>
                                  <th  style={{ width: "20%" }}> Assign To</th>
                                  </tr>
                                  <tr>
                                    <td>{activity.av_PR_TY}</td>
                                    {/* <td>{activity.av_AS_TO}</td> */}
                                    <td> {activity.assign_TO}</td>
                                  </tr>
                                </thead>
                              </table>
                            <div>{activity.av_TE_XT}</div>
                              </div>
                          )}
                          {/* Call */}
                            {activity.av_TY_PE === "C" && (
                            <div  style={{marginTop: "3%",border:"1px solid #f7f7f7",backgroundColor:"white",padding:"1%"}}>
                              <table style={{ width: "100%" }}>
                                <thead>
                                  <tr>
                                  <th  style={{ width: "20%" }}> Start Date </th>
                                  <th  style={{ width: "20%" }}> End Date </th>
                                  <th  style={{ width: "20%" }}> Start Time </th>
                                  <th  style={{ width: "20%" }}> End Time </th>
                                  <th  style={{ width: "20%" }}> Duration </th>
                                  </tr>
                                  <tr>
                                    <td>{formatDate(activity.av_CL_SD)}</td>
                                    <td>{formatDate(activity.av_CL_ED)}</td>
                                    <td>{formatTime(activity.av_CL_ST)}</td>
                                    <td>{formatTime(activity.av_CL_ET)}</td>
                                    <td>{activity.av_CL_DU}</td>
                                  </tr>
                                </thead>
                              </table>
                            {/* <div ></div>
                            <div >{formatTime(activity.av_CL_ST)}</div>
                            <div >{formatDate(activity.av_CL_ED)}</div>
                            <div >{formatTime(activity.av_CL_ET)}</div>
                            <div> <label className="font-bold"> Duration</label></div>
                            <div >{activity.av_CL_DU}</div> */}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="modal-footer">
            <Button
              icon="pi pi-times"
              onClick={handleClose}
              tooltip="Close"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityPopup;
