import React from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import 'primeflex/primeflex.css';                                   // css utility
import "views/common/flags.css";
import Dashboard from './PropertyOverview';

const PropertyOverview = () => {

  return (
   <div className='saleslead'>
      <Dashboard/>
   </div>
  )
}

export default PropertyOverview