import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import SupplierPopup from "../Popups/SupplierPopup";
import TestMasterPopup from "../Popups/TestMasterPopup";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function SpecificationDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  SpecificationTableData,
  setSpecificationTableData,
  sh_CO_CT,
  setsh_CO_CT,
  sh_TS_ST,
  setsh_TS_ST,
  ts_TS_UM,
  setts_TS_UM,
  ts_CO_NO,
  sh_UK_NO,
  tsUkNo,
}) {
  const {
   
    sh_SP_ID,
    sh_SP_DS,
    sh_RV_NO,   
  } = data;
  const {inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle} = ButtonStyles();

  // Dropdown
  const {fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [sh_CO_CTOptions, setsh_CO_CTOptions] = useState([]);
  const [sh_TS_STOptions, setsh_TS_STOptions] = useState([]);
  const [ts_TS_UMOptions, setts_TS_UMOptions] = useState([]);

  // Cost Centre
  const onchangeDropDownFormDataCostCentre = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsh_CO_CT(value);
  };
    // Cost Centre
    const onchangeDropDownFormDataStatus = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setsh_TS_ST(value);
    };
  // UM
  const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setts_TS_UM(value);
  };

  


  useEffect(() => {
    fetchDropdownCost();
    Status();
    UM();

  }, []);

  const fetchDropdownCost = async () => {
    const Options = await fetchCostCenter();
    setsh_CO_CTOptions(Options);
  };
  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setts_TS_UMOptions(Options);
  };
  const Status = async () => {
    const Options = await fetchClassCodeCD("SP/ST");
    setsh_TS_STOptions(Options);
  };

 


  const addRow = () => {
    setSpecificationTableData([...SpecificationTableData, {}]);
  };
  
  const deleteRow = () => {
    if (SpecificationTableData.length > 0) {
      const updatedData = [...SpecificationTableData];
      updatedData.pop();
      setSpecificationTableData(updatedData);
    }
  };
const isUpdateMode = Boolean(sh_UK_NO);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  const [SupplierVisible, setSupplierVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const [TestVisible, setITestVisible] = useState(false);

  const handleDataSelect =async (data) => {
    //setselectedSupplier(data.ab_AD_NM);
    document.getElementById (`ep_PR_SU_${SelectedRowIndex}`).value =  data.abAdNo;
    document.getElementById (`sup_NAME_${SelectedRowIndex}`).value =  data.ab_AD_NM;
    setSupplierVisible(false);
    setShowSearchIcon(false);
  };
  const handleTest = async (data) => {
    document.getElementById(`ts_TS_ID_${SelectedRowIndex}`).value = data.tm_TS_ID;
    document.getElementById(`ts_TS_DS_${SelectedRowIndex}`).value = data.tm_TS_DS;

    document.getElementById(`ts_AL_MI_${SelectedRowIndex}`).value = data.tm_AL_MI;
    document.getElementById(`ts_PR_MI_${SelectedRowIndex}`).value = data.tm_PR_MI;
    document.getElementById(`ts_TR_VL_${SelectedRowIndex}`).value = data.tm_TR_VL;
    document.getElementById(`ts_PR_MX_${SelectedRowIndex}`).value = data.tm_PR_MX;
    document.getElementById(`ts_AL_MX_${SelectedRowIndex}`).value = data.tm_AL_MX;
    UMDropdown(`ts_TS_UM_${SelectedRowIndex}`, data.tm_SM_UM);
    document.getElementById(`ts_FR_DT_${SelectedRowIndex}`).value = data.tm_FR_DT;
    document.getElementById(`ts_TO_DT_${SelectedRowIndex}`).value = data.tm_TO_DT;
    onchangeDropDownFormDataCostCentre("ts_CO_CT", data.tm_CO_CT);
    onchangeDropDownFormDataStatus("ts_TS_ST", data.tm_TS_ST);

    setITestVisible(false);
    setShowSearchIcon(false);
  };

  const openTestMasterPopup = (e) => {
    setITestVisible(true);
    setSelectedRowIndex(e);
  };
  const openSupplierPopup = (e) => {
    setSupplierVisible(true);
    setSelectedRowIndex(e);
  };


  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "85rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={sh_UK_NO ? "Revise Specification" : "Add Specification"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div  style={inboxstyle}>
         <div className="formgrid grid">
         <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                <label htmlFor="sh_UK_NO" className="font-bold">
                  UKNO
                </label>
                <InputText
                  id="sh_UK_NO"
                  name="sh_UK_NO"
                  value={sh_UK_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
         <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_SP_ID" className="font-bold">
                  Specification ID
                  </label>
                  <InputText
                    id="sh_SP_ID"
                    name="sh_SP_ID"
                    value={sh_SP_ID}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_SP_DS" className="font-bold">
                  Description
                  </label>
                  <InputText
                    id="sh_SP_DS"
                    name="sh_SP_DS"
                    value={sh_SP_DS}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sh_TS_ST" className="font-bold">
                    Status
                    </label>
                    <Dropdown
                    name="sh_TS_ST"
                    value={sh_TS_ST}
                    options={sh_TS_STOptions}
                    onChange={(e) => onchangeDropDownFormDataStatus("sh_TS_ST", e.value)}
                    placeholder="Status"
                    disabled
                  />
                  </div>
         <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sh_CO_CT" className="font-bold">
                    Cost Centre
                    </label>
                    <Dropdown
                    name="sh_CO_CT"
                    value={sh_CO_CT}
                    options={sh_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormDataCostCentre("sh_CO_CT", e.value)}
                    placeholder="Cost Centre"
                    disabled
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_RV_NO" className="font-bold">
                  Revisionn
                  </label>
                  <InputText disabled
                    id="sh_RV_NO"
                    name="sh_RV_NO"
                    value={sh_RV_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                  
          </div>
          </div>  
          <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="Specificationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Test ID <span style={{ color: 'red' }}>*</span> </th>
              <th>Description</th>
              <th>Allowed Minimum</th>
              <th>Preferred Minimum</th>
              <th>Target</th>
              <th>Preferred Maximum</th>
              <th>Allowed Maximum</th>
              <th>UM</th>
              <th>From Date</th>
              <th>To Date</th>
              <th style={{ display: "none" }}>Company</th>
            </tr>
          </thead>
          <tbody>
            {SpecificationTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`tsUkNo_${index}`}
                    name={`tsUkNo_${index}`}
                    value={row.tsUkNo}
                  />
                </td>
              <td
                    style={{ display: "flex" }}>
                    <InputText 
                      style={{ width: "110px" }}
                      id={`ts_TS_ID_${index}`}
                      name={`ts_TS_ID_${index}`}
                      onChange={(e) => onchangeFormData(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.ts_TS_ID ? row.ts_TS_ID : row[`${"ts_TS_ID"}_${index}`]}
                      
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "15%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openTestMasterPopup(index)}
                      ></i>
                    )}
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "200px" }}
                      id={`ts_TS_DS_${index}`}
                      name={`ts_TS_DS_${index}`}
                      value={row.ts_TS_DS ? row.ts_TS_DS : row[`${"ts_TS_DS"}_${index}`]}
                      readOnly
                    />
                  </td>
                  <td >
                  <InputText style={{width:"100px"}}
                    id={`ts_AL_MI_${index}`}
                    name={`ts_AL_MI_${index}`}
                    defaultValue={row.ts_AL_MI}
                    onChange={(e) => onchangedata(e)}
                    disabled
                  />
                </td>
                <td >
                  <InputText style={{width:"100px"}}
                    id={`ts_PR_MI_${index}`}
                    name={`ts_PR_MI_${index}`}
                    defaultValue={row.ts_PR_MI}
                    onChange={(e) => onchangedata(e)}
                    disabled
                  />
                </td>
                <td >
                  <InputText style={{width:"100px"}}
                    id={`ts_TR_VL_${index}`}
                    name={`ts_TR_VL_${index}`}
                    defaultValue={row.ts_TR_VL}
                    onChange={(e) => onchangedata(e)}
                    disabled
                  />
                </td>
                <td >
                  <InputText style={{width:"100px"}}
                    id={`ts_AL_MX_${index}`}
                    name={`ts_AL_MX_${index}`}
                    defaultValue={row.ts_AL_MX}
                    onChange={(e) => onchangedata(e)}
                    disabled
                  />
                </td>
                <td >
                  <InputText style={{width:"100px"}}
                    id={`ts_PR_MX_${index}`}
                    name={`ts_PR_MX_${index}`}
                    defaultValue={row.ts_PR_MX}
                    onChange={(e) => onchangedata(e)}
                    disabled
                  />
                </td>
                 
                 
                <td>
                  <Dropdown
                    id={`ts_TS_UM_${index}`}
                    name={`ts_TS_UM_${index}`}
                    value={ data[`ts_TS_UM_${index}`] ? data[`ts_TS_UM_${index}`]: row.ep_LO_CN}
                    options={ts_TS_UMOptions}
                    onChange={(e) =>UMDropdown(`ts_TS_UM_${index}`, e.value)}
                    placeholder="UM"
                    disabled
                  />
                  </td>
                  <td >
                  <InputText style={{width:"100px"}}
                    id={`ts_FR_DT_${index}`}
                    name={`ts_FR_DT_${index}`}
                    defaultValue={row.ts_FR_DT}
                    onChange={(e) => onchangedata(e)}
                    disabled
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`ts_TO_DT_${index}`}
                    name={`ts_TO_DT_${index}`}
                    defaultValue={row.ts_TO_DT}
                    onChange={(e) => onchangedata(e)}
                    disabled
                  />
                </td>
                <td style={{ display: 'none' }}>
  <InputText
    disabled
    style={{ width: "90px" }}
    id={`ts_CO_NO_${index}`}
    name={`ts_CO_NO_${index}`}
    defaultValue={ts_CO_NO}
    onChange={(e) => onchangedata(e)}
  />
</td>

              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <SupplierPopup
        visible={SupplierVisible}
        onClose={() => setSupplierVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
 <TestMasterPopup
        visible={TestVisible}
        onClose={() => setITestVisible(false)}
        onDataSelect={handleTest}
        formData={data}
      />
     
    </Dialog>
  );
}
