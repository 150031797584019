import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import AccountProjectPopup from "../Popups/AccountProjectPopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ProjectMasterPopup from "views/admin/Popups/ProjectMasterPopup";
import { Calendar } from "primereact/calendar";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function ProjectMasterDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  pm_PR_CO,
  pm_TR_NO,
  pm_TR_TY,
  pm_PR_TY,
  setpm_PR_TY,
  pm_PR_ST,
  setpm_PR_ST,
  pm_CO_RY,
  setpm_CO_RY,
  pm_CC_02,
  setpm_CC_02,
  pm_CC_05,
  setpm_CC_05,
  pm_CC_06,
  setpm_CC_06,
  pm_CC_07,
  setpm_CC_07,
  pm_CC_08,
  setpm_CC_08,
  pm_CC_09,
  setpm_CC_09,
  pm_CC_10,
  setpm_CC_10,
  pm_PM_TY,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNO1,
  setSelectedemEMNO1,
  SelectedAccount,
  setSelectedAccount,
  SelectedAccountNo,
  setSelectedAccountNo,
  SelectedProjectMaster,
  setSelectedProjectMaster,
  setSelectedsite,
  setSelectedowner,
  Selectedowner,
  Selectedsite,
  ProjectStatusDropDown
}) {
  const {
    pmUkNo,
    pm_PR_NO,
    pm_PR_DS,
    pm_PS_DT,
    pm_PC_DT,
    pm_AS_DT,
    pm_AC_DT,
    pm_CC_04,
    pm_CC_01,
    pm_PA_PR,
    pm_AC_CD,
    pm_OW_AD,
    pm_SI_AD
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle,TabBoxStyle} = ButtonStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [AddresstypeE, setAddresstypeE] = useState("E");
  const [AddresstypeC, setAddresstypeC] = useState("C");
  const [Addresstype, setAddresstype] = useState("F");
  const [AddresstypeS, setAddresstypeS] = useState("F");

  // DROPDOWNS
  const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
  const [pm_PR_TYOptions, setpm_PR_TYOptions] = useState([]);
  const [pm_PR_STOptions, setpm_PR_STOptions] = useState([]);
  const [pm_CO_RYOptions, setpm_CO_RYOptions] = useState([]);
  const [pm_CC_02Options, setpm_CC_02Options] = useState([]);
  const [pm_CC_05Options, setpm_CC_05Options] = useState([]);
  const [pm_CC_06Options, setpm_CC_06Options] = useState([]);
  const [pm_CC_07Options, setpm_CC_07Options] = useState([]);
  const [pm_CC_08Options, setpm_CC_08Options] = useState([]);
  const [pm_CC_09Options, setpm_CC_09Options] = useState([]);
  const [pm_CC_10Options, setpm_CC_10Options] = useState([]);

  const ProjectTypeDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_PR_TY(value);
  };
  const CountryDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_CO_RY(value);
  };
  const TeamDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_CC_02(value);
  };
  const ClassCodeDropDown5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_CC_05(value);
  };
  const ClassCodeDropDown6 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_CC_06(value);
  };
  const ClassCodeDropDown7 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_CC_07(value);
  };
  const ClassCodeDropDown8 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_CC_08(value);
  };
  const ClassCodeDropDown9 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_CC_09(value);
  };
  const ClassCodeDropDown10 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_CC_10(value);
  };
  useEffect(() => {
    ProjectType();
    ProjectStatus();
    Country();
    Team();
    ClassCode5();
    ClassCode6();
    ClassCode7();
    ClassCode8();
    ClassCode9();
    ClassCode10();
  }, []);

  const ProjectType = async () => {
    const CostOptions = await fetchClassCodeDS("PR/TY");
    setpm_PR_TYOptions(CostOptions);
  };
  const ProjectStatus = async () => {
    const Options = await fetchClassCodeDS("PR/ST");
    setpm_PR_STOptions(Options);
  };
  const Country = async () => {
    const Options = await fetchClassCodeDS("FO/CY");
    setpm_CO_RYOptions(Options);
  };
  const Team = async () => {
    const Options = await fetchClassCodeDS("PR/02");
    setpm_CC_02Options(Options);
  };
  const ClassCode5 = async () => {
    const Options = await fetchClassCodeDS("PR/05");
    setpm_CC_05Options(Options);
  };
  const ClassCode6 = async () => {
    const Options = await fetchClassCodeDS("PR/06");
    setpm_CC_06Options(Options);
  };
  const ClassCode7 = async () => {
    const Options = await fetchClassCodeDS("PR/07");
    setpm_CC_07Options(Options);
  };
  const ClassCode8 = async () => {
    const Options = await fetchClassCodeDS("PR/08");
    setpm_CC_08Options(Options);
  };
  const ClassCode9 = async () => {
    const Options = await fetchClassCodeDS("PR/09");
    setpm_CC_09Options(Options);
  };
  const ClassCode10 = async () => {
    const Options = await fetchClassCodeDS("PR/10");
    setpm_CC_10Options(Options);
  };
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("pm_CC_04", data.abAdNo);
    setCustomerPopupVisible(false);
  };
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);
  const openEmployeePopup = () => {
    setEmployeePopupVisible(true);
  }
  const handleDataSelect1 = async (data) => {
    setSelectedemEMNO1(data.ab_AD_NM);
    onchangeFormData("pm_CC_01", data.abAdNo);
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const openAccountPopup = () => {
    setAccountPopupVisible(true);
  };
  const handleAccount = async (data) => {
    setSelectedAccount(data.pa_CD_DS);
    setSelectedAccountNo(data.paAcCd);
    onchangeFormData("pm_AC_CD", data.paAcCd);
    setAccountPopupVisible(false);
    setShowSearchIcon(false);
  };
  // Advance Search
  const [ProjectMasterPopupVisible, setProjectMasterPopupVisible] = useState(false);
  const openProjectMasterPopup = (e) => {
    setProjectMasterPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleProjectMaster = async (data) => {
    setSelectedProjectMaster(data.pm_PR_DS);
    onchangeFormData("pm_PA_PR", data.pm_PR_NO);
    setProjectMasterPopupVisible(false);
  };
  const [OwnerPopupVisible, setOwnerPopupVisible] = useState(false);
  const openOwnerPopup = (e) => {
    setOwnerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleOwnerAddress = async (data) => {
    setSelectedowner(data.ab_AD_NM);
    onchangeFormData("pm_OW_AD", data.abAdNo);
    setOwnerPopupVisible(false);
  };
  const [SiteAddressPopupVisible, setSiteAddressPopupVisible] = useState(false);
  const openSitePopup = () => {
    setSiteAddressPopupVisible(true);
  }
  const handleSiteAddress = async (data) => {
    setSelectedsite(data.ab_AD_NM);
    onchangeFormData("pm_SI_AD", data.abAdNo);
    setSiteAddressPopupVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(pmUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{pmUkNo ? "Revise Project Master" : "Add Project Master"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
      {activeTab === 0 && (
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
      )}
      {activeTab === 0 && (
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      )}
      </div>
    </div>
  );
  const [showSearchIcon, setShowSearchIcon] = useState("");
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle} >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Details">
            {/* tab1 general */}
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_TR_NO" className="font-bold">
                    Transaction No
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText disabled
                      id="pm_TR_NO"
                      name="pm_TR_NO"
                      value={pm_TR_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_TR_TY" className="font-bold">
                    Type
                  </label>
                  <InputText disabled
                    id="pm_TR_TY"
                    name="pm_TR_TY"
                    value={pm_TR_TY}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_PR_CO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="pm_PR_CO"
                    name="pm_PR_CO"
                    value={pm_PR_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_PR_NO" className="font-bold">
                    Project No
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText disabled
                      id="pm_PR_NO"
                      name="pm_PR_NO"
                      value={pm_PR_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_PM_TY" className="font-bold">
                    Type
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText disabled
                      id="pm_PM_TY"
                      name="pm_PM_TY"
                      value={pm_PM_TY}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_PR_DS" className="font-bold">
                  Description
                  </label>
                  <InputText
                    id="pm_PR_DS"
                    name="pm_PR_DS"
                    value={pm_PR_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_PR_TY" className="font-bold">
                   Project Type
                  </label>
                  <Dropdown
                    name="pm_PR_TY"
                    value={pm_PR_TY}
                    options={pm_PR_TYOptions}
                    onChange={(e) => ProjectTypeDropDown("pm_PR_TY", e.value)}
                    placeholder="Select  Project Type"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_PR_ST" className="font-bold">
                   Project Status
                  </label>
                  <Dropdown
                    name="pm_PR_ST"
                    value={pm_PR_ST}
                    options={pm_PR_STOptions}
                    onChange={(e) => ProjectStatusDropDown("pm_PR_ST", e.value)}
                    placeholder="Select Project Status"
                  />
                </div>
                
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_CO_RY" className="font-bold">
                    Country
                  </label>
                  <Dropdown
                    name="pm_CO_RY"
                    value={pm_CO_RY}
                    options={pm_CO_RYOptions}
                    onChange={(e) => CountryDropDown("pm_CO_RY", e.value)}
                    placeholder="Select Country"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="pm_AC_CD" className="font-bold">
                Account Code Structure<span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText
                    id="accntDES"
                    name="accntDES"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedAccount}
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openAccountPopup}
                  ></i>
                  )}
                </div>
                <div style={{ display: "none" }}>
                  <InputText
                    id="paAcCd"
                    name="paAcCd"
                    value={SelectedAccountNo}
                  />
                </div>
              </div> 
                 <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="pm_SI_AD" className="font-bold">
                     Site Address<span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                    <InputText
                      id="sitedDES"
                      name="sitedDES"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={Selectedsite}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openSitePopup}
                    ></i>
                    )}
                  </div>
                  <div >
                    <InputText style={{ display: "none" }}
                      id="pm_SI_AD"
                      name="pm_SI_AD"
                      value={pm_SI_AD}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_OW_AD" className="font-bold">
                    Owner Address  <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ownerDES"
                      name="ownerDES"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={Selectedowner}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openOwnerPopup}
                    ></i>
                    )}
                  </div>
                  <div style={{ display: "none" }}>
                    <InputText
                      id="pm_OW_AD"
                      name="pm_OW_AD"
                      value={pm_OW_AD}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_PS_DT" className="font-bold">
                    Planned Start Date
                  </label>
                  <Calendar
                    id="pm_PS_DT"
                    name="pm_PS_DT"
                    value={moment(pm_PS_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "pm_PS_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_PC_DT" className="font-bold">
                  Planned Compl. Date
                  </label>
                  <Calendar
                    id="pm_PC_DT"
                    name="pm_PC_DT"
                    value={moment(pm_PC_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "pm_PC_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_AS_DT" className="font-bold">
                   Actual Start Date
                  </label>
                  <Calendar
                    id="pm_AS_DT"
                    name="pm_AS_DT"
                    value={moment(pm_AS_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "pm_AS_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_AC_DT" className="font-bold">
                   Actual Compl. Date
                  </label>
                  <Calendar
                    id="pm_AC_DT"
                    name="pm_AC_DT"
                    value={moment(pm_AC_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "pm_AC_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                </div>
                </div>
          </TabPanel>
          {/* tab2 */}
          <TabPanel header="Classification Codes">
            <div style={inboxstyle} >
              <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pm_CC_01" className="font-bold">
              Sales Person 
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="sales_PERSON"
                      name="sales_PERSON"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedemEMNO1}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openEmployeePopup}
                    ></i>
                    )}
                  </div>
                
                  <div >
                    <InputText style={{ display: "none" }}
                      id="pm_CC_01"
                      name="pm_CC_01"
                      value={pm_CC_01}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pm_CC_02" className="font-bold">
              Team
              </label>
              <Dropdown
                name="pm_CC_02"
                value={pm_CC_02}
                options={pm_CC_02Options}
                onChange={(e) => TeamDropDown("pm_CC_02", e.value)}
                placeholder="Select Team"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="pm_PA_PR" className="font-bold">
              Parent Project <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
                id="proj_NAME"
                name="proj_NAME"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedProjectMaster}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openProjectMasterPopup}
                ></i>
                )}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="pm_PA_PR"
                  name="pm_PA_PR"
                  value={pm_PA_PR}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pm_CC_04" className="font-bold">
                Customer  
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="cus_NAME"
                  name="cus_NAME"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedemEMNO}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openCustomerPopup}
                ></i>
                )}
                </div>
                <div style={{ display: "none" }}>
                  <InputText
                    id="pm_CC_04"
                    name="pm_CC_04"
                    value={pm_CC_04}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
              </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_CC_05" className="font-bold">
                  Class Code 5
                  </label>
                  <Dropdown
                    name="pm_CC_05"
                    value={pm_CC_05}
                    options={pm_CC_05Options}
                    onChange={(e) => ClassCodeDropDown5("pm_CC_05", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_CC_06" className="font-bold">
                  Class Code 6
                  </label>
                  <Dropdown
                    name="pm_CC_06"
                    value={pm_CC_06}
                    options={pm_CC_06Options}
                    onChange={(e) => ClassCodeDropDown6("pm_CC_06", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_CC_07" className="font-bold">
                  Class Code 7
                  </label>
                  <Dropdown
                    name="pm_CC_07"
                    value={pm_CC_07}
                    options={pm_CC_07Options}
                    onChange={(e) => ClassCodeDropDown7("pm_CC_07", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_CC_08" className="font-bold">
                  Class Code 8
                  </label>
                  <Dropdown
                    name="pm_CC_08"
                    value={pm_CC_08}
                    options={pm_CC_08Options}
                    onChange={(e) => ClassCodeDropDown8("pm_CC_08", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_CC_09" className="font-bold">
                  Class Code 9
                  </label>
                  <Dropdown
                    name="pm_CC_09"
                    value={pm_CC_09}
                    options={pm_CC_09Options}
                    onChange={(e) => ClassCodeDropDown9("pm_CC_09", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pm_CC_10" className="font-bold">
                  Class Code 10
                  </label>
                  <Dropdown
                    name="pm_CC_10"
                    value={pm_CC_10}
                    options={pm_CC_10Options}
                    onChange={(e) => ClassCodeDropDown10("pm_CC_10", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                </div>
              </div>
          </TabPanel>
      </TabView>
      </div>    
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={AddresstypeC}
      />
      <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={AddresstypeE}
      /> 
      <AccountProjectPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleAccount}
        formData={data}
      />
      <AddressBookPopup
        visible={OwnerPopupVisible}
        onClose={() => setOwnerPopupVisible(false)}
        onDataSelect={handleOwnerAddress}
        formData={data}
        addressType={Addresstype}
      />
      <AddressBookPopup
        visible={SiteAddressPopupVisible}
        onClose={() => setSiteAddressPopupVisible(false)}
        onDataSelect={handleSiteAddress}
        formData={data}
        addressType={AddresstypeS}
      />
      <ProjectMasterPopup
        visible={ProjectMasterPopupVisible}
        onClose={() => setProjectMasterPopupVisible(false)}
        onDataSelect={handleProjectMaster}
        formData={data}
      />
    </Dialog>
  );
}
