import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import RoutingMasterDialog from "./RoutingMasterDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";

const initialValue = {
  rt_IT_NM:"",
  rt_BT_QT:"",
  rt_BT_UM:"",
  rt_CO_CT:"",
  rt_RT_TY:"",
  rt_WK_CT:"", 
  rt_OP_SQ:"", 
  rt_OP_DS:"",
  rt_LB_HR:"",
  rt_MC_HR:"",
  rt_ST_HR:"",
  rt_TB_CD:"",
  rt_FR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  rt_TO_DT: moment(moment().toDate()).format("YYYYMMDD"),
  rt_LN_NO:"",
  rtUkNo:"",
};
const initialFilters = {
  rt_IT_NM: { value: "", operation: "" },
  parent_DES: { value: "", operation: "" },
  rt_BT_QT: { value: "", operation: "" },
  rt_BT_UM: { value: "", operation: "" },
  rt_CO_CT: { value: "", operation: "" },
  rt_RT_TY: { value: "", operation: "" },
  rt_WK_CT: { value: "", operation: "" }, 
  rt_OP_SQ: { value: "", operation: "" }, 
  rt_OP_DS: { value: "", operation: "" },
  rt_LB_HR: { value: "", operation: "" },
  rt_MC_HR: { value: "", operation: "" },
  rt_ST_HR: { value: "", operation: "" },
  rt_TB_CD: { value: "", operation: "" },
  rt_FR_DT: { value: "", operation: "" },
  rt_TO_DT: { value: "", operation: "" },
  rt_LN_NO: { value: "", operation: "" },
};
const RoutingMasterMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
     copyButtonStyles,exportButtonStyles,
     checkboxStyle,tableHeaderStyle,
     inputStyle,tableCellStyle,inputStyle1,
    } = ButtonStyles();

  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setRoutingMastertable([{}]);
    setroutingItemName(null);
    setrtWK_dESC(null);
    setrt_BT_UM(null);
    setFormData(initialValue);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "RT_CO_CT"){
              onchangeDropDownFormData1("rt_CO_CT",dataDefault.versionIds[i].default_VALUE);
              setrt_CO_CT(dataDefault.versionIds[i].default_VALUE);
            }
            if(fName === "RT_RT_TY"){
              onchangeDropDownFormData2("rt_RT_TY",dataDefault.versionIds[i].default_VALUE);
              setrt_RT_TY(dataDefault.versionIds[i].default_VALUE);
            }
            if(fName === "RT_TB_CD"){
              onchangeDropDownFormData4("rt_TB_CD",dataDefault.versionIds[i].default_VALUE);
              setrt_TB_CD(dataDefault.versionIds[i].default_VALUE);
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/RoutingMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value,}));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  // COST CENTER
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrt_CO_CT(value);
  };
  // Type
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrt_RT_TY(value);
  };
  // Time Basis
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrt_TB_CD(value);
  };
  const [RoutingMastertable, setRoutingMastertable] = useState([{}]);
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const [routingItemName, setroutingItemName] = useState("");
  const [rtWK_dESC, setrtWK_dESC] = useState("");

  const [rt_CO_CT, setrt_CO_CT] = useState("");
  const [rt_BT_UM, setrt_BT_UM] = useState("");
  const [rt_RT_TY, setrt_RT_TY] = useState("");
  const [rt_TB_CD, setrt_TB_CD] = useState("");

const handleRowSelect = (rowId) => {
  const isRowSelected = selectedRows.includes(rowId);
  if (!isRowSelected) {
    setSelectedRows([rowId]);
  } else {
    setSelectedRows([]);
  }
  setIsRecordSelected(!isRowSelected);
};
const handleUpdate = async () => {
  if (!isRecordSelected || selectedRows.length > 1) {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: isRecordSelected
        ? "Select a single record to edit."
        : "Select only one record to edit.",
      life: 3000,
    });
    return;
  }
  setFormData(initialValue);
  try {
    const response = await axios.get(
      `${domain}/api/RoutingMaster/Select/${selectedRows[0]}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      }
    );

    if (response.status === 200) {
      const RoutingMasterData = response.data;
      setRoutingMastertable(RoutingMasterData.routingMasterList);
      setFormData(RoutingMasterData);
      setroutingItemName(RoutingMasterData.parent_DES);
      handleClickOpen();
    } else {
      console.error(`API request failed with status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const handleCopyData = async () => {
  if (selectedRows.length === 1) {
    try {
      const response = await axios.get(
        `${domain}/api/RoutingMaster/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const RoutingMasterData = response.data;
        delete RoutingMasterData.rtUkNo;
        setFormData(RoutingMasterData);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
};
var requestBodyInput = {};
const values = () => {
    const table = document.getElementById("RoutingMastertable");
    const totalRowCount = table.tBodies[0].rows.length;
    const routingMasterList = [];
    for (let i = 0; i < totalRowCount; i++) {  
      const FR_DT = document.getElementsByName("rt_FR_DT_" + i)[0];
      const TO_DT = document.getElementsByName("rt_TO_DT_" + i)[0];
      const Details = {
        rtUkNo: document.getElementById("rtUkNo_" + i).value,
        rt_LN_NO: document.getElementById("rt_LN_NO_" + i).value,
        rt_WK_CT: document.getElementById("rt_WK_CT_" + i).value,
        rt_OP_SQ: document.getElementById("rt_OP_SQ_" + i).value,
        rt_OP_DS: document.getElementById("rt_OP_DS_" + i).value,
        rt_IN_ST: document.getElementById("rt_IN_ST_" + i).value,
        rt_LB_HR: document.getElementById("rt_LB_HR_" + i).value,
        rt_MC_HR: document.getElementById("rt_MC_HR_" + i).value,
        rt_ST_HR: document.getElementById("rt_ST_HR_" + i).value,
        rt_TB_CD: table.tBodies[0].rows[i].cells[10].children[0].children[1].children[0].value,
        rt_FR_DT: FR_DT.value?moment(FR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        rt_TO_DT: TO_DT.value?moment(TO_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
      };
      routingMasterList.push(Details);
    }
    requestBodyInput = {
      rtUkNo: document.getElementById("rtUkNo").value,
      rt_IT_NM: document.getElementById("rt_IT_NM").value,
      rt_RT_TY: rt_RT_TY,
      rt_CO_CT: rt_CO_CT,
      rt_BT_QT: document.getElementById("rt_BT_QT").value,
      rt_BT_UM: rt_BT_UM,
      routingMasterList,
    };
};
const handleFormSubmit = () => {
  values();
  const ukNo = FormData.rtUkNo;
    if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/RoutingMaster/Update`;
  
      if (confirm) {
        axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/RoutingMaster/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  
};
// header buttons starts
const exportExcel = () => {
  import("xlsx").then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(tableData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "products_export.xlsx");
  });
};
const leftToolbarTemplate = ( 
  <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack >
      <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
      Update
    </div>  
    </Tooltip>
      <Button   
      style={buttonStyles}
      icon="pi pi-check"
      outlined
      className="mr-2 update-button"
      onClick={handleUpdate}
      disabled={!isRecordSelected}
    ></Button>
    <Tooltip target=".add-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Add
      </div>
  </Tooltip>
    <Button 
      style={addButtonStyles}
      icon="pi pi-plus"     
      outlined
      className="mr-2 add-button"
      onClick={handleAddButtonClick}
    ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Copy
      </div>
  </Tooltip>
    <Button 
      style={copyButtonStyles}
      icon="pi pi-copy"
      outlined
      className="mr-2 copy-button"
      onClick={handleCopyData}
    ></Button>
  </React.Fragment>
);
const rightToolbarTemplate = (
  <React.Fragment>
    <Button
      style={exportButtonStyles}
      icon="pi pi-upload"
      className="p-button-help"
      rounded
      onClick={exportExcel}
      data-pr-tooltip="XLS"
    />
  </React.Fragment>
);
// header button ends
return (
  <div className="tableData" style={{ marginTop: "5%" }}>
    <Toast ref={toast} />
    <div className="card">
      <Toolbar  className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}  />
      <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        <table
          style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px", }}>
          <thead>
            <tr>
              <td style={{width:'40px'}}>
                <input
                  style={checkboxStyle}
                  readOnly
                  onMouseDown={(e) => e.preventDefault()}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["rt_IT_NM"] ? filters["rt_IT_NM"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "rt_IT_NM",
                      e.target.value,
                      filters["rt_IT_NM"]
                        ? filters["rt_IT_NM"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle1}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["parent_DES"] ? filters["parent_DES"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "parent_DES",
                      e.target.value,
                      filters["parent_DES"]
                        ? filters["parent_DES"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle1}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["rt_BT_QT"] ? filters["rt_BT_QT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "rt_BT_QT",
                      e.target.value,
                      filters["rt_BT_QT"]
                        ? filters["rt_BT_QT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["rt_BT_UM"] ? filters["rt_BT_UM"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "rt_BT_UM",
                      e.target.value,
                      filters["rt_BT_UM"]
                        ? filters["rt_BT_UM"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>              
              <td>
                <input
                  type="text"
                  value={filters["rt_CO_CT"] ? filters["rt_CO_CT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "rt_CO_CT",
                      e.target.value,
                      filters["rt_CO_CT"]
                        ? filters["rt_CO_CT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["rt_RT_TY"] ? filters["rt_RT_TY"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "rt_RT_TY",
                      e.target.value,
                      filters["rt_RT_TY"]
                        ? filters["rt_RT_TY"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["rt_WK_CT"] ? filters["rt_WK_CT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "rt_WK_CT",
                      e.target.value,
                      filters["rt_WK_CT"]
                        ? filters["rt_WK_CT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["rt_OP_SQ"] ? filters["rt_OP_SQ"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "rt_OP_SQ",
                      e.target.value,
                      filters["rt_OP_SQ"]
                        ? filters["rt_OP_SQ"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["rt_OP_DS"] ? filters["rt_OP_DS"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "rt_OP_DS",
                      e.target.value,
                      filters["rt_OP_DS"]
                        ? filters["rt_OP_DS"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["rt_LB_HR"] ? filters["rt_LB_HR"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "rt_LB_HR",
                      e.target.value,
                      filters["rt_LB_HR"]
                        ? filters["rt_LB_HR"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["rt_MC_HR"] ? filters["rt_MC_HR"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "rt_MC_HR",
                      e.target.value,
                      filters["rt_MC_HR"]
                        ? filters["rt_MC_HR"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["rt_ST_HR"] ? filters["rt_ST_HR"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "rt_ST_HR",
                      e.target.value,
                      filters["rt_ST_HR"]
                        ? filters["rt_ST_HR"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["rt_TB_CD"] ? filters["rt_TB_CD"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "rt_TB_CD",
                      e.target.value,
                      filters["rt_TB_CD"]
                        ? filters["rt_TB_CD"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["rt_FR_DT"] ? filters["rt_FR_DT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "rt_FR_DT",
                      e.target.value,
                      filters["rt_FR_DT"]
                        ? filters["rt_FR_DT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["rt_TO_DT"] ? filters["rt_TO_DT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "rt_TO_DT",
                      e.target.value,
                      filters["rt_TO_DT"]
                        ? filters["rt_TO_DT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["rt_LN_NO"] ? filters["rt_LN_NO"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "rt_LN_NO",
                      e.target.value,
                      filters["rt_LN_NO"]
                        ? filters["rt_LN_NO"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
            </tr>
            <tr>
              <th style={tableHeaderStyle}></th>
              <th style={tableHeaderStyle}>Parent Item</th>
              <th style={tableHeaderStyle}>Description</th>
              <th style={tableHeaderStyle}>Batch Qty</th>
              <th style={tableHeaderStyle}>UM</th>
              <th style={tableHeaderStyle}>Cost Centre</th>
              <th style={tableHeaderStyle}>Type</th>
              <th style={tableHeaderStyle}>Work Centre</th>
              <th style={tableHeaderStyle}>Oper. Seq</th>
              <th style={tableHeaderStyle}>Description</th>
              <th style={tableHeaderStyle}>Labour Hrs</th>
              <th style={tableHeaderStyle}>Machine Hrs</th>
              <th style={tableHeaderStyle}>Setup Hrs</th>
              <th style={tableHeaderStyle}>Time Basis</th>
              <th style={tableHeaderStyle}>From Date</th>
              <th style={tableHeaderStyle}>To Date</th>
              <th style={tableHeaderStyle}>Line No</th>
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData.map((rowData, index) => (
                <tr
                  key={rowData.rtUkNo}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "aliceblue" : "#ffffff",
                  }}
                  className="table-row"
                >
                  <td style={tableCellStyle}>
                    <input
                      style={{ width: "10% !important" }}
                      type="checkbox"
                      value={rowData.rtUkNo}
                      checked={selectedRows.includes(rowData.rtUkNo)}
                      onChange={() => handleRowSelect(rowData.rtUkNo)}
                    />
                  </td>
                  <td style={tableCellStyle}>{rowData.rt_IT_NM}</td>
                  <td style={tableCellStyle}>{rowData.item_DESC}</td>
                  <td style={tableCellStyle}>{rowData.rt_BT_QT}</td>
                  <td style={tableCellStyle}>{rowData.rt_BT_UM}</td>
                  <td style={tableCellStyle}>{rowData.rt_CO_CT}</td>
                  <td style={tableCellStyle}>{rowData.rt_RT_TY}</td>
                  <td style={tableCellStyle}>{rowData.rt_WK_CT}</td> 
                  <td style={tableCellStyle}>{rowData.rt_OP_SQ}</td> 
                  <td style={tableCellStyle}>{rowData.rt_OP_DS}</td>
                  <td style={tableCellStyle}>{rowData.rt_LB_HR}</td>
                  <td style={tableCellStyle}>{rowData.rt_MC_HR}</td>
                  <td style={tableCellStyle}>{rowData.rt_ST_HR}</td>
                  <td style={tableCellStyle}>{rowData.rt_TB_CD}</td>
                  <td style={tableCellStyle}>{formatDate(rowData.rt_FR_DT)}</td>
                  <td style={tableCellStyle}>{formatDate(rowData.rt_TO_DT)}</td>
                  <td style={tableCellStyle}>{rowData.rt_LN_NO}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    {/* pagination */}
    <Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      setCurrentPage={setCurrentPage}
    />
    </div>
    <RoutingMasterDialog
      open={open}
      handleClose={handleClose}
      data={FormData}
      FormData={FormData}
      setFormData={setFormData}
      onchangedata={onchangedata}
      handleFormSubmit={handleFormSubmit}
      onchangeFormData={onchangeFormData}
      RoutingMastertable={RoutingMastertable}
      setRoutingMastertable={setRoutingMastertable}
      onchangeDropDownFormData1={onchangeDropDownFormData1}
      onchangeDropDownFormData2={onchangeDropDownFormData2}
      onchangeDropDownFormData4={onchangeDropDownFormData4}
      routingItemName={routingItemName}
      setroutingItemName={setroutingItemName}
      rtWK_dESC={rtWK_dESC}
      setrtWK_dESC={setrtWK_dESC}
      rt_CO_CT={rt_CO_CT}
      setrt_CO_CT={setrt_CO_CT}
      rt_BT_UM={rt_BT_UM}
      setrt_BT_UM={setrt_BT_UM}
      rt_RT_TY={rt_RT_TY}
      setrt_RT_TY={setrt_RT_TY}
      rt_TB_CD={rt_TB_CD}
      setrt_TB_CD={setrt_TB_CD}
    />
  </div>
);
};

export default RoutingMasterMain;
