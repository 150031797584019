import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import Pagination from "views/common/Pagination";

const initialFilters = {
  ed_OR_NO: { value: "", operation: "" },
  ed_OR_TY: { value: "", operation: "" },
  ed_OR_CO: { value: "", operation: "" },
  ed_LN_NO: { value: "", operation: "" },
  ed_IT_NO: { value: "", operation: "" },
  itemDES: { value: "", operation: "" },
  ed_EX_PR: { value: "", operation: "" },
  ed_EX_CT: { value: "", operation: "" },
  ed_OR_QT: { value: "", operation: "" },
  ed_OR_DT: { value: "", operation: "" },
  ed_RQ_DT: { value: "", operation: "" },
};

const EstimateEntryPopup = ({ visible, onClose, onDataSelect, ItemlmId}) => {
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [open, setOpen] = useState(false);
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;

        if (column === 'ed_OR_NO') {
          constructedColumn = 'ed_OR_NO';
        } else {
          constructedColumn = column.toUpperCase();
        }

        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };
    // dateformat
    const formatDate = (inputDate) => {
      if (!inputDate) {
        return inputDate;
      }
      const dateString = inputDate.toString();
      if (dateString.length !== 8) {
        return dateString;
      }
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const day = dateString.substring(6, 8);
      return `${day}/${month}/${year}`;
    };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(visible);
    handleSearch();
    setCurrentPage(0);
  }, [currentPage, pageSize, filters, visible]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/EstimateEntryDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
  };

  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{width:"75rem"}}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Estimate Entry Details"
      modal
      className="p-fluid"
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    value={filters["ed_OR_NO"] ? filters["ed_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_OR_NO",
                        e.target.value,
                        filters["ed_OR_NO"]
                          ? filters["ed_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ed_OR_TY"] ? filters["ed_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_OR_TY",
                        e.target.value,
                        filters["ed_OR_TY"]
                          ? filters["ed_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ed_OR_CO"] ? filters["ed_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_OR_CO",
                        e.target.value,
                        filters["ed_OR_CO"]
                          ? filters["ed_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ed_LN_NO"] ? filters["ed_LN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_LN_NO",
                        e.target.value,
                        filters["ed_LN_NO"]
                          ? filters["ed_LN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ed_IT_NO"] ? filters["ed_IT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_IT_NO",
                        e.target.value,
                        filters["ed_IT_NO"]
                          ? filters["ed_IT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["itemDES"] ? filters["itemDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "itemDES",
                        e.target.value,
                        filters["itemDES"]
                          ? filters["itemDES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ed_EX_PR"] ? filters["ed_EX_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_EX_PR",
                        e.target.value,
                        filters["ed_EX_PR"]
                          ? filters["ed_EX_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ed_EX_CT"] ? filters["ed_EX_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_EX_CT",
                        e.target.value,
                        filters["ed_EX_CT"]
                          ? filters["ed_EX_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ed_OR_QT"] ? filters["ed_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_OR_QT",
                        e.target.value,
                        filters["ed_OR_QT"]
                          ? filters["ed_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ed_OR_DT"] ? filters["ed_OR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_OR_DT",
                        e.target.value,
                        filters["ed_OR_DT"]
                          ? filters["ed_OR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ed_RQ_DT"] ? filters["ed_RQ_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ed_RQ_DT",
                        e.target.value,
                        filters["ed_RQ_DT"]
                          ? filters["ed_RQ_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}>Estimate No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Line No</th>
                <th style={tableHeaderStyle}>Item No</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Amount</th>
                <th style={tableHeaderStyle}>Cost</th>
                <th style={tableHeaderStyle}>Order Quantity</th>
                <th style={tableHeaderStyle}>Order Date</th>
                <th style={tableHeaderStyle}>Req. Date</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                      key={rowData.imItNo}
                      onClick={() => onDataSelect(rowData,ItemlmId)}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "aliceblue" : "#ffffff",
                      }}
                      className="table-row"
                    >
                      <td style={tableCellStyle}>{rowData.ed_OR_NO}</td>
                      <td style={tableCellStyle}>{rowData.ed_OR_TY}</td>
                      <td style={tableCellStyle}>{rowData.ed_OR_CO}</td>
                      <td style={tableCellStyle}>{rowData.ed_LN_NO}</td>
                      <td style={tableCellStyle}>{rowData.ed_IT_NO}</td>
                      <td style={tableCellStyle}>{rowData.itemDES}</td>
                      <td style={tableCellStyle}>{rowData.ed_EX_PR}</td>
                      <td style={tableCellStyle}>{rowData.ed_EX_CT}</td>
                      <td style={tableCellStyle}>{rowData.ed_OR_QT}</td>
                      <td style={tableCellStyle}>{formatDate(rowData.ed_OR_DT)}</td>
                      <td style={tableCellStyle}>{formatDate(rowData.ed_RQ_DT)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
        />      
      </div>
    </Dialog>
  );
};
EstimateEntryPopup.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDataSelect: PropTypes.func,
};

export default EstimateEntryPopup;
