import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import SalesMethodologyDialog from "./SalesMethodologyDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import '../../../components/ListStyle/ListStyle.css';
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  sm_UK_NO: "",
  smSqNo: "",
  smMeNm: "",
  sm_NA_ME: "",
  sm_PR_TY: "",
  sm_DS_ON: "",
  sm_ET_TM: "",
  sm_AC_PL: "",
  sd_DR_NM: "",
  sd_DR_DS: "",
  sd_DR_IM: "",
};

const initialFilters = {
  smSqNo: { value: "", operation: "" },
  smMeNm: { value: "", operation: "" },
  sm_NA_ME: { value: "", operation: "" },
  sm_PR_TY: { value: "", operation: "" },
  sm_DS_ON: { value: "", operation: "" },
  sm_ET_TM: { value: "", operation: "" },
  sm_AC_PL: { value: "", operation: "" },
};

const SalesMethodologyMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);

  const [smMeNm, setsmMeNm] = useState([]);
  const [sm_AC_PL, setsm_AC_PL] = useState([]);
  const [sd_DR_NM, setsd_DR_NM] = useState([]);
  const [sm_UK_NO, setsm_UK_NO] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const [salesMethodologyData, setSalesMethodologyData] = useState([{}]);
  const [salesDriverData, setSalesDriverData] = useState([{}]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAddButtonClick = () => {
    setSalesMethodologyData([{}]);
    setSalesDriverData([{}]);
    setFormData(initialValue);
    setsm_UK_NO(null);
    setsmMeNm(null);
    setsm_AC_PL(null);
    setsd_DR_NM(null);
    setOpen(true);
  };

  const handleClose = () => {
    setsm_UK_NO(null);
    setFormData(initialValue);
    setsmMeNm(null);
    setsm_AC_PL(null);
    setsd_DR_NM(null);
    setOpen(false);
  };

  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/SalesMethodology/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const SalesMethodologyData = response.data;
          setFormData(SalesMethodologyData);
          setsmMeNm(SalesMethodologyData.smMeNm);
          setsm_AC_PL(SalesMethodologyData.sm_AC_PL);
          setsd_DR_NM(SalesMethodologyData.sd_DR_NM);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const addSalesCycleRow = () => {
    setSalesMethodologyData([...salesMethodologyData, {}]);
  };

  const deleteSalesCycleRow = () => {
    if (salesMethodologyData.length > 0) {
      const updatedData = [...salesMethodologyData];
      updatedData.pop();
      setSalesMethodologyData(updatedData);
    }
  };

  const addSalesDriverRow = () => {
    setSalesDriverData([...salesDriverData, {}]);
  };

  const deleteSalesDriverRow = () => {
    if (salesDriverData.length > 0) {
      const updatedData = [...salesDriverData];
      updatedData.pop();
      setSalesDriverData(updatedData);
    }
  };
  const url = `${domain}/api/sal_met/getAlLSales_Methodology`;

  useEffect(() => {
    handleSearch();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/SalesMethodology/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeDropDownFormData = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeDropDownFormData2 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/SalesMethodology/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const SalesMethodologyData1 = response.data;
        setSalesMethodologyData(SalesMethodologyData1.salesMethodology);
        setSalesDriverData(SalesMethodologyData1.salesDriver);
        setFormData(SalesMethodologyData1.salesMethodology[0]);
        setFormData(SalesMethodologyData1.salesDriver[0]);
        setsm_AC_PL(SalesMethodologyData1.salesMethodology[0].sm_AC_PL);
        setsd_DR_NM(SalesMethodologyData1.salesDriver[0].sd_DR_NM);
        setsmMeNm(response.data.smMeNm);
        setsm_UK_NO(response.data.sm_UK_NO);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFormSubmit = () => {
    if (sm_UK_NO !== null) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/SalesMethodology/Update`;

      if (confirm) {
        const table = document.getElementById("SalesCycle");
        const totalRowCount = table.tBodies[0].rows.length;
        const nam = document.getElementById("smMeNm");
        const salesMethodology = [];
        for (let i = 0; i < totalRowCount; i++) {
          const UK_NO = document.getElementById("sm_UK_NO_" + i);
          const SQ_NO = document.getElementById("smSqNo_" + i);
          const NA_ME = FormData['sm_NA_ME_'+i];
          // document.getElementById("sm_NA_ME_" + i);
          const DS_ON = document.getElementById("sm_DS_ON_" + i);
          const PR_TY = document.getElementById("sm_PR_TY_" + i);
          const ET_TM = document.getElementById("sm_ET_TM_" + i);
          const AC_PL = FormData['sm_AC_PL_'+i];
          // document.getElementById("sm_AC_PL_" + i);

          const Methodology = {
            sm_UK_NO: UK_NO.value,
            smSqNo: SQ_NO.value,
            sm_NA_ME: NA_ME,
            sm_DS_ON: DS_ON.value,
            sm_PR_TY: PR_TY.value,
            sm_ET_TM: ET_TM.value,
            sm_AC_PL: AC_PL,
            sm_CO_NO: company,
          };
          salesMethodology.push(Methodology);
        }
        const table1 = document.getElementById("SalesDriver");
        const totalRowCount1 = table1.tBodies[0].rows.length;
        const salesDriver = [];
        for (let i = 0; i < totalRowCount1; i++) {
          const SD_UK = document.getElementById("sd_UK_NO_" + i);
          const DR_NM = FormData['sd_DR_NM_'+i];
          // document.getElementById("sd_DR_NM_" + i);
          const DR_DS = document.getElementById("sd_DR_DS_" + i);
          const DR_IM = document.getElementById("sd_DR_IM_" + i);
          const SalesDrive = {
            sd_UK_NO: SD_UK.value,
            sd_DR_NM: DR_NM,
            sd_DR_DS: DR_DS.value,
            sd_DR_IM: DR_IM.value,
            sd_CO_NO: company,
          };
          salesDriver.push(SalesDrive);
        }
        const requestBody = {
          smMeNm: nam.value,
          salesMethodology,
          salesDriver,
        };

        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      const table = document.getElementById("SalesCycle");
      const totalRowCount = table.tBodies[0].rows.length;
      const name = document.getElementById("smMeNm");

      const salesMethodology = [];
      for (let i = 0; i < totalRowCount; i++) {
        const SQ_NO = document.getElementById("smSqNo_" + i);
        const NA_ME = FormData['sm_NA_ME_'+i];
        // document.getElementsByName("sm_NA_ME_" + i);
        const DS_ON = document.getElementById("sm_DS_ON_" + i);
        const PR_TY = document.getElementById("sm_PR_TY_" + i);
        const ET_TM = document.getElementById("sm_ET_TM_" + i);
        const AC_PL = FormData['sm_AC_PL_'+i];
        // document.getElementById("sm_AC_PL_" + i);

        const Methodology = {
          smSqNo: SQ_NO.value,
          sm_NA_ME: NA_ME,
          sm_DS_ON: DS_ON.value,
          sm_PR_TY: PR_TY.value,
          sm_ET_TM: ET_TM.value,
          sm_AC_PL: AC_PL,
          sm_CO_NO: company,
        };
        salesMethodology.push(Methodology);
      }

      const table1 = document.getElementById("SalesDriver");
      const totalRowCount1 = table1.tBodies[0].rows.length;

      const salesDriver = [];
      for (let i = 0; i < totalRowCount1; i++) {
        const DR_NM = FormData['sd_DR_NM_'+i];
        // document.getElementById("sd_DR_NM_" + i);
        const DR_DS = document.getElementById("sd_DR_DS_" + i);
        const DR_IM = document.getElementById("sd_DR_IM_" + i);

        const SalesDrive = {
          sd_DR_NM: DR_NM,
          sd_DR_DS: DR_DS.value,
          sd_DR_IM: DR_IM.value,
          sd_ME_NM: name.value,
          sd_CO_NO: company,
        };
        salesDriver.push(SalesDrive);
      }
      const requestBody = {
        smMeNm: name.value,
        salesMethodology,
        salesDriver,
      };
      axios
        .post(`${domain}/api/SalesMethodology/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
    }
  };

  const handleResponse = (data) => {
    const { warnings = [], errors = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errors.length > 0) {
      const errorMessage = errors.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
      });
    }

    if (errors.length === 0) {
      handleClose();
      handleSearch();
    }
  };
  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "red !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "white !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "white !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const tableHeaderStyleHC = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#e1e1e1",
  };
  const tableHeaderStyleCheck = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width: "0%",
  };  
  const tableCellStyleSPT = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sm_NA_ME"] ? filters["sm_NA_ME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sm_NA_ME",
                        e.target.value,
                        filters["sm_NA_ME"]
                          ? filters["sm_NA_ME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["smSqNo"] ? filters["smSqNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "smSqNo",
                        e.target.value,
                        filters["smSqNo"]
                          ? filters["smSqNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["smMeNm"] ? filters["smMeNm"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "smMeNm",
                        e.target.value,
                        filters["smMeNm"]
                          ? filters["smMeNm"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sm_DS_ON"] ? filters["sm_DS_ON"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sm_DS_ON",
                        e.target.value,
                        filters["sm_DS_ON"]
                          ? filters["sm_DS_ON"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sm_PR_TY"] ? filters["sm_PR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sm_PR_TY",
                        e.target.value,
                        filters["sm_PR_TY"]
                          ? filters["sm_PR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sm_ET_TM"] ? filters["sm_ET_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sm_ET_TM",
                        e.target.value,
                        filters["sm_ET_TM"]
                          ? filters["sm_ET_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sm_AC_PL"] ? filters["sm_AC_PL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sm_AC_PL",
                        e.target.value,
                        filters["sm_AC_PL"]
                          ? filters["sm_AC_PL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyleCheck}></th>
                <th style={tableHeaderStyle}>Methodology</th>
                <th style={tableHeaderStyleHC}>Sequence</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyleHC}>Probability</th>
                <th style={tableHeaderStyleHC}>Time to Close</th>
                <th style={tableHeaderStyle}>Action Plan</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.sm_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        type="checkbox"
                        value={rowData.sm_UK_NO}
                        checked={selectedRows.includes(rowData.sm_UK_NO)}
                        onChange={() => handleRowSelect(rowData.sm_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.smMeNm}</td>
                    <td style={tableCellStyleSPT}>{rowData.smSqNo}</td>
                    <td style={tableCellStyle}>{rowData.sm_NA_ME}</td>
                    <td style={tableCellStyle}>{rowData.sm_DS_ON}</td>
                    <td style={tableCellStyleSPT}>{rowData.sm_PR_TY}</td>
                    <td style={tableCellStyleSPT}>{rowData.sm_ET_TM}</td>
                    <td style={tableCellStyle}>{rowData.sm_AC_PL}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <SalesMethodologyDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeDropDownFormData={onchangeDropDownFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        smMeNm={smMeNm}
        sm_AC_PL={sm_AC_PL}
        sd_DR_NM={sd_DR_NM}
        sm_UK_NO={sm_UK_NO}
        salesMethodologyData={salesMethodologyData}
        addSalesCycleRow={addSalesCycleRow}
        deleteSalesCycleRow={deleteSalesCycleRow}
        setSalesDriverData={setSalesDriverData}
        salesDriverData={salesDriverData}
        addSalesDriverRow={addSalesDriverRow}
        deleteSalesDriverRow={deleteSalesDriverRow}
      />
    </div>
  );
};

export default SalesMethodologyMain;
