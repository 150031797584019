import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import Pagination from "views/common/Pagination";

const initialFilters = {
  cm_CN_NO: { value: "", operation: "" },
  cm_CN_TY: { value: "", operation: "" },
  cm_CN_CO: { value: "", operation: "" },
  cm_CU_NO: { value: "", operation: "" },
  cm_PR_NO: { value: "", operation: "" },
  cm_PR_TY: { value: "", operation: "" },
  cm_CR_CD: { value: "", operation: "" },
  cm_EX_RT: { value: "", operation: "" },
  cm_BS_CR: { value: "", operation: "" },
  cm_CN_ST: { value: "", operation: "" },
};

const ConrtactPopup = ({ visible, onClose, onDataSelect, ItemlmId}) => {
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [open, setOpen] = useState(false);
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;

        if (column === 'cm_CN_NO') {
          constructedColumn = 'cm_CN_NO';
        } else {
          constructedColumn = column.toUpperCase();
        }

        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(visible);
    handleSearch();
    setCurrentPage(0);
  }, [currentPage, pageSize, filters, visible]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ContractMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
  };

  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{width:"75rem"}}
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    value={filters["cm_CN_NO"] ? filters["cm_CN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cm_CN_NO",
                        e.target.value,
                        filters["cm_CN_NO"]
                          ? filters["cm_CN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cm_CN_TY"] ? filters["cm_CN_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cm_CN_TY",
                        e.target.value,
                        filters["cm_CN_TY"]
                          ? filters["cm_CN_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cm_CN_CO"] ? filters["cm_CN_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cm_CN_CO",
                        e.target.value,
                        filters["cm_CN_CO"]
                          ? filters["cm_CN_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cm_CU_NO"] ? filters["cm_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cm_CU_NO",
                        e.target.value,
                        filters["cm_CU_NO"]
                          ? filters["cm_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cm_PR_NO"] ? filters["cm_PR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cm_PR_NO",
                        e.target.value,
                        filters["cm_PR_NO"]
                          ? filters["cm_PR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["proj_NAME"] ? filters["proj_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "proj_NAME",
                        e.target.value,
                        filters["proj_NAME"]
                          ? filters["proj_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cm_PR_TY"] ? filters["cm_PR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cm_PR_TY",
                        e.target.value,
                        filters["cm_PR_TY"]
                          ? filters["cm_PR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cm_CR_CD"] ? filters["cm_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cm_CR_CD",
                        e.target.value,
                        filters["cm_CR_CD"]
                          ? filters["cm_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cm_EX_RT"] ? filters["cm_EX_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cm_EX_RT",
                        e.target.value,
                        filters["cm_EX_RT"]
                          ? filters["cm_EX_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cm_BS_CR"] ? filters["cm_BS_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cm_BS_CR",
                        e.target.value,
                        filters["cm_BS_CR"]
                          ? filters["cm_BS_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cm_CN_ST"] ? filters["cm_CN_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cm_CN_ST",
                        e.target.value,
                        filters["cm_CN_ST"]
                          ? filters["cm_CN_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}>Contract No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Customer No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Project No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Project Type</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Exchange Rate</th>
                <th style={tableHeaderStyle}>Base Currency</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                      key={rowData.imItNo}
                      onClick={() => onDataSelect(rowData,ItemlmId)}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "aliceblue" : "#ffffff",
                      }}
                      className="table-row"
                    >
                      <td style={tableCellStyle}>{rowData.cm_CN_NO}</td>
                      <td style={tableCellStyle}>{rowData.cm_CN_TY}</td>
                      <td style={tableCellStyle}>{rowData.cm_CN_CO}</td>
                      <td style={tableCellStyle}>{rowData.cm_CU_NO}</td>
                      <td style={tableCellStyle}>{rowData.name}</td>
                      <td style={tableCellStyle}>{rowData.cm_PR_NO}</td>
                      <td style={tableCellStyle}>{rowData.proj_NAME}</td>
                      <td style={tableCellStyle}>{rowData.cm_PR_TY}</td>
                      <td style={tableCellStyle}>{rowData.cm_CR_CD}</td>
                      <td style={tableCellStyle}>{rowData.cm_EX_RT}</td>
                      <td style={tableCellStyle}>{rowData.cm_BS_CR}</td>
                      <td style={tableCellStyle}>{rowData.cm_CN_ST}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
        />      
      </div>
    </Dialog>
  );
};
ConrtactPopup.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDataSelect: PropTypes.func,
};

export default ConrtactPopup;
