import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import TimeCardInterfaceDialog from "./TimeCardInterfaceDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  tcUkNo: "",
  tc_TR_NO:"",
  tc_TR_TY:"",
  tc_TR_CO:"",
  tc_TR_DT:moment(moment().toDate()).format("YYYYMMDD"),
  tc_EM_CD:"",
  tc_EM_NM:"",
  tc_SH_FT:"",
  tc_IN_TM:"",
  tc_OT_TM:"",
  tc_BR_AK:"",
  tc_NR_DU:"",
  tc_OT_TM:"",
  TC_OT_DU:"",
  tc_ST_CD:"",
  tc_RM_RK:"",
  tc_PR_FL:"",
};

const initialFilters = {
  tc_TR_NO: { value: "", operation: "" },
  tc_TR_TY: { value: "", operation: "" },
  tc_TR_CO: { value: "", operation: "" },
  tc_TR_DT: { value: "", operation: "" }, 
  tc_EM_CD: { value: "", operation: "" },
  tc_EM_NM: { value: "", operation: "" },
  tc_SH_FT: { value: "", operation: "" },
  tc_IN_TM: { value: "", operation: "" },
  tc_OT_TM: { value: "", operation: "" },
  tc_BR_AK: { value: "", operation: "" },
  tc_NR_DU: { value: "", operation: "" },
  tc_OT_TM: { value: "", operation: "" },
  TC_OT_DU: { value: "", operation: "" },
  tc_ST_CD: { value: "", operation: "" },
  tc_RM_RK: { value: "", operation: "" },
  tc_PR_FL: { value: "", operation: "" },
};

const WorkCentreMain = () => {
  // buttonstyles
  const { formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle, tableHeaderStyle,
    inputStyle, tableCellStyle, rightalined
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData1, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [pi_TR_TY, setpi_TR_TY] = useState("");
  const [tc_TR_TY, settc_TR_TY] = useState("");

  
  // drodpdowns
  const [pi_SU_NO, setpi_SU_NO] = useState("");
  const [pi_SH_TO, setpi_SH_TO] = useState("");
  const [ep_EX_CT, setep_EX_CT] = useState("");
  const [ep_EX_TY, setep_EX_TY] = useState("");
  const [ep_LO_CN, setep_LO_CN] = useState("");
  // AdvanceSearch
  const [selectedSupplier, setselectedSupplier] = useState("");
  const [selectedshipto, setselectedshipto] = useState("");
  const [item_DES, setitem_DES] = useState("");
  

  const [ep_PR_SU, setep_PR_SU] = useState("");
  const [tc_TR_NO, settc_TR_NO] = useState("");

  const [tc_PR_FL, settc_PR_FL] = useState("");
  
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [TimeCardInterfaceTableData, setTimeCardInterfaceTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setTimeCardInterfaceTableData([{}]);
    setpi_SH_TO(null);
    setpi_SU_NO(null);
    settc_TR_NO(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleFormDataChange = (name, value) => {
    setFormData({ ...setFormData, [name]: value });
    settc_PR_FL(value);
  };



  // time fromat
  const formatTime = (inputTime) => {
    if (!inputTime) {
      return inputTime;
    }
    return moment(inputTime, "HH:mm:ss").format("HH:mm:ss");
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }

    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/TimeCard/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const CashFlowData = response.data;
          const updatedCashFlowData = CashFlowData.timeCardList.map((target) => {
            const UpdatedworkData = { ...target };
            delete UpdatedworkData.tcUkNo;
            return UpdatedworkData;
          });
          setTimeCardInterfaceTableData(updatedCashFlowData);
          setFormData(updatedCashFlowData[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [tc_TR_CO, settc_TR_CO] = useState("");
  
  const handleDefault = async () => {
    settc_TR_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];

    const requestBody = {
      "version_ID": LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if (dataDefault.versionIds[i].field_TYPE === "SCREEN") {
            const fName = dataDefault.versionIds[i].field_ID;
            
            if (fName === "TC_TR_TY") {
              settc_TR_TY(dataDefault.versionIds[i].default_VALUE) 
            }
          }
          if (dataDefault.versionIds[i].field_TYPE === "LIST") {
            count++;
            const def = {
              "column": dataDefault.versionIds[i].field_ID,
              "value": dataDefault.versionIds[i].default_VALUE,
              "operation": "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault, count);
      } else {
        handleSearch(listDefault, count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists, nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for (let i = 0; i < nos; i++) {
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/TimeCard/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/TimeCard/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const PurchaseData = response.data;
        setTimeCardInterfaceTableData(PurchaseData.timeCardList);
        setFormData(PurchaseData.timeCardList[0]);
        // setselectedSupplier(PurchaseData.purchaseOrderInterfaceList.cus_NAME);
        // setselectedshipto(PurchaseData.purchaseOrderInterfaceList.shipto_NAME); 
        // setitem_DES(PurchaseData.purchaseOrderInterfaceList.item_DES); 
        settc_TR_NO(PurchaseData.timeCardList[0].tc_TR_NO);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBody = {};
  function values() {
    const table = document.getElementById("TimeCardInterfaceTableData");
    const totalRowCount1 = table.tBodies[0].rows.length;
    const timeCardList = [];
    for (let i = 0; i < totalRowCount1; i++) {
      const IN_TM = document.getElementById("tc_IN_TM_" + i);
      const OT_TM = document.getElementById("tc_OT_TM_" + i);
      const OT_DU = document.getElementById("et_IN_TM_" + i);


      const details = { 
        tcUkNo: document.getElementById("tcUkNo_" + i).value,
        tc_EM_CD: document.getElementById("tc_EM_CD_" + i).value,
        tc_EM_NM: document.getElementById("tc_EM_NM_" + i).value,
        tc_SH_FT: document.getElementById("tc_SH_FT_" + i).value,
        tc_IN_TM: IN_TM.value.replaceAll(":", ""),
        tc_OT_TM: OT_TM.value.replaceAll(":", ""),
        tc_BR_AK: document.getElementById("tc_BR_AK_" + i).value,
        tc_NR_DU: document.getElementById("tc_NR_DU_" + i).value,
       // tc_OT_DU: OT_DU.value.replaceAll(":", ""),
       tc_OT_DU: document.getElementById("tc_OT_DU_" + i).value,
        tc_TO_DU: document.getElementById("tc_TO_DU_" + i).value,
        tc_ST_CD: document.getElementById("tc_ST_CD_" + i).value,
        tc_RM_RK: document.getElementById("tc_RM_RK_" + i).value,
        tc_PR_FL : table.tBodies[0].rows[i].cells[12].children[0].children[1].children[0].value,
      };
      timeCardList.push(details);
    }
    requestBody = {

      timeCardList,
      tcUkNo: FormData1.tcUkNo,
      tc_TR_NO: tc_TR_NO,
      tc_TR_TY: tc_TR_TY,
      tc_TR_CO: tc_TR_CO,
      tc_TR_DT: FormData1.tc_TR_DT,
    };
  }
  const handleFormSubmit = (type) => {
    values(); // Assuming this function does necessary validations or updates
    // Check if tc_TR_NO exists (for update or process case)
    if (FormData1.tc_TR_NO) {
      if (type === "process") {
        // Process Logic
        const confirm = window.confirm("Do you want to Process ?");
        const process = `${domain}/api/TimeCard/ProcessInterface`;
        if (confirm) {
          FormData1.tc_TR_TY = tc_TR_TY; // Ensure proper setting
  
          axios
            .post(process, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`,
                "x-flag": localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data); // Handle success response
            })
            .catch((error) => {
              console.error("Error processing data:", error);
            });
        }
      } else if (FormData1.tcUkNo) {
        // Update Logic
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/TimeCard/Update`;
        if (confirm) {
          axios
            .put(update, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`,
                "x-flag": localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data); // Handle success response
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      }
    } else {
      // Insert logic when tc_TR_NO doesn't exist
      const TR_DT = FormData1.tc_TR_DT;
      if (TR_DT) {
        const currentYear = TR_DT.toString();
        const firstHalf = currentYear.substring(0, 2);
        const secondHalf = currentYear.substring(2, 4);
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
        const parameters = `${tc_TR_TY}/${secondHalf}/${company}/${firstHalf}`;
        const finalUrl1 = `${urlNextNumber}/${parameters}`;
  
        fetch(finalUrl1, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
        })
          .then((resp) => {
            if (!resp.ok) {
              throw new Error(`Request failed with status: ${resp.status}`);
            }
            return resp.json();
          })
          .then((resp) => {
            onchangeFormData("tc_TR_NO", resp.dn_DO_NO);
            settc_TR_NO(resp.dn_DO_NO);
            setFormData({ ...FormData1, ["tc_TR_NO"]: resp.dn_DO_NO });
  
            axios
              .post(`${domain}/api/TimeCard/Add`, requestBody, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`,
                  "x-flag": localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data); // Handle success response
              })
              .catch((error) => {
                console.error("Error saving data:", error);
              });
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }
  };
  


  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack >
        <div style={{ background: "red !important", color: "white", fontSize: "10px" }} >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  // const exportExcel = () => {
  //   import("xlsx").then((xlsx) => {
  //     const worksheet = xlsx.utils.json_to_sheet(tableData);
  //     const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  //     const excelBuffer = xlsx.write(workbook, {
  //       bookType: "xlsx",
  //       type: "array",
  //     });

  //     const data = new Blob([excelBuffer], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //     });
  //     saveAs(data, "products_export.xlsx");
  //   });
  // };

  // const rightToolbarTemplate = (
  //   <React.Fragment>
  //     <Button
  //       style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
  //       icon="pi pi-upload"
  //       className="p-button-help"
  //       rounded
  //       onClick={exportExcel}
  //       data-pr-tooltip="XLS"
  //     />
  //   </React.Fragment>
  // );
  // upload function
  const url = `${domain}/api/FileUpload/UploadTimeCard`;

  const UploadExcel = (event) => {
    const FileMeta = event.target.files[0];

    if (FileMeta) {
      const fileName = FileMeta.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (fileExtension === "csv" || fileExtension === "xlsx") {
        const formData = new FormData();
        formData.append('FileMeta', FileMeta); // Append the file
        formData.append('type', 'I2'); // Assuming 'type' is a required parameter

        try {
          fetch(url, {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`, // Assuming token is defined elsewhere
              "x-company": `${company}`, "x-flag": localStorage.getItem("userType"), // Assuming company is defined elsewhere
            },
          })
            .then((resp) => {
              if (!resp.ok) {
                throw new Error('Failed to upload file');
              }
              return resp.json();
            })
            .then((resp) => {
              // Assuming you have a toast mechanism like toast.current.show
              toast.current.show({
                severity: "success",
                summary: "Import Successful",
              });
              // Assuming handleSearch is a function to trigger search after import
              handleSearch();
            })
            .catch((error) => {
              console.error("Error importing file:", error);
              toast.current.show({
                severity: "error",
                summary: "Import Error",
                detail: "Failed to import. Please try again later.",
              });
            });
        } catch (error) {
          console.error("Error parsing imported data:", error);
          toast.current.show({
            severity: "error",
            summary: "Import Error",
            detail:
              "Invalid data format. Please ensure the imported file is in the correct format.",
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Unsupported File Type",
          detail: "Please select a CSV or XLSX file for Upload.",
        });
      }
    }
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px", backgroundColor: "#1ed01e", border: "none" }}
        type="button"
        icon="pi pi-download"
        className="p-button-download"
        tooltip="Upload"
        rounded
        onClick={() => {
          const input = document.getElementById("fileInput");
          input.click();
        }}
        data-pr-tooltip="Upload"
      />
      <input
        id="fileInput"
        type="file"
        accept=".csv, .xlsx"
        onChange={UploadExcel}
        style={{ display: "none" }}
      />

    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tc_TR_NO"] ? filters["tc_TR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tc_TR_NO",
                        e.target.value,
                        filters["tc_TR_NO"]
                          ? filters["tc_TR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tc_TR_TY"] ? filters["tc_TR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tc_TR_TY",
                        e.target.value,
                        filters["tc_TR_TY"] ? filters["tc_TR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tc_TR_CO"] ? filters["tc_TR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tc_TR_CO",
                        e.target.value,
                        filters["tc_TR_CO"]
                          ? filters["tc_TR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["tc_TR_DT"] ? filters["tc_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tc_TR_DT",
                        e.target.value,
                        filters["tc_TR_DT"]
                          ? filters["tc_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tc_EM_CD"] ? filters["tc_EM_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tc_EM_CD",
                        e.target.value,
                        filters["tc_EM_CD"] ? filters["tc_EM_CD"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tc_EM_NM"] ? filters["tc_EM_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tc_EM_NM",
                        e.target.value,
                        filters["tc_EM_NM"]
                          ? filters["tc_EM_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tc_SH_FT"] ? filters["tc_SH_FT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tc_SH_FT",
                        e.target.value,
                        filters["tc_SH_FT"]
                          ? filters["tc_SH_FT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle} 
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tc_IN_TM"] ? filters["tc_IN_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tc_IN_TM",
                        e.target.value,
                        filters["tc_IN_TM"]
                          ? filters["tc_IN_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tc_OT_TM"] ? filters["tc_OT_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tc_OT_TM",
                        e.target.value,
                        filters["tc_OT_TM"]
                          ? filters["tc_OT_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tc_BR_AK"] ? filters["tc_BR_AK"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tc_BR_AK",
                        e.target.value,
                        filters["tc_BR_AK"]
                          ? filters["tc_BR_AK"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tc_NR_DU"] ? filters["tc_NR_DU"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tc_NR_DU",
                        e.target.value,
                        filters["tc_NR_DU"]
                          ? filters["tc_NR_DU"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tc_OT_TM"] ? filters["tc_OT_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tc_OT_TM",
                        e.target.value,
                        filters["tc_OT_TM"]
                          ? filters["tc_OT_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["TC_OT_DU"] ? filters["TC_OT_DU"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "TC_OT_DU",
                        e.target.value,
                        filters["TC_OT_DU"]
                          ? filters["TC_OT_DU"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tc_ST_CD"] ? filters["tc_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tc_ST_CD",
                        e.target.value,
                        filters["tc_ST_CD"]
                          ? filters["tc_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tc_RM_RK"] ? filters["tc_RM_RK"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tc_RM_RK",
                        e.target.value,
                        filters["tc_RM_RK"]
                          ? filters["tc_RM_RK"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tc_PR_FL"] ? filters["tc_PR_FL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tc_PR_FL",
                        e.target.value,
                        filters["tc_PR_FL"]
                          ? filters["tc_PR_FL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                

              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Transaction No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Date</th>
                <th style={tableHeaderStyle}>Employee Code</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Shift</th>
                <th style={tableHeaderStyle}>In Time</th>
                <th style={tableHeaderStyle}>Out Time</th>
                <th style={tableHeaderStyle}>Break</th>
                <th style={tableHeaderStyle}>Working Duration</th>
                <th style={tableHeaderStyle}>Overtime</th>
                <th style={tableHeaderStyle}>Total Duration</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Remarks</th>
                <th style={tableHeaderStyle}>Processed</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.tcUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.tcUkNo}
                        checked={selectedRows.includes(rowData.tcUkNo)}
                        onChange={() => handleRowSelect(rowData.tcUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.tc_TR_NO}</td>
                    <td style={tableCellStyle}>{rowData.tc_TR_TY}</td>
                    <td style={tableCellStyle}>{rowData.tc_TR_CO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.tc_TR_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.tc_EM_CD}</td>
                    <td style={tableCellStyle}>{rowData.tc_EM_NM}</td>
                    <td style={tableCellStyle}>{rowData.tc_SH_FT}</td>
                    <td style={tableCellStyle}>{formatTime(rowData.tc_IN_TM)}</td>
                    <td style={tableCellStyle}>{formatTime(rowData.tc_OT_TM)}</td>
                    <td style={tableCellStyle}>{rowData.tc_BR_AK}</td>
                    <td style={tableCellStyle}>{rowData.tc_NR_DU}</td>
                    <td style={tableCellStyle}>{formatTime(rowData.tc_OT_TM)}</td>
                    <td style={tableCellStyle}>{rowData.TC_OT_DU}</td>
                    <td style={tableCellStyle}>{rowData.tc_ST_CD}</td>
                    <td style={tableCellStyle}>{rowData.tc_RM_RK}</td>
                    <td style={tableCellStyle}>{rowData.tc_PR_FL}</td>

                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <TimeCardInterfaceDialog
        open={open}
        handleClose={handleClose}
        data={FormData1}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        TimeCardInterfaceTableData={TimeCardInterfaceTableData}
        setTimeCardInterfaceTableData={setTimeCardInterfaceTableData}
      
        ep_PR_SU={ep_PR_SU}
        pi_SU_NO={pi_SU_NO}
        setpi_SU_NO={setpi_SU_NO}
       
        pi_SH_TO={pi_SH_TO}
        setpi_SH_TO={setpi_SH_TO}
        ep_EX_TY={ep_EX_TY}
        setep_EX_TY={setep_EX_TY}
        ep_LO_CN={ep_LO_CN}
        setep_LO_CN={setep_LO_CN}
        selectedSupplier={selectedSupplier}
        setselectedSupplier={setselectedSupplier}
        selectedshipto={selectedshipto}
        setselectedshipto={setselectedshipto}
        item_DES={item_DES}
        setitem_DES={setitem_DES}
        pi_TR_TY={pi_TR_TY}
        setpi_TR_TY={setpi_TR_TY}
        tc_TR_NO={tc_TR_NO}
        tc_TR_TY={tc_TR_TY}
        settc_TR_TY={settc_TR_TY}

        handleFormDataChange={handleFormDataChange}
        tc_PR_FL={tc_PR_FL}
        settc_PR_FL={settc_PR_FL}
        tc_TR_CO={tc_TR_CO}
        settc_TR_CO={settc_TR_CO}
      />
    </div>
  );
};

export default WorkCentreMain;
