import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import ParentItemPopup from "../Popups/ParentItemPopup";
import moment from "moment";
import axios from "axios";
import { InputMask } from "primereact/inputmask";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import EstimateEntryPopup from "../Popups/EstimateEntryPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function EstimateLabourDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  EstimateLabourTableData,
  setEstimateLabourTableData,
  setbd_BL_ST,
  setbd_DO_TY,
  SelectedItem1,
  setselectedItem1,
  SelectedDesc1,
  setselectedDesc1,
  SelectedemEMNO1,
  setSelectedemEMNO1,
  ed_OR_QT,
  ed_UT_CT,
  el_EM_AM,
  el_LN_NO,
  setel_LN_NO,
  seted_UT_CT,
  seted_OR_QT,
  setel_EM_AM,
}) {
  const {
    elUkNo,
    el_OR_NO,
    el_OR_TY,
    el_OR_CO,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Handle form data change
  const onChangeTime=(name,value,index)=>{
    value= moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value,index);
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AddresstypeE, setAddresstypeE] = useState("E");
  const [AddressPopupVisible, setAddressPopupVisible] = useState(false);
  const openAddressPopup = (e) => {
    setAddressPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect1 = (data) => {
    setSelectedemEMNO1(data.ab_AD_NM);
    onchangeFormData(`el_AD_NO_${SelectedRowIndex}`, data.abAdNo);
    onchangeFormData(`name_${SelectedRowIndex}`, data.ab_AD_NM);
    setAddressPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [Item1Visible, setItem1Visible] = useState(false);
  const handleItem1 =async (data) => {
    setselectedItem1(data.im_IT_NM)
    setselectedDesc1(data.im_IT_DS)
    onchangeFormData("el_PA_IT", data.im_IT_NM);
    onchangeFormData("itemDES", data.im_IT_DS);
    setItem1Visible(false);
    setShowSearchIcon(false);
  };
  const openItem1Popup = (e) => {
    setItem1Visible(true);
    setSelectedRowIndex(e);
  };
  const [EstimateEntryPopupVisible, setEstimateEntryPopupVisible] = useState(false);
  const openEstimateEntryPopup = () => {
    setEstimateEntryPopupVisible(true);
  };
  const handleEstimateEntry = (data) => {
    onchangeFormData("el_OR_TY", data.ed_OR_TY);
    onchangeFormData("el_OR_CO", data.ed_OR_CO);
    seted_UT_CT(data.ed_EX_CT);
    seted_OR_QT(data.ed_OR_QT);
    setel_EM_AM(data.ed_EX_PR);
    setel_LN_NO(data.ed_LN_NO);
    setselectedItem1(data.ed_IT_NO)
    setselectedDesc1(data.itemDES)
    document.getElementById("el_OR_NO").value =  data.ed_OR_NO;
    setEstimateEntryPopupVisible(false);
    setShowSearchIcon(false);
  };
  function updateTotalHours(index) {
    let el_IN_TM = EstimateLabourTableData[index].el_IN_TM;
    let el_OT_TM = EstimateLabourTableData[index].el_OT_TM;

    if (el_IN_TM !== undefined && el_OT_TM !== undefined && el_OT_TM !== "" && el_IN_TM !== "") {
      let inTime = moment(el_IN_TM, "HHmmss").format("HH:mm:ss");
      let outTime = moment(el_OT_TM, "HHmmss").format("HH:mm:ss");

      const checkInTime = moment(`${inTime}`, "HH:mm:ss");
      const checkOutTime = moment(`${outTime}`, "HH:mm:ss");

      const diffInMillis = checkOutTime - checkInTime;

      const totalHours = diffInMillis / (1000 * 60 * 60);

      onchangeFormData("el_NT_TM", totalHours.toFixed(2), index);
    } else {
      onchangeFormData("el_NT_TM","",index );
    }
  };
  const onchangeFormUnit = async(e,index) => {
    handleTime(index);
  };
  const handleTime =async (SelectedRowIndex) => {
    const Url = `${domain}/api/EstimateLabour/GetTimeAmount`;
    var rate = document.getElementById (`el_EL_RT_${SelectedRowIndex}`).value;
    if(!rate){
      rate = 0;
    }
    const requestData={
      "estimateLabourList":[
        {
          "el_NT_TM":document.getElementById (`el_NT_TM_${SelectedRowIndex}`).value,
          "el_EL_RT": rate,
        }
      ]
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data;

      if (dataLead) {
        document.getElementById (`el_EL_AM_${SelectedRowIndex}`).value =  dataLead.Amount;

      } else {
        console.error("Invalid API response:" );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  
  };
  const addRow = () => {
    setEstimateLabourTableData([...EstimateLabourTableData, {}]);
  };
  const deleteRow = () => {
    if (EstimateLabourTableData.length > 0) {
      const updatedData = [...EstimateLabourTableData];
      updatedData.pop();
      setEstimateLabourTableData(updatedData);
    }
  };  
const isUpdateMode = Boolean(elUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{elUkNo ? "Revise Estimate Labour" : "Add Estimate Labour"}</h5>
    <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
        tooltip={isUpdateMode ? "Update" : "Submit"}
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
         <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="el_OR_NO" className="font-bold">
              Estimate No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText
                    id="el_OR_NO"
                    name="el_OR_NO"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={el_OR_NO}
                  />
                   {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openEstimateEntryPopup}
                    ></i>
                   )}
              </div>
                </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="el_OR_TY" className="font-bold">
           Type 
          </label>
          <InputText disabled
            id="el_OR_TY"
            name="el_OR_TY"
            value={el_OR_TY}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="el_OR_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="el_OR_CO"
            name="el_OR_CO"
            value={el_OR_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="el_LN_NO" className="font-bold">
                   Line No
                  </label>
                  <InputText  disabled
                    id="el_LN_NO"
                    name="el_LN_NO"
                    value={el_LN_NO}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="el_EM_AM" className="font-bold">
                   Amount
                  </label>
                  <InputText disabled
                    id="el_EM_AM"
                    name="el_EM_AM"
                    value={el_EM_AM}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ed_UT_CT" className="font-bold">
                   Cost
                  </label>
                  <InputText disabled
                    id="ed_UT_CT"
                    name="ed_UT_CT"
                    value={ed_UT_CT}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ed_OR_QT" className="font-bold">
                   Estimated Hours
                  </label>
                  <InputText disabled
                    id="ed_OR_QT"
                    name="ed_OR_QT"
                    value={ed_OR_QT}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="el_PA_IT" className="font-bold">
                Parent Item <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText disabled
                      id="el_PA_IT"
                      name="el_PA_IT"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedItem1}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItem1Popup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="itemDES" className="font-bold">
                  Description
                  </label>
                  <InputText disabled
                    id="itemDES"
                    name="itemDES"
                    value={SelectedDesc1}
                   readOnly
                  />
            </div>
       
          </div>
        </div>
        <div  style={tablestyle} >
    <div className="table-buttons" style={tablebutton} >
      {(
      <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
      )}
        {(
      <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
      )}
      </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="operationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th style={{display:"none"}}>Address No</th>
              <th>Address No <span style={{ color: 'red', }}>*</span></th>
              <th>Date</th>
              <th>In Time</th>
              <th>Out Time</th>
              <th>Net Time</th>
              <th>Rate</th>
              <th>Amount</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {EstimateLabourTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`elUkNo_${index}`}
                    name={`elUkNo_${index}`}
                    value={row.elUkNo}
                  />
                </td>
                    <td style={{display:"none"}}> 
                      <InputText
                        id={`el_AD_NO_${index}`}
                        name={`el_AD_NO_${index}`}
                        onFocus={() => setShowSearchIcon(true)}
                        value={row.el_AD_NO ? row.el_AD_NO: data[`${"el_AD_NO"}_${index}`]}
                        onChange={(e) => onchangedata(e)}
                      />
                      </td>
                      <td>
                      <div style={{ display: "flex",width:"15rem"}}>
                      <InputText
                        id={`name_${index}`}
                        name={`name_${index}`}
                        onChange={(e) => onchangedata(e)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={row.name ? row.name : data[`${"name"}_${index}`]}
                      />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "7%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openAddressPopup(index)}
                        ></i>
                      )}
                      </div>
                      </td>
                      <td>
                      <Calendar  
                        id={`el_TR_DT_${index}`}
                        name={`el_TR_DT_${index}`}
                        value={row.el_TR_DT? moment(row.el_TR_DT, "YYYYMMDD").toDate(): null}
                        onChange={(e) => onDateChange({ target: { name: "el_TR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                        dateFormat="dd/mm/yy"
                      />
                      </td>
                      <td>
                      <InputMask  
                        id={`el_IN_TM_${index}`}
                        name={`el_IN_TM_${index}`}
                        mask="99:99:99"
                        value={moment(row.el_IN_TM, "HHmmss").format("HH:mm:ss")}
                        onBlur={(e) => {
                          onChangeTime("el_IN_TM",e.target.value,index);
                          updateTotalHours(index);
                        }}
                      />
                     </td>
                    <td>
                      <InputMask 
                        id={`el_OT_TM_${index}`}
                        name={`el_OT_TM_${index}`}
                        mask="99:99:99"
                        onBlur={(e) => {
                          onChangeTime("el_OT_TM",e.target.value,index);
                          updateTotalHours(index);
                        }}
                        value={moment(row.el_OT_TM, "HHmmss").format("HH:mm:ss")}
                      />
                    </td>
                  {/* <td>
                    <InputMask style={{width:"120px"}} disabled
                      id={`el_NT_TM_${index}`}
                      name={`el_NT_TM_${index}`}
                      mask="99:99:99"
                      onBlur={(e) => {
                        onChangeTime("el_NT_TM",e.target.value,index);
                      }}
                      value={moment(row.el_NT_TM, "HHmmss").format("HH:mm:ss")}
                    />
                  </td> */}
                  <td>
                    <InputText  disabled 
                      id={`el_NT_TM_${index}`}
                      name={`el_NT_TM_${index}`}
                      defaultValue={row.el_NT_TM}
                      readOnly
                    />
                  </td>
                  <td >
                    <InputText  
                      id={`el_EL_RT_${index}`}
                      name={`el_EL_RT_${index}`}
                      defaultValue={row.el_EL_RT}
                      onChange={(e) => {
                        onchangeFormUnit(e, index);
                      }}
                    />
                  </td>
                  <td >
                    <InputText disabled 
                      id={`el_EL_AM_${index}`}
                      name={`el_EL_AM_${index}`}
                      defaultValue={row.el_EL_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText  style={{width:"50ch"}}
                      id={`el_RE_MK_${index}`}
                      name={`el_RE_MK_${index}`}
                      defaultValue={row.el_RE_MK}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      </div>  
      <AddressBookPopup
        visible={AddressPopupVisible}
        onClose={() => setAddressPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={AddresstypeE}
      />
      <ParentItemPopup
        visible={Item1Visible}
        onClose={() => setItem1Visible(false)}
        onDataSelect={handleItem1}
        formData={data}
      />

<EstimateEntryPopup
        visible={EstimateEntryPopupVisible}
        onClose={() => setEstimateEntryPopupVisible(false)}
        onDataSelect={handleEstimateEntry}
        formData={data}
      />
    </Dialog>
  );
}
