import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
export default function PaymentTermDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  pt_CO_NO,
 
 
}) {
  const { 
    ptUkNo,
    pt_PY_CO,
    pt_PY_DS,
    pt_NO_DA,
   
  } = data;


  const [activeTab, setActiveTab] = useState(0);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");


  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl1 = `${urlcost}`;
  const url = `${domain}/api/ClassCode/All`;
  useEffect(() => {
    
  }, []);

const isUpdateMode = Boolean(ptUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );
 

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={ptUkNo ? "Revise Payment Term" : "Add Payment Term"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
        }}
      >
          <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pt_PY_CO" className="font-bold">
                    Payment Term
                  </label>
                  <InputText
                    id="pt_PY_CO"
                    name="pt_PY_CO"
                    value={pt_PY_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pt_PY_DS" className="font-bold">
                    Description
                  </label>
                  <InputText
                    id="pt_PY_DS"
                    name="pt_PY_DS"
                    value={pt_PY_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pt_NO_DA" className="font-bold">
                    No. of Days
                  </label>
                  <InputText
                    id="pt_NO_DA"
                    name="pt_NO_DA"
                    value={pt_NO_DA}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
            
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pt_CO_NO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="pt_CO_NO"
                    name="pt_CO_NO"
                    value={pt_CO_NO}
                    readOnly={true}
                    
                  />
                </div>
              </div>
         </div>
      </div>  
    </Dialog>
  );
}
