import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";

const CashPopup = ({ visible,onClose,grossAmount,paidTotal,sh_BS_CR,sh_EX_RT,sh_OR_DT,handlebal,Cashsp_TR_CR,setCashsp_TR_CR}) => {
  const [formData, setFormData] = useState('');
  const [CashOpenAmount, setCashOpenAmount] = useState("");
  const [CashPaid_amt, setCashPaid_amt] = useState("");
  const [Difference, setDifference] = useState("");
  const [CashExchangeRate, setCashExchangeRate] = useState("");
  const toast = useRef(null);
  const { borderbox, inboxstyle } = ButtonStyles();

 // Define the CalculateOpamt function
 const calculateOpenAmount = async (gAmount,pTotal,flag,exRate) => {
 if(!pTotal){
  pTotal = 0;
 }
  const inputData ={
    "detail":{
      "exchangeRate": exRate, 
      "amount": pTotal, 
      "grossAmount": gAmount
    } 
  }
  try {
    const response = await axios.post(`${domain}/api/KioskSales/GetCalculateOpenAmount`, inputData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`,
        "x-flag": localStorage.getItem("userType"),
      },
    });
    const dataOPamt = response.data.Result;
    if (dataOPamt) {
      if(flag === 0){
        setCashOpenAmount(dataOPamt.OpenAmount);
      }
      setDifference(dataOPamt.OpenAmount);
      setCashPaid_amt(0.00);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const onchangepaidamt = () => {
    const pAmt = document.getElementById("CashPaid_amt").value;
    const eRate = document.getElementById("CashExchangeRate").value;
    const flag = 1;
    var pTOTAL = document.getElementById("PAID_TOTAL").value;
    if(pTOTAL === undefined || !pTOTAL){
      pTOTAL = 0;
    }
    const gAmt = grossAmount - pTOTAL;
    calculateOpenAmount(gAmt,pAmt,flag,eRate);    
  };
  // dropdowns

  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [sp_TR_CROptions, setsp_TR_CROptions] = useState([]);

  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setCashsp_TR_CR(value);
    const exData={
      "cr_TO_CR": sh_BS_CR, //base curr
      "cr_FR_CR": value, //currency
      "cr_EF_DT": sh_OR_DT
    }
    exchangeRate(exData);
  };
  useEffect(() => {
    fetchDropdownCurr();
  }, []);   
  

  const fetchDropdownCurr = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setsp_TR_CROptions(Options);
  };

  const onchangedata = (event) => {
    const { value, name } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleClose = () => {
    onClose();
  };
  useEffect(() => {
    setFormData();
  }, []);
// exchnage rate
function exchangeRate (exData){
  axios.post(`${domain}/api/ExChangeRate/Rate`, exData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    const dataExRt = resp.data.Result;
    if (dataExRt) {
      document.getElementById("CashExchangeRate").value = dataExRt.cr_CO_MR;
      setCashExchangeRate(dataExRt.cr_CO_MR);
      const PAmt = document.getElementById("CashPaid_amt").value;
      const flag = 0;
      const gAmt = grossAmount - document.getElementById("PAID_TOTAL").value;
      calculateOpenAmount(gAmt,PAmt,flag,dataExRt.cr_CO_MR);

    }
  })
  .catch((error) => {
    console.error("Error saving data:", error);
  });
};
const handleconfirm = () =>{
  handlebal();
  handleClose();
};

// Effect to handle CalculateOpamt on popup open
useEffect(() => {
  if (visible) {
    setCashExchangeRate(sh_EX_RT);
    setCashsp_TR_CR(sh_BS_CR);
    const flag = 0;
    calculateOpenAmount(grossAmount,document.getElementById("PAID_TOTAL").value,flag,sh_EX_RT);
  }
}, [visible,grossAmount,paidTotal,sh_EX_RT]);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "10%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      />
       <Button
        style={{ width: "10%" }}
        icon="pi pi-check"
        variant="outlined"
        onClick={handleconfirm}
        tooltip="Confirm"
      />
    </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={visible}
      onHide={handleClose}
      style={{ width: "40rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Pay By Cash"
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >      
    <Toast ref={toast} />
        <div style={borderbox}>
            <div style={inboxstyle}>
              <div style={{display:"none"}}>
              <InputText style={{height:"3vh"}} disabled
                  id="CashExchangeRate"
                  name="CashExchangeRate"
                  value={CashExchangeRate}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
                <div className="formgrid grid">
                    <div className="field col-lg-12 col-md-6 col-12 pe-md-2 d-flex justify-content-between align-items-center " >
                        <label htmlFor="Cashsp_TR_CR" className="font-bold w-50" style={{ marginRight: '10px' }}>
                        Currency
                        </label>
                        <Dropdown
                          id="Cashsp_TR_CR"
                          name="Cashsp_TR_CR"
                          value={Cashsp_TR_CR}
                          options={sp_TR_CROptions}
                          onChange={(e) => onchangeDropDownFormData1("Cashsp_TR_CR", e.value)}
                          placeholder="Select Currency"
                        />
                    </div>
                    </div>
                    <div className="formgrid grid">
                      <div className="field col-lg-12 col-md-6 col-12 pe-md-2 d-flex justify-content-between align-items-center ">
                          <label htmlFor="CashOpenAmount" className="font-bold w-50" style={{ marginRight: '10px' }}>
                            Open Amount
                          </label>
                          <InputText style={{height:"3vh"}} disabled
                            id="CashOpenAmount"
                            name="CashOpenAmount"
                            value={CashOpenAmount}
                            onChange={(e) => onchangedata(e)}
                          />
                      </div>
                    </div>
                    <div className="formgrid grid">
                      <div className="field col-lg-12 col-md-6 col-12 pe-md-2 d-flex justify-content-between align-items-center ">
                          <label htmlFor="CashPaid_amt" className="font-bold w-50" style={{ marginRight: '10px' }}>
                          Paid
                          </label>
                          <InputText style={{height:"3vh"}}
                            id="CashPaid_amt"
                            name="CashPaid_amt"
                            defaultValue={CashPaid_amt}
                            onChange={(e) => onchangepaidamt(e)}
                          />
                      </div>
                    </div>
                  <div className="formgrid grid">
                    <div className="field col-lg-12 col-md-6 col-12 pe-md-2 d-flex justify-content-between align-items-center ">
                        <label htmlFor="Difference" className="font-bold w-50" style={{ marginRight: '10px' }}>
                        Difference
                        </label>
                        <InputText style={{height:"3vh"}} disabled
                          id="Difference"
                          name="Difference"
                          value={Difference}
                          onChange={(e) => onchangedata(e)}
                        />
                    </div>
                </div>                
            </div>
        </div>
    </Dialog>
  );
};

CashPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  OPData: PropTypes.object,
};

export default CashPopup;
