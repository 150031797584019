import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "bootstrap/dist/css/bootstrap.min.css";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ButtonStyles from "views/common/ButtonStyles";
import '../../common/TabStyles.css';
export default function ShipConfirmationDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  ShipmentData,
  ShipmentTableData,
  setShipmentTableData,
  deleteRow,
  addRow,
  selectedCustomer,
  setselectedCustomer,
  selectedShipto,
  setselectedShipto,
  sh_UK_NO,
  setsh_UK_NO,
  handleConfirm,
  sd_PK_NO,
  sd_CT_PE,
  sd_CT_NO,
}) {
  const { sd_UK_NO, sh_PK_NO, sh_CU_PO, sh_CU_NO, sh_SH_TO,sd_VE_NO } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,multitableheader,
    savestyle,cancelstyle,Dialogstyle} = ButtonStyles();

  // advance search
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
  const [addressTypeCust, setaddressTypeCust] = useState("C");
  const [addressTypeShip, setaddressTypeShip] = useState("C");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const openShiptoPopup = () => {
    setShipToPopupVisible(true);
  };
  const handleSelectCustomer =async (dataAb) => {
    setselectedCustomer(dataAb.ab_AD_NM);
    document.getElementById ("sh_CU_NO").value =  dataAb.abAdNo;
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectShipTo =async (data) => {
    setselectedShipto(data.ab_AD_NM);
    document.getElementById ("sh_SH_TO").value =  data.abAdNo;
    setShipToPopupVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(sh_UK_NO);
  // DIALOG HEADER
  const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{sh_UK_NO ? "Revise Shipment Confirmation" : "Add Shipment Confirmation"}</h5>
    <div style={{width:'60rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleConfirm} 
        tooltip={isUpdateMode ? "Update" : "Save"}
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle}>
                <div className="formgrid grid">
                <div style={{display:"none"}}>
                    <label htmlFor="sh_UK_NO" className="font-bold">
                  uk_no
                    </label>
                    <InputText 
                      id="sh_UK_NO"
                      name="sh_UK_NO"
                      value={sh_UK_NO}
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sh_CU_NO" className="font-bold">
                       Customer <span style={{color:"red"}}>*</span>
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText disabled
                      id="SelectedCustomer"
                      name="SelectedCustomer"
                      onFocus={() => setShowSearchIcon(true)}                      
                      onChange={(e) => onchangedata(e)}
                      value={selectedCustomer}
                    />
                     {showSearchIcon && (
                      <i 
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "7%",
                        cursor: "pointer",                        
                      }}
                      onClick={(e) => openCustomerPopup(e)}
                    ></i>
                     )}
                     <InputText style={{display:"none"}}
                      id="sh_CU_NO"
                      name="sh_CU_NO"
                      value={sh_CU_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                     </div>            
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_SH_TO" className="font-bold">
                      Ship To <span style={{color:"red"}}>*</span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText disabled
                    id="selectedShipto"
                    name="selectedShipto"
                    onFocus={() => setShowSearchIcon(true)}                      
                    onChange={(e) => onchangedata(e)}
                    value={selectedShipto}
                  />
                    {showSearchIcon && (
                    <i 
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "7%",
                      cursor: "pointer",
                      
                    }}
                    onClick={(e) => openShiptoPopup(e)}
                  ></i>
                    )}
                    <InputText style={{display:"none"}}
                    id="sh_SH_TO"
                    name="sh_SH_TO"
                    value={sh_SH_TO}
                    onChange={(e) => onchangedata(e)}
                  />
                    </div>            
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sd_PK_NO" className="font-bold">
                     Pick No
                    </label>
                    <InputText disabled
                      id="sd_PK_NO"
                      name="sd_PK_NO"
                      value={sd_PK_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_CU_PO" className="font-bold">
                    Customer PO
                  </label>
                  <InputText disabled
                    id="sh_CU_PO"
                    name="sh_CU_PO"
                    value={sh_CU_PO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_CT_PE" className="font-bold">
                    Contact Person
                  </label>
                  <InputText 
                    id="sd_CT_PE"
                    name="sd_CT_PE"
                    value={sd_CT_PE}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_CT_NO" className="font-bold">
                    Contact Number
                  </label>
                  <InputText 
                    id="sd_CT_NO"
                    name="sd_CT_NO"
                    value={sd_CT_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div style={{display:'none'}}>
                  <label htmlFor="sh_CU_PO" className="font-bold">
                   Carrier No
                  </label>
                  <InputText
                    id="sh_CU_PO"
                    name="sh_CU_PO"
                    value={sh_CU_PO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
            </div>
        </div>
        <div style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
              {(
              <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
              )}
              {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
              )}
            </div>
            <div  style={multitablestyle}>
              <table className="custom-table" id="ShipmentTable">
                <thead>
                  <tr>
                  <th style={{display:"none"}}>uK </th>
                    <th>Order No </th>
                    <th>Type</th>
                    <th>Company</th>
                    <th>Line No</th>
                    <th>Item Number</th>
                    <th>Description</th>
                    <th>Shipped Quantity </th>
                    {/* <th>Returned Quantity </th> */}
                    <th>UM</th>
                    <th>Location</th>
                    <th>Lot Number</th>
                  </tr>
                </thead>
                <tbody>
                  {ShipmentTableData.map((row, index) => (
                    <tr key={index}>
                      <td style={{display:"none"}} > 
                        <InputText disabled
                        style={{width:"10rem"}}
                          id={`sd_UK_NO_${index}`}
                          name={`sd_UK_NO_${index}`}
                          defaultValue={row.sd_UK_NO}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"7rem"}}
                          id={`sdOrNo_${index}`}
                          name={`sdOrNo_${index}`}
                          value={row.sdOrNo}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"6rem"}}
                          id={`sdOrTy_${index}`}
                          name={`sdOrTy_${index}`}
                          value={row.sdOrTy}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"4rem"}}
                          id={`sd_OR_CO_${index}`}
                          name={`sd_OR_CO_${index}`}
                          value={row.sd_OR_CO}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"5rem"}}
                          id={`sdLnNo_${index}`}
                          name={`sdLnNo_${index}`}
                          value={row.sdLnNo}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`sd_IT_NM_${index}`}
                          name={`sd_IT_NM_${index}`}
                          value={row.sd_IT_NM}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"15rem"}}
                          id={`item_DESC_${index}`}
                          name={`item_DESC_${index}`}
                          value={row.item_DESC}
                        />
                      </td>
                      <td>
                        <InputText 
                         style={{width:"10rem"}}
                          id={`sd_SH_QT_${index}`}
                          name={`sd_SH_QT_${index}`}
                          defaultValue={row.sd_SH_QT}
                        />
                      </td>
                      {/* <td>
                        <InputText 
                         style={{width:"10rem"}}
                          id={`sd_RT_QT_${index}`}
                          name={`sd_RT_QT_${index}`}
                          value={row.sd_RT_QT}
                        />
                      </td> */}
                      <td>
                        <InputText disabled
                         style={{width:"5rem"}}
                          id={`sd_TR_UM_${index}`}
                          name={`sd_TR_UM_${index}`}
                          value={row.sd_TR_UM}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"10rem"}}
                          id={`sd_LO_CN_${index}`}
                          name={`sd_LO_CN_${index}`}
                          value={row.sd_LO_CN}
                        />
                      </td>
                      <td>
                        <InputText disabled
                         style={{width:"7rem"}}
                          id={`sd_LO_NO_${index}`}
                          name={`sd_LO_NO_${index}`}
                          value={row.sd_LO_NO}
                        />
                      </td>
                    
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        </div>
    </div>
     {/* customer */}
     <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleSelectCustomer}
        formData={data}
        addressType={addressTypeCust}
        setFormData={setFormData}
      />
      {/* Shipto */}
      <AddressBookPopup
        visible={ShipToPopupVisible}
        onClose={() => setShipToPopupVisible(false)}
        onDataSelect={handleSelectShipTo}
        formData={data}
        addressType={addressTypeShip}
        setFormData={setFormData}
      />
    </Dialog>
  );
}
