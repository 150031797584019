import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ServiceContractDialog from "./ServiceContractDialog";
import "jspdf-autotable";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  cdUkNo:"",
  ch_UK_NO:"",
  ch_ST_DT: moment(moment().toDate()).format("YYYYMMDD"),
  ch_EN_DT:"",
  ch_CO_DS:"",
  ch_EX_RT:"",
  ch_CU_NO:"",
  ch_ST_AT:"",
  ch_DS_PE:"",
  ch_DS_AM:"",
  ch_SI_NO:"",
  ch_PC_NO:"",
  ch_NO_PE:"",
  ch_CN_FE:"",
  ch_BL_TO:"",
  ch_SN_TO:"",
  cd_CV_TY:"",
  cd_SR_PK:"",
};

const initialFilters = {
  cd_CO_NO: { value: "", operation: "" },
  cd_CO_TY: { value: "", operation: "" },
  cd_CO_CO: { value: "", operation: "" },
  cd_ST_DT: { value: "", operation: "" },
  cd_EN_DT: { value: "", operation: "" },
  ch_CU_NO: { value: "", operation: "" },
  ch_SI_NO: { value: "", operation: "" },
  sp_SE_TY: { value: "", operation: "" },
  sp_CV_TY: { value: "", operation: "" },
  sp_CV_GP: { value: "", operation: "" },
};

const ServiceContractMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,GenerateStyles,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [ch_CO_NO, setch_CO_NO] = useState("");
  const [ch_CO_CO, setch_CO_CO] = useState("");
  const [ch_SC_TY, setch_SC_TY] = useState("");
  const [ch_BS_CR, setch_BS_CR] = useState("");

  const [ch_CO_CT, setch_CO_CT] = useState("");
  const [ch_TR_CR, setch_TR_CR] = useState("");
  const [ch_TX_AP, setch_TX_AP] = useState("");
  const [cd_BL_UM, setcd_BL_UM] = useState("");
  const [cd_FR_CY, setcd_FR_CY] = useState("");
  const [ch_CO_TY, setch_CO_TY] = useState("");
  const [ch_TX_AR, setch_TX_AR] = useState("");
  const [ch_ST_AT, setch_ST_AT] = useState("");
  const [cd_TX_RT, setcd_TX_RT] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [customer, setCustomer] = useState("");
  const [Status, setStatus] = useState("");
  const [FDate, setFDate] = useState("");
  const [EDate, setEDate] = useState("");
  const [so_OP_STOptions, setso_OP_STOptions] = useState([]);
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [selectedcustmer, setSelectedcustmer] = useState("");
  const [ServiceContractData, setServiceContractData] = useState([{}]);

  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
    if (cus !== null) {
      updatedFilters = {
        ...updatedFilters,
        la_AC_OW: {
          value: cus,
          operation: "EQUAL",
        },
      };
    }
    setFilters(updatedFilters);
  };
  const updateFilters1 = (sts) => {
    let updatedFilters = { ...filters };
    if (sts !== null) {
      updatedFilters = {
        ...updatedFilters,
        la_ST_CD: {
          value: sts,
          operation: "LIKE",
        },
      };
    }
    setFilters(updatedFilters);
  };
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const [SelectedCustmr, setSelectedCustmr] = useState("");
  const [SelectedSite, setSelectedSite] = useState("");
  const [SelectedEQNO, setSelectedEQNO] = useState("");
  const [SelectedItem, setSelectedItem] = useState("");
  const [SelectedBill, setSelectedBill] = useState("");
  const [SelectedSento, setSelectedSento] = useState("");

  const [ ch_EX_RT, setch_EX_RT ] = useState("");
  // date chnage
  const onchangestartdate = (Event) => {
    const { value, name } = Event.target;
     setFormData({ ...FormData, [name]: value });
     onChnageExchangeRate(ch_TR_CR,value);
  };
  // cURR
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setch_TR_CR(value);
    onChnageExchangeRate(value, FormData.ch_ST_DT);
  };
  function onChnageExchangeRate(frCr, ReceiveDate) {
    const exData = {
      "cr_TO_CR":  document.getElementById("ch_BS_CR").value, 
      "cr_FR_CR": frCr,
      "cr_EF_DT": ReceiveDate
    }
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("ch_EX_RT").value = dataExRt.cr_CO_MR;
          setch_EX_RT(dataExRt.cr_CO_MR);
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });

  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setServiceContractData([{}]);
    setSelectedCustmr(null);
    setSelectedSite(null);
    setSelectedEQNO(null);
    setSelectedItem(null);
    setSelectedBill(null);
    setSelectedSento(null);
    setch_TR_CR(null);
    setch_TX_AP(null);
    setch_CO_TY(null);
    setch_EX_RT(null);
    setch_TX_AR(null);
    setcd_FR_CY(null);
    setcd_BL_UM(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ServiceContract/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const ServiceContractData = response.data;
          delete ServiceContractData.cdUkNo;
          setServiceContractData(ServiceContractData.serviceContracts);
          setFormData(ServiceContractData);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const onchangeDropDown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setStatus(value);
    updateFilters1(value);
    console.log(value);
  };
  // status
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setch_ST_AT(value);
  };
  // Cost Centre
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setch_CO_CT(value);
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {

    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            setch_CO_CO(`${company}`);
            if(fName === "CH_SC_TY"){
              setch_SC_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "CH_BS_CR"){
              setch_BS_CR(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "CH_ST_AT"){
              onchangeDropDownFormData8("ch_ST_AT",dataDefault.versionIds[i].default_VALUE);
              setch_ST_AT(dataDefault.versionIds[i].default_VALUE);
            }
            if(fName === "CH_CO_CT"){
              onchangeDropDownFormData1("ch_CO_CT",dataDefault.versionIds[i].default_VALUE);
              setch_CO_CT(dataDefault.versionIds[i].default_VALUE);
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ServiceContract/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value, index) => {
    setFormData({ ...FormData, [name]: value });
    const fieldName=(FormData && FormData.cdUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
    ServiceContractData[index][fieldName]=value;
  };
  const OnChangeServiceContractData = (key, value, index) => {
    const updatedData = [...ServiceContractData];
    updatedData[index][key] = value;
    setServiceContractData(updatedData);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ServiceContract/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ServiceContractData = response.data;
        setServiceContractData(ServiceContractData.serviceContracts);
        setFormData(ServiceContractData.serviceContracts[0]);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values() {
    const table = document.getElementById("ServiceContractTable");
    if (!table || !table.tBodies[0]) {
      console.error("Table or table body not found");
      return;
    }
    const totalRowCount = table.tBodies[0].rows.length;
      const serviceContracts = [];
      for (let i = 0; i < totalRowCount; i++) {
        const IN_DT =  document.getElementsByName("cd_IN_DT_" + i)[0]; 
        const ST_DT =  document.getElementsByName("cd_ST_DT_" + i)[0];
        const EN_DT =  document.getElementsByName("cd_EN_DT_" + i)[0];
        const BL_FR =  document.getElementsByName("cd_ST_DT_" + i)[0];
        const BL_TO =  document.getElementsByName("cd_EN_DT_" + i)[0];
        const Details = {
          cd_CO_NO: company,
          cdUkNo: document.getElementById("cdUkNo_" + i).value,
          cd_LN_NO: document.getElementById("cd_LN_NO_" + i).value,
          // cd_CO_TY: document.getElementById("cd_CO_TY_" + i).value,
          cd_EQ_NO: document.getElementById("cd_EQ_NO_" + i).value,
          cd_IT_NO: document.getElementById("cd_IT_NO_" + i).value,
          cd_IN_DT: IN_DT.value?moment(IN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          cd_PR_MD: document.getElementById("cd_PR_MD_" + i).value,
          cd_SR_PK: document.getElementById("cd_SR_PK_" + i).value,
          cd_BL_QT: document.getElementById("cd_BL_QT_" + i).value,
          cd_BL_UM: table.tBodies[0].rows[i].cells[11].children[0].children[1].children[0].value,
          cd_BL_AM: document.getElementById("cd_BL_AM_" + i).value,
          cd_RC_AM: document.getElementById("cd_RC_AM_" + i).value,
          // cd_TX_RT: table.tBodies[0].rows[i].cells[14].children[0].children[1].children[0].value,
          cd_TX_AM: document.getElementById("cd_TX_AM_" + i).value,
          cd_CU_PO: document.getElementById("cd_CU_PO_" + i).value,
          cd_ST_DT: ST_DT.value?moment(ST_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          cd_EN_DT: EN_DT.value?moment(EN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          cd_BL_FR: BL_FR.value?moment(BL_FR.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          cd_BL_TO: BL_TO.value?moment(BL_TO.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          cd_FR_CY: table.tBodies[0].rows[i].cells[21].children[0].children[1].children[0].value,         
        };
        serviceContracts.push(Details);
      }

      const serviceContractHeaders ={
        ch_UK_NO : document.getElementById("ch_UK_NO").value,
        ch_CO_NO : document.getElementById("ch_CO_NO").value,
        ch_SC_TY : document.getElementById("ch_SC_TY").value,
        ch_CO_CO : company,
        ch_CO_TY : FormData.ch_CO_TY,
        ch_CO_CT : ch_CO_CT,
        ch_ST_DT : FormData.ch_ST_DT,
        ch_EN_DT : FormData.ch_EN_DT,
        ch_CO_DS : document.getElementById("ch_CO_DS").value,
        ch_TR_CR : FormData.ch_TR_CR,
        ch_EX_RT : document.getElementById("ch_EX_RT").value,
        ch_BS_CR : document.getElementById("ch_BS_CR").value,
        ch_CU_NO : FormData.ch_CU_NO,
        ch_SI_NO : document.getElementById("ch_SI_NO").value,
        ch_ST_AT : FormData.ch_ST_AT,
        ch_TX_AP : FormData.ch_TX_AP,
        ch_DS_PE : document.getElementById("ch_DS_PE").value,
        ch_DS_AM : document.getElementById("ch_DS_AM").value,
        // 2nd tab
        ch_PC_NO : FormData.ch_PC_NO,
        ch_CN_DT : FormData.ch_CN_DT,
        ch_NO_PE : FormData.ch_NO_PE,
        ch_CN_FE : FormData.ch_CN_FE,
        ch_BL_TO : FormData.ch_BL_TO,
        ch_SN_TO : FormData.ch_SN_TO,
        ch_TX_AR : FormData.ch_TX_AR,
      };
    requestBodyInput = {
      serviceContractHeaders,
      serviceContracts,
    };
  };
  const handleFormSubmit = () => {
    values();
    if (FormData.ch_CO_CO) {
    if (document.getElementById("ch_UK_NO").value) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/ServiceContract/Update`;
      if (confirm) {
        axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/ServiceContract/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
      } else {
        const ST_DT = FormData.ch_ST_DT;
        if(ST_DT){
          const currentYear = ST_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${ch_SC_TY}/${secondHalf}/${company}/${firstHalf}`;  
          const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            setch_CO_NO(resp.dn_DO_NO);
              document.getElementById("ch_CO_NO").value = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/ServiceContract/Add`, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
      }
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "red !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      {/* <Tooltip target=".search-button" position="bottom" mouseTrack>
        <div
          style={{
            backgroundColor: "transparent !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Search
        </div>
      </Tooltip>
      <Button
        style={searchButtonStyles}
        icon="pi pi-search"
        outlined
        className="mr-2 search-button"
      ></Button> */}
      <Tooltip target=".add-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "white !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "white !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
        <Tooltip target=".invoice-button" position="bottom" mouseTrack>
        <div
          style={{
            backgroundColor: "transparent !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Generate
        </div>
      </Tooltip>
      <Button 
        style={GenerateStyles}
        icon="pi pi-file"
        outlined
        className="mr-2 invoice-button"
        // onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  const AdvanceSearch = (
    <div className="formgrid grid" id="servicecol">
      <div className="field col">
        <label htmlFor="customer" className="font-bold">
          Customer
        </label>
        <div style={{ display: "flex" }}>
          <InputText
          style={{display:"none"}}
            id="customer"
            name="customer"
            value={customer}
          />
          <InputText
              id="ab_AD_NM"
              name="ab_AD_NM"
              readOnly
              value={selectedcustmer}
              style={{backgroundColor: "#ffffff" }}
              onChange={(Event) => onchangedata(Event)}
            />
          <i
            className="pi pi-search"
            style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
            }}
            onClick={openCustomerPopup}
          ></i>
        </div>
      </div>
      <div className="field col">
        <label htmlFor="sl_TR_DT" className="font-bold">
          From Date
        </label>
        <Calendar
          id="FDate"
          name="FDate"
          value={moment(FDate, "YYYYMMDD").toDate()}
          onChange={(e) =>
            onchangedata({
              target: {
                name: "FDate",
                value: moment(e.value).format("YYYYMMDD"),
              },
            })
          }
          showIcon
        />
      </div>
      <div className="field col">
        <label htmlFor="sl_TR_DT" className="font-bold">
          To Date
        </label>
        <Calendar
          id="EDate"
          name="EDate"
          value={moment(EDate, "YYYYMMDD").toDate()}
          onChange={(e) =>
            onchangedata({
              target: {
                name: "EDate",
                value: moment(e.value).format("YYYYMMDD"),
              },
            })
          }
          showIcon
        />
      </div>
      <div className="field col" style={{display:"grid"}} >
        <label htmlFor="Status" className="font-bold">
          Status
        </label>
        <Dropdown
          style={{ width: "140px" }}
          id="Status"
          name="Status"
          value={Status}
          options={so_OP_STOptions}
          onChange={(e) => onchangeDropDown("Status", e.value)}
          placeholder="Select Status"
        />
      </div>
    </div>
  );
  const handleClearButton = () => {
    setStatus(null);
    setSelectedcustmer("");
    setCustomer("");
    setFilters(initialFilters);
  };
  const rightClear = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-times"
        className="p-button-help"
        link
        onClick={handleClearButton}
      />
    </React.Fragment>
  );
  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        {/* <div style={{ marginTop: "2%" }}>
          <Toolbar className="p-mb-4" left={AdvanceSearch} right={rightClear} />
        </div> */}
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cd_CO_NO"] ? filters["cd_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cd_CO_NO",
                        e.target.value,
                        filters["cd_CO_NO"]
                          ? filters["cd_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cd_CO_TY"] ? filters["cd_CO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cd_CO_TY",
                        e.target.value,
                        filters["cd_CO_TY"]
                          ? filters["cd_CO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cd_CO_CO"] ? filters["cd_CO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cd_CO_CO",
                        e.target.value,
                        filters["cd_CO_CO"]
                          ? filters["cd_CO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cd_ST_DT"] ? filters["cd_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cd_ST_DT",
                        e.target.value,
                        filters["cd_ST_DT"]
                          ? filters["cd_ST_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cd_EN_DT"] ? filters["cd_EN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cd_EN_DT",
                        e.target.value,
                        filters["cd_EN_DT"]
                          ? filters["cd_EN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ch_CU_NO"] ? filters["ch_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ch_CU_NO",
                        e.target.value,
                        filters["ch_CU_NO"]
                          ? filters["ch_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ch_SI_NO"] ? filters["ch_SI_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ch_SI_NO",
                        e.target.value,
                        filters["ch_SI_NO"]
                          ? filters["ch_SI_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sp_SE_TY"] ? filters["sp_SE_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sp_SE_TY",
                        e.target.value,
                        filters["sp_SE_TY"]
                          ? filters["sp_SE_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sp_CV_TY"] ? filters["sp_CV_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sp_CV_TY",
                        e.target.value,
                        filters["sp_CV_TY"]
                          ? filters["sp_CV_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sp_CV_GP"] ? filters["sp_CV_GP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sp_CV_GP",
                        e.target.value,
                        filters["sp_CV_GP"]
                          ? filters["sp_CV_GP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Contact No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Start Date</th>
                <th style={tableHeaderStyle}>End Date</th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyle}>Site</th>
                <th style={tableHeaderStyle}>Service Type</th>
                <th style={tableHeaderStyle}>Coverage Type</th>
                <th style={tableHeaderStyle}>Coverage Group</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.cdUkNo}
                    style={{  backgroundColor: index % 2 === 0 ? "aliceblue" : "#ffffff",  }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        type="checkbox"
                        value={rowData.cdUkNo}
                        checked={selectedRows.includes(rowData.cdUkNo)}
                        onChange={() => handleRowSelect(rowData.cdUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.cd_CO_NO}</td>
                    <td style={tableCellStyle}>{rowData.cd_CO_TY}</td>
                    <td style={tableCellStyle}>{rowData.cd_CO_CO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.cd_ST_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.cd_EN_DT)}</td>
                    <td style={tableCellStyle}>{rowData.ch_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.ch_SI_NO}</td>
                    <td style={tableCellStyle}>{rowData.sp_SE_TY}</td>
                    <td style={tableCellStyle}>{rowData.sp_CV_TY}</td>
                    <td style={tableCellStyle}>{rowData.sp_CV_GP}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <ServiceContractDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        ServiceContractData={ServiceContractData}
        setServiceContractData={setServiceContractData}
        ch_CO_NO={ch_CO_NO}
        ch_SC_TY={ch_SC_TY}
        ch_CO_CO={ch_CO_CO}
        ch_BS_CR={ch_BS_CR}
        ch_CO_CT={ch_CO_CT}
        setch_CO_CT={setch_CO_CT}
        ch_TR_CR={ch_TR_CR}
        setch_TR_CR={setch_TR_CR}
        ch_TX_AP={ch_TX_AP}
        setch_TX_AP={setch_TX_AP}
        cd_BL_UM={cd_BL_UM}
        setcd_BL_UM={setcd_BL_UM}
        cd_FR_CY={cd_FR_CY}
        setcd_FR_CY={setcd_FR_CY}
        ch_CO_TY={ch_CO_TY}
        setch_CO_TY={setch_CO_TY}
        ch_TX_AR={ch_TX_AR}
        setch_TX_AR={setch_TX_AR}
        ch_ST_AT={ch_ST_AT}
        setch_ST_AT={setch_ST_AT}
        ch_EX_RT={ch_EX_RT}
        setch_EX_RT={setch_EX_RT}
        cd_TX_RT={cd_TX_RT}
        setcd_TX_RT={setcd_TX_RT}
        SelectedCustmr={SelectedCustmr}
        setSelectedCustmr={setSelectedCustmr}
        SelectedSite={SelectedSite}
        setSelectedSite={setSelectedSite}
        SelectedBill={SelectedBill}
        setSelectedBill={setSelectedBill}
        SelectedSento={SelectedSento}
        setSelectedSento={setSelectedSento}
        SelectedEQNO={SelectedEQNO}
        setSelectedEQNO={setSelectedEQNO}
        SelectedItem={SelectedItem}
        setSelectedItem={setSelectedItem}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangestartdate={onchangestartdate}
        onchangeDropDownFormData8={onchangeDropDownFormData8}
        onChangeServiceContractData={OnChangeServiceContractData}
      />
    </div>
  );
};

export default ServiceContractMain;

