import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ResourceCompetencyDetailsDialog from "./ResourceCompetencyDetailsDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  rc_AD_NO: "",
  rc_CO_TY: "",
  rc_CO_NO: "",
  rcUkNo:"",
  rc_TR_RE:"",
  rc_CS_PR:"",
  rc_LO_CN:"",
  rc_DL_TY:"",
  rc_EN_DT:"",
  rc_CO_LV: "",
  rc_ST_DT: "",
  rc_UN_TS: "",
  rc_CO_ST:"",
  rc_GR_DE:"",
  rc_CE_CD:"",
  rc_CO_CD:"",
};

const initialFilters = {
  rc_CO_NO: { value: "", operation: "" },
  rc_AD_NO: { value: "", operation: "" },
  rc_CO_TY: { value: "", operation: "" },
  rc_CO_CD: { value: "", operation: "" },
  rc_CO_LV: { value: "", operation: "" },
  rc_CS_PR: { value: "", operation: "" },
  rc_CE_CD: { value: "", operation: "" },
  rc_ST_DT: { value: "", operation: "" },
  rc_EN_DT: { value: "", operation: "" },
  rc_GR_DE: { value: "", operation: "" },
  rc_LO_CN: { value: "", operation: "" },
  rc_DL_TY: { value: "", operation: "" },
  rc_UN_TS: { value: "", operation: "" },
  rc_CO_ST: { value: "", operation: "" },
  rc_TR_RE: { value: "", operation: "" },
};

const ResourceCompetencyDetailsMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedResource, setSelectedResource] = useState("");
  const [rc_CO_TY, setrc_CO_TY] = useState("");
  const [rc_CO_LV, setrc_CO_LV] = useState("");
  const [rc_CS_PR, setrc_CS_PR] = useState("");
  const [rc_GR_DE, setrc_GR_DE] = useState("");
  const [rc_DL_TY, setrc_DL_TY] = useState("");
  const [rc_TR_RE, setrc_TR_RE] = useState("");
  const [ResourceCompetencytable, setResourceCompetencytable] = useState([{}]);
  const [Selectedprdv, setSelectedprdv] = useState("");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setResourceCompetencytable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setrc_CO_TY(null);
    setrc_CO_LV(null);
    setrc_CS_PR(null);
    setrc_GR_DE(null);
    setrc_DL_TY(null);
    setrc_TR_RE(null);
    setOpen(true);
  };
  const handleClose = () => {
    setResourceCompetencytable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setrc_CO_TY(null);
    setrc_CO_LV(null);
    setrc_CS_PR(null);
    setrc_GR_DE(null);
    setrc_DL_TY(null);
    setrc_TR_RE(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const addRow = () => {
    setResourceCompetencytable([...ResourceCompetencytable, {}]);
  };
  const deleteRow = () => {
    if (ResourceCompetencytable.length > 0) {
      const updatedData = [...ResourceCompetencytable];
      updatedData.pop();
      setResourceCompetencytable(updatedData);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ResourceCompetencyDetails/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const ResopurceCompetencyData = response.data;
          delete ResopurceCompetencyData.rcUkNo;
          for (let i = 0; i < ResopurceCompetencyData.resourceCompetencyDetails.length; i++) {
            delete ResopurceCompetencyData.resourceCompetencyDetails[i].rcUkNo;          }
          setResourceCompetencytable(ResopurceCompetencyData.resourceCompetencyDetails);
          setFormData(ResopurceCompetencyData.resourceCompetencyDetails[0]);
          setFormData(ResopurceCompetencyData);
          setSelectedemEMNO(response.data.ad_AD_NM); //ad_EM_NM
          setrc_CO_TY(response.data.rc_CO_TY);
          setrc_CO_LV(response.data.rc_CO_LV);
          setrc_CS_PR(response.data.rc_CS_PR);
          setrc_GR_DE(response.data.rc_GR_DE);
          setrc_DL_TY(response.data.rc_DL_TY);
          setrc_TR_RE(response.data.rc_TR_RE);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ResourceCompetencyDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrc_CO_TY(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrc_CO_LV(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrc_CS_PR(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrc_GR_DE(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrc_DL_TY(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrc_TR_RE(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ResourceCompetencyDetails/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ResopurceCompetencyData = response.data;
        setResourceCompetencytable(ResopurceCompetencyData.resourceCompetencyDetails);
        setFormData(ResopurceCompetencyData.resourceCompetencyDetails[0]);
        setFormData(ResopurceCompetencyData);
        setSelectedemEMNO(response.data.ad_AD_NM); //ad_EM_NM
        setrc_CO_TY(response.data.rc_CO_TY);
        setrc_CO_LV(response.data.rc_CO_LV);
        setrc_CS_PR(response.data.rc_CS_PR);
        setrc_GR_DE(response.data.rc_GR_DE);
        setrc_DL_TY(response.data.rc_DL_TY);
        setrc_TR_RE(response.data.rc_TR_RE);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values () {   
  const table = document.getElementById("ResourceCompetencytable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const resourceCompetencyDetails = [];
  for (let i = 0; i < totalRowCount1; i++) {    
    const ST_DT = document.getElementsByName("rc_ST_DT_" + i)[0];
    const EN_DT = document.getElementsByName("rc_EN_DT_" + i)[0];
    const Details = {
    rcUkNo : document.getElementById("rcUkNo_" + i).value,
    rc_CE_CD : document.getElementById("rc_CE_CD_" + i).value,
    rc_CS_PR : table.tBodies[0].rows[i].cells[2].children[0].children[1].children[0].value,
    rc_GR_DE : table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
    rc_ST_DT : ST_DT.value?moment(ST_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
    rc_EN_DT : EN_DT.value?moment(EN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
    rc_LO_CN : document.getElementById("rc_LO_CN_" + i).value,
    rc_UN_TS : document.getElementById("rc_UN_TS_" + i).value,
    rc_CO_ST : document.getElementById("rc_CO_ST_" + i).value,
    rc_DL_TY : table.tBodies[0].rows[i].cells[9].children[0].children[1].children[0].value,
    rc_TR_RE : table.tBodies[0].rows[i].cells[10].children[0].children[1].children[0].value,
    }
  resourceCompetencyDetails.push(Details);
  }  
  requestBodyInput = {
    rcUkNo : FormData.rcUkNo,
     rc_AD_NO : document.getElementById("rc_AD_NO").value,
     rc_CO_TY : document.getElementById("rc_CO_TY").value,
     rc_CO_CD : document.getElementById("rc_CO_CD").value,
     rc_CO_LV : document.getElementById("rc_CO_LV").value,
     resourceCompetencyDetails,
  };
  };
  const handleFormSubmit = () => { 
    values(); 
    const ukno = FormData.rcUkNo;  
      if (ukno) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/ResourceCompetencyDetails/Update`;
        if (confirm) {
          axios .put(update, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        axios.post(`${domain}/api/ResourceCompetencyDetails/Add`, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
          // error
          //   const infos = resp.data.infos;
          //  document.write(infos);
          handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_CO_NO"] ? filters["rc_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_CO_NO",
                        e.target.value,
                        filters["rc_CO_NO"]
                          ? filters["rc_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_AD_NO"] ? filters["rc_AD_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_AD_NO",
                        e.target.value,
                        filters["rc_AD_NO"]
                          ? filters["rc_AD_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_CO_TY"] ? filters["rc_CO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_CO_TY",
                        e.target.value,
                        filters["rc_CO_TY"]
                          ? filters["rc_CO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_CO_CD"] ? filters["rc_CO_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_CO_CD",
                        e.target.value,
                        filters["rc_CO_CD"]
                          ? filters["rc_CO_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["rc_CO_LV"] ? filters["rc_CO_LV"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_CO_LV",
                        e.target.value,
                        filters["rc_CO_LV"]
                          ? filters["rc_CO_LV"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_CS_PR"] ? filters["rc_CS_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_CS_PR",
                        e.target.value,
                        filters["rc_CS_PR"]
                          ? filters["rc_CS_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_CE_CD"] ? filters["rc_CE_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_CE_CD",
                        e.target.value,
                        filters["rc_CE_CD"]
                          ? filters["rc_CE_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_ST_DT"] ? filters["rc_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_ST_DT",
                        e.target.value,
                        filters["rc_ST_DT"]
                          ? filters["rc_ST_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_EN_DT"] ? filters["rc_EN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_EN_DT",
                        e.target.value,
                        filters["rc_EN_DT"]
                          ? filters["rc_EN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_GR_DE"] ? filters["rc_GR_DE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_GR_DE",
                        e.target.value,
                        filters["rc_GR_DE"]
                          ? filters["rc_GR_DE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_LO_CN"] ? filters["rc_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_LO_CN",
                        e.target.value,
                        filters["rc_LO_CN"]
                          ? filters["rc_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_DL_TY"] ? filters["rc_DL_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_DL_TY",
                        e.target.value,
                        filters["rc_DL_TY"]
                          ? filters["rc_DL_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_UN_TS"] ? filters["rc_UN_TS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_UN_TS",
                        e.target.value,
                        filters["rc_UN_TS"]
                          ? filters["rc_UN_TS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_CO_ST"] ? filters["rc_CO_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_CO_ST",
                        e.target.value,
                        filters["rc_CO_ST"]
                          ? filters["rc_CO_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rc_TR_RE"] ? filters["rc_TR_RE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rc_TR_RE",
                        e.target.value,
                        filters["rc_TR_RE"]
                          ? filters["rc_TR_RE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Resource</th>
                <th style={tableHeaderStyle}>Competency Type</th>
                <th style={tableHeaderStyle}>Competency Code</th>
                <th style={tableHeaderStyle}>Competency Level</th>
                <th style={tableHeaderStyle}>Provider</th>
                <th style={tableHeaderStyle}>Course Code</th>
                <th style={tableHeaderStyle}>Start Date</th>
                <th style={tableHeaderStyle}>End Date</th>
                <th style={tableHeaderStyle}>Grade</th>
                <th style={tableHeaderStyle}>Location</th>
                <th style={tableHeaderStyle}>Delivery Type</th>
                <th style={tableHeaderStyle}>Units</th>
                <th style={tableHeaderStyle}>Cost</th>
                <th style={tableHeaderStyle}>Training Reason</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.rcUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.rcUkNo}
                        checked={selectedRows.includes(rowData.rcUkNo)}
                        onChange={() => handleRowSelect(rowData.rcUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.rc_CO_NO}</td>
                    <td style={tableCellStyle}>{rowData.rc_AD_NO}</td>
                    <td style={tableCellStyle}>{rowData.rc_CO_TY}</td>
                    <td style={tableCellStyle}>{rowData.rc_CO_CD}</td>
                    <td style={tableCellStyle}>{rowData.rc_CO_LV}</td>
                    <td style={tableCellStyle}>{rowData.provider}</td>
                    <td style={tableCellStyle}>{rowData.rc_CE_CD}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.rc_ST_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.rc_EN_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.grade}</td>
                    <td style={tableCellStyle}>{rowData.rc_LO_CN}</td>
                    <td style={tableCellStyle}>{rowData.del_TYPE}</td>
                    <td style={tableCellStyle}>{rowData.rc_UN_TS}</td>
                    <td style={tableCellStyle}>{rowData.rc_CO_ST}</td>
                    <td style={tableCellStyle}>{rowData.reason}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <ResourceCompetencyDetailsDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        rc_CO_TY={rc_CO_TY}
        rc_CO_LV={rc_CO_LV}
        rc_CS_PR={rc_CS_PR}
        rc_GR_DE={rc_GR_DE}
        rc_DL_TY={rc_DL_TY}
        rc_TR_RE={rc_TR_RE}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        ResourceCompetencytable={ResourceCompetencytable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
        SelectedResource={SelectedResource}
        setSelectedResource={setSelectedResource}
        Selectedprdv={Selectedprdv}
        setSelectedprdv={setSelectedprdv}
      />
    </div>
  );
};

export default ResourceCompetencyDetailsMain;
