
import axios from "axios";

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const ClassCode = () => {

  const fetchClassCodeDS = async (parameter) => {
    const url = `${domain}/api/ClassCode/All`;
    const finalUrl = `${url}/${parameter}`;
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      return response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
      return [];
    }
  };

  const fetchClassCodeCD = async (parameter) => {
    const url = `${domain}/api/ClassCode/All`;
    const finalUrl = `${url}/${parameter}`;
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      return response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
      return [];
    }
  };
  return {fetchClassCodeDS,fetchClassCodeCD};
  };

export default ClassCode;