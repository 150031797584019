import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css'
import { Calendar } from "primereact/calendar";
import axios from "axios";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import WorkRequisitionPopup from "views/admin/Popups/WorkRequisitionPopup";

export default function ApplicationInformationDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  ai_AP_NO,
  ai_AP_BY,
  SelectedemEMNO,
  setSelectedemEMNO,
  ai_AP_CO,
  ai_AI_SO,
  ApplicationtableData,
  setApplicationtableData,
  ai_AP_TY,
  ai_AI_LI,
  ai_NX_AC,
  interviewerDES,
  setinterviewerDES,
}) {
  const { 
    ai_RQ_TY,
    ai_AP_NM,
    ai_CO_CT,  
    aiUkNo,
    ai_ST_CD,
    ai_AP_DT,
    ai_AV_DT,
    ai_JB_PO,
    ai_EX_SL,
    ai_TR_CR,
    ai_JB_TY,
    ai_RQ_NO,
    ai_IN_VW,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();

  const addRow = () => {
    setApplicationtableData([...ApplicationtableData, {}]);
  };
  const deleteRow = () => {
    if (ApplicationtableData.length > 0) {
      const updatedData = [...ApplicationtableData];
      updatedData.pop();
      setApplicationtableData(updatedData);
    }
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };  
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [addressType, setaddressType] = useState("E");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ApprovedBYPopupVisible, setApprovedBYPopupVisible] = useState(false);
  const [RequisitionPopupVisible, setRequisitionPopupVisible] = useState(false);
  const [InterviewerPopupVisible, setInterviewerPopupVisible] = useState(false);

  const openInterviewerPopup = (e) => {
    setInterviewerPopupVisible(true);
    setSelectedRowIndex(e);
    setShowSearchIcon(false);
  };

  const handleSelectInterviewer = (data) => {
    setinterviewerDES(data.ab_AD_NM);
    document.getElementById(`interviewerDES_${SelectedRowIndex}`).value = data.ab_AD_NM;
    document.getElementById(`ai_IN_VW_${SelectedRowIndex}`).value = data.abAdNo;
    setInterviewerPopupVisible(false);
    setShowSearchIcon(false);
  };


  const openApprovedBYPopup = () => {
    setApprovedBYPopupVisible(true);
  };
  //  startsearchpopup
const handleDataSelect = (data) => {
  setSelectedemEMNO(data.ab_AD_NM);
  // onchangeFormData("ai_AP_BY", data.abAdNo);
  document.getElementById("ai_AP_BY").value = data.abAdNo;
  setApprovedBYPopupVisible(false);
  setShowSearchIcon(false);
};

const openRequisitionPopup = () => {
  setRequisitionPopupVisible(true);
};
const handleSelectRequisition = (data) => {
  onchangeFormData("ai_RQ_NO", data.wr_RQ_NO);
  onchangeFormData("ai_RQ_TY", data.wr_RQ_TY);
  onchangeFormData("ai_CO_CT", data.wr_CO_CT);
  onchangeFormData("ai_JB_TY", data.wr_JB_TY);
  onchangeFormData("ai_JB_PO", data.wr_JB_PO);
  // onchangeFormData("ai_RQ_NO", data.wr_RQ_NO);
  setRequisitionPopupVisible(false);
  setShowSearchIcon(false);
};

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [ai_CO_CTOptions, setai_CO_CTOptions] = useState([]);
  const [ai_ST_CDOptions, setai_ST_CDOptions] = useState([]);
  const [ai_JB_POOptions, setai_JB_POOptions] = useState([]);
  const [ai_JB_TYOptions, setai_JB_TYOptions] = useState([]);
  const [ai_TR_CROptions, setai_TR_CROptions] = useState([]);
  const [ai_AI_SOOptions, setai_AI_SOOptions] = useState([]);
  // dropdowns
  const [ ai_AI_LIOptions, setai_AI_LIOptions ] = useState([]);
  const [ ai_NX_ACOptions, setai_NX_ACOptions ] = useState([]);
  const urlcost = `${domain}/api/CostCenter/DropDown`;
  // const parameter1 = "CO/CT";
  const finalUrl1 = `${urlcost}`;

  const url = `${domain}/api/ClassCode/All`;

  const parameter2 = "AP/SC";
  const finalUrl2 = `${url}/${parameter2}`;

  const parameter3 = "JB/PO";
  const finalUrl3 = `${url}/${parameter3}`;

  const parameter4 = "JB/TY";
  const finalUrl4 = `${url}/${parameter4}`;

  const parameter5 = "FO/CR";
  const finalUrl5 = `${url}/${parameter5}`;

  const parameter6 = "AP/LV";
  const finalUrl6 = `${url}/${parameter6}`;

  const parameter7 = "NX/AC";
  const finalUrl7 = `${url}/${parameter7}`;

  const parameter8 = "AP/SO";
  const finalUrl8 = `${url}/${parameter8}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
    fetchDropdownOptions8();

  }, []);
  // 1st fetchdropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setai_CO_CTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 2rd fetchdropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setai_ST_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  //3rd fetchdropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setai_JB_POOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  //4th fetchdropdown
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setai_JB_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 5th fetchdropdown
  const fetchDropdownOptions5 = async () => {
    try {
      const response = await axios.get(finalUrl5, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setai_TR_CROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  //6th fetchdropdown
  const fetchDropdownOptions6 = async () => {
    try {
      const response = await axios.get(finalUrl6, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setai_AI_LIOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 7th fetchdropdown
  const fetchDropdownOptions7 = async () => {
    try {
      const response = await axios.get(finalUrl7, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setai_NX_ACOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 8th fetchdropdown
  const fetchDropdownOptions8 = async () => {
    try {
      const response = await axios.get(finalUrl8, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setai_AI_SOOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  const isUpdateMode = Boolean(aiUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{aiUkNo ? "Revise Application Information" : "Add Application Information"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle} >
          <div className="formgrid grid">
            <div style={{ display: "none" }}>
              <label htmlFor="ai_AP_NO" className="font-bold">
                Applicant
              </label>
              <InputText disabled
                id="ai_AP_NO"
                name="ai_AP_NO"
                value={ai_AP_NO}
              />  
            </div>
            <div style={{ display: "none" }}>
              <label htmlFor="ai_AP_TY" className="font-bold">
                AP_TY
              </label>
              <InputText disabled
                id="ai_AP_TY"
                name="ai_AP_TY"
                value={ai_AP_TY}
              />  
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ai_AP_NM" className="font-bold">
              Applicant Name
              </label>
              <InputText
                id="ai_AP_NM"
                name="ai_AP_NM"
                value={ai_AP_NM}
                onChange={(e) => onchangedata(e)}
              />
            </div> 
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ai_AP_DT" className="font-bold">
                Application Date
              </label>
              <Calendar
                id="ai_AP_DT"
                name="ai_AP_DT"
                value={moment(data.ai_AP_DT, "YYYYMMDD").isValid() ? moment(data.ai_AP_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "ai_AP_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
            </div> 
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ai_AP_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="ai_AP_CO"
                name="ai_AP_CO"
                value={ai_AP_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div> 
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ai_RQ_NO" className="font-bold">
              Requisition No <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="ai_RQ_NO"
                  name="ai_RQ_NO"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={ai_RQ_NO}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openRequisitionPopup}
                ></i>
                )}
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ai_RQ_TY" className="font-bold">
              Type
              </label>
              <InputText disabled
                id="ai_RQ_TY"
                name="ai_RQ_TY"
                value={ai_RQ_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ai_JB_PO" className="font-bold">
                Department
              </label>
              <Dropdown disabled
                id="ai_JB_PO"
                name="ai_JB_PO"
                value={ai_JB_PO}
                options={ai_JB_POOptions}
                onChange={(e) => onchangeDropDownFormData3("ai_JB_PO", e.value)}
                placeholder="Select Department"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ai_JB_TY" className="font-bold">
              Job Type
              </label>
              <Dropdown disabled
                id="ai_JB_TY"
                name="ai_JB_TY"
                value={ai_JB_TY}
                options={ai_JB_TYOptions}
                onChange={(e) => onchangeDropDownFormData4("ai_JB_TY", e.value)}
                placeholder="Select Job Type"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ai_JB_PO" className="font-bold">
                Position
              </label>
              <Dropdown disabled
                id="ai_JB_PO"
                name="ai_JB_PO"
                value={ai_JB_PO}
                options={ai_JB_POOptions}
                onChange={(e) => onchangeDropDownFormData3("ai_JB_PO", e.value)}
                placeholder="Select Position"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ai_CO_CT" className="font-bold">
                Cost Center
              </label>
              <Dropdown 
                id="ai_CO_CT"
                name="ai_CO_CT"
                value={ai_CO_CT}
                options={ai_CO_CTOptions}
                onChange={(e) => onchangeDropDownFormData1("ai_CO_CT", e.value)}
                placeholder="Select Cost Center"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ai_AV_DT" className="font-bold">
              Date Available
              </label>
              <Calendar
                id="ai_AV_DT"
                name="ai_AV_DT"
                value={moment(data.ai_AV_DT, "YYYYMMDD").isValid() ? moment(data.ai_AV_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "ai_AV_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ai_EX_SL" className="font-bold">
              Expected Salary
              </label>
              <InputText
                id="ai_EX_SL"
                name="ai_EX_SL"
                value={ai_EX_SL}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ai_TR_CR" className="font-bold">
              Currency
              </label>
              <Dropdown
                id="ai_TR_CR"
                name="ai_TR_CR"
                value={ai_TR_CR}
                options={ai_TR_CROptions}
                onChange={(e) => onchangeDropDownFormData5("ai_TR_CR", e.value)}
                placeholder="Select Currency"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ai_AI_SO" className="font-bold">
              Source
              </label>
              <Dropdown
                id="ai_AI_SO"
                name="ai_AI_SO"
                value={ai_AI_SO}
                options={ai_AI_SOOptions}
                onChange={(e) => onchangeDropDownFormData6("ai_AI_SO", e.value)}
                placeholder="Select Source"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ai_AP_BY" className="font-bold">
                Approver <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedemEMNO}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openApprovedBYPopup}
                ></i>
                )}
              </div>
              <div  style={{ display: "none" }}>
                <InputText
                  id="ai_AP_BY"
                  name="ai_AP_BY"
                  defaultValue={ai_AP_BY}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ai_ST_CD" className="font-bold">
              Status
              </label>
              <Dropdown
                id="ai_ST_CD"
                name="ai_ST_CD"
                value={ai_ST_CD}
                options={ai_ST_CDOptions}
                onChange={(e) => onchangeDropDownFormData2("ai_ST_CD", e.value)}
                placeholder="Select Status"
              />
            </div>
          </div>
        </div>
      </div>

      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="Applicationtable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>   
          <th> Interview Date </th>
          <th> Level </th>
          <th> Interviewer <span style={{color:"red"}}>*</span> </th>    
          <th> Next Action </th>
          <th> Remarks </th>          
        </tr>
      </thead>
      <tbody>
      {ApplicationtableData.map((row, index) => (
        <tr key={index}>
          <td style={{ display: "none" }} >
            <InputText
              id={`aiUkNo_${index}`}
              name={`aiUkNo_${index}`}
              value={row.aiUkNo}
            />
          </td>
          <td>
          <Calendar
            id={`ai_IN_DT_${index}`}
            name={`ai_IN_DT_${index}`}
            value={ row.ai_IN_DT ? moment(row.ai_IN_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "ai_IN_DT", value: moment(e.value).format("YYYYMMDD") } })}
            dateFormat="dd/mm/yy"
          />
          </td>
          <td>
          <Dropdown
            id={`ai_AI_LI_${index}`}
            name={`ai_AI_LI_${index}`}
            value={data[`ai_AI_LI_${index}`] ? data[`ai_AI_LI_${index}`] : row.ai_AI_LI}
            options={ai_AI_LIOptions}
            onChange={(e) => onchangeDropDownFormData7(`ai_AI_LI_${index}`, e.value)}
            placeholder="Select Level"
          />
          </td>
          <td>
            <div style={{display:"flex"}}>
            <InputText 
              id={`interviewerDES_${index}`}
              name={`interviewerDES_${index}`}
              onFocus={() => setShowSearchIcon(true)}
              // onChange={(Event) => onchangedata(Event)}
              onChange={(e) => onchangedata('interviewerDES',e.value,index)}
              defaultValue={row.interviewerDES ? row.interviewerDES : row[`${'interviewerDES'}_${index}`]}
            />
              {showSearchIcon && (
              <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "7%",
                    cursor: "pointer",
                  }}
                  onClick={(e)=>openInterviewerPopup(index)}
                ></i>
              )}
            </div>
            <InputText 
                style={{display:"none"}}
                id={`ai_IN_VW_${index}`}
                name={`ai_IN_VW_${index}`}
                onChange={(Event) => onchangedata(Event)}
                defaultValue={row.ai_IN_VW ? row.ai_IN_VW : row[`${'ai_IN_VW'}_${index}`]}
              />
           
          </td>
          <td>
          <Dropdown
            id={`ai_NX_AC_${index}`}
            name={`ai_NX_AC_${index}`}
            value={data[`ai_NX_AC_${index}`] ? data[`ai_NX_AC_${index}`] : row.ai_NX_AC}
            options={ai_NX_ACOptions}
            onChange={(e) => onchangeDropDownFormData8(`ai_NX_AC_${index}`, e.value)}
            placeholder="Select Next Action"
          />
          </td>
          <td>
          <InputText 
            style={{width:"90ch"}}
            id={`ai_RE_MK_${index}`}
            name={`ai_RE_MK_${index}`}
            defaultValue={row.ai_RE_MK}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
        </tr>
      ))}
      </tbody>
    </table> 
  </div>
</div>



      <AddressBookPopup
        visible={InterviewerPopupVisible}
        onClose={() => setInterviewerPopupVisible(false)}
        onDataSelect={handleSelectInterviewer}
        formData={data}
        addressType={addressType}
      />
       <AddressBookPopup
        visible={ApprovedBYPopupVisible}
        onClose={() => setApprovedBYPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressType}
      />
       <WorkRequisitionPopup
          visible={RequisitionPopupVisible}
          onClose={() => setRequisitionPopupVisible(false)}
          onDataSelect={handleSelectRequisition}
          formData={data}
        />
    </Dialog>
  );
}

