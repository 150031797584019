import React, { useState, useEffect, useRef } from "react";
import  "components/ListStyle/ListStyle.css";
import Chart from "chart.js/auto";
import { Pie } from 'react-chartjs-2';
import { Dropdown } from "primereact/dropdown";
import { Select } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";

const CompletedProjectDetails = ({
    visible,
    onClose,
    selectedYear,
    selectedMonth,
}) => {
    const {formatDate,tableHeaderStyle, tableCellStyle,inboxstyle} = ButtonStyles();
    const [open, setOpen] = useState(false);
    const [dashboardData, setDashboardData] = useState({
        COMPLETED_Project: [],
    });
    const token = localStorage.getItem("token");
    const company = localStorage.getItem("company");
    const domain = localStorage.getItem("domain");

    const dashboard = `${domain}/api/Dashboard/ConstructionDashboard`;
    const REdashboard = async () => {
        const requestinput = {
            "year":selectedYear ,
            "month": selectedMonth,
        };
        try {
            const response = await axios.post(dashboard, requestinput, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
            });
            const data = response.data;
            setDashboardData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleClose = () => {
        onClose();
        setOpen(false);
    };

    useEffect(() => {
        REdashboard();
        setOpen(visible);
    }, [visible, selectedMonth, selectedYear]);

    return (
        <Dialog id="dialog-style"
            visible={open}
            onHide={handleClose}
            style={{ width: "75rem" }}
        >
            <div style={inboxstyle}>
                <div style={{ display: "flex", height: "30vh", }}>
                    <div style={{ flex: "1 0 48%",  borderRadius: "10px", flexDirection: "column" }}>
                        <div style={{ height: "13%", textAlign: "center", fontSize: "150%", fontWeight: "bold", marginBottom: "1%" }}>Completed Projects</div>
                        <div style={{ height: "80%", overflow: "auto" }}>
                            <table id="Table2" style={{ border: "1px solid", borderRadius: "10px", width: "100%", tableLayout: "fixed", height: "auto" }}>
                                <thead style={{position: "sticky", top: "0", zIndex: "1", backgroundColor: "#e1e1e1"}}>
                                    <tr>
                                        <th style={tableHeaderStyle} >Project No</th>
                                        <th style={tableHeaderStyle} >Description</th>
                                        <th style={tableHeaderStyle} >Project Type</th>
                                        <th style={tableHeaderStyle} >Parent Project</th>
                                        <th style={tableHeaderStyle} >Start Date</th>
                                        <th style={tableHeaderStyle} >Completion Date</th>
                                        <th style={tableHeaderStyle} >Status</th>
                                    </tr>
                                </thead>
                                <tbody style={{ backgroundColor: "white",overflowY: "auto", maxHeight: "calc(80vh - 80px)" }}>
                                {dashboardData.COMPLETED_Project && dashboardData.COMPLETED_Project.map((rowData, index) => (
                                        <tr key={index} className="table-row" 
                                        style={{ backgroundColor:index % 2 === 0 ? "aliceblue" : "#ffffff",}}>
                                            <td style={tableCellStyle} >{rowData.pm_PR_NO}</td>
                                            <td style={tableCellStyle} >{rowData.pm_PR_DS}</td>
                                            <td style={{display:"none"}} >{rowData.pm_PR_TY}</td>
                                            <td style={tableCellStyle} >{rowData.proj_TYPE}</td> 
                                            <td style={tableCellStyle} >{rowData.pm_PA_PR}</td> 
                                            <td style={tableCellStyle} >{formatDate(rowData.pm_PS_DT)}</td>
                                            <td style={tableCellStyle} >{formatDate(rowData.pm_PC_DT)}</td>
                                            <td style={{display:"none"}} >{rowData.pm_PR_ST}</td>
                                            <td style={tableCellStyle} >{rowData.proj_STATUS}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default CompletedProjectDetails;

