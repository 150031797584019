import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import EmployeeBenefitsDialog from "./EmployeeBenefitsDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  name: "",
  eb_YE_AR: "",
  eb_PL_DS : "",
  eb_EM_NO:"",
  eb_EF_TO:"",
  eb_PL_ST:"",
  eb_BE_GP:"",
  eb_PY_DE:"",
  eb_CO_CT:"",
  eb_EM_PA:"",
  eb_CO_NO:"",
  ebUkNo:"",
  eb_PL_OP:"",
  eb_HR_DT:"",
  eb_EF_FR:"",
  eb_DE_PT:"",
};

const initialFilters = {
  eb_CO_NO: { value: "", operation: "" },
  eb_EM_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },
  eb_CO_CT: { value: "", operation: "" },
  eb_BE_GP: { value: "", operation: "" },
  eb_YE_AR: { value: "", operation: "" },
  eb_HR_DT: { value: "", operation: "" },
  eb_PL_DS: { value: "", operation: "" },
  eb_PL_OP: { value: "", operation: "" },
  eb_PL_ST: { value: "", operation: "" },
  eb_EF_FR: { value: "", operation: "" },
  eb_EF_TO: { value: "", operation: "" },
  eb_PY_DE: { value: "", operation: "" },
  eb_EM_PA: { value: "", operation: "" },
};

const EmployeeBenefitsMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,inputStyle1,savestyle,cancelstyle,Dialogstyle
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [eb_CO_CT, seteb_CO_CT] = useState([]);
  const [eb_BE_GP, seteb_BE_GP] = useState([]);
  const [eb_DE_PT, seteb_DE_PT] = useState([]);
  const [eb_PL_DS, seteb_PL_DS] = useState([]);
  const [eb_PL_OP, seteb_PL_OP] = useState([]);
  const [eb_PL_ST, seteb_PL_ST] = useState([]);
  const [eb_EM_PA, seteb_EM_PA] = useState([]);
  const [eb_YE_AR, seteb_YE_AR] = useState([]);
  const [eb_EM_NO, seteb_EM_NO] = useState("");
  const [employeeBenefitstable, setemployeeBenefitstable] = useState([{}]);
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setemployeeBenefitstable([{}]);
    setSelectedemEMNO(null);
    seteb_CO_CT(null);
    seteb_BE_GP(null);
    seteb_PL_DS(null);
    seteb_PL_OP(null);
    seteb_PL_ST(null);
    seteb_EM_PA(null);
    seteb_EM_NO(null);
    seteb_DE_PT(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setemployeeBenefitstable([{}]);
    setSelectedemEMNO(null);
    seteb_CO_CT(null);
    seteb_BE_GP(null);
    seteb_PL_DS(null);
    seteb_PL_OP(null);
    seteb_PL_ST(null);
    seteb_EM_PA(null);
    seteb_DE_PT(null);
    setOpen(false);
  };
  const addRow = () => {
    setemployeeBenefitstable([...employeeBenefitstable, {}]);
  };
  const deleteRow = () => {
    if (employeeBenefitstable.length > 0) {
      const updatedData = [...employeeBenefitstable];
      updatedData.pop();
      setemployeeBenefitstable(updatedData);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/EmployeeBenefits/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const EmployeeBenefitsData = response.data;
          delete EmployeeBenefitsData.ebUkNo;
          for (let i = 0; i < EmployeeBenefitsData.employeeBenefits.length; i++) {
            delete EmployeeBenefitsData.employeeBenefits[i].ebUkNo;
          }
          setemployeeBenefitstable(EmployeeBenefitsData.employeeBenefits);
          setFormData(EmployeeBenefitsData.employeeBenefits[0]);
          setSelectedemEMNO(response.data.emp_NAME);
          seteb_CO_CT(response.data.eb_CO_CT);
          seteb_BE_GP(response.data.eb_BE_GP);
          seteb_PL_DS(response.data.eb_PL_DS);
          seteb_PL_OP(response.data.eb_PL_OP);
          seteb_PL_ST(response.data.eb_PL_ST);
          seteb_EM_PA(response.data.eb_EM_PA);
          seteb_EM_NO(response.data.eb_EM_NO);
          seteb_DE_PT(response.data.eb_DE_PT);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/EmployeeBenefits/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({  ...prevData, [name]: value,  }));
  };
  const onchangeFormData = (name, value) => {
      setFormData((prevData) => ({ ...prevData, [name]: value,}));
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    seteb_CO_CT(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    seteb_BE_GP(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    seteb_PL_DS(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    seteb_PL_OP(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    seteb_PL_ST(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    seteb_YE_AR(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    seteb_DE_PT(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/EmployeeBenefits/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const EmployeeBenefitsData = response.data;
        setemployeeBenefitstable(EmployeeBenefitsData.employeeBenefits);
        setFormData(EmployeeBenefitsData.employeeBenefits[0]);
        // setFormData(EmployeeBenefitsData);
        setSelectedemEMNO(response.data.emp_NAME);
        seteb_CO_CT(response.data.eb_CO_CT);
        seteb_BE_GP(response.data.eb_BE_GP);
        seteb_PL_DS(response.data.eb_PL_DS);
        seteb_PL_OP(response.data.eb_PL_OP);
        seteb_PL_ST(response.data.eb_PL_ST);
        seteb_EM_PA(response.data.eb_EM_PA);
        seteb_EM_NO(response.data.eb_EM_NO);
        seteb_DE_PT(response.data.eb_DE_PT);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBody = {};
  function values () {
    const table = document.getElementById("employeeBenefitstable");
    const totalRowCount1 = table.tBodies[0].rows.length;
    const employeeBenefits = [];
    for (let i = 0; i < totalRowCount1; i++) {
      const FR_DT = document.getElementsByName("eb_EF_FR_" + i)[0];
      const TO_DT = document.getElementsByName("eb_EF_TO_" + i)[0];
      const Benefits = {
      ebUkNo : document.getElementById("ebUkNo_" + i).value,
      eb_PL_DS : table.tBodies[0].rows[i].cells[1].children[0].children[1].children[0].value,
      eb_PL_OP : table.tBodies[0].rows[i].cells[2].children[0].children[1].children[0].value,
      eb_EF_FR : FR_DT.value?moment(FR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
      eb_EF_TO : TO_DT.value?moment(TO_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
      eb_PL_ST : table.tBodies[0].rows[i].cells[3].children[0].children[1].children[0].value,
      eb_PY_DE : document.getElementById("eb_PY_DE_" + i).value,
      eb_EM_PA : document.getElementById("eb_EM_PA_" + i).value,
      };
      employeeBenefits.push(Benefits);
    }
   requestBody = {
    employeeBenefits,
    ebUkNo : FormData.ebUkNo,
    eb_EM_NO : document.getElementById("eb_EM_NO").value,
    eb_CO_CT :  eb_CO_CT,
    eb_DE_PT :  FormData.eb_DE_PT,
    eb_BE_GP :  FormData.eb_BE_GP,
    eb_YE_AR :  FormData.eb_YE_AR,
    eb_HR_DT :  FormData.eb_HR_DT,
    };
  };
  const handleFormSubmit = () => {
    values ();
    if (FormData.ebUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/EmployeeBenefits/Update`;
      if (confirm) {
        axios.put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.eb_CO_NO = company;
      console.log(requestBody);
      axios.post(`${domain}/api/EmployeeBenefits/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div  className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["eb_CO_NO"] ? filters["eb_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eb_CO_NO",
                        e.target.value,
                        filters["eb_CO_NO"]
                          ? filters["eb_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["eb_EM_NO"] ? filters["eb_EM_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eb_EM_NO",
                        e.target.value,
                        filters["eb_EM_NO"]
                          ? filters["eb_EM_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["eb_CO_CT"] ? filters["eb_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eb_CO_CT",
                        e.target.value,
                        filters["eb_CO_CT"]
                          ? filters["eb_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["eb_BE_GP"] ? filters["eb_BE_GP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eb_BE_GP",
                        e.target.value,
                        filters["eb_BE_GP"]
                          ? filters["eb_BE_GP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["eb_YE_AR"] ? filters["eb_YE_AR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eb_YE_AR",
                        e.target.value,
                        filters["eb_YE_AR"]
                          ? filters["eb_YE_AR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["eb_HR_DT"] ? filters["eb_HR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eb_HR_DT",
                        e.target.value,
                        filters["eb_HR_DT"]
                          ? filters["eb_HR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["eb_PL_DS"] ? filters["eb_PL_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eb_PL_DS",
                        e.target.value,
                        filters["eb_PL_DS"]
                          ? filters["eb_PL_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["eb_PL_OP"] ? filters["eb_PL_OP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eb_PL_OP",
                        e.target.value,
                        filters["eb_PL_OP"]
                          ? filters["eb_PL_OP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["eb_PL_ST"] ? filters["eb_PL_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eb_PL_ST",
                        e.target.value,
                        filters["eb_PL_ST"]
                          ? filters["eb_PL_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["eb_EF_FR"] ? filters["eb_EF_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eb_EF_FR",
                        e.target.value,
                        filters["eb_EF_FR"]
                          ? filters["eb_EF_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["eb_EF_TO"] ? filters["eb_EF_TO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eb_EF_TO",
                        e.target.value,
                        filters["eb_EF_TO"]
                          ? filters["eb_EF_TO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["eb_PY_DE"] ? filters["eb_PY_DE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eb_PY_DE",
                        e.target.value,
                        filters["eb_PY_DE"]
                          ? filters["eb_PY_DE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["eb_EM_PA"] ? filters["eb_EM_PA"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "eb_EM_PA",
                        e.target.value,
                        filters["eb_EM_PA"]
                          ? filters["eb_EM_PA"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
               
             
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Employee ID</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Benefit Group</th>
                <th style={tableHeaderStyle}>Year</th>
                <th style={tableHeaderStyle}>Hire Date</th>
                <th style={tableHeaderStyle}>Plan Describtion</th>
                <th style={tableHeaderStyle}>Plan Option</th>
                <th style={tableHeaderStyle}>Plan Status</th>
                <th style={tableHeaderStyle}>Effective From</th>
                <th style={tableHeaderStyle}>Effective To</th>
                <th style={tableHeaderStyle}>Pay Deduction</th>
                <th style={tableHeaderStyle}>Employer Paid</th>
           
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.ebUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.ebUkNo}
                        checked={selectedRows.includes(rowData.ebUkNo)}
                        onChange={() => handleRowSelect(rowData.ebUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.eb_CO_NO}</td>
                    <td style={tableCellStyle}>{rowData.eb_EM_NO}</td>
                    <td style={tableCellStyle}>{rowData.emp_NAME}</td>
                    <td style={tableCellStyle}>{rowData.eb_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.eb_BE_GP}</td>
                    <td style={tableCellStyle}>{rowData.eb_YE_AR}</td>
                    <td style={tableCellStyle}>{rowData.eb_HR_DT}</td>
                    <td style={tableCellStyle}>{rowData.eb_PL_DS}</td>
                    <td style={tableCellStyle}>{rowData.eb_PL_OP}</td>
                    <td style={tableCellStyle}>{rowData.eb_PL_ST}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.eb_EF_FR)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.eb_EF_TO)}</td> 
                    <td style={tableCellStyle}>{rowData.eb_PY_DE}</td>
                    <td style={tableCellStyle}>{rowData.eb_EM_PA}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

   {/* pagination */}
   <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <EmployeeBenefitsDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData} 
        employeeBenefitstable={employeeBenefitstable} 
        addRow={addRow} 
        deleteRow={deleteRow}     
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        eb_CO_CT={eb_CO_CT}
        eb_YE_AR={eb_YE_AR}
        eb_BE_GP={eb_BE_GP}
        eb_DE_PT={eb_DE_PT}
        eb_PL_DS={eb_PL_DS}
        eb_PL_OP={eb_PL_OP}
        eb_PL_ST={eb_PL_ST}
        eb_EM_PA={eb_EM_PA}
        eb_EM_NO={eb_EM_NO}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
  
      />
    </div>
  );
};

export default EmployeeBenefitsMain;
