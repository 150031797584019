import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import ClassCode from "views/common/ClassCode";
export default function WorkCentreDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,  
  wc_CO_CT,
  wc_CP_UM,
  setwc_CO_CT,
  setwc_CP_UM,
}) {
  const {
    wcUkNo,
    wc_WK_CT,
    wc_WC_DS,
    wc_ST_CP,
    wc_MI_CP,
    wc_MX_CP,
    wc_EF_CY,
    wc_UT_ON,
    wc_SH_01,
    wc_SH_02,
    wc_SH_03,
    wc_SH_04,
    wc_HR_01,
    wc_HR_02,
    wc_HR_03,
    wc_HR_04,
    wc_MC_RT,
    wc_LB_RT,
    wc_OH_RT,
    wc_OR_RT,
    wc_TO_RT,

  } = data;
    
  const [activeTab, setActiveTab] = useState(0);

  // DROPDOWNS
  const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
  const [wc_CP_UMOptions, setwc_CP_UMOptions] = useState([]);

// cost centre dropdown
const { fetchCostCenter } = Costcenter();
const [wc_CO_CTOptions, setwc_CO_CTOptions] = useState([]);

const onchangeDropDownFormData1 = (name, value) => {
  setFormData({ ...FormData, [name]: value });
  setwc_CO_CT(value);
};
const fetchCostCentre = async () => {
  const CostOptions = await fetchCostCenter();
  setwc_CO_CTOptions(CostOptions);
};
// class code

const onchangeDropDownFormData2 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setwc_CP_UM(value);
};
  useEffect(() => {
    fetchCostCentre();
    fetchDropdown1();
  }, []);

  const fetchDropdown1 = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setwc_CP_UMOptions(Options);
  };

const isUpdateMode = Boolean(wcUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
    tooltip={isUpdateMode ? "Update" : "Submit"}>
    </Button>
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={wcUkNo ? "Revise Work Centre" : "Add Work Centre"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Work Centre Details">
            {/* tab1 general */}
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
              <div style={{display:"none"}}>
                  <label htmlFor="wcUkNo" className="font-bold">
                    UK_NO
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="wcUkNo"
                      name="wcUkNo"
                      value={wcUkNo}
                    />
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_WK_CT" className="font-bold">
                    Work Centre
                  </label>
                  <InputText
                    id="wc_WK_CT"
                    name="wc_WK_CT"
                    defaultValue={wc_WK_CT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_WC_DS" className="font-bold">
                    Description
                  </label>
                  <InputText
                    id="wc_WC_DS"
                    name="wc_WC_DS"
                    defaultValue={wc_WC_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_CO_CT" className="font-bold">
                    Cost Center
                  </label>
                  <Dropdown
                    name="wc_CO_CT"
                    value={wc_CO_CT}
                    options={wc_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormData1("wc_CO_CT", e.value)}
                    placeholder="Select Cost Centre"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_CP_UM" className="font-bold">
                    UM
                  </label>
                  <Dropdown
                    name="wc_CP_UM"
                    value={wc_CP_UM}
                    options={wc_CP_UMOptions}
                    onChange={(e) => onchangeDropDownFormData2("wc_CP_UM", e.value)}
                    placeholder="Select UM"
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_ST_CP" className="font-bold">
                   Standard Capacity
                  </label>
                  <InputText
                    id="wc_ST_CP"
                    name="wc_ST_CP"
                    value={wc_ST_CP}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_MI_CP" className="font-bold">
                  Minimum Capacity
                  </label>
                  <InputText
                    id="wc_MI_CP"
                    name="wc_MI_CP"
                    value={wc_MI_CP}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_MX_CP" className="font-bold">
                    Maximum Capacity
                  </label>
                  <InputText
                    id="wc_MX_CP"
                    name="wc_MX_CP"
                    value={wc_MX_CP}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_EF_CY" className="font-bold">
                    Efficiency
                  </label>
                  <InputText
                    id="wc_EF_CY"
                    name="wc_EF_CY"
                    value={wc_EF_CY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_UT_ON" className="font-bold">
                   Utilization
                  </label>
                  <InputText
                    id="wc_UT_ON"
                    name="wc_UT_ON"
                    value={wc_UT_ON}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_SH_01" className="font-bold">
                   Shift 1
                  </label>
                  <InputText
                    id="wc_SH_01"
                    name="wc_SH_01"
                    value={wc_SH_01}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_SH_02" className="font-bold">
                   Shift 2
                  </label>
                  <InputText
                    id="wc_SH_02"
                    name="wc_SH_02"
                    value={wc_SH_02}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_SH_03" className="font-bold">
                   Shift 3
                  </label>
                  <InputText
                    id="wc_SH_03"
                    name="wc_SH_03"
                    value={wc_SH_03}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_HR_01" className="font-bold">
                  Hour 1
                  </label>
                  <InputText
                    id="wc_HR_01"
                    name="wc_HR_01"
                    value={wc_HR_01}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_HR_02" className="font-bold">
                  Hour 2
                  </label>
                  <InputText
                    id="wc_HR_02"
                    name="wc_HR_02"
                    value={wc_HR_02}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_HR_03" className="font-bold">
                  Hour 3
                  </label>
                  <InputText
                    id="wc_HR_03"
                    name="wc_HR_03"
                    value={wc_HR_03}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_SH_04" className="font-bold">
                   Shift 4
                  </label>
                  <InputText
                    id="wc_SH_04"
                    name="wc_SH_04"
                    value={wc_SH_04}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
               
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_HR_04" className="font-bold">
                  Hour 4
                  </label>
                  <InputText
                    id="wc_HR_04"
                    name="wc_HR_04"
                    value={wc_HR_04}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          {/* tab2 */}
          <TabPanel header="Work Centre Rates">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_MC_RT" className="font-bold">
                Machine 
                </label>
                  <InputText
                    id="wc_MC_RT"
                    name="wc_MC_RT"
                    value={wc_MC_RT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_LB_RT" className="font-bold">
                 Labour
                  </label>
                    <InputText
                      id="wc_LB_RT"
                      name="wc_LB_RT"
                      value={wc_LB_RT}
                      onChange={(e) => onchangedata(e)}
                    />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_OH_RT" className="font-bold">
                   Overhead
                  </label>
                  <InputText
                    id="wc_OH_RT"
                    name="wc_OH_RT"
                    value={wc_OH_RT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_OR_RT" className="font-bold">
                 Others
                  </label>
                    <InputText
                      id="wc_OR_RT"
                      name="wc_OR_RT"
                      value={wc_OR_RT}
                      onChange={(e) => onchangedata(e)}
                    />
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_TO_RT" className="font-bold">
                  Total
                  </label>
                  <InputText
                    id="wc_TO_RT"
                    name="wc_TO_RT"
                    value={wc_TO_RT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                </div>
              </div>
          </TabPanel>
      </TabView>
      </div> 
    </Dialog>
  );
}
