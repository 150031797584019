import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
// import AccountProjectPopup from "../Popups/AccountProjectPopup";
import AccountJobPopup from "../Popups/AccountJobPopup";
// import CostCentrePopup from "../Popups/CostCentrePopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ProjectMasterPopup from "views/admin/Popups/ProjectMasterPopup";
import { Calendar } from "primereact/calendar";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function JobMasterDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  jm_PR_CO,
  jm_PR_TY,
  setjm_PR_TY,
  jm_PR_ST,
  setjm_PR_ST,
  jm_CO_RY,
  setjm_CO_RY,
  jm_CC_01,
  setjm_CC_01,
  jm_CC_02,
  setjm_CC_02,
  jm_CC_03,
  setjm_CC_03,
  jm_CC_04,
  setjm_CC_04,
  jm_CC_05,
  setjm_CC_05,
  jm_CC_06,
  setjm_CC_06,
  jm_CC_07,
  setjm_CC_07,
  jm_CC_08,
  setjm_CC_08,
  jm_CC_09,
  setjm_CC_09,
  jm_CC_10,
  setjm_CC_10,
  jm_AC_CD,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNO1,
  setSelectedemEMNO1,
  SelectedAccount,
  setSelectedAccount,
  SelectedAccountNo,
  setSelectedAccountNo,
  SelectedProjectMaster,
  setSelectedProjectMaster,
  ProjectStatusDropDown,
  jm_JB_TY
}) {
  const {
    jmUkNo,
    jm_PS_DT,
    jm_PC_DT,
    jm_AS_DT,
    jm_AC_DT,
    jm_OW_AD,
    jm_SI_AD,
    jm_PR_DS,
    jm_PR_NO,
    jm_PA_PR,
  } = data;
  const {borderbox,TabBoxStyle,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [Addresstype, setAddresstype] = useState("F");
  const [AddresstypeS, setAddresstypeS] = useState("F");
  // DROPDOWNS
  const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
  const [jm_PR_TYOptions, setjm_PR_TYOptions] = useState([]);
  const [jm_PR_STOptions, setjm_PR_STOptions] = useState([]);
  const [jm_CO_RYOptions, setjm_CO_RYOptions] = useState([]);
  const [jm_CC_01Options, setjm_CC_01Options] = useState([]);
  const [jm_CC_02Options, setjm_CC_02Options] = useState([]);
  const [jm_CC_03Options, setjm_CC_03Options] = useState([]);
  const [jm_CC_04Options, setjm_CC_04Options] = useState([]);
  const [jm_CC_05Options, setjm_CC_05Options] = useState([]);
  const [jm_CC_06Options, setjm_CC_06Options] = useState([]);
  const [jm_CC_07Options, setjm_CC_07Options] = useState([]);
  const [jm_CC_08Options, setjm_CC_08Options] = useState([]);
  const [jm_CC_09Options, setjm_CC_09Options] = useState([]);
  const [jm_CC_10Options, setjm_CC_10Options] = useState([]);

const ProjectTypeDropDown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setjm_PR_TY(value);
};
const CountryDropDown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setjm_CO_RY(value);
};
const ClassCodeDropDown1 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setjm_CC_01(value);
};
const ClassCodeDropDown2 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setjm_CC_02(value);
};
const ClassCodeDropDown3 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setjm_CC_03(value);
};
const ClassCodeDropDown4 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setjm_CC_04(value);
};
const ClassCodeDropDown5 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setjm_CC_05(value);
};
const ClassCodeDropDown6 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setjm_CC_06(value);
};
const ClassCodeDropDown7 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setjm_CC_07(value);
};
const ClassCodeDropDown8 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setjm_CC_08(value);
};
const ClassCodeDropDown9 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setjm_CC_09(value);
};
const ClassCodeDropDown10 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setjm_CC_10(value);
};
  useEffect(() => {
    ProjectType();
    ProjectStatus();
    Country();
    ClassCode1();
    ClassCode2();
    ClassCode3();
    ClassCode4();
    ClassCode5();
    ClassCode6();
    ClassCode7();
    ClassCode8();
    ClassCode9();
    ClassCode10();
  }, []);

  const ProjectType = async () => {
    const Options = await fetchClassCodeDS("PR/TY");
    setjm_PR_TYOptions(Options);
  };
  const ProjectStatus = async () => {
    const Options = await fetchClassCodeDS("PR/ST");
    setjm_PR_STOptions(Options);
  };
  const Country = async () => {
    const Options = await fetchClassCodeDS("FO/CY");
    setjm_CO_RYOptions(Options);
  };
 const ClassCode1 = async () => {
    const Options = await fetchClassCodeDS("PR/01");
    setjm_CC_01Options(Options);
  };
  const ClassCode2 = async () => {
    const Options = await fetchClassCodeDS("PR/02");
    setjm_CC_02Options(Options);
  };
  const ClassCode3 = async () => {
    const Options = await fetchClassCodeDS("PR/03");
    setjm_CC_03Options(Options);
  };
  const ClassCode4 = async () => {
    const Options = await fetchClassCodeDS("PR/04");
    setjm_CC_04Options(Options);
  };
  const ClassCode5 = async () => {
    const Options = await fetchClassCodeDS("PR/05");
    setjm_CC_05Options(Options);
  };
  const ClassCode6 = async () => {
    const Options = await fetchClassCodeDS("PR/06");
    setjm_CC_06Options(Options);
  };
  const ClassCode7 = async () => {
    const Options = await fetchClassCodeDS("PR/07");
    setjm_CC_07Options(Options);
  };
  const ClassCode8 = async () => {
    const Options = await fetchClassCodeDS("PR/08");
    setjm_CC_08Options(Options);
  };
  const ClassCode9 = async () => {
    const Options = await fetchClassCodeDS("PR/09");
    setjm_CC_09Options(Options);
  };
  const ClassCode10 = async () => {
    const Options = await fetchClassCodeDS("PR/10");
    setjm_CC_10Options(Options);
  };
  // Advance Search
  const [OwnerPopupVisible, setOwnerPopupVisible] = useState(false);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const openCustomerPopup = (e) => {
    setOwnerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleOwnerAddress = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("jm_OW_AD", data.abAdNo);
    setOwnerPopupVisible(false);
  };
  const [SiteAddressPopupVisible, setSiteAddressPopupVisible] = useState(false);
  const openEmployeePopup = () => {
    setSiteAddressPopupVisible(true);
  }
  const handleSiteAddress = async (data) => {
    setSelectedemEMNO1(data.ab_AD_NM);
    onchangeFormData("jm_SI_AD", data.abAdNo);
    setSiteAddressPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const openAccountPopup = () => {
    setAccountPopupVisible(true);
  };
  const handleAccount = async (data) => {
    setSelectedAccount(data.ja_CD_DS);
    setSelectedAccountNo(data.jaAcCd);
    onchangeFormData("jm_AC_CD", data.jaAcCd);
    setAccountPopupVisible(false);
    setShowSearchIcon(false);
  };
  // Advance Search
  const [ProjectMasterPopupVisible, setProjectMasterPopupVisible] = useState(false);
  const openProjectMasterPopup = (e) => {
    setProjectMasterPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleProjectMaster = async (data) => {
    setSelectedProjectMaster(data.pm_PR_DS);
    onchangeFormData("jm_PA_PR", data.pm_PR_NO);
    setProjectMasterPopupVisible(false);
  };
  const isUpdateMode = Boolean(jmUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{jmUkNo ? "Revise Job Master" : "Add Job Master"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
      {activeTab === 0 && (
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}
        />
      )}
      {activeTab === 0 && (
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      )}
      </div>
    </div>
  );
  const [showSearchIcon, setShowSearchIcon] = useState("");
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div  style={TabBoxStyle}  >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Details">
            {/* tab1 general */}
            <div style={inboxstyle} >
              <div className="formgrid grid">
              {/* <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_PR_NO" className="font-bold">
                Job No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText 
                      id="jm_PR_NO"
                      name="jm_PR_NO"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedCostCentre}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItemPopup}
                    ></i>
                    )}
                  </div>
                </div> */}
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="jm_PR_NO" className="font-bold">
                Job No
                </label>
                <InputText disabled
                  id="jm_PR_NO"
                  name="jm_PR_NO"
                  value={jm_PR_NO}
                  onChange={(e) => onchangedata(e)}
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_JB_TY" className="font-bold">
                    Type
                  </label>
                  <InputText disabled
                    id="jm_JB_TY"
                    name="jm_JB_TY"
                    value={jm_JB_TY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_PR_CO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="jm_PR_CO"
                    name="jm_PR_CO"
                    value={jm_PR_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_PR_DS" className="font-bold">
                  Description
                  </label>
                  <InputText 
                    id="jm_PR_DS"
                    name="jm_PR_DS"
                    value={jm_PR_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_PR_TY" className="font-bold">
                   Project Type
                  </label>
                  <Dropdown
                    name="jm_PR_TY"
                    value={jm_PR_TY}
                    options={jm_PR_TYOptions}
                    onChange={(e) => ProjectTypeDropDown("jm_PR_TY", e.value)}
                    placeholder="Select  Project Type"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_PR_ST" className="font-bold">
                   Project Status
                  </label>
                  <Dropdown
                    name="jm_PR_ST"
                    value={jm_PR_ST}
                    options={jm_PR_STOptions}
                    onChange={(e) => ProjectStatusDropDown("jm_PR_ST", e.value)}
                    placeholder="Select Project Status"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_CO_RY" className="font-bold">
                    Country
                  </label>
                  <Dropdown
                    name="jm_CO_RY"
                    value={jm_CO_RY}
                    options={jm_CO_RYOptions}
                    onChange={(e) => CountryDropDown("jm_CO_RY", e.value)}
                    placeholder="Select Country"
                  />
                </div>           
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_AC_CD" className="font-bold">
                      Account Code Structure<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="accntDES"
                      name="accntDES"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedAccount}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openAccountPopup}
                    ></i>
                    )}
                  </div>
                  <div style={{ display: "none" }}>
                    <InputText
                      id="jaAcCd"
                      name="jaAcCd"
                      value={SelectedAccountNo}
                    />
                  </div>
              </div> 
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_SI_AD" className="font-bold">
                      Site Address<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="sitedDES"
                      name="sitedDES"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedemEMNO1}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openEmployeePopup}
                    ></i>
                    )}
                  </div>
                  <div >
                    <InputText style={{ display: "none" }}
                      id="jm_SI_AD"
                      name="jm_SI_AD"
                      value={jm_SI_AD}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_OW_AD" className="font-bold">
                    Owner Address  <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ownerDES"
                      name="ownerDES"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedemEMNO}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openCustomerPopup}
                    ></i>
                    )}
                  </div>
                <InputText style={{ display: "none" }}
                  id="jm_OW_AD"
                  name="jm_OW_AD"
                  value={jm_OW_AD}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_PA_PR" className="font-bold">
                    Parent Project <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="proj_NAME"
                      name="proj_NAME"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedProjectMaster}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openProjectMasterPopup}
                    ></i>
                    )}
                    </div>
                    <div style={{ display: "none" }}>
                      <InputText
                        id="jm_PA_PR"
                        name="jm_PA_PR"
                        value={jm_PA_PR}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </div>
                  </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_PS_DT" className="font-bold">
                    Planned Start Date
                  </label>
                  <Calendar
                    id="jm_PS_DT"
                    name="jm_PS_DT"
                    value={moment(jm_PS_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "jm_PS_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_PC_DT" className="font-bold">
                  Planned Compl. Date
                  </label>
                  <Calendar
                    id="jm_PC_DT"
                    name="jm_PC_DT"
                    value={moment(jm_PC_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "jm_PC_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_AS_DT" className="font-bold">
                   Actual Start Date
                  </label>
                  <Calendar
                    id="jm_AS_DT"
                    name="jm_AS_DT"
                    value={moment(jm_AS_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "jm_AS_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_AC_DT" className="font-bold">
                   Actual Compl. Date
                  </label>
                  <Calendar
                    id="jm_AC_DT"
                    name="jm_AC_DT"
                    value={moment(jm_AC_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "jm_AC_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                </div>
                </div>
          </TabPanel>
          {/* tab2 */}
          <TabPanel header="Classification Codes">
            <div style={inboxstyle} >
             <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_CC_01" className="font-bold">
                  Class Code 1
                  </label>
                  <Dropdown
                    name="jm_CC_01"
                    value={jm_CC_01}
                    options={jm_CC_01Options}
                    onChange={(e) =>ClassCodeDropDown1("jm_CC_01", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_CC_02" className="font-bold">
                  Class Code 2
                  </label>
                  <Dropdown
                    name="jm_CC_02"
                    value={jm_CC_02}
                    options={jm_CC_02Options}
                    onChange={(e) =>ClassCodeDropDown2("jm_CC_02", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_CC_03" className="font-bold">
                  Class Code 3
                  </label>
                  <Dropdown
                    name="jm_CC_03"
                    value={jm_CC_03}
                    options={jm_CC_03Options}
                    onChange={(e) => ClassCodeDropDown3("jm_CC_03", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_CC_04" className="font-bold">
                  Class Code 4
                  </label>
                  <Dropdown
                    name="jm_CC_04"
                    value={jm_CC_04}
                    options={jm_CC_04Options}
                    onChange={(e) => ClassCodeDropDown4("jm_CC_04", e.value)}
                    placeholder="Select Class Code "
                  />
                  
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_CC_05" className="font-bold">
                  Class Code 5
                  </label>
                  <Dropdown
                    name="jm_CC_05"
                    value={jm_CC_05}
                    options={jm_CC_05Options}
                    onChange={(e) => ClassCodeDropDown5("jm_CC_05", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_CC_06" className="font-bold">
                  Class Code 6
                  </label>
                  <Dropdown
                    name="jm_CC_06"
                    value={jm_CC_06}
                    options={jm_CC_06Options}
                    onChange={(e) => ClassCodeDropDown6("jm_CC_06", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_CC_07" className="font-bold">
                  Class Code 7
                  </label>
                  <Dropdown
                    name="jm_CC_07"
                    value={jm_CC_07}
                    options={jm_CC_07Options}
                    onChange={(e) => ClassCodeDropDown7("jm_CC_07", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_CC_08" className="font-bold">
                  Class Code 8
                  </label>
                  <Dropdown
                    name="jm_CC_08"
                    value={jm_CC_08}
                    options={jm_CC_08Options}
                    onChange={(e) => ClassCodeDropDown8("jm_CC_08", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_CC_09" className="font-bold">
                  Class Code 9
                  </label>
                  <Dropdown
                    name="jm_CC_09"
                    value={jm_CC_09}
                    options={jm_CC_09Options}
                    onChange={(e) => ClassCodeDropDown9("jm_CC_09", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jm_CC_10" className="font-bold">
                  Class Code 10
                  </label>
                  <Dropdown
                    name="jm_CC_10"
                    value={jm_CC_10}
                    options={jm_CC_10Options}
                    onChange={(e) => ClassCodeDropDown10("jm_CC_10", e.value)}
                    placeholder="Select Class Code "
                  />
                </div>
              </div>
              </div>
          </TabPanel>
      </TabView>
      </div>    
      <AddressBookPopup
        visible={OwnerPopupVisible}
        onClose={() => setOwnerPopupVisible(false)}
        onDataSelect={handleOwnerAddress}
        formData={data}
        addressType={Addresstype}
      />
        <AddressBookPopup
        visible={SiteAddressPopupVisible}
        onClose={() => setSiteAddressPopupVisible(false)}
        onDataSelect={handleSiteAddress}
        formData={data}
        addressType={AddresstypeS}
      />
        <ProjectMasterPopup
        visible={ProjectMasterPopupVisible}
        onClose={() => setProjectMasterPopupVisible(false)}
        onDataSelect={handleProjectMaster}
        formData={data}
      />
        <AccountJobPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleAccount}
        formData={data}
      />
    </Dialog>
  );
}
