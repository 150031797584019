import React, { useState, useEffect, useRef } from "react";
import "components/ListStyle/ListStyle.css";
import Chart from "chart.js/auto";
import { Pie } from 'react-chartjs-2';
import { Dropdown } from "primereact/dropdown";
import { Select } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";

const  LeaveStatusDetails = ({ 
    visible,
    onClose,
    open, 
    setOpen,
    selectedYear,
    selectedMonth,
    }) => {
const {formatDate} = ButtonStyles();

// front
const [dashboardData, setDashboardData] = useState({
    employeeLeaveDetailList: [],
});
const token = localStorage.getItem("token");
const company = localStorage.getItem("company");
const domain = localStorage.getItem("domain");

const dashboard = `${domain}/api/Dashboard/HCMDashboard`;
const HCMDashboard = async() =>{
  const requestinput ={
          "year":selectedYear,
          "month":selectedMonth
        }
  try {
    const response = await axios.post(dashboard, requestinput, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const data = response.data;
    setDashboardData(data);
    } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const handleClose = () => {
    onClose();
    setOpen(false);
  };
   useEffect(() => {
        HCMDashboard();
        setOpen(visible);
    }, [visible, selectedMonth, selectedYear]);

return (
<Dialog id="dialog-style"
  visible={open}
  onHide={handleClose}
  style={{ width: "75rem", height: "53rem"}}
  header={'Leave Status Details '}
  headerStyle={{textAlign:"center"}}
>
    {/* Table1&2 */}
    <div style={{ display: "flex",height:'100%',overflowY:'auto'  }}>
      <table id="Table2" style={{ border: "1px solid", borderRadius: "10px", width: "100%", tableLayout: "fixed", height: "auto" }}>
        <thead style={{position: "sticky", top: "0", zIndex: "1", backgroundColor: "#e1e1e1"}}>
                  <tr>
                      <th className="table-header" >Employee No</th>
                      <th className="table-header" >Name</th>
                      <th className="table-header" >Department</th>
                      <th className="table-header" >Leave Type</th>
                      <th className="table-header" >From Date</th>
                      <th className="table-header" >To Date</th>
                      <th className="table-header" >No of Days</th>
                  </tr>
        </thead>
        <tbody style={{ backgroundColor: "white",overflowY: "auto", maxHeight: "calc(80vh - 80px)" }}>
        {dashboardData.employeeLeaveDetailList && dashboardData.employeeLeaveDetailList.map((rowData, index) => (
                <tr key={index} className="table-row" 
                style={{ backgroundColor:index % 2 === 0 ? "aliceblue" : "#ffffff"}} >
                    <td className="table-cell" >{rowData.ld_EM_NO}</td>
                    <td className="table-cell" >{rowData.name}</td>
                    <td className="table-cell" >{rowData.department}</td>
                    <td className="table-cell" >{rowData.leaveType}</td>
                    <td className="table-cell" >{formatDate(rowData.ld_FR_DT)}</td>
                    <td className="table-cell" >{formatDate(rowData.ld_TO_DT)}</td>
                    <td className="table-cell" >{rowData.ld_NO_DA}</td>
                </tr>
            ))}
        </tbody>
      </table>
    </div>
</Dialog>
  );
};

export default LeaveStatusDetails;
