import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import SupplierPopup from "../Popups/SupplierPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function NotificationSetupDialog({
  open,
  handleClose,
  data,
  setFormData,
 
  onchangeFormData,
  handleFormSubmit,
  NotificationSetupTableData,
  setNotificationSetupTableData,

  dp_CO_NO,
  SelectedITNM,
  setSelectedITNM,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  dp_CO_NM,
  setdp_CO_NM,
  dp_PO_IT,
  setdp_PO_IT,
  dp_DO_TY,
  setdp_DO_TY,
  ns_EV_NT,
  setns_EV_NT,
  dp_GL_CD,
  setdp_GL_CD,
  dp_TY_PE,
  setdp_TY_PE,
  SelectedemEMNO,
  setSelectedemEMNO,
}) {
  const {
    nsUkNo,
    ph_PM_NO,
    ep_EF_DT,
    os_SQ_DS,
  } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, multitableheader } = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter, fetchCostCenterP } = Costcenter();

  const [ns_EV_NTOptions, setns_EV_NTOptions] = useState([]);

  const onchangedata = (e) => {
    const { name, value } = e.target;

    if (name === "et_IN_DT" || name === "et_OU_DT") {
      const formattedDate = moment(value, "DD/MM/YYYY", true).format( "YYYYMMDD" );
      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    }else {
      onchangeFormData(name, value);
    }
  };
  
  // Event
  const onchangeDropDownFormDataEvent = (name, value) => {
    const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setns_EV_NT(value);
  };
 
  useEffect(() => {
    Event();
  }, []);



  const Event = async () => {
    const Options = await fetchClassCodeDS("NO/VE");
    setns_EV_NTOptions(Options);
  };


  const addRow = () => {
    setNotificationSetupTableData([...NotificationSetupTableData, {}]);
  };

  const deleteRow = () => {
    if (NotificationSetupTableData.length > 0) {
      const updatedData = [...NotificationSetupTableData];
      updatedData.pop();
      setNotificationSetupTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(nsUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
        tooltip={isUpdateMode ? "Update" : "Submit"}>
      </Button>
    </React.Fragment>
  );

  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);
  const [addressType, setaddressType] = useState("E");
  const openEmployeePopup = (e) => {
    setEmployeePopupVisible(true);
    setSelectedRowIndex(e);
  };

  // Handle data selection in employee popup
  const handleDataSelect = (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("et_EM_NO", data.abAdNo, SelectedRowIndex);
    onchangeFormData("name", data.ab_AD_NM, SelectedRowIndex);
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  return (
    <Dialog 
      visible={open}
      onHide={handleClose}
      style={{ width: "65rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={nsUkNo ? "Revise Notification Setup" : "Add Notification Setup"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      
      <div style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
            <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
          )}
          {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div style={multitablestyle}>
          <table className="custom-table" id="NotificationSetuptable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Event</th>
                <th>Address No</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {NotificationSetupTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`nsUkNo_${index}`}
                      name={`nsUkNo_${index}`}
                      value={row.nsUkNo}
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`ns_EV_NT_${index}`}
                      name={`ns_EV_NT_${index}`}
                      value={data[`ns_EV_NT_${index}`] ? data[`ns_EV_NT_${index}`] : row.ns_EV_NT}
                      options={ns_EV_NTOptions}
                      onChange={(e) => onchangeDropDownFormDataEvent(`ns_EV_NT_${index}`, e.value)}
                      placeholder="Event"
                    />
                  </td>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`ns_AD_NO_${index}`}
                      name={`ns_AD_NO_${index}`}
                      value={row.ns_AD_NO ? row.ns_AD_NO : row[`ns_AD_NO_${index}`]}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <InputText
                        id={`name_${index}`}
                        name={`name_${index}`}
                        onChange={(e) => onchangedata(e)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={row.name ? row.name : row[`name_${index}`] }
                      />
                      {showSearchIcon && (
                        <i
                          className="pi pi-search"
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "119%",
                            marginTop: "7%",
                            cursor: "pointer",
                          }}
                          onClick={(e) => openEmployeePopup(index)}
                        ></i>
                      )}
                    </div>
                  </td>
                  <td > 
                  <InputText
                   // style={{width:"90px"}}
                    id={`ns_MS_AG_${index}`}
                    name={`ns_MS_AG_${index}`}
                    defaultValue={row.ns_MS_AG ? row.ns_MS_AG: data[`${"ns_AD_NO"}_${index}`]}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      { /*Posting Item Number */}
      <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressType}
      />

    </Dialog>
  );
}
